import React, { useEffect, useState } from 'react'
import Footer from '../../../containers/footer'
import Header from '../../../containers/header'
import Preloader from '../../../components/preloader';
import UpComingEvents from "../../../views/company/components/common/upComingEvents";
import TopEvents from "../../../views/company/components/common/topEvents";
import { useHistory } from 'react-router-dom';

function CollegeShowcase() {
    const history = useHistory();
    const [loaderCount, setLoader] = useState(0)

    useEffect(() => {
        localStorage.removeItem("textName");
        if (localStorage.getItem("company") !== null) {
            let path = "/" + localStorage.getItem("company") + "-collegeShowcase";
            history.push(path);
        } else {
            history.push("/");
        }
    }, [])

    const increment = () => {
        setLoader(loaderCount + 1);
    }

    const decrement = () => {
        setLoader(loaderCount - 1);
    }

    return (
        <div>
            <>
                <Header />
                <div className="event-main">
                    {/* event section */}

                    {/* <TopEvents increment={increment} decrement={decrement} eventType="" /> */}

                    {/* upcoming Event */}

                    <UpComingEvents isPresent={3} filterEventType={true} filterCompany={false} eventTeamType={2} cType="pwb" textName="College Showcases" increment={increment} decrement={decrement} eventLabel={true} eventLinkLabel={true} filterForm={true} hideChkBox={true} noOfRec={6} eventType="" />

                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </>
        </div>
    )
}

export default CollegeShowcase
import {
    GET_PLAYER_COACH_SEARCH_REQUEST,
    GET_PLAYER_COACH_SEARCH_SUCCESS,
    GET_PLAYER_COACH_SEARCH_ERROR,

    SAVE_PLAYER_COACH_REQUEST,
    SAVE_PLAYER_COACH_SUCCESS,
    SAVE_PLAYER_COACH_ERROR,

    UPDATE_PLAYER_COACH_REQUEST,
    UPDATE_PLAYER_COACH_SUCCESS,
    UPDATE_PLAYER_COACH_ERROR,
    
    ROSTER_LIST_REQUEST,
    ROSTER_LIST_SUCCESS,
    ROSTER_LIST_ERROR,

    SAVE_COACH_ROSTER_REQUEST,
    SAVE_COACH_ROSTER_SUCCESS,
    SAVE_COACH_ROSTER_ERROR
} from '../../../../constants/authantication/signup/player/coach.const';

const initialState = {
    searchCoachRes: false,
    searchCoachData: null,
    searchCoachDataError: null,

    addCoachRes: false,
    addCoachData: null,
    addCoachDataError: null,

    upCoachRes: false,
    upCoachData: null,
    upCoachDataError: null,

    rosterListRes: false,
    rosterListData: null,
    rosterListError: null,

    addRosterRes: false,
    addRosterData: null,
    addRosterError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Zipcode Search Data
        case GET_PLAYER_COACH_SEARCH_REQUEST:
            return {
                ...state,
                searchCoachRes: true
            };
        case GET_PLAYER_COACH_SEARCH_SUCCESS:
            return {
                ...state,
                searchCoachData: action.payload,
            };
        case GET_PLAYER_COACH_SEARCH_ERROR:
            return {
                ...state,
                searchCoachDataError: action.error,
            };

        // save coach
        case SAVE_PLAYER_COACH_REQUEST:
            return {
                ...state,
                addCoachRes: true
            };
        case SAVE_PLAYER_COACH_SUCCESS:
            return {
                ...state,
                addCoachData: action.payload,
            };
        case SAVE_PLAYER_COACH_ERROR:
            return {
                ...state,
                addCoachDataError: action.error,
            };

        // update coach
        case UPDATE_PLAYER_COACH_REQUEST:
            return {
                ...state,
                upCoachRes: true
            };
        case UPDATE_PLAYER_COACH_SUCCESS:
            return {
                ...state,
                upCoachData: action.payload,
            };
        case UPDATE_PLAYER_COACH_ERROR:
            return {
                ...state,
                upCoachDataError: action.error,
            };

        // roster List
        case ROSTER_LIST_REQUEST:
            return {
                ...state,
                rosterListRes: true
            };
        case ROSTER_LIST_SUCCESS:
            return {
                ...state,
                rosterListData: action.payload,
            };
        case ROSTER_LIST_ERROR:
            return {
                ...state,
                rosterListError: action.error,
            };

        // save roster
        case SAVE_COACH_ROSTER_REQUEST:
            return {
                ...state,
                addRosterRes: true
            };
        case SAVE_COACH_ROSTER_SUCCESS:
            return {
                ...state,
                addRosterData: action.payload,
            };
        case SAVE_COACH_ROSTER_ERROR:
            return {
                ...state,
                addRosterError: action.error,
            };

        default:
            return state;
    }
}
import {
    FAN_COACH_LIST_REQUEST,
    FAN_COACH_LIST_SUCCESS,
    FAN_COACH_LIST_ERROR,
    FAN_ADD_COACH_REQUEST,
    FAN_ADD_COACH_SUCCESS,
    FAN_ADD_COACH_ERROR,
    FAN_COMPLETE_REQUEST,
    FAN_COMPLETE_SUCCESS,
    FAN_COMPLETE_ERROR,
} from '../../../../constants/authantication/signup/fan/coach.const';

const initialState = {
    searchCoachRes: false,
    searchCoachData: null,
    searchCoachDataError: null,

    saveCoachRes: false,
    saveCoachData: null,
    saveCoachDataError: null,

    completeFanRes: false,
    completeFanData: null,
    completeFanError: null
}

export default function (state = initialState, action) {
    switch (action.type) {

        // Team List
        case FAN_COACH_LIST_REQUEST:
            return {
                ...state,
                searchCoachRes: true
            };
        case FAN_COACH_LIST_SUCCESS:
            return {
                ...state,
                searchCoachData: action.payload,
            };
        case FAN_COACH_LIST_ERROR:
            return {
                ...state,
                searchCoachDataError: action.error,
            };


        // save Coach
        case FAN_ADD_COACH_REQUEST:
            return {
                ...state,
                saveCoachRes: true
            };
        case FAN_ADD_COACH_SUCCESS:
            return {
                ...state,
                saveCoachData: action.payload,
            };
        case FAN_ADD_COACH_ERROR:
            return {
                ...state,
                saveCoachDataError: action.error,
            };


        // complete Fan
        case FAN_COMPLETE_REQUEST:
            return {
                ...state,
                completeFanRes: true
            };
        case FAN_COMPLETE_SUCCESS:
            return {
                ...state,
                completeFanData: action.payload,
            };
        case FAN_COMPLETE_ERROR:
            return {
                ...state,
                completeFanError: action.error,
            };
        default:
            return state;
    }
}


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';
import { getLicenseName, getLicensePlayer, addAssign, editAssign, getLicenseCoach, addCoachAssign, editCoachAssign } from "../../saga/actions/views/coach/license.action";
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import DatePicker from "react-datepicker";
import commonCalls from "../../businesslogic/commonCalls";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
class assignLicense extends Component {
    constructor(props) {
        super(props)
        this.state = {
            getLic: {
                fullDetail: false,
                subscription_id: this.props.sub_id
            },
            licNameArr: [],
            sendAssign: {
                subscription_id: "",
                user_id: [],
                assignDate: new Date(),
                fromDate: "",
                toDate: "",
                isActive: true,
                indefiniteAccess: false
            },
            licPlayerArr: [],
            openFcal: false,
            openTcal: false,
            gameType: "1"
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.dateValidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        if (this.props.flag) {
            document.body.classList.add("modal-open");
            if (this.props.isEdited) {
                this.setState((prevState) => ({
                    ...prevState,
                    sendAssign: {
                        ...prevState.sendAssign,
                        subscription_id: this.props.subScriptionId,
                        user_id: this.props.data.user._id,
                        assignDate: new Date(this.props.data.assignDate),
                        fromDate: new Date(this.props.data.fromDate),
                        toDate: new Date(this.props.data.toDate),
                        isActive: true,
                        indefiniteAccess: this.props.data.indefiniteAccess
                    },
                    getLic: {
                        ...prevState.getLic,
                        subscription_id: this.props.subScriptionId
                    },
                }))
            }
        }
        this.setState({
            gameType: localStorage.getItem("gameType")
        })
        this.props.getLicenseName(this.state.getLic);
        if (this.props.subTab !== "coach") return this.props.getLicensePlayer();
        else return this.props.getLicenseCoach();

    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.licenseData &&
            nextProps.licenseData.licenseNameReq &&
            nextProps.licenseData.licenseNameData
        ) {
            nextProps.licenseData.licenseNameReq = false;
            if (nextProps.licenseData.licenseNameData.flag) {
                this.setState({
                    licNameArr: nextProps.licenseData.licenseNameData.result.data,
                }, () => {
                    nextProps.licenseData.licenseNameData = null;
                })
            } else {
                nextProps.licenseData.licenseNameData = null;
            }
        }

        if (nextProps.licenseData &&
            nextProps.licenseData.licensePlayerReq &&
            nextProps.licenseData.licensePlayerData
        ) {
            nextProps.licenseData.licensePlayerReq = false;
            if (nextProps.licenseData.licensePlayerData.flag) {
                let optionsArr = [];
                nextProps.licenseData.licensePlayerData.result.data.map((item, i) => {
                    optionsArr.push({ value: item._id, label: item.fname + " " + item.lname })
                });
                this.setState({
                    licPlayerArr: optionsArr,
                }, () => {
                    nextProps.licenseData.licensePlayerData = null;
                })
            } else {
                nextProps.licenseData.licensePlayerData = null;
            }
        }

        if (nextProps.licenseData &&
            nextProps.licenseData.addLicenseReq &&
            nextProps.licenseData.addLicenseData
        ) {
            console.log("add assign:--", nextProps.licenseData.addLicenseData);
            nextProps.licenseData.addLicenseReq = false;
            if (nextProps.licenseData.addLicenseData.flag) {
                this.commonCall.successMessage(nextProps.licenseData.addLicenseData.message);
                nextProps.licenseData.addLicenseData = null;
                document.body.classList.remove("modal-open");
                this.props.close();
            } else {
                this.commonCall.errorMessage(nextProps.licenseData.addLicenseData.message);
                nextProps.licenseData.addLicenseData = null;
            }
        }

        if (nextProps.licenseData &&
            nextProps.licenseData.updateLicenseReq &&
            nextProps.licenseData.updateLicenseData
        ) {
            console.log("update assign:--", nextProps.licenseData.updateLicenseData);
            nextProps.licenseData.updateLicenseReq = false;
            if (nextProps.licenseData.updateLicenseData.flag) {
                this.commonCall.successMessage(nextProps.licenseData.updateLicenseData.message);
                nextProps.licenseData.updateLicenseData = null;
                document.body.classList.remove("modal-open");
                this.props.close();
            } else {
                this.commonCall.errorMessage(nextProps.licenseData.updateLicenseData.message);
                nextProps.licenseData.updateLicenseData = null;
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "indefiniteAccess") {
            this.setState((prevState) => ({
                ...prevState,
                sendAssign: {
                    ...prevState.sendAssign,
                    [name]: !JSON.parse(value),
                    fromDate: "",
                    toDate: "",
                },
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sendAssign: {
                    ...prevState.sendAssign,
                    [name]: value
                },
            }));
        }
    }

    onHandleDateChange = (value, type) => {
        if (type === "fromDate") {
            this.setState((prevState) => ({
                ...prevState,
                sendAssign: {
                    ...prevState.sendAssign,
                    toDate: ""
                }
            }), () => {
                this.setState((prevState) => ({
                    ...prevState,
                    sendAssign: {
                        ...prevState.sendAssign,
                        [type]: new Date(value)
                    },
                }));
            })
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sendAssign: {
                    ...prevState.sendAssign,
                    [type]: new Date(value)
                },
            }));
        }

    }

    setOption = (val) => {
        console.log("val:--", val);
        if (val !== null) {
            let valArr = [];
            val.map((item, i) => {
                valArr.push(item.value)
            });
            this.setState((prevState) => ({
                ...prevState,
                sendAssign: {
                    ...prevState.sendAssign,
                    user_id: valArr
                },
                optionData: val
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sendAssign: {
                    ...prevState.sendAssign,
                    user_id: []
                },
                optionData: []
            }));
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    addAssign = () => {
        if (this.validator.allValid()) {
            if (this.state.sendAssign.indefiniteAccess) {
                this.state.sendAssign.fromDate = "";
                this.state.sendAssign.toDate = "";
                if (this.props.isEdited) {
                    if (this.props.subTab !== "coach") return this.props.editAssign(this.state.sendAssign);
                    else return this.props.editCoachAssign(this.state.sendAssign);
                } else {
                    if (this.props.subTab !== "coach") return this.props.addAssign(this.state.sendAssign);
                    else return this.props.addCoachAssign(this.state.sendAssign);
                }
            } else {
                if (this.dateValidator.allValid()) {
                    if (this.props.isEdited) {
                        console.log(this.state.sendAssign);
                        if (this.props.subTab !== "coach") return this.props.editAssign(this.state.sendAssign);
                        else return this.props.editCoachAssign(this.state.sendAssign);
                    } else {
                        if (this.props.subTab !== "coach") return this.props.addAssign(this.state.sendAssign);
                        else return this.props.addCoachAssign(this.state.sendAssign);
                    }
                } else {
                    this.dateValidator.showMessages();
                    this.forceUpdate();
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { flag, isEdited, subTab } = this.props;
        const { licNameArr, sendAssign, licPlayerArr, optionData, gameType } = this.state;
        const years = Array.from(new Array(81), (val, index) => (((new Date()).getFullYear() + 1) - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <>
                {/* Assign License modal */}
                <section className={`common-modal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>Assign License to {subTab === "coach" ? "Coach" : subTab === "player" ? "Player" : ""}</h3>
                                <div className="modal_close" onClick={this.props.close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                            </div>
                            <div className="modal-body">
                                <div className="team-body-inner">
                                    <div className="form-group mb-0">
                                        <label className="common-lable">Name</label>
                                        {
                                            !isEdited ?
                                                <Select
                                                    defaultValue={optionData}
                                                    value={optionData}
                                                    options={licPlayerArr}
                                                    onChange={(e) => this.setOption(e)}
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isMulti
                                                />
                                                :
                                                <select className="custom-select" name="user_id" value={sendAssign.user_id} disabled={isEdited} onChange={this.onHandleChange}>
                                                    <option value="" selected disabled>Select Name</option>
                                                    {
                                                        licPlayerArr &&
                                                        licPlayerArr.map((list, i) => {
                                                            return (
                                                                <option value={list.value}>{list.label}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                        }
                                        <span className="validMsg">{this.validator.message('Name', sendAssign.user_id, 'required')}</span>
                                    </div>
                                    <div className="form-group mb-0">
                                        <label className="common-lable">Module</label>
                                        <select className="custom-select" name="subscription_id" value={sendAssign.subscription_id} disabled={isEdited} onChange={this.onHandleChange}>
                                            <option value="" selected disabled>Select Module</option>
                                            {
                                                licNameArr &&
                                                licNameArr.map((list, i) => {
                                                    return (
                                                        <option value={list._id}>{list.productId.name === "BCTeam" ? gameType === "2" ? "SCTeam" : list.productId.name : list.productId.name}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <span className="validMsg">{this.validator.message('Module', sendAssign.subscription_id, 'required')}</span>
                                    </div>
                                    <div className="form-group-checkbox pt-10 mb-0">
                                        <input type="checkbox" name="indefiniteAccess" value={sendAssign.indefiniteAccess} checked={sendAssign.indefiniteAccess} onChange={this.onHandleChange} id="assign" />
                                        <label for="assign">Indefinite Access</label>
                                    </div>
                                    {
                                        !sendAssign.indefiniteAccess &&
                                        <>
                                            <div className="form-group mb-0">
                                                <label className="common-lable">From date</label>
                                                <div className="bc_relative">
                                                    <DatePicker
                                                        ref={(c) => (this._fcal = c)}
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                            </div>
                                                        )}
                                                        selected={sendAssign.fromDate}
                                                        minDate={moment().subtract(0, 'days').toDate()}
                                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                        onChange={(e) => this.onHandleDateChange(e, 'fromDate')}
                                                        placeholderText="Select date"
                                                    />
                                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                                        this.state.openFcal = !this.state.openFcal;
                                                        this._fcal.setOpen(this.state.openFcal);
                                                    }} />
                                                </div>
                                                <span className="validMsg">{this.dateValidator.message('From date', sendAssign.fromDate, 'required')}</span>
                                            </div>
                                            <div className="form-group mb-0">
                                                <label className="common-lable">To date</label>
                                                <div className="bc_relative">
                                                    <DatePicker
                                                        ref={(c) => (this._tcal = c)}
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                            </div>
                                                        )}
                                                        selected={sendAssign.toDate}
                                                        minDate={moment(sendAssign.fromDate).subtract(-1, 'days').toDate()}
                                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                        onChange={(e) => this.onHandleDateChange(e, 'toDate')}
                                                        placeholderText="Select date"
                                                    />
                                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                                        this.state.openTcal = !this.state.openTcal;
                                                        this._tcal.setOpen(this.state.openTcal);
                                                    }} />
                                                </div>
                                                <span className="validMsg">{this.dateValidator.message('To date', sendAssign.toDate, 'required')}</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="flex-align next-button">
                                    <Link className="btn light-green" onClick={() => this.addAssign()}>Assign</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

assignLicense.propTypes = {
    getLicenseName: PropTypes.func.isRequired,
    getLicensePlayer: PropTypes.func.isRequired,
    addAssign: PropTypes.func.isRequired,
    editAssign: PropTypes.func.isRequired,
    getLicenseCoach: PropTypes.func.isRequired,
    addCoachAssign: PropTypes.func.isRequired,
    editCoachAssign: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    licenseData: state.licenseInfo,
});

export default connect(mapStateToProps, { getLicenseName, getLicensePlayer, addAssign, editAssign, getLicenseCoach, addCoachAssign, editCoachAssign })(assignLicense);

import React, { useEffect, useState } from 'react';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RankingHeroBanner from '../../../../components/rankingHeroBanner';
import { getPlayerRanking, getAllPlayerList } from '../../../../saga/actions/views/company/pwRanking/rankings/playerRanking.action';
import { getAllStates } from '../../../../saga/actions/common/api.action';
import { GET_PLAYER_RANKING_STATES_DETAILS_SUCCESS } from '../../../../saga/constants/common/api.const';
import { GET_PLAYER_RANKING_PAGE_SUCCESS, GET_ALL_PLAYER_RANKING_LIST_SUCCESS } from '../../../../saga/constants/view/company/pwRanking/rankings/playerRanking.const';
import { imagesArr } from '../../../../assets/images';
import Preloader from '../../../../components/preloader';
import PurchaseProspectwire from '../../../popup/purchaseProspectwire';
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const DropdownIndicator = (
    props
) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={imagesArr.black_slight_search} />
        </components.DropdownIndicator>
    );
};

function PlayerRanking(props) {

    const [type, setType] = useState({
        page: 0,
        limit: 0,
        type: 2,
        rankType: localStorage.getItem("rankType") ? JSON.parse(localStorage.getItem("rankType")) : 1,
        ageGroup: "",
        position: localStorage.getItem("position") ? localStorage.getItem("position") : "",
        gradeYear: localStorage.getItem("gradeYear") ? localStorage.getItem("gradeYear") : "2021",
        state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
        playerName: localStorage.getItem("playerName") ? JSON.parse(localStorage.getItem("playerName")).label : "",
        companyId: localStorage.getItem("cid")
    })
    const history = useHistory();
    const [playerList, setPlayerList] = useState([])
    const [playerOption, setPlayerOption] = useState([])
    const [optionData, setOptionData] = useState(localStorage.getItem("playerName") ? JSON.parse(localStorage.getItem("playerName")) : [])
    const [stateList, setStateList] = useState([])

    const [loader, setLoader] = useState(0);
    const [purchase, setPurchase] = useState(false);

    const { getPlayerRankingSuccess: selectedData, getAllPlayerRankingListSuccess: playerListData } = useSelector((state) => state.playerRanking);
    const { getPlayerRankingStatesData: statelistData } = useSelector((state) => state.api);
    // const { sendSummaryReq: sendSummaryReq, tabs: tabs, backupTab: backupTab, statsType: statsType, eventPageReq: eventPageReq, eventCurrentPage: eventCurrentPage } = useSelector((state) => state.profileSummaryInfo);
    const summaryInfo = useSelector((state) => state.profileSummaryInfo);

    const increment = () => {
        setLoader(1)
    }

    const decrement = () => {
        setLoader(-1)
    }

    const updateCategory = e => {
        const { name, value } = e.target;
        if (name === "rankType" && value === 1) {
            setType((prevState) => ({
                ...prevState,
                state: ""
            }))
        }
        increment();
        setType((prevState) => ({
            ...prevState,
            [name]: value
        }))
        console.log("type:---", type);
        dispatch(getPlayerRanking({
            page: 0,
            limit: 0,
            type: 2,
            rankType: name === "rankType" ? Number(value) : Number(type.rankType),
            ageGroup: name === "ageGroup" ? value : type.ageGroup,
            position: name === "position" ? value : type.position,
            gradeYear: name === "gradeYear" ? value : type.gradeYear,
            state: name === "state" ? value : type.state,
            playerName: type.playerName,
            companyId: localStorage.getItem("cid")
        }))
        return () => {
            decrement();
        }
    }

    useEffect(() => {
        increment();
        dispatch(getPlayerRanking(type))
    }, [])

    useEffect(() => {
        if (selectedData) {
            if (selectedData.flag) {
                setPlayerList(selectedData.result.data)
                dispatch({
                    type: GET_PLAYER_RANKING_PAGE_SUCCESS,
                    payload: null,
                });
                if (playerOption.length !== 0) {
                    decrement();
                } else {
                    dispatch(getAllPlayerList())
                }
            } else {
                dispatch({
                    type: GET_PLAYER_RANKING_PAGE_SUCCESS,
                    payload: null,
                });
                decrement();
            }
        }
    }, [selectedData])

    useEffect(() => {
        if (playerListData) {
            if (playerListData.flag) {
                let result = playerListData.result;
                let arr = [];
                result.data.map((item, i) => {
                    arr.push({ value: item._id, label: item.fname ? item.fname + " " + item.lname : "" })
                });
                setPlayerOption(arr)
                dispatch({
                    type: GET_ALL_PLAYER_RANKING_LIST_SUCCESS,
                    payload: null,
                });
                if (stateList.length !== 0) {
                    decrement();
                } else {
                    dispatch(getAllStates('PLAYER_RANKING'))
                }
            } else {
                dispatch({
                    type: GET_ALL_PLAYER_RANKING_LIST_SUCCESS,
                    payload: null,
                });
                decrement();
            }
        }
    }, [playerListData])

    useEffect(() => {
        if (statelistData) {
            if (statelistData.flag) {
                let result = statelistData.result;
                setStateList(result.data);
                dispatch({
                    type: GET_PLAYER_RANKING_STATES_DETAILS_SUCCESS,
                    payload: null,
                });
                decrement();
            } else {
                dispatch({
                    type: GET_PLAYER_RANKING_STATES_DETAILS_SUCCESS,
                    payload: null,
                });
                decrement();
            }
        }
    }, [statelistData])

    const dispatch = useDispatch()

    const goto = (item) => {
        dispatch({
            type: "SET_DEFAULT_VALUE",
            payload: Object.assign(summaryInfo, {
                sendSummaryReq: {
                    tab: "summary",
                    id: "",
                    eventTeamType: 0,
                    sortOn: {
                        startDate: "",
                        endDate: ""
                    },
                    seasonType: 1,
                    pitching: "basic",
                    batting: "basic",
                    filterOn: "",
                    field: "10YardDash",
                    metricSubType: "Speed",
                    metricType: 1
                },
                tabs: 0,
                backupTab: 0,
                statsType: 0,
                eventPageReq: {
                    id: "",
                    page: 1,
                    limit: 10
                },
                eventCurrentPage: 0
            })
        })
        history.push(`/pw-profile-info/${item._id}`)
    }

    const purchasePW = () => {
        setPurchase(true);
    }

    const handlePurchaseClose = () => {
        setPurchase(false);
        document.body.classList.remove("modal-open");
    }

    const setOption = (val) => {
        console.log("val:---", val);
        goto({ _id: val.value })
        // setType((prevState) => ({
        //     ...prevState,
        //     playerName: val.label,
        // }))
        // setOptionData(val);
        // increment();
        // dispatch(getPlayerRanking({
        //     page: 0,
        //     limit: 0,
        //     type: 2,
        //     rankType: Number(type.rankType),
        //     ageGroup: type.ageGroup,
        //     position: type.position,
        //     gradeYear: type.gradeYear,
        //     state: type.state,
        //     playerName: val.label,
        //     companyId: type.companyId
        // }))
    }

    const years = Array.from(new Array(5), (val, index) => ((new Date()).getFullYear() + index));
    console.log("type.rankType:---", type.rankType)
    return (
        <>
            <Header />
            {/* <RankingHeroBanner /> */}
            <div className="player-ranking-section pb-70 pt-70">
                <div className="container">
                    <div className="team-ranking">
                        <div className="team-rankings-info">
                            <h3 className="section_title">Player Rankings</h3>
                            <div className="national">
                                <Select
                                    defaultValue={optionData}
                                    value={optionData}
                                    options={playerOption}
                                    onChange={(e) => setOption(e)}
                                    closeMenuOnSelect={true}
                                    className="ranking-select"
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                    placeholder={"Player search"}
                                />
                            </div>
                            <div className="national">
                                <select className="custom-select" name="rankType" value={type.rankType} onChange={updateCategory}>
                                    <option value="1" className={`list-group-item ${type.rankType === 1 ? "active" : ""}`}>National</option>
                                    <option value="2" className={`list-group-item ${type.rankType === 2 ? "active" : ""}`}>State</option>
                                    <option value="3" className={`list-group-item ${type.rankType === 3 ? "active" : ""}`}>Position</option>
                                </select>
                            </div>
                            {
                                type.rankType === 2 &&
                                <div className="national">
                                    <select className="custom-select" name="state" value={type.state} onChange={updateCategory}>
                                        <option value="">State</option>
                                        {stateList &&
                                            stateList.map((item) => {
                                                return (<option value={item.state}>{item.state}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            <div className="national">
                                <select className="custom-select" name="position" value={type.position} onChange={updateCategory}>
                                    <option value="">All</option>
                                    <option value="RHP">RHP</option>
                                    <option value="LHP">LHP</option>
                                    <option value="C">C</option>
                                    <option value="1B">1B</option>
                                    <option value="2B">2B</option>
                                    <option value="3B">3B</option>
                                    <option value="SS">SS</option>
                                    <option value="LF">LF</option>
                                    <option value="CF">CF</option>
                                    <option value="RF">RF</option>
                                    <option value="UTIL">UTIL</option>
                                </select>
                            </div>
                            <div className="national">
                                <select className="custom-select" name="gradeYear" value={type.gradeYear} onChange={updateCategory}>
                                    {
                                        years &&
                                        years.map((y) => {
                                            return (
                                                <option value={y} className="list-group-item">{y}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="national-rankings-info">
                            <h3 className="section_title">Class of {localStorage.getItem("gradeYear") ? localStorage.getItem("gradeYear") : "2021"} {type.rankType == "1" ? 'National' : (type.rankType == "2" ? 'State' : 'Position')} Rankings</h3>
                            {
                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") ?
                                    <div className="ranking-logo">
                                        <div className="national-rank-item">
                                            <div className="national-rank-img">
                                                <img src={imagesArr.cart_user} />
                                                <span>01</span>
                                            </div>
                                        </div>
                                        <div className="national-rank-item">
                                            <div className="national-rank-img">
                                                <img src={imagesArr.dashboard_user} />
                                                <span>02</span>
                                            </div>
                                        </div>
                                        <div className="national-rank-item">
                                            <div className="national-rank-img">
                                                <img src={imagesArr.cart_user} />
                                                <span>03</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    (type.rankType) && (playerList && playerList.length !== 0) ?
                                        <div className="ranking-logo">
                                            {playerList.map((item, index) => {
                                                return (
                                                    <div className="national-rank-item">
                                                        <div className="national-rank-img">
                                                            {item.profileUrl ?
                                                                <img src={item.profileUrl} />
                                                                :
                                                                <img src={imagesArr.default_user} />
                                                            }

                                                            {((index + 1) > 9) ? <span>{index + 1}</span> : <span>0{index + 1}</span>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <p className="text-center pt-10">No data found</p>
                            }

                        </div>

                    </div>

                    <div className="ranking-table">
                        <div className="table-responsive-xl">
                            <table className="table">
                                <thead>
                                    <th>Rank</th>
                                    <th>Player Name</th>
                                    <th>Position</th>
                                    <th>Home Town</th>
                                    <th>High School</th>
                                </thead>
                                <tbody>
                                    {
                                        // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") ?
                                        //     <>
                                        //         <tr>
                                        //             <td className="p-0" colSpan="7">
                                        //                 <div className="ranking-banner">
                                        //                     <div className="ranking-banner-logo">
                                        //                         <img src={imagesArr.ranking_banner_img} alt="ranking banner " />
                                        //                         <p>Available Exclusivly Through the DiamondConnect App</p>
                                        //                         <div className="see-more-btn">
                                        //                             <a class="btn ranking-btn" href="">See More</a>
                                        //                         </div>
                                        //                         <span className="lock-icon" onClick={() => purchasePW()}><img src={imagesArr.lock_icon} alt="lock icon" /></span>
                                        //                     </div>
                                        //                 </div>
                                        //                 <div className="lock_view">
                                        //                     <div className="logo_lock">
                                        //                         <div className="logo_img">
                                        //                             <img src={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} />
                                        //                         </div>
                                        //                         <div className="lock_text">
                                        //                             <p>PW+ displays the top five rankings in all categories.<span onClick={() => purchasePW()}>Subscribe </span>to one of our Premium Packages to <span onClick={() => purchasePW()}>see more.</span></p>
                                        //                         </div>
                                        //                         <div className="lock_img" onClick={() => purchasePW()}>

                                        //                             <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                        //                         </div>
                                        //                     </div>
                                        //                 </div>
                                        //             </td>
                                        //         </tr>
                                        //     </>
                                        //     :
                                            (playerList && playerList.length !== 0) ?
                                                <>
                                                    {playerList.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr onClick={() => goto(item)}>
                                                                    <td rowspan="2">{(index + 1) > 9 ? `${index + 1}` : `0${index + 1}`}</td>
                                                                    <td rowspan="2" className="team-name"><span><img src={item.profileUrl ? `${item.profileUrl}` : `${imagesArr.default_user}`} alt="player" />{item.fname}</span></td>
                                                                    <td>{item.position ? `${item.position}` : `-`}</td>
                                                                    <td>{item.homeTown ? `${item.homeTown}` : `-`}</td>
                                                                    <td>{item.school ? `${item.school}` : `-`}</td>
                                                                </tr>
                                                                <tr>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <tr>
                                                    <td colspan="5">
                                                        <p className="text-center">No data found</p>
                                                    </td>
                                                </tr>
                                    }
                                    {/* {(playerList && playerList.length !== 0) ?
                                        <>
                                            {playerList.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr onClick={() => goto(item._id)}>
                                                            <td rowspan="2">{(index + 1) > 9 ? `${index + 1}` : `0${index + 1}`}</td>
                                                            <td rowspan="2" className="team-name"><span><img src={item.profileUrl ? `${item.profileUrl}` : `${imagesArr.default_user}`} alt="player" />{item.fname}</span></td>
                                                            <td>{item.position ? `${item.position}` : `-`}</td>
                                                            <td>{item.homeTown ? `${item.homeTown}` : `-`}</td>
                                                            <td>{item.school ? `${item.school}` : `-`}</td>
                                                        </tr>
                                                        <tr>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                        :
                                        <tr>
                                            <td colspan="5">
                                                <p className="text-center">No data found</p>
                                            </td>
                                        </tr>
                                    } */}
                                    {/* {
                                        (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") &&
                                        <tr>
                                            <td className="p-0" colSpan="7">
                                                <div className="ranking-banner">
                                                    <div className="ranking-banner-logo">
                                                        <img src={imagesArr.ranking_banner_img} alt="ranking banner " />
                                                        <p>Available Exclusivly Through the DiamondConnect App</p>
                                                        <div className="see-more-btn">
                                                            <a class="btn ranking-btn" href="">See More</a>
                                                        </div>
                                                        <span className="lock-icon" onClick={() => purchasePW()}><img src={imagesArr.lock_icon} alt="lock icon" /></span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    } */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Preloader flag={loader > 0 ? true : false} />
            {
                purchase &&
                <PurchaseProspectwire
                    flag={purchase}
                    close={handlePurchaseClose}
                    type="PW"
                />
            }
        </>
    )
}

export default PlayerRanking;

import {
    GET_EVENT_DETAILS_REQUEST,
    GET_EVENT_DETAILS_SUCCESS,
    GET_EVENT_DETAILS_ERROR,

    GET_PARTICIPATION_EVENT_REQUEST,
    GET_PARTICIPATION_EVENT_SUCCESS,
    GET_PARTICIPATION_EVENT_ERROR,

    GET_ROSTER_GROUP_LIST_REQUEST,
    GET_ROSTER_GROUP_LIST_SUCCESS,
    GET_ROSTER_GROUP_LIST_ERROR,

    FETCH_PAYMENT_INTENT_REQUEST,
    FETCH_PAYMENT_INTENT_SUCCESS,
    FETCH_PAYMENT_INTENT_ERROR,

    EVENT_REGISTRATION_PAYMENT_REQUEST,
    EVENT_REGISTRATION_PAYMENT_SUCCESS,
    EVENT_REGISTRATION_PAYMENT_ERROR,

    EVENT_PAYMENT_COMPLETE_REQUEST,
    EVENT_PAYMENT_COMPLETE_SUCCESS,
    EVENT_PAYMENT_COMPLETE_ERROR,

    GET_TEAM_AGE_VEFICATION_REQUEST,
    GET_TEAM_AGE_VEFICATION_SUCCESS,
    GET_TEAM_AGE_VEFICATION_ERROR,

    GET_ROSTER_AGE_AMOUNT_REQUEST,
    GET_ROSTER_AGE_AMOUNT_SUCCESS,
    GET_ROSTER_AGE_AMOUNT_ERROR,

    SET_EVENT_REGI_AGE_GROUP_REQUEST,
    SET_EVENT_REGI_AGE_GROUP_SUCCESS,
    SET_EVENT_REGI_AGE_GROUP_ERROR,

    GET_COUPAN_CODE_AMOUNT_REQUEST,
    GET_COUPAN_CODE_AMOUNT_SUCCESS,
    GET_COUPAN_CODE_AMOUNT_ERROR,

    GET_LEAGUE_COUPAN_CODE_AMOUNT_REQUEST,
    GET_LEAGUE_COUPAN_CODE_AMOUNT_SUCCESS,
    GET_LEAGUE_COUPAN_CODE_AMOUNT_ERROR,

    GET_INSTALLMENT_DETAILS_REQUEST,
    GET_INSTALLMENT_DETAILS_SUCCESS,
    GET_INSTALLMENT_DETAILS_ERROR,

    EVENT_INSTALLMENT_PAYMENT_REQUEST,
    EVENT_INSTALLMENT_PAYMENT_SUCCESS,
    EVENT_INSTALLMENT_PAYMENT_ERROR,
     
    GET_MULTIPLE_EVENT_ERROR,
    GET_MULTIPLE_EVENT_SUCCESS,

    MULTIPLE_EVENT_PAYMENT_SUCCESS,
    MULTIPLE_EVENT_PAYMENT_ERROR,

    BAY_SCOUT_PACKAGE_SUCCESS,
    BAY_SCOUT_PACKAGE_ERROR,

    DOWNLOAD_SCOUT_PACKAGE_SUCCESS,
    DOWNLOAD_SCOUT_PACKAGE_ERROR,

    GET_EVENT_RULE_SUCCESS,
    GET_EVENT_RULE_ERROR,
    
    GET_EVENT_LINKED_PLAYER_LIST_REQ,
    GET_EVENT_LINKED_PLAYER_LIST_SUCCESS,
    GET_EVENT_LINKED_PLAYER_LIST_ERROR,
    GET_COST_BY_AGE_REQUEST,
    GET_COST_BY_AGE_SUCCESS,
    GET_COST_BY_AGE_ERROR,
  
} from '../../constants/common/eventDetails.const';

const initialState = {
    getDetailsReq: false,
    getDetailsData: null,
    getDetailsError: null,

    getParticipationReq: false,
    getParticipationData: null,
    getParticipationError: null,

    getRosterOptReq: false,
    getRosterOptData: null,
    getRosterOptError: null,

    getPaymentIntentReq: false,
    getPaymentIntentData: null,
    getPaymentIntentError: null,

    eventRegistrationReq: false,
    eventRegistrationData: null,
    eventRegistrationError: null,

    eventPaymentCompleteReq: false,
    eventPaymentCompleteData: null,
    eventPaymentCompleteError: null,

    getAgeVerifyReq: false,
    getAgeVerifyData: null,
    getAgeVerifyError: null,

    getRosterAmountReq: false,
    getRosterAmountData: null,
    getRosterAmountError: null,

    setEventRegiAgeReq: false,
    setEventRegiAgeData: null,
    setEventRegiAgeError: null,

    getCoupanCodeReq: false,
    getCoupanCodeData: null,
    getCoupanCodeError: null,

    getLeagueCoupanCodeReq: false,
    getLeagueCoupanCodeData: null,
    getLeagueCoupanCodeError: null,

    getInstallmentDetailsReq: false,
    getInstallmentDetailsData: null,
    getInstallmentDetailsError: null,

    payInstallmentReq: false,
    payInstallmentData: null,
    payInstallmentError: null,

    getMultipleEvent: null,
    getMultipleEventError: null,

    multipleEventPayment: null,
    multipleEventPaymentError: null,

    buyScoutPackage: null,
    buyScoutPackagError: null,

    downloadScoutPackage: null,
    downloadScoutPackagError: null,

    getEventRuleData: null,
    getEventRuleError: null,

    getLinkedPlayerReq: false,
    getLinkedPlayerData: null,
    getLinkedPlayerError: null,

    getCostByAgeReq:false,
    getCostByAgeData:null,
    getCostByAgeError:null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // pay installment data request    
        case EVENT_INSTALLMENT_PAYMENT_REQUEST:
            return {
                ...state,
                payInstallmentReq: true,
            };

        case EVENT_INSTALLMENT_PAYMENT_SUCCESS:
            return {
                ...state,
                payInstallmentData: action.payload,
            };
        case EVENT_INSTALLMENT_PAYMENT_ERROR:
            return {
                ...state,
                payInstallmentError: action.error,
            };

        // get installment data request    
        case GET_INSTALLMENT_DETAILS_REQUEST:
            return {
                ...state,
                getInstallmentDetailsReq: true,
            };

        case GET_INSTALLMENT_DETAILS_SUCCESS:
            return {
                ...state,
                getInstallmentDetailsData: action.payload,
            };
        case GET_INSTALLMENT_DETAILS_ERROR:
            return {
                ...state,
                getInstallmentDetailsError: action.error,
            };

        // send league coupancode request    
        case GET_LEAGUE_COUPAN_CODE_AMOUNT_REQUEST:
            return {
                ...state,
                getLeagueCoupanCodeReq: true,
            };

        case GET_LEAGUE_COUPAN_CODE_AMOUNT_SUCCESS:
            return {
                ...state,
                getLeagueCoupanCodeData: action.payload,
            };
        case GET_LEAGUE_COUPAN_CODE_AMOUNT_ERROR:
            return {
                ...state,
                getLeagueCoupanCodeError: action.error,
            };

        // send coupancode request    
        case GET_COUPAN_CODE_AMOUNT_REQUEST:
            return {
                ...state,
                getCoupanCodeReq: true,
            };

        case GET_COUPAN_CODE_AMOUNT_SUCCESS:
            return {
                ...state,
                getCoupanCodeData: action.payload,
            };
        case GET_COUPAN_CODE_AMOUNT_ERROR:
            return {
                ...state,
                getCoupanCodeError: action.error,
            };

        // Set Event Registration Age Group req    
        case SET_EVENT_REGI_AGE_GROUP_REQUEST:
            return {
                ...state,
                setEventRegiAgeReq: true,
            };

        case SET_EVENT_REGI_AGE_GROUP_SUCCESS:
            return {
                ...state,
                setEventRegiAgeData: action.payload,
            };
        case SET_EVENT_REGI_AGE_GROUP_ERROR:
            return {
                ...state,
                setEventRegiAgeError: action.error,
            };

        // Get Cost By Age Req
        case GET_COST_BY_AGE_REQUEST:
            return {
                ...state,
                getCostByAgeReq:true,
            }

        case GET_COST_BY_AGE_SUCCESS:
            return {
                ...state,
                getCostByAgeData:action.payload
            }

            case GET_COST_BY_AGE_ERROR:
                return {
                    ...state,
                    getCostByAgeError:action.error
                }

        // Event details req    
        case GET_EVENT_DETAILS_REQUEST:
            return {
                ...state,
                getDetailsReq: true,
            };

        case GET_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                getDetailsData: action.payload,
            };
        case GET_EVENT_DETAILS_ERROR:
            return {
                ...state,
                getDetailsError: action.error,
            };


        // Event participation req    
        case GET_PARTICIPATION_EVENT_REQUEST:
            return {
                ...state,
                getParticipationReq: true,
            };

        case GET_PARTICIPATION_EVENT_SUCCESS:
            return {
                ...state,
                getParticipationData: action.payload,
            };
        case GET_PARTICIPATION_EVENT_ERROR:
            return {
                ...state,
                getParticipationError: action.error,
            };

        // Get Roster List req    
        case GET_ROSTER_GROUP_LIST_REQUEST:
            return {
                ...state,
                getRosterOptReq: true,
            };

        case GET_ROSTER_GROUP_LIST_SUCCESS:
            return {
                ...state,
                getRosterOptData: action.payload,
            };
        case GET_ROSTER_GROUP_LIST_ERROR:
            return {
                ...state,
                getRosterOptError: action.error,
            };


        // Get Payment Intent req    
        case FETCH_PAYMENT_INTENT_REQUEST:
            return {
                ...state,
                getPaymentIntentReq: true,
            };

        case FETCH_PAYMENT_INTENT_SUCCESS:
            return {
                ...state,
                getPaymentIntentData: action.payload,
            };
        case FETCH_PAYMENT_INTENT_ERROR:
            return {
                ...state,
                getPaymentIntentError: action.error,
            };

        // Event Registration Complete req    
        case EVENT_REGISTRATION_PAYMENT_REQUEST:
            return {
                ...state,
                eventRegistrationReq: true,
            };

        case EVENT_REGISTRATION_PAYMENT_SUCCESS:
            return {
                ...state,
                eventRegistrationData: action.payload,
            };
        case EVENT_REGISTRATION_PAYMENT_ERROR:
            return {
                ...state,
                eventRegistrationError: action.error,
            };

        // Event Payment Complete req    
        case EVENT_PAYMENT_COMPLETE_REQUEST:
            return {
                ...state,
                eventPaymentCompleteReq: true,
            };

        case EVENT_PAYMENT_COMPLETE_SUCCESS:
            return {
                ...state,
                eventPaymentCompleteData: action.payload,
            };
        case EVENT_PAYMENT_COMPLETE_ERROR:
            return {
                ...state,
                eventPaymentCompleteError: action.error,
            };

        // Event Roster age verification req    
        case GET_TEAM_AGE_VEFICATION_REQUEST:
            return {
                ...state,
                getAgeVerifyReq: true,
            };

        case GET_TEAM_AGE_VEFICATION_SUCCESS:
            return {
                ...state,
                getAgeVerifyData: action.payload,
            };
        case GET_TEAM_AGE_VEFICATION_ERROR:
            return {
                ...state,
                getAgeVerifyError: action.error,
            };

        // Event Roster age amount req    
        case GET_ROSTER_AGE_AMOUNT_REQUEST:
            return {
                ...state,
                getRosterAmountReq: true,
            };

        case GET_ROSTER_AGE_AMOUNT_SUCCESS:
            return {
                ...state,
                getRosterAmountData: action.payload,
            };
        case GET_ROSTER_AGE_AMOUNT_ERROR:
            return {
                ...state,
                getRosterAmountError: action.error,
            };

            
        // for list of multiple event payment    
        case GET_MULTIPLE_EVENT_SUCCESS:
            return {
                ...state,
                getMultipleEvent: action.payload,
            };
        case GET_MULTIPLE_EVENT_ERROR:
            return {
                ...state,
                getMultipleEventError: action.error,
            };


        // for pay of multiple event 
        case MULTIPLE_EVENT_PAYMENT_SUCCESS:
            return {
                ...state,
                multipleEventPayment: action.payload,
            };
        case MULTIPLE_EVENT_PAYMENT_ERROR:
            return {
                ...state,
                multipleEventPaymentError: action.error,
            };


        // for digital scout package
        case BAY_SCOUT_PACKAGE_SUCCESS:
            return {
                ...state,
                buyScoutPackage: action.payload,
            };
        case BAY_SCOUT_PACKAGE_ERROR:
            return {
                ...state,
                buyScoutPackagError: action.error,
            };


        // for download scout package
        case DOWNLOAD_SCOUT_PACKAGE_SUCCESS:
            return {
                ...state,
                downloadScoutPackage: action.payload,
            };
        case DOWNLOAD_SCOUT_PACKAGE_ERROR:
            return {
                ...state,
                downloadScoutPackagError: action.error,
            };

        // For GET Admin Data
        case GET_EVENT_RULE_SUCCESS:
            return {
                ...state,
                getEventRuleData: action.payload,
            };
        case GET_EVENT_RULE_ERROR:
            return {
                ...state,
                getEventRuleError: action.error,
            };

        // For GET Linked Player List
        case GET_EVENT_LINKED_PLAYER_LIST_REQ:
            return {
                ...state,
                getLinkedPlayerReq:true
            }
        case GET_EVENT_LINKED_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                getLinkedPlayerData:action.payload,
            }
        case GET_EVENT_LINKED_PLAYER_LIST_ERROR:
            return{
                ...state,
                getLinkedPlayerError:action.error,
            }

        default:
            return state;
    }
}
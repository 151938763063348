import {
    GET_FAC_REQUEST,
    GET_FAC_SUCCESS,
    GET_FAC_ERROR,

    GET_FAC_SEARCH_REQUEST,
    GET_FAC_SEARCH_SUCCESS,
    GET_FAC_SEARCH_ERROR,

    UP_FAC_IMAGE_REQUEST,
    UP_FAC_IMAGE_SUCCESS,
    UP_FAC_IMAGE_ERROR,

    SAVE_FAC_REQUEST,
    SAVE_FAC_SUCCESS,
    SAVE_FAC_ERROR,
} from '../../../../constants/authantication/signup/coach/facility.const';

const initialState = {
    facilityRes: false,
    facilityData: null,
    facilityDataError: null,

    searchZipRes: false,
    searchZipData: null,
    searchZipDataError: null,

    facImageRes: false,
    facImageData: null,
    facImageDataError: null,

    saveFacRes: false,
    saveFacData: null,
    saveFacDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Facility
        case GET_FAC_REQUEST:
            return {
                ...state,
                facilityRes: true
            };
        case GET_FAC_SUCCESS:
            return {
                ...state,
                facilityData: action.payload,
            };
        case GET_FAC_ERROR:
            return {
                ...state,
                facilityDataError: action.error,
            };

        // Get Zipcode Search Data
        case GET_FAC_SEARCH_REQUEST:
            return {
                ...state,
                searchZipRes: true
            };
        case GET_FAC_SEARCH_SUCCESS:
            return {
                ...state,
                searchZipData: action.payload,
            };
        case GET_FAC_SEARCH_ERROR:
            return {
                ...state,
                searchZipDataError: action.error,
            };

        // upload Image Search Data
        case UP_FAC_IMAGE_REQUEST:
            return {
                ...state,
                facImageRes: true
            };
        case UP_FAC_IMAGE_SUCCESS:
            return {
                ...state,
                facImageData: action.payload,
            };
        case UP_FAC_IMAGE_ERROR:
            return {
                ...state,
                facImageDataError: action.error,
            };

        // save team
        case SAVE_FAC_REQUEST:
            return {
                ...state,
                saveFacRes: true
            };
        case SAVE_FAC_SUCCESS:
            return {
                ...state,
                saveFacData: action.payload,
            };
        case SAVE_FAC_ERROR:
            return {
                ...state,
                saveFacDataError: action.error,
            };

        default:
            return state;
    }
}
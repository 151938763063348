import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Bracket,
    BracketGame,
    BracketGenerator
} from "react-tournament-bracket";

import { getAppleTest } from '../saga/actions/common/api.action';

// schedulingSS1

const winOfGame4SS1 = {
    id: "winOfGame4SS1",
    name: "quarter-finals #4",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112456",
                name: "Team B"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: null,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "12545765",
                name: "Team C"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: null,
                sourcePool: {}
            }
        }
    }
};

const winOfGame5SS1 = {
    id: "winOfGame5SS1",
    name: "semi-finals #5",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team A"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 4"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame4SS1,
                sourcePool: {}
            }
        }
    }
};

const winOfGame6SS1 = {
    id: "winOfGame6SS1",
    name: "semi-finals #6",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112",
                name: "Losser of game 4"
            },
        },
        visitor: {
            team: {
                id: "125",
                name: "Losser of game 5"
            },
        }
    }
};

const gameSchedulingSS1 = {
    id: "gameSchedulingSS1",
    name: "final #7",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "103",
                name: "Winner of game 5"
            },
            seed: {
                displayName: "A1",
                rank: 1,
                sourceGame: winOfGame5SS1,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "11",
                name: "Winner of game 6"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame6SS1,
                sourcePool: {}
            }
        }
    }
};

const gameSchedulingSS1Final = {
    id: "gameSchedulingSS1Final",
    name: "final",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "103",
                name: "Winner of game 5"
            },
            seed: {
                displayName: "A1",
                rank: 1,
                sourceGame: gameSchedulingSS1,
                sourcePool: {}
            }
        },
    }
};

// schedulingSS2

const winOfGame5 = {
    id: "winOfGame5",
    name: "semi-finals #5",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112",
                name: "Team A"
            },
        },
        visitor: {
            team: {
                id: "125",
                name: "Team B"
            },
        }
    }
};

const winOfGame6 = {
    id: "winOfGame6",
    name: "semi-finals #6",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112",
                name: "Team C"
            },
        },
        visitor: {
            team: {
                id: "125",
                name: "Team D"
            },
        }
    }
};

const gameSchedulingSS2 = {
    id: "gameSchedulingSS2",
    name: "final #7",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Winning of game 5"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame5,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 6"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame6,
                sourcePool: {}
            }
        }
    }
};

const gameSchedulingSS2Final = {
    id: "gameSchedulingSS2Final",
    name: "final",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "103",
                name: "Winner of game 6"
            },
            seed: {
                displayName: "A1",
                rank: 1,
                sourceGame: gameSchedulingSS2,
                sourcePool: {}
            }
        },
    }
};

// schedulingSS3

const winOfGame2SS3 = {
    id: "winOfGame2SS3",
    name: "semi-finals #2",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112",
                name: "Team C"
            },
        },
        visitor: {
            team: {
                id: "125",
                name: "Team D"
            },
        }
    }
};

const winOfGame1SS3 = {
    id: "winOfGame1SS3",
    name: "quarter-finals #1",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112",
                name: "Team A"
            },
        },
        visitor: {
            team: {
                id: "125",
                name: "Team B"
            },
        }
    }
};

const winOfGame3SS3 = {
    id: "winOfGame3SS3",
    name: "semi-finals #3",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "112",
                name: "Team E"
            },
        },
        visitor: {
            team: {
                id: "125",
                name: "Winner of game 1"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame1SS3,
                sourcePool: {}
            }
        }
    }
};

const gameSchedulingSS3 = {
    id: "gameSchedulingSS3",
    name: "final #4",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Winning of game 2"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame2SS3,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 3"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame3SS3,
                sourcePool: {}
            }
        }
    }
};

const gameSchedulingSS3Final = {
    id: "gameSchedulingSS3Final",
    name: "final",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "103",
                name: "Winner of game 2"
            },
            seed: {
                displayName: "A1",
                rank: 1,
                sourceGame: gameSchedulingSS3,
                sourcePool: {}
            }
        },
    }
};

// schedulingSS4

const winOfGame1SS4 = {
    id: "winOfGame1SS4",
    name: "querter-final #1",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team A"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Team B"
            }
        }
    }
}
const winOfGame2SS4 = {
    id: "winOfGame2SS4",
    name: "querter-final #2",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team C"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Team D"
            }
        }
    }
}
const winOfGame3SS4 = {
    id: "winOfGame3SS4",
    name: "semi-final #3",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team E"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 1"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame1SS4,
                sourcePool: {}
            }
        }
    }
}
const winOfGame4SS4 = {
    id: "winOfGame4SS4",
    name: "semi-final #4",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team F"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 2"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame2SS4,
                sourcePool: {}
            }
        }
    }
}
const gameSchedulingSS4 = {
    id: "final #5",
    name: "final #5",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Winning of game 3"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame3SS4,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 4"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame4SS4,
                sourcePool: {}
            }
        }
    }
};
const gameSchedulingSS4Final = {
    id: "gameSchedulingSS4Final",
    name: "final",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "103",
                name: "Winner of game 2"
            },
            seed: {
                displayName: "A1",
                rank: 1,
                sourceGame: gameSchedulingSS4,
                sourcePool: {}
            }
        },
    }
};

// schedulingSS5

const winOfGame1SS5 = {
    id: "winOfGame1SS5",
    name: "querter-final #1",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team A"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Team B"
            }
        }
    }
}

const winOfGame2SS5 = {
    id: "winOfGame2SS5",
    name: "querter-final #2",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team C"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Team D"
            }
        }
    }
}

const winOfGame3SS5 = {
    id: "winOfGame3SS5",
    name: "querter-final #3",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team E"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Team F"
            }
        }
    }
}

const winOfGame4SS5 = {
    id: "winOfGame4SS5",
    name: "semi-final #4",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Team G"
            },
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 1"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame1SS5,
                sourcePool: {}
            }
        }
    }
}

const winOfGame5SS5 = {
    id: "winOfGame5SS5",
    name: "semi-final #5",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Winning of game 2"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame2SS5,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 3"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame3SS5,
                sourcePool: {}
            }
        }
    }
}

const gameSchedulingSS5 = {
    id: "gameSchedulingSS5",
    name: "final #6",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "12",
                name: "Winning of game 4"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame4SS5,
                sourcePool: {}
            }
        },
        visitor: {
            team: {
                id: "13",
                name: "Winning of game 5"
            },
            seed: {
                displayName: "A2",
                rank: 1,
                sourceGame: winOfGame5SS5,
                sourcePool: {}
            }
        }
    }
};

const gameSchedulingSS5Final = {
    id: "gameSchedulingSS5Final",
    name: "final",
    scheduled: Number(new Date()),
    sides: {
        home: {
            team: {
                id: "103",
                name: "Winner of game 2"
            },
            seed: {
                displayName: "A1",
                rank: 1,
                sourceGame: gameSchedulingSS5,
                sourcePool: {}
            }
        },
    }
};

class testing extends Component {
    // old version
    // findGame = (s, i) => {
    //     let game = data.qsf[i + 1]
    //     console.log("game:------", game);
    //     let flg;
    //     if (game) {
    //         for (let j = 0; j < game.seeds.length; j++) {
    //             console.log("j:--", j);
    //             console.log("qf:-----", game.seeds[j].qf);
    //             console.log("s.game_no:-----", s.game_no);
    //             if (game.seeds[j].qf.length) {
    //                 if (game.seeds[j].qf.includes(s.game_no)) {
    //                     flg = true
    //                 }
    //             } else {
    //                 flg = false
    //             }
    //         }
    //     } else {
    //         flg = true
    //     }
    //     return flg;
    // }

    componentDidMount = () => {
        let url = new URL(document.location);
        let getPath = url.search.split("?").length > 1 ? { [url.search.split("?")[1].split("=")[0]]: url.search.split("?")[1].split("=")[1] } : {};

        console.log("getPath header:---", getPath);

        this.props.getAppleTest(getPath);

        document.querySelectorAll("g > svg").forEach(i => {
            var div1Paras = i.getElementsByTagName('g');
            if (div1Paras.length === 3) {
                // aa statc svg jyare 2 j round hoy like semifnial and final tyare j aa batava nu 
                // i.outerHTML = '<svg width="400" height="130" viewBox="0 0 200 82" x="950" y="195"><text x="100" y="8" text-anchor="middle" style="fill: rgb(131, 146, 165); font-size: 11px; display: none; font-family: &quot;Helvetica Medium&quot;;">2/17/2022</text><rect x="0" y="12" width="200" height="22.5" fill="white" rx="3" ry="3"></rect><g><rect x="0" y="12" height="22.5" width="200" fill-opacity="0" style="fill: white; stroke-width: 1; stroke-linejoin: round; stroke: rgb(150, 207, 4);"><title>A1</title></rect><g><defs><clipPath id="0d535569-0fb1-45e7-8a85-c9fd7cd5ec24"><rect x="0" y="12" width="165" height="22.5"></rect></clipPath></defs><g clip-path="url(#0d535569-0fb1-45e7-8a85-c9fd7cd5ec24)"><text x="5" y="28" style="fill: rgb(131, 146, 165); font-size: 13px; font-family: &quot;Helvetica Medium&quot;; font-style: italic;"><title>A1</title>Winner Team</text></g></g><text x="185" y="28" text-anchor="middle" style="font-size: 13px; display: none; font-family: &quot;Helvetica Medium&quot;;"></text></g></svg>'

                // aa static design jyare 3 round hoy ne pachi final batava no hoy tyare batava ni
                i.outerHTML = '<svg width="400" height="130" viewBox="0 0 200 82" x="1400" y="455"><text x="100" y="8" text-anchor="middle" style="fill: rgb(131, 146, 165); font-size: 11px; display: none; font-family: &quot;Helvetica Medium&quot;;">2/17/2022</text><rect x="0" y="12" width="200" height="11" fill="white" rx="3" ry="3"></rect><rect x="0" y="12" width="200" height="22.5" fill="white" rx="3" ry="3"></rect><g><rect x="0" y="12" height="22.5" width="200" fill-opacity="0" style="fill: white; stroke-width: 1; stroke-linejoin: round; stroke: rgb(150, 207, 4);"><title>A1</title></rect><g><defs><clipPath id="3611ceda-e153-4b4f-882d-4d0710c297f5"><rect x="0" y="12" width="165" height="22.5"></rect></clipPath></defs><g clip-path="url(#3611ceda-e153-4b4f-882d-4d0710c297f5)"><text x="5" y="28" style="fill: rgb(131, 146, 165); font-size: 13px; font-family: &quot;Helvetica Medium&quot;; font-style: italic;"><title>A1</title>Winner Team</text></g></g><text x="185" y="28" text-anchor="middle" style="font-size: 13px; display: none; font-family: &quot;Helvetica Medium&quot;;"></text></g><line x1="0" y1="34.5" x2="200" y2="34.5" style="stroke: black; stroke-width: 0;"></line></svg>';
            }
        })

        document.querySelectorAll("g > rect").forEach(i => {
            i.style.fill = "white"
            i.style.strokeWidth = "1"
            i.style.strokeLinejoin = "round"
            i.style.stroke = "rgb(150, 207, 4)"
            return i;
        })

        document.querySelectorAll("g > path").forEach(i => {
            i.style.stroke = "rgb(150, 207, 4)"
            return i;
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonData &&
            nextProps.commonData.appleTestReq &&
            nextProps.commonData.appleTestData
        ) {
            console.log("nextProps.commonData.appleTestData:-", nextProps.commonData.appleTestData);
        }
    }

    render() {

        return (
            <>
                <h1>schedulingSS1</h1>
                {/* <Bracket game={gameSchedulingSS1} lineInfo={{ yOffset: -5, separation: 0, homeVisitorSpread: 0 }} svgPadding={0} className="testing" /> */}
                <Bracket
                    game={gameSchedulingSS1Final}
                    hoveredTeamId={null}
                    lineInfo={{ yOffset: -10, separation: -25, homeVisitorSpread: 15 }}
                    gameDimensions={{ width: 400, height: 130 }}
                    roundSeparatorWidth={50}
                    svgPadding={0}
                    styles={
                        {
                            backgroundColor: 'white',
                            scoreBackground: 'white',
                            winningScoreBackground: 'white',
                            teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                            teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                            gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                            gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                            teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                        }
                    }
                />
                <br />
                <h1>schedulingSS2</h1>
                <Bracket game={gameSchedulingSS2Final}
                    hoveredTeamId={null}
                    lineInfo={{ yOffset: -10, separation: -25, homeVisitorSpread: 15 }}
                    gameDimensions={{ width: 400, height: 130 }}
                    roundSeparatorWidth={50}
                    svgPadding={0}
                    styles={
                        {
                            backgroundColor: 'white',
                            scoreBackground: 'white',
                            winningScoreBackground: 'white',
                            teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                            teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                            gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                            gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                            teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                        }
                    } />
                <br />
                <h1>schedulingSS3</h1>
                <Bracket game={gameSchedulingSS3Final} hoveredTeamId={null}
                    lineInfo={{ yOffset: -10, separation: -25, homeVisitorSpread: 15 }}
                    gameDimensions={{ width: 400, height: 130 }}
                    roundSeparatorWidth={50}
                    svgPadding={0}
                    styles={
                        {
                            backgroundColor: 'white',
                            scoreBackground: 'white',
                            winningScoreBackground: 'white',
                            teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                            teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                            gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                            gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                            teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                        }
                    } />
                <br />
                <h1>schedulingSS4</h1>
                <Bracket game={gameSchedulingSS4Final} hoveredTeamId={null}
                    lineInfo={{ yOffset: -10, separation: -25, homeVisitorSpread: 15 }}
                    gameDimensions={{ width: 400, height: 130 }}
                    roundSeparatorWidth={50}
                    svgPadding={0}
                    styles={
                        {
                            backgroundColor: 'white',
                            scoreBackground: 'white',
                            winningScoreBackground: 'white',
                            teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                            teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                            gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                            gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                            teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                        }
                    } />
                <br />
                <h1>schedulingSS5</h1>
                <Bracket game={gameSchedulingSS5Final} hoveredTeamId={null}
                    lineInfo={{ yOffset: -10, separation: -25, homeVisitorSpread: 15 }}
                    gameDimensions={{ width: 400, height: 130 }}
                    roundSeparatorWidth={50}
                    svgPadding={0}
                    styles={
                        {
                            backgroundColor: 'white',
                            scoreBackground: 'white',
                            winningScoreBackground: 'white',
                            teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                            teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                            gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                            gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                            teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                        }
                    } />


                {/* <main id="tournament">
                    {
                        data.qsf.map((b, i) => {
                            return (
                                <ul className={`round round-${i + 1}`}>
                                    {
                                        b.title === "Quarter Final" ?
                                            b.seeds.length > 1 ?
                                                <li className="spacer">&nbsp;</li>
                                                :
                                                ""
                                            :
                                            <li className="spacer">&nbsp;</li>
                                    }
                                    {
                                        b.seeds.map((s, j) => {
                                            console.log("this.findGame(s):---", this.findGame(s, i));
                                            if (this.findGame(s, i)) {
                                                return (
                                                    <>
                                                        <li className="game game-top winner">{s.teams[0].name}</li>
                                                        <li className={`game ${b.title === "Quarter Final" ? b.seeds.length > 1 ? "game-spacer" : "qf-game-spacer" : "game-spacer"}`}>#{s.game_no}</li>
                                                        <li className="game game-bottom ">{s.teams[1].name}</li>

                                                        <li className="spacer">&nbsp;</li>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <li className="game game-top winner">No Team</li>
                                                        <li className="game game-spacer">#0</li>
                                                        <li className="game game-bottom ">No Team</li>

                                                        <li className="spacer">&nbsp;</li>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            )
                        })
                    }
                </main> */}
            </>
        );
    }
}

testing.propTypes = {
    getAppleTest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    commonData: state.api,
});

export default connect(mapStateToProps, { getAppleTest })(testing);

import {
    SOCIAL_CHECK_REQUEST,
    SOCIAL_CHECK_SUCCESS,
    SOCIAL_CHECK_ERROR,
} from '../../../constants/authantication/signup/step1.const';

const initialState = {
    loadRes: 0,
    socialReq: false,
    socialData: null,
    socialDataError: null,
    actionData: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Country Code
        case SOCIAL_CHECK_REQUEST:
            return {
                ...state,
                socialReq: true
            };
        case SOCIAL_CHECK_SUCCESS:
            return {
                ...state,
                socialData: action.payload,
                actionData: action.storageData
            };
        case SOCIAL_CHECK_ERROR:
            return {
                ...state,
                socialDataError: action.error,
                actionData: action.storageData
            };

        default:
            return state;
    }
}

import {
    GET_PLAYER_REQUEST,
    GET_PLAYER_SUCCESS,
    GET_PLAYER_ERROR,

    GET_OPTION_REQUEST,
    GET_OPTION_SUCCESS,
    GET_OPTION_ERROR,

    GET_PLAYER_OPTION_REQUEST,
    GET_PLAYER_OPTION_SUCCESS,
    GET_PLAYER_OPTION_ERROR,

    SAVE_PLAYER_ROSTER_REQUEST,
    SAVE_PLAYER_ROSTER_SUCCESS,
    SAVE_PLAYER_ROSTER_ERROR,

    ADD_NEW_PLAYER_REQUEST,
    ADD_NEW_PLAYER_SUCCESS,
    ADD_NEW_PLAYER_ERROR,

    EDIT_NEW_PLAYER_REQUEST,
    EDIT_NEW_PLAYER_SUCCESS,
    EDIT_NEW_PLAYER_ERROR,

    DEL_NEW_PLAYER_REQUEST,
    DEL_NEW_PLAYER_SUCCESS,
    DEL_NEW_PLAYER_ERROR,

    DIS_NEW_PLAYER_REQUEST,
    DIS_NEW_PLAYER_SUCCESS,
    DIS_NEW_PLAYER_ERROR,

    GTE_LINK_PLAYER_REQUEST,
    GTE_LINK_PLAYER_SUCCESS,
    GTE_LINK_PLAYER_ERROR,

    ALL_MAIL_PLAYERINFO_SUCCESS,
    ALL_MAIL_PLAYERINFO_ERROR,
} from '../../../../constants/view/coach/profile/playerInfo.const';

const initialState = {
    getPlayerReq: false,
    getPlayerData: null,
    getPlayerError: null,

    getOptionReq: false,
    getOptionData: null,
    getOptionError: null,

    getPlayerOptionReq: false,
    getPlayerOptionData: null,
    getPlayerOptionError: null,

    saveRosterReq: false,
    saveRosterData: null,
    saveRosterError: null,

    addNewPlayerReq: false,
    addNewPlayerData: null,
    addNewPlayerError: null,

    editNewPlayerReq: false,
    editNewPlayerData: null,
    editNewPlayerError: null,

    delNewPlayerReq: false,
    delNewPlayerData: null,
    delNewPlayerError: null,

    disNewPlayerReq: false,
    disNewPlayerData: null,
    disNewPlayerError: null,

    getLinkPlayerReq: false,
    getLinkPlayerData: null,
    getLinkPlayerError: null,

    getAllCheckInfoPlayerData: null,
    getAllCheckInfoPlayerError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // get player req    
        case GET_PLAYER_REQUEST:
            return {
                ...state,
                getPlayerReq: true,
            };
        case GET_PLAYER_SUCCESS:
            return {
                ...state,
                getPlayerData: action.payload,
            };
        case GET_PLAYER_ERROR:
            return {
                ...state,
                getPlayerError: action.error,
            };

        // Option req    
        case GET_OPTION_REQUEST:
            return {
                ...state,
                getOptionReq: true,
            };
        case GET_OPTION_SUCCESS:
            return {
                ...state,
                getOptionData: action.payload,
            };
        case GET_OPTION_ERROR:
            return {
                ...state,
                getOptionError: action.error,
            };

        // Option player req    
        case GET_PLAYER_OPTION_REQUEST:
            return {
                ...state,
                getPlayerOptionReq: true,
            };
        case GET_PLAYER_OPTION_SUCCESS:
            return {
                ...state,
                getPlayerOptionData: action.payload,
            };
        case GET_PLAYER_OPTION_ERROR:
            return {
                ...state,
                getPlayerOptionError: action.error,
            };

        // save roster req    
        case SAVE_PLAYER_ROSTER_REQUEST:
            return {
                ...state,
                saveRosterReq: true,
            };
        case SAVE_PLAYER_ROSTER_SUCCESS:
            return {
                ...state,
                saveRosterData: action.payload,
            };
        case SAVE_PLAYER_ROSTER_ERROR:
            return {
                ...state,
                saveRosterError: action.error,
            };

        // add player req    
        case ADD_NEW_PLAYER_REQUEST:
            return {
                ...state,
                addNewPlayerReq: true,
            };
        case ADD_NEW_PLAYER_SUCCESS:
            return {
                ...state,
                addNewPlayerData: action.payload,
            };
        case ADD_NEW_PLAYER_ERROR:
            return {
                ...state,
                addNewPlayerError: action.error,
            };

        // edit player req    
        case EDIT_NEW_PLAYER_REQUEST:
            return {
                ...state,
                editNewPlayerReq: true,
            };
        case EDIT_NEW_PLAYER_SUCCESS:
            return {
                ...state,
                editNewPlayerData: action.payload,
            };
        case EDIT_NEW_PLAYER_ERROR:
            return {
                ...state,
                editNewPlayerError: action.error,
            };

        // delete player req    
        case DEL_NEW_PLAYER_REQUEST:
            return {
                ...state,
                delNewPlayerReq: true,
            };
        case DEL_NEW_PLAYER_SUCCESS:
            return {
                ...state,
                delNewPlayerData: action.payload,
            };
        case DEL_NEW_PLAYER_ERROR:
            return {
                ...state,
                delNewPlayerError: action.error,
            };

        // dis player req    
        case DIS_NEW_PLAYER_REQUEST:
            return {
                ...state,
                disNewPlayerReq: true,
            };
        case DIS_NEW_PLAYER_SUCCESS:
            return {
                ...state,
                disNewPlayerData: action.payload,
            };
        case DIS_NEW_PLAYER_ERROR:
            return {
                ...state,
                disNewPlayerError: action.error,
            };

        // get link player req    
        case GTE_LINK_PLAYER_REQUEST:
            return {
                ...state,
                getLinkPlayerReq: true,
            };
        case GTE_LINK_PLAYER_SUCCESS:
            return {
                ...state,
                getLinkPlayerData: action.payload,
            };
        case GTE_LINK_PLAYER_ERROR:
            return {
                ...state,
                getLinkPlayerError: action.error,
            };

        case ALL_MAIL_PLAYERINFO_SUCCESS:
            return {
                ...state,
                getAllCheckInfoPlayerData: action.payload,
            };
        case ALL_MAIL_PLAYERINFO_ERROR:
            return {
                ...state,
                getAllCheckInfoPlayerError: action.error,
            };

        default:
            return state;
    }
}
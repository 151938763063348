import axios from 'axios';
import { Auth_Company_Key, API_Url, Authorization, EVENT_API_Url, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    COM_AD_SLIDER_REQUEST,
    COM_AD_SLIDER_SUCCESS,
    COM_AD_SLIDER_ERROR,

    COM_DIVISION_SLIDER_REQUEST,
    COM_DIVISION_SLIDER_SUCCESS,
    COM_DIVISION_SLIDER_ERROR,

    COM_DIVISION_EVENTLIST_REQUEST,
    COM_DIVISION_EVENTLIST_SUCCESS,
    COM_DIVISION_EVENTLIST_ERROR,

    COM_DIVISION_SLIDER_DIV_LIST_REQUEST,
    COM_DIVISION_SLIDER_DIV_LIST_SUCCESS,
    COM_DIVISION_SLIDER_DIV_LIST_ERROR,

    COM_GET_AGE_DIV_REQUEST,
    COM_GET_AGE_DIV_SUCCESS,
    COM_GET_AGE_DIV_ERROR,

    GET_LIVE_EVENT_SCORE_REQUEST,
    GET_LIVE_EVENT_SCORE_SUCCESS,
    GET_LIVE_EVENT_SCORE_ERROR
} from '../../constants/common/slider.const';
import histrory from '../../../History';

export const getAgeDivData = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_GET_AGE_DIV_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/eventFilter/list`,
        data: data,
        headers: {
            // 'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(getagediv => {
        console.log("getagediv:--", getagediv);
        dispatch({
            type: COM_GET_AGE_DIV_SUCCESS,
            payload: getagediv.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_GET_AGE_DIV_ERROR, error: error.response.data.message })
        }
    });
};

export const adSlider = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_AD_SLIDER_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event-company-advertise/list`,
        data: data,
        headers: {
            // 'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(ads => {
        console.log("ads:--", ads);
        dispatch({
            type: COM_AD_SLIDER_SUCCESS,
            payload: ads.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_AD_SLIDER_ERROR, error: error.response.data.message })
        }
    });
};

export const divisionEvents = (id) => dispatch => {
    dispatch({ type: COM_DIVISION_EVENTLIST_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/list?id=${id}`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(sliderEvent => {
        dispatch({
            type: COM_DIVISION_EVENTLIST_SUCCESS,
            payload: sliderEvent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_DIVISION_EVENTLIST_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventDivisionScore = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_DIVISION_SLIDER_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/eventscore/filter`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(divisionInfo => {
        dispatch({
            type: COM_DIVISION_SLIDER_SUCCESS,
            payload: divisionInfo.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_DIVISION_SLIDER_ERROR, error: error.response.data.message })
        }
    });
};

export const getDivisionList = () => dispatch => {
    dispatch({ type: COM_DIVISION_SLIDER_DIV_LIST_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}setting/division`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(divList => {
        dispatch({
            type: COM_DIVISION_SLIDER_DIV_LIST_SUCCESS,
            payload: divList.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_DIVISION_SLIDER_DIV_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const getLiveEventScore = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: GET_LIVE_EVENT_SCORE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/eventList/score`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(res => {
        dispatch({
            type: GET_LIVE_EVENT_SCORE_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LIVE_EVENT_SCORE_ERROR, error: error.response.data.message })
        }
    });
};

export const emptyLiveEventScore=()=>dispatch=>{
    dispatch({
        type: GET_LIVE_EVENT_SCORE_SUCCESS,
        payload:null
    })
}
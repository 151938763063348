import React, { Component } from 'react';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import ProudSponsors from "../../components/common/proudSponsors";
import PastEvents from "../../components/common/pastEvents";
import Shop from "../../components/common/shop";
import Preloader from '../../../../components/preloader';

export default class allPastEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0
        }
    }

    componentDidMount = () => {
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { loaderCount } = this.state;
        return (
            <div>
                <Header />
                <div className="event-main">
                    {console.log('localStorage.getItem("textName")',localStorage.getItem("textName"))}
                    <PastEvents
                        eventTeamType={(localStorage.getItem("textName") === "Tryouts" || localStorage.getItem("textName") === "Showcases" || localStorage.getItem("textName") === "Camps" || localStorage.getItem("textName") === "College Showcases") ? 2 : localStorage.getItem("textName") === "Events" ? "" : 1} textName={localStorage.getItem("textName")}
                        increment={this.increment}
                        decrement={this.decrement}
                        eventType={(localStorage.getItem("company") === "pwb" || localStorage.getItem("company") === "gob" || localStorage.getItem("company") === "pioneer") ? 1 : (localStorage.getItem("company") === "utb" || localStorage.getItem("company") === "dc") ? "" : 2}
                        hideChkBox={true}
                        filterForm={true}
                    />
                    {localStorage.getItem("company") !== "dc" &&
                        <>
                            {localStorage.getItem("company") !== "pioneer" && <div className="upcomingevent_section">
                                <Shop cType={localStorage.getItem("company")} />
                            </div>}
                            <ProudSponsors increment={this.increment} decrement={this.decrement} />
                        </>}
                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </div>
        )
    }
}

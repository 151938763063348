import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_INVITATION_REQUEST,
    GET_PLAYER_INVITATION_SUCCESS,
    GET_PLAYER_INVITATION_ERROR,

    GET_PLAYER_BASIC_REQUEST,
    GET_PLAYER_BASIC_SUCCESS,
    GET_PLAYER_BASIC_ERROR,

    SAVE_PLAYER_BASIC_REQUEST,
    SAVE_PLAYER_BASIC_SUCCESS,
    SAVE_PLAYER_BASIC_ERROR,

    SAVE_PLAYER_ACCEPT_REQUEST,
    SAVE_PLAYER_ACCEPT_SUCCESS,
    SAVE_PLAYER_ACCEPT_ERROR,
} from '../../../../constants/authantication/signup/player/basic.const';
import histrory from '../../../../../History';

export const getInvitation = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_INVITATION_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/player/invitaion`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(invitation => {
        console.log("invitation:--", invitation);
        dispatch({
            type: GET_PLAYER_INVITATION_SUCCESS,
            payload: invitation.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_PLAYER_INVITATION_ERROR, error: error.response.data.message })
    });
};

export const getPlayerBasicDetails = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_BASIC_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/player/basic`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: GET_PLAYER_BASIC_SUCCESS,
            payload: basic.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_PLAYER_BASIC_ERROR, error: error.response.data.message })
    });
};

export const savePlayerBasic = (data) => dispatch => {
    console.log("saveBasic:--", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_PLAYER_BASIC_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/basic`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(basic => {
        console.log("basic:---- ", basic);
        dispatch({
            type: SAVE_PLAYER_BASIC_SUCCESS,
            payload: basic.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_PLAYER_BASIC_ERROR, error: error.response.data.message })
    });
};

export const saveAccept = (data) => dispatch => {
    console.log("saveAccept:--", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_PLAYER_ACCEPT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/invitaion`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(accept => {
        console.log("accept:---- ", accept);
        dispatch({
            type: SAVE_PLAYER_ACCEPT_SUCCESS,
            payload: accept.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_PLAYER_ACCEPT_ERROR, error: error.response.data.message })
    });
};
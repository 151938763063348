import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import CommonHeroBanner from '../../components/commonHeroBanner';
import { getPerksLink } from '../../saga/actions/common/api.action';
import Preloader from '../../components/preloader';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';

class coachPerks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            perksLink: "",
        }
    }

    componentDidMount() {
        this.setLoader(true);
        this.props.getPerksLink('Coach');
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.perkInfo &&
            nextProps.perkInfo.getPerksInfoReq &&
            nextProps.perkInfo.getPerksInfoData
        ) {
            nextProps.perkInfo.getPerksInfoReq = false;
            console.log("nextProps.perkInfo.getPerksInfoData:--", nextProps.perkInfo.getPerksInfoData);
            if (nextProps.perkInfo.getPerksInfoData.flag) {
                this.setState({
                    perksLink: nextProps.perkInfo.getPerksInfoData.result.CurrentToken
                }, () => {
                    nextProps.perkInfo.getPerksInfoData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.perkInfo.getPerksInfoData.message);
                nextProps.perkInfo.getPerksInfoData = null;
                this.setLoader(false);
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, perksLink } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="coachdashboard">
                    <div className="products pb-0">
                        <div className="container">
                            {/* <div className="white-bg text-center"> */}
                            <div className="perks-section">
                                <div className="perks-inof">
                                    <h3>Perks</h3>
                                    <p>We appreciate your business. BaseballCloud is working hard to strategically partner with companies to bring you deals, discounts, and membership perks.Please continue to check back for new additions to this page</p>
                                </div>
                                <div className="img-link">
                                    <div className="perks-img">
                                        <img src={imagesArr.perks} />
                                    </div>
                                    <div className="link-info">
                                    {
                                        perksLink &&
                                        <a className="btn light-green mt-10" href={`https://www.dctravelrewards.com/v6?siteId=71019&memberToken=${perksLink}`} target="_blank">DCTravel - Hotel Discounts</a>
                                    }   
                                    </div>
                                </div>
                                <div className="img-link">
                                    <div className="perks-img">
                                        <img src={imagesArr.gameSense} alt='GAMESENSE' />
                                    </div>
                                    <div className="link-info">
                                    {
                                        perksLink &&
                                        <a className="btn light-green mt-10" href={'https://gamesensesports.com/?via=diamondconnect'} target="GAMESENSE">GAMESENSE – Pitch Recognition</a>
                                    }   
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        )
    }
}

coachPerks.propTypes = {
    getPerksLink: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    perkInfo: state.api,
});

export default connect(mapStateToProps, { getPerksLink })(coachPerks);

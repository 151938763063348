import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import { sendCalculate, getCalculate } from '../../saga/actions/views/company/personalInfo.action';
import commonCalls from "../../businesslogic/commonCalls";
import PurchaseProspectwire from './purchaseProspectwire';

class Strength extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                benchPress: {
                    flag: true,
                    value: 0
                },
                inclinePress: {
                    flag: true,
                    value: 0
                },
                backSquat: {
                    flag: true,
                    value: 0
                },
                frontSquat: {
                    flag: true,
                    value: 0
                },
                boardJump: {
                    flag: true,
                    value: 0
                },
                verticalJump: {
                    flag: true,
                    value: 0
                },
                deadLift: {
                    flag: true,
                    value: 0
                },
                handClean: {
                    flag: true,
                    value: 0
                },
                powerClean: {
                    flag: true,
                    value: 0
                }
            },
            purchase: false,
        };
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        if (this.props.flag) {
            document.body.classList.add("modal-open");
        }
        if (localStorage.getItem("webglToken")) {
            this.props.increment();
            this.props.getCalculate();
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.personalInfo &&
            nextProps.personalInfo.setCalculateDataReq &&
            nextProps.personalInfo.setCalculateData
        ) {
            nextProps.personalInfo.setCalculateDataReq = false;
            if (nextProps.personalInfo.setCalculateData.flag) {
                nextProps.personalInfo.setCalculateData = null;
                this.props.decrement();
                this.props.close();
            } else {
                this.commonCall.errorMessage(nextProps.personalInfo.setCalculateData.message);
                nextProps.personalInfo.setCalculateData = null;
                this.props.decrement();
            }
        }

        if (nextProps.personalInfo &&
            nextProps.personalInfo.getCalculateDataReq &&
            nextProps.personalInfo.getCalculateData
        ) {
            nextProps.personalInfo.getCalculateDataReq = false;
            if (nextProps.personalInfo.getCalculateData.flag) {
                this.setState((prevState) => ({
                    ...prevState,
                    sendReq: {
                        ...prevState.sendReq,
                        ...nextProps.personalInfo.getCalculateData.result
                    },
                }), () => {
                    nextProps.personalInfo.getCalculateData = null;
                    this.props.decrement();
                });
            } else {
                this.commonCall.errorMessage(nextProps.personalInfo.getCalculateData.message);
                nextProps.personalInfo.getCalculateData = null;
                this.props.decrement();
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: {
                    ...prevState.sendReq[name],
                    value: Number(value)
                }
            },
        }));
    }

    chkChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: {
                    ...prevState.sendReq[name],
                    flag: !this.state.sendReq[name].flag
                }
            },
        }));
    }

    onlyNumber = event => {
        const keycode = event.which;
        console.log("keycode:===", keycode);
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    keycode === 46 ||
                    keycode === 45 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };

    calculate = () => {
        this.props.increment();
        this.props.sendCalculate(this.state.sendReq);
    }

    purchasePW = () => {
        this.setState({
            purchase: true,
        })
    }

    handlePurchaseClose = () => {
        this.setState({
            purchase: false,
        })
    }

    render() {
        const { flag, registeredCount } = this.props;
        const { sendReq, purchase } = this.state;

        return (
            <>
                {
                    !purchase &&
                    <section className={`common-modal ${flag ? "show" : ""}`}>
                        <div className="modal-dialog strength-info">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3>Strength - Manually entered, used to calculate score with H/W/Age</h3>
                                    <div className="modal_close" onClick={this.props.close}>
                                        <img src={imagesArr.gray_modal_close} alt="modal_close" />
                                    </div>
                                </div>
                                <div className="modal-body ">
                                    <div className="strength-info-content">
                                        <div className="strength-item">
                                            <div className="strength-box">
                                                <h3>Upper Body Strength</h3>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">

                                                        <input type="checkbox" id="players" name="benchPress" onChange={(e) => this.chkChange(e)} value={sendReq.benchPress.flag} checked={sendReq.benchPress.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Bench Press</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="benchPress" value={sendReq.benchPress.value} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." onKeyPress={this.onlyNumber} disabled={sendReq.benchPress.flag} />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="inclinePress" onChange={(e) => this.chkChange(e)} value={sendReq.inclinePress.flag} checked={sendReq.inclinePress.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Incline Press</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="inclinePress" value={sendReq.inclinePress.value} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." onKeyPress={this.onlyNumber} disabled={sendReq.inclinePress.flag} />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="strength-item">
                                            <div className="strength-box">
                                                <h3>Leg and Hip Strength</h3>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="backSquat" onChange={(e) => this.chkChange(e)} value={sendReq.backSquat.flag} checked={sendReq.backSquat.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Back Squat</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="backSquat" value={sendReq.backSquat.value} onKeyPress={this.onlyNumber} disabled={sendReq.backSquat.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="frontSquat" onChange={(e) => this.chkChange(e)} value={sendReq.frontSquat.flag} checked={sendReq.frontSquat.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Front Squat</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="frontSquat" value={sendReq.frontSquat.value} onKeyPress={this.onlyNumber} disabled={sendReq.frontSquat.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="strength-item">
                                            <div className="strength-box">
                                                <h3>Explosive Performance</h3>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="boardJump" onChange={(e) => this.chkChange(e)} value={sendReq.boardJump.flag} checked={sendReq.boardJump.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Board Jump</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <input className="form-control" name="boardJump" value={sendReq.boardJump.value} onKeyPress={this.onlyNumber} disabled={sendReq.boardJump.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                    </div>
                                                </div>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="verticalJump" onChange={(e) => this.chkChange(e)} value={sendReq.verticalJump.flag} checked={sendReq.verticalJump.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Vertical Jump</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <input className="form-control" name="verticalJump" value={sendReq.verticalJump.value} onKeyPress={this.onlyNumber} disabled={sendReq.verticalJump.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="strength-item">
                                            <div className="strength-box">
                                                <h3>Explosive Strength</h3>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="deadLift" onChange={(e) => this.chkChange(e)} value={sendReq.deadLift.flag} checked={sendReq.deadLift.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Deadlift</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="deadLift" value={sendReq.deadLift.value} onKeyPress={this.onlyNumber} disabled={sendReq.deadLift.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="handClean" onChange={(e) => this.chkChange(e)} value={sendReq.handClean.flag} checked={sendReq.handClean.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Hand Clean</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="handClean" value={sendReq.handClean.value} onKeyPress={this.onlyNumber} disabled={sendReq.handClean.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="strength-select">
                                                    <div className="checkbox m-0">
                                                        <input type="checkbox" id="players" name="powerClean" onChange={(e) => this.chkChange(e)} value={sendReq.powerClean.flag} checked={sendReq.powerClean.flag ? true : false} />
                                                        <span className="square"></span>
                                                        <label for="players" className="ml-15">Power Clean</label>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                                <input className="form-control" name="powerClean" value={sendReq.powerClean.value} onKeyPress={this.onlyNumber} disabled={sendReq.powerClean.flag} onChange={(e) => this.onHandleChange(e)} placeholder="Enter Value..." />
                                                                :
                                                                <>
                                                                    <input type="text" className="form-control" value="1234" disabled />
                                                                    <div className="loc-section" onClick={() => this.purchasePW()}>
                                                                        <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mb-15">
                                        <button type="button" className="btn" onClick={() => this.calculate()}>Calculate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {
                    purchase &&
                    <PurchaseProspectwire
                        flag={purchase}
                        close={this.handlePurchaseClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

Strength.propTypes = {
    sendCalculate: PropTypes.func.isRequired,
    getCalculate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    personalInfo: state.personalSummaryInfo
});

export default connect(mapStateToProps, { sendCalculate, getCalculate })(Strength);
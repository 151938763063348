import React, { Component } from "react";
import Header from "../containers/header";
import Footer from "../containers/footer";
import { imagesArr } from "../assets/images";
import { Link } from "react-router-dom";
import CommonHeroBanner from "../components/commonHeroBanner";

export class AboutUs extends Component {
  componentDidMount = () => {
    localStorage.removeItem("company");
    localStorage.removeItem("cid");
    localStorage.removeItem("companyColor");
    let defaultColor = [
      {
        variable: "--green-color",
        colorCode: "#7CBD42"
      },
      {
        variable: "--green-color-light",
        colorCode: "#96CF04"
      },
      {
        variable: "--green-color-shadow",
        colorCode: "#7CBD42"
      },
      {
        variable: "--green-color-extra-light",
        colorCode: "#eaf6cc"
      },
    ]
    this.setThemeColor(defaultColor);
  }

  setThemeColor = (colArr) => {
    localStorage.setItem("companyColor", JSON.stringify(colArr));
    for (let i = 0; i < colArr.length; i++) {
      const element = colArr[i];
      document.body.style.setProperty(element.variable, element.colorCode);
    }
  }

  render() {
    return (
      <>
        <Header />
        <CommonHeroBanner dataList="" />
        <section className="aboutus">
          <div className="container">
            <div className="heading-title">
              <h2>What is BaseballCloud?</h2>
            </div>
            <p>
              BaseballCloud is a community focused on enhancing amateur baseball through the use of data. We started in 2017 in a response to the growing divide between the quantity of data available and the quality of tools used to analyze it. Our company has always been focused on listening to the market and making sure what we offer truly provides a valuable experience.
            </p>
            <p>
              Our first product offering focused on providing college baseball coaches and players with an easy way to make use of ball flight data. As the amount of data available grew, BaseballCloud changed with it. We shifted to becoming a central repository for anyone in the baseball or softball community to conveniently house their data. Through our current and continually growing product lineup, we aim to impact all parts of the baseball and softball experience.
            </p>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default AboutUs;

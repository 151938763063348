import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from '../assets/images';
import { getProfile, getPlayerProfile, getFanProfile, getCommiProfile, logOut } from '../saga/actions/containers/header.action';
import { getNotification, getNotificationCount, acceptNotify, declineNotify, acceptNotifyByHeadCoach, readNotify, deleteNotify, getCompanyInfo, acceptNotifyLinkedPlayer, acceptNotifyFan } from '../saga/actions/common/api.action';
import commonCalls from "../businesslogic/commonCalls";
import ChangePassword from "../views/popup/changePassword";
import { leaveTeam } from "../saga/actions/views/player/dashboard.action";
import LeaveTeam from '../views/popup/leaveTeam';
import moment from "moment";
import ReassignTeam from "../views/popup/reassignTeam";
import { postReassignInvite } from '../saga/actions/views/coach/profile/teamInfo.action';
import Preloader from '../components/preloader';
import Cookies from 'js-cookie';
import { commonHeader, companyHeader } from '../businesslogic/content';
import BcHeader from "./components/bcHeader";
import CompanyHeader from "./components/companyHeader";
class header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isScroll: false,
            userDropdown: false,
            toggleMenu: false,
            toggleCompany: false,
            profileInfo: {
                uimg: "",
                uname: ""
            },
            teamArr: "",
            subMenu: '',
            petaMenu: 'Profile',
            show: false,
            openChangePassword: false,
            userType: "",
            leaveTeamPopup: false,
            invite: "",
            notification: {
                page: 1,
                limit: 10
            },
            notifyArr: [],
            notifyCount: 0,
            reassignTeam: false,
            assignType: 0,
            nid: "",
            loader: false,
            scroll: true,
            webglClass: "",
            subCoach: true,
            subCoachInvite: false,
            saveRoute: "",
            showGameType: "1",
            gameType: false,
            subMenuCom:''
        }
        // this.handleScroll = this.handleScroll.bind(this)
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        // window.addEventListener('scroll', this.handleScroll, true);

        let url = new URL(document.location);
        let getPath = url.pathname.split("/")[1];

        console.log("getPath header:---", url);

        if (getPath === "pwb" || getPath === "pws" || getPath === "gob" || getPath === "dc" || getPath === 'pioneer') {
            let item = commonHeader.events.filter((e) => e.key === getPath);
            this.setHeaderData(item[0]);
        }


        // expert with chat button remove
        window.embedded_svc.settings.displayHelpButton = false;
        // if (url.hostname === "devbc.diamondconnect.com") {
        //     if (getPath === 'ballrapp' || getPath === 'bcteamapp' || getPath === 'pitchrapp' || getPath === 'analyzrapp') {
        //         window.embedded_svc.settings.displayHelpButton = false;
        //         // window.zE('webWidget', 'hide');
        //     } else {
        //         window.embedded_svc.settings.displayHelpButton = true;
        //         // window.zE('webWidget', 'show');
        //     }
        // } else {
        //     window.embedded_svc.settings.displayHelpButton = false;
        // }

        if (getPath === "bcteamapp" || getPath === "analyzrapp") {
            document.body.classList.add("scroll-hide");
        } else {
            document.body.classList.remove("scroll-hide");
        }

        if (getPath === "bcteamapp") {
            this.setState({
                gameType: true
            })
        } else {
            this.setState({
                gameType: false
            })
        }

        if (getPath !== 'coach-signup4' && getPath !== 'player-signup4' && getPath !== 'fan-signup4' && getPath !== 'signup' && getPath !== 'signup2' &&
            getPath !== 'signup3' && getPath !== 'login' && getPath !== 'verify' && getPath !== 'fverify') {
            if (localStorage.getItem("coachToken") !== null) {
                this.props.getProfile();
                this.setState({
                    userType: "Coach",
                    show: true
                })
                if (this.props.commonData.notifyData) {
                    this.props.commonData.notifyData = null;
                }
                this.props.getNotification(this.state.notification, "Coach");
            }
            if (localStorage.getItem("playerToken") !== null) {
                this.props.getPlayerProfile();
                this.setState({
                    userType: "Player",
                    show: true
                })
                if (this.props.commonData.notifyData) {
                    this.props.commonData.notifyData = null;
                }
                this.props.getNotification(this.state.notification, "Player");
            }
            if (localStorage.getItem("fanToken") !== null) {
                this.props.getFanProfile();
                this.setState({
                    userType: "Fan",
                    show: true
                })
                if (this.props.commonData.notifyData) {
                    this.props.commonData.notifyData = null;
                }
                this.props.getNotification(this.state.notification, "Fan");
            }
            if (localStorage.getItem("commiToken") !== null) {
                this.props.getCommiProfile();
                this.setState({
                    userType: "BC Commissioner",
                    show: true
                })
            }
        }

        if (this.props.type) {
            this.setState({
                webglClass: true
            })
        } else {
            this.setState({
                webglClass: false
            })
        }

        if (localStorage.getItem("companyColor") !== null && getPath !== "about-us" && getPath !== "our-team" && getPath !== "press-room" && getPath !== "analyzr" && getPath !== "bcteam" && getPath !== "ballr" && getPath !== "pitchr" && getPath !== "dc") {
            let colorsArr = JSON.parse(localStorage.getItem("companyColor"));
            this.setThemeColor(colorsArr);
        }

        if (!localStorage.getItem("webglToken")) {
            localStorage.setItem("subscribe", "Free");
            localStorage.setItem("dc_stats", "Free");
        }

        this.renderCompanyHeader();
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.profileDetails &&
            nextProps.profileDetails.headData &&
            nextProps.profileDetails.headReq
        ) {
            nextProps.profileDetails.headReq = false;
            if (nextProps.profileDetails.headData.result.profile) {
                localStorage.setItem("userID", nextProps.profileDetails.headData.result._id);
                let teamCred = nextProps.profileDetails.headData.result.profile.hasOwnProperty('parentTeam') ? nextProps.profileDetails.headData.result.profile.parentTeam : "";
                if (teamCred) {
                    localStorage.setItem("gameType", teamCred.coach && teamCred.coach.profile["game-type"]);
                }
                if (nextProps.profileDetails.headData.result.profile.hasOwnProperty('subscribe')) {
                    localStorage.setItem("subscribe", nextProps.profileDetails.headData.result.profile.subscribe);
                } else {
                    localStorage.setItem("subscribe", "Free");
                }

                if (nextProps.profileDetails.headData.result.profile.hasOwnProperty('DC_stats')) {
                    localStorage.setItem("dc_stats", nextProps.profileDetails.headData.result.profile.DC_stats);
                } else {
                    localStorage.setItem("dc_stats", "Free");
                }

                this.setState({
                    profileInfo: {
                        uimg: nextProps.profileDetails.headData.result.profileUrl,
                        uname: nextProps.profileDetails.headData.result.fname,
                        uid: nextProps.profileDetails.headData.result._id,
                    },
                    show: nextProps.profileDetails.headData.result.profile.isCompleted,
                    subCoach: nextProps.profileDetails.headData.result.profile.subCoach,
                    subCoachInvite: nextProps.profileDetails.headData.result.profile.invite,
                    showGameType: teamCred ? teamCred.coach && teamCred.coach.profile["game-type"] : "",
                }, () => {
                    nextProps.profileDetails.headData = null;
                })


            }
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.headPlayerData &&
            nextProps.profileDetails.headPlayerReq
        ) {
            nextProps.profileDetails.headPlayerReq = false;
            if (nextProps.profileDetails.headPlayerData.result.profile) {
                localStorage.setItem("userID", nextProps.profileDetails.headPlayerData.result._id);
                let playerCred = nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('parentTeam') ? nextProps.profileDetails.headPlayerData.result.profile.parentTeam : "";
                if (playerCred) {
                    localStorage.setItem("gameType", nextProps.profileDetails.headPlayerData.result.profile["game-type"]);
                }
                if (nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('subscribe')) {
                    localStorage.setItem("subscribe", nextProps.profileDetails.headPlayerData.result.profile.subscribe);
                } else {
                    localStorage.setItem("subscribe", "Free");
                }
                if (nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('DC_stats')) {
                    localStorage.setItem("dc_stats", nextProps.profileDetails.headPlayerData.result.profile.DC_stats);
                } else {
                    localStorage.setItem("dc_stats", "Free");
                }
                this.setState({
                    profileInfo: {
                        uimg: nextProps.profileDetails.headPlayerData.result.profileUrl,
                        uname: nextProps.profileDetails.headPlayerData.result.fname,
                        uid: nextProps.profileDetails.headPlayerData.result._id,
                    },
                    teamArr: nextProps.profileDetails.headPlayerData.result.profile.parentTeam,
                    invite: nextProps.profileDetails.headPlayerData.result.profile.invite,
                    showGameType: nextProps.profileDetails.headPlayerData.result.profile["game-type"],
                }, () => {
                    nextProps.profileDetails.headPlayerData = null;
                })
            }
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.headFanData &&
            nextProps.profileDetails.headFanReq
        ) {
            nextProps.profileDetails.headFanReq = false;
            if (nextProps.profileDetails.headFanData.result.profile) {
                localStorage.setItem("userID", nextProps.profileDetails.headFanData.result._id);
                if (nextProps.profileDetails.headFanData.result.profile.hasOwnProperty('subscribe')) {
                    localStorage.setItem("subscribe", nextProps.profileDetails.headFanData.result.profile.subscribe);
                } else {
                    localStorage.setItem("subscribe", "Free");
                }

                if (nextProps.profileDetails.headFanData.result.profile.hasOwnProperty('DC_stats')) {
                    localStorage.setItem("dc_stats", nextProps.profileDetails.headFanData.result.profile.DC_stats);
                } else {
                    localStorage.setItem("dc_stats", "Free");
                }

                this.setState({
                    profileInfo: {
                        uimg: nextProps.profileDetails.headFanData.result.profileUrl,
                        uname: nextProps.profileDetails.headFanData.result.fname,
                    },
                    userType: nextProps.profileDetails.headFanData.result.profile.role,
                }, () => {
                    nextProps.profileDetails.headFanData = null;
                })
            }
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.headCommiData &&
            nextProps.profileDetails.headCommiReq
        ) {
            nextProps.profileDetails.headCommiReq = false;
            if (nextProps.profileDetails.headCommiData.result) {
                localStorage.setItem("userID", nextProps.profileDetails.headCommiData.result._id);
                let teamCred = nextProps.profileDetails.headCommiData.result.profile.hasOwnProperty('parentTeam') ? nextProps.profileDetails.headCommiData.result.profile.parentTeam : "";
                if (teamCred) {
                    localStorage.setItem("gameType", teamCred.coach && teamCred.coach.profile["game-type"]);
                }
                this.setState({
                    profileInfo: {
                        uimg: nextProps.profileDetails.headCommiData.result.profileUrl,
                        uname: nextProps.profileDetails.headCommiData.result.fname,
                    },
                }, () => {
                    nextProps.profileDetails.headCommiData = null;
                })
            }
        }

        if (nextProps.dashboardDetails &&
            nextProps.dashboardDetails.leaveTeamReq &&
            nextProps.dashboardDetails.leaveTeamData
        ) {
            nextProps.dashboardDetails.leaveTeamReq = false;
            this.commonCall.successMessage(nextProps.dashboardDetails.leaveTeamData.message);
            nextProps.dashboardDetails.leaveTeamData = null;
            this.props.getPlayerProfile();
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyReq &&
            nextProps.commonData.notifyData
        ) {
            this.setLoader(false);
            if (nextProps.commonData.notifyData.flag) {
                if (!this.state.scroll) {
                    return this.setState({
                        notifyArr: [],
                        scroll: true,
                    }, () => {
                        this.setState({
                            notifyArr: nextProps.commonData.notifyData.result.docs,
                        }, () => {
                            nextProps.commonData.notifyReq = false;
                            nextProps.commonData.notifyData = null;
                            this.props.getNotificationCount(localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : "Fan");
                        })
                    })
                } else {
                    let listData = nextProps.commonData.notifyData.result.docs;
                    let temp = [];

                    listData.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.notifyArr.concat(temp);
                    nextProps.commonData.notifyReq = false;
                    nextProps.commonData.notifyData = null;
                    if (listData.length != 0) {
                        return this.setState({
                            scroll: true,
                            notifyArr: joined,
                        }, () => {
                            this.props.getNotificationCount(localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : "Fan");
                        })
                    }
                }

            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyData.message);
                nextProps.commonData.notifyReq = false;
                nextProps.commonData.notifyData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyCountReq &&
            nextProps.commonData.notifyCountData
        ) {
            nextProps.commonData.notifyCountReq = false;
            this.setLoader(false);
            if (nextProps.commonData.notifyCountData.flag) {
                this.setState({ 
                    notifyCount: nextProps.commonData.notifyCountData.result.data
                }, () => {
                    nextProps.commonData.notifyReq = false;
                    nextProps.commonData.notifyData = null;
                    nextProps.commonData.notifyCountData = null;
                })
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyCountData.message);
                nextProps.commonData.notifyCountData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyAcceptReq &&
            nextProps.commonData.notifyAcceptData
        ) {
            nextProps.commonData.notifyAcceptReq = false;
            if (nextProps.commonData.notifyAcceptData.flag) {
                this.commonCall.successMessage(nextProps.commonData.notifyAcceptData.message);
                nextProps.commonData.notifyAcceptData = null;
                this.props.getNotification(this.state.notification, localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : "Fan");
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyAcceptData.message);
                nextProps.commonData.notifyAcceptData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyDeclineReq &&
            nextProps.commonData.notifyDeclineData
        ) {
            nextProps.commonData.notifyDeclineReq = false;
            if (nextProps.commonData.notifyDeclineData.flag) {
                this.commonCall.successMessage(nextProps.commonData.notifyDeclineData.message);
                nextProps.commonData.notifyDeclineData = null;
                this.props.getNotification(this.state.notification, "Coach");
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyDeclineData.message);
                nextProps.commonData.notifyDeclineData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyAcceptByCoachReq &&
            nextProps.commonData.notifyAcceptByCoachData
        ) {
            nextProps.commonData.notifyAcceptByCoachReq = false;
            if (nextProps.commonData.notifyAcceptByCoachData.flag) {
                this.commonCall.successMessage(nextProps.commonData.notifyAcceptByCoachData.message);
                nextProps.commonData.notifyAcceptByCoachData = null;
                this.props.getNotification(this.state.notification, "Coach");
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyAcceptByCoachData.message);
                nextProps.commonData.notifyAcceptByCoachData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyDeclineReq &&
            nextProps.commonData.notifyDeclineData
        ) {
            nextProps.commonData.notifyDeclineReq = false;
            if (nextProps.commonData.notifyDeclineData.flag) {
                this.commonCall.successMessage(nextProps.commonData.notifyDeclineData.message);
                nextProps.commonData.notifyDeclineData = null;
                this.props.getNotification(this.state.notification, "Coach");
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyDeclineData.message);
                nextProps.commonData.notifyDeclineData = null;
            }
        }


        if (nextProps.commonData &&
            nextProps.commonData.notifyAcceptLinkedPlayerReq &&
            nextProps.commonData.notifyAcceptLinkedPlayerData
        ) {
            nextProps.commonData.notifyAcceptLinkedPlayerReq = false;
            if (nextProps.commonData.notifyAcceptLinkedPlayerData.flag) {
                this.commonCall.successMessage(nextProps.commonData.notifyAcceptLinkedPlayerData.message);
                nextProps.commonData.notifyAcceptLinkedPlayerData = null;
                this.props.getNotification(this.state.notification, "Player");
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyAcceptLinkedPlayerData.message);
                nextProps.commonData.notifyAcceptLinkedPlayerData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.notifyAcceptFanReq &&
            nextProps.commonData.notifyAcceptFanData
        ) {
            nextProps.commonData.notifyAcceptFanReq = false;
            if (nextProps.commonData.notifyAcceptFanData.flag) {
                this.commonCall.successMessage(nextProps.commonData.notifyAcceptFanData.message);
                nextProps.commonData.notifyAcceptFanData = null;
                this.props.getNotification(this.state.notification, localStorage.getItem("coachToken") !== null ? "Coach" : "Fan");
            } else {
                this.commonCall.errorMessage(nextProps.commonData.notifyAcceptFanData.message);
                nextProps.commonData.notifyAcceptFanData = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.deleteNotifyReq &&
            nextProps.commonData.deleteNotifyData
        ) {
            nextProps.commonData.deleteNotifyReq = false;
            if (nextProps.commonData.deleteNotifyData.flag) {
                this.commonCall.successMessage(nextProps.commonData.deleteNotifyData.message);
                nextProps.commonData.deleteNotifyData = null;
                this.setState((prevState) => ({
                    ...prevState,
                    notification: {
                        page: 1,
                        limit: 10
                    },
                }), () => {
                    this.props.getNotification(this.state.notification, localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : "Fan");
                })
            } else {
                this.commonCall.errorMessage(nextProps.commonData.deleteNotifyData.message);
                nextProps.commonData.deleteNotifyData = null;
            }
        }


        if (this.state.nid !== "" &&
            nextProps.reassignResponse &&
            nextProps.reassignResponse.postReassignData
        ) {
            if (nextProps.reassignResponse.postReassignData.flag) {
                this.commonCall.successMessage(nextProps.reassignResponse.postReassignData.message);
                nextProps.reassignResponse.postReassignData = null;
                this.props.readNotify({ notificationId: this.state.nid }, "Coach");
                this.props.getNotification(this.state.notification, "Coach");
            } else {
                this.commonCall.errorMessage(nextProps.reassignResponse.postReassignData.message);
                nextProps.reassignResponse.postReassignData = null;
            }
        }   

        if (nextProps.commonData &&
            nextProps.commonData.readNotifyReq &&
            nextProps.commonData.readNotifyData
        ) {
            nextProps.commonData.readNotifyReq = false;
            nextProps.commonData.readNotifyData = null;
        }

        if (nextProps.commonData &&
            nextProps.commonData.getCompanyInfoReq &&
            nextProps.commonData.getCompanyInfoData
        ) {
            nextProps.commonData.getCompanyInfoReq = false;
            if (nextProps.commonData.getCompanyInfoData.flag) {
                this.props.mainBoardInfo.sendReq.companyId = nextProps.commonData.getCompanyInfoData.result._id;
                this.props.mainBoardInfo.sendFieldReq.companyId = nextProps.commonData.getCompanyInfoData.result._id;
                this.props.profileSummaryInfo.sendSummaryReq.companyId = nextProps.commonData.getCompanyInfoData.result._id;

                localStorage.setItem("cid", nextProps.commonData.getCompanyInfoData.result._id);
                this.props.history.push(this.state.saveRoute);
                nextProps.commonData.getCompanyInfoData = null;
                this.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.commonData.getCompanyInfoData.message);
                nextProps.commonData.getCompanyInfoData = null;
                this.setLoader(false);
            }
        }
    }


    componentDidUpdate(){
        console.log(window.innerWidth);
    }

    
    updateSize = () => {
        let height = document.getElementById('bc-header') && document.getElementById('bc-header').offsetHeight;
        console.log("window:--", height);
    }

    // handleScroll = () => {
    //     if (window.scrollY === 0) this.setState({ isScroll: false })
    //     else this.setState({ isScroll: true })
    // }

    // toggle menu bar
    toggleMenuBar = (type) => {
        if (type === "bc") {
            this.setState({
                subMenu: ''
            })
            document.body.classList.toggle("show-menu")
        } else {
            document.body.classList.toggle("show-menu2")
        }
    }


    closeSideBar = (type) => {
        if (type === "bc") {
            document.body.classList.remove("show-menu")
        } else {
            document.body.classList.remove("show-menu2")
        }
    }

    logout = () => {

        Cookies.remove('bcSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        Cookies.remove('gameType', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        Cookies.remove('analyzrSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN });

        (this.state.userType === "Coach") ? this.commonCall.removeStorage("coachToken") : (this.state.userType === "Player") ? this.commonCall.removeStorage("playerToken") : (this.state.userType === "BC Commissioner") ? this.commonCall.removeStorage("commiToken") : this.commonCall.removeStorage("fanToken");
        this.commonCall.removeStorage("authToken");
        this.commonCall.removeStorage("userStep1");
        this.commonCall.removeStorage("socialMedia");
        this.commonCall.removeStorage("coachTab");
        this.commonCall.removeStorage("payPlans");
        this.commonCall.removeStorage("webglToken");
        this.commonCall.removeStorage("loginToken")
        this.commonCall.removeStorage("webglUrl");
        this.commonCall.removeStorage("rostername");
        this.commonCall.removeStorage("eventDirectPayment");
        this.commonCall.removeStorage("eventDetail");
        this.commonCall.removeStorage("gameType");
        this.commonCall.removeStorage("seatIndex");
        this.commonCall.removeStorage("rosterId");
        this.commonCall.removeStorage("lpid");
        this.commonCall.removeStorage("rosterToken");
        this.commonCall.removeStorage("popupUrl");
        this.commonCall.removeStorage("rolPay");
        this.commonCall.removeStorage("analProduct");
        this.commonCall.removeStorage("ballrProduct");
        this.commonCall.removeStorage("bcteamProduct");
        this.commonCall.removeStorage("pitchrProduct");
        this.commonCall.removeStorage("analyzr");
        this.commonCall.removeStorage("playerId");
        this.commonCall.removeStorage("tempRole");
        this.commonCall.removeStorage("subscribe");
        this.commonCall.removeStorage("dc_stats");
        this.commonCall.removeStorage("dcStats");
        this.commonCall.removeStorage("ageGroupData");
        this.commonCall.removeStorage("userID");
        this.commonCall.removeStorage("zeroAmount");
        this.commonCall.removeStorage("manageRid");
        this.commonCall.removeStorage("eventReport");
        this.commonCall.successMessage("Logout Successfully");
        // this.props.logOut(this.state.userType, {
        //     fcmToken: localStorage.getItem("notificationToken")
        // });
        this.props.logOut(this.state.userType, {
            fcmToken: localStorage.getItem("notificationToken")
        });
        this.props.history.push('/login');
    }

    // handle sub menu
    handleMenu = (mname) => {
        this.setState({ subMenu: (mname === this.state.subMenu ? '' : mname) })
    }

    handlePetaMenu = (mname) => {
        this.setState({ petaMenu: (mname === this.state.petaMenu ? '' : mname) })
    }

    nextPage = (name) => {
        this.commonCall.setStorage("coachTab", name, flag => {
            if (flag) {
                let url = new URL(document.location);
                let getPath = url.pathname.split("/")[1];
                if (localStorage.getItem("coachToken")) {
                    this.props.history.push("/coach-setting");
                } else if (localStorage.getItem("playerToken")) {
                    this.props.history.push("/player-setting");
                } else {
                    this.props.history.push("/fan-setting");
                }
            }
        });
    }

    closeChangePass = () => {
        document.body.classList.remove("modal-open");
        this.setState((prevState) => ({
            ...prevState,
            openChangePassword: false
        }));
    };

    backToHome = () => {
        this.closeSideBar("bc");
        if (localStorage.getItem("coachToken") !== null) {
            this.props.history.push("/coach-dashboard");
        }
        if (localStorage.getItem("playerToken") !== null) {
            this.props.history.push("/player-dashboard");
        }
        if (localStorage.getItem("fanToken") !== null) {
            this.props.history.push("/fan-dashboard");
        }
        if (localStorage.getItem("commiToken") !== null) {
            this.props.history.push("/commi-dashboard");
        }
    }

    leaveTeamConfirm = () => {
        if (this.state.teamArr) {
            this.setState({
                leaveTeamPopup: !this.state.leaveTeamPopup
            })
        }

    }

    leaveTeam = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            leaveTeamPopup: !this.state.leaveTeamPopup
        }, () => {
            this.props.leaveTeam();
        })
    }

    closeTeam = (type) => {
        document.body.classList.remove('modal-open');
        if (type === "leaveTeam") {
            this.setState({
                leaveTeamPopup: !this.state.leaveTeamPopup
            })
        }
    }

    getAccept = (nid, nuid, notifyType) => {
        this.setState({
            notification: {
                page: 1,
                limit: 10
            },
            // notifyArr:[],
            scroll: false
        })
        if (notifyType === 2) {
            this.props.acceptNotify({ notification: nid }, "Coach");
        } else if (notifyType === 9) {
            this.props.acceptNotifyByHeadCoach({ notification: nid }, "Coach");
        } else if (notifyType === 10) {
            this.props.acceptNotifyLinkedPlayer({ notificationId: nid }, localStorage.getItem("coachToken") !== null ? "Coach" : "Player");
        } else if (notifyType === 11) {
            this.props.acceptNotifyFan({ notificationId: nid }, localStorage.getItem("coachToken") !== null ? "Coach" : "Fan");
        }
        // this.props.readNotify({ notificationId: nid }, "Coach");
    }

    getDecline = (nid, nuid, notifyType) => {
        this.setState({
            notification: {
                page: 1,
                limit: 10
            },
            // notifyArr:[],
            scroll: false
        })
        if (notifyType === 2) {
            this.props.declineNotify({ notificationId: nid }, "Coach");
        }
    }

    getReassign = (nid) => {
        this.setState({
            reassignTeam: !this.state.reassignTeam,
            assignType: 1,
            nid: nid,
            notification: {
                page: 1,
                limit: 10
            },
            // notifyArr:[],
            scroll: false
        })
    }

    getHold = (nid) => {
        this.setState({
            nid: nid,
            notification: {
                page: 1,
                limit: 10
            },
            // notifyArr:[],
            scroll: false
        }, () => {
            this.props.postReassignInvite({
                cCode: "",
                email: "",
                phone: "",
                type: 2
            })
        })
    }

    getDelete = (id) => {
        this.setState({
            notification: {
                page: 1,
                limit: 10
            },
            // notifyArr:[],
            scroll: false
        },()=>{
            if (this.props.commonData.notifyData) {
                this.props.commonData.notifyData = null;
            }
            this.props.deleteNotify({ "notificationId": id }, localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : "Fan")
        })
    }

    getDecline = (nid, nuid, notifyType) => {
        this.setState({
            notification: {
                page: 1,
                limit: 10
            },
            // notifyArr:[],
            scroll: false
        })
        if (notifyType === 2) {
            this.props.declineNotify({ notificationId: nid }, "Coach");
        }
    }

    readNotification = (nid) => {
        this.setState({
            notification: {
                page: 1,
                limit: 10
            },
            scroll: false
        }, () => {
            this.props.readNotify({ notificationId: nid }, localStorage.getItem("coachToken") !== null ? "Coach" : "Player");
            this.props.getNotification(this.state.notification, localStorage.getItem("coachToken") !== null ? "Coach" : "Player")
        })
    }

    renderNotification = () => {
        return (
            this.state.notifyArr.length !== 0 ?
                this.state.notifyArr.map((list, i) => {
                    return (
                        <li key={i} onClick={() => ((list.type === 23 || list.type === 15) && list.isRead === 0) ? this.readNotification(list._id) : ""}>
                            <div className="notification_images">
                                <img src={list.type === 23 ? list?.awardMaster && list.awardMaster.awardIcon.filePath : list.nuId && list.nuId.profileUrl ? list.nuId.profileUrl : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="notification_user" />
                            </div>
                            <div className="notification_dec">
                                <div className="notification_title">
                                    <p>
                                        {list.type === 21 ?
                                            <>
                                                <span>AnalyzR Support Team</span>
                                                {" " + list.notiStr}
                                            </> :
                                            list.type !== 10 ?
                                                list.type === 23 ?
                                                    <>
                                                        Congratulations! You’ve been awarded {<><span>{list?.awardMaster && list.awardMaster.name}</span> </>} from {<span>{list?.evtId?.companies ? list?.evtId?.companies.name : ""}</span>} for you performance in {<span>{list?.evtId?.eventTitle ? list?.evtId?.eventTitle : ""}</span>}.
                                                    </>
                                                    :
                                                    <>
                                                        <span>{list.nuId && (list.type === 9 ? "Coach " : '') + list.nuId.fname + " " + list.nuId.lname}</span>
                                                        {list.notiStr}
                                                        <span>{list.pTid && list.pTid.name}.</span>
                                                    </>
                                                :
                                                <>
                                                    Congrats! Your parent {<span>{list.nuId && list.nuId.fname + " " + list.nuId.lname}</span>} has invited you or linked you to DiamondConnect.
                                                </>
                                        }
                                    </p>
                                </div>
                                <div className="notification-btn">
                                    {
                                        (list.type === 2 || list.type === 9 || list.type === 10 || list.type === 11) &&
                                        <div className="notification-inner-btn">
                                            <Link className="btn" onClick={() => list.isRead === 0 ? this.getAccept(list._id, list.nuId._id, list.type) : ""} disabled={list.isRead === 0 ? false : true}>Accept</Link>
                                            {
                                                list.type === 2 &&
                                                <Link className="red_btn" onClick={() => list.isRead === 0 ? this.getDecline(list._id, list.nuId._id, list.type) : ""} disabled={list.isRead === 0 ? false : true}>Decline</Link>
                                            }
                                        </div>
                                    }
                                    {
                                        list.type === 7 &&
                                        <div className="notification-inner-btn">
                                            <Link className="btn" onClick={() => list.isRead === 0 ? this.getReassign(list._id) : ""} disabled={list.isRead === 0 ? false : true}>Reassign</Link>
                                            <Link className="red_btn" onClick={() => list.isRead === 0 ? this.getHold(list._id) : ""} disabled={list.isRead === 0 ? false : true}>Hold</Link>
                                        </div>
                                    }
                                    <span>
                                        {moment(list.createdAt).format("MM/DD/YYYY")}
                                    </span>
                                </div>
                                {
                                // (list.isRead === 1 || list.type === 9) &&
                                    <div className="close_notification" onClick={() => this.getDelete(list._id)}>
                                        <img src={imagesArr.deletes} alt="deletes" />
                                    </div>}
                            </div>
                        </li>
                    );
                })
                :
                <h4>No New Notifications</h4>
        )
    }

    onHandleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            reassignTeam: !this.state.reassignTeam
        })
    };

    supportZendesk = () => {
        window.open("https://baseballcloud.force.com/knowledgebase/s/", '_blank');
    }

    gotoContact = () => {
        let url = new URL(document.location);
        localStorage.removeItem("company");
        localStorage.removeItem("cid");
        localStorage.removeItem("companyColor");

        if (url.pathname !== "/press-room" && url.pathname !== "/about-us" && url.pathname !== "/our-team" && url.pathname !== "/") {
            localStorage.setItem("scrollFooter", true);
            this.props.history.push("/");
        } else {
            const footer = document.getElementById("footer");
            if (footer) {
                footer.scrollIntoView({ behavior: 'smooth' });
                document.body.classList.remove("show-menu");
            }
        }
    }

    redirectBlog = () => {
        window.open("https://baseballcloud.blog", '_blank');
    }

    redirectBcTeamLogin = () => {
        window.open("https://app.baseballcloud.com/login", '_blank');
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getScroll = (event) => {
        if (this.state.scroll) {
            let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
            if (bottom) {
                this.setLoader(true);
                this.setState({
                    notification: {
                        page: this.state.notification.page + 1,
                        limit: 10
                    },
                }, () => {
                    this.props.getNotification(this.state.notification, localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : "Fan");
                })
            }
        }
    }

    renderAboutMenu = (type) => {
        if (type === "desktop") {
            return (
                <ul>
                    {
                        commonHeader.about.map((item, i) => {
                            return (
                                <li onClick={() => item.key === "blog" ? this.redirectBlog() : item.key === "contact" ? this.gotoContact() : ""}>
                                    {
                                        (item.key === "blog" || item.key === "contact") &&
                                        <Link>{item.label}</Link>
                                    }
                                    {
                                        item.key !== "blog" && item.key !== "contact" &&
                                        <Link to={item.route}>{item.label}</Link>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            )
        } else {
            return (
                <ul className={`sub-menu ${this.state.subMenu === 'About' ? 'active-submenu' : ''}`}>
                    {
                        commonHeader.about.map((item, i) => {
                            return (
                                <li onClick={() => item.key === "blog" ? this.redirectBlog() : item.key === "contact" ? this.gotoContact() : (item.key !== "blog" && item.key !== "contact") ? this.closeSideBar("bc") : ""}>
                                    {
                                        (item.key === "blog" || item.key === "contact") &&
                                        <Link>{item.label}</Link>
                                    }
                                    {
                                        item.key !== "blog" && item.key !== "contact" &&
                                        <Link to={item.route}>{item.label}</Link>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
    }

    redirectProduct = (path, type) => {
        if (type !== "desktop") {
            this.closeSideBar("bc")
        }

        let defaultColor = [
            {
                variable: "--green-color",
                colorCode: "#7CBD42"
            },
            {
                variable: "--green-color-light",
                colorCode: "#96CF04"
            }
        ]
        for (let i = 0; i < defaultColor.length; i++) {
            const element = defaultColor[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
        this.props.history.push(path);
    }

    renderProductMenu = (type) => {
        if (type === "desktop") {
            return (
                <ul>
                    {
                        commonHeader.products.map((item, i) => {
                            return (
                                <li><Link onClick={() => this.redirectProduct(item.route, type)}><img src={item.icon} alt="menu" /></Link></li>
                            )
                        })
                    }
                </ul>
            )
        } else {
            return (
                <ul className={`sub-menu ${this.state.subMenu === 'Products' ? 'active-submenu' : ''}`}>
                    {
                        commonHeader.products.map((item, i) => {
                            return (
                                <li onClick={() => this.redirectProduct(item.route, type)}><Link to={item.route}><img src={item.icon} alt="menu" /></Link></li>
                            )
                        })
                    }
                </ul>
            )
        }
    }

    renderEventMenu = (type) => {

        let url = new URL(document.location);
        let getPath = url.pathname.split("/")[1];

        if (type === "desktop") {
            return (
                <ul>
                    {
                        commonHeader.events.map((item, i) => {
                            return (
                                <li onClick={() => (getPath !== "verify" && getPath !== "signup3" && getPath !== "coach-signup4" && getPath !== "player-signup4" && item.key !== "uts") ? this.setHeaderData(item) : ""}>
                                    <div className="menu_event_list">
                                        <div className="event_img">
                                            <img src={item.icon} alt="prospectwire" />
                                        </div>
                                        <h5>{item.key === "pws" ? "Prospect Wire Softball" : item.label}</h5>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        } else {
            return (
                <ul className={`sub-menu ${this.state.subMenu === 'Event' ? 'active-submenu' : ''}`}>
                    {
                        commonHeader.events.map((item, i) => {
                            return (
                                <li onClick={() => (getPath !== "verify" && getPath !== "signup3" && getPath !== "coach-signup4" && getPath !== "player-signup4" && item.key !== "uts") ? this.setHeaderData(item) : ""}>
                                    <Link>
                                        <div className="menu_event_list">
                                            <div className="event_img">
                                                <img src={item.icon} alt="prospectwire" />
                                            </div>
                                            <h5>{item.key === "pws" ? "Prospect Wire Softball" : item.label}</h5>
                                        </div>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
    }

    setHeaderData = (item) => {
        if (item.key === "rb" || item.key === "rs" || item.key === "utb") {
            window.open(item.route, '_blank');
        } else {
            localStorage.setItem("isCollegeShowcase", (window.location.pathname === '/dc-collegeShowcase') ? true : false);
            localStorage.setItem("isAllCompany", (window.location.pathname === '/dc-event') ? true : false);
            this.setThemeColor(item.colors);
            localStorage.setItem("company", item.key);
            this.setState({
                saveRoute: item.route
            }, () => {
                this.closeSideBar("bc");
                let set_label = item.key === "gob" ? "GOBTournament" : item.label;
                this.setLoader(true);
                this.props.getCompanyInfo(set_label);
            })
        }
    }

    setThemeColor = (colArr) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
    }

    renderCompanyHeader = () => {
        let url = new URL(document.location);
        let getPath = url.pathname.split("/")[1];


        let key = localStorage.getItem("company");
        let getData = this.setHead(key, companyHeader);
        if (key !== null && getPath !== "ballrapp" && getPath !== "bcteamapp" && getPath !== "analyzrapp" && getPath !== "pitchrapp" && getPath !== "about-us" && getPath !== "our-team" && getPath !== "press-room" && getPath !== "bcteam" && getPath !== "ballr" && getPath !== "analyzr" && getPath !== "pitchr" && getData) {
            return (
                <nav className="navbar navbar-expand-lg secondnav ">
                    <CompanyHeader subMenuCom={this.state.subMenuCom} handleComMenu={(menu)=>this.setState({subMenuCom : (menu === this.state.subMenuCom ? '' : menu)})} callIncrement={this.props.callIncrement} getData={getData} toggleMenuBar={this.toggleMenuBar} closeSideBar={this.closeSideBar} profileInfo={this.state.profileInfo} clearStates={this.props.clearStates} renderPlayerProfile={this.props.renderPlayerProfile} />
                </nav>
            )
        }
    }

    setHead = (key, data) => {
        var isHead = "";
        data && data.length > 0 &&
            data.forEach((data) => {
                if (data.key === key) return (isHead = data);
            });
        return isHead;
    }

    backToLanding = (props) => {
            this.setLoader(true)
            localStorage.removeItem("company");
            localStorage.removeItem("cid");
            localStorage.removeItem("companyColor");
            localStorage.removeItem("isAllCompany");
            localStorage.removeItem("isCollegeShowcase");
            let defaultColor = [
                {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                }
            ]
            this.setThemeColor(defaultColor);
            
            this.setState({
                showCompanyHead: false,
                saveRoute: "",
            }, () => {
                this.props.history.push("/");
                window.scrollTo(0, 0);
                // setTimeout(() => {
                    window.location.reload()
                // }, 500)
                this.setLoader(true)
            })
    }

    renderAllMenu = () => {
        return (
            <div className='menu_alert_sec'>
                <div className="menu_alert">
                    <Link className="btn menubar"><img src={imagesArr.menu} alt="menu" /></Link>
                    <div className={`menu_dropdown ${this.state.userType === "Fan" ?"fan_menu_dropdown" :""}`}>
                        <div className="menu_box">
                            {/* <div className="menu_about">
                                <div className="menu_title">
                                    <h4>About</h4>
                                </div>
                                {this.renderAboutMenu("desktop")}
                            </div> */}
                            {this.state.userType !== "Fan" &&
                                <>
                                    {/* <div className="menu_product">
                                        <div className="menu_title">
                                            <h4>Products</h4>
                                        </div>
                                        {this.renderProductMenu("desktop")}
                                        <div className="powered-by">
                                            <p>products powered by <img src={imagesArr.main_logo} alt="main_logo" /></p>
                                        </div>
                                    </div> */}
                                    <div className="menu_event">
                                        <div className="menu_title">
                                            <h4>Events</h4>
                                        </div>
                                        {this.renderEventMenu("desktop")}
                                    </div>
                                </>
                                }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    serDropDownFlg = () => {
        this.setState({ userDropdown: !this.state.userDropdown })
    }

    changePassFlg = () => {
        this.setState((prevState) => ({ ...prevState, openChangePassword: true }))
    }

    render() {
        const { profileInfo, show, subCoach, subMenu, petaMenu, userType, teamArr, leaveTeamPopup, showGameType, gameType, invite, notifyCount, reassignTeam, assignType, webglClass, subCoachInvite } = this.state

        return (
            <>
                {/* <header className={`${this.state.isScroll ? `fixed ${webglClass ? 'webglheader' : ''}` : `${webglClass ? 'webglheader' : ''}`}`} id="bc-header"> */}

                <header className={`${webglClass ? 'webglheader' : ''}`} id="bc-header">
                    <BcHeader
                        profileInfo={profileInfo}
                        show={show}
                        subCoach={subCoach}
                        subMenu={subMenu}
                        petaMenu={petaMenu}
                        userType={userType}
                        teamArr={teamArr}
                        invite={invite}
                        notifyCount={notifyCount}
                        subCoachInvite={subCoachInvite}
                        userDropdown={this.state.userDropdown}
                        backToLanding={this.backToLanding}
                        backToHome={this.backToHome}
                        handleMenu={this.handleMenu}
                        renderAboutMenu={this.renderAboutMenu}
                        renderProductMenu={this.renderProductMenu}
                        renderEventMenu={this.renderEventMenu}
                        getScroll={this.getScroll}
                        renderNotification={this.renderNotification}
                        renderAllMenu={this.renderAllMenu}
                        toggleMenuBar={this.toggleMenuBar}
                        serDropDownFlg={this.serDropDownFlg}
                        handlePetaMenu={this.handlePetaMenu}
                        changePassFlg={this.changePassFlg}
                        nextPage={this.nextPage}
                        supportZendesk={this.supportZendesk}
                        logout={this.logout}
                        leaveTeamConfirm={this.leaveTeamConfirm}
                        showGameType={showGameType}
                        gameType={gameType}
                    />

                    {this.props.location.pathname === "/" ?
                        localStorage.getItem("company") === "dc" ? this.renderCompanyHeader() : ""
                        : this.renderCompanyHeader()}
                </header >
                {
                    reassignTeam &&
                    <ReassignTeam flag={reassignTeam} close={this.onHandleClose} type={assignType} />
                }

                {
                    this.state.openChangePassword &&
                    <ChangePassword
                        flag={this.state.openChangePassword}
                        close={() => this.closeChangePass()}
                        type={userType}
                    />
                }
                <LeaveTeam
                    flag={leaveTeamPopup}
                    close={this.closeTeam}
                    confirm={this.leaveTeam}
                />
                <Preloader flag={this.state.loader} />
            </>
        );
    }
}

header.propTypes = {
    getProfile: PropTypes.func.isRequired,
    getPlayerProfile: PropTypes.func.isRequired,
    getFanProfile: PropTypes.func.isRequired,
    getCommiProfile: PropTypes.func.isRequired,
    leaveTeam: PropTypes.func.isRequired,
    getNotification: PropTypes.func.isRequired,
    getNotificationCount: PropTypes.func.isRequired,
    acceptNotify: PropTypes.func.isRequired,
    declineNotify: PropTypes.func.isRequired,
    acceptNotifyByHeadCoach: PropTypes.func.isRequired,
    acceptNotifyLinkedPlayer: PropTypes.func.isRequired,
    acceptNotifyFan: PropTypes.func.isRequired,
    postReassignInvite: PropTypes.func.isRequired,
    readNotify: PropTypes.func.isRequired,
    deleteNotify: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    getCompanyInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileDetails: state.headerData,
    dashboardDetails: state.dashboardInfo,
    commonData: state.api,
    reassignResponse: state.teamInfo,
    mainBoardInfo: state.mainBoardInfo,
    profileSummaryInfo: state.profileSummaryInfo,
});

const MyComponent = connect(mapStateToProps, {
    getProfile,
    getPlayerProfile,
    getFanProfile,
    getCommiProfile,
    leaveTeam,
    getNotification,
    getNotificationCount,
    acceptNotify,
    declineNotify,
    getCompanyInfo,
    acceptNotifyByHeadCoach,
    acceptNotifyLinkedPlayer,
    acceptNotifyFan,
    postReassignInvite,
    readNotify,
    deleteNotify,
    logOut
})(header);
export default withRouter(MyComponent);

import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken } from '../../../../helpers/authToken';
import {
    COM_RECAP_INFO_REQUEST,
    COM_RECAP_INFO_SUCCESS,
    COM_RECAP_INFO_ERROR,

    COM_RECAP_DIVISION_REQUEST,
    COM_RECAP_DIVISION_SUCCESS,
    COM_RECAP_DIVISION_ERROR,

    COM_RECAP_INFORMATION_REQUEST,
    COM_RECAP_INFORMATION_SUCCESS,
    COM_RECAP_INFORMATION_ERROR,

    COM_RECAP_DESCRIPTION_REQUEST,
    COM_RECAP_DESCRIPTION_SUCCESS,
    COM_RECAP_DESCRIPTION_ERROR,

    COM_RECAP_AGE_GROUP_REQUEST,
    COM_RECAP_AGE_GROUP_SUCCESS,
    COM_RECAP_AGE_GROUP_ERROR,

    GET_RECAP_FILTER_DATA_REQUEST,
    GET_RECAP_FILTER_DATA_SUCCESS,
    GET_RECAP_FILTER_DATA_ERROR,

    GET_RECAP_SHOWCASE_REQUEST,
    GET_RECAP_SHOWCASE_SUCCESS,
    GET_RECAP_SHOWCASE_ERROR,

    COM_ALL_RECAP_DESCRIPTION_REQUEST,
    COM_ALL_RECAP_DESCRIPTION_SUCCESS,
    COM_ALL_RECAP_DESCRIPTION_ERROR,

    COM_STATS_LEADER_HITTING_REQUEST,
    COM_STATS_LEADER_HITTING_SUCCESS,
    COM_STATS_LEADER_HITTING_ERROR,

    COM_STATS_LEADER_PITCHING_REQUEST,
    COM_STATS_LEADER_PITCHING_SUCCESS,
    COM_STATS_LEADER_PITCHING_ERROR

} from '../../../../constants/view/company/pwb_pws/recapInfo.const';
import histrory from '../../../../../History';

export const findDivisionArr = (data, type) => dispatch => {
    dispatch({ type: `GET_${type}_BRACKET_DIVISION_REQUEST` })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/event/filter`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(division => {
        dispatch({
            type: `GET_${type}_BRACKET_DIVISION_SUCCESS`,
            payload: division.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: `GET_${type}_BRACKET_DIVISION_ERROR`, error: error.response.data.message })
        }
    });
};

export const bracketFilter = (data, type) => dispatch => {
    dispatch({ type: `GET_${type}_BRACKET_FILTER_REQUEST` })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/eventRecap/schedule/details`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(bracketInfo => {
        dispatch({
            type: `GET_${type}_BRACKET_FILTER_SUCCESS`,
            payload: bracketInfo.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: `GET_${type}_BRACKET_FILTER_ERROR`, error: error.response.data.message })
        }
    });
};

export const getOptionFilter = (data, type) => dispatch => {
    dispatch({ type: `GET_${type}_OPTION_FILTER_REQUEST` })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/eventRecap/filter/list`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(bracketInfo => {
        dispatch({
            type: `GET_${type}_OPTION_FILTER_SUCCESS`,
            payload: bracketInfo.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: `GET_${type}_OPTION_FILTER_ERROR`, error: error.response.data.message })
        }
    });
};

export const getRecapShowcaseData = (data) => dispatch => {
    dispatch({ type: GET_RECAP_SHOWCASE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}pwUser/advance/search/list/temp`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(search => {
        dispatch({
            type: GET_RECAP_SHOWCASE_SUCCESS,
            payload: search.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: GET_RECAP_SHOWCASE_ERROR, error: error.response.data.message })
        }
    });
};

export const getRecapFilterValue = () => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_RECAP_FILTER_DATA_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/filterList" : "pwUser/woAuth/filterList"}`,
        headers: setHeader
    }).then(filter => {
        dispatch({
            type: GET_RECAP_FILTER_DATA_SUCCESS,
            payload: filter.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: GET_RECAP_FILTER_DATA_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventRecapInfo = (eid, age, module) => dispatch => {
    dispatch({ type: COM_RECAP_INFORMATION_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/eventrecap/info/v2/recap`,
        data: module ? {
            id: eid,
            ageGroup: Number(age),
            module: module
        } : {
            id: eid,
            ageGroup: Number(age),
            module: 0
        },
        headers: {
            'Accept': 'application/json'
        }
    }).then(recapInformation => {
        dispatch({
            type: COM_RECAP_INFORMATION_SUCCESS,
            payload: recapInformation.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_RECAP_INFORMATION_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventScore = (data) => dispatch => {
    dispatch({ type: COM_RECAP_INFO_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/eventscore/filter`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recapinfo => {
        dispatch({
            type: COM_RECAP_INFO_SUCCESS,
            payload: recapinfo.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_RECAP_INFO_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventDivision = () => dispatch => {
    dispatch({ type: COM_RECAP_DIVISION_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}setting/division`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recapDivision => {
        dispatch({
            type: COM_RECAP_DIVISION_SUCCESS,
            payload: recapDivision.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_RECAP_DIVISION_ERROR, error: error.response.data.message })
        }
    });
};

export const getScoreDescription = (eid) => dispatch => {
    dispatch({ type: COM_RECAP_DESCRIPTION_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/eventrecap/info/${eid}`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recap_desc => {
        dispatch({
            type: COM_RECAP_DESCRIPTION_SUCCESS,
            payload: recap_desc.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_RECAP_DESCRIPTION_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventAgeGroup = (data) => dispatch => {
    dispatch({ type: COM_RECAP_AGE_GROUP_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/ageGroup/list`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recap_group => {
        dispatch({
            type: COM_RECAP_AGE_GROUP_SUCCESS,
            payload: recap_group.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_RECAP_AGE_GROUP_ERROR, error: error.response.data.message })
        }
    });
};

// Get EventAge Group If Publish Scheduling
export const getEventAgeGroupAfterPublish = (data) => dispatch => {
    dispatch({ type: COM_RECAP_AGE_GROUP_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/eventRecap/filter/list`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recap_group => {
        dispatch({
            type: COM_RECAP_AGE_GROUP_SUCCESS,
            payload: recap_group.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_RECAP_AGE_GROUP_ERROR, error: error.response.data.message })
        }
    });
};

// Get EventAge Group If Publish Scheduling
export const getAllRecapDescription = (data) => dispatch => {
    dispatch({ type: COM_ALL_RECAP_DESCRIPTION_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/eventRecap/schedule/details/v2`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recap_group => {
        dispatch({
            type: COM_ALL_RECAP_DESCRIPTION_SUCCESS,
            payload: recap_group.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: COM_ALL_RECAP_DESCRIPTION_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventStatsLeaderHitting = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_STATS_LEADER_HITTING_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/hittingStats`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recap_group => {
        dispatch({
            type: COM_STATS_LEADER_HITTING_SUCCESS,
            payload: recap_group.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_STATS_LEADER_HITTING_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventStatsLeaderPitching = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_STATS_LEADER_PITCHING_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/pitchingStats`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(recap_group => {
        dispatch({
            type: COM_STATS_LEADER_PITCHING_SUCCESS,
            payload: recap_group.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_STATS_LEADER_PITCHING_ERROR, error: error.response.data.message })
        }
    });
};



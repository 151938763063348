import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from "../../../../assets/images";
import { getPlayerRanking, getAllPlayerList } from '../../../../saga/actions/views/company/pwRanking/leaderboard/playerRanking.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import { withRouter } from "react-router-dom";
import Purchase from '../../../popup/purchaseProspectwire';
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const DropdownIndicator = (
    props
) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={imagesArr.black_slight_search} />
        </components.DropdownIndicator>
    );
};

class playerRanking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            playerRankingList: [],
            purchase: false,
            tempFlg: true,
            playerOption: [],
            playerListReq:{
                company:localStorage.getItem("cid")
            }
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.getRankingList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.playerRankInfo &&
            nextProps.playerRankInfo.getPlayerRankingReq &&
            nextProps.playerRankInfo.getPlayerRankingData
        ) {
            nextProps.playerRankInfo.getPlayerRankingReq = false;
            console.log("nextProps.stateList:--", nextProps.playerRankInfo.getPlayerRankingData);
            if (nextProps.playerRankInfo.getPlayerRankingData.flag) {
                this.setState({
                    playerRankingList: nextProps.playerRankInfo.getPlayerRankingData.result.data
                }, () => {
                    nextProps.playerRankInfo.getPlayerRankingData = null;
                    if (this.state.playerOption.length !== 0) {
                        this.props.decrement();
                    } else {
                        this.props.getAllPlayerList(this.state.playerListReq);
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.playerRankInfo.getPlayerRankingData.message);
                nextProps.playerRankInfo.getPlayerRankingData = null;
                this.props.decrement();
            }
        }

        if (nextProps.playerRankInfo &&
            nextProps.playerRankInfo.getAllPlayerListReq &&
            nextProps.playerRankInfo.getAllPlayerListSuccess
        ) {
            nextProps.playerRankInfo.getAllPlayerListReq = false;
            if (nextProps.playerRankInfo.getAllPlayerListSuccess.flag) {
                console.log("playerranking list:-----", nextProps.playerRankInfo.getAllPlayerListSuccess.result);
                let result = nextProps.playerRankInfo.getAllPlayerListSuccess.result;
                let arr = [];
                result.data.map((item, i) => {
                    arr.push({ value: item._id, label: item.fname ? item.fname + " " + item.lname : "" })
                });
                this.setState({
                    playerOption: arr
                }, () => {
                    nextProps.playerRankInfo.getAllPlayerListSuccess = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.playerRankInfo.getAllPlayerListSuccess.message);
                nextProps.playerRankInfo.getAllPlayerListSuccess = null;
                this.props.decrement();
            }
        }
    }

    getRankingList = () => {
        this.props.increment();
        this.props.getPlayerRanking(this.props.playerRankInfo.sendPlayerRankReq);
    }

    renderRanking = () => {
        return (
            this.state.playerRankingList.length !== 0 ?
                this.state.playerRankingList.map((item, i) => {
                    if (i < 3) {
                        return (
                            <div className="player-item" key={i}>
                                <div className="player-name" onClick={() => this.goto('/pw-profile-info', item._id)}>
                                    <img src={item?.profileUrl} alt="player" />{item.fname + " " + item.lname}
                                </div>
                                <div className="player-rank">{i + 1}{i === 0 ? "st" : i === 1 ? "nd" : "rd"}</div>
                            </div>
                        )
                    }
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.props.playerRankInfo.sendPlayerRankReq = {
            ...this.props.playerRankInfo.sendPlayerRankReq,
            [name]: value
        };
        this.setState((prevState) => ({
            ...prevState,
            tempFlg: !this.state.tempFlg
        }))
    }

    filterChange = (name, perams) => {
        if (name === "rankType" && perams === 1) {
            this.props.playerRankInfo.sendPlayerRankReq = {
                ...this.props.playerRankInfo.sendPlayerRankReq,
                state: ""
            };
        }
        this.props.playerRankInfo.sendPlayerRankReq = {
            ...this.props.playerRankInfo.sendPlayerRankReq,
            [name]: perams
        };
        this.getRankingList();
        // this.setState((prevState) => ({
        //     ...prevState,
        //     tempFlg: !this.state.tempFlg
        // }), () => {
        // })
    }

    goto = (path, id) => {
        localStorage.setItem("userID", id);
        this.props.profileInfo.sendSummaryReq = {
            tab: "summary",
            id: "",
            eventTeamType: 0,
            sortOn: {
                eventstartDate: "",
                eventendDate: "",
                awardstartDate: "",
                awardendDate: "",
                gallerystartDate: "",
                galleryendDate: ""
            },
            seasonType: 1,
            pitching: "basic",
            batting: "basic",
            filterOn: "",
            field: "",
            metricSubType: "Speed",
            metricType: 1,
            companyId: localStorage.getItem("cid"),
            statsType:"Hitting"
        };
        this.props.profileInfo.tabs = 0;
        this.props.profileInfo.backupTab = 0;
        this.props.profileInfo.statsType = 0;
        this.props.profileInfo.eventPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: id,
        };
        this.props.profileInfo.mediaPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: id,
        };
        this.props.profileInfo.eventCurrentPage = 0;
        localStorage.setItem("rankType", this.props.playerRankInfo.sendPlayerRankReq.rankType);
        localStorage.setItem("gradeYear", this.props.playerRankInfo.sendPlayerRankReq.gradeYear);
        localStorage.setItem("position", this.props.playerRankInfo.sendPlayerRankReq.position);
        localStorage.setItem("state", this.props.playerRankInfo.sendPlayerRankReq.state);
        localStorage.setItem("playerName", JSON.stringify(this.props.playerRankInfo.optionData));
        if (id) {
            this.props.history.push(path + "/" + id);
        } else {
            this.props.history.push(path);
        }
    }

    purchaseProspectwire = () => {
        this.setState({
            purchase: true,
        })
    }

    handleClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    setOption = (val) => {
        console.log("val:---", val);
        this.goto('/pw-profile-info', val.value)
        // this.props.playerRankInfo.sendPlayerRankReq = {
        //     ...this.props.playerRankInfo.sendPlayerRankReq,
        //     playerName: val.label
        // };
        // this.props.playerRankInfo.optionData = val;
        // this.setState((prevState) => ({
        //     ...prevState,
        //     tempFlg: !this.state.tempFlg
        // }), () => {
        //     this.getRankingList();
        // })
    }

    render() {
        const { purchase, playerOption } = this.state;
        const { stateList } = this.props;
        const { sendPlayerRankReq, optionData } = this.props.playerRankInfo;
        const years = Array.from(new Array(5), (val, index) => ((new Date()).getFullYear() + index));
        return (
            <>
                <div className="player-ranking">
                    <div className="custom-player-search">
                        <Select
                            defaultValue={optionData}
                            value={optionData}
                            options={playerOption}
                            onChange={(e) => this.setOption(e)}
                            closeMenuOnSelect={true}
                            className="ranking-select"
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            placeholder={"Player search"}
                        />
                        {/* <input className="form-control" name="playerName" onChange={(e) => this.onHandleChange(e)} value={sendPlayerRankReq.playerName} placeholder="Player search" /> */}
                        {/* <img className="search-icon" src={imagesArr.search} onClick={() => this.getRankingList()} /> */}
                    </div>
                    <div className="white-bg mt-20 p-20">
                        <h1 className="section_title">Player Rankings</h1>
                        <div className="list-group-scroll">
                            <ul className="list-group mt-15 mb-15">
                                <li className={`list-group-item ${sendPlayerRankReq.rankType === 1 ? "active" : ""}`} onClick={() => this.filterChange('rankType', 1)}>National</li>
                                <li className={`list-group-item ${sendPlayerRankReq.rankType === 2 ? "active" : ""}`} onClick={() => this.filterChange('rankType', 2)}>State</li>
                                {/* <li className={`list-group-item ${sendPlayerRankReq.rankType === 3 ? "active" : ""}`} onClick={() => this.filterChange('rankType', 3)}>Position</li> */}
                            </ul>
                        </div>
                        {
                            sendPlayerRankReq.rankType === 2 &&
                            <div className="pb-15">
                                    <label>State</label>
                                <select className="custom-select" name="state" value={sendPlayerRankReq.state} onChange={(e) => this.filterChange('state', e.target.value)}>
                                    <option value="">State</option>
                                    {stateList &&
                                        stateList.map((item) => {
                                            return (<option value={item.state}>{item.state}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        }
                        <div className="pb-15">
                            <label>Position</label>
                            <select className="custom-select" name="position" value={sendPlayerRankReq.position} onChange={(e) => this.filterChange('position', e.target.value)}>
                                <option value="">All</option>
                                <option value="RHP">RHP</option>
                                <option value="LHP">LHP</option>
                                <option value="C">C</option>
                                <option value="1B">1B</option>
                                <option value="2B">2B</option>
                                <option value="3B">3B</option>
                                <option value="SS">SS</option>
                                <option value="LF">LF</option>
                                <option value="CF">CF</option>
                                <option value="RF">RF</option>
                                <option value="UTIL">UTIL</option>
                            </select>
                        </div>
                        <div className="pb-15">
                            <label>Grad Year</label>
                            <select className="custom-select" name="gradeYear" value={sendPlayerRankReq.gradeYear} onChange={(e) => this.filterChange('gradeYear', e.target.value)}>
                                {
                                    years &&
                                    years.map((y) => {
                                        return (
                                            <option value={y} className="list-group-item">{y}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="player-ranking-year mb-30">
                            <div className="ranking-year">{sendPlayerRankReq.gradeYear}</div>
                            <div className="player-list">
                                {this.renderRanking()}
                            </div>
                            {
                                // localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" ?
                                //     <div onClick={() => this.purchaseProspectwire()} className="more-info mb-10">
                                //         <span>MORE</span>
                                //         <span className="lock-icon bg-grey" >
                                //             <img src={imagesArr.lock_icon} alt="lock_icon" />
                                //         </span>
                                //     </div>
                                //     :
                                    <div className="more-info mb-10" onClick={() => this.goto('/pw-player-ranking')}>
                                        <span>MORE</span>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

playerRanking.propTypes = {
    getPlayerRanking: PropTypes.func.isRequired,
    getAllPlayerList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    playerRankInfo: state.playerRankingInfo,
    profileInfo: state.profileSummaryInfo,
});

export default withRouter(connect(mapStateToProps, { getPlayerRanking, getAllPlayerList })(playerRanking));

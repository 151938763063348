import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    SAVE_PLAYER_GROUND_REQUEST,
    SAVE_PLAYER_GROUND_SUCCESS,
    SAVE_PLAYER_GROUND_ERROR,

    GET_PLAYER_GROUND_REQUEST,
    GET_PLAYER_GROUND_SUCCESS,
    GET_PLAYER_GROUND_ERROR,
} from '../../../../constants/authantication/signup/player/ground.const';
import histrory from '../../../../../History';

export const savePlayerGround = (data) => dispatch => {
    console.log("data:----action:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_PLAYER_GROUND_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/background`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(ground => {
        console.log("ground:----", ground);
        dispatch({
            type: SAVE_PLAYER_GROUND_SUCCESS,
            payload: ground.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_PLAYER_GROUND_ERROR, error: error.response.data.message })
    });
};

export const groundList = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_GROUND_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/player/background`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(ground => {
        console.log("ground:----", ground);
        dispatch({
            type: GET_PLAYER_GROUND_SUCCESS,
            payload: ground.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_PLAYER_GROUND_ERROR, error: error.response.data.message })
    });
};


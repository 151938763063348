import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getspotlight } from '../../../../saga/actions/common/api.action';
import { imagesArr } from "../../../../assets/images";

class pressRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            embedPost: true,
            pwImage: "",
            pwTitle: "",
            pwDesc: "",
        }
    }

    componentDidMount = () => {
        if (window) {
            if (window.FB) {
                window.FB.init({
                    xfbml: true,
                    version: 'v10.0'
                });
            }
            if (window.twttr && window.twttr.widgets) {
                window.twttr.widgets.load();
            }
        }

        if (this.props.displayOn) {
            this.props.increment();
            this.props.getspotlight({ displayOn: this.props.displayOn })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.spotlight &&
            nextProps.spotlight.spotlightReq &&
            nextProps.spotlight.spotlightData
        ) {
            nextProps.spotlight.spotlightReq = false;
            console.log("nextProps.spotlight:--", nextProps.spotlight.spotlightData);
            if (nextProps.spotlight.spotlightData.flag) {
                let fdata = nextProps.spotlight.spotlightData.result.data[0];
                this.setState({
                    pwImage: fdata.media && fdata.media.filePath && fdata.media.filePath,
                    pwTitle: fdata.title,
                    pwDesc: fdata.description,
                }, () => {
                    nextProps.spotlight.spotlightData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.spotlight.spotlightData.message);
                nextProps.spotlight.spotlightData = null;
                this.props.decrement();
            }
        }
    }

    renderSpotlightContent = () => {
        const { pwImage, pwTitle, pwDesc } = this.state;
        return (
            <div className="w-50">
                <div className="player-spotlight">
                    <h1 className="section_title_left">{this.props.type === "pwb" || this.props.type === "pws" ? "PW" : this.props.type === "gob" ? "GOB" : "USA"} Spotlight</h1>
                    <div className="room_spotlight_card">
                        <img src={pwImage ? pwImage : imagesArr.default_user} alt="player_spotlight" />
                        <h5 className="section_title_left">{pwTitle}</h5>
                        <p>{pwDesc}</p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { embedPost } = this.state;
        const { type } = this.props;
        var twitterLink, fbLink;
        if (type === "pwb") {
            twitterLink = "https://twitter.com/ProspectWire";
            fbLink = "https://www.facebook.com/ProspectWire";
        } else if (type === "pws") {
            twitterLink = "https://twitter.com/PWireSoftball";
            fbLink = "https://www.facebook.com/Prospect-Wire-Softball-100778911931393";
        } else if (type === "gob") {
            twitterLink = "https://twitter.com/GreaterBaseball";
            fbLink = "https://www.facebook.com/GreaterOrlandoBaseball";
        } else if (type === "utb") {
            twitterLink = "https://twitter.com/USATravelBall1";
            fbLink = "https://www.facebook.com/usatravelball";
        }
        return (
            <div className="room_spotlight">
                <div className="container">
                    <div className="row">
                        <div className="w-50">
                            <div className="press-room">
                                <h1 className="section_title_left">PW Press Room</h1>
                                <div className="room_spotlight_card">
                                    <div className="twitter-facebook">
                                        <a className={`social-btn twitter ${embedPost ? 'active' : ''}`} onClick={() => this.setState({ embedPost: true })}><i class="fab fa-twitter"></i>Twitter</a>
                                        <a className={`social-btn facebook ${!embedPost ? 'active' : ''}`} onClick={() => this.setState({ embedPost: false })}><i class="fab fa-facebook-f"></i> Facebook</a>
                                    </div>
                                    <div style={{ display: embedPost ? "block" : "none" }}>
                                        <a class="twitter-timeline" data-width="100%" data-height="600" href={twitterLink}>Tweets by Prospect Wire</a>
                                    </div>
                                    <div className="facebook-iframe" style={{ display: !embedPost ? "block" : "none" }}>
                                        <div class="fb-page" data-href={fbLink} data-tabs="timeline" data-width="520" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true">
                                            <blockquote cite={fbLink} class="fb-xfbml-parse-ignore">
                                                <a href={fbLink}>Prospect Wire</a>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.renderSpotlightContent()}
                    </div>
                </div>
            </div>
        )
    }
}

pressRoom.propTypes = {
    getspotlight: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    spotlight: state.api,
});
var topEventComponent = connect(mapStateToProps, { getspotlight })(pressRoom);
export default withRouter(topEventComponent);

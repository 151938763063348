import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, Authorization, API_Url, checkIsValidCompanyToken, checkLoginValidation } from '../../../../../helpers/authToken';
import {
    GET_TEAM_RANKING_SUCCESS,
    GET_TEAM_RANKING_REQUEST,
    GET_TEAM_RANKING_ERROR,
} from '../../../../../constants/view/company/pwRanking/rankings/teamRanking.const';
import histrory from '../../../../../../History';

export const getAllTeamList = (type,data) => (dispatch) => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: `GET_ALL_${type}_TEAM_LIST_REQUEST` });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}${mode ? "pwUser/ranking/team/list" : "pwUser/woAuth/ranking/team/list"}`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((teamList) => {
        console.log("teamList:--", teamList.data);
        dispatch({
            type: `GET_ALL_${type}_TEAM_LIST_SUCCESS`,
            payload: teamList.data,
        });
    }).catch((error) => {
        console.log(error)
        dispatch({ type: `GET_ALL_${type}_TEAM_LIST_ERROR`, error: error });
    });
};

export const getTeamRanking = (data) => (dispatch) => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_TEAM_RANKING_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}${mode ? "pwUser/ranking/data" : "pwUser/woAuth/ranking/data"}`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((rankingEvent) => {
        console.log("rankingEvent:--", rankingEvent.data);
        dispatch({
            type: GET_TEAM_RANKING_SUCCESS,
            payload: rankingEvent.data,
        });
    }).catch((error) => {
        console.log(error)
        dispatch({ type: GET_TEAM_RANKING_ERROR, error: error });
    });
};


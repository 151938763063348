import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { saveRoster } from '../../saga/actions/views/coach/profile/playerInfo.action';
import { getDivision } from '../../saga/actions/authantication/signup/coach/roster.action';
import Preloader from '../../components/preloader';

export class playerRoster extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sendReq: {
        rosterName: "",
        ageGroup: "",
        division: "",
        teamRecord: "",
        type: "1",
        competitionLevel: ""
      },
      loader: false,
      division: []
    };

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    if (this.props.flag) {
      document.body.classList.add("video-open");
    }
    this.props.getDivision();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rosterDetails &&
      nextProps.rosterDetails.divisionRes &&
      nextProps.rosterDetails.divisionData
    ) {
      nextProps.rosterDetails.divisionRes = false;
      console.log("nextProps.rosterDetails.divisionData:-", nextProps.rosterDetails.divisionData);
      this.setState({
        division: nextProps.rosterDetails.divisionData.data
      }, () => {
        nextProps.rosterDetails.divisionData = null
      });
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.saveRosterReq &&
      nextProps.playerDetails.saveRosterData
    ) {
      nextProps.playerDetails.saveRosterReq = false;
      console.log("nextProps.playerDetails.saveRosterData:-", nextProps.playerDetails.saveRosterData);
      if (nextProps.playerDetails.saveRosterData.flag) {
        this.commonCall.successMessage(nextProps.playerDetails.saveRosterData.message);
        nextProps.playerDetails.saveRosterData = null;
        this.setLoader(false);
        this.props.close();
      } else {
        this.commonCall.errorMessage(nextProps.playerDetails.saveRosterData.message);
        nextProps.playerDetails.saveRosterData = null;
        this.setLoader(false);
      }
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  onHandleChange = (e) => {
    const { name, value } = e.target;
    console.log("name:---val:----", name, value);
    this.setState((prevState) => ({
      ...prevState,
      sendReq: {
        ...prevState.sendReq,
        [name]: value
      },
    }));
  }

  onSave = () => {
    if (this.validator.allValid()) {
      console.log("saver:---", this.state.sendReq);
      this.setLoader(true);
      this.props.saveRoster(this.state.sendReq);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const { sendReq, loader, division } = this.state;
    const { flag, type } = this.props;

    return (
      <>
        <section className={`common-modal videomodal ${flag ? "show" : ""}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="green-header modal-header">
                <h3>{type === "add" ? "Add" : "Edit"} New Roster</h3>
                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.white_modal_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body">
                <div className="team-body-inner">
                  <div className="form-group mb-0">
                    <div className="multi-radio">
                      <label className="round-radio">
                        Team
                        <input
                          type="radio"
                          id="team"
                          name="type"
                          value="1"
                          onChange={this.onHandleChange}
                          checked={sendReq.type === "1" && true}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="round-radio">
                        Facility
                        <input
                          type="radio"
                          id="facility"
                          name="type"
                          value="2"
                          onChange={this.onHandleChange}
                          checked={sendReq.type === "2" && true}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <span className="validMsg">{this.validator.message("Roster type", sendReq.type, "required")}</span>
                  </div>
                  <div className="form-group mb-0">
                    <label className="common-lable">Name of Roster</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      placeholder="Enter roster name"
                      name="rosterName"
                      value={sendReq.rosterName}
                      onChange={this.onHandleChange}
                    />
                    <span className="validMsg">{this.validator.message("roster Name", sendReq.rosterName, "required")}</span>
                  </div>

                  <div className="form-group mb-0">
                    <label className="common-lable">Competition Level</label>
                    <select name="competitionLevel" className="custom-select" onChange={this.onHandleChange}>
                      <option value="" selected disabled>Competition Level</option>
                      <option value="Youth" selected={sendReq && sendReq.competitionLevel === "Youth" ? "selected" : ""}>Youth</option>
                      <option value="High School" selected={sendReq && sendReq.competitionLevel === "High School" ? "selected" : ""}>High School</option>
                      <option value="College" selected={sendReq && sendReq.competitionLevel === "College" ? "selected" : ""}>College</option>
                      <option value="Independent" selected={sendReq && sendReq.competitionLevel === "Independent" ? "selected" : ""}>Independent</option>
                      <option value="MLB" selected={sendReq && sendReq.competitionLevel === "MLB" ? "selected" : ""}>MLB</option>
                    </select>
                  </div>
                  {/* <div className="form-group mb-0">
                    <label className="common-lable">Age Group</label>
                    <select name="ageGroup" value={sendReq.ageGroup} className="custom-select" onChange={this.onHandleChange}>
                      <option value="" selected disabled>Select age group</option>
                      <option value="8" selected={sendReq && sendReq.ageGroup === "8" ? "selected" : ""}>8U</option>
                      <option value="9" selected={sendReq && sendReq.ageGroup === "9" ? "selected" : ""}>9U</option>
                      <option value="10" selected={sendReq && sendReq.ageGroup === "10" ? "selected" : ""}>10U</option>
                      <option value="11" selected={sendReq && sendReq.ageGroup === "11" ? "selected" : ""}>11U</option>
                      <option value="12" selected={sendReq && sendReq.ageGroup === "12" ? "selected" : ""}>12U</option>
                      <option value="13" selected={sendReq && sendReq.ageGroup === "13" ? "selected" : ""}>13U</option>
                      <option value="14" selected={sendReq && sendReq.ageGroup === "14" ? "selected" : ""}>14U</option>
                      <option value="15" selected={sendReq && sendReq.ageGroup === "15" ? "selected" : ""}>15U</option>
                      <option value="16" selected={sendReq && sendReq.ageGroup === "16" ? "selected" : ""}>16U</option>
                      <option value="17" selected={sendReq && sendReq.ageGroup === "17" ? "selected" : ""}>17U</option>
                      <option value="18" selected={sendReq && sendReq.ageGroup === "18" ? "selected" : ""}>18U</option>
                      <option value="Independent" selected={sendReq && sendReq.ageGroup === "Independent" ? "selected" : ""}>Independent</option>
                      <option value="International" selected={sendReq && sendReq.ageGroup === "International" ? "selected" : ""}>International</option>
                      <option value="miLB" selected={sendReq && sendReq.ageGroup === "miLB" ? "selected" : ""}>MiLB</option>
                    </select>
                    <span className="validMsg">{this.validator.message("Age Group", sendReq.ageGroup, "required")}</span>
                  </div>
                  <div className="form-group mb-0">
                    <label className="common-lable">Division</label>
                    <select name="division" value={sendReq.division} onChange={this.onHandleChange} className="custom-select">
                      <option value="">N/A</option>
                      {
                        division &&
                        division.map(function (obj, i) {
                          return (
                            <option
                              key={i}
                              defaultValue={sendReq.division}
                              selected={
                                sendReq.division === obj._id
                                  ? "selected"
                                  : ""
                              }
                            >
                              {obj.title}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div> */}
                </div>
                <div className="flex-align next-button">
                  <Link className="btn gray" onClick={this.props.close}>
                    Cancel
                  </Link>
                  <Link className="btn light-green" onClick={() => this.onSave()}>
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Preloader flag={loader} />
      </>
    );
  }
}

playerRoster.propTypes = {
  getDivision: PropTypes.func.isRequired,
  saveRoster: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  playerDetails: state.playerInfo,
  rosterDetails: state.rosterDetails
});

export default connect(mapStateToProps, { getDivision, saveRoster })(playerRoster);

import React, { Component } from 'react';
import AcceptreqHeader from '../containers/acceptreqHeader';
import AcceptHeroBanner from '../components/acceptHeroBanner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { acceptRequest } from "../saga/actions/common/api.action";
import Preloader from '../components/preloader';
import commonCalls from "../businesslogic/commonCalls";

class acceptReq extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            sendArr: {
                notification: ""
            }
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let url = new URL(document.location);
        let id = url.pathname.split("/")[3];
        this.setState({
            sendArr: {
                notification: id
            }
        }, () => {
            this.setLoader(true);
            this.props.acceptRequest(this.state.sendArr);
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonDetails &&
            nextProps.commonDetails.acceptReq &&
            nextProps.commonDetails.acceptData
        ) {
            nextProps.commonDetails.acceptReq = false;
            if (nextProps.commonDetails.acceptData.flag) {
                this.commonCall.successMessage(nextProps.commonDetails.acceptData.message);
                nextProps.commonDetails.acceptData = null;
                this.setLoader(false);
            } else {
                nextProps.commonDetails.acceptData = null;
                this.setLoader(false);
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state
        return (
            <>
                <AcceptreqHeader />
                <AcceptHeroBanner />
                <Preloader flag={loader} />
            </>
        );
    }
}

acceptReq.propTypes = {
    acceptRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    commonDetails: state.api,
});

export default connect(mapStateToProps, { acceptRequest })(acceptReq);

import React, { useState, useEffect, memo } from 'react';
import { hittingStatsTabInfo, pitchingStatsTabInfo } from './eventTab'

function StatsLeaders({ allRecapDescription, statsLeadersHittingTab, statsLeadersPitchingTab, statsLeadersHittingTabHandler, statsLeadersPitchingTabHandler, statsLeadersHittingName, statsLeadersPitchingName, topRecDatahandler, redirectPwProfilePopupOpenHandler, hittingDescription, pitchingDescription }) {
    const [stateHitting, setStateHitting] = useState({ hittingStatArray: [], itemsToShow: 25, isShow: true,hittingStatTitle: "" });
    const [statePitching, setStatePitching] = useState({ pitchingStatArray: [], itemsToShow: 25, isShow: true,pitchingStatTitle: "" });

    useEffect(() => {
        if (allRecapDescription) {
            const isTempHittingShow = allRecapDescription.hittingStats?.length > 25;
            const isTempPitchingShow = allRecapDescription.pitchingStats?.length > 25;
            setStateHitting({ ...stateHitting, hittingStatArray: allRecapDescription.hittingStats, isShow: isTempHittingShow, itemsToShow: 25,hittingStatTitle: allRecapDescription?.hittingKey })
            setStatePitching({ ...statePitching, pitchingStatArray: allRecapDescription.pitchingStats, isShow: isTempPitchingShow, itemsToShow: 25,pitchingStatTitle: allRecapDescription?.pitchingKey })
        }
    }, [allRecapDescription])

    useEffect(() => {
        if (hittingDescription) {
            const isTempHittingShow =  hittingDescription.hittingStats?.length > 25;
            setStateHitting({...stateHitting, hittingStatArray: hittingDescription?.hittingStats, isShow: isTempHittingShow, itemsToShow: 25,hittingStatTitle: hittingDescription?.hittingKey   })
        }
    }, [hittingDescription])


    useEffect(() => {
        if(pitchingDescription){
            const isTempPitchingShow = pitchingDescription.pitchingStats?.length > 25;
            setStatePitching({...statePitching,pitchingStatArray: pitchingDescription?.pitchingStats, isShow: isTempPitchingShow, itemsToShow: 25,pitchingStatTitle: pitchingDescription?.pitchingKey})
        }
    }, [pitchingDescription])

    const showMoreHittingHandler = () => {
        const isTempShow = stateHitting.hittingStatArray.length > stateHitting.itemsToShow + 25;
        setStateHitting({ ...stateHitting, itemsToShow: stateHitting.itemsToShow + 25, isShow: isTempShow })
    }

    const showMorePitchingHandler = () => {
        const isTempShow = statePitching.pitchingStatArray.length > statePitching.itemsToShow + 25;
        setStatePitching({ ...statePitching, itemsToShow: statePitching.itemsToShow + 25, isShow: isTempShow })
    }

    return (
        <>
            <div className="stats-leaders-tab">
                <div className="container">
                    <div className="stats-leaders-info">
                        <div className="stats-leaders-row">
                            <div className="stats-hitting">
                                <div className="stats-info">
                                    <h1 className="section_title">Hitting Stats</h1>
                                    <div className="sub-tab">
                                        {(statsLeadersHittingTab === "AVG" || 
                                        statsLeadersHittingTab === "OPS" ||
                                        statsLeadersHittingTab === "SLG" || 
                                        statsLeadersHittingTab === "OBP" ) &&
                                         <p className='text-right mb-0'>{`Minimum: > 1 AB per Team Game`}</p>}
                                        <div className="row">
                                            {hittingStatsTabInfo.map(({ title, id, payload }, index) => (
                                                <div className="w-25" key={id + payload + index} onClick={() => statsLeadersHittingTabHandler(payload, statsLeadersPitchingTab, title, statsLeadersPitchingName)}>
                                                    <div className={`tab-text ${statsLeadersHittingTab === payload ? "active" : ""}`}>{title}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="recap-table-info">
                                            <div className="table-responsive-xl">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{stateHitting.hittingStatTitle ? `${stateHitting.hittingStatTitle}` : "-"}</th>
                                                            <th>{statsLeadersHittingName ? statsLeadersHittingName : "-"}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stateHitting?.hittingStatArray?.length !== 0 ? stateHitting?.hittingStatArray?.slice(0, stateHitting.itemsToShow).map(({ PlayerName, Stat, PlayerId, TeamName }, index) => (
                                                            <tr key={PlayerId + Stat + index}>
                                                                <td onClick={() => redirectPwProfilePopupOpenHandler({ PlayerId, PlayerName })}>{index + 1}. {PlayerName ? `${PlayerName} ${TeamName ? `- ${TeamName}` : ""}` : "-"}</td>
                                                                <td>{Stat}</td>
                                                            </tr>
                                                        )) : <tr><td className='text-center' colSpan="2">No data found</td></tr>}
                                                        {stateHitting.isShow && <tr><td className='text-center' colSpan="2" onClick={() => showMoreHittingHandler()}> Show More </td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stats-pitching">
                                <div className="stats-info">
                                    <h1 className="section_title">Pitching Stats</h1>
                                    <div className="sub-tab">
                                    {(statsLeadersPitchingTab === "ERA" ||
                                    statsLeadersPitchingTab === "WHIP" ||
                                    statsLeadersPitchingTab === "ER" ||
                                    statsLeadersPitchingTab === "KperBB" ||
                                    statsLeadersPitchingTab === "BAA") &&
                                      <p className='text-right mb-0'>Minimum: 9 Outs</p>}
                                        <div className="row">
                                            {pitchingStatsTabInfo.map(({ title, id, payload }, index) => (
                                                <div className="w-25" key={index + payload + id} onClick={() => statsLeadersPitchingTabHandler(statsLeadersHittingTab, payload, statsLeadersHittingName, title)}>
                                                    <div className={`tab-text ${statsLeadersPitchingTab === payload ? "active" : ""}`}>{title}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="recap-table-info">
                                            <div className="table-responsive-xl">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{statePitching.pitchingStatTitle ? `${statePitching.pitchingStatTitle}` : "-"}</th>
                                                            <th>{statsLeadersPitchingName ? statsLeadersPitchingName : "-"}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {statePitching?.pitchingStatArray?.length !== 0 ? statePitching?.pitchingStatArray?.slice(0, statePitching.itemsToShow).map(({ PlayerName, Stat, PlayerId, TeamName }, index) => (
                                                            <tr key={index + Stat + PlayerId}>
                                                                <td onClick={() => redirectPwProfilePopupOpenHandler({ PlayerId, PlayerName })}>{index + 1}. {PlayerName ? `${PlayerName} ${TeamName ? `- ${TeamName}` : ""}` : "-"}</td>
                                                                <td>{Stat}</td>
                                                            </tr>
                                                        )) : <tr><td className='text-center' colSpan="2">No data found</td></tr>}
                                                        {statePitching.isShow && <tr><td className='text-center' colSpan="2" onClick={() => showMorePitchingHandler()}> Show More </td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stat-leaders">
                <div className="container">
                    {/* <h1 className="section_title">Stats Leaders</h1> */}
                    {
                        (allRecapDescription?.eventObj?.RunsScored || allRecapDescription?.eventObj?.TotalBase || allRecapDescription?.eventObj?.RunsBatted || allRecapDescription?.eventObj?.Walks || allRecapDescription?.eventObj?.StolenBases
                            || allRecapDescription?.eventObj?.OBP || allRecapDescription?.eventObj?.OPS || allRecapDescription?.eventObj?.HR || allRecapDescription?.eventObj?.B1 || allRecapDescription?.eventObj?.B2 || allRecapDescription?.eventObj?.B3 || allRecapDescription?.eventObj?.B4) ?
                            <h4 className="section-sub-title">Batting Leaders</h4>
                            :
                            <p className="text-center mt-15">No data found</p>
                    }
                    <div className="row">
                        {
                            allRecapDescription?.eventObj?.RunsScored &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>Runs Scored (R)</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'Runs Scored (R)', data: allRecapDescription?.eventObj?.topRunscorerR })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.topRunscorerR?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.topRunscorerR.map((item, i) => {
                                                        return (
                                                            <li> {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.TotalBase &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>Total Bases (TB)</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'Runs Scored (R)', data: allRecapDescription?.eventObj?.totalbase })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.totalbase?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.totalbase.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.RunsBatted &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>Runs Batted In (RBI)</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'Runs Batted In (RBI)', data: allRecapDescription?.eventObj?.runsbattedin })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.runsbattedin?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.runsbattedin.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.Walks &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>Walks (BB)</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'Walks (BB)', data: allRecapDescription?.eventObj?.walksbb })} >Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.walksbb?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.walksbb.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.StolenBases &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>Stolen Bases (SB)</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'Stolen Bases (SB)', data: allRecapDescription?.eventObj?.stolebase })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.stolebase?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.stolebase.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.OBP &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>OBP</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'OBP', data: allRecapDescription?.eventObj?.obp })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.obp?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.obp.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.OPS &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>OPS</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'OPS', data: allRecapDescription?.eventObj?.ops })} >Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.ops?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.ops.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.HR &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>HR</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'HR', data: allRecapDescription?.eventObj?.hr })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.hr?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.hr.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.B1 &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>1B</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: '1B', data: allRecapDescription?.eventObj?.b1 })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.b1?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.b1.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.B2 &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>2B</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: '2B', data: allRecapDescription?.eventObj?.b2 })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.b2?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.b2.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.B3 &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>3B</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: '3B', data: allRecapDescription?.eventObj?.b3 })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.b3?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.b3.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.B4 &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>BA</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'BA', data: allRecapDescription?.eventObj?.ba })} >Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.ba?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.ba.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        (allRecapDescription?.eventObj?.WHIP || allRecapDescription?.eventObj?.StrikeOUts) &&
                        <h4 className="section-sub-title">Pitching Leaders</h4>
                    }
                    <div className="row">
                        {
                            allRecapDescription?.eventObj?.WHIP &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>WHIP</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'WHIP', data: allRecapDescription?.eventObj?.whip })} >Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.whip?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.whip.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.StrikeOUts &&
                            <div className="col-3">
                                <div className="stat-leaders-box">
                                    <div className="title-tag">
                                        <h5>Strikeouts</h5>
                                        {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                            <button className="btn" onClick={() => topRecDatahandler({ title: 'Strikeouts', data: allRecapDescription?.eventObj?.strikeouts })}>Top 10</button>
                                        }
                                    </div>
                                    <div className="list">
                                        <ul>
                                            {
                                                allRecapDescription?.eventObj?.strikeouts?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.strikeouts.map((item, i) => {
                                                        return (
                                                            <li>{item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                        )
                                                    })
                                                    :
                                                    <li className="text-center">
                                                        No data found
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(StatsLeaders)

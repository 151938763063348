import {
  GETINFO_REQUEST,
  GETINFO_SUCCESS,
  GETINFO_ERROR,

  ZIPINFO_REQUEST,
  ZIPINFO_SUCCESS,
  ZIPINFO_ERROR,

  POSTINFO_REQUEST,
  POSTINFO_SUCCESS,
  POSTINFO_ERROR,

  PICTUREUPLOAD_REQUEST,
  PICTUREUPLOAD_SUCCESS,
  PICTUREUPLOAD_ERROR,
} from "../../../../constants/view/player/profile/personalInfo.const";

const initialState = {
  getInfoData: null,
  getInfoError: null,

  zipcodeData: null,
  zipcodeError: null,

  postInfoData: null,
  postInfoError: null,

  pictureUploadData: null,
  pictureUploadError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETINFO_SUCCESS:
      return {
        ...state,
        getInfoData: action.payload,
      };

    case GETINFO_ERROR:
      return {
        ...state,
        getInfoError: action.error,
      };

    case ZIPINFO_SUCCESS:
      return {
        ...state,
        zipcodeData: action.payload,
      };

    case ZIPINFO_ERROR:
      return {
        ...state,
        zipcodeError: action.error,
      };

    case POSTINFO_SUCCESS:
      return {
        ...state,
        postInfoData: action.payload,
      };

    case POSTINFO_ERROR:
      return {
        ...state,
        postInfoError: action.error,
      };

    case PICTUREUPLOAD_SUCCESS:
      return {
        ...state,
        pictureUploadData: action.payload,
      };

    case PICTUREUPLOAD_ERROR:
      return {
        ...state,
        pictureUploadError: action.error,
      };

    default:
      return state;
  }
}

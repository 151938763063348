import axios from "axios";
import { API_Url, Auth_Player_Key, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import { OTHERINFO_SUCCESS, OTHERINFO_ERROR, POSTOTHERINFO_SUCCESS, POSTOTHERINFO_ERROR, COLLEGEINFO_SUCCESS, COLLEGEINFO_ERROR } from "../../../../constants/view/player/profile/otherInfo.const";
import histrory from '../../../../../History';

export const getOtherInfo = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    axios({
        method: 'GET',
        url: `${API_Url}bc/player/other`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(other => {
        dispatch({
            type: OTHERINFO_SUCCESS,
            payload: other.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: OTHERINFO_ERROR, error: error.response.data.message })
    });
};

export const getCollegeInfo = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    axios({
        method: 'POST',
        url: `${API_Url}college/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(college => {
        dispatch({
            type: COLLEGEINFO_SUCCESS,
            payload: college.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: COLLEGEINFO_ERROR, error: error.response.data.message })
    });
};

export const passInfo = (registerPostData) => dispatch => {
    checkIsValidPlayerToken(histrory)
    return axios({
        method: 'POST',
        url: `${API_Url}bc/player/other`,
        data: registerPostData,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then((post) => {
        console.log("post----->", post)
        dispatch({
            type: POSTOTHERINFO_SUCCESS,
            payload: post.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: POSTOTHERINFO_ERROR, error: error.response.data.message })
    });
};

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import SimpleReactValidator from 'simple-react-validator';

class proCollage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            teamName: ""
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    onSave = () => {
        if (this.validator.allValid()) {
            this.props.saveData(this.props.tName ? this.props.tName : this.state.teamName);
            this.setState({
                teamName: ""
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { teamName } = this.state;
        const { flag, userData, tName, type } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>{type === "collage" ? "College" : "Professional"}</h3>
                                <div className="modal_close" onClick={this.props.close}>
                                    <img src={imagesArr.white_modal_close} alt="modal_close" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="team-body-inner">
                                    <h4>We will contact you using the number and email provided</h4>
                                    <div className="form-group">
                                        <input type="text" name="teamName" value={tName ? tName : teamName} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Enter Program Name" />
                                        <span className="validMsg">{this.validator.message('teamName', tName ? tName : teamName, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" id="usr" value={userData.fname + " " + userData.lname} placeholder="Your Name from sign up" disabled />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" id="usr" value={userData.email} placeholder="Your Email from sign up" disabled />
                                    </div>
                                    <div className="form-group mb-0">
                                        <input type="text" name="email" className="form-control" id="usr" value={userData.phone} placeholder="Your Phone Number from sign up" disabled />
                                    </div>
                                </div>
                                <div className="flex-align next-button">
                                    <Link className="btn light-green" onClick={() => this.onSave()}>Submit</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default proCollage;
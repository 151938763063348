import React, { useEffect, useReducer } from 'react'
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import { getMultipleEvent ,fetchPaymentIntent, multipleEventPayment} from '../../../../saga/actions/common/eventDetails.action';
import { useDispatch, useSelector } from 'react-redux';
import commonCalls from "../../../../businesslogic/commonCalls";
import CreditCard from "../payment/creditCard";
import { FETCH_PAYMENT_INTENT_SUCCESS, GET_MULTIPLE_EVENT_SUCCESS, MULTIPLE_EVENT_PAYMENT_SUCCESS } from '../../../../saga/constants/common/eventDetails.const';
import Preloader from "../../../../components/preloader";
import { useHistory } from 'react-router-dom';
import { checkLoginValidation } from '../../../../saga/helpers/authToken';

const initialState = {
  loader: false,
  detailReq: {
    notificationId: ""
  },
  events:[],
  remainingPrize: 0,
  paymentType: "",
  paymentMethod: 1,
  creditCard: false,
  eventJoin:{
    notificationId: "",
    amount: 0,
    metaPayment: {},
    couponCode: ""
  }
}

const reducer = (state, payload) => ({ ...state, ...payload });


function EventMultiplePay() {
  const dispatch = useDispatch();
  const history = useHistory();
  const commonCall = new commonCalls();
  const [event, setEvent] = useReducer(reducer, initialState);
  const { getMultipleEvent: _EVENTS } = useSelector((state) => state.eventDetails);
  const eventDeatils = useSelector((state) => state.eventDetails);

  useEffect(() => {
    let url = new URL(document.location);
    var isloggedIn = checkLoginValidation();
    localStorage.removeItem("company");
    localStorage.removeItem("cid");
    localStorage.removeItem("companyColor");
    if (!isloggedIn) {
      localStorage.setItem("multiPay",true);
      localStorage.setItem("payId", url.search ? url.search.split("=")[1] : "");
      history.push("/login");
    }else{
      localStorage.removeItem("multiPay");
      localStorage.removeItem("payId");
      setLoader(true);
     
      localStorage.removeItem("installment");
      setEvent({
        ...event,
        detailReq: {
          notificationId: url.search ? url.search.split("=")[1] : ""
        },
        eventJoin:{
          ...event.eventJoin,
          notificationId: url.search ? url.search.split("=")[1] : "",
        }
      })
      getMultipleEvent({ notificationId: url.search ? url.search.split("=")[1] : "" })(dispatch);
    }
  }, [])

  useEffect(() => {
    console.log("event",_EVENTS);
    if (_EVENTS) {
    if (_EVENTS.flag) {
        setEvent({
          ...event,
          events: _EVENTS.result.event,
          paymentType: _EVENTS.result.paymentType,
          remainingPrize: _EVENTS.result.price,
          eventJoin:{
            ...event.eventJoin,
            amount: _EVENTS.result.price,
          },
          loader:false
        })
      } else {
        commonCall.errorMessage(_EVENTS.message);
      }
      dispatch({
        type: GET_MULTIPLE_EVENT_SUCCESS,
        payload: null,
      });
    }
  }, [_EVENTS])


  useEffect(() => {
    if (eventDeatils &&
      eventDeatils.getPaymentIntentData
  ) {
      if (eventDeatils.getPaymentIntentData.flag) {
          localStorage.setItem(
              "stripeIntent",
              eventDeatils.getPaymentIntentData.result.data
          );
          setEvent({
            ...event,
            paymentMethod : event.paymentMethod === 1 ? "" : 1,
            creditCard: true,
            loader: false
          })
          dispatch({
            type: FETCH_PAYMENT_INTENT_SUCCESS,
            payload: null,
        });
      } else {
        commonCall.errorMessage(eventDeatils.getPaymentIntentData.message);
          dispatch({
            type: FETCH_PAYMENT_INTENT_SUCCESS,
            payload: null,
        });
          setLoader(false);
      }
  }

  if (eventDeatils &&
    eventDeatils.multipleEventPayment
) {
    if (eventDeatils.multipleEventPayment.flag) {
      history.push(`/multi-event-joined/${event.eventJoin.notificationId}`);
      
    } else {
      commonCall.errorMessage(eventDeatils.multipleEventPayment.message);
        dispatch({
          type: MULTIPLE_EVENT_PAYMENT_SUCCESS,
          payload: null,
      });
        setLoader(false);
    }
}
  }, [eventDeatils])
  
  


  const setLoader = (val) => {
    setEvent({
      ...event,
      loader: val
    })
  }

  const chkValid = (e) => {
    if (event.paymentType !== 0) {
      var payment = {
        amount: event.remainingPrize,
        objetId: event.events.map(_ => {return _.event._id}),
        module: 1,
        method: "stripe",
      };
      console.log("payment:---", payment);
      setLoader(true);
      fetchPaymentIntent(payment)(dispatch);
      // this.props.onHandleChange(e)
    }

  }

  const onHandleClose = () => {
    setEvent({
      ...event,
      creditCard: false
    })
    document.body.remove("modal-open");
  }

  const stripePaymentError = (error) => {
    commonCall.errorMessage(error);
    setEvent({
      ...event,
      loader: false,
    })
  };


  const stripePaymentSuccess = (payment) => {
    let req = {
      ...event.eventJoin,
      metaPayment: payment,
    }
    setEvent({
      ...event,
      creditCard: false,
      eventJoin: req
    })
    document.body.classList.remove("modal-open");
    multipleEventPayment(req)(dispatch);
  };

  const renderEventList = () => {
    return (
      event.events.length ?
      event.events.map(_ => {
        return (
          <div className='event_list_item'>
            <div className='event_logo'>
              <img src={_.event?.eventLogo?.filePath ? _.event?.eventLogo?.filePath :'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'} alt="event logo" />
            </div>
            <div className='event_detail'>
              <h5>{_.event?.eventTitle}</h5>
              <p>${_.price}</p>
            </div>
          </div>
        )
      })
        :
        <div className='event_list_item' >
          <p style={{ textAlign: "center" }}>No Event</p></div>
    )

  }


  return (
    <>
      <div>
        <Header />
        <div className='event_multiple_Pay'>
          <div className='container'>
            <div className='event_list_info'>
              <div className='title'>
                <h1 class="section_title">Event List</h1>
              </div>
              <div className='event_list'>
                {renderEventList()}
              </div>
            </div>

            <div className='event_list_info'>
              <div className='title'>
                <h1 class="section_title">Payment</h1>
              </div>
              <div className='payment_info'>
                <form>
                  <div className='row'>
                    <div className='w-full'>
                      <div class="form-group">
                        <label>Payment Type</label>
                        <input type="text" name="paymentType" class="form-control" disabled value={"Full Payment ( $" + event.remainingPrize + " )"} />
                      </div>
                    </div>
                    {/* <div className='w-85'>
                      <div class="form-group">
                        <label>Payment Type</label>
                        <input type="text" name="address" class="form-control" placeholder="Enter address" value="" />
                      </div>
                    </div>
                    <div className='w-15'>
                      <div className='apply_btn'><button class="btn">Apply</button></div>
                    </div> */}
                    <div className='w-full'>
                      <div className="form-group">
                        <label className="common-lable">Payment Method</label>
                        <div className="check_input">
                          <div className="check_box">
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                name="creditcard"
                              // value={eventJoin.paymentMethod}
                              onChange={(e) => chkValid(e)}
                              // checked={
                              //     eventJoin.paymentMethod === 1
                              //         ? true
                              //         : false
                              // }
                              />
                              <label>Credit Card </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className='join_btn'><button class="btn">Join</button></div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {
        event.creditCard &&
        <CreditCard
          flag={event.creditCard}
          close={onHandleClose}
          stripePaymentSuccess={stripePaymentSuccess}
          stripePaymentError={stripePaymentError}
          amount={event.remainingPrize}
          setLoader={setLoader}
        />
      }
      <Preloader flag={event.loader} />
    </>
  )
}

export default EventMultiplePay
import {
    GET_LICENSE_REQUEST,
    GET_LICENSE_SUCCESS,
    GET_LICENSE_ERROR,

    GET_LICENSE_NAME_REQUEST,
    GET_LICENSE_NAME_SUCCESS,
    GET_LICENSE_NAME_ERROR,

    GET_LICENSE_PLAYER_REQUEST,
    GET_LICENSE_PLAYER_SUCCESS,
    GET_LICENSE_PLAYER_ERROR,

    ADD_LICENSE_REQUEST,
    ADD_LICENSE_SUCCESS,
    ADD_LICENSE_ERROR,

    UP_STATUS_REQUEST,
    UP_STATUS_SUCCESS,
    UP_STATUS_ERROR,

    UPDATE_LICENSE_ERROR,
    UPDATE_LICENSE_REQUEST,
    UPDATE_LICENSE_SUCCESS,

    DELETE_LICENSE_ERROR,
    DELETE_LICENSE_REQUEST,
    DELETE_LICENSE_SUCCESS
} from '../../../constants/view/coach/license.const';

const initialState = {
    licenseReq: false,
    licenseData: null,
    licenseError: null,

    licenseNameReq: false,
    licenseNameData: null,
    licenseNameError: null,

    licensePlayerReq: false,
    licensePlayerData: null,
    licensePlayerError: null,

    addLicenseReq: false,
    addLicenseData: null,
    addLicenseError: null,

    upStatusReq: false,
    upStatusData: null,
    upStatusError: null,

    updateLicenseReq: false,
    updateLicenseData: null,
    updateLicenseError: null,

    deleteLicenseReq: false,
    deleteLicenseData: null,
    deleteLicenseError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // get license req    
        case GET_LICENSE_REQUEST:
            return {
                ...state,
                licenseReq: true,
            };
        case GET_LICENSE_SUCCESS:
            return {
                ...state,
                licenseData: action.payload,
            };
        case GET_LICENSE_ERROR:
            return {
                ...state,
                licenseError: action.error,
            };

        // update status req    
        case UP_STATUS_REQUEST:
            return {
                ...state,
                upStatusReq: true,
            };
        case UP_STATUS_SUCCESS:
            return {
                ...state,
                upStatusData: action.payload,
            };
        case UP_STATUS_ERROR:
            return {
                ...state,
                upStatusError: action.error,
            };

        // get license name req    
        case GET_LICENSE_NAME_REQUEST:
            return {
                ...state,
                licenseNameReq: true,
            };
        case GET_LICENSE_NAME_SUCCESS:
            return {
                ...state,
                licenseNameData: action.payload,
            };
        case GET_LICENSE_NAME_ERROR:
            return {
                ...state,
                licenseNameError: action.error,
            };

        // get license player req    
        case GET_LICENSE_PLAYER_REQUEST:
            return {
                ...state,
                licensePlayerReq: true,
            };
        case GET_LICENSE_PLAYER_SUCCESS:
            return {
                ...state,
                licensePlayerData: action.payload,
            };
        case GET_LICENSE_PLAYER_ERROR:
            return {
                ...state,
                licensePlayerError: action.error,
            };

        // add license req    
        case ADD_LICENSE_REQUEST:
            return {
                ...state,
                addLicenseReq: true,
            };
        case ADD_LICENSE_SUCCESS:
            return {
                ...state,
                addLicenseData: action.payload,
            };
        case ADD_LICENSE_ERROR:
            return {
                ...state,
                addLicenseError: action.error,
            };

        // update license req    
        case UPDATE_LICENSE_REQUEST:
            return {
                ...state,
                updateLicenseReq: true,
            };
        case UPDATE_LICENSE_SUCCESS:
            return {
                ...state,
                updateLicenseData: action.payload,
            };
        case UPDATE_LICENSE_ERROR:
            return {
                ...state,
                updateLicenseError: action.error,
            };

        // delete license req    
        case DELETE_LICENSE_REQUEST:
            return {
                ...state,
                deleteLicenseReq: true,
            };
        case DELETE_LICENSE_SUCCESS:
            return {
                ...state,
                deleteLicenseData: action.payload,
            };
        case DELETE_LICENSE_ERROR:
            return {
                ...state,
                deleteLicenseError: action.error,
            };
        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import CommonHeroBanner from "../../../components/commonHeroBanner";
import commonCalls from "../../../businesslogic/commonCalls";
import { updateDeviceRow } from "../../../saga/actions/common/uploadcsv.action";
import { Link } from "react-router-dom";
import Preloader from "../../../components/preloader";
import { imagesArr } from '../../../assets/images';
import moment from "moment";
import DatePicker from "react-datepicker";

class fanEditDeviceData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            eid: "",
            formData: [],
            opencal: false
        };

        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.getId();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.deviceRowReq &&
            nextProps.uploadCsvInfo.deviceRowData
        ) {
            console.log("nextProps from deviceRowData:----", nextProps.uploadCsvInfo.deviceRowData);
            nextProps.uploadCsvInfo.deviceRowReq = false;
            if (nextProps.uploadCsvInfo.deviceRowData.flag) {
                this.commonCall.successMessage(nextProps.uploadCsvInfo.deviceRowData.message);
                nextProps.uploadCsvInfo.deviceRowData = null;
                this.setLoader(false);
                this.commonCall.removeStorage('formData');
                this.props.history.push(`/fan-device/${this.state.eid}`);
            } else {
                nextProps.uploadCsvInfo.deviceRowData = null;
                this.setLoader(false);
            }
        }
    }

    getId = () => {
        let url = new URL(document.location);
        let eid = url.pathname.split("/")[2];
        this.setState({
            eid: eid,
        }, () => {
            this.commonCall.getStorage('formData', getData => {
                if (getData) {
                    console.log("getData:--", getData);
                    this.setState({
                        formData: getData
                    }, () => {
                        this.setLoader(false);
                    })
                }
            })
        });
    };

    back = () => {
        this.commonCall.removeStorage('formData');
        this.props.history.push(`/fan-device/${this.state.eid}`);
    }

    handleChange = (e, field) => {
        if (field === "date") {
            this.state.formData.forEach((item) => {
                if (item.key === field) {
                    Object.assign(item, { value: e });
                }
            });
            this.setState({
                formData: this.state.formData
            })
        } else {
            const { name, value } = e.target;
            this.state.formData.forEach((item) => {
                if (item.key === name) {
                    Object.assign(item, { value: value });
                }
            });
            this.setState({
                formData: this.state.formData
            })
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderForm = () => {
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            this.state.formData.map((item, i) => {
                if (item.key !== "associated_videos" && item.key !== "bcsource_id" && item.key !== "_id") {
                    return (
                        <>
                            <label className="common-lable">{item.label}</label>
                            <div className="form-group mb-0">
                                {
                                    item.key === "date" &&
                                    <div className="bc_relative">
                                        <DatePicker
                                            ref={(c) => (this._calendar = c)}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                            // maxDate={moment().toDate()}
                                            selected={new Date(item.value)}
                                            onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                            placeholderText="Enter Event Date"
                                            onChange={(e) => this.handleChange(e, "date")}
                                        />
                                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                            this.state.opencal = !this.state.opencal;
                                            this._calendar.setOpen(this.state.opencal);
                                        }} />
                                    </div>
                                }
                                {
                                    item.key !== "date" && item.key !== "time" &&
                                    <input type="text" className="form-control" placeholder={`Enter ${item.label}`} name={item.key} value={item.value} onChange={(e) => this.handleChange(e)} />
                                }
                                {
                                    item.key === "time" &&
                                    <input type="text" className="form-control" placeholder={`Enter ${item.label}`} name={item.key} value={item.value} onChange={(e) => this.handleChange(e)} />
                                }
                            </div>
                        </>
                    )
                }
            })
        )
    }

    sendReq = () => {
        let sendData = this.commonCall.buildObject(this.state.formData);
        this.setLoader(true);
        this.props.updateDeviceRow(sendData, "Fan",localStorage.getItem("rosterToken"));
    }

    setLoader = (val) => {
        this.setState({
            loader: val,
        });
    };

    render() {
        const { loader, formData } = this.state;
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                <section className="uploadcsv-uploadcsvdata uploadcsv">
                    <div className="container">
                        <div className="white-bg tablecontent">
                            <div className="tableinfo">
                                <div>
                                    <h3>Edit Row</h3>
                                </div>
                                <div className="rightsection">
                                    <Link className="btn light-green btn-addnewevent" onClick={() => this.back()}>Back</Link>
                                </div>
                            </div>
                            <div className="table-responsive-xl">
                                <section className="before-dashboard">
                                    <div className="white-bg" style={{ padding: '0 25px', boxShadow: 'none' }}>
                                        {formData.length !== 0 && this.renderForm()}
                                        <div className="flex-align" style={{ border: "none", padding: "0" }}>
                                            <Link className="btn light-green" onClick={() => this.sendReq()}>Save</Link>
                                            <Link className="btn gray" onClick={() => this.back()}>Cancel</Link>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}


fanEditDeviceData.propTypes = {
    updateDeviceRow: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default connect(mapStateToProps, { updateDeviceRow })(fanEditDeviceData);

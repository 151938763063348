export const GET_PLAYER_PERSONAL_INFO_REQUEST = 'GET_PLAYER_PERSONAL_INFO_REQUEST';
export const GET_PLAYER_PERSONAL_INFO_SUCCESS = 'GET_PLAYER_PERSONAL_INFO_SUCCESS';
export const GET_PLAYER_PERSONAL_INFO_ERROR   = 'GET_PLAYER_PERSONAL_INFO_ERROR';

export const SET_CALCULATE_DATA_REQUEST = 'SET_CALCULATE_DATA_REQUEST';
export const SET_CALCULATE_DATA_SUCCESS = 'SET_CALCULATE_DATA_SUCCESS';
export const SET_CALCULATE_DATA_ERROR   = 'SET_CALCULATE_DATA_ERROR';

export const GET_CALCULATE_DATA_REQUEST = 'GET_CALCULATE_DATA_REQUEST';
export const GET_CALCULATE_DATA_SUCCESS = 'GET_CALCULATE_DATA_SUCCESS';
export const GET_CALCULATE_DATA_ERROR   = 'GET_CALCULATE_DATA_ERROR';

export const SET_CONNECTION_REQ_REQUEST = 'SET_CONNECTION_REQ_REQUEST';
export const SET_CONNECTION_REQ_SUCCESS = 'SET_CONNECTION_REQ_SUCCESS';
export const SET_CONNECTION_REQ_ERROR   = 'SET_CONNECTION_REQ_ERROR';

export const SAVE_COMMITTED_REQUEST = 'SAVE_COMMITTED_REQUEST';
export const SAVE_COMMITTED_SUCCESS = 'SAVE_COMMITTED_SUCCESS';
export const SAVE_COMMITTED_ERROR   = 'SAVE_COMMITTED_ERROR';
import {
    GET_ACTIVE_SUB_REQUEST,
    GET_ACTIVE_SUB_SUCCESS,
    GET_ACTIVE_SUB_ERROR,

    DELETE_ACTIVE_SUB_REQUEST,
    DELETE_ACTIVE_SUB_SUCCESS,
    DELETE_ACTIVE_SUB_ERROR,

    GET_EXPIRE_SUB_REQUEST,
    GET_EXPIRE_SUB_SUCCESS,
    GET_EXPIRE_SUB_ERROR,

    GET_UPGRADE_REQUEST,
    GET_UPGRADE_SUCCESS,
    GET_UPGRADE_ERROR,

    SAVE_UPGRADE_REQUEST,
    SAVE_UPGRADE_SUCCESS,
    SAVE_UPGRADE_ERROR,

    GET_ROSTER_LIST_COACH_REQUEST,
    GET_ROSTER_LIST_COACH_SUCCESS,
    GET_ROSTER_LIST_COACH_ERROR,

    GET_ROSTER_LIST_PLAYER_REQUEST,
    GET_ROSTER_LIST_PLAYER_SUCCESS,
    GET_ROSTER_LIST_PLAYER_ERROR,
} from '../../constants/common/subscription.const';

const initialState = {
    getActiveReq: false,
    getActiveData: null,
    getActiveError: null,

    getExpireReq: false,
    getExpireData: null,
    getExpireError: null,

    delActiveReq: false,
    delActiveData: null,
    delActiveError: null,

    getUpgradeReq: false,
    getUpgradeData: null,
    getUpgradeError: null,

    saveUpgradeReq: false,
    saveUpgradeData: null,
    saveUpgradeError: null,

    rosterCoachReq: false,
    rosterCoachData: null,
    rosterCoachError: null,

    rosterPlayerReq: false,
    rosterPlayerData: null,
    rosterPlayerError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // get active req    
        case GET_ACTIVE_SUB_REQUEST:
            return {
                ...state,
                getActiveReq: true,
            };
        case GET_ACTIVE_SUB_SUCCESS:
            return {
                ...state,
                getActiveData: action.payload,
            };
        case GET_ACTIVE_SUB_ERROR:
            return {
                ...state,
                getActiveError: action.error,
            };

        // get expire req    
        case GET_EXPIRE_SUB_REQUEST:
            return {
                ...state,
                getExpireReq: true,
            };
        case GET_EXPIRE_SUB_SUCCESS:
            return {
                ...state,
                getExpireData: action.payload,
            };
        case GET_EXPIRE_SUB_ERROR:
            return {
                ...state,
                getExpireError: action.error,
            };

        // delete active req    
        case DELETE_ACTIVE_SUB_REQUEST:
            return {
                ...state,
                delActiveReq: true,
            };
        case DELETE_ACTIVE_SUB_SUCCESS:
            return {
                ...state,
                delActiveData: action.payload,
            };
        case DELETE_ACTIVE_SUB_ERROR:
            return {
                ...state,
                delActiveError: action.error,
            };

        // upgrade list req    
        case GET_UPGRADE_REQUEST:
            return {
                ...state,
                getUpgradeReq: true,
            };
        case GET_UPGRADE_SUCCESS:
            return {
                ...state,
                getUpgradeData: action.payload,
            };
        case GET_UPGRADE_ERROR:
            return {
                ...state,
                getUpgradeError: action.error,
            };

        // upgrade save req    
        case SAVE_UPGRADE_REQUEST:
            return {
                ...state,
                saveUpgradeReq: true,
            };
        case SAVE_UPGRADE_SUCCESS:
            return {
                ...state,
                saveUpgradeData: action.payload,
            };
        case SAVE_UPGRADE_ERROR:
            return {
                ...state,
                saveUpgradeError: action.error,
            };

        // dashboard roster group for coach   
        case GET_ROSTER_LIST_COACH_REQUEST:
            return {
                ...state,
                rosterCoachReq: true,
            };
        case GET_ROSTER_LIST_COACH_SUCCESS:
            return {
                ...state,
                rosterCoachData: action.payload,
            };
        case GET_ROSTER_LIST_COACH_ERROR:
            return {
                ...state,
                rosterCoachError: action.error,
            };

        // dashboard roster group for player   
        case GET_ROSTER_LIST_PLAYER_REQUEST:
            return {
                ...state,
                rosterPlayerReq: true,
            };
        case GET_ROSTER_LIST_PLAYER_SUCCESS:
            return {
                ...state,
                rosterPlayerData: action.payload,
            };
        case GET_ROSTER_LIST_PLAYER_ERROR:
            return {
                ...state,
                rosterPlayerError: action.error,
            };
        default:
            return state;
    }
}
import {
    GET_ANALYZR_PACKAGE_REQUEST,
    GET_ANALYZR_PACKAGE_SUCCESS,
    GET_ANALYZR_PACKAGE_ERROR,

    BUY_ANALYZR_PACKAGE_REQUEST,
    BUY_ANALYZR_PACKAGE_SUCCESS,
    BUY_ANALYZR_PACKAGE_ERROR,
} from '../../constants/landing/analyzr.const';

const initialState = {
    analyzrPackReq: false,
    analyzrPackData: null,
    analyzrPackError: null,

    buyPackReq: false,
    buyPackData: null,
    buyPackError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Pachage req    
        case GET_ANALYZR_PACKAGE_REQUEST:
            return {
                ...state,
                analyzrPackReq: true,
            };
        case GET_ANALYZR_PACKAGE_SUCCESS:
            return {
                ...state,
                analyzrPackData: action.payload,
            };
        case GET_ANALYZR_PACKAGE_ERROR:
            return {
                ...state,
                analyzrPackError: action.error,
            };

        case BUY_ANALYZR_PACKAGE_REQUEST:
            return {
                ...state,
                buyPackReq: true,
            };
        case BUY_ANALYZR_PACKAGE_SUCCESS:
            return {
                ...state,
                buyPackData: action.payload,
            };
        case BUY_ANALYZR_PACKAGE_ERROR:
            return {
                ...state,
                buyPackError: action.error,
            };

        default:
            return state;
    }
}
import {
  FACILITYINFO_SUCCESS,
  FACILITYINFO_ERROR,
} from "../../../../constants/view/coach/profile/facilityInfo.const";

const initialState = {
    facilityInfoData : null,
    facilityInfoError : null,
    facilityLogoData : null,
    facilityLogoError : null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FACILITYINFO_SUCCESS:
            return {
                ...state,
                facilityInfoData: action.payload,
            };
            
        case FACILITYINFO_ERROR:
            return {
                ...state,
                facilityInfoError: action.error,
            };
        default:
            return state;
    }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { trainingList } from '../../../../saga/actions/authantication/signup/coach/training.action';
import Preloader from '../../../../components/preloader';

class training extends Component {
    constructor(props) {
        super(props)
        this.state = {
            trainingArr: {
                // gpy: "",
                facilty: "yes",
                devices: "yes",
                "device-type": [],
                "use-devices": [],
                "device-helpful": "yes",
                "devices-team": "",
                "device-type-other": "",
                "use-devices-other": "",
            },
            dType: [
                "rapsodo", "blast", "flight", "track", "hit", "dk", "yakkertech", "other"
            ],
            useDevice: [
                "Bullpen", "Batting Practice", "Game", "Scrimmage", "Other"
            ],
            loader: false
        }

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorNo = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorDevice = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorUseDevice = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.onRef(this);
        this.setLoader(true);
        this.props.trainingList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.trainingDetails &&
            nextProps.trainingDetails.trainingRes &&
            nextProps.trainingDetails.trainingData
        ) {
            nextProps.trainingDetails.trainingRes = false;
            if (nextProps.trainingDetails.trainingData.hasOwnProperty('_id')) {
                if (nextProps.trainingDetails.trainingData.fieldArr.devices === "yes") {
                    this.setState({
                        trainingArr: {
                            ...nextProps.trainingDetails.trainingData.fieldArr
                        },
                    }, () => {
                        nextProps.trainingDetails.trainingData = null;
                        this.setLoader(false);
                    })
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        trainingArr: {
                            ...prevState.trainingArr,
                            devices: nextProps.trainingDetails.trainingData.fieldArr.devices,
                            facilty: nextProps.trainingDetails.trainingData.fieldArr.facilty,
                            // gpy: nextProps.trainingDetails.trainingData.fieldArr.gpy,
                        }
                    }), () => {
                        nextProps.trainingDetails.trainingData = null;
                        this.setLoader(false);
                    });
                }

            } else {
                this.setLoader(false);
            }
        }
    }

    onHandleChange = (name, value) => {
        if (name === "gpy" ||
            name === "facilty" ||
            name === "devices" ||
            name === "device-helpful" ||
            name === "devices-team"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    [name]: value
                },
            }));
        }
    }

    handleChangeDtype = (e, item) => {
        if (this.state.trainingArr['device-type'].find((d) => d === item)) {
            // subtraction
            let tempArray = this.state.trainingArr['device-type'].filter((d) => d !== item);
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['device-type']: tempArray
                },
            }));
        } else {
            // addition
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['device-type']: [...prevState.trainingArr['device-type'], item]
                },
            }));
        }
    };

    handleChangeUseDevice = (e, item) => {
        if (this.state.trainingArr['use-devices'].find((d) => d === item)) {
            // subtraction
            let tempArray = this.state.trainingArr['use-devices'].filter((d) => d !== item);
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['use-devices']: tempArray
                },
            }));
        } else {
            // addition
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['use-devices']: [...prevState.trainingArr['use-devices'], item]
                },
            }));
        }
    };

    renderDeviceType = () => {
        return (
            this.state.dType &&
            this.state.dType.map((item, i) => (
                <div className="check_box true">
                    <div className="check-box">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id={i}
                                value={item}
                                name={item}
                                checked={
                                    this.state.trainingArr['device-type'].find((d) => d === item)
                                        ? true
                                        : false
                                }
                                onClick={(e) => this.handleChangeDtype(e, item)}
                            />
                            <label htmlFor="dtype">
                                {item === "rapsodo" ?
                                    "Rapsodo" : item === "blast" ?
                                        "Blast Motion" : item === "flight" ?
                                            "FlightScope" : item === "track" ?
                                                "Trackman" : item === "hit" ?
                                                    "HitTrax" : item === "dk" ?
                                                        "Diamond Kinetics" : item === "yakkertech" ?
                                                            "Yakkertech" : item === "other" ?
                                                                "Other" : ""
                                }
                            </label>
                        </div>

                    </div>
                </div>
            ))
        )
    }

    renderUseDevice = () => {
        return (
            this.state.useDevice &&
            this.state.useDevice.map((item, i) => (
                <div className="check_box true">
                    <div className="check-box">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id={i}
                                value={item}
                                name={item}
                                checked={
                                    this.state.trainingArr['use-devices'].find((d) => d === item)
                                        ? true
                                        : false
                                }
                                onClick={(e) => this.handleChangeUseDevice(e, item)}
                            />
                            <label htmlFor="dtype">{item}</label>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    validationUpdate = (cb) => {
        if (this.state.trainingArr.devices === "yes" && ((!!this.state.trainingArr["device-type"].find(e => e === "other") && !this.validatorDevice.allValid()) || (!!this.state.trainingArr["use-devices"].find(e => e === "Other") && !this.validatorUseDevice.allValid()))) {
            this.validatorDevice.showMessages();
            this.validatorUseDevice.showMessages();
            this.validator.showMessages();
            this.forceUpdate();
        } else {
            if (this.state.trainingArr.devices === "yes") {
                if (this.validator.allValid()) {
                    this.state.trainingArr["device-type-other"] = !!this.state.trainingArr["device-type"].find(e => e === "other")  ? this.state.trainingArr["device-type-other"]  : ""
                    this.state.trainingArr["use-devices-other"] = !!this.state.trainingArr["use-devices"].find(e => e === "Other")  ? this.state.trainingArr["use-devices-other"]  : ""
                    cb(this.state.trainingArr);
                } else {
                    this.validator.showMessages();
                    this.forceUpdate();
                }
            } else {
                if (this.validatorNo.allValid()) {
                    let data = {
                        // gpy: this.state.trainingArr.gpy,
                        facilty: this.state.trainingArr.facilty,
                        devices: this.state.trainingArr.devices,
                    }
                    cb(data);
                } else {
                    this.validator.showMessages();
                    this.forceUpdate();
                }
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { profileData } = this.props
        const { trainingArr, loader } = this.state
        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name">
                            <h2>
                                Hi {profileData.fname + " " + profileData.lname},
                            </h2>
                            <p>
                                We have a few QUESTIONS for you.
                            </p>
                        </div>
                        {/* <label className="common-lable">How many games a year do you coach?</label>
                        <div className="check_input row">
                            <div className="check_box">
                                <select name="gpy" value={trainingArr.gpy} onChange={(e) => this.onHandleChange('gpy', e.target.value)} className="custom-select">
                                    <option value="" selected disabled>Select games you play</option>
                                    <option value="5" selected={trainingArr.gpy === "5" ? "selected" : ""}>5</option>
                                    <option value="10" selected={trainingArr.gpy === "10" ? "selected" : ""}>10</option>
                                    <option value="15" selected={trainingArr.gpy === "15" ? "selected" : ""}>15</option>
                                    <option value="20" selected={trainingArr.gpy === "20" ? "selected" : ""}>20</option>
                                    <option value="25" selected={trainingArr.gpy === "25" ? "selected" : ""}>25</option>
                                    <option value="30" selected={trainingArr.gpy === "30" ? "selected" : ""}>30</option>
                                    <option value="35" selected={trainingArr.gpy === "35" ? "selected" : ""}>35</option>
                                    <option value="40" selected={trainingArr.gpy === "40" ? "selected" : ""}>40</option>
                                    <option value="45" selected={trainingArr.gpy === "45" ? "selected" : ""}>45</option>
                                    <option value="50" selected={trainingArr.gpy === "50" ? "selected" : ""}>50</option>
                                    <option value="55" selected={trainingArr.gpy === "55" ? "selected" : ""}>55</option>
                                    <option value="60" selected={trainingArr.gpy === "60" ? "selected" : ""}>60</option>
                                    <option value="65" selected={trainingArr.gpy === "65" ? "selected" : ""}>65</option>
                                    <option value="70" selected={trainingArr.gpy === "70" ? "selected" : ""}>70</option>
                                    <option value="75" selected={trainingArr.gpy === "75" ? "selected" : ""}>75</option>
                                    <option value="80" selected={trainingArr.gpy === "80" ? "selected" : ""}>80</option>
                                    <option value="85" selected={trainingArr.gpy === "85" ? "selected" : ""}>85</option>
                                    <option value="90" selected={trainingArr.gpy === "90" ? "selected" : ""}>90</option>
                                    <option value="95" selected={trainingArr.gpy === "95" ? "selected" : ""}>95</option>
                                    <option value="100" selected={trainingArr.gpy === "100" ? "selected" : ""}>100</option>
                                </select>
                                <span className="validMsg">{this.validator.message('game years', trainingArr.gpy, 'required')}</span>
                                <span className="validMsg">{this.validatorNo.message('game years', trainingArr.gpy, 'required')}</span>

                            </div>
                        </div> */}

                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">Do you coach at a facility?</label>
                                <div className="check-box">
                                    <div className="checkbox" >
                                        <input type="radio" id="Male1" value="yes" name="facilty" onChange={(e) => this.onHandleChange('facilty', e.target.value)} checked={trainingArr.facilty === "yes" ? true : false} />
                                        <label for="Male1">Yes </label>
                                    </div>
                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Female1" value="no" name="facilty" onChange={(e) => this.onHandleChange('facilty', e.target.value)} checked={trainingArr.facilty === "no" ? true : false} />
                                        <label for="Female1">No </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('facility', trainingArr.facilty, 'required')}</span>
                            </div>
                        </div>

                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">Does your team train with devices?</label>
                                <div className="check-box">
                                    <div className="checkbox" >
                                        <input type="radio" id="Male2" value="yes" name="devices" onChange={(e) => this.onHandleChange('devices', e.target.value)} checked={trainingArr.devices === "yes" ? true : false} />
                                        <label for="Male2">Yes </label>
                                    </div>

                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Female2" value="no" name="devices" onChange={(e) => this.onHandleChange('devices', e.target.value)} checked={trainingArr.devices === "no" ? true : false} />
                                        <label for="Female2">No </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('devices', trainingArr.devices, 'required')}</span>
                            </div>
                        </div>

                        {
                            trainingArr.devices === "yes" &&

                            <>
                                <div className="check_input row">
                                    <label className="common-lable">Which training device do you use?</label>

                                    {this.renderDeviceType()}
                                    {/* <span className="validMsg">{this.validator.message('training device', trainingArr["device-type"], 'required')}</span> */}

                                </div>

                                {!!trainingArr["device-type"].find(e => e === "other") &&
                                    <><label className="common-lable">
                                        If other please explain
                                    </label>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter text here" value={trainingArr["device-type-other"]} onChange={(e) => this.setState((prevState) => ({ ...prevState, trainingArr: { ...prevState.trainingArr, "device-type-other": e.target.value } }))} />
                                            <span className="validMsg">{this.validatorDevice.message('', trainingArr["device-type-other"], 'required', { className: 'text-danger' })}</span>
                                        </div>
                                    </>}


                                <div className="check_input row">
                                    <label className="common-lable">How do you use these devices?</label>

                                    {this.renderUseDevice()}
                                    {/* <span className="validMsg">{this.validator.message('use device', trainingArr["use-devices"], 'required')}</span> */}
                                </div>

                                {!!trainingArr["use-devices"].find(e => e === "Other") &&
                                    <><label className="common-lable">
                                        If other please explain
                                    </label>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter text here" value={trainingArr["use-devices-other"]} onChange={(e) => this.setState((prevState) => ({ ...prevState, trainingArr: { ...prevState.trainingArr, "use-devices-other": e.target.value } }))} />
                                            <span className="validMsg">{this.validatorUseDevice.message('', trainingArr["use-devices-other"], 'required', { className: 'text-danger' })}</span>
                                        </div>
                                    </>}


                                <div className="check_input row">
                                    <div className="check_box">
                                        <label className="common-lable">Do you find the device data helpful?</label>
                                        <div className="check-box">
                                            <div className="checkbox" >
                                                <input type="radio" id="Male3" value="yes" name="device-helpful" onChange={(e) => this.onHandleChange('device-helpful', e.target.value)} checked={trainingArr["device-helpful"] === "yes" ? true : false} />
                                                <label for="Male3">Yes </label>
                                            </div>

                                            <div className="checkbox mr-0">
                                                <input type="radio" id="Female3" value="no" name="device-helpful" onChange={(e) => this.onHandleChange('device-helpful', e.target.value)} checked={trainingArr["device-helpful"] === "no" ? true : false} />
                                                <label for="Female3">No </label>
                                            </div>

                                        </div>
                                        <span className="validMsg">{this.validator.message('device helpful', trainingArr["device-helpful"], 'required')}</span>
                                    </div>
                                </div>
                                <label className="common-lable">How many times a week do you or your team use a device?</label>
                                <div className="check_input row">
                                    <div className="check_box">
                                        <select name="devices-team" value={trainingArr["devices-team"]} onChange={(e) => this.onHandleChange('devices-team', e.target.value)} className="custom-select">
                                            <option value="" selected disabled>Select session</option>
                                            <option value="1" selected={trainingArr["devices-team"] === "1" ? "selected" : ""}>Once a week</option>
                                            <option value="2" selected={trainingArr["devices-team"] === "2" ? "selected" : ""}>Multiple times a week</option>
                                            <option value="3" selected={trainingArr["devices-team"] === "3" ? "selected" : ""}>Daily</option>
                                            {/* <option value="4" selected={trainingArr["devices-team"] === "4" ? "selected" : ""}>Many times a week</option> */}
                                        </select>
                                        <span className="validMsg">{this.validator.message('device team', trainingArr["devices-team"], 'required')}</span>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        )
    }
}

training.propTypes = {
    trainingList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    trainingDetails: state.trainingDetails,
});

export default connect(mapStateToProps, { trainingList })(training);

import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ReactPaginate from "react-paginate";

import { imagesArr } from '../../../assets/images';
import { getAllPastChampList, getStatesForPastEvent, getTornamentListFilter, getTournamentList } from '../../../saga/actions/common/events.action';
import commonCalls from '../../../businesslogic/commonCalls';
import ProudSponsors from '../components/common/proudSponsors';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import Preloader from '../../../components/preloader';

import Shop from "../../../views/company/components/common/shop";

const initialState = {
    stateList: [],
    tournamentList: [],
    loaderCount: 0,
    eventList: [],
    stateReq: {
        isPresent: 3,
        eventType: 1,
        companies: "",
        eventTeamType: 1,
    },
    req: {
        companyId: "",
        startDate: "",
        endDate: "",
        Event: "",
        state: "",
        Division: "",
        page: 1
    },
    // pagination variable diclaration
    offset: 0,
    perPage: 6,
    currentPage: "",
    pageCount: "",
}

const reducer = (state, payload) => ({ ...state, ...payload })

function PastChampionsInfo(props) {
    const dispatch = useDispatch();
    const commonCall = new commonCalls();
    const [event, setEvent] = useReducer(reducer, initialState);
    const { statePastEvent: _SL, tournamentFilterList: _TL, allPastChampList: _PEL } = useSelector(state => state.eventsInfo);
    const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    useEffect(() => {
        increment();
      
        event.req.companyId = localStorage.getItem('cid');
        event.stateReq.companies = localStorage.getItem('cid');
        setEvent(event)
        getStatesForPastEvent(event.stateReq)(dispatch);
        getTornamentListFilter({ companyId: localStorage.getItem('cid')})(dispatch);
    }, [])

    useEffect(() => {
        if (_SL.length) {
            setEvent({
                ...event,
                stateList: _SL
            })
        } else {
            commonCall.errorMessage(_SL.message);
        }
    }, [_SL])

    useEffect(() => {
        if (_TL.length) {
            setEvent({
                ...event,
                tournamentList: _TL,
                loaderCount: event.loaderCount - 1 
            })
        } else {
            commonCall.errorMessage(_TL.message);
        }
    }, [_TL])

    useEffect(() => {
        if (_PEL.flag) {
            setEvent({
                ...event,
                eventList: _PEL.result.docs,
                pageCount: _PEL.result.pages,
                loaderCount: event.loaderCount - 1 
            })
        }
    }, [_PEL])

    useEffect(() => {
        increment();
        getAllPastChampList(event.req)(dispatch)
    }, [event.req])


    const getPastEventList = (req) =>{
        getAllPastChampList(req)(dispatch)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const state = {
            ...event,
            req: {
                ...event.req,
                page: selectedPage + 1,
            },
            currentPage: selectedPage,
            loaderCount : event.loaderCount + 1
        }
        setEvent(state);
        getAllPastChampList(state.req)(dispatch);
    };

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setEvent({
            ...event,
            req:{
                ...event.req,
                [name] : value,
                page: 1

            },
            currentPage: ""
        })
    }

    const handleDate = (date,type) =>{
        setEvent({
            ...event,
            req: {
                ...event.req,
                [type]: date
            },
        })
    }

    const   renderList = () => {
        return (
            event.eventList.length !== 0 ?
                event.eventList.map((list, i) => {
                    return (
                        <tr>
                            <td className='p-0'>
                                <ul>
                                    {
                                        Object.entries(list.Year).map((key, value) => {
                                            if (key[0] && key[1]) {
                                                return (
                                                    <li>{key[0]}  |  {key[1]}</li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </td>
                            <td>{list.Event}</td>
                            <td>{list.location || "-"}</td>
                            <td>{list.startDate ? moment(list.startDate).format("MM/DD/YY") : "-"}</td>
                            <td>{list.Division == '19U' ? '18U+' : list.Division}</td>
                           
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan="5"><p className="text-center m-0 past-champions-nodeta">No data found</p></td>
                </tr>
        )
    }

    const increment = () => {
        setEvent({
            ...event,
            loaderCount: event.loaderCount + 1
        })
    }

    const decrement = () => {
        setEvent({
            ...event,
            loaderCount: event.loaderCount - 1
        })
    }


    return (
        <>
        <Header />
        <div>
                <div className="event-main">

                    {/* Past Champions */}

                    <div className="past-champions pwb-pastCham">
                        <div className="container">
                            <h1 className="section_title">Past Champions</h1>
                            <form>
                                <div className="filter-champion">

                                    <div className="form-group ">
                                        <select className="custom-select" name="Division" value={event.req.Division} onChange={handleChange} >
                                            <option value="" >Age Group</option>
                                            <option value="8U">8U</option>
                                            <option value="9U">9U</option>
                                            <option value="10U">10U</option>
                                            <option value="11U">11U</option>
                                            <option value="12U">12U</option>
                                            <option value="13U">13U</option>
                                            <option value="14U">14U</option>
                                            <option value="15U">15U</option>
                                            <option value="16U">16U</option>
                                            <option value="17U">17U</option>
                                            <option value="18U">18U</option>
                                            <option value="19U">18U+</option>
                                        </select>
                                    </div>


                                    {/* <div className="form-group ">
                                        <select className="custom-select" name="state" value={event.req.state} onChange={handleChange}>
                                            <option value="">State</option>
                                            {
                                                event.stateList &&
                                                event.stateList.map((item) => {
                                                    return (
                                                        <option value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div> */}

                                    <div className="form-group ">
                                        <select className="custom-select" name="Event" value={event.req.Event} onChange={handleChange}>
                                            <option value="">Tournament</option>
                                            {
                                                event.tournamentList &&
                                                event.tournamentList.map((item) => {
                                                    return (
                                                        <option value={item.title}>{item.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group ">
                                        <div className="bc_relative">
                                            <DatePicker
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                            {months.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                    </div>
                                                )}
                                                selected={event.req.startDate}
                                                maxDate={moment().subtract(1, 'days').toDate()}
                                                onChange={(e) => handleDate(e, "startDate")}
                                                placeholderText="Start date"
                                            />
                                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                        </div>
                                    </div>


                                    <div className="form-group ml-0">
                                        <div className="bc_relative">
                                            <DatePicker
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                            {months.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                    </div>
                                                )}
                                                selected={event.req.endDate}
                                                minDate={moment(event.req.startDate).subtract(-1, 'days').toDate()}
                                                onChange={(e) => handleDate(e, "endDate")}
                                                placeholderText="End date"
                                            />
                                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="past-champions-table">
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Winner</th>
                                                <th scope="col">Tournament</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Age Group</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderList()}
                                        </tbody>
                                    </table>
                                    <div className="pagenation-search" style={{ justifyContent: 'end' }}>
                                            <ReactPaginate
                                                previousLabel={"Prev"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={event.pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={1}
                                                onPageChange={handlePageClick}
                                                containerClassName={"champion-pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                forcePage={event.currentPage}
                                            />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
        </div>
            {/* Shop */}

            <div className="upcomingevent_section">
            <Shop cType="pwb" />
        </div>

        {/* Proud Sponsors */}

        <ProudSponsors increment={increment} decrement={decrement} />


        <Footer />
        <Preloader flag={event.loaderCount > 0 ? true : false} />
        </>
    );
}

export default PastChampionsInfo;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getEventRule } from '../../../../saga/actions/common/eventDetails.action';
import { GET_EVENT_RULE_SUCCESS } from '../../../../saga/constants/common/eventDetails.const';

function EventRuleDetails(props) {
    const dispatch = useDispatch();
    const [draft, setDraft] = useState([{
        secName: "",
        description: "",
    }],);
    const colorCode = {
        'dc': '#96CF04',
        'pwb': '#96CF04',
        'pws': '#76CDC0',
        'gob': '#222160',
    }
    const { getEventRuleData: _RULE } = useSelector((state) => state.eventDetails);

    useEffect(() => {
        props.increment();
        getEventRule(localStorage.getItem('cid'))(dispatch);
    }, [])

    useEffect(() => {
        if (_RULE) {
            if (_RULE.companyRules != 0) {
                setDraft(_RULE.companyRules)
            } else {
                setDraft([])
            }
            props.decrement();
            dispatch({
                type: GET_EVENT_RULE_SUCCESS,
                payload: null
            })
        }
    }, [_RULE])

    return (
        <section className="tearm-policy">
            <div className="container">
                {draft.length != 0 ?
                    draft.map((item, index) => {
                        return (
                             <div class="tabs tabs_box">
                                <div class="tab" style={{ background: colorCode[localStorage.getItem('company')] }}>
                                    <input type="checkbox" id={`chck${index}`} />
                                    <label class="tab-label" for={`chck${index}`}>
                                        {item.secName}
                                    </label>
                                    <div
                                        class="tab-content ruleDraft"
                                        dangerouslySetInnerHTML={{__html:  item?.description}}
                                    ></div>
                                </div>
                            </div>
                        )
                    }) :
                    <div className='ruleDraft' dangerouslySetInnerHTML={{ __html: "<p style=\"text-align:center;\">No Rule</p>" }}></div>
                }
                {/* <div className='ruleDraft' dangerouslySetInnerHTML={{
                    __html: draft ? draft : "<p style=\"text-align:center;\">No Rule</p>"
                }}>
                </div> */}
            </div>
        </section>
    )
}

export default EventRuleDetails

import axios from 'axios';
import { Auth_Key, Auth_Coach_Key, Auth_Player_Key, Auth_Fan_Key, API_Url, Authorization, checkIsValidToken, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidFanToken, checkIsValidCommiToken, Auth_Commi_Key } from '../../helpers/authToken';
import {

    NOTIFICATION_LOGIN_REQUEST,
    NOTIFICATION_LOGIN_SUCCESS,
    NOTIFICATION_LOGIN_ERROR,

} from '../../constants/authantication/login.const';
import histrory from '../../../History';

export const tokenForNotification = (data, userType) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : (userType === "Fan" || userType === "Parent") ? checkIsValidFanToken(histrory) : userType === "BC Commissioner" ? checkIsValidCommiToken(histrory)  : checkIsValidToken(histrory);
    dispatch({ type: NOTIFICATION_LOGIN_REQUEST })
    axios({
        method: "POST",
        url: `${API_Url}user/deviceToken`,
        data: data,
        headers: {
            "x-auth-token": (userType === "Coach") ? Auth_Coach_Key : userType === "Player" ? Auth_Player_Key : userType === "BC Commissioner" ? Auth_Commi_Key : Auth_Fan_Key,
            'Accept': "application/json",
        },
    }).then((res) => {
        dispatch({
            type: NOTIFICATION_LOGIN_SUCCESS,
            payload: res.data.result,
        });
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            dispatch({
                type: NOTIFICATION_LOGIN_ERROR,
                error: error.response.data.message,
            });
        }
    });
};

export const loginData = (data, type) => dispatch => {
    dispatch({ type: `${type}_REQUEST` })
    axios({
        method: 'POST',
        url: `${API_Url}bc/user/login`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(login => {
        dispatch({
            type: `${type}_SUCCESS`,
            payload: login.data
        })
        localStorage.setItem("loginToken",login.data.result.token)
        localStorage.setItem("role",login.data.result.role)
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: `${type}_ERROR`, error: error.response.data.message })
        }
    });
};

export const getUserCred = (data, type) => dispatch => {
    dispatch({ type: `${type}_CRED_REQUEST` })
    axios({
        method: 'POST',
        url: `${API_Url}wallet/userData`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(cred => {
        dispatch({
            type: `${type}_CRED_SUCCESS`,
            payload: cred.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: `${type}_CRED_ERROR`, error: error.response.data.message })
        }
    });
};


import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { imagesArr } from "../../assets/images";
import commonCalls from "../../businesslogic/commonCalls";
import Preloader from '../../components/preloader';
import { tokenForNotification } from "../../saga/actions/authantication/login.action";
import Cookies from 'js-cookie';
import { isSafari, isMobile } from 'react-device-detect';
import firebaseConnection from "../../businesslogic/firebaseConnection";
import { signInUserWithClaim } from "../../saga/actions/common/accountCreation.action";
import queryString from "query-string";

const initialState = {
  loginReq: {
    username: "",
    password: "",
    rosterId: "",
    userType: ""
  },
  ptype: "password",
  loader: false
}

const reducer = (state, payload) => ({ ...state, ...payload })

function LoginUser({ flag, close, state, oldUserAuthenticationHandler }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const commonCall = new commonCalls();
  const firebaseApi = new firebaseConnection();

  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const location = useLocation();

  const [login, setLogin] = useReducer(reducer, initialState);
  const { loginData: _LOGIN, loginError: _LOGINERROR } = useSelector((state) => state.loginData);

  useEffect(() => {
    setLogin({
      ...login,
      loginReq: {
        ...login.loginReq,
        rosterId: state.selectedRow._id,
        userType: state.claimForm == 1 ? 1 : 2
      }
    })
    firebaseApi.firebaseConnect();
  }, [])


  useEffect(() => {
    if (_LOGIN) {
      if (_LOGIN.flag) {
        if (Object.keys(_LOGIN.result)?.length !== 0) {

          localStorage.setItem("webglToken", _LOGIN.result.token);
          Cookies.set('bcSession', _LOGIN.result.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

          let setToken = _LOGIN.result.role === "Coach" ? "coachToken" :
            _LOGIN.result.role === "Player" ? "playerToken" :
              (_LOGIN.result.role === "Fan" || _LOGIN.result.role === "Parent") ? "fanToken" :
                _LOGIN.result.role === "BC Commissioner" ? "commiToken" : "authToken";
          let role = _LOGIN.result.role;
          commonCall.setAuthToken(setToken, _LOGIN.result.token, flag => {
            if (flag) {
              setLogin({
                ...login,
                loader: false
              })
              if (_LOGIN.result.role === "Coach" || _LOGIN.result.role === "Player") {
                history.push(`/verifyCMSAuth/${role}/${_LOGIN.result.token}?redirectTo=${location.pathname}`);
              }
              else if (_LOGIN.result.role === "Fan" || _LOGIN.result.role === "Parent") {
                if (_LOGIN.result.role === "Fan") {
                  localStorage.removeItem("company");
                  localStorage.removeItem("cid");
                  localStorage.removeItem("companyColor");
                  localStorage.removeItem("isAllCompany");
                  localStorage.removeItem("isCollegeShowcase");
                  let defaultColor = [
                    {
                      variable: "--green-color",
                      colorCode: "#7CBD42"
                    },
                    {
                      variable: "--green-color-light",
                      colorCode: "#96CF04"
                    }
                  ]
                  setThemeColor(defaultColor);
                }

                localStorage.removeItem("rolPay");
                localStorage.removeItem("dcStats");
                localStorage.removeItem("payPlans");
                localStorage.removeItem("analyzr");
                // history.push('/fan-dashboard');
                window.location.reload();

              } else if (_LOGIN.result.role === "BC Commissioner") {
                // history.push('/commi-dashboard');
                window.location.reload();
                localStorage.removeItem("company");
                localStorage.removeItem("cid");
                localStorage.removeItem("companyColor");
                localStorage.removeItem("isAllCompany");
                localStorage.removeItem("isCollegeShowcase");
                let defaultColor = [
                  {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                  },
                  {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                  }
                ]
                setThemeColor(defaultColor);
              } else {
                history.push('/signup3');
              }
            }
          });
          if (!isSafari && !isMobile) {
            role = role === "" ? "authToken" : role;
            window.messaging.getToken().then((currentToken) => {
              if (currentToken) {
                const data = {
                  appVersion: 1,
                  deviceVersion: "13.3.1",
                  deviceId: "3295D8F5-2435-4BD7-B9AB-BE88CBE302BB",
                  token: currentToken,
                  deviceType: 3,
                }
                tokenForNotification(data, role)(dispatch);
                localStorage.setItem("notificationToken", currentToken);
              } else {
                // Show permission request.
                console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
          }

          let url = new URL(document.location);
          let self = this;
          if (url.hostname !== "www.diamondconnect.com" && url.hostname !== "diamondconnect.com") {
            if ('safari' in window && 'pushNotification' in window.safari) {
              console.log("------------window.safari.pushNotification.permission-----------");
              var permissionData = window.safari.pushNotification.permission(process.env.REACT_APP_APNS_WEB_PUSH_ID);
              console.log("permissionData:---", permissionData);
              self.checkRemotePermission(permissionData, role);
            }
          }
        } else {
          oldUserAuthenticationHandler();
        }
        dispatch({ type: `LOGIN_SUCCESS`, payload: null })
      } else {
        setLogin({
          ...login,
          loader: false
        })
        commonCall.errorMessage(_LOGIN.message);
        dispatch({ type: `LOGIN_SUCCESS`, payload: null })
      }
    }

    if (_LOGINERROR) {
      setLogin({
        ...login,
        loader: false
      })
      commonCall.errorMessage(_LOGINERROR);
      dispatch({ type: `LOGIN_ERROR`, error: null })
    }

  }, [_LOGIN, _LOGINERROR])

  const setThemeColor = (colArr) => {
    localStorage.setItem("companyColor", JSON.stringify(colArr));
    for (let i = 0; i < colArr.length; i++) {
      const element = colArr[i];
      document.body.style.setProperty(element.variable, element.colorCode);
    }
  }

  const onlyNumber = event => {
    const keycode = event.which;
    if (keycode === 13) {
    } else if (
      !(
        event.shiftKey === false &&
        (keycode === 8 ||
          keycode === 37 ||
          keycode === 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    )
      event.preventDefault();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setLogin({
      ...login,
      loginReq: {
        ...login.loginReq,
        [name]: value
      }
    })
  }

  const onSave = (e) => {
    e.preventDefault();

    const sigInAllow = !localStorage.getItem("webglToken");
    const payload = { ...login.loginReq, sigInAllow };

    if (validator.current.allValid()) {
      setLogin({
        ...login,
        loader: true
      })
      signInUserWithClaim(payload, 'LOGIN')(dispatch)
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  }

  return (
    <>
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>Sign In</h3>
              <div className="modal_close" onClick={close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="team-body-inner">
                <div class="form-group  mb-0">
                  <label class="common-lable">Email</label>
                  <input type="text" class="form-control" id="usr" name="username" value={login.loginReq.username} placeholder="Enter email" onChange={handleChange} />
                  <span className="validMsg">{validator.current.message("Email", login.loginReq.username, "required", { className: "text-danger" })}</span>
                </div>

                <div class="form-group mb-0">
                  <label class="common-lable">Password</label>
                  <div className="svg_icon">
                    <input type={login.ptype} class="form-control" id="usr" name="password" value={login.loginReq.password} placeholder="Enter password" onChange={handleChange} />
                    {login.ptype == "password" ? (
                      <img src={imagesArr.eye} onClick={() => setLogin({ ...login, ptype: "text" })} alt="eye" />
                    ) : (
                      <img src={imagesArr.eyeopen} onClick={() => setLogin({ ...login, ptype: "password" })} alt="eye" />
                    )}
                  </div>
                  <span className="validMsg">{validator.current.message("password", login.loginReq.password, "required", { className: "text-danger" })}</span>
                </div>

              </div>
              <div className="flex-align next-button">
                <button className="btn light-green" onClick={onSave}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Preloader flag={login.loader} />

    </>
  );
}
export default LoginUser;


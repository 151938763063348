import {
    GET_PLAYER_BASIC_REQUEST,
    GET_PLAYER_BASIC_SUCCESS,
    GET_PLAYER_BASIC_ERROR,

    GET_PLAYER_INVITATION_REQUEST,
    GET_PLAYER_INVITATION_SUCCESS,
    GET_PLAYER_INVITATION_ERROR,

    SAVE_PLAYER_BASIC_REQUEST,
    SAVE_PLAYER_BASIC_SUCCESS,
    SAVE_PLAYER_BASIC_ERROR,

    SAVE_PLAYER_ACCEPT_REQUEST,
    SAVE_PLAYER_ACCEPT_SUCCESS,
    SAVE_PLAYER_ACCEPT_ERROR,
} from '../../../../constants/authantication/signup/player/basic.const';

const initialState = {
    playerInviteRes: false,
    playerInviteData: null,
    playerInviteDataError: null,

    playerBasicRes: false,
    playerBasicData: null,
    playerBasicDataError: null,

    savePlayerRes: false,
    savePlayerData: null,
    savePlayerDataError: null,

    saveAcceptRes: false,
    saveAcceptData: null,
    saveAcceptDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Basic Player Invite
        case GET_PLAYER_INVITATION_REQUEST:
            return {
                ...state,
                playerInviteRes: true
            };
        case GET_PLAYER_INVITATION_SUCCESS:
            return {
                ...state,
                playerInviteData: action.payload,
            };
        case GET_PLAYER_INVITATION_ERROR:
            return {
                ...state,
                playerInviteDataError: action.error,
            };

        // Get Basic Data
        case GET_PLAYER_BASIC_REQUEST:
            return {
                ...state,
                playerBasicRes: true
            };
        case GET_PLAYER_BASIC_SUCCESS:
            return {
                ...state,
                playerBasicData: action.payload,
            };
        case GET_PLAYER_BASIC_ERROR:
            return {
                ...state,
                playerBasicDataError: action.error,
            };

        // save player
        case SAVE_PLAYER_BASIC_REQUEST:
            return {
                ...state,
                savePlayerRes: true
            };
        case SAVE_PLAYER_BASIC_SUCCESS:
            return {
                ...state,
                savePlayerData: action.payload,
            };
        case SAVE_PLAYER_BASIC_ERROR:
            return {
                ...state,
                savePlayerDataError: action.error,
            };

        // save accept
        case  SAVE_PLAYER_ACCEPT_REQUEST:
            return {
                ...state,
                saveAcceptRes: true
            };
        case  SAVE_PLAYER_ACCEPT_SUCCESS:
            return {
                ...state,
                saveAcceptData: action.payload,
            };
        case  SAVE_PLAYER_ACCEPT_ERROR:
            return {
                ...state,
                saveAcceptDataError: action.error,
            };

        default:
            return state;
    }
}
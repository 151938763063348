import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../../containers/footer'
import Header from '../../../containers/header'
import Preloader from '../../../components/preloader';
import { eventContactUs } from '../../../saga/actions/common/contactUs.action';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { EVENT_CONTACT_US_SUCCESS } from '../../../saga/constants/common/contactUs.const';

function ContactUs() {
    const dispatch = useDispatch();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [loader, setLoader] = useState(false);
    const [contact, setContact] = useState({
        fullname: "",
        email: "",
        message: ""
    })
    const { eventContactUsData: _SAVE } = useSelector((state) => state.contactUsForm);

    useEffect(() => {
        if (_SAVE) {
            if (_SAVE.flag) {
                toast.success(_SAVE.message, { position: toast.POSITION.TOP_RIGHT });
                setLoader(false);
            } else {
                toast.error(_SAVE.message, { position: toast.POSITION.TOP_RIGHT });
                setLoader(false);
            }
            dispatch({
                type: EVENT_CONTACT_US_SUCCESS,
                payload: null
            })
        }
    }, [_SAVE])


    const handleChange = (e) =>{
        const { name, value } = e.target;
        setContact({
            ...contact,
            [name]: value
        })
    }

    const onSave = () => {
        if (validator.current.allValid()) {
            setLoader(true);
            eventContactUs(contact)(dispatch);
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }

    
    return (
        <div>
            <>
                <Header />
                <div className="event-main">
                    <div className="contactus_section">
                        <div className="container">
                            <h1 class="section_title">Contact Us</h1>
                            <p className="text-center pt-15 pb-15">Got a question? We'd love to hear from you. Send us a message and we'll respond as soon as possible.</p>
                            <form className="text-center">
                                <div className="contactus_form">
                                    <div className="row">
                                        <div className="w-50">
                                        <label className="common-lable">Enter Name<span> *</span></label>
                                            <div className="form-group mb-0">
                                                <input name="fullname" className="form-control" placeholder="Enter name" onChange={handleChange} />
                                            </div>
                                            <span className="validMsg">{validator.current.message("full name", contact.fullname, "required")}</span>
                                        </div>
                                        <div className="w-50">
                                        <label className="common-lable">Enter Email<span> *</span></label>
                                            <div className="form-group mb-0">
                                                <input name="email" className="form-control" placeholder="Enter email address" onChange={handleChange} />
                                            </div>
                                            <span className="validMsg">{validator.current.message("email", contact.email, "required|email")}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="w-100">
                                        <label className="common-lable">Message</label>
                                            <div className="form-group mb-0">
                                                <textarea name="message" className="form-control" rows="8" placeholder="Write here....." onChange={handleChange}></textarea>
                                            </div>
                                            <span className="validMsg">{validator.current.message("message", contact.message, "required")}</span>
                                        </div>
                                    </div>
                                </div>
                                    <button type="button" className="btn mt-40" onClick={onSave}>Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </>
        </div>
    )
}

export default ContactUs;

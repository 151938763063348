import React, { Component } from 'react';
import { imagesArr } from '../../../../assets/images';
import { Link, withRouter } from "react-router-dom";
import moment from 'moment';
import { mainBoardFilter } from '../../../../businesslogic/content';
import PwLock from "../../../company/components/common/pwLock";
import Purchase from '../../../popup/purchaseProspectwire';

class dataTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchase: false,
        }
    }

    returnValues = (item) => {
        return (
            this.props.sendReq.field === "10YardDash" ?
                item["10 Yard"] ? item["10 Yard"] + " seconds" : "" : this.props.sendReq.field === "60YardDash" ?
                    item["60 Yard"] ? item["60 Yard"] + " seconds" : "" : this.props.sendReq.field === "Max Velocity" ?
                        item["Max Velocity"] ? item["Max Velocity"] + " seconds" : "" : this.props.sendReq.field === "Avg Velocity" ?
                            item["Avg Velocity"] ? item["Avg Velocity"] + " seconds" : "" : this.props.sendReq.field === "Max Spin Rate" ?
                                item["Max Spin Rate"] ? item["Max Spin Rate"] + " seconds" : "" : this.props.sendReq.field === "Avg Spin Rate" ?
                                    item["Avg Spin Rate"] ? item["Avg Spin Rate"] + " seconds" : "" : this.props.sendReq.field === "Spin Efficiency" ?
                                        item["Spin Efficiency"] ? item["Spin Efficiency"] + " seconds" : "" : this.props.sendReq.field === "Avg Tilt" ?
                                            item["Avg Tilt"] ? item["Avg Tilt"] + " seconds" : "" : this.props.sendReq.field === "Max Exit Velocity" ?
                                                item["Max Exit Velocity"] ? item["Max Exit Velocity"] + " seconds" : "" : this.props.sendReq.field === "Avg Exit Velocity" ?
                                                    item["Avg Exit Velocity"] ? item["Avg Exit Velocity"] + " seconds" : "" : this.props.sendReq.field === "Avg Launch Angle" ?
                                                        item["Avg Launch Angle"] ? item["Avg Launch Angle"] + " seconds" : "" : this.props.sendReq.field === "Max Distance" ?
                                                            item["Max Distance"] ? item["Max Distance"] + " seconds" : "" : this.props.sendReq.field === "Bat Speed" ?
                                                                item["Bat Speed"] ? item["Bat Speed"] + " seconds" : "" : this.props.sendReq.field === "Attack Angle" ?
                                                                    item["Attack Angle"] ? item["Attack Angle"] + " seconds" : "" : this.props.sendReq.field === "ERA" ?
                                                                        item["ERA"] ? item["ERA"] + " seconds" : "" : this.props.sendReq.field === "K" ?
                                                                            item["K"] ? item["K"] + " seconds" : "" : this.props.sendReq.field === "WHIP" ?
                                                                                item["WHIP"] ? item["WHIP"] + " seconds" : "" : this.props.sendReq.field === "Saves" ?
                                                                                    item["Saves"] ? item["Saves"] + " seconds" : "" : this.props.sendReq.field === "R" ?
                                                                                        item["R"] ? item["R"] + " seconds" : "" : this.props.sendReq.field === "RBI" ?
                                                                                            item["RBI"] ? item["RBI"] + " seconds" : "" : this.props.sendReq.field === "SB" ?
                                                                                                item["SB"] ? item["SB"] + " seconds" : "" : this.props.sendReq.field === "OBP" ?
                                                                                                    item["OBP"] ? item["OBP"] + " seconds" : "" : this.props.sendReq.field === "HR" ?
                                                                                                        item["HR"] ? item["HR"] + " seconds" : "" : this.props.sendReq.field === "1B" ?
                                                                                                            item["1B"] ? item["1B"] + " seconds" : "" : this.props.sendReq.field === "2B" ?
                                                                                                                item["2B"] ? item["2B"] + " seconds" : "" : this.props.sendReq.field === "BA" ?
                                                                                                                    item["BA"] ? item["BA"] + " seconds" : "" : this.props.sendReq.field === "5-10-5" ?
                                                                                                                        item["5-10-5"] ? item["5-10-5"] + " seconds" : "" : this.props.sendReq.field === "broadJump" ?
                                                                                                                            item["Broad Jump"] ? item["Broad Jump"] : "" : this.props.sendReq.field === "verticalJump" ?
                                                                                                                                item["Vertical Jump"] ? item["Vertical Jump"] : "" : this.props.sendReq.field === "chinUps" ?
                                                                                                                                    item["Chin-Up"] ? item["Chin-Up"] : "" : this.props.sendReq.field === "pullUps" ?
                                                                                                                                        item["Pull-Up"] ? item["Pull-Up"] : "" : this.props.sendReq.field === "sitUps" ?
                                                                                                                                            item["Sit-Up"] ? item["Sit-Up"] : "" : this.props.sendReq.field === "pushUps" ?
                                                                                                                                                item["Push-Up"] ? item["Push-Up"] : "" : this.props.sendReq.field === "plankTest" ?
                                                                                                                                                    item["Planks"] ? item["Planks"] : "" : this.props.sendReq.field === "handGripStrenght" ?
                                                                                                                                                        item["Grip Strength"] ? item["Grip Strength"] : "" : this.props.sendReq.field === "catcherPopTime" ?
                                                                                                                                                            item["Catcher Pop Time"] ? item["Catcher Pop Time"] + " seconds" : "" : this.props.sendReq.field === "homeToFirst" ?
                                                                                                                                                                item["Home to 1B"] ? item["Home to 1B"] + " seconds" : "" : this.props.sendReq.field === "homeToSecond" ?
                                                                                                                                                                    item["Home to 2B"] ? item["Home to 2B"] + " seconds" : "" : this.props.sendReq.field === "homeToHome" ?
                                                                                                                                                                        item["Home to Home"] ? item["Home to Home"] + " seconds" : "" : this.props.sendReq.field === "maxVelocity" ?
                                                                                                                                                                            item["maxVelocity"] ? item["maxVelocity"] : "" : this.props.sendReq.field === "avgVelocity" ?
                                                                                                                                                                                item["avgVelocity"] ? item["avgVelocity"] : "" : this.props.sendReq.field === "maxSpinRate" ?
                                                                                                                                                                                    item["maxSpinRate"] ? item["maxSpinRate"] : "" : this.props.sendReq.field === "avgSpinRate" ?
                                                                                                                                                                                        item["avgSpinRate"] ? item["avgSpinRate"] : "" : this.props.sendReq.field === "spinEfficency" ?
                                                                                                                                                                                            item["spinEfficency"] ? item["spinEfficency"] : "" : this.props.sendReq.field === "avgTilt" ?
                                                                                                                                                                                                item["avgTilt"] ? item["avgTilt"] : "" : this.props.sendReq.field === "maxExitVelocity" ?
                                                                                                                                                                                                    item["maxExitVelocity"] ? item["maxExitVelocity"] : "" : this.props.sendReq.field === "avgExitVelocity" ?
                                                                                                                                                                                                        item["avgExitVelocity"] ? item["avgExitVelocity"] : "" : this.props.sendReq.field === "avgLaunch" ?
                                                                                                                                                                                                            item["avgLaunch"] ? item["avgLaunch"] : "" : this.props.sendReq.field === "maxDistance" ?
                                                                                                                                                                                                                item["maxDistance"] ? item["maxDistance"] : "" : this.props.sendReq.field === "batSpeed" ?
                                                                                                                                                                                                                    item["batSpeed"] ? item["batSpeed"] : "" : this.props.sendReq.field === "attackAngle" ?
                                                                                                                                                                                                                        item["attackAngle"] ? item["attackAngle"] : "" : this.props.sendReq.field === "exitVelocity" ?
                                                                                                                                                                                                                            item["exitVelocity"] ? item["exitVelocity"] + " seconds" : "" : this.props.sendReq.field === "infieldVelocity" ?
                                                                                                                                                                                                                                item["infieldVelocity"] ? item["infieldVelocity"] : "" : this.props.sendReq.field === "OutfieldVelocity" ?
                                                                                                                                                                                                                                    item["OutfieldVelocity"] ? item["OutfieldVelocity"] : "" : this.props.sendReq.field === "catcherVelocity" ?
                                                                                                                                                                                                                                        item["C Velo"] ? item["C Velo"] : "" : item["Bullpen Strike Percentage"] ? item["Bullpen Strike Percentage"] : ""
        )
    }

    returnLabel = (item) => {
        return (
            item === "10YardDash" ?
                "10 Yard" : item === "60YardDash" ?
                    "60 Yard" : item === "broadJump" ?
                        "Broad Jump" : item === "verticalJump" ?
                            "Vertical Jump" : item === "chinUps" ?
                                "Chin-Up" : item === "pullUps" ?
                                    "Pull-Up" : item === "sitUps" ?
                                        "Sit-Ups" : item === "pushUps" ?
                                            "Push-Ups" : item === "plankTest" ?
                                                "Planks" : item === "handGripStrenght" ?
                                                    "Hand Grip Strength" : item === "catcherPopTime" ?
                                                        "Catcher Pop Time" : item === "homeToFirst" ?
                                                            "Home to 1B" : item === "homeToSecond" ?
                                                                "Home to 2B" : item === "homeToHome" ?
                                                                    "Home to Home" : item === "bullpenStrikePercentage" ?
                                                                        "Bullpen Strike Percentage" : item === "infieldVelocity" ?
                                                                            "Infield Velo" : item === "fastballVelocity" ?
                                                                                "Fastball Velo" : item === "OutfieldVelocity" ?
                                                                                    "Outfield Velo" : item === "maxVelocity" ?
                                                                                        "Max Velocity" : item === "avgVelocity" ?
                                                                                            "Avg Velocity" : item === "maxSpinRate" ?
                                                                                                "Max Spin Rate" : item === "avgSpinRate" ?
                                                                                                    "Avg Spin Rate" : item === "spinEfficency" ?
                                                                                                        "Spin Efficency" : item === "avgTilt" ?
                                                                                                            "Avg Tilt" : item === "maxExitVelocity" ?
                                                                                                                "Max Exit Velocity" : item === "avgExitVelocity" ?
                                                                                                                    "Avg Exit Velocity" : item === "avgLaunch" ?
                                                                                                                        "Avg Launch" : item === "maxDistance" ?
                                                                                                                            "Max Distance" : item === "batSpeed" ?
                                                                                                                                "Bat Speed" : item === "attackAngle" ?
                                                                                                                                    "Attack Angle" : item === "exitVelocity" ?
                                                                                                                                        "Exit Velocity" : item === "catcherVelocity" ?
                                                                                                                                            "C Velo" : item
        )
    }

    renderLeaderBoard = () => {
        return (
            this.props.summaryData &&
                this.props.summaryData.data &&
                this.props.summaryData.data.length !== 0 ?
                this.props.summaryData.data.map((item, i) => {
                    if (i < 5) {
                        return (
                            <div className="mainBoard-item" key={i}>
                                <div className="Board-info">
                                    <div className="mainboard-img">
                                        <div className="img">
                                            <img src={item?.athlete?.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player_1" />
                                        </div>
                                        <div className="name">
                                        <p onClick={() => item?.athlete?._id && this.props.history.push(`/pw-profile-info/${item?.athlete?._id}`)}>{item?.playerName}</p>
                                            {item.stats && <div className="time">{item.stats}</div>}

                                        </div>
                                    </div>
                                    <div className="mainboard-info">
                                        {/* <p>
                                       seconds
                                    </p> */}
                                        <p className={`${localStorage.getItem('company') === "pwb" ? "date-icon" : "date-icon light-date-icon"}`}>Collected on {moment(item.gameDate).format('MM/DD/YYYY')}</p>
                                        <p className={`${localStorage.getItem('company') === "pwb" ? "location-icon" : "location-icon light-location-icon"}`} onClick={() => (item.event.company.name !== "USA Travel Ball" && item.event.company.name !== "GOBTournament") ? this.recapRedirect(item) : ""}>{item?.event?.eventTitle ? item?.event.eventTitle : ""}</p>
                                    </div>
                                </div>
                                <span className="winner-img"><img src={item?.award?.awardType === 1 ? imagesArr.winner_icon1 : item?.award?.awardType === 2 ? imagesArr.winner_icon2 : imagesArr.winner_icon3} alt="winner_icon" /></span>
                            </div >
                        )
                    }
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    goto = (path) => {
        this.props.history.push(path);
    }

    purchaseProspectwire = () => {
        this.setState({
            purchase: true,
        })
    }

    handleClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    render() {
        const { sendReq, getAllFields } = this.props;
        const { purchase } = this.state;
        return (
            <>
                <div className="mainBoard">
                    <div className="mainboard-group">
                        <div className="list-group-scroll">
                            <ul className="list-group pt-15 mb-20">
                                <li className={`list-group-item ${sendReq.metricType === 1 ? "active" : ""}`} onClick={() => this.props.setSendReq(1)}>Performance Data</li>
                                <li className={`list-group-item ${sendReq.metricType === 2 ? "active" : ""}`} onClick={() => this.props.setSendReq(2)}>Hardware Data</li>
                                <li className={`list-group-item ${sendReq.metricType === 3 ? "active" : ""}`} onClick={() => this.props.setSendReq(3)}>Game Stats</li>
                            </ul>
                        </div>
                        <div className="list-group-scroll">
                            <ul className="list-group mb-20">
                                {
                                    mainBoardFilter &&
                                    mainBoardFilter[sendReq.metricType].map((item) => {
                                        return (
                                            <li className={`list-group-item ${sendReq.metricSubType === item ? "active" : ""}`} onClick={() => this.props.onHandleMstype(item)}>{item}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="list-group-scroll">
                            <ul className="list-group mb-20">
                                {
                                    getAllFields &&
                                    getAllFields.map((item) => {
                                        return (
                                            <li className={`list-group-item ${sendReq.field === item ? "active" : ""} ${getAllFields.length === 1 ? "list-group-item-one" : ""}`} onClick={() => this.props.onHandleField(item)}>
                                                {this.returnLabel(item)}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="mainBoard-list">
                        {this.renderLeaderBoard()}
                        {/* {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || (sendReq.field !== "5-10-5"
                            && sendReq.field !== "verticalJump" && sendReq.field !== "handGripStrenght" && sendReq.field !== "Fastball Spin rate"
                            && sendReq.field !== "Curveball spin rate" && sendReq.field !== "Changeup spin Rate" && sendReq.field !== "Changeup spin Rate") ?
                            ""
                            :
                            <> */}
                                {/* <p className="text-center">No data found</p> */}
                                {/* <div class="mainBoard-item">
                                    <div class="Board-info">
                                        <div class="mainboard-img">
                                            <div class="img">
                                                <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="player_1" />
                                            </div>
                                            <div class="name">
                                                <p>Punit Mandani</p>
                                                <div className="time">{sendReq.field === "verticalJump" ? "10 ft. 65in." : sendReq.field === "handGripStrenght" ? "40 lbs" : "40 seconds"}</div>
                                            </div>
                                        </div>
                                        <div class="mainboard-info">
                                            <p class="date-icon">Collected on 12/01/2021</p>
                                            <p class="location-icon">Event Name</p>
                                        </div>
                                    </div>
                                    <span class="winner-img">
                                        <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="winner_icon" />
                                    </span>
                                </div>
                                <div class="mainBoard-item">
                                    <div class="Board-info">
                                        <div class="mainboard-img">
                                            <div class="img">
                                                <img src="https://artoon-taxuz.s3.amazonaws.com/16/7178140e4bfbcf4a62f5be3ee868be79" alt="player_1" />
                                            </div>
                                            <div class="name">
                                                <p>Jhon Deo</p>
                                                <div className="time">{sendReq.field === "verticalJump" ? "20 ft. 30in." : sendReq.field === "handGripStrenght" ? "40 lbs" : "40 seconds"}</div>
                                            </div>
                                        </div>
                                        <div class="mainboard-info">
                                            <p class="date-icon">Collected on 12/01/2021</p>
                                            <p class="location-icon">Event Name</p>
                                        </div>
                                    </div>
                                    <span class="winner-img">
                                        <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="winner_icon" />
                                    </span>
                                </div>
                                <div class="mainBoard-item">
                                    <div class="Board-info">
                                        <div class="mainboard-img">
                                            <div class="img">
                                                <img src="https://artoon-taxuz.s3.amazonaws.com/16/914faac642e7725386e10df2beb76704" alt="player_1" />
                                            </div>
                                            <div class="name">
                                                <p>Larry paige</p>
                                                <div className="time">{sendReq.field === "verticalJump" ? "40 ft. 45in." : sendReq.field === "handGripStrenght" ? "60 lbs" : "60 seconds"}</div>
                                            </div>
                                        </div>
                                        <div class="mainboard-info">
                                            <p class="date-icon">Collected on 12/01/2021</p>
                                            <p class="location-icon">Event Name</p>
                                        </div>
                                    </div>
                                    <span class="winner-img">
                                        <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="winner_icon" />
                                    </span>
                                </div>
                                <div class="mainBoard-item">
                                    <div class="Board-info">
                                        <div class="mainboard-img">
                                            <div class="img">
                                                <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="player_1" />
                                            </div>
                                            <div class="name">
                                                <p>Punit Mandani</p>
                                                <div className="time">{sendReq.field === "verticalJump" ? "50 ft. 55in." : sendReq.field === "handGripStrenght" ? "40 lbs" : "40 seconds"}</div>
                                            </div>
                                        </div>
                                        <div class="mainboard-info">
                                            <p class="date-icon">Collected on 12/01/2021</p>
                                            <p class="location-icon">Event Name</p>
                                        </div>
                                    </div>
                                    <span class="winner-img">
                                        <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="winner_icon" />
                                    </span>
                                </div>
                                <div class="mainBoard-item">
                                    <div class="Board-info">
                                        <div class="mainboard-img">
                                            <div class="img">
                                                <img src="https://artoon-taxuz.s3.amazonaws.com/16/7178140e4bfbcf4a62f5be3ee868be79" alt="player_1" />
                                            </div>
                                            <div class="name">
                                                <p>Jhon Deo</p>
                                                <div className="time">{sendReq.field === "verticalJump" ? "60 ft. 66in." : sendReq.field === "handGripStrenght" ? "45 lbs" : "45 seconds"}</div>
                                            </div>
                                        </div>
                                        <div class="mainboard-info">
                                            <p class="date-icon">Collected on 12/01/2021</p>
                                            <p class="location-icon">Event Name</p>
                                        </div>
                                    </div>
                                    <span class="winner-img">
                                        <img src="https://artoon-taxuz.s3.amazonaws.com/10/673fe866d5694bebe586a55122a529f9.png" alt="winner_icon" />
                                    </span>
                                </div> */}
                                {/* <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                            </>
                        } */}
                    </div>
                    <div className="see-more-btn">
                        <Link className="btn">
                            <span onClick={() => this.goto('/pw-player-ranking')}>See More</span>
                            {/* {
                                localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" &&
                                <span className="lock-icon bg-grey" onClick={() => this.purchaseProspectwire()}>
                                    <img src={imagesArr.lock_icon} alt="lock_icon" />
                                </span>
                            } */}
                        </Link>
                    </div>
                </div>
                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

export default withRouter(dataTab);
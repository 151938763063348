import React, { Component } from "react";
import Header from "../containers/header";
import Footer from "../containers/footer";
import { imagesArr } from "../assets/images";
import { Link } from "react-router-dom";
import CommonHeroBanner from "../components/commonHeroBanner";
import PressRoomDec from "../views/popup/pressRoomDec";

export class PressRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomIndex: 0,
      openPopup: false,
    };
  }

  componentDidMount = () => {
    localStorage.removeItem("company");
    localStorage.removeItem("cid");
    localStorage.removeItem("companyColor");
    let defaultColor = [
      {
        variable: "--green-color",
        colorCode: "#7CBD42"
      },
      {
        variable: "--green-color-light",
        colorCode: "#96CF04"
      },
      {
        variable: "--green-color-shadow",
        colorCode: "#7CBD42"
      },
      {
        variable: "--green-color-extra-light",
        colorCode: "#eaf6cc"
      },
    ]
    this.setThemeColor(defaultColor);
  }

  setThemeColor = (colArr) => {
    localStorage.setItem("companyColor", JSON.stringify(colArr));
    for (let i = 0; i < colArr.length; i++) {
      const element = colArr[i];
      document.body.style.setProperty(element.variable, element.colorCode);
    }
  }

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      openPopup: !this.state.openPopup,
    });
  };

  redirectNewTab = () => {
    window.open("https://www.sporttechie.com/new-york-mets-mlb-world-series-champions-technology-road-map", '_blank');
  }

  render() {
    const { openPopup, roomIndex } = this.state;
    return (
      <>
        <Header />
        <CommonHeroBanner dataList="" />
        <section className="pressroom">
          <div className="container">
            <div className="heading-title">
              <h2>Latest News</h2>
            </div>
            <div className="press_room_banner">
              <img src={imagesArr.main_press_room} alt="press_room" />
              <div className="press_room_banner_text">
                <h5 onClick={() =>
                  this.setState({
                    roomIndex: 13,
                    openPopup: true,
                  })
                }>
                  The Frontier League and BaseballCloud/Yakkertech Announce Exclusive Data Partnership
                </h5>
                <span>April 12, 2022</span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="press_room_blog">
              <div className="press_room_blog_part">
                <div className="press_room_blog_inner">
                  <div className="press_room_img">
                    <img
                      src={imagesArr.press_room_img1}
                      alt="press_room_img1"
                    />
                  </div>
                  <h5
                    onClick={() =>
                      this.setState({
                        roomIndex: 9,
                        openPopup: true,
                      })
                    }
                  >
                    SportTechie Webinar
                  </h5>
                  <p className="mb-0">April 14th, 2021</p>
                </div>
              </div>
              <div className="press_room_blog_part">
                <div className="press_room_blog_inner">
                  <div className="press_room_img">
                    <img
                      src={imagesArr.press_room_img2}
                      alt="press_room_img2"
                    />
                  </div>
                  <h5
                    onClick={() =>
                      this.setState({
                        roomIndex: 10,
                        openPopup: true,
                      })
                    }
                  >
                    PitchR on MLB Network #2
                  </h5>
                  <p className="mb-0">March 31st, 2021</p>
                </div>
              </div>
              <div className="press_room_blog_part">
                <div className="press_room_blog_inner">
                  <div className="press_room_img">
                    <img
                      src={imagesArr.press_room_img3}
                      alt="press_room_img3"
                    />
                  </div>
                  <h5
                    onClick={() => this.redirectNewTab()}
                  >
                    Technology Roadmap
                  </h5>
                  <p className="mb-0">March 18th, 2021</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="press_room_thumb">
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb15}
                      alt="press_room_thumb7"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 8,
                          openPopup: true,
                        })
                      }
                    >
                      BaseballCloud partners with the Lexington Legends and West Virginia Power of the Atlantic League
                    </h5>
                    <span className="dates">May 7th, 2021</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb9}
                      alt="press_room_thumb7"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 11,
                          openPopup: true,
                        })
                      }
                    >
                      BallR 1.0 Launch: The Release is just the Beginning
                    </h5>
                    <span className="dates">January 9th, 2021</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb10}
                      alt="press_room_thumb7"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 4,
                          openPopup: true,
                        })
                      }
                    >
                      BaseballCloud presents and hosts
                      booth at ABCA Virtual Convention
                    </h5>
                    <span className="dates">January 6th, 2021</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb12}
                      alt="press_room_thumb7"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 3,
                          openPopup: true,
                        })
                      }
                    >
                      Baseball sponsors new P3 Summer League for 2021
                    </h5>
                    <span className="dates">December 16th, 2020</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb11}
                      alt="press_room_thumb7"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 5,
                          openPopup: true,
                        })
                      }
                    >
                      DS Sports Ventures announces
                      creation of ProspectWire Softball
                    </h5>
                    <span className="dates">December 16th, 2020</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb14}
                      alt="press_room_thumb8"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">BC News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 12,
                          openPopup: true,
                        })
                      }
                    >
                      Interns in Pro Ball
                    </h5>
                    <span className="dates">December 14th, 2020</span>
                  </div>
                </div>
              </div>          
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb7}
                      alt="press_room_thumb7"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">BC News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 6,
                          openPopup: true,
                        })
                      }
                    >
                      BaseballCloud 2020 fall interns land positions in MLB analytics departments
                    </h5>
                    <span className="dates">December 14th, 2020</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb1}
                      alt="press_room_thumb1"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">Media</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 0,
                          openPopup: true,
                        })
                      }
                    >
                      PitchR on MLB Network
                    </h5>
                    <span className="dates">November 13th, 2020</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb2}
                      alt="press_room_thumb2"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 1,
                          openPopup: true,
                        })
                      }
                    >
                      BallR Featured in Fox Business Article: Baseball Focuses on Tech
                    </h5>
                    <span className="dates">August 26th, 2020</span>
                  </div>
                </div>
              </div>
              <div className="press_room_thumb_part">
                <div className="press_room_thumb_inner">
                  <div className="press_room_thumb_img">
                    <img
                      src={imagesArr.press_room_thumb8}
                      alt="press_room_thumb8"
                    />
                  </div>
                  <div className="press_room_thumb_text">
                    <span className="news_thumb">News</span>
                    <h5
                      onClick={() =>
                        this.setState({
                          roomIndex: 7,
                          openPopup: true,
                        })
                      }
                    >
                      The Future of Baseball Scouting:
                      Every Day Is Friday
                    </h5>
                    <span className="dates">June 3th, 2020</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <PressRoomDec
          flag={openPopup}
          dataIndex={roomIndex}
          close={this.handleClose}
        />
      </>
    );
  }
}

export default PressRoom;

import {
    RESEND_CODE_REQUEST,
    RESEND_CODE_SUCCESS,
    RESEND_CODE_ERROR,

    MOBILE_CODE_REQUEST,
    MOBILE_CODE_SUCCESS,
    MOBILE_CODE_ERROR,

    RESEND_FCODE_REQUEST,
    RESEND_FCODE_SUCCESS,
    RESEND_FCODE_ERROR,

    FVERIFY_CODE_REQUEST,
    FVERIFY_CODE_SUCCESS,
    FVERIFY_CODE_ERROR,

    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_ERROR,

    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,

    GET_VERIFY_PROFILE_REQUEST,
    GET_VERIFY_PROFILE_SUCCESS,
    GET_VERIFY_PROFILE_ERROR,

    GET_PLAYER_PROFILE_REQUEST,
    GET_PLAYER_PROFILE_SUCCESS,
    GET_PLAYER_PROFILE_ERROR,

    GET_FAN_PROFILE_REQUEST,
    GET_FAN_PROFILE_SUCCESS,
    GET_FAN_PROFILE_ERROR,

    RESET_REQUEST,
    RESET_SUCCESS,
    RESET_ERROR,

    RESET_MOBILE_REQUEST,
    RESET_MOBILE_SUCCESS,
    RESET_MOBILE_ERROR,
} from '../../constants/authantication/verify.const';

const initialState = {
    rendReq: false,
    resendData: null,
    resendError: null,

    mresendReq: false,
    mresendData: null,
    mresendError: null,

    fresendReq: false,
    fresendData: null,
    fresendError: null,

    fverifyReq: false,
    fverifyData: null,
    fverifyError: null,

    verifyReq: false,
    verifyData: null,
    verifyError: null,

    profileReq: false,
    profileData: null,
    profileError: null,

    verifyProfileReq: false,
    verifyProfileData: null,
    verifyProfileError: null,

    playerProfileReq: false,
    playerProfileData: null,
    playerProfileError: null,

    fanProfileReq: false,
    fanProfileData: null,
    fanProfileError: null,

    resetReq: false,
    resetData: null,
    resetError: null,

    mobileSendReq: false,
    mobileSendData: null,
    mobileSendError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Resend Fotp
        case RESEND_FCODE_REQUEST:
            return {
                ...state,
                fresendReq: true,
            };
        case RESEND_FCODE_SUCCESS:
            return {
                ...state,
                fresendData: action.payload,
            };
        case RESEND_FCODE_ERROR:
            return {
                ...state,
                fresendError: action.error,
            };

        // Resend mobile otp
        case MOBILE_CODE_REQUEST:
            return {
                ...state,
                mresendReq: true,
            };
        case MOBILE_CODE_SUCCESS:
            return {
                ...state,
                mresendData: action.payload,
            };
        case MOBILE_CODE_ERROR:
            return {
                ...state,
                mresendError: action.error,
            };

        // Resend otp
        case RESEND_CODE_REQUEST:
            return {
                ...state,
                rendReq: true,
            };
        case RESEND_CODE_SUCCESS:
            return {
                ...state,
                resendData: action.payload,
            };
        case RESEND_CODE_ERROR:
            return {
                ...state,
                resendError: action.error,
            };

        // send fverify code
        case FVERIFY_CODE_REQUEST:
            return {
                ...state,
                fverifyReq: true,
            };
        case FVERIFY_CODE_SUCCESS:
            return {
                ...state,
                fverifyData: action.payload,
            };
        case FVERIFY_CODE_ERROR:
            return {
                ...state,
                fverifyError: action.error,
            };

        // send verify code
        case VERIFY_CODE_REQUEST:
            return {
                ...state,
                verifyReq: true,
            };
        case VERIFY_CODE_SUCCESS:
            return {
                ...state,
                verifyData: action.payload,
            };
        case VERIFY_CODE_ERROR:
            return {
                ...state,
                verifyError: action.error,
            };

        // GET PROFILE data

        case GET_PROFILE_REQUEST:
            return {
                ...state,
                profileReq: true,
            };
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                profileData: action.payload,
            };
        case GET_PROFILE_ERROR:
            return {
                ...state,
                profileError: action.error,
            };

        // GET Verify PROFILE data

        case GET_VERIFY_PROFILE_REQUEST:
            return {
                ...state,
                verifyProfileReq: true,
            };
        case GET_VERIFY_PROFILE_SUCCESS:
            return {
                ...state,
                verifyProfileData: action.payload,
            };
        case GET_VERIFY_PROFILE_ERROR:
            return {
                ...state,
                verifyProfileError: action.error,
            };

        // GET Player PROFILE data

        case GET_PLAYER_PROFILE_REQUEST:
            return {
                ...state,
                playerProfileReq: true,
            };
        case GET_PLAYER_PROFILE_SUCCESS:
            return {
                ...state,
                playerProfileData: action.payload,
            };
        case GET_PLAYER_PROFILE_ERROR:
            return {
                ...state,
                playerProfileError: action.error,
            };


        // GET Fan PROFILE data

        case GET_FAN_PROFILE_REQUEST:
            return {
                ...state,
                fanProfileReq: true,
            };
        case GET_FAN_PROFILE_SUCCESS:
            return {
                ...state,
                fanProfileData: action.payload,
            };
        case GET_FAN_PROFILE_ERROR:
            return {
                ...state,
                fanProfileError: action.error,
            };

        // reset

        case RESET_REQUEST:
            return {
                ...state,
                resetReq: true,
            };
        case RESET_SUCCESS:
            return {
                ...state,
                resetData: action.payload,
            };
        case RESET_ERROR:
            return {
                ...state,
                resetError: action.error,
            };

        // mobile reset

        case RESET_MOBILE_REQUEST:
            return {
                ...state,
                mobileSendReq: true,
            };
        case RESET_MOBILE_SUCCESS:
            return {
                ...state,
                mobileSendData: action.payload,
            };
        case RESET_MOBILE_ERROR:
            return {
                ...state,
                mobileSendError: action.error,
            };

        default:
            return state;
    }
}
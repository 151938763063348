
import bclogo from './logo/bc_logo_white.png';
import logo from './logo/diamondconnect_headerlogo.png';
import bcWhitePostLogin from './logo/BC_White_Logo_Postlogin.png'
import scWhitePostLogin from './logo/SC_White_Logo_Postlogin.png'
import SportsCloud from './logo/SportsCloud.png';
import profile from './user/profile.jpg';
import headingimg from './landing/heading-img.png';

import development from './landing/development.png';

import headinglogo1 from './landing/headinglogo1.png';
import headinglogo2 from './landing/headinglogo2.png';
import headinglogo3 from './landing/headinglogo3.png';
import headinglogo4 from './landing/headinglogo4.png';
import headinglogo5 from './landing/headinglogo5.png';
import headinglogo6 from './landing/headinglogo6.png';
import headinglogo8 from './landing/headinglogo8.png';
import headinglogo9 from './landing/headinglogo9.png';
import headinglogo10 from './landing/headinglogo10.png';

// import footer_logo from './logo/footer-logo.png';
import footer_logo from './logo/diamondconnect_footerlogo.png';
import footer_SportsCloud from './logo/footer_SportsCloud.png';
import common_img from './commen-bg/common-img.png';
// import logo_common from './commen-bg/logo_common.png';
import logo_common from './logo/dc_logo_white.png';
import insta from './social/insta.svg';
import fb from './social/fb.svg';
import snapchat from './social/snapchat.svg';
import eye from './social/eye.svg';
import calendar from './social/calendar.svg';
import smartphone from './social/smartphone.svg';
import eyeopen from './social/eyeopen.svg';
import search from './social/search.svg';
import addteam from './social/addteam.svg';
import team1 from './team/team1.svg';
import team2 from './team/team2.svg';
import team3 from './team/team3.svg';
import team4 from './team/team4.svg';
import team5 from './team/team5.svg';
import claim from './social/claim.svg';
import modal_close from './social/modal_close.svg';
import twitter from './social/twitter.svg';
import claim_plus from './social/claim_plus.svg';
import deletes from './social/deletes.svg';
import thumb_up from './social/thumb_up.svg';
import white_modal_close from './social/white_modal_close.svg';
import gray_modal_close from './social/gray_modal_close.svg';
import facalitiy_logo from './dashborad/facalitiy_logo.png';
import team_logo from './dashborad/team_logo.png';
import slide1 from './dashborad/slide1.png';
import green_teamwork from './dashborad/green_teamwork.svg';
import green_upload from './dashborad/green_upload.svg';
import green_subscription from './dashborad/green_subscription.svg';
import aquamarine_teamwork from './dashborad/aquamarine_teamwork.svg';
import aquamarine_upload from './dashborad/aquamarine_upload.svg';
import aquamarine_subscription from './dashborad/aquamarine_subscription.svg';
import blue_teamwork from './dashborad/blue_teamwork.svg';
import blue_upload from './dashborad/blue_upload.svg';
import blue_subscription from './dashborad/blue_subscription.svg';
import blue_light_teamwork from './dashborad/blue_light_teamwork.svg';
import blue_light_upload from './dashborad/blue_light_upload.svg';
import blue_light_subscription from './dashborad/blue_light_subscription.svg';
import white_teamwork from './dashborad/white_teamwork.svg';
import white_upload from './dashborad/white_upload.svg';
import edit_profile from './dashborad/edit_profile.svg';
import white_subscription from './dashborad/white_subscription.svg';
import ballrteam1 from './dashborad/ballrteam1.png';
import ballrteam2 from './dashborad/ballrteam2.png';
import user from './user/user.png';
import user_basic from './user/user_basic.png';
import dashboard_user from './user/dashboard_user.png';
import ground1 from './ground/ground1.png';
import ground2 from './ground/ground2.png';
import collage1 from './collage/collage1.png';
import collage2 from './collage/collage2.png';
import collage3 from './collage/collage3.png';
import collage4 from './collage/collage4.png';
import webgl from './webgl/webgl.jpg';
import cart_user from './user/cart_user.png';
import video_slide1 from './video_slide/video_slide1.jpg';
import video_slide2 from './video_slide/video_slide2.jpg';
import collage_name1 from './collage/collage_name1.jpg';
import collage_name2 from './collage/collage_name2.jpg';
import collage_name3 from './collage/collage_name3.jpg';
import collage_name4 from './collage/collage_name4.jpg';
import collage_name5 from './collage/collage_name5.jpg';
import collage_name6 from './collage/collage_name6.jpg';
import cancel from './dashborad/cancel.png';
import ballr_logo from './ballr/ballr_logo.svg';
import ballr_slider1 from './ballr/ballr_slider1.png';
import ballr_slider2 from './ballr/ballr_slider2.png';
import for_way1 from './ballr/for_way1.png';
import for_way2 from './ballr/for_way2.png';
import for_way3 from './ballr/for_way3.png';
import for_way4 from './ballr/for_way4.png';
import for_way5 from './ballr/for_way5.png';
import for_way6 from './ballr/for_way6.png';
import play from './ballr/play.png';
import brand_logo1 from './ballr/brandlogo/brand_logo1.png';
import brand_logo2 from './ballr/brandlogo/brand_logo2.png';
import brand_logo3 from './ballr/brandlogo/brand_logo3.png';
import brand_logo4 from './ballr/brandlogo/brand_logo4.png';
import brand_logo5 from './ballr/brandlogo/brand_logo5.png';
import diamond_kinetics from './ballr/diamond_kinetics.png';
import diamond_kinetics2 from './ballr/diamond_kinetics2.png';
import diamond_video1 from './ballr/video/diamond_video1.png';
import diamond_video2 from './ballr/video/diamond_video2.png';
import diamond_video3 from './ballr/video/diamond_video3.png';
import diamond_video4 from './ballr/video/diamond_video4.png';
import check from './ballr/price/check.svg';
import playerdashboard from './dashborad/playerprofile.png';
import playerprofileicon from './dashborad/playerprofileicon.png';
import playerclippath from './dashborad/playerclippath.png';
import playerslider from './dashborad/playerslider.png';
import claim_minus from './social/claim_minus.svg';
import visa_icon from './payment/visa.svg';
import color_visa_icon from './payment/color-visa.svg';
import american_express_icon from './payment/american-express.svg';
import color_american_express_icon from './payment/color-american-express.svg';
import mastercard_icon from './payment/mastercard.svg';
import color_mastercard_icon from './payment/color-mastercard.svg';
import discover_icon from './payment/discover.svg';
import color_discover_icon from './payment/color-discover.svg';
import ballrlogo from './payment/ballrlogo.svg';
import credit_card from './payment/credit-card.svg';
import loader from './preloader.gif';
import main_press_room from './pressroom/main_press_room.png';
import press_room from './pressroom/press_room.png';
import press_room_img1 from './pressroom/press_room_img1.png';
import press_room_img2 from './pressroom/press_room_img2.png';
import press_room_img3 from './pressroom/press_room_img3.png';
import press_room_thumb1 from './pressroom/press_room_thumb1.png';
import press_room_thumb2 from './pressroom/press_room_thumb2.png';
import press_room_thumb3 from './pressroom/press_room_thumb3.png';
import press_room_thumb4 from './pressroom/press_room_thumb4.jpg';
import press_room_thumb5 from './pressroom/press_room_thumb5.jpg';
import press_room_thumb6 from './pressroom/press_room_thumb6.png';
import press_room_thumb7 from './pressroom/press_room_thumb7.jpg';
import press_room_thumb8 from './pressroom/press_room_thumb8.png';
import press_room_thumb9 from './pressroom/press_room_thumb9.png';
import press_room_thumb10 from './pressroom/press_room_thumb10.png';
import press_room_thumb11 from './pressroom/press_room_thumb11.png';
import press_room_thumb12 from './pressroom/press_room_thumb12.jpeg';
import press_room_thumb13 from './pressroom/press_room_thumb13.png';
import press_room_thumb14 from './pressroom/press_room_thumb14.png';
import press_room_thumb15 from './pressroom/press_room_thumb15.png';
import press_room_thumb_modal1 from './pressroom/modal/press_room_thumb_modal1.jpg';
import press_room_thumb_modal2 from './pressroom/modal/press_room_thumb_modal2.jpg';
import press_room_thumb_modal3 from './pressroom/modal/press_room_thumb_modal3.png';
import press_room_thumb_modal4 from './pressroom/modal/press_room_thumb_modal4.jpg';
import press_room_thumb_modal5 from './pressroom/modal/press_room_thumb_modal5.jpg';
import press_room_thumb_modal6 from './pressroom/modal/press_room_thumb_modal6.png';
import press_room_thumb_modal7 from './pressroom/modal/press_room_thumb_modal7.jpg';
import press_room_thumb_modal8 from './pressroom/modal/press_room_thumb_modal8.png';
import press_room_thumb_modal9 from './pressroom/modal/press_room_thumb_modal9.png';
import press_room_thumb_modal10 from './pressroom/modal/press_room_thumb_modal10.jpg';
import ourteam1 from './ourteam/ourteam1.png';
import ourteam2 from './ourteam/ourteam2.png';
import ourteam3 from './ourteam/ourteam3.png';
import default_user from './user/default.png';
import menu from './landing/menu.svg';
import menu_dc from './header/menu_dc.png';
import menu_bcteam from './header/menu_bcteam.png'
import menu_ballr from './header/menu_ballr.png'
import menu_analyzr from './header/menu_analyzr.png'
import menu_pitchr from './header/menu_pitchr.png'
import diamondconnect from './header/diamondconnect.png'
import prospectwire from './header/prospectwire.png'
import prospectwiresoftball from './header/prospectwiresoftball.png'
import russmattbaseball from './header/russmattbaseball.png'
import russmattsoftball from './header/russmattsoftball.png'
import greaterorkandobaseball from './header/greaterorkandobaseball.png'
import usatravelball from './header/usatravelball.png'
// import usatravelball from './header/usatravelball.svg'
import gemtravelball from './header/gemtravelball.png'
import main_logo from './header/main_logo.png'
import company_logo_1 from './header/company_logo_1.png'
import company_logo_2 from './header/company_logo_2.png'
import company_logo_3 from './header/company_logo_3.png'
import company_logo_4 from './header/company_logo_4.png'
import company_logo_6 from './header/company_logo_6.png'



// comapny landing
import setting_bar from './comapnylanding/setting_bar.svg';
import banner_img1 from "./comapnylanding/banner_img1.png";
import banner_slider1 from "./comapnylanding/banner_slider1.png";
import player_spotlight from "./comapnylanding/player_spotlight.png";
import product_sponsors_1 from "./comapnylanding/product_sponsors_1.png";
import product_sponsors_2 from "./comapnylanding/product_sponsors_2.png";
import product_sponsors_3 from "./comapnylanding/product_sponsors_3.png";
import bar from "./comapnylanding/bar.svg";
import c_twitter from "./social/c_twitter.svg";
import c_facebook from "./social/c_facebook.svg";
import c_instagram from "./social/c_instagram.svg";
import c_tiktok from "./social/c_tiktok.svg";
import player_1 from "./comapnylanding/player_1.png";
import company_logo from "./comapnylanding/company_logo.png";
import team_thumb from "./comapnylanding/team_thumb.png";
import mvp_1 from "./comapnylanding/mvp_1.png"

import  pioneer_banner_homepage from './comapnylanding/pioneer_banner_homepage.png'


import eventinfo_banner from "./comapnylanding/eventinfo_banner.png";
import event_info_bannerlogo from "./comapnylanding/event_info_bannerlogo.png";
import search_icon from "./comapnylanding/search_icon.svg";
import map_icon_green from "./comapnylanding/map_icon_green.svg";
import map_icon_blue from "./comapnylanding/map_icon_blue.svg";
import map_icon_blue_light from "./comapnylanding/map_icon_blue_light.svg";
import round_arrow from "./comapnylanding/round_arrow.svg";
import shap from "./comapnylanding/shap.svg";
import hotel from "./comapnylanding/hotel.png";
import user_profile from "./comapnylanding/user_profile.png";
import insurance from "./comapnylanding/insurance.png";
import link from "./comapnylanding/link.svg";
import close_shap from "./comapnylanding/close_shap.png";
import map_icon_aquamarine from "./comapnylanding/map_icon_aquamarine.svg";
import white_close from "./social/white_close.svg";
import maximize from "./social/maximize.svg";
import black_slight_search from "./comapnylanding/black_slight_search.svg";
import right_arrow from './comapnylanding/right_arrow.svg';

import All_American from "./showcase/All_American.jpeg";
import Data_Driven_Showcases from "./showcase/Data_Driven_Showcases.png";
import Data_Games from "./showcase/Data_Games.jpg";
import Next_Gen from "./showcase/Next_Gen.jpeg";
import Team_Workouts from "./showcase/Team_Workouts.jpeg";
import showcase_1 from "./showcase/showcase_1.png";
import showcase_2 from "./showcase/showcase_2.png";
import showcase_3 from "./showcase/showcase_3.png";
import showcase_4 from "./showcase/showcase_4.png";
import showcase_5 from "./showcase/showcase_5.png";
import pws_showcase_1 from "./showcase/pws_showcase_1.png";
import pws_showcase_2 from "./showcase/pws_showcase_2.png";
import pws_showcase_3 from "./showcase/pws_showcase_3.png";
import pws_showcase_4 from "./showcase/pws_showcase_4.png";
import pws_showcase_5 from "./showcase/pws_showcase_5.png";
import winner from "./comapnylanding/winner.svg";

import S_Analyzer from "./showcase/analyzer.png";
import S_Ballr from "./showcase/ballr.png";
import S_Pitchar from "./showcase/pitchar.png";
import S_BcTeam from "./showcase/bcTeam.png";

import S_DK_Logo from "./showcase/dk_logo.png";
import S_DK_Product from "./showcase/dk_product.png";
import video from "./video.mov";
// Analyzr landing page
import analyzr from "./analyzr/analyzr.gif";
import forplayer from "./analyzr/forplayer.png";
import colleges from "./analyzr/colleges.png";
import agents from "./analyzr/agents.png";
import facilities from "./analyzr/facilities.png";
import events from "./analyzr/events.png";
import scouting from "./analyzr/scouting.png";
import bullpen from "./analyzr/bullpen.png";
import game from "./analyzr/game.png";
import batting_practice from "./analyzr/batting_practice.png";
import scrimmage from "./analyzr/scrimmage.png";
import camp from "./analyzr/camp.png";
import reporting_1 from "./analyzr/reporting_1.png";
import reporting_2 from "./analyzr/reporting_2.png";
import stalker_sports from "./comapnylanding/stalker_sports.png";
import dimond_kinetics from "./comapnylanding/dimond_kinetics.png";
import Alternate_Logo from "./comapnylanding/Alternate_Logo.png";
import no_ads from "./comapnylanding/no_ads.png";
import about_slide_1 from "./comapnylanding/about_slide_1.png";
import about_slide_2 from "./comapnylanding/about_slide_2.png";
import about_slide_3 from "./comapnylanding/about_slide_3.png";
import about_slide_4 from "./comapnylanding/about_slide_4.png";
import about_slide_5 from "./comapnylanding/about_slide_5.png";
import like_thumb from "./comapnylanding/like_thumb.svg";
import playstore from "./comapnylanding/playstore.png";
import appstore from "./comapnylanding/appstore.png";
import gob_about_slide_1 from "./comapnylanding/gob_about_slide_1.png";
import gob_about_slide_2 from "./comapnylanding/gob_about_slide_2.png";
import gob_about_slide_3 from "./comapnylanding/gob_about_slide_3.png";
import gob_about_slide_4 from "./comapnylanding/gob_about_slide_4.png";
import white_blank from "./user/white_blank.png";
import mobileapp from "./comapnylanding/mobileapp.png"
// import dimond_connect from "./comapnylanding/dimond_connect.png"
import dimond_connect from "./comapnylanding/dc_logo_white.png"
import dc_logo_black from "./comapnylanding/dc_logo_black.png"
import dimond_connect_black from "./comapnylanding/dimond_connect_black.png"
import pioneer_logo_black from "./comapnylanding/pioneer_logo_black.png"
import pioneer_logo_black_1 from "./comapnylanding/pioneer_logo_black_1.png"

import SCTeam from './logo/SCTeam_Field.png';
import SoftballCloudLogo from './logo/SoftballCloudLogo.png';

import nati_rank from "./pw-ranking/rank-1.png";
import ranking_banner_img from "./pw-ranking/ranking-banner-logo.png";
import lock_icon from "./pw-ranking/lock-icon.svg";
import winner_icon1 from "./pw-ranking/winner-icon1.svg";
import winner_icon2 from "./pw-ranking/winner-icon2.svg";
import winner_icon3 from "./pw-ranking/winner-icon3.svg";
import team_info_logo from "./pw-ranking/team-info-logo.png";
import user_ranking from "./pw-ranking/user_ranking.png";
import lock from "./pw-ranking/lock.png";
import lock_light from "./pw-ranking/lock-light.png";

import pw_events from "./pw-ranking/pw_events.png";
import down_arrow from "./pw-ranking/down-arrow.svg";
import pdf_icon from "./pw-ranking/pdf-icon.svg";
import awards from "./pw-ranking/awards.png";
import gallery from "./pw-ranking/gallery.png";
import infocontact from "./pw-ranking/infocontact.png";
import media from "./pw-ranking/media.png";
import stats from "./pw-ranking/stats.png";
import info from "./pw-ranking/info.png";
import productlist from "./pw-ranking/productlist.png";
import summary from "./pw-ranking/summary.png";
import pw_calender from "./pw-ranking/calender.png";
import lock_pw from "./pw-ranking/lock_pw.png";
import lock_pws from "./pw-ranking/lock_pws_1.png";
import info_green from "./pw-ranking/info_green.png";
import info_green_light from "./pw-ranking/info_green_light.png";

import info_black from "./pw-ranking/info_black.svg";

import dk_logo from "./pw-ranking/dk_logo.png";

import facebook_icon from "./pw-ranking/facebook-icon.svg";
import instagram_icon from "./pw-ranking/instagram-icon.svg";
import twitter_icon from "./pw-ranking/twitter-icon.svg";
import snapchat_icon from "./pw-ranking/snapchat-icon.svg";
import trophy_1 from "./pw-ranking/trophy_1.svg";
import trophy_2 from "./pw-ranking/trophy_2.svg";
import trophy_3 from "./pw-ranking/trophy_3.svg";
import trophy_4 from "./pw-ranking/trophy_4.svg";

import location_bg_icon from "./pw-ranking/location-bg-icon.svg";
import mail_bg_icon from "./pw-ranking/mail-bg-icon.svg";
import call_icon from "./pw-ranking/call-icon.svg";
import user_icon from "./pw-ranking/user-icon.svg";

import location_bg_icon_light from "./pw-ranking/location-bg-icon-light.svg";
import mail_bg_icon_light from "./pw-ranking/mail-bg-icon-light.svg";
import call_icon_light from "./pw-ranking/call-icon-light.svg";
import user_icon_light from "./pw-ranking/user-icon-light.svg";


import edit from "./pw-ranking/edit.png";
import edit_light from "./pw-ranking/edit-light.png";

import upgrade from "./pw-ranking/upgrade.png";
import upgrade_light from "./pw-ranking/upgrade-light.png";

import remove from "./pw-ranking/delete.png";
import remove_light from "./pw-ranking/delete_light.png";

import dc_stats from "./pw-ranking/dc_stats.png";

// Diamond Connect Landing Page
import dc_main_mob from "./dc/dc-main-mob.png";
import event_handel from "./dc/event-handel.png";
import doubble_mob from "./dc/doubble-mob.png";
import partners_1 from "./dc/partners_1.png";
import partners_2 from "./dc/partners_2.png";
import partners_3 from "./dc/partners_3.png";
import partners_group_1 from "./dc/partners_group_1.png";
import partners_group_2 from "./dc/partners_group_2.png";
import stalkersport_1 from "./dc/stalkersport_1.png";
import stalkersport_2 from "./dc/stalkersport_2.png";
import yakkertech_1 from "./dc/yakkertech_1.png";
import yakkertech_2 from "./dc/yakkertech_2.png";
import awards_group from "./dc/awards.png";
import ios_store from "./dc/ios_store.png";
import and_store from "./dc/and_store.png";
import computer from "./dc/computer.png";
import slider_mob_1 from "./dc/slider_mob_1.png";
import slider_mob_2 from "./dc/slider_mob_2.png";
import slider_mob_3 from "./dc/slider_mob_3.png";

// pitchr img

import game_analyze_1 from "./pitchr/game_analyze_1.png"
import game_analyze_2 from "./pitchr/game_analyze_2.png"
import game_analyze_3 from "./pitchr/game_analyze_3.png"
import Find_1 from "./pitchr/Find_1.png"
import Find_2 from "./pitchr/Find_2.png"
import Find_3 from "./pitchr/Find_3.png"
import Find_4 from "./pitchr/Find_4.png"
import place_home from "./pitchr/place_home.png"
import place_home_1 from "./pitchr/place_home_1.jpg"
import place_home_2 from "./pitchr/place_home_2.jpg"
import place_home_3 from "./pitchr/place_home_3.jpg"
import place_home_4 from "./pitchr/place_home_4.jpg"
import develop_plate from "./pitchr/develop_plate.png"
import develop_mound from "./pitchr/develop_mound.png"
import develop_side from "./pitchr/develop_side.png"
import develop_outfield from "./pitchr/develop_outfield.png"
import develop_overhead from "./pitchr/develop_overhead.png"

// bcteam

import bcscteam from "./bcteam/bcscteam.png"
import playerprofiles from "./bcteam/playerprofiles.png"
import teamsummary from "./bcteam/teamsummary.png"
import gamereports from "./bcteam/gamereports.png"
import practicereports from "./bcteam/practicereports.png"
import comparison from "./bcteam/comparison.png"
import oneportalendlessdevices from "./bcteam/oneportalendlessdevices.png"
import matchups from "./bcteam/matchups.png"
import effectiveness from "./bcteam/effectiveness.png"
import immediateturnaround from "./bcteam/immediateturnaround.png"
// import customezone from "./bcteam/customezone.png"
import customezone from "./bcteam/customzones.gif"

import touranment_award from "./comapnylanding/touranment_award.png"
import award_mvp_1 from "./comapnylanding/award_mvp_1.png"

import perks from "./perks.jpg"
import gameSense from "./b1c256bd-76d0-4fdb-8ad3-21bf2740c28d.jpg"
import travellogo from "./travellogo.png"

import ncsa from "./ncsa.png"
import ncsaLogoFullColor from "./ncsa-logo-full-color.png"

//commissioner Frontier League Logo
import FrontierLeagueLogo from "./Frontier_League_Logo.png";


export const imagesArr = {
    bclogo, logo, SportsCloud, bcWhitePostLogin, scWhitePostLogin, profile, development, headinglogo1, headinglogo2, headinglogo3, headinglogo4,
    headinglogo5, headinglogo6, headinglogo8, headinglogo9, headinglogo10, footer_logo,footer_SportsCloud, common_img, logo_common, insta, snapchat, fb, calendar, eye, smartphone, eyeopen, search, addteam,
    team1, team2, team3, team4, team5, claim, modal_close, twitter, claim_plus, deletes, thumb_up, white_modal_close, gray_modal_close, facalitiy_logo, team_logo,
    slide1, green_teamwork, green_upload, green_subscription, aquamarine_teamwork, aquamarine_upload, aquamarine_subscription, blue_teamwork, blue_upload, blue_subscription, blue_light_teamwork, blue_light_upload, blue_light_subscription, white_teamwork, white_upload, white_subscription, ballrteam1, ballrteam2, visa_icon, color_visa_icon,
    american_express_icon, color_american_express_icon, mastercard_icon, color_mastercard_icon, discover_icon, color_discover_icon, ballrlogo, user, user_basic, credit_card, ground1, ground2, collage1, collage2, collage3, collage4,
    webgl, cart_user, video_slide1, video_slide2, collage_name1, collage_name2, collage_name3, collage_name4, collage_name5, collage_name6, cancel, edit_profile, ballr_logo, ballr_slider1, ballr_slider2, for_way1, for_way2, for_way3, for_way4, for_way5, for_way6, play, brand_logo1, brand_logo2, brand_logo3, brand_logo4, brand_logo5,
    diamond_kinetics, diamond_kinetics2, diamond_video1, diamond_video2, diamond_video3, diamond_video4, check, playerdashboard, playerprofileicon, playerclippath, playerslider,
    claim_minus, loader, dashboard_user, press_room, press_room_img1, press_room_img2, press_room_img3, press_room_thumb1, press_room_thumb2, press_room_thumb3,
    press_room_thumb4, press_room_thumb5, press_room_thumb6, press_room_thumb7, press_room_thumb8, press_room_thumb9, press_room_thumb10, press_room_thumb11, press_room_thumb12, press_room_thumb13, press_room_thumb14, press_room_thumb15, ourteam1, ourteam2, ourteam3, press_room_thumb_modal1, press_room_thumb_modal2,
    analyzr, forplayer, colleges, agents, facilities, scouting, bullpen, game, batting_practice, scrimmage, camp, reporting_1, reporting_2,
    press_room_thumb_modal3, press_room_thumb_modal4, press_room_thumb_modal5, press_room_thumb_modal6, press_room_thumb_modal7, press_room_thumb_modal8, press_room_thumb_modal9,
    press_room_thumb_modal10, default_user, menu, menu_dc, menu_bcteam, menu_ballr, menu_analyzr, menu_pitchr, diamondconnect, prospectwire, prospectwiresoftball, russmattbaseball, russmattsoftball, greaterorkandobaseball, usatravelball, gemtravelball, main_logo,
    company_logo_1, main_press_room, company_logo_2, company_logo_3, company_logo_4,company_logo_6, setting_bar, banner_img1, banner_slider1, player_spotlight, product_sponsors_1, product_sponsors_2, product_sponsors_3, bar, c_twitter, c_facebook, c_instagram, c_tiktok,
    player_1, company_logo, team_thumb, mvp_1, eventinfo_banner, event_info_bannerlogo, search_icon, map_icon_green, map_icon_blue, map_icon_blue_light, round_arrow, shap, hotel, user_profile, close_shap, white_close, black_slight_search, insurance, link,
    All_American, Data_Driven_Showcases, Data_Games, Next_Gen, Team_Workouts, S_Analyzer, S_Ballr, S_Pitchar, S_BcTeam, S_DK_Logo, S_DK_Product, winner, maximize, map_icon_aquamarine, video, stalker_sports, dimond_kinetics, Alternate_Logo, no_ads, about_slide_1, about_slide_2, about_slide_3, about_slide_4, about_slide_5, showcase_1, showcase_2, showcase_3, showcase_4, showcase_5,
    pws_showcase_1, pws_showcase_2, pws_showcase_3, pws_showcase_4, pws_showcase_5, white_blank, nati_rank, ranking_banner_img, lock_icon, winner_icon1, winner_icon2, winner_icon3, team_info_logo,
    SCTeam, SoftballCloudLogo, user_ranking, lock, down_arrow, awards, events, gallery, infocontact, media, stats, info, productlist, summary, dk_logo, pdf_icon, instagram_icon, twitter_icon, snapchat_icon, facebook_icon,
    location_bg_icon, mail_bg_icon, call_icon, user_icon, location_bg_icon_light, mail_bg_icon_light, call_icon_light, user_icon_light, trophy_1, trophy_2, trophy_3, trophy_4, like_thumb, playstore, appstore,
    gob_about_slide_1, gob_about_slide_2, gob_about_slide_3, gob_about_slide_4, pw_events, mobileapp, dimond_connect, dc_main_mob, event_handel, doubble_mob, partners_1, partners_2, partners_3, partners_group_1, partners_group_2, awards_group, ios_store, and_store, computer, slider_mob_1, slider_mob_2, slider_mob_3, stalkersport_1, stalkersport_2, yakkertech_1, yakkertech_2, game_analyze_1, game_analyze_2, game_analyze_3, Find_1, Find_2, Find_3, Find_4, place_home,
    develop_plate, develop_mound, develop_side, develop_outfield, develop_overhead, touranment_award, award_mvp_1, perks, playerprofiles, teamsummary, gamereports, practicereports, comparison, oneportalendlessdevices, matchups, effectiveness, immediateturnaround, customezone, bcscteam, place_home_1, place_home_2, place_home_3, place_home_4, pw_calender, lock_pw, lock_pws, right_arrow,
    info_green, info_black, edit, upgrade, remove, dc_stats, dimond_connect_black, headingimg, lock_light, info_green_light, upgrade_light, remove_light, edit_light, ncsa, ncsaLogoFullColor, FrontierLeagueLogo, travellogo,
    dc_logo_black,pioneer_logo_black,pioneer_logo_black_1,pioneer_banner_homepage,gameSense
}

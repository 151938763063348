import {
    SAVE_PLAYER_COMPLETE_REQUEST,
    SAVE_PLAYER_COMPLETE_SUCCESS,
    SAVE_PLAYER_COMPLETE_ERROR,
} from '../../../../constants/authantication/signup/player/complete.const';

const initialState = {
    savePlayerComplateRes: false,
    savePlayerComplateData: null,
    savePlayerComplateDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // save complate
        case SAVE_PLAYER_COMPLETE_REQUEST:
            return {
                ...state,
                savePlayerComplateRes: true
            };
        case SAVE_PLAYER_COMPLETE_SUCCESS:
            return {
                ...state,
                savePlayerComplateData: action.payload,
            };
        case SAVE_PLAYER_COMPLETE_ERROR:
            return {
                ...state,
                savePlayerComplateDataError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';


class errorMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    render() {
        const { flag, deleteId, delMsgData } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }

        return (
            <>
                <section className={`common-modal ${flag ? "show" : ""}`} >
                    <div className="modal-dialog">
                        <div className="modal-content sccess_payment">
                            <div className="modal-body">
                                <div className="confirmcancel">
                                    <div className="cancellation">
                                        <img src={imagesArr.cancel} alt="cancel" />
                                    </div>
                                    <h4>
                                        Confirm Cancellation
                                    </h4>
                                    <p>{delMsgData.message}</p>
                                    {
                                        delMsgData.type !== 2 ?
                                            <div className="flex-align">
                                                <Link className="btn gray mr-15" onClick={() => this.props.close()} >Not Now</Link>
                                                <Link className="btn light-green" onClick={() => this.props.delete(deleteId, delMsgData.type)} >Confirm</Link>
                                            </div>
                                            :
                                            <div className="flex-align">
                                                <Link className="btn light-green" onClick={() => this.props.close()} >Ok</Link>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}




export default errorMessage;

import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_PROFILE_INFO_REQUEST,
    GET_PLAYER_PROFILE_INFO_SUCCESS,
    GET_PLAYER_PROFILE_INFO_ERROR,

    GET_SCOUTING_FEED_REQUEST,
    GET_SCOUTING_FEED_SUCCESS,
    GET_SCOUTING_FEED_ERROR,

    GET_LOGIN_RESPONSE_REQUEST,
    GET_LOGIN_RESPONSE_SUCCESS,
    GET_LOGIN_RESPONSE_ERROR,

    SAVE_SOCIAL_MEDIA_REQUEST,
    SAVE_SOCIAL_MEDIA_SUCCESS,
    SAVE_SOCIAL_MEDIA_ERROR,

    GET_COACH_TEAM_LIST_REQUEST,
    GET_COACH_TEAM_LIST_SUCCESS,
    GET_COACH_TEAM_LIST_ERROR,

    SAVE_COACH_DETAILS_REQUEST,
    SAVE_COACH_DETAILS_SUCCESS,
    SAVE_COACH_DETAILS_ERROR,

    GET_USERID_RESPONSE_REQUEST,
    GET_USERID_RESPONSE_SUCCESS,
    GET_USERID_RESPONSE_ERROR,

    DELETE_COACH_DETAILS_REQUEST,
    DELETE_COACH_DETAILS_SUCCESS,
    DELETE_COACH_DETAILS_ERROR,

    SEND_VIEW_COUNT_REQUEST,
    SEND_VIEW_COUNT_SUCCESS,
    SEND_VIEW_COUNT_ERROR,

    DIRECT_RECAP_INFORMATION_REQUEST,
    DIRECT_RECAP_INFORMATION_SUCCESS,
    DIRECT_RECAP_INFORMATION_ERROR,

    GET_PROFILE_FIELDS_REQUEST,
    GET_PROFILE_FIELDS_SUCCESS,
    GET_PROFILE_FIELDS_ERROR,

    GET_PW_SCOUTING_FEED_REQUEST,
    GET_PW_SCOUTING_FEED_SUCCESS,
    GET_PW_SCOUTING_FEED_ERROR,

} from '../../../../constants/view/company/pwRanking/profileSummary.const';
import histrory from '../../../../../History';

export const getPWScoutingFeed = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_PW_SCOUTING_FEED_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/pwScouting/media" : "pwUser/woAuth/pwScouting/media"}`,
        data: data,
        headers: setHeader
    }).then(scouting => {
        dispatch({
            type: GET_PW_SCOUTING_FEED_SUCCESS,
            payload: scouting.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PW_SCOUTING_FEED_ERROR, error: error.response.data.message })
        }
    });
};

export const getProfileFields = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_PROFILE_FIELDS_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}${mode ? `pwUser/leaderboard/field/list?metricType=${data.metricType}&metricSubType=${data.metricSubType}&companyId=${data.companyId}` : `pwUser/leaderboard/field/list?metricType=${data.metricType}&metricSubType=${data.metricSubType}&companyId=${data.companyId}`}`,
        headers: setHeader
    }).then(mainboard => {
        dispatch({
            type: GET_PROFILE_FIELDS_SUCCESS,
            payload: mainboard.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PROFILE_FIELDS_ERROR, error: error.response.data.message })
        }
    });
};

export const getDirectRecapInfo = (eid, age, module) => dispatch => {
    dispatch({ type: DIRECT_RECAP_INFORMATION_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/eventrecap/info/v2/recap`,
        data: module ? {
            id: eid,
            ageGroup: Number(age),
            module: module
        } : {
            id: eid,
            ageGroup: Number(age),
            module: 0
        },
        headers: {
            'Accept': 'application/json'
        }
    }).then(recapInformation => {
        dispatch({
            type: DIRECT_RECAP_INFORMATION_SUCCESS,
            payload: recapInformation.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DIRECT_RECAP_INFORMATION_ERROR, error: error.response.data.message })
        }
    });
};

export const sendCount = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: SEND_VIEW_COUNT_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/video/count" : "pwUser/video/count"}`,
        data: data,
        headers: setHeader
    }).then(saveCount => {
        dispatch({
            type: SEND_VIEW_COUNT_SUCCESS,
            payload: saveCount.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SEND_VIEW_COUNT_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteCoachDetails = (id) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: DELETE_COACH_DETAILS_REQUEST })
    axios({
        method: 'DELETE',
        url: `${EVENT_API_Url}pwUser/remove/tempCoach/${id}`,
        headers: setHeader
    }).then(del => {
        dispatch({
            type: DELETE_COACH_DETAILS_SUCCESS,
            payload: del.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DELETE_COACH_DETAILS_ERROR, error: error.response.data.message })
        }
    });
};

export const saveCoachDetails = (data, type) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: SAVE_COACH_DETAILS_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? type === 'add' ? "pwUser/add/tempCoach" : "pwUser/update/coach" : "pwUser/add/tempCoach"}`,
        data: data,
        headers: setHeader
    }).then(save => {
        dispatch({
            type: SAVE_COACH_DETAILS_SUCCESS,
            payload: save.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_COACH_DETAILS_ERROR, error: error.response.data.message })
        }
    });
};

export const getCoachTeamList = () => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_COACH_TEAM_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/team/list" : "pwUser/team/list"}`,
        data: {},
        headers: setHeader
    }).then(team => {
        dispatch({
            type: GET_COACH_TEAM_LIST_SUCCESS,
            payload: team.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_COACH_TEAM_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const getSummaryPlayerProfile = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_PLAYER_PROFILE_INFO_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/prospectWire" : "pwUser/woAuth/prospectWire"}`,
        data: data,
        headers: setHeader
    }).then(profile => {
        dispatch({
            type: GET_PLAYER_PROFILE_INFO_SUCCESS,
            payload: profile.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_PROFILE_INFO_ERROR, error: error.response.data.message })
        }
    });
};

export const getScoutingFeed = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_SCOUTING_FEED_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/pwScouting" : "pwUser/woAuth/pwScouting"}`,
        data: data,
        headers: setHeader
    }).then(scouting => {
        dispatch({
            type: GET_SCOUTING_FEED_SUCCESS,
            payload: scouting.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_SCOUTING_FEED_ERROR, error: error.response.data.message })
        }
    });
};

export const getLoginResponse = () => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_LOGIN_RESPONSE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "showcase/login/response" : "showcase/login/response"}`,
        data: {companyId: localStorage.getItem("cid")},
        headers: setHeader
    }).then(loginRes => {
        dispatch({
            type: GET_LOGIN_RESPONSE_SUCCESS,
            payload: loginRes.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LOGIN_RESPONSE_ERROR, error: error.response.data.message })
        }
    });
};

export const getUserIDResponse = () => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_USERID_RESPONSE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "showcase/login/response" : "showcase/login/response"}`,
        data: {},
        headers: setHeader
    }).then(loginRes => {
        dispatch({
            type: GET_USERID_RESPONSE_SUCCESS,
            payload: loginRes.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_USERID_RESPONSE_ERROR, error: error.response.data.message })
        }
    });
};

export const saveSocialMediaLink = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: SAVE_SOCIAL_MEDIA_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/edit/social/link" : "pwUser/edit/social/link"}`,
        data: data,
        headers: setHeader
    }).then(save => {
        dispatch({
            type: SAVE_SOCIAL_MEDIA_SUCCESS,
            payload: save.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_SOCIAL_MEDIA_ERROR, error: error.response.data.message })
        }
    });
};



import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import update from "react-addons-update";
import { Link, useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { getDivision, getRosterInfo, postRosterInfo, sendMailRosterPlayer, sendAllMailRosterPlayer, deleteConfirmation } from "../../../saga/actions/views/coach/profile/rosterInfo.action";
import AddManually from "../../popup/addManually";
import commonCalls from "../../../businesslogic/commonCalls";
import { ROSTERINFO_SUCCESS, MAIL_ROSTERINFO_SUCCESS, ALL_MAIL_ROSTERINFO_SUCCESS, DELETE_ROSTER_CONFIRMATION_SUCCESS } from "../../../saga/constants/view/coach/profile/rosterInfo.const";
import { REGISTERINFO_SUCCESS } from '../../../saga/constants/view/coach/profile/personalInfo.const';
import Preloader from '../../../components/preloader';
import Confirmation from '../../popup/confirmation';
import { imagesArr } from "../../../assets/images";
import ImageCrop from '../../popup/imageCrop';
import { postFileUpload } from "../../../saga/actions/common/api.action";
import { FILEUPLOAD_SUCCESS } from "../../../saga/constants/common/api.const";
import heic2any from "heic2any";

const initialState = {
  tabsfacility: 0,
  tabsupload: 1,
  file: null,
  facilityRoster: [],
  teamRoster: [],
  division: "",
  record: "",
  manuallyRecord: {},
  newManually: [],
  loader: false,
  isMLB: false,
  mailConfirmation: false,
  recIndex: "",
  playerId: "",
  allMailConfirmation: false,
  rid: "",
  parentLogo: "",
  imgObj: "",
  cropPopup: false,
  renderFile: "",
  shopFlg: false,
  parentTeam:""
};
const reducer = (state, payload) => ({ ...state, ...payload });

function RosterInfo({ coachLevel }) {
  const commonCall = new commonCalls();
  const validator = useRef(new SimpleReactValidator());
  const validatorAgeGrp = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [roster, setRoster] = useReducer(reducer, initialState);
  const { tabsfacility, tabsupload, facilityRoster, teamRoster, file, record, manuallyRecord, loader, newManually, mailConfirmation, recIndex, playerId, allMailConfirmation, rid, parentLogo,parentTeam } = roster;
  const { rosterInfoData: _RI, mailRosterInfoData: _MRI, allMailRosterInfoData: _AMRI, deleteRosterConfirmData: _DRC } = useSelector((state) => state.rosterInfo);
  const { registerInfoData: _REG } = useSelector((state) => state.personalInfo);
  const { divisonObj: _DIV, divisonList: _DIVLIST } = useSelector((state) => state.rosterInfo);
  const { fileUploadData: _LOGO } = useSelector((state) => state.api);
  const [isNewRoster, setIsNewRoster] = useState(false);
  const [isSection1, setIsSection1] = useState(true);
  const [isSection2, setIsSection2] = useState(false);
  const [openRoster, setOpenRoster] = useState(false);
  const [openConfimation, setOpenConfimation] = useState({
    roster: false,
    manually: false
  });
  const [deleteManuallyIndex, setDeleteManuallyIndex] = useState(null);
  const [deleteRosterIndex, setDeleteRosterIndex] = useState(null);
  const [index, setIndex] = useState(null);
  const [manuallyIndex, setManuallyIndex] = useState(0);
  const [type, setType] = useState("Add");
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [isFinish, setFinish] = useState(false);
  const [openTwitterLink, setOpenTwitterLink] = useState(false);
  const [openInstaLink, setOpenInstaLink] = useState(false);
  const [backupData, setBackupData] = useState([]);
  const [rosterConfirmation, setRosterConfirmation] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("noAge")) {
      setIsSection1(false);
      setIsSection2(true);
      setIsNewRoster(true);
      setRoster({
        ...roster, record: {
          rosterName: "",
          ageGroup: "",
          divison: "",
          "upload-roster": []
        }
      });
    }
    setLoader(true);
    getRosterInfo()(dispatch);
    getDivision()(dispatch);
  }, []);

  useEffect(() => {
    if (_RI) {
      console.log("_RI::---", _RI);
      setRoster({
        ...roster,
        facilityRoster: _RI.hasOwnProperty("facilityRoster") ? _RI.facilityRoster : [],
        teamRoster: _RI.hasOwnProperty("teamRoster") ? _RI.teamRoster : [],
        isMLB: _RI.isMLB,
        parentLogo: _RI.teamlogo,
        parentTeam: _RI?.parentTeam?.type
        // record: tabsfacility === 0 ? _RI.teamRoster[index] : _RI.facilityRoster[index],
      });
      setLoader(false);
      if (localStorage.getItem("manageRid")) {
        let item = _RI.teamRoster.find(_ => _._id === localStorage.getItem("manageRid"))
        let itemIndex = _RI.teamRoster.findIndex(_ => _._id === localStorage.getItem("manageRid"))
        fetchRoster(item, itemIndex);
      }
    }
  }, [_RI]);

  useEffect(() => {
    if (_MRI) {
      console.log("_MRI::---", _MRI);
      if (_MRI.flag) {
        setRoster({
          ...roster,
          mailConfirmation: false,
        });
        document.body.classList.remove("modal-open");
        setLoader(false);
        dispatch({
          type: MAIL_ROSTERINFO_SUCCESS,
          payload: null,
        });
      } else {
        commonCall.errorMessage(_MRI.message);
        dispatch({
          type: MAIL_ROSTERINFO_SUCCESS,
          payload: null,
        });
        record["upload-roster"].forEach((item, j) => {
          if (recIndex === j) {
            Object.assign(item, { isMail: !item.isMail });
          }
        });
        setRoster({
          ...roster,
          record: record,
        });
        setLoader(false);
        console.log("record:--", recIndex, record);
      }
    }
  }, [_MRI]);

  useEffect(() => {
    if (_REG) {
      if (_REG.flag) {
        commonCall.successMessage(_REG.message);
        dispatch({
          type: ROSTERINFO_SUCCESS,
          payload: _REG.result,
        });
        setRoster({
          record: null
        })
      } else if (!_REG.flag) {
        commonCall.errorMessage(_REG.message);
        // dispatch({
        //   type: ROSTERINFO_SUCCESS,
        //   payload: null,
        // });
        getRosterInfo()(dispatch)
      }
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: null,
      });
      setLoader(false);
      if (localStorage.getItem("noAge")) {
        setFinish(true);
      }
    }
  }, [_REG])

  useEffect(() => {
    if (_AMRI) {
      console.log("_AMRI::---", _AMRI);
      if (_AMRI.flag) {
        setRoster({
          ...roster,
          allMailConfirmation: false,
        });
        document.body.classList.remove("modal-open");
        setLoader(false);
        dispatch({
          type: ALL_MAIL_ROSTERINFO_SUCCESS,
          payload: null,
        });
      } else {
        commonCall.errorMessage(_AMRI.message);
        dispatch({
          type: ALL_MAIL_ROSTERINFO_SUCCESS,
          payload: null,
        });
        record["upload-roster"].forEach((item, j) => {
          Object.assign(item, { isMail: false });
        });
        setRoster({
          ...roster,
          record: record,
        });
        setLoader(false);
      }
    }
  }, [_AMRI]);

  useEffect(() => {
    if (record) {
      setIsAllCheck(record.sendMail)
    }
  }, [record]);

  useEffect(() => {
    if (_LOGO) {
      record['teamLogo'] = _LOGO.filePath
      setRoster({
        record: record,
        imgObj: "",
        cropPopup: false,
        renderFile: ""
      });
      document.body.classList.remove('modal-open');
      setLoader(false);
      dispatch({
        type: FILEUPLOAD_SUCCESS,
        payload: null,
      });
    }
  }, [_LOGO]);

  useEffect(() => {
    console.log("_DRC:--", _DRC);
    if (_DRC) {
      if (_DRC.flag) {
        dispatch({
          type: DELETE_ROSTER_CONFIRMATION_SUCCESS,
          payload: null,
        });
        setLoader(false);
        setOpenConfimation({
          ...openConfimation,
          roster: true
        });
        setDeleteRosterIndex(backupData);
      } else {
        setBackupData(_DRC.message);
        setRosterConfirmation(true);
        dispatch({
          type: DELETE_ROSTER_CONFIRMATION_SUCCESS,
          payload: null,
        });
        setLoader(false);
      }
    }
  }, [_DRC])

  const handleTabsFacility = (e) => {
    if (record && !isNewRoster) {
      record.type = e === 0 ? 1 : 2
      if (e === 0) {
        facilityRoster.splice(index, 1);
        teamRoster.push(record);
        setIndex(teamRoster.length - 1)

      } else {
        teamRoster.splice(index, 1);
        facilityRoster.push(record);
        setIndex(facilityRoster.length - 1)
      }
    }
    setRoster({
      tabsfacility: e,
      facilityRoster: facilityRoster,
      teamRoster: teamRoster,
      record: record
    });
  };

  const handleTabsUpload = (e) => {
    setRoster({
      tabsupload: e,
    });
  };

  const uploadFile = (ev) => {
    ev.preventDefault();
    if (ev.target.files[0].name.match(new RegExp('[^.]+$'))[0] !== "csv") {
      return commonCall.errorMessage("Accept only CSV file");
    }
    var files = ev.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = (ev) => {
      var data = ev.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      var rosters = [];
      const re = /^[0-9\b]+$/;
      let isValid = true;

      let breakCondition = false;
      dataParse.forEach((item, i) => {
        if (dataParse[i].length !== 0) {
          var roster = {};
          if (parentTeam === 2 || parentTeam === 3) {

            //no duplicate record in player list
            if (!!record["upload-roster"].find((e) => e.email === item[3]) && !breakCondition && i != 0) {
              breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ",Email already exits.")
            }
            //no duplicate record in CSV file
            if (!!dataParse.find((e, j) => { if (j !== i) { if (e[3] === item[3]) { return true } else return false } }) && !breakCondition && i != 0) {
              breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ",which has a duplicate email")
            }

            if (item[0] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", first name is required field."); };
            if (item[1] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", last name is required field."); };
            // if (item[2] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", phone no. is required field."); };
            // if (item[3] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", email is required field."); };
            // if (item[4] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", number is required field."); };
            if (item[5] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", position is required field."); };
            if (setDate(item[7]) === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", birthday is required field."); };
            if (item[2] !== undefined && !breakCondition) {
              if (!breakCondition && item[2].toString().length <= 12 && i != 0) {
                if (!re.test(item[2]) || item[2].length <= 0) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ",Enter valid phone number.");; }
              }
              if (!breakCondition && item[2].toString().length > 12 && i != 0) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ",Enter valid phone number."); }
            }
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(item[3]) && i != 0 && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ",Enter valid email."); }
            var roster = {};
            roster.fname = item[0];
            roster.lname = item[1];
            roster.country_code = "+1";
            roster.phone = item[2];
            roster.email = item[3];
            roster.number = item[4];
            roster.position = (item[5] === "Catcher" || item[5] === "catcher" || item[5] === "C" || item[5] === "c") ? "Catcher" :
              (item[5] === "Pitcher" || item[5] === "pitcher" || item[5] === "RHP" || item[5] === "rhp" || item[5] === "LHP" || item[5] === "lhp" || item[5] === "P" || item[5] === "p") ? "Pitcher" :
                (item[5] === "1B" || item[5] === "1b" || item[5] === "First Base" || item[5] === "first base") ? "1B" :
                  (item[5] === "2B" || item[5] === "2b" || item[5] === "Second Base" || item[5] === "second base") ? "2B" :
                    (item[5] === "3B" || item[5] === "3b" || item[5] === "Third Base" || item[5] === "third base") ? "3B" :
                      (item[5] === "SS" || item[5] === "ss" || item[5] === "Shortstop" || item[5] === "shortstop") ? "SS" :
                        (item[5] === "Infield" || item[5] === "infield" || item[5] === "INF" || item[5] === "inf" || item[5] === "Inf") ? "Infield" :
                          (item[5] === "Outfield" || item[5] === "outfield" || item[5] === "OF" || item[5] === "of" || item[5] === "Of") ? "Outfield" :
                            (item[5] === "Utility" || item[5] === "utility" || item[5] === "U" || item[5] === "u" || item[5] === "Util" || item[5] === "util") ? "Utility" : item[5];
            roster.position2 = (item[6] === "Catcher" || item[6] === "catcher" || item[6] === "C" || item[6] === "c") ? "Catcher" :
              (item[6] === "Pitcher" || item[6] === "pitcher" || item[6] === "RHP" || item[6] === "rhp" || item[6] === "LHP" || item[6] === "lhp" || item[6] === "P" || item[6] === "p") ? "Pitcher" :
                (item[6] === "1B" || item[6] === "1b" || item[6] === "First Base" || item[6] === "first base") ? "1B" :
                  (item[6] === "2B" || item[6] === "2b" || item[6] === "Second Base" || item[6] === "second base") ? "2B" :
                    (item[6] === "3B" || item[6] === "3b" || item[6] === "Third Base" || item[6] === "third base") ? "3B" :
                      (item[6] === "SS" || item[6] === "ss" || item[6] === "Shortstop" || item[6] === "shortstop") ? "SS" :
                        (item[6] === "Infield" || item[6] === "infield" || item[6] === "INF" || item[6] === "inf" || item[6] === "Inf") ? "Infield" :
                          (item[6] === "Outfield" || item[6] === "outfield" || item[6] === "OF" || item[6] === "of" || item[6] === "Of") ? "Outfield" :
                            (item[6] === "Utility" || item[6] === "utility" || item[6] === "U" || item[6] === "u" || item[6] === "Util" || item[6] === "util") ? "Utility" : item[6];
            roster.dob = setDate(item[7]);
          }
        else {
            if (item[0] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", first name is required field."); };
            if (item[1] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", last name is required field."); };
            if (item[2] === undefined && !breakCondition) { breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ", jersey number is required field."); } 
            if (item[2] !== undefined && !breakCondition && item[2].toString().length > 2 && i != 0) {
                breakCondition = true; isValid = false; return commonCall.errorMessage("Please check row " + i + ",Enter upto 2 digit jersey number.");
            }
         
            roster.fname = item[0];
            roster.lname = item[1];
            roster.number = item[2];
         
        }
        roster.role = "Player";
        roster.isMail = false;
        rosters.push(roster);
      }
    });
      rosters.splice(0, 1);

      setRoster({
        tabsupload: 1,
      });

      if (isValid) {
        let uploadRoster = record["upload-roster"];
        if (isNewRoster) {
          setRoster({
            ...roster,
            record: {
              ...roster.record,
              "upload-roster": rosters
            },
            tabsupload: 1,
          })
        } else {
          rosters.map((item) => {
            uploadRoster.push(item);
          });
          setRoster({
            ...roster,
            record: {
              ...roster.record,
              "upload-roster": uploadRoster
            },
            tabsupload: 1,
          })
        }
      }
    };
    reader.readAsBinaryString(f);
  };

  const setDate = (date) => {
    let fDate = XLSX.SSF.format("yyyy-mm-dd", date);
    return fDate;
  };

  //fetch Roster Record
  const fetchRoster = (e, index) => {
    let arr = e?.teamRecord && e?.teamRecord.split("-");
    e['you'] = e?.teamRecord ? arr.length !== 0 && arr[0] : "";
    e['opp'] = e?.teamRecord ? arr.length !== 0 && arr[1] : "";
    e['isEdited'] = true;
    setRoster({ record: e, shopFlg: e?.shoplink ? "yes" : 'no' });
    setIndex(index);
    setIsSection1(false);
    setIsSection2(true);
  };

  //fetch manually Record
  const fetchManually = (e, index) => {
    setRoster({ manuallyRecord: e });
    setManuallyIndex(index);
    setOpenRoster(!openRoster);
    setType("Edit");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "you" || name === "opp") {
      const re = /^[0-9\b]+$/;
      if (re.test(value) || value.length <= 0) return setRoster({ record: { ...record, [name]: value } });
    }
    else return setRoster({ record: { ...record, [name]: value } });
  };

  const chkHandleChange = (e, i, pid) => {
    if (!JSON.parse(e.target.value)) {
      setRoster({
        ...roster,
        recIndex: i,
        playerId: pid,
        mailConfirmation: true
      });
    } else {
      record["upload-roster"].forEach((item, j) => {
        if (i === j) {
          Object.assign(item, { isMail: !item.isMail });
        }
      });
      setRoster({
        ...roster,
        record: record,
      });
      setLoader(true);
      sendMailRosterPlayer({ rosterPlayerId: pid, isMail: false })(dispatch);
    }
  }

  const changeSection = () => {
    setIsSection1(true);
    setIsSection2(false);
    setIsNewRoster(false);
    setRoster({ record: null });
  };

  const handleNewRoster = () => {
    setIsSection1(false);
    setIsSection2(true);
    setIsNewRoster(true);
    setRoster({
      ...roster, record: {
        rosterName: "",
        ageGroup: "",
        divison: "",
        "upload-roster": []
      }
    });
  };

  // save Manually Data
  const saveManually = (rosters) => {
    if (type === "Add") return setRoster({
      ...roster,
      record: {
        ...roster.record,
        "upload-roster": [...roster.record["upload-roster"], rosters]
      },
      tabsupload: 1,
    })
    if (type === "Edit") {
      record["upload-roster"][manuallyIndex] = rosters
    }
  }

  // Add and Edit roster
  const handleSaveRoster = (data) => {
    localStorage.removeItem("manageRid");
    console.log("roster", roster.record);
    if (record && (record.competitionLevel === "Youth" || record.competitionLevel === "High School")) {
      if (!validator.current.allValid() || !validatorAgeGrp.current.allValid()) {
        validator.current.showMessages(true);
        validatorAgeGrp.current.showMessages(true);
        forceUpdate(1);
      } else {
        modifyRoster();
      }
    } else {
      if (validator.current.allValid()) {
        modifyRoster();
      } else {
        validator.current.showMessages(true);
        forceUpdate(1);
      }
    }
  };


  const modifyRoster = () => {
    changeSection();
    let dRoster;
    if (isNewRoster) {
      dRoster = {
        rosterName: record.rosterName,
        ageGroup: record.ageGroup,
        competitionLevel: record.competitionLevel,
        divison: record.divison,
        teamLogo: record.teamLogo ? record.teamLogo : "",
        info: record.info,
        teamRecord: (record.you ? record.you : "") + '-' + (record.opp ? record.opp : ""),
        'social-link': record['social-link'],
        shoplink: roster.shopFlg === "yes" ? record.shoplink : "",
        isMarchandise: record.shoplink ? false : record.isMarchandise,
        "upload-roster": record["upload-roster"] ? record["upload-roster"].length !== 0 ? record["upload-roster"] : [] : [],
        ageGroup: (record.competitionLevel === "Youth" || record.competitionLevel === "High School") ? record.ageGroup : "",
      };
      delete record.you;
      delete record.opp;
      if (tabsfacility === 0) return updateTeam(update(teamRoster, { $push: [dRoster] }));
      if (tabsfacility === 1) return updateFaciliy(update(facilityRoster, { $push: [dRoster] }));

    } else {
      // dRoster = update(teamRoster[index], {
      //   rosterName: { $set: record.rosterName },
      //   ageGroup: { $set: record.ageGroup },
      //   divison: { $set: record.divison },
      //   "upload-roster": { $set: record["upload-roster"] },
      // });
      // if (tabsfacility === 0) return updateTeam(update(teamRoster, { $splice: [[index, 1, dRoster]], }));
      // if (tabsfacility === 1) return updateFaciliy(update(facilityRoster, { $splice: [[index, 1, dRoster]], }));
      record.teamRecord = (record.you ? record.you : "") + '-' + (record.opp ? record.opp : "");
      record.ageGroup = (record.competitionLevel === "Youth" || record.competitionLevel === "High School") ? record.ageGroup : "";
      delete record.you;
      delete record.opp;
      record.shoplink = roster.shopFlg === "yes" ? record.shoplink : "";
      record.isMarchandise = record.shoplink ? false : record.isMarchandise;
      if (tabsfacility === 0) {
        teamRoster[index] = record
      } else {
        facilityRoster[index] = record
      }
      setLoader(true);
      postRosterInfo({ teamRoster: teamRoster, facilityRoster: facilityRoster })(dispatch);
    }
  }

  // delete  roster info
  const handleDeleteRoster = () => {
    setOpenConfimation({
      ...openConfimation,
      roster: false
    });
    setDeleteRosterIndex(null);
    setBackupData([]);
    document.body.classList.remove("modal-open");
    if (tabsfacility === 0) {
      updateTeam(
        update(teamRoster, {
          $splice: [[deleteRosterIndex, 1]],
        })
      );
    } else if (tabsfacility === 1) {
      updateFaciliy(
        update(facilityRoster, {
          $splice: [[deleteRosterIndex, 1]],
        })
      );
    }
  };

  //for Manually
  const openConfimationPopup = (e) => {
    setOpenConfimation({
      ...openConfimation,
      manually: true
    });
    setDeleteManuallyIndex(e);
  }

  //for Manually
  const confirmPopupRoster = (data, e) => {
    console.log("delete info:--", data, e);
    setLoader(true);
    deleteConfirmation({ rosterTeamId: data._id })(dispatch);
    setBackupData(e)
  }

  //delete Manually info
  const hanldeDeleteManually = () => {
    setOpenConfimation({
      ...openConfimation,
      manually: false
    });
    setDeleteManuallyIndex(null);
    document.body.classList.remove("modal-open");
    setRoster({
      ...roster,
      record: {
        ...roster.record,
        "upload-roster": roster.record["upload-roster"].filter((item, i) => i !== deleteManuallyIndex)
      },
      tabsupload: 1,
    })
  };

  //for posting data
  const updateData = (fac, team) => {
    if (fac) return updateFaciliy(fac);
    updateTeam(team);
  };

  const updateTeam = (data) => {
    console.log("data:---", data);
    setRoster({
      teamRoster: data,
      record: data[index],
    });
    setLoader(true);
    postRosterInfo({ teamRoster: data })(dispatch);

    // dispatch({
    //   type: ROSTERINFO_SUCCESS,
    //   payload: { ..._RI, teamRoster: data },
    // });
  };

  const updateFaciliy = (data) => {
    setRoster({
      facilityRoster: data,
      record: data[index],
    });
    setLoader(true);
    postRosterInfo({ facilityRoster: data })(dispatch);

    // dispatch({
    //   type: ROSTERINFO_SUCCESS,
    //   payload: { ..._RI, facilityRoster: data },
    // });
  };

  const onHandleClose = () => {
    document.body.classList.remove("modal-open");
    setOpenRoster(!openRoster);
    setRoster({ manuallyRecord: null });
    setType("Add");
  };

  const setLoader = (val) => {
    setRoster({
      loader: val
    });
  }

  const closeDeletePopup = () => {
    document.body.classList.remove("modal-open");
    setDeleteManuallyIndex(null);
    setOpenConfimation({
      ...openConfimation,
      manually: false
    })
  }

  const closeDeletePopupRoster = () => {
    document.body.classList.remove("modal-open");
    setDeleteRosterIndex(null);
    setBackupData([]);
    setOpenConfimation({
      ...openConfimation,
      roster: false
    })
  }

  const confirmCloseRoster = () => {
    document.body.classList.remove("modal-open");
    setBackupData([]);
    setRosterConfirmation(false);
  }

  const closeMail = () => {
    document.body.classList.remove("modal-open");
    setRoster({
      ...roster,
      mailConfirmation: false
    });
  }

  const hanldeMail = () => {
    record["upload-roster"].forEach((item, j) => {
      if (recIndex === j) {
        Object.assign(item, { isMail: !item.isMail });
      }
    });
    setRoster({
      ...roster,
      record: record,
    });
    setLoader(true);
    sendMailRosterPlayer({ rosterPlayerId: playerId, isMail: true })(dispatch);
  }

  const allChkHandle = (e, data) => {
    console.log("value:---", !JSON.parse(e.target.value));
    setIsAllCheck(!isAllCheck);
    if (!JSON.parse(e.target.value)) {
      setRoster({
        ...roster,
        rid: data._id,
        allMailConfirmation: true
      });
    } else {
      record["upload-roster"].forEach((item, j) => {
        Object.assign(item, { isMail: false });
      });
      setRoster({
        ...roster,
        record: record,
      });
      setLoader(true);
      sendAllMailRosterPlayer({ rosterId: data._id, sendMail: false })(dispatch);
    }
  }

  const closeAllMail = () => {
    document.body.classList.remove("modal-open");
    setIsAllCheck(!isAllCheck);
    setRoster({
      ...roster,
      allMailConfirmation: false,
      rid: ""
    });
  }

  const hanldeAllMail = () => {
    record["upload-roster"].forEach((item, j) => {
      Object.assign(item, { isMail: true });
    });
    setRoster({
      ...roster,
      record: record,
    });
    setLoader(true);
    sendAllMailRosterPlayer({ rosterId: rid, sendMail: true })(dispatch);
  }

  const changeImage = async (event) => {
    let file = event.target.files[0];
    // if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png" ) {
    //   return commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC,HEIF file");
    // }

    setLoader(true);

    //if HEIC file
    if (event.target.files[0] && (file.name.includes(".heic") || file.name.includes(".heif"))) {
      // get image as blob url
      var blob = event.target.files[0]; //ev.target.files[0];
      heic2any({
        blob: blob,
        toType: "image/jpg",
      })
        .then(function (resultBlob) {
          let file = new File([resultBlob], "heic" + ".jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
          setRoster({
            imgObj: file,
            cropPopup: true,
            renderFile: URL.createObjectURL(file)
          });
          setLoader(false);
        })
        .catch(function (x) {
          setLoader(false);
          console.log(x.code);
          console.log(x.message);
        });
    } else {
      setLoader(false);
      setRoster({
        imgObj: file,
        cropPopup: true,
        renderFile: URL.createObjectURL(file)
      });
    }

  };

  const removeImage = () => {
    setRoster({ record: { ...record, teamLogo: null } });
  };

  const onHadleObject = (obj) => {
    setLoader(true);
    postFileUpload(obj.file, 10)(dispatch);
  }

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    setRoster({
      cropPopup: false
    });
  }
  const onlyNumber = event => {
    const keycode = event.which;
    if (keycode === 13) {
    } else if (
      !(
        event.shiftKey === false &&
        (keycode === 8 ||
          keycode === 37 ||
          keycode === 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    )
      event.preventDefault();
  };

  const handleTwitterEvent = () => {
    record['social-link'] = {
      ...record['social-link'],
      twt: record['social-link'].twt
    }
    setRoster({ record: record });
    setOpenTwitterLink(false);
  };

  const handleInstagramEvent = () => {
    record['social-link'] = {
      ...record['social-link'],
      insta: record['social-link'].insta
    }
    setRoster({ record: record });
    setOpenInstaLink(false);
  }

  const closeFinishPopup = () => {
    setFinish(false);
    document.body.classList.remove('modal-open');
    if (localStorage.getItem("noLeagueAge")) {
      history.push(`/league-detail/${localStorage.getItem("noAge")}`);
    } else {
      history.push(`/event-detail/${localStorage.getItem("noAge")}`);
    }
  }

  const finishClose = () => {
    setFinish(false);
    document.body.classList.remove('modal-open');
    localStorage.removeItem("noAge");
    localStorage.removeItem("noLeagueAge");
  }

  const copyContant = (roster, i) => {
    //Copy + Paste URL
    let input = document.createElement('input');
    input.type = "text";
    input.value = `${process.env.REACT_APP_WEB_LINK}/account-creation/${roster._id}/1`;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    let button = document.getElementById(`copy${i}`);
    button.className = "tooltips active"; 
    setInterval(() => {
      button.className = "tooltips"; 
    }, 2000);
  }

  return (
    <>
      {isSection1 && (
        <section className="before-dashboard">
          <div className="white-bg">
            <div className="facilitytab">
              <ul className="tabsfacility">
                <li className={`${tabsfacility === 0 ? "active" : ""}`} onClick={() => handleTabsFacility(0)}><Link>Team</Link></li>
                <li className={`${tabsfacility === 1 ? "active" : ""}`} onClick={() => handleTabsFacility(1)}><Link>Facility</Link> </li>
              </ul>

              {tabsfacility === 0 ? (
                <>
                  <div className="tabsfacility-view">
                    <div className="manage_roster">
                      <div className="table-responsive-xl overflow-horizontal-auto">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">Roster Name</th>
                              <th scope="col">Age Group</th>
                              <th scope="col">Competition Level</th>
                              <th scope="col"></th>
                              <th colSpan="2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_RI && _RI.hasOwnProperty('teamRoster') && teamRoster.length != 0 ?
                              teamRoster.map((e, index) => {
                                return (
                                  <tr key={index}>
                                    <th>0{index + 1}</th>
                                    <td>{e.rosterName}</td>
                                    <td>{e.ageGroup ? e.ageGroup == 19 ? "18U+" : e.ageGroup + "U" : "-"}</td>
                                    <td>{e.competitionLevel ? e.competitionLevel : "-"}</td>
                                    <td className="common_btn copyBtn" ><a className="btn light-green w-100" onClick={() => copyContant(e, index)}>Direct Roster Link<b className="tooltips" id={`copy${index}`}>Copied</b></a></td>
                                    <td className="common_btn"> <a className="blue_btn w-100" onClick={() => fetchRoster(e, index)}>Manage Roster </a></td>
                                    <td className="common_btn"><a className="red_btn w-100" onClick={() => confirmPopupRoster(e, index)}>Delete</a></td>
                                  </tr>
                                );
                              }) :
                              <>
                                <tr>
                                  <td colSpan="6" style={{ textAlign: 'center' }}>No data found</td>
                                </tr>
                              </>}
                          </tbody>
                        </table>
                      </div>
                      <a className="gray_btn w-100" onClick={handleNewRoster}>+ Add New Roster</a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="tabsfacility-view">
                    <div className="manage_roster">
                      <div className="table-responsive-xl">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">Roster Name</th>
                              <th scope="col">Age Group</th>
                              <th scope="col">Competition Level</th>
                              <th scope="col"></th>
                              <th colSpan="2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_RI && _RI.hasOwnProperty('facilityRoster') && facilityRoster.length != 0 ?
                              facilityRoster.map((e, index) => {
                                return (
                                  <tr key={index}>
                                    <th>0{index + 1}</th>
                                    <td>{e.rosterName}</td>
                                    <td>{e.ageGroup ? e.ageGroup + "U" : "-"}</td>
                                    <td>{e.competitionLevel ? e.competitionLevel : "-"}</td>
                                    <td className="common_btn copyBtn" ><a className="btn light-green w-100" onClick={() => copyContant(e, index)}>Direct Roster Link <b className="tooltips" id={`copy${index}`} >Copied</b></a></td>
                                    <td className="common_btn"><a className="blue_btn w-100" onClick={() => fetchRoster(e, index)}>Manage Roster</a></td>
                                    <td className="common_btn"><a className="red_btn w-100" onClick={() => confirmPopupRoster(e, index)}> Delete</a></td>
                                  </tr>
                                );
                              }) :
                              <>
                                <tr>
                                  <td colSpan="6" style={{ textAlign: 'center' }}>No data found</td>
                                </tr>
                              </>}
                          </tbody>
                        </table>
                      </div>
                      <a className="gray_btn w-100" onClick={handleNewRoster}>+ Add New Roster</a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      )}
      {isSection2 && (
        <section className="before-dashboard">
          <div className="white-bg">
            <div className="check_input row">
              <div className="check_inputbox">
                <label className="common-lable">Roster Name</label>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Enter roster name" id="usr" name="rosterName" value={record ? record.rosterName : ""} onChange={handleChange} />
                  <span className="validMsg">{validator.current.message("Roster Name", record && record.rosterName, "required", { className: "text-danger" })}</span>
                </div>
              </div>
              <div className="check_inputbox">
                <label className="common-lable">
                  Is this roster for a team or facility?
                </label>
                <div className="form-group">
                  <div className="multi-radio">
                    <div className="check_inputradio">
                      <label className="round-radio"> Team
                        <input type="radio" id="team" name="roster" value="team" checked={tabsfacility === 0 && true} onChange={() => handleTabsFacility(0)} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="check_inputradio">
                      <label className="round-radio"> Facility
                        <input type="radio" id="facility" name="roster" value="facility" checked={tabsfacility === 1 && true} onChange={() => handleTabsFacility(1)} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="check_input row">
              <div className="check_inputbox">
                <label className="common-lable">Competition Level</label>
                <select name="competitionLevel" className="custom-select" onChange={handleChange}>
                  <option value="" selected disabled>Competition Level</option>
                  <option value="Youth" selected={record && record.competitionLevel === "Youth" ? "selected" : ""}>Youth</option>
                  <option value="High School" selected={record && record.competitionLevel === "High School" ? "selected" : ""}>High School</option>
                  <option value="College" selected={record && record.competitionLevel === "College" ? "selected" : ""}>College</option>
                  <option value="Independent" selected={record && record.competitionLevel === "Independent" ? "selected" : ""}>Independent</option>
                  <option value="MLB" selected={record && record.competitionLevel === "MLB" ? "selected" : ""}>MLB</option>
                </select>
                <span className="validMsg">{validator.current.message("competition level", record && record.competitionLevel, "required", { className: "text-danger" })}</span>
              </div>

              {/* {(coachLevel !== 2 && coachLevel !== 3) &&
                <div className="check_inputbox">
                  <label className="common-lable">Division</label>
                  <select name="divison" className="custom-select" onChange={handleChange}>
                    <option value="">N/A</option>
                    {_DIVLIST.map((option) => (
                      <option key={option._id} value={option._id} selected={record && record.divison === option._id ? "selected" : ""}>{option.title}</option>
                    ))}
                  </select>
                    // <span className="validMsg">{validator.current.message("Divison", record && record.divison, "required", { className: "text-danger" })}</span> 
                </div>} */}

              {record && (record.competitionLevel === "Youth" || record.competitionLevel === "High School") && <div className="check_inputbox">
                <label className="common-lable">Age Group</label>
                <select name="ageGroup" className="custom-select" onChange={handleChange}>
                  <option value="" selected disabled>Select age group</option>
                  {record && record.competitionLevel === "Youth" &&
                    <>
                      <option value="6" selected={record && record.ageGroup === "6" ? "selected" : ""}>6U</option>
                      <option value="7" selected={record && record.ageGroup === "7" ? "selected" : ""}>7U</option>
                      <option value="8" selected={record && record.ageGroup === "8" ? "selected" : ""}>8U</option>
                      <option value="9" selected={record && record.ageGroup === "9" ? "selected" : ""}>9U</option>
                      <option value="10" selected={record && record.ageGroup === "10" ? "selected" : ""}>10U</option>
                      <option value="11" selected={record && record.ageGroup === "11" ? "selected" : ""}>11U</option>
                      <option value="12" selected={record && record.ageGroup === "12" ? "selected" : ""}>12U</option>
                      <option value="13" selected={record && record.ageGroup === "13" ? "selected" : ""}>13U</option>
                      <option value="14" selected={record && record.ageGroup === "14" ? "selected" : ""}>14U</option> </>}
                  {record && record.competitionLevel === "High School" &&
                    <>
                      <option value="15" selected={record && record.ageGroup === "15" ? "selected" : ""}>15U</option>
                      <option value="16" selected={record && record.ageGroup === "16" ? "selected" : ""}>16U</option>
                      <option value="17" selected={record && record.ageGroup === "17" ? "selected" : ""}>17U</option>
                      <option value="18" selected={record && record.ageGroup === "18" ? "selected" : ""}>18U</option> 
                      <option value="19" selected={record && record.ageGroup === "19" ? "selected" : ""}>18U+</option> 
                      </>
                      }
                  {/* <option value="Independent" selected={record && record.ageGroup === "Independent" ? "selected" : ""}>Independent</option>
                  <option value="International" selected={record && record.ageGroup === "International" ? "selected" : ""}>International</option>
                  <option value="miLB" selected={record && record.ageGroup === "miLB" ? "selected" : ""}>MiLB</option> */}
                </select>
                <span className="validMsg">{validatorAgeGrp.current.message("Age Group", record && record.ageGroup, "required", { className: "text-danger" })}</span>
              </div>}
            </div>



            {(coachLevel !== 2 && coachLevel !== 3) &&
              <>
                <label className="common-lable">Upload Team Logo</label>
                <div className="dragdrop-img">
                  <div className="create_event_fileUpload file_custom_upload text-center">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <p>Click or drop Documents</p>
                    {/* .heic,.HEIC, */}
                    <input
                      name="logofile"
                      onClick={(ev) => { ev.target.value = null }}
                      type="file"
                      className="img_file_input"
                      accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png"
                      onChange={changeImage}
                    />
                    {(record.teamLogo) &&
                      <div className="preview_img">
                        <i className="fas fa-times" onClick={removeImage}></i>
                        <img className="preview_img_inner" alt="" src={record.teamLogo && record.teamLogo} />
                      </div>
                    }
                  </div>
                </div>
                {/* <span className="validMsg">
              {validator.current.message("Team Logo", record.teamLogo ? record.teamLogo : parentLogo, "required", {
                className: "text-danger",
              })}
            </span> */}

                <label className="common-lable">Tell Us About Your Team</label>
                <textarea
                  id="subject"
                  rows="6"
                  value={record && record.info}
                  onChange={handleChange}
                  name="info"
                  placeholder="Tell Us About Your Team"
                  className="form-control"
                ></textarea>
                {/* <span className="validMsg">
            {validator.current.message("About Team", record && record.info, "required", {
              className: "text-danger",
            })}
          </span> */}

                <label className="common-lable">Team Record</label>
                <div className="check_input row">
                  <div className="check_inputbox">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="00"
                        value={record && record.you}
                        name="you"
                        onChange={handleChange}
                        onKeyPress={onlyNumber}
                      />
                      {/* <span className="validMsg">
                    {validator.current.message("Score", record && record.you, "required", { className: "text-dange  r", })}
                </span> */}
                    </div>
                  </div>
                  <div className="check_inputbox">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="00"
                        value={record && record.opp}
                        name="opp"
                        onChange={handleChange}
                        onKeyPress={onlyNumber}
                      />
                      {/* <span className="validMsg">
                    {validator.current.message("Opponent Score", record && record.opp, "required", { className: "text-danger" })}
                </span> */}
                    </div>
                  </div>
                </div>


                <label className="common-lable">Link Your Social Accounts</label>
                <div className="check_input row">
                  <div className="check_box add_filed">
                    <Link
                      onClick={() => setOpenTwitterLink(true)}
                      className="twitter social_btn w-100"
                    >
                      <img src={imagesArr.twitter} alt="twitter" />
                      {
                        record && record['social-link'] ?
                          record['social-link'].hasOwnProperty('twt') ? "@" + record['social-link'].twt : "Link Twitter account"
                          :
                          "Link Twitter account"
                      }
                    </Link>
                    {openTwitterLink && (
                      <div className="form-group">
                        <div className="serch-box">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="@Name"
                            value={record && record['social-link'] ? record['social-link'].twt : ""}
                            name="twt"
                            onChange={(e) => setRoster({ record: { ...record, 'social-link': { ...record['social-link'], twt: e.target.value } } })}
                          />
                          <Link className="serch-btn" onClick={handleTwitterEvent}>
                            save
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="check_box add_filed">
                    <Link
                      onClick={() => setOpenInstaLink(true)}
                      className="insta social_btn w-100 mr-0"
                    >
                      <img src={imagesArr.insta} alt="insta" />
                      {
                        record && record['social-link'] ?
                          record['social-link'].hasOwnProperty('insta') ? "@" + record['social-link'].insta : "Link Instagram account"
                          :
                          "Link Instagram account"
                      }
                    </Link>
                    {openInstaLink && (
                      <div className="form-group">
                        <div className="serch-box">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            value={record && record['social-link'] ? record['social-link'].insta : ""}
                            name="twt"
                            onChange={(e) => setRoster({ record: { ...record, ['social-link']: { ...record['social-link'], insta: e.target.value } } })}
                          />
                          <Link className="serch-btn" onClick={handleInstagramEvent}>
                            save
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <label className="common-lable">Do you have a Team Merchandise Store?</label>
                <div className="check_input row">
                  <div className="check_box">
                    <div className="checkbox">
                      <input
                        type="radio"
                        name="shopFlg"
                        onChange={() => setRoster({ shopFlg: "yes" })}
                        value="yes"
                        checked={roster.shopFlg === "yes"}
                      />
                      <label>Yes </label>
                    </div>
                    <div className="checkbox mr-0">
                      <input
                        type="radio"
                        name="shopFlg"
                        onChange={() => setRoster({ shopFlg: "no" })}
                        value="no"
                        checked={roster.shopFlg === "no"}
                      />
                      <label>No </label>
                    </div>
                  </div>
                </div>
                {
                  roster.shopFlg === "yes" ?
                    <>
                      <label className="common-lable">Team Store URL</label>
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Team Store Url"
                          name="shoplink"
                          value={record.shoplink}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <span className="validMsg">
                    {validatorShopLink.current.message("Store url", record.shoplink, "required", { className: "text-danger" })}
                  </span> */}
                    </>
                    :
                    <>
                      <label className="common-lable">Are you interested in creating Team Merchandise Store?</label>
                      <div className="check_input row">
                        <div className="check_box">
                          <div className="checkbox">
                            <input
                              type="radio"
                              name="isMarchandise"
                              onChange={() => setRoster({ record: { ...record, isMarchandise: true } })}
                              value={true}
                              checked={record.isMarchandise === true}
                            />
                            <label>Yes </label>
                          </div>
                          <div className="checkbox mr-0">
                            <input
                              type="radio"
                              name="isMarchandise"
                              onChange={() => setRoster({ record: { ...record, isMarchandise: false } })}
                              value={false}
                              checked={record.isMarchandise === false}
                            />
                            <label>No </label>
                          </div>
                        </div>
                      </div>
                    </>
                }
              </>
            }


            <ul className="uploadtab">
              <li className={`${tabsupload === 0 ? "active" : ""}`} onClick={() => handleTabsUpload(0)}><Link>Import</Link></li>
              <li className={`${tabsupload === 1 ? "active" : ""}`} onClick={() => handleTabsUpload(1)}> <Link>Manually</Link></li>
            </ul>

            {tabsupload === 0 ? (
              <>
                <div className="create_event_fileUpload file_custom_upload text-center">
                  <i className="fas fa-cloud-upload-alt"></i>
                  <p>Click to upload</p>
                  <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".csv" onChange={uploadFile} />
                </div>
                <div style={{ textAlign: 'end', padding: "10px 0 0 0 ", fontSize: "15px" }}>
                 { console.log('parentTeam', parentTeam)}
                  <a
                    href={parentTeam=== 2 || parentTeam === 3 ? "https://diamondconnect.s3.us-east-2.amazonaws.com/0/db4aa0bca9bb48b6c95a77f35a50f85b.csv"
                    :"https://diamondconnect.s3.amazonaws.com/24/a6f1c4fc262a4c7a7f0c5b1341ac0b23.csv"
                    }
                  >
                    Download Sample CSV
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="uploadtab_view">
                  <div style={{ textAlign: 'end', padding: "10px 10px 0 10px", fontSize: "15px" }}>
                    <a
                      href={parentTeam=== 2 || parentTeam === 3 ? "https://diamondconnect.s3.us-east-2.amazonaws.com/0/db4aa0bca9bb48b6c95a77f35a50f85b.csv"
                      :"https://diamondconnect.s3.amazonaws.com/24/a6f1c4fc262a4c7a7f0c5b1341ac0b23.csv"
                      }
                    >
                      Download Sample CSV
                    </a>
                  </div>
                  <div className="table-responsive-xl">
                    <table className="table manag-roster-table">
                      {roster &&
                        roster.isMLB &&
                        record["upload-roster"] &&
                        record["upload-roster"].length !== 0 &&
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th className="toggle-btn">
                              Email
                              <label class="switch switch-default switch-pill switch-primary">
                                <input type="checkbox" value={isAllCheck} checked={isAllCheck} class="switch-input" onChange={(e) => allChkHandle(e, record)} />
                                <span class="switch-label"></span>
                                <span class="switch-handle"></span>
                              </label>
                            </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                      }

                      <tbody>
                        {record &&
                          record["upload-roster"] &&
                          record["upload-roster"].map((list, index) => {
                            return (
                              <tr key={index}>
                                <th>0{index + 1}</th>
                                <td>{list.fname} {list.lname}</td>
                                {
                                  roster.isMLB &&
                                  <td className="common_btn toggle-btn">
                                    {
                                      list._id &&
                                      <label class="switch switch-default switch-pill switch-primary">
                                        <input type="checkbox" value={list.isMail} checked={list.isMail} class="switch-input" onChange={(e) => chkHandleChange(e, index, list._id)} />
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                      </label>
                                    }
                                  </td>
                                }
                                <td className="common_btn"><Link className="blue_btn" onClick={() => fetchManually(list, index)}> 
                                {  // list.invType === 2 ? "Invite" :
                                  "Edit"}</Link></td>
                                <td className="common_btn"><Link className="red_btn" onClick={() => openConfimationPopup(index)}>Delete</Link></td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <Link onClick={() => setOpenRoster(!openRoster)} className="gray_btn">+ Add New</Link>
                </div>
              </>
            )}
            <div className="flex-align">
              <Link className="btn gray" onClick={() => { setIsSection1(true); setIsSection2(false); localStorage.removeItem("noAge"); localStorage.removeItem("noLeagueAge"); }}>Cancel</Link>
              <Link className="btn light-green" onClick={() => handleSaveRoster()}>Save</Link>
            </div>
          </div>
        </section>
      )}
      <Preloader flag={loader} />
      {openRoster && (
        <AddManually
          flag={openRoster}
          close={onHandleClose}
          index={index}
          roster={tabsfacility}
          manuallyIndex={manuallyIndex}
          manuallyRecord={manuallyRecord}
          type={type}
          updateData={updateData}
          handleSaveRoster={handleSaveRoster}
          saveManually={saveManually}
          record={record}
          parentTeam={parentTeam}
        />
      )}
      {
        openConfimation.manually &&
        <Confirmation
          flag={openConfimation.manually}
          close={closeDeletePopup}
          type="roster"
          ok={hanldeDeleteManually}
        />
      }
      {
        isFinish &&
        <Confirmation
          flag={isFinish}
          type="isFinish"
          close={closeFinishPopup}
          closePopup={finishClose}
        />
      }
      {
        openConfimation.roster &&
        <Confirmation
          flag={openConfimation.roster}
          close={closeDeletePopupRoster}
          type="roster"
          ok={handleDeleteRoster}
        />
      }
      {
        rosterConfirmation &&
        <Confirmation
          flag={rosterConfirmation}
          close={confirmCloseRoster}
          type="rosterConfirmation"
          msg={backupData}
        />
      }
      {
        mailConfirmation &&
        <Confirmation
          flag={mailConfirmation}
          close={closeMail}
          type="isMail"
          ok={hanldeMail}
        />
      }
      {
        allMailConfirmation &&
        <Confirmation
          flag={allMailConfirmation}
          close={closeAllMail}
          type="allMail"
          ok={hanldeAllMail}
        />
      }
      <ImageCrop
        imgObj={roster.imgObj}
        flag={roster.cropPopup}
        close={handleClose}
        renderImg={roster.renderFile}
        getObj={onHadleObject}
      />
    </>
  );
}

export default RosterInfo;

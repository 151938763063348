import React, { useEffect, useReducer } from 'react';
import { Link, useHistory } from "react-router-dom";
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { imagesArr } from '../../assets/images';
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { changeMyTeamData, getAdverticement, getTeamList } from '../../saga/actions/common/api.action';
import commonCalls from '../../businesslogic/commonCalls';
import { CHANGE_MYTEAM_LIST_SUCCESS, GET_ADS_SUCCESS } from '../../saga/constants/common/api.const';
import Cookies from 'js-cookie';
import Preloader from '../../components/preloader';

const initialState = {
  myTeamList: [],
  teamArr: [],
  rosterGroup: "",
  adsArr: [],
  loader: false,
}
const reducer = (state, payload) => ({ ...state, ...payload });

function CommiDashboard() {
  const commonCall = new commonCalls();
  const dispatch = useDispatch();
  const history = useHistory();
  const [commissionerData, setCommissionerData] = useReducer(reducer, initialState);
  const { getMyTeamListData: _MYTEAMLIST, changeMyTeamListData: _CMTLD, getAdsData: _ADS } = useSelector((state) => state.api);
  const { myTeamList, rosterGroup } = commissionerData

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };

  useEffect(() => {
    setLoader(true);
    getTeamList('BC Commissioner')(dispatch);
    getAdverticement({ moduleId: 1 }, "BC Commissioner")(dispatch);
  }, [])

  useEffect(() => {
    if (_MYTEAMLIST && _MYTEAMLIST.result.data.length !== 0) {
      setCommissionerData({
        ...commissionerData,
        myTeamList: _MYTEAMLIST.result.data,
        loader: false
      })
    }
  }, [_MYTEAMLIST])

  useEffect(() => {
    if (_CMTLD) {
      if (_CMTLD.flag) {
        if (process.env.REACT_APP_BCTEAM_LINK) {
          Cookies.set('gameType', localStorage.getItem("gameType"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
          localStorage.setItem('webglUrl', process.env.REACT_APP_BCTEAM_LINK);
          history.push("/bcteamapp");
        }
        dispatch({
          type: CHANGE_MYTEAM_LIST_SUCCESS,
          payload: null
        })
      } else {
        commonCall.errorMessage(_CMTLD.message);
      }
    }
  }, [_CMTLD])

  useEffect(() => {
    if (_ADS && _ADS.result.data.length !== 0) {
      setCommissionerData({
        ...commissionerData,
        adsArr: _ADS.result.data,
        loader: false
      })
      dispatch({
        type: GET_ADS_SUCCESS,
        payload: null
      })
    }
  }, [_ADS])

  const setLoader = (val) => {
    setCommissionerData({
      loader: val
    })
  }

  const redirectNew = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  }

  const renderAdds = () => {
    return (
      commissionerData.adsArr.length != 0 ?
        commissionerData.adsArr.map((list, i) => {
          return (
            <Link className="item" onClick={() => redirectNew(list.destination)}>
              {
                list.media.hasOwnProperty('_id') ?
                  <img src={list.media.filePath} alt="slide1" />
                  :
                  <p>Ads</p>
              }
            </Link>
          );
        })
        :
        <Link className="item">
          <p>Ads</p>
        </Link>
    )
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setCommissionerData({
      rosterGroup: value,
    })
    changeMyTeamData({ parentTeam: value }, "BC Commissioner")(dispatch);

  }


  return (
    <>
      <Header />
      <section className="coachdashboard event-dashboard">
        <div className="container">
          <div className="mb-15">
            <div className="event-roster-select">
              <label className="common-lable">Choose Team to Open BCTeam Account</label>
              <select className="custom-select" name="rosterGroup" value={rosterGroup} onChange={handleChange}>
                <option value="" selected disabled>Choose Team</option>
                {myTeamList.map((list, i) => {
                  return (
                    list.parentTeam && <option value={list.parentTeam._id}> {list.parentTeam.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="common-dashbord mt-15">
            <div className="dashbord-center">
              <div className="slider-common">
                <Slider {...settings}>
                  {renderAdds()}
                </Slider>
              </div>
              <div className={`upload_file`}>
                <Link className={`upload_inner`} disabled>
                  <div className="upload_img" style={{ background: "#bfbfbf" }}>
                    <img src={imagesArr.white_teamwork} alt="teamwork" />
                  </div>
                  <h5>Team Roster</h5>
                </Link>
                <Link className={`upload_inner`} disabled>
                  <div className="upload_img" style={{ background: "#bfbfbf" }}>
                    <img src={imagesArr.white_upload} alt="teamwork" />
                  </div>
                  <h5>Upload Data</h5>
                </Link>
                <Link className={`upload_inner`} disabled>
                  <div className="upload_img" style={{ background: "#bfbfbf" }}>
                    <img src={imagesArr.white_subscription} alt="teamwork" />
                  </div>
                  <h5>Manage Subscriptions</h5>
                </Link>
              </div>
            </div>
            <div className="common-div">
              {/* <h3>Team Roster</h3> */}
              <div className="team-name">
                <h3>Frontier League</h3>
              </div>
              <div className="team_logo commissioner_team">
                <img src={imagesArr.FrontierLeagueLogo || 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'} alt="team_logo" />
              </div>
            </div >
          </div >
        </div >
      </section>
      <Footer />

      <Preloader flag={commissionerData.loader} />
    </>
  )
}

export default CommiDashboard
import React, { Component } from 'react';
import { imagesArr } from '../../../assets/images';
import { isMobile } from 'react-device-detect';
import DownloadApp from '../../popup/downloadApp';
class fanDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appPopUp: false
        }
    }

    componentDidMount() {
        this.setState({
            appPopUp: isMobile ? true : false
        })
    }

    closeApp = () => {
        document.body.classList.remove('modal-open');
        this.setState((prevState) => ({
            ...prevState,
            appPopUp: false
        }))
    }
    render() {
        return (
            <>
                <div className="fan-dashboard">
                    <div className="container">
                        <div className="download-app">
                            <img src={imagesArr.like_thumb} />
                            <h3>Thanks for signing up for your BaseballCloud account. Download DiamondConnect on your iOS and Android devices.
                                You can follow your favorite teams, players, and coaches, and so much more.</h3>
                            <div className="store-img">
                                <a href="#"><img src={imagesArr.appstore} /></a>
                                {/* <a href="#"> <img src={imagesArr.playstore} /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.appPopUp && <DownloadApp
                    flag={this.state.appPopUp}
                    close={this.closeApp}
                />}
            </>
        )
    }
}

export default fanDashboard;

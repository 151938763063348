import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { imagesArr } from '../../assets/images';
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { saveUpgrade } from '../../saga/actions/common/subscription.action';
import Preloader from '../../components/preloader';

export class UpgradeSubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                subscription_id: "",
                packageId: "",
                planId: "",
                amount: ""
            },
            loader: false
        }

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        if (this.props.flag) {
            document.body.classList.add('modal-open');
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.subInfo &&
            nextProps.subInfo.saveUpgradeReq &&
            nextProps.subInfo.saveUpgradeData
        ) {
            nextProps.subInfo.saveUpgradeReq = false;
            console.log("nextProps.subInfo.saveUpgradeData:--", nextProps.subInfo.saveUpgradeData);
            if (nextProps.subInfo.saveUpgradeData.flag) {
                nextProps.subInfo.saveUpgradeData = null;
                this.setLoader(false);
                this.props.ok();
            } else {
                this.commonCall.errorMessage(nextProps.subInfo.saveUpgradeData.message);
                nextProps.subInfo.saveUpgradeData = null;
                this.setLoader(false);
            }
        }
    }

    gotoLanding = () => {
        localStorage.setItem("scrollLanding", "");
        document.body.classList.remove('modal-open');
        this.props.history.push("/ballr");
    }

    onHandleChange = (data) => {
        console.log("data:-----", data);
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                subscription_id: this.props.sid,
                packageId: data.packageId,
                planId: data.stripePlanId,
                amount: data.amount
            },
        }));
    }

    renderSubscriptionList = () => {
        return (
            this.props.data &&
            this.props.data.map((list, i) => {
                return (
                    <label className="round-radio" key={i}>
                        {list.name} - <Link onClick={() => this.gotoLanding()}>View More</Link>
                        <input
                            type="radio"
                            id={list.packageId}
                            name="packageId"
                            value={list.packageId}
                            onClick={() => this.onHandleChange(list)}
                        />
                        <span className="checkmark"></span>

                    </label>
                );
            })
        )
    }

    buyNow = () => {
        if (this.validator.allValid()) {
            console.log("send:---", this.state.sendReq);
            this.setLoader(true);
            this.props.saveUpgrade(this.state.sendReq, this.props.type);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { flag } = this.props;

        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>Upgrade Subscription</h3>
                                <div className="modal_close" onClick={this.props.close}>
                                    <img src={imagesArr.white_modal_close} alt="modal_close" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="team-body-inner">
                                    <div className="form-group mb-0">
                                        <div className="upgrade-radio">
                                            {this.renderSubscriptionList()}
                                            <span className="validMsg">{this.validator.message('Package name', this.state.sendReq.packageId, 'required')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-align next-button">
                                    <Link className="btn light-green" onClick={() => this.buyNow()}>
                                        Buy Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={this.state.loader} />
            </>
        )
    }
}

UpgradeSubscribe.propTypes = {
    saveUpgrade: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    subInfo: state.subscriptionInfo,
});

const MyComponent = connect(mapStateToProps, { saveUpgrade })(UpgradeSubscribe);
export default withRouter(MyComponent);

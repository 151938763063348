import Awards from "./Awards";
import Bracket from "./Bracket";
import CustomBracket from "./CustomBracket";
import Recap from "./Recap";
import Schedule from "./Schedule";
import Standings from "./Standings";
import StatsLeaders from "./StatsLeaders";

const eventRecapTabInfo = (isOldEvent) => ([
    {
        id : "Schedule",
        title : "Schedule",
        Element: Schedule
    },
    {
        id : "Recap",
        title : "Recap",
        Element: Recap
    },
    {
        id : "Awards",
        title : "Awards",
        Element: Awards
    },
    {
        id : "Standings",
        title : "Standings",
        Element: Standings
    },
    {
        id : "Bracket/Results",
        title : "Bracket/Results",
        Element: isOldEvent ? Bracket : CustomBracket
    },
    {
        id : "Stats Leaders",
        title : "Stats Leaders",
        Element: StatsLeaders
    },
    
])

const bracketTabInfo = [
    {
        id : "Gold",
        title : "Gold",
    },
    {
        id : "Silver",
        title : "Silver"
    },
    {
        id : "Bronze",
        title : "Bronze"
    },
]

const hittingStatsTabInfo = [
    {
        id : "AVG",
        title : "AVG",
        payload : "AVG"
    },
    {
        id : "OPS",
        title : "OPS",
        payload : "OPS"
    },
    {
        id : "SLG",
        title : "SLG",
        payload : "SLG"
    },
    {
        id : "OBP",
        title : "OBP",
        payload : "OBP"
    },
    {
        id : "HITS",
        title : "HITS",
        payload : "H"
    },
    {
        id : "DOUBLES",
        title : "DOUBLES",
        payload : "Doubles"
    },
    {
        id : "HR",
        title : "HR",
        payload : "HR"
    },
    {
        id : "XBH",
        title : "XBH",
        payload : "EB"
    },
    {
        id : "RBI",
        title : "RBI",
        payload : "RBI"
    },
    {
        id : "R",
        title : "R",
        payload : "R"
    },
    {
        id : "SB",
        title : "SB",
        payload : "SB"
    },
]

const pitchingStatsTabInfo = [
    {
        id : "ERA",
        title : "ERA",
        payload : "ERA"
    },
    {
        id : "WHIP",
        title : "WHIP",
        payload : "WHIP"
    },
    {
        id : "WINS",
        title : "WINS",
        payload : "Won"
    },
    {
        id : "STRIKEOUTS",
        title : "STRIKEOUTS",
        payload : "K"
    },
    {
        id : "EARNED RUNS",
        title : "EARNED RUNS",
        payload : "ER"
    },
    {
        id : "K/BB",
        title : "K/BB",
        payload : "KperBB"
    },
    {
        id : "BAA",
        title : "BAA",
        payload : "BAA"
    },
    // {
    //     id : "OBP",
    //     title : "OBP",
    //     payload : "OBP"
    // },
]

export {
    eventRecapTabInfo,
    bracketTabInfo,
    hittingStatsTabInfo,
    pitchingStatsTabInfo
}
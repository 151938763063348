import React, { memo } from "react";

function Standings({ allRecapDescription, ageGroup, divisionName, poolId, gameType, onHandleChange }) {

  return (
    <>
      <div className="pt-20">
        <div className="container">
          <div className="champions-Score schedule-filter p-0 mt-20">
            <div className="select_btn mt-0">
              <div className="row">
                {/* <div className={`${poolArr?.length !== 0 && gameType === "Matchup" ? "w-25" : "w-50"}`}> */}
                <div className="w-25">
                  <select name="gameType" value={gameType} className="custom-select" onChange={(e) => onHandleChange(e)}>
                    <option value="All">All</option>
                    <option value="Bracket">Bracket</option>
                    <option value="Matchup">Matchup</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {allRecapDescription?.scheduleLive ? (
        (gameType === 'Bracket' || gameType === 'All') || (gameType === 'Matchup' && poolId !== "SeeAll") ? (
          <div className="standings-info-tab">
            <div className="poll_standings">
              <div className="container">
                <h1 className="section_title">Pool Standings</h1>
                <div className="recap-table-info">
                  <div className="table-responsive-xl">
                    <table>
                      <thead>
                        {/* {ageGroup && (
                          <tr>
                            <th
                              colspan="7"
                              className="text-center"
                            >{`${ageGroup}U ${
                              divisionName ? "Division " + divisionName : ""
                            }`}</th>
                          </tr>
                        )} */}
                        <tr>
                          <th>Team Name</th>
                          <th>W</th>
                          <th>L</th>
                          <th>T</th>
                          <th>PCT</th>
                          <th>RS</th>
                          <th>RA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allRecapDescription?.teamList?.length !== 0 ? (
                          allRecapDescription?.teamList?.map(
                            ({ team, win, lose, ties, PCT, RS, RA }) => (
                              <tr>
                                <td>{team ? team : "-"}</td>
                                <td>{win}</td>
                                <td>{lose}</td>
                                <td>{ties}</td>
                                <td>{PCT}</td>
                                <td>{RS}</td>
                                <td>{RA}</td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td className="text-center" colspan="7">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="standings-info-tab">
              <div className="poll_standings">
                <div className="container">
                  <h1 className="section_title">Pool Standings</h1>
                  {allRecapDescription?.poolList?.map((_) => {
                    return (
                      <div className="recap-table-info">
                        <div className="table-responsive-xl">
                          <table>
                            <thead>
                              <tr>
                                <th colspan="7" className="text-center">
                                  {_.poolName}
                                </th>
                              </tr>
                              <tr>
                                <th>Team Name</th>
                                <th>W</th>
                                <th>L</th>
                                <th>T</th>
                                <th>PCT</th>
                                <th>RS</th>
                                <th>RA</th>
                              </tr>
                            </thead>
                            <tbody>
                              {_?.score?.length !== 0 ? (
                                _?.score?.map(
                                  ({ team, win, lose, ties, PCT, RS, RA }) => (
                                    <tr>
                                      <td>{team ? team : "-"}</td>
                                      <td>{win}</td>
                                      <td>{lose}</td>
                                      <td>{ties}</td>
                                      <td>{PCT}</td>
                                      <td>{RS}</td>
                                      <td>{RA}</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td className="text-center" colspan="7">
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <p className="text-center">Revised Schedule Coming Soon</p>
      )}
    </>
  );
}

export default memo(Standings);

{
  /* <div className="no-schedule-age">
<p className="text-center">Revised Schedule Coming Soon</p>
</div> */
}

import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_OTHERINFO_ERROR,
    GET_OTHERINFO_REQUEST,
    GET_OTHERINFO_SUCCESS,
    POST_OTHERINFO_ERROR,
    POST_OTHERINFO_SUCCESS
} from '../../../../constants/authantication/signup/player/about.const';
import histrory from '../../../../../History';

export const savePlayerAbout = (data) => dispatch => {
    console.log("data:----action:---", data);
    checkIsValidPlayerToken(histrory)
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/other`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(res => {
        dispatch({
            type: POST_OTHERINFO_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: POST_OTHERINFO_ERROR, error: error.response.data })
    });
};

export const getOtherInfo = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/player/other`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        console.log("OtherInfo:----", res);
        dispatch({
            type: GET_OTHERINFO_SUCCESS,
            payload: res.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_OTHERINFO_ERROR, error: error.response.data })
    });
};


import {
    GET_ADVANCED_SEARCH_REQUEST,
    GET_ADVANCED_SEARCH_SUCCESS,
    GET_ADVANCED_SEARCH_ERROR,

    GET_FILTER_DATA_REQUEST,
    GET_FILTER_DATA_SUCCESS,
    GET_FILTER_DATA_ERROR,
} from '../../../../constants/view/company/pwRanking/advancedSearch.const';

const initialState = {
    getAdvancedSearchReq: false,
    getAdvancedSearchData: null,
    getAdvancedSearchError: null,

    getFilterReq: false,
    getFilterData: null,
    getFilterError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get filter list req    
        case GET_FILTER_DATA_REQUEST:
            return {
                ...state,
                getFilterReq: true,
            };

        case GET_FILTER_DATA_SUCCESS:
            return {
                ...state,
                getFilterData: action.payload,
            };
        case GET_FILTER_DATA_ERROR:
            return {
                ...state,
                getFilterError: action.error,
            };

        // Get advanced search req    
        case GET_ADVANCED_SEARCH_REQUEST:
            return {
                ...state,
                getAdvancedSearchReq: true,
            };

        case GET_ADVANCED_SEARCH_SUCCESS:
            return {
                ...state,
                getAdvancedSearchData: action.payload,
            };
        case GET_ADVANCED_SEARCH_ERROR:
            return {
                ...state,
                getAdvancedSearchError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { imagesArr } from '../assets/images';

class acceptreqHeader extends Component {
    render() {
        return (
            <>
                <header className="" id="bc-header">
                    <nav className="navbar navbar-expand-lg mainNav">
                        <div className="container">
                            <Link to={"/"} className="navbar-brand">
                                <img src={imagesArr.logo} alt="logo" />
                            </Link>
                        </div>
                    </nav>

                </header >
            </>
        );
    }
}

export default acceptreqHeader;

import {
    COM_AD_SLIDER_REQUEST,
    COM_AD_SLIDER_SUCCESS,
    COM_AD_SLIDER_ERROR,

    COM_DIVISION_SLIDER_REQUEST,
    COM_DIVISION_SLIDER_SUCCESS,
    COM_DIVISION_SLIDER_ERROR,

    COM_DIVISION_EVENTLIST_REQUEST,
    COM_DIVISION_EVENTLIST_SUCCESS,
    COM_DIVISION_EVENTLIST_ERROR,

    COM_DIVISION_SLIDER_DIV_LIST_REQUEST,
    COM_DIVISION_SLIDER_DIV_LIST_SUCCESS,
    COM_DIVISION_SLIDER_DIV_LIST_ERROR,

    COM_GET_AGE_DIV_REQUEST,
    COM_GET_AGE_DIV_SUCCESS,
    COM_GET_AGE_DIV_ERROR,

    GET_LIVE_EVENT_SCORE_REQUEST,
    GET_LIVE_EVENT_SCORE_SUCCESS,
    GET_LIVE_EVENT_SCORE_ERROR
} from '../../constants/common/slider.const';

const initialState = {
    adsSliderReq: false,
    adsSliderData: [],
    adsSliderError: null,

    divisionSliderReq: false,
    divisionSliderData: null,
    divisionSliderError: null,

    divisionEventReq: false,
    divisionEventData: null,
    divisionEventError: null,

    divSliderListReq: false,
    divSliderListData: null,
    divSliderListError: null,

    getAgeDivReq: false,
    getAgeDivData: null,
    getAgeDivError: null,

    eventScoreListReq: false,
    eventScoreListData: null,
    eventScoreListError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

         // Get age group division slider req    
         case COM_GET_AGE_DIV_REQUEST:
            return {
                ...state,
                getAgeDivReq: true,
            };

        case COM_GET_AGE_DIV_SUCCESS:
            return {
                ...state,
                getAgeDivData: action.payload,
            };
        case COM_GET_AGE_DIV_ERROR:
            return {
                ...state,
                getAgeDivError: action.error,
            };

        // Advertisement slider req    
        case COM_AD_SLIDER_REQUEST:
            return {
                ...state,
                adsSliderReq: true,
            };

        case COM_AD_SLIDER_SUCCESS:
            return {
                ...state,
                adsSliderData: action.payload,
            };
        case COM_AD_SLIDER_ERROR:
            return {
                ...state,
                adsSliderError: action.error,
            };

        // Division slider req    
        case COM_DIVISION_SLIDER_REQUEST:
            return {
                ...state,
                divisionSliderReq: true,
            };

        case COM_DIVISION_SLIDER_SUCCESS:
            return {
                ...state,
                divisionSliderData: action.payload,
            };
        case COM_DIVISION_SLIDER_ERROR:
            return {
                ...state,
                divisionSliderError: action.error,
            };

        // Division slider event list req    
        case COM_DIVISION_EVENTLIST_REQUEST:
            return {
                ...state,
                divisionEventReq: true,
            };

        case COM_DIVISION_EVENTLIST_SUCCESS:
            return {
                ...state,
                divisionEventData: action.payload,
            };
        case COM_DIVISION_EVENTLIST_ERROR:
            return {
                ...state,
                divisionEventError: action.error,
            };


        // Div slider list req    
        case COM_DIVISION_SLIDER_DIV_LIST_REQUEST:
            return {
                ...state,
                divSliderListReq: true,
            };

        case COM_DIVISION_SLIDER_DIV_LIST_SUCCESS:
            return {
                ...state,
                divSliderListData: action.payload,
            };
        case COM_DIVISION_SLIDER_DIV_LIST_ERROR:
            return {
                ...state,
                divSliderListError: action.error,
            };

        // live event score list
        case GET_LIVE_EVENT_SCORE_REQUEST:
            return {
                ...state,
                liveEventScoreReq: true,
            };

        case GET_LIVE_EVENT_SCORE_SUCCESS:
            return {
                ...state,
                liveEventScoreData: action.payload,
            };
        case GET_LIVE_EVENT_SCORE_ERROR:
            return {
                ...state,
                liveEventScoreError: action.error,
            };


        default:
            return state;
    }
}
import React, { Component } from 'react'
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import { imagesArr } from '../../../assets/images';
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import Shop from "../../../views/company/components/common/shop";
import FeaturedLeague from "../../../views/company/components/common/featuredLeague";
import UpComingLeague from "../../../views/company/components/common/upComingLeague";
import Preloader from '../../../components/preloader';

export default class utbLeague extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0
        }
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { loaderCount } = this.state;
        return (
            <>
                <Header />
                <div className="event-main">

                    {/* featured Leagues */}

                    <FeaturedLeague increment={this.increment} decrement={this.decrement} noOfRec={6} leagueType="" />

                    {/* upcoming Leagues */}

                    <UpComingLeague increment={this.increment} decrement={this.decrement} noOfRec={6} leagueType="" />

                    <div className="upcomingevent_section">
                        <Shop cType="utb" />
                    </div>
                    <ProudSponsors increment={this.increment} decrement={this.decrement}/>
                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </>
        )
    }
}


export const GET_ACTIVE_SUB_REQUEST = 'GET_ACTIVE_SUB_REQUEST';
export const GET_ACTIVE_SUB_SUCCESS = 'GET_ACTIVE_SUB_SUCCESS';
export const GET_ACTIVE_SUB_ERROR   = 'GET_ACTIVE_SUB_ERROR';

export const DELETE_ACTIVE_SUB_REQUEST = 'DELETE_ACTIVE_SUB_REQUEST';
export const DELETE_ACTIVE_SUB_SUCCESS = 'DELETE_ACTIVE_SUB_SUCCESS';
export const DELETE_ACTIVE_SUB_ERROR   = 'DELETE_ACTIVE_SUB_ERROR';

export const GET_EXPIRE_SUB_REQUEST = 'GET_EXPIRE_SUB_REQUEST';
export const GET_EXPIRE_SUB_SUCCESS = 'GET_EXPIRE_SUB_SUCCESS';
export const GET_EXPIRE_SUB_ERROR   = 'GET_EXPIRE_SUB_ERROR';

export const GET_UPGRADE_REQUEST = 'GET_UPGRADE_REQUEST';
export const GET_UPGRADE_SUCCESS = 'GET_UPGRADE_SUCCESS';
export const GET_UPGRADE_ERROR   = 'GET_UPGRADE_ERROR';

export const SAVE_UPGRADE_REQUEST = 'SAVE_UPGRADE_REQUEST';
export const SAVE_UPGRADE_SUCCESS = 'SAVE_UPGRADE_SUCCESS';
export const SAVE_UPGRADE_ERROR   = 'SAVE_UPGRADE_ERROR';

export const GET_ROSTER_LIST_COACH_REQUEST = 'GET_ROSTER_LIST_COACH_REQUEST';
export const GET_ROSTER_LIST_COACH_SUCCESS = 'GET_ROSTER_LIST_COACH_SUCCESS';
export const GET_ROSTER_LIST_COACH_ERROR   = 'GET_ROSTER_LIST_COACH_ERROR';

export const GET_ROSTER_LIST_PLAYER_REQUEST = 'GET_ROSTER_LIST_PLAYER_REQUEST';
export const GET_ROSTER_LIST_PLAYER_SUCCESS = 'GET_ROSTER_LIST_PLAYER_SUCCESS';
export const GET_ROSTER_LIST_PLAYER_ERROR   = 'GET_ROSTER_LIST_PLAYER_ERROR';

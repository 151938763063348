import axios from 'axios';
import { API_Url, Auth_Fan_Key ,checkIsValidFanToken} from '../../../../helpers/authToken';
import {
    FAN_COACH_LIST_REQUEST,
    FAN_COACH_LIST_SUCCESS,
    FAN_COACH_LIST_ERROR,
    FAN_ADD_COACH_REQUEST,
    FAN_ADD_COACH_SUCCESS,
    FAN_ADD_COACH_ERROR,
    FAN_COMPLETE_REQUEST,
    FAN_COMPLETE_SUCCESS,
    FAN_COMPLETE_ERROR
} from '../../../../constants/authantication/signup/fan/coach.const';
import histrory from '../../../../../History';

export const coachList = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: FAN_COACH_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/fan/child/search`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(searchcoach => {
        dispatch({
            type: FAN_COACH_LIST_SUCCESS,
            payload: searchcoach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: FAN_COACH_LIST_ERROR, error: error.response.data.message })
    });
};

export const saveCoach = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: FAN_ADD_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/fan/team`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(coach => {
        dispatch({
            type: FAN_ADD_COACH_SUCCESS,
            payload: coach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: FAN_ADD_COACH_ERROR, error: error.response.data.message })
    });
};

export const saveFanComplete = () => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: FAN_COMPLETE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/fan/complete`,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(coach => {
        dispatch({
            type: FAN_COMPLETE_SUCCESS,
            payload: coach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: FAN_COMPLETE_ERROR, error: error.response.data.message })
    });
};

export const skipStep = () => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: FAN_COMPLETE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/fan/complete`,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(coach => {
        dispatch({
            type: FAN_COMPLETE_SUCCESS,
            payload: coach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: FAN_COMPLETE_ERROR, error: error.response.data.message })
    });
};

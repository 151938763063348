import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from "../../../../assets/images";
import ReactPaginate from "react-paginate";
import { withRouter } from 'react-router-dom';
import ProductList from "../../../company/components/common/productList";
import moment from "moment";
import { getSumOfRegisterTeam, getAvailableSport } from "../../../../businesslogic/content";
import { getScoutingFeed, getDirectRecapInfo } from '../../../../saga/actions/views/company/pwRanking/profileSummary.action';
import AllPagesPdfViwer from './common/allPagesPdfViwer';

class events extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventList: [],
            // sendReq: {
            //     id: "",
            //     page: 1,
            //     limit: 10
            // },
            scouting: [],
            eventInfo: [],
            isScroll: false,
            offset: 0,
            perPage: 1,
            // currentPage: "",
            pageCount: "",
            pdfContent: ""
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileInfo &&
            nextProps.profileInfo.getScoutingReq &&
            nextProps.profileInfo.getScoutingData
        ) {
            nextProps.profileInfo.getScoutingReq = false;
            console.log(" nextProps.profileInfo.getScoutingData:--", nextProps.profileInfo.getScoutingData);
            if (nextProps.profileInfo.getScoutingData.flag) {
                let listData = nextProps.profileInfo.getScoutingData.result.docs;
                if (this.state.isScroll) {
                    let temp = [];

                    listData.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.scouting.concat(temp);
                    if (listData.length !== 0) {
                        this.setState({
                            scouting: joined,
                        }, () => {
                            nextProps.profileInfo.getScoutingData = null;
                            this.props.decrement();
                        })
                        this.props.decrement();
                    } else {
                        nextProps.profileInfo.getScoutingData = null;
                        this.props.decrement();
                    }
                } else {
                    this.setState({
                        scouting: listData,
                    }, () => {
                        nextProps.profileInfo.getScoutingData = null;
                        this.props.decrement();
                    })
                }
            } else {
                nextProps.profileInfo.getScoutingData = null;
                this.props.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.directRecapReq &&
            nextProps.profileInfo.directRecapData
        ) {
            nextProps.profileInfo.directRecapReq = false;
            console.log(" nextProps.profileInfo.directRecapData:--", nextProps.profileInfo.directRecapData);
            if (nextProps.profileInfo.directRecapData.flag) {
                let recap = nextProps.profileInfo.directRecapData.result;
                localStorage.setItem("recapInfo", JSON.stringify(recap));
                nextProps.profileInfo.directRecapData = null;
                this.props.decrement();
                let route = process.env.REACT_APP_WEB_LINK + "/pw-player-info";
                window.open(route, "_blank");
            } else {
                nextProps.profileInfo.directRecapData = null;
                this.props.decrement();
            }
        }
    }

    getSummary = (data) => {
        if (data.docs.length !== 0) {
            let lenData;
            if (data.docs.length > this.props.profileInfo.eventCurrentPage) {
                lenData = this.props.profileInfo.eventCurrentPage;
            } else {
                lenData = 0;
            }
            this.props.profileInfo.eventPageReq = {
                ...this.props.profileInfo.eventPageReq,
                id: data.docs[lenData]._id,
                page: 1,
                limit: 10,
            };
            this.setState({
                eventList: data,
                pageCount: data.total,
                eventInfo: data.docs[lenData]
            }, () => {
                this.props.increment();
                this.props.getScoutingFeed(this.props.profileInfo.eventPageReq);
            })
        } else {
            this.props.profileInfo.eventPageReq = {
                ...this.props.profileInfo.eventPageReq,
                id: "",
                page: 1,
                limit: 10
            };
            this.setState({
                eventList: [],
                pageCount: "",
                eventInfo: []
            })
        }
    }

    redirectDetails = (data) => {
        localStorage.setItem("recapInfo", JSON.stringify(data))
        if (data.eventType === 1) {
            this.props.history.push(`/pwb-event-info/${data._id}`);
        } else {
            this.props.history.push(`/pws-event-info/${data._id}`);
        }
    }

    renderEvent = () => {
        console.log("this.props.summaryData.docs:---", this.props.summaryData.docs);
        return (
            this.props.summaryData &&
            this.props.summaryData.docs &&
            this.props.summaryData.docs.map((item, i) => {
                const location = item.location && item.location.streetAddress.split(",");
                return (
                    <div className="event_box" key={i} onClick={() => this.handlePageClick({ selected: i })}>
                        <div className="event_card">
                            <div class="event_card_img">
                                <img src={item.eventImages && item.eventImages.filePath} alt="team logo" />
                                <span className="event_card_address">
                                    {item.location &&
                                        item.location.city ?
                                        item.location.city :
                                        item.location.streetAddress ?
                                            location[0] :
                                            item.location.stateRegion}
                                </span>
                            </div>
                            <div class="event_card_desc">
                                <div class="event_card_logo">
                                    <img src={item.eventLogo && item.eventLogo.filePath ? item.eventLogo.filePath : imagesArr.default_user} alt="team logo" />
                                </div>
                                <div class="event_card_titles">
                                    <h3>{item.eventTitle}</h3>
                                    <div class="event_card_dates">
                                        <p class="date_icon">{moment(item.startDate).format("DD-MMM")} - {moment(item.endDate).format("DD-MMM")}</p>
                                        <p class="team_icons">{item.payment.feesByGroups.length ? getSumOfRegisterTeam(item.payment.feesByGroups) : 0} Player Registered</p>
                                        <p class="team_icons">{item.payment.feesByGroups.length ? getAvailableSport(item.payment.feesByGroups) : 0} Available Spots</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        )
    }

    mentionText = (text) => {
        // let str = text.replace(/\</g, "&lt;b&gt;").replace(/\>/g, "&lt;/b&gt;");
        // let finalText = "";
        // if (text.split("<")[1]) {
        //     finalText = "<b>" + text.split("<")[1].split(">")[0] + "</b>" + "&nbsp;" + text.split("<")[1].split(">")[1];
        // } else {
        //     finalText = text;
        // }
        return text;
    }

    getExtantion = (file) => {
        var fileExtension = file.split('/').pop();
        return fileExtension;
    }

    redirectLink = (link) => {
        window.open(link, '_blank')
    }

    renderMessage = () => {
        return (
            this.state.scouting.length !== 0 ?
                this.state.scouting.map((item, i) => {
                    return (
                        <div className="media" key={i}>
                            <img src={item?.sId?.profileUrl ? item.sId.profileUrl : imagesArr.default_user} alt="media" />
                            <div className="media-contant">
                                <h4>{item?.sId ? item.sId.fname + " " + item.sId.lname : ""}</h4>
                                <div className="media-type">

                                    {
                                        item.file &&
                                            item.mTy === 2 ?
                                            <div className="media-img">
                                                <img src={item.file.filePath} alt="team logo" onClick={() => this.redirectLink(item.file.filePath)} />
                                            </div>
                                            :
                                            item.mTy === 3 ?
                                                <audio controls>
                                                    <source src={item.file.filePath} type="audio/ogg" />
                                                </audio>
                                                : item.mTy === 6 ?
                                                    <div className="media-file">
                                                        <div className="file-formate" onClick={() => this.redirectLink(item.file.filePath)} >
                                                            <div className="file-icon">
                                                                <img src={imagesArr.pdf_icon} alt="media" />
                                                            </div>
                                                            <div className="file-name">
                                                                <h4>{this.getExtantion(item.file.filePath)}</h4>
                                                                <p>{item.file.size} KB</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : item.mTy === 4 ?
                                                        <div>
                                                            <video src={item.file.filePath} type="video" controls style={{ width: "100%", height: "200px" }}></video>
                                                        </div>
                                                        : ""
                                    }
                                    {
                                        item.msg &&
                                        // <p className="text">{this.mentionText(item.msg)}</p>
                                        <p className="text" dangerouslySetInnerHTML={{
                                            __html: this.mentionText(item.msg)
                                        }}></p>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    handlePageClick = (e) => {
        console.log("e.selected:---", e.selected);
        this.setState({
            pdfContent: ""
        })
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.props.profileInfo.eventPageReq = {
            ...this.props.profileInfo.eventPageReq,
            id: this.state.eventList.docs[selectedPage]._id,
            page: 1,
            limit: this.props.profileInfo.eventPageReq.limit
        };
        this.props.profileInfo.eventCurrentPage = selectedPage;
        this.setState((prevState) => ({
            ...prevState,
            eventInfo: this.state.eventList.docs[selectedPage],
            offset: offset,
            isScroll: false,
        }), () => {
            this.props.increment();
            this.props.getScoutingFeed(this.props.profileInfo.eventPageReq);
        });
    };

    getScoutScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom && this.state.scouting.length !== 0) {
            this.props.profileInfo.eventPageReq = {
                ...this.props.profileInfo.eventPageReq,
                page: this.props.profileInfo.eventPageReq.page + 1,
                limit: this.props.profileInfo.eventPageReq.limit
            };
            this.setState({
                isScroll: true,
            }, () => {
                this.props.increment();
                this.props.getScoutingFeed(this.props.profileInfo.eventPageReq);
            })
        }
    }

    getRedirect = (eventInfo) => {
        this.props.increment();
        this.props.getDirectRecapInfo(eventInfo._id, 0, eventInfo.eventTeamType)
    }

    convertpdfToBase64(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        // xhr.setRequestHeader('Content-Type', 'application/pdf');
        xhr.send();
    }
    
    OpenEvals = (event) => {
        this.props.increment();
        this.convertpdfToBase64(event.evaluationFile.filePath, (base64) => {
            this.setState({
                pdfContent: base64
            })
        })
    }

    render() {
        const { summaryData, sendReq } = this.props;
        const { eventInfo, eventList, pageCount, currentPage } = this.state;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            summaryData.length !== 0 &&
            <>
                <div className="event-report-content">
                    <div className="event-report">
                        <div className="event-report-filter pb-15">
                            <div className="form-group ">
                                <div className="custom-checkbox">
                                    <label>
                                        <input type="checkbox" name="eventTeamType" value={1} checked={sendReq.eventTeamType === 1 ? true : false} onChange={(e) => this.props.onHandleTeamType(e)} />Tournaments
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="custom-checkbox">
                                    <label>
                                        <input type="checkbox" name="eventTeamType" value={2} checked={sendReq.eventTeamType === 2 ? true : false} onChange={(e) => this.props.onHandleShowcaseType(e)} />Showcases
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={(c) => (this._calendar = c)}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                    {years.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                            </div>
                                        )}
                                        selected={sendReq.sortOn.eventstartDate}
                                        maxDate={moment().subtract(1, 'days').toDate()}
                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(e) => this.props.onChangeDate(e, "eventstartDate", "eventTab")}
                                        placeholderText="Start date"
                                    />
                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={(c) => (this._calendar = c)}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                    {years.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                            </div>
                                        )}
                                        selected={sendReq.sortOn.eventendDate}
                                        minDate={moment(sendReq.sortOn.eventstartDate).subtract(0, 'days').toDate()}
                                        maxDate={moment().subtract(1, 'days').toDate()}
                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(e) => this.props.onChangeDate(e, "eventendDate", "eventTab")}
                                        placeholderText="End date"
                                    />
                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                </div>
                            </div>
                        </div>
                        <div className="event-report-group">
                            <div className="PW-scouting-feed">
                                <h3 class="section_title">PW Scouting Feed</h3>
                                {
                                    eventList.length !== 0 &&
                                    <div className="scouting-heading" >
                                        <div className="scouting-title" >
                                            <img src={eventInfo.eventLogo.filePath} alt="player" />{eventInfo.eventTitle}
                                        </div>
                                        <div className="scouting-btn">
                                                {((localStorage.getItem("company") === "pws" || localStorage.getItem("company") === "pwb") && Object.keys(eventInfo.evaluationFile).length !== 0)  && <button class="btn" onClick={() => this.OpenEvals(eventInfo)}>Evals</button>}
                                            <button class="btn" onClick={() => (eventInfo.companies.name !== "USA Travel Ball" && eventInfo.companies.name !== "GOBTournament") ? this.redirectDetails(eventInfo) : ""}>Recap</button>
                                            <button class="btn" onClick={() => this.props.redirectScout(eventInfo, this.state.scouting)}>Scout feed</button>
                                        </div>
                                    </div>
                                }
                                {!this.state.pdfContent ?
                                    <div className="scouting-feed-group">
                                        <div className="scouting-feed-media" onScroll={(e) => this.getScoutScroll(e)}>
                                            {eventList.length !== 0 ? this.renderMessage() : <p className="text-center">No data found</p>}
                                        </div>
                                    </div> :
                                    <div className='page-pdf'>
                                        <AllPagesPdfViwer pdf={this.state.pdfContent} loader={this.props.decrement} />
                                    </div>
                                }
                                {
                                    this.props.summaryData &&
                                    this.props.summaryData.docs.length !== 0 &&
                                    <div className="Paginate mt-20">
                                        <ReactPaginate
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            forcePage={this.props.profileInfo.eventCurrentPage}
                                        />
                                    </div>
                                }
                            </div>

                            {/* <div className="event-blog">
                                {
                                    summaryData &&
                                        summaryData.docs &&
                                        summaryData.docs.length !== 0 ?
                                        <div className="row">
                                            {this.renderEvent()}
                                        </div>
                                        :
                                        <p className="text-center">No data found</p>
                                }
                            </div> */}
                        </div>
                        <ProductList />
                    </div>
                </div>
            </>
        )
    }
}

events.propTypes = {
    getScoutingFeed: PropTypes.func.isRequired,
    getDirectRecapInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileInfo: state.profileSummaryInfo
});

export default withRouter(connect(mapStateToProps, { getScoutingFeed, getDirectRecapInfo })(events));
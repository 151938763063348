import {
  DIVISIONTITLE_ERROR,
  DIVISIONTITLE_SUCCESS,
  DIVISION_ERROR,
  DIVISION_SUCCESS,
  ROSTERINFO_ERROR,
  ROSTERINFO_SUCCESS,
  MAIL_ROSTERINFO_SUCCESS,
  MAIL_ROSTERINFO_ERROR,
  ALL_MAIL_ROSTERINFO_SUCCESS,
  ALL_MAIL_ROSTERINFO_ERROR,
  DELETE_ROSTER_CONFIRMATION_SUCCESS,
  DELETE_ROSTER_CONFIRMATION_ERROR
} from "../../../../constants/view/coach/profile/rosterInfo.const";

const initialState = {
  rosterInfoData: null,
  rosterInfoError: null,
  divisonObj: {},
  divisonList: [],
  divisionaError: null,
  mailRosterInfoData: null,
  mailRosterInfoError: null,
  allMailRosterInfoData: null,
  allMailRosterInfoError: null,
  deleteRosterConfirmData: null,
  deleteRosterConfirmError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_ROSTER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        deleteRosterConfirmData: action.payload,
      };
      break;
    case DELETE_ROSTER_CONFIRMATION_ERROR:
      return {
        ...state,
        deleteRosterConfirmError: action.error,
      };
      break;
    case ROSTERINFO_SUCCESS:
      return {
        ...state,
        rosterInfoData: action.payload,
      };
      break;
    case ROSTERINFO_ERROR:
      return {
        ...state,
        rosterInfoData: action.error,
      };
      break;
    case MAIL_ROSTERINFO_SUCCESS:
      return {
        ...state,
        mailRosterInfoData: action.payload,
      };
      break;
    case MAIL_ROSTERINFO_ERROR:
      return {
        ...state,
        mailRosterInfoData: action.error,
      };
      break;
    case ALL_MAIL_ROSTERINFO_SUCCESS:
      return {
        ...state,
        allMailRosterInfoData: action.payload,
      };
      break;
    case ALL_MAIL_ROSTERINFO_ERROR:
      return {
        ...state,
        allMailRosterInfoData: action.error,
      };
      break;
    case DIVISION_SUCCESS:
      let objDivision = {};
      action.payload.map(e => { objDivision[e._id] = e.title })
      return {
        ...state,
        divisonList: action.payload,
        divisonObj: objDivision,
      };
      break;
    case DIVISION_ERROR:
      return {
        ...state,
        divisionaError: action.error,
      };
      break;
    case MAIL_ROSTERINFO_SUCCESS:
      return {
        ...state,
        mailRosterInfoData: action.payload,
      };
      break;
    case MAIL_ROSTERINFO_ERROR:
      return {
        ...state,
        mailRosterInfoData: action.error,
      };
      break;
    case ALL_MAIL_ROSTERINFO_SUCCESS:
      return {
        ...state,
        allMailRosterInfoData: action.payload,
      };
      break;
    case ALL_MAIL_ROSTERINFO_ERROR:
      return {
        ...state,
        allMailRosterInfoData: action.error,
      };
      break;
    default:
      return state;
      break;
  }
}

import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
   GET_COACH_LIST_SEARCH_ERROR,
   GET_COACH_LIST_SEARCH_REQUEST,
   GET_COACH_LIST_SEARCH_SUCCESS
} from '../../../../constants/view/player/profile/coachInfo.const';
import histrory from '../../../../../History';

export const subCoachList = (data) => dispatch => {
    // data.limit = data.limit;
    // data.page = data.page;
    // data.parentTeam = data.parentTeam;
    // data.name = data.search;
    // delete data.search
    console.log("data:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_COACH_LIST_SEARCH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/childTeam/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(searchteam => {
        dispatch({
            type: GET_COACH_LIST_SEARCH_SUCCESS,
            payload: searchteam.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_COACH_LIST_SEARCH_ERROR, error: error.response.data.message })
    });
};
import { combineReducers } from "redux";

import socialCheck from "./authantication/signup/step1.reducer";
import step2 from "./authantication/signup/step2.reducer";
import setRole from "./authantication/signup/step3.reducer";
import verifyOtp from "./authantication/verify.reducer";
import loginData from "./authantication/login.reducer";
import forgotData from "./authantication/forgot.reducer";

import headerData from "./containers/header.reducer";

import basicDetails from "./authantication/signup/coach/basic.reducer";
import teamDetails from "./authantication/signup/coach/team.reducer";
import facDetails from "./authantication/signup/coach/facility.reducer";
import rosterDetails from "./authantication/signup/coach/roster.reducer";
import trainingDetails from "./authantication/signup/coach/training.reducer";
import complateDetails from "./authantication/signup/coach/complete.reducer";

import personalInfo from "./view/coach/profile/personalInfo.reducer";
import facilityInfo from "./view/coach/profile/facilityInfo.reducer";
import rosterInfo from "./view/coach/profile/rosterInfo.reducer";
import trainingInfo from "./view/coach/profile/trainingInfo.reducer";
import teamInfo from "./view/coach/profile/teamInfo.reducer";
import playerInfo from "./view/coach/profile/playerInfo.reducer";
import coachInfo from "./view/coach/profile/coachInfo.reducer";
import linkedPlayerInfo from "./view/coach/profile/linkedPlayerInfo.reducer";
import licenseInfo from "./view/coach/license.reducer";

import playerTrainingInfo from "./view/player/profile/training.reducer";
import gameStatsInfo from "./view/player/profile/gameStats.reducer";
import gameGroundInfo from "./view/player/profile/gameBackground.reducer";
import subCoachInfo from "./view/player/profile/coachInfo.reducer";
import parentInfo from "./view/player/profile/parentInfo.reducer";

import aboutPlayerDetails from "./authantication/signup/player/about.reducer";
import basicPlayerDetails from "./authantication/signup/player/basic.reducer";
import teamPlayerDetails from "./authantication/signup/player/team.reducer";
import coachPlayerDetails from "./authantication/signup/player/coach.reducer";
import trainingPlayerDetails from "./authantication/signup/player/training.reducer";
import groundPlayerDetails from "./authantication/signup/player/ground.reducer";
import collagePlayerDetails from "./authantication/signup/player/collage.reducer";
import questionDetails from "./authantication/signup/player/question.reducer";
import complatePlayerDetails from "./authantication/signup/player/complete.reducer";

import basicFanDetails from "./authantication/signup/fan/basic.reducer";
import teamFanDetails from "./authantication/signup/fan/team.reducer";
import coachFanDetails from "./authantication/signup/fan/coach.reducer";
import parentDetails from "./authantication/signup/fan/parent.reducer";

import bollrDetails from "./landing/bollr.reducer";
import analyzrDetails from "./landing/analyzr.reducer";
import companyDetails from "./landing/company.reducer";

import playerPersonalInfo from "./view/player/profile/personalInfo.reducer";
import otherInfo from "./view/player/profile/otherInfo.reducer";
import dashboardInfo from "./view/player/dashboard.reducer";
import cartInfo from "./view/player/cart.reducer";

import api from "./common/api.reducer";
import paymentInfo from "./common/payment.reducer";
import subscriptionInfo from "./common/subscription.reducer";
import uploadCsvInfo from "./common/uploadcsv.reducer";
import billingInfo from "./common/billing.reducer";
import eventsInfo from "./common/events.reducer";
import sliderInfo from "./common/slider.reducer";
import leaguesInfo from "./common/leagues.reducer";
import eventDetails from "./common/eventDetails.reducer";
import leagueDetails from "./common/leagueDetails.reducer";
import pastChampionDetails from "./common/pastChampion.reducer";
import recommendProspect from "./common/recommendProspect.reducer";
import contactUs from "./common/contactUs.reducer";

import recapInfo from "./view/company/pwb_pws/recapInfo.reducer";

import fanBasicInfo from "./view/fan/setting/personalInfo.reducer";
import fanLinkedPlayerInfo from "./view/fan/setting/linkedPlayerInfo.reducer";
import fanDashboard from "./view/fan/dashboard.reducer";

import teamRanking from "./view/company/pwRanking/rankings/teamRanking.reducer";
import playerRanking from './view/company/pwRanking/rankings/playerRanking.reducer';
import teamProfile from "./view/company/pwRanking/teamProfile.reducer";
import mainBoardInfo from "./view/company/pwRanking/leaderboard/mainBoard.reducer";
import advancedSearch from "./view/company/pwRanking/advancedSearch.reducer";

import playerRankingInfo from "./view/company/pwRanking/leaderboard/playerRanking.reducer";
import profileSummaryInfo from "./view/company/pwRanking/profileSummary.reducer";
import teamProfileInfo from "./view/company/pwRanking/teamProfile.reducer";
import personalSummaryInfo from "./view/company/personalInfo.reducer";

import accountCreation from "./common/accountCreation.reducer";


export default combineReducers({
  step2: step2,
  socialCheck: socialCheck,
  verifyOtp: verifyOtp,
  setRole: setRole,

  basicDetails: basicDetails,
  teamDetails: teamDetails,
  facDetails: facDetails,

  personalInfo: personalInfo,
  facilityInfo: facilityInfo,
  rosterInfo: rosterInfo,
  trainingInfo: trainingInfo,
  teamInfo: teamInfo,
  complateDetails: complateDetails,
  linkedPlayerInfo: linkedPlayerInfo,

  playerTrainingInfo: playerTrainingInfo,
  gameStatsInfo: gameStatsInfo,
  gameGroundInfo: gameGroundInfo,
  subCoachInfo: subCoachInfo,
  parentInfo: parentInfo,

  rosterDetails: rosterDetails,
  trainingDetails: trainingDetails,
  loginData: loginData,
  forgotData: forgotData,
  api: api,

  headerData: headerData,

  playerPersonalInfo: playerPersonalInfo,
  otherInfo: otherInfo,

  aboutPlayerDetails: aboutPlayerDetails,
  basicPlayerDetails: basicPlayerDetails,
  teamPlayerDetails: teamPlayerDetails,
  coachPlayerDetails: coachPlayerDetails,
  trainingPlayerDetails: trainingPlayerDetails,
  groundPlayerDetails: groundPlayerDetails,
  collagePlayerDetails: collagePlayerDetails,
  questionDetails: questionDetails,
  complatePlayerDetails: complatePlayerDetails,

  bollrDetails: bollrDetails,
  analyzrDetails: analyzrDetails,
  paymentInfo: paymentInfo,
  dashboardInfo: dashboardInfo,
  cartInfo: cartInfo,
  subscriptionInfo: subscriptionInfo,
  uploadCsvInfo: uploadCsvInfo,
  licenseInfo: licenseInfo,
  playerInfo: playerInfo,
  coachInfo: coachInfo,

  billingInfo: billingInfo,
  eventsInfo: eventsInfo,
  sliderInfo: sliderInfo,
  leaguesInfo: leaguesInfo,
  eventDetails: eventDetails,
  leagueDetails: leagueDetails,
  pastChampionDetails: pastChampionDetails,
  recommendProspect: recommendProspect,
  contactUsForm: contactUs,

  companyDetails: companyDetails,

  recapInfo: recapInfo,

  basicFanDetails: basicFanDetails,
  teamFanDetails: teamFanDetails,
  coachFanDetails: coachFanDetails,
  parentDetails: parentDetails,

  fanBasicInfo: fanBasicInfo,
  fanLinkedPlayerInfo: fanLinkedPlayerInfo,
  fanDashboard: fanDashboard,

  teamRanking: teamRanking,
  playerRanking: playerRanking,
  teamProfile: teamProfile,

  playerRankingInfo: playerRankingInfo,
  profileSummaryInfo: profileSummaryInfo,
  teamProfileInfo: teamProfileInfo,
  personalSummaryInfo: personalSummaryInfo,
  mainBoardInfo: mainBoardInfo,
  advancedSearchInfo: advancedSearch,

  accountCreation: accountCreation
});

import React, { Component } from 'react';
import { imagesArr } from '../../assets/images';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class coverImageCrop extends Component {
    constructor(props) {

        super(props);
        this.state = {
            crop: {
                unit: '%',
                width: 30,
                aspect: 75 / 16,
            },
        };
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.props.imgObj.name
            );
            this.setState({ croppedImageUrl: croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const canvas = document.createElement("canvas");
        canvas.width = Math.ceil(crop.width * scaleX);
        canvas.height = Math.ceil(crop.height * scaleY);

        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                let fdata = {
                    file: blob,
                    url: this.fileUrl
                }
                resolve(fdata);
            }, 'image/jpeg');
        });
    }

    render() {
        const { flag, close, renderImg } = this.props;
        const { crop, croppedImageUrl } = this.state;

        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>Crop Image</h3>
                                <div className="modal_close" onClick={close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                            </div>
                            <div className="modal-body">
                                <div style={{ padding: "10px" }}>
                                    {renderImg && (
                                        <ReactCrop
                                            src={renderImg}
                                            crop={crop}
                                            ruleOfThirds
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}
                                    {croppedImageUrl && (
                                        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl.url} />
                                    )}
                                </div>

                            </div>
                            <div className="flex-align" style={{ padding: "10px" }}>
                                <button className="btn" onClick={() => this.props.getObj(croppedImageUrl)}>Save</button>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default coverImageCrop;

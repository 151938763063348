import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getPlayerOption, displaySave } from '../../saga/actions/views/coach/profile/playerInfo.action';
import Preloader from '../../components/preloader';

const animatedComponents = makeAnimated();

export class displayRoster extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sendReq: {
        rosterTeamId: [],
        _id: ""
      },
      optionsArr: [],
      optionData: "",
      loader: false,
      disArr: []
    };

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
      let data = this.props.disData;
      console.log("data:----", data);
      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          _id: data._id
        }
      }), () => {
        let newVal = [];
        data.rosterTeamId.map((item, i) => {
          newVal.push(item._id)
        });
        this.setState((prevState) => ({
          ...prevState,
          sendReq: {
            ...prevState.sendReq,
            rosterTeamId: newVal,
          },
        }), () => {
          let arrOpt = [];
          data.rosterTeamId.map((item, i) => {
            arrOpt.push({ value: item._id, label: item.rosterName })
          });

          this.setState((prevState) => ({
            ...prevState,
            optionData: arrOpt
          }), () => {
            console.log("optionData:---------", this.state.optionData);
          });
        });
      });
    }
    this.setLoader(true);
    this.props.getPlayerOption();
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.playerDetails &&
      nextProps.playerDetails.getPlayerOptionReq &&
      nextProps.playerDetails.getPlayerOptionData
    ) {
      console.log("nextProps.getPlayerOptionData:-----", nextProps.playerDetails.getPlayerOptionData);
      nextProps.playerDetails.getPlayerOptionReq = false;
      if (nextProps.playerDetails.getPlayerOptionData.flag) {
        let arr = [];
        nextProps.playerDetails.getPlayerOptionData.result.data.map((item, i) => {
          arr.push({ value: item._id, label: item.rosterName })
        });
        this.setState({
          optionsArr: arr,
        }, () => {
          nextProps.playerDetails.getPlayerOptionData = null;
          this.setLoader(false);
        })
      } else {
        this.commonCall.errorMessage(nextProps.playerDetails.getPlayerOptionData.message);
        nextProps.playerDetails.getPlayerOptionData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.disNewPlayerReq &&
      nextProps.playerDetails.disNewPlayerData
    ) {
      console.log("nextProps.disNewPlayerData:-----", nextProps.playerDetails.disNewPlayerData);
      nextProps.playerDetails.disNewPlayerReq = false;
      if (nextProps.playerDetails.disNewPlayerData.flag) {
        this.commonCall.successMessage(nextProps.playerDetails.disNewPlayerData.message);
        nextProps.playerDetails.disNewPlayerData = null;
        this.setLoader(false);
        this.props.close();
      } else {
        this.commonCall.errorMessage(nextProps.playerDetails.disNewPlayerData.message);
        nextProps.playerDetails.disNewPlayerData = null;
        this.setLoader(false);
      }
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  handleChange = (option) => {
    console.log(`Option selected:`, option);
  };

  setOption = (val) => {
    console.log("val:--", val);
    if (val !== null) {
      let valArr = [];
      val.map((item, i) => {
        valArr.push(item.value)
      });

      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          rosterTeamId: valArr
        },
        optionData: val
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        optionData: [],
        sendReq: {
          ...prevState.sendReq,
          rosterTeamId: []
        },
      }));
    }
  }

  onSave = () => {
    if (this.validator.allValid()) {
      console.log("adddd:----", this.state.sendReq);
      this.setLoader(true);
      this.props.displaySave(this.state.sendReq);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const { sendReq, optionsArr, loader } = this.state;
    const { flag, disData } = this.props;

    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="green-header modal-header">
                <h3>Change Roster Name</h3>
                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.white_modal_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body">
                <div className="team-body-inner">
                  <div className="form-group mb-0">
                    <label className="common-lable">Player Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      placeholder={disData.fname + " " + disData.lname} disabled
                    />
                  </div>
                  <div className="form-group mb-0">
                    <label className="common-lable">Roster Name</label>
                    <Select
                      defaultValue={this.state.optionData}
                      value={this.state.optionData}
                      options={optionsArr}
                      onInputChange={this.handleChange}
                      onChange={(e) => this.setOption(e)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                    />
                    <span className="validMsg">
                      {this.validator.message(
                        "Roster Name",
                        sendReq.rosterTeamId,
                        "required"
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex-align next-button">
                  <Link className="btn gray" onClick={this.props.close}>
                    Cancel
                  </Link>
                  <Link className="btn light-green" onClick={() => this.onSave()}>
                    Save
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Preloader flag={loader} />
      </>
    );
  }
}

displayRoster.propTypes = {
  getPlayerOption: PropTypes.func.isRequired,
  displaySave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  playerDetails: state.playerInfo,
});

export default connect(mapStateToProps, { getPlayerOption, displaySave })(displayRoster);

import {
    ADD_LINKED_PLAYER_SUCCESS,
    ADD_LINKED_PLAYER_ERROR,
    LIST_LINKED_PLAYER_SUCCESS,
    LIST_LINKED_PLAYER_ERROR,
    EDIT_LINKED_PLAYER_SUCCESS,
    EDIT_LINKED_PLAYER_ERROR,
    DISCONNECT_PLAYER_SUCCESS,
    DISCONNECT_PLAYER_ERROR,
    PHONE_VERIFY_PLAYER_SUCCESS,
    PHONE_VERIFY_PLAYER_ERROR
} from '../../../../constants/view/coach/profile/linkedPlayerInfo.const';

const initialState = {
    savePlayer: null,
    savePlayerError: null,

    editPlayer: null,
    editPlayerError: null,

    playerList: [],
    playerListError: null,

    disconnectPlayer: null,
    disconnectPlayerError: null,

    verifyPhone: null,
    verifyPhoneError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // save Player
        case ADD_LINKED_PLAYER_SUCCESS:
            return {
                ...state,
                savePlayer: action.payload,
            };
        case ADD_LINKED_PLAYER_ERROR:
            return {
                ...state,
                savePlayerError: action.error,
            };

        // list of Linked Player
        case LIST_LINKED_PLAYER_SUCCESS:
            return {
                ...state,
                playerList: action.payload,
            };
        case LIST_LINKED_PLAYER_ERROR:
            return {
                ...state,
                playerListError: action.error,
            };

        // edit Player
        case EDIT_LINKED_PLAYER_SUCCESS:
            return {
                ...state,
                editPlayer: action.payload,
            };
        case EDIT_LINKED_PLAYER_ERROR:
            return {
                ...state,
                editPlayerError: action.error,
            };
            
        // disconnect Player
        case DISCONNECT_PLAYER_SUCCESS:
            return {
                ...state,
                disconnectPlayer: action.payload,
            };
        case DISCONNECT_PLAYER_ERROR:
            return {
                ...state,
                disconnectPlayerError: action.error,
            };


        // verify phone number of Player
        case PHONE_VERIFY_PLAYER_SUCCESS:
            return {
                ...state,
                verifyPhone: action.payload,
            };
        case PHONE_VERIFY_PLAYER_ERROR:
            return {
                ...state,
                verifyPhoneError: action.error,
            };
        default:
            return state;
    }
}
import {
    SAVE_EVENT_REQUEST,
    SAVE_EVENT_SUCCESS,
    SAVE_EVENT_ERROR,

    SAVE_SOURCE_REQUEST,
    SAVE_SOURCE_SUCCESS,
    SAVE_SOURCE_ERROR,

    SAVE_RAPSODO_SOURCE_REQUEST,
    SAVE_RAPSODO_SOURCE_SUCCESS,
    SAVE_RAPSODO_SOURCE_ERROR,

    GET_LINK_REQUEST,
    GET_LINK_SUCCESS,
    GET_LINK_ERROR,

    GET_OPTION_REQUEST,
    GET_OPTION_SUCCESS,
    GET_OPTION_ERROR,

    GET_RMAP_REQUEST,
    GET_RMAP_SUCCESS,
    GET_RMAP_ERROR,

    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,

    GET_DEVICE_REQUEST,
    GET_DEVICE_SUCCESS,
    GET_DEVICE_ERROR,

    DELETE_EVENT_ERROR,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_REQUEST,

    ROW_DEVICE_DATA_SUCCESS,
    ROW_DEVICE_DATA_REQUEST,
    ROW_DEVICE_DATA_ERROR,

    GET_VIDEO_SOURCE_SUCCESS,
    GET_VIDEO_SOURCE_REQUEST,
    GET_VIDEO_SOURCE_ERROR,

    SAVE_VIDEO_SOURCE_SUCCESS,
    SAVE_VIDEO_SOURCE_REQUEST,
    SAVE_VIDEO_SOURCE_ERROR,

    IMPORT_VIDEO_SOURCE_SUCCESS,
    IMPORT_VIDEO_SOURCE_REQUEST,
    IMPORT_VIDEO_SOURCE_ERROR,

    TABLE_VIDEO_SOURCE_SUCCESS,
    TABLE_VIDEO_SOURCE_REQUEST,
    TABLE_VIDEO_SOURCE_ERROR,

    ATTACH_VIDEO_SOURCE_SUCCESS,
    ATTACH_VIDEO_SOURCE_REQUEST,
    ATTACH_VIDEO_SOURCE_ERROR,

    DEVICE_DATA_ROW_REQUEST,
    DEVICE_DATA_ROW_SUCCESS,
    DEVICE_DATA_ROW_ERROR,

    GET_RAPSODO_OPTION_REQUEST,
    GET_RAPSODO_OPTION_SUCCESS,
    GET_RAPSODO_OPTION_ERROR,

    MAP_RAPSODO_REQUEST,
    MAP_RAPSODO_SUCCESS,
    MAP_RAPSODO_ERROR,

    TEAM_SOURCE_DETAILS_REQUEST,
    TEAM_SOURCE_DETAILS_SUCCESS,
    TEAM_SOURCE_DETAILS_ERROR,

    TEAM_SOURCE_MAP_REQUEST,
    TEAM_SOURCE_MAP_SUCCESS,
    TEAM_SOURCE_MAP_ERROR,

    DK_AUTHANTICATION_REQUEST,
    DK_AUTHANTICATION_SUCCESS,
    DK_AUTHANTICATION_ERROR,

    GET_DK_AUTHANTICATION_REQUEST,
    GET_DK_AUTHANTICATION_SUCCESS,
    GET_DK_AUTHANTICATION_ERROR,

    GET_DK_DISCONNECT_REQUEST,
    GET_DK_DISCONNECT_SUCCESS,
    GET_DK_DISCONNECT_ERROR,

    DEL_SELL_VIDEO_REQUEST,
    DEL_SELL_VIDEO_SUCCESS,
    DEL_SELL_VIDEO_ERROR,

    GET_TEAM_LINK_LIST_REQUEST,
    GET_TEAM_LINK_LIST_SUCCESS,
    GET_TEAM_LINK_LIST_ERROR,

    TEAM_LINK_REQUEST,
    TEAM_LINK_SUCCESS,
    TEAM_LINK_ERROR,
    
} from '../../constants/common/uploadcsv.const';

const initialState = {
    saveEventReq: false,
    saveEventData: null,
    saveEventError: null,

    saveSourceReq: false,
    saveSourceData: null,
    saveSourceError: null,

    saveRapsodoSourceReq: false,
    saveRapsodoSourceData: null,
    saveRapsodoSourceError: null,

    getLinkReq: false,
    getLinkData: null,
    getLinkError: null,

    getOptionReq: false,
    getOptionData: null,
    getOptionError: null,

    getRmapReq: false,
    getRmapData: null,
    getRmapError: null,

    getEventReq: false,
    getEventData: null,
    getEventError: null,

    getDeviceReq: false,
    getDeviceData: null,
    getDeviceError: null,

    deleteEventReq: false,
    deleteEventData: null,
    deleteEventError: null,

    getRowDeviceReq: false,
    getRowDeviceData: null,
    getRowDeviceError: null,

    getVideoSourceReq: false,
    getVideoSourceData: null,
    getVideoSourceError: null,

    saveVideoSourceReq: false,
    saveVideoSourceData: null,
    saveVideoSourceError: null,

    importVideoSourceReq: false,
    importVideoSourceData: null,
    importVideoSourceError: null,

    tableVideoDataReq: false,
    tableVideoData: null,
    tableVideoDataError: null,

    attachVideoDataReq: false,
    attachVideoData: null,
    attachVideoDataError: null,

    deviceRowReq: false,
    deviceRowData: null,
    deviceRowError: null,

    getRapsodoOptionReq: false,
    getRapsodoOptionData: null,
    getRapsodoOptionError: null,

    mappingRapsodoReq: false,
    mappingRapsodoData: null,
    mappingRapsodoError: null,

    sourceTeamInfoReq: false,
    sourceTeamInfoData: null,
    sourceTeamInfoError: null,

    sourceTeamMapReq: false,
    sourceTeamMapData: null,
    sourceTeamMapError: null,

    DKAuthReq: false,
    DKAuthData: null,
    DKAuthError: null,

    getDKAuthReq: false,
    getDKAuthData: null,
    getDKAuthError: null,

    getDKDisconnectReq: false,
    getDKDisconnectData: null,
    getDKDisconnectError: null,

    delSellVideoReq: false,
    delSellVideoData: null,
    delSellVideoError: null,

    teamListReq: false,
    teamListData: null,
    teamListError: null,

    linkTeamReq: false,
    linkTeamData: null,
    linkTeamError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // delete sell video req    
        case DEL_SELL_VIDEO_REQUEST:
            return {
                ...state,
                delSellVideoReq: true,
            };
        case DEL_SELL_VIDEO_SUCCESS:
            return {
                ...state,
                delSellVideoData: action.payload,
            };
        case DEL_SELL_VIDEO_ERROR:
            return {
                ...state,
                delSellVideoError: action.error,
            };

        // create event req    
        case SAVE_EVENT_REQUEST:
            return {
                ...state,
                saveEventReq: true,
            };
        case SAVE_EVENT_SUCCESS:
            return {
                ...state,
                saveEventData: action.payload,
            };
        case SAVE_EVENT_ERROR:
            return {
                ...state,
                saveEventError: action.error,
            };

        // save source req    
        case SAVE_SOURCE_REQUEST:
            return {
                ...state,
                saveSourceReq: true,
            };
        case SAVE_SOURCE_SUCCESS:
            return {
                ...state,
                saveSourceData: action.payload,
            };
        case SAVE_SOURCE_ERROR:
            return {
                ...state,
                saveSourceError: action.error,
            };

        // save rapsodo source req    
        case SAVE_RAPSODO_SOURCE_REQUEST:
            return {
                ...state,
                saveRapsodoSourceReq: true,
            };
        case SAVE_RAPSODO_SOURCE_SUCCESS:
            return {
                ...state,
                saveRapsodoSourceData: action.payload,
            };
        case SAVE_RAPSODO_SOURCE_ERROR:
            return {
                ...state,
                saveRapsodoSourceError: action.error,
            };

        // get link req    
        case GET_LINK_REQUEST:
            return {
                ...state,
                getLinkReq: true,
            };
        case GET_LINK_SUCCESS:
            return {
                ...state,
                getLinkData: action.payload,
            };
        case GET_LINK_ERROR:
            return {
                ...state,
                getLinkError: action.error,
            };

        // get option req    
        case GET_OPTION_REQUEST:
            return {
                ...state,
                getOptionReq: true,
            };
        case GET_OPTION_SUCCESS:
            return {
                ...state,
                getOptionData: action.payload,
            };
        case GET_OPTION_ERROR:
            return {
                ...state,
                getOptionError: action.error,
            };

        // get roster map req    
        case GET_RMAP_REQUEST:
            return {
                ...state,
                getRmapReq: true,
            };
        case GET_RMAP_SUCCESS:
            return {
                ...state,
                getRmapData: action.payload,
            };
        case GET_RMAP_ERROR:
            return {
                ...state,
                getRmapError: action.error,
            };

        // get event req    
        case GET_EVENT_REQUEST:
            return {
                ...state,
                getEventReq: true,
            };
        case GET_EVENT_SUCCESS:
            return {
                ...state,
                getEventData: action.payload,
            };
        case GET_EVENT_ERROR:
            return {
                ...state,
                getEventError: action.error,
            };

        // get device req    
        case GET_DEVICE_REQUEST:
            return {
                ...state,
                getDeviceReq: true,
            };
        case GET_DEVICE_SUCCESS:
            return {
                ...state,
                getDeviceData: action.payload,
            };
        case GET_DEVICE_ERROR:
            return {
                ...state,
                getDeviceError: action.error,
            };

        // delete Event   
        case DELETE_EVENT_REQUEST:
            return {
                ...state,
                deleteEventReq: true,
            };
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                deleteEventData: action.payload,
            };
        case DELETE_EVENT_ERROR:
            return {
                ...state,
                deleteEventError: action.error,
            };

        // get row device   
        case ROW_DEVICE_DATA_REQUEST:
            return {
                ...state,
                getRowDeviceReq: true,
            };
        case ROW_DEVICE_DATA_SUCCESS:
            return {
                ...state,
                getRowDeviceData: action.payload,
            };
        case ROW_DEVICE_DATA_ERROR:
            return {
                ...state,
                getRowDeviceError: action.error,
            };

        // get video source
        case GET_VIDEO_SOURCE_REQUEST:
            return {
                ...state,
                getVideoSourceReq: true,
            };
        case GET_VIDEO_SOURCE_SUCCESS:
            return {
                ...state,
                getVideoSourceData: action.payload,
            };
        case GET_VIDEO_SOURCE_ERROR:
            return {
                ...state,
                getVideoSourceError: action.error,
            };

        // save video source
        case SAVE_VIDEO_SOURCE_REQUEST:
            return {
                ...state,
                saveVideoSourceReq: true,
            };
        case SAVE_VIDEO_SOURCE_SUCCESS:
            return {
                ...state,
                saveVideoSourceData: action.payload,
            };
        case SAVE_VIDEO_SOURCE_ERROR:
            return {
                ...state,
                saveVideoSourceError: action.error,
            };

        // import video source
        case IMPORT_VIDEO_SOURCE_REQUEST:
            return {
                ...state,
                importVideoSourceReq: true,
            };
        case IMPORT_VIDEO_SOURCE_SUCCESS:
            return {
                ...state,
                importVideoSourceData: action.payload,
            };
        case IMPORT_VIDEO_SOURCE_ERROR:
            return {
                ...state,
                importVideoSourceError: action.error,
            };

        // table video data
        case TABLE_VIDEO_SOURCE_REQUEST:
            return {
                ...state,
                tableVideoDataReq: true,
            };
        case TABLE_VIDEO_SOURCE_SUCCESS:
            return {
                ...state,
                tableVideoData: action.payload,
            };
        case TABLE_VIDEO_SOURCE_ERROR:
            return {
                ...state,
                tableVideoDataError: action.error,
            };

        // attach video data
        case ATTACH_VIDEO_SOURCE_REQUEST:
            return {
                ...state,
                attachVideoDataReq: true,
            };
        case ATTACH_VIDEO_SOURCE_SUCCESS:
            return {
                ...state,
                attachVideoData: action.payload,
            };
        case ATTACH_VIDEO_SOURCE_ERROR:
            return {
                ...state,
                attachVideoDataError: action.error,
            };

        // update device data row  
        case DEVICE_DATA_ROW_REQUEST:
            return {
                ...state,
                deviceRowReq: true,
            };
        case DEVICE_DATA_ROW_SUCCESS:
            return {
                ...state,
                deviceRowData: action.payload,
            };
        case DEVICE_DATA_ROW_ERROR:
            return {
                ...state,
                deviceRowError: action.error,
            };

        // get rapsodo option req    
        case GET_RAPSODO_OPTION_REQUEST:
            return {
                ...state,
                getRapsodoOptionReq: true,
            };
        case GET_RAPSODO_OPTION_SUCCESS:
            return {
                ...state,
                getRapsodoOptionData: action.payload,
            };
        case GET_RAPSODO_OPTION_ERROR:
            return {
                ...state,
                getRapsodoOptionError: action.error,
            };


        // mapping rapsodo option req    
        case MAP_RAPSODO_REQUEST:
            return {
                ...state,
                mappingRapsodoReq: true,
            };
        case MAP_RAPSODO_SUCCESS:
            return {
                ...state,
                mappingRapsodoData: action.payload,
            };
        case MAP_RAPSODO_ERROR:
            return {
                ...state,
                mappingRapsodoError: action.error,
            };

        // source team info req    
        case TEAM_SOURCE_DETAILS_REQUEST:
            return {
                ...state,
                sourceTeamInfoReq: true,
            };
        case TEAM_SOURCE_DETAILS_SUCCESS:
            return {
                ...state,
                sourceTeamInfoData: action.payload,
            };
        case TEAM_SOURCE_DETAILS_ERROR:
            return {
                ...state,
                sourceTeamInfoError: action.error,
            };

        // source team mapping req    
        case TEAM_SOURCE_MAP_REQUEST:
            return {
                ...state,
                sourceTeamMapReq: true,
            };
        case TEAM_SOURCE_MAP_SUCCESS:
            return {
                ...state,
                sourceTeamMapData: action.payload,
            };
        case TEAM_SOURCE_MAP_ERROR:
            return {
                ...state,
                sourceTeamMapError: action.error,
            };

        // DK Authantication req    
        case DK_AUTHANTICATION_REQUEST:
            return {
                ...state,
                DKAuthReq: true,
            };
        case DK_AUTHANTICATION_SUCCESS:
            return {
                ...state,
                DKAuthData: action.payload,
            };
        case DK_AUTHANTICATION_ERROR:
            return {
                ...state,
                DKAuthError: action.error,
            };

        // Get DK Authantication req    
        case GET_DK_AUTHANTICATION_REQUEST:
            return {
                ...state,
                getDKAuthReq: true,
            };
        case GET_DK_AUTHANTICATION_SUCCESS:
            return {
                ...state,
                getDKAuthData: action.payload,
            };
        case GET_DK_AUTHANTICATION_ERROR:
            return {
                ...state,
                getDKAuthError: action.error,
            };

        // Get DK Disconnected req    
        case GET_DK_DISCONNECT_REQUEST:
            return {
                ...state,
                getDKDisconnectReq: true,
            };
        case GET_DK_DISCONNECT_SUCCESS:
            return {
                ...state,
                getDKDisconnectData: action.payload,
            };
        case GET_DK_DISCONNECT_ERROR:
            return {
                ...state,
                getDKDisconnectError: action.error,
            };


        // get Team list for link   
        case GET_TEAM_LINK_LIST_REQUEST:
            return {
                ...state,
                teamListReq: true,
            };
        case GET_TEAM_LINK_LIST_SUCCESS:
            return {
                ...state,
                teamListData: action.payload,
            };
        case GET_TEAM_LINK_LIST_ERROR:
            return {
                ...state,
                teamListError: action.error,
            };


        // get Team list for link   
        case TEAM_LINK_REQUEST:
            return {
                ...state,
                linkTeamReq: true,
            };
        case TEAM_LINK_SUCCESS:
            return {
                ...state,
                linkTeamData: action.payload,
            };
        case TEAM_LINK_ERROR:
            return {
                ...state,
                linkTeamError: action.error,
            };

        default:
            return state;
    }
}

import { GAMESTATS_SUCCESS , GAMESTATS_ERROR ,GAMESTATS_REG} from '../../../../constants/view/player/profile/gameStats.const';

const initialState = {
    gameStatsData : {},
    gameStatsError : null,
    ragisterInfo : null
}

export default function(state = initialState , action){

        switch (action.type) {
            case GAMESTATS_SUCCESS:
                return{
                    ...state,
                    gameStatsData : action.payload
                }
            case GAMESTATS_ERROR:
                return{
                    ...state,
                    gameStatsError : action.payload
                }
            break;
            case GAMESTATS_REG:
                return{
                    ...state,
                    ragisterInfo:action.payload
                }
                break;
            default:
                return state;
                break;
        }
}
export const COM_AD_SLIDER_REQUEST = 'COM_AD_SLIDER_REQUEST';
export const COM_AD_SLIDER_SUCCESS = 'COM_AD_SLIDER_SUCCESS';
export const COM_AD_SLIDER_ERROR   = 'COM_AD_SLIDER_ERROR';

export const COM_DIVISION_SLIDER_REQUEST = 'COM_DIVISION_SLIDER_REQUEST';
export const COM_DIVISION_SLIDER_SUCCESS = 'COM_DIVISION_SLIDER_SUCCESS';
export const COM_DIVISION_SLIDER_ERROR   = 'COM_DIVISION_SLIDER_ERROR';

export const COM_DIVISION_EVENTLIST_REQUEST = 'COM_DIVISION_EVENTLIST_REQUEST';
export const COM_DIVISION_EVENTLIST_SUCCESS = 'COM_DIVISION_EVENTLIST_SUCCESS';
export const COM_DIVISION_EVENTLIST_ERROR   = 'COM_DIVISION_EVENTLIST_ERROR';

export const COM_DIVISION_SLIDER_DIV_LIST_REQUEST = 'COM_DIVISION_SLIDER_DIV_LIST_REQUEST';
export const COM_DIVISION_SLIDER_DIV_LIST_SUCCESS = 'COM_DIVISION_SLIDER_DIV_LIST_SUCCESS';
export const COM_DIVISION_SLIDER_DIV_LIST_ERROR   = 'COM_DIVISION_SLIDER_DIV_LIST_ERROR';

export const COM_GET_AGE_DIV_REQUEST = 'COM_GET_AGE_DIV_REQUEST';
export const COM_GET_AGE_DIV_SUCCESS = 'COM_GET_AGE_DIV_SUCCESS';
export const COM_GET_AGE_DIV_ERROR   = 'COM_GET_AGE_DIV_ERROR';

export const GET_LIVE_EVENT_SCORE_REQUEST = 'GET_LIVE_EVENT_SCORE_REQUEST';
export const GET_LIVE_EVENT_SCORE_SUCCESS = 'GET_LIVE_EVENT_SCORE_SUCCESS';
export const GET_LIVE_EVENT_SCORE_ERROR   = 'GET_LIVE_EVENT_SCORE_ERROR';

import {
    GET_PACKAGE_REQUEST,
    GET_PACKAGE_SUCCESS,
    GET_PACKAGE_ERROR,
} from '../../constants/landing/bollr.const';

const initialState = {
    packReq: false,
    packData: null,
    packError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Pachage req    
        case GET_PACKAGE_REQUEST:
            return {
                ...state,
                packReq: true,
            };
        case GET_PACKAGE_SUCCESS:
            return {
                ...state,
                packData: action.payload,
            };
        case GET_PACKAGE_ERROR:
            return {
                ...state,
                packError: action.error,
            };

        default:
            return state;
    }
}
import {
    GET_PLAYER_PROFILE_INFO_REQUEST,
    GET_PLAYER_PROFILE_INFO_SUCCESS,
    GET_PLAYER_PROFILE_INFO_ERROR,

    GET_SCOUTING_FEED_REQUEST,
    GET_SCOUTING_FEED_SUCCESS,
    GET_SCOUTING_FEED_ERROR,

    GET_LOGIN_RESPONSE_REQUEST,
    GET_LOGIN_RESPONSE_SUCCESS,
    GET_LOGIN_RESPONSE_ERROR,

    SAVE_SOCIAL_MEDIA_REQUEST,
    SAVE_SOCIAL_MEDIA_SUCCESS,
    SAVE_SOCIAL_MEDIA_ERROR,

    GET_COACH_TEAM_LIST_REQUEST,
    GET_COACH_TEAM_LIST_SUCCESS,
    GET_COACH_TEAM_LIST_ERROR,

    SAVE_COACH_DETAILS_REQUEST,
    SAVE_COACH_DETAILS_SUCCESS,
    SAVE_COACH_DETAILS_ERROR,

    GET_USERID_RESPONSE_REQUEST,
    GET_USERID_RESPONSE_SUCCESS,
    GET_USERID_RESPONSE_ERROR,

    DELETE_COACH_DETAILS_REQUEST,
    DELETE_COACH_DETAILS_SUCCESS,
    DELETE_COACH_DETAILS_ERROR,

    SEND_VIEW_COUNT_REQUEST,
    SEND_VIEW_COUNT_SUCCESS,
    SEND_VIEW_COUNT_ERROR,

    DIRECT_RECAP_INFORMATION_REQUEST,
    DIRECT_RECAP_INFORMATION_SUCCESS,
    DIRECT_RECAP_INFORMATION_ERROR,

    GET_PROFILE_FIELDS_REQUEST,
    GET_PROFILE_FIELDS_SUCCESS,
    GET_PROFILE_FIELDS_ERROR,

    GET_PW_SCOUTING_FEED_REQUEST,
    GET_PW_SCOUTING_FEED_SUCCESS,
    GET_PW_SCOUTING_FEED_ERROR,

} from '../../../../constants/view/company/pwRanking/profileSummary.const';

const initialState = {
    sendSummaryReq: {
        tab: "summary",
        id: "",
        eventTeamType: 0,
        sortOn: {
            eventstartDate: "",
            eventendDate: "",
            awardstartDate: "",
            awardendDate: "",
            gallerystartDate: "",
            galleryendDate: ""
        },
        seasonType: 1,
        pitching: "basic",
        batting: "basic",
        filterOn: "",
        field: "",
        metricSubType: "Speed",
        metricType: 1,
        companyId: localStorage.getItem("cid"),
        statsType: "Hitting"
    },
    getBackSummaryFields: [],
    tabs: 0,
    backupTab: 0,
    statsType: 0,
    eventPageReq: {
        id: "",
        page: 1,
        limit: 10,
        companyId: localStorage.getItem("cid"),
        userId: localStorage.getItem("userID"),
    },
    mediaPageReq: {
        id: "",
        page: 1,
        limit: 10,
        companyId: localStorage.getItem("cid"),
        userId: localStorage.getItem("userID"),
    },
    eventCurrentPage: 0,

    getPlayerProfileReq: false,
    getPlayerProfileData: null,
    getPlayerProfileError: null,

    getScoutingReq: false,
    getScoutingData: null,
    getScoutingError: null,

    getLoginResReq: false,
    getLoginResData: null,
    getLoginResError: null,

    saveSocialLinkReq: false,
    saveSocialLinkData: null,
    saveSocialLinkError: null,

    getCoachTeamListReq: false,
    getCoachTeamListData: null,
    getCoachTeamListError: null,

    saveCoachDetailReq: false,
    saveCoachDetailData: null,
    saveCoachDetailError: null,

    delCoachDetailReq: false,
    delCoachDetailData: null,
    delCoachDetailError: null,

    getUserIDResReq: false,
    getUserIDResData: null,
    getUserIDResError: null,

    saveViewCountReq: false,
    saveViewCountData: null,
    saveViewCountError: null,

    directRecapReq: false,
    directRecapData: null,
    directRecapError: null,

    profileFieldsReq: false,
    profileFieldsData: null,
    profileFieldsError: null,

    getPWScoutingReq: false,
    getPWScoutingData: null,
    getPWScoutingError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get PW Player Scouting req    
        case GET_PW_SCOUTING_FEED_REQUEST:
            return {
                ...state,
                getPWScoutingReq: true,
            };

        case GET_PW_SCOUTING_FEED_SUCCESS:
            return {
                ...state,
                getPWScoutingData: action.payload,
            };
        case GET_PW_SCOUTING_FEED_ERROR:
            return {
                ...state,
                getPWScoutingError: action.error,
            };

        case "SET_DEFAULT_VALUE":
            return {
                ...state,
                ...action.payload
            }

        // profile fields req    
        case GET_PROFILE_FIELDS_REQUEST:
            return {
                ...state,
                profileFieldsReq: true,
            };

        case GET_PROFILE_FIELDS_SUCCESS:
            return {
                ...state,
                profileFieldsData: action.payload,
            };
        case GET_PROFILE_FIELDS_ERROR:
            return {
                ...state,
                profileFieldsError: action.error,
            };

        // direct recap req    
        case DIRECT_RECAP_INFORMATION_REQUEST:
            return {
                ...state,
                directRecapReq: true,
            };

        case DIRECT_RECAP_INFORMATION_SUCCESS:
            return {
                ...state,
                directRecapData: action.payload,
            };
        case DIRECT_RECAP_INFORMATION_ERROR:
            return {
                ...state,
                directRecapError: action.error,
            };

        // save view count req    
        case SEND_VIEW_COUNT_REQUEST:
            return {
                ...state,
                saveViewCountReq: true,
            };

        case SEND_VIEW_COUNT_SUCCESS:
            return {
                ...state,
                saveViewCountData: action.payload,
            };
        case SEND_VIEW_COUNT_ERROR:
            return {
                ...state,
                saveViewCountError: action.error,
            };

        // Delete coach detail req    
        case DELETE_COACH_DETAILS_REQUEST:
            return {
                ...state,
                delCoachDetailReq: true,
            };

        case DELETE_COACH_DETAILS_SUCCESS:
            return {
                ...state,
                delCoachDetailData: action.payload,
            };
        case DELETE_COACH_DETAILS_ERROR:
            return {
                ...state,
                delCoachDetailError: action.error,
            };


        // Save coach detail req    
        case SAVE_COACH_DETAILS_REQUEST:
            return {
                ...state,
                saveCoachDetailReq: true,
            };

        case SAVE_COACH_DETAILS_SUCCESS:
            return {
                ...state,
                saveCoachDetailData: action.payload,
            };
        case SAVE_COACH_DETAILS_ERROR:
            return {
                ...state,
                saveCoachDetailError: action.error,
            };

        // Get team list req    
        case GET_COACH_TEAM_LIST_REQUEST:
            return {
                ...state,
                getCoachTeamListReq: true,
            };

        case GET_COACH_TEAM_LIST_SUCCESS:
            return {
                ...state,
                getCoachTeamListData: action.payload,
            };
        case GET_COACH_TEAM_LIST_ERROR:
            return {
                ...state,
                getCoachTeamListError: action.error,
            };

        // Get login response req    
        case GET_LOGIN_RESPONSE_REQUEST:
            return {
                ...state,
                getLoginResReq: true,
            };

        case GET_LOGIN_RESPONSE_SUCCESS:
            return {
                ...state,
                getLoginResData: action.payload,
            };
        case GET_LOGIN_RESPONSE_ERROR:
            return {
                ...state,
                getLoginResError: action.error,
            };

        // Get Player Scouting req    
        case GET_SCOUTING_FEED_REQUEST:
            return {
                ...state,
                getScoutingReq: true,
            };

        case GET_SCOUTING_FEED_SUCCESS:
            return {
                ...state,
                getScoutingData: action.payload,
            };
        case GET_SCOUTING_FEED_ERROR:
            return {
                ...state,
                getScoutingError: action.error,
            };

        // Get Player Profile req    
        case GET_PLAYER_PROFILE_INFO_REQUEST:
            return {
                ...state,
                getPlayerProfileReq: true,
            };

        case GET_PLAYER_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                getPlayerProfileData: action.payload,
            };
        case GET_PLAYER_PROFILE_INFO_ERROR:
            return {
                ...state,
                getPlayerProfileError: action.error,
            };

        // Save social media link req    
        case SAVE_SOCIAL_MEDIA_REQUEST:
            return {
                ...state,
                saveSocialLinkReq: true,
            };

        case SAVE_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                saveSocialLinkData: action.payload,
            };
        case SAVE_SOCIAL_MEDIA_ERROR:
            return {
                ...state,
                saveSocialLinkError: action.error,
            };

        // Get userid response req    
        case GET_USERID_RESPONSE_REQUEST:
            return {
                ...state,
                getUserIDResReq: true,
            };

        case GET_USERID_RESPONSE_SUCCESS:
            return {
                ...state,
                getUserIDResData: action.payload,
            };
        case GET_USERID_RESPONSE_ERROR:
            return {
                ...state,
                getUserIDResError: action.error,
            };

        default:
            return state;
    }
}
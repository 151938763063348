export const GET_FAC_REQUEST = 'GET_FAC_REQUEST';
export const GET_FAC_SUCCESS = 'GET_FAC_SUCCESS';
export const GET_FAC_ERROR = 'GET_FAC_ERROR';

export const GET_FAC_SEARCH_REQUEST = 'GET_FAC_SEARCH_REQUEST';
export const GET_FAC_SEARCH_SUCCESS = 'GET_FAC_SEARCH_SUCCESS';
export const GET_FAC_SEARCH_ERROR = 'GET_FAC_SEARCH_ERROR';

export const UP_FAC_IMAGE_REQUEST = 'UP_FAC_IMAGE_REQUEST';
export const UP_FAC_IMAGE_SUCCESS = 'UP_FAC_IMAGE_SUCCESS';
export const UP_FAC_IMAGE_ERROR = 'UP_FAC_IMAGE_ERROR';

export const SAVE_FAC_REQUEST = 'SAVE_FAC_REQUEST';
export const SAVE_FAC_SUCCESS = 'SAVE_FAC_SUCCESS';
export const SAVE_FAC_ERROR = 'SAVE_FAC_ERROR';




import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import CommonHeroBanner from '../../components/commonHeroBanner';
import { connect } from 'react-redux';
import FanDashboard from './dashboard/fan';
import ParentDashboard from './dashboard/parent';
import commonCalls from '../../businesslogic/commonCalls';

class dashboard extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
           role:""
        }
        this.commonCall = new commonCalls();
    }
    componentWillReceiveProps = (nextProps) => {

        if (nextProps.login.claimMsg) {
            this.commonCall.successMessage(nextProps.login.claimMsg);
            nextProps.login.claimMsg = null
        }

		if (nextProps.profileDetails &&
			nextProps.profileDetails.headFanData &&
			nextProps.profileDetails.headFanReq
		) {
			if (nextProps.profileDetails.headFanData.result.profile) {
			   this.setState({
                   role: nextProps.profileDetails.headFanData.result.profile.role
               })
			}
		}
    }
    render() {
        const { role } = this.state;
        return (
            <div>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {role && role === "Fan" &&  <FanDashboard />}
                {role && role === "Parent" &&  <ParentDashboard props={this.props}/>}
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) =>({
    profileDetails: state.headerData,
    login: state.loginData,
})

export default connect(mapStateToProps)(dashboard);

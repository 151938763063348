export const ROSTERINFO_REQUEST = "ROSTERINFO_REQUEST";
export const ROSTERINFO_SUCCESS = "ROSTERINFO_SUCCESS";
export const ROSTERINFO_ERROR = "ROSTERINFO_ERROR";


export const DIVISION_REQUEST = "DIVISION_REQUEST";
export const DIVISION_SUCCESS = "DIVISION_SUCCESS";
export const DIVISION_ERROR = "DIVISION_ERROR";

export const MAIL_ROSTERINFO_REQUEST = "MAIL_ROSTERINFO_REQUEST";
export const MAIL_ROSTERINFO_SUCCESS = " MAIL_ROSTERINFO_SUCCESS";
export const MAIL_ROSTERINFO_ERROR = "MAIL_ROSTERINFO_ERROR";

export const ALL_MAIL_ROSTERINFO_REQUEST = "ALL_MAIL_ROSTERINFO_REQUEST";
export const ALL_MAIL_ROSTERINFO_SUCCESS = " ALL_MAIL_ROSTERINFO_SUCCESS";
export const ALL_MAIL_ROSTERINFO_ERROR = "ALL_MAIL_ROSTERINFO_ERROR";

export const DELETE_ROSTER_CONFIRMATION_REQUEST = "DELETE_ROSTER_CONFIRMATION_REQUEST";
export const DELETE_ROSTER_CONFIRMATION_SUCCESS = " DELETE_ROSTER_CONFIRMATION_SUCCESS";
export const DELETE_ROSTER_CONFIRMATION_ERROR = "DELETE_ROSTER_CONFIRMATION_ERROR";

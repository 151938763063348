import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    SAVE_COMPLETE_REQUEST,
    SAVE_COMPLETE_SUCCESS,
    SAVE_COMPLETE_ERROR,
} from '../../../../constants/authantication/signup/coach/complete.const';
import histrory from '../../../../../History';

export const saveComplete = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_COMPLETE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/coach/complete`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(complete => {
        console.log("complete:----", complete);
        dispatch({
            type: SAVE_COMPLETE_SUCCESS,
            payload: complete.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_COMPLETE_ERROR, error: error.response.data.message })
    });
};


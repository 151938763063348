import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import { Link } from 'react-router-dom';
import Preloader from '../../../components/preloader';
import Select from 'react-select';
import { getTeamLinkList, scoutingLinkTeam } from '../../../saga/actions/common/uploadcsv.action'

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '4em',
    color: '$gray-btn',
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '1',
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

class coachScoutingLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            teamSearchReq:{
                bcsource_id: "",
                text: ""
            },
            linkTeam:{
                bcsource_id: "",
                team: ""
            },
            optionsArr: [],
            selectedTeam: localStorage.getItem("linkTeam")
        }
    }


    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.getIds();
    }
    
    getIds = () => {
        let url = new URL(document.location);
        let sid = url.pathname.split("/")[2];

        this.setState((prevState) => ({
            ...prevState,
            teamSearchReq: {
                ...prevState.linkData,
                bcsource_id: sid
            },
            linkTeam: {
                ...prevState.mapArr,
                bcsource_id: sid
            },
        }), () => {
            if (this.props.uploadCsvInfo.teamListData) {
                this.props.uploadCsvInfo.teamListData = null;
            }
            this.props.getTeamLinkList(this.state.teamSearchReq, "Coach");
        });
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.teamListReq &&
            nextProps.uploadCsvInfo.teamListData
        ) {
            console.log("nextProps from teamListData:----", nextProps.uploadCsvInfo.teamListData);
            nextProps.uploadCsvInfo.teamListReq = false;
            if (nextProps.uploadCsvInfo.teamListData.flag) {
                let arr = [];
                arr.push({ value: "exclude", label: "Select Team" })
                nextProps.uploadCsvInfo.teamListData.result.data.map((item, i) => {
                    arr.push({ value: item, label: item })
                });

                this.setState({
                    optionsArr: arr
                }, () => {
                    nextProps.uploadCsvInfo.teamListData = null;
                    this.setLoader(false);
                })
            } else {
                this.setState({
                    optionsArr: []
                }, () => {
                    this.commonCall.errorMessage(nextProps.uploadCsvInfo.teamListData.message);
                    nextProps.uploadCsvInfo.teamListData = null;
                    setTimeout(() => {
                        this.setLoader(false);
                    }, 1500);
                })
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.linkTeamReq &&
            nextProps.uploadCsvInfo.linkTeamData
        ) {
            console.log("nextProps from linkTeamData:----", nextProps.uploadCsvInfo.linkTeamData);
            nextProps.uploadCsvInfo.linkTeamReq = false;
            if (nextProps.uploadCsvInfo.linkTeamData.flag) {
                // this.commonCall.successMessage(nextProps.uploadCsvInfo.linkTeamData.message);
                nextProps.uploadCsvInfo.linkTeamData = null;
            }
        }
    }

    handleChange = (option) => {
        this.setState((prevState) => ({
            ...prevState,
            teamSearchReq: {
                ...prevState.teamSearchReq,
                text: option,
            },
        }), () => {
            if (option.length !== 0) {
                console.log(`Option selected:`, this.state.teamSearchReq);
                this.props.getTeamLinkList(this.state.teamSearchReq, "Coach");
            } else {
                this.props.uploadCsvInfo.teamListData = null;
                this.props.getTeamLinkList(this.state.teamSearchReq, "Coach");
            }
        })
    };

    setOption = (val, pid) => {
        console.log("val:--", val, pid);
        this.setState((prevState) => ({
            ...prevState,
            linkTeam: {
                ...prevState.linkTeam,
                team: val.value
            },
            selectedTeam: val.value
        }), () => {
            console.log("set:-----", this.state.linkTeam);
            // this.setLoader(true);
            this.props.scoutingLinkTeam(this.state.linkTeam, "Coach");
        });

    }

    formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }


    render() {
        const { loader, sourceData, isTeam, allTeam, sendTeamSource } = this.state;

        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {/* Uploadcsv Link data */}
                <section className="uploadcsv-linkdata uploadcsv">
                    <div className="container">
                        <div className={`white-bg tablecontent`}>
                            <div className="tableinfo">
                                <h3>Link Data</h3>
                                <p className="mb-0">
                                Select the Team that this Scouting Data is for. Once submitted it may take a few seconds to upload.
                                </p>
                            </div>
                            <div className="select_team">
                                <div className="team_title"><h4>Select Team</h4></div>
                                <div className="team-selectbox">
                                    <Select
                                        options={this.state.optionsArr}
                                        formatGroupLabel={this.formatGroupLabel}
                                        onInputChange={this.handleChange}
                                        onChange={(e) => this.setOption(e)}
                                        value={this.state.selectedTeam === "exclude" ? "" : this.state.optionsArr.find(_ => _.value === this.state.selectedTeam)}
                                        menuPlacement="auto"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-align">
                            <Link className="btn gray" to={'/coach-uploadcsv'}>Submit</Link>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

coachScoutingLink.propTypes = {};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default connect(mapStateToProps,{
    getTeamLinkList,
    scoutingLinkTeam
})(coachScoutingLink);

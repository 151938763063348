import {
    COM_FEATURE_LEAGUES_REQUEST,
    COM_FEATURE_LEAGUES_SUCCESS,
    COM_FEATURE_LEAGUES_ERROR,

    COM_UP_COMING_LEAGUES_REQUEST,
    COM_UP_COMING_LEAGUES_SUCCESS,
    COM_UP_COMING_LEAGUES_ERROR,

    COM_OPTION_LEAGUES_REQUEST,
    COM_OPTION_LEAGUES_SUCCESS,
    COM_OPTION_LEAGUES_ERROR,

    COM_LEAGUES_STATE_REQUEST,
    COM_LEAGUES_STATE_SUCCESS,
    COM_LEAGUES_STATE_ERROR,
} from '../../constants/common/leagues.const';

const initialState = {
    featureLeagueReq: false,
    featureLeagueData: null,
    featureLeagueError: null,

    upcomingLeagueReq: false,
    upcomingLeagueData: null,
    upcomingLeagueError: null,

    optionLeagueListReq: false,
    optionLeagueListData: null,
    optionLeagueListError: null,

    leagueStateListReq: false,
    leagueStateListData: null,
    leagueStateListError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Featured leagues req    
        case COM_FEATURE_LEAGUES_REQUEST:
            return {
                ...state,
                featureLeagueReq: true,
            };

        case COM_FEATURE_LEAGUES_SUCCESS:
            return {
                ...state,
                featureLeagueData: action.payload,
            };
        case COM_FEATURE_LEAGUES_ERROR:
            return {
                ...state,
                featureLeagueError: action.error,
            };

        // Upcoming leagues req    
        case COM_UP_COMING_LEAGUES_REQUEST:
            return {
                ...state,
                upcomingLeagueReq: true,
            };

        case COM_UP_COMING_LEAGUES_SUCCESS:
            return {
                ...state,
                upcomingLeagueData: action.payload,
            };
        case COM_UP_COMING_LEAGUES_ERROR:
            return {
                ...state,
                upcomingLeagueError: action.error,
            };

        // leagues list req    
        case COM_OPTION_LEAGUES_REQUEST:
            return {
                ...state,
                optionLeagueListReq: true,
            };

        case COM_OPTION_LEAGUES_SUCCESS:
            return {
                ...state,
                optionLeagueListData: action.payload,
            };
        case COM_OPTION_LEAGUES_ERROR:
            return {
                ...state,
                optionLeagueListError: action.error,
            };

        // state leagues list req    
        case COM_LEAGUES_STATE_REQUEST:
            return {
                ...state,
                leagueStateListReq: true,
            };

        case COM_LEAGUES_STATE_SUCCESS:
            return {
                ...state,
                leagueStateListData: action.payload,
            };
        case COM_LEAGUES_STATE_ERROR:
            return {
                ...state,
                leagueStateListError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postCoverFileUpload, saveCoverPhoto } from '../saga/actions/common/api.action';
import PropTypes from 'prop-types';
import Preloader from '../components/preloader';
import CoverImageCrop from '../views/popup/coverImageCrop';
import commonCalls from '../businesslogic/commonCalls';
import AvatarEditor from 'react-avatar-editor'

class commonHeroBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pathName: "",
            file: null,
            coverPhoto: false,
            loader: false,
            coverId: "",
            userType: "",
            cropPopup: false,
            renderFile: "",
            imgObj: "",
            isEditor: false,
            avatarFile: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.commonCall = new commonCalls();
    }

    componentWillMount() {
        let url = new URL(document.location);
        let path = url.pathname.split("/")[1];

        if (path === "login" ||
            path === "signup" ||
            path === "verify" ||
            path === "signup2" ||
            path === "signup3" ||
            path === "forgot" ||
            path === "reset" ||
            path === "coach-signup4" ||
            path === "coach-setting" ||
            path === "coach-dashboard" ||
            path === "player-signup4" ||
            path === "player-setting" ||
            path === "player-cart" ||
            path === "player-dashboard" ||
            path === "fan-signup4" ||
            path === "fan-setting" ||
            path === "fan-dashboard"
        ) {
            this.setState({
                pathName: ""
            })
        } else if (path === "coach-payment" || path === "player-payment") {
            this.setState({
                pathName: "Payment"
            })
        } else if (path === "coach-billing" || path === "player-billing") {
            this.setState({
                pathName: "Billing"
            })
        } else if (path === "coach-subscription" || path === "player-subscription") {
            this.setState({
                pathName: "Subscription"
            })
        } else if (path === "coach-uploadcsv" || path === "player-uploadcsv") {
            this.setState({
                pathName: "Upload Data"
            })
        } else if (path === "coach-license") {
            this.setState({
                pathName: "Manage Seats"
            })
        } else if (path === "bollrWebGL") {
            this.setState({
                pathName: "BollR WebGL"
            })
        } else if (path === "404") {
            this.setState({
                pathName: "404 Page Not Found"
            })
        } else if (path === "about-us") {
            this.setState({
                pathName: "About Us",
            })
        } else if (path === "our-team") {
            this.setState({
                pathName: "Our Team"
            })
        } else if (path === "press-room") {
            this.setState({
                pathName: "Press Room"
            })
        } else if (path === "bcteam") {
            this.setState({
                pathName: "BCTeam"
            })
        } else if (path === "pitchr") {
            this.setState({
                pathName: "PitchR"
            })
        } else if (path === "analyzr") {
            this.setState({
                pathName: "AnalyzR"
            })
        } else if (path === "ovr") {
            this.setState({
                pathName: "OvR"
            })
        }
        else if (path === "terms-conditions") {
            this.setState({
                pathName: "Terms and Conditions"
            })
        }
        else if (path === "privacy-policy") {
            this.setState({
                pathName: "Privacy Policy"
            })
        }

        if (path === "coach-setting" || path === "player-setting" || path === "fan-setting") {
            this.setState({
                pathName: "Dashboard",
                coverPhoto: true,
                userType: (path === "coach-setting") ? "Coach" : (path === "player-setting") ? "Player" : "Fan"
            })
        } else {
            this.setState({
                coverPhoto: false,
            })
        }

    };

    componentDidMount(){
        this.setState({
            file: this.props.dataList ?
                this.props.dataList?.eventImages ? this.props.dataList.eventImages.filePath
                    : this.props.dataList?.leagueImages ? this.props.dataList.leagueImages.filePath : '' : ''
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps._FILE &&
            nextProps._FILE.coverUploadData
        ) {
            this.setState({
                file: nextProps._FILE.coverUploadData.filePath,
                coverId: nextProps._FILE.coverUploadData._id,
            }, () => {
                nextProps._FILE.coverUploadData = null;
                this.props.saveCoverPhoto({ profileWall: this.state.coverId }, this.state.userType);
            });
        }

        if (nextProps._FILE &&
            nextProps._FILE.saveCoverRes &&
            nextProps._FILE.saveCoverData
        ) {
            nextProps._FILE.saveCoverRes = false;
            this.setState({
                file: nextProps._FILE.saveCoverData.result.filePath,
                imgObj: "",
                cropPopup: false,
                renderFile: ""
            }, () => {
                nextProps._FILE.saveCoverData = null;
                document.body.classList.remove('modal-open');
                this.setLoader(false);
            });
        }

        // if (nextProps.profileDetails &&
        //     nextProps.profileDetails.headData
        // ) {
        //     if (nextProps.profileDetails.headData.result.profileWall) {
        //         let url = new URL(document.location);
        //         let path = url.pathname.split("/")[1];
        //         if (path === "about-us" || path === "our-team" || path === "press-room" || path === "analyzr" || path === "ovr" || path === "bcteam" || path === "pitchr") {
        //             this.setState({
        //                 file: null
        //             })
        //         } else {
        //             this.setState({
        //                 file: this.props.dataList ?
        //                     this.props.dataList?.eventImages ? this.props.dataList.eventImages.filePath
        //                         : this.props.dataList?.leagueImages ? this.props.dataList.leagueImages.filePath : nextProps.profileDetails.headData.result.profileWall
        //                     : nextProps.profileDetails.headData.result.profileWall
        //             })
        //         }
        //     } else {
        //         this.setState({
        //             file: this.props.dataList ?
        //                 this.props.dataList?.eventImages ? this.props.dataList.eventImages.filePath
        //                     : this.props.dataList?.leagueImages ? this.props.dataList.leagueImages.filePath : '' : ''
        //         })
        //     }
        // }

        // if (nextProps.profileDetails &&
        //     nextProps.profileDetails.headPlayerData
        // ) {
        //     if (nextProps.profileDetails.headPlayerData.result.profileWall) {
        //         let url = new URL(document.location);
        //         let path = url.pathname.split("/")[1];

        //         if (path === "about-us" || path === "our-team" || path === "press-room" || path === "analyzr" || path === "ovr" || path === "bcteam" || path === "pitchr") {
        //             this.setState({
        //                 file: null
        //             })
        //         } else {
        //             this.setState({
        //                 file: this.props.dataList ?
        //                     this.props.dataList?.eventImages ? this.props.dataList.eventImages.filePath
        //                         : this.props.dataList?.leagueImages ? this.props.dataList.leagueImages.filePath : nextProps.profileDetails.headPlayerData.result.profileWall
        //                     : nextProps.profileDetails.headPlayerData.result.profileWall
        //             })
        //         }
        //     } else {
        //         this.setState({
        //             file: this.props.dataList ?
        //                 this.props.dataList?.eventImages ? this.props.dataList.eventImages.filePath
        //                     : this.props.dataList?.leagueImages ? this.props.dataList.leagueImages.filePath : '' : ''
        //         })
        //     }
        // }

        // if (nextProps.profileDetails &&
        //     nextProps.profileDetails.headFanData
        // ) {
        //     if (nextProps.profileDetails.headFanData.result.profileWall) {
        //         let url = new URL(document.location);
        //         let path = url.pathname.split("/")[1];
        //         if (path === "about-us" || path === "our-team" || path === "press-room" || path === "analyzr" || path === "ovr" || path === "bcteam" || path === "pitchr") {
        //             this.setState({
        //                 file: null
        //             })
        //         } else {
        //             this.setState({
        //                 file: this.props.dataList ?
        //                     this.props.dataList?.eventImages ? this.props.dataList.eventImages.filePath
        //                         : this.props.dataList?.leagueImages ? this.props.dataList.leagueImages.filePath : nextProps.profileDetails.headFanData.result.profileWall
        //                     : nextProps.profileDetails.headFanData.result.profileWall
        //             })
        //         }
        //     }
        // }

    }

    handleChange(event) {
        let file = event.target.files[0];
        if (file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "png") {
            return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG file");
        }
        this.setState({
            imgObj: file,
            cropPopup: true,
            renderFile: URL.createObjectURL(file)
        });
    }

    handleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            cropPopup: false
        });
    }

    onHadleObject = (obj) => {
        this.setLoader(true);
        this.setState({
            file: null,
            avatarFile: obj.url,
            cropPopup: false,
            isEditor: true
        }, () => {
            document.body.classList.remove('modal-open');
            this.setLoader(false);
        });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    saveCover = () => {
        if (this.editor) {
            const img = this.editor.getImageScaledToCanvas().toDataURL()

            this.setState({
                isEditor: false,
                file: img
            })

            const canvas = this.editor.getImageScaledToCanvas().toDataURL();
            fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    blob.name = this.state.imgObj.name;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    let fdata = {
                        file: blob,
                        url: this.fileUrl
                    }
                    this.setLoader(true);
                    this.props.postCoverFileUpload(fdata.file, 16, this.state.userType)
                });
        }
    }

    setEditorRef = (editor) => this.editor = editor

    render() {
        const { loader, imgObj, cropPopup, renderFile } = this.state;
        const dataList = this.props;
        console.log('dataList', dataList)
        return (
            <>
                <section className={`${localStorage.getItem("cid") ? (localStorage.getItem("company") === "pioneer" || dataList?.dataList?.isNewCompanyEvent) ? "commomn-bg tryout_bg":"commomn-bg event-bg" :(localStorage.getItem("company") === "pioneer" || dataList?.dataList?.isNewCompanyEvent) ? "commomn-bg tryout_bg": "commomn-bg"} ${this.state.isEditor ? 'common-bg-active' : ''}`} style={{ backgroundImage: `url(${this.state.file ? this.state.file : (localStorage.getItem("company") === "pioneer" || dataList?.dataList?.isNewCompanyEvent) ? "/static/media/tryout_camps.6648d771.png":"/static/media/common-bg.1507abb8.jpg"})` }}>
                    {this.state.isEditor && <AvatarEditor
                        ref={this.setEditorRef}
                        image={this.state.avatarFile ? this.state.avatarFile : "/static/media/common-bg.1507abb8.jpg"}
                        width={1862}
                        height={378}
                        border={20}
                        style={{ width: '100%', height: '100%' }}
                        // color={[255, 255, 255, 0.6]} // kRGBA
                        scale={1.2}
                    />}
                    {!this.state.isEditor && <div className="container">
                        <div className="commomn-bg-text"><h1>{this.state.pathName}</h1></div>
                    </div>}
                    {
                        this.state.isEditor && <div className="profile_banner">
                            <div className="profile_banner_inner" onClick={this.saveCover}>
                                <i class="fas fa-camera"></i>
                                save
                            </div>
                        </div>
                    }
                    {this.state.coverPhoto && !this.state.isEditor &&
                        <div className="profile_banner">
                            <div className="profile_banner_inner">
                                <i class="fas fa-camera"></i>
                                Edit Cover
                                {/* .heic,.HEIC */}
                                <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={this.handleChange} />
                            </div>

                        </div>}
                </section>
                <Preloader flag={loader} />
                <CoverImageCrop
                    imgObj={imgObj}
                    flag={cropPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />
            </>
        );
    }
}

commonHeroBanner.propTypes = {
    postCoverFileUpload: PropTypes.func.isRequired,
    saveCoverPhoto: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    _FILE: state.api,
    profileDetails: state.headerData,
});

export default connect(mapStateToProps, { postCoverFileUpload, saveCoverPhoto })(commonHeroBanner);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { rosterList } from '../../../../saga/actions/authantication/signup/player/coach.action';
import Preloader from '../../../../components/preloader';

class Roster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rosterArr: [],
            filter: {
                page: 1,
                limit: 10,
                search: ""
            }
        }
    }

    componentWillMount() {
        this.props.onRef(this);
        this.setLoader(true);
        this.props.rosterList(this.state.filter);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.coachDetails &&
            nextProps.coachDetails.rosterListRes &&
            nextProps.coachDetails.rosterListData
        ) {
            nextProps.coachDetails.rosterListRes = false;
            if (nextProps.coachDetails.rosterListData.flag) {
                if (this.state.filter.search) {
                    return this.setState({
                        rosterArr: nextProps.coachDetails.rosterListData.result.rosterTeamlist,
                    }, () => {
                        nextProps.coachDetails.rosterListData = null;
                        this.setLoader(false);
                    })
                }
                if (nextProps.coachDetails.rosterListData.result.hasOwnProperty('myrosterTeam') && nextProps.coachDetails.rosterListData.result.myrosterTeam.length !== 0) {
                    let val = nextProps.coachDetails.rosterListData.result.rosterTeamlist;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.rosterArr.concat(temp);
                    for (let i = 0; i < nextProps.coachDetails.rosterListData.result.myrosterTeam.length; i++) {
                        joined.unshift(nextProps.coachDetails.rosterListData.result.myrosterTeam[i])
                    }

                    this.setState({
                        rosterArr: joined,
                        selectedId: nextProps.coachDetails.rosterListData.result.myrosterTeam[0]._id,
                    }, () => {
                        nextProps.coachDetails.rosterListData = null;
                        this.setLoader(false);
                    })
                } else {
                    let val = nextProps.coachDetails.rosterListData.result.rosterTeamlist;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.rosterArr.concat(temp);
                    this.setState({
                        rosterArr: joined
                    }, () => {
                        nextProps.coachDetails.rosterListData = null;
                        this.setLoader(false);
                    });
                }
            }
        } else {
            this.setLoader(false);
            nextProps.coachDetails.rosterListData = null;
        }

    }

    setSelectValue = (val, cb) => {
        this.setState({
            rosterArr: val,
        }, () => cb(true));
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "roster") {
            this.setState((prevState) => ({
                ...prevState,
                selectedId: value
            }));
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    page: 1,
                    limit: 10,
                    search: value
                },
            }), () => {
                if (value.length !== 0) {
                    this.setLoader(true);
                    this.props.rosterList(this.state.filter);
                } else {
                    this.state.rosterArr = [];
                    this.props.coachDetails.rosterListData = null;
                    this.setLoader(true);
                    this.props.rosterList(this.state.filter);
                }
            });
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.rosterList(this.state.filter);
    }

    renderCoachList = () => {
        return (
            this.state.rosterArr.length !== 0 ?
                this.state.rosterArr.map((roster, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={roster.teamLogo ? roster.teamLogo : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{roster.rosterName && roster.rosterName}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="radio"
                                    id="Baseball"
                                    value={roster._id}
                                    name="roster"
                                    onChange={(e) => this.onHandleChange(e)}
                                    checked={roster._id === this.state.selectedId ? true : false}
                                />
                                <span for="Baseball"></span>
                            </div>
                        </div>
                    );
                }) :
                <h4 className="text-center">No Data Found</h4>
        );
    }

    validationUpdate = (cb) => {
        if (this.state.selectedId) {
            cb({ data: { rosterTeamId: this.state.selectedId }, flag: true });
        } else {
            cb({ data: '', flag: false });
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    page: this.state.filter.page + 1
                }
            }), () => {
                this.setLoader(true);
                this.props.rosterList(this.state.filter);
            })
        }
    }

    render() {
        const { profileData } = this.props;
        const { loader, filter, invite } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg playes-team">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname},
                            </h2>
                            <p>
                                Find your facility or team Roster Group by typing in their name below.
                            </p>
                        </div>
                        {
                            !invite &&
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your roster" name="search" value={filter.search} onChange={this.onHandleChange} />
                                        {/* <Link className="serch-btn" onClick={this.onSearch}><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="scrollbar_team" onScroll={this.getScroll}>
                            <div className="select_team ml-0">
                                {this.renderCoachList()}
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

Roster.propTypes = {
    coachList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    coachDetails: state.coachPlayerDetails
});

export default connect(mapStateToProps, { rosterList })(Roster);


export const SAVE_EVENT_REQUEST = 'SAVE_EVENT_REQUEST';
export const SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS';
export const SAVE_EVENT_ERROR = 'SAVE_EVENT_ERROR';

export const SAVE_SOURCE_REQUEST = 'SAVE_SOURCE_REQUEST';
export const SAVE_SOURCE_SUCCESS = 'SAVE_SOURCE_SUCCESS';
export const SAVE_SOURCE_ERROR = 'SAVE_SOURCE_ERROR';

export const SAVE_RAPSODO_SOURCE_REQUEST = 'SAVE_RAPSODO_SOURCE_REQUEST';
export const SAVE_RAPSODO_SOURCE_SUCCESS = 'SAVE_RAPSODO_SOURCE_SUCCESS';
export const SAVE_RAPSODO_SOURCE_ERROR = 'SAVE_RAPSODO_SOURCE_ERROR';

export const GET_LINK_REQUEST = 'GET_LINK_REQUEST';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const GET_LINK_ERROR = 'GET_LINK_ERROR';

export const GET_OPTION_REQUEST = 'GET_OPTION_REQUEST';
export const GET_OPTION_SUCCESS = 'GET_OPTION_SUCCESS';
export const GET_OPTION_ERROR = 'GET_OPTION_ERROR';

export const GET_RMAP_REQUEST = 'GET_RMAP_REQUEST';
export const GET_RMAP_SUCCESS = 'GET_RMAP_SUCCESS';
export const GET_RMAP_ERROR = 'GET_RMAP_ERROR';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';

export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_ERROR = 'GET_DEVICE_ERROR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const ROW_DEVICE_DATA_REQUEST = 'ROW_DEVICE_DATA_REQUEST';
export const ROW_DEVICE_DATA_SUCCESS = 'ROW_DEVICE_DATA_SUCCESS';
export const ROW_DEVICE_DATA_ERROR = 'ROW_DEVICE_DATA_ERROR';

export const GET_VIDEO_SOURCE_REQUEST = 'GET_VIDEO_SOURCE_REQUEST';
export const GET_VIDEO_SOURCE_SUCCESS = 'GET_VIDEO_SOURCE_SUCCESS';
export const GET_VIDEO_SOURCE_ERROR = 'GET_VIDEO_SOURCE_ERROR';

export const SAVE_VIDEO_SOURCE_REQUEST = 'SAVE_VIDEO_SOURCE_REQUEST';
export const SAVE_VIDEO_SOURCE_SUCCESS = 'SAVE_VIDEO_SOURCE_SUCCESS';
export const SAVE_VIDEO_SOURCE_ERROR = 'SAVE_VIDEO_SOURCE_ERROR';

export const IMPORT_VIDEO_SOURCE_REQUEST = 'IMPORT_VIDEO_SOURCE_REQUEST';
export const IMPORT_VIDEO_SOURCE_SUCCESS = 'IMPORT_VIDEO_SOURCE_SUCCESS';
export const IMPORT_VIDEO_SOURCE_ERROR = 'IMPORT_VIDEO_SOURCE_ERROR';

export const TABLE_VIDEO_SOURCE_REQUEST = 'TABLE_VIDEO_SOURCE_REQUEST';
export const TABLE_VIDEO_SOURCE_SUCCESS = 'TABLE_VIDEO_SOURCE_SUCCESS';
export const TABLE_VIDEO_SOURCE_ERROR = 'TABLE_VIDEO_SOURCE_ERROR';

export const ATTACH_VIDEO_SOURCE_REQUEST = 'ATTACH_VIDEO_SOURCE_REQUEST';
export const ATTACH_VIDEO_SOURCE_SUCCESS = 'ATTACH_VIDEO_SOURCE_SUCCESS';
export const ATTACH_VIDEO_SOURCE_ERROR = 'ATTACH_VIDEO_SOURCE_ERROR';

export const DEVICE_DATA_ROW_REQUEST = 'DEVICE_DATA_ROW_REQUEST';
export const DEVICE_DATA_ROW_SUCCESS = 'DEVICE_DATA_ROW_SUCCESS';
export const DEVICE_DATA_ROW_ERROR = 'DEVICE_DATA_ROW_ERROR';

export const GET_RAPSODO_OPTION_REQUEST = 'GET_RAPSODO_OPTION_REQUEST';
export const GET_RAPSODO_OPTION_SUCCESS = 'GET_RAPSODO_OPTION_SUCCESS';
export const GET_RAPSODO_OPTION_ERROR = 'GET_RAPSODO_OPTION_ERROR';

export const MAP_RAPSODO_REQUEST = 'MAP_RAPSODO_REQUEST';
export const MAP_RAPSODO_SUCCESS = 'MAP_RAPSODO_SUCCESS';
export const MAP_RAPSODO_ERROR = 'MAP_RAPSODO_ERROR';

export const TEAM_SOURCE_DETAILS_REQUEST = 'TEAM_SOURCE_DETAILS_REQUEST';
export const TEAM_SOURCE_DETAILS_SUCCESS = 'TEAM_SOURCE_DETAILS_SUCCESS';
export const TEAM_SOURCE_DETAILS_ERROR = 'TEAM_SOURCE_DETAILS_ERROR';

export const TEAM_SOURCE_MAP_REQUEST = 'TEAM_SOURCE_MAP_REQUEST';
export const TEAM_SOURCE_MAP_SUCCESS = 'TEAM_SOURCE_MAP_SUCCESS';
export const TEAM_SOURCE_MAP_ERROR = 'TEAM_SOURCE_MAP_ERROR';

export const DK_AUTHANTICATION_REQUEST = 'DK_AUTHANTICATION_REQUEST';
export const DK_AUTHANTICATION_SUCCESS = 'DK_AUTHANTICATION_SUCCESS';
export const DK_AUTHANTICATION_ERROR = 'DK_AUTHANTICATION_ERROR';

export const GET_DK_AUTHANTICATION_REQUEST = 'GET_DK_AUTHANTICATION_REQUEST';
export const GET_DK_AUTHANTICATION_SUCCESS = 'GET_DK_AUTHANTICATION_SUCCESS';
export const GET_DK_AUTHANTICATION_ERROR = 'GET_DK_AUTHANTICATION_ERROR';

export const GET_DK_DISCONNECT_REQUEST = 'GET_DK_DISCONNECT_REQUEST';
export const GET_DK_DISCONNECT_SUCCESS = 'GET_DK_DISCONNECT_SUCCESS';
export const GET_DK_DISCONNECT_ERROR = 'GET_DK_DISCONNECT_ERROR';

export const DEL_SELL_VIDEO_REQUEST = 'DEL_SELL_VIDEO_REQUEST';
export const DEL_SELL_VIDEO_SUCCESS = 'DEL_SELL_VIDEO_SUCCESS';
export const DEL_SELL_VIDEO_ERROR = 'DEL_SELL_VIDEO_ERROR';

export const GET_TEAM_LINK_LIST_REQUEST = 'GET_TEAM_LINK_LIST_REQUEST';
export const GET_TEAM_LINK_LIST_SUCCESS = 'GET_TEAM_LINK_LIST_SUCCESS';
export const GET_TEAM_LINK_LIST_ERROR = 'GET_TEAM_LINK_LIST_ERROR';

export const TEAM_LINK_REQUEST = 'TEAM_LINK_REQUEST';
export const TEAM_LINK_SUCCESS = 'TEAM_LINK_SUCCESS';
export const TEAM_LINK_ERROR = 'TEAM_LINK_ERROR';
import React, { Component } from 'react';
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from "../../assets/images";
import DivisionSlider from "../../views/company/components/slider/divisionSlider";
import AdsSlider from "../../views/company/components/slider/adsSlider";
import TopEvents from "../../views/company/components/common/topEvents";
import UpComingEvents from "../../views/company/components/common/upComingEvents";
import PressRoom from "../../views/company/components/common/pressRoom";
import ProudSponsors from "../../views/company/components/common/proudSponsors";
import Shop from "../../views/company/components/common/shop";
import Preloader from '../../components/preloader';
import { connect } from 'react-redux';
import AdditionalInfo from '../../views/company/components/common/AdditionalInfo';

class pioneerBaseball extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0,
            bannerReq: {
                moduleId: 5
            },
            comId: ""
        }
    }

    componentDidMount = () => {
        localStorage.removeItem("textName");
        if (localStorage.getItem("company") !== null) {
            let path = "/" + localStorage.getItem("company");
            this.props.history.push(path);
        } else {
            this.props.history.push("/");
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonData &&
            nextProps.commonData.getChange
        ) {
            console.log("nextProps.commonData.getChange:----", nextProps.commonData.getChange);
            this.setState({
                comId: nextProps.commonData.getChange.result._id
            }, () => {
                nextProps.commonData.getChange = null;
            })
        }
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { loaderCount, bannerReq, comId } = this.state;

        return (
            <>
                <Header />
                {
                    localStorage.getItem("cid") === comId &&
                    <div className="event-main">

                        {/* banner section */}

                        <img style={{width: '100%'}} src={imagesArr.pioneer_banner_homepage} alt="pioneer"/>

                        {/* upcoming Event */}

                        <UpComingEvents isPresent={3} filterEventType={false} filterCompany={false} eventTeamType="" cType="pioneer" textName="Tryouts" increment={this.increment} decrement={this.decrement} eventLabel={true} eventLinkLabel={true} filterForm={true} hideChkBox={false} noOfRec={6} eventType={1} showCase={false} />

                         {/* Additional info and FAQ */}

                         <AdditionalInfo />
                         
                        {/* Proud Sponsors */}

                        <ProudSponsors increment={this.increment} decrement={this.decrement} />

                    </div>
                }
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />

            </>
        );
    }
}

const mapStateToProps = state => ({
    commonData: state.api,
});
export default connect(mapStateToProps)(pioneerBaseball);
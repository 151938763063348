import axios from "axios";
import { API_Url, Auth_Fan_Key, checkIsValidFanToken } from "../../../helpers/authToken";
import {
  PARENT_LP_LIST_REQUEST,
  PARENT_LP_LIST_SUCCESS,
  PARENT_LP_LIST_ERROR,

  PLAYER_TOKEN_REQUEST,
  PLAYER_TOKEN_SUCCESS,
  PLAYER_TOKEN_ERROR,
} from "../../../constants/view/fan/dashboard.const";
import histrory from '../../../../History';


export const geLinkedPlayerList = () => (dispatch) => {
    dispatch({ type: PARENT_LP_LIST_REQUEST })
    checkIsValidFanToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/fan/dashboard/player/list`,
    headers: {
      "x-auth-token": Auth_Fan_Key,
      Accept: "application/json",
    },
  })
    .then((player) => {
      dispatch({
        type: PARENT_LP_LIST_SUCCESS,
        payload: player.data.result.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: PARENT_LP_LIST_ERROR,
          error: error.response.data.message,
        });
    });
};

export const getPlayerToken = (data) => (dispatch) => {
  dispatch({ type: PLAYER_TOKEN_REQUEST })
  checkIsValidFanToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/fan/create/player/token`,
    data: data,
    headers: {
      "x-auth-token": Auth_Fan_Key,
      Accept: "application/json",
    },
  })
    .then((player) => {
      dispatch({
        type: PLAYER_TOKEN_SUCCESS,
        payload: player.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: PLAYER_TOKEN_ERROR,
          error: error.response.data.message,
        });
    });
};


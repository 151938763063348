export const GET_PLAYER_COACH_SEARCH_REQUEST = 'GET_PLAYER_COACH_SEARCH_REQUEST';
export const GET_PLAYER_COACH_SEARCH_SUCCESS = 'GET_PLAYER_COACH_SEARCH_SUCCESS';
export const GET_PLAYER_COACH_SEARCH_ERROR = 'GET_PLAYER_COACH_SEARCH_ERROR';

export const SAVE_PLAYER_COACH_REQUEST = 'SAVE_PLAYER_COACH_REQUEST';
export const SAVE_PLAYER_COACH_SUCCESS = 'SAVE_PLAYER_COACH_SUCCESS';
export const SAVE_PLAYER_COACH_ERROR = 'SAVE_PLAYER_COACH_ERROR';

export const UPDATE_PLAYER_COACH_REQUEST = 'UPDATE_PLAYER_COACH_REQUEST';
export const UPDATE_PLAYER_COACH_SUCCESS = 'UPDATE_PLAYER_COACH_SUCCESS';
export const UPDATE_PLAYER_COACH_ERROR = 'UPDATE_PLAYER_COACH_ERROR';


export const ROSTER_LIST_REQUEST = 'ROSTER_LIST_REQUEST';
export const ROSTER_LIST_SUCCESS = 'ROSTER_LIST_SUCCESS';
export const ROSTER_LIST_ERROR = 'ROSTER_LIST_ERROR';

export const SAVE_COACH_ROSTER_REQUEST = 'SAVE_COACH_ROSTER_REQUEST';
export const SAVE_COACH_ROSTER_SUCCESS = 'SAVE_COACH_ROSTER_SUCCESS';
export const SAVE_COACH_ROSTER_ERROR = 'SAVE_COACH_ROSTER_ERROR';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import { imagesArr } from '../../../assets/images';
import { getAdvancedSearch, getFilterValue } from '../../../saga/actions/views/company/pwRanking/advancedSearch.action';
import commonCalls from "../../../businesslogic/commonCalls";
import Preloader from '../../../components/preloader';
import ReactPaginate from "react-paginate";
import { advacedSearchColumn } from "../../../businesslogic/content";

var sortArr = [];
class advancedSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                page: 0,
                limit: 0,
                type: 4,
                sportType: 0,
                search: "",
                state: "",
                gradeYear: "",
                position: "",
                sort: {},
                print: false,
                export: false,
                event: "",
                companyId: localStorage.getItem("cid")
            },
            advacedSearchList: [],
            advacedSearchCols: [],
            loaderCount: 0,
            activeFilters: [],
            filterObject: [],
            position: [],
            gradeYear: [],
            state: [],
            sportType: [],

            offset: 0,
            perPage: 10,
            currentPage: "",
            pageCount: ""
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.getAdvancedSearch();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.searchInfo &&
            nextProps.searchInfo.getAdvancedSearchReq &&
            nextProps.searchInfo.getAdvancedSearchData
        ) {
            nextProps.searchInfo.getAdvancedSearchReq = false;
            if (nextProps.searchInfo.getAdvancedSearchData.flag) {
                this.setState({
                    advacedSearchList: nextProps.searchInfo.getAdvancedSearchData.result.docs,
                    advacedSearchCols: nextProps.searchInfo.getAdvancedSearchData.result.keys ? nextProps.searchInfo.getAdvancedSearchData.result.keys : [],
                    pageCount: Math.ceil(nextProps.searchInfo.getAdvancedSearchData.result.total / this.state.perPage),
                }, () => {
                    if (this.state.sendReq.export) {
                        window.open(nextProps.searchInfo.getAdvancedSearchData.result.csvLink, "_blank");
                        this.setState({
                            sendReq: {
                                ...this.state.sendReq,
                                export: false
                            },
                        })
                    }
                    if (this.state.sendReq.print) {
                        window.open(nextProps.searchInfo.getAdvancedSearchData.result.pdfLink, "_blank");
                        this.setState({
                            sendReq: {
                                ...this.state.sendReq,
                                print: false
                            },
                        })
                    }
                    nextProps.searchInfo.getAdvancedSearchData = null;
                    if (this.state.filterObject.length === 0) {
                        this.props.getFilterValue();
                    } else {
                        this.decrement();
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.searchInfo.getAdvancedSearchData.message);
                nextProps.searchInfo.getAdvancedSearchData = null;
                this.decrement();
            }
        }

        if (nextProps.searchInfo &&
            nextProps.searchInfo.getFilterReq &&
            nextProps.searchInfo.getFilterData
        ) {
            nextProps.searchInfo.getFilterReq = false;
            if (nextProps.searchInfo.getFilterData.flag) {
                this.setState({
                    filterObject: nextProps.searchInfo.getFilterData.result
                }, () => {
                    nextProps.searchInfo.getFilterData = null;
                    this.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.searchInfo.getFilterData.message);
                nextProps.searchInfo.getFilterData = null;
                this.decrement();
            }
        }
    }

    getAdvancedSearch = () => {
        this.increment();
        this.props.getAdvancedSearch(this.state.sendReq);
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    renderPerformanceData = () => {
        return (
            this.state.advacedSearchList &&
                this.state.advacedSearchList.length !== 0 ?
                this.state.advacedSearchList.map((item, i) => {
                    return (
                        <tr>
                            <td>{i + 1}</td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    {/* <img src={item.athlete.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player" /> */}
                                    <div className="athlete-info">
                                        <h3>{item?.athlete?.fname + " " + item?.athlete?.lname}</h3>
                                        {/* <p>
                                            <span>{item.athlete.profile &&
                                                item.athlete.profile.hasOwnProperty('fielding-pos') && item.athlete.profile['fielding-pos'].length !== 0 ?
                                                item.athlete.profile['fielding-pos'].map((pos) => (
                                                    pos
                                                )).reduce((prev, curr) => [prev, ", ", curr])
                                                : ""}
                                            </span>
                                            <span>#652</span>
                                            <span>{item.gradeYear}</span>
                                            <span>175 lbs</span>
                                        </p>
                                        <h6><b>Height : </b>{item.athlete.profile.height ? item.athlete.profile.height : "-"}</h6>
                                        <h6><b>Weight : </b>{item.athlete.profile.weight ? item.athlete.profile.weight : "-"}</h6> */}
                                    </div>
                                </div>
                            </td>
                            <td>{item['10YardDash'] ? item['10YardDash'] + "s" : "-"}</td>
                            <td>{item['60YardDash'] ? item['60YardDash'] + "s" : "-"}</td>
                            <td>{item['5-10-5'] ? item['5-10-5'] + "s" : "-"}</td>
                            <td>{item.broadJump ? item.broadJump + "''" : "-"}</td>
                            <td>{item.verticalJump ? item.verticalJump + "''" : "-"}</td>
                            <td>{item.chinUps ? item.chinUps : "-"}</td>
                            <td>{item.pullUps ? item.pullUps : "-"}</td>
                            <td>{item.sitUps ? item.sitUps : "-"}</td>
                            <td>{item.pushUps ? item.pushUps : "-"}</td>
                            <td>{item.plankTest ? item.plankTest : "-"}</td>
                            <td>{item.handGripStrenght ? item.handGripStrenght : "-"}</td>
                            <td>{item.catcherPopTime ? item.catcherPopTime : "-"}</td>
                            <td>{item.homeToFirst ? item.homeToFirst : "-"}</td>
                            <td>{item.homeToSecond ? item.homeToSecond : "-"}</td>
                            <td>{item.homeToHome ? item.homeToHome : "-"}</td>
                            <td>{item.bullpenStrikePercentage ? item.bullpenStrikePercentage + "%" : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.performance.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    renderPitchingData = () => {
        return (
            this.state.advacedSearchList &&
                this.state.advacedSearchList.length !== 0 ?
                this.state.advacedSearchList.map((item, i) => {
                    return (
                        <tr>
                            <td>{i + 1}</td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    {/* <img src={item.athlete.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player" /> */}
                                    <div className="athlete-info">
                                        <h3>{item?.athlete?.fname + " " + item?.athlete?.lname}</h3>
                                        {/* <p>
                                            <span>{item.athlete.profile &&
                                                item.athlete.profile.hasOwnProperty('fielding-pos') && item.athlete.profile['fielding-pos'].length !== 0 ?
                                                item.athlete.profile['fielding-pos'].map((pos) => (
                                                    pos
                                                )).reduce((prev, curr) => [prev, ", ", curr])
                                                : ""}
                                            </span>
                                            <span>#652</span>
                                            <span>{item.gradeYear}</span>
                                            <span>175 lbs</span>
                                        </p>
                                        <h6><b>Height : </b>{item.athlete.profile.height ? item.athlete.profile.height : "-"}</h6>
                                        <h6><b>Weight : </b>{item.athlete.profile.weight ? item.athlete.profile.weight : "-"}</h6> */}
                                    </div>
                                </div>
                            </td>
                            <td>{item.maxVelocity ? item.maxVelocity + "mph" : "-"}</td>
                            <td>{item.avgVelocity ? item.avgVelocity + "mph" : "-"}</td>
                            <td>{item.maxSpinRate ? item.maxSpinRate : "-"}</td>
                            <td>{item.avgSpinRate ? item.avgSpinRate : "-"}</td>
                            <td>{item.spinEfficiency ? item.spinEfficiency : "-"}</td>
                            <td>{item.avgTilt ? item.avgTilt : "-"}</td>
                            <td>{item.ERA ? item.ERA : "-"}</td>
                            <td>{item.K ? item.K : "-"}</td>
                            <td>{item.WHIP ? item.WHIP : "-"}</td>
                            <td>{item.saves ? item.saves : "-"}</td>
                            <td>{item.GP ? item.GP : "-"}</td>
                            <td>{item.BB ? item.BB : "-"}</td>
                            <td>{item.GS ? item.GS : "-"}</td>
                            <td>{item.IP ? item.IP : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.pitching.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    renderHittingData = () => {
        return (
            this.state.advacedSearchList &&
                this.state.advacedSearchList.length !== 0 ?
                this.state.advacedSearchList.map((item, i) => {
                    return (
                        <tr>
                            <td>{i + 1}</td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    {/* <img src={item.athlete.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player" /> */}
                                    <div className="athlete-info">
                                        <h3>{item?.athlete?.fname + " " + item?.athlete?.lname}</h3>
                                        {/* <p>
                                            <span>{item.athlete.profile &&
                                                item.athlete.profile.hasOwnProperty('fielding-pos') && item.athlete.profile['fielding-pos'].length !== 0 ?
                                                item.athlete.profile['fielding-pos'].map((pos) => (
                                                    pos
                                                )).reduce((prev, curr) => [prev, ", ", curr])
                                                : ""}
                                            </span>
                                            <span>#652</span>
                                            <span>{item.gradeYear}</span>
                                            <span>175 lbs</span>
                                        </p>
                                        <h6><b>Height : </b>{item.athlete.profile.height ? item.athlete.profile.height : "-"}</h6>
                                        <h6><b>Weight : </b>{item.athlete.profile.weight ? item.athlete.profile.weight : "-"}</h6> */}
                                    </div>
                                </div>
                            </td>
                            <td>{item.maxExitVelocity ? item.maxExitVelocity + "mph" : "-"}</td>
                            <td>{item.avgExitVelocity ? item.avgExitVelocity + "mph" : "-"}</td>
                            <td>{item.avgLaunchAngle ? item.avgLaunchAngle : "-"}</td>
                            <td>{item.maxDistance ? item.maxDistance + "''" : "-"}</td>
                            <td>{item.batSpeed ? item.batSpeed + "''" : "-"}</td>
                            <td>{item.attackAngle ? item.attackAngle : "-"}</td>
                            <td>{item.R ? item.R : "-"}</td>
                            <td>{item.RBI ? item.RBI : "-"}</td>
                            <td>{item.SB ? item.SB : "-"}</td>
                            <td>{item.OBP ? item.OBP : "-"}</td>
                            <td>{item.HR ? item.HR : "-"}</td>
                            <td>{item["1B"] ? item["1B"] : "-"}</td>
                            <td>{item["2B"] ? item["2B"] : "-"}</td>
                            <td>{item.BA ? item.BA : "-"}</td>
                            <td>{item.GP ? item.GP : "-"}</td>
                            <td>{item.AB ? item.AB : "-"}</td>
                            {/* <td>{item.SB ? item.SB : "-"}</td> */}
                            <td>{item.OPS ? item.OPS : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.hitting.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    redirectProfile = (item) => {
        this.props.profileInfo.sendSummaryReq = {
            tab: "summary",
            id: "",
            eventTeamType: 0,
            sortOn: {
                eventstartDate: "",
                eventendDate: "",
                awardstartDate: "",
                awardendDate: "",
                gallerystartDate: "",
                galleryendDate: ""
            },
            seasonType: 1,
            pitching: "basic",
            batting: "basic",
            filterOn: "",
            field: "",
            metricSubType: "Speed",
            metricType: 1,
            companyId: localStorage.getItem("cid"),
            statsType:"Hitting"
        };
        this.props.profileInfo.tabs = 0;
        this.props.profileInfo.backupTab = 0;
        this.props.profileInfo.statsType = 0;
        this.props.profileInfo.eventPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: localStorage.getItem("userID"),
        };
        this.props.profileInfo.mediaPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: localStorage.getItem("userID"),
        };
        this.props.profileInfo.eventCurrentPage = 0;
        this.props.history.push(`/pw-profile-info/${item._id}`);
    }

    renderPlayerData = () => {

        return (
            this.state.advacedSearchList &&
                this.state.advacedSearchList.length !== 0 ?
                this.state.advacedSearchList.map((item, i) => {
                    return (
                        <tr>
                            {/* <td>{i + 1}</td> */}
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    <img src={item.picture ? item.picture : imagesArr.default_user} alt="player" />
                                </div>
                            </td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    <div className="athlete-info" onClick={() => this.redirectProfile(item)}>
                                        <h3>{item.fname + " " + item.lname}</h3>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {item.position &&
                                    item.position.length !== 0 ?
                                    item.position.map((pos) => (
                                        pos
                                    )).reduce((prev, curr) => [prev, ", ", curr])
                                    // item.position
                                    : "-"
                                }
                            </td>
                            <td>{item.height ? item.height : "-"}</td>
                            <td>{item.weight ? item.weight : "-"}</td>
                            <td>{item.gradeYear ? item.gradeYear : "-"}</td>
                            <td>{item.team ? item.team : "-"}</td>
                            <td>{item.GPA ? item.GPA : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.hitting.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    onHandleChange = (name, value) => {
        sortArr = [];
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: value,
                page: 1,
                limit: Number(this.state.perPage),
                sort: {},
                search: "",
            },
            currentPage: 0
        }), () => {
            this.getAdvancedSearch();
        });
    }

    onHandleSeacrh = (name, value) => {
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: value,
                page: 1,
                limit: Number(this.state.perPage),
            },
            currentPage: 0
        }));
    }

    onFilterChange = (name, val) => {
        let old = this.state[name].some((item) => {
            return item === val
        });
        if (old) {
            this.state[name].map((item, i) => {
                if (item === val) {
                    this.state[name].splice(i, 1)
                }
            })
            this.setState({
                [name]: this.state[name]
            }, () => {
                this.onHandleChange(name, this.state[name].length !== 0 ? this.state[name] : "");
            })
        } else {
            this.setState({
                [name]: [...this.state[name], val]
            }, () => {
                this.onHandleChange(name, this.state[name]);
            })
        }
    }

    renderPosition = () => {
        return (
            this.state.filterObject &&
                this.state.filterObject.positions &&
                this.state.filterObject.positions.length !== 0 ?
                this.state.filterObject.positions.map((item, i) => {
                    return (
                        <div className="checkbox">
                            <label for={"position" + i}>{item}</label>
                            <input type="checkbox" id={"position" + i} name="positions" checked={this.state.position.find(e => e === item)} onChange={() => this.onFilterChange('position', item)} />
                            <span className="square"></span>
                        </div>
                    )
                })
                :
                <p className="text-center"></p>
        )
    }

    renderGradYear = () => {
        return (
            this.state.filterObject &&
                this.state.filterObject.gradeYearObj &&
                this.state.filterObject.gradeYearObj.length !== 0 ?
                this.state.filterObject.gradeYearObj.map((item, i) => {
                    return (
                        <div className="checkbox">
                            <label for={"gradyear" + i}>{item}</label>
                            <input type="checkbox" id={"gradyear" + i} name="gradyears" checked={this.state.gradeYear.find(e => e === item)} onChange={() => this.onFilterChange('gradeYear', item)} />
                            <span className="square"></span>
                        </div>
                    )
                })
                :
                <p className="text-center"></p>
        )
    }

    renderStates = () => {
        return (
            this.state.filterObject &&
                this.state.filterObject.stateObj &&
                this.state.filterObject.stateObj.length !== 0 ?
                this.state.filterObject.stateObj.map((item, i) => {
                    return (
                        <div className="checkbox">
                            <label for={"state" + i}>{item}</label>
                            <input type="checkbox" id={"state" + i} name="states" checked={this.state.state.find(e => e === item)} onChange={() => this.onFilterChange('state', item)} />
                            <span className="square"></span>
                        </div>
                    )
                })
                :
                <p className="text-center"></p>
        )
    }

    filterTabs = (value) => {
        let old = this.state.activeFilters.some((item) => {
            return item === value
        });
        if (old) {
            this.state.activeFilters.map((item, i) => {
                if (item === value) {
                    this.state.activeFilters.splice(i, 1)
                }
            })
            this.setState({
                ...this.state.activeFilters,
                activeFilters: this.state.activeFilters
            })
        } else {
            this.setState({
                activeFilters: [...this.state.activeFilters, value]
            })
        }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                page: selectedPage + 1,
                limit: Number(this.state.perPage),
            },
            currentPage: selectedPage,
            offset: offset,
        }), () => {
            this.getAdvancedSearch();
        });
    };

    sortData = (key) => {
        let old = sortArr.some((item) => {
            return item === key;
        });

        if (!old) {
            sortArr = [];
            sortArr.push(key);
        } else {
            sortArr.some((item, i) => {
                if (item === key) {
                    sortArr.splice(i, 1);
                }
            })
        }

        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                sort: {
                    [key]: sortArr.some((d) => d === key) ? 1 : -1
                },
            },
        }), () => {
            console.log("sendReq:----", this.state.sendReq, sortArr);
            this.getAdvancedSearch();
        })
    }

    sendlink = (key) => {
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [key]: true
            },
        }), () => {
            console.log("sendReq:----", this.state.sendReq);
            this.getAdvancedSearch();
        })
    }

    setHeaderVal = (item) => {
        let findkeys = advacedSearchColumn[this.state.sendReq.type === 1 ? "performance" : this.state.sendReq.type === 2 ? "pitching" : this.state.sendReq.type === 3 ? "hitting" : "playerInfo"].find((_) => _.name === item);
        if (!!findkeys) {
            return findkeys.label;
        } else {
            return item;
        }
    }

    render() {
        const { loaderCount, sendReq, activeFilters, advacedSearchCols, advacedSearchList } = this.state;
        return (
            <>
                <Header />
                <div className="advancedsearch-section main-ranking">
                    <div className="container">
                        <div className="advanced-filter mb-20">
                            <div className="fiter-list">
                                <h4>Display By</h4>
                                <div className="list-group-scroll">
                                    <ul className="list-group mt-15 mb-15">
                                        <li className={`list-group-item ${sendReq.type === 4 ? 'active' : ''}`} onClick={() => this.onHandleChange('type', 4)}>Player Info</li>
                                        <li className={`list-group-item ${sendReq.type === 1 ? 'active' : ''}`} onClick={() => this.onHandleChange('type', 1)}>Performance</li>
                                        <li className={`list-group-item ${sendReq.type === 2 ? 'active' : ''}`} onClick={() => this.onHandleChange('type', 2)}>Pitching</li>
                                        <li className={`list-group-item ${sendReq.type === 3 ? 'active' : ''}`} onClick={() => this.onHandleChange('type', 3)}>Hitting</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="fiter-input">
                                <div className="player-search">
                                    <input className="form-control" name="search" value={sendReq.search} onChange={(e) => this.onHandleSeacrh('search', e.target.value)} placeholder="Search here...." />
                                    <img className="search-icon" src={imagesArr.search} onClick={() => this.getAdvancedSearch()} />
                                </div>
                                <div className="export-print mt-15">
                                    <a className="btn" onClick={() => advacedSearchList.length !== 0 ? this.sendlink('export') : ""} disabled={advacedSearchList.length !== 0 ? false : true}>Export</a>
                                    <a className="btn" onClick={() => advacedSearchList.length !== 0 ? this.sendlink('print') : ""} disabled={advacedSearchList.length !== 0 ? false : true}>Print</a>
                                </div>
                            </div>
                        </div>

                        <div className="search-data">
                            <div className="data-section">
                                <div className="side-filter">
                                    <div className="white-bg">
                                        <div className={`advanced-filter-collaps ${activeFilters.find(e => e === "sporttype") ? "active" : ""}`} >
                                            <button className="collapsible" onClick={() => this.filterTabs('sporttype')}>Sport Type </button>
                                            <div className="content">
                                                <div class="checkbox">
                                                    <label for="baseball">Baseball </label>
                                                    <input type="checkbox" id="baseball" name="sportType" checked={this.state.sportType.find(e => e === 1)} onChange={() => this.onFilterChange('sportType', 1)} />
                                                    <span class="square"></span>
                                                </div>
                                                <div class="checkbox">
                                                    <label for="softball">Softball </label>
                                                    <input type="checkbox" id="softball" name="sportType" checked={this.state.sportType.find(e => e === 2)} onChange={() => this.onFilterChange('sportType', 2)} />
                                                    <span class="square"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`advanced-filter-collaps ${activeFilters.find(e => e === "position") ? "active" : ""}`} >
                                            <button className="collapsible" onClick={() => this.filterTabs('position')}>Position</button>
                                            <div className="content">
                                                {this.renderPosition()}
                                            </div>
                                        </div>
                                        <div className={`advanced-filter-collaps ${activeFilters.find(e => e === "year") ? "active" : ""}`} >
                                            <button className="collapsible " onClick={() => this.filterTabs('year')}>Grad Year</button>
                                            <div className="content">
                                                {this.renderGradYear()}
                                            </div>
                                        </div>
                                        <div className={`advanced-filter-collaps ${activeFilters.find(e => e === "state") ? "active" : ""}`}>
                                            <button className="collapsible " onClick={() => this.filterTabs('state')}>State</button>
                                            <div className="content">
                                                {this.renderStates()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="advanced-data-table">
                                    <div className="table-responsive-xl">
                                        <table className="table">
                                            <thead>
                                                {
                                                    sendReq.type !== 4 && advacedSearchCols.length !== 0 &&
                                                    <>
                                                        <th>Rank</th>
                                                        <th>Athlete</th>
                                                    </>
                                                }
                                                {
                                                    advacedSearchColumn &&
                                                    sendReq.type === 4 &&
                                                    advacedSearchColumn["playerInfo"].map((item) => {
                                                        return (
                                                            <th onClick={() => sendReq.type !== 4 ? this.sortData(item.column_name) : ""}>
                                                                {item.name} {sendReq.type !== 4 ? sortArr.some((d) => d === item.column_name) ?
                                                                    <i class="fas fa-sort-up"></i> : this.state.sendReq.sort[item.column_name] === -1 ?
                                                                        <i class="fas fa-sort-down" aria-hidden="true"></i> : <i class="fa fa-sort" aria-hidden="true"></i> : ""}
                                                            </th>
                                                        )
                                                    })
                                                }
                                                {
                                                    sendReq.type !== 4 &&
                                                    advacedSearchCols.length !== 0 &&
                                                    advacedSearchCols.map((item, i) => {
                                                        return (
                                                            <th onClick={() => sendReq.type !== 4 ? this.sortData(item) : ""}>
                                                                {this.setHeaderVal(item)} {sendReq.type !== 4 ? sortArr.some((d) => d === item) ?
                                                                    <i class="fas fa-sort-up"></i> : this.state.sendReq.sort[item] === -1 ?
                                                                        <i class="fas fa-sort-down" aria-hidden="true"></i> : <i class="fa fa-sort" aria-hidden="true"></i> : ""}
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </thead>
                                            <tbody>
                                                {
                                                    advacedSearchCols.length !== 0 ?
                                                        advacedSearchList.length !== 0 ?
                                                            advacedSearchList.map((td, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td className="team-name">
                                                                            <div className="athlete-user-info">
                                                                                <div className="athlete-info" onClick={() => this.redirectProfile(td.athlete)}>
                                                                                    <h3>{td?.athlete?.fname + " " + td?.athlete?.lname}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            advacedSearchCols.map((th, j) => {
                                                                                return (
                                                                                    <td>{td[th] ? td[th] : "-"}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={advacedSearchCols.length + 2}>
                                                                    <p className="text-center" >No data found</p>
                                                                </td>
                                                            </tr>
                                                        :
                                                        sendReq.type !== 4 &&
                                                        <p className="text-center pt-15">No data found</p>
                                                }
                                                {
                                                    sendReq.type === 4 &&
                                                    this.renderPlayerData()
                                                }
                                                {/* {
                                                    sendReq.type === 1 ?
                                                        this.renderPerformanceData() : sendReq.type === 2 ?
                                                            this.renderPitchingData() : sendReq.type === 3 ?
                                                                this.renderHittingData() : sendReq.type === 4 ? this.renderPlayerData() : ""} */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        advacedSearchCols.length !== 0 &&
                                        <div className="Paginate mt-20">
                                            <ReactPaginate
                                                previousLabel={"<<"}
                                                nextLabel={">>"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={1}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                forcePage={this.state.currentPage}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </>
        )
    }
}

advancedSearch.propTypes = {
    getAdvancedSearch: PropTypes.func.isRequired,
    getFilterValue: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    searchInfo: state.advancedSearchInfo,
    profileInfo: state.profileSummaryInfo,
});

export default connect(mapStateToProps, { getAdvancedSearch, getFilterValue })(advancedSearch);

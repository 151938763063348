import {
    SAVE_TRAINING_REQUEST,
    SAVE_TRAINING_SUCCESS,
    SAVE_TRAINING_ERROR,

    GET_TRAINING_REQUEST,
    GET_TRAINING_SUCCESS,
    GET_TRAINING_ERROR,
} from '../../../../constants/authantication/signup/coach/training.const';

const initialState = {
    saveTrainingRes: false,
    saveTrainingData: null,
    saveTrainingDataError: null,

    trainingRes: false,
    trainingData: null,
    trainingDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get training
        case GET_TRAINING_REQUEST:
            return {
                ...state,
                trainingRes: true
            };
        case GET_TRAINING_SUCCESS:
            return {
                ...state,
                trainingData: action.payload,
            };
        case GET_TRAINING_ERROR:
            return {
                ...state,
                trainingDataError: action.error,
            };

        // save training
        case SAVE_TRAINING_REQUEST:
            return {
                ...state,
                saveTrainingRes: true
            };
        case SAVE_TRAINING_SUCCESS:
            return {
                ...state,
                saveTrainingData: action.payload,
            };
        case SAVE_TRAINING_ERROR:
            return {
                ...state,
                saveTrainingDataError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from "moment";
import { imagesArr } from '../../../../assets/images';
import { getDivisionList } from '../../../../saga/actions/common/slider.action';
import { featuredComLeague, leaguesOptionList, leaguesStateOption } from '../../../../saga/actions/common/leagues.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import * as data from "../../../../businesslogic/country.json";

class featuredLeague extends Component {

    constructor(props) {
        super(props);
        this.state = {
            divList: [],
            gameNumber: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            sendReq: {
                companyId: localStorage.getItem("cid"),
                leagueGames: "",
                noOfLeagueGames: "",
                id: "",
                isPresent: 1,
                startDate: "",
                endDate: "",
                state: "",
                ageGroup: "",
                division: "",
                leagueType: this.props.leagueType,
                currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
            },
            leaguesList: [],
            l_options: [],
            stateList: [],
            sendStateReq: {
                isPresent: 1,
                companyId: localStorage.getItem("cid"),
            },
        };
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.props.increment();
        this.props.featuredComLeague(this.state.sendReq);
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.leaguesInfo &&
            nextProps.leaguesInfo.featureLeagueReq &&
            nextProps.leaguesInfo.featureLeagueData
        ) {
            console.log("nextProps.leaguesInfo.featureLeagueData:---", nextProps.leaguesInfo.featureLeagueData);
            nextProps.leaguesInfo.featureLeagueReq = false;
            if (nextProps.leaguesInfo.featureLeagueData.flag) {
                let fdata = nextProps.leaguesInfo.featureLeagueData.result.data[0];
                this.setState({
                    leaguesList: fdata
                }, () => {
                    nextProps.leaguesInfo.featureLeagueData = null;
                    if (this.state.divList.length === 0) {
                        this.props.getDivisionList();
                    } else {
                        this.props.decrement();
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.leaguesInfo.featureLeagueData.message);
                nextProps.leaguesInfo.featureLeagueData = null;
                this.props.decrement();
            }
        }

        if (nextProps.sliderInfo &&
            nextProps.sliderInfo.divSliderListReq &&
            nextProps.sliderInfo.divSliderListData
        ) {
            nextProps.sliderInfo.divSliderListReq = false;
            if (nextProps.sliderInfo.divSliderListData.flag) {
                this.setState({
                    divList: nextProps.sliderInfo.divSliderListData.result.data
                }, () => {
                    nextProps.sliderInfo.divSliderListData = null;
                    this.props.leaguesOptionList();
                })
            } else {
                this.commonCall.errorMessage(nextProps.sliderInfo.divSliderListData.message);
                nextProps.sliderInfo.divSliderListData = null;
                this.props.decrement();
            }
        }

        if (nextProps.leaguesInfo &&
            nextProps.leaguesInfo.optionLeagueListReq &&
            nextProps.leaguesInfo.optionLeagueListData
        ) {
            nextProps.leaguesInfo.optionLeagueListReq = false;
            if (nextProps.leaguesInfo.optionLeagueListData.flag) {
                this.setState({
                    l_options: nextProps.leaguesInfo.optionLeagueListData.result.data
                }, () => {
                    nextProps.leaguesInfo.optionLeagueListData = null;
                    // this.setStateList();
                    this.props.leaguesStateOption(this.state.sendStateReq);
                })
            } else {
                this.commonCall.errorMessage(nextProps.leaguesInfo.optionLeagueListData.message);
                nextProps.leaguesInfo.optionLeagueListData = null;
                this.props.decrement();
            }
        }

        if (nextProps.leaguesInfo &&
            nextProps.leaguesInfo.leagueStateListReq &&
            nextProps.leaguesInfo.leagueStateListData
        ) {
            nextProps.leaguesInfo.leagueStateListReq = false;
            if (nextProps.leaguesInfo.leagueStateListData.flag) {
                this.setState({
                    stateList: nextProps.leaguesInfo.leagueStateListData.result.data
                }, () => {
                    nextProps.leaguesInfo.leagueStateListData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.leaguesInfo.leagueStateListData.message);
                nextProps.leaguesInfo.leagueStateListData = null;
                this.props.decrement();
            }
        }
    }

    setStateList = () => {
        data.default.countries.map((a) => {
            if (a.country == "United States") {
                this.setState({
                    stateList: a.states,
                }, () => {
                    this.props.decrement();
                });
            }
        });
    }

    redirectDetails = (data) => {
        this.props.history.push(`/league-detail/${data._id}`);
    }

    renderFeaturedLeagues = () => {
        return (
            this.state.leaguesList.length !== 0 ?
                this.state.leaguesList.map((list, i) => {
                    const location = list.location && list.location.streetAddress.split(",");
                    if (i < 6) {
                        return (
                            <div className="event_box w-33" key={i} onClick={() => this.redirectDetails(list)}>
                                <div className="event_card">
                                    <div class="event_card_img">
                                        <img src={list.leagueImages && list.leagueImages.filePath} alt="event" />
                                        {

                                            (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                            <span className="event_card_address">
                                                {list.location &&
                                                    list.location.city ?
                                                    list.location.city :
                                                    list.location.streetAddress ?
                                                        location[0] :
                                                        list.location.stateRegion}
                                            </span>
                                        }
                                    </div>
                                    <div class="event_card_desc">
                                        <div class="event_card_logo">
                                            <img src={list.leagueLogo && list.leagueLogo.filePath ? list.leagueLogo.filePath : imagesArr.default_user} alt="event logo" />
                                        </div>
                                        <div class="event_card_titles">
                                            <h3>{list.leagueTitle}</h3>
                                            <div class="event_card_dates">
                                                <p class="date_icon">{moment(list.startDate).format("DD-MMM")} - {moment(list.endDate).format("DD-MMM")}</p>
                                                <p class="team_icons">{list.teamLimit} Teams</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
                :
                <p className="text-center w-100">No leagues found.</p>
        )
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: name === "noOfLeagueGames" || name === "ageGroup" ? value === "" ? value : JSON.parse(value) : value,
            }
        }, () => {
            this.props.increment();
            this.props.featuredComLeague(this.state.sendReq);
        });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onChangeDate = (value, name) => {
        if (name === "startDate") {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value,
                    endDate: ""
                }
            }, () => {
                if (this.state.sendReq.endDate) {
                    this.props.increment();
                    this.props.featuredComLeague(this.state.sendReq);
                }
            });
        } else {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value,
                }
            }, () => {
                if (this.state.sendReq.startDate) {
                    this.props.increment();
                    this.props.featuredComLeague(this.state.sendReq);
                }
            });
        }
    }

    render() {
        const { divList, gameNumber, l_options, stateList, sendReq } = this.state;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <div className="upcomingevent_section">
                <div className="container">
                    <div className="upcoming_events_title">
                        <h1 className="section_title">Featured Leagues </h1>
                    </div>
                    <form>
                        <div className="event-filter">
                            <div className="form-group ">
                                <select className="custom-select" name="leagueGames" value={sendReq.leagueGames} onChange={(e) => this.onHandleChange(e)}>
                                    <option value="" >League games</option>
                                    {
                                        l_options &&
                                        l_options.map((list) => {
                                            return (
                                                <option value={list.leagueTitle}>{list.leagueTitle}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group ">
                                <select className="custom-select" name="noOfLeagueGames" value={sendReq.noOfLeagueGames} onChange={(e) => this.onHandleChange(e)}>
                                    <option value="" >Number of league games</option>
                                    {
                                        gameNumber &&
                                        gameNumber.map((items) => {
                                            return (
                                                <option value={items}>{items}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group ">
                                <select className="custom-select" name="ageGroup" value={sendReq.ageGroup} onChange={(e) => this.onHandleChange(e)}>
                                    <option value="">Age Group</option>
                                    <option value="8">8U</option>
                                    <option value="9">9U</option>
                                    <option value="10">10U</option>
                                    <option value="11">11U</option>
                                    <option value="12">12U</option>
                                    <option value="13">13U</option>
                                    <option value="14">14U</option>
                                    <option value="15">15U</option>
                                    <option value="16">16U</option>
                                    <option value="17">17U</option>
                                    <option value="18">18U</option>
                                </select>
                            </div>
                            <div className="form-group ">
                                <select className="custom-select" name="division" value={sendReq.division} onChange={(e) => this.onHandleChange(e)}>
                                    <option value="" >Division</option>
                                    {
                                        divList &&
                                        divList.map((item) => {
                                            return (
                                                <option value={item._id}>{item.title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="event-filter p-0">

                            <div className="form-group ">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={(c) => (this._calendar = c)}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                        selected={sendReq.startDate ? new Date(sendReq.startDate) : sendReq.startDate}
                                        minDate={moment().subtract(0, 'days').toDate()}
                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(e) => this.onChangeDate(e, "startDate")}
                                        placeholderText="Start date"
                                    />
                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                </div>
                            </div>

                            <div className="form-group ">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={(c) => (this._calendar = c)}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                        selected={sendReq.endDate ? new Date(sendReq.endDate) : sendReq.endDate}
                                        minDate={moment(sendReq.startDate).subtract(-1, 'days').toDate()}
                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(e) => this.onChangeDate(e, "endDate")}
                                        placeholderText="End date"
                                    />
                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                </div>
                            </div>

                            <div className="form-group ">
                                <select className="custom-select" name="state" value={sendReq.state} onChange={(e) => this.onHandleChange(e)}>
                                    <option value="">State</option>
                                    {
                                        stateList &&
                                        stateList.map((item) => {
                                            return (
                                                <option value={item.stateRegion} style={{ color: item.isUpcoming ? 'black' : '#6C7B95' }}>{item.state}</option>
                                                // <option value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                        </div>
                    </form>
                    <div className="upcoming_events ">
                        <div className="row justify-content-center">
                            {this.renderFeaturedLeagues()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

featuredLeague.propTypes = {
    getDivisionList: PropTypes.func.isRequired,
    featuredComLeague: PropTypes.func.isRequired,
    leaguesOptionList: PropTypes.func.isRequired,
    leaguesStateOption: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    sliderInfo: state.sliderInfo,
    leaguesInfo: state.leaguesInfo,
});
var featuredLeagueComponent = connect(mapStateToProps, { getDivisionList, featuredComLeague, leaguesOptionList, leaguesStateOption })(featuredLeague);
export default withRouter(featuredLeagueComponent);

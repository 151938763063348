import axios from "axios";
import { API_Url, Auth_Player_Key, checkIsValidPlayerToken } from "../../../../helpers/authToken";
import { PLAYER_TRAINING_SUCCESS, PLAYER_TRAINING_ERROR, PLAYER_TRAINING_REG } from "../../../../constants/view/player/profile/training.const";
import histrory from '../../../../../History';

export const getPlayerTrainingInfo = () => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/player/training`,
    headers: {
      "x-auth-token": Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: PLAYER_TRAINING_SUCCESS,
        payload: res.data.result,
      }); 
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: PLAYER_TRAINING_ERROR,
          error: error.response.data.message,
        });
    });
}

export const postPlayerTrainingInfo = (data) => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  return axios({
    method: "POST",
    url: `${API_Url}bc/player/training`,
    data: data,
    headers: {
      "x-auth-token": Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: PLAYER_TRAINING_REG,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: PLAYER_TRAINING_ERROR,
          error: error.response.data.message,
        });
    });
}

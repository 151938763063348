import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken, checkIsValidFanToken } from '../../../helpers/authToken';
import {
    PLAYER_CART_REQUEST,
    PLAYER_CART_SUCCESS,
    PLAYER_CART_ERROR,

    PLAYER_CART_VIDEO_SUCCESS,
    PLAYER_CART_VIDEO_ERROR,

    DEL_VIDEO_REQUEST,
    DEL_VIDEO_SUCCESS,
    DEL_VIDEO_ERROR,
    VIDEO_LIST_ERROR,
    VIDEO_LIST_SUCCESS
} from '../../../constants/view/player/cart.const';
import histrory from '../../../../History';

export const getCart = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: PLAYER_CART_REQUEST });
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/card`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(cart => {
        console.log("cart:---- ", cart);
        dispatch({
            type: PLAYER_CART_SUCCESS,
            payload: cart.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: PLAYER_CART_ERROR, error: error.response.data.message })
        }
    });
};

export const addVideo = (data, token) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/addPlayerCardVideo`,
        data: data,
        headers: {
            "x-auth-token": token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(cart => {
        console.log("cart", cart);
        dispatch({
            type: PLAYER_CART_VIDEO_SUCCESS,
            payload: cart.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: PLAYER_CART_VIDEO_ERROR, error: error.response.data.message })
        }
    });
}

export const delVideo = (data, token) => dispatch => {
    console.log("data:--", data);
    token ? checkIsValidFanToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: DEL_VIDEO_REQUEST });
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/removePlayerCardVideo`,
        data: data,
        headers: {
            "x-auth-token": token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(delVideo => {
        console.log("delVideo:---- ", delVideo);
        dispatch({
            type: DEL_VIDEO_SUCCESS,
            payload: delVideo.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DEL_VIDEO_ERROR, error: error.response.data.message })
        }
    });
};

export const getVideoList = (data) => dispatch => {
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/card`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(cart => {
        dispatch({
            type: VIDEO_LIST_SUCCESS,
            payload: cart.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: VIDEO_LIST_ERROR, error: error.response.data.message })
        }
    });
};

// export const leaveTeam = () => dispatch => {
//     checkIsValidPlayerToken(histrory);
//     dispatch({ type: LEAVE_TEAM_REQUEST });
//     axios({
//         method: 'POST',
//         url: `${API_Url}bc/player/leaveTeam`,
//         headers: {
//             'x-auth-token': Auth_Player_Key,
//             'Accept': 'application/json'
//         }
//     }).then(leave => {
//         console.log("leave:---- ", leave);
//         dispatch({
//             type: LEAVE_TEAM_SUCCESS,
//             payload: leave.data
//         })
//     }).catch((error) => {
//         if (error.response) {
//             console.log("error.response:--", error.response);
//             dispatch({ type: LEAVE_TEAM_ERROR, error: error.response.data.message })
//         }
//     });
// };


import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_TEAM_INFO_REQUEST,
    GET_PLAYER_TEAM_INFO_SUCCESS,
    GET_PLAYER_TEAM_INFO_ERROR,

    GET_TEAM_PROFILE_SUCCESS,
    GET_TEAM_PROFILE_REQUEST,
    GET_TEAM_PROFILE_ERROR
} from '../../../../constants/view/company/pwRanking/teamProfile.const';
import histrory from '../../../../../History';

export const getPlayerTeam = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_PLAYER_TEAM_INFO_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/team/detail" : "pwUser/woAuth/team/detail"}`,
        data: data,
        headers: setHeader
    }).then(profile => {
        dispatch({
            type: GET_PLAYER_TEAM_INFO_SUCCESS,
            payload: profile.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_TEAM_INFO_ERROR, error: error.response.data.message })
        }
    });
};

export const getTeamProfile = (data) => dispatch => {
    console.log(data)
    dispatch({ type: GET_TEAM_PROFILE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/state/list`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(xyz => {
        dispatch({
            type: GET_TEAM_PROFILE_SUCCESS,
            payload: xyz.data
            // (.data = data from api)
        })
    }).catch((error) => {
        // if (error.response) {
        //     console.log("error.response:--", error.response);
        //     dispatch({ type: GET_TEAM_PROFILE_ERROR, error: error.response.data.message })
        // }
    });
};

export const getScoutAwardTabDetail = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: 'GET_SCOUT_AWARD_TAB_REQUEST' })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/team/feed/tab" : "pwUser/woAuth/team/feed/tab"}`,
        data: data,
        headers: setHeader
    }).then(profile => {
        dispatch({
            type: 'GET_SCOUT_AWARD_TAB_SUCCESS',
            payload: profile.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: 'GET_SCOUT_AWARD_TAB_ERROR', error: error.response.data.message })
        }
    });
};


export const getTeamScoutFeed = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: 'GET_TEAM_SCOUT_FEED_REQUEST' })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/team/scouting" : "pwUser/woAuth/team/scouting"}`,
        data: data,
        headers: setHeader
    }).then(profile => {
        dispatch({
            type: 'GET_TEAM_SCOUT_FEED_SUCCESS',
            payload: profile.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: 'GET_TEAM_SCOUT_FEED_ERROR', error: error.response.data.message })
        }
    });
};

import {
    GET_PLAYER_RANKING_REQUEST,
    GET_PLAYER_RANKING_SUCCESS,
    GET_PLAYER_RANKING_ERROR,

    GET_ALL_PLAYER_LIST_SUCCESS,
    GET_ALL_PLAYER_LIST_REQUEST,
    GET_ALL_PLAYER_LIST_ERROR
} from '../../../../../constants/view/company/pwRanking/leaderboard/playerRanking.const';

const initialState = {
    sendPlayerRankReq: {
        page: 0,
        limit: 0,
        type: 2,
        rankType: 1,
        ageGroup: "",
        position: "",
        gradeYear: "2022",
        state: "",
        playerName: "",
        companyId: localStorage.getItem("cid")
    },
    optionData: [],
    getPlayerRankingReq: false,
    getPlayerRankingData: null,
    getPlayerRankingError: null,

    getAllPlayerListReq: false,
    getAllPlayerListSuccess: null,
    getAllPlayerListError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_ALL_PLAYER_LIST_REQUEST:
            return {
                ...state,
                getAllPlayerListReq: true,
            };

        case GET_ALL_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                getAllPlayerListSuccess: action.payload,
            };
        case GET_ALL_PLAYER_LIST_ERROR:
            return {
                ...state,
                getAllPlayerListError: action.error,
            };

        // Get Player Ranking req    
        case GET_PLAYER_RANKING_REQUEST:
            return {
                ...state,
                getPlayerRankingReq: true,
            };

        case GET_PLAYER_RANKING_SUCCESS:
            return {
                ...state,
                getPlayerRankingData: action.payload,
            };
        case GET_PLAYER_RANKING_ERROR:
            return {
                ...state,
                getPlayerRankingError: action.error,
            };

        default:
            return state;
    }
}
export const PARENT_LP_LIST_REQUEST = 'PARENT_LP_LIST_REQUEST';
export const PARENT_LP_LIST_SUCCESS = 'PARENT_LP_LIST_SUCCESS';
export const PARENT_LP_LIST_ERROR   = 'PARENT_LP_LIST_ERROR';

export const FAN_REGISTERINFO_REQUEST ="FAN_REGISTERINFO_REQUEST";
export const FAN_REGISTERINFO_SUCCESS ="FAN_REGISTERINFO_SUCCESS";
export const FAN_REGISTERINFO_ERROR   ="FAN_REGISTERINFO_ERROR";

export const PHONE_VERIFY_FAN_REQUEST ="PHONE_VERIFY_FAN_REQUEST";
export const PHONE_VERIFY_FAN_SUCCESS ="PHONE_VERIFY_FAN_SUCCESS";
export const PHONE_VERIFY_FAN_ERROR   ="PHONE_VERIFY_FAN_ERROR";

export const PLAYER_TOKEN_REQUEST = 'PLAYER_TOKEN_REQUEST';
export const PLAYER_TOKEN_SUCCESS = 'PLAYER_TOKEN_SUCCESS';
export const PLAYER_TOKEN_ERROR   = 'PLAYER_TOKEN_ERROR';

import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { imagesArr } from '../../../../assets/images';
import { upcomingComEvents, getStatesForEvent, getCompanyList } from '../../../../saga/actions/common/events.action';
import { getEventDetails } from '../../../../saga/actions/common/eventDetails.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import moment from "moment";
import * as data from "../../../../businesslogic/country.json";
import { checkLoginValidation } from '../../../../saga/helpers/authToken';
import Geosuggest from 'react-geosuggest';
import { getSumOfRegisterTeam, getAvailableSport } from "../../../../businesslogic/content";
import ReactPaginate from "react-paginate";

class upcomingEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upcommingEvent: [],
            sendReq: {
                page: 0,
                limit: 6,
                isPresent: this.props.isPresent,
                companies: JSON.parse(localStorage.getItem("isAllCompany")) === true ? "" : this.props.location.pathname === "/dc-event" ? "" : localStorage.getItem("cid"),
                state: "",
                startDate: "",
                endDate: "",
                eventTeamType: this.props.eventTeamType,
                eventType: this.props.eventType,
                isCollegeShowcase: JSON.parse(localStorage.getItem("isCollegeShowcase")) === true ? true : false,
                currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
            },
            sendStateReq: {
                isPresent: this.props.isPresent,
                eventType: this.props.eventType,
                companies: JSON.parse(localStorage.getItem("isAllCompany")) === true ? "" : this.props.location.pathname === "/dc-event" ? "" : localStorage.getItem("cid"),
                eventTeamType: this.props.eventTeamType,
            },
            stateList: [],

            // pagination variable diclaration
            offset: 0,
            perPage: 6,
            currentPage: "",
            pageCount: "",
            companyList: []
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        if (localStorage.getItem("cid")) {
            this.props.increment();
            this.props.getCompanyList();
            this.props.increment();
            this.props.upcomingComEvents(this.state.sendReq);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.companyListReq &&
            nextProps.eventsInfo.companyList.length
        ) {
            nextProps.eventsInfo.companyListReq = false;
            if (nextProps.eventsInfo.companyList.length) {
                this.setState((prevState) => ({
                    ...prevState,
                    companyList: nextProps.eventsInfo.companyList
                }))
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.companyList.message);
                nextProps.eventsInfo.companyList = null;
            }
        }


        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.upcomingEventReq &&
            nextProps.eventsInfo.upcomingEventData
        ) {
            nextProps.eventsInfo.upcomingEventReq = false;
            console.log("nextProps.upcoming:--", nextProps.eventsInfo.upcomingEventData);
            if (nextProps.eventsInfo.upcomingEventData.flag) {
                this.setState({
                    upcommingEvent: nextProps.eventsInfo.upcomingEventData.result.data,
                    pageCount: Math.ceil(nextProps.eventsInfo.upcomingEventData.result.total / this.state.perPage),
                }, () => {
                    nextProps.eventsInfo.upcomingEventData = null;
                    this.props.decrement();
                    if (this.state.stateList.length === 0) {
                        // this.setStateList();
                        this.props.getStatesForEvent(this.state.sendStateReq);
                    } else {
                        this.props.decrement();
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.upcomingEventData.message);
                nextProps.eventsInfo.upcomingEventData = null;
                this.props.decrement();
            }
        }

        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.stateEventReq &&
            nextProps.eventsInfo.stateEventData
        ) {
            nextProps.eventsInfo.stateEventReq = false;
            console.log("nextProps.stateList:--", nextProps.eventsInfo.stateEventData);
            if (nextProps.eventsInfo.stateEventData.flag) {
                this.setState({
                    stateList: nextProps.eventsInfo.stateEventData.result.data
                }, () => {
                    nextProps.eventsInfo.stateEventData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.stateEventData.message);
                nextProps.eventsInfo.stateEventData = null;
                this.props.decrement();
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            if (nextProps.eventDetails.getDetailsData.flag) {
                let res = nextProps.eventDetails.getDetailsData.result.data[0];
                if (res.hotels.length !== 0) {
                    window.open(res.hotels[0].link, "_blank");
                }
                nextProps.eventDetails.getDetailsData = null;
                this.props.decrement();
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.props.decrement();
            }
        }
    }

    setStateList = () => {
        data.default.countries.map((a) => {
            if (a.country == "United States") {
                this.setState({
                    stateList: a.states,
                }, () => {
                    this.props.decrement();
                });
            }
        });
    }

    redirectDetails = (data) => {
        this.props.history.push(`/event-detail/${data._id}`);
    }

    redirectHotelInfo = (data) => {
        console.log("data:---", data);
        this.props.increment();
        this.props.getEventDetails({ eventId: data._id }, false);
        // if (this.props.eventType === 1 && this.props.cType === "pwb") {
        //     this.props.history.push(`/pwb-hotel-info/${data._id}`);
        // } else if (this.props.eventType === 2 && this.props.cType === "pws") {
        //     this.props.history.push(`/pws-hotel-info/${data._id}`);
        // } else if (this.props.eventType === 1 && this.props.cType === "gob") {
        //     this.props.history.push(`/gob-hotel-info/${data._id}`);
        // } else if (this.props.eventType === "" && this.props.cType === "utb") {
        //     this.props.history.push(`/utb-hotel-info/${data._id}`);
        // }
    }

    renderEvents = () => {
        return (
            this.state.upcommingEvent.length !== 0 ?
                this.state.upcommingEvent.map((list, i) => {
                   
                    const location = list.location && list.location.streetAddress.split(",");

                    // let svalid_date = moment().format("MM/DD/YYYY");
                    // let evalid_date = moment(list.regClosedDate).format("MM/DD/YYYY");
                    // let chkdate = moment(svalid_date).isBefore(evalid_date);

                    return (
                        <div className="event_box w-33" key={i} onClick={() => !this.props.eventLabel ? this.redirectHotelInfo(list) : this.redirectDetails(list)}>
                            <div className="event_card">
                                <div class="event_card_img">
                                    <img src={list.eventImages && list.eventImages.filePath} alt="event" />
                                    {

                                        (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                        <span className="event_card_address">
                                            {list.location &&
                                                list.location.city ?
                                                list.location.city :
                                                list.location.streetAddress ?
                                                    location[0] :
                                                    list.location.stateRegion}
                                        </span>
                                    }
                                </div>
                                {
                                    // !chkdate &&
                                    <div class="event_tags text-right">
                                            {list.regCountDown !== "Registration Closed" ? 'Registration Closes In ' : ""}<span>{list.regCountDown}</span>
                                    </div>
                                }
                                <div class="event_card_desc">
                                    <div class="event_card_logo">
                                        <img src={list.eventLogo && list.eventLogo.filePath ? list.eventLogo.filePath : imagesArr.default_user} alt="event logo" />
                                    </div>
                                    <div class="event_card_titles">
                                        <h3>{list.eventTitle}</h3>
                                        <div class="event_card_dates">
                                            <p class="date_icon">{moment(list.startDate).format("DD-MMM-YYYY")} - {moment(list.endDate).format("DD-MMM-YYYY")}</p>
                                            {list?.showSpotAvail ?
                                                <><p class="team_icons">{list.feesByGroups.length ? getSumOfRegisterTeam(list.feesByGroups) : 0} {list.eventTeamType === 1 ? 'Teams Registered' : 'Players Registered'}</p>
                                                    <p class="team_icons">{list.feesByGroups.length ? getAvailableSport(list.feesByGroups) : 0} Available Spots</p> </>
                                                :
                                                <p class="team_icons showSpotAvail"> {list.regCountDown === "Registration Closed" ? "Registration Closed" : "Registration Open"}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center w-100">No events found.</p>
        )
    }

    handleSelectGeoSuggest = (place) => {
        if (!place) {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    state: "",
                },
                state: ""
            }, () => {
                this.props.increment();
                this.props.upcomingComEvents(this.state.sendReq);
            })
        } else {
            var componentForm = {
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name',
            };

            for (var i = 0; i < place.gmaps.address_components.length; i++) {
                var addressType = place.gmaps.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.gmaps.address_components[i][componentForm[addressType]];
                    if (val)
                        componentForm[addressType] = val
                }
            }

            var location = {
                streetAddress: place.gmaps.formatted_address,
                city: componentForm.locality !== 'long_name' ? componentForm.locality : 'Surat',
                stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? componentForm.administrative_area_level_1 : 'GJ',
                postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : 394105,
                latitude: place.location.lat || 42.2538048,
                longitude: place.location.lng || -83.5735806
            }
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    state: location.stateRegion,
                },
                state: location.streetAddress
            }, () => {
                this.props.increment();
                this.props.upcomingComEvents(this.state.sendReq);
            })
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: value,
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.props.increment();
            this.props.upcomingComEvents(this.state.sendReq);
        });
    }

    onHandleTeamType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: this.state.sendReq.eventTeamType === 1 ? "" : JSON.parse(value),
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.props.increment();
            this.props.upcomingComEvents(this.state.sendReq);
        });
    }

    onHandleShowcaseType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: this.state.sendReq.eventTeamType === 2 ? "" : JSON.parse(value),
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.props.increment();
            this.props.upcomingComEvents(this.state.sendReq);
        });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onChangeDate = (value, name) => {
        if (name === "startDate") {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value,
                    endDate: "",
                    page: 0,
                    limit: 6,
                },
                currentPage: 0
            }, () => {
                if (this.state.sendReq.endDate) {
                    this.props.increment();
                    this.props.upcomingComEvents(this.state.sendReq);
                }
            });
        } else {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value,
                    page: 0,
                    limit: 6,
                },
                currentPage: 0
            }, () => {
                if (this.state.sendReq.startDate) {
                    this.props.increment();
                    this.props.upcomingComEvents(this.state.sendReq);
                }
            });
        }

    }

    redirectEvents = (route) => {
        localStorage.setItem("textName", this.props.textName);
        this.props.history.push(route);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                page: selectedPage + 1,
                limit: Number(this.state.perPage),
            },
            currentPage: selectedPage,
            offset: offset,
        }), () => {
            this.props.increment();
            this.props.upcomingComEvents(this.state.sendReq);
        });
    };

    render() {
        console.log("localStorage.getItem(", typeof localStorage.getItem("isCollegeShowcase"));
        const { filterEventType, filterCompany, hideChkBox, eventLabel, eventLinkLabel, filterForm, textName, eventTeamType, cType } = this.props;
        const { sendReq, stateList, companyList } = this.state;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <div className="upcomingevent_section">
                <div className="container">

                    {
                        eventLabel &&
                        <div className="upcoming_events_title">
                            <h1 className="section_title">
                                {localStorage.getItem("textName") === "Events" || localStorage.getItem("textName") === "Tournaments" || localStorage.getItem("textName") === "Showcases" ? "Live " : "Upcoming "}
                                {textName}
                            </h1>

                            {
                                eventLinkLabel &&
                                <span className="see_post">
                                    {
                                        localStorage.getItem("textName") === null ?
                                            <>
                                                {localStorage.getItem("company") !== "pioneer" && <Link onClick={() => this.redirectEvents('/all-live-event')}>See Live {textName === "College Showcases" ? "Events" : textName}</Link>}
                                                <Link onClick={() => this.redirectEvents('/all-past-event')} to={`/all-past-event`}>See Past {textName === "College Showcases" ? "Events" : textName}</Link>
                                            </>
                                            :
                                            (eventTeamType === "" || eventTeamType === 1 || eventTeamType === 2) &&
                                            <Link onClick={() => this.redirectEvents('/all-past-event')} to={`/all-past-event`}>Past {textName}</Link>
                                    }
                                </span>
                            }
                        </div>
                    }
                    {
                        filterForm &&
                        <form>
                            {this.props.location.pathname === "/dc-event" &&
                                <div className="event-filter">
                                    {filterEventType &&
                                        <div className="form-group ">
                                            <select className="custom-select" name="eventType" value={sendReq.eventType} onChange={(e) => this.onHandleChange(e)}>
                                                <option value="">Select Event Type</option>
                                                <option value={1} >Baseball</option>
                                                <option value={2} >Softball</option>
                                            </select>
                                        </div>}
                                    {filterCompany &&
                                        <div className="form-group ">
                                            <select className="custom-select" name='companies' value={sendReq.companies} onChange={(e) => this.onHandleChange(e)}>
                                                <option value="">Select Company</option>
                                                {
                                                    companyList.length &&
                                                    companyList.map((item) => {
                                                        return (
                                                            <option value={item._id}>{item.name}</option>
                                                            // <option value={item}>{item}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>}

                                    <div className="form-group ">
                                        <select className="custom-select" name="state" value={sendReq.state} onChange={(e) => this.onHandleChange(e)}>
                                            <option value="">State</option>
                                            {
                                                stateList &&
                                                stateList.map((item) => {
                                                    return (
                                                        <option value={item.stateRegion} style={{ color: item.isUpcoming ? 'black' : '#6C7B95' }}>{item.state}</option>
                                                        // <option value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* <Geosuggest
                                        ref={el => this._geoSuggest = el}
                                        onSuggestSelect={this.handleSelectGeoSuggest}
                                        placeholder="State"
                                        initialValue={state}
                                    /> */}
                                    </div>

                                </div>}
                            <div className={`event-filter ${this.props.location.pathname === "/dc-event" ? "pt-0" : ""}`}>
                                {(filterEventType && this.props.location.pathname !== "/dc-event") &&
                                    <div className="form-group ">
                                        <select className="custom-select" name="eventType" value={sendReq.eventType} onChange={(e) => this.onHandleChange(e)}>
                                            <option value="">Select Event Type</option>
                                            <option value={1} >Baseball</option>
                                            <option value={2} >Softball</option>
                                        </select>
                                    </div>}
                                {
                                    hideChkBox ?
                                        ""
                                        :
                                        <>
                                            {
                                                (eventTeamType === 1 || eventTeamType === "") &&
                                                <div className="form-group ">
                                                    <div className="custom-checkbox">
                                                        <label>
                                                            <input type="checkbox" name="eventTeamType" value={1} checked={sendReq.eventTeamType === 1 ? true : false} disabled={eventTeamType === 1 ? true : false} onChange={(e) => this.onHandleTeamType(e)} />Tournaments
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (eventTeamType === 2 || eventTeamType === "") &&
                                                <div className="form-group ">
                                                    <div className="custom-checkbox">
                                                        <label>
                                                            <input type="checkbox" name="eventTeamType" value={2} checked={sendReq.eventTeamType === 2 ? true : false} disabled={eventTeamType === 2 ? true : false} onChange={(e) => this.onHandleShowcaseType(e)} />{cType === "pws" ? "Camps" : "Showcase"}
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                }
                                <div className="form-group ">
                                    <div className="bc_relative">
                                        <DatePicker
                                            ref={(c) => (this._calendar = c)}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                            selected={sendReq.startDate}
                                            minDate={moment().subtract(0, 'days').toDate()}
                                            onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                            onChange={(e) => this.onChangeDate(e, "startDate")}
                                            placeholderText="Start date"
                                        />
                                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                    </div>
                                </div>

                                <div className="form-group ">
                                    <div className="bc_relative">
                                        <DatePicker
                                            ref={(c) => (this._calendar = c)}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                            selected={sendReq.endDate}
                                            minDate={moment(sendReq.startDate).subtract(-1, 'days').toDate()}
                                            onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                            onChange={(e) => this.onChangeDate(e, "endDate")}
                                            placeholderText="End date"
                                        />
                                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                    </div>
                                </div>

                                {this.props.location.pathname !== "/dc-event" && <div className="form-group ">
                                    <select className="custom-select" name="state" value={sendReq.state} onChange={(e) => this.onHandleChange(e)}>
                                        <option value="">State</option>
                                        {
                                            stateList &&
                                            stateList.map((item) => {
                                                return (
                                                    <option value={item.stateRegion} style={{ color: item.isUpcoming ? 'black' : '#6C7B95' }}>{item.state}</option>
                                                    // <option value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <Geosuggest
                                        ref={el => this._geoSuggest = el}
                                        onSuggestSelect={this.handleSelectGeoSuggest}
                                        placeholder="State"
                                        initialValue={state}
                                    /> */}
                                </div>}

                            </div>
                        </form>
                    }
                    <div className="upcoming_events ">
                        <div className="row justify-content-center">
                            {this.renderEvents()}
                        </div>
                        {
                            this.state.upcommingEvent.length !== 0 &&
                            <div className="pagenation-search bg-grey">
                                <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={1}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"champion-pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={this.state.currentPage}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

upcomingEvents.propTypes = {
    getCompanyList: PropTypes.func.isRequired,
    upcomingComEvents: PropTypes.func.isRequired,
    getStatesForEvent: PropTypes.func.isRequired,
    getEventDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventsInfo: state.eventsInfo,
    eventDetails: state.eventDetails,
});
var upcomingEventComponent = connect(mapStateToProps, { getCompanyList, upcomingComEvents, getStatesForEvent, getEventDetails })(upcomingEvents);
export default withRouter(upcomingEventComponent);

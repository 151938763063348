import axios from 'axios';
import { Auth_Key, API_Url, Authorization, checkIsValidToken } from '../../helpers/authToken';
import {
    FORGOT_REQUEST,
    FORGOT_SUCCESS,
    FORGOT_ERROR,
} from '../../constants/authantication/forgot.const';
import histrory from '../../../History';

export const forgotData = (data) => dispatch => {
    dispatch({ type: FORGOT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/user/password/forgot`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(forgot => {
        dispatch({
            type: FORGOT_SUCCESS,
            payload: forgot.data
        })
    }).catch((error) => {
        dispatch({ type: FORGOT_ERROR, error: error.response.data.message })
    });
};


import React, { Component } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPaymentIntent } from '../../../../../saga/actions/common/eventDetails.action';
import Confirmation from '../../../../popup/confirmation';
class payment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewInstallment: false,
            installmentList: []
        };
        this.validatorPayment = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        this.setState({
            installmentList: this.props.dataList.payment.installments
        })
        // var payment = {
        //     amount: this.props.dataList.payment.feesTournament,
        //     objetId: this.props.dataList._id,
        //     module: 1,
        //     method: "stripe",
        // };
        // if(!this.props.isPayLater){
        //     this.props.fetchPaymentIntent(payment);
        // }
    }

    chkValid = (e) => {
        if (this.validatorPayment.allValid()) {
            if (this.props.eventJoin.paymentType !== 0) {
                   var payment = {
                    amount: this.props.eventJoin.amount,
                    objetId: this.props.dataList._id,
                    module: 1,
                    method: "stripe",
                };
                console.log("payment:---", payment);
                this.props.setLoader(true);
                this.props.fetchPaymentIntent(payment);
                // this.props.onHandleChange(e)
            }
        } else {
            this.validatorPayment.showMessages();
            this.forceUpdate();
        }
    }

    onHandleClose = () => {
        this.setState({
            viewInstallment: false,
        })
        document.body.classList.remove('modal-open');
    }

    render() {
        const { eventJoin, dataList, managePayMethod, isPayLater } = this.props;
        var isPaymentDisabled = true;
        if (
            dataList.payment.paymentType.length === 1 &&
            dataList.payment.paymentType.indexOf(2) !== -1
        ) {
            isPaymentDisabled = true;
        } else {
            isPaymentDisabled = false;
        }
        return (
            <>
                <div className="event-form">
                    <div className="join-form">
                        <h4>Payment</h4>
                        <form>

                            <div className="form-group">
                                <div className="double-label">
                                    <label style={{ color: '#28263D' }}>Payment Type</label>
                                    {/* {
                                        dataList.payment.paymentType.indexOf(3) !== -1 &&
                                        dataList.payment.installments.length > 0 &&
                                        <label onClick={() => this.setState({ viewInstallment: !this.state.viewInstallment })}>View Installment</label>
                                    } */}
                                </div>
                                <select className="custom-select" name="paymentType" value={eventJoin.paymentType} onChange={(e) => this.props.onHandleChange(e)}>
                                    <option value="">Please select payment type</option>
                                    {dataList &&
                                        dataList.payment &&
                                        dataList.payment.paymentType.indexOf(1) !== -1 ? (
                                        <option value={1}>
                                            Full Payment ( ${dataList.payment.feesTournament} )
                                        </option>
                                    ) : null}
                                    {dataList &&
                                        dataList.payment &&
                                        dataList.payment.paymentType.indexOf(2) !== -1 ? (
                                        <option value={2}>
                                            Day Of Event ( ${dataList.payment.feesTournament} )
                                        </option>
                                    ) : null}
                                    {dataList &&
                                        dataList.payment &&
                                        dataList.payment.paymentType.indexOf(3) !== -1 &&
                                        dataList.payment.installments.length > 0 ? (
                                        <option value={3}>
                                            Pay Installment ( ${dataList.payment.downpaidAmt} )
                                            {/* {dataList.payment.installments[0].price} */}
                                        </option>
                                    ) : null}
                                    {dataList &&
                                        dataList.payment &&
                                        dataList.payment.paymentType.indexOf(4) !== -1 ? (
                                        <option value={4}>
                                            Pay Later ( $0 )
                                        </option>)
                                        : null}
                                </select>
                                <span className="validMsg">{this.validatorPayment.message("payment Type", eventJoin.paymentType, "required")}</span>
                            </div>
                            {
                                eventJoin.paymentType === 1 &&
                                <div className="form-group">
                                    <label>Apply Coupon Code</label>
                                    <div className="input-btn">
                                        <input type="text" name="couponCode" value={eventJoin.couponCode} onChange={(e) => this.props.onHandleChange(e)} className="form-control" placeholder="Enter coupon code" />
                                        <Link className="btn light-green" onClick={() => this.props.applyCode()}>Apply</Link>
                                    </div>
                                </div>
                            }
                            {
                                !managePayMethod &&
                                    eventJoin &&
                                    !isPayLater &&
                                    (eventJoin.paymentType === 1 || eventJoin.paymentType === 3) ?
                                    <div className="form-group">
                                        <label className="common-lable">Payment Method</label>
                                        <div className="check_input">
                                            <div className="check_box">
                                                <div className="checkbox">
                                                    <input
                                                        disabled={
                                                            isPaymentDisabled ||
                                                            eventJoin.paymentType === 2
                                                        }
                                                        type="checkbox"
                                                        name="creditcard"
                                                        value={eventJoin.paymentMethod}
                                                        onChange={(e) => this.chkValid(e)}
                                                        checked={
                                                            eventJoin.paymentMethod === 1
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <label>Credit Card </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                managePayMethod &&
                                <Link className="btn light-green" onClick={() => this.props.registerEvent()}>Submit</Link>
                            }
                            {/* <div className="form-group">
                            <label>Enter Card Number</label>
                            <input type="text" className="form-control" placeholder="ex. 1234 0000 0000 0026" />
                        </div>
                        <div className="form-group">
                            <label>Enter Exp.Date</label>
                            <input type="text" className="form-control" placeholder="**" />
                        </div>
                        <div className="form-group">
                            <label>Enter CVV Code</label>
                            <input type="text" className="form-control" placeholder="***" />
                        </div> */}
                        </form>
                    </div>
                </div>
                {
                    this.state.viewInstallment &&
                    <Confirmation
                        flag={this.state.viewInstallment}
                        close={this.onHandleClose}
                        type="installmentList"
                        installmentList={this.state.installmentList}
                    />
                }
            </>
        )
    }
}

payment.propTypes = {
    fetchPaymentIntent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
});
var paymentComponent = connect(mapStateToProps, { fetchPaymentIntent })(payment);
export default withRouter(paymentComponent);

import {
    SAVE_PLAYER_TRAINING_REQUEST,
    SAVE_PLAYER_TRAINING_SUCCESS,
    SAVE_PLAYER_TRAINING_ERROR,

    GET_PLAYER_TRAINING_REQUEST,
    GET_PLAYER_TRAINING_SUCCESS,
    GET_PLAYER_TRAINING_ERROR,
} from '../../../../constants/authantication/signup/player/training.const';

const initialState = {
    savePlayerTrainingRes: false,
    savePlayerTrainingData: null,
    savePlayerTrainingDataError: null,

    trainingPlayerRes: false,
    trainingPlayerData: null,
    trainingPlayerDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get training
        case GET_PLAYER_TRAINING_REQUEST:
            return {
                ...state,
                trainingPlayerRes: true
            };
        case GET_PLAYER_TRAINING_SUCCESS:
            return {
                ...state,
                trainingPlayerData: action.payload,
            };
        case GET_PLAYER_TRAINING_ERROR:
            return {
                ...state,
                trainingPlayerDataError: action.error,
            };

        // save training
        case SAVE_PLAYER_TRAINING_REQUEST:
            return {
                ...state,
                savePlayerTrainingRes: true
            };
        case SAVE_PLAYER_TRAINING_SUCCESS:
            return {
                ...state,
                savePlayerTrainingData: action.payload,
            };
        case SAVE_PLAYER_TRAINING_ERROR:
            return {
                ...state,
                savePlayerTrainingDataError: action.error,
            };

        default:
            return state;
    }
}
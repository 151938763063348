export const GET_FAN_BASIC_REQUEST = 'GET_FAN_BASIC_REQUEST';
export const GET_FAN_BASIC_SUCCESS = 'GET_FAN_BASIC_SUCCESS';
export const GET_FAN_BASIC_ERROR = 'GET_FAN_BASIC_ERROR';

export const GET_SEARCH_REQUEST = 'GET_SEARCH_REQUEST';
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const GET_SEARCH_ERROR = 'GET_SEARCH_ERROR';

export const UP_COVER_IMAGE_REQUEST = 'UP_COVER_IMAGE_REQUEST';
export const UP_COVER_IMAGE_SUCCESS = 'UP_COVER_IMAGE_SUCCESS';
export const UP_COVER_IMAGE_ERROR = 'UP_COVER_IMAGE_ERROR';

export const SAVE_FAN_BASIC_REQUEST = 'SAVE_FAN_BASIC_REQUEST';
export const SAVE_FAN_BASIC_SUCCESS = 'SAVE_FAN_BASIC_SUCCESS';
export const SAVE_FAN_BASIC_ERROR = 'SAVE_FAN_BASIC_ERROR';

export const GET_INVITE_COACH_REQUEST = 'GET_INVITE_COACH_REQUEST';
export const GET_INVITE_COACH_SUCCESS = 'GET_INVITE_COACH_SUCCESS';
export const GET_INVITE_COACH_ERROR = 'GET_INVITE_COACH_ERROR';

export const GET_INVITE_SUB_COACH_REQUEST = 'GET_INVITE_SUB_COACH_REQUEST';
export const GET_INVITE_SUB_COACH_SUCCESS = 'GET_INVITE_SUB_COACH_SUCCESS';
export const GET_INVITE_SUB_COACH_ERROR = 'GET_INVITE_SUB_COACH_ERROR';

export const ACCEPT_COACH_NOTIFY_REQUEST = 'ACCEPT_COACH_NOTIFY_REQUEST';
export const ACCEPT_COACH_NOTIFY_SUCCESS = 'ACCEPT_COACH_NOTIFY_SUCCESS';
export const ACCEPT_COACH_NOTIFY_ERROR = 'ACCEPT_COACH_NOTIFY_ERROR';

export const CANCEL_COACH_NOTIFY_REQUEST = 'CANCEL_COACH_NOTIFY_REQUEST';
export const CANCEL_COACH_NOTIFY_SUCCESS = 'CANCEL_COACH_NOTIFY_SUCCESS';
export const CANCEL_COACH_NOTIFY_ERROR = 'CANCEL_COACH_NOTIFY_ERROR';


import {  PLAYER_TRAINING_SUCCESS ,PLAYER_TRAINING_ERROR,PLAYER_TRAINING_REG } from "../../../../constants/view/player/profile/training.const";


const initialState = {
    trainingInfo : null,
    trainingError : null,
    ragisterInfo : null
}

export default function(state = initialState , action){

        switch (action.type) {
            case PLAYER_TRAINING_SUCCESS:
                return{
                    ...state,
                    trainingInfo : action.payload
                }
            case PLAYER_TRAINING_ERROR:
                return{
                    ...state,
                    trainingError : action.payload
                }
            break;
            case PLAYER_TRAINING_REG:
                return{
                    ...state,
                    ragisterInfo:action.payload
                }
                break;
            default:
                return state;
                break;
        }
}
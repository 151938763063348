import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import Footer from "../../../../containers/footer";
import commonCalls from "../../../../businesslogic/commonCalls";
import { getLeagueDetails, getLeagueParticipating } from '../../../../saga/actions/common/leagueDetails.action';
import Preloader from "../../../../components/preloader";
import LeagueRow from './details/leagueRow';
import LeagueInfo from './details/leagueInfo';
import Location from './details/location';
import SocialMediaLink from './details/socialMediaLink';
import HotelInfo from './details/hotelInfo';
import { commonHeader } from "../../../../businesslogic/content";
class leagueDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leagueId: "",
            loader: false,
            leagueDetailData: null,
            participationList: [],
            teamArr: [],
            loadHeader: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        localStorage.removeItem("leagueDetail");
        localStorage.removeItem("transaction");
        localStorage.removeItem("noLeagueAge");
        this.setLoader(true);
        let url = new URL(document.location);
        let leagueId = url.pathname.split("/")[2];
        this.setState({
            leagueId: leagueId
        }, () => {
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueDetailsReq &&
            nextProps.leagueDetails.leagueDetailsData
        ) {
            nextProps.leagueDetails.leagueDetailsReq = false;
            console.log("nextProps.leagueDetails:--", nextProps.leagueDetails.leagueDetailsData);
            if (nextProps.leagueDetails.leagueDetailsData.flag) {
                this.setState({
                    leagueDetailData: nextProps.leagueDetails.leagueDetailsData.result
                }, () => {
                    if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== this.state.leagueDetailData.dsCompanies._id) {
                        let cname = this.state.leagueDetailData.dsCompanies.name === "GOBTournament" ? "Greater Orlando Baseball" : this.state.leagueDetailData.dsCompanies.name;
                        let item = commonHeader.events.filter((e) => e.label === cname)[0];
                        localStorage.setItem("cid", this.state.leagueDetailData.dsCompanies._id)
                        localStorage.setItem("company", item.key)
                        localStorage.setItem("companyColor", JSON.stringify(item.colors));
                        this.setState({
                            loadHeader: true
                        })
                    } else {
                        this.setState({
                            loadHeader: true
                        })
                    }

                    nextProps.leagueDetails.leagueDetailsData = null;
                    this.props.getLeagueParticipating(this.state.leagueId);
                    // this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueDetailsData.message);
                nextProps.leagueDetails.leagueDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueParticipationReq &&
            nextProps.leagueDetails.leagueParticipationData
        ) {
            nextProps.leagueDetails.leagueParticipationReq = false;
            if (nextProps.leagueDetails.leagueParticipationData.flag) {
                this.setState({
                    participationList: nextProps.leagueDetails.leagueParticipationData.result.data
                }, () => {
                    nextProps.leagueDetails.leagueParticipationData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueParticipationData.message);
                nextProps.leagueDetails.leagueParticipationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.commonData) {
            if (nextProps.profileDetails.commonData.flag) {
                this.setState({
                    teamArr: nextProps.profileDetails.commonData.result.profile.team
                })
            }
        }
    }

    getDetails = () => {
        if (localStorage.getItem("webglToken") !== null) {
            this.props.getLeagueDetails(this.state.leagueId, true);
        } else {
            this.props.getLeagueDetails({
                leagueId: this.state.leagueId
            }, false);
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, leagueDetailData, participationList, leagueId, teamArr, loadHeader } = this.state;
        return (
            // loadHeader &&
            <div>
                <Header />
                <CommonHeroBanner dataList={leagueDetailData} />
                <div className="event_details">
                    <div className="container">
                        <LeagueRow teamArr={teamArr} dataList={leagueDetailData} isHideJoinButton={false} leagueId={leagueId} complete={false} isHideAddEventButton={false} setLoader={this.setLoader} />
                        <div className="row">
                            {
                                // leagueDetailData.length !== 0 &&
                                <LeagueInfo dataList={leagueDetailData} pList={participationList} />
                            }
                            <div className="event_right_side">
                                <Location dataList={leagueDetailData} />
                                <SocialMediaLink dataList={leagueDetailData} />
                                {/* {
                                    leagueDetailData.hotels &&
                                    leagueDetailData.hotels.length > 0 &&
                                    <HotelInfo hotelList={leagueDetailData.hotels} />
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </div>
        )
    }
}

leagueDetail.propTypes = {
    getLeagueDetails: PropTypes.func.isRequired,
    getLeagueParticipating: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leagueDetails: state.leagueDetails,
    profileDetails: state.headerData,
});
var leagueDetailComponent = connect(mapStateToProps, { getLeagueDetails, getLeagueParticipating })(leagueDetail);
export default withRouter(leagueDetailComponent);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getEvent, deleteEvent } from '../../../saga/actions/common/uploadcsv.action';
import { Link, withRouter } from 'react-router-dom';
import Preloader from '../../../components/preloader';
import moment from "moment";
import Confirmation from '../../popup/confirmation';
import ReactPaginate from "react-paginate";
import Cookies from 'js-cookie';
class uploadcsvList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            filter: {
                page: 0,
                limit: 0
            },
            eventArr: [],
            deleteId: null,
            openConfimation: false,

            // pagination variable diclaration
            offset: 0,
            perPage: 10,
            currentPage: "",
            index: "",
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        localStorage.removeItem("formData");
        localStorage.removeItem("sourceCred");
        localStorage.removeItem("showVideo");
        localStorage.removeItem("selectedSource");
        window.scrollTo(0, 0);
        this.setLoader(true);
        this.props.getEvent(this.state.filter, "Fan", localStorage.getItem("rosterToken"));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getEventReq &&
            nextProps.uploadCsvInfo.getEventData
        ) {
            console.log("nextProps from getEventData:----", nextProps.uploadCsvInfo.getEventData);
            nextProps.uploadCsvInfo.getEventReq = false;
            if (nextProps.uploadCsvInfo.getEventData.flag) {
                this.setState({
                    pageCount: Math.ceil(nextProps.uploadCsvInfo.getEventData.result.total / this.state.perPage),

                    eventArr: nextProps.uploadCsvInfo.getEventData.result.docs
                }, () => {
                    this.setLoader(false);
                    nextProps.uploadCsvInfo.getEventData = null;
                })
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.deleteEventReq &&
            nextProps.uploadCsvInfo.deleteEventData
        ) {
            console.log("nextProps from deleteEventData:----", nextProps.uploadCsvInfo.deleteEventData);
            nextProps.uploadCsvInfo.deleteEventReq = false;
            if (nextProps.uploadCsvInfo.deleteEventData.flag) {
                document.body.classList.remove("modal-open");
                this.setState({
                    openConfimation: false,
                    deleteId: null
                }, () => {
                    this.commonCall.successMessage(nextProps.uploadCsvInfo.deleteEventData.message);
                    this.setLoader(false);
                    nextProps.uploadCsvInfo.deleteEventData = null;
                    this.props.getEvent(this.state.filter, "Fan", localStorage.getItem("rosterToken"));
                })
            }
        }
    }

    confirmationDelete = (id) => {
        this.setState((prevState) => ({
            ...prevState,
            openConfimation: true,
            deleteId: id
        }))
    }

    closeDeletePopup = () => {
        document.body.classList.remove("modal-open");
        this.setState((prevState) => ({
            ...prevState,
            openConfimation: false,
            deleteId: null
        }))
    }

    handleDeleteEvent = () => {
        this.props.deleteEvent({ event_id: this.state.deleteId }, "Fan", localStorage.getItem("rosterToken"));
    }

    redirectSource = (list) => {
        if (list.sources.length !== 0) {
            localStorage.setItem("showVideo", true);
        } else {
            localStorage.setItem("showVideo", false);
        }
        this.props.history.push(`/fan-source/${list._id}`);
    }

    renderEventList = () => {
        return (
            this.state.eventArr.length != 0 ?
                this.state.eventArr.map((list, i) => {
                    return (
                        <tr key={i}>
                            <td colspan="2">{list.event_name}</td>
                            <td colspan="2">{moment(list.date, 'MM/DD/YYYY').format('MM/DD/YYYY')}</td>
                            <td className="common_btn">
                                <Link className="blue_btn action_btn" onClick={() => this.redirectSource(list)}>Add Data</Link>
                                <Link className="gray btn action_btn" to={`/fan-device/${list._id}`}>Edit Data</Link>
                                <Link className="red_btn action_btn" onClick={() => this.confirmationDelete(list._id)}>Delete</Link>
                            </td>
                        </tr>
                    );
                })
                :
                <tr>
                    <td colSpan="6" className="text-center">No Data Found</td>
                </tr>
        )
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    handlePageClick = (e) => {
        console.log("e:----------------------------", e);

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.setState((prevState) => ({
                    ...prevState,
                    filter: {
                        page: selectedPage + 1,
                        limit: this.state.perPage
                    },
                }), () => {
                    this.setLoader(true);
                    this.props.getEvent(this.state.filter, "Fan", localStorage.getItem("rosterToken"));

                });
            }
        );
    };

    redirectWebgl = (name) => {
        if (name === "BCTeam") {
            Cookies.set('gameType', localStorage.getItem("gameType"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            localStorage.setItem("webglUrl", process.env.REACT_APP_BCTEAM_LINK);
            this.props.history.push("/bcteamapp");
        } else if (name === "BallR") {
            localStorage.setItem("webglUrl", process.env.REACT_APP_WEBGL_LINK);
            this.props.history.push("/ballrapp");
        } else if (name === "PitchR") {
            localStorage.setItem("webglUrl", process.env.REACT_APP_PITCHR_LINK);
            this.props.history.push("/pitchrapp");
        } else {
            Cookies.remove('analyzrSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            localStorage.setItem("webglUrl", process.env.REACT_APP_ANALYZR_LINK);
            this.props.history.push("/analyzrapp");
        }
    }

    render() {
        const { loader, eventArr } = this.state;
        return (
            <>
                <section className="uploadcsv-uploadcsvdata uploadcsv" style={{ marginTop: '0px' }}>
                    <div className="container">
                        <div className="white-bg tablecontent">
                            <div className="tableinfo">
                                <div>
                                    <h3>Upload CSV</h3>
                                    {/* <p className="mb-0">User csv details</p> */}
                                </div>
                                <div className="rightsection">
                                    {
                                        localStorage.getItem("analProduct") &&
                                        <>
                                            <Link className="btn light-green btn-addnewevent" onClick={() => this.redirectWebgl("AnalyzR")}>AnalyzR</Link>
                                            &nbsp;&nbsp;
                                        </>
                                    }
                                    {
                                        localStorage.getItem("ballrProduct") &&
                                        <>
                                            <Link className="btn light-green btn-addnewevent" onClick={() => this.redirectWebgl("BallR")}>BallR</Link>
                                            &nbsp;&nbsp;
                                        </>
                                    }
                                    {
                                        localStorage.getItem("bcteamProduct") &&
                                        <>
                                            <Link className="btn light-green btn-addnewevent" onClick={() => this.redirectWebgl("BCTeam")}>BCTeam</Link>
                                            &nbsp;&nbsp;
                                        </>
                                    }
                                    {
                                        localStorage.getItem("pitchrProduct") &&
                                        <>
                                            <Link className="btn light-green btn-addnewevent" onClick={() => this.redirectWebgl("PitchR")}>PitchR</Link>
                                            &nbsp;&nbsp;
                                        </>
                                    }
                                    <Link className="btn light-green btn-addnewevent" to={"/fan-event"}>Upload Data</Link>
                                </div>
                            </div>
                            <div className="table-responsive-xl">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colspan="2">Event Name</th>
                                            <th scope="col" colspan="2">Event Date</th>
                                            <th scope="col" colspan="2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderEventList()}
                                    </tbody>
                                </table>
                            </div>
                            {eventArr.length !== 0 && (

                                <ReactPaginate
                                    previousLabel={<i class="fas fa-chevron-left"></i>}
                                    nextLabel={<i class="fas fa-chevron-right"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={this.state.currentPage}
                                />

                            )}
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
                {
                    this.state.openConfimation &&
                    <Confirmation
                        flag={this.state.openConfimation}
                        close={this.closeDeletePopup}
                        type="event"
                        ok={this.handleDeleteEvent}
                    />
                }
            </>
        )
    }
}

uploadcsvList.propTypes = {
    getEvent: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default withRouter(connect(mapStateToProps, { getEvent, deleteEvent })(uploadcsvList));

import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    GET_LEAGUE_DETAILS_REQUEST,
    GET_LEAGUE_DETAILS_SUCCESS,
    GET_LEAGUE_DETAILS_ERROR,

    GET_PARTICIPATION_LEAGUE_REQUEST,
    GET_PARTICIPATION_LEAGUE_SUCCESS,
    GET_PARTICIPATION_LEAGUE_ERROR,

    GET_LEAGUE_EVENTS_REQUEST,
    GET_LEAGUE_EVENTS_SUCCESS,
    GET_LEAGUE_EVENTS_ERROR,

    LEAGUE_EVENT_SELECT_REQUEST,
    LEAGUE_EVENT_SELECT_SUCCESS,
    LEAGUE_EVENT_SELECT_ERROR,

    CHECK_LEAGUE_ROSTER_REQUEST,
    CHECK_LEAGUE_ROSTER_SUCCESS,
    CHECK_LEAGUE_ROSTER_ERROR,

} from '../../constants/common/leagueDetails.const';
import histrory from '../../../History';

export const getRosterConfirmation = (data) => dispatch => {
    console.log("data:--", data);
    checkIsValidCompanyToken(histrory);
    dispatch({ type: CHECK_LEAGUE_ROSTER_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/check/roster/available`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(l_event => {
        dispatch({
            type: CHECK_LEAGUE_ROSTER_SUCCESS,
            payload: l_event.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: CHECK_LEAGUE_ROSTER_ERROR, error: error.response.data.message })
        }
    });
};

export const getLeagueDetails = (data, type) => dispatch => {
    console.log("data:--", data);
    if (type) {
        checkIsValidCompanyToken(histrory);
        dispatch({ type: GET_LEAGUE_DETAILS_REQUEST })
        axios({
            method: 'GET',
            url: `${EVENT_API_Url}league/detail/${data}`,
            headers: {
                'x-auth-token': Auth_Company_Key,
                'Accept': 'application/json'
            }
        }).then(details => {
            dispatch({
                type: GET_LEAGUE_DETAILS_SUCCESS,
                payload: details.data
            })
        }).catch((error) => {
            if (error.response) {
                console.log("error.response:--", error.response);
                dispatch({ type: GET_LEAGUE_DETAILS_ERROR, error: error.response.data.message })
            }
        });
    } else {
        dispatch({ type: GET_LEAGUE_DETAILS_REQUEST })
        axios({
            method: 'POST',
            url: `${EVENT_API_Url}league/getLeagueById`,
            data: data,
            headers: {
                'Accept': 'application/json'
            }
        }).then(details => {
            dispatch({
                type: GET_LEAGUE_DETAILS_SUCCESS,
                payload: details.data
            })
        }).catch((error) => {
            if (error.response) {
                console.log("error.response:--", error.response);
                dispatch({ type: GET_LEAGUE_DETAILS_ERROR, error: error.response.data.message })
            }
        });
    }
};

export const getLeagueParticipating = (data) => dispatch => {
    console.log("data:--", data);
    // checkIsValidCompanyToken(histrory);
    dispatch({ type: GET_PARTICIPATION_LEAGUE_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}league/participateTeam?id=${data}`,
        headers: {
            // 'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(plist => {
        dispatch({
            type: GET_PARTICIPATION_LEAGUE_SUCCESS,
            payload: plist.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PARTICIPATION_LEAGUE_ERROR, error: error.response.data.message })
        }
    });
};

export const getLeagueEvents = (data) => dispatch => {
    console.log("data:--", data);
    checkIsValidCompanyToken(histrory);
    dispatch({ type: GET_LEAGUE_EVENTS_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/event/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(l_event => {
        dispatch({
            type: GET_LEAGUE_EVENTS_SUCCESS,
            payload: l_event.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LEAGUE_EVENTS_ERROR, error: error.response.data.message })
        }
    });
};

export const leagueEventSelect = (data) => dispatch => {
    console.log("data:--", data);
    checkIsValidCompanyToken(histrory);
    dispatch({ type: LEAGUE_EVENT_SELECT_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/event/select`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(l_event_select => {
        dispatch({
            type: LEAGUE_EVENT_SELECT_SUCCESS,
            payload: l_event_select.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: LEAGUE_EVENT_SELECT_ERROR, error: error.response.data.message })
        }
    });
};

export const GET_DIV_REQUEST = 'GET_DIV_REQUEST';
export const GET_DIV_SUCCESS = 'GET_DIV_SUCCESS';
export const GET_DIV_ERROR = 'GET_DIV_ERROR';

export const SAVE_ROSTER_REQUEST = 'SAVE_ROSTER_REQUEST';
export const SAVE_ROSTER_SUCCESS = 'SAVE_ROSTER_SUCCESS';
export const SAVE_ROSTER_ERROR = 'SAVE_ROSTER_ERROR';

export const GET_ROSTER_REQUEST = 'GET_ROSTER_REQUEST';
export const GET_ROSTER_SUCCESS = 'GET_ROSTER_SUCCESS';
export const GET_ROSTER_ERROR = 'GET_ROSTER_ERROR';

export const GET_ROSTER_BY_PARENT_TEAM_REQUEST = 'GET_ROSTER_BY_PARENT_TEAM_REQUEST';
export const GET_ROSTER_BY_PARENT_TEAM_SUCCESS = 'GET_ROSTER_BY_PARENT_TEAM_SUCCESS';
export const GET_ROSTER_BY_PARENT_TEAM_ERROR = 'GET_ROSTER_BY_PARENT_TEAM_ERROR';

export const POST_ROSTER_BY_PARENT_TEAM_REQUEST = 'POST_ROSTER_BY_PARENT_TEAM_REQUEST';
export const POST_ROSTER_BY_PARENT_TEAM_SUCCESS = 'POST_ROSTER_BY_PARENT_TEAM_SUCCESS';
export const POST_ROSTER_BY_PARENT_TEAM_ERROR = 'POST_ROSTER_BY_PARENT_TEAM_ERROR';


import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { imagesArr } from "../../../../assets/images";
import { pastComEvents, getStatesForEvent } from '../../../../saga/actions/common/events.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import * as data from "../../../../businesslogic/country.json";
import moment from "moment";
import Geosuggest from 'react-geosuggest';
import { getSumOfRegisterTeam, getAvailableSport } from "../../../../businesslogic/content";
import ReactPaginate from "react-paginate";
class pastEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pastEvent: [],
            sendReq: {
                page: 0,
                limit: 6,
                isPresent: 2,
                companies: JSON.parse(localStorage.getItem("isAllCompany")) === true ? "" : localStorage.getItem("cid"),
                state: "",
                startDate: "",
                endDate: "",
                eventType: this.props.eventType,
                eventTeamType: this.props.eventTeamType,
                isCollegeShowcase: JSON.parse(localStorage.getItem("isCollegeShowcase")) === true ? true : false,
                currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
            },
            sendStateReq: {
                isPresent: 2,
                eventType: this.props.eventType,
                companies: JSON.parse(localStorage.getItem("isAllCompany")) === true ? "" : localStorage.getItem("cid"),
                eventTeamType: this.props.eventTeamType,
            },
            stateList: [],

            // pagination variable diclaration
            offset: 0,
            perPage: 6,
            currentPage: "",
            pageCount: ""
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        if (localStorage.getItem("cid")) {
            this.props.increment();
            this.props.pastComEvents(this.state.sendReq)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.pastEventReq &&
            nextProps.eventsInfo.pastEventData
        ) {
            nextProps.eventsInfo.pastEventReq = false;
            console.log("nextProps.pastevent:--", nextProps.eventsInfo.pastEventData);
            if (nextProps.eventsInfo.pastEventData.flag) {
                this.setState({
                    pastEvent: nextProps.eventsInfo.pastEventData.result.data,
                    pageCount: Math.ceil(nextProps.eventsInfo.pastEventData.result.total / this.state.perPage),
                }, () => {
                    nextProps.eventsInfo.pastEventData = null;
                    if (this.state.stateList.length === 0) {
                        // this.setStateList();
                        this.props.getStatesForEvent(this.state.sendStateReq);
                    } else {
                        this.props.decrement();
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.pastEventData.message);
                nextProps.eventsInfo.pastEventData = null;
                this.props.decrement();
            }
        }

        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.stateEventReq &&
            nextProps.eventsInfo.stateEventData
        ) {
            nextProps.eventsInfo.stateEventReq = false;
            console.log("nextProps.stateList:--", nextProps.eventsInfo.stateEventData);
            if (nextProps.eventsInfo.stateEventData.flag) {
                this.setState({
                    stateList: nextProps.eventsInfo.stateEventData.result.data
                }, () => {
                    nextProps.eventsInfo.stateEventData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.stateEventData.message);
                nextProps.eventsInfo.stateEventData = null;
                this.props.decrement();
            }
        }
    }

    setStateList = () => {
        data.default.countries.map((a) => {
            if (a.country == "United States") {
                this.setState({
                    stateList: a.states,
                }, () => {
                    this.props.decrement();
                });
            }
        });
    }

    redirectDetails = (data) => {
        localStorage.setItem("recapInfo", JSON.stringify(data))
        this.props.history.push(`/${localStorage.getItem("company")}-event-info/${data._id}`);
        // if (this.props.eventType === 1) {
        //     this.props.history.push(`/pwb-event-info/${data._id}`);
        // } else {
        //     this.props.history.push(`/pws-event-info/${data._id}`);
        // }
    }

    renderEvents = () => {
        return (
            this.state.pastEvent.length !== 0 ?
                this.state.pastEvent.map((list, i) => {
                    const location = list.location && list.location.streetAddress.split(",");
                    let svalid_date = moment().format("MM/DD/YYYY");
                    let evalid_date = moment(list.regClosedDate).format("MM/DD/YYYY");
                    let chkdate = moment(svalid_date).isBefore(evalid_date);
                    return (
                        <div className="event_box w-33" key={i} onClick={() => this.redirectDetails(list)}>
                            <div className="event_card">
                                <div class="event_card_img">
                                    <img src={list.eventImages && list.eventImages.filePath} alt="event" />
                                    {

                                        (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                        <span className="event_card_address">
                                            {list.location &&
                                                list.location.city ?
                                                list.location.city :
                                                list.location.streetAddress ?
                                                    location[0] :
                                                    list.location.stateRegion}
                                        </span>
                                    }
                                </div>
                                {
                                    // !chkdate &&
                                    <div class="event_tags text-right">
                                        {list.regCountDown !== "Registration Closed" ? 'Registration Closes In ' : ""}<span>{list.regCountDown}</span>
                                    </div>
                                }
                                <div class="event_card_desc">
                                    <div class="event_card_logo">
                                        <img src={list.eventLogo && list.eventLogo.filePath ? list.eventLogo.filePath : imagesArr.default_user} alt="event logo" />
                                    </div>
                                    <div class="event_card_titles">
                                        <h3>{list.eventTitle}</h3>
                                        <div class="event_card_dates">
                                            <p class="date_icon">{moment(list.startDate).format("DD-MMM-YYYY")} - {moment(list.endDate).format("DD-MMM-YYYY")}</p>
                                            {list?.showSpotAvail ?
                                                <>
                                                    <p class="team_icons">{list.feesByGroups.length ? getSumOfRegisterTeam(list.feesByGroups) : 0} {list.eventTeamType === 1 ? 'Teams Registered' : 'Players Registered'}</p>
                                                    <p class="team_icons">{list.feesByGroups.length ? getAvailableSport(list.feesByGroups) : 0} Available Spots</p>
                                                </>
                                                :
                                                <p class="team_icons showSpotAvail"> {!chkdate ? "Registration Closed" : "Registration Open"}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center w-100">No events found.</p>
        )
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onChangeDate = (value, name) => {

        if (name === "startDate") {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value,
                    endDate: "",
                    page: 0,
                    limit: 6,
                },
                currentPage: 0
            }, () => {
                if (this.state.sendReq.endDate) {
                    this.props.increment();
                    this.props.pastComEvents(this.state.sendReq);
                }
            });
        } else {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value,
                    page: 0,
                    limit: 6,
                },
                currentPage: 0
            }, () => {
                if (this.state.sendReq.startDate) {
                    this.props.increment();
                    this.props.pastComEvents(this.state.sendReq);
                }
            });
        }

    }

    handleSelectGeoSuggest = (place) => {
        if (!place) {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    state: "",
                },
                state: ""
            }, () => {
                this.props.increment();
                this.props.pastComEvents(this.state.sendReq);
            })
        } else {
            var componentForm = {
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name',
            };

            for (var i = 0; i < place.gmaps.address_components.length; i++) {
                var addressType = place.gmaps.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.gmaps.address_components[i][componentForm[addressType]];
                    if (val)
                        componentForm[addressType] = val
                }
            }

            var location = {
                streetAddress: place.gmaps.formatted_address,
                city: componentForm.locality !== 'long_name' ? componentForm.locality : 'Surat',
                stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? componentForm.administrative_area_level_1 : 'GJ',
                postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : 394105,
                latitude: place.location.lat || 42.2538048,
                longitude: place.location.lng || -83.5735806
            }
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    state: location.stateRegion,
                },
                state: location.streetAddress
            }, () => {
                this.props.increment();
                this.props.pastComEvents(this.state.sendReq);
            })
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: value,
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.props.increment();
            this.props.pastComEvents(this.state.sendReq);
        });
    }

    onHandleTeamType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: this.state.sendReq.eventTeamType === 1 ? "" : JSON.parse(value),
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.props.increment();
            this.props.pastComEvents(this.state.sendReq);
        });
    }

    onHandleShowcaseType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: this.state.sendReq.eventTeamType === 2 ? "" : JSON.parse(value),
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.props.increment();
            this.props.pastComEvents(this.state.sendReq);
        });
    }

    redirectEvents = (route) => {
        localStorage.setItem("textName", this.props.textName);
        this.props.history.push(route);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                page: selectedPage + 1,
                limit: Number(this.state.perPage),
            },
            currentPage: selectedPage,
            offset: offset,
        }), () => {
            this.props.increment();
            this.props.pastComEvents(this.state.sendReq);
        });
    };

    render() {
        const { sendReq, stateList } = this.state;
        const { hideChkBox, textName, filterForm, eventTeamType, cType } = this.props;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <div className="upcomingevent_section">
                <div className="container">
                    <div className="upcoming_events_title">
                        <h1 className="section_title">Past {textName} </h1>
                        <span className="see_post">
                            {
                                localStorage.getItem("textName") === null ?
                                    ""
                                    :
                                    (eventTeamType === "" || eventTeamType === 1 || eventTeamType === 2) &&
                                    localStorage.getItem('company') !== 'pioneer' && <Link onClick={() => this.redirectEvents('/all-live-event')} to={`/all-live-event`}>Live {textName}</Link>
                            }
                        </span>
                    </div>
                    {
                        filterForm &&
                        <form>
                            <div className="event-filter">
                                {
                                    hideChkBox ?
                                        ""
                                        :
                                        <>
                                            <div className="form-group ">
                                                <div className="custom-checkbox">
                                                    <label>
                                                        <input type="checkbox" name="eventTeamType" value={1} checked={sendReq.eventTeamType === 1 ? true : false} onChange={(e) => this.onHandleTeamType(e)} />Tournaments
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="custom-checkbox">
                                                    <label>
                                                        <input type="checkbox" name="eventTeamType" value={2} checked={sendReq.eventTeamType === 2 ? true : false} onChange={(e) => this.onHandleShowcaseType(e)} />{cType === "pws" ? "Camps" : "Showcase"}
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                }
                                <div className="form-group ">
                                    <div className="bc_relative">
                                        <DatePicker
                                            ref={(c) => (this._calendar = c)}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                            selected={sendReq.startDate}
                                            maxDate={moment().subtract(1, 'days').toDate()}
                                            onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                            onChange={(e) => this.onChangeDate(e, "startDate")}
                                            placeholderText="Start date"
                                        />
                                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                    </div>
                                </div>

                                <div className="form-group ">
                                    <div className="bc_relative">
                                        <DatePicker
                                            ref={(c) => (this._calendar = c)}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                </div>
                                            )}
                                            selected={sendReq.endDate}
                                            minDate={moment(sendReq.startDate).subtract(0, 'days').toDate()}
                                            maxDate={moment().subtract(1, 'days').toDate()}
                                            onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                            onChange={(e) => this.onChangeDate(e, "endDate")}
                                            placeholderText="End date"
                                        />
                                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                    </div>
                                </div>

                                <div className="form-group ">
                                    <select className="custom-select" name="state" value={sendReq.state} onChange={(e) => this.onHandleChange(e)}>
                                        <option value="">State</option>
                                        {
                                            stateList &&
                                            stateList.map((item) => {
                                                return (
                                                    <option value={item.stateRegion} style={{ color: item.isUpcoming ? 'black' : '#6C7B95' }}>{item.state}</option>
                                                    // <option value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <Geosuggest
                                    ref={el => this._geoSuggest = el}
                                    onSuggestSelect={this.handleSelectGeoSuggest}
                                    placeholder="State"
                                    initialValue={state}
                                /> */}
                                </div>
                            </div>
                        </form>
                    }
                    <div className="upcoming_events ">
                        <div className="row">
                            {this.renderEvents()}
                        </div>
                        {
                            this.state.pastEvent.length !== 0 &&
                            <div className="pagenation-search bg-grey">
                                <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={1}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"champion-pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={this.state.currentPage}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

pastEvents.propTypes = {
    pastComEvents: PropTypes.func.isRequired,
    getStatesForEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventsInfo: state.eventsInfo,
});
var pastEventsComponent = connect(mapStateToProps, { pastComEvents, getStatesForEvent })(pastEvents);
export default withRouter(pastEventsComponent);

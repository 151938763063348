import {
    GET_PLAYER_COLLEGE_SEARCH_REQUEST,
    GET_PLAYER_COLLEGE_SEARCH_SUCCESS,
    GET_PLAYER_COLLEGE_SEARCH_ERROR,

    GET_SUGGETION_REQUEST,
    GET_SUGGETION_SUCCESS,
    GET_SUGGETION_ERROR,

    POST_COLLEGELIST_SUCCESS,
    POST_COLLEGELIST_ERROR
} from '../../../../constants/authantication/signup/player/collage.const';

const initialState = {
    searchPlayerTeamRes: false,
    searchPlayerTeamData: null,
    searchPlayerTeamDataError: null,

    searchSuggestionRes: false,
    searchSuggestionData: null,
    searchSuggestionDataError: null,

    postInfo: null,
    postError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Search Data
        case GET_PLAYER_COLLEGE_SEARCH_REQUEST:
            return {
                ...state,
                searchPlayerTeamRes: true
            };
        case GET_PLAYER_COLLEGE_SEARCH_SUCCESS:
            return {
                ...state,
                searchPlayerTeamData: action.payload,
            };
        case GET_PLAYER_COLLEGE_SEARCH_ERROR:
            return {
                ...state,
                searchPlayerTeamDataError: action.error,
            };

        // Get Suggestion Data
        case GET_SUGGETION_REQUEST:
            return {
                ...state,
                searchSuggestionRes: true
            };
        case GET_SUGGETION_SUCCESS:
            return {
                ...state,
                searchSuggestionData: action.payload,
            };
        case GET_SUGGETION_ERROR:
            return {
                ...state,
                searchSuggestionDataError: action.error,
            };

        case POST_COLLEGELIST_SUCCESS:
            return {
                ...state,
                postInfo: action.payload,
            };
        case POST_COLLEGELIST_ERROR:
            return {
                ...state,
                postError: action.error,
            };
        default:
            return state;
    }
}
export const FAN_PERSONALINFO_REQUEST = 'FAN_PERSONALINFO_REQUEST';
export const FAN_PERSONALINFO_SUCCESS = 'FAN_PERSONALINFO_SUCCESS';
export const FAN_PERSONALINFO_ERROR   = 'FAN_PERSONALINFO_ERROR';

export const FAN_REGISTERINFO_REQUEST ="FAN_REGISTERINFO_REQUEST";
export const FAN_REGISTERINFO_SUCCESS ="FAN_REGISTERINFO_SUCCESS";
export const FAN_REGISTERINFO_ERROR   ="FAN_REGISTERINFO_ERROR";

export const PHONE_VERIFY_FAN_REQUEST ="PHONE_VERIFY_FAN_REQUEST";
export const PHONE_VERIFY_FAN_SUCCESS ="PHONE_VERIFY_FAN_SUCCESS";
export const PHONE_VERIFY_FAN_ERROR   ="PHONE_VERIFY_FAN_ERROR";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { imagesArr } from '../../assets/images';
import CommonHeroBanner from '../../components/commonHeroBanner';
import Confirmation from '../popup/confirmation';
import LeaveTeam from '../popup/leaveTeam';
import QuiteTeam from '../popup/quiteTeam';
import RequestTeam from '../popup/requestTeam';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Preloader from '../../components/preloader';
import commonCalls from "../../businesslogic/commonCalls";
import { postFileUpload } from "../../saga/actions/common/api.action";
import { saveBGphoto, leaveTeam } from "../../saga/actions/views/player/dashboard.action";
import { getPlayerProfile } from '../../saga/actions/containers/header.action';
import { getActiveList, deleteSubscription, upgradeList, getPlayerRosterList } from '../../saga/actions/common/subscription.action';
import { getProductList, getAdverticement, getEventDash, getTeamList, changeMyTeamData } from '../../saga/actions/common/api.action';
import { checkLoginValidation } from '../../saga/helpers/authToken';
import moment from "moment";
import UpgradeSubscribe from '../popup/UpgradeSubscribe';
import SuccessUpgrade from '../popup/successUpgrade';
import {
	EmailShareButton,
	EmailIcon,
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
} from "react-share";
import Cookies from 'js-cookie';
import EventSlider from './component/eventSlider';
import { isIOS, isMobileOnly, isMobile } from 'react-device-detect';
import DownloadApp from '../popup/downloadApp';
import ResetPassword from '../popup/resetPassword';
import { commonHeader } from "../../businesslogic/content";

class playerDashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			file: null,
			loader: false,
			address: "",
			playerName: "",
			profileLogo: "",
			teamArr: "",
			teamRecord: "",
			userId: "",
			activeFilter: {
				limit: 0
			},
			product: {
				fullDetail: true
			},
			activeList: "",
			delId: "",
			deletePopup: false,
			date: "",
			shareBtn: false,
			siteUrl: "",
			openTeamConfirm: false,
			productList: [],

			invite: "",
			invitePopup: false,
			leaveTeamPopup: false,

			coachName: "",
			adsArr: [],
			upgradeArr: [],
			upgradePopup: false,
			subId: "",
			successPopup: false,
			skipFlg: false,
			isImport: false,
			rosterList: [],
			sendEvents: {
				type: 1,
				page: 0,
				limit: 0,
				eventTeamType: 2,
				startDate: "",
				endDate: "",
				state: "",
				companies: "",
				rosterTeamId: localStorage.getItem("rosterId") ? localStorage.getItem("rosterId") : "",
				myEvent: 1,
				currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
			},
			eventList: [],
			myTeamList: [],
			reloadData: false,
			firstLoad: true,
			rosterGroup: localStorage.getItem("rosterId") ? localStorage.getItem("rosterId") : "",
			rosterArr: null,
			appPopUp: false,
			resetPassword: false,
			isCmsUser: false
		}
		this.commonCall = new commonCalls();
	}

	componentDidMount() {
		let url = new URL(document.location);

		this.setLoader(true);
		window.scrollTo(0, 0);
		this.props.getActiveList(this.state.activeFilter, "Player");
		this.props.getProductList(this.state.product, "Player");
		this.props.getAdverticement({ moduleId: 2 }, "Player");
		// if (localStorage.getItem("company")) {
		this.props.getEventDash(this.state.sendEvents);
		// }
		if (this.state.firstLoad) {
			this.setState({
				firstLoad: false
			}, () => {
				if (this.props.fileUpload &&
					this.props.fileUpload.getMyTeamListData) {
					this.props.fileUpload.getMyTeamListData = null
				}
				this.props.getTeamList('Player');
			})
		}

		this.setState({
			siteUrl: url.origin,
			appPopUp: isMobile ? true : false
		})
	}

	componentWillReceiveProps = (nextProps) => {

		if (nextProps.login.claimMsg) {
            this.commonCall.successMessage(nextProps.login.claimMsg);
            nextProps.login.claimMsg = null
        }

		if (nextProps.profileDetails &&
			nextProps.profileDetails.headPlayerData &&
			nextProps.profileDetails.headPlayerReq
		) {
			if (nextProps.profileDetails.headPlayerData.result.profile) {
				console.log("nextProps.profileDetails.headPlayerData:--", nextProps.profileDetails.headPlayerData.result);
				this.setState({
					address: nextProps.profileDetails.headPlayerData.result.profile.address,
					playerName: nextProps.profileDetails.headPlayerData.result.fname + " " + nextProps.profileDetails.headPlayerData.result.lname,
					profileLogo: nextProps.profileDetails.headPlayerData.result.profileUrl,
					teamArr: nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('parentTeam') && nextProps.profileDetails.headPlayerData.result.profile.parentTeam,
					teamRecord: nextProps.profileDetails.headPlayerData.result.profile.team.length !== 0 ? nextProps.profileDetails.headPlayerData.result.profile.team[0].teamRecord : "",
					file: nextProps.profileDetails.headPlayerData.result.profile.cardBGImg && nextProps.profileDetails.headPlayerData.result.profile.cardBGImg.filePath ? nextProps.profileDetails.headPlayerData.result.profile.cardBGImg.filePath : null,
					userId: nextProps.profileDetails.headPlayerData.result._id,
					invite: nextProps.profileDetails.headPlayerData.result.profile.invite,
					coachName: nextProps.profileDetails.headPlayerData.result.profile.team.length !== 0 ? nextProps.profileDetails.headPlayerData.result.profile.team[0].coach.fname + " " + nextProps.profileDetails.headPlayerData.result.profile.team[0].coach.lname : "",
					skipFlg: nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('skipTeam') ? nextProps.profileDetails.headPlayerData.result.profile.skipTeam : false,
					isImport: nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('isImport') ? nextProps.profileDetails.headPlayerData.result.profile.isImport === 1 ? true : false : false,
					resetPassword: nextProps.profileDetails.headPlayerData.result.profile.ressetpasswordpop,
					isCmsUser: nextProps.profileDetails.headPlayerData.result.profile.hasOwnProperty('cmsUser') ? nextProps.profileDetails.headPlayerData.result.profile.cmsUser : false
				}, () => {

					if (!this.state.firstLoad) {
						this.props.getTeamList('Player');
					}
					setTimeout(() => {
						this.setLoader(false);
					}, 1500);
				})
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.fileUploadData
		) {
			console.log("nextProps.:---", nextProps.fileUpload.fileUploadData);

			this.setState({
				file: nextProps.fileUpload.fileUploadData.filePath,
			}, () => {
				this.props.saveBGphoto({
					cardBGImg: nextProps.fileUpload.fileUploadData._id
				})
				nextProps.fileUpload.fileUploadData = null;
			})
		}

		if (nextProps.dashboardDetails &&
			nextProps.dashboardDetails.bgPhotoReq &&
			nextProps.dashboardDetails.bgPhotoData
		) {
			console.log("nextProps.:---", nextProps.dashboardDetails.bgPhotoData);
			nextProps.dashboardDetails.bgPhotoReq = false;
			this.setLoader(false);
			this.commonCall.successMessage(nextProps.dashboardDetails.bgPhotoData.message);
			nextProps.dashboardDetails.bgPhotoData = null;
		}

		if (nextProps.dashboardDetails &&
			nextProps.dashboardDetails.leaveTeamReq &&
			nextProps.dashboardDetails.leaveTeamData
		) {
			console.log("nextProps.:---", nextProps.dashboardDetails.leaveTeamData);
			nextProps.dashboardDetails.leaveTeamReq = false;
			this.commonCall.successMessage(nextProps.dashboardDetails.leaveTeamData.message);
			nextProps.dashboardDetails.leaveTeamData = null;
			this.props.getPlayerProfile();
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.getActiveReq &&
			nextProps.subInfo.getActiveData
		) {
			nextProps.subInfo.getActiveReq = false;
			console.log("nextProps.subInfo.getActiveData:--", nextProps.subInfo.getActiveData);
			if (nextProps.subInfo.getActiveData.flag) {
				this.setState({
					activeList: nextProps.subInfo.getActiveData.result.docs
				}, () => {
					nextProps.subInfo.getActiveData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.subInfo.getActiveData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.delActiveReq &&
			nextProps.subInfo.delActiveData
		) {
			nextProps.subInfo.delActiveReq = false;
			console.log("nextProps.subInfo.delActiveData:--", nextProps.subInfo.delActiveData);
			if (nextProps.subInfo.delActiveData.flag) {
				document.body.classList.remove('modal-open');
				this.setState({
					delId: "",
					deletePopup: false
				}, () => {
					this.props.getActiveList(this.state.activeFilter, "Player");
				})
			} else {
				nextProps.subInfo.delActiveData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.fileUpload && nextProps.fileUpload.productList) {
			if (nextProps.fileUpload.productList.flag) {
				this.setState((prevState) => ({
					...prevState,
					productList: nextProps.fileUpload.productList.result.data ? nextProps.fileUpload.productList.result.data : []
				}), () => {
					let getAnal = this.state.productList.some((d) => d.productId.name === "AnalyzR");
					let getBallr = this.state.productList.some((d) => d.productId.name === "BallR");
					let getBCTeam = this.state.productList.some((d) => d.productId.name === "BCTeam");
					let getPitchr = this.state.productList.some((d) => d.productId.name === "PitchR");
					if (getAnal) {
						localStorage.setItem("analProduct", true);
					}
					if (getBallr) {
						localStorage.setItem("ballrProduct", true);
					}
					if (getBCTeam) {
						localStorage.setItem("bcteamProduct", true);
					}
					if (getPitchr) {
						localStorage.setItem("pitchrProduct", true);
					}
					nextProps.fileUpload.productList = null;
					this.setLoader(false);
				})
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.getAdsReq &&
			nextProps.fileUpload.getAdsData
		) {
			nextProps.fileUpload.getAdsReq = false;
			console.log("nextProps.fileUpload.getAdsData:--", nextProps.fileUpload.getAdsData);
			if (nextProps.fileUpload.getAdsData.flag) {
				this.setState({
					adsArr: nextProps.fileUpload.getAdsData.result.data
				}, () => {
					nextProps.fileUpload.getAdsData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.fileUpload.getAdsData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.getUpgradeReq &&
			nextProps.subInfo.getUpgradeData
		) {
			nextProps.subInfo.getUpgradeReq = false;
			console.log("nextProps.subInfo.getUpgradeData:--", nextProps.subInfo.getUpgradeData);
			if (nextProps.subInfo.getUpgradeData.flag) {
				this.setState({
					upgradeArr: nextProps.subInfo.getUpgradeData.result.data,
					upgradePopup: !this.state.upgradePopup
				}, () => {
					nextProps.subInfo.getUpgradeData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.subInfo.getUpgradeData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.rosterPlayerReq &&
			nextProps.subInfo.rosterPlayerData
		) {
			nextProps.subInfo.rosterPlayerReq = false;
			console.log("nextProps.subInfo.rosterPlayerData:--", nextProps.subInfo.rosterPlayerData.result.data);
			if (nextProps.subInfo.rosterPlayerData.flag) {
				let setTeam;
				let rosterData;
				if (localStorage.getItem("rosterId")) {
					rosterData = nextProps.subInfo.rosterPlayerData.result.data.find(e => e._id === localStorage.getItem("rosterId"));
					setTeam = {
						teamLogo: {
							filePath: rosterData.teamLogo ? rosterData.teamLogo : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'
						},
						name: rosterData.rosterName || "",
					}
				}
				this.setState({
					rosterArr: localStorage.getItem("rosterId") ? setTeam : null,
					rosterList: nextProps.subInfo.rosterPlayerData.result.data
				}, () => {
					nextProps.subInfo.rosterPlayerData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.subInfo.rosterPlayerData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.getEventDashReq &&
			nextProps.fileUpload.getEventDashData
		) {
			nextProps.fileUpload.getEventDashReq = false;
			if (nextProps.fileUpload.getEventDashData.flag) {
				console.log("nextProps.fileUpload.getEventDashData:--", nextProps.fileUpload.getEventDashData);
				this.setState({
					eventList: nextProps.fileUpload.getEventDashData.result.docs
				}, () => {
					nextProps.fileUpload.getEventDashData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.fileUpload.getEventDashData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.getMyTeamListReq &&
			nextProps.fileUpload.getMyTeamListData
		) {
			nextProps.fileUpload.getMyTeamListReq = false;
			console.log("nextProps.fileUpload.getMyTeamListData.result.data:-----", nextProps.fileUpload.getMyTeamListData.result.data);
			if (nextProps.fileUpload.getMyTeamListData.flag) {
				if (nextProps.fileUpload.getMyTeamListData.result.data.length !== 0) {
					this.setState({
						myTeamList: nextProps.fileUpload.getMyTeamListData.result.data
					}, () => {
						let team = nextProps.fileUpload.getMyTeamListData.result.data.find(e => e.default === true)
						if (team) {
							this.props.getPlayerRosterList({ parentTeam: team.parentTeam._id });
						}

						nextProps.fileUpload.getMyTeamListData = null;
						if (this.state.reloadData) {
							this.setState({
								reloadData: false
							}, () => {
								window.location.reload();
								this.setLoader(false);
							})
						}
					})
				} else {
					nextProps.fileUpload.getMyTeamListData = null;
				}
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.changeMyTeamListReq &&
			nextProps.fileUpload.changeMyTeamListData
		) {
			console.log("changeMyTeamListData:---", nextProps.fileUpload.changeMyTeamListData);
			nextProps.fileUpload.changeMyTeamListReq = false;
			if (nextProps.fileUpload.changeMyTeamListData.flag) {
				nextProps.fileUpload.changeMyTeamListData = null;
				this.props.getTeamList('Player');
			}
		}


	}

	onHandleUpload = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.onloadend = () => { };
		reader.readAsDataURL(file);

		setTimeout(() => {
			this.setLoader(true);
			this.props.postFileUpload(file, 18);
		}, 1000);
	}

	setLoader = (val) => {
		this.setState({
			loader: val
		})
	}

	leaveTeam = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			leaveTeamPopup: !this.state.leaveTeamPopup,
			reloadData: true
		}, () => {
			if (this.props.fileUpload.getMyTeamListData) {
				this.props.fileUpload.getMyTeamListData = null;
			}
			this.setLoader(true);
			this.props.leaveTeam();
		})
	}

	shareCart = () => {
		let url = "/share-cart/" + this.state.userId;
		window.open(url, '_blank');
	}

	gotoLanding = () => {
		localStorage.setItem("scrollLanding", "");
		this.props.history.push("/ballr");
	}

	getUpgrade = (sid) => {
		this.setState({
			subId: sid
		}, () => {
			this.setLoader(true);
			this.props.upgradeList({ subscription_id: sid }, "Player");
		})
	}

	renderActiveList = () => {
		return (
			this.state.activeList.length !== 0 &&
			this.state.activeList.map((list, i) => {
				return (
					<tr key={i}>
						<th className="title">{list.productId.name} {this.setPlan(list.priceId, list.packageId).type === 1 ? "Monthly" : "Yearly"} Subscription -  {list.packageId && list.packageId.type + " " + list.packageId.role}</th>
						{
							list.stripeLogId &&
							list.stripeLogId.stripeLog &&
							list.stripeLogId.stripeLog.status === "trialing" &&
							list.packageId &&
							list.packageId.trialPeriod > 0 &&
							<td style={{ color: 'red' }}> Free Trial {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_start * 1000).format("DD MMM, YYYY")} - {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_end * 1000).format("DD MMM, YYYY")}</td>
						}
						<td>{(list.isActive && list.isExpire) ? "Good through " : "Renew "} {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
						<td className="status_link">
							{
								list.packageId && list.packageId.role === "Player" &&
								<>
									{
										list.isActive &&
										<Link className="green-status">Active</Link>
									}

									{
										this.setPlan(list.priceId, list.packageId).type === 2 &&
											list.packageId.type === "Gold" ?
											""
											:
											<Link className="blue-status" onClick={() => this.getUpgrade(list._id)}>Upgrade</Link>
									}
								</>
							}
						</td>
						<td className="payment_link">
							{
								list.packageId && list.packageId.role === "Coach" &&
								<span>Assigned by {list.userId.fname + " " + list.userId.lname}</span>
							}

							{/* {
									list.isActive && !list.isExpire &&
									<Link onClick={() => this.cancelSub(list.lastSubStripeId, list.exprireDate)}>Cancel Subscription</Link>
								}
								{
									!list.isActive && list.isExpire &&
									<Link style={{ pointerEvents: 'none', color: "lightgray" }}>Cancel Subscription</Link>
								}
								{
									list.isActive && list.isExpire &&
									<Link style={{ color: "#0cbf3e" }} onClick={() => this.nextPayment(list.packageId)}>Renew Now</Link>
								} */}
						</td>
					</tr>
				);
			})
			// :
			// <p className="text-center mb-0">There is no subscription active yet. <Link onClick={() => this.gotoLanding()}>Click Here</Link> to buy now.</p>
		)
	}

	nextPayment = (data) => {
		let nextData = {
			packid: data && data._id,
			plans: data ? data.plan[0] : "-",
			title: data ? data.role + " - " + data.type + " Package" : "-"
		}
		this.commonCall.setStorage("payPlans", nextData, flag => {
			if (flag) {
				if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
					this.props.history.push('/login');
				} else if (localStorage.getItem("coachToken")) {
					this.props.history.push('/coach-payment');
				} else {
					this.props.history.push('/player-payment');
				}
			}
		});
	}

	setPlan = (typeId, data) => {
		var isPlans = "";
		data && data.plans.length > 0 &&
			data.plans.forEach((data) => {
				if (data.stripePlanId === typeId) return (isPlans = data);
			});
		return isPlans;
	}

	cancelSub = (subId, expiryDate) => {
		this.setState({
			delId: subId,
			deletePopup: true,
			date: expiryDate
		})
	}

	closePopup = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			delId: "",
			deletePopup: false
		})
	}

	leaveTeamConfirm = () => {
		// teamArr ? this.leaveTeam() : ""
		if (this.state.teamArr) {
			this.setState({
				leaveTeamPopup: !this.state.leaveTeamPopup
			})
		}

	}

	confirmPopup = () => {
		this.setLoader(true);
		this.props.deleteSubscription({
			subscription_id: this.state.delId
		}, "Player");
	}

	closeTeam = (type) => {
		document.body.classList.remove('modal-open');
		if (type === "team") {
			this.setState({
				openTeamConfirm: !this.state.openTeamConfirm
			})
		} else if (type === "leaveTeam") {
			this.setState({
				leaveTeamPopup: !this.state.leaveTeamPopup
			})
		} else {
			this.setState({
				invitePopup: !this.state.invitePopup
			})
		}
	}

	checkFlow = () => {
		console.log("this.state.teamArr:--", this.state.teamArr);
		if (this.state.teamArr) {
			if (this.state.invite) {
				this.setState({ openTeamConfirm: !this.state.openTeamConfirm })
			} else {
				this.setState({ invitePopup: !this.state.invitePopup })
			}
		} else {
			this.setState({ openTeamConfirm: !this.state.openTeamConfirm })
		}
	}

	redirectNew = (link) => {
		if (link) {
			window.open(link, '_blank');
		}
	}

	renderAdds = () => {
		return (
			this.state.adsArr.length != 0 ?
				this.state.adsArr.map((list, i) => {
					return (
						<Link className="item" onClick={() => this.redirectNew(list.destination)}>
							{
								list.media.hasOwnProperty('_id') ?
									<img src={list.media.filePath} alt="slide1" />
									:
									<p>Ads</p>
							}
						</Link>
					);
				})
				:
				<Link className="item">
					<p>Ads</p>
				</Link>
		)
	}

	onHandleClose = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			upgradePopup: !this.state.upgradePopup,
			upgradeArr: [],
			subId: ""
		})
	}

	onHandleOk = () => {
		this.setState({
			upgradePopup: !this.state.upgradePopup,
			upgradeArr: [],
			subId: "",
			successPopup: true
		})
	}

	onHandleCloseSuccess = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			successPopup: !this.state.successPopup,
		}, () => {
			this.props.getActiveList(this.state.activeFilter, "Player");
		})
	}

	redirectWebgl = (name, url) => {
		if (name === "BCTeam") {
			Cookies.set('gameType', localStorage.getItem("gameType"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
			localStorage.setItem("webglUrl", url);
			this.props.history.push("/bcteamapp");
		} else if (name === "BallR") {
			localStorage.setItem("webglUrl", url);
			this.props.history.push("/ballrapp");
		} else if (name === "PitchR") {
			localStorage.setItem("webglUrl", url);
			this.props.history.push("/pitchrapp");
		} else {
			localStorage.setItem("webglUrl", url);
			this.props.history.push("/analyzrapp");
		}
	}

	closeImport = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			isImport: false
		})
	}

	nextEditPage = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			isImport: false
		}, () => {
			this.props.history.push("/player-setting")
		})
	}

	closeCmsUser = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			isCmsUser: false
		})
	}

	cmsUserNext = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			isCmsUser: false
		}, () => {
			this.props.history.push("/player-setting")
		})
	}

	handleChange = (e) => {
		const { value } = e.target;
		let rosterTeam = this.state.rosterList.find((r) => r._id === value)
		localStorage.setItem("rosterId", rosterTeam._id);
		this.setState((prevState) => ({
			...prevState,
			rosterGroup: value,
			rosterArr: {
				teamLogo: {
					filePath: rosterTeam.teamLogo ? rosterTeam.teamLogo : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'
				},
				name: rosterTeam.rosterName || "",
				shoplink: rosterTeam.shoplink || "",
				teamRecord: rosterTeam.teamRecord || "",
			},
			sendEvents: {
				...prevState.sendEvents,
				rosterTeamId: value
			},
		}), () => {
			// if (localStorage.getItem("company")) {
			this.props.getEventDash(this.state.sendEvents);
			// }
		})
	}

	redirectPlayerCart = (name) => {
		localStorage.setItem("rostername", name)
		this.props.history.push("/player-cart")
	}


	redirectDetails = (data) => {
		var isloggedIn = checkLoginValidation();
		if (isloggedIn) {
			if (this.state.sendEvents.type === 2) {
				this.setCompany(data);
			} else {
				this.props.history.push(`/event-detail/${data._id}`);
			}
		} else {
			this.props.history.push("/login");
		}
	}

	setCompany = (data) => {
		if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== data.companies._id) {
			let companyname = data.companies.name === "GOBTournament" ? "Greater Orlando Baseball" : data.companies.name;
			let item = commonHeader.events.filter((e) => e.label === companyname)[0];
			if (item.key !== "gob" && item.key !== "utb") {
				localStorage.setItem("cid", data.companies._id)
				localStorage.setItem("company", item.key)
				localStorage.setItem("companyColor", JSON.stringify(item.colors));
				localStorage.setItem("recapInfo", JSON.stringify(data))
				this.props.history.push(`/${localStorage.getItem('company')}-event-info/${data._id}`);
			}
		} else {
			if (localStorage.getItem('company') !== "gob" && localStorage.getItem('company') !== "utb") {
				localStorage.setItem("recapInfo", JSON.stringify(data))
				this.props.history.push(`/${localStorage.getItem('company')}-event-info/${data._id}`);
			}
		}
	}


	changeTeam = (e) => {

		this.setState({
			reloadData: true,
			rosterList: [],
			rosterGroup: "",
			rosterArr: null
		}, () => {
			this.setLoader(true);
			localStorage.removeItem("rosterId")
			this.props.changeMyTeamData({ parentTeam: e.target.value }, "Player")
		})
	}

	eventFilter = (name, value) => {

		this.setState((prevState) => ({
			...prevState,

			sendEvents: {
				...prevState.sendEvents,
				[name]: value
			},
		}), () => {
			// if (localStorage.getItem("company")) {
			this.setLoader(true);
			this.props.getEventDash(this.state.sendEvents);
			// }
		})
	}

	closeApp = () => {
		document.body.classList.remove('modal-open');
		this.setState((prevState) => ({
			...prevState,
			appPopUp: false
		}))
	}


	closeResetPassword = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			resetPassword: false
		})
	}

	render() {
		const { appPopUp, resetPassword, loader, address, isImport, playerName, profileLogo, coachName, teamArr, rosterArr, teamRecord, rosterGroup, file, deletePopup, shareBtn, userId, siteUrl, myTeamList, skipFlg, openTeamConfirm, leaveTeamPopup, invitePopup, invite, upgradePopup, upgradeArr, subId, successPopup, isCmsUser } = this.state

		var settings = {
			dots: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			arrows: true,
		};
		var product = {
			dots: false,
			speed: 500,
			slidesToShow: this.state.productList.length > 4 ? 1 : 4,
			slidesToScroll: 1,
			infinite: false,
			initialSlide: 0,
			arrows: this.state.productList.length > 4 ? true : false,
			autoplay: false,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1445,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: true,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,
						variableWidth: true,
					}
				},
			]
		};
		var ballrslider = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 575,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		return (
			<>
				<Header />
				{/* <CommonHeroBanner dataList="" /> */}
				<section className="playerdashboard ">
					<div className="container">
						{this.state.rosterList.length !== 0 &&
							<div className="mb-15">
								<div className="event-roster-select">
									<label className="common-lable">Choose Roster Group:</label>
									<select className="custom-select" name="rosterGroup" value={rosterGroup} onChange={this.handleChange}>
										<option value="" selected disabled>Choose Roster Group</option>
										{this.state.rosterList.map((l, i) => {
											return (
												<option value={l._id} key={i}>{l.rosterName}</option>
											)
										})}
									</select>
								</div>
							</div>}
						<div className="common-dashbord mt-15">
							<div className="common-div">
								<h3> Your Team </h3>
								{
									teamArr && invite &&
									<>
										<div className="team_logo">
											<img src={teamArr && teamArr.teamLogo ? teamArr.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team_logo" />
										</div>
										<h4 className="p-black">
											{
												myTeamList &&
													myTeamList.length >= 2 ?
													<select className="custom-select" onChange={(e) => this.changeTeam(e)}>
														{
															myTeamList.map((list, i) => {
																return (
																	<option selected={list.default} value={list.parentTeam._id}> {list.parentTeam.name}</option>
																)
															})
														}
													</select>
													:
													teamArr && teamArr.name
											}

										</h4>
										<div className="form-group" data-left="You" data-right="Opp.">
											<input type="text" name="you" value={teamRecord} className="form-control" id="usr" placeholder="00-00" readOnly />
										</div>
									</>

								}
								{
									teamArr && !invite &&
									<h4 className="p-black">
										We are still waiting for Coach {coachName} to approve your roster spot
									</h4>
								}

								{
									!teamArr && invite && !skipFlg &&
									<h4 className="p-black">
										You're not currently rostered on a team
									</h4>
								}

								{
									skipFlg && !teamArr &&
									<h4 className="p-black">
										You're not currently rostered on a team
									</h4>
								}

								<div className="profile-btn btn-group">
									{
										teamArr && invite &&
										<Link className="btn light-green w-100" onClick={() => this.leaveTeamConfirm()} disabled={teamArr ? false : true}>Leave Team</Link>
									}
									{
										!teamArr &&
										<Link className="btn blue_btn w-100" onClick={() => this.checkFlow()}>Join Team</Link>
									}
									{
										teamArr && teamArr.shoplink &&
										<a className="btn blue_btn w-100" href={teamArr.shoplink} target="_blank"><i class="fas fa-shopping-bag"></i>Store</a>
									}
								</div>
							</div>
							<div className="dashbord-center">
								<div className="slider-common">
									<Slider {...settings}>
										{this.renderAdds()}
									</Slider>
								</div>
								<div className="upload_file">
									<Link className="upload_inner" to={"/player-uploadcsv"}>
										<div className="upload_img">
											<img src={imagesArr.white_upload} alt="teamwork" />
										</div>
										<h5>Upload Data</h5>
									</Link>
									<Link className="upload_inner" to={"/player-setting"}>
										<div className="upload_img">
											<img src={imagesArr.edit_profile} alt="edit_profile" />
										</div>
										<h5>Edit Profile</h5>
									</Link>
								</div>
							</div>
							<div className="common-div profile-card">
								<div className="mainprofile">
									<div className="img-profile">
										{
											file ?
												<img src={file} alt="playerprofile" />
												: ""
										}
									</div>
									<div className="dragdrop-img">
										<div className="file_profile_upload text-center">
											<i className="fas fa-camera"></i>
											{/* .heic,.HEIC, */}
											<input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={this.onHandleUpload} />
										</div>
									</div>
									<div className="profiletext">
										<div className="playerprofileicon">
											<img src={rosterArr ? rosterArr.teamLogo ? rosterArr.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png" : teamArr && invite && teamArr.teamLogo ? teamArr.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="profile" />
										</div>
										<div className="profiletitle">
											<h5 className="text-uppercase">{playerName}</h5>
											{
												teamArr && invite &&
												<>
													<span>Team:</span>
													<h6>{rosterArr ? rosterArr.name : teamArr && teamArr.name}</h6>
												</>
											}
										</div>
										{
											teamArr && invite &&
											<p className="playerclip">{address ? address.city + "," + address.state + "," + address.zipcode : ""}</p>
										}
									</div>
									<div className="profile-buttons">
										<Link className="btn light-green" onClick={() => this.redirectPlayerCart(teamArr?.name)}>View</Link>
										<div className={`share-icon ${shareBtn ? 'active' : ''}`}>
											<Link className="btn blue_btn mr-0" onClick={() => this.setState({ shareBtn: !shareBtn })}>Share</Link>
											<ul>
												<li><FacebookShareButton url={`${siteUrl}/share-cart/${userId}`} children={<FacebookIcon size={32} round={true} />} /></li>
												<li> <EmailShareButton url={`${siteUrl}/share-cart/${userId}`} subject="Baseballcloud" children={<EmailIcon size={32} round={true} />} /></li>
												<li><TwitterShareButton url={`${siteUrl}/share-cart/${userId}`} children={<TwitterIcon size={32} round={true} />} /></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="products">
					<div className="container">
						<div className="white-bg">
							<div className="roster-event-tab">
								<h3>My Events</h3>
								<div className="roster-event-tab-info">
									<div className="list-group-scroll">
										<ul className="list-group">
											<li class={`list-group-item ${this.state.sendEvents.type === 2 ? "active" : ""} `} onClick={() => this.eventFilter("type", 2)}>Past</li>
											<li class={`list-group-item ${this.state.sendEvents.type === 1 ? "active" : ""} `} onClick={() => this.eventFilter("type", 1)}>Upcoming</li>
										</ul>
									</div>
									<div className="list-group-scroll">
										<ul className="list-group">
											<li class={`list-group-item ${this.state.sendEvents.eventTeamType === 1 ? "active" : ""} `} onClick={() => this.eventFilter("eventTeamType", 1)}>Tournament</li>
											<li class={`list-group-item ${this.state.sendEvents.eventTeamType === 2 ? "active" : ""} `} onClick={() => this.eventFilter("eventTeamType", 2)} >{localStorage.getItem("company") === "pws" ? "Camps" : "Showcase"} </li>
										</ul>
									</div>
								</div>
							</div>
							<div className="event_slider">
								<div className="all_event">
									<EventSlider
										eventList={this.state.eventList}
										redirectDetails={this.redirectDetails}
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				{this.state.productList.length !== 0 &&
					<section className="products">
						<div className="container">
							<div className="white-bg">
								<h3> My Products</h3>
								<div className="produc-common">
									<Slider {...product}>
										{this.state.productList.length !== 0 &&
											this.state.productList.sort((a, b) => (a.productId.name > b.productId.name ? 1 : -1)).map((e, item) => {
												return (
													<div className="item" key={item}>
														<div className="items">
															<div className="team_img">
																{
																	e.productId.name !== "BCTeam" &&
																	<img src={e.productId.logo !== null ? e.productId.logo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="ballrteam" />
																}
																{
																	e.productId.name === "BCTeam" &&
																	<img src={teamArr.coach && teamArr.coach.profile["game-type"] === "2" ?
																		e?.productId?.secondary_logo ? e.productId.secondary_logo.filePath : imagesArr.default_user : e.productId.logo !== null ?
																			e.productId.logo.filePath : imagesArr.default_user} alt="ballrteam" />
																}
																<span>{e.productId.name}</span>
															</div>
															<div className="team_dec">
																<div className="team_name">
																	<h6>{e.productId.name === "BCTeam" && teamArr.coach && teamArr.coach.profile["game-type"] === "2" ? "SCTeam" : e.productId.name}</h6>
																</div>
																{/* <span>{e.role === "Coach" ? "" : "Expires on " + e.stripeLogId.hasOwnProperty('stripeLog') ? moment(e.stripeLogId && e.stripeLogId.stripeLog && e.stripeLogId.stripeLog.current_period_end * 1000).format("LL") : ""}</span> */}
																<Link className="btn light-green w-100" onClick={() => this.redirectWebgl(e.productId.name, e.productId.url)}>Open</Link>
															</div>
														</div>
													</div>
												)
											})
										}
									</Slider>
									{this.state.productList.length === 0 &&
										<p className="text-center mb-0">You haven't subscribed to any plan yet!</p>
									}
								</div>
							</div>
						</div>
					</section>}
				{this.state.activeList.length !== 0 && <section className="products">
					<div className="container">
						<div className="white-bg">
							<h3>Active subscription</h3>
							<p className="mb-0">Your Active Subscription</p>
							<div className="table-responsive-xl">
								<table className="table">
									<tbody>
										{this.renderActiveList()}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>}
				<Footer />
				<Confirmation
					flag={deletePopup}
					type="subcription"
					close={this.closePopup}
					confirm={this.confirmPopup}
					date={this.state.date}
				/>
				<LeaveTeam
					flag={leaveTeamPopup}
					close={this.closeTeam}
					confirm={this.leaveTeam}
				/>
				<QuiteTeam
					flag={openTeamConfirm}
					close={this.closeTeam}
				/>
				<RequestTeam
					flag={invitePopup}
					close={this.closeTeam}
				/>
				{
					upgradePopup &&
					<UpgradeSubscribe
						flag={upgradePopup}
						data={upgradeArr}
						close={this.onHandleClose}
						sid={subId}
						ok={this.onHandleOk}
						type="Player"
					/>
				}
				{
					successPopup &&
					<SuccessUpgrade
						flag={successPopup}
						close={this.onHandleCloseSuccess}
					/>
				}
				{
					isImport &&
					<Confirmation
						flag={isImport}
						type="isImport"
						close={this.closeImport}
						confirm={this.nextEditPage}
					/>
				}
				{
					isCmsUser &&
					<Confirmation
						flag={isCmsUser}
						type="isCmsUser"
						name={playerName}
						close={this.closeCmsUser}
						confirm={this.cmsUserNext}
					/>
				}
				{appPopUp && <DownloadApp
					flag={appPopUp}
					close={this.closeApp}
				/>}
				{
					resetPassword &&
					<ResetPassword
						flag={resetPassword}
						close={this.closeResetPassword}
						type="Player"
					/>
				}
				<Preloader flag={loader} />
			</>
		);
	}
}


playerDashboard.propTypes = {
	postFileUpload: PropTypes.func.isRequired,
	saveBGphoto: PropTypes.func.isRequired,
	leaveTeam: PropTypes.func.isRequired,
	getPlayerProfile: PropTypes.func.isRequired,
	getActiveList: PropTypes.func.isRequired,
	deleteSubscription: PropTypes.func.isRequired,
	getProductList: PropTypes.func.isRequired,
	getAdverticement: PropTypes.func.isRequired,
	upgradeList: PropTypes.func.isRequired,
	getPlayerRosterList: PropTypes.func.isRequired,
	getEventDash: PropTypes.func.isRequired,
	getTeamList: PropTypes.func.isRequired,
	changeMyTeamData: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
	profileDetails: state.headerData,
	fileUpload: state.api,
	dashboardDetails: state.dashboardInfo,
	subInfo: state.subscriptionInfo,
	login: state.loginData,
});

export default connect(mapStateToProps, { postFileUpload, saveBGphoto, leaveTeam, getPlayerProfile, getActiveList, deleteSubscription, getEventDash, getProductList, getAdverticement, upgradeList, getPlayerRosterList, changeMyTeamData, getTeamList })(playerDashboard);

import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { imagesArr } from "../../assets/images";
import commonCalls from "../../businesslogic/commonCalls";
import Preloader from '../../components/preloader';
import { AddPlayer } from "../../saga/actions/authantication/signup/fan/parent.action";
import { saveLinkPlayer } from "../../saga/actions/views/coach/profile/linkedPlayerInfo.action";
import { saveParent } from "../../saga/actions/views/player/profile/parentInfo.action";
import { ADD_LINKED_PLAYER_ERROR, ADD_LINKED_PLAYER_SUCCESS } from "../../saga/constants/view/coach/profile/linkedPlayerInfo.const";
import { ADD_PARENT_ERROR, ADD_PARENT_SUCCESS } from "../../saga/constants/view/player/profile/parentInfo.const";
import { getEventDetails } from "../../saga/actions/common/eventDetails.action";

const initialState = {
  fname: "",
  lname: "",
  email: "",
  recruiting: "",
  loader: false
};

const reducer = (state, payload) => ({ ...state, ...payload });

function AddLinkedPlayer({ flag, close, setLoader, type, openChild }) {
  const [player, setPlayer] = useReducer(reducer, initialState);
  const { fname, lname, email, loader, recruiting } = player;
  const commonCall = new commonCalls();
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { savePlayer: _SAVE, savePlayerError: _ERROR } = useSelector((state) => state.linkedPlayerInfo);
  const { saveParent: _PARENT, saveParentError: _PARENTERROR } = useSelector((state) => state.parentInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (flag) {
      document.body.classList.add("modal-open");
    }
  }, []);

  useEffect(() => {
    if (_SAVE) {
      if (_SAVE.flag) {
        commonCall.successMessage(_SAVE.message);
        setLoader(false);
        close();
      } else {
        commonCall.errorMessage(_SAVE.message)
        setLoader(false);
        dispatch({
          type: ADD_LINKED_PLAYER_SUCCESS,
          payload: null
        })
      }
    }
    if (_ERROR) {
      commonCall.errorMessage(_ERROR.message)
      setLoader(false);
      dispatch({ type: ADD_LINKED_PLAYER_ERROR, error: null })
    }

    if (_PARENT) {
      if (_PARENT.flag) {
        commonCall.successMessage(_PARENT.message);
        setLoader(false);
        close();
      } else {
        commonCall.errorMessage(_PARENT.message)
        setLoader(false);
        dispatch({
          type: ADD_PARENT_SUCCESS,
          payload: null
        })
      }
    }
    if (_PARENTERROR) {
      commonCall.errorMessage(_PARENTERROR.message)
      setLoader(false);
      dispatch({ type: ADD_PARENT_ERROR, error: null })
    }
  }, [_SAVE, _ERROR, _PARENT, _PARENTERROR]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlayer({
      [name]: value
    })
  }

  const onSave = async () => {
    if (validator.current.allValid()) {
      if (type === "Fan" && recruiting) {
        let req = {
          fname: fname,
          lname: lname,
          email: email,
          recruiting: "yes"
        }
        openChild(req);
      } else {
        let req = {
          fname: fname,
          lname: lname,
          email: email,
          recruiting: "no"
        }
        if (type === "Player") return saveParent(req)(dispatch); // add parent info inside player profile edit
        if (type === "Fan") return AddPlayer(req, 'LINKED')(dispatch);    //add player inside fan sign up flow (parent tab)
        else {
          let url = new URL(document.location);
          let eventId = url.pathname.split("/")[2];
            await saveLinkPlayer(req, localStorage.getItem("coachToken") !== null ? "Coach" : "Fan")(dispatch);  // linked player by coach and fan (profile edit)
            await getEventDetails(eventId,true)(dispatch)
        } 
      }
    }
    else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  }

  return (
    <>
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>{type === "Player" ? "Parent" : "Player"}</h3>
              <div className="modal_close" onClick={close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="team-body-inner">

                <div className="form-group mb-0">
                  <label className="common-lable">First Name</label>
                  <input
                    type="text"
                    name="fname"
                    value={fname}
                    onChange={handleChange}
                    className="form-control"
                    id="usr"
                    placeholder="First Name"
                  />
                  <span className="validMsg">
                    {validator.current.message("first name", fname, "required")}
                  </span>
                </div>
                <div className="form-group mb-0">
                  <label className="common-lable">Last Name</label>
                  <input
                    type="text"
                    name="lname"
                    value={lname}
                    onChange={handleChange}
                    className="form-control"
                    id="usr"
                    placeholder="Last Name"
                  />
                  <span className="validMsg">
                    {validator.current.message("last name", lname, "required")}
                  </span>
                </div>


                <div className="form-group mb-0">
                  <label className="common-lable">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className="form-control"
                    id="usr"
                    placeholder="Enter email address"
                  />
                  <span className="validMsg">
                    {validator.current.message("email Address", email, "required|email")}
                  </span>
                </div>
                {
                  type === "Fan" &&
                  <div className="form-group mb-0">
                    <label className="common-lable">Does {fname ? fname : "Player"} want to compete at the college level?</label>
                    <div className="custom-checkbox pt-0">
                      <label>
                        <input type="checkbox" name="recruiting" onClick={() => setPlayer({ recruiting: true })} checked={recruiting === true ? true : false} />Yes, my child wants to play in college!
                        <span className="checkmark"></span>
                      </label>
                      <div className="custom-checkbox pt-10">
                        <label>
                          <input type="checkbox" name="recruiting" onClick={() => setPlayer({ recruiting: false })} checked={recruiting === false ? true : false} />No thanks.
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <span className="validMsg">
                      {validator.current.message("college level", recruiting, "required")}
                    </span>
                  </div>
                }
              </div>
              <div className="flex-align next-button">
                <Link className="btn light-green" onClick={onSave}>
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Preloader flag={loader} />
    </>
  );
}

export default AddLinkedPlayer;

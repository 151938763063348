export const GET_HEAD_PROFILE_REQUEST = 'GET_HEAD_PROFILE_REQUEST';
export const GET_HEAD_PROFILE_SUCCESS = 'GET_HEAD_PROFILE_SUCCESS';
export const GET_HEAD_PROFILE_ERROR = 'GET_HEAD_PROFILE_ERROR';

export const GET_HEAD_PLAYER_PROFILE_REQUEST = 'GET_HEAD_PLAYER_PROFILE_REQUEST';
export const GET_HEAD_PLAYER_PROFILE_SUCCESS = 'GET_HEAD_PLAYER_PROFILE_SUCCESS';
export const GET_HEAD_PLAYER_PROFILE_ERROR = 'GET_HEAD_PLAYER_PROFILE_ERROR';

export const GET_HEAD_FAN_PROFILE_REQUEST = 'GET_HEAD_FAN_PROFILE_REQUEST';
export const GET_HEAD_FAN_PROFILE_SUCCESS = 'GET_HEAD_FAN_PROFILE_SUCCESS';
export const GET_HEAD_FAN_PROFILE_ERROR = 'GET_HEAD_FAN_PROFILE_ERROR';

export const GET_HEAD_COMMI_PROFILE_REQUEST = 'GET_HEAD_COMMI_PROFILE_REQUEST';
export const GET_HEAD_COMMI_PROFILE_SUCCESS = 'GET_HEAD_COMMI_PROFILE_SUCCESS';
export const GET_HEAD_COMMI_PROFILE_ERROR = 'GET_HEAD_COMMI_PROFILE_ERROR';

export const GET_DOB_PLAYER_PROFILE_REQUEST = 'GET_DOB_PLAYER_PROFILE_REQUEST';
export const GET_DOB_PLAYER_PROFILE_SUCCESS = 'GET_DOB_PLAYER_PROFILE_SUCCESS';
export const GET_DOB_PLAYER_PROFILE_ERROR = 'GET_DOB_PLAYER_PROFILE_ERROR';

export const GET_CHANGE_PASSWORD_REQUEST = 'GET_CHANGE_PASSWORD_REQUEST';
export const GET_CHANGE_PASSWORD_SUCCESS = 'GET_CHANGE_PASSWORD_SUCCESS';
export const GET_CHANGE_PASSWORD_ERROR = 'GET_CHANGE_PASSWORD_ERROR';

export const GET_RESET_PASSWORD_REQUEST = 'GET_RESET_PASSWORD_REQUEST';
export const GET_RESET_PASSWORD_SUCCESS = 'GET_RESET_PASSWORD_SUCCESS';
export const GET_RESET_PASSWORD_ERROR = 'GET_RESET_PASSWORD_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
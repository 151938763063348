import axios from 'axios';
import { Auth_Coach_Key, Auth_Player_Key, API_Url, Authorization, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidFanToken } from '../../helpers/authToken';
import {
    SAVE_EVENT_REQUEST,
    SAVE_EVENT_SUCCESS,
    SAVE_EVENT_ERROR,

    SAVE_SOURCE_REQUEST,
    SAVE_SOURCE_SUCCESS,
    SAVE_SOURCE_ERROR,

    SAVE_RAPSODO_SOURCE_REQUEST,
    SAVE_RAPSODO_SOURCE_SUCCESS,
    SAVE_RAPSODO_SOURCE_ERROR,

    GET_LINK_REQUEST,
    GET_LINK_SUCCESS,
    GET_LINK_ERROR,

    GET_OPTION_REQUEST,
    GET_OPTION_SUCCESS,
    GET_OPTION_ERROR,

    GET_RMAP_REQUEST,
    GET_RMAP_SUCCESS,
    GET_RMAP_ERROR,

    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,

    GET_DEVICE_REQUEST,
    GET_DEVICE_SUCCESS,
    GET_DEVICE_ERROR,

    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_ERROR,

    ROW_DEVICE_DATA_SUCCESS,
    ROW_DEVICE_DATA_REQUEST,
    ROW_DEVICE_DATA_ERROR,

    GET_VIDEO_SOURCE_SUCCESS,
    GET_VIDEO_SOURCE_REQUEST,
    GET_VIDEO_SOURCE_ERROR,

    SAVE_VIDEO_SOURCE_SUCCESS,
    SAVE_VIDEO_SOURCE_REQUEST,
    SAVE_VIDEO_SOURCE_ERROR,

    IMPORT_VIDEO_SOURCE_SUCCESS,
    IMPORT_VIDEO_SOURCE_REQUEST,
    IMPORT_VIDEO_SOURCE_ERROR,

    TABLE_VIDEO_SOURCE_SUCCESS,
    TABLE_VIDEO_SOURCE_REQUEST,
    TABLE_VIDEO_SOURCE_ERROR,

    ATTACH_VIDEO_SOURCE_SUCCESS,
    ATTACH_VIDEO_SOURCE_REQUEST,
    ATTACH_VIDEO_SOURCE_ERROR,

    DEVICE_DATA_ROW_REQUEST,
    DEVICE_DATA_ROW_SUCCESS,
    DEVICE_DATA_ROW_ERROR,

    GET_RAPSODO_OPTION_REQUEST,
    GET_RAPSODO_OPTION_SUCCESS,
    GET_RAPSODO_OPTION_ERROR,

    MAP_RAPSODO_REQUEST,
    MAP_RAPSODO_SUCCESS,
    MAP_RAPSODO_ERROR,

    TEAM_SOURCE_DETAILS_REQUEST,
    TEAM_SOURCE_DETAILS_SUCCESS,
    TEAM_SOURCE_DETAILS_ERROR,

    TEAM_SOURCE_MAP_REQUEST,
    TEAM_SOURCE_MAP_SUCCESS,
    TEAM_SOURCE_MAP_ERROR,

    DK_AUTHANTICATION_REQUEST,
    DK_AUTHANTICATION_SUCCESS,
    DK_AUTHANTICATION_ERROR,

    GET_DK_AUTHANTICATION_REQUEST,
    GET_DK_AUTHANTICATION_SUCCESS,
    GET_DK_AUTHANTICATION_ERROR,

    GET_DK_DISCONNECT_REQUEST,
    GET_DK_DISCONNECT_SUCCESS,
    GET_DK_DISCONNECT_ERROR,

    DEL_SELL_VIDEO_REQUEST,
    DEL_SELL_VIDEO_SUCCESS,
    DEL_SELL_VIDEO_ERROR,

    GET_TEAM_LINK_LIST_REQUEST,
    GET_TEAM_LINK_LIST_SUCCESS,
    GET_TEAM_LINK_LIST_ERROR,

    TEAM_LINK_REQUEST,
    TEAM_LINK_SUCCESS,
    TEAM_LINK_ERROR
} from '../../constants/common/uploadcsv.const';
import histrory from '../../../History';

export const deleteSellVideo = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: DEL_SELL_VIDEO_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/video/detach`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(delVideo => {
        console.log("delVideo:--", delVideo);
        dispatch({
            type: DEL_SELL_VIDEO_SUCCESS,
            payload: delVideo.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: DEL_SELL_VIDEO_ERROR, error: error.response.data.message })
        }
    });
};

export const createEvent = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: SAVE_EVENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/event`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(saveEvent => {
        console.log("saveEvent:--", saveEvent);
        dispatch({
            type: SAVE_EVENT_SUCCESS,
            payload: saveEvent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_EVENT_ERROR, error: error.response.data.message })
        }
    });
};

export const saveSource = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: SAVE_SOURCE_REQUEST })

    let formData = new FormData();
    formData.append('file_type', data.file_type);
    formData.append('event', data.event);

    if (data.file_type === "FlightScope" ||
        data.file_type === "TrackMan" ||
        data.file_type === "Yakkertech" ||
        data.file_type === "Blast" ||
        data.file_type === "TrackMan Portable Hitting" ||
        data.file_type === "TrackMan Portable Pitching" ||
        data.file_type === "Hittrax" ||
        data.file_type === "Savant Hitting" ||
        data.file_type === "Savant Pitching" ||
        data.file_type === "Pocket Radar" ||
        data.file_type === "Stalker Radar") {
        let tempArr = [];
        data.file_path.map((item, i) => {
            tempArr.push(formData.append(`file_path`, item));
        })
        data.file_path = tempArr;
        console.log("data:---", data);
        // formData.append('file_path', data.file_path);
    }

    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source`,
        data: formData,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(source => {
        console.log("source:--", source);
        dispatch({
            type: SAVE_SOURCE_SUCCESS,
            payload: source.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const saveRapsodoSource = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: SAVE_RAPSODO_SOURCE_REQUEST })

    let formData = new FormData();
    formData.append('file_type', data.file_type + " " + data.file_subtype);
    formData.append('event', data.event);
    let tempArr = [];
    data.file_path.map((item, i) => {
        tempArr.push(formData.append(`file_path`, item));
    })
    data.file_path = tempArr;

    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source`,
        data: formData,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(source => {
        console.log("source:--", source);
        dispatch({
            type: SAVE_RAPSODO_SOURCE_SUCCESS,
            payload: source.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_RAPSODO_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const getLink = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_LINK_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/roster/list`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(linkdata => {
        console.log("linkdata:--", linkdata);
        dispatch({
            type: GET_LINK_SUCCESS,
            payload: linkdata.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LINK_ERROR, error: error.response.data.message })
        }
    });
};

export const getOption = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_OPTION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/bcuser/search`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(option => {
        dispatch({
            type: GET_OPTION_SUCCESS,
            payload: option.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_OPTION_ERROR, error: error.response.data.message })
        }
    });
};

export const getRapsodoOption = (id, userType, next, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_RAPSODO_OPTION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/data/${id}`,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(option => {
        dispatch({
            type: GET_RAPSODO_OPTION_SUCCESS,
            payload: option.data
        })
        next(option.data);
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_RAPSODO_OPTION_ERROR, error: error.response.data.message })
        }
    });
};

export const mappingRapsodo = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: MAP_RAPSODO_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/rapsodo/attach`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(mapping => {
        dispatch({
            type: MAP_RAPSODO_SUCCESS,
            payload: mapping.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: MAP_RAPSODO_ERROR, error: error.response.data.message })
        }
    });
};

export const setRosterMap = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_RMAP_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/roster/map`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(rmap => {
        console.log("rmap:--", rmap);
        dispatch({
            type: GET_RMAP_SUCCESS,
            payload: rmap.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_RMAP_ERROR, error: error.response.data.message })
        }
    });
};

export const getEvent = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_EVENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/event/list`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(getevent => {
        console.log("getevent:--", getevent);
        dispatch({
            type: GET_EVENT_SUCCESS,
            payload: getevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_EVENT_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteEvent = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: DELETE_EVENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/event/delete`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        console.log("delete Event:--", res);
        dispatch({
            type: DELETE_EVENT_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DELETE_EVENT_ERROR, error: error.response.data })
        }
    });
};

export const getDevice = (id, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_DEVICE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/import/event/${id}`,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(getdevice => {
        dispatch({
            type: GET_DEVICE_SUCCESS,
            payload: getdevice.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_DEVICE_ERROR, error: error.response.data.message })
        }
    });
};

export const getDeviceRowsData = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: ROW_DEVICE_DATA_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/data`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(getrows => {
        console.log("getrows:--", getrows);
        dispatch({
            type: ROW_DEVICE_DATA_SUCCESS,
            payload: getrows.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ROW_DEVICE_DATA_ERROR, error: error.response.data.message })
        }
    });
};

export const getVideoSource = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_VIDEO_SOURCE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/list`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(source => {
        dispatch({
            type: GET_VIDEO_SOURCE_SUCCESS,
            payload: source.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_VIDEO_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const saveVideoSource = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: SAVE_VIDEO_SOURCE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/video/add`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(save_source => {
        dispatch({
            type: SAVE_VIDEO_SOURCE_SUCCESS,
            payload: save_source.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_VIDEO_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const importSourceVideo = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: IMPORT_VIDEO_SOURCE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/video/add`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(import_source => {
        dispatch({
            type: IMPORT_VIDEO_SOURCE_SUCCESS,
            payload: import_source.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: IMPORT_VIDEO_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const getTableData = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: TABLE_VIDEO_SOURCE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/video/mapping/list`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(tableData => {
        dispatch({
            type: TABLE_VIDEO_SOURCE_SUCCESS,
            payload: tableData.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: TABLE_VIDEO_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const attachVideo = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: ATTACH_VIDEO_SOURCE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/video/attach`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(attachTable => {
        dispatch({
            type: ATTACH_VIDEO_SOURCE_SUCCESS,
            payload: attachTable.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ATTACH_VIDEO_SOURCE_ERROR, error: error.response.data.message })
        }
    });
};

export const updateDeviceRow = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: DEVICE_DATA_ROW_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/devicedata/update`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(rowData => {
        dispatch({
            type: DEVICE_DATA_ROW_SUCCESS,
            payload: rowData.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DEVICE_DATA_ROW_ERROR, error: error.response.data.message })
        }
    });
};

export const sourceTeamDetails = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: TEAM_SOURCE_DETAILS_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/team/detail`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(sournceTeam => {
        dispatch({
            type: TEAM_SOURCE_DETAILS_SUCCESS,
            payload: sournceTeam.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: TEAM_SOURCE_DETAILS_ERROR, error: error.response.data.message })
        }
    });
};

export const sourceTeamMap = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: TEAM_SOURCE_MAP_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/source/team/map`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(teamMapping => {
        dispatch({
            type: TEAM_SOURCE_MAP_SUCCESS,
            payload: teamMapping.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: TEAM_SOURCE_MAP_ERROR, error: error.response.data.message })
        }
    });
};

export const DKAuthantication = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: DK_AUTHANTICATION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/add/dkcredentials`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(dkAuth => {
        dispatch({
            type: DK_AUTHANTICATION_SUCCESS,
            payload: dkAuth.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DK_AUTHANTICATION_ERROR, error: error.response.data.message })
        }
    });
};

export const getDKAuthantication = (userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_DK_AUTHANTICATION_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/import/dkcredentials/list`,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(getDKAuth => {
        dispatch({
            type: GET_DK_AUTHANTICATION_SUCCESS,
            payload: getDKAuth.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_DK_AUTHANTICATION_ERROR, error: error.response.data.message })
        }
    });
};

export const getDKDisconnectAccount = (userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_DK_DISCONNECT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/dkcredentials/disconnect`,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(disconnect => {
        dispatch({
            type: GET_DK_DISCONNECT_SUCCESS,
            payload: disconnect.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_DK_DISCONNECT_ERROR, error: error.response.data.message })
        }
    });
};

export const getTeamLinkList = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_TEAM_LINK_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/bcuser/team/search`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        dispatch({
            type: GET_TEAM_LINK_LIST_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_TEAM_LINK_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const scoutingLinkTeam = (data, userType, token) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: TEAM_LINK_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/import/team/profile-link`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        dispatch({
            type: TEAM_LINK_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: TEAM_LINK_ERROR, error: error.response.data.message })
        }
    });
};
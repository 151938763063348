
export const RECEIVE_BILLING_REQUEST = 'RECEIVE_BILLING_REQUEST';
export const RECEIVE_BILLING_SUCCESS = 'RECEIVE_BILLING_SUCCESS';
export const RECEIVE_BILLING_ERROR   = 'RECEIVE_BILLING_ERROR';

export const RECEIVE_PAYMENT_REQUEST = 'RECEIVE_PAYMENT_REQUEST';
export const RECEIVE_PAYMENT_SUCCESS = 'RECEIVE_PAYMENT_SUCCESS';
export const RECEIVE_PAYMENT_ERROR   = 'RECEIVE_PAYMENT_ERROR';

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_ERROR   = 'ADD_CARD_ERROR';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_ERROR   = 'DELETE_CARD_ERROR';

export const CHECK_CARD_TYPE_REQUEST = 'CHECK_CARD_TYPE_REQUEST';
export const CHECK_CARD_TYPE_SUCCESS = 'CHECK_CARD_TYPE_SUCCESS';
export const CHECK_CARD_TYPE_ERROR   = 'CHECK_CARD_TYPE_ERROR';

export const MAKE_DEFAULT_CARD_REQUEST = 'MAKE_DEFAULT_CARD_REQUEST';
export const MAKE_DEFAULT_CARD_SUCCESS = 'MAKE_DEFAULT_CARD_SUCCESS';
export const MAKE_DEFAULT_CARD_ERROR   = 'MAKE_DEFAULT_CARD_ERROR';

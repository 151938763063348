import React, { Component } from 'react';
import { imagesArr } from "../../../../assets/images";
import { Link } from 'react-router-dom';
import StripePayment from "./Stripe";

export default class creditCard extends Component {

    componentDidMount = () => {
        if (this.props.flag) {
            document.body.classList.add("modal-open");
        }
    }

    render() {
        const { flag } = this.props;
        return (
            <section className={`common-modal ${flag ? 'show' : ''}`}>
                <div className="modal-dialog creditcard-popup">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal_close" onClick={this.props.close}><img src={imagesArr.gray_modal_close} alt="modal_close" /></div>
                        </div>
                        <div className="stripe_payment">
                            <StripePayment
                                amount={this.props.amount}
                                onPaymentSuccess={this.props.stripePaymentSuccess}
                                onPaymentError={this.props.stripePaymentError}
                                setLoader={this.props.setLoader}
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

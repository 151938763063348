import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import PersonalInfo from "../../company/components/common/personalInfo";
import Summary from "./summary/summary";
import Events from "./summary/events";
import Stats from "./summary/stats";
import Awards from "./summary/awards";
import Media from "./summary/media";
import Gallery from "./summary/gallery";
import InfoContact from "./summary/infoContact";
import { Link } from 'react-router-dom';
import { imagesArr } from '../../../assets/images';
import Preloader from '../../../components/preloader';
import { getSummaryPlayerProfile, getUserIDResponse, getProfileFields } from '../../../saga/actions/views/company/pwRanking/profileSummary.action';
import moment from "moment";
import commonCalls from "../../../businesslogic/commonCalls";
import ReactTooltip from 'react-tooltip';
import Purchase from '../../popup/purchaseProspectwire';

class profileSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0,
            summaryData: [],
            battingStats: [],
            pitchingStats: [],
            purchase: false,
            loginUid: "",
            getAllFields: [],
            newBackup: 0,
            fackVar: false,
            statsType:"Hitting"
        }
        this.commonCall = new commonCalls();
        this.eventInfo = null;
        this.mediaInfo = null;
        this.contactInfo = null;
        this.personalInfo = null;
    }

    componentDidMount = () => {
        // localStorage.removeItem("recapInfo");
        this.getSummaryList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileInfo &&
            nextProps.profileInfo.getPlayerProfileReq &&
            nextProps.profileInfo.getPlayerProfileData
        ) {
            nextProps.profileInfo.getPlayerProfileReq = false;
            console.log(" nextProps.profileInfo.getPlayerProfileData:--", nextProps.profileInfo.getPlayerProfileData);
            if (nextProps.profileInfo.getPlayerProfileData.flag) {
                nextProps.profileInfo.tabs = nextProps.profileInfo.backupTab;

                this.setState({
                    summaryData: nextProps.profileInfo.getPlayerProfileData.result,
                }, () => {
                    if (this.props.profileInfo.tabs === 0) {
                        this.setState({
                            battingStats: this.state.summaryData['batting-stats'],
                            pitchingStats: this.state.summaryData['pitching-stats'],
                        })
                    }
                    if (this.props.profileInfo.tabs === 1) {
                        this.eventInfo.getSummary(this.state.summaryData);
                    }
                    if (this.props.profileInfo.tabs === 2 && this.state.newBackup === 0) {
                        this.setState({
                            newBackup: 2
                        }, () => {
                            if (this.props.profileInfo.sendSummaryReq.field !== "") {
                                console.log("this.props.profileInfo.sendSummaryReq.field:==-", this.props.profileInfo.sendSummaryReq.field);
                                console.log("getAllFields:---", this.props.profileInfo.getBackSummaryFields);
                                this.setState({
                                    getAllFields: this.props.profileInfo.getBackSummaryFields
                                })
                                this.getSummaryList();
                            } else {
                                this.increment();
                                this.props.getProfileFields({
                                    metricType: this.props.profileInfo.sendSummaryReq.metricType,
                                    metricSubType: this.props.profileInfo.sendSummaryReq.metricSubType,
                                    companyId: localStorage.getItem("cid")
                                });
                            }

                        })
                    }
                    if (this.props.profileInfo.tabs === 4) {
                        this.mediaInfo.getSummary(this.state.summaryData);
                    }
                    if (this.props.profileInfo.tabs === 6) {
                        this.contactInfo.getContact(this.state.summaryData)
                    }
                    nextProps.profileInfo.getPlayerProfileData = null;
                    if (this.props.profileInfo.tabs === 0 && localStorage.getItem("webglToken")) {
                        this.props.getUserIDResponse();
                    } else {
                        this.decrement();
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.profileInfo.getPlayerProfileData.message);
                nextProps.profileInfo.getPlayerProfileData = null;
                this.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.getUserIDResReq &&
            nextProps.profileInfo.getUserIDResData
        ) {
            nextProps.profileInfo.getUserIDResReq = false;
            if (nextProps.profileInfo.getUserIDResData.flag) {
                this.setState({
                    loginUid: nextProps.profileInfo.getUserIDResData.result._id
                }, () => {
                    nextProps.profileInfo.getUserIDResData = null;
                    this.decrement();
                })
            } else {
                nextProps.profileInfo.getUserIDResData = null;
                this.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.profileFieldsReq &&
            nextProps.profileInfo.profileFieldsData
        ) {
            nextProps.profileInfo.profileFieldsReq = false;
            if (nextProps.profileInfo.profileFieldsData.flag) {
                let result = nextProps.profileInfo.profileFieldsData.result.data;
                nextProps.profileInfo.getBackSummaryFields = result;
                this.setState({
                    getAllFields: result,
                }, () => {
                    this.props.profileInfo.sendSummaryReq.field = result[0];
                    nextProps.profileInfo.profileFieldsData = null;
                    this.decrement();
                    this.getSummaryList();
                })
            } else {
                this.commonCall.errorMessage(nextProps.profileInfo.profileFieldsData.message);
                nextProps.profileInfo.profileFieldsData = null;
                this.decrement();
            }
        }
    }

    getSummaryList = () => {
        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];
        this.props.profileInfo.sendSummaryReq.id = pid;
        this.setState((prevState) => ({
            ...prevState,
        }), () => {
            let newData = JSON.parse(JSON.stringify(this.props.profileInfo.sendSummaryReq));
            if (newData.tab === "event-report") {
                newData.sortOn.eventstartDate = moment.utc(moment(newData.sortOn.eventstartDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
                newData.sortOn.eventendDate = moment.utc(moment(newData.sortOn.eventendDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
            } else if (newData.tab === "awards") {
                newData.sortOn.awardstartDate = moment.utc(moment(newData.sortOn.awardstartDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
                newData.sortOn.awardendDate = moment.utc(moment(newData.sortOn.awardendDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
            } else if (newData.tab === "gallery") {
                newData.sortOn.gallerystartDate = moment.utc(moment(newData.sortOn.gallerystartDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
                newData.sortOn.galleryendDate = moment.utc(moment(newData.sortOn.galleryendDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
            }
            this.increment();
            this.props.getSummaryPlayerProfile(newData);
        });
    }


    handleTabs = (e, tab) => {
        this.props.profileInfo.backupTab = e;
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            tab: tab,
        };
        this.setState({
            newBackup: e
        }, () => {
            if (tab === "stats") {
                if (this.props.profileInfo.sendSummaryReq.field !== "") {
                    console.log("this.props.profileInfo.sendSummaryReq.field:==-", this.props.profileInfo.sendSummaryReq.field);
                    this.getSummaryList();
                } else {
                    this.increment();
                    this.props.getProfileFields({
                        metricType: this.props.profileInfo.sendSummaryReq.metricType,
                        metricSubType: this.props.profileInfo.sendSummaryReq.metricSubType,
                        companyId: localStorage.getItem("cid")
                    });
                }
            } else {
                this.getSummaryList();
            }
        })
    }

    onHandleSubField = (name, value) => {
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            [name]: value
        }
        this.getSummaryList();
    }

    onHandleTeamType = (e) => {
        const { name, value } = e.target;
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            [name]: this.props.profileInfo.sendSummaryReq.eventTeamType === 1 ? "" : JSON.parse(value)
        }
        this.getSummaryList();
    }

    onHandleShowcaseType = (e) => {
        const { name, value } = e.target;
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            [name]: this.props.profileInfo.sendSummaryReq.eventTeamType === 2 ? "" : JSON.parse(value),
        }
        this.getSummaryList();
    }

    onHandleChange = (name, value) => {
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            [name]: JSON.parse(value)
        }
        this.getSummaryList();
    }

    onChangeDate = (value, name, tab) => {
        if (tab === "eventTab") {
            if (name === "eventstartDate") {
                this.props.profileInfo.sendSummaryReq = {
                    ...this.props.profileInfo.sendSummaryReq,
                    sortOn: {
                        ...this.props.profileInfo.sendSummaryReq.sortOn,
                        [name]: value,
                        eventendDate: ""
                    }
                }
                this.setState({
                    fackVar: !this.state.fackVar
                }, () => {
                    if (this.props.profileInfo.sendSummaryReq.sortOn.eventendDate) {
                        this.getSummaryList();
                    }
                })
            } else {
                this.props.profileInfo.sendSummaryReq = {
                    ...this.props.profileInfo.sendSummaryReq,
                    sortOn: {
                        ...this.props.profileInfo.sendSummaryReq.sortOn,
                        [name]: value,
                    }
                }
                this.setState({
                    fackVar: !this.state.fackVar
                }, () => {
                    if (this.props.profileInfo.sendSummaryReq.sortOn.eventstartDate) {
                        this.getSummaryList();
                    }
                })
            }
        } else if (tab === "awardTab") {
            if (name === "awardstartDate") {
                this.props.profileInfo.sendSummaryReq = {
                    ...this.props.profileInfo.sendSummaryReq,
                    sortOn: {
                        ...this.props.profileInfo.sendSummaryReq.sortOn,
                        [name]: value,
                        awardendDate: ""
                    }
                }
                this.setState({
                    fackVar: !this.state.fackVar
                }, () => {
                    if (this.props.profileInfo.sendSummaryReq.sortOn.awardendDate) {
                        this.getSummaryList();
                    }
                })
            } else {
                this.props.profileInfo.sendSummaryReq = {
                    ...this.props.profileInfo.sendSummaryReq,
                    sortOn: {
                        ...this.props.profileInfo.sendSummaryReq.sortOn,
                        [name]: value,
                    }
                }
                this.setState({
                    fackVar: !this.state.fackVar
                }, () => {
                    if (this.props.profileInfo.sendSummaryReq.sortOn.awardstartDate) {
                        this.getSummaryList();
                    }
                })
            }
        } else if (tab === "galleryTab") {
            if (name === "gallerystartDate") {
                this.props.profileInfo.sendSummaryReq = {
                    ...this.props.profileInfo.sendSummaryReq,
                    sortOn: {
                        ...this.props.profileInfo.sendSummaryReq.sortOn,
                        [name]: value,
                        galleryendDate: ""
                    }
                }
                this.setState({
                    fackVar: !this.state.fackVar
                }, () => {
                    if (this.props.profileInfo.sendSummaryReq.sortOn.galleryendDate) {
                        this.getSummaryList();
                    }
                })
            } else {
                this.props.profileInfo.sendSummaryReq = {
                    ...this.props.profileInfo.sendSummaryReq,
                    sortOn: {
                        ...this.props.profileInfo.sendSummaryReq.sortOn,
                        [name]: value,
                    }
                }
                this.setState({
                    fackVar: !this.state.fackVar
                }, () => {
                    if (this.props.profileInfo.sendSummaryReq.sortOn.gallerystartDate) {
                        this.getSummaryList();
                    }
                })
            }
        }
    }

    onFilter = (e) => {
        const { name, value } = e.target;
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            [name]: JSON.parse(value),
            sortOn: {
                ...this.props.profileInfo.sendSummaryReq.sortOn,
                gallerystartDate: JSON.parse(value) === 2 ? "" : this.props.profileInfo.sendSummaryReq.gallerystartDate,
                galleryendDate: JSON.parse(value) === 2 ? "" : this.props.profileInfo.sendSummaryReq.galleryendDate
            }
        }
        this.setState({
            fackVar: !this.state.fackVar
        }, () => {
            if (JSON.parse(value) !== 1) {
                this.getSummaryList();
            }
        })
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    purchaseProspectwire = () => {
        this.setState({
            purchase: true,
        })
    }

    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            purchase: false,
        })
    }

    clearStates = () => {
        this.props.profileInfo.tabs = 0;
        this.props.profileInfo.backupTab = 0;
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            tab: "summary",
            id: "",
            eventTeamType: 0,
            sortOn: {
                startDate: "",
                endDate: ""
            },
            seasonType: 1,
            pitching: "basic",
            batting: "basic",
            filterOn: "",
            field: "10YardDash",
            metricSubType: "Speed",
            metricType: 1,
            statsType: "Hitting"
        }
        this.setState({
            loaderCount: 0,
            summaryData: [],
            battingStats: [],
            pitchingStats: [],
            purchase: false,
        }, () => {
            this.personalInfo.renderProfile();
        })
    }

    redirectScout = (eventInfo, scoutFeed) => {
        let send = {
            event: {
                logo: eventInfo.eventLogo,
                tittle: eventInfo.eventTitle,
                _id: eventInfo._id
            },
            docs: scoutFeed
        }
        localStorage.setItem("eventReport", JSON.stringify(send));
        this.handleTabs(4, 'media');
    }

    setSendReq = (value) => {
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            metricType: value,
            metricSubType: value === 1 ? "Speed" : value === 2 ? "Pitching" : "Pitching"
        }
        this.increment();
        this.props.getProfileFields({
            metricType: this.props.profileInfo.sendSummaryReq.metricType,
            metricSubType: value === 1 ? "Speed" : value === 2 ? "Pitching" : "Pitching",
            companyId: localStorage.getItem("cid")
        });
    }

    onHandleMstype = (value) => {
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            metricSubType: value
        }
        this.increment();
        this.props.getProfileFields({
            metricType: this.props.profileInfo.sendSummaryReq.metricType,
            metricSubType: value,
            companyId: localStorage.getItem("cid")
        });
    }

    onHandleField = (value) => {
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            field: value
        }
        this.getSummaryList();
    }

    onChangeTopStats = (value) => {
        this.props.profileInfo.sendSummaryReq = {
            ...this.props.profileInfo.sendSummaryReq,
            statsType: value
        }
        this.getSummaryList();
    }

    render() {
        const { loaderCount, summaryData, sendReq, battingStats, pitchingStats, purchase, loginUid, getAllFields } = this.state;
        const { sendSummaryReq, tabs } = this.props.profileInfo;
        return (
            <>
                <Header
                    clearStates={this.clearStates}
                    renderPlayerProfile={this.getSummaryList}
                />
                <div className="main-ranking">
                    <div className="container">
                        <PersonalInfo onRef={e => this.personalInfo = e} increment={this.increment} decrement={this.decrement} pid={sendSummaryReq?.id} />
                        <ReactTooltip className="tooltip" type='dark' html={true} />

                        <div className="tab-summary">
                            <ul>
                                <li className={`${tabs === 0 ? "active" : ""}`} onClick={() => this.handleTabs(0, 'summary')}>
                                    <Link><span><img src={imagesArr.summary} /></span><span>Summary</span></Link>
                                </li>
                                <li className={`${tabs === 1 ? "active" : ""}`} onClick={() => this.handleTabs(1, 'event-report')}>
                                    <Link><span><img src={imagesArr.pw_calender} /></span><span>Events</span></Link>
                                </li>
                                <li className={`${tabs === 2 ? "active" : ""}`} onClick={() => this.handleTabs(2, 'stats')}>
                                    <Link><span><img src={imagesArr.stats} /></span><span>Data</span></Link>
                                </li>
                                <li className={`${tabs === 3 ? "active" : ""}`} onClick={() => this.handleTabs(3, 'awards')}>
                                    <Link><span><img src={imagesArr.awards} /></span><span>Awards</span></Link>
                                </li>
                                <li className={`${tabs === 4 ? "active" : ""}`} onClick={() => this.handleTabs(4, 'media')}>
                                    <Link><span><img src={imagesArr.media} /></span><span>Media</span></Link>
                                </li>
                                <li className={`${tabs === 5 ? "active" : ""}`} onClick={() => this.handleTabs(5, 'gallery')}>
                                    <Link><span><img src={imagesArr.gallery} /></span><span>Gallery</span></Link>
                                </li>
                                {
                                    (!localStorage.getItem("playerToken") || sendSummaryReq?.id === loginUid) &&
                                    <li
                                        className={`${tabs === 6 ? "active" : ""} 
                                        ${(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || sendSummaryReq?.id === loginUid) ? "" : "disable"}
                                    `}
                                        onClick={() =>
                                         (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || sendSummaryReq?.id === loginUid) ?
                                          this.handleTabs(6, 'contact') 
                                          : ""
                                           }>
                                        <Link>
                                            <span>
                                                {
                                                    // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || sendSummaryReq?.id === loginUid) ?
                                                    <img src={imagesArr.infocontact} />
                                                    // :
                                                    // <img className="lock-img" src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} onClick={() => this.purchaseProspectwire()} />
                                                }
                                            </span>
                                            <span>Info/Contact</span>
                                            {/* {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || sendSummaryReq?.id === loginUid) ?
                                                    ""
                                                    :
                                                    <img className="info-tooltip" src={localStorage.getItem('company') === "pwb" ? imagesArr.info_green : imagesArr.info_green_light} data-tip="<span>
                                        <b>What is it?</b>
                              <p>The maximum speed of the sweet spot of the barrel of the bat during the 
                              swing. The sweet spot is 20% of the bat length from the tip of the bat.</p>
                              </span>"/>
                                            } */}
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="tab-summary-content">
                            {tabs === 0 && <Summary summaryData={summaryData} battingStats={battingStats} pitchingStats={pitchingStats} onChangeTopStats={this.onChangeTopStats} />}
                            {tabs === 1 && <Events
                                onRef={e => this.eventInfo = e}
                                summaryData={summaryData}
                                increment={this.increment}
                                decrement={this.decrement}
                                onHandleTeamType={this.onHandleTeamType}
                                onHandleShowcaseType={this.onHandleShowcaseType}
                                sendReq={sendSummaryReq}
                                onChangeDate={this.onChangeDate}
                                redirectScout={this.redirectScout}
                            />}
                            {tabs === 2 && <Stats
                                summaryData={summaryData}
                                onHandleChange={this.onHandleChange}
                                sendReq={sendSummaryReq}
                                onHandleSubField={this.onHandleSubField}
                                setSendReq={this.setSendReq}
                                onHandleMstype={this.onHandleMstype}
                                onHandleField={this.onHandleField}
                                getAllFields={getAllFields}
                            />}
                            {tabs === 3 && <Awards
                                summaryData={summaryData}
                                sendReq={sendSummaryReq}
                                onChangeDate={this.onChangeDate}
                            />}
                            {tabs === 4 && <Media
                                onRef={e => this.mediaInfo = e}
                                summaryData={summaryData}
                                increment={this.increment}
                                decrement={this.decrement} />}
                            {tabs === 5 && <Gallery
                                summaryData={summaryData}
                                sendReq={sendSummaryReq}
                                getPlayerList={this.getSummaryList}
                                increment={this.increment}
                                decrement={this.decrement}
                                onChangeDate={this.onChangeDate}
                                onFilter={this.onFilter}
                            />}
                            {tabs === 6 && (!localStorage.getItem("playerToken") || sendSummaryReq?.id === loginUid) && <InfoContact
                                onRef={e => this.contactInfo = e}
                                summaryData={summaryData}
                                increment={this.increment}
                                decrement={this.decrement}
                                getSocialList={this.getSummaryList}
                            />}
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

profileSummary.propTypes = {
    getSummaryPlayerProfile: PropTypes.func.isRequired,
    getUserIDResponse: PropTypes.func.isRequired,
    getProfileFields: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileInfo: state.profileSummaryInfo,
});

export default connect(mapStateToProps, { getSummaryPlayerProfile, getUserIDResponse, getProfileFields })(profileSummary);

import {
    GET_TEAM_SEARCH_REQUEST,
    GET_TEAM_SEARCH_SUCCESS,
    GET_TEAM_SEARCH_ERROR,

    UP_TEAM_IMAGE_REQUEST,
    UP_TEAM_IMAGE_SUCCESS,
    UP_TEAM_IMAGE_ERROR,

    ADD_TEAM_REQUEST,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_ERROR,

    SAVE_TEAM_REQUEST,
    SAVE_TEAM_SUCCESS,
    SAVE_TEAM_ERROR,

    SAVE_CLAIM_REQUEST,
    SAVE_CLAIM_SUCCESS,
    SAVE_CLAIM_ERROR,

    GET_CLAIM_REQUEST,
    GET_CLAIM_SUCCESS,
    GET_CLAIM_ERROR,

    GET_TEAM_FLOW_REQUEST,
    GET_TEAM_FLOW_SUCCESS,
    GET_TEAM_FLOW_ERROR,
} from '../../../../constants/authantication/signup/coach/team.const';

const initialState = {
    searchTeamRes: false,
    searchTeamData: null,
    searchTeamDataError: null,

    teamImageRes: false,
    teamImageData: null,
    teamImageDataError: null,

    saveTeamRes: false,
    saveTeamData: null,
    saveTeamDataError: null,

    addTeamRes: false,
    addTeamData: null,
    addTeamDataError: null,

    saveClaimRes: false,
    saveClaimData: null,
    saveClaimDataError: null,

    getClaimRes: false,
    getClaimData: null,
    getClaimDataError: null,

    getTeamFlowRes: false,
    getTeamFlowData: null,
    getTeamFlowDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Zipcode Search Data
        case GET_TEAM_SEARCH_REQUEST:
            return {
                ...state,
                searchTeamRes: true
            };
        case GET_TEAM_SEARCH_SUCCESS:
            return {
                ...state,
                searchTeamData: action.payload,
            };
        case GET_TEAM_SEARCH_ERROR:
            return {
                ...state,
                searchTeamDataError: action.error,
            };

        // upload Image Search Data
        case UP_TEAM_IMAGE_REQUEST:
            return {
                ...state,
                teamImageRes: true
            };
        case UP_TEAM_IMAGE_SUCCESS:
            return {
                ...state,
                teamImageData: action.payload,
            };
        case UP_TEAM_IMAGE_ERROR:
            return {
                ...state,
                teamImageDataError: action.error,
            };

        // add team
        case ADD_TEAM_REQUEST:
            return {
                ...state,
                saveTeamRes: true
            };
        case ADD_TEAM_SUCCESS:
            return {
                ...state,
                saveTeamData: action.payload,
            };
        case ADD_TEAM_ERROR:
            return {
                ...state,
                saveTeamDataError: action.error,
            };

        // save claimTeam
        case SAVE_CLAIM_REQUEST:
            return {
                ...state,
                saveClaimRes: true
            };
        case SAVE_CLAIM_SUCCESS:
            return {
                ...state,
                saveClaimData: action.payload,
            };
        case SAVE_CLAIM_ERROR:
            return {
                ...state,
                saveClaimDataError: action.error,
            };

        // save team
        case SAVE_TEAM_REQUEST:
            return {
                ...state,
                addTeamRes: true
            };
        case SAVE_TEAM_SUCCESS:
            return {
                ...state,
                addTeamData: action.payload,
            };
        case SAVE_TEAM_ERROR:
            return {
                ...state,
                addTeamDataError: action.error,
            };

        // get claim
        case GET_CLAIM_REQUEST:
            return {
                ...state,
                getClaimRes: true
            };
        case GET_CLAIM_SUCCESS:
            return {
                ...state,
                getClaimData: action.payload,
            };
        case GET_CLAIM_ERROR:
            return {
                ...state,
                getClaimDataError: action.error,
            };

        // get team flow
        case GET_TEAM_FLOW_REQUEST:
            return {
                ...state,
                getTeamFlowRes: true
            };
        case GET_TEAM_FLOW_SUCCESS:
            return {
                ...state,
                getTeamFlowData: action.payload,
            };
        case GET_TEAM_FLOW_ERROR:
            return {
                ...state,
                getTeamFlowDataError: action.error,
            };

        default:
            return state;
    }
}
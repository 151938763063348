import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { imagesArr } from '../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../components/leftAuthImage';
import CommonHeroBanner from '../components/commonHeroBanner';
import firebaseConnection from "../businesslogic/firebaseConnection";
import commonCalls from "../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import firebase from 'firebase';
import SnapChatLogin from '../businesslogic/SnapChatLogin';
import { socialCheck } from '../saga/actions/authantication/signup/step1.action';
import { loginData, tokenForNotification } from '../saga/actions/authantication/login.action';
import { instagramLogin } from '../saga/actions/common/api.action';
import Preloader from '../components/preloader';
import Cookies from 'js-cookie';
import { isSafari, isMobile } from 'react-device-detect';

class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ptype: "password",
            logArr: {
                username: "",
                password: ""
            },
            loader: false,
        }

        this.firebaseApi = new firebaseConnection();
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        localStorage.removeItem("notificationToken");
        this.firebaseApi.firebaseConnect();
        window.scrollTo(0, 0);
        this.setLoader(true);
        setTimeout(() => {
            this.setLoader(false);
        }, 1000);
        // localStorage.clear();
        // this.getInstagramCode();
    };

    componentWillReceiveProps(nextProps) {

        if (nextProps.socialData &&
            nextProps.socialData.socialReq &&
            nextProps.socialData.socialData
        ) {
            nextProps.socialData.socialReq = false;

            let productType = nextProps.socialData.socialData.result.products.some((item) => {
                return item === "DC"
            });

            localStorage.setItem("webglToken", nextProps.socialData.socialData.result.token);
            Cookies.set('bcSession', nextProps.socialData.socialData.result.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

            if (productType) {
                let setToken = nextProps.socialData.socialData.result.role === "Coach" ? "coachToken" :
                    nextProps.socialData.socialData.result.role === "Player" ? "playerToken" : "authToken";

                this.commonCall.setAuthToken(setToken, nextProps.socialData.socialData.result.token, flag => {
                    if (flag) {
                        if (nextProps.socialData.socialData.result.role === "Coach") {
                            if (localStorage.getItem("payPlans")) {
                                if (localStorage.getItem("analyzr")) {
                                    this.props.history.push('/analyzr');
                                } else {
                                    this.commonCall.successMessage("Login Successfully");
                                    this.props.history.push('/coach-payment');
                                }
                            } else {
                                if (localStorage.getItem("multiPay")) {
                                    this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                } 
                                else if (localStorage.getItem("coachTab")){
                                    let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                    let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                    this.props.history.push(`/coach-setting/${number}`)
                                }
                                else if (localStorage.getItem("eventDetail")) {
                                    this.props.history.push(
                                        localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                            : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                } else if (localStorage.getItem("leagueDetail")) {
                                    this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                } else {
                                    if (localStorage.getItem("analyzrSession")) {
                                        localStorage.removeItem("analyzrSession");
                                        this.props.history.push('/analyzrapp');
                                    } else {
                                        this.setLoader(false);
                                        nextProps.socialData.socialData = null;
                                        nextProps.socialData.actionData = null;
                                        this.commonCall.successMessage("Login Successfully");
                                        this.props.history.push('/coach-dashboard');
                                    }
                                }
                            }
                        } else if (nextProps.socialData.socialData.result.role === "Player") {
                            if (localStorage.getItem("payPlans")) {
                                if (localStorage.getItem("analyzr")) {
                                    this.props.history.push('/analyzr');
                                } else {
                                    this.commonCall.successMessage("Login Successfully");
                                    this.props.history.push('/player-payment');
                                }
                            } else {
                                if (localStorage.getItem("multiPay")) {
                                    this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                }
                                else if (localStorage.getItem("coachTab")){
                                    let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                    let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                    this.props.history.push(`/coach-setting/${number}`)
                                }
                                else if (localStorage.getItem("eventDetail")) {
                                    this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}` 
                                            : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                } else if (localStorage.getItem("leagueDetail")) {
                                    this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                } else {
                                    if (localStorage.getItem("analyzrSession")) {
                                        localStorage.removeItem("analyzrSession");
                                        this.props.history.push('/analyzrapp');
                                    } else {
                                        this.setLoader(false);
                                        nextProps.socialData.socialData = null;
                                        nextProps.socialData.actionData = null;
                                        this.commonCall.successMessage("Login Successfully");
                                        this.props.history.push('/player-dashboard');
                                    }
                                }
                            }
                        } else if (nextProps.socialData.socialData.result.role === "Fan" || nextProps.socialData.socialData.result.role === "Parent") {
                            if (localStorage.getItem("analyzrSession")) {
                                localStorage.removeItem("analyzrSession");
                                this.props.history.push('/analyzrapp');
                            } else {
                                this.setLoader(false);
                                nextProps.socialData.socialData = null;
                                nextProps.socialData.actionData = null;
                                this.commonCall.errorMessage("Invalid login details");
                            }
                        } else {
                            this.commonCall.setStorage("socialMedia", nextProps.socialData.actionData, flag => {
                                if (flag) {
                                    this.setLoader(false);
                                    nextProps.socialData.socialData = null;
                                    nextProps.socialData.actionData = null;
                                    this.props.history.push('/signup2');
                                }
                            });
                        }
                    }
                });
            } else {

                let setToken = nextProps.socialData.socialData.result.role === "Coach" ? "coachToken" :
                    nextProps.socialData.socialData.result.role === "Player" ? "playerToken" : "authToken";

                this.commonCall.setAuthToken(setToken, nextProps.socialData.socialData.result.token, flag => {
                    if (flag) {
                        if (nextProps.socialData.socialData.result.role === "Coach") {
                            if (localStorage.getItem("payPlans")) {
                                if (localStorage.getItem("analyzr")) {
                                    this.props.history.push('/analyzr');
                                } else {
                                    this.commonCall.successMessage("Login Successfully");
                                    this.props.history.push('/coach-payment');
                                }
                            } else {
                                if (localStorage.getItem("multiPay")) {
                                    this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                }
                                else if (localStorage.getItem("coachTab")){
                                    let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                    let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                    this.props.history.push(`/coach-setting/${number}`)
                                }
                                else if (localStorage.getItem("eventDetail")) {
                                    this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                        : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                } else if (localStorage.getItem("leagueDetail")) {
                                    this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                } else {
                                    if (localStorage.getItem("analyzrSession")) {
                                        localStorage.removeItem("analyzrSession");
                                        this.props.history.push('/analyzrapp');
                                    } else {
                                        this.setLoader(false);
                                        nextProps.socialData.socialData = null;
                                        nextProps.socialData.actionData = null;
                                        this.props.history.push('/coach-signup4');
                                    }
                                }
                            }
                        } else if (nextProps.socialData.socialData.result.role === "Player") {
                            if (localStorage.getItem("payPlans")) {
                                if (localStorage.getItem("analyzr")) {
                                    this.props.history.push('/analyzr');
                                } else {
                                    this.commonCall.successMessage("Login Successfully");
                                    this.props.history.push('/player-payment');
                                }
                            } else {
                                if (localStorage.getItem("multiPay")) {
                                    this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                }
                                else if (localStorage.getItem("coachTab")){
                                    let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                    let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                    this.props.history.push(`/coach-setting/${number}`)
                                }
                                else if (localStorage.getItem("eventDetail")) {
                                    this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                        : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                } else if (localStorage.getItem("leagueDetail")) {
                                    this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                } else {
                                    if (localStorage.getItem("analyzrSession")) {
                                        localStorage.removeItem("analyzrSession");
                                        this.props.history.push('/analyzrapp');
                                    } else {
                                        this.setLoader(false);
                                        nextProps.socialData.socialData = null;
                                        nextProps.socialData.actionData = null;
                                        this.props.history.push('/player-signup4');
                                    }
                                }
                            }
                        } else if (nextProps.socialData.socialData.result.role === "Fan" || nextProps.socialData.socialData.result.role === "Parent") {
                            if (localStorage.getItem("analyzrSession")) {
                                localStorage.removeItem("analyzrSession");
                                this.props.history.push('/analyzrapp');
                            } else {
                                this.setLoader(false);
                                nextProps.socialData.socialData = null;
                                nextProps.socialData.actionData = null;
                                this.commonCall.errorMessage("Invalid login details");
                            }
                        } else {
                            this.commonCall.setStorage("socialMedia", nextProps.socialData.actionData, flag => {
                                if (flag) {
                                    this.setLoader(false);
                                    nextProps.socialData.socialData = null;
                                    nextProps.socialData.actionData = null;
                                    this.props.history.push('/signup2');
                                }
                            });
                        }
                    }
                });
            }
        }

        if (nextProps.loginDetails &&
            nextProps.loginDetails.loginReq
        ) {
            if (nextProps.loginDetails.loginData) {
                nextProps.loginDetails.loginReq = false;

                localStorage.setItem("webglToken", nextProps.loginDetails.loginData.result.token);
                Cookies.set('bcSession', nextProps.loginDetails.loginData.result.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

                let productType = nextProps.loginDetails.loginData.result.products.some((item) => {
                    return item === "DC"
                });
                if (productType) {
                    let setToken = nextProps.loginDetails.loginData.result.role === "Coach" ? "coachToken" :
                        nextProps.loginDetails.loginData.result.role === "Player" ? "playerToken" :
                            (nextProps.loginDetails.loginData.result.role === "Fan" || nextProps.loginDetails.loginData.result.role === "Parent") ? "fanToken" :
                                nextProps.loginDetails.loginData.result.role === "BC Commissioner" ? "commiToken" : "authToken";

                    this.commonCall.setAuthToken(setToken, nextProps.loginDetails.loginData.result.token, flag => {
                        if (flag) {
                            this.setLoader(false);
                            if (nextProps.loginDetails.loginData.result.role === "Coach") {
                                if (localStorage.getItem("payPlans")) {
                                    if (localStorage.getItem("analyzr")) {
                                        this.props.history.push('/analyzr');
                                    } else {
                                        if (localStorage.getItem("rolPay") === "Coach" || localStorage.getItem("rolPay") === "") {
                                            this.commonCall.successMessage("Login Successfully");
                                            this.props.history.push('/coach-payment');
                                        } else {
                                            localStorage.removeItem("rolPay");
                                            localStorage.removeItem("dcStats");
                                            localStorage.removeItem("payPlans");
                                            localStorage.removeItem("analyzr");
                                            this.props.history.push('/coach-dashboard');
                                        }
                                    }
                                } else {
                                    if (localStorage.getItem("multiPay")) {
                                        this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                    }
                                    else if (localStorage.getItem("coachTab")){
                                        let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                        let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                        this.props.history.push(`/coach-setting/${number}`)
                                    }
                                    else if (localStorage.getItem("eventDetail")) {
                                        this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                            : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                    } else if (localStorage.getItem("leagueDetail")) {
                                        this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                    } else {
                                        if (localStorage.getItem("analyzrSession")) {
                                            localStorage.removeItem("analyzrSession");
                                            this.props.history.push('/analyzrapp');
                                        } else {
                                            this.props.history.push('/coach-dashboard');
                                        }
                                    }
                                }
                            } else if (nextProps.loginDetails.loginData.result.role === "Player") {
                                if (localStorage.getItem("payPlans")) {
                                    if (localStorage.getItem("analyzr")) {
                                        this.props.history.push('/analyzr');
                                    } else {
                                        if (localStorage.getItem("rolPay") === "Player" || localStorage.getItem("rolPay") === "") {
                                            this.commonCall.successMessage("Login Successfully");
                                            this.props.history.push('/player-payment');
                                        } else {
                                            localStorage.removeItem("rolPay");
                                            localStorage.removeItem("dcStats");
                                            localStorage.removeItem("payPlans");
                                            localStorage.removeItem("analyzr");
                                            this.props.history.push('/player-dashboard');
                                        }
                                    }
                                } else {
                                    if (localStorage.getItem("multiPay")) {
                                        this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                    }
                                    else if (localStorage.getItem("coachTab")){
                                        let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                        let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                        this.props.history.push(`/coach-setting/${number}`)
                                    }
                                    else if (localStorage.getItem("eventDetail")) {
                                        this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                             : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                    } else if (localStorage.getItem("leagueDetail")) {
                                        this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                    } else {
                                        if (localStorage.getItem("analyzrSession")) {
                                            localStorage.removeItem("analyzrSession");
                                            this.props.history.push('/analyzrapp');
                                        } else {
                                            this.props.history.push('/player-dashboard');
                                        }
                                    }
                                }
                            } else if (nextProps.loginDetails.loginData.result.role === "Fan" || nextProps.loginDetails.loginData.result.role === "Parent") {
                                if (nextProps.loginDetails.loginData.result.role === "Fan") {
                                    localStorage.removeItem("company");
                                    localStorage.removeItem("cid");
                                    localStorage.removeItem("companyColor");
                                    localStorage.removeItem("isAllCompany");
                                    localStorage.removeItem("isCollegeShowcase");
                                    let defaultColor = [
                                        {
                                            variable: "--green-color",
                                            colorCode: "#7CBD42"
                                        },
                                        {
                                            variable: "--green-color-light",
                                            colorCode: "#96CF04"
                                        }
                                    ]
                                    this.setThemeColor(defaultColor);
                                }
                                if (localStorage.getItem("payPlans")) {
                                    if (localStorage.getItem("analyzr")) {
                                        this.props.history.push('/analyzr');
                                    } else {
                                        if (localStorage.getItem("rolPay") === "Fan" || localStorage.getItem("rolPay") === "") {
                                            this.commonCall.successMessage("Login Successfully");
                                            this.props.history.push('/fan-payment');
                                        } else {
                                            localStorage.removeItem("rolPay");
                                            localStorage.removeItem("dcStats");
                                            localStorage.removeItem("payPlans");
                                            localStorage.removeItem("analyzr");
                                            this.props.history.push('/fan-dashboard');
                                        }
                                    }
                                } else {
                                    if (localStorage.getItem("analyzrSession")) {
                                        localStorage.removeItem("analyzrSession");
                                        this.props.history.push('/analyzrapp');
                                    } else {
                                        this.props.history.push('/fan-dashboard');
                                    }
                                }
                            } else if (nextProps.loginDetails.loginData.result.role === "BC Commissioner") {
                                this.props.history.push('/commi-dashboard');
                                localStorage.removeItem("company");
                                localStorage.removeItem("cid");
                                localStorage.removeItem("companyColor");
                                localStorage.removeItem("isAllCompany");
                                localStorage.removeItem("isCollegeShowcase");
                                let defaultColor = [
                                    {
                                        variable: "--green-color",
                                        colorCode: "#7CBD42"
                                    },
                                    {
                                        variable: "--green-color-light",
                                        colorCode: "#96CF04"
                                    }
                                ]
                                this.setThemeColor(defaultColor);
                            } else {
                                this.props.history.push('/signup3');
                            }
                        }
                    });
                    nextProps.loginDetails.loginData = null;
                } else {
                    let setToken = nextProps.loginDetails.loginData.result.role === "Coach" ? "coachToken" :
                        nextProps.loginDetails.loginData.result.role === "Player" ? "playerToken" :
                            (nextProps.loginDetails.loginData.result.role === "Fan" || nextProps.loginDetails.loginData.result.role === "Parent") ? "fanToken" : 
                                nextProps.loginDetails.loginData.result.role === "BC Commissioner" ? "commiToken" : "authToken";
                    let role = nextProps.loginDetails.loginData.result.role;
                    this.commonCall.setAuthToken(setToken, nextProps.loginDetails.loginData.result.token, flag => {
                        if (flag) {
                            this.setLoader(false);
                            if (nextProps.loginDetails.loginData.result.role === "Coach") {
                                if (localStorage.getItem("payPlans")) {
                                    if (localStorage.getItem("analyzr")) {
                                        this.props.history.push('/analyzr');
                                    } else {
                                        if (localStorage.getItem("rolPay") === "Coach" || localStorage.getItem("rolPay") === "") {
                                            this.commonCall.successMessage("Login Successfully");
                                            this.props.history.push('/coach-payment');
                                        } else {
                                            localStorage.removeItem("rolPay");
                                            localStorage.removeItem("dcStats");
                                            localStorage.removeItem("payPlans");
                                            localStorage.removeItem("analyzr");
                                            this.props.history.push('/coach-signup4');
                                        }
                                    }
                                    
                                } else {
                                    if (localStorage.getItem("multiPay")) {
                                        this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                    }
                                    else if (localStorage.getItem("coachTab")){
                                        let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                        let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                        this.props.history.push(`/coach-setting/${number}`)
                                    }
                                    else if (localStorage.getItem("eventDetail")) {
                                        this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                            : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                    } else if (localStorage.getItem("leagueDetail")) {
                                        this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                    } else {
                                        if (localStorage.getItem("analyzrSession")) {
                                            localStorage.removeItem("analyzrSession");
                                            this.props.history.push('/analyzrapp');
                                        } else {
                                            this.props.history.push('/coach-signup4');
                                        }
                                    }
                                }
                            } else if (nextProps.loginDetails.loginData.result.role === "Player") {
                                if (localStorage.getItem("payPlans")) {
                                    if (localStorage.getItem("analyzr")) {
                                        this.props.history.push('/analyzr');
                                    } else {
                                        if (localStorage.getItem("rolPay") === "Player" || localStorage.getItem("rolPay") === "") {
                                            this.commonCall.successMessage("Login Successfully");
                                            this.props.history.push('/player-payment');
                                        } else {
                                            localStorage.removeItem("rolPay");
                                            localStorage.removeItem("dcStats");
                                            localStorage.removeItem("payPlans");
                                            localStorage.removeItem("analyzr");
                                            this.props.history.push('/player-signup4');
                                        }
                                    }
                                } else {
                                    if (localStorage.getItem("multiPay")) {
                                        this.props.history.push(`/event-multiple-pay?payId=${localStorage.getItem("payId")}`)
                                    }
                                    else if (localStorage.getItem("coachTab")){
                                        let coachTab = this.commonCall.decryption(localStorage.getItem('coachTab'))
                                        let number =  coachTab === 'facility' ? 1 : coachTab === 'profile' ? 0 : coachTab === 'roster' ? 2 : coachTab === 'training' ? 3 : coachTab === 'team' ? 4 : coachTab === 'billing' ? 7 : 0
                                        this.props.history.push(`/coach-setting/${number}`)
                                    }
                                    else if (localStorage.getItem("eventDetail")) {
                                        this.props.history.push(localStorage.getItem("eventDirectPayment") ? `/event-payment/${localStorage.getItem("eventDetail")}?nid=${localStorage.getItem("nid")}`
                                            : `/event-detail/${localStorage.getItem("eventDetail")}`);
                                    } else if (localStorage.getItem("leagueDetail")) {
                                        this.props.history.push(`/league-detail/${localStorage.getItem("leagueDetail")}`);
                                    } else {
                                        if (localStorage.getItem("analyzrSession")) {
                                            localStorage.removeItem("analyzrSession");
                                            this.props.history.push('/analyzrapp');
                                        } else {
                                            this.props.history.push('/player-signup4');
                                        }
                                    }
                                }
                            } else if (nextProps.loginDetails.loginData.result.role === "Fan" || nextProps.loginDetails.loginData.result.role === "Parent") {
                                if (nextProps.loginDetails.loginData.result.role === "Fan") {
                                    localStorage.removeItem("company");
                                    localStorage.removeItem("cid");
                                    localStorage.removeItem("companyColor");
                                    localStorage.removeItem("isAllCompany");
                                    localStorage.removeItem("isCollegeShowcase");
                                    let defaultColor = [
                                        {
                                            variable: "--green-color",
                                            colorCode: "#7CBD42"
                                        },
                                        {
                                            variable: "--green-color-light",
                                            colorCode: "#96CF04"
                                        }
                                    ]
                                    this.setThemeColor(defaultColor);
                                }
                                if (localStorage.getItem("payPlans")) {
                                    if (localStorage.getItem("analyzr")) {
                                        this.props.history.push('/analyzr');
                                    } else {
                                        if (localStorage.getItem("rolPay") === "Fan" || localStorage.getItem("rolPay") === "") {
                                            this.commonCall.successMessage("Login Successfully");
                                            this.props.history.push('/fan-payment');
                                        } else {
                                            localStorage.removeItem("rolPay");
                                            localStorage.removeItem("dcStats");
                                            localStorage.removeItem("payPlans");
                                            localStorage.removeItem("analyzr");
                                            this.props.history.push('/fan-signup4');
                                        }
                                    }
                                } else {
                                    if (localStorage.getItem("analyzrSession")) {
                                        localStorage.removeItem("analyzrSession");
                                        this.props.history.push('/analyzrapp');
                                    } else {
                                        this.props.history.push('/fan-signup4');
                                    }
                                }
                            } else if (nextProps.loginDetails.loginData.result.role === "BC Commissioner") {
                                this.props.history.push('/commi-dashboard');
                                localStorage.removeItem("company");
                                localStorage.removeItem("cid");
                                localStorage.removeItem("companyColor");
                                localStorage.removeItem("isAllCompany");
                                localStorage.removeItem("isCollegeShowcase");
                                let defaultColor = [
                                    {
                                        variable: "--green-color",
                                        colorCode: "#7CBD42"
                                    },
                                    {
                                        variable: "--green-color-light",
                                        colorCode: "#96CF04"
                                    }
                                ]
                                this.setThemeColor(defaultColor);
                            } else {
                                this.props.history.push('/signup3');
                            }
                        }
                    });
                    if (!isSafari && !isMobile) {
                        role = role === "" ? "authToken" : role;
                        window.messaging.getToken().then((currentToken) => {
                            if (currentToken) {
                                const data = {
                                    appVersion: 1,
                                    deviceVersion: "13.3.1",
                                    deviceId: "3295D8F5-2435-4BD7-B9AB-BE88CBE302BB",
                                    token: currentToken,
                                    deviceType: 3,
                                }
                                this.props.tokenForNotification(data, role);
                                localStorage.setItem("notificationToken", currentToken);
                            } else {
                                // Show permission request.
                                console.log('No registration token available. Request permission to generate one.');
                            }
                        }).catch((err) => {
                            console.log('An error occurred while retrieving token. ', err);
                        });
                    }

                    let url = new URL(document.location);
                    let self = this;
                    if (url.hostname !== "www.diamondconnect.com" && url.hostname !== "diamondconnect.com") {
                        if ('safari' in window && 'pushNotification' in window.safari) {
                            console.log("------------window.safari.pushNotification.permission-----------");
                            var permissionData = window.safari.pushNotification.permission(process.env.REACT_APP_APNS_WEB_PUSH_ID);
                            console.log("permissionData:---", permissionData);
                            self.checkRemotePermission(permissionData, role);
                        }
                    }

                    nextProps.loginDetails.loginData = null;
                }
            }

            if (nextProps.loginDetails.loginError !== null) {
                this.setLoader(false);
                nextProps.loginDetails.loginReq = false;
                this.commonCall.errorMessage(nextProps.loginDetails.loginError);
                nextProps.loginDetails.loginError = null;
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.instagramReq &&
            nextProps.commonData.instagramData
        ) {
            nextProps.commonData.instagramReq = false;
            let data = {
                fname: "",
                lname: "",
                email: "",
                socialId: nextProps.commonData.instagramData.data.user_id,
                type: 4
            }
            this.props.socialCheck(data);
            nextProps.commonData.instagramData = null;
        }

    }

    setThemeColor = (colArr) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
    }

    checkRemotePermission = (permissionData, role) => {
        try {
            console.log("-----------try-----------");
            if (permissionData.permission === 'default') {
                console.log("window:------", window);
                // This is a new web service URL and its validity is unknown.
                window.safari.pushNotification.requestPermission(
                    process.env.REACT_APP_APNS_WEB_SERVICE_URL, // The web service URL.
                    process.env.REACT_APP_APNS_WEB_PUSH_ID,     // The Website Push ID.
                    {}, // Data that you choose to send to your server to help you identify the user.
                    (permissionData) => {
                        this.checkRemotePermission(permissionData)         // The callback function.
                    }
                );
            }
            else if (permissionData.permission === 'denied') {
                console.log("denied --------------", permissionData);
                // The user said no.
            }
            else if (permissionData.permission === 'granted') {
                console.log("granted --------------", permissionData);
                const data = {
                    appVersion: 1,
                    deviceVersion: "13.3.1",
                    deviceId: "3295D8F5-2435-4BD7-B9AB-BE88CBE302BB",
                    token: permissionData.deviceToken,
                    deviceType: 4,
                }
                this.props.tokenForNotification(data, role);
                localStorage.setItem("notificationToken", permissionData.deviceToken);
                // The web service URL is a valid push provider, and the user said yes.
                // permissionData.deviceToken is now available to use.
            }
        } catch (error) {
            console.log("conenction error --------", error);
        }
    };

    socialMediaLogin = (data) => {
        let self = this;
        if (data === 'fb') {
            var provider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(provider).then(function (result) {
                let name = result.user.displayName.split(" ");
                let fdata = {
                    fname: name[0] ? name[0] : "",
                    lname: name[1] ? name[1] : "",
                    email: result.user.email ? result.user.email : "",
                    socialId: result.user.uid,
                    type: 1
                }
                self.setLoader(true);
                self.props.socialCheck(fdata);
            }).catch(function (error) {
                console.log("error::---", error);
            });
        }
    }

    onChangeFn = (e) => {
        const { name, value } = e.target;
        this.setState({
            logArr: {
                ...this.state.logArr,
                [name]: value
            }
        })
    };

    login = (e) => {
        e.preventDefault();

        if (this.validator.allValid()) {
            this.props.loginData(this.state.logArr, 'LOGIN')
            this.setLoader(true);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {

        const { logArr, loader } = this.state

        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>Sign In</h3>
                                    <p>Please Enter your Email Address and Password</p>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <label>Email or Phone number</label>
                                        <input type="text" name="username" value={logArr.username} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Email or  Phone number" />
                                        <span className="validMsg">{this.validator.message('Email or Phone number', logArr.username, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <div className="svg_icon">
                                            <input type={this.state.ptype} name="password" value={logArr.password} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter password" />
                                            {this.state.ptype == "password" ? (
                                                <img src={imagesArr.eye} onClick={() => this.setState({ ptype: "text" })} alt="eye" />
                                            ) : (
                                                <img src={imagesArr.eyeopen} onClick={() => this.setState({ ptype: "password" })} alt="eye" />
                                            )}
                                            <p className="text-right"><Link to="/forgot">Forgot Password?</Link></p>
                                        </div>
                                        <span className="validMsg">{this.validator.message('password', logArr.password, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn w-100 text-center" onClick={this.login}>Sign In</button>
                                    </div>
                                    <div className="form-group">
                                        <div className="or-with">OR</div>
                                    </div>
                                    <div className="header_align social-with">
                                        <Link onClick={() => this.socialMediaLogin('fb')} className="facebook social_btn"><img src={imagesArr.fb} alt="fb" />Facebook</Link>
                                        {/* <Link onClick={() => this.socialMediaLogin('insta')} className="insta social_btn"><img src={imagesArr.insta} alt="insta" />Instagram</Link> */}
                                        {/* <SnapChatLogin snapLogin={this.socialMediaLogin} load={this.setLoader} /> */}
                                    </div>
                                    <div className="form-group">
                                        <button className="btn w-100 text-center signup_button" onClick={() =>  this.props.history.push('/signup2')}>Sign Up</button>
                                    </div>
                                    {/* <button type="button" onClick={() => this.socialMediaLogin('twit')}>Twitter</button> */}
                                </form>
                                {/* <span className="login_alert_text">Please sign up using your email. Social sign up will be available shortly upon approval.</span> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

login.propTypes = {
    socialCheck: PropTypes.func.isRequired,
    loginData: PropTypes.func.isRequired,
    instagramLogin: PropTypes.func.isRequired,
    tokenForNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    socialData: state.socialCheck,
    loginDetails: state.loginData,
    commonData: state.api
});

export default connect(mapStateToProps, { socialCheck, loginData, instagramLogin, tokenForNotification })(login);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';

class leaveTeam extends Component {

    componentDidMount() {
        
    }

    render() {
        const { flag } = this.props;

        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="onlymodal_close" onClick={() => this.props.close('leaveTeam')}><img src={imagesArr.modal_close} alt="modal_close" /></div>
                            <div className="modal-body">
                                <div className="leave_team">
                                    <h4>
                                        Leave Team
                                    </h4>
                                    <p>Are you sure? you want to leave team.</p>
                                    <div className="flex-align">
                                        <Link className="btn gray" onClick={() => this.props.close('leaveTeam')}>Cancel</Link>
                                        <Link className="btn" onClick={() => this.props.confirm()}>Yes</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default leaveTeam;
import React, { Component } from 'react';
import { imagesArr } from '../assets/images';

class leftAuthImage extends Component {
    render() {
        return (
            <>
                <div className="commong-img">
                    <img src={imagesArr.common_img} alt="common_img" />
                    <div className="logo-common">
                        <img src={imagesArr.logo_common} alt="logo_common" />
                    </div>
                </div>
            </>
        );
    }
}

export default leftAuthImage;
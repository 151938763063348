import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    GET_DIV_REQUEST,
    GET_DIV_SUCCESS,
    GET_DIV_ERROR,

    SAVE_ROSTER_REQUEST,
    SAVE_ROSTER_SUCCESS,
    SAVE_ROSTER_ERROR,

    GET_ROSTER_REQUEST,
    GET_ROSTER_SUCCESS,
    GET_ROSTER_ERROR,

    GET_ROSTER_BY_PARENT_TEAM_REQUEST,
    GET_ROSTER_BY_PARENT_TEAM_SUCCESS,
    GET_ROSTER_BY_PARENT_TEAM_ERROR,

    POST_ROSTER_BY_PARENT_TEAM_REQUEST,
    POST_ROSTER_BY_PARENT_TEAM_SUCCESS,
    POST_ROSTER_BY_PARENT_TEAM_ERROR
} from '../../../../constants/authantication/signup/coach/roster.const';
import histrory from '../../../../../History';

export const getDivision = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_DIV_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}setting/division`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(division => {
        console.log("division:--", division);
        dispatch({
            type: GET_DIV_SUCCESS,
            payload: division.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_DIV_ERROR, error: error.response.data.message })
    });
};

export const saveRoster = (data) => dispatch => {
    console.log("data:----action:---", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_ROSTER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/roster`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(roster => {
        console.log("roster:----", roster);
        dispatch({
            type: SAVE_ROSTER_SUCCESS,
            payload: roster.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_ROSTER_ERROR, error: error.response.data.message })
    });
};

export const rosterList = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_ROSTER_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/roster`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(roster => {
        dispatch({
            type: GET_ROSTER_SUCCESS,
            payload: roster.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_ROSTER_ERROR, error: error.response.data.message })
    });
};

export const rosterListByParentTeam = (data) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_ROSTER_BY_PARENT_TEAM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/roster/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(roster => {
        dispatch({
            type: GET_ROSTER_BY_PARENT_TEAM_SUCCESS,
            payload: roster.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_ROSTER_BY_PARENT_TEAM_ERROR, error: error.response.data.message })
    });
};

export const saveRosterByTeam = (data) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: POST_ROSTER_BY_PARENT_TEAM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/roster/team`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(roster => {
        dispatch({
            type: POST_ROSTER_BY_PARENT_TEAM_SUCCESS,
            payload: roster.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: POST_ROSTER_BY_PARENT_TEAM_ERROR, error: error.response.data.message })
    });
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getDevice, getDeviceRowsData } from '../../../saga/actions/common/uploadcsv.action';
import { Link } from 'react-router-dom';
import Preloader from '../../../components/preloader';
import moment from "moment";
class fanDeviceData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            eid: "",
            sourceArr: [],
            openRows: false,
            tableData: [],
            tableHead: [],
            ftype: ""
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.getId();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getDeviceReq &&
            nextProps.uploadCsvInfo.getDeviceData
        ) {
            console.log("nextProps from getDeviceData:----", nextProps.uploadCsvInfo.getDeviceData);
            nextProps.uploadCsvInfo.getDeviceReq = false;
            if (nextProps.uploadCsvInfo.getDeviceData.flag) {

                this.setState({
                    sourceArr: nextProps.uploadCsvInfo.getDeviceData.result.sources,
                    eventName: nextProps.uploadCsvInfo.getDeviceData.result.event_name,
                }, () => {
                    this.setLoader(false);
                    nextProps.uploadCsvInfo.getDeviceData = null;
                })
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getRowDeviceReq &&
            nextProps.uploadCsvInfo.getRowDeviceData
        ) {
            console.log("nextProps from getRowDeviceData:----", nextProps.uploadCsvInfo.getRowDeviceData);
            nextProps.uploadCsvInfo.getRowDeviceReq = false;
            if (nextProps.uploadCsvInfo.getRowDeviceData.flag) {
                this.setState({
                    tableHead: nextProps.uploadCsvInfo.getRowDeviceData.result.header,
                    tableData: nextProps.uploadCsvInfo.getRowDeviceData.result.data,
                    openRows: !this.state.openRows
                }, () => {
                    this.setLoader(false);
                    nextProps.uploadCsvInfo.getRowDeviceData = null;
                })
            }
        }
    }

    getId = () => {
        let url = new URL(document.location);
        let eid = url.pathname.split("/")[2];
        this.setState({
            eid: eid
        }, () => {
            if (localStorage.getItem("sourceCred") !== null) {
                this.commonCall.getStorage("sourceCred", data => {
                    this.getRowsData(data.sid, data.file_type);
                })
            } else {
                this.props.getDevice(this.state.eid, "Fan", localStorage.getItem("rosterToken"))
            }
        });
    }

    redirectUrl = (link) => {
        // let url = process.env.REACT_APP_END_POINT;
        // let data = url.split("/")[0] + "//" + url.split("/")[2] + "/" + link.split("/")[1] + "/" + link.split("/")[2];
        // console.log("data:---", data);

        // let data = process.env.REACT_APP_END_POINT.split("/")[0] + "//" + process.env.REACT_APP_END_POINT.split("/")[2] + "/" + link.split("/")[1] + "/" + link.split("/")[2];
        window.open(link, '_blank');
    }

    getRowsData = (sid, ftype) => {
        let sourceCred = {
            sid: sid,
            file_type: ftype
        }
        this.commonCall.setStorage("sourceCred", sourceCred, flag => {
            if (flag) {
                this.setState({
                    ftype: ftype
                }, () => {
                    this.setLoader(true);
                    this.props.getDeviceRowsData({ source_id: sid }, "Fan", localStorage.getItem("rosterToken"));
                })
            }
        })
    }

    redirectUrlForVideo = (sid) => {
        console.log("sid:---", sid);
        localStorage.setItem("selectedSource", sid);
        this.props.history.push(`/fan-event-model/${this.state.eid}`);
    }

    renderType = (type) => {
        var myPattern = new RegExp('(\\w*Rapsodo\\w*)', 'gi');
        var matches = type.match(myPattern);
        if (matches) {
            if (type === "Rapsodo Pitching Lite") {
                return "Rapsodo Pitching Lite";
            } else if (type === "Rapsodo Pitching Pro") {
                return "Rapsodo Pitching Pro";
            } else if (type === "Rapsodo Hitting Lite") {
                return "Rapsodo Hitting Lite";
            } else {
                return "Rapsodo Hitting Pro";
            }
        }
    }

    renderDeviceList = () => {
        return (
            this.state.sourceArr.length != 0 ?
                this.state.sourceArr.map((list, i) => {
                    return (
                        <tr key={i}>
                            <td colspan="2">
                                {list.file_type}
                            </td>
                            <td colspan="2">
                                {(list.file_type === "FlightScope" ||
                                    list.file_type === "TrackMan" ||
                                    list.file_type === "Yakkertech" ||
                                    list.file_type === "Blast" ||
                                    list.file_type === "TrackMan Portable Hitting" ||
                                    list.file_type === "TrackMan Portable Pitching" ||
                                    list.file_type === "Hittrax" ||
                                    list.file_type === "Savant Hitting" ||
                                    list.file_type === "Savant Pitching" ||
                                    list.file_type === "Pocket Radar" ||
                                    list.file_type === "Stalker Radar") &&
                                    list.s3_path ?
                                    <>{list.s3_path.map((_, i) => {
                                        return (
                                            <>
                                                <Link onClick={() => this.redirectUrl(_)}>
                                                    {_.split("/")[4]}
                                                </Link>
                                                <br />
                                            </>
                                        )
                                    }
                                    )}
                                    </>
                                    :
                                    (this.renderType(list.file_type) === "Rapsodo Pitching Lite") || (this.renderType(list.file_type) === "Rapsodo Pitching Pro") || (this.renderType(list.file_type) === "Rapsodo Hitting Lite") || (this.renderType(list.file_type) === "Rapsodo Hitting Pro") ?
                                        list.s3_path ?
                                            list.s3_path.map((_, i) => {
                                                return (
                                                    <>
                                                        <Link onClick={() => this.redirectUrl(_)}>
                                                            {_.split("/")[4]}
                                                        </Link>
                                                        <br />
                                                    </>
                                                )
                                            })
                                            :
                                            "-"
                                        :
                                        "-"
                                }
                            </td>
                            <td className="common_btn btnleft">
                                {
                                    (list.file_type === "FlightScope" ||
                                        list.file_type === "TrackMan" ||
                                        list.file_type === "Yakkertech" ||
                                        list.file_type === "Blast" ||
                                        list.file_type === "TrackMan Portable Hitting" ||
                                        list.file_type === "TrackMan Portable Pitching" ||
                                        list.file_type === "Hittrax" ||
                                        list.file_type === "Savant Hitting" ||
                                        list.file_type === "Savant Pitching" ||
                                        list.file_type === "Pocket Radar" ||
                                        list.file_type === "Stalker Radar") ?
                                        <>
                                            <Link className="blue_btn action_btn" to={`/fan-linkdata/${this.state.eid}/${list._id}`}>Profile Links</Link>
                                            <Link className="gray btn action_btn" onClick={() => this.getRowsData(list._id, list.file_type)}>Edit Rows</Link>
                                        </>
                                        :
                                        (this.renderType(list.file_type) === "Rapsodo Pitching Lite") || (this.renderType(list.file_type) === "Rapsodo Pitching Pro") || (this.renderType(list.file_type) === "Rapsodo Hitting Lite") || (this.renderType(list.file_type) === "Rapsodo Hitting Pro") ?
                                            <>
                                                <Link className="blue_btn action_btn" to={`/fan-linkdata/${this.state.eid}/${list._id}`}>Profile Links</Link>
                                                {/* <Link className="gray btn action_btn" onClick={() => this.getRowsData(list._id, list.file_type)}>View rows</Link> */}
                                            </>
                                            :
                                            <Link
                                                className="blue_btn action_btn"
                                                onClick={() => this.redirectUrlForVideo(list.v_source)}
                                            >
                                                Profile Links
                                            </Link>
                                }

                            </td>
                        </tr>
                    );
                })
                :
                <tr>
                    <td colSpan="6" className="text-center">No Data Found</td>
                </tr>
        )
    }

    renderTableHead = () => {
        return (
            this.state.tableHead.length !== 0 &&
            this.state.tableHead.map((list, i) => {
                if (list.device_column_name) {
                    return (
                        <th scope="col">{list.device_column_name}</th>
                    );
                }
            })
        )
    }

    renderTableData = () => {
        return (
            this.state.tableData.length !== 0 &&
            this.state.tableData.map((td, i) => {
                return (
                    <tr>
                        <td>
                            <Link className="btn light-green btn-addnewevent" onClick={() => this.setCellValue(td)}>Edit</Link>
                        </td>
                        {
                            this.state.tableHead.length !== 0 &&
                            this.state.tableHead.map((th, i) => {
                                if (th.device_column_name) {
                                    return <td>{th.column_name === "associated_videos" ? td[th.column_name].length !== 0 ? <a href={td[th.column_name][0].filePath} target="_blank">{td[th.column_name][0].filePath}</a> : "" : th.column_name === "date" ? moment(td[th.column_name]).format('MM/DD/YYYY') : td[th.column_name]}</td>;
                                }
                            })
                        }
                    </tr>
                )
            })
        )
    }

    setCellValue = (data) => {
        console.log("data:---", data);
        let setArr = [];

        setArr.push({ label: "bcsourceId", value: data.bcsource_id, key: "bcsource_id" })
        setArr.push({ label: "_Id", value: data._id, key: "_id" })

        this.state.tableHead.length !== 0 &&
            this.state.tableHead.map((th, i) => {
                setArr.push({ label: th.device_column_name, value: data[th.column_name], key: th.column_name })
            })

        this.commonCall.setStorage('formData', setArr, flag => {
            if (flag) {
                this.props.history.push(`/fan-edit-device/${this.state.eid}`);
            }
        })
    }

    backToList = () => {
        this.setState({
            openRows: !this.state.openRows
        }, () => {
            this.commonCall.removeStorage("sourceCred");
            this.setLoader(true);
            this.props.getDevice(this.state.eid, "Fan", localStorage.getItem("rosterToken"));
        })
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, openRows, ftype, eventName } = this.state
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {/* Uploadcsv User CSV Data -2 */}
                {
                    !openRows &&
                    <section className="uploadcsv-uploadcsvdata uploadcsv">
                        <div className="container">
                            <div className="white-bg tablecontent">
                                <div className="tableinfo">
                                    <div>
                                        <h3>{eventName}</h3>
                                        {/* <p className="mb-0">User csv details</p> */}
                                    </div>
                                    <div className="rightsection">
                                        <Link className="btn light-green btn-addnewevent" to={"/fan-uploadcsv"}>Back</Link>
                                    </div>
                                </div>
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" colspan="2">Device</th>
                                                <th scope="col" colspan="2">Content</th>
                                                <th scope="col" colspan="2">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderDeviceList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                {/* View Row */}
                {
                    openRows &&
                    <section className="uploadcsv-uploadcsvdata uploadcsv inner-row">
                        <div className="container">
                            <div className="white-bg tablecontent">
                                <div className="tableinfo">
                                    <div>
                                        <h3 className="mb-0">{ftype}</h3>
                                    </div>
                                    <div className="rightsection"><Link className="btn light-green btn-addnewevent" onClick={() => this.backToList()}>Back</Link></div>
                                </div>
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Action</th>
                                                {this.renderTableHead()}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderTableData()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

fanDeviceData.propTypes = {
    getDevice: PropTypes.func.isRequired,
    getDeviceRowsData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default connect(mapStateToProps, { getDevice, getDeviceRowsData })(fanDeviceData);

import axios from 'axios';
import { Auth_Key, API_Url, Authorization, checkIsValidToken } from '../../../helpers/authToken';
import {
    SET_ROLE_REQUEST,
    SET_ROLE_SUCCESS,
    SET_ROLE_ERROR,
} from '../../../constants/authantication/signup/step3.const';
import histrory from '../../../../History';

export const setRole = (setRole) => dispatch => {
    console.log("setRole:--", setRole);
    checkIsValidToken(histrory)
    dispatch({ type: SET_ROLE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/role/set`,
        data: setRole,
        headers: {
            'x-auth-token': Auth_Key,
            'Accept': 'application/json'
        }
    }).then(role => {
        console.log("role:---- ", role);
        dispatch({
            type: SET_ROLE_SUCCESS,
            payload: role.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SET_ROLE_ERROR, error: error.response.data.message })
    });
};


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { imagesArr } from '../../../../assets/images';
import { groundList } from '../../../../saga/actions/authantication/signup/player/ground.action';
import Preloader from '../../../../components/preloader';

class ground extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groundArr: {
                // "year-exp": "",
                "batting-pos": [],
                "fielding-pos": [],
                useHanded:""
            },
            fieldingPos: [
                "CF", "LF", "RF", "2B", "SS", "3B", "P", "1B", "C"
            ],
            battingPos: [
                "Left", "Switch", "Right"
            ],
            loader: false,
        }

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        this.props.onRef(this);
        this.setLoader(true);
        this.props.groundList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.groundDetails &&
            nextProps.groundDetails.groundRes &&
            nextProps.groundDetails.groundData
        ) {
            nextProps.groundDetails.groundRes = false;
            if (nextProps.groundDetails.groundData.hasOwnProperty('_id')) {
                this.setState({
                    groundArr: {
                        ...nextProps.groundDetails.groundData.fieldArr
                    },
                }, () => {
                    nextProps.groundDetails.groundData = null;
                    this.setLoader(false);
                })
            } else {
                this.setLoader(false);
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "year-exp" || name === 'useHanded') {
            this.setState((prevState) => ({
                ...prevState,
                groundArr: {
                    ...prevState.groundArr,
                    [name]: value
                },
            }));
        }
    }

    handleChangePos = (e, item, type) => {
        if (type === "fielding-pos") {
            if (this.state.groundArr['fielding-pos'].find((d) => d === item)) {
                // subtraction
                let tempArray = this.state.groundArr['fielding-pos'].filter((d) => d !== item);
                this.setState((prevState) => ({
                    ...prevState,
                    groundArr: {
                        ...prevState.groundArr,
                        ['fielding-pos']: tempArray
                    },
                }));
            } else {
                // addition
                this.setState((prevState) => ({
                    ...prevState,
                    groundArr: {
                        ...prevState.groundArr,
                        ['fielding-pos']: [...prevState.groundArr['fielding-pos'],item]
                    },
                }));
            }
        } else {
            if (this.state.groundArr['batting-pos'].find((d) => d === item)) {
                // subtraction
                let tempArray = this.state.groundArr['batting-pos'].filter((d) => d !== item);
                this.setState((prevState) => ({
                    ...prevState,
                    groundArr: {
                        ...prevState.groundArr,
                        ['batting-pos']: tempArray
                    },
                }));
            } else {
                // addition
                this.setState((prevState) => ({
                    ...prevState,
                    groundArr: {
                        ...prevState.groundArr,
                        ['batting-pos']: [item]
                    },
                }));
            }
        }
    };

    renderFieldingPos = () => {
        return (
            this.state.fieldingPos &&
            this.state.fieldingPos.map((item, i) => (
                <>
                    <span className={item === "CF" ? 'cf' : item === "LF" ? "lf" : item === "RF" ? "rf" : item === "2B" ? "tow" : item === "SS" ? "ss" : item === "3B" ? "three" : item === "P" ? "gp" : item === "1B" ? "ones" : item === "C" ? "see" : ""}>
                        <div className="inner_ground">
                            <input
                                type="checkbox"
                                id={i}
                                value={item}
                                name="fielding-pos"
                                checked={
                                    this.state.groundArr['fielding-pos'].find((d) => d === item)
                                        ? true
                                        : false
                                }
                                onClick={(e) => this.handleChangePos(e, item, 'fielding-pos')}
                            />
                            <div className="inner_span">{item}</div>
                        </div>
                    </span>
                </>
            ))
        )
    }

    renderBattingPos = () => {
        return (
            this.state.battingPos &&
            this.state.battingPos.map((item, i) => (
                <>
                    <div className="checkbox">
                        <input
                            type="radio"
                            id={i}
                            value={item}
                            name={item}
                            checked={
                                this.state.groundArr['batting-pos'].find((d) => d === item)
                                    ? true
                                    : false
                            }
                            onClick={(e) => this.handleChangePos(e, item, 'batting-pos')}
                        />
                        <label for="Baseball">{item}</label>
                    </div>
                </>
            ))
        )
    }

    renderHandness = () => {
        return (
            <>
                <div className="check-box mb-0">
                    <div className="checkbox">
                        <input type="radio" id="" value="right" name="useHanded" checked={this.state.groundArr['useHanded'] === "right" ? true : false} onClick={(e) => this.onHandleChange(e)} />
                        <label for="Baseball">Right</label>
                    </div>
                    <div className="checkbox">
                        <input type="radio" id="" value="left" name="useHanded" checked={this.state.groundArr['useHanded'] === "left" ? true : false} onClick={(e) => this.onHandleChange(e)} />
                        <label for="Baseball">Left</label>
                    </div>
                    <div className="checkbox">
                        <input type="radio" id="" value="both" name="useHanded" checked={this.state.groundArr['useHanded'] === "both" ? true : false} onClick={(e) => this.onHandleChange(e)} />
                        <label for="Baseball">Both</label>
                    </div>
                </div>
            </>
        )
    }

    validationUpdate = (cb) => {
        if (this.validator.allValid()) {
            cb(this.state.groundArr);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { profileData } = this.props;
        const { groundArr, loader } = this.state;

        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname},
                        </h2>
                            <p>
                                Tell us about your baseball background
                        </p>
                        </div>
                        <div className="row ground">
                            <div className="ground_inner">

                                <label className="common-lable">Select your hand</label>
                                <div className="button_transform">
                                    <div className="check_input">
                                        <div className="check_box">
                                            <div className="   ">
                                                {this.renderHandness()}
                                            </div>
                                        </div>
                                        <span className="validMsg">{this.validator.message('hand position', groundArr.useHanded, 'required')}</span>
                                    </div>
                                </div>

                                <label className="common-lable">Select your position</label>
                                <div className="ground_bg">
                                    <div className="ground_img">
                                        <img src={imagesArr.ground1} alt="ground1" />
                                        {this.renderFieldingPos()}
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('fielding position', groundArr['fielding-pos'], 'required')}</span>
                            </div>
                            <div className="ground_inner">
                                <label className="common-lable">Which side of the plate do you bat from?</label>
                                <div className="ground_bg">
                                    <div className="button_transform">
                                        <div className="check_input">
                                            <div className="check_box">
                                                <div className="check-box">
                                                    {this.renderBattingPos()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ground_img">
                                        <img src={imagesArr.ground2} alt="ground1" />

                                        {this.state.groundArr['batting-pos'].find((d) => d === "Right") &&
                                            <span className="r">
                                                <div className="inner_ground">
                                                    <input type="checkbox" checked />
                                                    <div className="inner_span">R</div>
                                                </div>
                                            </span>
                                        }
                                        {this.state.groundArr['batting-pos'].find((d) => d === "Left") &&
                                            <span className="l">
                                                <div className="inner_ground">
                                                    <input type="checkbox" checked />
                                                    <div className="inner_span">L</div>
                                                </div>
                                            </span>
                                        }
                                        {this.state.groundArr['batting-pos'].find((d) => d === "Switch") &&
                                            <>
                                                <span className="sl">
                                                    <div className="inner_ground">
                                                        <input type="checkbox" checked />
                                                        <div className="inner_span">R</div>
                                                    </div>
                                                </span>
                                                <span className="sr">
                                                    <div className="inner_ground">
                                                        <input type="checkbox" checked />
                                                        <div className="inner_span">L</div>
                                                    </div>
                                                </span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('batting position', groundArr['batting-pos'], 'required')}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

ground.propTypes = {
    groundList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    groundDetails: state.groundPlayerDetails,
});

export default connect(mapStateToProps, { groundList })(ground);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import ProductList from "../../../company/components/common/productList"
import ReactTooltip from 'react-tooltip';
import PwLock from "../../../company/components/common/pwLock";
import moment from 'moment';

class summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "dark",
            effect: "float",
            condition: false,
            eventResult: [],
            forUpdate: false
        };
    }

    componentDidMount = () => {

    }

    returnValue = (item) => {
        return (
            Object.keys(item)[0] === "60TimeSince" ? "60 TIME SINCE" :
                Object.keys(item)[0] === "IFvelosince" ? "IF VELO SINCE" :
                    Object.keys(item)[0] === "ExitVelocity" ? "EXIT VELO SINCE" :
                        Object.keys(item)[0] === "DKMBSSince" ? "DK MBS SINCE" :
                            Object.keys(item)[0] === "DKIMSince" ? "DK IM SINCE" : "DK MA SINCE"
        )
    }

    renderTable = () => {
        return (

            this.props.summaryData &&
            this.props.summaryData.daiamondKinetics &&
            this.props.summaryData.daiamondKinetics.map((item, i) => {
                return (
                    <>
                        <ReactTooltip id={item.category} className="tooltip" type='dark' html={true} />
                        <tr>
                            <td>
                                {item.category}
                                {
                                    item.category === "Max Barrel Speed" &&
                                    <img src={imagesArr.info} data-for={item.category} data-tip="<span>
                                    <b>Max barrel speed (MPH)</b>
                                    <hr />
                                    <b>What is it?</b>
                                <p>The maximum speed of the sweet spot of the barrel of the bat during the swing. The sweet spot is 20% of the bat length from the tip of the bat.</p>
                                <hr />
                                <b>On-field impect</b>
                                <p>High bat speed produces high ball exit speed, so increasing your barrel speed is an important goal. Keep in mind that you need to “square up the ball” to maximize your ball exit speed.</p>
                                </span>"/>
                                }

                                {
                                    item.category === "Impact Momentum" &&
                                    <img src={imagesArr.info} data-for={item.category} data-tip="<span>
                                    <b>Impact momentum (KG/M)</b>
                                    <hr />
                                    <b>What is it?</b>
                                <p>The momentum your bat has at impact. Impact Momentum is a combination of the barrel speed at impact and the weight of the bat.</p>
                                <hr />
                                <b>On-field impect</b>
                                <p>Increasing your impact momentum directly translates to a higher ball exit velocity. Higher impact momentum means better potential for further hits and higher exit velocity.</p>
                                </span>"/>
                                }

                                {
                                    item.category === "Max Acceleration" &&
                                    <img src={imagesArr.info} data-for={item.category} data-tip="<span>
                                    <b>Max acceleration (G's)</b>
                                    <hr />
                                    <b>What is it?</b>
                                <p>The maximum acceleration the bat experiences during the swing, measured in “g’s.” To be clear, acceleration is not how fast the bat is moving; that’s barrel speed. Acceleration determines how quickly you can reach that top speed.</p>
                                <hr />
                                <b>On-field impect</b>
                                <p>More acceleration means that you can reach your max barrel speed quicker. Higher acceleration lets you wait a little longer before you decide to swing - and that’s a good thing! Acceleration is what creates “that sound” elite hitters make at contact</p>
                                </span>"/>
                                }
                            </td>
                            <td>{item["Personal Best"]} MPH</td>
                            <td>{item["Personal Average"]}</td>
                            <td>{item["Class Average"]}</td>
                        </tr>
                    </>
                )
            })
        )
    }

    renderBatting = () => {
        return (
            this.props.battingStats &&
                Object.keys(this.props.battingStats).length !== 0 ?
                Object.keys(this.props.battingStats).map((bat, i) => {
                    return (
                        <tr>
                            <td>{bat}</td>
                            {/* <td>{this.props.battingStats[bat]["2020"] ? this.props.battingStats[bat]["2020"] : "-"}</td> */}
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan="2" className="text-center">No data found</td>
                </tr>
        )
    }

    renderPitching = () => {
        return (
            this.props.pitchingStats &&
                Object.keys(this.props.pitchingStats).length !== 0 ?
                Object.keys(this.props.pitchingStats).map((pitch, i) => {
                    return (
                        <tr>
                            <td>{pitch}</td>
                            {/* <td>{this.props.pitchingStats[pitch]["2020"] ? this.props.pitchingStats[pitch]["2020"] : "-"}</td> */}
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan="2" className="text-center">No data found</td>
                </tr>
        )
    }

    setType = (no) => {
        this.props.profileInfo.statsType = no;
        this.setState((prevState) => ({
            ...prevState,
            forUpdate: !this.state.forUpdate
        }))
    }

    renderTopStatsRow = () => {
        return (
            this.props?.summaryData?.stats?.length !== 0 &&
            this.props?.summaryData?.stats?.map(_ => {
                return (
                    <tr>
                        {this.props?.summaryData?.statsKey?.map(key => {
                            return (
                                <td>{key === "date" ? moment(_[key]).format("MMM D") : _[key] || _[key] === 0 ? _[key] : "-"}</td>
                            )
                        })}
                    </tr>
                )
            })
        )
    }

    render() {
        const { summaryData } = this.props;
        const { statsType } = this.props.profileInfo;
        console.log("statsType:--", statsType);
        // const { statsType } = this.state;
        return (
            <>
                <div className="summary-content">
                    <div className="event-result pw-event-result">
                        <h1 className="section_title">My PW Best</h1>
                        <h5>Performance</h5>
                        <ul>
                            <li>
                                <h3>
                                    {summaryData?.eventresult?.performance ? summaryData.eventresult.performance['10YardDash'] ? summaryData.eventresult.performance['10YardDash'] + " Sec" : 0 + " Sec" : 0 + " Sec"}
                                </h3>
                                <p>10 Yard Split</p>
                            </li>
                            <li>
                                <h3>
                                    {summaryData?.eventresult?.performance ? summaryData.eventresult.performance['60YardDash'] ? summaryData.eventresult.performance['60YardDash'] + " Sec" : 0 + " Sec" : 0 + " Sec"}
                                </h3>
                                <p>60 Yard Dash</p>
                            </li>
                            <li>
                                <h3>
                                    {summaryData?.eventresult?.performance ? summaryData.eventresult.performance['IFvelocity'] ? summaryData.eventresult.performance['IFvelocity'] + " MPH" : 0 + " MPH" : 0 + " MPH"}
                                </h3>
                                <p>IF Velo</p>
                            </li>
                        </ul>
                        <h5>Hitting</h5>
                        <ul>
                            <li>
                                <h3>
                                    {summaryData?.eventresult?.hitting ? summaryData.eventresult.hitting.exitVelocity ? summaryData.eventresult.hitting.exitVelocity + " MPH" : 0 + " MPH" : 0 + " MPH"}
                                </h3>
                                <p>Exit Velo</p>
                            </li>
                            <li>
                                <h3>
                                    {summaryData?.eventresult?.hitting ? summaryData.eventresult.hitting.maxDistance ? summaryData.eventresult.hitting.maxDistance + " MPH" : 0 + " MPH" : 0 + " MPH"}
                                </h3>
                                <p>Max Distance</p>
                            </li>
                        </ul>
                        <h5>Pitching</h5>
                        <ul>
                            <li>
                                <h3>
                                    {summaryData?.eventresult?.pitching ? summaryData.eventresult.pitching.FBvelocity ? summaryData.eventresult.pitching.FBvelocity + " MPH" : 0 + " MPH" : 0 + " MPH"}
                                </h3>
                                <p>FB Velo</p>
                            </li>
                        </ul>
                    </div>
                    <div className="event-result pw-event-result">
                        <h1 className="section_title">Improvement</h1>
                        <h5>Performance</h5>
                        <ul>
                            <li>
                                <h3>{summaryData?.improvement ? summaryData.improvement[0].Performance["10YardDash"] === "" ? 0 : summaryData.improvement[0].Performance["10YardDash"] : "0"} Sec</h3>
                                <p>10 Yard Dash</p>
                            </li>
                            <li>
                                <h3>{summaryData?.improvement ? summaryData.improvement[0].Performance["60YardDash"] === "" ? 0 : summaryData.improvement[0].Performance["60YardDash"] : "0"} Sec</h3>
                                <p>60 Yard Dash</p>
                            </li>
                            <li>
                                <h3>{summaryData?.improvement ? summaryData.improvement[0].Performance["IFvelocity"] === "" ? 0 : summaryData.improvement[0].Performance["IFvelocity"] : "0"} MPH</h3>
                                <p>IF Velo</p>
                            </li>
                        </ul>
                        <h5>Hitting</h5>
                        <ul>
                            <li>
                                <h3>{summaryData?.improvement ? summaryData.improvement[0].Hitting["exitVelocity"] === "" ? 0 : summaryData.improvement[0].Hitting["exitVelocity"] : "0"} MPH</h3>
                                <p>Exit Velo</p>
                            </li>
                            <li>
                                <h3>{summaryData?.improvement ? summaryData.improvement[0].Hitting["maxDistance"] === "" ? 0 : summaryData.improvement[0].Hitting["maxDistance"] : "0"} MPH</h3>
                                <p>Max Distance</p>
                            </li>
                        </ul>
                        <h5>Pitching</h5>
                        <ul>
                            <li>
                                <h3>{summaryData?.improvement ? summaryData.improvement[0].Pitching["FBvelocity"] === "" ? 0 : summaryData.improvement[0].Pitching["FBvelocity"] : "0"} MPH</h3>
                                <p>FB Velo</p>
                            </li>
                        </ul>
                    </div>
                    <div className="to-state">
                        <h1 className="section_title">Recent Game Log</h1>
                        <div className="list-group-scroll">
                            <ul className="list-group mb-15">
                                <li className={`list-group-item ${this.props.profileInfo.sendSummaryReq.statsType === "Hitting" ? "active" : ""}`} onClick={() => this.props.onChangeTopStats("Hitting")}>Hitting</li>
                                <li className={`list-group-item ${this.props.profileInfo.sendSummaryReq.statsType === "Pitching" ? "active" : ""}`} onClick={() => this.props.onChangeTopStats("Pitching")}>Pitching</li>
                            </ul>
                        </div>
                        <div className="avg-table to-state-new-table">
                            <div className="table-responsive-xl">
                                <table>
                                    <thead>
                                        <tr>
                                            {this.props?.summaryData?.statsKey?.length !== 0 &&
                                                this.props?.summaryData?.statsKey?.map(_ => {
                                                    return (
                                                        <th>{_.toUpperCase()}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout")
                                            //  ?
                                                <>
                                                    {this.props?.summaryData?.stats?.length ?
                                                        this.renderTopStatsRow()
                                                        :
                                                        <tr>
                                                            <td colSpan={this.props?.summaryData?.statsKey?.length} style={{ textAlign: 'center' }}>No Data</td>
                                                        </tr>
                                                    }
                                                </>
                                                // :
                                                // <tr>
                                                //     <td colSpan={this.props?.summaryData?.statsKey?.length} style={{ textAlign: 'center' }}>No Data</td>
                                                // </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") &&
                            <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                            } */}

                            {/* <table className="to-state-table">
                                <thead>
                                    <tr>
                                        <th>{statsType === 0 ? "Hitting" : "Pitching"} Stats</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {statsType === 0 ?
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") ?
                                                this.renderBatting()
                                                :
                                                <>
                                                    <tr><td>G</td></tr>
                                                    <tr><td>AB</td></tr>
                                                    <tr><td>R</td></tr>
                                                    <tr><td>A</td></tr>
                                                    <tr><td>C</td></tr>
                                                    <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                                                </>
                                            :
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") ?
                                                this.renderPitching()
                                                :
                                                <>
                                                    <tr><td>G</td></tr>
                                                    <tr><td>AB</td></tr>
                                                    <tr><td>R</td></tr>
                                                    <tr><td>A</td></tr>
                                                    <tr><td>C</td></tr>
                                                    <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                                                </>
                                    }
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
                {/* <div className="avg-table">
                    <div className="avg-table-logo text-center">
                        <img src={imagesArr.dk_logo} />
                    </div>
                    <div className="table-responsive-xl">
                        <table>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Personal Best</th>
                                    <th>Personal Average</th>
                                    <th>Class Average</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                        this.renderTable()
                                        :
                                        <>
                                            <tr>
                                                <td>
                                                    <ReactTooltip id="Max Barrel Speed" className="tooltip" type='dark' html={true} />
                                                    Max Barrel Speed
                                                    <img src={imagesArr.info} data-for="Max Barrel Speed" data-tip="<span>
                                    <b>Max barrel speed (MPH)</b>
                                    <hr />
                                    <b>What is it?</b>
                                <p>The maximum speed of the sweet spot of the barrel of the bat during the swing. The sweet spot is 20% of the bat length from the tip of the bat.</p>
                                <hr />
                                <b>On-field impect</b>
                                <p>High bat speed produces high ball exit speed, so increasing your barrel speed is an important goal. Keep in mind that you need to “square up the ball” to maximize your ball exit speed.</p>
                                </span>"/>
                                                </td>
                                                <td>60 MPH</td>
                                                <td>55.89</td>
                                                <td>55.70</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ReactTooltip id="Impact Momentum" className="tooltip" type='dark' html={true} />
                                                    Impact Momentum
                                                    <img src={imagesArr.info} data-for="Impact Momentum" data-tip="<span>
                                    <b>Impact momentum (KG/M)</b>
                                    <hr />
                                    <b>What is it?</b>
                                <p>The momentum your bat has at impact. Impact Momentum is a combination of the barrel speed at impact and the weight of the bat.</p>
                                <hr />
                                <b>On-field impect</b>
                                <p>Increasing your impact momentum directly translates to a higher ball exit velocity. Higher impact momentum means better potential for further hits and higher exit velocity.</p>
                                </span>"/>
                                                </td>
                                                <td>24.210</td>
                                                <td>20.07</td>
                                                <td>55.90</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ReactTooltip id="Max Acceleration" className="tooltip" type='dark' html={true} />
                                                    Max Acceleration
                                                    <img src={imagesArr.info} data-for="Max Acceleration" data-tip="<span>
                                    <b>Max acceleration (G's)</b>
                                    <hr />
                                    <b>What is it?</b>
                                <p>The maximum acceleration the bat experiences during the swing, measured in “g’s.” To be clear, acceleration is not how fast the bat is moving; that’s barrel speed. Acceleration determines how quickly you can reach that top speed.</p>
                                <hr />
                                <b>On-field impect</b>
                                <p>More acceleration means that you can reach your max barrel speed quicker. Higher acceleration lets you wait a little longer before you decide to swing - and that’s a good thing! Acceleration is what creates “that sound” elite hitters make at contact</p>
                                </span>"/>
                                                </td>
                                                <td>30.296</td>
                                                <td>45.89</td>
                                                <td>60.50</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                                                </td>
                                            </tr>
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div> */}
                <ProductList />
            </>
        )
    }
}

const mapStateToProps = state => ({
    profileInfo: state.profileSummaryInfo,
});

export default connect(mapStateToProps)(summary);

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import AddNewCard from "../../../views/popup/addNewCard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchPaymentInfo,
  fetchBillingInfo,
  deleteCard,
  checkType,
  makeDefaultCard
} from "../../../saga/actions/common/billing.action";
import commonCalls from "../../../businesslogic/commonCalls";
import moment from "moment";
import ErrorMessage from "../../../views/popup/errorMessage";


import Confirmation from '../../popup/confirmation';
import { getActiveList, deleteSubscription, getExpireList, upgradeList } from '../../../saga/actions/common/subscription.action';
import Preloader from '../../../components/preloader';
import UpgradeSubscribe from '../../popup/UpgradeSubscribe';
import SuccessUpgrade from '../../popup/successUpgrade';

class billingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentList: [],
      billingList: [],
      loader: false,
      addCardPopUp: false,
      confirmPopUp: false,
      filterBill: {
        page: 0,
        limit: 0,
      },
      deleteInfo: "",
      removeId: "",
      delMsgData: "",

      activeFilter: {
        limit: 0
      },
      activeList: "",
      expireList: "",
      delId: "",
      deletePopup: false,
      expireFilter: {
        limit: 0
      },
      date: "",
      upgradeArr: [],
      upgradePopup: false,
      subId: "",
      successPopup: false
    };
    this.commonCall = new commonCalls();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setLoader(true);
    this.props.fetchPaymentInfo("Coach");
    this.props.getActiveList(this.state.activeFilter, "Coach");
    this.props.getExpireList(this.state.expireFilter, "Coach");
  }

  componentWillReceiveProps = (nextProps) => {
    //Billing
    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.paymentInfoData) {
      console.log("PAYMENTDATA----->", nextProps._BILLINGDATA.paymentInfoData);
      if (nextProps._BILLINGDATA.paymentInfoData) {
        this.setState(
          {
            paymentList: nextProps._BILLINGDATA.paymentInfoData.data,
          },
          () => {
            nextProps._BILLINGDATA.paymentInfoData = null;
            this.props.fetchBillingInfo(this.state.filterBill, "Coach");
          }
        );
      }
    }

    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.billingInfoData) {
      console.log("BILLINGDATA----->", nextProps._BILLINGDATA.billingInfoData);
      if (nextProps._BILLINGDATA.billingInfoData) {
        this.setState(
          {
            billingList: nextProps._BILLINGDATA.billingInfoData.docs,
          },
          () => {
            nextProps._BILLINGDATA.billingInfoData = null;
            this.setLoader(false);
          }
        );
      }
    }

    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.deleteCardData) {
      console.log("DELETECARD--->", nextProps._BILLINGDATA.deleteCardData)
      if (nextProps._BILLINGDATA.deleteCardData.flag) {
        document.body.classList.remove("modal-open");
        this.setState({
          confirmPopUp: false,
        }, () => {
          this.commonCall.successMessage(nextProps._BILLINGDATA.deleteCardData.message);
          nextProps._BILLINGDATA.deleteCardData = null;
          this.setLoader(false);
          this.props.fetchPaymentInfo("Coach");
        });
      } else {
        nextProps._BILLINGDATA.deleteCardData = null;
        this.commonCall.errorMessage(nextProps._BILLINGDATA.deleteCardData.message);
        this.setLoader(false);
      }
    }

    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.billingInfoData) {
      console.log("BILLINGDATA----->", nextProps._BILLINGDATA.billingInfoData);
      if (nextProps._BILLINGDATA.billingInfoData) {
        this.setState(
          {
            billingList: nextProps._BILLINGDATA.billingInfoData.docs,
          },
          () => {
            nextProps._BILLINGDATA.billingInfoData = null;
            this.setLoader(false);
          }
        );
      }
    }

    if (nextProps._BILLINGDATA &&
      nextProps._BILLINGDATA.checkTypeCardReq &&
      nextProps._BILLINGDATA.checkTypeCardData
    ) {
      nextProps._BILLINGDATA.checkTypeCardReq = false;
      console.log("checkTypeCardData--->", nextProps._BILLINGDATA.checkTypeCardData);
      if (nextProps._BILLINGDATA.checkTypeCardData.flag) {
        this.setState({
          delMsgData: nextProps._BILLINGDATA.checkTypeCardData.result,
          confirmPopUp: true,
        }, () => {
          nextProps._BILLINGDATA.checkTypeCardData = null;
          this.setLoader(false);
        })
      } else {
        nextProps._BILLINGDATA.checkTypeCardData = null;
        this.setLoader(false);
      }
    }

    if (nextProps._BILLINGDATA &&
      nextProps._BILLINGDATA.makeDefaultCardReq &&
      nextProps._BILLINGDATA.makeDefaultCardData
    ) {
      nextProps._BILLINGDATA.makeDefaultCardReq = false;
      if (nextProps._BILLINGDATA.makeDefaultCardData.flag) {
        nextProps._BILLINGDATA.makeDefaultCardData = null;
        this.props.fetchPaymentInfo("Coach");
      } else {
        nextProps._BILLINGDATA.makeDefaultCardData = null;
        this.setLoader(false);
      }
    }

    // subcription
    if (nextProps.subInfo &&
      nextProps.subInfo.getActiveReq &&
      nextProps.subInfo.getActiveData
    ) {
      nextProps.subInfo.getActiveReq = false;
      console.log("nextProps.subInfo.getActiveData:--", nextProps.subInfo.getActiveData);
      if (nextProps.subInfo.getActiveData.flag) {
        this.setState({
          activeList: nextProps.subInfo.getActiveData.result.docs
        }, () => {
          nextProps.subInfo.getActiveData = null;
          this.setLoader(false);
        })
      } else {
        nextProps.subInfo.getActiveData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.subInfo &&
      nextProps.subInfo.delActiveReq &&
      nextProps.subInfo.delActiveData
    ) {
      nextProps.subInfo.delActiveReq = false;
      console.log("nextProps.subInfo.delActiveData:--", nextProps.subInfo.delActiveData);
      if (nextProps.subInfo.delActiveData.flag) {
        document.body.classList.remove('modal-open');
        this.setState({
          delId: "",
          deletePopup: false
        }, () => {
          this.props.getActiveList(this.state.activeFilter, "Coach");
          this.props.getExpireList(this.state.expireFilter, "Coach");
        })
      } else {
        nextProps.subInfo.delActiveData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.subInfo &&
      nextProps.subInfo.getExpireReq &&
      nextProps.subInfo.getExpireData
    ) {
      nextProps.subInfo.getExpireReq = false;
      console.log("nextProps.subInfo.getExpireData:--", nextProps.subInfo.getExpireData);
      if (nextProps.subInfo.getExpireData.flag) {
        this.setState({
          expireList: nextProps.subInfo.getExpireData.result.docs
        }, () => {
          nextProps.subInfo.getExpireData = null;
          this.setLoader(false);
        })
      } else {
        nextProps.subInfo.getExpireData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.subInfo &&
      nextProps.subInfo.getUpgradeReq &&
      nextProps.subInfo.getUpgradeData
    ) {
      nextProps.subInfo.getUpgradeReq = false;
      console.log("nextProps.subInfo.getUpgradeData:--", nextProps.subInfo.getUpgradeData);
      if (nextProps.subInfo.getUpgradeData.flag) {
        this.setState({
          upgradeArr: nextProps.subInfo.getUpgradeData.result.data,
          upgradePopup: !this.state.upgradePopup
        }, () => {
          nextProps.subInfo.getUpgradeData = null;
          this.setLoader(false);
        })
      } else {
        nextProps.subInfo.getUpgradeData = null;
        this.setLoader(false);
      }
    }
  };

  //Billing
  handleDelete = (id, dataType) => {
    console.log("id:-----type:----", id, dataType);
    this.setLoader(true);
    this.props.deleteCard({ card_id: id, type: dataType }, "Coach");
  };

  handleMakeDefaultCard = (id) => {
    this.setLoader(true);
    this.props.makeDefaultCard({ card_id: id }, "Coach");
  }

  renderPaymentList = () => {
    return this.state.paymentList.length !== 0 ? (
      this.state.paymentList.map((e) => {
        return (
          <tr key={e.id} >
            <td>
              <div className="card_holder_lable" >
                <p >
                  {e.billing_details.name} XXXXXX-{e.card.last4}
                </p>
                {e.metadata.isDefault === "false" ? "" : <Link>Default</Link>}
              </div>
            </td>
            <td>
              <p>
                {e.card.exp_month}, {e.card.exp_year}
              </p>
            </td>
            <td>
              <div className="action-btn">
                {
                  e.metadata.isDefault === "false" &&
                  <Link
                    className="green-status"
                    onClick={() => this.handleMakeDefaultCard(e.id)}
                  >
                    Make Default
                  </Link>
                }
                <Link
                  className="red-status"
                  onClick={() => this.handleDeleteCard(e.id)}
                >
                  Delete
                </Link>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5">
          <h3 style={{ textAlign: "center" }}>No Data Found </h3>
        </td>
      </tr>
    );
  };

  setBillingPlan = (typeId, plans) => {
    var isPlans = "";
    plans.length > 0 &&
      plans.forEach((data) => {
        if (data.stripePlanId === typeId) return (isPlans = data);
      });
    return isPlans;
  }

  renderBillingList = () => {
    return this.state.billingList.length !== 0 ? (
      this.state.billingList.map((e) => {
        return (
          <tr>
            <td>
              <p>
                {e.packageId !== null ? e.productId.name : ""}{" "}
                {e.packageId !== null ? this.setBillingPlan(e.priceId, e.packageId.plans).type === 1 ? "Monthly" : "Yearly" : ""}{" "}
                Subscription -  {e.packageId !== null ? e.packageId.type + " " + e.packageId.role : ""}
              </p>
            </td>
            <td>
              <p>$ {e.price}.00</p>
            </td>
            <td>
              <p>
                {moment(e.stripeLog.current_period_start * 1000).format("LL")}
              </p>
            </td>
            <td>
              <p>
                {moment(e.stripeLog.current_period_end * 1000).format("LL")}
              </p>
            </td>
            <td>
              {e.stripeLog.status === "active" ? (
                <Link className="green-status">Active</Link>
              ) : (
                <Link className="red-status">Expire</Link>
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5">
          <h3 style={{ textAlign: "center" }}>No Data Found </h3>
        </td>
      </tr>
    );
  };

  openAddCard = () => {
    this.setState({
      addCardPopUp: true,
    });
  };

  handleDeleteCard = (deleteCardId) => {
    this.setState({
      deleteInfo: deleteCardId,
    }, () => {
      this.setLoader(true);
      this.props.checkType({ card_id: deleteCardId }, "Coach");
    });
  };

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      addCardPopUp: false,
    });
  };

  onHandleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      confirmPopUp: false,
    });
  };

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  //Subcription
  setPlan = (typeId, plans) => {
    var isPlans = "";
    plans.length > 0 &&
      plans.forEach((data) => {
        if (data.stripePlanId === typeId) return (isPlans = data);
      });
    return isPlans;
  }

  gotoLanding = () => {
    localStorage.setItem("scrollLanding", "");
    this.props.history.push("/ballr");
  }

  getUpgrade = (sid) => {
    this.setState({
      subId: sid
    }, () => {
      this.setLoader(true);
      this.props.upgradeList({ subscription_id: sid }, "Coach");
    })
  }

  renderActiveList = () => {
    return (
      this.state.activeList.length !== 0 ?
        this.state.activeList.map((list, i) => {
          return (
            <tr key={i}>
              <th className="title">{list.productId.name} {this.setPlan(list.priceId, list.packageId.plans).type === 1 ? "Monthly" : "Yearly"} Subscription -  {list.packageId.type + " " + list.packageId.role}</th>
              {
                list.stripeLogId &&
                list.stripeLogId.stripeLog &&
                list.stripeLogId.stripeLog.status === "trialing" &&
                list.packageId &&
                list.packageId.trialPeriod > 0 &&
                <td style={{ color: 'red' }}> Free Trial {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_start * 1000).format("DD MMM, YYYY")} - {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_end * 1000).format("DD MMM, YYYY")}</td>
              }
              <td>{(list.isActive && list.isExpire) ? "Good through " : "Renew "} {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
              <td className="status_link text-right">
                {
                  list.isActive &&
                  <Link className="green-status">Active</Link>
                }
                {
                  this.setPlan(list.priceId, list.packageId.plans).type === 2 &&
                    list.packageId.type === "Gold" ?
                    ""
                    :
                    <Link className="blue-status" onClick={() => this.getUpgrade(list._id)}>Upgrade</Link>
                }
              </td>
              <td className="subscription_link">
                {
                  list.isActive && !list.isExpire &&
                  <Link onClick={() => this.cancelSub(list.lastSubStripeId, list.exprireDate)}>Cancel Subscription</Link>
                }
                {
                  !list.isActive && list.isExpire &&
                  <Link style={{ pointerEvents: 'none', color: "lightgray" }}>Cancel Subscription</Link>
                }
                {
                  list.isActive && list.isExpire &&
                  <Link style={{ color: "#0cbf3e" }} onClick={() => this.nextPayment(list.packageId.plans, list.packageId, list)}>Renew Now</Link>
                }
              </td>
            </tr>
          );
        })
        :
        <p className="text-center mb-0">There is no subscription active yet. <Link onClick={() => this.gotoLanding()}>Click Here</Link> to buy now.</p>
    )
  }

  renderExpireList = () => {
    return (
      this.state.expireList.length !== 0 ?
        this.state.expireList.map((list, i) => {
          return (
            <tr key={i}>
              <th className="title">{list.productId.name} {this.setPlan(list.priceId, list.packageId.plans).type === 1 ? "Monthly" : "Yearly"} Subscription -  {list.packageId.role}</th>
              <td className="lightgray" colspan="2">Expires {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
            </tr>
          );
        })
        :
        <p className="text-center mb-0">No Data Found</p>
    )
  }

  cancelSub = (subId, expiryDate) => {
    document.body.classList.add("modal-open");
    this.setState({
      delId: subId,
      deletePopup: true,
      date: expiryDate
    })
  }

  nextPayment = (plan, data, list) => {
    let nextData = {
      packid: data._id,
      plans: plan[0],
      title: data.role + " - " + data.type + " Package",
      trialPeriod: data.trialPeriod
    }
    let url = list.productId.name === "BallR" ? "/ballrapp" : "/pitchrapp";
    localStorage.setItem("popupUrl", url);
    this.commonCall.setStorage("payPlans", nextData, flag => {
      if (flag) {
        if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
          this.props.history.push('/login');
        } else if (localStorage.getItem("coachToken")) {
          this.props.history.push('/coach-payment');
        } else {
          this.props.history.push('/player-payment');
        }
      }
    });
  }

  closePopup = () => {
    document.body.classList.remove('modal-open');
    this.setState({
      delId: "",
      deletePopup: false
    })
  }

  confirmPopup = () => {
    this.setLoader(true);
    this.props.deleteSubscription({
      subscription_id: this.state.delId
    }, "Coach");
  }

  onHandleSubClose = () => {
    document.body.classList.remove('modal-open');
    this.setState({
      upgradePopup: !this.state.upgradePopup,
      upgradeArr: [],
      subId: ""
    })
  }

  onHandleOk = () => {
    this.setState({
      upgradePopup: !this.state.upgradePopup,
      upgradeArr: [],
      subId: "",
      successPopup: true
    })
  }

  onHandleCloseSuccess = () => {
    document.body.classList.remove('modal-open');
    this.setState({
      successPopup: !this.state.successPopup,
    }, () => {
      this.props.getActiveList(this.state.activeFilter, "Coach");
    })
  }

  render() {
    const { addCardPopUp, confirmPopUp, loader, deletePopup, upgradePopup, upgradeArr, subId, successPopup } = this.state;

    return (
      <>
        {/* Billing */}
        <section className="payment_section billing_payment">
          <div className="container">
            <div className="billing_paymethod white-bg">
              <div className="payment-title">
                <h2>Payment Method</h2>
                <Link
                  className="pay_add_btn"
                  onClick={(e) => {
                    this.openAddCard();
                  }}
                >
                  {" "}
                  + Add Card
                </Link>
              </div>
              <div className="payment_list">
                <div className="payment_table">
                  <table>
                    <thead>
                      <tr>
                        <th>Card Holder</th>
                        <th>Expire Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPaymentList()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="billing_paymethod white-bg mb-0">
              <div className="payment-title">
                <h2>Billing History</h2>
              </div>
              <div className="payment_list">
                <div className="billing_table">
                  <table>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Charge</th>
                        <th>Date of Order</th>
                        <th>Date of Expire</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderBillingList()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ErrorMessage
          flag={confirmPopUp}
          close={this.onHandleClose}
          deleteId={this.state.deleteInfo}
          delete={this.handleDelete}
          delMsgData={this.state.delMsgData}
        />

        <AddNewCard
          flag={addCardPopUp}
          close={this.handleClose}
          type="Coach"
        />


        {/* Subcription */}
        <section className="coachsubscription">
          <div className="subscriptioninfo">
            <div className="container">
              <div className="white-bg">
                <h3>Active</h3>
                <p>Your Active Subscription</p>
                <div className="table-responsive-xl">
                  <table className="table">
                    <tbody>
                      {this.renderActiveList()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="subscriptioninfo mb-0">
            <div className="container">
              <div className="white-bg">
                <h3>Expired</h3>
                <p>Your Expired Subscription</p>
                <div className="table-responsive-xl">
                  <table className="table">
                    <tbody>
                      {this.renderExpireList()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Confirmation
          flag={deletePopup}
          type="subcription"
          close={this.closePopup}
          confirm={this.confirmPopup}
          date={this.state.date}
        />
        {
          upgradePopup &&
          <UpgradeSubscribe
            flag={upgradePopup}
            data={upgradeArr}
            close={this.onHandleSubClose}
            sid={subId}
            ok={this.onHandleOk}
            type="Coach"
          />
        }
        {
          successPopup &&
          <SuccessUpgrade
            flag={successPopup}
            close={this.onHandleCloseSuccess}
          />
        }

        <Preloader flag={loader} />
      </>
    );
  }
}

billingInfo.propTypes = {
  fetchPaymentInfo: PropTypes.func.isRequired,
  fetchBillingInfo: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  checkType: PropTypes.func.isRequired,
  makeDefaultCard: PropTypes.func.isRequired,
  getActiveList: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired,
  getExpireList: PropTypes.func.isRequired,
  upgradeList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  _BILLINGDATA: state.billingInfo,
  subInfo: state.subscriptionInfo,
});

const myBillingList = connect(mapStateToProps, {
  fetchPaymentInfo,
  fetchBillingInfo,
  deleteCard,
  checkType,
  makeDefaultCard,
  getActiveList,
  deleteSubscription,
  getExpireList,
  upgradeList
})(billingInfo);
export default withRouter(myBillingList);
import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import CommonHeroBanner from '../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';
import Preloader from '../../components/preloader';
class bollrWebGL extends Component {

    constructor(props) {
        super(props);
        this.state = {
            webGLUrl: "",
            loader: false,
            iframeHeight: 600
        }

    }

    componentDidMount() {
        window.addEventListener("message", (event) => {
            console.log(">>>>>>>>>>>>> ", JSON.parse(event.data));
            this.setState({ iframeHeight: JSON.parse(event.data).height })
        }, false);

        this.setLoader(true);
        if (localStorage.getItem("webglUrl") !== null && localStorage.getItem("webglUrl") !== "undefined") {
            this.setState({
                webGLUrl: localStorage.getItem("webglUrl")
            })
        } else {
            this.setState({
                webGLUrl: process.env.REACT_APP_WEBGL_LINK
            })
        }
    }

    GiveKeyToiframe = () => {
        this.setLoader(false);
        // Create a json object containing the login key to send to the iframe. "webglToken" is case-sensitive.
        var json = { "webglToken": localStorage.getItem("webglToken") }; // Grab the key from the host page's local storage and put it in this json object to send to the iframe

        // Use the ID of the BallR iframe; it is called "iframe" in this doc, but may be called something else on dev/production
        document.getElementById("bollr_iframe").contentWindow.postMessage(JSON.stringify(json), "*"); // Use "https://baseballcloud.com" for production
    }

    maximizeReq = () => {
        document.getElementById('bollr_iframe').requestFullscreen();
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state;
        return (
            <>
                <Header type="webgl" />
                {/* <CommonHeroBanner dataList="" /> */}
                <div className="bollr-webgl" style={{ textAlign: 'center' }}>
                    <iframe onLoad={() => this.GiveKeyToiframe()} id="bollr_iframe" frameborder="0" src={this.state.webGLUrl} height="900" width="1600" allowfullscreen style={{ height: `${this.state.iframeHeight}px` }} />
                    <div className="maximize">
                        <span>Maximize </span><img src={imagesArr.maximize} alt="maximize" width="30" height="30" onClick={() => this.maximizeReq()} />
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

export default bollrWebGL;

import {
    PLAYER_CART_REQUEST,
    PLAYER_CART_SUCCESS,
    PLAYER_CART_ERROR,

    PLAYER_CART_VIDEO_ERROR,
    PLAYER_CART_VIDEO_SUCCESS,

    DEL_VIDEO_REQUEST,
    DEL_VIDEO_SUCCESS,
    DEL_VIDEO_ERROR,

    VIDEO_LIST_ERROR,
    VIDEO_LIST_SUCCESS
} from '../../../constants/view/player/cart.const';

const initialState = {
    cartReq: false,
    cartData: null,
    cartError: null,
    
    videoList:null,
    videoError:null,

    delVideoReq: false,
    delVideoData: null,
    delVideoError: null,

    videoData: null,
    viedoError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // cart req    
        case PLAYER_CART_REQUEST:
            return {
                ...state,
                cartReq: true,
            };
        case PLAYER_CART_SUCCESS:
            return {
                ...state,
                cartData: action.payload,
                
            };
        case PLAYER_CART_ERROR:
            return {
                ...state,
                cartError: action.error,
            };

        case DEL_VIDEO_REQUEST:
            return {
                ...state,
                delVideoReq: true,
            };
        case DEL_VIDEO_SUCCESS:
            return {
                ...state,
                delVideoData: action.payload,
            };
        case DEL_VIDEO_ERROR:
            return {
                ...state,
                delVideoError: action.error,
            };

        case PLAYER_CART_VIDEO_SUCCESS:
            return {
                ...state,
                videoData: action.payload
            }
        case PLAYER_CART_VIDEO_ERROR:
            return {
                ...state,
                viedoError: action.error
            }
            case VIDEO_LIST_SUCCESS:
            return {
                ...state,
                videoList: action.payload,
            }
        case VIDEO_LIST_ERROR:
            return {
                ...state,
                videoError: action.error,
            }
        default:
            return state;
    }
}
import {
    FAN_TEAM_LIST_REQUEST,
    FAN_TEAM_LIST_SUCCESS,
    FAN_TEAM_LIST_ERROR,

    FAN_ADD_TEAM_REQUEST,
    FAN_ADD_TEAM_SUCCESS,
    FAN_ADD_TEAM_ERROR,
} from '../../../../constants/authantication/signup/fan/team.const';

const initialState = {
    searchTeamRes: false,
    searchTeamData: null,
    searchTeamDataError: null,

    saveTeamRes: false,
    saveTeamData: null,
    saveTeamDataError: null,
}

export default function (state = initialState, action) {
    switch (action.type) {

        // Team List
        case FAN_TEAM_LIST_REQUEST:
            return {
                ...state,
                searchTeamRes: true
            };
        case FAN_TEAM_LIST_SUCCESS:
            return {
                ...state,
                searchTeamData: action.payload,
            };
        case FAN_TEAM_LIST_ERROR:
            return {
                ...state,
                searchTeamDataError: action.error,
            };


        // Team List
        case FAN_ADD_TEAM_REQUEST:
            return {
                ...state,
                saveTeamRes: true
            };
        case FAN_ADD_TEAM_SUCCESS:
            return {
                ...state,
                saveTeamData: action.payload,
            };
        case FAN_ADD_TEAM_ERROR:
            return {
                ...state,
                saveTeamDataError: action.error,
            };

        default:
            return state;
    }
}


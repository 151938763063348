import React, { Component } from 'react';
import { Player } from 'video-react';
import Slider from "react-slick";
import { imagesArr } from '../../assets/images';

class LandingVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoArr: []
        }
    }
    
    componentDidMount() {
        if (this.props.flag) {
            document.body.classList.add('video-open');
        }
    }

    render() {
        let { flag } = this.props;
        var videoslider = {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: true,
            infinite: true,
        };

        return (
            <>
                <section className={`videomodal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog">
                        <div className="video-inner">
                            <div className="modal_close" onClick={() => this.props.close()}><i class="fas fa-times"></i></div>
                            <Slider {...videoslider}>
                            <div className="item" >
                                <Player playsInline poster={this.props.video} src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/video1.mp4" {...this.props} />
                            </div>
                            <div className="item" >
                                <Player playsInline poster={this.props.video} src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/video2.mp4" {...this.props} />
                            </div>
                            <div className="item" >
                                <Player playsInline poster={this.props.video} src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/video3.mp4" {...this.props} />
                            </div>
                            <div className="item" >
                                <Player playsInline poster={this.props.video} src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/video4.mp4" {...this.props} />
                            </div>
                            </Slider>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default LandingVideo;

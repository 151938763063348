import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken, checkIsValidFanToken, Auth_Fan_Key } from '../../../../helpers/authToken';
import {
    LIST_LINKED_PLAYER_FAN_REQUEST,
    LIST_LINKED_PLAYER_FAN_SUCCESS,
    LIST_LINKED_PLAYER_FAN_ERROR,
} from '../../../../constants/view/fan/setting/linkedPlayerInfo.const';
import histrory from '../../../../../History';

export const listLinkedPlayer = (data) => dispatch => {
    checkIsValidFanToken(histrory);
    dispatch({ type: LIST_LINKED_PLAYER_FAN_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/fan/linked/player/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: LIST_LINKED_PLAYER_FAN_SUCCESS,
            payload: addnew.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: LIST_LINKED_PLAYER_FAN_ERROR, error: error.response.data.message })
        }
    });
};

import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_COACH_SEARCH_REQUEST,
    GET_PLAYER_COACH_SEARCH_SUCCESS,
    GET_PLAYER_COACH_SEARCH_ERROR,

    SAVE_PLAYER_COACH_REQUEST,
    SAVE_PLAYER_COACH_SUCCESS,
    SAVE_PLAYER_COACH_ERROR,

    UPDATE_PLAYER_COACH_REQUEST,
    UPDATE_PLAYER_COACH_SUCCESS,
    UPDATE_PLAYER_COACH_ERROR,

    ROSTER_LIST_REQUEST,
    ROSTER_LIST_SUCCESS,
    ROSTER_LIST_ERROR,

    SAVE_COACH_ROSTER_REQUEST,
    SAVE_COACH_ROSTER_SUCCESS,
    SAVE_COACH_ROSTER_ERROR
} from '../../../../constants/authantication/signup/player/coach.const';
import histrory from '../../../../../History';

export const coachList = (data) => dispatch => {
    // data.limit = data.limit;
    // data.page = data.page;
    // data.parentTeam = data.parentTeam;
    // data.name = data.search;
    // delete data.search
    console.log("data:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_COACH_SEARCH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}team/child/search`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(searchteam => {
        dispatch({
            type: GET_PLAYER_COACH_SEARCH_SUCCESS,
            payload: searchteam.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_PLAYER_COACH_SEARCH_ERROR, error: error.response.data.message })
    });
};

export const savePlayerCoach = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_PLAYER_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/team`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(coach => {
        dispatch({
            type: SAVE_PLAYER_COACH_SUCCESS,
            payload: coach.data
        })

    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_PLAYER_COACH_ERROR, error: error.response.data.message })
    });
};

export const updateTeam = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: UPDATE_PLAYER_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/join/team/request`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(updateteam => {
        dispatch({
            type: UPDATE_PLAYER_COACH_SUCCESS,
            payload: updateteam.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: UPDATE_PLAYER_COACH_ERROR, error: error.response.data.message })
    });
};

export const rosterList = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: ROSTER_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/rosterTeam/player/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(roster => {
        dispatch({
            type: ROSTER_LIST_SUCCESS,
            payload: roster.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: ROSTER_LIST_ERROR, error: error.response.data.message })
    });
};

export const saveRoster = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_COACH_ROSTER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/rosterTeam/player`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(roster => {
        dispatch({
            type: SAVE_COACH_ROSTER_SUCCESS,
            payload: roster.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_COACH_ROSTER_ERROR, error: error.response.data.message })
    });
};
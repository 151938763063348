import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import JoinEvent from './joinForm/joinEvent';
import Payment from './joinForm/payment';
import ShopingCart from './joinForm/shopingCart';
import RegistrationAgegroup from './joinForm/registrationAgegroup';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import { imagesArr } from "../../../../assets/images";
import commonCalls from "../../../../businesslogic/commonCalls";
import { getEventDetails, getRosterOption, fetchPaymentIntent, getCostByAge, eventRegistrationPayment,getEventRegistrationAge, eventPaymentComplete, getAgeVerification, getRosterAmount, rendCoupanCode,getEventLinkedPlayerList } from '../../../../saga/actions/common/eventDetails.action';
import { postFileUpload } from "../../../../saga/actions/common/api.action";
import Preloader from "../../../../components/preloader";
import CreditCard from "../payment/creditCard";
import EventRow from './details/eventRow';
import Confirmation from '../../../../views/popup/confirmation';
import { getMinOfKeys } from "../../../../businesslogic/content";
class eventPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            eventId: "",
            loader: false,
            eventDetailData: [],
            rosterList: [],
            eventJoin: {
                event: "",
                isManager: true,
                managerName: "",
                managerEmail: "",
                managerPhone: "",
                waiversImg: "",
                insuranceImg: "",
                paymentType: 0,
                couponCode: "",
                metaPayment: {},
                paymentMethod: "",
                amount: 0,
                question: "",
                products: [],
                positionRegistration: false,
                pitcherRegistration: false,
                twoWayRegistration: false,
                analyzrReportpay: false,
                ageGroup: "",
                ageGroupTo: "",
                waiverUrlClick: "no",
                slectedGroupFeeId: "",
                companyThrough: localStorage.getItem("company") === 'dc' ? 2 : 1
            },
            managePayMethod: false,
            insuranceName: "",
            isShowPayment: true,
            showPaymentButton: false,
            creditCard: false,
            rosterPopup: false,
            rosterMsg: "",
            rosterAmount: "",
            fillTab: 0,
            eventAmount: 0,
            finalProducts: [],
            productConfirm: false,
            regiProduct: "",
            analReport: "",
            playerId:"",
            showAmount: false,
            disableStep: "",
            isDisableNext: false,
            isPayLater: false,
            rosterDisable:false,
            linkedPlayerListOpt:[]
        }
        this.joinEvent = null;
        this.ageGroup = null;
        this.eventPay = null;
        this.shopingCart = null;
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        localStorage.removeItem("installment");
        this.setState({
            eventId: eventId,
            eventJoin: {
                ...this.state.eventJoin,
                event: eventId,
            },
            sendCoupanReq: {
                ...this.state.sendCoupanReq,
                eventId: eventId
            }
        }, () => {
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        let type=nextProps.profileDetails.commonData?.result?.profile?.parentTeam?.type;
        if (nextProps.eventDetails &&
            nextProps.eventDetails.setEventRegiAgeReq &&
            nextProps.eventDetails.setEventRegiAgeData && ((localStorage.getItem("coachToken") && ( type == 4 || type == 1)) || localStorage.getItem("role")==="Parent")
        ) {
            nextProps.eventDetails.setEventRegiAgeReq = false;
            let isFlag=nextProps.eventDetails.setEventRegiAgeData.flag;
            if(nextProps.eventDetails.setEventRegiAgeData.isNext){
                this.ageGroup.validationUpdate((flag, price, slectedGroupFeeId) => {
                    if (flag &&  isFlag) {
                        if (this.state.fillTab > 1) {
                            this.setState({
                                tabs: this.state.tabs + 1,
                                fillTab: this.state.fillTab + 1,
                                eventJoin: {
                                    ...this.state.eventJoin,
                                    slectedGroupFeeId: slectedGroupFeeId
                                }
                            })
                        } else {
                            this.setState({
                                eventDetailData: {
                                    ...this.state.eventDetailData,
                                    payment: {
                                        ...this.state.eventDetailData.payment,
                                        feesTournament: price
                                    }
                                }
                            }, () => {
                                this.setState({
                                    tabs:isFlag ? this.state.tabs + 1:this.state.tabs,
                                    fillTab:isFlag ?this.state.fillTab + 1:this.state.fillTab,
                                    eventJoin: {
                                        ...this.state.eventJoin,
                                        slectedGroupFeeId: slectedGroupFeeId
                                    }
                                })
                            })
                        }
                        nextProps.eventDetails.setEventRegiAgeData=null;
                    }
                });
                if (!isFlag) {
                    this.commonCall.errorMessage(nextProps.eventDetails.setEventRegiAgeData.message);
                    nextProps.eventDetails.setEventRegiAgeData = null;
                    this.setLoader(false);
                } 
            }
        }
        if(nextProps.eventDetails && !nextProps.eventDetails.getLinkedPlayerReq && ((localStorage.getItem("coachToken") && ( type == 4 || type == 1)) || localStorage.getItem("role")==="Parent")){
            if(this.state.eventId !==""){
                nextProps.getEventLinkedPlayerList({eventId:this.state.eventId})
            }
        }
        if(nextProps.eventDetails && nextProps.eventDetails.getLinkedPlayerReq && nextProps.eventDetails.getLinkedPlayerData){
            let arr= nextProps.eventDetails.getLinkedPlayerData?.result?.data[0]?.map((i)=>({label:i.fname+" "+i.lname,value:i._id}))
            this.setState({
                linkedPlayerListOpt: arr
            })
        }
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            if (nextProps.eventDetails.getDetailsData.flag) {
                let proArr = [];
                let fdata = nextProps.eventDetails.getDetailsData.result.data[0];
                let roster = fdata.payment.feesByGroups.find(_=> { if (_.hasOwnProperty('roster')) { return _; }});
                this.setState({
                    eventDetailData: fdata,
                    eventJoin: {
                        ...this.state.eventJoin,
                        question: fdata.questions && fdata.questions,
                        isManager: fdata.settings.haveTeamManger,
                        amount: fdata.payment.feesTournament,
                        rosterTeamId : roster ? roster.roster._id : "",
                    },
                    rosterDisable : roster ? true : false
                }, () => {
                    let setType = localStorage.getItem("ageGroupData") ? JSON.parse(localStorage.getItem("ageGroupData")) : this.state.eventDetailData.payment;
                    if (!this.state.eventDetailData.settings.haveTeamRoster &&
                        !this.state.eventDetailData.settings.haveWaiver &&
                        !this.state.eventDetailData.settings.isShowInsurance &&
                        this.state.eventDetailData.questions.length === 0) {
                        this.setState({
                            disableStep: 0,
                            fillTab: this.state.eventDetailData.eventTeamType === 2 ? 1 : (setType.paymentType.length === 1 &&
                                setType.paymentType.indexOf(2) !== -1) ? 0 : 3,
                            tabs: this.state.eventDetailData.eventTeamType === 2 ? 1 : (setType.paymentType.length === 1 &&
                                setType.paymentType.indexOf(2) !== -1) ? 0 : 3,
                            eventJoin: {
                                ...this.state.eventJoin,
                                slectedGroupFeeId: this.state.eventDetailData.eventTeamType === 1 ? setType._id : ""
                            },
                        })
                    }

                    if (setType.paymentType.length === 1 &&
                        setType.paymentType.indexOf(2) !== -1
                    ) {
                        this.setState({
                            eventJoin: {
                                ...this.state.eventJoin,
                                paymentType: 2
                            },
                            isShowPayment: false,
                        });
                    } else {
                        if (localStorage.getItem("ageGroupData")) {
                            let data = JSON.parse(localStorage.getItem("ageGroupData"));
                            this.state.eventDetailData.payment.feesTournament = data.feesOfGroup;
                            this.state.eventDetailData.payment.paymentType = data.paymentType;
                            this.state.eventDetailData.payment.installments = data.installments;
                            this.state.eventDetailData.payment.downpaidAmt = data.downpaidAmt;
                            this.state.eventJoin.downpaidAmt = data.downpaidAmt;
                        } else {
                            let myArray;
                            myArray = setType.feesByGroups.sort(function (a, b) {
                                return a.feesOfGroup - b.feesOfGroup
                            })

                            var minObj = myArray[0];
                            let price = getMinOfKeys(setType.feesByGroups, ['feesOfGroup']);
                            this.state.eventDetailData.payment.feesTournament = price;
                            this.state.eventDetailData.payment.paymentType = minObj.paymentType;
                            this.state.eventDetailData.payment.installments = minObj.installments;

                            this.setState({
                                eventAmount: price
                            })
                        }
                    }

                    nextProps.eventDetails.getDetailsData = null;
                    if (this.state.eventDetailData.eventTeamType === 1 && this.state.eventDetailData.settings.haveTeamRoster) {
                        let sendRosterReq;
                        if (localStorage.getItem("ageGroupData")) {
                            let data = JSON.parse(localStorage.getItem("ageGroupData"));
                            sendRosterReq = {
                                teamRoster: 1,
                                ageGroup: data.ageGroup,
                                ageGroupTo: data.ageGroupTo,
                                event: this.state.eventId
                            }
                        } else {
                            sendRosterReq = {
                                teamRoster: 1,
                                ageGroup: 0,
                                ageGroupTo: 0,
                                event: this.state.eventId
                            }
                        }
                        this.props.getRosterOption(sendRosterReq);
                    } else {
                        this.setLoader(false);
                    }

                    if (fdata.chargeReport) {
                        this.setState({
                            analReport: "yes",
                            eventJoin: {
                                ...this.state.eventJoin,
                                analyzrReportpay: true,
                            },
                            finalProducts: [...this.state.finalProducts, {
                                _id: this.state.finalProducts.length + 1,
                                key: true,
                                name: "AnalyzR Report",
                                price: !fdata.chargeReport ? fdata.feeReport : 0,
                                rewardBanner: { filePath: imagesArr.headinglogo5 },
                                startDate: "",
                                endDate: ""
                            }]
                        })
                    }

                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getRosterOptReq &&
            nextProps.eventDetails.getRosterOptData
        ) {
            nextProps.eventDetails.getRosterOptReq = false;
            if (nextProps.eventDetails.getRosterOptData.flag) {
                this.setState({
                    rosterList: nextProps.eventDetails.getRosterOptData.result.data,
                }, () => {
                    nextProps.eventDetails.getRosterOptData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getRosterOptData.message);
                nextProps.eventDetails.getRosterOptData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getPaymentIntentReq &&
            nextProps.eventDetails.getPaymentIntentData
        ) {
            nextProps.eventDetails.getPaymentIntentReq = false;
            if (nextProps.eventDetails.getPaymentIntentData.flag) {
                localStorage.setItem(
                    "stripeIntent",
                    nextProps.eventDetails.getPaymentIntentData.result.data
                );
                this.setState({
                    eventJoin: {
                        ...this.state.eventJoin,
                        paymentMethod: this.state.eventJoin.paymentMethod === 1 ? "" : 1,
                    },
                    creditCard: true
                });
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getPaymentIntentData.message);
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.commonApiDetails &&
            nextProps.commonApiDetails.fileUploadData) {
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    insuranceImg: nextProps.commonApiDetails.fileUploadData,
                },
                insuranceName: nextProps.commonApiDetails.fileUploadData.originalname,
            }, () => {
                nextProps.commonApiDetails.fileUploadData = null;
                this.setLoader(false);
            });
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.eventRegistrationReq &&
            nextProps.eventDetails.eventRegistrationData
        ) {
            nextProps.eventDetails.eventRegistrationReq = false;
            if (nextProps.eventDetails.eventRegistrationData.flag) {
                nextProps.eventDetails.eventRegistrationData = null;
                var data = {
                    event: this.state.eventJoin.event,
                    couponCode: this.state.eventJoin.couponCode,
                    paymentType: this.state.eventJoin.paymentType,
                    paymentMethod: this.state.eventJoin.paymentType,
                    amount: this.state.eventJoin.amount,
                    installments: this.state.eventDetailData.payment.installments,
                    metaPayment: this.state.eventJoin.metaPayment,
                    rosterTeamId: this.state.eventJoin.rosterTeamId
                };
                this.props.eventPaymentComplete(this.state.eventDetailData.eventTeamType === 2 && (localStorage.getItem("role") === "Coach" ||
                localStorage.getItem("role")  === "Parent") ? {...data,playerId: this.state.playerId?.value} :data);
                this.props.history.push(`/event-joined/${this.state.eventJoin.event}`);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getAgeVerifyReq &&
            nextProps.eventDetails.getAgeVerifyData
        ) {
            nextProps.eventDetails.getAgeVerifyReq = false;
            if (nextProps.eventDetails.getAgeVerifyData.flag) {
                this.setState({
                    rosterPopup: nextProps.eventDetails.getAgeVerifyData.result.pop,
                    rosterMsg: nextProps.eventDetails.getAgeVerifyData.result.msg
                }, () => {
                    nextProps.eventDetails.getAgeVerifyData = null;
                    let setRosterId = localStorage.getItem("ageGroupData") ? JSON.parse(localStorage.getItem("ageGroupData"))._id : "";
                    this.props.getRosterAmount({ eventId: this.state.eventId, rosterId: this.state.eventJoin.rosterTeamId, slectedGroupFeeId: setRosterId });
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getAgeVerifyData.message);
                nextProps.eventDetails.getAgeVerifyData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getRosterAmountReq &&
            nextProps.eventDetails.getRosterAmountData
        ) {
            nextProps.eventDetails.getRosterAmountReq = false;
            if (nextProps.eventDetails.getRosterAmountData.flag) {
                let alldata = nextProps.eventDetails.getRosterAmountData.result;
                let price = nextProps.eventDetails.getRosterAmountData.result.feesOfGroup;
                this.state.eventDetailData.payment.feesTournament = price;
                this.state.eventDetailData.payment.paymentType = alldata.paymentType;
                this.state.eventDetailData.payment.installments = alldata.installments;

                // if (this.state.eventDetailData.eventPriority === 2) {
                //     this.setState({
                //         eventJoin: {
                //             ...this.state.eventJoin,
                //             paymentType: 2
                //         },
                //         isShowPayment: false,
                //     });
                // } else {
                    if (this.state.eventDetailData.payment.paymentType.length === 1 &&
                        this.state.eventDetailData.payment.paymentType.indexOf(2) !== -1
                    ) {
                        this.setState({
                            eventJoin: {
                                ...this.state.eventJoin,
                                paymentType: 2
                            },
                            isShowPayment: false,
                        });
                    } else {
                        this.setState({
                            isShowPayment: true,
                        });
                    }
                // }

                this.setState({
                    eventAmount: price,
                    eventJoin: {
                        ...this.state.eventJoin,
                        slectedGroupFeeId: nextProps.eventDetails.getRosterAmountData.result._id
                    },
                }, () => {
                    this.state.finalProducts.forEach((item) => {
                        if (item._id === this.state.eventId) {
                            Object.assign(item, { price: price });
                        }
                    });
                    this.setState({
                        finalProducts: this.state.finalProducts,
                        eventJoin: {
                            ...this.state.eventJoin,
                            ageGroup: nextProps.eventDetails.getRosterAmountData.result.ageGroup,
                            ageGroupTo: nextProps.eventDetails.getRosterAmountData.result.ageGroupTo
                        }
                    }, () => {
                        nextProps.eventDetails.getRosterAmountData = null;
                        this.setLoader(false);
                    })
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getRosterAmountData.message);
                nextProps.eventDetails.getRosterAmountData = null;
                this.setState({
                    // rosterPopup: false,
                    // rosterMsg: "",
                    isDisableNext: true
                }, () => {
                    // document.body.classList.remove('modal-open');
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getCoupanCodeReq &&
            nextProps.eventDetails.getCoupanCodeData
        ) {
            nextProps.eventDetails.getCoupanCodeReq = false;
            if (nextProps.eventDetails.getCoupanCodeData.flag) {
                if (nextProps.eventDetails.getCoupanCodeData.result.newFeesTournament === 0) {
                    localStorage.setItem("zeroAmount", true);
                    this.state.eventDetailData.payment.feesTournament = nextProps.eventDetails.getCoupanCodeData.result.newFeesTournament;
                    this.state.eventJoin.amount = nextProps.eventDetails.getCoupanCodeData.result.newFeesTournament;
                    this.setState({
                        isPayLater: true,
                    }, () => {
                        nextProps.eventDetails.getCoupanCodeData = null;
                        this.setLoader(false);
                    });
                } else {
                    this.state.eventDetailData.payment.feesTournament = nextProps.eventDetails.getCoupanCodeData.result.newFeesTournament;
                    this.state.eventJoin.amount = nextProps.eventDetails.getCoupanCodeData.result.newFeesTournament;
                    nextProps.eventDetails.getCoupanCodeData = null;
                    // var payment = {
                    //     amount: this.state.eventDetailData.payment.feesTournament,
                    //     objetId: this.state.eventDetailData._id,
                    //     module: 1,
                    //     method: "stripe",
                    // };
                    // this.props.fetchPaymentIntent(payment);
                    this.setLoader(false);
                }
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getCoupanCodeData.message);
                nextProps.eventDetails.getCoupanCodeData = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        let type=this.props.profileDetails.commonData?.result?.profile?.parentTeam?.type
        this.props.getEventDetails(this.state.eventId, true);
        if(((localStorage.getItem("coachToken") && ( type == 4 || type == 1)) || localStorage.getItem("role")==="Parent") && this.state.eventId !==""){
            this.props.getEventLinkedPlayerList({eventId:this.state.eventId})
        }
    }

    goBackToEventDetail = () => {
        this.props.history.push(`/event-detail/${this.state.eventId}`);
    };

    handleTabs = (e) => {
        if (this.state.fillTab <= e) {
            this.setState({
                tabs: this.state.fillTab
            })
        } else {
            this.setState({
                tabs: e
            })
        }
    }

    handleNext = () => {
        let type=this.props.profileDetails.commonData?.result?.profile?.parentTeam?.type
        if (this.state.tabs === 0) {
            this.joinEvent.validationUpdate(flag => {
                if (flag) {
                    this.setState({
                        tabs: this.state.eventDetailData.eventTeamType === 2 ? this.state.tabs + 1 : this.state.tabs + 3,
                        fillTab: this.state.eventDetailData.eventTeamType === 2 ? this.state.fillTab + 1 : this.state.fillTab + 3,
                    })
                }
            });
        } else {
            if (this.state.eventDetailData.eventTeamType === 2 && this.state.tabs === 1) {
                this.ageGroup.validationUpdate((flag, price, slectedGroupFeeId) => {
                    if (flag) {
                            if((localStorage.getItem("coachToken") && ( type == 4 || type == 1)) || localStorage.getItem("role")==="Parent"){
                                this.props.getEventRegistrationAge({ event: this.state.eventId,playerId: this.state.playerId?.value})
                            } else {
                            if (this.state.fillTab > 1) {
                                this.setState({
                                    tabs: this.state.tabs + 1,
                                    fillTab: this.state.fillTab + 1,
                                    eventJoin: {
                                        ...this.state.eventJoin,
                                        slectedGroupFeeId: slectedGroupFeeId
                                    }
                                })
                            } else {
                                this.setState({
                                    eventDetailData: {
                                        ...this.state.eventDetailData,
                                        payment: {
                                            ...this.state.eventDetailData.payment,
                                            feesTournament: price
                                        }
                                    }
                                }, () => {
                                    this.setState({
                                        tabs: this.state.tabs + 1,
                                        fillTab: this.state.fillTab + 1,
                                        eventJoin: {
                                            ...this.state.eventJoin,
                                            slectedGroupFeeId: slectedGroupFeeId
                                        }
                                    })
                                })
                            }
                        }
                        }
                    });
                
            } else {
                if (this.state.tabs > 1) {
                    this.setState({
                        showAmount: true
                    })
                }

                if (this.state.eventDetailData.eventTeamType === 2) {
                    let totalAmount = this.state.finalProducts.reduce(function (sum, current) {
                        return sum + current.price;
                    }, 0);
                    this.state.eventDetailData.payment.feesTournament = totalAmount;
                }

                this.setState({
                    tabs: this.state.tabs + 1,
                    fillTab: this.state.fillTab + 1,
                })
            }
        }
    }

    handleBack = () => {
        let tab = this.state.eventDetailData.eventTeamType === 2 ? 1 : 3;
        if (this.state.tabs === 0 || (this.state.disableStep === 0 && this.state.tabs === tab)) {
            this.props.history.push(`/event-detail/${this.state.eventId}`);
        } else {
            this.setState({
                tabs: this.state.eventDetailData.eventTeamType === 2 ? this.state.tabs - 1 : this.state.tabs - 3
            }, () => {
                if (this.state.eventDetailData.eventTeamType === 2) {
                    if (this.state.tabs >= 3) {
                        this.setState({
                            showAmount: true
                        })
                    } else {
                        this.setState({
                            showAmount: false
                        })
                    }
                }
            })
        }
    }

    onHandleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "insuranceImg") {
            if (value) {
                this.setLoader(true);
                this.props.postFileUpload(files[0], 24);
            }
        } else if (name === "managerPhone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState({
                        eventJoin: {
                            ...this.state.eventJoin,
                            [name]: value,
                        }
                    });
                }
            }
        } else if (name === "creditcard") {
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    paymentMethod: this.state.eventJoin.paymentMethod === 1 ? "" : 1,
                },
                creditCard: true
            });
        } else if (name === "paymentType") {
            var amount = 0;
            var paymentType = JSON.parse(value);
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    [name]: paymentType,
                },
                eventAmount: this.state.eventDetailData.payment.feesTournament
            }, () => {
                if (paymentType === 1 || paymentType === 2)
                    amount = this.state.eventDetailData.payment.feesTournament;
                else if (paymentType === 3)
                    amount = this.state.eventDetailData.payment.downpaidAmt;
                else amount = 0;
                this.setState({
                    eventJoin: {
                        ...this.state.eventJoin,
                        amount: amount,
                    },
                    managePayMethod: paymentType === 2 ? true : false,
                }, () => {

                    if (paymentType === 1 || paymentType === 3) {
                        if (paymentType === 3 && this.state.eventDetailData.payment.downpaidAmt === 0)
                            return this.setState({
                                isPayLater: true,
                            })
                        else return this.setState({
                            isPayLater: false,
                        }, () => {
                            // var payment = {
                            //     amount: amount,
                            //     objetId: this.state.eventDetailData._id,
                            //     module: 1,
                            //     method: "stripe",
                            // };
                            // console.log("payment:---", payment);
                            // this.setLoader(true);
                            // this.props.fetchPaymentIntent(payment);
                        });
                    } else {
                        this.setState({
                            isPayLater: true,
                        });
                    }
                })
            });
        } else if (name === "rosterTeamId") {
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    [name]: value,
                }
            }, () => {
                if (value !== "") {
                    this.setLoader(true);
                    this.props.getAgeVerification({ roasterId: value });
                }
            });
        } else {
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    [name]: (name === "isManager") ? JSON.parse(value) : value,
                },
                managePayMethod: this.state.eventJoin.paymentType === 2 ? true : false,
            });
        }
    }

    onHandleProduct = (item) => {
        let oldFPro = this.state.finalProducts.some((items) => {
            return items._id === item._id;
        });

        let oldEventPro = this.state.eventJoin.products.some((items) => {
            return items._id === item._id;
        });

        if (oldFPro) {
            this.state.finalProducts.forEach((del, i) => {
                if (del._id === item._id) {
                    this.state.finalProducts.splice(i, 1);
                }
            });
            this.setState({
                finalProducts: this.state.finalProducts
            })
        } else {
            let addPro = {
                _id: item._id,
                name: item.name,
                price: item.price,
                rewardBanner: item.rewardBanner,
                startDate: "",
                endDate: ""
            }
            this.setState({
                finalProducts: [...this.state.finalProducts, addPro],
            });
        }

        if (oldEventPro) {
            this.state.eventJoin.products.forEach((del, i) => {
                if (del._id === item._id) {
                    this.state.eventJoin.products.splice(i, 1);
                }
            });
            this.setState({
                eventJoin: this.state.eventJoin
            })
        } else {
            let addPro = {
                _id: item._id,
                name: item.name,
                price: item.price,
                rewardBanner: item.rewardBanner
            }
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    products: [...this.state.eventJoin.products, addPro],
                }
            })
        }
    }

    removeInsurance = () => {
        this.setState({
            eventJoin: {
                ...this.state.eventJoin,
                insuranceImg: "",
            },
            insuranceName: "",
        });
    }

    handleQA = (e) => {
        const { name, value } = e.target;
        var questions = this.state.eventJoin.question;
        questions.forEach((question) => {
            if (question._id === name) question.answer = value;
        });
        this.setState({
            eventJoin: {
                ...this.state.eventJoin,
                question: questions,
            },
        });
    }

    onHandleRegiProduct = (type, key, val) => {
        if (type === "option") {
            this.state.finalProducts.map((item, i) => {
                if (item.key === "option") {
                    this.state.finalProducts.splice(i, 1)
                }
            })
            this.setState({
                regiProduct: key,
                eventJoin: {
                    ...this.state.eventJoin,
                    positionRegistration: key === "Position" ? true : false,
                    pitcherRegistration: key === "Pitcher" ? true : false,
                    twoWayRegistration: key === "2Way" ? true : false,
                },
                finalProducts: [...this.state.finalProducts, {
                    _id: this.state.finalProducts.length + 1,
                    key: "option",
                    name: val.label,
                    price: val.fee,
                    rewardBanner: { filePath: this.state.eventDetailData.eventImages.filePath },
                    startDate: "",
                    endDate: ""
                }]
            })
        }

        if (type === "add") {
            this.setState({
                analReport: key,
                eventJoin: {
                    ...this.state.eventJoin,
                    analyzrReportpay: true,
                },
                finalProducts: [...this.state.finalProducts, {
                    _id: this.state.finalProducts.length + 1,
                    key: key,
                    name: "AnalyzR Report",
                    price: !val.chargeReport ? val.feeReport : 0,
                    rewardBanner: { filePath: imagesArr.headinglogo5 },
                    startDate: "",
                    endDate: ""
                }]
            })
        }

        if (type === "remove") {
            this.setState({
                analReport: key,
                eventJoin: {
                    ...this.state.eventJoin,
                    analyzrReportpay: false,
                },
            }, () => {
                this.state.finalProducts.map((item, i) => {
                    if (item.key === "yes") {
                        this.state.finalProducts.splice(i, 1)
                    }
                })
                this.setState({
                    finalProducts: this.state.finalProducts
                })
            })
        }

    }

    changeAgeGroup = (data) => {
        this.setState({
            eventJoin: {
                ...this.state.eventJoin,
                ageGroup: data.ageGroup,
                ageGroupTo: data.ageGroupTo
            }
        },()=>{
            this.state.eventDetailData.payment.paymentType = data.paymentType;
            if (data.paymentType.length === 1 &&
                data.paymentType.indexOf(2) !== -1
            ) {
                this.setState({
                    eventJoin: {
                        ...this.state.eventJoin,
                        paymentType: 2
                    },
                    isShowPayment: false,
                });
            } else {
                this.setState({
                    isShowPayment: true,
                });
            }
        })
    }

    setWaiverFlag = () => {
        this.setState({
            eventJoin: {
                ...this.state.eventJoin,
                waiverUrlClick: "yes",
            }
        })
    }

    applyCode = () => {
        let sendReq;
        if (this.state.eventDetailData.eventTeamType === 1) {
            sendReq = {
                eventId: this.state.eventId,
                couponCode: this.state.eventJoin.couponCode,
                slectedGroupFeeId: this.state.eventJoin.slectedGroupFeeId ? this.state.eventJoin.slectedGroupFeeId : JSON.parse(localStorage.getItem("ageGroupData"))._id,
                positionFee: false,
                twoWayFee: false,
                pitcherFee: false,
                amount: this.state.eventAmount
            }
        } else {
            sendReq = {
                eventId: this.state.eventId,
                couponCode: this.state.eventJoin.couponCode,
                slectedGroupFeeId: this.state.eventJoin.slectedGroupFeeId ? this.state.eventJoin.slectedGroupFeeId : JSON.parse(localStorage.getItem("ageGroupData"))._id,
                positionFee: this.state.eventJoin.positionRegistration,
                twoWayFee: this.state.eventJoin.twoWayRegistration,
                pitcherFee: this.state.eventJoin.pitcherRegistration,
                amount: this.state.eventAmount
            }
        }

        this.setLoader(true);
        this.props.rendCoupanCode(sendReq);
    }

    changeRegisrationPlayer =(e) => {
        this.props.getCostByAge({ event: this.state.eventId, playerId: e.value })
        this.setState({
            playerId:e,
            regiProduct:""
        })
    }

    renderSwitch = () => {
        const { tabs, eventDetailData, finalProducts, rosterList, eventJoin, isShowPayment, linkedPlayerListOpt, insuranceName, managePayMethod, eventId, regiProduct, analReport,playerId } = this.state;
        console.log("linkedPlayerListOpt",linkedPlayerListOpt);
        switch (tabs) {
            case 0:
                return (
                    <JoinEvent
                        onRef={e => this.joinEvent = e}
                        dataList={eventDetailData}
                        rosterList={rosterList}
                        eventJoin={eventJoin}
                        // isShowPayment={isShowPayment}
                        onHandleChange={this.onHandleChange}
                        insuranceName={insuranceName}
                        removeInsurance={this.removeInsurance}
                        handleQA={this.handleQA}
                        registerEvent={this.registerEvent}
                        setWaiverFlag={this.setWaiverFlag}
                        setLoader={this.setLoader}
                        rosterDisable={this.state.rosterDisable}
                    />
                );
            case 1:
                return (
                    <RegistrationAgegroup
                        onRef={e => this.ageGroup = e}
                        dataList={eventDetailData}
                        eventJoin={eventJoin}
                        eventId={eventId}
                        linkedPlayerListOpt={linkedPlayerListOpt}
                        onHandleRegiProduct={this.onHandleRegiProduct}
                        regiProduct={regiProduct}
                        analReport={analReport}
                        playerId={playerId}
                        changeRegisrationPlayer={this.changeRegisrationPlayer}
                        changeAgeGroup={this.changeAgeGroup}
                    />
                );
            case 2:
                return (
                    <ShopingCart
                        onRef={e => this.shopingCart = e}
                        dataList={eventDetailData}
                        eventJoin={eventJoin}
                        onHandleChange={this.onHandleChange}
                        finalProducts={finalProducts}
                        onHandleProduct={this.onHandleProduct}
                    />
                );
            case 3:
                return (
                    <Payment
                        onRef={e => this.eventPay = e}
                        dataList={eventDetailData}
                        eventJoin={eventJoin}
                        onHandleChange={this.onHandleChange}
                        managePayMethod={managePayMethod}
                        registerEvent={this.registerEvent}
                        applyCode={this.applyCode}
                        isPayLater={this.state.isPayLater}
                        setLoader={this.setLoader}
                    />
                );
            default: {
            }
        }
    }

    renderStepBar = () => {
        return (
            <div class="time-line">
                {
                    this.state.isShowPayment ?
                        <div class="timeline-scoll">
                            <ul class="timeline-inner">
                                {
                                    this.state.disableStep !== 0 &&
                                    <li className={`${this.state.tabs === 0 ? "select" : this.state.tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}><a>1</a></li>
                                }
                                {
                                    this.state.eventDetailData.eventTeamType === 2 &&
                                    <>
                                        <li className={`${this.state.tabs === 1 ? "select" : this.state.tabs >= 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}><a>{this.state.disableStep !== 0 ? "2" : "1"}</a></li>
                                        <li className={`${this.state.tabs === 2 ? "select" : this.state.tabs >= 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}><a>{this.state.disableStep !== 0 ? "3" : "2"}</a></li>
                                    </>
                                }
                                <li className={`${this.state.tabs === 3 ? "select" : this.state.tabs > 3 ? "active" : ""}`} onClick={() => this.handleTabs(3)}>
                                    <a>{this.state.eventDetailData.eventTeamType === 2 ? this.state.disableStep !== 0 ? "4" : "3" : this.state.disableStep !== 0 ? "2" : "1"}</a>
                                </li>

                            </ul>
                        </div>
                        :
                        this.state.eventDetailData.eventTeamType === 2 ?
                            <div class="timeline-scoll">
                                <ul class="timeline-inner">
                                    <li className={`${this.state.tabs === 0 ? "select" : this.state.tabs >= 0 ? "active" : ""}`} onClick={() => this.state.disableStep === 0 ? "" : this.handleTabs(0)}><a>1</a></li>
                                    <li className={`${this.state.tabs === 1 ? "select" : this.state.tabs >= 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}><a>2</a></li>
                                    <li className={`${this.state.tabs === 2 ? "select" : this.state.tabs >= 1 ? "active" : ""}`} onClick={() => this.handleTabs(2)}><a>3</a></li>
                                    <li className={`${this.state.tabs === 3 ? "select" : this.state.tabs >= 1 ? "active" : ""}`} onClick={() => this.handleTabs(3)}><a>4</a></li>
                                </ul>
                            </div>
                            : ""
                }
                <div className="all_btn_step">
                    <button className="btn blue_btn" onClick={() => this.handleBack()}>Back</button>
                    {
                        this.state.isShowPayment ?
                            (this.state.eventDetailData.eventTeamType === 1 && this.state.tabs <= 0) ?
                                <button className="btn light-green" style={{ cursor: this.state.isDisableNext ? 'not-allowed' : "" }} onClick={() => this.state.isDisableNext ? "" : this.handleNext()}>Next</button>
                                : this.state.tabs !== 3 ? <button className="btn light-green" onClick={() => this.handleNext()}>Next</button>
                                    : ""
                            : (this.state.eventDetailData.eventTeamType === 2 && this.state.tabs <= 0) ? <button className="btn light-green" onClick={() => this.handleNext()}>Next</button>
                                : <button className="btn light-green" onClick={() => this.directRegisterEvent()}>Submit</button>
                    }
                    {/* {!this.state.isShowPayment ?
                            <Link className="btn light-green" onClick={() => this.directRegisterEvent()}>Submit</Link>
                          
                        : ""
                    } */}
                    {
                        this.state.isPayLater && this.state.tabs >= 1 &&
                        <button className="btn light-green" onClick={() => this.registerEvent()}>Submit</button>
                    }
                </div>
            </div>
        )
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    onHandleClose = () => {
        this.setState({
            creditCard: false
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    stripePaymentSuccess = (payment) => {
        this.setState({
            creditCard: false,
            showPaymentButton: true,
            eventJoin: {
                ...this.state.eventJoin,
                metaPayment: payment,
            }
        }, () => {
            document.body.classList.remove("modal-open");
            this.registerEvent();
        })
    };

    stripePaymentError = (error) => {
        this.commonCall.errorMessage(error);
        this.setLoader(false);
    };

    registerEvent = () => {
        localStorage.removeItem("ageGroupData");
        this.joinEvent.validationUpdate(flag => {
            if (flag) {
                if (this.state.eventJoin.paymentType !== 3) delete this.state.eventJoin.downpaidAmt;
                this.props.eventRegistrationPayment(this.state.eventDetailData.eventTeamType === 2 && (localStorage.getItem("role") === "Coach" ||
                localStorage.getItem("role") === "Parent") ? {...this.state.eventJoin, playerId : this.state.playerId?.value} : this.state.eventJoin);
            } else {
                this.setLoader(false);
            }
        });
    }

    directRegisterEvent = () => {
        localStorage.removeItem("ageGroupData");
        if (this.state.eventDetailData.eventTeamType === 2 && this.state.eventDetailData.eventPriority === 1) {
            if (this.shopingCart.state.filteredActiveData.length > 0) {
                this.setState({
                    productConfirm: true
                })
            } else {
                this.joinEvent.validationUpdate(flag => {
                    if (flag) {
                        // console.log("this.state.eventJoin:--", this.state.eventJoin);
                        this.props.eventRegistrationPayment(this.state.eventJoin);
                    } else {
                        this.setLoader(false);
                    }
                });
            }
        } else {
            this.joinEvent.validationUpdate(flag => {
                if (flag) {
                    if (this.state.eventDetailData.eventTeamType === 2 && this.state.eventDetailData.eventPriority === 2 && this.ageGroup) {
                        this.ageGroup.validationUpdate((flag, price, slectedGroupFeeId) => {
                            if (flag) {
                                // console.log("this.state.eventJoin:--", this.state.eventJoin, price, slectedGroupFeeId);
                                this.props.eventRegistrationPayment(this.state.eventJoin);
                            } else {
                                this.setLoader(false);
                            }
                        });
                    } else {
                        // console.log("this.state.eventJoin:--", this.state.eventJoin);
                        this.props.eventRegistrationPayment(this.state.eventJoin);
                    }
                } else {
                    this.setLoader(false);
                }
            });
        }
    }

    onHandleRosterClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            rosterPopup: false
        });
    }

    onHandleProductClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            productConfirm: false
        });
    }

    onHandleProductOk = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            productConfirm: false
        }, () => {
            // console.log("this.state.eventJoin:--", this.state.eventJoin);
            this.props.eventRegistrationPayment(this.state.eventJoin);
        });
    }

    render() {
        const { tabs, loader, eventDetailData, eventId, isShowPayment, showPaymentButton, creditCard, rosterPopup, rosterMsg, productConfirm, showAmount } = this.state;
        return (
            <div className="event_details">
                <Header />
                <CommonHeroBanner dataList={eventDetailData} />
                <div className="container">
                    <EventRow dataList={eventDetailData} isHideJoinButton={true} eventId={eventId} complete={false} directPay={false} showAmount={eventDetailData.eventTeamType === 2 ? showAmount : true} />
                    <div className="event-container">
                        <div class="main-timeline">
                            {this.renderStepBar()}
                            {this.renderSwitch()}
                            {this.renderStepBar()}
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
                {
                    creditCard &&
                    <CreditCard
                        flag={creditCard}
                        close={this.onHandleClose}
                        stripePaymentSuccess={this.stripePaymentSuccess}
                        stripePaymentError={this.stripePaymentError}
                        amount={eventDetailData.payment && eventDetailData.payment.feesTournament}
                        setLoader={this.setLoader}
                    />
                }
                {
                    rosterPopup &&
                    <Confirmation
                        flag={rosterPopup}
                        close={this.onHandleRosterClose}
                        type="confirmRoster"
                        rosterMsg={rosterMsg}
                    />
                }
                {
                    productConfirm &&
                    <Confirmation
                        flag={productConfirm}
                        close={this.onHandleProductClose}
                        type="confirmProduct"
                        ok={this.onHandleProductOk}
                    />
                }
            </div>
        )
    }
}

eventPayment.propTypes = {
    getEventDetails: PropTypes.func.isRequired,
    getRosterOption: PropTypes.func.isRequired,
    postFileUpload: PropTypes.func.isRequired,
    fetchPaymentIntent: PropTypes.func.isRequired,
    eventRegistrationPayment: PropTypes.func.isRequired,
    eventPaymentComplete: PropTypes.func.isRequired,
    getAgeVerification: PropTypes.func.isRequired,
    getRosterAmount: PropTypes.func.isRequired,
    rendCoupanCode: PropTypes.func.isRequired,
    getEventLinkedPlayerList:PropTypes.func.isRequired,
    getEventRegistrationAge:PropTypes.func.isRequired,
    getCostByAge:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
    commonApiDetails: state.api,
    profileDetails: state.headerData,
});
var eventPaymentComponent = connect(mapStateToProps, { rendCoupanCode, getEventDetails, getRosterOption,getEventRegistrationAge, postFileUpload, fetchPaymentIntent, eventRegistrationPayment, eventPaymentComplete, getAgeVerification, getRosterAmount, getEventLinkedPlayerList, getCostByAge })(eventPayment);
export default withRouter(eventPaymentComponent);

import React, { Component } from 'react';
import { imagesArr } from "../../../../../assets/images";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { checkLoginValidation } from '../../../../../saga/helpers/authToken';
import Confirmation from '../../../../popup/confirmation';
import commonCalls from "../../../../../businesslogic/commonCalls";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRosterConfirmation } from '../../../../../saga/actions/common/leagueDetails.action';

class leagueRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closePopup: false,
            eligiblePopup: false,
            eligibleText: "",
            eligibleButoon: ""
        }
        this.commonCall = new commonCalls();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.checkLeagueConfirmReq &&
            nextProps.leagueDetails.checkLeagueConfirmData
        ) {
            console.log("nextProps.leagueDetails.checkLeagueConfirmData:--", nextProps.leagueDetails.checkLeagueConfirmData);
            nextProps.leagueDetails.checkLeagueConfirmReq = false;
            if (nextProps.leagueDetails.checkLeagueConfirmData.flag) {
                let result = nextProps.leagueDetails.checkLeagueConfirmData.result;
                if (result.eligible) {
                    let svalid_date = moment(this.props.dataList.startDate).format("MM/DD/YYYY");
                    let evalid_date = moment(this.props.dataList.regClosedDate).format("MM/DD/YYYY");

                    let chkdate = moment(svalid_date).isAfter(evalid_date);

                    if (chkdate) {
                        if (this.props.teamArr.length === 0) {
                            this.commonCall.errorMessage("You need to tie up with one team before doing league registration.");
                        } else {
                            this.props.history.push(`/league-form/${this.props.leagueId}`);
                        }
                    } else {
                        this.setState({
                            closePopup: true
                        })
                    }
                } else {
                    let ageGroupMsg = result.isUsed ?
                        <>
                            <h2>Let’s create your roster</h2>
                            <p>Looks like you need to create a new roster. You can’t add the same roster to one event. Add your next roster now to continue registering.</p>
                        </>
                        :
                        <>
                            <h2>Let’s create your first roster</h2>
                            <p>Click below to access the Roster Management Tool. As a Coach, you can have multiple teams under your account. Let's create your first roster. Click below to complete this step and finish registration. You can add your players now or later, and they are not required to register; only a team is required for now.</p>
                        </>;
                    this.setState({
                        eligiblePopup: true,
                        eligibleText: ageGroupMsg,
                        eligibleButoon: result.isUsed ? "Create Your Roster" : "Create Your First Roster"
                    })
                }
                nextProps.leagueDetails.checkLeagueConfirmData = null;
                this.props.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.checkLeagueConfirmData.message);
                nextProps.leagueDetails.checkLeagueConfirmData = null;
                this.props.setLoader(false);
            }
        }

    }

    payment = () => {
        var isloggedIn = checkLoginValidation();
        if (isloggedIn) {
            console.log("this.props.dataList:----", this.props.dataList);
            if (this.props.dataList.settings.redirectURL) {
                window.open(this.props.dataList.linkUrl, '_blank');
            } else {
                this.props.setLoader(true);
                this.props.getRosterConfirmation({ leagueId: this.props.leagueId });
            }

        } else {
            localStorage.setItem("leagueDetail", this.props.leagueId);
            this.props.history.push("/login")
        }
    }

    payDirect = () => {
        var isloggedIn = checkLoginValidation();
        if (isloggedIn) {
            let svalid_date = moment(this.props.dataList.startDate).format("MM/DD/YYYY");
            let evalid_date = moment(this.props.dataList.regClosedDate).format("MM/DD/YYYY");

            let chkdate = moment(svalid_date).isAfter(evalid_date);

            if (chkdate) {
                this.props.paymentIntent();
            } else {
                this.setState({
                    closePopup: true
                })
            }
        } else {
            localStorage.setItem("leagueDetail", this.props.leagueId);
            localStorage.setItem("leagueDirectPayment", true)
            this.props.history.push("/signup")
        }
    }

    selectEvent = () => {
        var isloggedIn = checkLoginValidation();
        if (isloggedIn) {
            this.props.history.push(`/league-selection/${this.props.leagueId}`);
        } else {
            localStorage.setItem("leagueDetail", this.props.leagueId);
            this.props.history.push("/login")
        }
    }

    showAlertInvitation = () => {
        this.commonCall.errorMessage("You are waiting to join the team. Please try after you tie-up with the team.");
    }

    isShowJoin = () => {
        const league = this.props.dataList;
        // console.log("league:----", league);
        let count = league.payment && league.payment.eventToChoose && league.payment.eventToChoose;
        if (localStorage.getItem("playerToken")) {
            return null;
        } else {
            if (league.selPer === 4 && localStorage.getItem("webglToken") !== null) {
                return (this.props.isHideJoinButton ? '' : <button className="btn light-green" style={{ cursor: 'not-allowed' }}>Joined</button>)
            } else {
                if (localStorage.getItem("webglToken") !== null) {
                    return (this.props.isHideJoinButton ? '' :
                    !league.settings.redirectURL?
                        <button className="btn light-green" style={league.selEvent !== count ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} disabled={league.selEvent !== count} onClick={!this.props.profileDetails.commonData?.result?.profile?.invite ? this.payment : this.showAlertInvitation} >Join</button>
                        :
                        <button className="btn light-green" onClick={this.payment} >Join</button>
                    )
                } else {
                    return (this.props.isHideJoinButton ? '' : <button className="btn light-green" onClick={this.payment} >Join</button>)
                }
            }
        }
    }

    clickClosePopup = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            closePopup: false
        })
    }

    closePopup = () => {
        this.setState({
            eligiblePopup: false,
            eligibleText: "",
            eligibleButoon: ""
        }, () => {
            document.body.classList.remove('modal-open');
            localStorage.setItem("noAge", this.props.leagueId);
            localStorage.setItem("noLeagueAge", this.props.leagueId);
            this.commonCall.setStorage("coachTab", "roster", flag => {
                if (flag) {
                    this.props.history.push('/coach-setting');
                }
            });
        })
    }

    close = () => {
        this.setState({
            eligiblePopup: false,
            eligibleText: "",
            eligibleButoon: ""
        }, () => {
            document.body.classList.remove('modal-open');
        })
    }

    render() {
        const { dataList, complete, isHideAddEventButton, directPay, alreadyPaid, directAmout } = this.props;
        const { closePopup, eligiblePopup, eligibleText, eligibleButoon } = this.state;
        const location = dataList?.location && dataList?.location.streetAddress.split(",");
        const totEventCount = dataList?.payment && dataList?.payment.eventToChoose && dataList?.payment.eventToChoose;

        let svalid_date = moment().format("MM/DD/YYYY");
        let evalid_date = moment(dataList?.regClosedDate).format("MM/DD/YYYY");
        let chkdate = moment(svalid_date).isBefore(evalid_date);

        return (
            <>
                <div className="web_eventOuterList">
                    <div className="web_eventlist_wrapper d-flex flex-wrap align-items-center">
                        <div className="web_eventList_img">
                            <img src={dataList && dataList.leagueImages && dataList.leagueImages.filePath} alt="event banner" />
                            {

                                (dataList?.location && (dataList.location.city || dataList.location.streetAddress || dataList.location.stateRegion)) &&
                                <span className="event_card_address">
                                    {dataList.location &&
                                        dataList.location.city ?
                                        dataList.location.city :
                                        dataList.location.streetAddress ?
                                            location[0] :
                                            dataList.location.stateRegion}
                                </span>
                            }
                        </div>
                        <div className="web_eventList_content">
                            <div className="web_eventList_data">
                                <div class="web_eventList_logo">
                                    <img src={dataList && dataList?.leagueLogo && dataList?.leagueLogo.filePath} alt="event logo" />
                                </div>
                                <div className="web_eventList_detail">
                                    <h1>{dataList && dataList.leagueTitle}</h1>
                                    <div className="web_eventList_icons d-flex flex-wrap">
                                        <span class="common_softball_icon d-flex align-items-center">{dataList && dataList?.eventType === 1 ? 'Baseball' : 'Softball'}</span>
                                        <span class="common_date_icon d-flex align-items-center">{moment(dataList?.startDate).format('DD-MMM')} - {moment(dataList?.endDate).format('DD-MMM')}</span>
                                        <span class="common_team_icon d-flex align-items-center">Pay for {dataList?.payment && dataList.payment.eventsToPay} and Get {dataList?.payment && dataList.payment.eventToChoose}</span>
                                    </div>
                                    {/* <div className="web_eventList_icons d-flex flex-wrap">
                                        <span class="common_date_icon d-flex align-items-center">Registration close on {moment(dataList.regClosedDate).format('DD-MMM')}</span>
                                    </div> */}
                                </div>
                            </div>
                            {
                                !chkdate ?
                                    <p className="mb-0">Registration closed.</p>
                                    :
                                    // <div className="web_eventList_join">
                                    //     {
                                    //         !complete &&
                                    //         <h2>${dataList.payment && dataList.payment.feesLeague} <span>Fee to Join</span></h2>
                                    //     }
                                    //     {
                                    //         this.isShowJoin()
                                    //     }
                                    //     {
                                    //         complete &&
                                    //         <button class="btn light-green">Joined</button>
                                    //     }
                                    //     {
                                    //         !isHideAddEventButton &&
                                    //         localStorage.getItem("coachToken") &&
                                    //         <button class="btn blue_btn" style={dataList.selPer === 4 ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} onClick={() => dataList.selPer === 4 ? "" : this.selectEvent()}>Add to Event(s) {totEventCount} ({dataList.selEvent ? dataList.selEvent : 0})</button>
                                    //     }
                                    // </div>
                                    !directPay ?
                                        <div className="web_eventList_join">
                                            {
                                                !complete &&
                                                <h2>${dataList.payment && dataList.payment.feesLeague} <span>Fee to Join</span></h2>
                                            }
                                            {
                                                this.isShowJoin()
                                            }
                                            {
                                                complete &&
                                                <button class="btn light-green">Joined</button>
                                            }
                                            {
                                                !isHideAddEventButton &&
                                                !dataList.settings.redirectURL &&
                                                localStorage.getItem("coachToken") &&
                                                <button class="btn blue_btn" style={dataList.selPer === 4 ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} onClick={() => dataList.selPer === 4 ? "" : this.selectEvent()}>Add to Event(s) {totEventCount} ({dataList.selEvent ? dataList.selEvent : 0})</button>
                                            }
                                        </div>
                                        :
                                        alreadyPaid ?
                                            <button className="btn light-green" style={{ cursor: 'not-allowed' }} >Joined</button>
                                            :
                                            <div className="web_eventList_join">
                                                <h2><span></span> ${directAmout ? directAmout : dataList?.payment?.feesLeague}</h2>
                                                <button className="btn light-green" onClick={() => this.payDirect()}>Pay</button>
                                            </div>
                            }
                        </div>
                    </div>
                </div >
                {
                    closePopup &&
                    <Confirmation
                        flag={closePopup}
                        type="regiClose"
                        close={this.clickClosePopup}
                    />
                }
                {
                    eligiblePopup &&
                    <Confirmation
                        flag={eligiblePopup}
                        type="eligible"
                        close={this.closePopup}
                        closePopup={this.close}
                        eligibleText={eligibleText}
                        eligibleButoon={eligibleButoon}
                    />
                }
            </>
        )
    }
}

leagueRow.propTypes = {
    getRosterConfirmation: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    leagueDetails: state.leagueDetails,
    profileDetails: state.headerData,
});

let leagueRowComponents = connect(mapStateToProps, { getRosterConfirmation })(leagueRow);
export default withRouter(leagueRowComponents);
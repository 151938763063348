import {
    GET_OTHERINFO_ERROR,
    GET_OTHERINFO_SUCCESS,
    POST_OTHERINFO_SUCCESS,
    POST_OTHERINFO_ERROR
} from '../../../../constants/authantication/signup/player/about.const';

const initialState = {
    getOtherInfo: {},
    getOtherError: null,

    postInfo: null,
    postError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get training
        case GET_OTHERINFO_SUCCESS:
            return {
                ...state,
                getOtherInfo: action.payload
            };
        case GET_OTHERINFO_ERROR:
            return {
                ...state,
                getOtherError: action.error,
            };

        case POST_OTHERINFO_SUCCESS:
            return {
                ...state,
                postInfo: action.payload
            };
        case POST_OTHERINFO_ERROR:
            return {
                ...state,
                postError: action.error,
            };
        // case SET_QUESTION_DATA:
        //     return { ...state, questionData: action.payload }

        default:
            return state;
    }
}
import {
    COM_PAST_CHAMPION_REQUEST,
    COM_PAST_CHAMPION_SUCCESS,
    COM_PAST_CHAMPION_ERROR,

    COM_CHAMPION_EVENT_REQUEST,
    COM_CHAMPION_EVENT_SUCCESS,
    COM_CHAMPION_EVENT_ERROR,
} from '../../constants/common/pastChampion.const';

const initialState = {
    pastChampionReq: false,
    pastChampionData: null,
    pastChampionError: null,

    championEventReq: false,
    championEventData: null,
    championEventError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Past champion slider req    
        case COM_PAST_CHAMPION_REQUEST:
            return {
                ...state,
                pastChampionReq: true,
            };

        case COM_PAST_CHAMPION_SUCCESS:
            return {
                ...state,
                pastChampionData: action.payload,
            };
        case COM_PAST_CHAMPION_ERROR:
            return {
                ...state,
                pastChampionError: action.error,
            };

        // Champion event req    
        case COM_CHAMPION_EVENT_REQUEST:
            return {
                ...state,
                championEventReq: true,
            };

        case COM_CHAMPION_EVENT_SUCCESS:
            return {
                ...state,
                championEventData: action.payload,
            };
        case COM_CHAMPION_EVENT_ERROR:
            return {
                ...state,
                championEventError: action.error,
            };

        default:
            return state;
    }
}
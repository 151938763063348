import {
    PERSONALINFO_REQUEST, PERSONALINFO_SUCCESS, PERSONALINFO_ERROR,  REGISTERINFO_SUCCESS, REGISTERINFO_ERROR,
} from '../../../../constants/view/coach/profile/personalInfo.const';


const initialState = {
    personalInfoData: null,
    personalInfoError: null,
   
    registerInfoData: null,
    registerInfoError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case PERSONALINFO_SUCCESS:
            return {
                ...state,
                personalInfoData: action.payload,
            };

        case PERSONALINFO_ERROR:
            return {
                ...state,
                personalInfoError: action.error,
            };
        
        case REGISTERINFO_SUCCESS:
            return {
                ...state,
                registerInfoData: action.payload,
            };

        case REGISTERINFO_ERROR:
            return {
                ...state,
                registerInfoError: action.error,
            };
   
        default:
            return state;
    }
}
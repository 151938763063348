import React, { Component, useEffect, useReducer, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../../assets/images';
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../../businesslogic/commonCalls";
import { PLAYER_TRAINING_REG } from "../../../saga/constants/view/player/profile/training.const";
import { getPlayerTrainingInfo, postPlayerTrainingInfo } from "../../../saga/actions/views/player/profile/training.action";
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../../components/preloader';

const initialState =
{
    devices: "yes",
    "train-device": "no",
    "device-type": [],
    "train-device-type": [],
    "device-type-other": "",
    "train-device-type-other": "",
    loader: false
}

const reducer = (state, payload) => ({ ...state, ...payload });
function TrainingInfo() {

    const [training, setTraining] = useReducer(reducer, initialState);
    const validator = useRef(new SimpleReactValidator());
    const validatorDeviceOther = useRef(new SimpleReactValidator());
    const validatorTrainOther = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const commonCall = new commonCalls();
    const dispatch = useDispatch();
    const { trainingInfo: _TI, ragisterInfo: _REG } = useSelector(state => state.playerTrainingInfo);
    const [trainingDevice, setTrainingDevice] = useState({
        deviceType: ["rapsodo", "blast", "flight", "track", "hit", "dk", "yakkertech", "other"]
    })

    useEffect(() => {
        setLoader(true)
        getPlayerTrainingInfo()(dispatch);
    }, [])

    useEffect(() => {
        if (_TI) {
            if (_TI["devices"] === "yes") {
                if (_TI["train-device"] === "yes") {
                    setTraining({
                        devices: _TI.devices,
                        "train-device": _TI["train-device"],
                        "device-type": _TI["device-type"] ? _TI["device-type"] : [],
                        "train-device-type": _TI["train-device-type"] ? _TI["train-device-type"] : [],
                        "device-type-other": _TI["device-type-other"],
                        "train-device-type-other": _TI["train-device-type-other"]
                    })
                    setLoader(false)
                } else {
                    setTraining({
                        devices: _TI.devices,
                        "train-device": _TI["train-device"],
                        "device-type": _TI["device-type"] ? _TI["device-type"] : [],
                        "device-type-other": _TI["device-type-other"],
                        "train-device-type-other": _TI["train-device-type-other"]
                    })
                    setLoader(false)
                }
            } else {
                if (_TI["train-device"] === "yes") {
                    setTraining({
                        devices: _TI.devices,
                        "train-device": _TI["train-device"],
                        "train-device-type": _TI["train-device-type"] ? _TI["train-device-type"] : [],
                        "device-type-other": _TI["device-type-other"],
                        "train-device-type-other": _TI["train-device-type-other"]
                    })
                    setLoader(false)
                } else {
                    setTraining({
                        devices: _TI.devices,
                        "train-device": _TI["train-device"],
                        "device-type-other": _TI["device-type-other"],
                        "train-device-type-other": _TI["train-device-type-other"]
                    })
                    setLoader(false)
                }
            }
        }
    }, [_TI])

    useEffect(() => {
        if (_REG) {
            if (_REG.flag) {
                commonCall.successMessage(_REG.message)
                getPlayerTrainingInfo()(dispatch);
            } else if (!_REG.flag) {
                commonCall.errorMessage(_REG.message)
            }
            dispatch({
                type: PLAYER_TRAINING_REG,
                payload: null,
            });
        }
    }, [_REG])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTraining({
            [name]: value
        })
    }

    const handleChangeDevice = (e) => {
        if (training["device-type"].find(item => item === e)) {
            setTraining({
                ...training,
                "device-type": training["device-type"].filter(item => { if (item !== e) { return item; } })
            })
        }
        else {
            setTraining({
                ...training,
                "device-type": [...training["device-type"], e]
            })
        }
    }

    const renderTrainingDevice = () => {
        return (
            trainingDevice.deviceType &&
            trainingDevice.deviceType.map((item, i) => (
                <>
                    <div className="check_box true">
                        <div className="check-box">
                            <div className="checkbox">
                                <input type="checkbox" id={item} value={item} name={item} checked={training['device-type'].find((d) => d === item) ? true : false}
                                    onClick={() => handleChangeDevice(item)} />
                                <label for="Male01">
                                    {item === "rapsodo"
                                        ? "Rapsodo"
                                        : item === "blast"
                                            ? "Blast Motion"
                                            : item === "flight"
                                                ? "FlightScope"
                                                : item === "track"
                                                    ? "Trackman"
                                                    : item === "hit"
                                                        ? "HitTrax"
                                                        : item === "dk" ?
                                                            "Diamond Kinetics" : item === "yakkertech" ?
                                                                "Yakkertech" : item === "other" ?
                                                                    "Other" : ""}
                                </label>
                            </div>
                        </div>
                    </div>
                </>
            ))
        )
    }

    const handleChangeFacilityDevice = (e) => {
        if (training["train-device-type"].find(item => item === e)) {
            setTraining({
                ...training,
                "train-device-type": training["train-device-type"].filter(item => { if (item !== e) { return item; } })
            })
        }
        else {
            setTraining({
                ...training,
                "train-device-type": [...training["train-device-type"], e]
            })
        }
    }

    const renderFacilityDevice = () => {
        return (
            trainingDevice.deviceType &&
            trainingDevice.deviceType.map((item, i) => (
                <>
                    <div className="check_box true">
                        <div className="check-box">
                            <div className="checkbox">
                                <input type="checkbox" id={item} value={item} name={item} checked={training['train-device-type'].find((d) => d === item) ? true : false}
                                    onClick={() => handleChangeFacilityDevice(item)} />
                                <label for="Male01">
                                    {item === "rapsodo"
                                        ? "Rapsodo"
                                        : item === "blast"
                                            ? "Blast Motion"
                                            : item === "flight"
                                                ? "FlightScope"
                                                : item === "track"
                                                    ? "Trackman"
                                                    : item === "hit"
                                                        ? "HitTrax"
                                                        : item === "dk" ?
                                                            "Diamond Kinetics" : item === "yakkertech" ?
                                                                "Yakkertech" : item === "other" ?
                                                                    "Other" : ""}
                                </label>
                            </div>
                        </div>
                    </div>
                </>
            ))
        )
    }

    const onSave = async () => {
        if (training["devices"] === "yes") {
            if (training["train-device"] === "yes") {
                if (validator.current.allValid()) {
                    if ((!!training['device-type'].find(e => e === "other") && !validatorDeviceOther.current.allValid()) || (!!training['train-device-type'].find(e => e === "other") && !validatorTrainOther.current.allValid())) {
                        validatorDeviceOther.current.showMessages(true);
                        validatorTrainOther.current.showMessages(true);
                        forceUpdate(1);
                    }else{
                        setLoader(true);
                        training["device-type-other"] = !!training['device-type'].find(e => e === "other") ? training["device-type-other"] : ""
                        training["train-device-type-other"] = !!training['train-device-type'].find(e => e === "other") ? training["train-device-type-other"] : ""
                        await postPlayerTrainingInfo(training)(dispatch);
                    }
                } else {
                    validator.current.showMessages(true);
                    forceUpdate(1);
                }
            } else {
                if (!!training['device-type'].find(e => e === "other") && !validatorDeviceOther.current.allValid()) {
                    validatorDeviceOther.current.showMessages(true);
                    forceUpdate(1);
                }else{
                    let data = {
                        devices: training.devices,
                        "train-device": training['train-device'],
                        "device-type": training['device-type'],
                        "device-type-other": !!training['device-type'].find(e => e === "other") ? training["device-type-other"] : "",
                        "train-device-type-other": !!training['train-device-type'].find(e => e === "other") ? training["train-device-type-other"] : ""
                    }
                    setLoader(true);
                    await postPlayerTrainingInfo(data)(dispatch);
                }
            }
        } else {
            if (training["train-device"] === "yes") {
                if (!!training['train-device-type'].find(e => e === "other") && !validatorTrainOther.current.allValid()) {
                    validatorTrainOther.current.showMessages(true);
                    forceUpdate(1);
                } else {
                    let data = {
                        devices: training.devices,
                        "train-device": training['train-device'],
                        "train-device-type": training['train-device-type'],
                        "device-type-other": !!training['device-type'].find(e => e === "other") ? training["device-type-other"] : "",
                        "train-device-type-other": !!training['train-device-type'].find(e => e === "other") ? training["train-device-type-other"] : ""
                    }
                    setLoader(true);
                    await postPlayerTrainingInfo(data)(dispatch);
                }
            } else {
                let data = {
                    devices: training.devices,
                    "train-device": training['train-device'],
                    "device-type-other": !!training['device-type'].find(e => e === "other") ? training["device-type-other"] : "",
                    "train-device-type-other": !!training['train-device-type'].find(e => e === "other") ? training["train-device-type-other"] : ""
                }
                setLoader(true);
                await postPlayerTrainingInfo(data)(dispatch);
            }
        }
    }

    const handleOtherInfo = (e) =>{
        const { name, value } = e.target.value;

    }


    const setLoader = (val) => {
        setTraining({
            loader: val
        })
    }

    return (
        <>
            <section className="before-login other-before-dashboard">
                <div className="white-bg">
                    <div className="check_input row">
                        <div className="check_box">
                            <label className="common-lable">Do you own or train with a training device?</label>
                            <div className="check-box mb-0">
                                <div className="checkbox" >
                                    <input type="radio" id="Baseball" value="yes" name="devices" checked={training.devices === "yes" && true} onChange={handleChange} />
                                    <label for="Baseball">Yes </label>
                                </div>

                                <div className="checkbox mr-0">
                                    <input type="radio" id="Softball" value="no" name="devices" checked={training.devices === "no" && true} onChange={handleChange} />
                                    <label for="Softball">No </label>
                                </div>

                            </div>
                            <span className="validMsg">{validator.current.message('', training.devices, 'required', { className: 'text-danger' })}</span>

                        </div>
                    </div>
                    {
                        training.devices === "yes" &&
                        <>
                            <label className="common-lable">Which training device do you use?</label>
                            <div className="check_input row">
                                {renderTrainingDevice()}
                            </div>
                            {/* <span className="validMsg">{validator.current.message('', training["device-type"], 'required', { className: 'text-danger' })}</span> */}
                            {!!training['device-type'].find(e => e === "other") &&
                                <><label className="common-lable">
                                    If other please explain
                                </label>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter text here" value={training["device-type-other"]} onChange={(e) => setTraining({ ...training, "device-type-other": e.target.value })} />
                                        <span className="validMsg">{validatorDeviceOther.current.message('', training["device-type-other"], 'required', { className: 'text-danger' })}</span>
                                    </div>
                                </>}
                        </>
                    }

                    <div className="check_input row">
                        <div className="check_box">
                            <label className="common-lable">Do you train with a device at your baseball facility?</label>
                            <div className="check-box mb-0">
                                <div className="checkbox" >
                                    <input type="radio" id="Baseball" value="yes" name="train-device" checked={training["train-device"] === "yes" && true} onChange={handleChange} />
                                    <label for="Baseball">Yes </label>
                                </div>

                                <div className="checkbox mr-0">
                                    <input type="radio" id="Softball" value="no" name="train-device" checked={training["train-device"] === "no" && true} onChange={handleChange} />
                                    <label for="Softball">No </label>
                                </div>
                            </div>
                            <span className="validMsg">{validator.current.message('', training["train-device"], 'required', { className: 'text-danger' })}</span>

                        </div>
                    </div>

                    {
                        training["train-device"] === "yes" &&
                        <>
                            <label className="common-lable">Which training device do you use?</label>
                            <div className="check_input row">
                                {renderFacilityDevice()}
                            </div>
                            {/* <span className="validMsg">{validator.current.message('', training["device-type"], 'required', { className: 'text-danger' })}</span> */}

                            {!!training['train-device-type'].find(e => e === "other") &&
                                <><label className="common-lable">
                                    If other please explain
                                </label>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter text here" value={training["train-device-type-other"]} onChange={(e) => setTraining({ ...training, "train-device-type-other": e.target.value })} />
                                        <span className="validMsg">{validatorTrainOther.current.message('', training["train-device-type-other"], 'required', { className: 'text-danger' })}</span>
                                    </div>
                                </>}
                        </>
                    }

                    <div className="flex-align">
                        <Link className="btn gray" to={"/player-dashboard"}>Cancel</Link>
                        <Link className="btn light-green" onClick={onSave}>Save</Link>
                    </div>
                </div>
            </section>
            <Preloader flag={training.loader} />
        </>
    );
}

export default TrainingInfo;

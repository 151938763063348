import React, { Component } from "react";
import { imagesArr } from "../../../../../assets/images";
import Confirmation from "../../../../popup/confirmation";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { checkLoginValidation } from "../../../../../saga/helpers/authToken";
import commonCalls from "../../../../../businesslogic/commonCalls";
import { connect } from "react-redux";
import { Table } from "antd";
import "antd/dist/antd.css";
import RegisterRosterPopup from "../../../../popup/registerRoster";
import SimpleReactValidator from 'simple-react-validator';

class eventInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      eventJoin: {
        event: "",
        isManager: true,
        managerName: "",
        managerEmail: "",
        managerPhone: "",
        waiversImg: "",
        insuranceImg: "",
        paymentType: 0,
        couponCode: "",
        metaPayment: {},
        paymentMethod: "",
        amount: 0,
        question: "",
        products: [],
        positionRegistration: false,
        pitcherRegistration: false,
        twoWayRegistration: false,
        analyzrReportpay: false,
        ageGroup: "",
        ageGroupTo: "",
        waiverUrlClick: "no",
        slectedGroupFeeId: "",
        companyThrough: localStorage.getItem("company") === "dc" ? 2 : 1,
      },
      regRosterPopup: false,
      reqRoster: {
        ageGroup: 0,
        ageGroupTo: 0,
        eventId: "",
        _id: "",
      },
    };
    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount = () => {
    let fdata = this.props.dataList;
    this.setState(
      {
        eventJoin: {
          ...this.state.eventJoin,
          question: fdata?.questions && fdata?.questions,
          isManager: fdata?.settings.haveTeamManger,
          amount: fdata?.payment.feesTournament,
        },
      },
      () => {
        if (fdata?.chargeReport) {
          this.setState({
            eventJoin: {
              ...this.state.eventJoin,
              analyzrReportpay: true,
            },
          });
        }
      }
    );
  };

  getEventType = (eventTeamType) => {
    if (eventTeamType === 1) return "Tournament";
    else
      return localStorage.getItem("company") === "pws" ? "Camps" : "Showcase";
  };

  getDivision = (division) => {
    if (division) {
      return division
        .map((div) => {
          return div.title;
        })
        .join(",");
    } else {
      return "";
    }
  };

  renderTeamList = () => {
    return (
      this.props.pList &&
      this.props.pList.map((list, i) => {
        return (
          <tr>
            <td>{i + 1}</td>
            <td>
              <div className="teams-logo">
                <span>
                  <img
                    src={
                      list.team
                        ? list.team.parentTeam &&
                          list.team.parentTeam?.hasOwnProperty("teamLogo")
                          ? list.team.parentTeam.teamLogo.filePath
                          : imagesArr.default_user
                        : imagesArr.default_user
                    }
                    alt="Teams"
                  />
                </span>
                <span className="teams-text">
                  {list.team ? list.team.parentTeam.name : "-"}
                </span>
              </div>
            </td>
            <td>
              {list.rosterTeamId.length !== 0
                ? list.rosterTeamId[0].rosterName
                : "-"}
            </td>
            <td>
              {list.team
                ? list.team.coach.fname + " " + list.team.coach.lname
                : "-"}
            </td>
            <td>
              {list.team
                ? list.team.coach.profile.address
                  ? list.team.coach.profile.address.state
                  : ""
                : "-"}
            </td>
            <td>{list.ageGroup}</td>
          </tr>
        );
      })
    );
  };

  isShowJoin = (item) => {
    const event = this.props.dataList;
    let svalid_date = moment().format("MM/DD/YYYY");
    let evalid_date = moment(event.regClosedDate).format("MM/DD/YYYY");
    let chkdate = moment(svalid_date).isBefore(evalid_date);
    if (event.eventTeamType === 1) {
      if (
        localStorage.getItem("playerToken") ||
        localStorage.getItem("rosterToken")
      ) {
        return null;
      } else {
        if (!chkdate) {
          return <p className="mb-0">Registration closed.</p>;
        } else {
          if (
            event.selPer === 4 &&
            localStorage.getItem("webglToken") !== null
          ) {
            return this.props.isHideJoinButton ? (
              ""
            ) : (
              <button
                className="btn light-green"
                style={{ cursor: "not-allowed" }}
              >
                Joined
              </button>
            );
          } else {
            if (!this.props.directPay) {
              if (event.eventPriority === 1) {
                return this.props.isHideJoinButton ? (
                  ""
                ) : !event.settings.haveTeamRoster ? (
                  <button
                    className="btn light-green"
                    onClick={() => this.payment(item)}
                  >
                    Register
                  </button>
                ) : (
                  <button
                    className="btn light-green"
                    onClick={() =>
                      item.eligible
                        ? this.payment(item)
                        : !this.props.profileDetails.commonData?.result?.profile
                          ?.invite
                          ? item.hasOwnProperty("eligible")
                            ? this.props.eligibleNext(item)
                            : this.payment(item)
                          : this.showAlertInvitation()
                    }
                  >
                    Register
                  </button>
                );
              } else {
                return this.props.isHideJoinButton ? (
                  ""
                ) : !event.settings.haveTeamRoster ? (
                  <button
                    className="btn light-green"
                    onClick={() => this.payment(item)}
                  >
                    Register
                  </button>
                ) : item.regRequest === 0 ? (
                  <button
                    className="btn light-green"
                    onClick={() =>
                      item.eligible
                        ? this.openRegisterRoster(item)
                        : !this.props.profileDetails.commonData?.result?.profile
                          ?.invite
                          ? item.hasOwnProperty("eligible")
                            ? this.props.eligibleNext(item)
                            : this.openRegisterRoster(item)
                          : this.showAlertInvitation()
                    }
                  >
                    Request to Register
                  </button>
                ) : item.regRequest === 1 ? (
                  <button
                    className="btn light-green"
                    style={{ cursor: "not-allowed" }}
                  >
                    Request Pending
                  </button>
                ) : (
                  <button
                    className="btn light-green"
                    onClick={() =>
                      (item?.roster && item?.roster?._id) ? this.payment(item) :
                        item.eligible
                          ? this.payment(item)
                          : !this.props.profileDetails.commonData?.result?.profile
                            ?.invite
                            ? item.hasOwnProperty("eligible")
                              ? this.props.eligibleNext(item)
                              : this.payment(item)
                            : this.showAlertInvitation()
                    }
                  >
                    Register
                  </button>
                );

                // this.requestRegister(item) //// use karva no 6

                // item.regRequest === 0 ? <button className="btn light-green" onClick={() => item.eligible ? this.payment(item) : !this.props.profileDetails.commonData?.result?.profile?.invite ? item.hasOwnProperty('eligible') ? this.props.eligibleNext(item) : this.payment(item) : this.showAlertInvitation()} >Request to Register</button> :
                //     item.regRequest === 1 ? <button className="btn light-green" style={{ cursor: 'not-allowed' }}>Request Pending</button> :
                //         <button className="btn light-green" onClick={() => item.regRoster ? this.payment(item) : !this.props.profileDetails.commonData?.result?.profile?.invite ? item.hasOwnProperty('regRoster') ? this.props.eligibleNext(item) : this.payment(item) : this.showAlertInvitation()} >Request to Register</button>
                //         )
              }
            } else {
              return (
                <button
                  className="btn light-green"
                  onClick={() => this.payDirect()}
                >
                  Pay
                </button>
              );
            }
          }
        }
      }
    }

    if (event.eventTeamType === 2) {
      if (localStorage.getItem("coachToken")) {
        return null;
      } else {
        if (!chkdate) {
          return <p className="mb-0">Registration closed.</p>;
        } else {
          if (
            event.selPer === 4 &&
            localStorage.getItem("webglToken") !== null
          ) {
            return this.props.isHideJoinButton ? (
              ""
            ) : (
              <button
                className="btn light-green"
                style={{ cursor: "not-allowed" }}
              >
                Joined
              </button>
            );
          } else {
            if (!this.props.directPay) {
              return this.props.isHideJoinButton ? (
                ""
              ) : !event.settings.haveTeamRoster ? (
                <button
                  className="btn light-green"
                  onClick={() => this.payment(item)}
                >
                  Register
                </button>
              ) : (
                <button
                  className="btn light-green"
                  onClick={() =>
                    item.eligible
                      ? this.payment(item)
                      : this.props.eligibleNext()
                  }
                >
                  Register
                </button>
              );
            } else {
              return (
                <button
                  className="btn light-green"
                  onClick={() => this.payDirect()}
                >
                  Pay
                </button>
              );
            }
          }
        }
      }
    }
  };

  requestRegister = (data) => {
    var isloggedIn = checkLoginValidation();
    // if (this.validator.allValid()) {
    if (isloggedIn) {
      let url = new URL(document.location);
      let eventId = url.pathname.split("/")[2];
      this.setState(
        {
          regRosterPopup: false,
          eventJoin: {
            ...this.state.eventJoin,
            amount: 0,
            ageGroup: data.ageGroup,
            ageGroupTo: data.ageGroupTo,
            slectedGroupFeeId: data._id,
            event: eventId,
          },
        },
        () => {
          this.props.requestToRegister(this.state.eventJoin);
        }
      );
    }
    // }
    else {
      // this.validator.showMessages();
      //       this.forceUpdate();
      localStorage.setItem("eventDetail", this.props.eventId);
      this.props.history.push("/login");
    }
  };

  payment = (item) => {
    var isloggedIn = checkLoginValidation();
    if (isloggedIn) {
      if (this.props.dataList.settings.redirectURL || item.redirectURL) {
        let linkURL = this.props.dataList.settings.redirectURL
          ? this.props.dataList.linkUrl
          : item.linkUrl;
        window.open(linkURL, "_blank");
      } else {
        let svalid_date = moment().format("MM/DD/YYYY");
        let evalid_date = moment(this.props.dataList.regClosedDate).format("MM/DD/YYYY");

        let chkdate = moment(svalid_date).isSameOrBefore(evalid_date);

        if (chkdate) {
          if (
            this.props.dataList.eventTeamType === 1 &&
            this.props.teamArr.length === 0
          ) {
            this.commonCall.errorMessage(
              "You need to tie up with one team before doing event registration."
            );
          } else {
            localStorage.setItem("ageGroupData", JSON.stringify(item));
            this.props.history.push(`/event-form/${this.props.eventId}`);
            // if( this.props.dataList.eventPriority === 1 ){
            //         this.props.history.push(`/event-form/${this.props.eventId}`)
            //     }else{
            //         this.props.regRosterPopup = true
            //     }
          }
        } else {
          console.log("registration closed.....................");
          // this.setState({
          //     closePopup: true
          // })
        }
      }
    } else {
      localStorage.setItem("eventDetail", this.props.eventId);
      localStorage.setItem("noAge", this.props.eventId);
      this.props.history.push("/login");
    }
  };

  payDirect = () => {
    var isloggedIn = checkLoginValidation();
    if (isloggedIn) {
      let svalid_date = moment().format(
        "MM/DD/YYYY"
      );
      let evalid_date = moment(this.props.dataList.regClosedDate).format(
        "MM/DD/YYYY"
      );

      let chkdate = moment(svalid_date).isAfter(evalid_date);

      if (chkdate) {
        this.props.paymentIntent();
      } else {
        this.setState({
          closePopup: true,
        });
      }
    } else {
      localStorage.setItem("eventDetail", this.props.eventId);
      localStorage.setItem("eventDirectPayment", true);
      this.props.history.push("/login");
    }
  };

  showAlertInvitation = () => {
    this.commonCall.errorMessage(
      "You are waiting to join the team. Please try after you tie-up with the team."
    );
  };

  renderAgeGroupData = () => {
    return this?.props?.dataList?.payment?.feesByGroups.map((item, i) => {

      console.log('item', item)

      return (
        <tr>
          <td>
            {
               (localStorage.getItem("company") === "pioneer" || this.props.dataList?.isNewCompanyEvent) && item.ageGroup === 19 && item.ageGroupTo === 19 ? "18U+" :
                <>
                  {item.ageGroup === 19 ? "18U+" : item.ageGroup + "U"} -{" "}
                  {item.ageGroupTo === 19 ? "18U+" : item.ageGroupTo + "U"}
                </>
            }
          </td>
          {this.props.dataList.eventTeamType === 1 && (
            <td>{item.div[0] ? item.div[0].title : "-"}</td>
          )}
          {this.props.dataList.eventTeamType === 1 ?
            this.props.dataList.settings.showTeams === true ?
              <td>
                {item.teamLimit - item.teamReg}
              </td> : "" :
            this.props.dataList.eventTeamType === 2 ?
              this.props.dataList.settings.showSpotAvail === true ? <td>
                {item.teamLimit - item.teamReg}
              </td> : "" : ""}
          {this.props.dataList.eventTeamType === 1 && (
            <td>${item.feesOfGroup}</td>
          )}
          {this.props.dataList.eventTeamType === 2 && (
            <>
              <td>${item.positionFee}</td>
              <td>${item.pitcherFee}</td>
              <td>${item.twoWayFee}</td>
            </>
          )}
          {this.props.dataList.eventTeamType === 1 && !this.props.directPay && (
            <td>{this.isShowJoin(item)}</td>
          )}
        </tr>
      );
    });
  };

  sortFileValue = (obj1Value, obj2Value) => {
    const firstString =
      obj1Value && obj1Value.isValue
        ? obj1Value.isValue.path.toUpperCase()
        : "";
    const secondString =
      obj2Value && obj2Value.isValue
        ? obj2Value.isValue.path.toUpperCase()
        : "";
    if (firstString > secondString) return 1;
    return -1;
  };

  customSortByTeam = (obj1Value, obj2Value) => {
    const firstString = obj1Value
      ? obj1Value.props.children.props.children[1].props.children.toUpperCase()
      : "";
    const secondString = obj2Value
      ? obj2Value.props.children.props.children[1].props.children.toUpperCase()
      : "";
    if (firstString < secondString) {
      return -1;
    }
    if (firstString > secondString) {
      return 1;
    }
    return 0;
  };



  openRegisterRoster = (item) => {
    let url = new URL(document.location);
    let eventId = url.pathname.split("/")[2];
    if (
      this.props.dataList.eventTeamType === 1 &&
      this.props.teamArr.length === 0
    ) {
      this.commonCall.errorMessage(
        "You need to tie up with one team before doing event registration."
      );
    } else {
      this.setState({
        regRosterPopup: true,
        reqRoster: {
          ageGroup: item.ageGroup,
          ageGroupTo: item.ageGroupTo,
          eventId: eventId,
          _id: item._id,
        },
      });
    }
  };

  closeRegisterRoster = () => {
    this.setState(
      {
        regRosterPopup: false,
      },
      () => {
        document.body.classList.remove("modal-open");
      }
    );
  };

  selectRoster = (value) => {
    this.setState({
      eventJoin: { ...this.state.eventJoin, rosterTeamId: value },
    });
  }

  render() {
    const { dataList, pList, regRosterPopup } = this.props;
    var rows = [];

    pList &&
      pList.map((list, i) =>
        rows.push({
          no: i + 1,
          team: (
            <>
              <div className="teams-logo">
                <span>
                  <img
                    src={
                      list.team
                        ? list.team.parentTeam &&
                          list.team.parentTeam?.hasOwnProperty("teamLogo")
                          ? list.team.parentTeam.teamLogo ? list.team.parentTeam.teamLogo?.filePath
                            : imagesArr.default_user
                          : imagesArr.default_user
                        : imagesArr.default_user
                    }
                    alt="Teams"
                  />
                </span>
                <span className="teams-text">
                  {list.team ? list.team.parentTeam.name : "-"}
                </span>
              </div>
            </>
          ),
          roster:
            list.rosterTeamId.length !== 0
              ? list.rosterTeamId[0].rosterName
              : "-",
          coach: list.team
            ? list.team.coach.fname + " " + list.team.coach.lname
            : "-",
          state: list.team
            ? list.team.coach.profile.address
              ? list.team.coach.profile.address.state
              : ""
            : "-",
          age:
            list.rosterTeamId.length !== 0
              ? list.rosterTeamId[0]?.ageGroup
                ? list.rosterTeamId[0]?.ageGroup
                : "-"
              : "-",
          regDate: (
            <>{list.regDt ? moment(list.regDt).format("DD-MMM 'YY") : "-"} </>
          ),
        })
      );

    const columns = [
      {
        title: "No.",
        dataIndex: "no",
        key: "no",
      },
      {
        title: "Teams",
        dataIndex: "team",
        key: "team",
        sorter: (a, b) => this.customSortByTeam(a.team, b.team),
        render: (text, record) => {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Roster Name",
        dataIndex: "roster",
        key: "roster",
      },
      {
        title: "Coaches",
        dataIndex: "coach",
        key: "coach",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        sorter: (a, b) => a.state.localeCompare(b.state),
      },
      {
        title: "Age",
        dataIndex: "age",
        key: "age",
        filters: [
          {
            text: "6",
            value: "6",
          },
          {
            text: "7",
            value: "7",
          },
          {
            text: "8",
            value: "8",
          },
          {
            text: "9",
            value: "9",
          },
          {
            text: "10",
            value: "10",
          },
          {
            text: "11",
            value: "11",
          },
          {
            text: "12",
            value: "12",
          },
          {
            text: "13",
            value: "13",
          },
          {
            text: "14",
            value: "14",
          },
          {
            text: "15",
            value: "15",
          },
          {
            text: "16",
            value: "16",
          },
          {
            text: "17",
            value: "17",
          },
          {
            text: "18",
            value: "18",
          },
        ],
        onFilter: (value, record) => {
          return record.age.indexOf(value) === 0;
        },
      },
      {
        title: "Date",
        dataIndex: "regDate",
        key: "regDate",
        sorter: (a, b) => this.sortFileValue(a.regDate, b.regDate),
      },
    ];
    return (
      <>

        <div className="event_left_side">
          <div className="web_description_box">
            <div class="web_desc_title">
              <h3>Details</h3>
            </div>
            <div className="web_list_data d-flex flex-wrap">
              <div className="web_list_data_box">
                <label>Event Type</label>
                <h3>{(localStorage.getItem("company") === "pioneer" || dataList?.isNewCompanyEvent) ? 'Tryout Camp' : this.getEventType(dataList?.eventTeamType)}</h3>
              </div>
              <div className="web_list_data_box table">
                {/* {
                                    dataList??.eventPriority !== 2 && */}
                <table>
                  <tr>
                    <th>Age Group</th>
                    {dataList?.eventTeamType === 1 && <th>Division</th>}
                    {dataList?.eventTeamType === 1 ? dataList?.settings.showTeams === true ? <th>Spots Remaining</th> : "" : dataList?.eventTeamType === 2 && dataList?.settings.showSpotAvail === true ? <th>Spots Remaining</th> : ""}
                    {dataList?.eventTeamType === 1 && (
                      <th>Price Starting At</th>
                    )}
                    {dataList?.eventTeamType === 2 && (
                      <>
                        <th>Position Player</th>
                        <th>Pitcher</th>
                        <th>2 Way Player</th>
                      </>
                    )}
                    {dataList?.eventTeamType === 1 && <th></th>}
                  </tr>
                  {this.renderAgeGroupData()}
                </table>
                {/* } */}
              </div>
            </div>
            {/* <div class="web_desc_title"><h3>Description</h3></div>
                        <p dangerouslySetInnerHTML={{
                            __html: dataList?.information && dataList?.information.event
                        }}></p> */}
            <div class="tabs web_desc">
              <div class="tab">
                <input type="checkbox" id="chck5" />
                <label class="tab-label" for="chck5">
                  {" "}
                  Description
                </label>
                <div
                  class="tab-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      dataList?.information && dataList?.information.event,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck1" />
              <label class="tab-label" for="chck1">

                {dataList?.eventTeamType === 2 ?
                  localStorage.getItem("company") === "pws" ?
                    dataList?.isNewCompanyEvent ? "Early Bird Special" : "Camps" :
                    localStorage.getItem("company") === "pioneer" ?
                      'Early Bird Special' :
                      dataList?.isNewCompanyEvent ? "Early Bird Special" : "Showcase" :
                  dataList?.isNewCompanyEvent ? "Early Bird Special" : "Tournament"}{" "}

                {localStorage.getItem('company') === 'pioneer' || dataList?.isNewCompanyEvent ? '' : "Format"}
              </label>
              <div
                class="tab-content"
                dangerouslySetInnerHTML={{
                  __html:
                    dataList?.information &&
                    dataList?.information.tournamentFormat,
                }}
              ></div>
            </div>
          </div>

          <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck2" />
              <label class="tab-label" for="chck2">
                {dataList?.eventTeamType === 2 ?
                  localStorage.getItem("company") === "pws" ?
                    dataList?.isNewCompanyEvent ? "PBL Eligibility" : "Camps" :
                    localStorage.getItem("company") === "pioneer" ?
                      "PBL Eligibility" :
                      dataList?.isNewCompanyEvent ? "PBL Eligibility" : "Showcase" :
                  dataList?.isNewCompanyEvent ? "PBL Eligibility" : "Tournament"}{" "}

                {localStorage.getItem("company") === "pioneer" || dataList?.isNewCompanyEvent ? '' : "Rules"}
              </label>
              <div
                class="tab-content tournament_rules"
                dangerouslySetInnerHTML={{
                  __html:
                    dataList?.information &&
                    dataList?.information.tournamentRule,
                }}
              ></div>
            </div>
          </div>

          <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck3" />
              <label class="tab-label" for="chck3">
                {localStorage.getItem("company") === "pioneer" || dataList?.isNewCompanyEvent ? 'Frequently Asked Questions' : "Gate Information"}
              </label>
              <div
                class="tab-content"
                dangerouslySetInnerHTML={{
                  __html:
                    dataList?.information && dataList?.information.gateInfo,
                }}
              ></div>
            </div>
          </div>

          {dataList?.settings &&
            dataList?.settings.showTeams &&
            dataList?.eventTeamType === 1 && (
              <div class="tabs">
                <div class="tab">
                  <input type="checkbox" id="chck4" />
                  <label class="tab-label" for="chck4">
                    Participating Teams
                  </label>
                  <div class="tab-content">
                    <div className="participating-teams-table">
                      <div className="table-responsive-xl">
                        <Table
                          className="table"
                          dataSource={rows}
                          columns={columns}
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>

        {this.state.regRosterPopup && (
          <RegisterRosterPopup
            flag={this.state.regRosterPopup}
            type="regRoster"
            close={this.closeRegisterRoster}
            ok={() => this.requestRegister(this.state.reqRoster)}
            req={this.state.reqRoster}
            eventJoin={this.state}
            onHandleChange={this.selectRoster}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profileDetails: state.headerData,
});

let eventInfoComponents = connect(mapStateToProps)(eventInfo);
export default withRouter(eventInfoComponents);

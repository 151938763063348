import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { coachList } from '../../../../saga/actions/authantication/signup/player/coach.action';
import Preloader from '../../../../components/preloader';

class coach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamArr: [],
            selectedId: "",
            filter: {
                parentTeam: this.props.pTeamId,
                page: 1,
                limit: 10,
                name: ""
            },
            coachArr: {
                team: ""
            },
            loader: false,
            invite: false,
            childCoach: []
        }
    }

    componentWillMount() {
        this.props.onRef(this);
        if (this.props.inviteFlag) {
            this.setState({
                invite: this.props.inviteFlag,
                childCoach: this.props.data
            }, () => {
                this.setState((prevState) => ({
                    ...prevState,
                    coachArr: {
                        ...prevState.teamArr,
                        team: this.state.childCoach._id
                    }
                }));
            })
        } else {
            this.setLoader(true);
            this.props.coachList(this.state.filter);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.coachDetails &&
            nextProps.coachDetails.searchCoachRes &&
            nextProps.coachDetails.searchCoachData
        ) {
            nextProps.coachDetails.searchCoachRes = false;
            if (nextProps.coachDetails.searchCoachData.flag) {
                if (nextProps.coachDetails.searchCoachData.result.hasOwnProperty('notification') && nextProps.coachDetails.searchCoachData.result.notification !== null) {       
                    this.setState({
                        invite: true,
                        childCoach: nextProps.coachDetails.searchCoachData.result.notification.team,
                        teamArr: nextProps.coachDetails.searchCoachData.result.allTeam
                    }, () => {
                        this.setLoader(false);
                        this.setState((prevState) => ({
                            ...prevState,
                            coachArr: {
                                ...prevState.coachArr,
                                team: this.state.childCoach._id
                            }
                        }));
                        nextProps.coachDetails.searchCoachData = null;
                    })
                } else {
                    if (nextProps.coachDetails.searchCoachData.result.hasOwnProperty('myTeam') && nextProps.coachDetails.searchCoachData.result.myTeam.length !== 0) {
                        this.setSelectValue(nextProps.coachDetails.searchCoachData.result.allTeam, flag => {

                            if (flag) {

                                if (this.state.filter.search) {
                                    return this.setState({
                                        teamArr: [nextProps.coachDetails.searchCoachData.result.myTeam[0]],
                                        selectedId: nextProps.coachDetails.searchCoachData.result.myTeam[0]._id,
                                        coachArr: {
                                            team: nextProps.coachDetails.searchCoachData.result.myTeam[0]._id
                                        },
                                    }, () => {
                                        nextProps.coachDetails.searchCoachData = null;
                                        this.setLoader(false);
                                    })
                                }

                                for (let i = 0; i < nextProps.coachDetails.searchCoachData.result.myTeam.length; i++) {
                                    nextProps.coachDetails.searchCoachData.result.allTeam.unshift(nextProps.coachDetails.searchCoachData.result.myTeam[i])
                                }

                                this.setState({
                                    // teamArr: [nextProps.coachDetails.searchCoachData.result.myTeam[0]],
                                    selectedId: nextProps.coachDetails.searchCoachData.result.myTeam[0]._id,
                                    coachArr: {
                                        team: nextProps.coachDetails.searchCoachData.result.myTeam[0]._id
                                    },
                                }, () => {
                                    nextProps.coachDetails.searchCoachData = null;
                                    this.setLoader(false);
                                })
                            }
                        });
                    } else {
                        this.setState({
                            teamArr: nextProps.coachDetails.searchCoachData.result.allTeam
                        }, () => {
                            nextProps.coachDetails.searchCoachData = null;
                            this.setLoader(false);
                        });
                    }
                }
            } else {
                this.setLoader(false);
                nextProps.coachDetails.searchCoachData = null;
            }
        }

    }

    setSelectValue = (val, cb) => {
        this.setState({
            teamArr: val,
        }, () => cb(true));
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "team") {
            this.setState((prevState) => ({
                ...prevState,
                coachArr: {
                    ...prevState.coachArr,
                    [name]: value
                },
                selectedId: value
            }));
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    page: 1,
                    limit: 10,
                    name: value
                },
            }), () => {
                if (value.length !== 0) {
                    this.setLoader(true);
                    this.props.coachList(this.state.filter);
                } else {
                    this.state.teamArr = [];
                    this.props.coachDetails.searchCoachData = null;
                    this.setLoader(true);
                    this.props.coachList(this.state.filter);
                }
            });
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.coachList(this.state.filter);
    }

    renderCoachList = () => {
        return (
            this.state.invite ?
                <div className="team-flex">
                    <div className="team-data">
                        <div className="team-img"><img src={this.state.childCoach.coach.profileUrl ? this.state.childCoach.coach.profileUrl : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                        <h4>{this.state.childCoach.coach.fname + " " + this.state.childCoach.coach.lname}</h4>
                    </div>
                    <div className="radiobox">
                        <input
                            type="radio"
                            id="team"
                            value={this.state.childCoach._id}
                            name="team"
                            onChange={(e) => this.onHandleChange(e)}
                            checked
                        />
                        <span for="team"></span>
                    </div>
                </div>
                :
                this.state.teamArr.length !== 0 ?
                    this.state.teamArr.map((list, i) => {
                        return (
                            <div className="team-flex" key={i}>
                                <div className="team-data">
                                    <div className="team-img"><img src={list.coach ? list.coach.profileUrl : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                    <h4>{list.coach && list.coach.fname + " " + list.coach.lname}</h4>
                                </div>
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="Baseball"
                                        value={list._id}
                                        name="team"
                                        onChange={(e) => this.onHandleChange(e)}
                                        checked={list._id === this.state.selectedId ? true : false}
                                    />
                                    <span for="Baseball"></span>
                                </div>
                            </div>
                        );
                    }) :
                    <h4 className="text-center">No Data Found</h4>
        );
    }

    validationUpdate = (cb) => {
        if (this.state.coachArr.team) {
            cb({ data: this.state.coachArr, flag: true, coachData: this.state.teamArr });
        } else {
            cb({ data: '', flag: false });
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

      render() {
        const { profileData } = this.props;
        const { loader, filter, invite } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg playes-team">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname},
                            </h2>
                            <p>
                                Find your coach by typing in their name below.
                            </p>
                        </div>
                        {
                            !invite &&
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your coach" name="search" value={filter.search} onChange={this.onHandleChange} />
                                        {/* <Link className="serch-btn" onClick={this.onSearch}><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="scrollbar_team">
                            <div className="select_team ml-0">
                                {this.renderCoachList()}
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

coach.propTypes = {
    coachList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    coachDetails: state.coachPlayerDetails
});

export default connect(mapStateToProps, { coachList })(coach);

import {
    GET_QUESTION_SUCCESS,
    GET_QUESTION_ERROR,
    GET_QUESTION_REQUEST,

    POST_QUESTION_REQUEST,
    POST_QUESTION_ERROR,
    POST_QUESTION_SUCCESS,

    SET_QUESTION_DATA

} from '../../../../constants/authantication/signup/player/question.const';

const initialState = {
    getQuestionRes: false,
    getQuestionData: null,
    getQuestionError: null,

    savePlayerQuestionRes: false,
    savePlayerQuestionData: null,
    savePlayerQuestionError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get question
        case GET_QUESTION_REQUEST:
            return {
                ...state,
                getQuestionRes: true
            };
        case GET_QUESTION_SUCCESS:
            return {
                ...state,
                getQuestionData: action.payload
            };
        case GET_QUESTION_ERROR:
            return {
                ...state,
                getQuestionError: action.error,
            };

        case POST_QUESTION_REQUEST:
            return {
                ...state,
                savePlayerQuestionRes: true
            };

        case POST_QUESTION_SUCCESS:
            return {
                ...state,
                savePlayerQuestionData: action.payload
            };
        case POST_QUESTION_ERROR:
            return {
                ...state,
                savePlayerQuestionError: action.error,
            };
        case SET_QUESTION_DATA:
            return { ...state, questionData: action.payload }

        default:
            return state;
    }
}
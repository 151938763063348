import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import CommonHeroBanner from '../../components/commonHeroBanner';
import { imagesArr } from '../../../src/assets/images';
import ProductPurchase from '../../views/popup/productPurchase';
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { purchase } from '../../saga/actions/common/payment.action';
import Preloader from '../../components/preloader';
import InputMask from 'react-input-mask';

class fanPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            sendData: {
                packageId: "",
                planId: "",
                trialPeriod: 0,
                cardObj: {
                    fullname: "",
                    number: "",
                    exp_month: "",
                    exp_year: "",
                    cvc: ""
                }
            },
            chk: false,
            title: "",
            purchasePopup: false,
            loader: false,
            mask: "9999 9999 9999 9999"
        }
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        this.setLoader(true);
        this.getPack();
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.paymentInfo &&
            nextProps.paymentInfo.paymentReq &&
            nextProps.paymentInfo.paymentData
        ) {
            nextProps.paymentInfo.paymentReq = false;
            console.log("nextProps.paymentInfo.paymentData:--", nextProps.paymentInfo.paymentData);
            if (nextProps.paymentInfo.paymentData.flag) {
                let resData = nextProps.paymentInfo.paymentData.result.result.packageId;
                if (localStorage.getItem("popupUrl") === "/pw-leaderboard" && resData.name === "DC-Stats-Player-Premium") {
                    localStorage.setItem("dc_stats", "true");
                } else {
                    localStorage.setItem("subscribe", resData.type);
                }
                this.setState({
                    purchasePopup: true
                }, () => {
                    nextProps.paymentInfo.paymentData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.paymentInfo.paymentData.message);
                nextProps.paymentInfo.paymentData = null;
                this.setLoader(false);
            }
        }
    }

    getPack = () => {
        this.commonCall.getStorage("payPlans", data => {
            console.log(data);
            if (data !== null) {
                this.setState((prevState) => ({
                    ...prevState,
                    sendData: {
                        ...prevState.sendData,
                        packageId: data.packid,
                        planId: data.plans._id,
                        trialPeriod: data.trialPeriod
                    },
                    plans: data.plans,
                    title: data.title
                }), () => {
                    this.setLoader(false);
                });
            }
        });
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        if (name === "fullname") {
            this.setState((prevState) => ({
                ...prevState,
                sendData: {
                    ...prevState.sendData,
                    cardObj: {
                        ...prevState.sendData.cardObj,
                        [name]: value
                    }
                },
            }));
        }

        if (name === "number") {
            if (/^3[47]/.test(value)) {
                this.setState({
                    mask: "9999 999999 99999"
                })
            } else {
                this.setState({
                    mask: "9999 9999 9999 9999"
                })
            }

            this.setState((prevState) => ({
                ...prevState,
                sendData: {
                    ...prevState.sendData,
                    cardObj: {
                        ...prevState.sendData.cardObj,
                        [name]: value
                    }
                },
            }));
        }

        if (name === "exp_month") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 2 && value <= 12 && value !== '00' && value !== '0') {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendData: {
                            ...prevState.sendData,
                            cardObj: {
                                ...prevState.sendData.cardObj,
                                [name]: value
                            }
                        },
                    }));
                }
            }
        }

        if (name === "exp_year") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 4) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendData: {
                            ...prevState.sendData,
                            cardObj: {
                                ...prevState.sendData.cardObj,
                                [name]: value
                            }
                        },
                    }));
                }
            }
        }

        if (name === "cvc") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 4) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendData: {
                            ...prevState.sendData,
                            cardObj: {
                                ...prevState.sendData.cardObj,
                                [name]: value
                            }
                        },
                    }));
                }
            }
        }

        if (name === "chk") {
            this.setState({
                [name]: !this.state.chk
            })
        }
    }

    send = () => {
        if (this.validator.allValid()) {
            if (this.renderCardName(this.state.sendData.cardObj.number) === "Visa" ||
                this.renderCardName(this.state.sendData.cardObj.number) === "Mastercard" ||
                this.renderCardName(this.state.sendData.cardObj.number) === "Discover") {
                if (this.state.sendData.cardObj.number.replace(/[^0-9 ]/g, "").trim().length >= 19) {
                    if (this.state.chk) {
                        this.state.sendData.cardObj.number = this.state.sendData.cardObj.number.replace(/[^0-9 ]/g, "").trim()
                        this.setLoader(true);
                        this.props.purchase(this.state.sendData, "Fan", localStorage.getItem("fanToken"));
                    } else {
                        this.commonCall.errorMessage("Please check privacy policy");
                    }
                } else {
                    this.commonCall.errorMessage("Enter valid card number");
                }
            } else {
                if (this.renderCardName(this.state.sendData.cardObj.number) === "AMEX") {
                    if (this.state.sendData.cardObj.number.replace(/[^0-9 ]/g, "").trim().length >= 17) {
                        if (this.state.chk) {
                            this.state.sendData.cardObj.number = this.state.sendData.cardObj.number.replace(/[^0-9 ]/g, "").trim()
                            this.setLoader(true);
                            this.props.purchase(this.state.sendData, "Fan", localStorage.getItem("fanToken"));
                        } else {
                            this.commonCall.errorMessage("Please check privacy policy");
                        }
                    } else {
                        this.commonCall.errorMessage("Enter valid card number");
                    }
                }
            }

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    renderCardName = (number) => {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard
        var re = new RegExp("^5[1-8][0-9][0-5]|2[1-5][0-9][0-5]");
        if (number.match(re) != null)
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";
    }

    render() {
        const { title, plans, sendData, chk, purchasePopup, loader } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="payment_section">
                    <div className="container">
                        <div className="white-bg">
                            <div className="payment_method row">
                                <div className="payment_detail">
                                    <div className="title-name">
                                        <h2>Payment Method</h2>
                                        <p></p>
                                    </div>
                                    <div className="payment_form">
                                        <div className="check_input">
                                            <div className="check_inputbox">
                                                <label className="common-lable">Card Number</label>
                                                <div className="form-group mb-0">
                                                    <InputMask {...this.props} className="form-control" mask={this.state.mask} name="number" value={sendData.cardObj.number} onChange={this.onHandleChange} placeholder="Enter Card Number" />
                                                    {/* <input type="text" name="number" value={sendData.cardObj.number} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Enter Card Number" /> */}
                                                </div>
                                                <span className="validMsg">{this.validator.message('Card Number', sendData.cardObj.number, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="check_input">
                                            <div className="check_inputbox">
                                                <label className="common-lable">Full Name</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" name="fullname" value={sendData.cardObj.fullname} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Enter Full Name" />
                                                </div>
                                                <span className="validMsg">{this.validator.message('full Name', sendData.cardObj.fullname, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="select_box row">
                                            <div className="select_box_inner">
                                                <label className="common-lable">Exp. Month</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" name="exp_month" value={sendData.cardObj.exp_month} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Enter Month" />
                                                </div>
                                                <span className="validMsg">{this.validator.message('month', sendData.cardObj.exp_month, 'required')}</span>
                                            </div>
                                            <div className="select_box_inner">
                                                <label className="common-lable">Exp. Year</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" name="exp_year" value={sendData.cardObj.exp_year} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Enter Year" />
                                                </div>
                                                <span className="validMsg">{this.validator.message('Year', sendData.cardObj.exp_year, 'required')}</span>
                                            </div>
                                            <div className="select_box_inner">
                                                <label className="common-lable">CVV</label>
                                                <div className="form-group mb-0">
                                                    <input type="password" name="cvc" value={sendData.cardObj.cvc} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Enter CVV" />
                                                </div>
                                                <span className="validMsg">{this.validator.message('cvv', sendData.cardObj.cvc, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="payment_symbols">
                                            <img src={this.renderCardName(sendData.cardObj.number) === "Visa" ? imagesArr.color_visa_icon : imagesArr.visa_icon} />
                                            <img src={this.renderCardName(sendData.cardObj.number) === "AMEX" ? imagesArr.color_american_express_icon : imagesArr.american_express_icon} />
                                            <img src={this.renderCardName(sendData.cardObj.number) === "Mastercard" ? imagesArr.color_mastercard_icon : imagesArr.mastercard_icon} />
                                            <img src={this.renderCardName(sendData.cardObj.number) === "Discover" ? imagesArr.color_discover_icon : imagesArr.discover_icon} />
                                        </div>
                                        {/* <div className="billing_addrss">
                                            <h3>Billing Address</h3>
                                            <div className="toggle_switch">
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                                <p>Same as Shipping Address</p>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                                <div className="payment_amount">
                                    <div className="form_content">
                                        <div className="table_content_main">
                                            <div className="table_header">
                                                <h4>{title}</h4>
                                                <h4 className="price-right">${plans.amount} <span>/{plans.type === 1 ? "month" : "year"}</span></h4>
                                            </div>
                                            <div className="table_content">
                                                <table>
                                                    {
                                                        sendData.trialPeriod > 0 &&
                                                        <thead>
                                                            <th colSpan="2" style={{ textAlign: 'center' }}>You will be billed after your {sendData.trialPeriod} day free trial has ended</th>
                                                        </thead>
                                                    }
                                                    <thead>
                                                        <th>Total Amount</th>
                                                        <th>${plans.amount}</th>
                                                    </thead>
                                                    {/* <thead>
                                                        <th>subtotal</th>
                                                        <th>$19.9</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Shipping</td>
                                                            <td>$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discount</td>
                                                            <td>$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Taxes</td>
                                                            <td>$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="TotalAmount">Total Amount</td>
                                                            <td className="TotalAmount">$19.99</td>
                                                        </tr>
                                                    </tbody> */}
                                                </table>
                                                <div className="purchase_policy">
                                                    <div className="form-group-checkbox">
                                                        <input type="checkbox" name="chk" value={chk} onChange={this.onHandleChange} id="html" />
                                                        <label for="html">Agree with terms conditions and privacy policy</label>
                                                    </div>
                                                    <div className="purchase_btn">
                                                        <Link className="btn w-100" onClick={() => this.send()}>Purchase</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <ProductPurchase
                    flag={purchasePopup}
                    productImage={localStorage.getItem("popupUrl") === "/ballrapp" ? imagesArr.ballrlogo : localStorage.getItem("popupUrl") === "/pw-leaderboard" ? localStorage.getItem("dcStats") ? imagesArr.dc_stats : imagesArr.company_logo_1 : imagesArr.headinglogo3}
                    nextUrl={localStorage.getItem("popupUrl") === "/pw-leaderboard" ? "/pw-leaderboard" : "/fan-dashboard"}
                />
                <Preloader flag={loader} />
            </>
        );
    }
}

fanPayment.propTypes = {
    purchase: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    paymentInfo: state.paymentInfo,
});

export default connect(mapStateToProps, { purchase })(fanPayment);

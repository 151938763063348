import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { uploadFacImage, searchZipcode, facilityList } from '../../../../saga/actions/authantication/signup/coach/facility.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import Preloader from '../../../../components/preloader';
import ImageCrop from '../../../../views/popup/imageCrop';

class facility extends Component {
    constructor(props) {
        super(props)
        this.state = {
            faciltyLogo: null,
            facilityArr: {
                facilty: "yes",
                faciltyName: "",
                faciltyLogo: "",
                faciltyAddress: {
                    street: "",
                    city: "",
                    state: "",
                    zipcode: ""
                },
                facTwitterLink: "",
                facInstagramLink: ""
            },
            slink: "",
            loader: false,
            cropPopup: false,
            renderFile: "",
            imgObj: "",
        }
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onRef(this);
        this.setLoader(true);
        this.props.facilityList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.facDetails &&
            nextProps.facDetails.facImageRes &&
            nextProps.facDetails.facImageData
        ) {
            nextProps.facDetails.facImageRes = false;
            this.setState((prevState) => ({
                ...prevState,
                facilityArr: {
                    ...prevState.facilityArr,
                    faciltyLogo: nextProps.facDetails.facImageData._id
                },
                faciltyLogo: nextProps.facDetails.facImageData.filePath,
                imgObj: "",
                cropPopup: false,
                renderFile: ""
            }), () => {
                nextProps.facDetails.facImageData = null;
                document.body.classList.remove('modal-open');
                this.setLoader(false);
            });
        }

        if (nextProps.facDetails &&
            nextProps.facDetails.searchZipRes &&
            nextProps.facDetails.searchZipData
        ) {
            nextProps.facDetails.searchZipRes = false;
            if (nextProps.facDetails.searchZipData.flag) {
                let data = nextProps.facDetails.searchZipData;
                this.setState((prevState) => ({
                    ...prevState,
                    facilityArr: {
                        ...prevState.facilityArr,
                        faciltyAddress: {
                            ...prevState.facilityArr.faciltyAddress,
                            city: data.result.city,
                            state: data.result.state,
                            zipcode: data.result.zipcodestr
                        }
                    }
                }), () => {
                    nextProps.facDetails.searchZipData = null;
                    this.setLoader(false);
                });
            } else {
                this.commonCall.errorMessage(nextProps.facDetails.searchZipData.message);
                nextProps.facDetails.searchZipData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.facDetails &&
            nextProps.facDetails.facilityRes &&
            nextProps.facDetails.facilityData
        ) {
            nextProps.facDetails.facilityRes = false;
            if (nextProps.facDetails.facilityData.hasOwnProperty('_id')) {
                if (nextProps.facDetails.facilityData.fieldArr.facilty === "no") {
                    this.setState((prevState) => ({
                        ...prevState,
                        facilityArr: {
                            ...prevState.facilityArr,
                            facilty: nextProps.facDetails.facilityData.fieldArr.facilty,
                            faciltyName: "",
                            faciltyLogo: "",
                            faciltyAddress: {
                                street: "",
                                city: "",
                                state: "",
                                zipcode: ""
                            },
                            facTwitterLink: "",
                            facInstagramLink: ""
                        },
                        faciltyLogo: null,
                    }), () => {
                        nextProps.facDetails.facilityData = null
                        this.setLoader(false);
                    });
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        facilityArr: {
                            ...prevState.facilityArr,
                            facilty: nextProps.facDetails.facilityData.fieldArr.facilty,
                            faciltyName: nextProps.facDetails.facilityData.fieldArr.faciltyName,
                            faciltyLogo: (nextProps.facDetails.facilityData.fieldArr.faciltyLogo) ? nextProps.facDetails.facilityData.fieldArr.faciltyLogo._id : "",
                            faciltyAddress: {
                                street: nextProps.facDetails.facilityData.fieldArr.faciltyAddress.street,
                                city: nextProps.facDetails.facilityData.fieldArr.faciltyAddress.city,
                                state: nextProps.facDetails.facilityData.fieldArr.faciltyAddress.state,
                                zipcode: nextProps.facDetails.facilityData.fieldArr.faciltyAddress.zipcode
                            },
                            facTwitterLink: nextProps.facDetails.facilityData.fieldArr.facTwitterLink,
                            facInstagramLink: nextProps.facDetails.facilityData.fieldArr.facInstagramLink
                        },
                        faciltyLogo: (nextProps.facDetails.facilityData.fieldArr.faciltyLogo) ? nextProps.facDetails.facilityData.fieldArr.faciltyLogo.filePath : "",
                    }), () => {
                        nextProps.facDetails.facilityData = null
                        this.setLoader(false);
                    });
                }
            } else {
                this.setLoader(false);
            }
        }

    }

    handleChange = (e) => {
        let file = e.target.files[0];
        if (file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "png") {
            return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG file");
        }
        this.setState({
            imgObj: file,
            cropPopup: true,
            renderFile: URL.createObjectURL(file)
        });
    }

    handleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            cropPopup: false
        });
    }

    onHadleObject = (obj) => {
        let data = {
            documentType: 10,
            imgFile: obj.file
        }
        this.setLoader(true);
        this.props.uploadFacImage(data);
    }

    onHandleChange = (name, value) => {
        if (name === "facilty" ||
            name === "faciltyName" ||
            name === "facTwitterLink" ||
            name === "facInstagramLink"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                facilityArr: {
                    ...prevState.facilityArr,
                    [name]: value
                },
            }));
        }

        if (name === "street") {
            this.setState((prevState) => ({
                ...prevState,
                facilityArr: {
                    ...prevState.facilityArr,
                    faciltyAddress: {
                        ...prevState.facilityArr.faciltyAddress,
                        [name]: value
                    }
                },
            }));
        }

        if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        facilityArr: {
                            ...prevState.facilityArr,
                            faciltyAddress: {
                                ...prevState.facilityArr.faciltyAddress,
                                [name]: value
                            }
                        },
                    }));
                }
            }
        }

    }

    removeImg = () => {
        this.setState((prevState) => ({
            ...prevState,
            facilityArr: {
                ...prevState.facilityArr,
                faciltyLogo: ""
            },
            faciltyLogo: null
        }));
    }

    searchZipcode = () => {
        if (this.state.facilityArr.faciltyAddress.zipcode.length !== 0) {
            this.setLoader(true);
            this.props.searchZipcode(this.state.facilityArr.faciltyAddress.zipcode);
        }
    }

    validationUpdate = (cb) => {
        let data;
        if (this.state.facilityArr.facilty === "yes") {
            if (this.validator.allValid()) {
                data = {
                    flag: true,
                    facilityArr: this.state.facilityArr
                }
                cb(data);
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                data = {
                    flag: false,
                }
                cb(data);
            }
        } else {
            data = {
                flag: true,
                facilityArr: {
                    facilty: this.state.facilityArr.facilty,
                }
            }
            cb(data);
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { profileData } = this.props
        const { facilityArr, faciltyLogo, loader, imgObj, cropPopup, renderFile } = this.state
        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname + " " + profileData.lname},
                            </h2>
                            <p>
                                Enter Your Facility Information
                            </p>
                        </div>
                        <label className="common-lable">Do you manage or own a baseball/softball training facility?</label>
                        <div className="check_input row">
                            <div className="check_box">
                                <div className="check-box">
                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Baseball" value="yes" name="facilty" onChange={(e) => this.onHandleChange('facilty', e.target.value)} checked={facilityArr.facilty === "yes" ? true : false} />
                                        <label for="Baseball">Yes</label>
                                    </div>
                                </div>
                            </div>
                            <div className="check_box">
                                <div className="check-box">
                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Softball" value="no" name="facilty" onChange={(e) => this.onHandleChange('facilty', e.target.value)} checked={facilityArr.facilty === "no" ? true : false} />
                                        <label for="Softball">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            facilityArr.facilty === "yes" &&
                            <>
                                <label className="common-lable">Name of facility</label>
                                <div className="form-group mb-0">
                                    <input type="text" name="faciltyName" value={facilityArr.faciltyName} onChange={(e) => this.onHandleChange('faciltyName', e.target.value)} className="form-control" id="usr" placeholder="Enter your facility name" />
                                    <span className="validMsg">{this.validator.message('Facility name', facilityArr.faciltyName, 'required')}</span>
                                </div>

                                <label className="common-lable">Upload facility logo</label>
                                <div className="dragdrop-img">
                                    <div className="create_event_fileUpload file_custom_upload text-center">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        {!this.state.faciltyLogo && <p>Click to upload</p>}
                                        {/* .heic,.HEIC */}
                                        <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={this.handleChange} />
                                        {
                                            faciltyLogo &&
                                            <div className="preview_img">
                                                <i className="fas fa-times" onClick={() => this.removeImg()}></i>
                                                <img className="preview_img_inner" src={this.state.faciltyLogo} />
                                            </div>
                                        }
                                    </div>
                                </div>

                                <label className="common-lable">Street address</label>
                                <div className="form-group mb-0">
                                    <input type="text" name="street" value={facilityArr.faciltyAddress.street} onChange={(e) => this.onHandleChange('street', e.target.value)} className="form-control" id="usr" placeholder="Enter street address" />
                                    <span className="validMsg">{this.validator.message('Street Address', facilityArr.faciltyAddress.street, 'required')}</span>
                                </div>

                                <div className="select_box row">
                                    <div className="select_box_inner">
                                        <label className="common-lable">Zip Code <span>( To Look Up City and State )</span></label>
                                        <div className="form-group mb-0">
                                            <div className="serch-box">
                                                <input type="text" value={facilityArr.faciltyAddress.zipcode} onChange={(e) => this.onHandleChange('zipcode', e.target.value)} name="zipcode" className="form-control" id="usr" placeholder="Zip Code" />
                                                <Link className="serch-btn" onClick={() => this.searchZipcode()}>
                                                    {/* <img src={imagesArr.search} alt="search" /> */}
                                                    Next
                                                </Link>
                                            </div>
                                            <span className="validMsg">{this.validator.message('Zipcode', facilityArr.faciltyAddress.zipcode, 'required')}</span>
                                        </div>
                                    </div>
                                    <div className="select_box_inner">
                                        <label className="common-lable">State</label>
                                        <div className="form-group mb-0">
                                            <input type="text" name="state" value={facilityArr.faciltyAddress.state} className="form-control" id="usr" placeholder="State" readOnly />
                                        </div>
                                    </div>
                                    <div className="select_box_inner">
                                        <label className="common-lable">City</label>
                                        <div className="form-group mb-0">
                                            <input type="text" name="city" value={facilityArr.faciltyAddress.city} className="form-control" id="usr" placeholder="City" readOnly />
                                        </div>
                                    </div>
                                </div>

                                <label className="common-lable">Link Your Social Accounts</label>
                                <div className="check_input row">
                                    <div className="check_box">
                                        <Link onClick={() => this.setState({ slink: "twitter" })} className="twitter social_btn w-100">
                                            <img src={imagesArr.twitter} alt="twitter" />Link Twitter account
                                        </Link>
                                    </div>
                                    <div className="check_box">
                                        <Link onClick={() => this.setState({ slink: "insta" })} className="insta social_btn w-100 mr-0">
                                            <img src={imagesArr.insta} alt="insta" />Link Instagram account
                                        </Link>
                                    </div>
                                </div>
                                {
                                    this.state.slink &&
                                    <>
                                        {
                                            this.state.slink === "twitter" ?
                                                <>
                                                    <label className="common-lable">Enter Twitter Link</label>
                                                    <div className="form-group mb-0">
                                                        <input type="text" name="facTwitterLink" value={facilityArr.facTwitterLink} onChange={(e) => this.onHandleChange('facTwitterLink', e.target.value)} className="form-control" id="usr" placeholder="@Name" />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <label className="common-lable">Enter Instagram Link</label>
                                                    <div className="form-group mb-0">
                                                        <input type="text" name="facInstagramLink" value={facilityArr.facInstagramLink} onChange={(e) => this.onHandleChange('facInstagramLink', e.target.value)} className="form-control" id="usr" placeholder="Username" />
                                                    </div>
                                                </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                </section>
                <Preloader flag={loader} />
                <ImageCrop
                    imgObj={imgObj}
                    flag={cropPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />
            </>
        )
    }
}

facility.propTypes = {
    uploadFacImage: PropTypes.func.isRequired,
    searchZipcode: PropTypes.func.isRequired,
    facilityList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    facDetails: state.facDetails,
});

export default connect(mapStateToProps, { uploadFacImage, searchZipcode, facilityList })(facility);
import {
    SAVE_PLAYER_GROUND_REQUEST,
    SAVE_PLAYER_GROUND_SUCCESS,
    SAVE_PLAYER_GROUND_ERROR,

    GET_PLAYER_GROUND_REQUEST,
    GET_PLAYER_GROUND_SUCCESS,
    GET_PLAYER_GROUND_ERROR,
} from '../../../../constants/authantication/signup/player/ground.const';

const initialState = {
    savePlayerGroundRes: false,
    savePlayerGroundData: null,
    savePlayerGroundDataError: null,

    groundRes: false,
    groundData: null,
    groundDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get training
        case GET_PLAYER_GROUND_REQUEST:
            return {
                ...state,
                groundRes: true
            };
        case GET_PLAYER_GROUND_SUCCESS:
            return {
                ...state,
                groundData: action.payload,
            };
        case GET_PLAYER_GROUND_ERROR:
            return {
                ...state,
                groundDataError: action.error,
            };

        // save ground
        case SAVE_PLAYER_GROUND_REQUEST:
            return {
                ...state,
                savePlayerGroundRes: true
            };
        case SAVE_PLAYER_GROUND_SUCCESS:
            return {
                ...state,
                savePlayerGroundData: action.payload,
            };
        case SAVE_PLAYER_GROUND_ERROR:
            return {
                ...state,
                savePlayerGroundDataError: action.error,
            };

        default:
            return state;
    }
}
import {
  GET_COACH_LIST_SEARCH_ERROR,
  GET_COACH_LIST_SEARCH_SUCCESS,
  GET_COACH_LIST_SEARCH_REQUEST
} from '../../../../constants/view/player/profile/coachInfo.const';

const initialState = {
    searchCoachRes: false,
    searchCoachData: null,
    searchCoachDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Zipcode Search Data
        case GET_COACH_LIST_SEARCH_REQUEST:
            return {
                ...state,
                searchCoachRes: true
            };
        case GET_COACH_LIST_SEARCH_SUCCESS:
            return {
                ...state,
                searchCoachData: action.payload,
            };
        case GET_COACH_LIST_SEARCH_ERROR:
            return {
                ...state,
                searchCoachDataError: action.error,
            };


        default:
            return state;
    }
}
import React, { useState } from 'react'
import Footer from '../../../containers/footer'
import Header from '../../../containers/header'
import Preloader from '../../../components/preloader';
import EventRuleDetails from '../components/common/eventRuleDetails';

function Event() {
    const [loaderCount, setLoader] = useState(0);

    const increment = () => {
        setLoader(loaderCount + 1);
    }

    const decrement = () => {
        setLoader(loaderCount - 1);
    }

    return (
        <div>
            <>
                <Header />
                <div className="event-main">

                    {/* Rules section */}
                      <EventRuleDetails increment={increment} decrement={decrement}/>

                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </>
        </div>
    )
}

export default Event
import {
    LIST_LINKED_PLAYER_FAN_SUCCESS,
    LIST_LINKED_PLAYER_FAN_ERROR,
} from '../../../../constants/view/fan/setting/linkedPlayerInfo.const';

const initialState = {
    playerList: [],
    playerListError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // list of Linked Player
        case LIST_LINKED_PLAYER_FAN_SUCCESS:
            return {
                ...state,
                playerList: action.payload,
            };
        case LIST_LINKED_PLAYER_FAN_ERROR:
            return {
                ...state,
                playerListError: action.error,
            };
        default:
            return state;
    }
}
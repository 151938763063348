import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import CommonHeroBanner from '../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import UploadcsvList from './uploadDetails/uploadcsvList';
import DiamondKinetics from './uploadDetails/diamondKinetics';

class fanUploadcsv extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
        }
    }

    handleTabs = (e) => {
        this.setState({
            tabs: e
        })
    }

    render() {
        const { tabs } = this.state;
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {/* Uploadcsv User CSV Data -1 */}
                <section className="main_pro_tab">
                    <div className="container">
                        <div className="edit_pro_scroll">
                            <ul className="edit_pro_inner">
                                <li className={`${tabs === 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                    <Link>Upload CSV</Link>
                                </li>
                                <li className={`${tabs === 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                    <Link>Diamond Kinetics</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {tabs === 0 && <UploadcsvList />}
                {tabs === 1 && <DiamondKinetics />}
                <Footer />

            </>
        );
    }
}

export default fanUploadcsv;

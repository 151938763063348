import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { imagesArr } from '../../../assets/images';
import { connect } from "react-redux";
import { getOtherInfo, getCollegeInfo, passInfo } from "../../../saga/actions/views/player/profile/otherInfo.action";
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import Preloader from '../../../components/preloader';
import InputMask from 'react-input-mask';
import { playerSuggetionList } from '../../../saga/actions/authantication/signup/player/collage.action';
import ReactAutocomplete from 'react-autocomplete';
import { getPlayerDobProfile } from '../../../saga/actions/containers/header.action';


class OtherInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: "",
            graYear: "",
            weight: "",
            currGpa: "",
            school: "",
            inHighSchool: "",

            search: {
                page: 1,
                limit: 10,
                search: "",
            },
            collegeList: [],
            myCollage: {
                "fav-colleges": []
            },

            loader: false,
            listCollage: [],
            collageObj: {},
            value: '',
            suggestions: [],
            dob: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
        this.handleOption = this.handleOption.bind(this);

        //current year + 10 year
        const year = (new Date()).getFullYear();
        let options = [];
        for (var Y = 2035; Y >= 1980; Y--) {
            options.push(Y)
        }
        this.years = options;
        // this.years = Array.from(new Array(16), (val, index) => index + year);
    }

    componentWillMount() {
        this.setLoader(true);
        this.props.getOtherInfo();
        this.props.getPlayerDobProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profileDetails &&
            nextProps.profileDetails.dobPlayerReq &&
            nextProps.profileDetails.dobPlayerData
        ) {
            nextProps.profileDetails.dobPlayerReq = false;
            if (nextProps.profileDetails.dobPlayerData.result.profile) {
                console.log("profile info:--", nextProps.profileDetails.dobPlayerData.result);
                this.setState({
                    dob: nextProps.profileDetails.dobPlayerData.result.profile.dob
                }, () => {
                    nextProps.profileDetails.dobPlayerData = null;
                })
            }
        }

        if (nextProps.otherDetails.otherInfoData) {
            this.setState({
                height: nextProps.otherDetails.otherInfoData.height,
                graYear: nextProps.otherDetails.otherInfoData["gra-year"],
                weight: nextProps.otherDetails.otherInfoData.weight,
                currGpa: nextProps.otherDetails.otherInfoData["curr-gpa"],
                school: nextProps.otherDetails.otherInfoData.school,
                inHighSchool: nextProps.otherDetails.otherInfoData["in-high-school"],
            }, () => {
                this.props.getCollegeInfo(this.state.search);
                nextProps.otherDetails.otherInfoData = null;
                this.setLoader(false);
            });

            // if (nextProps.otherDetails.otherInfoData.height) {
            //     console.log("nextProps.otherDetails.otherInfoData.college:--", nextProps.otherDetails.otherInfoData);

            //     this.setState({
            //         height: nextProps.otherDetails.otherInfoData.height,
            //         graYear: nextProps.otherDetails.otherInfoData["gra-year"],
            //         weight: nextProps.otherDetails.otherInfoData.weight,
            //         currGpa: nextProps.otherDetails.otherInfoData["curr-gpa"],
            //         school: nextProps.otherDetails.otherInfoData.school,
            //         inHighSchool: nextProps.otherDetails.otherInfoData["in-high-school"],
            //     }, () => {
            //         console.log("inHighSchool:----", this.state.inHighSchool);
            //         this.props.getCollegeInfo(this.state.search);
            //         nextProps.otherDetails.otherInfoData = null;
            //         this.setLoader(false);
            //     });
            // } else {
            //     this.props.getCollegeInfo(this.state.search);
            //     nextProps.otherDetails.otherInfoData = null;
            //     this.setLoader(false);
            // }
        }

        if (nextProps.otherDetails.collegeInfoData) {
            console.log("nextProps.otherDetails.collegeInfoData:---", nextProps.otherDetails.collegeInfoData);
            if (nextProps.otherDetails.collegeInfoData.myCollage.length != 0) {
                if (this.state.search.search) {
                    return this.setState((prevState) => ({
                        ...prevState,
                        collegeList: nextProps.otherDetails.collegeInfoData.collagesList,
                    }), () => {
                        nextProps.otherDetails.collegeInfoData = null;
                        this.setLoader(false);
                    })
                }
                console.log("myCollage:---", this.state.myCollage);

                let arr = [];
                if (this.state.search.page === 1) {
                    for (let i = nextProps.otherDetails.collegeInfoData.myCollage.length - 1; i >= 0; i--) {
                        nextProps.otherDetails.collegeInfoData.collagesList.unshift(nextProps.otherDetails.collegeInfoData.myCollage[i])
                        arr.push(nextProps.otherDetails.collegeInfoData.myCollage[i]._id);
                    }
                    this.setState({
                        listCollage: this.state.listCollage.length === 0 ? nextProps.otherDetails.collegeInfoData.myCollage : this.state.listCollage,
                        myCollage: {
                            "fav-colleges": this.state.listCollage.length === 0 ? arr : this.state.myCollage['fav-colleges']
                        },
                    })
                }

                let listData = nextProps.otherDetails.collegeInfoData.collagesList;
                let temp = [];

                listData.map((item) => {
                    temp.push(item)
                });
                var joined = this.state.collegeList.concat(temp);

                this.setState((prevState) => ({
                    ...prevState,
                    collegeList: joined,
                    search: {
                        ...prevState.search,
                        page: this.state.search.page + 1
                    }
                }), () => {
                    nextProps.otherDetails.collegeInfoData = null;
                    this.setLoader(false);
                });
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    collegeList: nextProps.otherDetails.collegeInfoData.collagesList,
                    search: {
                        ...prevState.search,
                        page: this.state.search.page + 1
                    }
                }), () => {
                    nextProps.otherDetails.collegeInfoData = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.otherDetails.postOtherInfoData) {
            if (nextProps.otherDetails.postOtherInfoData.flag) {
                this.commonCall.successMessage(nextProps.otherDetails.postOtherInfoData.message);
                nextProps.otherDetails.postOtherInfoData = null;
                this.setState({
                    collegeList: [],
                    search: {
                        page: 1,
                        limit: 10,
                        search: ""
                    }
                }, () => {
                    this.props.otherDetails.collegeInfoData = null;
                    this.props.getCollegeInfo(this.state.search);
                })

            } else if (!nextProps.otherDetails.postOtherInfoData.flag) {
                this.commonCall.errorMessage(nextProps.otherDetails.postOtherInfoData.message);
                nextProps.otherDetails.postOtherInfoData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.collageDetails &&
            nextProps.collageDetails.searchSuggestionRes &&
            nextProps.collageDetails.searchSuggestionData
        ) {
            nextProps.collageDetails.searchSuggestionRes = false;
            let val = nextProps.collageDetails.searchSuggestionData.result.collagesList;
            let arr = [];
            val.map((item, i) => {
                arr.push({ name: item.name })
            });

            this.setState({
                suggestions: val
            }, () => {
                nextProps.collageDetails.searchSuggestionData = null;
                this.setLoader(false);
            })
        }

    }

    calculate_age = (dob1) => {
        if (dob1) {
            var ageDifMs = Date.now() - new Date(dob1).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;

            // var today = new Date();
            // var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
            // var age_now = today.getFullYear() - birthDate.getFullYear();
            // var m = today.getMonth() - birthDate.getMonth();

            // console.log("today.getDate() < birthDate.getDate():--", today.getDate() < birthDate.getDate());

            // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            //     age_now--;
            // }
            // if (age_now > 18) {
            //     console.log("age_now false:--", age_now);
            //     return false;
            // } else {
            //     console.log("age_now true:--", age_now);
            //     return true;
            // }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    handleChange = (e) => {
        if (e.target.name === "weight") {
            const re = /^[0-9\b]+$/;
            if (e.target.value.length <= 3) {
                if (re.test(e.target.value) || e.target.value.length <= 0) {
                    this.setState({
                        [e.target.name]: e.target.value,
                    });
                }
            }
        }
        else if (e.target.name === "height") {

            const foot = e.target.value.split("'");
            const inches = e.target.value.split(`"`);
            if (foot[1] && inches[0]) {
                const inch = parseInt(foot[1].split(`"`)[0])
                if (inches[1]) {
                    if (inches[1] === "0" || inches[1] === "1" && inch === 1) this.setState({ [e.target.name]: `${foot[0]}'${inch}${inches[1]}"` })
                } else {
                    if (inch > 9 && inch < 12) {
                        if (this.state.height.includes(`"`) && !e.target.value.includes(`"`)) this.setState({ [e.target.name]: `${foot[0]}'${foot[1][0]}"` })
                        else this.setState({ [e.target.name]: `${foot[0]}'${inch}"` })
                    } else if (inch >= 0 && inch <= 9) {
                        const prev = this.state.height.split("'")[1]
                        if (this.state.height.includes(`"`) && !(prev.includes('10') || prev.includes('11'))) this.setState({ [e.target.name]: `${foot[0]}'` })
                        else if (prev.includes('10') || prev.includes('11')) this.setState({ [e.target.name]: `${foot[0]}'${foot[1]}` })
                        else this.setState({ [e.target.name]: `${foot[0]}'${foot[1]}"` })
                    } else if (isNaN(inch)) this.setState({ [e.target.name]: `${foot[0]}'` })
                }
            }
            else if (inches[0] && this.state.height.includes(`"`) && inches[1] !== "") { this.setState({ [e.target.name]: `` }) }
            else if (foot[0] && !this.state.height.includes("'") && foot[1] !== "") { this.setState({ [e.target.name]: `${e.target.value}'` }) }
            else if (foot[0] && this.state.height.includes("'") && foot[1] !== "") { this.setState({ [e.target.name]: `` }) }
            else { this.setState({ [e.target.name]: `${e.target.value}`, }) }

        }
        else{
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    handleOption = (e, item, data) => {
        if (this.state.myCollage['fav-colleges'].find((d) => d === item)) {
            // subtraction
            let tempArray = this.state.myCollage['fav-colleges'].filter((d) => d !== item);
            let tempCollage = this.state.listCollage.filter((d) => d._id !== item);

            this.setState((prevState) => ({
                ...prevState,
                myCollage: {
                    ...prevState.myCollage,
                    ['fav-colleges']: tempArray
                },
                listCollage: tempCollage
            }), () => console.log("this.state.listCollage:--", this.state.listCollage));
        } else {
            // addition
            if (this.state.listCollage.length >= 10) {
                e.preventDefault()
                e.currentTarget.checked = false;
                return this.commonCall.errorMessage("You can't select more than 10 colleges.");
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    myCollage: {
                        ...prevState.myCollage,
                        ['fav-colleges']: [...prevState.myCollage['fav-colleges'], item]
                    },
                    listCollage: [...prevState.listCollage, data]
                }), () => console.log("this.state.listCollage:--", this.state.listCollage));
            }

        }
    }

    renderCollageList = () => {
        return (
            this.state.collegeList.length != 0 &&
            this.state.collegeList.map((e) => {
                return (
                    <div className="team-flex" key={e._id}>
                        <div className="team-data">
                            <div className="team-img"><img src={e.image && e.image.filePath ? e.image.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="collage0" /></div>
                            <h4>{e.name}</h4>
                        </div>
                        <div className="radiobox" >
                            <input type="checkbox" checked={this.state.myCollage['fav-colleges'].find((d) => { if (d === e._id) return "checked" })} value={e._id} name="selectedCollage" onChange={(d) => this.handleOption(d, e._id, e)} />
                            <span for="Baseball"></span>
                        </div>
                    </div>
                );
            })
        )
    }

    searchCollege = () => {
        this.props.getCollegeInfo(this.state.search);
    }

    postData = async () => {

        let registerPostData = {
            height: this.state.height,
            weight: this.state.weight,
            school: this.state.school,
            "gra-year": this.state.graYear,
            "curr-gpa": this.state.currGpa,
            "in-high-school": this.state.inHighSchool,
            "fav-colleges": this.state.myCollage['fav-colleges']
        }

        console.log("info:--", registerPostData);

        this.setLoader(true);
        await this.props.passInfo(registerPostData);



    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setLoader(true);
            this.props.getCollegeInfo(this.state.search);
        }
    }

    handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            search: {
                page: 1,
                limit: 10,
                search: value
            }
        }), () => {
            if (value.length !== 0) {
                this.props.getCollegeInfo(this.state.search);
            } else {
                this.state.collegeList = [];
                this.props.otherDetails.collegeInfoData = null;
                this.props.getCollegeInfo(this.state.search);
            }
        })
    }

    setValue = (e) => {
        console.log("e:----", e.target.value);
        if (e.target.value.length !== 0) {
            this.setState({
                value: e.target.value
            }, () => {
                this.setLoader(true);
                this.props.playerSuggetionList({
                    page: 1,
                    limit: 50,
                    search: e.target.value
                });
            })
        } else {
            this.setState((prevState) => ({
                ...prevState,
                value: e.target.value,
                search: {
                    page: 1,
                    limit: 10,
                    search: ""
                },
                suggestions: []
            }), () => {
                this.state.collegeList = [];
                this.props.otherDetails.collegeInfoData = null;
                this.setLoader(true);
                this.props.getCollegeInfo(this.state.search);
            })
        }

    }

    selectedVal = (value) => {
        console.log("value:--", value);
        this.setState((prevState) => ({
            ...prevState,
            value: value,
            search: {
                page: 1,
                limit: 10,
                search: value
            },
        }), () => {
            this.setLoader(true);
            this.props.getCollegeInfo(this.state.search);
        })
    }
    onlyNumber = event => {
        const keycode = event.which;
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };
    render() {
        const { search, loader, listCollage, height } = this.state
        const inputProps = {
            placeholder: 'Search for your college'
        };
        return (
            <>
                <section className="before-dashboard">
                    <div className="white-bg">
                        <form>
                            <div className="edit-50">
                                <div className="w-50">
                                    <label className="common-lable">Height</label>
                                    <div className="form-group mb-0">
                                        <InputMask {...this.props} className="form-control" name="height" placeholder="Height"  inputmode="numeric" onChange={this.handleChange} value={this.state.height} onKeyPress={this.onlyNumber} autoComplete="off"/>
                                        {/* <input type="text" className="form-control" id="usr" name="height" placeholder="Height" onChange={this.handleChange} value={this.state.height} autoComplete="off" /> */}
                                    </div>
                                    <label className="common-lable">Weight</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" name="weight" placeholder="Weight" onChange={this.handleChange} value={this.state.weight} />
                                    </div>
                                    <label className="common-lable">Are you in high school?</label>
                                    <div className="check_box">
                                        <div className="checkbox" >
                                            <input type="radio" id="Baseball" name="inHighSchool" value="yes" onChange={this.handleChange} checked={this.state.inHighSchool === 'yes'} />
                                            <label htmlFor="Baseball">Yes </label>
                                        </div>
                                        <div className="checkbox mr-0">
                                            <input type="radio" id="Softball" value="no" onChange={this.handleChange} name="inHighSchool" checked={this.state.inHighSchool === 'no'} />
                                            <label htmlFor="Softball">No </label>
                                        </div>
                                    </div>
                                    <label className="common-lable">Name of school</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" name="school" placeholder="Name of school" onChange={this.handleChange} value={this.state.school} />
                                    </div>
                                    {
                                        this.calculate_age(this.state.dob) &&
                                        <>
                                            <label className="common-lable">What year do you graduate high school?</label>
                                            <select name="graYear" value={this.state.graYear} onChange={this.handleChange} className="custom-select" >
                                                <option value="2020" selected disabled>Select year</option>
                                                {
                                                    this.years.map((year, index) => {
                                                        return <option key={`year${index}`} selected={this.state.graYear == year && "selected"} value={year}>{year}</option>
                                                    })
                                                }
                                            </select>
                                        </>
                                    }
                                    {
                                        this.calculate_age(this.state.dob) &&
                                        <>
                                            <label className="common-lable">What is your current GPA?</label>
                                            <div className="form-group mb-0">
                                                <select name="city" className="custom-select" name="curr-gpa" onChange={this.handleChange}>
                                                    <option selected="selected" id="0" disabled>Current GPA</option>
                                                    <option id="2.5-2.9" value="2.5-2.9" selected={this.state.currGpa === "2.5-2.9" && "selected"}>{"2.5-2.9"}</option>
                                                    <option id="3.0-3.4" value="3.0-3.4" selected={this.state.currGpa === "3.0-3.4" && "selected"}>{"3.0-3.4"}</option>
                                                    <option id="3.5-3.9" value="3.5-3.9" selected={this.state.currGpa === "3.5-3.9" && "selected"}>{"3.5-3.9"}</option>
                                                    <option id="4.0-4.4" value="4.0-4.4" selected={this.state.currGpa === "4.0-4.4" && "selected"}>{"4.0-4.4"}</option>
                                                    <option id="4.5-5.0" value="4.5-5.0" selected={this.state.currGpa === "4.5-5.0" && "selected"}>{"4.5-5.0"}</option>
                                                </select>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="w-50">
                                    <div className="player_list_collage">
                                        <div className="form-group mb-0">
                                            <div className="serch-box">

                                                <ReactAutocomplete
                                                    inputProps={inputProps}
                                                    items={this.state.suggestions}
                                                    shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                    getItemValue={item => item.name}
                                                    renderItem={(item, highlighted) =>
                                                        <div
                                                            className="inner-option"
                                                            style={{ backgroundColor: highlighted ? '#eee' : 'transparent', width: '100%' }}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    }
                                                    value={this.state.value}
                                                    onChange={e => this.setValue(e)}
                                                    onSelect={value => this.selectedVal(value)}
                                                    menuStyle={{
                                                        borderRadius: '3px',
                                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                        background: 'rgba(255, 255, 255, 1)',
                                                        padding: '15px',
                                                        fontSize: '90%',
                                                        position: 'absolute',
                                                        overflow: 'auto',
                                                        top: '100%',
                                                        left: 0,
                                                        right: 0,
                                                        maxHeight: '250px',
                                                        zIndex: 9,
                                                        cursor: 'pointer'
                                                    }}
                                                />

                                                {/* <input type="text" className="form-control" id="usr" name="search" placeholder="Search for your collage" value={search.search} onChange={this.handleSearch} /> */}
                                                {/* <Link onClick={this.searchCollege} type="button" className="serch-btn"><img src={imagesArr.search} alt="search" /></Link> */}
                                            </div>
                                        </div>
                                        {listCollage.length !== 0 ?
                                            <>
                                                <div className="otherbrand-logo">
                                                    <label className="common-lable w-100">Your Favorite College</label>
                                                    {
                                                        listCollage.map((list, i) => {
                                                            return (
                                                                <div className="brand-logo-inner" key={i}>
                                                                    <div className="brand-logo-main">
                                                                        <img src={list.image && list.image.filePath ? list.image.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="collage_name1" />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                                <label className="common-lable w-100">Your Favorite Collage</label>
                                                <p>Select college from below.</p>
                                            </>
                                        }

                                        {
                                            this.state.collegeList.length === 0 &&
                                            <h4 style={{ textAlign: 'center' }}>No Data Found</h4>
                                        }
                                        <div className="scrollbar_team" onScroll={this.getScroll}>
                                            <div className="select_team">
                                                {this.renderCollageList()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-align">
                                <Link className="btn gray" to={"/player-dashboard"}>Cancel</Link>
                                <Link className="btn light-green" onClick={this.postData}>Save</Link>
                            </div>
                        </form>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}


OtherInfo.propTypes = {
    getOtherInfo: PropTypes.func.isRequired,
    getCollegeInfo: PropTypes.func.isRequired,
    passInfo: PropTypes.func.isRequired,
    playerSuggetionList: PropTypes.func.isRequired,
    getPlayerDobProfile: PropTypes.func.isRequired,
}


const mapStateToProps = (state) => ({
    otherDetails: state.otherInfo,
    collageDetails: state.collagePlayerDetails,
    profileDetails: state.headerData,
})


export default connect(mapStateToProps, { getOtherInfo, getCollegeInfo, passInfo, playerSuggetionList, getPlayerDobProfile })(OtherInfo);

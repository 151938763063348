import {
    LIST_PLAYER_REQUEST,
    LIST_PLAYER_SUCCESS,
    LIST_PLAYER_ERROR,

    LINKED_PLAYER_LIST_REQUEST,
    LINKED_PLAYER_LIST_SUCCESS,
    LINKED_PLAYER_LIST_ERROR,

    ADD_PLAYER_REQUEST,
    ADD_PLAYER_SUCCESS,
    ADD_PLAYER_ERROR,

    ADD_LINKED_PLAYER_PARENT_REQUEST,
    ADD_LINKED_PLAYER_PARENT_SUCCESS,
    ADD_LINKED_PLAYER_PARENT_ERROR,

    COMPLETE_PARENT_REQUEST,
    COMPLETE_PARENT_SUCCESS,
    COMPLETE_PARENT_ERROR
} from '../../../../constants/authantication/signup/fan/parent.const';

const initialState = {
    playerListRes: false,
    playerList: null,
    playerListError: null,

    linkedPlayerRes: false,
    linkedPlayerData: null,
    linkedPlayerError: null,

    addPlayerRes: false,
    addPlayerData: null,
    addPlayerError: null,
    
    addPlayerChildRes: false,
    addPlayerChildData: null,
    addPlayerChildError: null,

    addLinkedPlayerRes: false,
    addLinkedPlayerData: null,
    addLinkedPlayerError: null,

    completeParentRes: false,
    completeParentData: null,
    completeParentError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        //player List
        case LIST_PLAYER_REQUEST:
            return {
                ...state,
                playerListRes: true
            };
        case LIST_PLAYER_SUCCESS:
            return {
                ...state,
                playerList: action.payload,
            };
        case LIST_PLAYER_ERROR:
            return {
                ...state,
                playerListError: action.error,
            };

        //Linked player List
        case LINKED_PLAYER_LIST_REQUEST:
            return {
                ...state,
                linkedPlayerRes: true
            };
        case LINKED_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                linkedPlayerData: action.payload,
            };
        case LINKED_PLAYER_LIST_ERROR:
            return {
                ...state,
                linkedPlayerError: action.error,
            };

        //Add player 
        case `ADD_LINKED_PLAYER_REQUEST`:
            return {
                ...state,
                addPlayerRes: true
            };
        case `ADD_LINKED_PLAYER_SUCCESS`:
            return {
                ...state,
                addPlayerData: action.payload,
            };
        case `ADD_LINKED_PLAYER_ERROR`:
            return {
                ...state,
                addPlayerError: action.error,
            };

        //Add player child
        case `ADD_CHILD_PLAYER_REQUEST`:
            return {
                ...state,
                addPlayerChildRes: true
            };
        case `ADD_CHILD_PLAYER_SUCCESS`:
            return {
                ...state,
                addPlayerChildData: action.payload,
            };
        case `ADD_CHILD_PLAYER_ERROR`:
            return {
                ...state,
                addPlayerChildError: action.error,
            };


        //Add Linked player 
        case ADD_LINKED_PLAYER_PARENT_REQUEST:
            return {
                ...state,
                addLinkedPlayerRes: true
            };
        case ADD_LINKED_PLAYER_PARENT_SUCCESS:
            return {
                ...state,
                addLinkedPlayerData: action.payload,
            };
        case ADD_LINKED_PLAYER_PARENT_ERROR:
            return {
                ...state,
                addLinkedPlayerError: action.error,
            };


        // Complete parent flow
        case COMPLETE_PARENT_REQUEST:
            return {
                ...state,
                completeParentRes: true
            };
        case COMPLETE_PARENT_SUCCESS:
            return {
                ...state,
                completeParentData: action.payload,
            };
        case COMPLETE_PARENT_ERROR:
            return {
                ...state,
                completeParentError: action.error,
            };

        default:
            return state;
    }
}
export const GET_VIDEO_DETAILS_REQUEST = 'GET_VIDEO_DETAILS_REQUEST';
export const GET_VIDEO_DETAILS_SUCCESS = 'GET_VIDEO_DETAILS_SUCCESS';
export const GET_VIDEO_DETAILS_ERROR = 'GET_VIDEO_DETAILS_ERROR';

export const ZIPCODE_REQUEST = 'ZIPCODE_REQUEST';
export const ZIPCODE_SUCCESS = 'ZIPCODE_SUCCESS';
export const ZIPCODE_ERROR = 'ZIPCODE_ERROR';

export const FILEUPLOAD_REQUEST = 'FILEUPLOAD_REQUEST';
export const FILEUPLOAD_SUCCESS = 'FILEUPLOAD_SUCCESS';
export const FILEUPLOAD_ERROR = 'FILEUPLOAD_ERROR';

export const COVER_FILEUPLOAD_REQUEST = 'COVER_FILEUPLOAD_REQUEST';
export const COVER_FILEUPLOAD_SUCCESS = 'COVER_FILEUPLOAD_SUCCESS';
export const COVER_FILEUPLOAD_ERROR = 'COVER_FILEUPLOAD_ERROR';

export const SAVE_COVER_PHOTO_REQUEST = 'SAVE_COVER_PHOTO_REQUEST';
export const SAVE_COVER_PHOTO_SUCCESS = 'SAVE_COVER_PHOTO_SUCCESS';
export const SAVE_COVER_PHOTO_ERROR = 'SAVE_COVER_PHOTO_ERROR';

export const GET_COUNTRYCODE_SUCCESS = "GET_COUNTRYCODE_SUCCESS";
export const GET_COUNTRYCODE_REQUEST = "GET_COUNTRYCODE_REQUEST";
export const GET_COUNTRYCODE_ERROR = "GET_COUNTRYCODE_ERROR";

export const INSTA_SUCCESS = "INSTA_SUCCESS";
export const INSTA_REQUEST = "INSTA_REQUEST";
export const INSTA_ERROR = "INSTA_ERROR";

export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";

export const ACCEPT_SUCCESS = "ACCEPT_SUCCESS";
export const ACCEPT_REQUEST = "ACCEPT_REQUEST";
export const ACCEPT_ERROR = "ACCEPT_ERROR";

export const NOTIFY_DECLINE_SUCCESS = "NOTIFY_DECLINE_SUCCESS";
export const NOTIFY_DECLINE_REQUEST = "NOTIFY_DECLINE_REQUEST";
export const NOTIFY_DECLINE_ERROR = "NOTIFY_DECLINE_ERROR";

export const ACCEPT_REQ_PLAYER_SUCCESS = "ACCEPT_REQ_PLAYER_SUCCESS";
export const ACCEPT_REQ_PLAYER_REQUEST = "ACCEPT_REQ_PLAYER_REQUEST";
export const ACCEPT_REQ_PLAYER_ERROR = "ACCEPT_REQ_PLAYER_ERROR";

export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_ERROR = "CONTACT_ERROR";

export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

export const NOTIFY_COUNT_SUCCESS = "NOTIFY_COUNT_SUCCESS";
export const NOTIFY_COUNT_REQUEST = "NOTIFY_COUNT_REQUEST";
export const NOTIFY_COUNT_ERROR = "NOTIFY_COUNT_ERROR";

export const NOTIFY_ACCEPT_SUCCESS = "NOTIFY_ACCEPT_SUCCESS";
export const NOTIFY_ACCEPT_REQUEST = "NOTIFY_ACCEPT_REQUEST";
export const NOTIFY_ACCEPT_ERROR = "NOTIFY_ACCEPT_ERROR";

export const NOTIFY_ACCEPT_BY_HEADCOACH_SUCCESS = "NOTIFY_ACCEPT_BY_HEADCOACH_SUCCESS";
export const NOTIFY_ACCEPT_BY_HEADCOACH_REQUEST = "NOTIFY_ACCEPT_BY_HEADCOACH_REQUEST";
export const NOTIFY_ACCEPT_BY_HEADCOACH_ERROR = "NOTIFY_ACCEPT_BY_HEADCOACH_ERROR";

export const NOTIFY_ACCEPT_LINKED_PLAYER_SUCCESS = "NOTIFY_ACCEPT_LINKED_PLAYER_SUCCESS";
export const NOTIFY_ACCEPT_LINKED_PLAYER_REQUEST = "NOTIFY_ACCEPT_LINKED_PLAYER_REQUEST";
export const NOTIFY_ACCEPT_LINKED_PLAYER_ERROR = "NOTIFY_ACCEPT_LINKED_PLAYER_ERROR";

export const NOTIFY_ACCEPT_FAN_SUCCESS = "NOTIFY_ACCEPT_FAN_SUCCESS";
export const NOTIFY_ACCEPT_FAN_REQUEST = "NOTIFY_ACCEPT_FAN_REQUEST";
export const NOTIFY_ACCEPT_FAN_ERROR = "NOTIFY_ACCEPT_FAN_ERROR";

export const READ_NOTIFY_SUCCESS = "READ_NOTIFY_SUCCESS";
export const READ_NOTIFY_REQUEST = "READ_NOTIFY_REQUEST";
export const READ_NOTIFY_ERROR = "READ_NOTIFY_ERROR";

export const DELETE_NOTIFY_SUCCESS = "DELETE_NOTIFY_SUCCESS";
export const DELETE_NOTIFY_REQUEST = "DELETE_NOTIFY_REQUEST";
export const DELETE_NOTIFY_ERROR = "DELETE_NOTIFY_ERROR";

export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";
export const GET_ADS_REQUEST = "GET_ADS_REQUEST";
export const GET_ADS_ERROR = "GET_ADS_ERROR";

export const COM_EVENT_DASH_SUCCESS = "COM_EVENT_DASH_SUCCESS";
export const COM_EVENT_DASH_REQUEST = "COM_EVENT_DASH_REQUEST";
export const COM_EVENT_DASH_ERROR = "COM_EVENT_DASH_ERROR";

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';

export const GET_PW_COMPANY_REQUEST = 'GET_PW_COMPANY_REQUEST';
export const GET_PW_COMPANY_SUCCESS = 'GET_PW_COMPANY_SUCCESS';
export const GET_PW_COMPANY_ERROR = 'GET_PW_COMPANY_ERROR';

export const FETCH_SPOTLIGHT_REQUEST = 'FETCH_SPOTLIGHT_REQUEST';
export const FETCH_SPOTLIGHT_SUCCESS = 'FETCH_SPOTLIGHT_SUCCESS';
export const FETCH_SPOTLIGHT_ERROR = 'FETCH_SPOTLIGHT_ERROR';

export const FETCH_SPONSORS_REQUEST = 'FETCH_SPONSORS_REQUEST';
export const FETCH_SPONSORS_SUCCESS = 'FETCH_SPONSORS_SUCCESS';
export const FETCH_SPONSORS_ERROR = 'FETCH_SPONSORS_ERROR';

export const GET_MYTEAM_LIST_SUCCESS = "GET_MYTEAM_LIST_SUCCESS";
export const GET_MYTEAM_LIST_REQUEST = "GET_MYTEAM_LIST_REQUEST";
export const GET_MYTEAM_LIST_ERROR = "GET_MYTEAM_LIST_ERROR";

export const CHANGE_MYTEAM_LIST_SUCCESS = "CHANGE_MYTEAM_LIST_SUCCESS";
export const CHANGE_MYTEAM_LIST_REQUEST = "CHANGE_MYTEAM_LIST_REQUEST";
export const CHANGE_MYTEAM_LIST_ERROR = "CHANGE_MYTEAM_LIST_ERROR";

export const GET_PERKS_INFO_SUCCESS = "GET_PERKS_INFO_SUCCESS";
export const GET_PERKS_INFO_REQUEST = "GET_PERKS_INFO_REQUEST";
export const GET_PERKS_INFO_ERROR = "GET_PERKS_INFO_ERROR";

export const GET_PW_PACK_SUCCESS = "GET_PW_PACK_SUCCESS";
export const GET_PW_PACK_REQUEST = "GET_PW_PACK_REQUEST";
export const GET_PW_PACK_ERROR = "GET_PW_PACK_ERROR";

export const LEAVE_EVENT_SUCCESS = "LEAVE_EVENT_SUCCESS";
export const LEAVE_EVENT_REQUEST = "LEAVE_EVENT_REQUEST";
export const LEAVE_EVENT_ERROR = "LEAVE_EVENT_ERROR";

export const GET_PLAYER_RANKING_STATES_DETAILS_SUCCESS = "GET_PLAYER_RANKING_STATES_DETAILS_SUCCESS";

export const GET_TEAM_RANKING_STATES_DETAILS_SUCCESS = "GET_TEAM_RANKING_STATES_DETAILS_SUCCESS";

export const PENDING_PAYMENT_DASH_SUCCESS = "PENDING_PAYMENT_DASH_SUCCESS";
export const PENDING_PAYMENT_DASH_REQUEST = "PENDING_PAYMENT_DASH_REQUEST";
export const PENDING_PAYMENT_DASH_ERROR = "PENDING_PAYMENT_DASH_ERROR";

export const PENDING_COUPEN_CODE_SUCCESS = "PENDING_COUPEN_CODE_SUCCESS";
export const PENDING_COUPEN_CODE_REQUEST = "PENDING_COUPEN_CODE_REQUEST";
export const PENDING_COUPEN_CODE_ERROR = "PENDING_COUPEN_CODE_ERROR";

export const APPLE_TEST_SUCCESS = "APPLE_TEST_SUCCESS";
export const APPLE_TEST_REQUEST = "APPLE_TEST_REQUEST";
export const APPLE_TEST_ERROR = "APPLE_TEST_ERROR";
import {
    ADD_PARENT_ERROR,
    ADD_PARENT_SUCCESS,
    LIST_PARENT_ERROR,
    LIST_PARENT_SUCCESS,
    EDIT_LINKED_PARENT_SUCCESS,
    EDIT_LINKED_PARENT_ERROR,
    DISCONNECT_PARENT_ERROR,
    DISCONNECT_PARENT_SUCCESS,
    PHONE_VERIFY_PARENT_SUCCESS,
    PHONE_VERIFY_PARENT_ERROR
} from '../../../../constants/view/player/profile/parentInfo.const';

const initialState = {
    saveParent: null,
    saveParentError: null,

    editParent: null,
    editParentError: null,

    playerList: [],
    playerListError: null,

    disconnectParent: null,
    disconnectParentError: null,

    verifyPhoneForParent: null,
    verifyPhoneForParentError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // save Player
        case ADD_PARENT_SUCCESS:
            return {
                ...state,
                saveParent: action.payload,
            };
        case ADD_PARENT_ERROR:
            return {
                ...state,
                saveParentError: action.error,
            };

        // list of Linked Player
        case LIST_PARENT_SUCCESS:
            return {
                ...state,
                playerList: action.payload,
            };
        case LIST_PARENT_ERROR:
            return {
                ...state,
                playerListError: action.error,
            };

        // edit Player
        case EDIT_LINKED_PARENT_SUCCESS:
            return {
                ...state,
                editParent: action.payload,
            };
        case EDIT_LINKED_PARENT_ERROR:
            return {
                ...state,
                editParentError: action.error,
            };
            
        // disconnect Player
        case DISCONNECT_PARENT_SUCCESS:
            return {
                ...state,
                disconnectParent: action.payload,
            };
        case DISCONNECT_PARENT_ERROR:
            return {
                ...state,
                disconnectParentError: action.error,
            };


        // verify phone number of Player
        case PHONE_VERIFY_PARENT_SUCCESS:
            return {
                ...state,
                verifyPhoneForParent: action.payload,
            };
        case PHONE_VERIFY_PARENT_ERROR:
            return {
                ...state,
                verifyPhoneForParentError: action.error,
            };
        default:
            return state;
    }
}
import {
    GET_MAIN_BOARD_LIST_REQUEST,
    GET_MAIN_BOARD_LIST_SUCCESS,
    GET_MAIN_BOARD_LIST_ERROR,

    GET_FIELDS_REQUEST,
    GET_FIELDS_SUCCESS,
    GET_FIELDS_ERROR,
} from '../../../../../constants/view/company/pwRanking/leaderboard/mainBoard.const';

const initialState = {
    sendReq: {
        metricType: 2,
        metricSubType: "Pitching",
        field: "",
        companyId: localStorage.getItem("cid"),
        gradeYear: "2022"
    },
    getBackFields: [],
    getMainBoardReq: false,
    getMainBoardData: null,
    getMainBoardError: null,

    sendFieldReq: {
        metricType: 2,
        metricSubType: "Pitching",
        companyId: localStorage.getItem("cid")
    },
    getFieldsReq: false,
    getFieldsData: null,
    getFieldsError: null,

    resetPage: false
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Player Main Board req    
        case GET_MAIN_BOARD_LIST_REQUEST:
            return {
                ...state,
                getMainBoardReq: true,
            };

        case GET_MAIN_BOARD_LIST_SUCCESS:
            return {
                ...state,
                getMainBoardData: action.payload,
            };
        case GET_MAIN_BOARD_LIST_ERROR:
            return {
                ...state,
                getMainBoardError: action.error,
            };

        // Get Fields req    
        case GET_FIELDS_REQUEST:
            return {
                ...state,
                getFieldsReq: true,
            };

        case GET_FIELDS_SUCCESS:
            return {
                ...state,
                getFieldsData: action.payload,
            };
        case GET_FIELDS_ERROR:
            return {
                ...state,
                getFieldsError: action.error,
            };

        case 'REFRESH_PAGE':
            return {
                ...state,
                resetPage: !state.resetPage
            }

        default:
            return state;
    }
}
import {
    CODE_REQUEST,
    CODE_SUCCESS,
    CODE_ERROR,

    REGI_STEP2_REQUEST,
    REGI_STEP2_SUCCESS,
    REGI_STEP2_ERROR,
} from '../../../constants/authantication/signup/step2.const';

const initialState = {
    settingData: null,
    countryCodeError: null,

    regiStep2Req: false,
    regiStep2Data: null,
    regiStep2Error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Country Code

        // case CODE_REQUEST:
        //     return {
        //         ...state,
        //         userProfileLoader: true
        //     };
        case CODE_SUCCESS:
            return {
                ...state,
                settingData: action.payload,
            };
        case CODE_ERROR:
            return {
                ...state,
                countryCodeError: action.error,
            };


        case REGI_STEP2_REQUEST:
            return {
                ...state,
                regiStep2Req: true
            };
        case REGI_STEP2_SUCCESS:
            return {
                ...state,
                regiStep2Data: action.payload,
            };
        case REGI_STEP2_ERROR:
            return {
                ...state,
                regiStep2Error: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import ProudSponsors from "../../components/common/proudSponsors";
import Shop from "../../components/common/shop";
import commonCalls from "../../../../businesslogic/commonCalls";
import { commonHeader, advacedSearchColumn } from "../../../../businesslogic/content";
import Preloader from "../../../../components/preloader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import PwLock from "../../components/common/pwLock";
import TopRecord from "../../../popup/topRecord";
import { getEventRecapInfo, getEventScore, getEventDivision, getScoreDescription, getEventAgeGroup, getRecapFilterValue, getRecapShowcaseData, findDivisionArr, getEventAgeGroupAfterPublish, getAllRecapDescription, getEventStatsLeaderHitting, getEventStatsLeaderPitching } from '../../../../saga/actions/views/company/pwb_pws/recapInfo.action';
import { eventRecapTabInfo } from './tournamentEventInfo/eventTab';
import { getEventDetails } from '../../../../saga/actions/common/eventDetails.action';
import RedirectPwProfilePopup from './tournamentEventInfo/RedirectPwProfilePopup';
import Awards from './tournamentEventInfo/Awards';
import Recap from './tournamentEventInfo/Recap';
import ClaimAccount from '../../../popup/claimAccount';

var sortArr = [];
class pwbEventInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            loadHeader: false,
            recapInfoData: [],
            recap_desc: [],
            topRec: false,
            topRecData: [],
            ageGroupArr: [],
            ageGroup: "",
            divisionArr: [],
            division: "",
            divisionName: "",

            eventDivisionArray: [],
            eventDivisionResponse: null,

            poolArr: [],
            poolId: "",
            activeFilters: [],
            filterObject: [],
            position: [],
            gradeYear: [],
            sendReq: {
                page: 0,
                limit: 0,
                type: 1,
                sportType: 0,
                search: "",
                state: "",
                gradeYear: "",
                position: "",
                sort: {},
                print: false,
                export: false,
                event: ""
            },

            offset: 0,
            perPage: 10,
            currentPage: "",
            pageCount: "",

            showcaseRecapList: [],
            showcaseCols: [],

            activeTab: "Schedule",

            bracketTab: "Gold",
            statsLeadersHittingTab: "AVG",
            statsLeadersPitchingTab: "ERA",
            gameType: "All",

            statsLeadersHittingName: "AVG",
            statsLeadersPitchingName: "ERA",

            allRecapDescription: {},
            hittingDescription: {},
            pitchingDescription: {},

            eventObj: "",

            disabledTab: true,

            redirectPwProfilePopup: false,
            playerInfo: null,
            recapFilter: {
                eventId: "",
                ageGroup: "",
                eventDivisionId: '',
                sdiv_id: ""
            },
            loaderCount: 0,

            claimPopup: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.increment();
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        this.getEventDetails(eventId);
        this.setState((prevState) => ({
            ...prevState,
            sendScoreReq: {
                ...prevState.sendScoreReq,
                eventId: eventId
            },
            sendReq: {
                ...prevState.sendReq,
                event: eventId
            },
            recapFilter: {
                ...prevState.recapFilter,
                eventId: eventId,
            }
            // eventObj: localStorage.getItem("recapInfo") ? JSON.parse(localStorage.getItem("recapInfo")) : []
        }), () => {
            // if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== this.state.eventObj.companies._id) {
            //     let companyname = this.state.eventObj.companies.name === "GOBTournament" ? "Greater Orlando Baseball" : this.state.eventObj.companies.name
            //     let item = commonHeader.events.filter((e) => e.label === companyname)[0];
            //     localStorage.setItem("cid", this.state.eventObj.companies._id)
            //     localStorage.setItem("company", item.key)
            //     localStorage.setItem("companyColor", JSON.stringify(item.colors));
            //     this.setState({
            //         loadHeader: true
            //     })
            // } else {
            //     this.setState({
            //         loadHeader: true
            //     })
            // }
            // if (this.state.eventObj.eventTeamType === 1) {
            //     this.getDetails();
            // } else {
            //     this.props.getRecapShowcaseData(this.state.sendReq);
            // }
        });
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            if (nextProps.eventDetails.getDetailsData.flag) {
                let fdata = nextProps.eventDetails.getDetailsData.result.data[0];
                this.setState({
                    eventObj: fdata
                }, () => {
                    if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== this.state.eventObj.companies._id) {
                        let companyname = this.state.eventObj.companies.name === "GOBTournament" ? "Greater Orlando Baseball" : this.state.eventObj.companies.name;
                        let item = commonHeader.events.filter((e) => e.label === companyname)[0];
                        localStorage.setItem("cid", this.state.eventObj.companies._id)
                        localStorage.setItem("company", item.key)
                        this.setThemeColor(item.colors);
                        this.setState({
                            loadHeader: true
                        })
                    } else {
                        this.setState({
                            loadHeader: true
                        })
                    }
                    nextProps.eventDetails.getDetailsData = null;
                    if (this.state.eventObj.eventTeamType === 1) {
                        this.getDetails();
                    } else {
                        this.props.getRecapShowcaseData(this.state.sendReq);
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.recapAgeGroupReq &&
            nextProps.eventRecapInfo.recapAgeGroupData
        ) {
            nextProps.eventRecapInfo.recapAgeGroupReq = false;
            if (nextProps.eventRecapInfo.recapAgeGroupData.flag) {

                const recapAgeGroupResponse = nextProps.eventRecapInfo.recapAgeGroupData;

                this.setState({
                    ageGroupArr: recapAgeGroupResponse.result.ageGroup?.sort((a, b) => (a - b)),
                }, () => {


                    this.setState({
                        recapFilter: {
                            ...this.state.recapFilter,
                            ageGroup: this.state.ageGroupArr?.[0]
                        }
                    }, () => {
                        if (this.state.recapFilter.ageGroup) {
                            const response = recapAgeGroupResponse.result.eventDivisionData;
                            if (response.length !== 0) {
                                const age = response.find(({ _id }) => _id === this.state.recapFilter.ageGroup);
                                const eventDivisionArray = age.eventDivisionId;
                                this.setState({ eventDivisionArray, eventDivisionResponse: response, recapFilter: { ...this.state.recapFilter, eventDivisionId: eventDivisionArray[0]._id } }, () => {
                                    nextProps.eventRecapInfo.recapAgeGroupData = null;
                                    this.props.findDivisionArr(this.state.recapFilter, "PWB");
                                })
                            } else {
                                nextProps.eventRecapInfo.recapAgeGroupData = null;
                                this.props.findDivisionArr(this.state.recapFilter, "PWB");
                            }
                        } else {
                            this.decrement();
                        }
                        // this.props.getEventRecapInfo(this.state.sendScoreReq.eventId, 0, 1);
                    })
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.recapAgeGroupData.message);
                nextProps.eventRecapInfo.recapAgeGroupData = null;
                this.decrement();
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.pwbBracketDivReq &&
            nextProps.eventRecapInfo.pwbBracketDivData
        ) {
            nextProps.eventRecapInfo.pwbBracketDivReq = false;
            if (nextProps.eventRecapInfo.pwbBracketDivData.flag) {
                this.setState({
                    divisionArr: nextProps.eventRecapInfo.pwbBracketDivData.result.data,
                    poolArr: nextProps.eventRecapInfo.pwbBracketDivData.result?.pool,
                    // poolArr: nextProps.eventRecapInfo.pwbBracketDivData.result?.poolList,
                    disabledTab: false,
                    activeTab: this.state.activeTab
                }, async () => {
                    nextProps.eventRecapInfo.pwbBracketDivData = null;
                    if (this.state.divisionArr?.length === 0 && this.state.poolArr?.length === 0) {
                        const payload = {
                            eventId: this.state.sendScoreReq.eventId,
                            ageGroup: this.state.recapFilter.ageGroup,
                            eventDivisionId: this.state.recapFilter.eventDivisionId,
                            bracket: this.state.bracketTab,
                            tab: this.state.activeTab,
                            divisionId: this.state.recapFilter.sdiv_id,
                            hittingField: this.state.statsLeadersHittingTab,
                            pitchingField: this.state.statsLeadersPitchingTab,
                            gameType: this.state.gameType,
                            pool: this.state.poolId,
                        }
                        await this.props.getAllRecapDescription(payload);
                        // this.increment();
                        return;
                    } else if (this.state.divisionArr?.length !== 0 && this.state.poolArr?.length === 0) {
                        this.setState({
                            recapFilter: {
                                ...this.state.recapFilter,
                                sdiv_id: this.state.recapFilter.sdiv_id ? this.state.recapFilter.sdiv_id : this.state.divisionArr[0]._id
                            }
                        }, async () => {
                            const payload = {
                                eventId: this.state.sendScoreReq.eventId,
                                ageGroup: this.state.recapFilter.ageGroup,
                                eventDivisionId: this.state.recapFilter.eventDivisionId,
                                bracket: this.state.bracketTab,
                                tab: this.state.activeTab,
                                divisionId: this.state.recapFilter.sdiv_id,
                                hittingField: this.state.statsLeadersHittingTab,
                                pitchingField: this.state.statsLeadersPitchingTab,
                                gameType: this.state.gameType,
                                pool: this.state.poolId,
                            }

                            this.setState({ disabledTab: false })
                            await this.props.getAllRecapDescription(payload);
                            // this.increment();
                            return;
                        })

                    } else if (this.state.divisionArr?.length === 0 && this.state.poolArr?.length !== 0) {
                        this.setState({
                            poolId: this.state.activeTab === 'Standings' ? 'SeeAll' : this.state.poolId ,
                        }, async () => {
                            const payload = {
                                eventId: this.state.sendScoreReq.eventId,
                                ageGroup: this.state.recapFilter.ageGroup,
                                eventDivisionId: this.state.recapFilter.eventDivisionId,
                                bracket: this.state.bracketTab,
                                tab: this.state.activeTab,
                                divisionId: this.state.recapFilter.sdiv_id,
                                hittingField: this.state.statsLeadersHittingTab,
                                pitchingField: this.state.statsLeadersPitchingTab,
                                gameType: this.state.gameType,
                                pool: this.state.poolId 
                            }

                            this.setState({ disabledTab: false })
                            await this.props.getAllRecapDescription(payload);
                            // this.increment();
                            return;
                        })

                    } else if (this.state.divisionArr?.length !== 0 && this.state.poolArr?.length !== 0) {
                        this.setState({
                            recapFilter: {
                                ...this.state.recapFilter,
                                sdiv_id: this.state.recapFilter.sdiv_id ? this.state.recapFilter.sdiv_id : this.state.divisionArr[0]._id,
                            },
                            poolId: this.state.activeTab === 'Standings' ? 'SeeAll' : this.state.poolId ,
                            disabledTab: false
                        }, async () => {
                            const payload = {
                                eventId: this.state.sendScoreReq.eventId,
                                ageGroup: this.state.recapFilter.ageGroup,
                                eventDivisionId: this.state.recapFilter.eventDivisionId,
                                bracket: this.state.bracketTab,
                                tab: this.state.activeTab,
                                divisionId: this.state.recapFilter.sdiv_id,
                                hittingField: this.state.statsLeadersHittingTab,
                                pitchingField: this.state.statsLeadersPitchingTab,
                                gameType: this.state.gameType,
                                pool:  this.state.poolId ,
                            }
                            await this.props.getAllRecapDescription(payload);
                            // this.increment();
                            return;
                        })
                        // if (this.state.recapFilter.sdiv_id && this.state.poolId) {
                        //     this.setState({ disabledTab: false })
                        //     this.decrement();
                        // }else{
                        //     this.setState({ disabledTab: true })
                        //     this.decrement();
                        // }
                    }
                    //  else {
                    //     nextProps.eventRecapInfo.pwbBracketDivData = null;
                    //     this.setState({ disabledTab: true })
                    //     this.decrement();
                    // }
                    // this.setLoader(false);
                    // this.props.getEventRecapInfo(this.state.sendScoreReq.eventId, 0, 1);
                })
            } else {
                this.setState({ disabledTab: true, allRecapDescription: {} });
                this.commonCall.errorMessage(nextProps.eventRecapInfo.pwbBracketDivData.message);
                nextProps.eventRecapInfo.pwbBracketDivData = null;
                // this.setLoader(false);
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.allRecapDescriptionReq &&
            nextProps.eventRecapInfo.allRecapDescriptionData
        ) {
            if (localStorage.getItem('company') === "pwb") {
                nextProps.eventRecapInfo.allRecapDescriptionReq = false;
                if (nextProps.eventRecapInfo.allRecapDescriptionData.flag) {
                    this.setState({
                        allRecapDescription: nextProps.eventRecapInfo.allRecapDescriptionData.result
                    }, () => {
                        nextProps.eventRecapInfo.allRecapDescriptionData = null;
                        // this.props.getEventRecapInfo(this.state.sendScoreReq.eventId, 0, 1);
                    })
                } else {
                    this.commonCall.errorMessage(nextProps.eventRecapInfo.allRecapDescriptionData.message);
                    nextProps.eventRecapInfo.allRecapDescriptionData = null;
                }
                this.decrement();
            }

        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.hittingStatsResponse
        ) {
            if (nextProps.eventRecapInfo.hittingStatsResponse.flag) {
                this.setState({
                    hittingDescription: nextProps.eventRecapInfo.hittingStatsResponse.result
                }, () => {
                    nextProps.eventRecapInfo.hittingStatsResponse = null;
                    this.decrement()
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.hittingStatsResponse.message);
                nextProps.eventRecapInfo.hittingStatsResponse = null;
                this.decrement()
                this.setLoader(false);
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.pitchingStatsResponse
        ) {
            if (nextProps.eventRecapInfo.pitchingStatsResponse.flag) {
                this.setState({
                    pitchingDescription: nextProps.eventRecapInfo.pitchingStatsResponse.result
                }, () => {
                    this.decrement()
                    nextProps.eventRecapInfo.pitchingStatsResponse = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.pitchingStatsResponse.message);
                nextProps.eventRecapInfo.pitchingStatsResponse = null;
                this.decrement()
                this.setLoader(false);
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.recapInformationReq &&
            nextProps.eventRecapInfo.recapInformationData
        ) {
            nextProps.eventRecapInfo.recapInformationReq = false;
            if (nextProps.eventRecapInfo.recapInformationData.flag) {
                this.setState({
                    recapInfoData: nextProps.eventRecapInfo.recapInformationData.result
                }, () => {
                    nextProps.eventRecapInfo.recapInformationData = null;
                    if (this.state.eventObj.eventTeamType === 1 || this.state.filterObject.length !== 0) {
                        this.decrement();
                    } else {
                        this.props.getRecapFilterValue();
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.recapInformationData.message);
                nextProps.eventRecapInfo.recapInformationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.recShowcaseReq &&
            nextProps.eventRecapInfo.recShowcaseData
        ) {
            nextProps.eventRecapInfo.recShowcaseReq = false;
            if (nextProps.eventRecapInfo.recShowcaseData.flag) {
                this.setState({
                    showcaseRecapList: nextProps.eventRecapInfo.recShowcaseData.result.docs,
                    showcaseCols: nextProps.eventRecapInfo.recShowcaseData.result.keys,
                    pageCount: Math.ceil(nextProps.eventRecapInfo.recShowcaseData.result.total / this.state.perPage),
                }, () => {
                    nextProps.eventRecapInfo.recShowcaseData = null;
                    if (this.state.recapInfoData.length !== 0) {
                        this.decrement();
                    } else {
                        this.props.getEventRecapInfo(this.state.sendScoreReq.eventId, 0, 2);
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.recShowcaseData.message);
                nextProps.eventRecapInfo.recShowcaseData = null;
                this.decrement();
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.recapFilterValReq &&
            nextProps.eventRecapInfo.recapFilterValData
        ) {
            nextProps.eventRecapInfo.recapFilterValReq = false;
            if (nextProps.eventRecapInfo.recapFilterValData.flag) {
                this.setState({
                    filterObject: nextProps.eventRecapInfo.recapFilterValData.result
                }, () => {
                    nextProps.eventRecapInfo.recapFilterValData = null;
                    this.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.recapFilterValData.message);
                nextProps.eventRecapInfo.recapFilterValData = null;
                this.decrement();
            }
        }
    }

    setThemeColor = (colArr) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
    }

    getEventDetails = (eventId) => {
        if (localStorage.getItem("webglToken") !== null) {
            this.props.getEventDetails(eventId, true);
        } else {
            this.props.getEventDetails({
                eventId: eventId
            }, false);
        }
    }


    getDetails = () => {
        const payload = {
            eventId: this.state.sendScoreReq.eventId,
            ageGroup: this.state.ageGroup,
            eventDivisionId: this.state.recapFilter.eventDivisionId,
            bracket: this.state.bracketTab,
            tab: this.state.activeTab,
            divisionId: this.state.recapFilter.sdiv_id,
            pitchingField: "",
            hittingField: "",
            gameType: this.state.gameType,
            pool: this.state.poolId,
        }
        this.props.getEventAgeGroupAfterPublish({ eventId: this.state.sendScoreReq.eventId });
        // this.props.getAllRecapDescription(payload);
        // this.increment();
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    increment = (active) => {
        this.setState(prevState => {
            return { loaderCount: active ? active : prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    // increment = () => {
    //     this.setLoader(true);
    // }

    // decrement = () => {
    //     this.setLoader(false);
    // }

    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            topRec: false,
            topRecData: []
        })
    }

    onTypeChange = (name, value) => {
        sortArr = [];
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: value,
                page: 1,
                limit: Number(this.state.perPage),
                sort: {},
                search: "",
            },
            currentPage: 0
        }), () => {
            this.increment();
            this.props.getRecapShowcaseData(this.state.sendReq);
        });
    }

    onFilterChange = (name, val) => {
        let old = this.state[name].some((item) => {
            return item === val
        });
        if (old) {
            this.state[name].map((item, i) => {
                if (item === val) {
                    this.state[name].splice(i, 1)
                }
            })
            this.setState({
                [name]: this.state[name]
            }, () => {
                this.onTypeChange(name, this.state[name].length !== 0 ? this.state[name] : "");
            })
        } else {
            this.setState({
                [name]: [...this.state[name], val]
            }, () => {
                this.onTypeChange(name, this.state[name]);
            })
        }
    }


    onHandleChange = (e) => {

        const { name, value } = e.target;

        // const nextGameType = this.state.activeTab === 'Standings' ? 'Matchup' : 'All';

        this.setState(() => {
            if (name === "ageGroup") {

                const age = this.state.eventDivisionResponse.find(({ _id }) => _id === value);
                const eventDivisionArray = age.eventDivisionId;

                return {
                    eventDivisionArray,
                    recapFilter: {
                        ...this.state.recapFilter,
                        [name]: value,
                        sdiv_id: "",
                        eventDivisionId: eventDivisionArray[0]._id
                    },
                    divisionArr: [],
                    division: "",
                    divisionName: "",
                    bracketTab: "Gold",
                    gameType: "All",
                    poolArr: [],
                    poolId: "",
                    statsLeadersHittingTab: "AVG",
                    statsLeadersPitchingTab: "ERA",
                    statsLeadersHittingName: "AVG",
                    statsLeadersPitchingName: "ERA",
                    activeTab: 'Schedule'
                }
            } else if (name === "eventDivisionId") {
                return {
                    recapFilter: {
                        ...this.state.recapFilter,
                        [name]: value,
                        sdiv_id: "",
                    },
                    divisionArr: [],
                    division: "",
                    divisionName: "",
                    bracketTab: "Gold",
                    gameType: "All",
                    poolArr: [],
                    poolId: "",
                    statsLeadersHittingTab: "AVG",
                    statsLeadersPitchingTab: "ERA",
                    statsLeadersHittingName: "AVG",
                    statsLeadersPitchingName: "ERA",
                    activeTab: 'Schedule'
                }
            } else if (name === "sdiv_id") {
                return {
                    recapFilter: {
                        ...this.state.recapFilter,
                        [name]: value,
                    },
                    divisionName: this.state.divisionArr ? this.state.divisionArr?.find(i => i?._id === value)?.title : "",
                    gameType: "All",
                    bracketTab: "Gold",
                    poolId: "",
                    activeTab: this.state.activeTab,
                    statsLeadersHittingTab: "AVG",
                    statsLeadersPitchingTab: "ERA",
                    statsLeadersHittingName: "AVG",
                    statsLeadersPitchingName: "ERA",
                    activeTab: 'Schedule'
                }
            } else if (name == "gameType") {
                console.log('this.state.gameType',this.state.gameType)
                return {
                    [name]: value,
                    poolId: value === 'Matchup' && this.state.poolArr?.length !== 0 ? 'SeeAll' : '',
                }
            } else if (name === "poolId") {
                return {
                    poolId: value
                }
            } else {
                return {
                    [name]: value,
                }
            }
        }, async () => {
            if ((name === "ageGroup" || name === "eventDivisionId") && value) {
                this.increment();
                await this.props.findDivisionArr(this.state.recapFilter, "PWB");
                return;
            }
            else if (name === "sdiv_id") {
                this.setState({ disabledTab: false });
                const payload = {
                    eventId: this.state.sendScoreReq.eventId,
                    ageGroup: this.state.recapFilter.ageGroup,
                    eventDivisionId: this.state.recapFilter.eventDivisionId,
                    bracket: this.state.bracketTab,
                    tab: this.state.activeTab,
                    divisionId: this.state.recapFilter.sdiv_id,
                    hittingField: this.state.statsLeadersHittingTab,
                    pitchingField: this.state.statsLeadersPitchingTab,
                    gameType: this.state.gameType,
                    pool: this.state.poolId,
                }
                this.increment();
                await this.props.getAllRecapDescription(payload);
                return;

            } else if (name === "poolId") {
                this.setState({ disabledTab: false });
                const payload = {
                    eventId: this.state.sendScoreReq.eventId,
                    ageGroup: this.state.recapFilter.ageGroup,
                    eventDivisionId: this.state.recapFilter.eventDivisionId,
                    bracket: this.state.bracketTab,
                    tab: this.state.activeTab,
                    divisionId: this.state.recapFilter.sdiv_id,
                    pool: this.state.poolId,
                    hittingField: this.state.statsLeadersHittingTab,
                    pitchingField: this.state.statsLeadersPitchingTab,
                    gameType: this.state.gameType,
                }
                await this.props.getAllRecapDescription(payload);
                this.increment();
                return;
            } else if (name === "gameType") {
                this.setState({ disabledTab: false });
                // console.log('this.state.poolId',this.state.gameType,this.state.poolId)
                const payload = {
                    eventId: this.state.sendScoreReq.eventId,
                    ageGroup: this.state.recapFilter.ageGroup,
                    eventDivisionId: this.state.recapFilter.eventDivisionId,
                    bracket: this.state.bracketTab,
                    tab: this.state.activeTab,
                    divisionId: this.state.recapFilter.sdiv_id,
                    pool: this.state.poolId,
                    hittingField: this.state.statsLeadersHittingTab,
                    pitchingField: this.state.statsLeadersPitchingTab,
                    gameType: this.state.gameType,
                }
                this.state.gameType === 'Matchup' && this.state.activeTab !== 'Standings' ? await this.props.findDivisionArr(this.state.recapFilter, "PWB") : await this.props.getAllRecapDescription(payload);
                this.increment();
                return;
            }

            const payload = {
                eventId: this.state.sendScoreReq.eventId,
                ageGroup: this.state.recapFilter.ageGroup,
                eventDivisionId: this.state.recapFilter.eventDivisionId,
                bracket: this.state.bracketTab,
                tab: this.state.activeTab,
                divisionId: this.state.recapFilter.sdiv_id,
                hittingField: this.state.statsLeadersHittingTab,
                pitchingField: this.state.statsLeadersPitchingTab,
                gameType: this.state.gameType,
                pool: this.state.poolId,
            }

            await this.props.getAllRecapDescription(payload);
            this.increment();
        })
    }

    sortData = (key) => {
        let old = sortArr.some((item) => {
            return item === key;
        });

        if (!old) {
            sortArr = [];
            sortArr.push(key);
        } else {
            sortArr.some((item, i) => {
                if (item === key) {
                    sortArr.splice(i, 1);
                }
            })
        }

        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                sort: {
                    [key]: sortArr.some((d) => d === key) ? 1 : -1
                },
            },
        }), () => {
            this.props.getRecapShowcaseData(this.state.sendReq);
        })
    }

    renderPerformanceData = () => {
        return (
            this.state.showcaseRecapList &&
                this.state.showcaseRecapList.length !== 0 ?
                this.state.showcaseRecapList.map((item, i) => {
                    return (
                        <tr>
                            <td>{i + 1}</td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    {/* <img src={item.athlete.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player" /> */}
                                    <div className="athlete-info">
                                        <h3>{item?.athlete?.fname + " " + item?.athlete?.lname}</h3>
                                        {/* <p>
                                            <span>{item.athlete.profile &&
                                                item.athlete.profile.hasOwnProperty('fielding-pos') && item.athlete.profile['fielding-pos'].length !== 0 ?
                                                item.athlete.profile['fielding-pos'].map((pos) => (
                                                    pos
                                                )).reduce((prev, curr) => [prev, ", ", curr])
                                                : ""}
                                            </span>
                                            <span>#652</span>
                                            <span>{item.gradeYear}</span>
                                            <span>175 lbs</span>
                                        </p> */}
                                        {/* <h6><b>Height : </b>{item.athlete.profile.height ? item.athlete.profile.height : "-"}</h6>
                                        <h6><b>Weight : </b>{item.athlete.profile.weight ? item.athlete.profile.weight : "-"}</h6> */}
                                    </div>
                                </div>
                            </td>
                            <td>{item['10YardDash'] ? item['10YardDash'] + "s" : "-"}</td>
                            <td>{item['60YardDash'] ? item['60YardDash'] + "s" : "-"}</td>
                            <td>{item['5-10-5'] ? item['5-10-5'] + "s" : "-"}</td>
                            <td>{item.broadJump ? item.broadJump + "''" : "-"}</td>
                            <td>{item.verticalJump ? item.verticalJump + "''" : "-"}</td>
                            <td>{item.chinUps ? item.chinUps : "-"}</td>
                            <td>{item.pullUps ? item.pullUps : "-"}</td>
                            <td>{item.sitUps ? item.sitUps : "-"}</td>
                            <td>{item.pushUps ? item.pushUps : "-"}</td>
                            <td>{item.plankTest ? item.plankTest : "-"}</td>
                            <td>{item.handGripStrenght ? item.handGripStrenght : "-"}</td>
                            <td>{item.catcherPopTime ? item.catcherPopTime : "-"}</td>
                            <td>{item.homeToFirst ? item.homeToFirst : "-"}</td>
                            <td>{item.homeToSecond ? item.homeToSecond : "-"}</td>
                            <td>{item.homeToHome ? item.homeToHome : "-"}</td>
                            <td>{item.bullpenStrikePercentage ? item.bullpenStrikePercentage + "%" : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.performance.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    renderPitchingData = () => {
        return (
            this.state.showcaseRecapList &&
                this.state.showcaseRecapList.length !== 0 ?
                this.state.showcaseRecapList.map((item, i) => {
                    return (
                        <tr>
                            <td>{i + 1}</td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    {/* <img src={item.athlete.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player" /> */}
                                    <div className="athlete-info">
                                        <h3>{item?.athlete?.fname + " " + item?.athlete?.lname}</h3>
                                        {/* <p>
                                            <span>{item.athlete.profile &&
                                                item.athlete.profile.hasOwnProperty('fielding-pos') && item.athlete.profile['fielding-pos'].length !== 0 ?
                                                item.athlete.profile['fielding-pos'].map((pos) => (
                                                    pos
                                                )).reduce((prev, curr) => [prev, ", ", curr])
                                                : ""}
                                            </span>
                                            <span>#652</span>
                                            <span>{item.gradeYear}</span>
                                            <span>175 lbs</span>
                                        </p>
                                        <h6><b>Height : </b>{item.athlete.profile.height ? item.athlete.profile.height : "-"}</h6>
                                        <h6><b>Weight : </b>{item.athlete.profile.weight ? item.athlete.profile.weight : "-"}</h6> */}
                                    </div>
                                </div>
                            </td>
                            <td>{item.maxVelocity ? item.maxVelocity + "mph" : "-"}</td>
                            <td>{item.avgVelocity ? item.avgVelocity + "mph" : "-"}</td>
                            <td>{item.maxSpinRate ? item.maxSpinRate : "-"}</td>
                            <td>{item.avgSpinRate ? item.avgSpinRate : "-"}</td>
                            <td>{item.spinEfficiency ? item.spinEfficiency : "-"}</td>
                            <td>{item.avgTilt ? item.avgTilt : "-"}</td>
                            <td>{item.ERA ? item.ERA : "-"}</td>
                            <td>{item.K ? item.K : "-"}</td>
                            <td>{item.WHIP ? item.WHIP : "-"}</td>
                            <td>{item.saves ? item.saves : "-"}</td>
                            <td>{item.GP ? item.GP : "-"}</td>
                            <td>{item.BB ? item.BB : "-"}</td>
                            <td>{item.GS ? item.GS : "-"}</td>
                            <td>{item.IP ? item.IP : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.pitching.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    renderHittingData = () => {
        return (
            this.state.showcaseRecapList &&
                this.state.showcaseRecapList.length !== 0 ?
                this.state.showcaseRecapList.map((item, i) => {
                    return (
                        <tr>
                            <td>{i + 1}</td>
                            <td className="team-name">
                                <div className="athlete-user-info">
                                    {/* <img src={item.athlete.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player" /> */}
                                    <div className="athlete-info">
                                        <h3>{item?.athlete?.fname + " " + item?.athlete?.lname}</h3>
                                        {/* <p>
                                            <span>{item.athlete.profile &&
                                                item.athlete.profile.hasOwnProperty('fielding-pos') && item.athlete.profile['fielding-pos'].length !== 0 ?
                                                item.athlete.profile['fielding-pos'].map((pos) => (
                                                    pos
                                                )).reduce((prev, curr) => [prev, ", ", curr])
                                                : ""}
                                            </span>
                                            <span>#652</span>
                                            <span>{item.gradeYear}</span>
                                            <span>175 lbs</span>
                                        </p>
                                        <h6><b>Height : </b>{item.athlete.profile.height ? item.athlete.profile.height : "-"}</h6>
                                        <h6><b>Weight : </b>{item.athlete.profile.weight ? item.athlete.profile.weight : "-"}</h6> */}
                                    </div>
                                </div>
                            </td>
                            <td>{item.maxExitVelocity ? item.maxExitVelocity + "mph" : "-"}</td>
                            <td>{item.avgExitVelocity ? item.avgExitVelocity + "mph" : "-"}</td>
                            <td>{item.avgLaunchAngle ? item.avgLaunchAngle : "-"}</td>
                            <td>{item.maxDistance ? item.maxDistance + "''" : "-"}</td>
                            <td>{item.batSpeed ? item.batSpeed + "''" : "-"}</td>
                            <td>{item.attackAngle ? item.attackAngle : "-"}</td>
                            <td>{item.R ? item.R : "-"}</td>
                            <td>{item.RBI ? item.RBI : "-"}</td>
                            <td>{item.SB ? item.SB : "-"}</td>
                            <td>{item.OBP ? item.OBP : "-"}</td>
                            <td>{item.HR ? item.HR : "-"}</td>
                            <td>{item["1B"] ? item["1B"] : "-"}</td>
                            <td>{item["2B"] ? item["2B"] : "-"}</td>
                            <td>{item.BA ? item.BA : "-"}</td>
                            <td>{item.GP ? item.GP : "-"}</td>
                            <td>{item.AB ? item.AB : "-"}</td>
                            {/* <td>{item.SB ? item.SB : "-"}</td> */}
                            <td>{item.OPS ? item.OPS : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan={advacedSearchColumn.hitting.length + 2}>
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    filterTabs = (value) => {
        let old = this.state.activeFilters.some((item) => {
            return item === value
        });
        if (old) {
            this.state.activeFilters.map((item, i) => {
                if (item === value) {
                    this.state.activeFilters.splice(i, 1)
                }
            })
            this.setState({
                ...this.state.activeFilters,
                activeFilters: this.state.activeFilters
            })
        } else {
            this.setState({
                activeFilters: [...this.state.activeFilters, value]
            })
        }
    }

    renderPosition = () => {
        return (
            this.state.filterObject &&
                this.state.filterObject.positions &&
                this.state.filterObject.positions.length !== 0 ?
                this.state.filterObject.positions.map((item, i) => {
                    return (
                        <div className="checkbox">
                            <label for={"position" + i}>{item}</label>
                            <input type="checkbox" id={"position" + i} name="positions" checked={this.state.position.find(e => e === item)} onChange={() => this.onFilterChange('position', item)} />
                            <span className="square"></span>
                        </div>
                    )
                })
                :
                <p className="text-center"></p>
        )
    }

    renderGradYear = () => {
        return (
            this.state.filterObject &&
                this.state.filterObject.gradeYearObj &&
                this.state.filterObject.gradeYearObj.length !== 0 ?
                this.state.filterObject.gradeYearObj.map((item, i) => {
                    return (
                        <div className="checkbox">
                            <label for={"gradyear" + i}>{item}</label>
                            <input type="checkbox" id={"gradyear" + i} name="gradyears" checked={this.state.gradeYear.find(e => e === item)} onChange={() => this.onFilterChange('gradeYear', item)} />
                            <span className="square"></span>
                        </div>
                    )
                })
                :
                <p className="text-center"></p>
        )
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                page: selectedPage + 1,
                limit: Number(this.state.perPage),
            },
            currentPage: selectedPage,
            offset: offset,
        }), () => {
            this.props.getRecapShowcaseData(this.state.sendReq);
        });
    };

    setHeaderVal = (item) => {
        let findkeys = advacedSearchColumn[this.state.sendReq.type === 1 ? "performance" : this.state.sendReq.type === 2 ? "pitching" : "hitting"].find((_) => _.name === item);
        if (!!findkeys) {
            return findkeys.label;
        } else {
            return item;
        }
    }

    redirectBracket = () => {
        this.props.history.push(`/pwb-bracket/${this.state.eventObj._id}`);
    }

    eventRecapChartTabHandler = async (activeId, eventTabTitle, bracketTitle) => {
        this.setState({ activeTab: eventTabTitle, bracketTab: bracketTitle, allRecapDescription: {} })
        this.increment()
        const payload = {
            eventId: this.state.sendScoreReq.eventId,
            ageGroup: this.state.recapFilter.ageGroup,
            eventDivisionId: this.state.recapFilter.eventDivisionId,
            bracket: bracketTitle,
            tab: eventTabTitle,
            divisionId: this.state.recapFilter.sdiv_id,
            hittingField: this.state.statsLeadersHittingTab,
            pitchingField: this.state.statsLeadersPitchingTab,
            pool: this.state.poolId,
            gameType: this.state.gameType
        }
        await this.props.getAllRecapDescription(payload);
    }

    eventRecapTabHandler = async (activeId, eventTabTitle, bracketTitle) => {

        let nextState = {
            activeTab: eventTabTitle,
            allRecapDescription: {},
            bracketTab: "Gold",
            gameType: "All",
            poolId: "",
            statsLeadersHittingTab: "AVG",
            statsLeadersPitchingTab: "ERA",
            statsLeadersHittingName: "AVG",
            statsLeadersPitchingName: "ERA",
        }

        this.increment();

        switch (activeId) {

            case 'Standings':

                nextState = { ...nextState, gameType: 'Matchup' }

                await this.props.findDivisionArr(this.state.recapFilter, "PWB");

                break;

            default:

                const payload = {
                    eventId: this.state.sendScoreReq.eventId,
                    ageGroup: this.state.recapFilter.ageGroup,
                    eventDivisionId: this.state.recapFilter.eventDivisionId,
                    bracket: "Gold",
                    tab: activeId,
                    divisionId: this.state.recapFilter.sdiv_id,
                    hittingField: "AVG",
                    pitchingField: "ERA",
                    pool: '',
                    gameType: "All",
                }

                await this.props.getAllRecapDescription(payload);

                break;
        }

        this.setState(nextState);

    }

    topRecDatahandler = (topRecObj) => {
        this.setState({ topRec: !this.state.topRec, topRecData: topRecObj })
    }


    statsLeadersHittingTabHandler = async (hittingStat, pitchingStat, hittingStatName, pitchingStatName) => {
        this.setState({ statsLeadersHittingTab: hittingStat, statsLeadersPitchingTab: pitchingStat, statsLeadersHittingName: hittingStatName, statsLeadersPitchingName: pitchingStatName, hittingDescription: {} })

        this.increment();
        const payload = {
            eventId: this.state.sendScoreReq.eventId,
            ageGroup: this.state.recapFilter.ageGroup,
            eventDivisionId: this.state.recapFilter.eventDivisionId,
            divisionId: this.state.recapFilter.sdiv_id,
            hittingField: hittingStat,
            pool: this.state.poolId
        }
        await this.props.getEventStatsLeaderHitting(payload);
    }

    statsLeadersPitchingTabHandler = async (hittingStat, pitchingStat, hittingStatName, pitchingStatName) => {
        this.setState({ statsLeadersHittingTab: hittingStat, statsLeadersPitchingTab: pitchingStat, statsLeadersHittingName: hittingStatName, statsLeadersPitchingName: pitchingStatName, pitchingDescription: {} })
        this.increment()
        const payload = {
            eventId: this.state.sendScoreReq.eventId,
            ageGroup: this.state.recapFilter.ageGroup,
            eventDivisionId: this.state.recapFilter.eventDivisionId,
            divisionId: this.state.recapFilter.sdiv_id,
            pitchingField: pitchingStat,
            pool: this.state.poolId
        }
        await this.props.getEventStatsLeaderPitching(payload);
    }

    redirectPwProfileHandler = () => {
        this.props.history.push(`/pw-profile-info/${this.state.playerInfo.PlayerId}`)
    }

    redirectPwProfilePopupCloseHandler = () => {
        this.setState({ redirectPwProfilePopup: false, playerInfo: null })
    }

    redirectPwProfilePopupOpenHandler = (playerInfo) => {
        this.setState({ redirectPwProfilePopup: true, playerInfo: playerInfo })
    }

    openCliamAccount = () => {
        this.setState({
            claimPopup: true
        })
    }

    closeClaimAccount = () => {
        this.setState({
            claimPopup: false
        })
    }

    render() {
        const { loader,
            eventObj,
            recapInfoData,
            recap_desc,
            loadHeader,
            topRec,
            ageGroupArr,
            ageGroup,
            filterObject,
            sendReq,
            activeFilters,
            showcaseCols,
            showcaseRecapList,
            activeTab,
            bracketTab,
            divisionArr,
            eventDivisionArray,
            division,
            divisionName,
            allRecapDescription,
            statsLeadersHittingTab,
            statsLeadersPitchingTab,
            statsLeadersHittingName,
            statsLeadersPitchingName,
            poolArr,
            poolId,
            gameType,
            disabledTab,
            redirectPwProfilePopup,
            playerInfo,
            hittingDescription,
            pitchingDescription,
            loaderCount
        } = this.state;

        const location = eventObj && eventObj?.location && eventObj?.location?.streetAddress.split(",");

        const isOldEvent = eventObj?.isOldEvent;

        return (
            // loadHeader &&
            <>
                <Header />
                {
                    eventObj?.eventTeamType === 1 ?
                        <>
                            <div className="hotelinfo-section event-main">
                                <div className="team-banner-section">
                                    <img src={eventObj && eventObj.eventImages && eventObj.eventImages.filePath ? eventObj.eventImages.filePath : imagesArr.eventinfo_banner} alt="event banner" />
                                    <div className="banner-details">
                                        <div className="event_card_logo">
                                            <img src={eventObj && eventObj.eventLogo && eventObj.eventLogo.filePath ? eventObj.eventLogo.filePath : imagesArr.event_info_bannerlogo} alt="event logo" />
                                        </div>
                                        <div className="event_card_titles">
                                            <h3>{eventObj && eventObj.eventTitle}</h3>
                                            <div className="event_card_dates">
                                                <p className="map_icon">{eventObj && eventObj.location && eventObj.location.streetAddress}</p>
                                                <p className="date_icon">{eventObj && moment(eventObj.startDate).format("DD MMM YYYY") + " - " + moment(eventObj.endDate).format("DD MMM YYYY")}</p>
                                                <p className="team_icons">{eventObj && eventObj.teamLimit} Teams</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrow">
                                        <img src={imagesArr.round_arrow} />
                                    </div>
                                    <div className="shap">
                                        <img src={imagesArr.shap} />
                                    </div>
                                </div>
                            </div>
                            <div className="recap-info-section">
                                <div className="container ">
                                    <div className="single-title d-flex">
                                        <h1 className="section_title">Recap/Awards</h1>
                                        <button className="btn light-green recap_claim_btn" onClick={() => this.openCliamAccount()}>Claim Your PLAYERS Accounts Create Guardian Accounts</button>
                                        {/* <p className="text-center">Event recap article to go here - entered in CMS with text editor and tagging capability in PW</p> */}
                                        {/* <p className="text-center">Check back on the recap page after the event has finished.</p> */}
                                    </div>
                                    {/* {
                                        recapInfoData?.media?.filePath &&
                                        <div className="playerinfo-section">
                                            <h2>{recapInfoData.title}</h2>
                                            <div className="user-img">
                                                <img src={recapInfoData?.media?.filePath ? recapInfoData.media.filePath : imagesArr.default_user} alt="team logo" />
                                            </div>
                                            <div className="player-detail">
                                                <p className="text" dangerouslySetInnerHTML={{
                                                    __html: recapInfoData.recapText && recapInfoData.recapText
                                                }}></p>
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            </div>
                            <div className="container">
                                <div className="recap-info-tab">
                                    <div className="champions-Score">
                                        {/* <h1 className="section_title">Champions & Score</h1> */}
                                        <div className="select_btn">
                                            <div className="row">
                                                <div className="w-50">
                                                    <div className="row">
                                                        <div className="w-33">
                                                            <select name="ageGroup" value={this.state.recapFilter.ageGroup} className="custom-select" onChange={(e) => this.onHandleChange(e)}>
                                                                <option value="" disabled={true}>Select age</option>
                                                                {ageGroupArr?.length !== 0 && ageGroupArr?.map((age, i) => <option value={age}>{age == 19 ? "18U+" : age + "U"}</option>)}
                                                            </select>
                                                        </div>
                                                        {eventDivisionArray?.length !== 0 ? <div className="w-33">
                                                            <select name="eventDivisionId" value={this.state.recapFilter.eventDivisionId} className="custom-select" onChange={(e) => this.onHandleChange(e)}>
                                                                <option value="" disabled={true}>Select Event Division</option>
                                                                {eventDivisionArray?.map(({ title, _id }) => <option key={_id} value={_id}>{title}</option>)}
                                                            </select>
                                                        </div> : ""}
                                                        {divisionArr?.length !== 0 ? <div className="w-33">
                                                            <select name="sdiv_id" value={this.state.recapFilter.sdiv_id} className="custom-select" onChange={(e) => this.onHandleChange(e)}>
                                                                <option value="" disabled={true}>Select Division</option>
                                                                {divisionArr?.map(({ title, _id }) => <option key={_id} value={_id}>{title}</option>)}
                                                            </select>
                                                        </div> : ""}
                                                        {poolArr?.length !== 0 && (gameType === "Matchup" || (gameType === "Bracket" && division)) ? <div className="w-33">
                                                            <select name="poolId" value={this.state.poolId} className="custom-select" onChange={(e) => this.onHandleChange(e)} >
                                                                <option value="SeeAll">See All</option>
                                                                {poolArr?.map((_) => <option key={_._id} value={_._id}>{_.name}</option>)}
                                                            </select>
                                                        </div> : ""}
                                                    </div>
                                                </div>
                                                <div className="w-50">
                                                    <div className="list-group-scroll">
                                                        <ul className="list-group">
                                                            <li className={`list-group-item ${activeTab === "Schedule" ? "active" : ""} `} onClick={() => this.eventRecapTabHandler("Schedule", "Schedule", this.state.bracketTab)}>{"Schedule"}</li>
                                                            <li className={`list-group-item ${activeTab === "Standings" ? "active" : ""} ${disabledTab ? "disabled" : ""}`} onClick={() => this.eventRecapTabHandler("Standings", "Standings", this.state.bracketTab)}>{"Standings"}</li>
                                                            <li className={`list-group-item ${activeTab === "Bracket/Results" ? "active" : ""} ${disabledTab ? "disabled" : ""}`} onClick={() => this.eventRecapTabHandler("Bracket/Results", "Bracket/Results", this.state.bracketTab)}>{"Bracket/Results"}</li>
                                                            <li className={`list-group-item ${activeTab === "Stats Leaders" ? "active" : ""} ${disabledTab ? "disabled" : ""}`} onClick={() => this.eventRecapTabHandler("Stats Leaders", "Stats Leaders", this.state.bracketTab)}>{"Stats Leaders"}</li>
                                                            <li className={`list-group-item ${activeTab === "Recap" ? "active" : ""} ${disabledTab ? "disabled" : ""}`} onClick={() => this.eventRecapTabHandler("Recap", "Recap", this.state.bracketTab)}>{"Recap"}</li>
                                                            <li className={`list-group-item ${activeTab === "Awards" ? "active" : ""} ${disabledTab ? "disabled" : ""}`} onClick={() => this.eventRecapTabHandler("Recap", "Awards", this.state.bracketTab)}>{"Awards"}</li>
                                                            {/* {eventRecapTabInfo?.map(({ title, id }) => <li key={id} className={`list-group-item ${activeTab === title ? "active" : ""} ${disabledTab ? "disabled" : ""}`} onClick={() => this.eventRecapTabHandler(id, title, this.state.bracketTab)}>{title}</li>)} */}
                                                            {/* <li className="list-group-item active">Recap</li>
                                                            <li className="list-group-item">Standings</li>
                                                            <li className="list-group-item">Bracket/Results</li>
                                                            <li className="list-group-item">Stats Leaders</li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* <button className="btn submit-arrow" onClick={() => this.redirectBracket()}>View Completed Bracket<span><i class="fa fa-arrow-right"></i></span></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* new design start */}
                            <div className="recap-tab-info-section">

                                {/* {activeTab === "Recap" && <Recap allRecapDescription={allRecapDescription} />} */}
                                {!disabledTab ? eventRecapTabInfo(isOldEvent)?.map(({ id, Element, title }) => activeTab === title &&
                                    <Element
                                        allRecapDescription={allRecapDescription}
                                        eventTabTitle={title}
                                        bracketTab={bracketTab}
                                        ageGroup={this.state.recapFilter.ageGroup}
                                        eventRecapChartTabHandler={this.eventRecapChartTabHandler}
                                        statsLeadersHittingTabHandler={this.statsLeadersHittingTabHandler}
                                        statsLeadersPitchingTabHandler={this.statsLeadersPitchingTabHandler}
                                        statsP
                                        onHandleChange={this.onHandleChange}
                                        activeTab={activeTab}
                                        statsLeadersHittingTab={statsLeadersHittingTab}
                                        statsLeadersPitchingTab={statsLeadersPitchingTab}
                                        division={this.state.recapFilter.sdiv_id}
                                        divisionName={divisionName}
                                        statsLeadersHittingName={statsLeadersHittingName}
                                        statsLeadersPitchingName={statsLeadersPitchingName}
                                        poolArr={poolArr}
                                        poolId={poolId}
                                        gameType={gameType}
                                        topRecDatahandler={this.topRecDatahandler}
                                        redirectPwProfilePopupOpenHandler={this.redirectPwProfilePopupOpenHandler}
                                        hittingDescription={hittingDescription}
                                        pitchingDescription={pitchingDescription}
                                    />)
                                    : activeTab !== "Recap" ? this.state.divisionArr?.length === 0 ?
                                        <p className="text-center">Please select age group to proceed.</p> :
                                        <p className="text-center">Please select age group and division to proceed.</p> : ""
                                }

                                {/* <div className="recap-info-tab">
                                    <div className="container">
                                        {
                                            recapInfoData?.media?.filePath &&
                                            <div className="playerinfo-section">
                                                <h2>{recapInfoData.title}</h2>
                                                <div className="user-img">
                                                    <img src={recapInfoData?.media?.filePath ? recapInfoData.media.filePath : imagesArr.default_user} alt="team logo" />
                                                </div>
                                                <div className="player-detail">
                                                    <p className="text" dangerouslySetInnerHTML={{
                                                        __html: recapInfoData.recapText && recapInfoData.recapText
                                                    }}></p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div> */}

                                {/* <div className="standings-info-tab">
                                    <div className="poll_standings">
                                        <div className="container">
                                            <h1 className="section_title">Pool Standings</h1>
                                            <div className="recap-table-info">
                                                <div className="table-responsive-xl">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colspan="7" className="text-center">14U Division</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Team Name</th>
                                                                <th>W</th>
                                                                <th>L</th>
                                                                <th>T</th>
                                                                <th>PCT</th>
                                                                <th>RS</th>
                                                                <th>RA</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Khaos 18U</td>
                                                                <td>3</td>
                                                                <td>0</td>
                                                                <td>1</td>
                                                                <td>.875</td>
                                                                <td>19</td>
                                                                <td>12</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="bracket-results-tab">
                                    <div className="container">
                                        <div className="bracket-tab">
                                            <div className="row">
                                                <div className="w-33">
                                                    <button className="tab active">Gold</button>
                                                </div>
                                                <div className="w-33">
                                                    <button className="tab">Silver</button>
                                                </div>
                                                <div className="w-33">
                                                    <button className="tab">Bronze</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bracket_chart">
                                            
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="stats-leaders-tab">
                                    <div className="container">
                                        <div className="stats-leaders-info">
                                            <div className="stats-leaders-row">
                                                <div className="stats-hitting">
                                                    <div className="stats-info">
                                                        <h1 className="section_title">Hitting Stats</h1>
                                                        <div className="sub-tab">
                                                            <div className="row">
                                                                <div className="w-25">
                                                                    <div className="tab-text active">AVG</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">OPS</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">SLG</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">OPB</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">HITS</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">DOUBLES</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">HR</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">EB</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">RBI</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">R</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">SB</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="recap-table-info">
                                                                <div className="table-responsive-xl">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>BATTING AVG (min 5 ABs)</th>
                                                                                <th>AVG</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1. Christian Tavarez</td>
                                                                                <td>.450</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>2. Ethan North</td>
                                                                                <td>.450</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>3. Donovan Murdock</td>
                                                                                <td>.450</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stats-pitching">
                                                    <div className="stats-info">
                                                        <h1 className="section_title">Pitching Stats</h1>
                                                        <div className="sub-tab">
                                                            <div className="row">
                                                                <div className="w-25">
                                                                    <div className="tab-text active">ERA</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">WHIP</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">WINS</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">STRIKEOUTS</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">EARNED RUNS</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">K/BB</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">BAA</div>
                                                                </div>
                                                                <div className="w-25">
                                                                    <div className="tab-text">OPB</div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="recap-table-info">
                                                                <div className="table-responsive-xl">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>EARNED RUN AVG</th>
                                                                                <th>ERA</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1. Jason Franzel</td>
                                                                                <td>0.00</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>2. Hunter Wilmoth</td>
                                                                                <td>0.00</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>3. Dariel Ramirez</td>
                                                                                <td>0.00</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                            {/* new design end */}






                            {/* <div className="touranment-awards">
                                <div className="container">
                                    <h1 className="section_title">Tournament Awards</h1>
                                    <p className="text-center">Event recap article to go here - entered in CMS with text editor and tagging capability in PW</p>
                                    {
                                        (recapInfoData.Nohitter || recapInfoData.perfectGammer) &&
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="awards-type">
                                                    <div className="award-img">
                                                        <img src={imagesArr.touranment_award} />
                                                    </div>
                                                    <h4>No Hitter or Perfect Game</h4>
                                                    <p>- {recapInfoData.Nohitter && recapInfoData.nohitter.length !== 0 ? recapInfoData.nohitter[0].fname + " " + recapInfoData.nohitter[0].lname : recapInfoData.perfectGammer && recapInfoData.PerfectGammer.length !== 0 ? recapInfoData.PerfectGammer[0].fname + " " + recapInfoData.PerfectGammer[0].lname : "No data found"} -</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row"> */}
                            {/* {
                                            recapInfoData.MVPPlayer &&
                                            <div className="col-4">
                                                <div className="awards-type">
                                                    <div className="award-img">
                                                        <img src={imagesArr.award_mvp_1} />
                                                    </div>
                                                    <h4>MVP Player</h4>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.mvpPlayer.length !== 0 ?
                                                                    recapInfoData.mvpPlayer.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.TopProspects &&
                                            <div className="col-4">
                                                <div className="awards-type">
                                                    <div className="award-img">
                                                        <img src={imagesArr.award_mvp_1} />
                                                    </div>
                                                    <h4>Top Prospects</h4>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.topProspects.length !== 0 ?
                                                                    recapInfoData.topProspects.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                            {/* {
                                            recapInfoData.TMVPPlayer &&
                                            <div className="col-4">
                                                <div className="awards-type">
                                                    <div className="award-img">
                                                        <img src={recapInfoData.awardImage.TMVPPlayer ? recapInfoData.awardImage.TMVPPlayer : imagesArr.award_mvp_1} />
                                                    </div>
                                                    <h4>MVP Player</h4>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.tournamentMVP_Player.length !== 0 ?
                                                                    recapInfoData.tournamentMVP_Player.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.TMVPPitcher &&
                                            <div className="col-4">
                                                <div className="awards-type">
                                                    <div className="award-img">
                                                        <img src={recapInfoData.awardImage.TMVPPitcher ? recapInfoData.awardImage.TMVPPitcher : imagesArr.award_mvp_1} />
                                                    </div>
                                                    <h4>MVP Pitcher</h4>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.tournamentMVP_Pitcher.length !== 0 ?
                                                                    recapInfoData.tournamentMVP_Pitcher.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.TMVPSlugger &&
                                            <div className="col-4">
                                                <div className="awards-type">
                                                    <div className="award-img">
                                                        <img src={recapInfoData.awardImage.TMVPSlugger ? recapInfoData.awardImage.TMVPSlugger : imagesArr.award_mvp_1} />
                                                    </div>
                                                    <h4>MVP Slugger</h4>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.tournamentMVP_Slugger.length !== 0 ?
                                                                    recapInfoData.tournamentMVP_Slugger.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        recapInfoData.TournamentFirstTeam &&
                                        <div className="touranment-team-rank">
                                            <div className="touranment-awards-type">
                                                <div className="award-img">
                                                    <img src={imagesArr.touranment_award} />
                                                    <h4>1st All Tournament Team</h4>
                                                </div>
                                                <div className="rank-list">
                                                    <div className={`team-list ${recapInfoData.Tournament_FirstTeam.length === 0 ? "no-data" : ""}`}>
                                                        {
                                                            recapInfoData.Tournament_FirstTeam.length !== 0 ?
                                                                recapInfoData.Tournament_FirstTeam.map((item, i) => {
                                                                    return (
                                                                        <div className="team-name">
                                                                            {item.rank}.{item.fname ? item.fname + " " + item.lname : "-"}
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <p>
                                                                    No data found
                                                                </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        recapInfoData.TournamentSecondTeam &&
                                        <div className="touranment-team-rank">
                                            <div className="touranment-awards-type">
                                                <div className="award-img">
                                                    <img src={imagesArr.touranment_award} />
                                                    <h4>2nd All Tournament Team</h4>
                                                </div>
                                                <div className="rank-list">
                                                    <div className={`team-list ${recapInfoData.Tournament_SecondTeam.length === 0 ? "no-data" : ""}`}>
                                                        {
                                                            recapInfoData.Tournament_SecondTeam.length !== 0 ?
                                                                recapInfoData.Tournament_SecondTeam.map((item, i) => {
                                                                    return (
                                                                        <div className="team-name">
                                                                            {item.rank}.{item.fname ? item.fname + " " + item.lname : "-"}
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <p>
                                                                    No data found
                                                                </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        recapInfoData.HounerableMention &&
                                        <div className="touranment-team-rank">
                                            <div className="touranment-awards-type">
                                                <div className="award-img">
                                                    <img src={imagesArr.touranment_award} />
                                                    <h4>Tournament Honorable Mention</h4>
                                                </div>
                                                <div className="rank-list">
                                                    <div className={`team-list ${recapInfoData.Tournament_HounerableTeam.length === 0 ? "no-data" : ""}`}>
                                                        {
                                                            recapInfoData.Tournament_HounerableTeam.length !== 0 ?
                                                                recapInfoData.Tournament_HounerableTeam.map((item, i) => {
                                                                    return (
                                                                        <div className="team-name">
                                                                            {item.rank}.{item.fname ? item.fname + " " + item.lname : "-"}
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <p>
                                                                    No data found
                                                                </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div> */}
                            {/* <div className="stat-leaders">
                                <div className="container">
                                    <h1 className="section_title">Stat Leaders</h1>
                                    {
                                        (recapInfoData.RunsScored || recapInfoData.TotalBase || recapInfoData.RunsBatted || recapInfoData.Walks || recapInfoData.StolenBases
                                            || recapInfoData.OBP || recapInfoData.OPS || recapInfoData.HR || recapInfoData.B1 || recapInfoData.B2 || recapInfoData.B3 || recapInfoData.B4) ?
                                            <h4 className="section-sub-title">Batting Leaders</h4>
                                            :
                                            <p className="text-center mt-15">No data found</p>
                                    }
                                    <div className="row">
                                        {
                                            recapInfoData.RunsScored &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>Runs Scored (R)</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Runs Scored (R)', data: recapInfoData.topRunscorerR } })}>Top 10</button>
                                                        }
                                                        <PwLock lock={imagesArr.lock} image={imagesArr.ranking_banner_img} showLock={false} />
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.topRunscorerR.length !== 0 ?
                                                                    recapInfoData.topRunscorerR.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.TotalBase &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>Total Bases (TB)</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Total Bases (TB)', data: recapInfoData.totalbase } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.totalbase.length !== 0 ?
                                                                    recapInfoData.totalbase.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.RunsBatted &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>Runs Batted In (RBI)</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Runs Batted In (RBI)', data: recapInfoData.runsbattedin } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.runsbattedin.length !== 0 ?
                                                                    recapInfoData.runsbattedin.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.Walks &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>Walks (BB)</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Walks (BB)', data: recapInfoData.walksbb } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.walksbb.length !== 0 ?
                                                                    recapInfoData.walksbb.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.StolenBases &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>Stolen Bases (SB)</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Stolen Bases (SB)', data: recapInfoData.stolebase } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.stolebase.length !== 0 ?
                                                                    recapInfoData.stolebase.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.OBP &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>OBP</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'OBP', data: recapInfoData.obp } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.obp.length !== 0 ?
                                                                    recapInfoData.obp.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.OPS &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>OPS</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'OPS', data: recapInfoData.ops } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.ops.length !== 0 ?
                                                                    recapInfoData.ops.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.HR &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>HR</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'HR', data: recapInfoData.hr } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.hr.length !== 0 ?
                                                                    recapInfoData.hr.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.B1 &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>1B</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: '1B', data: recapInfoData.b1 } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.b1.length !== 0 ?
                                                                    recapInfoData.b1.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.B2 &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>2B</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: '2B', data: recapInfoData.b2 } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.b2.length !== 0 ?
                                                                    recapInfoData.b2.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.B3 &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>3B</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: '3B', data: recapInfoData.b3 } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.b3.length !== 0 ?
                                                                    recapInfoData.b3.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.B4 &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>BA</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'BA', data: recapInfoData.ba } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.ba.length !== 0 ?
                                                                    recapInfoData.ba.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        (recapInfoData.WHIP || recapInfoData.StrikeOUts) &&
                                        <h4 className="section-sub-title">Pitching Leaders</h4>
                                    }
                                    <div className="row">
                                        {
                                            recapInfoData.WHIP &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>WHIP</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'WHIP', data: recapInfoData.whip } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.whip.length !== 0 ?
                                                                    recapInfoData.whip.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            recapInfoData.StrikeOUts &&
                                            <div className="col-3">
                                                <div className="stat-leaders-box">
                                                    <div className="title-tag">
                                                        <h5>Strikeouts</h5>
                                                        {
                                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                            <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Strikeouts', data: recapInfoData.strikeouts } })}>Top 10</button>
                                                        }
                                                    </div>
                                                    <div className="list">
                                                        <ul>
                                                            {
                                                                recapInfoData.strikeouts.length !== 0 ?
                                                                    recapInfoData.strikeouts.map((item, i) => {
                                                                        return (
                                                                            <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className="text-center">
                                                                        No data found
                                                                    </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="upcomingevent_section">
                                <Shop cType="gob" />
                            </div>
                            <ProudSponsors increment={this.increment} decrement={this.decrement} />
                        </>
                        :
                        <div className="advancedsearch-section">
                            <div className="container">

                                <div className="advanced-event">
                                    <div className="img-event">
                                        <img src={eventObj && eventObj.eventImages && eventObj.eventImages.filePath ? eventObj.eventImages.filePath : imagesArr.eventinfo_banner} />
                                    </div>
                                    <div className="info-event">
                                        <div className="event_card_desc">
                                            <div className="event_card_logo">
                                                <img src={eventObj && eventObj.eventLogo && eventObj.eventLogo.filePath ? eventObj.eventLogo.filePath : imagesArr.event_info_bannerlogo} alt="event logo" />
                                            </div>
                                            <div className="event_card_titles">
                                                <h3>{eventObj && eventObj.eventTitle}</h3>
                                                <div className="event_card_dates">
                                                    <p className="date_icon">{eventObj && moment(eventObj.startDate).format("DD MMM YYYY") + " - " + moment(eventObj.endDate).format("DD MMM YYYY")}</p>
                                                    <p className="location_icon">
                                                        {eventObj && eventObj.location &&
                                                            eventObj.location.city ?
                                                            eventObj.location.city :
                                                            eventObj?.location?.streetAddress ?
                                                                location[0] :
                                                                eventObj?.location?.stateRegion}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="advanced-filter">
                                        <div className="fiter-list">
                                            <div className="list-group-scroll">
                                                <ul className="list-group mt-15 mb-15">
                                                    <li className={`list-group-item ${sendReq.type === 1 ? 'active' : ''}`} onClick={() => this.onTypeChange('type', 1)}>Performance</li>
                                                    <li className={`list-group-item ${sendReq.type === 2 ? 'active' : ''}`} onClick={() => this.onTypeChange('type', 2)}>Pitching</li>
                                                    <li className={`list-group-item ${sendReq.type === 3 ? 'active' : ''}`} onClick={() => this.onTypeChange('type', 3)}>Hitting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="search-data">
                                    <div className="data-section">
                                        <div className="side-filter">
                                            <div className="white-bg">
                                                <div className={`advanced-filter-collaps ${activeFilters.find(e => e === "position") ? "active" : ""}`} >
                                                    <button className="collapsible" onClick={() => this.filterTabs('position')}>Position</button>
                                                    <div className="content">
                                                        {this.renderPosition()}
                                                    </div>
                                                </div>
                                                <div className={`advanced-filter-collaps ${activeFilters.find(e => e === "year") ? "active" : ""}`} >
                                                    <button className="collapsible " onClick={() => this.filterTabs('year')}>Grad Year</button>
                                                    <div className="content">
                                                        {this.renderGradYear()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="white-bg mt-30">
                                                <div className="awards_group">
                                                    <h2>AWARDS</h2>
                                                    <div className="awards_list">
                                                        {recapInfoData.showcaseAwards &&
                                                            recapInfoData.showcaseAwards.length !== 0 ?
                                                            recapInfoData.showcaseAwards.map((item, i) => {
                                                                return (
                                                                    <div className="awards_item">
                                                                        <div className="awards_img" >
                                                                            <img src={item.player.profileUrl ? item.player.profileUrl : imagesArr.default_user} />
                                                                        </div>
                                                                        <div className="awards_info">
                                                                            <span onClick={() => this.props.history.push(`/pw-profile-info/${item.player._id}`)}>
                                                                                {(item.player.fname || item.player.lname) ? item?.player?.fname + " " + item?.player?.lname : "-"}
                                                                            </span>
                                                                            <span>
                                                                                <img className="zoom_img" src={item.award?.awardIcon?.filePath ? item.award?.awardIcon?.filePath : imagesArr.default_user} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })

                                                            :
                                                            <p className="text-center">No data found</p>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="advanced-data-table">
                                            <div className="table-responsive-xl">
                                                <table className="table">
                                                    <thead>
                                                        {
                                                            sendReq.type !== 4 && showcaseCols.length !== 0 &&
                                                            <>
                                                                <th>Rank</th>
                                                                <th>Athlete</th>
                                                                <th>Grad Year</th>
                                                            </>
                                                        }
                                                        {
                                                            showcaseCols.length !== 0 &&
                                                            showcaseCols.map((item, i) => {
                                                                return (
                                                                    <th onClick={() => sendReq.type !== 4 ? this.sortData(item) : ""}>
                                                                        {this.setHeaderVal(item)} {sendReq.type !== 4 ? sortArr.some((d) => d === item) ?
                                                                            <i class="fas fa-sort-up"></i> : this.state.sendReq.sort[item] === -1 ?
                                                                                <i class="fas fa-sort-down" aria-hidden="true"></i> : <i class="fa fa-sort" aria-hidden="true"></i> : ""}
                                                                    </th>
                                                                )
                                                            })
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            showcaseCols.length !== 0 ?
                                                                showcaseRecapList.length !== 0 ?
                                                                    showcaseRecapList.map((td, i) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{this.state.sendReq.page > 1 ? (this.state.sendReq.page * 10 - 10 + i + 1) : (i + 1)}</td>
                                                                                <td className="team-name">
                                                                                    <div className="athlete-user-info">
                                                                                        <Link className="athlete-info" to={`/pw-profile-info/${td?.athlete._id}`} target="_blank">
                                                                                            <h3>{td?.athlete?.fname + " " + td?.athlete?.lname}</h3>
                                                                                        </Link>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{td?.athlete?.profile?.gradeyear ? td?.athlete?.profile?.gradeyear : "-"}</td>
                                                                                {
                                                                                    showcaseCols.map((th, j) => {
                                                                                        return (
                                                                                            <td>{td[th] ? td[th] : "-"}</td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={showcaseCols.length + 3}>
                                                                            <p className="text-center" >No data found</p>
                                                                        </td>
                                                                    </tr>
                                                                :
                                                                <p className="text-center pt-15">No data found</p>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                showcaseCols.length !== 0 &&
                                                showcaseRecapList.length !== 0 &&
                                                <div className="Paginate mt-20">
                                                    <ReactPaginate
                                                        previousLabel={"<<"}
                                                        nextLabel={">>"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={1}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}
                                                        forcePage={this.state.currentPage}
                                                    />
                                                </div>
                                            }

                                            <div className="text-event">
                                                {
                                                    recapInfoData.recapText ?
                                                        <p className="text" dangerouslySetInnerHTML={{
                                                            __html: recapInfoData.recapText && recapInfoData.recapText
                                                        }}></p>
                                                        :
                                                        <p className="text">No description yet</p>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                <RedirectPwProfilePopup
                    flag={redirectPwProfilePopup}
                    close={this.redirectPwProfilePopupCloseHandler}
                    redirectPwProfileHandler={this.redirectPwProfileHandler}
                    playerInfo={playerInfo}
                />

                {
                    this.state.claimPopup &&
                    <ClaimAccount
                        flag={this.state.claimPopup}
                        close={this.closeClaimAccount}
                        dataList={this.state.eventObj}
                    />
                }

                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
                {/* <Preloader flag={loader} /> */}
                {
                    topRec &&
                    <TopRecord
                        flag={topRec}
                        close={this.handleClose}
                        topRecData={this.state.topRecData}
                    />
                }

            </>
        )
    }
}

pwbEventInfo.propTypes = {
    getEventScore: PropTypes.func.isRequired,
    getEventDivision: PropTypes.func.isRequired,
    getScoreDescription: PropTypes.func.isRequired,
    getEventAgeGroup: PropTypes.func.isRequired,
    getEventRecapInfo: PropTypes.func.isRequired,
    getRecapFilterValue: PropTypes.func.isRequired,
    getRecapShowcaseData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventRecapInfo: state.recapInfo,
    eventDetails: state.eventDetails,
});
var pwbEventInfoComponent = connect(mapStateToProps, { getEventDetails, getEventRecapInfo, getRecapShowcaseData, getRecapFilterValue, getEventScore, getEventDivision, getScoreDescription, getEventAgeGroup, findDivisionArr, getEventAgeGroupAfterPublish, getAllRecapDescription, getEventStatsLeaderHitting, getEventStatsLeaderPitching })(pwbEventInfo);
export default withRouter(pwbEventInfoComponent);
import React, { Component, useState, useReducer, useEffect, useRef } from 'react'
import { imagesArr } from '../../../assets/images';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getGameStatsInfo, postGameStatsInfo } from '../../../saga/actions/views/player/profile/gameStats.action';
import { GAMESTATS_REG } from '../../../saga/constants/view/player/profile/gameStats.const';
import Preloader from '../../../components/preloader';
import InputMask from 'react-input-mask';

const initialState = {
    G: "",
    AB: "",
    btR: "",
    AVG: "",
    btH: "",
    RBI: "",
    BB: "",
    OBP: "",
    KS: "",
    E: "",
    chINN: "",
    chPO: "",
    chCS: "",
    fdINN: "",
    TC: "",
    fdPO: "",
    fdE: "",
    CS: "",
    IP: "",
    ptH: "",
    ptR: "",
    ER: "",
    K: "",
    ptBB: "",
    HR: "",
    HB: "",
    loader: false
};
let postData;
let battingArr = [];
let catchingArr = [];
let fieldingArr = [];
let pitchingArr = [];

const reducer = (state, payload) => ({ ...state, ...payload });

function GameStats() {

    const [gameStats, setGameStats] = useReducer(reducer, initialState);
    const { G, AB, btR, AVG, btH, RBI, BB, OBP, KS, fdINN, TC, fdPO, fdE, CS, chINN, chPO, chCS, E, IP, ptH, ptR, ER, K, ptBB, HR, HB } = gameStats;
    const commonCall = new commonCalls();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const { gameStatsData: _GS, ragisterInfo: _REG } = useSelector(state => state.gameStatsInfo);
    const [open, setOpen] = useState(false);
    const [openFor, setOpenFor] = useState();
    const [gameArr, setGameArr] = useState({
        batting: [],
        catching: [],
        fielding: [],
        pitching: []
    })
    const dispatch = useDispatch();

    useEffect(() => {
        setLoader(true)
        getGameStatsInfo()(dispatch);
    }, [])

    useEffect(() => {
        if (Object.keys(_GS).length !== 0) {
            battingArr = [];
            catchingArr = [];
            fieldingArr = [];
            pitchingArr = [];
            if (_GS.hasOwnProperty('batting-stats')) {
                setGameStats({
                    G: _GS["batting-stats"].hasOwnProperty('G') ? _GS["batting-stats"].G[2020] : "",
                    AB: _GS["batting-stats"].hasOwnProperty('AB') ? _GS["batting-stats"].AB[2020] : "",
                    btR: _GS["batting-stats"].hasOwnProperty('R') ? _GS["batting-stats"].R[2020] : "",
                    AVG: _GS["batting-stats"].hasOwnProperty('AVG') ? _GS["batting-stats"].AVG[2020] : "",
                    btH: _GS["batting-stats"].hasOwnProperty('H') ? _GS["batting-stats"].H[2020] : "",
                    RBI: _GS["batting-stats"].hasOwnProperty('RBI') ? _GS["batting-stats"].RBI[2020] : "",
                    BB: _GS["batting-stats"].hasOwnProperty('BB') ? _GS["batting-stats"].BB[2020] : "",
                    OBP: _GS["batting-stats"].hasOwnProperty('OBP') ? _GS["batting-stats"].OBP[2020] : "",
                    KS: _GS["batting-stats"].hasOwnProperty("K's") ? _GS["batting-stats"]["K's"][2020] : "",
                    fdINN: _GS["fielding-stats"].hasOwnProperty('INN') ? _GS["fielding-stats"].INN[2020] : "",
                    TC: _GS["fielding-stats"].hasOwnProperty('TC') ? _GS["fielding-stats"].TC[2020] : "",
                    fdPO: _GS["fielding-stats"].hasOwnProperty('PO') ? _GS["fielding-stats"].PO[2020] : "",
                    fdE: _GS["fielding-stats"].hasOwnProperty('E') ? _GS["fielding-stats"].E[2020] : "",
                    CS: _GS["fielding-stats"].hasOwnProperty('CS') ? _GS["fielding-stats"].CS[2020] : "",
                    chINN: _GS["catching-stats"].hasOwnProperty('INN') ? _GS["catching-stats"].INN[2020] : "",
                    chPO: _GS["catching-stats"].hasOwnProperty('PO') ? _GS["catching-stats"].PO[2020] : "",
                    E: _GS["catching-stats"].hasOwnProperty('E') ? _GS["catching-stats"].E[2020] : "",
                    chCS: _GS["catching-stats"].hasOwnProperty('CS') ? _GS["catching-stats"].CS[2020] : "",
                    IP: _GS["pitching-stats"].hasOwnProperty('IP') ? _GS["pitching-stats"].IP[2020] : "",
                    ptH: _GS["pitching-stats"].hasOwnProperty('H') ? _GS["pitching-stats"].H[2020] : "",
                    ptR: _GS["pitching-stats"].hasOwnProperty('R') ? _GS["pitching-stats"].R[2020] : "",
                    ER: _GS["pitching-stats"].hasOwnProperty('ER') ? _GS["pitching-stats"].ER[2020] : "",
                    K: _GS["pitching-stats"].hasOwnProperty('K') ? _GS["pitching-stats"].K[2020] : "",
                    ptBB: _GS["pitching-stats"].hasOwnProperty('BB') ? _GS["pitching-stats"].BB[2020] : "",
                    HR: _GS["pitching-stats"].hasOwnProperty('HR') ? _GS["pitching-stats"].HR[2020] : "",
                    HB: _GS["pitching-stats"].hasOwnProperty('HB') ? _GS["pitching-stats"].HB[2020] : "",
                })

                {
                    Object.keys(_GS["batting-stats"]).map((e, i) => {
                        if (_GS["batting-stats"][e][2020]) {
                            battingArr.push(e);
                        }
                    })
                }
                {
                    Object.keys(_GS["catching-stats"]).map((e, i) => {
                        if (_GS["catching-stats"][e][2020]) {
                            catchingArr.push(e);
                        }
                    })
                }
                {
                    Object.keys(_GS["fielding-stats"]).map((e, i) => {
                        if (_GS["fielding-stats"][e][2020]) {
                            fieldingArr.push(e);
                        }
                    })
                }
                {
                    Object.keys(_GS["pitching-stats"]).map((e, i) => {
                        if (_GS["pitching-stats"][e][2020]) {
                            pitchingArr.push(e);
                        }
                    })
                }
                setGameArr({
                    batting: battingArr,
                    catching: catchingArr,
                    fielding: fieldingArr,
                    pitching: pitchingArr,
                })
            }
        }
        setLoader(false)

    }, [_GS])

    useEffect(() => {
        if (_REG) {
            if (_REG.flag) {
                commonCall.successMessage(_REG.message)
                getGameStatsInfo()(dispatch);
            }
            else if (!_REG.flag) {
                commonCall.errorMessage(_REG.message)
            }
            dispatch({
                type: GAMESTATS_REG,
                payload: null,
            });
        }
    }, [_REG])

    const handleOpen = (e) => {
        setOpen(true);
        setOpenFor(e.target.value);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGameStats({
            ...gameStats,
            [name]: value
        })
    }

    const handleSubmit = (e, arr, val) => {
        if (validator.current.allValid()) {
            postData = {
                "batting-stats": {
                    "G": {
                        "2020": G
                    },
                    "AB": {
                        "2020": AB
                    },
                    "R": {
                        "2020": btR
                    },
                    "AVG": {
                        "2020": AVG
                    },
                    "H": {
                        "2020": btH
                    },
                    "RBI": {
                        "2020": RBI
                    },
                    "BB": {
                        "2020": BB
                    },
                    "OBP": {
                        "2020": OBP
                    },
                    "K's": {
                        "2020": KS
                    }
                },
                "fielding-stats": {
                    "INN": {
                        "2020": fdINN
                    },
                    "TC": {
                        "2020": TC
                    },
                    "PO": {
                        "2020": fdPO
                    },
                    "E": {
                        "2020": fdE
                    }
                },
                "catching-stats": {
                    "INN": {
                        "2020": chINN
                    },
                    "PO": {
                        "2020": chPO
                    },
                    "E": {
                        "2020": E
                    },
                    "CS": {
                        "2020": chCS
                    }
                },
                "pitching-stats": {
                    "IP": {
                        "2020": IP
                    },
                    "H": {
                        "2020": ptH
                    },
                    "R": {
                        "2020": ptR
                    },
                    "ER": {
                        "2020": ER
                    },
                    "K": {
                        "2020": K
                    },
                    "BB": {
                        "2020": ptBB
                    },
                    "HR": {
                        "2020": HR
                    },
                    "HB": {
                        "2020": HB
                    }
                }
            };

            if (gameArr[arr].find(item => item === e) && !val) {
                setGameArr({
                    ...gameArr,
                    [arr]: gameArr[arr].filter(item => { if (item !== e) { return item; } })
                })
            }
            else if (val) {
                setGameArr({
                    ...gameArr,
                    [arr]: [...gameArr[arr], e]
                })
            }

            setOpen(false);
            setOpenFor();
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }

    const onSave = async () => {
        // if (validator.current.allValid()) {
        setLoader(true)
        await postGameStatsInfo(postData)(dispatch);
        // } else {
        //     validator.current.showMessages(true);
        //     forceUpdate(1);
        // }
    };

    const renderPopUp = (e, arr) => {
        let text;
        let placeHolder;
        if (e === "G") {
            text = "Games";
            placeHolder = "Enter Games";
        } else if (e === "AB") {
            text = "At Bats";
            placeHolder = "Enter At Bats";
        } else if (e === "R") {
            text = "Runs";
            placeHolder = "Enter Runs";
        } else if (e === "AVG") {
            text = "Average";
            placeHolder = "Enter Average";
        } else if (e === "H") {
            text = "Hits";
            placeHolder = "Enter Hits";
        } else if (e === "RBI") {
            text = "Runs Batted In";
            placeHolder = "Enter Runs Batted In";
        } else if (e === "BB") {
            text = "Base on Balls";
            placeHolder = "Enter Base on Balls";
        } else if (e === "OBP") {
            text = "On Base Percentage";
            placeHolder = "Enter On Base Percentage";
        } else if (e === "INN") {
            text = "Innings";
            placeHolder = "Enter Innings";
        } else if (e === "TC") {
            text = "Total Chances";
            placeHolder = "Enter Total Chances";
        } else if (e === "PO") {
            text = "Putout";
            placeHolder = "Enter Putout";
        } else if (e === "CS") {
            text = "Caught Stealing";
            placeHolder = "Enter Caught Stealing";
        } else if (e === "E") {
            text = "Errors";
            placeHolder = "Enter Errors";
        } else if (e === "IP") {
            text = "Innings Pitched";
            placeHolder = "Enter Innings Pitched";
        } else if (e === "ER") {
            text = "ER";
            placeHolder = "Enter ER";
        } else if (e === "K") {
            text = "K";
            placeHolder = "Enter K";
        } else if (e === "HR") {
            text = "Home Run";
            placeHolder = "Enter Home Run";
        } else if (e === "HB") {
            text = "Hit by Pitch";
            placeHolder = "Enter Hit by Pitch";
        } else if (e === "K's") {
            text = "Strikeouts";
            placeHolder = "Enter Strikeouts";
        }
        return (
            <div className="bg_input">
                <p>{text}</p>
                <div className="form-group">
                    {
                        e === "AVG" || e === "OBP" ?
                            <InputMask className="form-control" mask=".999" name={openFor} value={gameStats[openFor]} onChange={handleChange} placeholder={placeHolder} />
                            :
                            <input type="text" placeholder={placeHolder} className="form-control" id="usr" name={openFor} value={gameStats[openFor]} onChange={handleChange} />
                    }
                    <Link className="years-btn">2022</Link>
                    <span className="validMsg">{validator.current.message('Average', gameStats[openFor], 'numeric|min:0,num', { className: 'text-danger' })}</span>
                </div>
                <Link className="btn light-green w-100" onClick={() => handleSubmit(e, arr, gameStats[openFor])}>Submit</Link>
                <Link className="clear_now" onClick={() => setOpen(false)}>Clear selection</Link>
            </div>
        )
    }

    const setLoader = (val) => {
        setGameStats({
            loader: val
        })
    }

    return (
        <>
            <section className="before-login other-before-dashboard">
                <div className="white-bg">
                    <label className="common-lable">Batting</label>
                    <div className="check_input row">
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className={`checkbox`}>
                                    <input type="checkbox" id="G" value="G" name="G" checked={!!gameArr.batting.find(item => item === "G")} onChange={handleOpen} />
                                    <label for="G">G {gameStats["G"] && !!gameArr.batting.find(item => item === "G") ? " - " + gameStats["G"] : ""}</label>
                                    {/* {renderPopUp("G", "batting","G")} */}
                                    {open && openFor === "G" && renderPopUp("G", "batting")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className={`checkbox`}>
                                    <input type="checkbox" id="AB" value="AB" name="AB" checked={!!gameArr.batting.find(item => item === "AB")} onChange={handleOpen} />
                                    <label for="AB">AB {gameStats["AB"] && !!gameArr.batting.find(item => item === "AB") ? " - " + gameStats["AB"] : ""}</label>
                                    {/* {renderPopUp("AB", "batting","AB")} */}
                                    {open && openFor === "AB" && renderPopUp("AB", "batting")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="btR" value="btR" name="btR" checked={!!gameArr.batting.find(item => item === "R")} onChange={handleOpen} />
                                    <label for="btR">R {gameStats["btR"] && !!gameArr.batting.find(item => item === "R") ? " - " + gameStats["btR"] : ""}</label>
                                    {/* {renderPopUp("R", "batting")} */}
                                    {open && openFor === "btR" && renderPopUp("R", "batting")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="AVG" value="AVG" name="AVG" checked={!!gameArr.batting.find(item => item === "AVG")} onChange={handleOpen} />
                                    <label for="AVG">AVG {gameStats["AVG"] && !!gameArr.batting.find(item => item === "AVG") ? " - " + gameStats["AVG"] : ""}</label>
                                    {/* {renderPopUp("AVG", "batting")} */}
                                    {open && openFor === "AVG" && renderPopUp("AVG", "batting")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="btH" value="btH" name="btH" checked={!!gameArr.batting.find(item => item === "H")} onChange={handleOpen} />
                                    <label for="btH">H {gameStats["btH"] && !!gameArr.batting.find(item => item === "H") ? " - " + gameStats["btH"] : ""}</label>
                                    {/* {renderPopUp("H", "batting")} */}
                                    {open && openFor === "btH" && renderPopUp("H", "batting")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="RBI" value="RBI" name="RBI" checked={!!gameArr.batting.find(item => item === "RBI")} onChange={handleOpen} />
                                    <label for="RBI">RBI {gameStats["RBI"] && !!gameArr.batting.find(item => item === "RBI") ? " - " + gameStats["RBI"] : ""}</label>
                                    {/* {renderPopUp("RBI", "batting")} */}
                                    {open && openFor === "RBI" && renderPopUp("RBI", "batting")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="BB" value="BB" name="BB" checked={!!gameArr.batting.find(item => item === "BB")} onChange={handleOpen} />
                                    <label for="BB">BB {gameStats["BB"] && !!gameArr.batting.find(item => item === "BB") ? " - " + gameStats["BB"] : ""}</label>
                                    {/* {renderPopUp("BB", "batting")} */}
                                    {open && openFor === "BB" && renderPopUp("BB", "batting")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="OBP" value="OBP" name="OBP" checked={!!gameArr.batting.find(item => item === "OBP")} onChange={handleOpen} />
                                    <label for="OBP">OBP {gameStats["OBP"] && !!gameArr.batting.find(item => item === "OBP") ? " - " + gameStats["OBP"] : ""}</label>
                                    {/* {renderPopUp("OBP", "batting")} */}
                                    {open && openFor === "OBP" && renderPopUp("OBP", "batting")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="KS" value="KS" name="KS" checked={!!gameArr.batting.find(item => item === "K's")} onChange={handleOpen} />
                                    <label for="KS">K's {gameStats["KS"] && !!gameArr.batting.find(item => item === "K's") ? " - " + gameStats["KS"] : ""}</label>
                                    {open && openFor === "KS" && renderPopUp("K's", "batting")}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span className="validMsg">{validator.current.message('Batting', gameArr.batting, 'required', { className: 'text-danger' })}</span> */}

                    <label className="common-lable">Catching</label>
                    <div className="check_input row">
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="chINN" value="chINN" name="chINN" checked={!!gameArr.catching.find(item => item === "INN")} onChange={handleOpen} />
                                    <label for="chINN">INN {gameStats["chINN"] && !!gameArr.catching.find(item => item === "INN") ? " - " + gameStats["chINN"] : ""}</label>
                                    {/* {renderPopUp("INN", "catching")} */}
                                    {open && openFor === "chINN" && renderPopUp("INN", "catching")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="chPO" value="chPO" name="chPO" checked={!!gameArr.catching.find(item => item === "PO")} onChange={handleOpen} />
                                    <label for="chPO">PO {gameStats["chPO"] && !!gameArr.catching.find(item => item === "PO") ? " - " + gameStats["chPO"] : ""}</label>
                                    {/* {renderPopUp("PO", "catching")} */}
                                    {open && openFor === "chPO" && renderPopUp("PO", "catching")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="E" value="E" name="E" checked={!!gameArr.catching.find(item => item === "E")} onChange={handleOpen} />
                                    <label for="E">E {gameStats["E"] && !!gameArr.catching.find(item => item === "E") ? " - " + gameStats["E"] : ""}</label>
                                    {/* {renderPopUp("E", "catching")} */}
                                    {open && openFor === "E" && renderPopUp("E", "catching")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="chCS" value="chCS" name="chCS" checked={!!gameArr.catching.find(item => item === "CS")} onChange={handleOpen} />
                                    <label for="chCS">CS {gameStats["chCS"] && !!gameArr.catching.find(item => item === "CS") ? " - " + gameStats["chCS"] : ""}</label>
                                    {/* {renderPopUp("PO", "catching")} */}
                                    {open && openFor === "chCS" && renderPopUp("CS", "catching")}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span className="validMsg">{validator.current.message('Catching', gameArr.catching, 'required', { className: 'text-danger' })}</span> */}

                    <label className="common-lable">Fielding</label>
                    <div className="check_input row">
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="fdINN" value="fdINN" name="fdINN" checked={!!gameArr.fielding.find(item => item === "INN")} onChange={handleOpen} />
                                    <label for="fdINN">INN {gameStats["fdINN"] && !!gameArr.fielding.find(item => item === "INN") ? " - " + gameStats["fdINN"] : ""}</label>
                                    {/* {renderPopUp("INN", "fielding")} */}
                                    {open && openFor === "fdINN" && renderPopUp("INN", "fielding")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="TC" value="TC" name="TC" checked={!!gameArr.fielding.find(item => item === "TC")} onChange={handleOpen} />
                                    <label for="TC">TC {gameStats["TC"] && !!gameArr.fielding.find(item => item === "TC") ? " - " + gameStats["TC"] : ""}</label>
                                    {/* {renderPopUp("TC", "fielding")} */}
                                    {open && openFor === "TC" && renderPopUp("TC", "fielding")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="fdPO" value="fdPO" name="fdPO" checked={!!gameArr.fielding.find(item => item === "PO")} onChange={handleOpen} />
                                    <label for="fdPO">PO {gameStats["fdPO"] && !!gameArr.fielding.find(item => item === "PO") ? " - " + gameStats["fdPO"] : ""}</label>
                                    {/* {renderPopUp("PO", "fielding")} */}
                                    {open && openFor === "fdPO" && renderPopUp("PO", "fielding")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="fdE" value="fdE" name="fdE" checked={!!gameArr.fielding.find(item => item === "E")} onChange={handleOpen} />
                                    <label for="fdE">E {gameStats["fdE"] && !!gameArr.fielding.find(item => item === "E") ? " - " + gameStats["fdE"] : ""}</label>
                                    {/* {renderPopUp("PO", "fielding")} */}
                                    {open && openFor === "fdE" && renderPopUp("E", "fielding")}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span className="validMsg">{validator.current.message('Fielding', gameArr.fielding, 'required', { className: 'text-danger' })}</span> */}

                    <label className="common-lable">Pitching</label>
                    <div className="check_input row">
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="IP" value="IP" name="IP" checked={!!gameArr.pitching.find(item => item === "IP")} onChange={handleOpen} />
                                    <label for="IP">IP {gameStats["IP"] && !!gameArr.pitching.find(item => item === "IP") ? " - " + gameStats["IP"] : ""}</label>
                                    {/* {renderPopUp("IP", "pitching")} */}
                                    {open && openFor === "IP" && renderPopUp("IP", "pitching")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="ptH" value="ptH" name="ptH" checked={!!gameArr.pitching.find(item => item === "H")} onChange={handleOpen} />
                                    <label for="ptH">H {gameStats["ptH"] && !!gameArr.pitching.find(item => item === "H") ? " - " + gameStats["ptH"] : ""}</label>
                                    {/* {renderPopUp("H", "pitching")} */}
                                    {open && openFor === "ptH" && renderPopUp("H", "pitching")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="ptR" value="ptR" name="ptR" checked={!!gameArr.pitching.find(item => item === "R")} onChange={handleOpen} />
                                    <label for="ptR">R {gameStats["ptR"] && !!gameArr.pitching.find(item => item === "R") ? " - " + gameStats["ptR"] : ""}</label>
                                    {/* {renderPopUp("R", "pitching")} */}
                                    {open && openFor === "ptR" && renderPopUp("R", "pitching")}

                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="ER" value="ER" name="ER" checked={!!gameArr.pitching.find(item => item === "ER")} onChange={handleOpen} />
                                    <label for="ER">ER {gameStats["ER"] && !!gameArr.pitching.find(item => item === "ER") ? " - " + gameStats["ER"] : ""}</label>
                                    {/* {renderPopUp("ER", "pitching")} */}
                                    {open && openFor === "ER" && renderPopUp("ER", "pitching")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="K" value="K" name="K" checked={!!gameArr.pitching.find(item => item === "K")} onChange={handleOpen} />
                                    <label for="K">K {gameStats["K"] && !!gameArr.pitching.find(item => item === "K") ? " - " + gameStats["K"] : ""}</label>
                                    {/* {renderPopUp("K", "pitching")} */}
                                    {open && openFor === "K" && renderPopUp("K", "pitching")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="ptBB" value="ptBB" name="ptBB" checked={!!gameArr.pitching.find(item => item === "BB")} onChange={handleOpen} />
                                    <label for="ptBB">BB {gameStats["ptBB"] && !!gameArr.pitching.find(item => item === "BB") ? " - " + gameStats["ptBB"] : ""}</label>
                                    {/* {renderPopUp("BB", "pitching")} */}
                                    {open && openFor === "ptBB" && renderPopUp("BB", "pitching")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="HR" value="HR" name="HR" checked={!!gameArr.pitching.find(item => item === "HR")} onChange={handleOpen} />
                                    <label for="HR">HR {gameStats["HR"] && !!gameArr.pitching.find(item => item === "HR") ? " - " + gameStats["HR"] : ""}</label>
                                    {/* {renderPopUp("HR", "pitching")} */}
                                    {open && openFor === "HR" && renderPopUp("HR", "pitching")}
                                </div>
                            </div>
                        </div>
                        <div className="check_box true question_input">
                            <div className="check-box">
                                <div className="checkbox">
                                    <input type="checkbox" id="HB" value="HB" name="HB" checked={!!gameArr.pitching.find(item => item === "HB")} onChange={handleOpen} />
                                    <label for="HB">HB {gameStats["HB"] && !!gameArr.pitching.find(item => item === "HB") ? " - " + gameStats["HB"] : ""}</label>
                                    {/* {renderPopUp("HB", "pitching")} */}
                                    {open && openFor === "HB" && renderPopUp("HB", "pitching")}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span className="validMsg">{validator.current.message('Pitching', gameArr.pitching, 'required', { className: 'text-danger' })}</span> */}

                    <div className="flex-align">
                        <Link className="btn gray" to={"/player-dashboard"}>Cancel</Link>
                        <Link className="btn light-green" onClick={onSave}>Save</Link>
                    </div>
                </div>
            </section>
            <Preloader flag={gameStats.loader} />
        </>
    );
}

export default GameStats;

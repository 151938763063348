import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

export default class joinEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.validManager = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    handleWaiverLink = (link) => {
        this.props.setWaiverFlag();
        window.open(link.waiverUrl);
    };

    generateQuestionBox = () => {
        var self = this;
        return (
            this.props.dataList &&
            this.props.dataList.questions &&
            this.props.dataList.settings.spotAvailable &&
            this.props.dataList.questions.map(function (question, index) {
                return (
                    <div className="form-group">
                        <label>{question.question}</label>
                        <input
                            type="text"
                            className="form-control"
                            name={question._id}
                            value={question.answer}
                            placeholder="Enter your answer."
                            onChange={(e) => self.props.handleQA(e)}
                        />
                        {/* <span className="validMsg">{this.validator.message("question", question.answer, "required")}</span> */}
                    </div>
                );
            })
        );
    };

    validationUpdate = (cb) => {
        if (this.validator.allValid()) {
            if (this.props.eventJoin.isManager) {
                if (this.validManager.allValid()) {
                    cb(true);
                } else {
                    this.validManager.showMessages();
                    this.forceUpdate();
                    cb(false);
                }
            } else {
                cb(true);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            cb(false);
        }
    }

    render() {

        const { settings } = this.props.dataList;
        const { rosterList, eventJoin, isShowPayment, insuranceName } = this.props;

        return (
            <div className="event-form">
                <div className="join-form">
                    <h4>Join the Event</h4>
                    <form>
                        {
                            settings &&
                            settings.haveTeamRoster &&
                            <div className="form-group">
                                <label>Roster Groups</label>
                                <select className="custom-select" name="rosterTeamId" value={eventJoin.rosterTeamId} onChange={(e) => this.props.onHandleChange(e)} disabled={this.props.rosterDisable}>
                                    <option value="">Select roster group</option>
                                    {
                                        rosterList &&
                                        rosterList.map((item, i) => {
                                            return (
                                                <option value={item._id}>{item.rosterName}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="validMsg">{this.validator.message("Roster group", eventJoin.rosterTeamId, "required")}</span>
                            </div>
                        }

                        {
                            settings &&
                            settings.haveWaiver &&
                            <div className="form-group">
                                <label>Waivers</label>
                                <button
                                    type="button"
                                    className="btn blue_btn active"
                                    onClick={() => this.handleWaiverLink(settings)}
                                >
                                    Complete the waiver
                                </button>
                            </div>
                        }

                        {
                            settings &&
                            settings.isShowInsurance &&
                            <div className="form-group">
                                <label>Upload Insurance</label>
                                <div className="create_event_fileUpload file_input">

                                    {!eventJoin.insuranceImg && (
                                        <>
                                            <i class="fas fa-cloud-upload-alt"></i>
                                            <p>Click or drop file</p>
                                        </>
                                    )}

                                    <div className="image_upload_wrap banner_upoad_img">
                                        <img src={eventJoin.insuranceImg.filePath} alt="" />
                                    </div>
                                    <input
                                        ref="file"
                                        name="insuranceImg"
                                        id="insurance"
                                        type="file"
                                        className="img_file_input form-control-file"
                                        onChange={(e) => this.props.onHandleChange(e)}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                </div>
                                {eventJoin.insuranceImg && (
                                    <div className="image_prev_name">
                                        <p className="image_name">{insuranceName}</p>
                                        <span
                                            className="rounded-circle round_close"
                                            onClick={() => this.props.removeInsurance()}
                                        >
                                            <i
                                                className="fa fa-times"
                                                id="insurance"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                    </div>
                                )}
                                <span className="validMsg">{this.validator.message("Insurance", eventJoin.insuranceImg, "required")}</span>
                            </div>
                        }

                        {settings &&
                            settings.haveTeamManger &&
                            <>
                                <div className="form-group">
                                    <label>Do You Have Team Manager?</label>
                                    <div className="check_input row">
                                        <div className="check_box">
                                            <div className="checkbox">
                                                <input
                                                    type="radio"
                                                    name="isManager"
                                                    onChange={(e) => this.props.onHandleChange(e)}
                                                    value={true}
                                                    checked={eventJoin.isManager === true}
                                                />
                                                <label>Yes </label>
                                            </div>
                                            <div className="checkbox mr-0">
                                                <input
                                                    type="radio"
                                                    name="isManager"
                                                    onChange={(e) => this.props.onHandleChange(e)}
                                                    value={false}
                                                    checked={eventJoin.isManager === false}
                                                />
                                                <label>No </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    eventJoin.isManager &&
                                    <>
                                        <div className="form-group">
                                            <label>Manager Name</label>
                                            <input
                                                type="text"
                                                name="managerName"
                                                placeholder="Enter manager name"
                                                className="form-control"
                                                value={eventJoin.managerName}
                                                onChange={(e) => this.props.onHandleChange(e)}
                                            />
                                            <span className="validMsg">{this.validManager.message("Manager Name", eventJoin.managerName, "required")}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>Manager Email</label>
                                            <input
                                                type="email"
                                                name="managerEmail"
                                                placeholder="Enter manager email"
                                                className="form-control"
                                                value={eventJoin.managerEmail}
                                                onChange={(e) => this.props.onHandleChange(e)}
                                            />
                                            <span className="validMsg">{this.validManager.message("Manager Email", eventJoin.managerEmail, "required")}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>Manager Phone</label>
                                            <input
                                                type="text"
                                                name="managerPhone"
                                                placeholder="Enter manager phone"
                                                className="form-control"
                                                value={eventJoin.managerPhone}
                                                onChange={(e) => this.props.onHandleChange(e)}
                                                pattern="[0-9]*"
                                            />
                                            <span className="validMsg">{this.validManager.message("Manager Phone", eventJoin.managerPhone, "required")}</span>
                                        </div>
                                    </>
                                }
                            </>
                        }

                        {this.generateQuestionBox()}

                        {/* {!isShowPayment &&
                            <Link className="btn light-green" onClick={() => this.props.registerEvent()}>Submit</Link>
                        } */}
                    </form>
                </div>
            </div >
        )
    }
}

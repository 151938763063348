import axios from "axios";
import { API_Url, Auth_Player_Key, checkIsValidPlayerToken } from "../../../../helpers/authToken";
import {
  GETINFO_SUCCESS,
  GETINFO_ERROR,
  ZIPINFO_SUCCESS,
  ZIPINFO_ERROR,

  POSTINFO_SUCCESS,
  POSTINFO_ERROR,
  PICTUREUPLOAD_SUCCESS,
  PICTUREUPLOAD_ERROR,
} from "../../../../constants/view/player/profile/personalInfo.const";
import histrory from '../../../../../History';

export const fetchInfo = () => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/player/basic`,
    headers: {
      "x-auth-token": Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((get) => {
      dispatch({
        type: GETINFO_SUCCESS,
        payload: get.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({ type: GETINFO_ERROR, error: error.response.data.message });
    });
};

export const zipCodeInfo = (zipcode) => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}setting/territory/zipcode/${Number(zipcode)}`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: ZIPINFO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({ type: ZIPINFO_ERROR, error: error.response.data.message });
    });
};

export const postInfo = (register) => dispatch => {
  checkIsValidPlayerToken(histrory)
  return axios({
    method: 'POST',
    url: `${API_Url}bc/player/basic`,
    data: register,
    headers: {
      'x-auth-token': Auth_Player_Key,
      'Accept': 'application/json'
    }
  }).then(post => {
    console.log("post:---- ", post);
    dispatch({
      type: POSTINFO_SUCCESS,
      payload: post.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: POSTINFO_ERROR, error: error.response.data.message })
  });
};

export const postPictureUpload = (file, type) => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  const formData = new FormData();
  formData.append("file", file);
  axios({
    method: "POST",
    url: `${API_Url}file/upload?documentType=${type}`,
    data: formData,
  })
    .then((res) => {
      dispatch({
        type: PICTUREUPLOAD_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: PICTUREUPLOAD_ERROR,
          error: error.response.data.message,
        });
    });
};


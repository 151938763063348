import axios from "axios";
import { API_Url, Auth_Coach_Key, checkIsValidCoachToken } from '../../../../helpers/authToken';
import { TRAININGINFO_SUCCESS, TRAININGINFO_ERROR, REGINFO_ERROR } from '../../../../constants/view/coach/profile/trainingInfo.const';
import { REGISTERINFO_SUCCESS } from "../../../../constants/view/coach/profile/personalInfo.const";
import histrory from '../../../../../History';

export const getTrainingInfo = () => dispatch => {
    checkIsValidCoachToken(histrory)
    axios({
        method: 'GET',
        url: `${API_Url}bc/coach/training`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(training => {
        dispatch({
            type: TRAININGINFO_SUCCESS,
            payload: training.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: TRAININGINFO_ERROR, error: error.response.data.message })
    });
};

export const regInfo = (regData) => dispatch => {
    checkIsValidCoachToken(histrory)
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/training`,
        data: regData,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(reg => {
        console.log("register:---- ", reg);
        dispatch({
            type: REGISTERINFO_SUCCESS,
            payload: reg.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: REGINFO_ERROR, error: error.response.data.message })
    });
};

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFields } from '../../saga/actions/views/company/pwRanking/leaderboard/mainBoard.action';

class companyHeader extends Component {


    redirectShop = (link) => {
        this.props.closeSideBar("bcEvent");
        window.open(link, "_blank");
    }

    redirectMenu = (path) => {
        this.props.closeSideBar("bcEvent");
        localStorage.setItem("isCollegeShowcase", (path === '/dc-collegeShowcase') ? true : false);
        localStorage.setItem("isAllCompany", (path === '/dc-event') ? true : false);
        if (path === "/pw-leaderboard") {
            this.props.mainBoardInfo.sendReq.gradeYear = "2022";
            this.props.mainBoardInfo.sendReq.field = "";
            this.props.mainBoardInfo.sendReq.metricType = 2;
            this.props.mainBoardInfo.sendReq.metricSubType = "Pitching";
            this.props.mainBoardInfo.sendFieldReq.metricType = 2;
            this.props.mainBoardInfo.sendFieldReq.metricSubType = "Pitching";
            localStorage.removeItem("eventReport");
            this.props.refresh();
        }
        this.props.history.push(path);
      
    }

    redirectRoute = (info) => {
        this.props.profileDetails.sendSummaryReq = {
            tab: "summary",
            id: "",
            eventTeamType: 0,
            sortOn: {
                eventstartDate: "",
                eventendDate: "",
                awardstartDate: "",
                awardendDate: "",
                gallerystartDate: "",
                galleryendDate: ""
            },
            seasonType: 1,
            pitching: "basic",
            batting: "basic",
            filterOn: "",
            field: "",
            metricSubType: "Speed",
            metricType: 1,
            companyId: localStorage.getItem("cid"),
            statsType:"Hitting"
        };
        this.props.profileDetails.tabs = 0;
        this.props.profileDetails.backupTab = 0;
        this.props.profileDetails.statsType = 0;
        this.props.profileDetails.eventPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: localStorage.getItem("userID"),
        };
        this.props.profileDetails.mediaPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: localStorage.getItem("userID"),
        };
        this.props.profileDetails.eventCurrentPage = 0;
        localStorage.setItem("playerId", info.uid)
        this.props.history.push(`/pw-profile-info/${info.uid}`);
        if (this.props.match.path === "/pw-profile-info/:id") {
            this.props.clearStates();
            this.props.renderPlayerProfile()
        }
    }


    render() {
        const { getData, profileInfo } = this.props;
        let url = new URL(document.location);
        return (
            <div className="container">
                {
                    localStorage.getItem("company") !== "dc" ?
                     <Link to={getData.route} className="navbar-brand">
                    <img src={getData.logo} alt="logo" className={getData.key === "gob" || getData.key === "utb" ? "gob-logo" : ""} />
                </Link>
                :
                        <div className="collapse navbar-collapse" id="companyheader">
                            <ul className="navbar-nav navbar-navs">
                                {
                                    getData.menu.map((item, i) => {
                                        return (
                                            item.label !== "Download App" ?

                                                <li className={`nav-item ${localStorage.getItem("company") === "pwb" ? "past_champions" : ""} ${(item.label === "PW+" || item.label === "PWS+") ? "beta-tag" : ""} ${url.pathname === item.route ? "active" : ""}`}>
                                                    <Link className="nav-link" onClick={() => this.redirectMenu(item.route)}>{item.label}</Link>
                                                    {
                                                        (item.label === "PW+" || item.label === "PWS+") &&
                                                        <span className='tag-text'>BETA</span>
                                                    }
                                                </li>
                                                :
                                                <>
                                                    {/* <li className={`nav-item avatar-dropdown-menu downloadapp-menu-mb pr-0 ${url.pathname === item.route ? "active" : ""}`}>
                                                                        <Link className="nav-link" >{item.label}</Link>
                                                                        <div className="avatar-dropdown-menu-items">
                                                                            <ul>
                                                                                <li> <a href='#'>Android App</a> </li>
                                                                                <li> <a href='#'>Apple App</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </li> */}

                                                    <li className="nav-item">
                                                        <span className={`arrow ${this.props.subMenuCom === "downLoadApp" ? 'active' : ''}`} onClick={() => this.props.handleComMenu('downLoadApp')} >
                                                            <i className="fas fa-chevron-right"></i>
                                                        </span>
                                                        <Link className="nav-link">{item.label}</Link>
                                                        <ul className={`sub-menu ${this.props.subMenuCom === "downLoadApp" ? 'active-submenu' : ''} `}>
                                                            {/* <li onClick={()=>this.props.closeSideBar("bcEvent")}>
                                                                                <a href='https://play.google.com/store/apps/details?id=com.baseballcloud.diamondconnect' target='_blank'>Android App</a>
                                                                            </li> */}
                                                            <li onClick={() => this.props.closeSideBar("bcEvent")}>
                                                                <a href='https://apps.apple.com/us/app/diamondconnect-mobile/id1605935504' target='_blank' >IOS App</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                }
               
                {
                   localStorage.getItem("company") !== "dc" &&  
                   <div className="collapse navbar-collapse" id="companyheader">
                    <ul className="navbar-nav ml-auto">
                        {
                            localStorage.getItem("coachToken") &&
                            (localStorage.getItem("company") === "pwb" || localStorage.getItem("company") === "pws") &&
                            url.pathname !== "/verify" && url.pathname !== "/signup3" && url.pathname !== "/coach-signup4" && url.pathname !== "/player-signup4" &&
                            <li className={`nav-item beta-tag ${url.pathname === "/pw-advanced-search" ? "active" : ""}`}>
                                <Link className="nav-link" to={"/pw-advanced-search"}>Advanced Search</Link>
                                <span className='tag-text'>BETA</span>
                            </li>
                        }
                        {
                            localStorage.getItem("playerToken") &&
                            (localStorage.getItem("company") === "pwb" || localStorage.getItem("company") === "pws") &&
                            url.pathname !== "/verify" && url.pathname !== "/signup3" && url.pathname !== "/coach-signup4" && url.pathname !== "/player-signup4" &&
                            <li className="nav-item beta-tag header-btn">
                                <Link className="btn header-dark" onClick={() => this.redirectRoute(profileInfo)}>My Profile</Link>
                                <span className='tag-text'>BETA</span>
                            </li>
                        }

                        { 
                            getData.menu.map((item, i) => {
                                return (

                                    item.label !== "Download App" ?

                                    <li className={`nav-item ${localStorage.getItem("company") === "pwb"? "past_champions": ""} ${(item.label === "PW+" || item.label === "PWS+") ? "beta-tag" : ""} ${url.pathname === item.route ? "active" : ""}`}>
                                        <Link className="nav-link" onClick={() => this.redirectMenu(item.route)}>{item.label}</Link>
                                        {
                                            (item.label === "PW+" || item.label === "PWS+") &&
                                            <span className='tag-text'>BETA</span>
                                        }
                                    </li>
                                    : 
                                 
                                       
                                       <>
                                            {/* <li className={`nav-item avatar-dropdown-menu downloadapp-menu-mb pr-0 ${url.pathname === item.route ? "active" : ""}`}>
                                                <Link className="nav-link" >{item.label}</Link>
                                                <div className="avatar-dropdown-menu-items">
                                                    <ul>
                                                        <li> <a href='#'>Android App</a> </li>
                                                        <li> <a href='#'>Apple App</a></li>
                                                    </ul>
                                                </div>
                                            </li> */}
                                          
                                             <li className="nav-item">
                                                <span className={`arrow ${this.props.subMenuCom === "downLoadApp" ? 'active' : ''}`} onClick={() => this.props.handleComMenu('downLoadApp')} >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                                <Link className="nav-link">{item.label}</Link>
                                                <ul className={`sub-menu ${this.props.subMenuCom === "downLoadApp" ? 'active-submenu' : ''} `}>
                                                    {/* <li onClick={()=>this.props.closeSideBar("bcEvent")}>
                                                        <a href='https://play.google.com/store/apps/details?id=com.baseballcloud.diamondconnect' target='_blank'>Android App</a>
                                                    </li> */}
                                                    <li onClick={()=>this.props.closeSideBar("bcEvent")}>
                                                        <a href='https://apps.apple.com/us/app/diamondconnect-mobile/id1605935504' target='_blank' >IOS App</a>
                                                    </li>
                                                </ul>
                                            </li> 
                                       </>
                                )
                            })
                        }
                        {localStorage.getItem("company") !== "dc" && <li className="nav-item">
                            {
                                getData.button.length !== 0 &&
                                getData.button.map((item, i) => {
                                    if (item.route) {
                                        return (
                                            <Link className={item.className} onClick={() => this.redirectMenu(item.route)}>
                                                {
                                                    item.icon &&
                                                    <i className={item.icon} aria-hidden="true"></i>
                                                }
                                                {item.label}
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <Link className={item.className} onClick={() => item.label === "Shop" ? this.redirectShop(item.link) : ""}>
                                                {
                                                    item.icon &&
                                                    <i className={item.icon} aria-hidden="true"></i>
                                                }
                                                {item.label}
                                            </Link>
                                        )
                                    }
                                })
                            }
                        </li>}
                    </ul>
                </div>
                }
                
                <button className="navbar-toggler-company" onClick={this.props.toggleMenuBar.bind(this, 'bcEvent')}><span className="navbar-toggler-icon-company"></span></button>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      refresh: () => dispatch({ type: 'REFRESH_PAGE' }),
    }
  }

const mapStateToProps = state => ({
    profileDetails: state.profileSummaryInfo,
    mainBoardInfo: state.mainBoardInfo,
});

let companyHeaderComponents = connect(mapStateToProps,mapDispatchToProps)(companyHeader);
export default withRouter(companyHeaderComponents);

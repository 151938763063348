import axios from "axios";
import { DELETE_ROSTER_CONFIRMATION_SUCCESS, DELETE_ROSTER_CONFIRMATION_ERROR, DIVISION_ERROR, DIVISION_SUCCESS, ROSTERINFO_ERROR, ROSTERINFO_SUCCESS, MAIL_ROSTERINFO_SUCCESS, MAIL_ROSTERINFO_ERROR, ALL_MAIL_ROSTERINFO_SUCCESS, ALL_MAIL_ROSTERINFO_ERROR } from "../../../../constants/view/coach/profile/rosterInfo.const";
import { REGISTERINFO_SUCCESS } from "../../../../constants/view/coach/profile/personalInfo.const";
import { API_Url, Auth_Coach_Key, checkIsValidCoachToken } from "../../../../helpers/authToken";
import histrory from '../../../../../History';

export const deleteConfirmation = (data) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/check/roster/active`,
    data: data,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: DELETE_ROSTER_CONFIRMATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: DELETE_ROSTER_CONFIRMATION_ERROR,
          error: error.response.data.message,
        });
    });
};

export const getRosterInfo = () => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/coach/roster/v2`,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: ROSTERINFO_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: ROSTERINFO_ERROR,
          error: error.response.data.message,
        });
    })
};

export const postRosterInfo = (data) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/roster`,
    data: data,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: ROSTERINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

export const getDivision = () => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}setting/division`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: DIVISION_SUCCESS,
        payload: res.data.result.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: DIVISION_ERROR,
          error: error.response.data.message,
        });
    })
};

export const sendMailRosterPlayer = (data) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/roster/player/send/email`,
    data: data,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  }).then((res) => {
    dispatch({
      type: MAIL_ROSTERINFO_SUCCESS,
      payload: res.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({
        type: MAIL_ROSTERINFO_ERROR,
        error: error.response.data.message,
      });
  })
};

export const sendAllMailRosterPlayer = (data) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/roster/playergroup/send/email`,
    data: data,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  }).then((res) => {
    dispatch({
      type: ALL_MAIL_ROSTERINFO_SUCCESS,
      payload: res.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({
        type: ALL_MAIL_ROSTERINFO_ERROR,
        error: error.response.data.message,
      });
  })
};

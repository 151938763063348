import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../components/commonHeroBanner';
import Confirmation from '../popup/confirmation';
import { imagesArr } from '../../assets/images';
import { getActiveList, deleteSubscription, getExpireList, upgradeList } from '../../saga/actions/common/subscription.action';
import Preloader from '../../components/preloader';
import moment from "moment";
import commonCalls from "../../businesslogic/commonCalls";
import UpgradeSubscribe from '../popup/UpgradeSubscribe';
import SuccessUpgrade from '../popup/successUpgrade';

class coachSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFilter: {
                limit: 0
            },
            activeList: "",
            expireList: "",
            loader: false,
            delId: "",
            deletePopup: false,
            expireFilter: {
                limit: 0
            },
            date: "",
            upgradeArr: [],
            upgradePopup: false,
            subId: "",
            successPopup: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setLoader(true);
        this.props.getActiveList(this.state.activeFilter, "Coach");
        this.props.getExpireList(this.state.expireFilter, "Coach");
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.subInfo &&
            nextProps.subInfo.getActiveReq &&
            nextProps.subInfo.getActiveData
        ) {
            nextProps.subInfo.getActiveReq = false;
            console.log("nextProps.subInfo.getActiveData:--", nextProps.subInfo.getActiveData);
            if (nextProps.subInfo.getActiveData.flag) {
                this.setState({
                    activeList: nextProps.subInfo.getActiveData.result.docs
                }, () => {
                    nextProps.subInfo.getActiveData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.subInfo.getActiveData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.delActiveReq &&
            nextProps.subInfo.delActiveData
        ) {
            nextProps.subInfo.delActiveReq = false;
            console.log("nextProps.subInfo.delActiveData:--", nextProps.subInfo.delActiveData);
            if (nextProps.subInfo.delActiveData.flag) {
                document.body.classList.remove('modal-open');
                this.setState({
                    delId: "",
                    deletePopup: false
                }, () => {
                    this.props.getActiveList(this.state.activeFilter, "Coach");
                    this.props.getExpireList(this.state.expireFilter, "Coach");
                })
            } else {
                nextProps.subInfo.delActiveData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.getExpireReq &&
            nextProps.subInfo.getExpireData
        ) {
            nextProps.subInfo.getExpireReq = false;
            console.log("nextProps.subInfo.getExpireData:--", nextProps.subInfo.getExpireData);
            if (nextProps.subInfo.getExpireData.flag) {
                this.setState({
                    expireList: nextProps.subInfo.getExpireData.result.docs
                }, () => {
                    nextProps.subInfo.getExpireData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.subInfo.getExpireData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.getUpgradeReq &&
            nextProps.subInfo.getUpgradeData
        ) {
            nextProps.subInfo.getUpgradeReq = false;
            console.log("nextProps.subInfo.getUpgradeData:--", nextProps.subInfo.getUpgradeData);
            if (nextProps.subInfo.getUpgradeData.flag) {
                this.setState({
                    upgradeArr: nextProps.subInfo.getUpgradeData.result.data,
                    upgradePopup: !this.state.upgradePopup
                }, () => {
                    nextProps.subInfo.getUpgradeData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.subInfo.getUpgradeData = null;
                this.setLoader(false);
            }
        }
    }

    setPlan = (typeId, plans) => {
        var isPlans = "";
        plans.length > 0 &&
            plans.forEach((data) => {
                if (data.stripePlanId === typeId) return (isPlans = data);
            });
        return isPlans;
    }

    gotoLanding = () => {
        localStorage.setItem("scrollLanding", "");
        this.props.history.push("/ballr");
    }

    getUpgrade = (sid) => {
        this.setState({
            subId: sid
        }, () => {
            this.setLoader(true);
            this.props.upgradeList({ subscription_id: sid }, "Coach");
        })
    }

    renderActiveList = () => {
        return (
            this.state.activeList.length !== 0 ?
                this.state.activeList.map((list, i) => {
                    if (list.productId.productCode !== "ANALYZR") {
                        return (
                            <tr key={i}>
                                <th className="title">{list.productId.name} {this.setPlan(list.priceId, list.packageId.plans).type === 1 ? "Monthly" : "Yearly"} Subscription -  {list.packageId.type + " " + list.packageId.role}</th>
                                {
                                    list.stripeLogId &&
                                    list.stripeLogId.stripeLog &&
                                    list.stripeLogId.stripeLog.status === "trialing" &&
                                    list.packageId &&
                                    list.packageId.trialPeriod > 0 &&
                                    <td style={{ color: 'red' }}> Free Trial {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_start * 1000).format("DD MMM, YYYY")} - {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_end * 1000).format("DD MMM, YYYY")}</td>
                                }
                                <td>{(list.isActive && list.isExpire) ? "Good through " : "Renew "} {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
                                <td className="status_link text-right">
                                    {
                                        list.isActive &&
                                        <Link className="green-status">Active</Link>
                                    }
                                    {
                                        this.setPlan(list.priceId, list.packageId.plans).type === 2 &&
                                            list.packageId.type === "Gold" ?
                                            ""
                                            :
                                            <Link className="blue-status" onClick={() => this.getUpgrade(list._id)}>Upgrade</Link>
                                    }
                                </td>
                                <td className="subscription_link">
                                    {
                                        list.isActive && !list.isExpire &&
                                        <Link onClick={() => this.cancelSub(list.lastSubStripeId, list.exprireDate)}>Cancel Subscription</Link>
                                    }
                                    {
                                        !list.isActive && list.isExpire &&
                                        <Link style={{ pointerEvents: 'none', color: "lightgray" }}>Cancel Subscription</Link>
                                    }
                                    {
                                        list.isActive && list.isExpire &&
                                        <Link style={{ color: "#0cbf3e" }} onClick={() => this.nextPayment(list.packageId.plans, list.packageId)}>Renew Now</Link>
                                    }
                                </td>
                            </tr>
                        );
                    }
                })
                :
                <p className="text-center mb-0">There is no subscription active yet. <Link onClick={() => this.gotoLanding()}>Click Here</Link> to buy now.</p>
        )
    }

    renderExpireList = () => {
        return (
            this.state.expireList.length !== 0 ?
                this.state.expireList.map((list, i) => {
                    return (
                        <tr key={i}>
                            <th className="title">{list.productId.name} {this.setPlan(list.priceId, list.packageId.plans).type === 1 ? "Monthly" : "Yearly"} Subscription -  {list.packageId.role}</th>
                            <td className="lightgray" colspan="2">Expires {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
                        </tr>
                    );
                })
                :
                <p className="text-center mb-0">No Data Found</p>
        )
    }

    cancelSub = (subId, expiryDate) => {
        document.body.classList.add("modal-open");
        this.setState({
            delId: subId,
            deletePopup: true,
            date: expiryDate
        })
    }

    nextPayment = (plan, data) => {
        let nextData = {
            packid: data._id,
            plans: plan[0],
            title: data.role + " - " + data.type + " Package",
            trialPeriod: data.trialPeriod
        }
        this.commonCall.setStorage("payPlans", nextData, flag => {
            if (flag) {
                if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
                    this.props.history.push('/login');
                } else if (localStorage.getItem("coachToken")) {
                    this.props.history.push('/coach-payment');
                } else {
                    this.props.history.push('/player-payment');
                }
            }
        });
    }

    closePopup = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            delId: "",
            deletePopup: false
        })
    }

    confirmPopup = () => {
        this.setLoader(true);
        this.props.deleteSubscription({
            subscription_id: this.state.delId
        }, "Coach");
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    onHandleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            upgradePopup: !this.state.upgradePopup,
            upgradeArr: [],
            subId: ""
        })
    }

    onHandleOk = () => {
        this.setState({
            upgradePopup: !this.state.upgradePopup,
            upgradeArr: [],
            subId: "",
            successPopup: true
        })
    }

    onHandleCloseSuccess = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            successPopup: !this.state.successPopup,
        }, () => {
            this.props.getActiveList(this.state.activeFilter, "Coach");
        })
    }

    render() {
        const { loader, deletePopup, upgradePopup, upgradeArr, subId, successPopup } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="coachsubscription">
                    <div className="subscriptioninfo">
                        <div className="container">
                            <div className="white-bg">
                                <h3>Active</h3>
                                <p>Your Active Subscription</p>
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <tbody>
                                            {this.renderActiveList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="subscriptioninfo mb-0">
                        <div className="container">
                            <div className="white-bg">
                                <h3>Expired</h3>
                                <p>Your Expired Subscription</p>
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <tbody>
                                            {this.renderExpireList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Confirmation
                    flag={deletePopup}
                    type="subcription"
                    close={this.closePopup}
                    confirm={this.confirmPopup}
                    date={this.state.date}
                />
                {
                    upgradePopup &&
                    <UpgradeSubscribe
                        flag={upgradePopup}
                        data={upgradeArr}
                        close={this.onHandleClose}
                        sid={subId}
                        ok={this.onHandleOk}
                        type="Coach"
                    />
                }
                {
                    successPopup &&
                    <SuccessUpgrade
                        flag={successPopup}
                        close={this.onHandleCloseSuccess}
                    />
                }

                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

coachSubscription.propTypes = {
    getActiveList: PropTypes.func.isRequired,
    deleteSubscription: PropTypes.func.isRequired,
    getExpireList: PropTypes.func.isRequired,
    upgradeList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    subInfo: state.subscriptionInfo,
});

export default connect(mapStateToProps, { getActiveList, deleteSubscription, getExpireList, upgradeList })(coachSubscription);

import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import ProudSponsors from "../company/components/common/proudSponsors";
import Shop from "../company/components/common/shop";
import Preloader from '../../components/preloader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import commonCalls from "../../businesslogic/commonCalls";
import { getEventDash } from '../../saga/actions/common/api.action';
import { getStatesForUserEvent } from '../../saga/actions/common/events.action';
import moment from "moment";
import { imagesArr } from '../../assets/images';
import { checkLoginValidation } from '../../saga/helpers/authToken';
import Geosuggest from 'react-geosuggest';
import DatePicker from "react-datepicker";
import * as data from "../../businesslogic/country.json";
import { getSumOfRegisterTeam, getAvailableSport } from "../../businesslogic/content";
import ReactPaginate from "react-paginate";

class coachMyEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventList: [],
            sendEvents: {
                type: 1,
                page: 0,
                limit: 6,
                eventTeamType: "",
                startDate: "",
                endDate: "",
                state: "",
                myEvent: 2
            },
            loader: false,
            sendStateReq: {
                type: 1,
                eventTeamType: "",
                startDate: "",
                endDate: "",
                state: "",
                // companies: localStorage.getItem("cid"),
            },
            stateList: [],

            // pagination variable diclaration
            offset: 0,
            perPage: 6,
            currentPage: "",
            pageCount: ""
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setLoader(true);
        if (localStorage.getItem("company")) {
            this.props.getEventDash(this.state.sendEvents);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.productInfo &&
            nextProps.productInfo.getEventDashReq &&
            nextProps.productInfo.getEventDashData
        ) {
            nextProps.productInfo.getEventDashReq = false;
            console.log("nextProps.productInfo.getEventDashData:--", nextProps.productInfo.getEventDashData);
            if (nextProps.productInfo.getEventDashData.flag) {
                this.setState({
                    eventList: nextProps.productInfo.getEventDashData.result.docs,
                    pageCount: Math.ceil(nextProps.productInfo.getEventDashData.result.total / this.state.perPage),
                }, () => {
                    nextProps.productInfo.getEventDashData = null;
                    if (this.state.sendEvents.type === 1 && this.state.stateList.length === 0) {
                        // this.setStateList();
                        this.props.getStatesForUserEvent(this.state.sendStateReq);
                    } else if (this.state.sendEvents.type === 2 && this.state.stateList.length === 0) {
                        // this.setStateList();
                        this.props.getStatesForUserEvent(this.state.sendStateReq);
                    } else {
                        this.setLoader(false);
                    }
                })
            } else {
                nextProps.productInfo.getEventDashData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.stateUserEventReq &&
            nextProps.eventsInfo.stateUserEventData
        ) {
            nextProps.eventsInfo.stateUserEventReq = false;
            if (nextProps.eventsInfo.stateUserEventData.flag) {
                this.setState({
                    stateList: nextProps.eventsInfo.stateUserEventData.result.data
                }, () => {
                    nextProps.eventsInfo.stateUserEventData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.stateUserEventData.message);
                nextProps.eventsInfo.stateUserEventData = null;
                this.setLoader(false);
            }
        }
    }

    setStateList = () => {
        data.default.countries.map((a) => {
            if (a.country == "United States") {
                this.setState({
                    stateList: a.states,
                }, () => {
                    this.setLoader(false);
                });
            }
        });
    }

    redirectDetails = (data) => {
        var isloggedIn = checkLoginValidation();
        if (isloggedIn) {
            if (this.state.sendEvents.type === 1) {
                this.props.history.push(`/event-detail/${data._id}`);
            } else {
                if (localStorage.getItem("company") === "pwb") {
                    this.props.history.push(`/pwb-event-info/${data._id}`);
                } else {
                    this.props.history.push(`/pws-event-info/${data._id}`);
                }
            }
        } else {
            this.props.history.push("/login");
        }
    }

    renderEvents = () => {
        return (
            this.state.eventList.length !== 0 ?
                this.state.eventList.map((list, i) => {
                    const location = list.location && list.location.streetAddress.split(",");
                    return (
                        <div className="event_box w-33" key={i} onClick={() => this.redirectDetails(list)}>
                            <div className="event_card">
                                <div class="event_card_img">
                                    <img src={list.eventImages && list.eventImages.filePath} alt="event" />
                                    {

                                        (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                        <span className="event_card_address">
                                            {list.location &&
                                                list.location.city ?
                                                list.location.city :
                                                list.location.streetAddress ?
                                                    location[0] :
                                                    list.location.stateRegion}
                                        </span>
                                    }
                                </div>
                                <div class="event_card_desc">
                                    <div class="event_card_logo">
                                        <img src={list.eventLogo && list.eventLogo.filePath ? list.eventLogo.filePath : imagesArr.default_user} alt="event logo" />
                                    </div>
                                    <div class="event_card_titles">
                                        <h3>{list.eventTitle}</h3>
                                        <div class="event_card_dates">
                                            <p class="date_icon">{moment(list.startDate).format("DD-MMM-YYYY")} - {moment(list.endDate).format("DD-MMM-YYYY")}</p>
                                            <p class="team_icons">{list.feesByGroups.length ? getSumOfRegisterTeam(list.feesByGroups) : 0} {list.eventTeamType === 1 ? 'Teams Registered' : 'Players Registered'}</p>
                                            <p class="team_icons">{list.feesByGroups.length ? getAvailableSport(list.feesByGroups) : 0} Available Spots</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center w-100">No events found.</p>
        )
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getEvents = (type) => {
        this.setState({
            sendEvents: {
                ...this.state.sendEvents,
                type: type,
                page: 0,
                limit: 6,
                eventTeamType: "",
                startDate: "",
                endDate: "",
                state: "",
                myEvent: 2
            },
            sendStateReq: {
                ...this.state.sendStateReq,
                type: type,
            },
            stateList: [],
            currentPage: 0,
        }, () => {
            this.setLoader(true);
            this.props.getEventDash(this.state.sendEvents);
        })
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    onHandleTeamType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendEvents: {
                ...this.state.sendEvents,
                [name]: this.state.sendEvents.eventTeamType === 1 ? "" : JSON.parse(value),
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.setLoader(true);
            this.props.getEventDash(this.state.sendEvents);
        });
    }

    onHandleShowcaseType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendEvents: {
                ...this.state.sendEvents,
                [name]: this.state.sendEvents.eventTeamType === 2 ? "" : JSON.parse(value),
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.setLoader(true);
            this.props.getEventDash(this.state.sendEvents);
        });
    }

    onChangeDate = (value, name) => {
        if (name === "startDate") {
            this.setState({
                sendEvents: {
                    ...this.state.sendEvents,
                    [name]: value,
                    endDate: "",
                    page: 0,
                    limit: 6,
                },
                currentPage: 0
            }, () => {
                if (this.state.sendEvents.endDate) {
                    this.setLoader(true);
                    this.props.getEventDash(this.state.sendEvents);
                }
            });
        } else {
            this.setState({
                sendEvents: {
                    ...this.state.sendEvents,
                    [name]: value,
                    page: 0,
                    limit: 6,
                },
                currentPage: 0
            }, () => {
                if (this.state.sendEvents.startDate) {
                    this.setLoader(true);
                    this.props.getEventDash(this.state.sendEvents);
                }
            });
        }
    }

    handleSelectGeoSuggest = (place) => {
        if (!place) {
            this.setState({
                sendEvents: {
                    ...this.state.sendEvents,
                    state: "",
                },
                state: ""
            }, () => {
                this.setLoader(true);
                this.props.getEventDash(this.state.sendEvents);
            })
        } else {
            var componentForm = {
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name',
            };

            for (var i = 0; i < place.gmaps.address_components.length; i++) {
                var addressType = place.gmaps.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.gmaps.address_components[i][componentForm[addressType]];
                    if (val)
                        componentForm[addressType] = val
                }
            }

            var location = {
                streetAddress: place.gmaps.formatted_address,
                city: componentForm.locality !== 'long_name' ? componentForm.locality : 'Surat',
                stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? componentForm.administrative_area_level_1 : 'GJ',
                postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : 394105,
                latitude: place.location.lat || 42.2538048,
                longitude: place.location.lng || -83.5735806
            }
            this.setState({
                sendEvents: {
                    ...this.state.sendEvents,
                    state: location.stateRegion,
                },
                state: location.streetAddress
            }, () => {
                this.setLoader(true);
                this.props.getEventDash(this.state.sendEvents);
            })
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendEvents: {
                ...this.state.sendEvents,
                [name]: value,
                page: 0,
                limit: 6,
            },
            currentPage: 0
        }, () => {
            this.setLoader(true);
            this.props.getEventDash(this.state.sendEvents);
        });
    }

    increment = () => {
        this.setLoader(true);
    }

    decrement = () => {
        this.setLoader(false);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState((prevState) => ({
            ...prevState,
            sendEvents: {
                ...prevState.sendEvents,
                page: selectedPage + 1,
                limit: Number(this.state.perPage),
            },
            currentPage: selectedPage,
            offset: offset,
        }), () => {
            this.setLoader(true);
            this.props.getEventDash(this.state.sendEvents);
        });
    };

    render() {
        const { loader, stateList } = this.state;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <div>
                <Header />
                <div className="event-main">
                    <div className="upcomingevent_section">
                        <div className="container">
                            <div className="event-group">
                                <a className={`btn trans ${this.state.sendEvents.type === 1 ? "active" : ""}`} onClick={() => this.getEvents(1)}>Upcoming Events</a>
                                <a className={`btn trans ${this.state.sendEvents.type === 2 ? "active" : ""}`} onClick={() => this.getEvents(2)}>Past Events</a>
                            </div>
                            <div className="upcoming_events_title">
                                <h1 className="section_title">{this.state.sendEvents.type === 1 ? "Upcoming Events " : "Past Events"}</h1>
                            </div>
                            <form>
                                <div className="event-filter">
                                    {
                                        this.state.sendEvents.type === 1 &&
                                        <>

                                            <div className="form-group ">
                                                <div className="custom-checkbox">
                                                    <label>
                                                        <input type="checkbox" name="eventTeamType" value={1} checked={this.state.sendEvents.eventTeamType === 1 ? true : false} onChange={(e) => this.onHandleTeamType(e)} />Tournaments
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="custom-checkbox">
                                                    <label>
                                                        <input type="checkbox" name="eventTeamType" value={2} checked={this.state.sendEvents.eventTeamType === 2 ? true : false} onChange={(e) => this.onHandleShowcaseType(e)} />{localStorage.getItem("company") === "pws" ? "Camps" : "Showcase"}
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="form-group ">
                                        <div className="bc_relative">
                                            {
                                                this.state.sendEvents.type === 1 ?
                                                    <DatePicker
                                                        ref={(c) => (this._calendar = c)}
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                            </div>
                                                        )}
                                                        selected={this.state.sendEvents.startDate}
                                                        minDate={moment().subtract(0, 'days').toDate()}
                                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                        onChange={(e) => this.onChangeDate(e, "startDate")}
                                                        placeholderText="Start date"
                                                    />
                                                    :
                                                    <DatePicker
                                                        ref={(c) => (this._calendar = c)}
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                            </div>
                                                        )}
                                                        selected={this.state.sendEvents.startDate}
                                                        maxDate={moment().subtract(1, 'days').toDate()}
                                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                        onChange={(e) => this.onChangeDate(e, "startDate")}
                                                        placeholderText="Start date"
                                                    />
                                            }
                                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <div className="bc_relative">
                                            {
                                                this.state.sendEvents.type === 1 ?
                                                    <DatePicker
                                                        ref={(c) => (this._calendar = c)}
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                            </div>
                                                        )}
                                                        selected={this.state.sendEvents.endDate}
                                                        minDate={moment(this.state.sendEvents.startDate).subtract(-1, 'days').toDate()}
                                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                        onChange={(e) => this.onChangeDate(e, "endDate")}
                                                        placeholderText="End date"
                                                    />
                                                    :
                                                    <DatePicker
                                                        ref={(c) => (this._calendar = c)}
                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                            </div>
                                                        )}
                                                        selected={this.state.sendEvents.endDate}
                                                        minDate={moment(this.state.sendEvents.startDate).subtract(0, 'days').toDate()}
                                                        maxDate={moment().subtract(1, 'days').toDate()}
                                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                        onChange={(e) => this.onChangeDate(e, "endDate")}
                                                        placeholderText="End date"
                                                    />
                                            }
                                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" />
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <select className="custom-select" name="state" value={this.state.sendEvents.state} onChange={(e) => this.onHandleChange(e)}>
                                            <option value="">State</option>
                                            {
                                                stateList &&
                                                stateList.map((item) => {
                                                    return (
                                                        <option value={item.stateRegion} style={{ color: item.isUpcoming ? 'black' : '#6C7B95' }}>{item.state}</option>
                                                        // <option value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* <Geosuggest
                                            ref={el => this._geoSuggest = el}
                                            onSuggestSelect={this.handleSelectGeoSuggest}
                                            placeholder="State"
                                            initialValue={this.state.state}
                                        /> */}
                                    </div>
                                </div>
                            </form>
                            <div className="upcoming_events ">
                                <div className="row">
                                    {this.renderEvents()}
                                </div>
                                {
                                    this.state.eventList.length !== 0 &&
                                    <div className="pagenation-search bg-grey">
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"champion-pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            forcePage={this.state.currentPage}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="upcomingevent_section">
                        <Shop cType="pwb" />
                    </div>
                    <ProudSponsors increment={this.increment} decrement={this.decrement} />
                </div>
                <Footer />
                <Preloader flag={loader} />
            </div>
        )
    }
}

coachMyEvent.propTypes = {
    getEventDash: PropTypes.func.isRequired,
    getStatesForUserEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    productInfo: state.api,
    eventsInfo: state.eventsInfo,
});

export default connect(mapStateToProps, { getEventDash, getStatesForUserEvent })(coachMyEvent);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { mobileCode, sendVerifyCode } from '../../saga/actions/authantication/verify.action';
import { phoneVerifyForLinkedPlayer } from '../../saga/actions/views/coach/profile/linkedPlayerInfo.action';
import { phoneVerifyByFan } from '../../saga/actions/views/fan/setting/personalInfo.action';
import { phoneVerifyForParent } from '../../saga/actions/views/player/profile/parentInfo.action'
let timerOn = true;
class phoneVerify extends Component {
    constructor(props) {

        super(props);
        this.state = {
            otp: "",
            token: "",
            mobile: "",
            second: "",
            timeOut: false
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        if (this.props.flag) {
            document.body.classList.add('modal-open');
        }
    }

    componentWillMount() {
        this.timer(30, false);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.verifyData) {
            if (nextProps.verifyData.rendReq &&
                nextProps.verifyData.resendData
            ) {
                console.log("nextProps:----", nextProps.verifyData);
                nextProps.verifyData.rendReq = false;
                this.commonCall.successMessage(nextProps.verifyData.resendData.message);
                nextProps.verifyData.resendData = null;
            }

            if (nextProps.verifyData.mresendReq &&
                nextProps.verifyData.mresendData
            ) {
                console.log("nextProps:----", nextProps.verifyData);
                nextProps.verifyData.mresendReq = false;
                this.commonCall.successMessage(nextProps.verifyData.mresendData.message);
                nextProps.verifyData.mresendData = null;
                this.props.load(false);
            }

            if (nextProps.verifyData.mobileSendReq &&
                nextProps.verifyData.mobileSendData
            ) {
                console.log("nextProps:----", nextProps.verifyData);
                nextProps.verifyData.mobileSendReq = false;
                if (nextProps.verifyData.mobileSendData.flag) {
                    this.commonCall.successMessage(nextProps.verifyData.mobileSendData.message);
                    nextProps.verifyData.mobileSendData = null;
                    this.props.load(false);
                    this.props.close();
                } else {
                    this.commonCall.errorMessage(nextProps.verifyData.mobileSendData.message);
                    nextProps.verifyData.mobileSendData = null;
                }

            }

            if (nextProps.verifyLinkedPlayer.verifyPhone) {
                console.log("nextProps:----", nextProps.verifyLinkedPlayer);
                if (nextProps.verifyLinkedPlayer.verifyPhone.flag) {
                    this.commonCall.successMessage(nextProps.verifyLinkedPlayer.verifyPhone.message);
                    nextProps.verifyLinkedPlayer.verifyPhone = null;
                    this.props.load(false);
                    this.props.close();
                } else {
                    this.commonCall.errorMessage(nextProps.verifyLinkedPlayer.verifyPhone.message);
                    nextProps.verifyLinkedPlayer.verifyPhone = null;
                }
            }

            if (nextProps.verifyfan.verifyFanPhone) {
                console.log("nextProps:----", nextProps.verifyfan);
                if (nextProps.verifyfan.verifyFanPhone.flag) {
                    this.commonCall.successMessage(nextProps.verifyfan.verifyFanPhone.message);
                    nextProps.verifyfan.verifyFanPhone = null;
                    this.props.load(false);
                    this.props.close();
                } else {
                    this.commonCall.errorMessage(nextProps.verifyfan.verifyFanPhone.message);
                    nextProps.verifyfan.verifyFanPhone = null;
                }
            }

            if (nextProps.verifyParent.verifyPhoneForParent) {
                console.log("nextProps:----", nextProps.verifyParent);
                if (nextProps.verifyParent.verifyPhoneForParent.flag) {
                    this.commonCall.successMessage(nextProps.verifyParent.verifyPhoneForParent.message);
                    nextProps.verifyParent.verifyPhoneForParent = null;
                    this.props.load(false);
                    this.props.close();
                } else {
                    this.commonCall.errorMessage(nextProps.verifyParent.verifyPhoneForParent.message);
                    nextProps.verifyParent.verifyPhoneForParent = null;
                }   
            }
        }
    }

    handleChange = otp => this.setState({ otp });

    timer = (remaining, apicall) => {
        if (apicall) {
            let data = {
                verifyToken: this.props.token,
                user_id: this.props.uid
            }
            this.props.load(true);
            this.props.mobileCode(data, this.props.type);
        }

        var s = remaining % 60;
        s = s < 10 ? '0' + s : s;

        this.setState({
            second: s,
            timeOut: false
        })

        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(() => {
                this.remainTime(remaining)
            }, 1000);
            return;
        }
        // Do timeout stuff here
        this.setState({ timeOut: true })
    }

    remainTime = (time) => {
        this.timer(time, false)
    }

    sendOTP = () => {
        if (this.validator.allValid()) {
            let data = {
                verifyToken: this.props.token,
                otp: this.state.otp
            }
            console.log("data:--", data);
            this.props.load(true);
            if(this.props.for === "linkedPlayer") return this.props.phoneVerifyForLinkedPlayer(data, this.props.type);
            if(this.props.for === "fan") return this.props.phoneVerifyByFan(data, this.props.type);
            if(this.props.for === "parent") return this.props.phoneVerifyForParent(data, this.props.type);
            else return this.props.sendVerifyCode(data, this.props.type);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { flag, close } = this.props;

        return (
            <>
                <section className={`common-modal ${flag ? "show" : ""}`}>  
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>Verification OTP</h3>
                                <div className="modal_close" onClick={close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                            </div>
                            <div className="modal-body main-login" style={{ marginTop: '0px' }}>
                                <div className="common-login">
                                    <div className="common-dec" style={{ width: '100%' }}>
                                        <div className="text-center">
                                            {(this.props.phone && this.props.email && this.props.phone !== "+1") ? <p>Please type the verification code send to <span>{this.props.phone}</span> or <span>{this.props.email}</span></p>
                                                : !(this.props.phone && this.props.phone !== "+1") && <p>Please type the verification code send to <span>{this.props.email}</span></p>}
                                        </div>
                                        <form className="verification_code">
                                            <div className="form-group">
                                                <OtpInput
                                                    value={this.state.otp}
                                                    onChange={this.handleChange}
                                                    numInputs={4}
                                                    inputStyle="form-control"
                                                    isInputNum={true}
                                                />
                                            </div>
                                            <span className="validMsg">{this.validator.message('otp', this.state.otp, 'required')}</span>
                                            <div className="resend">
                                                {
                                                    this.state.timeOut ?
                                                        <Link className="resend" onClick={() => this.timer(30, true)}>Resend OTP</Link>
                                                        :
                                                        <p>Resend OTP in {this.state.second}</p>
                                                }
                                            </div>
                                            <button type="button" className="btn w-100 text-center" onClick={this.sendOTP} disabled={this.state.timeOut ? true : false}>Verify</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

phoneVerify.propTypes = {
    mobileCode: PropTypes.func.isRequired,
    sendVerifyCode: PropTypes.func.isRequired,
    phoneVerifyForLinkedPlayer: PropTypes.func.isRequired,
    phoneVerifyByFan: PropTypes.func.isRequired,
    phoneVerifyForParent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
    verifyLinkedPlayer: state.linkedPlayerInfo,
    verifyfan: state.fanBasicInfo,
    verifyParent: state.parentInfo,
});

export default connect(mapStateToProps, { mobileCode, sendVerifyCode, phoneVerifyForLinkedPlayer, phoneVerifyByFan, phoneVerifyForParent })(phoneVerify);

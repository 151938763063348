import axios from "axios";
import { API_Url, EVENT_API_Url, Auth_Coach_Key, Auth_Player_Key, Auth_Company_Key, Auth_Key, checkIsValidToken, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidCompanyToken, Auth_Fan_Key, checkIsValidFanToken, checkIsValidCommiToken, Auth_Commi_Key } from "../../helpers/authToken";
import {
  ZIPCODE_SUCCESS,
  ZIPCODE_ERROR,
  FACILITYLOGO1_SUCCESS,
  FILEUPLOAD_SUCCESS,
  FILEUPLOAD_ERROR,
  COVER_FILEUPLOAD_SUCCESS,
  COVER_FILEUPLOAD_ERROR,

  SAVE_COVER_PHOTO_REQUEST,
  SAVE_COVER_PHOTO_SUCCESS,
  SAVE_COVER_PHOTO_ERROR,

  GET_COUNTRYCODE_SUCCESS,
  GET_COUNTRYCODE_ERROR,

  INSTA_REQUEST,
  INSTA_SUCCESS,
  INSTA_ERROR,

  GET_PRODUCT_LIST_ERROR,
  GET_PRODUCT_LIST_SUCCESS,

  ACCEPT_REQUEST,
  ACCEPT_SUCCESS,
  ACCEPT_ERROR,

  NOTIFY_DECLINE_REQUEST,
  NOTIFY_DECLINE_SUCCESS,
  NOTIFY_DECLINE_ERROR,

  ACCEPT_REQ_PLAYER_REQUEST,
  ACCEPT_REQ_PLAYER_SUCCESS,
  ACCEPT_REQ_PLAYER_ERROR,

  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_ERROR,

  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,

  NOTIFY_COUNT_REQUEST,
  NOTIFY_COUNT_SUCCESS,
  NOTIFY_COUNT_ERROR,

  NOTIFY_ACCEPT_REQUEST,
  NOTIFY_ACCEPT_SUCCESS,
  NOTIFY_ACCEPT_ERROR,

  READ_NOTIFY_REQUEST,
  READ_NOTIFY_SUCCESS,
  READ_NOTIFY_ERROR,

  DELETE_NOTIFY_REQUEST,
  DELETE_NOTIFY_SUCCESS,
  DELETE_NOTIFY_ERROR,

  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
  GET_ADS_ERROR,

  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,

  GET_PW_COMPANY_REQUEST,
  GET_PW_COMPANY_SUCCESS,
  GET_PW_COMPANY_ERROR,

  COM_EVENT_DASH_REQUEST,
  COM_EVENT_DASH_SUCCESS,
  COM_EVENT_DASH_ERROR,

  NOTIFY_ACCEPT_BY_HEADCOACH_ERROR,
  NOTIFY_ACCEPT_BY_HEADCOACH_REQUEST,
  NOTIFY_ACCEPT_BY_HEADCOACH_SUCCESS,

  FETCH_SPOTLIGHT_REQUEST,
  FETCH_SPOTLIGHT_SUCCESS,
  FETCH_SPOTLIGHT_ERROR,

  FETCH_SPONSORS_REQUEST,
  FETCH_SPONSORS_SUCCESS,
  FETCH_SPONSORS_ERROR,

  NOTIFY_ACCEPT_LINKED_PLAYER_REQUEST,
  NOTIFY_ACCEPT_LINKED_PLAYER_SUCCESS,
  NOTIFY_ACCEPT_LINKED_PLAYER_ERROR,

  NOTIFY_ACCEPT_FAN_REQUEST,
  NOTIFY_ACCEPT_FAN_SUCCESS,
  NOTIFY_ACCEPT_FAN_ERROR,

  GET_MYTEAM_LIST_REQUEST,
  GET_MYTEAM_LIST_SUCCESS,
  GET_MYTEAM_LIST_ERROR,

  CHANGE_MYTEAM_LIST_REQUEST,
  CHANGE_MYTEAM_LIST_SUCCESS,
  CHANGE_MYTEAM_LIST_ERROR,

  GET_VIDEO_DETAILS_REQUEST,
  GET_VIDEO_DETAILS_SUCCESS,
  GET_VIDEO_DETAILS_ERROR,

  GET_PERKS_INFO_REQUEST,
  GET_PERKS_INFO_SUCCESS,
  GET_PERKS_INFO_ERROR,

  GET_PW_PACK_REQUEST,
  GET_PW_PACK_SUCCESS,
  GET_PW_PACK_ERROR,

  LEAVE_EVENT_REQUEST,
  LEAVE_EVENT_SUCCESS,
  LEAVE_EVENT_ERROR,

  PENDING_PAYMENT_DASH_SUCCESS,
  PENDING_PAYMENT_DASH_REQUEST,
  PENDING_PAYMENT_DASH_ERROR,

  PENDING_COUPEN_CODE_SUCCESS,
  PENDING_COUPEN_CODE_ERROR,
  PENDING_COUPEN_CODE_REQUEST,

  APPLE_TEST_SUCCESS,
  APPLE_TEST_ERROR,
  APPLE_TEST_REQUEST,
} from "../../constants/common/api.const";
import histrory from '../../../History';

export const getAllStates = (type) => (dispatch) => {
  let mode = localStorage.getItem("webglToken");
  let setHeader;
  if (mode) {
    setHeader = {
      'x-auth-token': Auth_Company_Key,
      'Accept': 'application/json'
    }
    checkIsValidCompanyToken(histrory);
  } else {
    setHeader = {
      'Accept': 'application/json'
    }
  }
  dispatch({ type: `GET_${type}_STATES_DETAILS_REQUEST` })
  axios({
    method: 'GET',
    url: `${EVENT_API_Url}${mode ? "pwUser/ranking/state/list" : "pwUser/woAuth/ranking/state/list"}`,
    headers: setHeader
  }).then(video => {
    dispatch({
      type: `GET_${type}_STATES_DETAILS_SUCCESS`,
      payload: video.data
    })
  }).catch((error) => {
    if (error.response) {
      dispatch({ type: `GET_${type}_STATES_DETAILS_ERROR`, error: error.response.data.message })
    }
  });
}

export const getPWPackages = (data, packType) => (dispatch) => {
  let mode = localStorage.getItem("webglToken");
  let setHeader;
  if (mode) {
    setHeader = {
      'x-auth-token': Auth_Company_Key,
      'Accept': 'application/json'
    }
    checkIsValidCompanyToken(histrory);
  } else {
    setHeader = {
      'Accept': 'application/json'
    }
  }
  dispatch({ type: GET_PW_PACK_REQUEST })
  axios({
    method: 'POST',
    url: `${EVENT_API_Url}${packType === "PW" ?
      mode ? "pwProduct/package/list/web" : "pwProduct/woAuth/package/list/web" :
      mode ? "pwProduct/DCstast/package/list/web" : "pwProduct/woAuth/DCstast/package/list/web"}`,
    data: packType === "PW" ? data : { companyId: localStorage.getItem("cid") },
    headers: setHeader
  }).then(pack => {
    console.log("pack:----", pack);
    dispatch({
      type: GET_PW_PACK_SUCCESS,
      payload: pack.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: GET_PW_PACK_ERROR, error: error.response.data })
  });
};

export const getVideoDetail = () => (dispatch) => {
  let mode = localStorage.getItem("webglToken");
  let setHeader;
  if (mode) {
    setHeader = {
      'x-auth-token': Auth_Company_Key,
      'Accept': 'application/json'
    }
    checkIsValidCompanyToken(histrory);
  } else {
    setHeader = {
      'Accept': 'application/json'
    }
  }
  dispatch({ type: GET_VIDEO_DETAILS_REQUEST })
  axios({
    method: 'GET',
    url: `${EVENT_API_Url}${mode ? "pwUser/videos" : "pwUser/woAuth/videos"}`,
    headers: setHeader
  }).then(video => {
    dispatch({
      type: GET_VIDEO_DETAILS_SUCCESS,
      payload: video.data
    })
  }).catch((error) => {
    if (error.response) {
      dispatch({ type: GET_VIDEO_DETAILS_ERROR, error: error.response.data.message })
    }
  });
}

export const getPerksLink = (userType) => (dispatch) => {
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : (userType === "Player") ? checkIsValidPlayerToken(histrory) : userType === "BC Commissioner" ? checkIsValidCommiToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: GET_PERKS_INFO_REQUEST })
  axios({
    method: "GET",
    url: `${API_Url}bc/user/perks`,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : (userType === "Player") ? Auth_Player_Key : userType === "BC Commissioner" ? '' : Auth_Fan_Key,
      Accept: "application/json",
    },
  }).then((perksLink) => {
    dispatch({
      type: GET_PERKS_INFO_SUCCESS,
      payload: perksLink.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: GET_PERKS_INFO_ERROR, error: error.response.data.message });
  });
};

export const changeMyTeamData = (data, userType) => (dispatch) => {
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "BC Commissioner" ? checkIsValidCommiToken(histrory) : checkIsValidPlayerToken(histrory);
  dispatch({ type: CHANGE_MYTEAM_LIST_REQUEST })
  axios({
    method: 'POST',
    url: `${API_Url}bc/user/chanege/default/parentTeam`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "BC Commissioner" ? Auth_Commi_Key : Auth_Player_Key,
      'Accept': 'application/json',
    }
  }).then(acceptPl => {
    console.log("acceptPl:----", acceptPl);
    dispatch({
      type: CHANGE_MYTEAM_LIST_SUCCESS,
      payload: acceptPl.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: CHANGE_MYTEAM_LIST_ERROR, error: error.response.data })
  });
};

export const getTeamList = (userType) => (dispatch) => {
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "BC Commissioner" ? checkIsValidCommiToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidToken(histrory);
  dispatch({ type: GET_MYTEAM_LIST_REQUEST })
  axios({
    method: 'POST',
    url: `${API_Url}bc/user/getmyparentteam/list`,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "BC Commissioner" ? Auth_Commi_Key : Auth_Player_Key,
      'Accept': 'application/json',
    }
  }).then(acceptPl => {
    console.log("acceptPl:----", acceptPl);
    dispatch({
      type: GET_MYTEAM_LIST_SUCCESS,
      payload: acceptPl.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: GET_MYTEAM_LIST_ERROR, error: error.response.data })
  });
};

export const getZipcodeInfo = (zipcode) => (dispatch) => {
  // dispatch({ type: CODE_REQUEST })
  axios({
    method: "GET",
    url: `${API_Url}setting/territory/zipcode/${Number(zipcode)}`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: ZIPCODE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({ type: ZIPCODE_ERROR, error: error.response.data.message });
    });
};

export const postCoverFileUpload = (file, type, userType) => (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    method: "POST",
    url: `${API_Url}file/upload?documentType=${type}`,
    data: formData,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: COVER_FILEUPLOAD_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: COVER_FILEUPLOAD_ERROR,
          error: error.response.data.message,
        });
    });
};

export const postFileUpload = (file, type) => (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    method: "POST",
    url: `${API_Url}${type === 24 ? "file/upload/attachment?documentType=" : "file/upload?documentType="}${type}`,
    data: formData,
  })
    .then((res) => {
      dispatch({
        type: FILEUPLOAD_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: FILEUPLOAD_ERROR,
          error: error.response.data.message,
        });
    });
};

export const saveCoverPhoto = (data, userType) => dispatch => {
  console.log("data:----action:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: SAVE_COVER_PHOTO_REQUEST })
  axios({
    method: 'POST',
    url: `${API_Url}bc/user/update/profilewall`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "Player" ? Auth_Player_Key : Auth_Fan_Key,
      'Accept': 'application/json',
    }
  }).then(cover => {
    console.log("cover:----", cover);
    dispatch({
      type: SAVE_COVER_PHOTO_SUCCESS,
      payload: cover.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: SAVE_COVER_PHOTO_ERROR, error: error.response.data })
  });
};

export const instagramLogin = (data) => dispatch => {

  let formData = new FormData();
  formData.append('client_id', data.client_id);
  formData.append('client_secret', data.client_secret);
  formData.append('grant_type', data.grant_type);
  formData.append('redirect_uri', data.redirect_uri);
  formData.append('code', data.code);

  dispatch({ type: INSTA_REQUEST })
  axios({
    method: 'POST',
    url: `https://api.instagram.com/oauth/access_token`,
    data: formData,
    headers: {
      'Accept': 'application/json'
    }
  }).then(insta => {
    console.log("insta:---- ", insta);
    dispatch({
      type: INSTA_SUCCESS,
      payload: insta
    })
  }).catch((error) => {
    if (error.response && error.response.status === 404)
      dispatch({ type: INSTA_ERROR, error: error.response.data.message })
  });
};

export const getCountryCode = () => (dispatch) => {
  return axios({
    method: "GET",
    url: `${API_Url}setting/app`,
    headers: {
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: GET_COUNTRYCODE_SUCCESS,
        payload: res.data.result.countryCode.settingValue,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: GET_COUNTRYCODE_ERROR,
          error: error.response.data.message,
        });
    });
}

export const getProductList = (data, userType, token) => (dispatch) => {
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);

  return axios({
    method: "POST",
    url: `${API_Url}bc/subscription/allocation/product/list`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      console.log("productList============", res);
      dispatch({
        type: GET_PRODUCT_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: GET_PRODUCT_LIST_ERROR,
          error: error.response.data.message,
        });
    });
}

export const acceptRequest = (data) => dispatch => {
  console.log("data:----action:---", data);
  dispatch({ type: ACCEPT_REQUEST })
  axios({
    method: 'POST',
    url: `${API_Url}bc/coach/player/accept/request`,
    data: data,
    headers: {
      'Accept': 'application/json',
    }
  }).then(accept => {
    console.log("accept:----", accept);
    dispatch({
      type: ACCEPT_SUCCESS,
      payload: accept.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: ACCEPT_ERROR, error: error.response.data })
  });
};

export const acceptRequestPlayer = (data) => dispatch => {
  console.log("data:----action:---", data);
  dispatch({ type: ACCEPT_REQ_PLAYER_REQUEST })
  axios({
    method: 'POST',
    url: `${API_Url}bc/player/roster/accept/request`,
    data: data,
    headers: {
      'Accept': 'application/json',
    }
  }).then(acceptPl => {
    console.log("acceptPl:----", acceptPl);
    dispatch({
      type: ACCEPT_REQ_PLAYER_SUCCESS,
      payload: acceptPl.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: ACCEPT_REQ_PLAYER_ERROR, error: error.response.data })
  });
};

export const sendContact = (data) => dispatch => {
  console.log("data:----action:---", data);
  dispatch({ type: CONTACT_REQUEST })
  axios({
    method: 'POST',
    url: `${API_Url}bc/registration/contactus`,
    data: data,
    headers: {
      'Accept': 'application/json',
    }
  }).then(contact => {
    console.log("contact:----", contact);
    dispatch({
      type: CONTACT_SUCCESS,
      payload: contact.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: CONTACT_ERROR, error: error.response.data })
  });
};

export const getNotification = (data, userType) => (dispatch) => {
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: NOTIFICATION_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/user/notification/list`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "Player" ? Auth_Player_Key : Auth_Fan_Key,
      Accept: "application/json",
    },
  }).then((notification) => {
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFICATION_ERROR, error: error.response.data.message });
  });
};

export const getNotificationCount = (userType) => (dispatch) => {
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: NOTIFY_COUNT_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/user/notification/unread/count`,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "Player" ? Auth_Player_Key : Auth_Fan_Key,
      Accept: "application/json",
    },
  }).then((count) => {
    dispatch({
      type: NOTIFY_COUNT_SUCCESS,
      payload: count.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFY_COUNT_ERROR, error: error.response.data.message });
  });
};

export const acceptNotify = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
  dispatch({ type: NOTIFY_ACCEPT_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/player/accept/request`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
      Accept: "application/json",
    },
  }).then((notification) => {
    dispatch({
      type: NOTIFY_ACCEPT_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFY_ACCEPT_ERROR, error: error.response.data.message });
  });
};

export const declineNotify = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
  dispatch({ type: NOTIFY_DECLINE_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/user/notification/decline`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
      Accept: "application/json",
    },
  }).then((notification) => {
    dispatch({
      type: NOTIFY_DECLINE_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFY_DECLINE_ERROR, error: error.response.data.message });
  });
};

export const acceptNotifyByHeadCoach = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
  dispatch({ type: NOTIFY_ACCEPT_BY_HEADCOACH_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/registration/process/coach/accept/headcoach`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
      Accept: "application/json",
    },
  }).then((notification) => {
    dispatch({
      type: NOTIFY_ACCEPT_BY_HEADCOACH_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFY_ACCEPT_BY_HEADCOACH_ERROR, error: error.response.data.message });
  });
};

export const acceptNotifyLinkedPlayer = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
  dispatch({ type: NOTIFY_ACCEPT_LINKED_PLAYER_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/fan/player/linked/accept`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
      Accept: "application/json",
    },
  }).then((notification) => {
    dispatch({
      type: NOTIFY_ACCEPT_LINKED_PLAYER_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFY_ACCEPT_LINKED_PLAYER_ERROR, error: error.response.data.message });
  });
};

export const acceptNotifyFan = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: NOTIFY_ACCEPT_FAN_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/fan/both/accept`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Fan_Key,
      Accept: "application/json",
    },
  }).then((notification) => {
    dispatch({
      type: NOTIFY_ACCEPT_FAN_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: NOTIFY_ACCEPT_FAN_ERROR, error: error.response.data.message });
  });
};

export const readNotify = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
  dispatch({ type: READ_NOTIFY_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/user/notification/read`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
      Accept: "application/json",
    },
  }).then((notification) => {

    dispatch({
      type: READ_NOTIFY_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: READ_NOTIFY_ERROR, error: error.response.data.message });
  });
};

export const deleteNotify = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: DELETE_NOTIFY_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/user/notification/delete`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "Player" ? Auth_Player_Key : Auth_Fan_Key,
      Accept: "application/json",
    },
  }).then((notification) => {

    dispatch({
      type: DELETE_NOTIFY_SUCCESS,
      payload: notification.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: DELETE_NOTIFY_ERROR, error: error.response.data.message });
  });
};

export const getAdverticement = (data, userType) => (dispatch) => {
  console.log("data:---", data);
  (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : userType === "BC Commissioner" ? checkIsValidCommiToken(histrory) : checkIsValidFanToken(histrory);
  dispatch({ type: GET_ADS_REQUEST })
  axios({
    method: "POST",
    url: `${API_Url}bc/advertise/list`,
    data: data,
    headers: {
      'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : userType === "Player" ? Auth_Player_Key : userType === "BC Commissioner" ? Auth_Commi_Key : Auth_Fan_Key,
      Accept: "application/json",
    },
  }).then((ads) => {
    dispatch({
      type: GET_ADS_SUCCESS,
      payload: ads.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: GET_ADS_ERROR, error: error.response.data.message });
  });
};

export const getCompanyInfo = (name) => (dispatch) => {
  dispatch({ type: GET_COMPANY_REQUEST })
  axios({
    method: "GET",
    url: `${EVENT_API_Url}Company/${name}`,
    headers: {
      Accept: "application/json",
    },
  }).then((cname) => {
    console.log("cname:---", cname);
    dispatch({
      type: GET_COMPANY_SUCCESS,
      payload: cname.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: GET_COMPANY_ERROR, error: error.response.data.message });
  });
};

export const getCompanyPWInfo = (name, type) => (dispatch) => {
  dispatch({ type: `GET_${type}_COMPANY_REQUEST` })
  axios({
    method: "GET",
    url: `${EVENT_API_Url}Company/${name}`,
    headers: {
      Accept: "application/json",
    },
  }).then((cname) => {
    console.log("cname:---", cname);
    dispatch({
      type: `GET_${type}_COMPANY_SUCCESS`,
      payload: cname.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: `GET_${type}_COMPANY_ERROR`, error: error.response.data.message });
  });
};

export const getEventDash = (data, token) => (dispatch) => {
  console.log("data:---", data);
  checkIsValidCompanyToken(histrory);
  dispatch({ type: COM_EVENT_DASH_REQUEST })
  axios({
    method: "POST",
    url: `${EVENT_API_Url}event/v2/list/profile`,
    data: data,
    headers: {
      'x-auth-token': token ? token : Auth_Company_Key,
      Accept: "application/json",
    },
  }).then((getevent) => {
    console.log("getevent:---", getevent);
    dispatch({
      type: COM_EVENT_DASH_SUCCESS,
      payload: getevent.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: COM_EVENT_DASH_ERROR, error: error.response.data.message });
  });
};

export const leaveEvent = (data) => (dispatch) => {
  console.log("data:---", data);
  checkIsValidCompanyToken(histrory);
  dispatch({ type: LEAVE_EVENT_REQUEST })
  axios({
    method: "POST",
    url: `${EVENT_API_Url}event/withdraw`,
    data: { registrationId: data },
    headers: {
      'x-auth-token': Auth_Company_Key,
      Accept: "application/json",
    },
  }).then((leave) => {
    console.log("leave:---", leave);
    dispatch({
      type: LEAVE_EVENT_SUCCESS,
      payload: leave.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: LEAVE_EVENT_ERROR, error: error.response.data.message });
  });
};

export const getspotlight = (data) => dispatch => {
  console.log("data:----action:---", data);
  dispatch({ type: FETCH_SPOTLIGHT_REQUEST })
  axios({
    method: 'POST',
    url: `${EVENT_API_Url}event-company-advertise/company-spotlight/list`,
    data: data,
    headers: {
      'Accept': 'application/json',
    }
  }).then(spotlight => {
    console.log(`spotlight`, spotlight)
    dispatch({
      type: FETCH_SPOTLIGHT_SUCCESS,
      payload: spotlight.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: FETCH_SPOTLIGHT_ERROR, error: error.response.data })
  });
};

export const getSponsorList = (data) => (dispatch) => {
  console.log("data:---", data);
  dispatch({ type: FETCH_SPONSORS_REQUEST })
  axios({
    method: "GET",
    url: `${EVENT_API_Url}advertise/sponsor/user/list`,
    headers: {
      Accept: "application/json",
    },
  }).then((getsponsor) => {
    dispatch({
      type: FETCH_SPONSORS_SUCCESS,
      payload: getsponsor.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: FETCH_SPONSORS_ERROR, error: error.response.data.message });
  });
};

export const pendingPaymentDash = (data) => (dispatch) => {
  console.log("data:---", data);
  dispatch({ type: PENDING_PAYMENT_DASH_REQUEST })
  axios({
    method: "POST",
    url: `${EVENT_API_Url}event/v2/pending/payment`,
    data: data,
    headers: {
      'x-auth-token': Auth_Company_Key,
      Accept: "application/json",
    },
  }).then((getsponsor) => {
    dispatch({
      type: PENDING_PAYMENT_DASH_SUCCESS,
      payload: getsponsor.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: PENDING_PAYMENT_DASH_ERROR, error: error.response.data.message });
  });
};


export const pendingCoupenCode = (data) => (dispatch) => {
  console.log("data:---", data);
  dispatch({ type: PENDING_COUPEN_CODE_REQUEST })
  axios({
    method: "POST",
    url: `${EVENT_API_Url}event/v2/pending/payment/couponcode`,
    data: data,
    headers: {
      'x-auth-token': Auth_Company_Key,
      Accept: "application/json",
    },
  }).then((res) => {
    dispatch({
      type: PENDING_COUPEN_CODE_SUCCESS,
      payload: res.data,
    });
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: PENDING_COUPEN_CODE_ERROR, error: error.response.data.message });
  });
};

export const getAppleTest = (data) => dispatch => {
  dispatch({ type: APPLE_TEST_REQUEST })
  axios({
    method: 'POST',
    url: `${EVENT_API_Url}user/apple/testing`,
    data: data,
    headers: {
      'Accept': 'application/json',
    }
  }).then(res => {
    dispatch({
      type: APPLE_TEST_SUCCESS,
      payload: res.data
    })
  }).catch((error) => {
    if (error.response && error.response.status === 401)
      dispatch({ type: APPLE_TEST_ERROR, error: error.response.data })
  });
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { getMainBoard, getFields } from '../../../../saga/actions/views/company/pwRanking/leaderboard/mainBoard.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import { Link, withRouter } from "react-router-dom";
import moment from 'moment';
import { mainBoardFilter } from '../../../../businesslogic/content';
import PwLock from "../../../company/components/common/pwLock";
import Purchase from '../../../popup/purchaseProspectwire';

class mainBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leaderBoardList: [],
            getAllFields: [],
            purchase: false,
            forUpdate: false
        }

        this.commonCall = new commonCalls();
    }

    componentWillMount = () => {
        this.setState(() => ({
            forUpdate: !this.state.forUpdate
        }))
    }

    componentDidMount = () => {
        this.getFirstLoad();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.mainBoardInfo &&
            nextProps.mainBoardInfo.getMainBoardReq &&
            nextProps.mainBoardInfo.getMainBoardData
        ) {
            nextProps.mainBoardInfo.getMainBoardReq = false;
            if (nextProps.mainBoardInfo.getMainBoardData.flag) {
                if (this.props.mainBoardInfo.sendReq.field !== "") {
                    console.log("getAllFields:---", this.props.mainBoardInfo.getBackFields);
                    this.setState({
                        getAllFields: this.props.mainBoardInfo.getBackFields
                    })
                }
                this.setState({
                    leaderBoardList: nextProps.mainBoardInfo.getMainBoardData.result.data
                }, () => {
                    nextProps.mainBoardInfo.getMainBoardData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.mainBoardInfo.getMainBoardData.message);
                nextProps.mainBoardInfo.getMainBoardData = null;
                this.props.decrement();
            }
        }

        if (nextProps.mainBoardInfo &&
            nextProps.mainBoardInfo.getFieldsReq &&
            nextProps.mainBoardInfo.getFieldsData
        ) {
            nextProps.mainBoardInfo.getFieldsReq = false;
            if (nextProps.mainBoardInfo.getFieldsData.flag) {
                let result = nextProps.mainBoardInfo.getFieldsData.result.data;
                this.props.mainBoardInfo.sendReq.field = result[0];
                nextProps.mainBoardInfo.getBackFields = result;
                this.setState({
                    getAllFields: result,
                }, () => {
                    nextProps.mainBoardInfo.getFieldsData = null;
                    this.props.getMainBoard(this.props.mainBoardInfo.sendReq);
                })
            } else {
                this.commonCall.errorMessage(nextProps.mainBoardInfo.getFieldsData.message);
                nextProps.mainBoardInfo.getFieldsData = null;
                this.props.decrement();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mainBoardInfo.resetPage !== this.props.mainBoardInfo.resetPage) {
            this.getFirstLoad();
            this.setState({ forUpdate: !this.state.forUpdate });
        }
    }

    getFirstLoad = () => {
        if (this.props.mainBoardInfo.sendReq.field !== "") {
            this.props.increment();
            this.props.getMainBoard(this.props.mainBoardInfo.sendReq);
        } else {
            this.props.increment();
            this.props.getFields(this.props.mainBoardInfo.sendFieldReq);
        }
    }

    getLeaderBoardList = () => {
        this.props.increment();
        this.props.getFields(this.props.mainBoardInfo.sendFieldReq);
    }

    recapRedirect = (data) => {
        localStorage.setItem("recapInfo", JSON.stringify(data.event));
        window.open(`/pwb-event-info/${data.event._id}`);
    }

    returnLabel = (item) => {
        return (
            item === "10YardDash" ?
                "10 Yard" : item === "60YardDash" ?
                    "60 Yard" : item === "broadJump" ?
                        "Broad Jump" : item === "verticalJump" ?
                            "Vertical Jump" : item === "chinUps" ?
                                "Chin-Up" : item === "pullUps" ?
                                    "Pull-Up" : item === "sitUps" ?
                                        "Sit-Ups" : item === "pushUps" ?
                                            "Push-Ups" : item === "plankTest" ?
                                                "Planks" : item === "handGripStrenght" ?
                                                    "Hand Grip Strength" : item === "catcherPopTime" ?
                                                        "Catcher Pop Time" : item === "homeToFirst" ?
                                                            "Home to 1B" : item === "homeToSecond" ?
                                                                "Home to 2B" : item === "homeToHome" ?
                                                                    "Home to Home" : item === "bullpenStrikePercentage" ?
                                                                        "Bullpen Strike Percentage" : item === "infieldVelocity" ?
                                                                            "Infield Velo" : item === "fastballVelocity" ?
                                                                                "Fastball Velo" : item === "OutfieldVelocity" ?
                                                                                    "Outfield Velo" : item === "maxVelocity" ?
                                                                                        "Max Velocity" : item === "avgVelocity" ?
                                                                                            "Avg Velocity" : item === "maxSpinRate" ?
                                                                                                "Max Spin Rate" : item === "avgSpinRate" ?
                                                                                                    "Avg Spin Rate" : item === "spinEfficency" ?
                                                                                                        "Spin Efficency" : item === "avgTilt" ?
                                                                                                            "Avg Tilt" : item === "maxExitVelocity" ?
                                                                                                                "Max Exit Velocity" : item === "avgExitVelocity" ?
                                                                                                                    "Avg Exit Velocity" : item === "avgLaunch" ?
                                                                                                                        "Avg Launch" : item === "maxDistance" ?
                                                                                                                            "Max Distance" : item === "batSpeed" ?
                                                                                                                                "Bat Speed" : item === "attackAngle" ?
                                                                                                                                    "Attack Angle" : item === "exitVelocity" ?
                                                                                                                                        "Exit Velocity" : item === "catcherVelocity" ?
                                                                                                                                            "C Velo" : item
        )
    }

    returnValues = (item) => {
        return (
            this.props.mainBoardInfo.sendReq.field === "10YardDash" ?
                item["10 Yard"] ? item["10 Yard"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "60YardDash" ?
                    item["60 Yard"] ? item["60 Yard"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Max Velocity" ?
                        item["Max Velocity"] ? item["Max Velocity"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Avg Velocity" ?
                            item["Avg Velocity"] ? item["Avg Velocity"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Max Spin Rate" ?
                                item["Max Spin Rate"] ? item["Max Spin Rate"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Avg Spin Rate" ?
                                    item["Avg Spin Rate"] ? item["Avg Spin Rate"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Spin Efficiency" ?
                                        item["Spin Efficiency"] ? item["Spin Efficiency"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Avg Tilt" ?
                                            item["Avg Tilt"] ? item["Avg Tilt"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Max Exit Velocity" ?
                                                item["Max Exit Velocity"] ? item["Max Exit Velocity"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Avg Exit Velocity" ?
                                                    item["Avg Exit Velocity"] ? item["Avg Exit Velocity"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Avg Launch Angle" ?
                                                        item["Avg Launch Angle"] ? item["Avg Launch Angle"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Max Distance" ?
                                                            item["Max Distance"] ? item["Max Distance"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Bat Speed" ?
                                                                item["Bat Speed"] ? item["Bat Speed"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Attack Angle" ?
                                                                    item["Attack Angle"] ? item["Attack Angle"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "ERA" ?
                                                                        item["ERA"] ? item["ERA"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "K" ?
                                                                            item["K"] ? item["K"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "WHIP" ?
                                                                                item["WHIP"] ? item["WHIP"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "Saves" ?
                                                                                    item["Saves"] ? item["Saves"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "R" ?
                                                                                        item["R"] ? item["R"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "RBI" ?
                                                                                            item["RBI"] ? item["RBI"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "SB" ?
                                                                                                item["SB"] ? item["SB"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "OBP" ?
                                                                                                    item["OBP"] ? item["OBP"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "HR" ?
                                                                                                        item["HR"] ? item["HR"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "1B" ?
                                                                                                            item["1B"] ? item["1B"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "2B" ?
                                                                                                                item["2B"] ? item["2B"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "BA" ?
                                                                                                                    item["BA"] ? item["BA"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "5-10-5" ?
                                                                                                                        item["5-10-5"] ? item["5-10-5"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "broadJump" ?
                                                                                                                            item["Broad Jump"] ? item["Broad Jump"] : "" : this.props.mainBoardInfo.sendReq.field === "verticalJump" ?
                                                                                                                                item["Vertical Jump"] ? item["Vertical Jump"] : "" : this.props.mainBoardInfo.sendReq.field === "chinUps" ?
                                                                                                                                    item["Chin-Up"] ? item["Chin-Up"] : "" : this.props.mainBoardInfo.sendReq.field === "pullUps" ?
                                                                                                                                        item["Pull-Up"] ? item["Pull-Up"] : "" : this.props.mainBoardInfo.sendReq.field === "sitUps" ?
                                                                                                                                            item["Sit-Up"] ? item["Sit-Up"] : "" : this.props.mainBoardInfo.sendReq.field === "pushUps" ?
                                                                                                                                                item["Push-Up"] ? item["Push-Up"] : "" : this.props.mainBoardInfo.sendReq.field === "plankTest" ?
                                                                                                                                                    item["Planks"] ? item["Planks"] : "" : this.props.mainBoardInfo.sendReq.field === "handGripStrenght" ?
                                                                                                                                                        item["Grip Strength"] ? item["Grip Strength"] : "" : this.props.mainBoardInfo.sendReq.field === "catcherPopTime" ?
                                                                                                                                                            item["Catcher Pop Time"] ? item["Catcher Pop Time"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "homeToFirst" ?
                                                                                                                                                                item["Home to 1B"] ? item["Home to 1B"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "homeToSecond" ?
                                                                                                                                                                    item["Home to 2B"] ? item["Home to 2B"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "homeToHome" ?
                                                                                                                                                                        item["Home to Home"] ? item["Home to Home"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "maxVelocity" ?
                                                                                                                                                                            item["maxVelocity"] ? item["maxVelocity"] : "" : this.props.mainBoardInfo.sendReq.field === "avgVelocity" ?
                                                                                                                                                                                item["avgVelocity"] ? item["avgVelocity"] : "" : this.props.mainBoardInfo.sendReq.field === "maxSpinRate" ?
                                                                                                                                                                                    item["maxSpinRate"] ? item["maxSpinRate"] : "" : this.props.mainBoardInfo.sendReq.field === "avgSpinRate" ?
                                                                                                                                                                                        item["avgSpinRate"] ? item["avgSpinRate"] : "" : this.props.mainBoardInfo.sendReq.field === "spinEfficency" ?
                                                                                                                                                                                            item["spinEfficency"] ? item["spinEfficency"] : "" : this.props.mainBoardInfo.sendReq.field === "avgTilt" ?
                                                                                                                                                                                                item["avgTilt"] ? item["avgTilt"] : "" : this.props.mainBoardInfo.sendReq.field === "maxExitVelocity" ?
                                                                                                                                                                                                    item["maxExitVelocity"] ? item["maxExitVelocity"] : "" : this.props.mainBoardInfo.sendReq.field === "avgExitVelocity" ?
                                                                                                                                                                                                        item["avgExitVelocity"] ? item["avgExitVelocity"] : "" : this.props.mainBoardInfo.sendReq.field === "avgLaunch" ?
                                                                                                                                                                                                            item["avgLaunch"] ? item["avgLaunch"] : "" : this.props.mainBoardInfo.sendReq.field === "maxDistance" ?
                                                                                                                                                                                                                item["maxDistance"] ? item["maxDistance"] : "" : this.props.mainBoardInfo.sendReq.field === "batSpeed" ?
                                                                                                                                                                                                                    item["batSpeed"] ? item["batSpeed"] : "" : this.props.mainBoardInfo.sendReq.field === "attackAngle" ?
                                                                                                                                                                                                                        item["attackAngle"] ? item["attackAngle"] : "" : this.props.mainBoardInfo.sendReq.field === "exitVelocity" ?
                                                                                                                                                                                                                            item["exitVelocity"] ? item["exitVelocity"] + " seconds" : "" : this.props.mainBoardInfo.sendReq.field === "infieldVelocity" ?
                                                                                                                                                                                                                                item["infieldVelocity"] ? item["infieldVelocity"] : "" : this.props.mainBoardInfo.sendReq.field === "OutfieldVelocity" ?
                                                                                                                                                                                                                                    item["OutfieldVelocity"] ? item["OutfieldVelocity"] : "" : this.props.mainBoardInfo.sendReq.field === "catcherVelocity" ?
                                                                                                                                                                                                                                        item["C Velo"] ? item["C Velo"] : "" : item["Bullpen Strike Percentage"] ? item["Bullpen Strike Percentage"] : ""
        )
    }

    redirectProfile = (item) => {
        console.log("profileInfo:--", this.props.profileInfo);
        localStorage.setItem("userID", item.athlete._id);
        this.props.profileInfo.sendSummaryReq = {
            tab: "summary",
            id: "",
            eventTeamType: 0,
            sortOn: {
                eventstartDate: "",
                eventendDate: "",
                awardstartDate: "",
                awardendDate: "",
                gallerystartDate: "",
                galleryendDate: ""
            },
            seasonType: 1,
            pitching: "basic",
            batting: "basic",
            filterOn: "",
            field: "",
            metricSubType: "Speed",
            metricType: 1,
            companyId: localStorage.getItem("cid"),
            statsType: "Hitting"
        };
        this.props.profileInfo.tabs = 0;
        this.props.profileInfo.backupTab = 0;
        this.props.profileInfo.statsType = 0;
        this.props.profileInfo.eventPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: item.athlete._id,
        };
        this.props.profileInfo.mediaPageReq = {
            id: "",
            page: 1,
            limit: 10,
            companyId: localStorage.getItem("cid"),
            userId: item.athlete._id,
        };
        this.props.profileInfo.eventCurrentPage = 0;
        this.props.history.push(`/pw-profile-info/${item.athlete._id}`)
    }

    renderLeaderBoard = () => {
        return (
            this.state.leaderBoardList &&
                this.state.leaderBoardList.length !== 0 ?
                this.state.leaderBoardList.map((item, i) => {
                    if (i < 5) {
                        return (
                            <div className="mainBoard-item" key={i}>
                                <div className="Board-info">
                                    <div className="mainboard-img">
                                        <div className="img">
                                            <img src={item?.athlete?.profileUrl ? item.athlete.profileUrl : imagesArr.default_user} alt="player_1" />
                                        </div>
                                        <div className="name">
                                            <p onClick={() => this.redirectProfile(item)}>{item?.athlete?.fname + " " + item?.athlete?.lname}</p>
                                            {this.returnValues(item) ? <div className="time">{this.returnValues(item)}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="mainboard-info">
                                        {/* <p>
                                       seconds
                                    </p> */}
                                        {/* <p className={`${localStorage.getItem('company') === "pwb" ? "date-icon" : "date-icon light-date-icon"}`}>Collected on {moment(item.awardDate).format('MM/DD/YYYY')}</p> */}
                                        <p className={`${localStorage.getItem('company') === "pwb" ? "date-icon" : "date-icon light-date-icon"}`}>Collected on {moment(item?.event?.endDate).format('MM/DD/YYYY')}</p>
                                        <p className={`${localStorage.getItem('company') === "pwb" ? "location-icon" : "location-icon light-location-icon"}`} onClick={() => (item.event.companies.name !== "USA Travel Ball" && item.event.companies.name !== "GOBTournament") ? this.recapRedirect(item) : ""}>{item?.event?.eventTitle ? item.event.eventTitle : ""}</p>
                                    </div>
                                </div>
                                <span className="winner-img"><img src={item?.award?.awardType === 1 ? imagesArr.winner_icon1 : item?.award?.awardType === 2 ? imagesArr.winner_icon2 : imagesArr.winner_icon3} alt="winner_icon" /></span>
                            </div >
                        )
                    }
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    onHandleGradeYears = (value) => {
        this.props.mainBoardInfo.sendReq = {
            ...this.props.mainBoardInfo.sendReq,
            gradeYear: value,
        }
        this.getLeaderBoardList();
    }

    onHandleMtype = (value) => {
        this.props.mainBoardInfo.sendFieldReq = {
            ...this.props.mainBoardInfo.sendFieldReq,
            metricType: value,
            metricSubType: value === 1 ? "Speed" : value === 2 ? "Pitching" : "Pitching"
        };
        this.props.mainBoardInfo.sendReq = {
            ...this.props.mainBoardInfo.sendReq,
            metricType: value,
            metricSubType: value === 1 ? "Speed" : value === 2 ? "Pitching" : "Pitching"
        }
        this.getLeaderBoardList();
    }

    onHandleMstype = (value) => {
        this.props.mainBoardInfo.sendFieldReq.metricSubType = value;
        this.props.mainBoardInfo.sendReq.metricSubType = value;
        this.getLeaderBoardList();
    }

    onHandleField = (value) => {
        this.props.mainBoardInfo.sendReq.field = value;
        this.props.increment();
        this.props.getMainBoard(this.props.mainBoardInfo.sendReq);
    }

    goto = (path) => {
        this.props.history.push(path);
    }

    purchaseProspectwire = () => {
        this.setState({
            purchase: true,
        })
    }

    handleClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    render() {
        const { getAllFields, purchase } = this.state;
        const { sendReq } = this.props.mainBoardInfo;
        const years = Array.from(new Array(5), (val, index) => ((new Date()).getFullYear() + index));
        return (
            <>
                <div className="mainBoard">
                    <div className="mainboard-group">
                        <div className="list-group-scroll pt-15">
                            <label>Grad Year</label>
                            <select className="custom-select" name="gradeYear" value={sendReq.gradeYear} onChange={(e) => this.onHandleGradeYears(e.target.value)}>
                                {
                                    years &&
                                    years.map((y) => {
                                        return (
                                            <option value={y} className="list-group-item">{y}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="list-group-scroll pt-15 mb-20">
                            <ul className="list-group mb-20">
                                <li className={`list-group-item ${sendReq.metricType === 1 ? "active" : ""}`} onClick={() => this.onHandleMtype(1)}>Performance Data</li>
                                <li className={`list-group-item ${sendReq.metricType === 2 ? "active" : ""}`} onClick={() => this.onHandleMtype(2)}>Hardware Data</li>
                                {/* <li className={`list-group-item ${sendReq.metricType === 3 ? "active" : ""}`} onClick={() => this.onHandleMtype(3)}>Game Stats</li> */}
                            </ul>
                        </div>
                        <div className="list-group-scroll">
                            <ul className="list-group mb-20">
                                {
                                    mainBoardFilter &&
                                    mainBoardFilter[sendReq.metricType].map((item) => {
                                        return (
                                            <li className={`list-group-item ${sendReq.metricSubType === item ? "active" : ""}`} onClick={() => this.onHandleMstype(item)}>{item}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="list-group-scroll">
                            <ul className="list-group mb-20">
                                {
                                    getAllFields &&
                                    getAllFields.map((item) => {
                                        return (
                                            <li className={`list-group-item ${sendReq.field === item ? "active" : ""} ${getAllFields.length === 1 ? "list-group-item-one" : ""}`} onClick={() => this.onHandleField(item)}>
                                                {this.returnLabel(item)}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="mainBoard-list">
                        {this.renderLeaderBoard()}
                        {/* {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || (sendReq.field !== "5-10-5"
                            && sendReq.field !== "verticalJump" && sendReq.field !== "handGripStrenght" && sendReq.field !== "Fastball Spin rate"
                            && sendReq.field !== "Curveball spin rate" && sendReq.field !== "Changeup spin Rate" && sendReq.field !== "Changeup spin Rate") ?
                            ""
                            :
                            <>
                                <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                            </>
                        } */}
                    </div>
                    <div className="see-more-btn">
                        <Link className="btn">
                            {
                                // localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" ?
                                //     <div onClick={() => this.purchaseProspectwire()}>
                                //         <span>See More</span>
                                //         <span className="lock-icon bg-grey">
                                //             <img src={imagesArr.lock_icon} alt="lock_icon" />
                                //         </span>
                                //     </div>
                                //     :
                                    <div onClick={() => this.goto('/pw-player-ranking')}>
                                        <span>See More</span>
                                    </div>
                            }
                        </Link>
                    </div>
                </div>
                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

mainBoard.propTypes = {
    getMainBoard: PropTypes.func.isRequired,
    getFields: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    mainBoardInfo: state.mainBoardInfo,
    profileInfo: state.profileSummaryInfo,
});

export default withRouter(connect(mapStateToProps, { getMainBoard, getFields })(mainBoard));

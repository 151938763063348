export const GET_COACH_REQUEST = 'GET_COACH_REQUEST';
export const GET_COACH_SUCCESS = 'GET_COACH_SUCCESS';
export const GET_COACH_ERROR = 'GET_COACH_ERROR';

export const ADD_NEW_COACH_REQUEST = ' ADD_NEW_COACH_REQUEST';
export const ADD_NEW_COACH_SUCCESS = ' ADD_NEW_COACH_SUCCESS';
export const ADD_NEW_COACH_ERROR = ' ADD_NEW_COACH_ERROR';

export const DEL_NEW_COACH_REQUEST = ' DEL_NEW_COACH_REQUEST';
export const DEL_NEW_COACH_SUCCESS = ' DEL_NEW_COACH_SUCCESS';
export const DEL_NEW_COACH_ERROR = ' DEL_NEW_COACH_ERROR';

export const ASSIGN_HEAD_COACH_REQUEST = ' ASSIGN_HEAD_COACH_REQUEST';
export const ASSIGN_HEAD_COACH_SUCCESS = ' ASSIGN_HEAD_COACH_SUCCESS';
export const ASSIGN_HEAD_COACH_ERROR = ' ASSIGN_HEAD_COACH_ERROR';

export const OTHER_TEAM_COACH_REQUEST = ' OTHER_TEAM_COACH_REQUEST';
export const OTHER_TEAM_COACH_SUCCESS = ' OTHER_TEAM_COACH_SUCCESS';
export const OTHER_TEAM_COACH_ERROR = ' OTHER_TEAM_COACH_ERROR';

export const GET_ROSTER_LIST_COACH_REQUEST = ' GET_ROSTER_LIST_COACH_REQUEST';
export const GET_ROSTER_LIST_COACH_SUCCESS = ' GET_ROSTER_LIST_COACH_SUCCESS';
export const GET_ROSTER_LIST_COACH_ERROR = ' GET_ROSTER_LIST_COACH_ERROR';

export const SAVE_ROSTER_LIST_COACH_REQUEST = ' SAVE_ROSTER_LIST_COACH_REQUEST';
export const SAVE_ROSTER_LIST_COACH_SUCCESS = ' SAVE_ROSTER_LIST_COACH_SUCCESS';
export const SAVE_ROSTER_LIST_COACH_ERROR = ' SAVE_ROSTER_LIST_COACH_ERROR';

export const DEL_ROSTER_LIST_COACH_REQUEST = ' DEL_ROSTER_LIST_COACH_REQUEST';
export const DEL_ROSTER_LIST_COACH_SUCCESS = ' DEL_ROSTER_LIST_COACH_SUCCESS';
export const DEL_ROSTER_LIST_COACH_ERROR = ' DEL_ROSTER_LIST_COACH_ERROR';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Bracket as BracketGenerator } from "react-tournament-bracket";
import Preloader from "../../../../components/preloader";
import { bracketFilter } from '../../../../saga/actions/views/company/pwb_pws/recapInfo.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import { imagesArr } from '../../../../assets/images';
import CustomBracket from '../../pwb/event/tournamentEventInfo/CustomBracket';

class mobileBracketInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            bracketData: [],
            sendReq: {
                eventId: "",
                ageGroup: "",
                eventDivisionId: '',
                bracket: "",
                divisionId: ""
            }
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {

        const searchParams = new URLSearchParams(document.location.search);

        const eventId = searchParams.get('eventId');
        const ageGroup = searchParams.get('ageGroup');
        const eventDivisionId = searchParams.get('eventDivisionId');
        const bracket = searchParams.get('bracket');
        const divisionId = searchParams.get('divisionId');

        if (eventId) {

            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    eventId,
                    ageGroup,
                    eventDivisionId,
                    bracket,
                    divisionId
                }
            }, () => {
                this.filterBracket();
            })

        }

        if (window?.embedded_svc?.settings?.displayHelpButton) window.embedded_svc.settings.displayHelpButton = false;
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.pwbBracketReq &&
            nextProps.eventRecapInfo.pwbBracketData
        ) {
            console.log("pwbBracketData:--", nextProps.eventRecapInfo.pwbBracketData);
            nextProps.eventRecapInfo.pwbBracketReq = false;
            if (nextProps.eventRecapInfo.pwbBracketData.flag) {
                this.setState({
                    bracketData: nextProps.eventRecapInfo.pwbBracketData.result
                }, () => {
                    setTimeout(() => {
                        document.querySelectorAll("g > rect").forEach(i => {
                            i.style.fill = "white"
                            i.style.strokeWidth = "1"
                            i.style.strokeLinejoin = "round"
                            i.style.stroke = "rgb(150, 207, 4)"
                            return i;
                        })

                        document.querySelectorAll("g > path").forEach(i => {
                            i.style.stroke = "rgb(150, 207, 4)"
                            return i;
                        })
                    }, 5);
                    nextProps.eventRecapInfo.pwbBracketData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.pwbBracketData.message);
                nextProps.eventRecapInfo.pwbBracketData = null;
                this.setLoader(false);
            }
        }
    }

    filterBracket = () => {
        this.setLoader(true);
        this.props.bracketFilter(this.state.sendReq, 'PWB');
    }

    getBackType = (type) => {
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                bracket: type
            }
        }, () => {
            this.filterBracket();
        })
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, sendReq, bracketData } = this.state;
        return (
            <>
                <div className="bracket-info">
                    <div className="bracket_chart_section">
                        <div className="container">

                            <h1 class="section_title">Bracket</h1>

                            {this.state.sendReq.bracket && this.state.bracketData?.isOldEvent && <div className="bracket-tab-btn">
                                <div class="select-type text-center">
                                    <button type="button" className={`btn trans ${sendReq.bracket === "Gold" ? "active" : ""}`} onClick={() =>  this.getBackType("Gold")}>Gold</button>
                                    <button type="button" className={`btn trans ${sendReq.bracket === "Silver" ? "active" : ""}`} onClick={() => this.getBackType("Silver")}>Silver</button>
                                    <button type="button" className={`btn trans ${sendReq.bracket === "Bronze" ? "active" : ""}`} onClick={() =>  this.getBackType("Bronze")}>Bronze</button>
                                </div>
                            </div>}

                            {this.state.sendReq.bracket && this.state.bracketData?.isOldEvent && bracketData?.bracket?.length === 0 && bracketData?.qsf?.length === 0 && <p className='text-center' style={{ marginTop: "20px" }}>No data found</p>}

                            {this.state.sendReq.bracket && this.state.bracketData?.isOldEvent ? <div className="bracket_chart">
                                <div className='bracket_chart_info'>
                                    <div className='full_bracket_chart text-center'>
                                        {
                                            bracketData.bracket &&
                                                bracketData?.bracket[0]?.key?.length === 0 ?
                                                bracketData?.qsf && bracketData?.qsf?.map(i => i.key.map((match) => {
                                                    return (
                                                        <BracketGenerator
                                                            game={match}
                                                            hoveredTeamId={null}
                                                            lineInfo={{ yOffset: -10, separation: 10, homeVisitorSpread: 15 }}
                                                            gameDimensions={{ width: 300, height: 130 }}
                                                            roundSeparatorWidth={50}
                                                            svgPadding={0}
                                                            styles={
                                                                {
                                                                    backgroundColor: 'white',
                                                                    scoreBackground: 'white',
                                                                    winningScoreBackground: 'white',
                                                                    teamNameStyle: { fill: '#8392A5', fontSize: 12, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                                                                    teamScoreStyle: { fill: '#8392A5', fontSize: 12, fontFamily: "Helvetica Medium" },
                                                                    gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                                                                    gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                                                                    teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                                                                }
                                                            }
                                                        />
                                                    )
                                                }))
                                                :
                                                bracketData.bracket &&
                                                    bracketData.bracket.length !== 0 ?
                                                    bracketData.bracket[0].key.map((match) => {
                                                        return (
                                                            <BracketGenerator
                                                                game={match}
                                                                hoveredTeamId={null}
                                                                lineInfo={{ yOffset: -10, separation: 10, homeVisitorSpread: 15 }}
                                                                gameDimensions={{ width: 300, height: 130 }}
                                                                roundSeparatorWidth={50}
                                                                svgPadding={0}
                                                                styles={
                                                                    {
                                                                        backgroundColor: 'white',
                                                                        scoreBackground: 'white',
                                                                        winningScoreBackground: 'white',
                                                                        teamNameStyle: { fill: '#8392A5', fontSize: 12, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                                                                        teamScoreStyle: { fill: '#8392A5', fontSize: 12, fontFamily: "Helvetica Medium" },
                                                                        gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                                                                        gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                                                                        teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                                                                    }
                                                                }
                                                            />
                                                        )
                                                    })
                                                    :
                                                    ""
                                        }
                                    </div>
                                    {
                                        bracketData?.gameStatus === 7 && bracketData.teamName &&
                                        <div className='winner_team'>
                                            <img src={bracketData.teamLogo ? bracketData.teamLogo : imagesArr.default_user} />
                                            <p>{bracketData.teamName}</p>
                                            <span>Champion</span>
                                        </div>
                                    }
                                </div>
                            </div>

                                :

                                <CustomBracket allRecapDescription={{ gameList: this.state?.bracketData?.gameList || [], scheduleLive: this.state?.bracketData?.scheduleLive }} />

                            }


                        </div>
                    </div>
                </div>
                <Preloader flag={loader} />
            </>
        )
    }
}

mobileBracketInfo.propTypes = {
    bracketFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventRecapInfo: state.recapInfo,
});

export default connect(mapStateToProps, { bracketFilter })(mobileBracketInfo);
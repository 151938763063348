import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from "../../assets/images";
import { getPWPackages } from "../../saga/actions/common/api.action";
import Preloader from '../../components/preloader';
import commonCalls from "../../businesslogic/commonCalls";

class PurchaseProspectwire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            webPack: {
                productCode: "PROSPECTWIRE",
                companyId: localStorage.getItem("cid")
            },
            loader: false,
            packArr: []
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        this.props.getPWPackages(this.state.webPack, this.props.type);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonData &&
            nextProps.commonData.getPWPackReq &&
            nextProps.commonData.getPWPackData
        ) {
            console.log("nextProps.commonData.getPWPackData:------", nextProps.commonData.getPWPackData);
            nextProps.commonData.getPWPackReq = false;
            if (nextProps.commonData.getPWPackData.flag) {
                if (nextProps.commonData.getPWPackData.result.data.length !== 0) {
                    this.setState({
                        packArr: nextProps.commonData.getPWPackData.result.data
                    }, () => {
                        nextProps.commonData.getPWPackData = null;
                        this.setLoader(false);
                    })
                }
            } else {
                nextProps.commonData.getPWPackData = null;
                this.setLoader(false);
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    nextPayment = (data) => {
        let nextData = {
            packid: data._id,
            plans: data.plans[0],
            title: data.code,
            trialPeriod: data.trialPeriod
        }
        console.log("nextData:----", nextData);
        let role = localStorage.getItem("coachToken") !== null ? "Coach" : localStorage.getItem("playerToken") !== null ? "Player" : localStorage.getItem("fanToken") !== null ? "Fan" : "";
        localStorage.setItem("popupUrl", "/pw-leaderboard");
        localStorage.setItem("rolPay", role);
        if (nextData.title === "DC-STATS-PLAYER-PREMIUM") {
            localStorage.setItem("dcStats", "true");
        }
        this.commonCall.setStorage("payPlans", nextData, flag => {
            if (flag) {
                document.body.classList.remove("modal-open");
                if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken") && !localStorage.getItem("fanToken")) {
                    this.props.history.push('/signup');
                } else if (localStorage.getItem("coachToken")) {
                    this.props.history.push('/coach-payment');
                } else if (localStorage.getItem("fanToken")) {
                    this.props.history.push('/fan-payment');
                } else {
                    this.props.history.push('/player-payment');
                }
            }
        });
    }

    render() {
        const { flag } = this.props;
        const { loader, packArr } = this.state;
        if (flag) {
            document.body.classList.add("modal-open");
        }
        return (
            <>
                <div>
                    <section className={`common-modal purchase-prospectwire ${flag ? "show" : ""}`}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal_close" onClick={this.props.close}>
                                        <img src={imagesArr.gray_modal_close} alt="modal_close" />
                                    </div>
                                </div>
                                <div className="modal-body ">
                                    <div className="popup-content">
                                        {
                                            packArr.length !== 0 ?
                                                packArr.map((item, i) => {
                                                    let pname = (item.code === "PROSPECTWIRE-PLAYER-PW-PREMIUM" || item.code === "PROSPECTWIRES-PLAYER-PW-PREMIUM") ?
                                                        "PW Premium" : (item.code === "PROSPECTWIRE-PLAYER-PW-BASIC" || item.code === "PROSPECTWIRES-PLAYER-PW-BASIC") ?
                                                            "PW Basic" : (item.code === "DC-STATS-PLAYER-PREMIUM" || item.code === "DCS-STATS-PLAYER-Premium") ?
                                                                "DC Stats" : "PW Scout";
                                                    console.log("pname:----", pname);
                                                    return (
                                                        <div className="price-box" key={i}>
                                                            <div className="price-info">
                                                                <h5>{pname}</h5>
                                                                <ul>
                                                                    {
                                                                        pname === "PW Premium" ?
                                                                            <>
                                                                                <li>PW Basic and...</li>
                                                                                <li>Access to Scouting reports and Event Recaps</li>
                                                                                <li>Access to Scouting Photos and Videos</li>
                                                                                <li>Access to all PW Profiles</li>
                                                                                <li>National/State Rankings</li>
                                                                            </>
                                                                            :
                                                                            pname === "PW Basic" ?
                                                                                <>
                                                                                    <li>DK Integration</li>
                                                                                    <li>Unlimited Media Uploads</li>
                                                                                    <li>Access to all PW Tournament Stats, Performance, & Strength Scores</li>
                                                                                    <li>Access to PW Progression Data</li>
                                                                                </>
                                                                                :
                                                                                pname === "PW Scout" ?
                                                                                    <>
                                                                                        <li>PW Premium and…</li>
                                                                                        <li>Player Contact Information</li>
                                                                                        <li>Advanced Player Search</li>
                                                                                        <li>DK Integration</li>
                                                                                        <li>Unlimited Media Uploads</li>
                                                                                        <li>Access to all PW Tournament Stats, Performance, & Strength Scores</li>
                                                                                        <li>Access to PW Progression Data</li>
                                                                                        <li>Access to Scouting reports and Event Recaps</li>
                                                                                        <li>Access to Scouting Photos and Videos</li>
                                                                                        <li>Access to all PW Profiles</li>
                                                                                        <li>National/State Rankings</li>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <li>Player Stats</li>
                                                                                        <li>Team Stats</li>
                                                                                        <li>Event Stats</li>
                                                                                    </>
                                                                    }
                                                                </ul>
                                                                <h3>${item.plans[0].amount} <span>/ A YEAR</span></h3>
                                                                <div className="upgrade-btn">
                                                                    {/* onClick={() => this.nextPayment(item)} */}
                                                                    <button className="btn trans" onClick={() => this.nextPayment(item)}>
                                                                        {pname === "DC Stats" ?
                                                                            "buy now" :
                                                                            <>
                                                                                <img src={localStorage.getItem('company') === "pwb" ? imagesArr.upgrade : imagesArr.upgrade_light} />
                                                                                upgrade
                                                                            </>
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p>No package available</p>
                                        }
                                        {/* <div className="price-box">
                                            <div className="price-info">
                                                <h5>PW Basic</h5>
                                                <ul>
                                                    <li>DK Integration</li>
                                                    <li>Unlimited Media Uploads</li>
                                                    <li>Access to all PW Tournament Stats, Performance, & Strength Scores</li>
                                                    <li>Access to PW Progression Data</li>
                                                </ul>
                                                <h3>$99.99 <span>/ A YEAR</span></h3>
                                                <div className="upgrade-btn">
                                                    <button className="btn trans"><img src={imagesArr.upgrade} /> upgrade</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="price-box">
                                            <div className="price-info">
                                                <h5>PW Premium</h5>
                                                <ul>
                                                    <li>PW Basic and...</li>
                                                    <li>Access to Scouting reports and Event Recaps</li>
                                                    <li>Access to Scouting Photos and Videos</li>
                                                    <li>Access to ALL PW Profiles</li>
                                                    <li>National/State Rankings</li>
                                                </ul>
                                                <h3>$99.99 <span>/ A YEAR</span></h3>
                                                <div className="upgrade-btn">

                                                    <button className="btn trans"><img src={imagesArr.upgrade} /> upgrade</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="price-box">
                                            <div className="price-info">
                                                <h5>PW Scout</h5>
                                                <ul>
                                                    <li>DK Integration</li>
                                                    <li>Unlimited Media Uploads</li>
                                                    <li>Access to all PW Tournament Stats, Performance, & Strength Scores</li>
                                                    <li>Access to PW Progression Data</li>
                                                    <li>Access to Scouting reports and Event Recaps</li>
                                                    <li>Access to Scouting Photos and Videos</li>
                                                    <li>Access to ALL PW Profiles</li>
                                                    <li>National/State Rankings</li>
                                                    <li>Player contact information</li>
                                                    <li>Advanced player search</li>
                                                </ul>
                                                <h3>$99.99 <span>/ A YEAR</span></h3>
                                                <div className="upgrade-btn">

                                                    <button className="btn trans"><img src={imagesArr.upgrade} /> upgrade</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Preloader flag={loader} />
            </>
        )
    }
}

PurchaseProspectwire.propTypes = {
    getPWPackages: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    commonData: state.api
});

const PWPurchase = connect(mapStateToProps, { getPWPackages })(PurchaseProspectwire);
export default withRouter(PWPurchase);

import React, { Component } from 'react';
import { imagesArr } from "../../../../../assets/images";

export default class collegeSecLink extends Component {
    render() {
        const { dataList } = this.props;
        return (
            <div className="web_description_fullborderbox">
                {/* <div className="web_desc_title">
                    <h3>Event Company</h3>
                </div> */}
                <div className="web_description_box_20">
                    <div className="companyLogos">
                        <img src={dataList.college && dataList.college.image && dataList.college.image.filePath} alt="college logo" />
                    </div>
                    <div className="company_social_link">
                        {dataList.college && dataList.college?.facebookLink &&
                            <a target="_blank" class="fblink nav-link" href={dataList.college && dataList.college?.facebookLink}>
                                <i class="fab fa-facebook-f"></i>
                            </a>}
                        {dataList.college && dataList.college?.twitterLink &&
                            <a target="_blank" class="twtlink nav-link" href={dataList.college && dataList.college?.twitterLink}>
                                <i class="fab fa-twitter"></i>
                            </a>}
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';

class successUpgrade extends Component {
    render() {
        const { flag } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                {/*  Upgrade Subscription thanx modal popup */}
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="onlymodal_close" onClick={() => this.props.close()}><img src={imagesArr.modal_close} alt="modal_close" /></div>
                            <div className="modal-body">
                                <div className="sccess_team">
                                    <div className="thumb_up">
                                        <img src={imagesArr.thumb_up} alt="modal_close" />
                                    </div>
                                    <h4>
                                        Thanks for Upgrade
                                    </h4>
                                    <p>Your plan upgraded now and you get the updated invoice on the next billing date.</p>
                                    <div className="flex-align">
                                        <Link className="btn" onClick={() => this.props.close()}>Okay</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default successUpgrade;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import CommonHeroBanner from "../../../components/commonHeroBanner";
import AWS from "aws-sdk";
import { imagesArr } from '../../../assets/images';
import Preloader from '../../../components/preloader';
import { getVideoSource, saveVideoSource } from '../../../saga/actions/common/uploadcsv.action';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
class UploadVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: "",
            fileObj: "",
            eventClass: false,
            event: "",
            loader: false,
            optionArr: [],
            sourceData: {
                file_path: "",
                file_type: "",
                time: ""
            },
            uploaded: 0
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        this.setLoader(true);
        this.getId();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getVideoSourceReq &&
            nextProps.uploadCsvInfo.getVideoSourceData
        ) {
            console.log("nextProps from getVideoSourceData:----", nextProps.uploadCsvInfo.getVideoSourceData);
            nextProps.uploadCsvInfo.getVideoSourceReq = false;
            if (nextProps.uploadCsvInfo.getVideoSourceData.flag) {
                this.setState({
                    optionArr: nextProps.uploadCsvInfo.getVideoSourceData.result.data
                }, () => {
                    nextProps.uploadCsvInfo.getVideoSourceData = null;
                    this.setLoader(false);
                });
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.getVideoSourceData.message);
                nextProps.uploadCsvInfo.getVideoSourceData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.saveVideoSourceReq &&
            nextProps.uploadCsvInfo.saveVideoSourceData
        ) {
            console.log("nextProps from saveVideoSourceData:----", nextProps.uploadCsvInfo.saveVideoSourceData);
            nextProps.uploadCsvInfo.saveVideoSourceReq = false;
            if (nextProps.uploadCsvInfo.saveVideoSourceData.flag) {
                this.commonCall.successMessage('Video uploaded. An email will be sent when the video is ready.');
                nextProps.uploadCsvInfo.saveVideoSourceData = null;
                this.setLoader(false);
                localStorage.removeItem("showVideo");
                this.props.history.push('/fan-uploadcsv');
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.saveVideoSourceData.message);
                nextProps.uploadCsvInfo.saveVideoSourceData = null;
                this.setLoader(false);
            }
        }
    }

    getId = () => {
        let url = new URL(document.location);
        let eid = url.pathname.split("/")[2];

        this.setState((prevState) => ({
            ...prevState,
            event: eid
        }), () => {
            let data = {
                bcevent_id: this.state.event
            }
            console.log("data:---", data);
            this.props.getVideoSource(data, "Fan", localStorage.getItem("rosterToken"));
        });
    }

    handleVideo = (e) => {
        let file = e.target.files[0];
        console.log("file:--", file);

        let ext = file.name.match(new RegExp('[^.]+$'))[0];
        let newName = this.newGuid() + "." + ext;
        console.log(".split():---", newName);

        this.setState((prevState) => ({
            ...prevState,
            sourceData: {
                ...prevState.sourceData,
                file_path: file
            },
            fileName: newName,
        }));
    }

    onlyNumber = event => {
        const keycode = event.which;
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };


    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            sourceData: {
                ...prevState.sourceData,
                [name]: value
            },
        }));
    }

    onSubmit = () => {

        if (this.validator.allValid()) {

            const { fileName, sourceData } = this.state;

            document.body.classList.add("modal-open");
            this.setState({
                eventClass: true
            })

            console.log("filename:---", fileName);
            console.log("sourceData.file_path:---", sourceData.file_path);
            console.log("process.env.REACT_APP_ACCESSKEY_ID:---", process.env.REACT_APP_ACCESSKEY_ID);

            AWS.config.update({
                accessKeyId: process.env.REACT_APP_ACCESSKEY_ID,
                secretAccessKey: process.env.REACT_APP_SECRET_ACCESSKEY,
                region: process.env.REACT_APP_REGION,
            });

            var bucket = new AWS.S3({
                params: { Bucket: process.env.REACT_APP_BUCKET },
            });

            var params = {
                Key: `input/${fileName}`,
                ContentType: sourceData.file_path.type,
                Body: sourceData.file_path,
                Metadata: {
                    'x-amz-meta-source_id': sourceData.file_type,
                    'x-amz-meta-skip_time': sourceData.time,
                },
            };

            AWS.config.httpOptions.timeout = 0;

            if (Number((sourceData.file_path.size / (1024 * 1024)).toFixed(2)) > 100) {
                AWS.S3.ManagedUpload.maxTotalParts = 10000;
                var options = { partSize: 100 * 100 * 1024, queueSize: 1 }; // 100 mb up
            } else {
                AWS.S3.ManagedUpload.maxTotalParts = 100;
                var options = { partSize: 100 * 100 * 1024, queueSize: 1 }; // 100 mb down
            }

            // AWS.S3.ManagedUpload.maxTotalParts = 10000;
            // var options = { partSize: 100 * 1024 * 1024, queueSize: 1 };

            bucket
                .upload(params, options)
                .on("httpUploadProgress", (evt) => {
                    var uploaded = Math.round((evt.loaded / evt.total) * 100);
                    console.log(`File uploaded: ${uploaded}%`);
                    this.setState({
                        uploaded: uploaded
                    })
                })
                .send((err, data) => {
                    console.log("err", err);
                    if (err !== null) {
                        return err;
                    }
                    if (data) {
                        document.body.classList.remove("modal-open");
                        this.setState({
                            eventClass: false
                        }, () => {
                            let sendData = {
                                v_source: sourceData.file_type,
                                event: this.state.event,
                                file_path: data.Location
                            }
                            console.log("sendData:---", sendData);
                            this.setLoader(true);
                            this.props.saveVideoSource(sendData, "Fan", localStorage.getItem("rosterToken"));
                        })
                    }
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    newGuid = () => {
        return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    onCancel = () => {
        localStorage.removeItem("showVideo");
        this.props.history.push("/fan-uploadcsv");
    }

    render() {
        const { eventClass, loader, optionArr, sourceData, uploaded } = this.state
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {/* Uploadcsv Upload Video */}
                <section className="uploadcsv-uploadvideo uploadcsv">
                    <div className="container">
                        <div className="white-bg">
                            <h3>Upload Video</h3>
                            <p className="mb-0">Time stamp for clipping source</p>
                            <form>
                                <div className="row info-wrap">
                                    <div className="w-50">
                                        <label className="common-lable">Video</label>
                                        <div className="selectfile">
                                            <div className="dragdrop-img">
                                                <div className="create_event_fileUpload file_custom_upload text-center">
                                                    <p>Browse..</p>
                                                    <input name="file_path" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".mp4,.mov,.avi,.flv,.MP4,.MOV,.AVI,.FLV" onChange={this.handleVideo} />
                                                </div>
                                            </div>
                                            <p>{sourceData.file_path ? sourceData.file_path.name : "No file selected"}</p>
                                        </div>
                                        <span className="validMsg">{this.validator.message('Video', sourceData.file_path, 'required')}</span>
                                    </div>
                                    <div className="w-50 pr-0">
                                        <label className="common-lable">CSV</label>
                                        <select name="file_type" value={sourceData.file_type} onChange={this.onHandleChange} className="custom-select">
                                            <option selected value="" >Select CSV</option>
                                            {
                                                optionArr &&
                                                optionArr.map((list, i) => {
                                                    return (
                                                        <option value={list._id}>{list.file_type}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <span className="validMsg">{this.validator.message('csv', sourceData.file_type, 'required')}</span>
                                    </div>
                                    {/* <div className="w-50">
                                        <label className="common-lable">Time stamp file</label>
                                        <div className="selectfile">
                                            <div className="dragdrop-img">
                                                <div className="create_event_fileUpload file_custom_upload text-center">
                                                    <p>Browse..</p>
                                                    <input name="logofile" id="" type="file" className="img_file_input" accept="image/*" onClick={(event)=> { event.target.value = null }} />

                                                </div>
                                            </div>
                                            <p>No file selected</p>
                                        </div>
                                    </div> */}
                                    <div className="w-50 pr-0">
                                        <label className="common-lable">Start time ( Second )</label>
                                        <div className="form-group mb-0">
                                            <input type="text" name="time" value={sourceData.time} onChange={this.onHandleChange} className="form-control" placeholder="Enter time" onKeyPress={this.onlyNumber} />
                                        </div>
                                        <span className="validMsg">{this.validator.message('time', sourceData.time, 'required')}</span>
                                    </div>
                                </div>
                                <div className="flex-align">
                                    <Link className="btn light-green" onClick={() => this.onSubmit()}>Submit</Link>
                                    <Link className="btn gray" onClick={() => this.onCancel()}>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                {
                    eventClass &&
                    <>
                        <div className="video_upload_progressbar">
                            <img src={imagesArr.loader} />Please wait, Video uploading progress completes {uploaded}%
                        </div>
                    </>
                }
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

UploadVideo.propTypes = {
    getVideoSource: PropTypes.func.isRequired,
    saveVideoSource: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

const uploadVideoComponent = connect(mapStateToProps, { getVideoSource, saveVideoSource })(UploadVideo);
export default withRouter(uploadVideoComponent);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import Basic from './setting/basic';
import Team from './setting/team';
import Coach from './setting/coach';
import Training from './setting/training';
import Ground from './setting/ground';
import { getPlayerProfile } from '../../../saga/actions/authantication/verify.action';
import { savePlayerBasic, getInvitation, saveAccept } from '../../../saga/actions/authantication/signup/player/basic.action';
import { savePlayerTeam, skipStep } from '../../../saga/actions/authantication/signup/player/team.action';
import { savePlayerCoach ,saveRoster } from '../../../saga/actions/authantication/signup/player/coach.action';
import { savePlayerTraining } from '../../../saga/actions/authantication/signup/player/training.action';
import { savePlayerGround } from '../../../saga/actions/authantication/signup/player/ground.action';
import { savePlayerQuestion } from '../../../saga/actions/authantication/signup/player/question.action';
import { savePlayerAbout } from '../../../saga/actions/authantication/signup/player/about.action';
import { savePlayerTeamList } from '../../../saga/actions/authantication/signup/player/collage.action';
import { savePlayerComplete } from '../../../saga/actions/authantication/signup/player/complete.action';
import commonCalls from "../../../businesslogic/commonCalls";
import Preloader from '../../../components/preloader';
import Notification from '../../../views/popup/notification';
import Confirmation from '../../../views/popup/confirmation';
import Roster from './setting/roster';

class playerStep4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            playerProfileData: [],
            loader: false,
            invite: false,
            inviteData: [],
            acceptPop: false,
            inviteInfo: false,
            inviteTeamData: [],
            inviteCoachData: [],
            inviteRosterData: [],
            backupData: "",
            newPerentTeam: "",
            fillTab: 0,
            skipPopup: false,
            skipRoster: false
        }
        this.basicInfo = null;
        this.teamInfo = null;
        this.coachInfo = null;
        this.rosterInfo = null;
        this.trainingInfo = null;
        this.groundInfo = null;
        this.collageInfo = null;
        this.questionInfo = null;
        this.aboutInfo = null;
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        // this.setLoader(true);
        this.props.getPlayerProfile();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.verifyData &&
            nextProps.verifyData.playerProfileReq &&
            nextProps.verifyData.playerProfileData
        ) {
            nextProps.verifyData.playerProfileReq = false;
            this.setState({
                playerProfileData: nextProps.verifyData.playerProfileData.result,
            })
            if (nextProps.verifyData.playerProfileData.result.profile.isCompleted) {
                nextProps.verifyData.playerProfileData = null
                this.setLoader(false);
                // this.setState({ tabs: 7, fillTab: 7 });
                // this.commonCall.successMessage("Login Successfully");
                this.props.history.push('/player-dashboard');
            } else {
                if (nextProps.verifyData.playerProfileData.result.profile.formData.length !== 0) {
                    nextProps.verifyData.playerProfileData.result.profile.formData.map((item, i) => {
                        if (item.sequence === 1) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 1, fillTab: 1 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else if (item.sequence === 2) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 2, fillTab: 2 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else if (item.sequence === 3) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 3, fillTab: 3 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else if (item.sequence === 4) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 4, fillTab: 4 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else if (item.sequence === 5) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 5, fillTab: 5 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else if (item.sequence === 6) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 6, fillTab: 6 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else if (item.sequence === 7) {
                            if (nextProps.verifyData.playerProfileData.result.profile.team &&
                                nextProps.verifyData.playerProfileData.result.profile.team[0] !== null
                            ) {
                                this.setState({ tabs: 7, fillTab: 7 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                })
                            } else {
                                this.setState({ tabs: 0, fillTab: 0 }, () => {
                                    nextProps.verifyData.playerProfileData = null
                                    this.setLoader(false);
                                    this.props.getInvitation();
                                })
                            }
                        } else {
                            // this.setState({ tabs: 7 });
                            nextProps.verifyData.playerProfileData = null;
                            this.setLoader(false);
                            this.props.history.push('/player-dashboard');
                        }
                    });
                } else {
                    nextProps.verifyData.playerProfileData = null;
                    this.setLoader(false);
                    this.setState({
                        tabs: 0
                    })
                }
            }
        }

        if (nextProps.basicData &&
            nextProps.basicData.savePlayerRes &&
            nextProps.basicData.savePlayerData
        ) {
            nextProps.basicData.savePlayerRes = false
            if (nextProps.basicData.savePlayerData.flag) {
                nextProps.basicData.savePlayerData = null;
                this.setLoader(false);
                this.props.getInvitation();
            }
        }

        if (nextProps.basicData &&
            nextProps.basicData.playerInviteRes &&
            nextProps.basicData.playerInviteData
        ) {
            nextProps.basicData.playerInviteRes = false
            if (nextProps.basicData.playerInviteData.data.length !== 0) {
                this.setState({
                    invite: true,
                    inviteInfo: true,
                    inviteData: nextProps.basicData.playerInviteData.data
                }, () => {
                    nextProps.basicData.playerInviteData = null
                })
            } else {
                this.setState({
                    tabs: 1,
                    fillTab: 1
                }, () => {
                    nextProps.basicData.playerInviteData = null
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.basicData &&
            nextProps.basicData.saveAcceptRes &&
            nextProps.basicData.saveAcceptData
        ) {
            nextProps.basicData.saveAcceptRes = false
            nextProps.basicData.saveAcceptData = null;
            // document.body.classList.remove('modal-open');
            this.setState({
                // invite: false,
                // acceptPop: true,
                inviteTeamData: this.state.backupData.parentTeam,
                inviteCoachData: this.state.backupData.team,
                tabs: 4,
                fillTab: 4,
            })
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.addPlayerTeamRes &&
            nextProps.teamDetails.addPlayerTeamData
        ) {
            nextProps.teamDetails.addPlayerTeamRes = false
            if (nextProps.teamDetails.addPlayerTeamData.flag) {
                this.setState({
                    tabs: 2,
                    fillTab: 2,
                    newPerentTeam: nextProps.teamDetails.addPlayerTeamData.result.parentTeam
                }, () => {
                    nextProps.teamDetails.addPlayerTeamData = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.skipStepPlayerRes &&
            nextProps.teamDetails.skipStepPlayerData
        ) {
            nextProps.teamDetails.skipStepPlayerRes = false
            if (nextProps.teamDetails.skipStepPlayerData.flag) {
                nextProps.teamDetails.skipStepPlayerData = null;
                this.props.savePlayerComplete();
            }
        }

        if (nextProps.coachDetails &&
            nextProps.coachDetails.addCoachRes &&
            nextProps.coachDetails.addCoachData
        ) {
            nextProps.coachDetails.addCoachRes = false
            if (nextProps.coachDetails.addCoachData.flag) {
                this.setState({
                    tabs: 3,
                    fillTab: 3
                }, () => {
                    nextProps.coachDetails.addCoachData = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.coachDetails &&
            nextProps.coachDetails.addRosterRes &&
            nextProps.coachDetails.addRosterData
        ) {

            nextProps.coachDetails.addRosterRes = false
            if (nextProps.coachDetails.addRosterData.flag) {
                this.setState({
                    tabs: 4,
                    fillTab: 4
                }, () => {
                    nextProps.coachDetails.addRosterData = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.groundDetails &&
            nextProps.groundDetails.savePlayerGroundRes &&
            nextProps.groundDetails.savePlayerGroundData
        ) {
            nextProps.groundDetails.savePlayerGroundRes = false;
            if (nextProps.groundDetails.savePlayerGroundData.flag) {
                this.setState({
                    tabs: 5,
                    fillTab: 5
                }, () => {
                    nextProps.groundDetails.savePlayerGroundData = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.trainingDetails &&
            nextProps.trainingDetails.savePlayerTrainingRes &&
            nextProps.trainingDetails.savePlayerTrainingData
        ) {
            if (nextProps.trainingDetails.savePlayerTrainingData.flag) {
            nextProps.trainingDetails.savePlayerTrainingRes = false;
                this.props.savePlayerComplete();
            }
        }

       

        // if (nextProps.questionDetails &&
        //     nextProps.questionDetails.savePlayerQuestionRes &&
        //     nextProps.questionDetails.savePlayerQuestionData
        // ) {
        //     nextProps.questionDetails.savePlayerQuestionRes = false;
        //     if (nextProps.questionDetails.savePlayerQuestionData.flag) {
        //         this.setState({
        //             tabs: 6,
        //             fillTab: 6
        //         }, () => {
        //             nextProps.questionDetails.savePlayerQuestionData = null;
        //             this.setLoader(false);
        //         })
        //     }

        // }

        // if (nextProps.aboutDetails.postInfo) {
        //     if (nextProps.aboutDetails.postInfo.flag) {
        //         this.setState({
        //             tabs: 7,
        //             fillTab: 7
        //         }, () => {
        //             nextProps.aboutDetails.postInfo = null;
        //             this.setLoader(false);
        //         })
        //     }

        // }

        // if (nextProps.collegeDetails.postInfo) {
        //     if (nextProps.collegeDetails.postInfo.flag) {
        //         nextProps.collegeDetails.postInfo = null;
        //         this.props.savePlayerComplete();
        //     }
        // }

        if (nextProps.complatePlayerDetails &&
            nextProps.complatePlayerDetails.savePlayerComplateRes &&
            nextProps.complatePlayerDetails.savePlayerComplateData
        ) {
            nextProps.complatePlayerDetails.savePlayerComplateRes = false;
            if (nextProps.complatePlayerDetails.savePlayerComplateData.flag) {
                this.commonCall.successMessage(nextProps.complatePlayerDetails.savePlayerComplateData.message);
                nextProps.complatePlayerDetails.savePlayerComplateData = null;
                this.setLoader(false);
                if (localStorage.getItem("payPlans")) {
                    if (localStorage.getItem("rolPay") === "Player") {
                        this.props.history.push('/player-payment');
                    } else {
                        localStorage.removeItem("rolPay");
                        localStorage.removeItem("dcStats");
                        localStorage.removeItem("payPlans");
                        localStorage.removeItem("analyzr");
                        this.props.history.push('/player-dashboard');
                    }
                } else {
                    this.props.history.push('/player-dashboard');
                }


                // this.props.history.push('/player-dashboard');
            }
        }
    }

    handleTabs = (e) => {
        if (this.state.fillTab <= e) {
            this.setState({
                tabs: this.state.fillTab
            })
        } else {
            this.setState({
                tabs: e
            })
        }
    }

    nextStep = () => {
        if (this.state.tabs === 0) {
            this.basicInfo.validationUpdate(obj => {
                if (obj.flag) {
                    this.props.savePlayerBasic(obj.data);
                    this.setLoader(true);
                }
            });
        } else if (this.state.tabs === 1) {
            this.teamInfo.validationUpdate(obj => {
                if (obj.flag) {
                    this.props.savePlayerTeam(obj.data);
                    this.setLoader(true);
                } else {
                    this.commonCall.errorMessage("Please select team name");
                }
            });
        } else if (this.state.tabs === 2) {
            this.coachInfo.validationUpdate(obj => {
                if (obj.flag) {
                    this.props.savePlayerCoach(obj.data);
                    this.setLoader(true);
                    // if (obj.coachData.length !== 0) {
                    // } else {
                    //     this.commonCall.errorMessage("Choose the another team from previous step. Thanks.");
                    // }
                } else {
                    this.commonCall.errorMessage("Please select coach name");
                }
            });
        } else if (this.state.tabs === 3) {
            this.rosterInfo.validationUpdate(obj => {
                if (obj.flag) {
                    this.props.saveRoster(obj.data);
                    // this.setLoader(true);
                    // if (obj.coachData.length !== 0) {
                    // } else {
                    //     this.commonCall.errorMessage("Choose the another team from previous step. Thanks.");
                    // }
                } else {
                    this.commonCall.errorMessage("Please select roster name");
                }
            });
        }  else if (this.state.tabs === 4) {
            this.groundInfo.validationUpdate(obj => {
                if (obj) {
                    this.props.savePlayerGround(obj);
                    this.setLoader(true);
                }
            });
        } else if (this.state.tabs === 5) {
            this.trainingInfo.validationUpdate(obj => {
                if (obj) {
                    this.props.savePlayerTraining(obj);
                    this.setLoader(true);
                }
            });
        }
        // else if (this.state.tabs === 6) {
        //     this.questionInfo.validationUpdate(obj => {
        //         this.props.savePlayerQuestion(obj);
        //         this.setLoader(true);
        //     });
        // } else if (this.state.tabs === 7) {
        //     this.collageInfo.validationUpdate(obj => {
        //         if (obj.flag) {
        //             this.props.savePlayerTeamList(obj.collage);
        //             this.setLoader(true);
        //         }
        //         else {
        //             this.commonCall.errorMessage("Please select atleast one college")
        //         }
        //     });
        // }
        else {

        }

    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    onHandleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            invite: false,
            acceptPop: false
        })
    }

    acceptPopup = (data) => {
        document.body.classList.remove('modal-open');
        this.props.saveAccept({ notification: data._id })
        this.setState({
            invite: false,
            backupData: data
        })
    }

    cancelHandle = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            tabs: 0,
            acceptPop: false
        })
    }

    nextHandle = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            invite: false,
            acceptPop: false,
            tabs: 1,
        }, () => {
            this.setState({
                inviteInfo: true
            })
        })
    }

    nextSkip = () => {
        this.setState({
            skipPopup: true
        })
    }

    onHandleSkipClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            skipPopup: false,
        })
    }

    nextSkipHandle = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            skipPopup: false
        }, () => {
            this.setLoader(true);
            this.props.skipStep();
        })
    }

    render() {
        const tabs = this.state.tabs;
        const { loader, invite, inviteData, acceptPop, fillTab, skipPopup } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-timeline">
                    <div className="container">
                        <div className="time-line">
                            <div className="timeline-scoll">
                                <ul className="timeline-inner">
                                <li className={`${tabs === 0 ? "select" : tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                        <Link>1</Link>
                                    </li>
                                    {!this.state.inviteInfo && 
                                    <>
                                    <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                        <Link>2</Link>
                                    </li>
                                    <li className={`${tabs === 2 ? "select" : tabs > 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                        <Link>3</Link>
                                    </li>
                                    <li className={`${tabs === 3 ? "select" : tabs > 3 ? "active" : ""}`} onClick={() => this.handleTabs(3)}>
                                        <Link>4</Link>
                                    </li>
                                    </>}
                                    <li className={`${tabs === 4 ? "select" : tabs > 4 ? "active" : ""}`} onClick={() => this.handleTabs(4)}>
                                        <Link>{this.state.inviteInfo ? 2 : 5}</Link>
                                    </li>
                                    <li className={`${tabs === 5 ? "select" : tabs > 5 ? "active" : ""}`} onClick={() => this.handleTabs(5)}>
                                        <Link>{this.state.inviteInfo ? 3 : 6}</Link>
                                    </li>
                                    {/* <li className={`${tabs === 6 ? "select" : tabs > 6 ? "active" : ""}`} onClick={() => this.handleTabs(6)}>
                                        <Link>7</Link>
                                    </li>
                                    <li className={`${tabs === 7 ? "select" : tabs > 7 ? "active" : ""}`} onClick={() => this.handleTabs(7)}>
                                        <Link>8</Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="all_btn_step">
                                {tabs === 1 &&
                                    <Link className="btn blue_btn" onClick={() => this.nextSkip()}>Skip</Link>
                                }
                                {tabs === 3 &&
                                    <Link className="btn blue_btn" onClick={() => this.setState({ tabs: 4 })}>Skip</Link>
                                }
                                <Link className="btn light-green" onClick={() => this.nextStep()}>Next</Link>
                            </div>
                        </div>

                        {tabs === 0 &&
                            <Basic
                                onRef={e => this.basicInfo = e}
                                profileData={this.state.playerProfileData.length !== 0 && this.state.playerProfileData}
                            />
                        }
                        {tabs === 1 &&
                            <Team
                                onRef={e => this.teamInfo = e}
                                profileData={this.state.playerProfileData}
                                inviteFlag={this.state.inviteInfo}
                                data={this.state.inviteTeamData}
                            />
                        }
                        {tabs === 2 &&
                            <Coach
                                onRef={e => this.coachInfo = e}
                                profileData={this.state.playerProfileData}
                                inviteFlag={this.state.inviteInfo}
                                data={this.state.inviteCoachData}
                                pTeamId={this.state.newPerentTeam}
                            />
                        }
                        {tabs === 3 &&
                            <Roster
                                onRef={e => this.rosterInfo = e}
                                profileData={this.state.playerProfileData}
                            />
                        }
                      
                        {tabs === 4 &&
                            <Ground
                                onRef={e => this.groundInfo = e}
                                profileData={this.state.playerProfileData}
                            />
                        }
                          {tabs === 5 &&
                            <Training
                                onRef={e => this.trainingInfo = e}
                                profileData={this.state.playerProfileData}
                            />
                        }
                        {/* {tabs === 6 &&
                            <Question
                                onRef={e => this.questionInfo = e}
                                profileData={this.state.playerProfileData}
                            />
                        }
                        {tabs === 7 &&
                            <College
                                onRef={e => this.collageInfo = e}
                                profileData={this.state.playerProfileData}
                            />
                        } */}

                        <div className="time-line">
                            <div className="timeline-scoll">
                                <ul className="timeline-inner">
                                    <li className={`${tabs === 0 ? "select" : tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                        <Link>1</Link>
                                    </li>
                                    {!this.state.inviteInfo && 
                                    <>
                                    <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                        <Link>2</Link>
                                    </li>
                                    <li className={`${tabs === 2 ? "select" : tabs > 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                        <Link>3</Link>
                                    </li>
                                    <li className={`${tabs === 3 ? "select" : tabs > 3 ? "active" : ""}`} onClick={() => this.handleTabs(3)}>
                                        <Link>4</Link>
                                    </li>
                                    </>}
                                    <li className={`${tabs === 4 ? "select" : tabs > 4 ? "active" : ""}`} onClick={() => this.handleTabs(4)}>
                                        <Link>{this.state.inviteInfo ? 2 : 5}</Link>
                                    </li>
                                    <li className={`${tabs === 5 ? "select" : tabs > 5 ? "active" : ""}`} onClick={() => this.handleTabs(5)}>
                                        <Link>{this.state.inviteInfo ? 3 : 6}</Link>
                                    </li>
                                    {/* <li className={`${tabs === 6 ? "select" : tabs > 6 ? "active" : ""}`} onClick={() => this.handleTabs(6)}>
                                        <Link>7</Link>
                                    </li>
                                    <li className={`${tabs === 7 ? "select" : tabs > 7 ? "active" : ""}`} onClick={() => this.handleTabs(7)}>
                                        <Link>8</Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="all_btn_step">
                                {tabs === 1 &&
                                    <Link className="btn blue_btn" onClick={() => this.nextSkip()}>Skip</Link>
                                }
                                 {tabs === 3 &&
                                    <Link className="btn blue_btn" onClick={() => this.setState({ tabs: 4 })}>Skip</Link>
                                }
                                <Link className="btn light-green" onClick={() => this.nextStep()}>Next</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
                <Notification
                    flag={invite}
                    data={inviteData}
                    close={this.onHandleClose}
                    accept={this.acceptPopup}
                    inviteType="coachteam"
                />
                {
                    acceptPop &&
                    <Confirmation
                        flag={acceptPop}
                        close={this.onHandleClose}
                        type="coach"
                        closeCancel={this.cancelHandle}
                        closeNext={this.nextHandle}
                    />
                }
                {
                    skipPopup &&
                    <Confirmation
                        flag={skipPopup}
                        close={this.onHandleSkipClose}
                        type="skip"
                        closeCancel={this.onHandleSkipClose}
                        closeNext={this.nextSkipHandle}
                    />
                }
            </>
        );
    }
}

playerStep4.propTypes = {
    getPlayerProfile: PropTypes.func.isRequired,
    savePlayerBasic: PropTypes.func.isRequired,
    getInvitation: PropTypes.func.isRequired,
    savePlayerTeam: PropTypes.func.isRequired,
    savePlayerCoach: PropTypes.func.isRequired,
    savePlayerTraining: PropTypes.func.isRequired,
    savePlayerGround: PropTypes.func.isRequired,
    savePlayerQuestion: PropTypes.func.isRequired,
    savePlayerAbout: PropTypes.func.isRequired,
    savePlayerTeamList: PropTypes.func.isRequired,
    saveAccept: PropTypes.func.isRequired,
    savePlayerComplete: PropTypes.func.isRequired,
    skipStep: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
    basicData: state.basicPlayerDetails,
    teamDetails: state.teamPlayerDetails,
    coachDetails: state.coachPlayerDetails,
    trainingDetails: state.trainingPlayerDetails,
    groundDetails: state.groundPlayerDetails,
    questionDetails: state.questionDetails,
    aboutDetails: state.aboutPlayerDetails,
    collegeDetails: state.collagePlayerDetails,
    complatePlayerDetails: state.complatePlayerDetails
});

export default connect(mapStateToProps, { skipStep, getPlayerProfile, savePlayerBasic, getInvitation, savePlayerTeam, savePlayerCoach ,saveRoster, savePlayerTraining, savePlayerGround, savePlayerQuestion, savePlayerAbout, savePlayerTeamList, saveAccept, savePlayerComplete })(playerStep4);


import React, { Component } from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import moment from "moment";

class confirmation extends Component {

  componentDidMount() {
    if (this.props.flag && this.props.type !== "video") {
      document.body.classList.add("modal-open");
    }

    if (this.props.flag && this.props.type === "video") {
      document.body.classList.add("video-open");
    }
  }

  render() {
    const { flag, type, date, msg, name } = this.props;

    // if (flag && type !== "video") {
    //   document.body.classList.add("modal-open");
    // }

    // if (flag && type === "video") {
    //   document.body.classList.add("video-open");
    // }

    return (
      <>
        {type === "coach" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Are you sure!! Do you want to select this coach?</p>
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.closeCancel}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.closeNext}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "subcription" && (
          /* subscription modal popup */
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content sccess_payment">
                <div className="modal-body">
                  <div className="confirmcancel">
                    <div className="cancellation">
                      <img src={imagesArr.cancel} alt="cancel" />
                    </div>
                    <h4>Confirm Cancellation</h4>
                    <p>
                      If you confirm and end your subscription now, you can
                      still access it until {moment(date).format("DD MMM")}, {moment(date).format("YYYY")}.
                    </p>
                    <div className="flex-align">
                      <Link
                        className="btn gray mr-15"
                        onClick={this.props.close}
                      >
                        Not Now
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.confirm}
                      >
                        Confirm
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {
          type === "video" &&
          <section className={`common-modal videomodal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want remove this video?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "license" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want remove this license?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "roster" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want remove this roster group?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "rosterConfirmation" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>{this.props.msg}</h4>
                    {/* <p>{this.props.msg}</p> */}
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.close}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "isMail" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want to send an email invite to the players?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>Yes</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "allMail" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want to send an email invite to all players?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>Yes</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "headCoach" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want to assign {this.props.coachName} as an Admin?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>Yes</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "player" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want remove this player?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "subCoach" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure you want to delete this coach?</h4>
                    {/* <p>Do you want remove this coach?</p> */}
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "delCoachPopup" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure you want to delete this roster?</h4>
                    {/* <p>Do you want remove this coach?</p> */}
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "existCoach" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Head Coach Assignment</h4>
                    <p className="mrb">{this.props.popupText}</p>
                    <p>Are you sure? you want to add them to current {this.props.teamName}?</p>
                    <div className="flex-align">
                      <Link className="btn gray" onClick={this.props.close}>Cancel</Link>
                      <Link className="btn light-green" onClick={this.props.ok}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {type === "skip" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    {/* <p>Are you sure! Do you want skip the team selection and rest of steps?</p> */}
                    <p>Click Next to go to your dashboard. Click Cancel to find your team/facility.</p>
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.closeCancel}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.closeNext}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "skipTeam" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Click next to go to your dashboard</p>
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.closeCancel}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.closeNext}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "skipCoach" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Are you sure! Do you want skip the coach selection?</p>
                    {/* <p>Click Next to go to your dashboard. Click Cancel to find your team/facility.</p> */}
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.closeCancel}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.closeNext}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "event" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4 style={{ padding: '20px', fontSize: '30px' }}>Are you sure you want to delete this Event?</h4>
                    {/* <p>Are you sure! Do you want to delete this event data?</p> */}
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.close}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.ok}
                      >
                        ok
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "isImport" && (
          <section className={`common-modal ${flag ? 'show' : ''}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="green-header modal-header">
                  <h3>Edit Profile</h3>
                  <div className="modal_close" onClick={this.props.close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>Welcome to the new version of BaseballCloud! You will need to fill out some additional information to complete your profile migration. This includes inputting your Phone Number, Date of Birth, and Zip Code. This will allow you to verify your account. If you forgot your password, you can proceed with a phone number and verify using the OTP function before resetting your password.</p>
                    <div className="flex-align">
                      <Link className="blue_btn" onClick={this.props.confirm}>Edit profile now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "isCmsUser" && (
          <section className={`common-modal ${flag ? 'show' : ''}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="green-header modal-header">
                  <h3>Edit Profile</h3>
                  <div className="modal_close" onClick={this.props.close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>Welcome, {this.props.name} to Diamond Connect, please complete your profile to get started.</p>
                    <div className="flex-align">
                      <Link className="blue_btn" onClick={this.props.confirm}>Edit profile now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "isEosPlayer" && (
          <section className={`common-modal ${flag ? 'show' : ''}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="green-header modal-header">
                  <h3>New Player added on your team</h3>
                  <div className="modal_close" onClick={this.props.close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>Check new player added to the roster from EOS. Please review their account detail and invite them to signup on this platform.</p>
                    <div className="flex-align">
                      <Link className="blue_btn" onClick={this.props.confirm}>Edit Roster</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "isLeaveEventConfirm" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    {/* <h4>Are you sure you want to leave this event ?</h4> */}
                    <p>Are you sure you want to leave this event ?</p>
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.confirm}>
                        Yes
                      </Link>
                      <Link className="btn gray" onClick={this.props.close}>
                        No
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "isFinalMsg" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                {/* <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div> */}
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>You have unregistered for {this.props.eventName} <br />Please contact the event coordinator if you have questions.</p>
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.close}>
                        Ok
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "eventModelMsg" && (
          <section className={`common-modal ${flag ? 'show' : ''}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="green-header modal-header">
                  <h3>Alert!</h3>
                  <div className="modal_close" onClick={this.props.close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>{msg && msg}</p>
                    <div className="flex-align">
                      <Link className="blue_btn" onClick={this.props.close}>ok</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {
          type === "confirmRoster" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>{this.props.rosterMsg}</p>
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.close}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "regiClose" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <p>Registration Closed</p>
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.close}>OK</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "eligible" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.closePopup}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    {this.props.eligibleText}
                    {/* <h2>Let’s create your first roster</h2>
                    <p>{this.props.eligibleText}</p> */}
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.close}>{this.props.eligibleButoon}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {
          type === "isFinish" &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.closePopup}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h2>Thanks for creating your roster</h2>
                    {/* <p>{this.props.eligibleText}</p> */}
                    <p>Now that you have created your roster, please complete registration. You can add/manage your players at any time from your dashboard.</p>
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={this.props.close}>Finish Registration</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

        {type === "confirmProduct" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do want to go with selected products?</p>
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.close}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.ok}
                      >
                        ok
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "installmentList" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog installment-popup">
              <div className="modal-content">
                <div className="green-header modal-header">
                  <h3>Installment</h3>
                  <div className="modal_close" onClick={this.props.close}>
                    <img src={imagesArr.white_modal_close} alt="modal_close" />
                  </div>
                </div>
                <div className="modal-body">
                  <div className="installment-list">
                    <ul>
                      {
                        this.props.installmentList.map((item, i) => {
                          return (
                            <li>{moment(item.date).format("MM/DD/YYYY")} <span>${item.price}</span></li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "delContactConfirm" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do want to delete this coach detail?</p>
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.close}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.ok}
                      >
                        ok
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {type === "disconnect" && (
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="onlymodal_close" onClick={this.props.close}>
                  <img src={imagesArr.modal_close} alt="modal_close" />
                </div>
                <div className="modal-body">
                  <div className="sccess_team">
                    <h4>Are you sure?</h4>
                    <p>Do you want to disconnect {name}?</p>
                    <div className="flex-align">
                      <Link
                        className="btn gray"
                        onClick={this.props.closeCancel}
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn light-green"
                        onClick={this.props.closeNext}
                      >
                        ok
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/*  Remove Player modal popup */}
        <section className="common-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="onlymodal_close" onClick={this.props.close}>
                <img src={imagesArr.modal_close} alt="modal_close" />
              </div>
              <div className="modal-body">
                <div className="sccess_team">
                  <h4>Are you sure?</h4>
                  <p>Do you want remove this player?</p>
                  <div className="flex-align">
                    <Link className="btn gray" >Cancel</Link>
                    <Link className="btn light-green">OK</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    );
  }
}

export default confirmation;

import React, { Component } from "react";
import CryptoJS from "crypto-js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
class commonCalls extends Component {

    // instagramCred = {
    //     client_id: process.env.REACT_APP_INSTA_CLIENT_ID,
    //     client_secret: process.env.REACT_APP_INSTA_CLIENT_SECRET,
    //     grant_type: process.env.REACT_APP_INSTA_GRANT_TYPE,
    //     redirect_login_uri: process.env.REACT_APP_INSTA_LOGIN_REDIRECT_URL,
    //     redirect_regi_uri: process.env.REACT_APP_INSTA_REGI_REDIRECT_URL,
    // }

    setStorage = (key, value, cb) => {
        localStorage.setItem(key, this.encryption(value))
        cb(true);
    };

    setAuthToken = (key, value, cb) => {
        localStorage.setItem(key, value)
        cb(true);
    };

    getStorage = (key, cb) => {
        if (localStorage.getItem(key)) {
            let data = this.decryption(localStorage.getItem(key));
            cb(data);
        } else {
            cb(null);
        }
    };

    removeStorage = (key) => {
        localStorage.removeItem(key);
    };

    encryption = (data) => {
        let ciphertext = CryptoJS.Rabbit.encrypt(JSON.stringify(data), "basebollCloud");
        return ciphertext.toString();
    };

    decryption = (data) => {
        let bytes = CryptoJS.Rabbit.decrypt(data, "basebollCloud");
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(plaintext);
    };

    successMessage = (msg) => {
        toast.success(msg, { position: toast.POSITION.TOP_RIGHT });

    }

    errorMessage = (msg) => {
        toast.error(msg, { position: toast.POSITION.TOP_RIGHT });
    }

    removeURLParameter = (url, parameter) => {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    buildObject = arr => {
        const obj = {};
        for (let i = 0; i < arr.length; i++) {
            const { key, value } = arr[i];
            // if (key !== "associated_videos")
            obj[key] = key === "date" ? moment(value).format("MM/DD/YYYY") : value;
        };
        return obj;
    };

    getMaxOfKeys = (values, keys) => {
        return Math.min.apply(Math, values.map(function (obj) {
            return Math.min.apply(Math, keys.map(function (key) {
                return obj[key];
            }));
        }));
    }


    showFooter = (cb) => {
        let url = new URL(document.location);
        let path = url.pathname;
        if (localStorage.getItem("company") !== null && path !== "/about-us" && path !== "/our-team" && path !== "/press-room" && path !== "/analyzr"
            && path !== "/bcteam" && path !== "/ballr" && path !== "/pitchr" && path !== "/" && path !== "/pitchrapp" && path !== "/ballrapp" && path !== "/bcteamapp" && path !== "/analyzrapp") {
            cb(true);
        } else {
            cb(false);
        }
    }

}

export default commonCalls;

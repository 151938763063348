import React, { Component } from 'react'
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import ProudSponsors from "../../components/common/proudSponsors";
import UpComingEvents from "../../components/common/upComingEvents";
import Preloader from '../../../../components/preloader';
export default class pwsHotel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0
        }
    }

    componentDidMount = () => {
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { loaderCount } = this.state;
        return (
            <div>
                <Header />
                <div className="hotel-section event-main">
                    <div className="hotel-event-detail">
                        <div className="hotel-details">
                            <div className="container">
                                <p className="section_title">All Prospect Wire events have a mandatory "Stay to Play" policy. This means teams must stay AND book hotels through Prospectwire.com in order to participate in the event. There are a variety of reasons for this policy and all of them help Prospect Wire events to become more beneficial to players, secure better facilities, and more successful overall. All hotel rates listed are guaranteed to be the lowest rates found anywhere on the internet during this time frame. All hotels are within reasonable driving distance to the venues being used.</p>
                            </div>
                        </div>
                        <div className="hotel-event">
                            <UpComingEvents isPresent={3} filterEventType={false} filterCompany={false} eventTeamType="" cType="pws" textName="Events" increment={this.increment} decrement={this.decrement} eventLabel={false} eventLinkLabel={false} filterForm={false} hideChkBox={false} noOfRec={12} eventType={2}/>
                        </div>
                    </div>
                    <div className="ques-hotel">
                        <div className="ques-hotel-mail">
                            <h2>Questions on Hotels?</h2>
                            <a class="btn trans mr-15" href="mailto:Frank@prospectwire.com">Send Email</a>
                        </div>
                    </div>
                    <ProudSponsors increment={this.increment} decrement={this.decrement} />
                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </div>
        )
    }
}

import axios from 'axios';
import { API_Url, EVENT_API_Url } from '../../helpers/authToken';
import {
    GET_PLAYER_LIST_SUCCESS,
    GET_PLAYER_LIST_ERROR,

    AGE_TEAM_LIST_SUCCESS,
    AGE_TEAM_LIST_ERROR,

    GET_PLAYER_INFO_SUCCESS,
    GET_PLAYER_INFO_ERROR,

    POST_PLAYER_INFO_SUCCESS,
    POST_PLAYER_INFO_ERROR,

    EDIT_PLAYER_INFO_SUCCESS,
    EDIT_PLAYER_INFO_ERROR
} from '../../constants/common/accountCreation.const';
import { PICTUREUPLOAD_ERROR, PICTUREUPLOAD_SUCCESS } from '../../constants/view/player/profile/personalInfo.const';

export const getPlayerList = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}bc/coach/roster/data`,
        data: data,
    }).then(res => {
        dispatch({
            type: GET_PLAYER_LIST_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const getTeamListByAge = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/v2/groupwise/list`,
        data: data,
    }).then(res => {
        dispatch({
            type: AGE_TEAM_LIST_SUCCESS,
            payload: res.data.result
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: AGE_TEAM_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const getPlayerInfo = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}bc/coach/player/data`,
        data: data,
    }).then(res => {
        dispatch({
            type: GET_PLAYER_INFO_SUCCESS,
            payload: res.data.result
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_INFO_ERROR, error: error.response.data.message })
        }
    });
};

export const addPlayerInfo = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}bc/coach/claimPlayer/add`,
        data: data,
    }).then(res => {
        dispatch({
            type: POST_PLAYER_INFO_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: POST_PLAYER_INFO_ERROR, error: error.response.data.message })
        }
    });
};
export const editPlayerInfo = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}bc/coach/claimPlayer/edit`,
        data: data,
    }).then(res => {
        dispatch({
            type: EDIT_PLAYER_INFO_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: EDIT_PLAYER_INFO_ERROR, error: error.response.data.message })
        }
    });
};


export const claimProfile = (file, type) => (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    axios({
      method: "POST",
      url: `${API_Url}file/upload?documentType=${type}`,
      data: formData,
    })
      .then((res) => {
        dispatch({
          type: PICTUREUPLOAD_SUCCESS,
          payload: res.data.result,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401)
          dispatch({
            type: PICTUREUPLOAD_ERROR,
            error: error.response.data.message,
          });
      });
  };

  export const signInUserWithClaim = (data) => (dispatch) => {
    axios({
      method: "POST",
      url: `${API_Url}bc/coach/claimPlayer/sigin`,
      data: data,
    })
      .then((res) => {
        dispatch({
            type: `LOGIN_SUCCESS`,
          payload: res.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401)
        dispatch({ type: `LOGIN_ERROR`, error: error.response.data.message })
      });
  };

import axios from 'axios';
import { API_Url, Auth_Fan_Key ,checkIsValidFanToken} from '../../../../helpers/authToken';
import {
    FAN_TEAM_LIST_REQUEST,
    FAN_TEAM_LIST_SUCCESS,
    FAN_TEAM_LIST_ERROR,

    FAN_ADD_TEAM_REQUEST,
    FAN_ADD_TEAM_SUCCESS,
    FAN_ADD_TEAM_ERROR,
} from '../../../../constants/authantication/signup/fan/team.const';
import histrory from '../../../../../History';

export const teamList = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: FAN_TEAM_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parentTeam/fan/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(searchteam => {
        dispatch({
            type: FAN_TEAM_LIST_SUCCESS,
            payload: searchteam.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: FAN_TEAM_LIST_ERROR, error: error.response.data.message })
    });
};


export const saveTeam = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: FAN_ADD_TEAM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/fan/parentTeam`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(addTeam => {
        dispatch({
            type: FAN_ADD_TEAM_SUCCESS,
            payload: addTeam.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: FAN_ADD_TEAM_ERROR, error: error.response.data.message })
    });
};

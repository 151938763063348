import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from '../assets/images';
import { sendContact } from "../saga/actions/common/api.action";
import Preloader from '../components/preloader';
import commonCalls from "../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { companyHeader } from '../businesslogic/content';

class footer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            sendArr: {
                fullname: "",
                email: "",
                phone: "",
                message: ""
            },
            show: false,
            socialCred: {
                logo: imagesArr.footer_logo,
                twitter: "https://twitter.com/DCappUS",
                instagram: "https://www.instagram.com/dcapp_us/",
                facebook: "https://www.facebook.com/profile.php?id=100085569381904",
                className: ""
            }
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.commonCall.showFooter(flag => {
            this.setState({
                show: flag
            })
        });
        this.setSocialLink();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.commonDetails &&
            nextProps.commonDetails.contactReq &&
            nextProps.commonDetails.contactData
        ) {
            nextProps.commonDetails.contactReq = false;
            if (nextProps.commonDetails.contactData.flag) {
                this.commonCall.successMessage("Thanks for reaching out, we will contact you shortly.");
                nextProps.commonDetails.contactData = null;
                this.setLoader(false);
                this.setState({
                    sendArr: {
                        fullname: "",
                        email: "",
                        phone: "",
                        message: ""
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.commonDetails.contactData.message);
                nextProps.commonDetails.contactData = null;
                this.setLoader(false);
            }
        }
    }

    setSocialLink = () => {
        if (localStorage.getItem("company")) {
            let sdata = this.filterCompany();
            this.setState({
                socialCred: {
                    logo: sdata?.logo,
                    twitter: sdata?.socialLinks?.twitter,
                    instagram: sdata?.socialLinks?.instagram,
                    facebook: sdata?.socialLinks?.facebook,
                    className: sdata?.footerClass
                }
            })
        }
    }

    filterCompany = () => {
        var isData;
        companyHeader.forEach((item, i) => {
            if (item.key === localStorage.getItem("company")) return (isData = item);
        })
        return isData;
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;

        if (name === "phone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendArr: {
                            ...prevState.sendArr,
                            [name]: value
                        },
                    }))
                }
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sendArr: {
                    ...prevState.sendArr,
                    [name]: value
                },
            }));
        }
    }

    sendContact = () => {
        if (this.validator.allValid()) {
            this.setLoader(true);
            this.props.sendContact(this.state.sendArr);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    supportZendesk = () => {
        window.open("https://baseballcloud.force.com/diamondconnect/s/", '_blank');
    }

    soiclFacebook = (link) => {
        window.open(link, '_blank');
    }

    soiclTwitter = (link) => {
        window.open(link, '_blank');
    }

    soiclInstagram = (link) => {
        window.open(link, '_blank');
    }

    backToLanding = () => {
        window.scrollTo(0, 0);
        window.location.reload()
        this.setLoader(true);
            
    }

    render() {
        const { loader, sendArr, show, socialCred } = this.state
        return (
            <>
                {
                    !show &&

                    <footer id="footer">
                        <div className="footer_before" >
                            <div className="container">
                                <div className="flex-footer">
                                    <div className='flex-footer-logo_sec'>
                                        <div className='pr-15'>
                                            <Link className="footer-logo" to='/' onClick={this.backToLanding}><img data-src={imagesArr.footer_logo} src={imagesArr.footer_logo} alt="footer-logo" /></Link>
                                        </div>
                                        {/* <div ><Link className="footer-logo pt-15" style={{ cursor: "default" }}><img data-src={imagesArr.footer_SportsCloud} src={imagesArr.footer_SportsCloud} alt="footer-logo" /></Link>
                                        </div> */}
                                    </div>
                                    <div className="allfooter_url">
                                        <h6 className="footer-title">Home </h6>
                                        <ul className="footer-url">
                                            <li><Link to="/login">Sign In</Link></li>
                                            <li><Link to="/signup">Sign Up</Link></li>
                                            <li><Link to="/terms-conditions">Terms and Conditions</Link></li>
                                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                    <div className="allfooter_url">
                                        <h6 className="footer-title">Contact Us</h6>
                                        <ul className="footer-url">
                                            <li><a>800-985-9608</a></li>
                                            <li><a href="mailto:support@diamondconnect.com">support@diamondconnect.com</a></li>
                                            <li><Link onClick={() => this.supportZendesk()}>Helpdesk</Link></li>
                                        </ul>
                                    </div>
                                    {
                                        // localStorage.getItem("webglToken") !== null ?
                                        // <form className="footer_form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                                        //     <div className="allfooter_url">
                                        //         <h6 className="footer-title">Submit an Issue.</h6>
                                        //     </div>

                                        //     <input type="hidden" name="orgid" value="00D5f000000KeRA" />
                                        //     <input type="hidden" name="retURL" value={process.env.REACT_APP_WEB_LINK} />

                                        //     {/* <input type="hidden" name="debug" value="1" />
                                        //         <input type="hidden" name="debugEmail" value="chandresh.kathiriya@artoon.in" /> */}
                                        //     <div className="form-group">
                                        //         <label for="name">Contact Name</label>
                                        //         <input className="form-control" id="name" maxlength="80" name="name" size="20" type="text" required="true"/>
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="email">Email</label>
                                        //         <input className="form-control" id="email" maxlength="80" name="email" size="20" type="email" required="true" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="phone">Phone</label>
                                        //         <input className="form-control" id="phone" maxlength="40" name="phone" size="20" type="text" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="recordType">Case Record Type</label>
                                        //         <select className="custom-select" id="recordType" name="recordType">
                                        //             <option value="">--None--</option>
                                        //             <option value="0125f000000cQgi">BaseballCloud</option>
                                        //             <option value="0125f000000cQh2">DiamondConnect</option>
                                        //             <option value="0125f000000cQgx">Yakkertech</option>
                                        //         </select>
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="subject">Subject</label>
                                        //         <select className="custom-select" id="subject" name="subject" required="true">
                                        //             <option value="">--None--</option>
                                        //             <option value="Stats">Stats</option>
                                        //             <option value="Profile">Profile</option>
                                        //             <option value="Sign-Up">Sign-Up</option>
                                        //             <option value="Tournament">Tournament</option>
                                        //             <option value="Showcase">Showcase</option>
                                        //             <option value="Register">Register</option>
                                        //             <option value="Other">Other</option>
                                        //         </select>
                                        //         {/* <input className="form-control" id="subject" maxlength="80" name="subject" size="20" type="text" required="true" /> */}
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="sport">Sport</label>
                                        //         <select className="custom-select" id="sport" name="sport">
                                        //             <option value="">--None--</option>
                                        //             <option value="Baseball">Baseball</option>
                                        //             <option value="Softball">Softball</option>
                                        //         </select>
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="description">Description</label>
                                        //         <textarea className="form-control" name="description" required="true"></textarea>
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <input className="btn" type="submit" name="submit" />
                                        //     </div>
                                        // </form>
                                            <form className="footer_form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                                                <div className="allfooter_url">
                                                    <h6 className="footer-title">Submit an Issue.</h6>
                                                </div>
                                                <div className="allfooter_url">
                                                    <input type="hidden" name="orgid" value="00D5f000000KeRA" />
                                                </div>
                                                <div className="allfooter_url">
                                                    <input type="hidden" name="retURL" value="https://diamondconnect.com" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="name">Contact Name</label>
                                                    <input className="form-control" id="name" maxlength="80" name="name" size="20" type="text" required="true"/>
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <label for="email">Email</label>
                                                    <input className="form-control" id="email" maxlength="80" name="email" size="20" type="text" required="true"/>
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <label for="phone">Phone</label>
                                                    <input className="form-control" id="phone" maxlength="40" name="phone" size="20" type="text" />
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <label for="recordType">Case Record Type</label>
                                                    <select className="custom-select" id="recordType" name="recordType">
                                                        <option value="0125f000000cQh2">DiamondConnect</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label for="subject">Subject</label>
                                                    <input className="form-control" id="subject" maxlength="80" name="subject" size="20" type="text" required="true"/>
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <label for="sport">Sport</label>
                                                    <select className="custom-select" id="00NAJ000000OSLj" name="00NAJ000000OSLj" title="Sport">
                                                        <option value="">--None--</option>
                                                        <option value="Baseball">Baseball</option>
                                                        <option value="Softball">Softball</option>
                                                    </select>
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <label for="description">Description</label>
                                                    <textarea className="form-control" name="description" required="true"></textarea>
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <input type="hidden" id="external" name="external" value="1" />
                                                    <br />
                                                </div>

                                                <div className="form-group">
                                                    <input className="btn" type="submit" name="submit" />
                                                </div>

                                            </form>

                                        //     :
                                        //     <form className="footer_form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                                        //         <div className="allfooter_url">
                                        //             <h6 className="footer-title">Submit an Issue.</h6>
                                        //         </div>
                                        //     <input type="hidden" name="oid" value="00D5f000000KeRA" />
                                        //     <input type="hidden" name="retURL" value={process.env.REACT_APP_WEB_LINK} />

                                        //     {/* <input type="hidden" name="debug" value="1" />
                                        //     <input type="hidden" name="debugEmail" value="chandresh.kathiriya@artoon.in" /> */}
                                        //     <div className="form-group">
                                        //         <label for="first_name">First Name</label>
                                        //         <input className="form-control" id="first_name" maxlength="40" name="first_name" size="20" type="text" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="last_name">Last Name</label>
                                        //         <input className="form-control" id="last_name" maxlength="80" name="last_name" size="20" type="text" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="email">Email</label>
                                        //         <input className="form-control" id="email" maxlength="80" name="email" size="20" type="text" required="true" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="phone">Phone</label>
                                        //         <input className="form-control" id="phone" maxlength="40" name="phone" size="20" type="text" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="city">City</label>
                                        //         <input className="form-control" id="city" maxlength="40" name="city" size="20" type="text" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="state">State/Province</label>
                                        //         <input className="form-control" id="state" maxlength="20" name="state" size="20" type="text" />
                                        //         <br />
                                        //     </div>
                                        //     <div className="form-group">
                                        //         <label for="company">Team/Organization</label>
                                        //         <input className="form-control" id="company" maxlength="40" name="company" size="20" type="text" required="true" />
                                        //         <br />
                                        //     </div>

                                        //     <div className="form-group">
                                        //         <input className="btn" type="submit" name="submit" />
                                        //     </div>

                                        // </form>
                                    }
                                    {/* <form className="footer_form">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="fullname" value={sendArr.fullname} onChange={this.onHandleChange} id="usr" placeholder="Full Name" />
                                            <span className="validMsg">{this.validator.message('fullname', sendArr.fullname, 'required|alpha_space')}</span>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" value={sendArr.email} onChange={this.onHandleChange} id="usr" placeholder="Email Address" />
                                            <span className="validMsg">{this.validator.message('email', sendArr.email, 'required|email')}</span>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="phone" value={sendArr.phone} onChange={this.onHandleChange} id="usr" placeholder="Phone Number" />
                                            <span className="validMsg">{this.validator.message('phone', sendArr.phone, 'required|phone')}</span>
                                        </div>
                                        <div className="form-group">
                                            <textarea rows="4" className="form-control" name="message" value={sendArr.message} onChange={this.onHandleChange} id="usr" placeholder="Enter Message" style={{ height: 'auto' }}></textarea>
                                            <span className="validMsg">{this.validator.message('message', sendArr.message, 'required')}</span>
                                        </div>
                                        <div className="text-right">
                                            <Link className="btn" onClick={() => this.sendContact()}>Send</Link>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>

                        <div className="footer_middle">
                            <div className="container">
                                <ul className="social-icon">
                                    <li><Link onClick={() => this.soiclFacebook("https://www.facebook.com/profile.php?id=100085569381904")}><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link onClick={() => this.soiclTwitter("https://twitter.com/DCappUS")}><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link onClick={() => this.soiclInstagram("https://www.instagram.com/dcapp_us/")}><i class="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer_after">
                            <div className="container">
                                <p className="mb-0">@ 2021 DiamondConnect. All rights reserved.</p>
                            </div>
                        </div>
                    </footer>
                }
                {
                    show &&
                    <footer className="footer-company">
                        {localStorage.getItem("company") !== "dc" && <div className="footoe-logo">
                            <img src={socialCred.logo} className={socialCred.className} />
                        </div>}
                        {localStorage.getItem("company") !== "pioneer" && <div className="footer_middle">
                            <div className="container">
                                <ul className="social-icon">
                                    {localStorage.getItem("company") !== "dc" && <li>Follow us :</li>}
                                    <li><Link onClick={() => this.soiclTwitter(socialCred.twitter)}><img src={imagesArr.c_twitter} /></Link></li>
                                    <li><Link onClick={() => this.soiclInstagram(socialCred.instagram)}><img src={imagesArr.c_instagram} /></Link></li>
                                    <li><Link onClick={() => this.soiclFacebook(socialCred.facebook)}><img src={imagesArr.c_facebook} /></Link></li>
                                </ul>
                                {localStorage.getItem("company") == "dc" &&
                                    <div className="footer_download_btn">
                                        {/* <a href="https://play.google.com/store/apps/details?id=com.baseballcloud.diamondconnect" target='_blank' className='android_link'><img src={imagesArr.playstore} /></a> */}
                                        <a href="https://apps.apple.com/us/app/diamondconnect-mobile/id1605935504" target='_blank' className='ios_link'><img src={imagesArr.appstore} className="mx-auto"/></a>
                                    </div>}
                            </div>
                        </div>}
                    </footer>
                }
                <Link id="myBtn" title="Go to top" style={{ display: 'none' }}><i className="fas fa-angle-double-up"></i></Link>
                <Preloader flag={loader} />
            </>
        );
    }
}

footer.propTypes = {
    sendContact: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    commonDetails: state.api,
});

export default connect(mapStateToProps, { sendContact })(footer);

import axios from "axios";
import { API_Url, Auth_Coach_Key, checkIsValidCoachToken } from "../../../../helpers/authToken";
import {
  PERSONALINFO_ERROR,
  PERSONALINFO_SUCCESS,
  REGISTERINFO_SUCCESS,
  REGISTERINFO_ERROR,
} from "../../../../constants/view/coach/profile/personalInfo.const";
import histrory from '../../../../../History';


export const getPersonalInfo = () => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/coach/basic`,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((personal) => {
      dispatch({
        type: PERSONALINFO_SUCCESS,
        payload: personal.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: PERSONALINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

export const postPersonalInfo = (registerData) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  return axios({
    method: "POST",
    url: `${API_Url}bc/coach/basic`,
    data: registerData,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: REGISTERINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

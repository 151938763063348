import React, { Component, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imagesArr } from "../../../../assets/images";
import { adSlider } from '../../../../saga/actions/common/slider.action';
import { COM_AD_SLIDER_SUCCESS } from '../../../../saga/constants/common/slider.const';
import Slider from "react-slick";

const initialState = {
    sliderList: [],
    banner: null
};
const reducer = (state, payload) => ({ ...state, ...payload });

function AdsSlider({ setLoader, increment, decrement, bannerReq }) {
    const dispatch = useDispatch();
    const [adsSlider, setAdsSlider] = useReducer(reducer, initialState);
    const { sliderList, banner } = adsSlider;
    const { adsSliderData: _EB } = useSelector(state => state.sliderInfo);

    useEffect(() => {
        increment();
        adSlider(bannerReq)(dispatch);
    }, [])

    useEffect(() => {
        if (_EB.length !== 0) {
            console.log(_EB);
            setAdsSlider({
                ...adsSlider,
                sliderList: _EB,
                banner: _EB[0]
            })
            dispatch({ type: COM_AD_SLIDER_SUCCESS, payload: [] })
            decrement();
        }
    }, [_EB])

    const renderSlider = () => {
        return (
            sliderList.length !== 0 && sliderList.map(banner => {
                return (
                    <div className="slider-items" onClick={() => banner?.destination ? window.open(banner?.destination, "_blank") : ""}>
                    {banner.media?.hasOwnProperty('_id') ?
                            <img src={banner?.media?.filePath} alt="banner_slider1" />
                            :
                            <div className="no-ads">
                                <img src={imagesArr.no_ads} />
                            </div>
                        }
                    </div>
                )
            })
        )
    }
    const addslider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true

    };
    return (
        <div className="banner_section">
            <div className="addslider">
                <Slider {...addslider}>
                    {renderSlider()}
                </Slider>
            </div>

        </div>
    )
}

export default AdsSlider;

import React, { Component } from 'react';
import { imagesArr } from '../assets/images';

class preloader extends Component {
    render() {
        const { flag } = this.props
        return (
            <>
                {
                    flag &&
                    <div className="loader">
                        <img src={imagesArr.loader} />
                    </div>
                }
            </>
        );
    }
}

export default preloader;

import React, { Component } from 'react';

export default class shop extends Component {
    componentDidMount = () => {
        // this code is for product listing 
        if (this.props.cType !== "utb") {
            var pwbShop = document.getElementById("sqd-container");
            if (pwbShop.length !== 0) {
                this.loadShoppingSection(() => {
                    new window.sqd({
                        container: 'sqd-container',
                        moduleType: 'standard',
                        lockerId: this.props.cType === "pwb" ? '294094' : this.props.cType === "pws" ? '303488' : this.props.cType === "gob" ? '303500' : '294094',
                        environment: 'production',
                        theme: {
                            fontFamily: "'Lato', sans-serif",
                            color: '#2D3237',
                            backgroundColor: '#FFFFFF',
                            accentColor: 'var(--green-color)'
                        }
                    });
                    let sqd_cta__app = document.getElementById('sqd-cta__app');
                    sqd_cta__app.style['box-shadow'] = "none";
                });
            }
        }
    }

    loadShoppingSection = (callback) => {
        const existingScript = document.getElementById('shopScript');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = '../../../../assets/js/sqd.js';
            script.id = 'shopScript';
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    };

    render() {
        const { cType } = this.props;
        return (
            <div className="container">
                <div className="upcoming_events_title">
                    <h1 className="section_title">Shop</h1>
                </div>
                {
                    cType === "utb" ?
                        <p className="text-center mt-20">Waiting for USA Travel Ball script</p>
                        :
                        <div id="sqd-container"></div>
                }
            </div>
        )
    }
}

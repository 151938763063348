import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AddPlayer from "../../popup/addPlayer";
import Confirmation from "../../popup/confirmation";
import DisplayRoster from "../../popup/displayRoster";
import { getRosterPlayer, getOption, deleteRosterPlayer, getLinkData, sendAllMailPlayer } from '../../../saga/actions/views/coach/profile/playerInfo.action';
import { sendMailRosterPlayer } from '../../../saga/actions/views/coach/profile/rosterInfo.action';
import Preloader from '../../../components/preloader';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import AddPlayerManually from "../../popup/addPlayerManually";

const animatedComponents = makeAnimated();

export class PlayerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareBtn: false,
      loader: false,
      filter: {
        rosterGroupIdArr: [],
        page: 1,
        limit: 10,
        search: ""
      },
      playerList: [],
      optionsArr: [],
      optionData: "",
      addPlayer: false,
      addType: "",
      sendData: "",
      did: "",
      deletePopup: false,
      disRoster: false,
      disData: [],

      // pagination variable diclaration
      offset: 0,
      perPage: 10,
      currentPage: "",
      index: "",
      batchUpload: false,

      tabsupload: 0,
      rosterList: [],
      sendPopVal: [],
      actionType: "",
      popupFlag: false,
      indexVal: "",
      openConfimation: false,
      isAllCheck: false,
      mailConfirmation: false,
      allMailConfirmation: false,
      recIndex: "",
      playerId: "",
      isMLB: false,
      uid: "",
      parentTeam:""
    };

    // pagination handler function
    this.handlePageClick = this.handlePageClick.bind(this);

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.setLoader(true);
    this.props.getRosterPlayer(this.state.filter);
    this.props.getOption();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.playerDetails &&
      nextProps.playerDetails.getPlayerReq &&
      nextProps.playerDetails.getPlayerData
    ) {
      console.log("nextProps.getPlayerData:-----", nextProps.playerDetails.getPlayerData);
      nextProps.playerDetails.getPlayerReq = false;
      if (nextProps.playerDetails.getPlayerData.flag) {
        this.setState({
          pageCount: Math.ceil(nextProps.playerDetails.getPlayerData.result.total / this.state.perPage),
          playerList: nextProps.playerDetails.getPlayerData.result.docs,
          isMLB: nextProps.playerDetails.getPlayerData.result.isMLB,
          isAllCheck: nextProps.playerDetails.getPlayerData.result.sendMail,
          parentTeam:nextProps.playerDetails.getPlayerData.result?.parentTeam?.type
        }, () => {
          nextProps.playerDetails.getPlayerData = null;
          this.setLoader(false);
        })
      } else {
        // this.commonCall.errorMessage(nextProps.playerDetails.getPlayerData.message);
        nextProps.playerDetails.getPlayerData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.getOptionReq &&
      nextProps.playerDetails.getOptionData
    ) {
      console.log("nextProps.getOptionData:-----", nextProps.playerDetails.getOptionData);
      nextProps.playerDetails.getOptionReq = false;
      if (nextProps.playerDetails.getOptionData.flag) {
        let arr = [];
        nextProps.playerDetails.getOptionData.result.data.map((item, i) => {
          arr.push({ value: item._id, label: item.rosterName })
        });
        // [
        //   { value: "blue", label: "Blue Team", color: "#0052CC" },
        //   { value: "purple", label: "Purple Team", color: "#5243AA" },
        //   { value: "red", label: "Red Team", color: "#FF5630" },
        //   { value: "orange", label: "Orange Team", color: "#FF8B00" },
        //   { value: "yellow", label: "Yellow Team", color: "#FFC400" },
        //   { value: "green", label: "Green Team", color: "#36B37E" },
        //   { value: "forest", label: "Forest Team", color: "#00875A" },
        //   { value: "slate", label: "Slate Team", color: "#253858" },
        //   { value: "silver", label: "Silver Team", color: "#666666" },
        //   { value: "ocean", label: "Ocean Team", color: "#00B8D9" },
        // ]
        this.setState({
          optionsArr: arr,
        }, () => {
          nextProps.playerDetails.getOptionData = null;
          this.setLoader(false);
        })
      } else {
        nextProps.playerDetails.getOptionData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.delNewPlayerReq &&
      nextProps.playerDetails.delNewPlayerData
    ) {
      console.log("nextProps.delNewPlayerData:-----", nextProps.playerDetails.delNewPlayerData);
      nextProps.playerDetails.delNewPlayerReq = false;
      if (nextProps.playerDetails.delNewPlayerData.flag) {
        this.commonCall.successMessage(nextProps.playerDetails.delNewPlayerData.message);
        nextProps.playerDetails.delNewPlayerData = null;
        this.setState({
          did: "",
          deletePopup: false
        }, () => {
          document.body.classList.remove("modal-open");
          this.props.getRosterPlayer(this.state.filter);
        })
      } else {
        // this.commonCall.errorMessage(nextProps.playerDetails.delNewPlayerData.message);
        nextProps.playerDetails.delNewPlayerData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.getLinkPlayerReq &&
      nextProps.playerDetails.getLinkPlayerData
    ) {
      console.log("nextProps.getLinkPlayerData:-----", nextProps.playerDetails.getLinkPlayerData);
      nextProps.playerDetails.getLinkPlayerReq = false;
      if (nextProps.playerDetails.getLinkPlayerData.flag) {
        if (nextProps.playerDetails.getLinkPlayerData.result.hasOwnProperty('url')) {
          // window.open(nextProps.playerDetails.getLinkPlayerData.result.url, '_blank');
          const link = document.createElement('a');
          link.dataType = "json";
          link.href = nextProps.playerDetails.getLinkPlayerData.result.url;
          link.download = "report.pdf";
          link.target = "_blank";
          link.click();
        }
        this.commonCall.successMessage(nextProps.playerDetails.getLinkPlayerData.message);
        nextProps.playerDetails.getLinkPlayerData = null;
        this.setLoader(false);
      } else {
        // this.commonCall.errorMessage(nextProps.playerDetails.getLinkPlayerData.message);
        nextProps.playerDetails.getLinkPlayerData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.rosterInfo &&
      nextProps.rosterInfo.mailRosterInfoData) {
      if (nextProps.rosterInfo.mailRosterInfoData.flag) {
        this.setState({
          mailConfirmation: false,
        }, () => {
          document.body.classList.remove("modal-open");
          this.setLoader(false);
          nextProps.rosterInfo.mailRosterInfoData = null;
        })
      } else {
        this.commonCall.errorMessage(nextProps.rosterInfo.mailRosterInfoData.message);
        nextProps.rosterInfo.mailRosterInfoData = null;
        this.state.playerList.forEach((item, j) => {
          if (this.state.recIndex === j) {
            Object.assign(item, { isMail: !item.isMail });
          }
        });
        this.setState({
          playerList: this.state.playerList
        }, () => {
          this.setLoader(false);
        })
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.getAllCheckInfoPlayerData) {
      if (nextProps.playerDetails.getAllCheckInfoPlayerData.flag) {
        this.setState({
          allMailConfirmation: false,
        }, () => {
          document.body.classList.remove("modal-open");
          this.setLoader(false);
          nextProps.playerDetails.getAllCheckInfoPlayerData = null;
        })
      } else {
        this.commonCall.errorMessage(nextProps.playerDetails.getAllCheckInfoPlayerData.message);
        nextProps.playerDetails.getAllCheckInfoPlayerData = null;
        this.state.playerList.forEach((item, j) => {
          Object.assign(item, { isMail: false });
        });
        this.setState({
          playerList: this.state.playerList
        }, () => {
          this.setLoader(false);
        })
      }
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  chkHandleChange = (e, i, pid) => {
    if (!JSON.parse(e.target.value)) {
      this.setState({
        recIndex: i,
        playerId: pid,
        mailConfirmation: true
      })
    } else {
      this.state.playerList.forEach((item, j) => {
        if (i === j) {
          Object.assign(item, { isMail: !item.isMail });
        }
      });
      this.setState({
        playerList: this.state.playerList
      }, () => {
        this.setLoader(true);
        this.props.sendMailRosterPlayer({ rosterPlayerId: pid, isMail: false })
      })
    }
  }

  renderPlayerList = () => {
    return (
      this.state.playerList.length !== 0 ?
        this.state.playerList.map((list, i) => {
          return (
            <>
              <tr>
                {/* <th>{i + 1}</th> */}
                <td>{list.fname + " " + list.lname}</td>
                <td>{list.email}</td>
                <td>{list.phone ? list.cCode + list.phone : "-"}</td>
                {/* <td className="common_btn">
                  <Link className="blue_btn w-100" onClick={() => this.addNewPlayer('edit', list, i)}>Edit Profile</Link>
                </td> */}
                <td className="common_btn">
                  <Link className="red_btn w-100" onClick={() => this.delPlayer(list)}> Delete</Link>
                </td>
                <td>
                {  list?.rosterTeamId?.length ? 
                    list.rosterTeamId.map((r) => (r.rosterName))
                    .reduce((prev, curr) => [prev, ", ", curr]):null}
                  <Link className="edit_icon" onClick={() => this.disRoster(list)}><i class="fas fa-pencil-alt"></i> Edit</Link>
                </td>
                {
                  this.state.isMLB &&
                  <td className="common_btn toggle-btn">
                    {
                      list._id &&
                      <label class="switch switch-default switch-pill switch-primary">
                        <input type="checkbox" value={list.isMail} checked={list.isMail} className="switch-input" onChange={(e) => this.chkHandleChange(e, i, list._id)} />
                        <span class="switch-label"></span>
                        <span class="switch-handle"></span>
                      </label>
                    }
                  </td>
                }
              </tr>
            </>
          );
        }) :
        <tr>
          <td colSpan="10" className="text-center">No Data Found</td>
        </tr>
    )
  }

  handleChange = (option) => {
    console.log(`Option selected:`, option);
  };

  setOption = (val) => {

    console.log("val:--", val);
    if (val !== null) {
      let valArr = [];
      val.map((item, i) => {
        valArr.push(item.value)
      });

      this.setState((prevState) => ({
        ...prevState,
        filter: {
          rosterGroupIdArr: valArr,
          page: 1,
          limit: 10
        },
        optionData: val
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        optionData: []
      }));
    }
  }

  resetSearch = () => {
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        rosterGroupIdArr: [],
        page: 1,
        limit: 10,
        search: ""
      },
      optionData: []
    }), () => {
      this.props.getRosterPlayer(this.state.filter);
    });
  }

  addNewPlayer = (type, data, i) => {
    this.setState({
      addPlayer: true,
      addType: type,
      sendData: type === "edit" ? data : "",
      index: i
    })
  }

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      addPlayer: false,
      addType: ""
    }, () => {
      this.setLoader(true);
      this.props.getRosterPlayer(this.state.filter);
    })
  }

  delPlayer = (data) => {
    console.log("data:--", data);
    this.setState({
      did: data._id,
      deletePopup: true
    })
  }

  closePopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      did: "",
      deletePopup: false
    }, () => {
      this.setLoader(true);
      this.props.getRosterPlayer(this.state.filter);
    })
  }

  confirmPopup = () => {
    console.log(":this.state.did:---", this.state.did);
    this.setLoader(true);
    this.props.deleteRosterPlayer({
      _id: this.state.did
    })
  }

  disRoster = (data) => {
    console.log("data:----", data);
    this.setState({
      disRoster: true,
      disData: data
    })
  }

  closeDisPopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      disRoster: false,
      disData: []
    }, () => {
      this.setLoader(true);
      this.props.getRosterPlayer(this.state.filter);
    })
  }

  getLink = (type) => {
    let data = {
      rosterGroupIdArr: this.state.optionData.length !== 0 ? this.state.filter.rosterGroupIdArr : [],
      type: type === "pdf" ? 1 : 2
    }
    this.setLoader(true);
    this.props.getLinkData(data);
  }

  handlePageClick = (e) => {
    console.log("e:----------------------------", e);

    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.setState((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            page: selectedPage + 1,
            limit: this.state.perPage
          },
        }), () => {
          this.setLoader(true);
          this.props.getRosterPlayer(this.state.filter);
        });
      }
    );
  };

  handleTabsUpload = (i) => {
    this.setState({
      tabsupload: i
    })
  }

  uploadFile = (ev) => {
    ev.preventDefault();
    if (ev.target.files[0].name.match(new RegExp('[^.]+$'))[0] !== "csv") {
      return this.commonCall.errorMessage("Accept only CSV file");
    }
    var files = ev.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = (ev) => {
      var data = ev.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      var rosters = [];
      const re = /^[0-9\b]+$/;
      let isValid = true;

      let breakCondition = false;
      dataParse.forEach((item, i) => {
        //no duplicate record in player list
        if (!!this.state.rosterList.find((e) => e.email === item[3]) && !breakCondition && i != 0) {
          breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ",Email already exits.")
        }
        //no duplicate record in CSV file
        if (!!dataParse.find((e, j) => { if (j !== i) { if (e[3] === item[3]) { return true } else return false } }) && !breakCondition && i != 0) {
          breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ",which has a duplicate email")
        }
        if (item[0] === undefined && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ", first name is required field."); };
        if (item[1] === undefined && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ", last name is required field."); };
        if (item[3] === undefined && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ", email is required field."); };
        if (item[4] === undefined && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ", number is required field."); };
        if (item[5] === undefined && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ", position is required field."); };
        if (this.setDate(item[7]) === undefined && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ", birthday is required field."); };
        if (item[2] !== undefined && !breakCondition) {
          if (!breakCondition && item[2].toString().length <= 12 && i != 0) {
            if (!re.test(item[2]) || item[2].length <= 0) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ",Enter valid phone number.");; }
          }
          if (!breakCondition && item[2].toString().length > 12 && i != 0) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ",Enter valid phone number."); }
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(item[3]) && i != 0 && !breakCondition) { breakCondition = true; isValid = false; return this.commonCall.errorMessage("Please check row " + i + ",Enter valid email."); }
        var roster = {};
        roster.fname = item[0];
        roster.lname = item[1];
        // roster.country_code = "+1";
        // roster.phone = item[2];
        roster.email = item[3];
        // roster.number = item[4];
        // roster.position = item[5];
        // roster.position2 = item[6];
        // roster.dob = this.setDate(item[7]);
        // roster.role = "Player";
        rosters.push(roster);

      });
      rosters.splice(0, 1);

      if (isValid) {
        this.setState({
          rosterList: rosters,
          tabsupload: 1,
        })
      }
    };
    reader.readAsBinaryString(f);
  };

  setDate = (date) => {
    let fDate = XLSX.SSF.format("yyyy-mm-dd", date);
    return fDate;
  };

  setPopup = (data, type, i) => {
    if (type === "add") {
      this.setState({
        sendPopVal: [],
        actionType: type,
        popupFlag: true,
      })
    } else if (type === "edit") {
      this.setState({
        sendPopVal: data,
        actionType: type,
        indexVal: i,
        popupFlag: true,
      })
    } else {
      this.setState({
        sendPopVal: [],
        actionType: type,
        indexVal: i,
        openConfimation: true,
      })
    }
  }

  onHandleClose = () => {
    this.setState({
      actionType: "",
      popupFlag: false,
    }, () => {
      document.body.classList.remove("modal-open");
    })
  }

  handleSaveRoster = (save) => {
    let arr = [];
    if (this.state.actionType === "add") {
      this.setState({
        rosterList: [...this.state.rosterList, save],
      }, () => {
        this.onHandleClose();
      })
    } else {
      this.state.rosterList[this.state.indexVal] = save;
      this.onHandleClose();
    }
  }

  closeDeletePopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      indexVal: "",
      openConfimation: false
    })
  }

  hanldeDeleteManually = () => {
    this.state.rosterList.splice(this.state.indexVal, 1);
    this.closeDeletePopup();
  }

  saveAllRoster = () => {
    if (this.state.rosterList.length !== 0) {
      console.log("this.state.rosterList:----", this.state.rosterList);
    } else {
      this.commonCall.errorMessage("Please add player details");
    }
  }

  closeMail = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      mailConfirmation: false
    })
  }

  hanldeMail = () => {
    this.state.playerList.forEach((item, j) => {
      if (this.state.recIndex === j) {
        Object.assign(item, { isMail: !item.isMail });
      }
    });
    this.setState({
      playerList: this.state.playerList
    }, () => {
      this.setLoader(true);
      this.props.sendMailRosterPlayer({ rosterPlayerId: this.state.playerId, isMail: true })
    })
  }

  allChkHandle = (e) => {
    console.log("value:---", !JSON.parse(e.target.value));
    this.setState({
      isAllCheck: !this.state.isAllCheck
    })
    if (!JSON.parse(e.target.value)) {
      this.setState({
        uid: this.props.userId,
        allMailConfirmation: true
      })
    } else {
      this.state.playerList.forEach((item, j) => {
        Object.assign(item, { isMail: false });
      });
      this.setState({
        playerList: this.state.playerList
      }, () => {
        this.setLoader(true);
        this.props.sendAllMailPlayer({ isMail: false });
      });
    }
  }

  closeAllMail = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      isAllCheck: !this.state.isAllCheck,
      allMailConfirmation: false,
      uid: ""
    })
  }

  hanldeAllMail = () => {
    this.state.playerList.forEach((item, j) => {
      Object.assign(item, { isMail: true });
    });
    this.setState({
      playerList: this.state.playerList
    }, () => {
      this.setLoader(true);
      this.props.sendAllMailPlayer({ isMail: true });
    });
  }

  handleSearch = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        page: 1,
        search: e.target.value
      }
    }))
  }

  render() {
    const { loader, optionsArr, addPlayer, addType, sendData, deletePopup, disRoster, disData, playerList, batchUpload,
      tabsupload, rosterList, sendPopVal, actionType, popupFlag, openConfimation, indexVal, isAllCheck, mailConfirmation,
      isMLB, allMailConfirmation } = this.state;
    return (

      <>
        <section className="before-dashboard">
          <div className="white-bg">
            <div className="facilitytab add_playerinfo">
              <div className="pleayer_info_features">
                <div className="serach_filter">
                  <Select
                    defaultValue={this.state.optionData}
                    value={this.state.optionData}
                    options={optionsArr}
                    onInputChange={this.handleChange}
                    onChange={(e) => this.setOption(e)}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                  />
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="search" value={this.state.filter.search} onChange={this.handleSearch} />
                  </div>
                  <Link className="btn" onClick={() => this.props.getRosterPlayer(this.state.filter)}>Search</Link>
                  <Link className="red_btn" onClick={() => this.resetSearch()}>Reset</Link>
                </div>

                <div className="add_player">
                  {/* <Link className="pr-10" onClick={() => this.setState({ batchUpload: !batchUpload,tabsupload: 0 })}>
                        Batch Upload
                      </Link> */}
                  <a className="gmail" onClick={() => this.getLink('pdf')}>
                    <i class="fas fa-download"></i>
                  </a>
                  {/* <Link className="gmail" onClick={() => this.getLink('email')}>
                    <i class="fas fa-envelope-square"></i>
                  </Link> */}
                  <Link className="btn" onClick={() => this.addNewPlayer('add')}>Add New Player</Link>
                </div>


              </div>
              {/* <div className="serach_filter">
                  
                  <Link className="btn" onClick={() => this.props.getRosterPlayer(this.state.filter)}>find</Link>
                  <Link className="red_btn" onClick={() => this.props.getRosterPlayer(this.state.filter)}>clear</Link>
                </div> */}
              <div className="tabsfacility-view">
                <div className="manage_roster">
                  <div className="table-responsive-xl roster-table">
                    <table className="table manag-roster-table">
                      <thead>
                        <tr>
                          {/* <th scope="col">No.</th> */}
                          <th scope="col">Player Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th colSpan="2">Action</th>
                          <th scope="col">Roster</th>
                          {
                            isMLB &&
                            <th className="toggle-btn">
                              Email
                              <label class="switch switch-default switch-pill switch-primary">
                                <input type="checkbox" value={isAllCheck} checked={isAllCheck} onChange={(e) => this.allChkHandle(e)} className="switch-input" />
                                <span class="switch-label"></span>
                                <span class="switch-handle"></span>
                              </label>
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderPlayerList()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {playerList.length !== 0 && (
                  <div className="pagination">
                    <ReactPaginate
                      previousLabel={<i class="fas fa-chevron-left"></i>}
                      nextLabel={<i class="fas fa-chevron-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {
          addPlayer &&
          <AddPlayer
            flag={addPlayer}
            close={this.handleClose}
            type={addType}
            editData={sendData}
            playerList={this.state.playerList}
            index={this.state.index}
            parentTeam={this.state.parentTeam}
          />
        }
        {
          deletePopup &&
          <Confirmation
            flag={deletePopup}
            type="player"
            close={this.closePopup}
            ok={this.confirmPopup}
          />
        }
        {
          disRoster &&
          <DisplayRoster
            flag={disRoster}
            close={this.closeDisPopup}
            disData={disData}
          />
        }
        {popupFlag && (
          <AddPlayerManually
            flag={popupFlag}
            close={this.onHandleClose}
            type={actionType}
            handleSaveRoster={this.handleSaveRoster}
            record={sendPopVal}
            rosterList={rosterList}
            indexVal={indexVal}
          />
        )}
        {
          openConfimation &&
          <Confirmation
            flag={openConfimation}
            close={this.closeDeletePopup}
            type="player"
            ok={this.hanldeDeleteManually}
          />
        }
        {
          mailConfirmation &&
          <Confirmation
            flag={mailConfirmation}
            close={this.closeMail}
            type="isMail"
            ok={this.hanldeMail}
          />
        }
        {
          allMailConfirmation &&
          <Confirmation
            flag={allMailConfirmation}
            close={this.closeAllMail}
            type="allMail"
            ok={this.hanldeAllMail}
          />
        }
        <Preloader flag={loader} />
      </>
    );
  }
}

PlayerInfo.propTypes = {
  getRosterPlayer: PropTypes.func.isRequired,
  getOption: PropTypes.func.isRequired,
  deleteRosterPlayer: PropTypes.func.isRequired,
  getLinkData: PropTypes.func.isRequired,
  sendMailRosterPlayer: PropTypes.func.isRequired,
  sendAllMailPlayer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  playerDetails: state.playerInfo,
  rosterInfo: state.rosterInfo
});

export default connect(mapStateToProps, { getRosterPlayer, getOption, deleteRosterPlayer, getLinkData, sendMailRosterPlayer, sendAllMailPlayer })(PlayerInfo);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../../../businesslogic/commonCalls";
import { saveContactForm } from '../../../../saga/actions/common/contactUs.action';

class contactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                fullName: "",
                email: "",
                message: "",
                company: this.props.cType
            }
        };

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.contactForm &&
            nextProps.contactForm.saveContactFormReq &&
            nextProps.contactForm.saveContactFormData
        ) {
            nextProps.contactForm.saveContactFormReq = false;
            if (nextProps.contactForm.saveContactFormData.flag) {
                this.commonCall.successMessage(nextProps.contactForm.saveContactFormData.message);
                nextProps.contactForm.saveContactFormData = null;
                this.setState({
                    sendReq: {
                        fullName: "",
                        email: "",
                        message: "",
                        company: this.props.cType
                    }
                }, () => {
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.contactForm.saveContactFormData.message);
                nextProps.contactForm.saveContactFormData = null;
                this.props.decrement();
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: value
            }
        })
    }

    sendMessage = () => {
        if (this.validator.allValid()) {
            console.log("this.state.sendReq:--", this.state.sendReq);
            this.props.increment();
            this.props.saveContactForm(this.state.sendReq);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { sendReq } = this.state;
        return (
            <div className="contact-us">
                <div className="container">
                    <h1 className="section_title">Contact Us</h1>
                    <p>Got a question? We'd love to hear from you. Send us a message and we'll respond as soon as possible.</p>
                    <form className="text-center">
                        <div className="row ">
                            <div className="w-50">
                                <div className="form-group">
                                    <input name="fullName" value={sendReq.fullName} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter Name" />
                                    <span className="validMsg" style={{ color: 'red' }}>{this.validator.message('Name', sendReq.fullName, 'required|alpha_space')}</span>
                                </div>
                            </div>
                            <div className="w-50">
                                <div className="form-group">
                                    <input name="email" value={sendReq.email} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter Email" />
                                    <span className="validMsg" style={{ color: 'red' }}>{this.validator.message('Email', sendReq.email, 'required|email')}</span>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <textarea name="message" value={sendReq.message} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Message"></textarea>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn" onClick={() => this.sendMessage()}>Send Message</button>
                    </form>
                </div>
            </div>
        )
    }
}

contactUs.propTypes = {
    saveContactForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    contactForm: state.contactUsForm,
});
export default connect(mapStateToProps, { saveContactForm })(contactUs);
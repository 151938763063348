import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../components/commonHeroBanner';
import PersonalInfo from './profile/personalInfo';
import OtherInfo from './profile/otherInfo';
import GameBackground from './profile/gameBackground';
import TrainingInfo from './profile/trainingInfo';
import GameStats from './profile/gameStats';
import commonCalls from '../../businesslogic/commonCalls';
import ParentInfo from './profile/parentInfo';
import BillingInfo from './profile/billingInfo';


class playerSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: 0
        }
        this.commonCall = new commonCalls();

    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.commonCall.getStorage("playerTab", data => {
            if (data !== null) {
                console.log("flag::data:---", data);
                this.setState({
                    tabs: data === 'Other' ? 1 : data === 'profile' ? 0 : data === 'training' ? 2 : data === 'gameBackground' ? 3 : data === 'gameStats' ? 4 : 0
                }, () => {
                    this.commonCall.removeStorage("playerTab");
                })
            }
        });
    }

    handleTabs = (e) => {
        this.setState({
            tabs: e
        })
    }
    render() {
        const tabs = this.state.tabs;
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                <section className="main_pro_tab">
                    <div className="container">
                        <div className="edit_pro_scroll">
                            <ul className="edit_pro_inner">
                                <li className={`${tabs === 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                    <Link>Personal Info</Link>
                                </li>
                                <li className={`${tabs === 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                    <Link>Parent Info</Link>
                                </li>
                                <li className={`${tabs === 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                    <Link>Signup Questions</Link>
                                </li>
                                <li className={`${tabs === 3 ? "active" : ""}`} onClick={() => this.handleTabs(3)}>
                                    <Link>Billing</Link>
                                </li>
                                <li className={`${tabs === 4 ? "active" : ""}`} onClick={() => this.handleTabs(4)}>
                                    <Link>Game Stats</Link>
                                </li>
                            </ul>
                        </div>

                        {tabs === 0 && <PersonalInfo />}
                        {tabs === 1 && <ParentInfo/>}
                        {tabs === 2 && <TrainingInfo />}
                        {tabs === 3 && <BillingInfo/>}
                        {tabs === 4 && <GameStats />}
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default playerSetting;

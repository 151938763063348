import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';
import Slider from "react-slick";
import { getCart } from "../../saga/actions/views/player/cart.action";
import Preloader from '../../components/preloader';
import VideoPlayer from '../popup/videoPlayer';


class sharecart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            videoPopup: false,
            file: null,
            loader: false,
            uid: "",
            userInfo: {
                fname: "",
                lname: "",
                profileUrl: "",
                age: "",
                weight: "",
                height: "",
                team: "",
                city: "",
                state: ""
            },
            battingStats: [],
            pitchingStats: [],
            catchingStats: [],
            fieldingStats: [],
            collage: [],
            videoList: [],
            showVideo: [],
        }
        // this.commonCall = new commonCalls();
    }

    componentDidMount() {
        this.setLoader(true);
        this.getUserDetails();
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.cartDetails &&
            nextProps.cartDetails.cartReq &&
            nextProps.cartDetails.cartData
        ) {
            nextProps.cartDetails.cartReq = false;
            console.log("nextProps.cartDetails.cartData:--", nextProps.cartDetails.cartData);
            if (nextProps.cartDetails.cartData.flag) {
                this.setState((prevState) => ({
                    ...prevState,
                    userInfo: {
                        ...prevState.userInfo,
                        fname: nextProps.cartDetails.cartData.result.fname ? nextProps.cartDetails.cartData.result.fname : "",
                        lname: nextProps.cartDetails.cartData.result.lname ? nextProps.cartDetails.cartData.result.lname : "",
                        profileUrl: nextProps.cartDetails.cartData.result.profileUrl ? nextProps.cartDetails.cartData.result.profileUrl : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png",
                        age: nextProps.cartDetails.cartData.result.age,
                        weight: nextProps.cartDetails.cartData.result.weight ? nextProps.cartDetails.cartData.result.weight : "",
                        height: nextProps.cartDetails.cartData.result.height ? nextProps.cartDetails.cartData.result.height : "",
                        team: nextProps.cartDetails.cartData.result.team.length !== 0 ? nextProps.cartDetails.cartData.result.team[0].parentTeam.name : "",
                        city: nextProps.cartDetails.cartData.result.address ? nextProps.cartDetails.cartData.result.address.city : "",
                        state: nextProps.cartDetails.cartData.result.address ? nextProps.cartDetails.cartData.result.address.state : ""
                    },
                    battingStats: nextProps.cartDetails.cartData.result['batting-stats'] ? nextProps.cartDetails.cartData.result['batting-stats'] : [],
                    pitchingStats: nextProps.cartDetails.cartData.result['pitching-stats'] ? nextProps.cartDetails.cartData.result['pitching-stats'] : [],
                    catchingStats: nextProps.cartDetails.cartData.result['catching-stats'] ? nextProps.cartDetails.cartData.result['catching-stats'] : [],
                    fieldingStats: nextProps.cartDetails.cartData.result['fielding-stats'] ? nextProps.cartDetails.cartData.result['fielding-stats'] : [],
                    collage: nextProps.cartDetails.cartData.result['fav-colleges'] ? nextProps.cartDetails.cartData.result['fav-colleges'] : [],
                    videoList: nextProps.cartDetails.cartData.result.cVideos ? nextProps.cartDetails.cartData.result.cVideos : [],
                }), () => {
                    nextProps.cartDetails.cartData = null;
                    this.setLoader(false);

                });
            }

        }

    }

    getUserDetails = () => {
        let url = new URL(document.location);
        let userid = url.pathname.split("/")[2];
        this.setState({
            uid: userid
        }, () => {
            this.props.getCart({
                playerId: userid
            });
        })
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    renderCollageList = () => {
        return (
            this.state.collage &&
            this.state.collage.map((list, i) => {
                return (
                    <div className="collage_logo_img">
                        <img src={list.image && list.image.filePath ? list.image.filePath : imagesArr.collage_name1} alt="collage_name1" />
                    </div>
                );
            })
        );
    }

    calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        return (age_now === 0) ? "'00" : "'" + age_now;
    }

    openVideoPopup = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            videoPopup: true,
            showVideo: [...prevState.showVideo, e]
        }))
    }

    closePopup = () => {
        document.body.classList.remove('video-open');
        this.setState({
            videoPopup: false,
            showVideo: []
        })
    }

    render() {

        const { loader, userInfo, battingStats, pitchingStats, catchingStats, fieldingStats, videoList } = this.state;

        var video_slide = {
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            initialSlide: 0,
            arrows: false,
            autoplay: false,
            variableWidth: true,
        };
        return (
            <>
                <section className="playercart">
                    <div className="container">
                        <div className="white-bg">
                            <div className="car_profile">
                                <div className="car_profile_img">
                                    <div className="car_profile_images">
                                        <img src={userInfo.profileUrl} alt="cart_user" />
                                    </div>
                                    <div className="car_profile_name">
                                        <h4>{userInfo.fname + " " + userInfo.lname}</h4>
                                        <p>{userInfo.team}</p>
                                        {/* <Link className="blue_btn" To="/">Share</Link> */}
                                    </div>
                                </div>
                                <div className="car_profile_dec">
                                     <Link to={"/"} className="cart-logo">
                                        <img src={imagesArr.footer_logo} alt="logo" />
                                    </Link>
                                    <div className="car_profile_dec_innner">
                                        <ul>
                                            <li><span>Age :</span>{userInfo.age}</li>
                                            <li><span>Weight :</span>{userInfo.weight}</li>
                                            <li><span>Height :</span>{userInfo.height}</li>
                                        </ul>
                                        <ul>
                                            <li><span>Team :</span>{userInfo.team ? userInfo.team : "-"}</li>
                                            <li><span>City :</span>{userInfo.city}</li>
                                            <li><span>State :</span>{userInfo.state}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="w-50">
                                    <label className="common-lable">Batting Stats</label>
                                    {
                                        battingStats.length !== 0 ?
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">G</th>
                                                                <th scope="col">AB</th>
                                                                <th scope="col">R</th>
                                                                <th scope="col">AVG</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{battingStats && battingStats.G['2020'] ? battingStats.G['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.AB['2020'] ? battingStats.AB['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.R['2020'] ? battingStats.R['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.AVG['2020'] ? battingStats.AVG['2020'] : "00"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">H</th>
                                                                <th scope="col">RBI</th>
                                                                <th scope="col">BB</th>
                                                                <th scope="col">OBP</th>
                                                                <th scope="col">K's</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{battingStats && battingStats.H['2020'] ? battingStats.H['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.RBI['2020'] ? battingStats.RBI['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.BB['2020'] ? battingStats.BB['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.OBP['2020'] ? battingStats.OBP['2020'] : "00"}</td>
                                                                <td>{battingStats && battingStats.hasOwnProperty("K's") && battingStats["K's"]['2020'] ? battingStats["K's"]['2020'] : "00"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">G</th>
                                                                <th scope="col">AB</th>
                                                                <th scope="col">R</th>
                                                                <th scope="col">AVG</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td colspan="4">Not entered yet.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">H</th>
                                                                <th scope="col">RBI</th>
                                                                <th scope="col">BB</th>
                                                                <th scope="col">OBP</th>
                                                                <th scope="col">K's</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr >
                                                                <td colspan="5">Not entered yet.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                    }
                                    <label className="common-lable">Pitching</label>
                                    {
                                        pitchingStats.length !== 0 ?
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">IP</th>
                                                                <th scope="col">H</th>
                                                                <th scope="col">R</th>
                                                                <th scope="col">ER</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{pitchingStats && pitchingStats.IP['2020'] ? pitchingStats.IP['2020'] : "00"}</td>
                                                                <td>{pitchingStats && pitchingStats.H['2020'] ? pitchingStats.H['2020'] : "00"}</td>
                                                                <td>{pitchingStats && pitchingStats.R['2020'] ? pitchingStats.R['2020'] : "00"}</td>
                                                                <td>{pitchingStats && pitchingStats.ER['2020'] ? pitchingStats.ER['2020'] : "00"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">K</th>
                                                                <th scope="col">BB</th>
                                                                <th scope="col">HR</th>
                                                                <th scope="col">HB</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{pitchingStats && pitchingStats.K['2020'] ? pitchingStats.K['2020'] : "00"}</td>
                                                                <td>{pitchingStats && pitchingStats.BB['2020'] ? pitchingStats.BB['2020'] : "00"}</td>
                                                                <td>{pitchingStats && pitchingStats.HR['2020'] ? pitchingStats.HR['2020'] : "00"}</td>
                                                                <td>{pitchingStats && pitchingStats.HB['2020'] ? pitchingStats.HB['2020'] : "00"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">IP</th>
                                                                <th scope="col">H</th>
                                                                <th scope="col">R</th>
                                                                <th scope="col">ER</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">Not entered yet.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">K</th>
                                                                <th scope="col">BB</th>
                                                                <th scope="col">HR</th>
                                                                <th scope="col">HB</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">Not entered yet.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                    }

                                    <label className="common-lable">Catching</label>
                                    {
                                        catchingStats.length !== 0 ?
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">INN</th>
                                                                <th scope="col">PO</th>
                                                                <th scope="col">E</th>
                                                                <th scope="col">CS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{catchingStats && catchingStats.INN['2020'] ? catchingStats.INN['2020'] : "00"}</td>
                                                                <td>{catchingStats && catchingStats.PO['2020'] ? catchingStats.PO['2020'] : "00"}</td>
                                                                <td>{catchingStats && catchingStats.E['2020'] ? catchingStats.E['2020'] : "00"}</td>
                                                                <td>{catchingStats && catchingStats.hasOwnProperty("CS") && catchingStats.CS['2020'] ? catchingStats.CS['2020'] : "00"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">INN</th>
                                                                <th scope="col">PO</th>
                                                                <th scope="col">E</th>
                                                                <th scope="col">CS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">Not entered yet.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>

                                    }

                                    <label className="common-lable">Fielding</label>
                                    {
                                        fieldingStats.length !== 0 ?
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">INN</th>
                                                                <th scope="col">TC</th>
                                                                <th scope="col">PO</th>
                                                                <th scope="col">E</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{fieldingStats && fieldingStats.INN['2020'] ? fieldingStats.INN['2020'] : "00"}</td>
                                                                <td>{fieldingStats && fieldingStats.TC['2020'] ? fieldingStats.TC['2020'] : "00"}</td>
                                                                <td>{fieldingStats && fieldingStats.PO['2020'] ? fieldingStats.PO['2020'] : "00"}</td>
                                                                <td>{fieldingStats && fieldingStats.hasOwnProperty("E") && fieldingStats.E['2020'] ? fieldingStats.E['2020'] : "00"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="table-responsive-xl">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">INN</th>
                                                                <th scope="col">TC</th>
                                                                <th scope="col">PO</th>
                                                                <th scope="col">E</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">Not entered yet.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className="w-50">
                                    <label className="common-lable">Player Highlight Videos</label>
                                    {
                                        videoList.length !== 0 ?
                                            <>
                                                <Slider {...video_slide}>
                                                    {videoList.map((e, index) => {
                                                        return (
                                                            <div className="item" key={index}>
                                                                <div className="items">
                                                                    <div className="video_img">
                                                                        <img src={e.vId.thumbnailUrl[0]} alt="video_slide1" />
                                                                        <Link className="video-icon" onClick={() => this.openVideoPopup(e._id)}>
                                                                            <i className="fas fa-caret-right"></i>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="video_dec">
                                                                        <h6>{e.title}</h6>
                                                                        <span>{Math.floor(e.vId.durs / 60) === 0 ? "00:" + Math.floor(e.vId.durs) : Math.floor(e.vId.durs / 60) + ":" + (e.vId.durs % 60 ? Math.floor(e.vId.durs % 60) : '00')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    })}
                                                </Slider>
                                            </>
                                            :
                                            <>
                                                <p className="mb-0">Upload your videos.</p>
                                            </>
                                    }


                                    <label className="common-lable">Favorite Colleges</label>
                                    <div className="collage_logo_name">
                                        {this.renderCollageList()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
                { this.state.videoPopup && <VideoPlayer flag={this.state.videoPopup} videoPoster={videoList.length !== 0 && videoList} showVideo={this.state.showVideo} close={this.closePopup} autoPlay muted />}

            </>
        );
    }
}

sharecart.propTypes = {
    getCart: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    cartDetails: state.cartInfo,
});

export default connect(mapStateToProps, { getCart })(sharecart);

import React, { useState, useEffect } from 'react';
import { Rnd } from "react-rnd";

function CustomBracket({ allRecapDescription }) {

    const [maxContainerHeight, setMaxContainerHeight] = useState('600');

    useEffect(() => {
        if (Object.keys(allRecapDescription).length !== 0) {
            const maxScaleY = allRecapDescription.gameList.length !== 0 ? Math.max(...allRecapDescription.gameList.map(({ y }) => y)) : '600';
            setMaxContainerHeight(maxScaleY + 200)
        }
    }, [allRecapDescription])

    const resizeDirections = {
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
    };


    const gameElement = (game) => {

        const { id, width, height } = game;

        return (
            <Rnd
                key={id}
                disableDragging={true}
                enableResizing={resizeDirections}
                bounds="parent"
                className="custom_drag_sec"
                size={{
                    width: width,
                    height: height,
                }}
                scale={1}
                default={{
                    ...game,
                }}
            >
                <div className="handle">
                    <div
                        className="handle_sec"
                        style={{
                            width: `${Number(width.split("px")[0])}px`,
                            height: `${Number(height.split("px")[0]) - 10}px`,
                        }}
                    >
                        <div className="handle_part">
                            <div className="source_section">
                                <div className="source_list game_para_sec">
                                    <p className="game_para">{game.homeSource.teamName}</p>
                                    {game.homeSource.score !== '' && <p className="game_para_number_sec">{game.homeSource.score}</p>}
                                </div>
                                <div className="game_number">
                                    <p>G{game.gameNo}</p>
                                </div>
                                <div className="source_list game_para_sec">
                                    <p className="game_para">{game.awaySource.teamName}</p>
                                    {game.awaySource.score !== '' && <p className="game_para_number_sec">{game.awaySource.score}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Rnd>
        );
    };

    const finalGameElement = (game) => {

        const { id, width, height, teamName, label } = game;

        return (
            <Rnd
                key={id}
                disableDragging={true}
                enableResizing={resizeDirections}
                bounds="parent"
                className="custom_drag_sec"
                size={{
                    width: width,
                    height: height,
                }}
                scale={1}
                default={{
                    ...game,
                }}
            >
                <div className="handle final_handle">
                    <div
                        className="handle_sec"
                        style={{
                            width: `${Number(width.split("px")[0])}px`,
                            height: `${Number(height.split("px")[0])}px`,
                        }}
                    >
                        <div className="handle_part">
                            <div className="source_section final_section final_section_line">
                                <div className="source_list">
                                    <p className="final_para">{teamName}</p>

                                    <div className="final_line">
                                    </div>
                                    <p>{label}</p>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </Rnd>
        );
    }

    const noteElement = (game) => {

        const { id, width, height } = game;

        return (
            <Rnd
                key={id}
                disableDragging={true}
                enableResizing={resizeDirections}
                bounds="parent"
                className="custom_drag_sec"
                size={{
                    width: width,
                    height: height,
                }}
                scale={1}
                default={{
                    ...game,
                }}
            >
                <div className="handle note_ele_handle">
                    <div
                        className="handle_sec"
                        style={{
                            width: `${Number(width.split("px")[0])}px`,
                            height: `${Number(height.split("px")[0]) - 10}px`,
                        }}
                    >
                        <div className="handle_part">
                            <div className="source_section justify-content-center">
                                <div className="source_list">
                                    <div className="d-flex w-100 justify-content-center">
                                        <p className="note_ele_text">{game.label}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Rnd>
        );
    }

    const pageBreakElement = (game) => {

        const { id, width, height } = game;

        return (
            <Rnd
                key={id}
                disableDragging={true}
                enableResizing={resizeDirections}
                bounds="parent"
                className="custom_drag_sec handle_part"
                size={{
                    width: width,
                    height: height,
                }}
                scale={1}
                default={{
                    ...game,
                }}
            >
                <div className="handle page_break_read_only ">
                </div>
            </Rnd>
        );
    }

    const renderAllGamesHandler = () => {

        return allRecapDescription?.gameList?.map((game) => {

            const { elementType } = game;

            switch (elementType) {

                case "Game": return gameElement(game);
                case "Final": return finalGameElement(game);
                case "Note": return noteElement(game);
                case "Break": return pageBreakElement(game);

                default: break;

            }

        });
    };

    return (
        allRecapDescription?.scheduleLive ?
            allRecapDescription?.gameList.length !== 0 ?
                <div className='container'>
                    <div className="custom_chart_area">
                        <div className="custom_chart_area_sec" style={{ height: `${maxContainerHeight}px` }}>{renderAllGamesHandler()}</div>
                    </div>
                </div>
                : <p className="text-center">No Data Found</p>
            : <p className="text-center m-16">Revised Schedule Coming Soon</p>
    )
}

export default CustomBracket
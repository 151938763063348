import axios from 'axios';
import { Auth_Key, Auth_Coach_Key, Auth_Player_Key, Auth_Fan_Key, API_Url, Authorization, checkIsValidToken, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidFanToken, checkIsValidCompanyToken, Auth_Company_Key } from '../../helpers/authToken';
import {
    RESEND_CODE_REQUEST,
    RESEND_CODE_SUCCESS,
    RESEND_CODE_ERROR,

    MOBILE_CODE_REQUEST,
    MOBILE_CODE_SUCCESS,
    MOBILE_CODE_ERROR,

    RESEND_FCODE_REQUEST,
    RESEND_FCODE_SUCCESS,
    RESEND_FCODE_ERROR,

    FVERIFY_CODE_REQUEST,
    FVERIFY_CODE_SUCCESS,
    FVERIFY_CODE_ERROR,

    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_ERROR,

    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,

    GET_VERIFY_PROFILE_REQUEST,
    GET_VERIFY_PROFILE_SUCCESS,
    GET_VERIFY_PROFILE_ERROR,

    GET_PLAYER_PROFILE_REQUEST,
    GET_PLAYER_PROFILE_SUCCESS,
    GET_PLAYER_PROFILE_ERROR,

    GET_FAN_PROFILE_REQUEST,
    GET_FAN_PROFILE_SUCCESS,
    GET_FAN_PROFILE_ERROR,

    RESET_REQUEST,
    RESET_SUCCESS,
    RESET_ERROR,

    RESET_MOBILE_REQUEST,
    RESET_MOBILE_SUCCESS,
    RESET_MOBILE_ERROR,
} from '../../constants/authantication/verify.const';
import histrory from '../../../History';

export const resetPass = (data) => dispatch => {
    dispatch({ type: RESET_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/user/password/reset`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(reset => {
        dispatch({
            type: RESET_SUCCESS,
            payload: reset.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 404)
            dispatch({ type: RESET_ERROR, error: error.response.data.message })
    });
};

export const fverifyCode = (verifyData) => dispatch => {
    dispatch({ type: FVERIFY_CODE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/user/password/forgot/verify`,
        data: verifyData,
        headers: {
            'Accept': 'application/json'
        }
    }).then(fverify => {
        dispatch({
            type: FVERIFY_CODE_SUCCESS,
            payload: fverify.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 404)
            dispatch({ type: FVERIFY_CODE_ERROR, error: error.response.data.message })
    });
};

export const resendVerifyCode = (resendData) => dispatch => {
    dispatch({ type: RESEND_FCODE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/user/password/forgot/resend`,
        data: resendData,
        headers: {
            'Accept': 'application/json'
        }
    }).then(fverify => {
        dispatch({
            type: RESEND_FCODE_SUCCESS,
            payload: fverify.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: RESEND_FCODE_ERROR, error: error.response.data.message })
    });
};

export const resendCode = (resendData) => dispatch => {
    dispatch({ type: RESEND_CODE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/verfiy/resend`,
        data: resendData,
        headers: {
            'Accept': 'application/json'
        }
    }).then(verify => {
        dispatch({
            type: RESEND_CODE_SUCCESS,
            payload: verify.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: RESEND_CODE_ERROR, error: error.response.data.message })
    });
};

export const mobileCode = (resendData, userType) => dispatch => {

    console.log("resendData:--", resendData);
    dispatch({ type: MOBILE_CODE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/verfiy/resend_for_mobile`,
        data: resendData,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(mobilecode => {
        console.log("mobilecode:---- ", mobilecode);
        dispatch({
            type: MOBILE_CODE_SUCCESS,
            payload: mobilecode.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: MOBILE_CODE_ERROR, error: error.response.data.message })
    });
};

export const sendVerifyCode = (verifyData, userType) => dispatch => {
    dispatch({ type: RESET_MOBILE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/basic_verfiy`,
        data: verifyData,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(mobile => {
        console.log("mobile:---- ", mobile);
        dispatch({
            type: RESET_MOBILE_SUCCESS,
            payload: mobile.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: RESET_MOBILE_ERROR, error: error.response.data.message })
    });
};

export const verifyCode = (verifyData) => dispatch => {
    dispatch({ type: VERIFY_CODE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/verfiy`,
        data: verifyData,
        headers: {
            'Accept': 'application/json'
        }
    }).then(verify => {
        console.log("verify:---- ", verify);
        dispatch({
            type: VERIFY_CODE_SUCCESS,
            payload: verify.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: VERIFY_CODE_ERROR, error: error.response.data.message })
    });
};

export const getProfile = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/user/profile`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(chkrole => {
        dispatch({
            type: GET_PROFILE_SUCCESS,
            payload: chkrole.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_PROFILE_ERROR, error: error })
        }
    });
};

export const getVerifyProfile = () => dispatch => {
    checkIsValidToken(histrory)
    dispatch({ type: GET_VERIFY_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/user/profile`,
        headers: {
            'x-auth-token': Auth_Key,
            'Accept': 'application/json'
        }
    }).then(chkrole => {
        dispatch({
            type: GET_VERIFY_PROFILE_SUCCESS,
            payload: chkrole.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_VERIFY_PROFILE_ERROR, error: error })
        }
    });
};

export const getPlayerProfile = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/user/profile`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(player => {
        dispatch({
            type: GET_PLAYER_PROFILE_SUCCESS,
            payload: player.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_PLAYER_PROFILE_ERROR, error: error })
        }
    });
};

export const getFanProfile = () => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: GET_FAN_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/user/profile`,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(fan => {
        dispatch({
            type: GET_FAN_PROFILE_SUCCESS,
            payload: fan.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_FAN_PROFILE_ERROR, error: error })
        }
    });
};

export const verifyCMSAuth = () => dispatch => {
    checkIsValidCompanyToken(histrory)
    dispatch({ type: GET_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/user/profile`,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(chkrole => {
        dispatch({
            type: GET_PROFILE_SUCCESS,
            payload: chkrole.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_PROFILE_ERROR, error: error })
        }
    });
};
import React, { Component } from 'react'
import { imagesArr } from "../../assets/images";

class topRecord extends Component {
    componentDidMount() {
        if (this.props.flag) {
            document.body.classList.add("modal-open");
        }
    }
    render() {
        const { flag, topRecData } = this.props;
        return (
            <div>
                <section className={`common-modal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog total-base">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3>{topRecData.title}</h3>
                                <div className="modal_close" onClick={this.props.close}>
                                    <img src={imagesArr.gray_modal_close} alt="modal_close" />
                                </div>
                            </div>
                            <div className="modal-body ">
                                <ul>
                                    {
                                        topRecData.data.length !== 0 ?
                                            topRecData.data.map((item, i) => {
                                                return (
                                                    <li>{item.rank}. {item.fname ? item.fname + " " + item.lname : "-"}</li>
                                                )
                                            })
                                            :
                                            <li className="text-center">
                                                No data found
                                            </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default topRecord;
import {
    FAN_PERSONALINFO_SUCCESS,
    FAN_PERSONALINFO_ERROR,
    FAN_REGISTERINFO_SUCCESS,
    FAN_REGISTERINFO_ERROR,
    PHONE_VERIFY_FAN_SUCCESS,
    PHONE_VERIFY_FAN_ERROR
} from '../../../../constants/view/fan/setting/personalInfo.const';


const initialState = {
    personalInfoData: null,
    personalInfoError: null,
   
    registerInfoData: null,
    registerInfoError: null,

    verifyFanPhone : null,
    verifyFanPhoneError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        //get personal info
        case FAN_PERSONALINFO_SUCCESS:
            return {
                ...state,
                personalInfoData: action.payload,
            };

        case FAN_PERSONALINFO_ERROR:
            return {
                ...state,
                personalInfoError: action.error,
            };
        
        // edit personal info
        case FAN_REGISTERINFO_SUCCESS:
            return {
                ...state,
                registerInfoData: action.payload,
            };

        case FAN_REGISTERINFO_ERROR:
            return {
                ...state,
                registerInfoError: action.error,
            };
   
            
        // verify phone number
        case PHONE_VERIFY_FAN_SUCCESS:
            return {
                ...state,
                verifyFanPhone: action.payload,
            };

        case PHONE_VERIFY_FAN_ERROR:
            return {
                ...state,
                verifyFanPhoneError: action.error,
            };
        default:
            return state;
    }
}
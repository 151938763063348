export const ADD_LINKED_PLAYER_REQUEST = 'ADD_LINKED_PLAYER_REQUEST';
export const ADD_LINKED_PLAYER_SUCCESS = 'ADD_LINKED_PLAYER_SUCCESS';
export const ADD_LINKED_PLAYER_ERROR = 'ADD_LINKED_PLAYER_ERROR';

export const LIST_LINKED_PLAYER_REQUEST = 'LIST_LINKED_PLAYER_REQUEST';
export const LIST_LINKED_PLAYER_SUCCESS = 'LIST_LINKED_PLAYER_SUCCESS';
export const LIST_LINKED_PLAYER_ERROR = 'LIST_LINKED_PLAYER_ERROR';

export const EDIT_LINKED_PLAYER_REQUEST = 'EDIT_LINKED_PLAYER_REQUEST';
export const EDIT_LINKED_PLAYER_SUCCESS = 'EDIT_LINKED_PLAYER_SUCCESS';
export const EDIT_LINKED_PLAYER_ERROR = 'EDIT_LINKED_PLAYER_ERROR';

export const DISCONNECT_PLAYER_REQUEST = 'DISCONNECT_PLAYER_REQUEST';
export const DISCONNECT_PLAYER_SUCCESS = 'DISCONNECT_PLAYER_SUCCESS';
export const DISCONNECT_PLAYER_ERROR = 'DISCONNECT_PLAYER_ERROR';


export const PHONE_VERIFY_PLAYER_REQUEST = 'PHONE_VERIFY_PLAYER_REQUEST';
export const PHONE_VERIFY_PLAYER_SUCCESS = 'PHONE_VERIFY_PLAYER_SUCCESS';
export const PHONE_VERIFY_PLAYER_ERROR = 'PHONE_VERIFY_PLAYER_ERROR';

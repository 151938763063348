import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { imagesArr } from '../../../../assets/images';
import ProductList from "../../../company/components/common/productList";
import moment from "moment";
class awards extends Component {

    renderAward = () => {
        return (
            this.props.summaryData &&
                this.props.summaryData.eventName &&
                this.props.summaryData.eventName.length !== 0 ?
                this.props.summaryData.eventName.map((item, i) => {
                    return (
                        <tr>
                            <td>{item.event ? item.event.eventTitle : "-"}</td>
                            <td>{moment(item.awardDate).format("MM/DD/YYYY")}</td>
                            <td>{item?.award?.name} {item?.award?.awardIcon ? <img src={item.award.awardIcon.filePath} width="120px" /> : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan="4">
                        <p className="text-center m-0">No data found</p>
                    </td>
                </tr>
        )
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    render() {
        const { sendReq } = this.props;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <>
                <div className="awards-content">
                    <div className="awards-filter">
                        <div className="bc_relative">
                            <DatePicker
                                ref={(c) => (this._calendar = c)}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                            {years.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                            {months.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                    </div>
                                )}
                                selected={sendReq.sortOn.awardstartDate}
                                maxDate={moment().subtract(1, 'days').toDate()}
                                onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                onChange={(e) => this.props.onChangeDate(e, "awardstartDate", "awardTab")}
                                placeholderText="Start date"
                            />
                            <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" />
                        </div>
                        <div className="bc_relative">
                            <DatePicker
                                ref={(c) => (this._calendar = c)}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                            {years.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                            {months.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                    </div>
                                )}
                                selected={sendReq.sortOn.awardendDate}
                                minDate={moment(sendReq.sortOn.awardstartDate).subtract(0, 'days').toDate()}
                                maxDate={moment().subtract(1, 'days').toDate()}
                                onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                onChange={(e) => this.props.onChangeDate(e, "awardendDate", "awardTab")}
                                placeholderText="End date"
                            />
                            <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" />
                        </div>
                    </div>
                    <div className="award-table">
                        <div className="table-responsive-xl">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Event Name</th>
                                        <th>Date</th>
                                        <th>Award Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderAward()}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <ProductList />
            </>
        )
    }
}

export default awards;
import {
    PLAYER_BG_REQUEST,
    PLAYER_BG_SUCCESS,
    PLAYER_BG_ERROR,

    LEAVE_TEAM_REQUEST,
    LEAVE_TEAM_SUCCESS,
    LEAVE_TEAM_ERROR,
} from '../../../constants/view/player/dashboard.const';

const initialState = {
    bgPhotoReq: false,
    bgPhotoData: null,
    bgPhotoError: null,

    leaveTeamReq: false,
    leaveTeamData: null,
    leaveTeamError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // bgPhoto req    
        case PLAYER_BG_REQUEST:
            return {
                ...state,
                bgPhotoReq: true,
            };
        case PLAYER_BG_SUCCESS:
            return {
                ...state,
                bgPhotoData: action.payload,
            };
        case PLAYER_BG_ERROR:
            return {
                ...state,
                bgPhotoError: action.error,
            };

        case LEAVE_TEAM_REQUEST:
            return {
                ...state,
                leaveTeamReq: true,
            };
        case LEAVE_TEAM_SUCCESS:
            return {
                ...state,
                leaveTeamData: action.payload,
            };
        case LEAVE_TEAM_ERROR:
            return {
                ...state,
                leaveTeamError: action.error,
            };


        default:
            return state;
    }
}
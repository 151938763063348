import {
    SAVE_COMPLETE_REQUEST,
    SAVE_COMPLETE_SUCCESS,
    SAVE_COMPLETE_ERROR,
} from '../../../../constants/authantication/signup/coach/complete.const';

const initialState = {
    saveComplateRes: false,
    saveComplateData: null,
    saveComplateDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // save complate
        case SAVE_COMPLETE_REQUEST:
            return {
                ...state,
                saveComplateRes: true
            };
        case SAVE_COMPLETE_SUCCESS:
            return {
                ...state,
                saveComplateData: action.payload,
            };
        case SAVE_COMPLETE_ERROR:
            return {
                ...state,
                saveComplateDataError: action.error,
            };

        default:
            return state;
    }
}
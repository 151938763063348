import React from 'react'

const AdditionalInfo = () => {
  return (
    <div className='company_page_sec'>
        <div className='container'>
            <h2>Additional Information and Frequently Asked Questions:</h2>

            <div className='inner_page_sec'>
                <ul>
                    <li>
                        <span>Registration Details:</span>
                        <p>Your tryout fee covers your attendance at one Tryout Camp. You are responsible for your own travel and lodging expenses for the time of the camp.</p>
                    </li>


                    <li>
                        <span>If I am unable to make it to one of the tryouts, can I get a refund and/or credit for my registration fee?</span>
                        <p>No, there will be NO REFUNDS and NO CREDITS for no shows at these events. In the case of the camp being postponed due to inclement weather, players will be issued a credit to the rescheduled event. </p>
                    </li>

                    <li>
                        <span>Will PBL Clubs draft players at the Tryout Camps?</span>
                        <p>Prospects at the camp will be scouted by representatives from each of the 10 PBL clubs and a draft will follow the conclusion of each tryout camp. Each PBL club will sign at least one player from each camp to attend their 2023 Spring Training.</p>
                    </li>

                    <li>
                        <span>What should I expect at the Tryout Camps?</span>
                        <p>During the tryout camps, prospects will undergo pro-style workouts during which scouts will evaluate athletic ability as well as batting, pitching and fielding skills. These evaluations will include extended infield/outfield sessions, two days of bullpens for pitchers, batting practice sessions, and multiple live scrimmages. Advanced data and analytics on each prospect will be captured using the Yakkertech and Stalker systems and made available to scouts and players in individual profiles on the Diamond Connect platform. </p>
                    </li>

                    <li>
                        <span>Is there an age limit for the Tryout Camps?</span>
                        <p>Players must be at least 18 years old to attend. Players with college eligibility remaining should check with their school before registering. Players should also check the PBL eligibility requirements below.</p>
                    </li>

                    <li>
                        <span className='underline italic_font'>PBL Eligibility Rules:</span>
                        <p className='italic_font'>To be eligible to play on a PBL Club’s Active Roster, a prospect shall have played no more than three (3) years of professional baseball. For purposes of calculating prior years of professional baseball experience, a year of professional experience is defined as follows:</p>
                        <ul>
                            <li>POSITION PLAYERS – 45 games played</li>
                            <li>STARTING PITCHERS – 10 games started</li>
                            <li>PITCHERS – 25 games played</li>
                        </ul>
                        <p className='italic_font'>Professional experience includes previous play in all professional leagues, domestic or international, but does not include the leagues in the Caribbean Federation, the Australian Baseball League or any other leagues that might become “winter ball” leagues.</p>
                    </li>

                    <li>
                        <span>Are international players allowed to try out?</span>
                        <p>Amateur and professional athletes who wish to attend the Tryout Camp from non-US countries can participate in the camp but must meet US Immigration requirements on their own.  Once players are signed to a contract with a Pioneer Baseball League club, they will be required to possess a P1 visa in order to play professional baseball as a paid player in the United States.  The player needs to apply for and complete the application process for the P1 visa on his own.</p>
                    </li>

                    <li>
                        <span>What should I bring?</span>
                        <p>Players should bring all of their own equipment including baseball attire, cleats, helmets, bats, gloves, catching gear, etc. Baseballs will be provided as well as shirts for each player. </p>
                    </li>

                    <li>
                        <span>Who will be scouting the players?</span>
                        <p>Club representatives from the Pioneer League will be present to assess all players. Yakkertech, is a real-time camera system that captures and analyzes ball-flight data with unparalleled precision. Attendees will have access to comprehensive, accurate, and accessible data ranging from ball tracking to player motion. </p>
                    </li>

                    <li>
                        <span>Will there be refreshments provided for the players?</span>
                        <p>Ice water jugs will be provided. Players are encouraged to bring their own personal water bottles that can be filled up, although disposable cups will be available. Players should also bring their own lunch each day, there will be a break in order to eat, but food will not be provided at the tryout.</p>
                    </li>
                </ul>

                <div className='payment_notes'>
                    <span>******PLEASE NOTE******</span>
                    <p>ALL REGISTRATIONS AND PAYMENTS ARE <strong>NON-REFUNDABLE.</strong> ONCE A PLAYER IS REGISTERED AND PAYMENT IS COMPLETE THERE WILL BE NO REFUNDS OR CREDITS ISSUED FOR ANY FURTHER PBL EVENTS.  IN THE CASE OF CAMP BEING POSTPONED DUE TO INCLEMENT WEATHER, PLAYERS WILL BE ISSUED A CREDIT ONLY TO THE RESCHEDULED EVENT.  UPON COMPLETION OF THE REGISTRATION PROCESS, PARTICIPATING PLAYERS AGREE TO ALL TERMS ABOVE.</p>
                </div>  
            </div>
        </div>
    </div>
  ) 
}

export default AdditionalInfo
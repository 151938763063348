import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { teamList } from '../../../../saga/actions/authantication/signup/fan/team.action';
import Preloader from '../../../../components/preloader';

class team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamArr: [],
            filter: {
                page: 1,
                limit: 10,
                search: ""
            },
            selectedId: []
        }
    }

    componentWillMount() {
        this.props.onRef(this);
        if(this.state.filter.search >=2){
            this.setLoader(true);
            this.props.teamList(this.state.filter);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.teamDetails &&
            nextProps.teamDetails.searchTeamRes &&
            nextProps.teamDetails.searchTeamData
        ) {
            nextProps.teamDetails.searchTeamRes = false;
            if (nextProps.teamDetails.searchTeamData.flag) {
                if (this.state.filter.search) {
                    return this.setState({
                        teamArr: nextProps.teamDetails.searchTeamData.result.parentTeamList,
                    }, () => {
                        nextProps.teamDetails.searchTeamData = null;
                        this.setLoader(false);
                    })
                }
                if (nextProps.teamDetails.searchTeamData.result.hasOwnProperty('myParentTeam') && nextProps.teamDetails.searchTeamData.result.myParentTeam.length !== 0) {
                    let val = nextProps.teamDetails.searchTeamData.result.parentTeamList;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.teamArr.concat(temp);
                    for (let i = 0; i < nextProps.teamDetails.searchTeamData.result.myParentTeam.length; i++) {
                        let element = nextProps.teamDetails.searchTeamData.result.myParentTeam[i]
                        if (!this.state.teamArr.find(e => e._id === element._id)) {
                            joined.unshift(element)
                        }
                    }
                    this.setState({
                        teamArr: joined,
                        selectedId: this.state.selectedId.length ? this.state.selectedId : nextProps.teamDetails.searchTeamData.result.myParentTeam.map(e => { return e._id }),
                    }, () => {
                        nextProps.teamDetails.searchTeamData = null;
                        this.setLoader(false);
                    })
                } else {
                    let val = nextProps.teamDetails.searchTeamData.result.parentTeamList;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.teamArr.concat(temp);
                    this.setState({
                        teamArr: joined
                    }, () => {
                        nextProps.teamDetails.searchTeamData = null;
                        this.setLoader(false);
                    });
                }
            }
        } else {
            this.setLoader(false);
            nextProps.teamDetails.searchTeamData = null;
        }

    }

    setSelectValue = (val, cb) => {
        this.setState({
            teamArr: val,
        }, () => cb(true));
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "team") {
            if (!!this.state.selectedId.find(e => e === value)) {
                this.state.selectedId.splice(this.state.selectedId.findIndex(e => e === value), 1)
                this.setState((prevState) => ({
                    ...prevState,
                    selectedId: this.state.selectedId,
                }));
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    selectedId: [...this.state.selectedId, value],
                }));
            }
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    page: 1,
                    limit: 10,
                    search: value
                },
            }), () => {
                if (value.length !== 0) {
                    if (value.length >= 2) {
                        this.setLoader(true);
                        this.props.teamList(this.state.filter);
                    }
                } else {
                    this.state.teamArr = [];
                    this.setState((prevState) => ({
                        ...prevState,
                        teamArr: []
                    }))
                    this.props.teamDetails.searchTeamData = null;
                    // this.setLoader(true);
                    // this.props.teamList(this.state.filter);
                }
            });
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.teamList(this.state.filter);
    }

    renderTeamList = () => {
        return (
            this.state.teamArr.length !== 0 ?
                this.state.teamArr.map((team, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={team.teamLogo ? team.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{team.name && team.name}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="checkbox"
                                    checked={!!this.state.selectedId.find((d) => d === team._id) ? true : false }
                                    value={team._id}
                                    name="team"
                                    onChange={(e) => this.onHandleChange(e)}
                                />
                                <span htmlFor="Baseball"></span>
                            </div>
                        </div>
                    );
                }) :
                <h4 className="text-center">No Data Found</h4>
        );
    }

    validationUpdate = (cb) => {
        if (this.state.selectedId.length !== 0) {
            cb({ data: { parentTeam: this.state.selectedId }, flag: true });
        } else {
            cb({ data: '', flag: false });
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    page: this.state.filter.page + 1
                }
            }), () => {
                this.setLoader(true);
                this.props.teamList(this.state.filter);
            })
        }
    }

    render() {
        const { loader, filter, invite } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg playes-team">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {this.props.profileData && this.props.profileData.fname && this.props.profileData.fname + " " + this.props.profileData.lname},
                            </h2>
                            <p>
                                We found some teams in your area. Select the teams you would like to follow or skip below.
                            </p>
                        </div>
                        {
                            !invite &&
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your team" name="search" value={filter.search} onChange={this.onHandleChange} />
                                        {/* <Link className="serch-btn" onClick={this.onSearch}><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="scrollbar_team" onScroll={this.getScroll}>
                            <div className="select_team ml-0">
                                {this.renderTeamList()}
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

team.propTypes = {
    teamList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    teamDetails: state.teamFanDetails
});

export default connect(mapStateToProps, { teamList })(team);
export const GET_PLAYER_PROFILE_INFO_REQUEST = 'GET_PLAYER_PROFILE_INFO_REQUEST';
export const GET_PLAYER_PROFILE_INFO_SUCCESS = 'GET_PLAYER_PROFILE_INFO_SUCCESS';
export const GET_PLAYER_PROFILE_INFO_ERROR   = 'GET_PLAYER_PROFILE_INFO_ERROR';

export const GET_SCOUTING_FEED_REQUEST = 'GET_SCOUTING_FEED_REQUEST';
export const GET_SCOUTING_FEED_SUCCESS = 'GET_SCOUTING_FEED_SUCCESS';
export const GET_SCOUTING_FEED_ERROR   = 'GET_SCOUTING_FEED_ERROR';

export const GET_LOGIN_RESPONSE_REQUEST = 'GET_LOGIN_RESPONSE_REQUEST';
export const GET_LOGIN_RESPONSE_SUCCESS = 'GET_LOGIN_RESPONSE_SUCCESS';
export const GET_LOGIN_RESPONSE_ERROR   = 'GET_LOGIN_RESPONSE_ERROR';

export const SAVE_SOCIAL_MEDIA_REQUEST = 'SAVE_SOCIAL_MEDIA_REQUEST';
export const SAVE_SOCIAL_MEDIA_SUCCESS = 'SAVE_SOCIAL_MEDIA_SUCCESS';
export const SAVE_SOCIAL_MEDIA_ERROR   = 'SAVE_SOCIAL_MEDIA_ERROR';

export const GET_COACH_TEAM_LIST_REQUEST = 'GET_COACH_TEAM_LIST_REQUEST';
export const GET_COACH_TEAM_LIST_SUCCESS = 'GET_COACH_TEAM_LIST_SUCCESS';
export const GET_COACH_TEAM_LIST_ERROR   = 'GET_COACH_TEAM_LIST_ERROR';

export const SAVE_COACH_DETAILS_REQUEST = 'SAVE_COACH_DETAILS_REQUEST';
export const SAVE_COACH_DETAILS_SUCCESS = 'SAVE_COACH_DETAILS_SUCCESS';
export const SAVE_COACH_DETAILS_ERROR   = 'SAVE_COACH_DETAILS_ERROR';

export const GET_USERID_RESPONSE_REQUEST = 'GET_USERID_RESPONSE_REQUEST';
export const GET_USERID_RESPONSE_SUCCESS = 'GET_USERID_RESPONSE_SUCCESS';
export const GET_USERID_RESPONSE_ERROR   = 'GET_USERID_RESPONSE_ERROR';

export const DELETE_COACH_DETAILS_REQUEST = 'DELETE_COACH_DETAILS_REQUEST';
export const DELETE_COACH_DETAILS_SUCCESS = 'DELETE_COACH_DETAILS_SUCCESS';
export const DELETE_COACH_DETAILS_ERROR   = 'DELETE_COACH_DETAILS_ERROR';

export const SEND_VIEW_COUNT_REQUEST = 'SEND_VIEW_COUNT_REQUEST';
export const SEND_VIEW_COUNT_SUCCESS = 'SEND_VIEW_COUNT_SUCCESS';
export const SEND_VIEW_COUNT_ERROR   = 'SEND_VIEW_COUNT_ERROR';

export const DIRECT_RECAP_INFORMATION_REQUEST = 'DIRECT_RECAP_INFORMATION_REQUEST';
export const DIRECT_RECAP_INFORMATION_SUCCESS = 'DIRECT_RECAP_INFORMATION_SUCCESS';
export const DIRECT_RECAP_INFORMATION_ERROR   = 'DIRECT_RECAP_INFORMATION_ERROR';

export const GET_PROFILE_FIELDS_REQUEST = 'GET_PROFILE_FIELDS_REQUEST';
export const GET_PROFILE_FIELDS_SUCCESS = 'GET_PROFILE_FIELDS_SUCCESS';
export const GET_PROFILE_FIELDS_ERROR   = 'GET_PROFILE_FIELDS_ERROR';

export const GET_PW_SCOUTING_FEED_REQUEST = 'GET_PW_SCOUTING_FEED_REQUEST';
export const GET_PW_SCOUTING_FEED_SUCCESS = 'GET_PW_SCOUTING_FEED_SUCCESS';
export const GET_PW_SCOUTING_FEED_ERROR   = 'GET_PW_SCOUTING_FEED_ERROR';
import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../../../../helpers/authToken';
import {
    GET_PLAYER_RANKING_REQUEST,
    GET_PLAYER_RANKING_SUCCESS,
    GET_PLAYER_RANKING_ERROR,

    GET_ALL_PLAYER_LIST_SUCCESS,
    GET_ALL_PLAYER_LIST_REQUEST,
    GET_ALL_PLAYER_LIST_ERROR
} from '../../../../../constants/view/company/pwRanking/leaderboard/playerRanking.const';
import histrory from '../../../../../../History';


export const getAllPlayerList = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_ALL_PLAYER_LIST_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}${mode ? "pwUser/ranking/player/list" : "pwUser/woAuth/ranking/player/list"}`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((teamList) => {
        console.log("teamList:--", teamList.data);
        dispatch({
            type: GET_ALL_PLAYER_LIST_SUCCESS,
            payload: teamList.data,
        });
    }).catch((error) => {
        console.log(error)
        dispatch({ type: GET_ALL_PLAYER_LIST_ERROR, error: error });
    });
};

export const getPlayerRanking = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    console.log("mode:--", mode);
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': mode,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_PLAYER_RANKING_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/ranking/data" : "pwUser/woAuth/ranking/data"}`,
        data: data,
        headers: setHeader
    }).then(ranking => {
        console.log("ranking:--", ranking.data)
        dispatch({
            type: GET_PLAYER_RANKING_SUCCESS,
            payload: ranking.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_RANKING_ERROR, error: error.response.data.message })
        }
    });
};



import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getTrainingInfo,
  regInfo,
} from "../../../saga/actions/views/coach/profile/trainingInfo.action";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import commonCalls from "../../../businesslogic/commonCalls";
import { connect } from "react-redux";
import Preloader from '../../../components/preloader';


class trainingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilty: "",
      devices: "",
      // gpy: "",
      deviceType: [],
      deviceHelpful: "",
      devicesTeam: "",
      useDevices: [],
      dType: ["rapsodo", "blast", "flight", "track", "hit", "dk", "yakkertech", "other"],
      useDevice: ["Bullpen", "Batting Practice", "Game", "Scrimmage", "Other"],
      deviceTypeOther: "",
      useDeviceOther: "",
      loader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.devicesChange = this.devicesChange.bind(this);
    this.validatorDevice = new SimpleReactValidator();
    this.validatorUseDevice = new SimpleReactValidator();
    this.commonCall = new commonCalls();
  }

  componentWillMount() {
    this.setLoader(true);
    this.props.getTrainingInfo();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps._TRAININGINFO.trainingInfoData) {
      console.log("nextProps._TRAININGINFO:--", nextProps._TRAININGINFO.trainingInfoData.hasOwnProperty('device-type'));
      if (nextProps._TRAININGINFO.trainingInfoData.facilty) {
        this.setState(
          {
            facilty: nextProps._TRAININGINFO.trainingInfoData.facilty,
            devices: nextProps._TRAININGINFO.trainingInfoData.devices,
            // gpy: nextProps._TRAININGINFO.trainingInfoData.gpy,
            deviceType: nextProps._TRAININGINFO.trainingInfoData.hasOwnProperty('device-type') ? nextProps._TRAININGINFO.trainingInfoData["device-type"] : [],
            deviceHelpful:
              nextProps._TRAININGINFO.trainingInfoData.hasOwnProperty('device-helpful') ? nextProps._TRAININGINFO.trainingInfoData["device-helpful"] : "",
            devicesTeam: nextProps._TRAININGINFO.trainingInfoData["devices-team"],
            useDevices: nextProps._TRAININGINFO.trainingInfoData.hasOwnProperty('use-devices') ? nextProps._TRAININGINFO.trainingInfoData["use-devices"] : [],
            deviceTypeOther: nextProps._TRAININGINFO.trainingInfoData.hasOwnProperty('device-type-other') ? nextProps._TRAININGINFO.trainingInfoData["device-type-other"] : "",
            useDeviceOther: nextProps._TRAININGINFO.trainingInfoData.hasOwnProperty('use-devices-other') ? nextProps._TRAININGINFO.trainingInfoData["use-devices-other"] : "",
          },
          () => {
            nextProps._TRAININGINFO.trainingInfoData = null;
            this.setLoader(false);
          }
        );
      } else {
        nextProps._TRAININGINFO.trainingInfoData = null;
      }
      this.setLoader(false);
    }

    if (nextProps._REG.registerInfoData) {
      if (nextProps._REG.registerInfoData.flag) {
        this.commonCall.successMessage(nextProps._REG.registerInfoData.message);
        nextProps._REG.registerInfoData = null;
        this.setLoader(false);
      } else if (!nextProps._REG.registerInfoData.flag) {
        this.commonCall.errorMessage(nextProps._REG.registerInfoData.message);
        nextProps._REG.registerInfoData = null;
        this.setLoader(false);
      }
    }
  }

  renderDeviceType = () => {
    return (
      this.state.dType &&
      this.state.dType.map((item, i) => (
        <div className="check_box true">
          <div className="check-box">
            <div className="checkbox">
              <input
                type="checkbox"
                id={i}
                value={item}
                name={item}
                checked={
                  this.state.deviceType.find((d) => d === item) ? true : false
                }
                onClick={(e) => this.handleChange(e, item)}
              />
              <label htmlFor="dtype">
                {item === "rapsodo"
                  ? "Rapsodo"
                  : item === "blast"
                    ? "Blast Motion"
                    : item === "flight"
                      ? "FlightScope"
                      : item === "track"
                        ? "Trackman"
                        : item === "hit"
                          ? "HitTrax"
                          : item === "dk" ?
                            "Diamond Kinetics" : item === "yakkertech" ?
                              "Yakkertech" : item === "other" ?
                                "Other" : ""}
              </label>
            </div>
          </div>
        </div>
      ))
    );
  };

  onHandleChange = (e) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  handleChange = (e, item) => {
    if (this.state.deviceType.find((d) => d === item)) {
      // subtraction
      let tempArray = this.state.deviceType.filter((d) => d !== item);
      this.setState({
        deviceType: tempArray,
      });
    } else {
      // addition
      this.setState((prevState) => ({
        ...prevState,
        deviceType: [...prevState.deviceType, item],
      }));
    }
  };

  renderUseDevice = () => {
    return (
      this.state.useDevice &&
      this.state.useDevice.map((item, i) => (
        <div className="check_box true">
          <div className="check-box">
            <div className="checkbox">
              <input
                type="checkbox"
                id={i}
                value={item}
                name={item}
                checked={
                  this.state.useDevices.find((d) => d === item) ? true : false
                }
                onClick={(e) => this.devicesChange(e, item)}
              />
              <label htmlFor="dtype">{item}</label>
            </div>
          </div>
        </div>
      ))
    );
  };

  devicesChange = (e, item) => {
    if (this.state.useDevices.find((d) => d === item)) {
      // subtraction
      let tempArray = this.state.useDevices.filter((d) => d !== item);
      this.setState({
        useDevices: tempArray,
      });
    } else {
      // addition
      this.setState((prevState) => ({
        ...prevState,
        useDevices: [...prevState.useDevices, item],
      }));
    }
  };

  saveData = () => {
    let regData;
    if (this.state.devices === "yes" && ((!!this.state.deviceType.find(e => e === "other") && !this.validatorDevice.allValid()) || (!!this.state.useDevices.find(e => e === "Other") && !this.validatorUseDevice.allValid()))) {
      this.validatorDevice.showMessages();
      this.validatorUseDevice.showMessages();
      this.forceUpdate();
    } else {
      if (this.state.devices === "yes") {
        regData = {
          facilty: this.state.facilty,
          devices: this.state.devices,
          // gpy: this.state.gpy,
          "device-type": this.state.deviceType,
          "device-helpful": this.state.deviceHelpful,
          "devices-team": this.state.devicesTeam,
          "use-devices": this.state.useDevices,
          "device-type-other": this.state.deviceType.find(e => e === "other") ? this.state.deviceTypeOther : "",
          "use-devices-other": this.state.useDevices.find(e => e === "Other") ? this.state.useDeviceOther : ""
        };
      } else {
        regData = {
          facilty: this.state.facilty,
          devices: this.state.devices,
          // gpy: this.state.gpy,
        };
      }
      this.setLoader(true);
      this.props.regInfo(regData);
    }
  };

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  render() {
    const { loader } = this.state
    return (
      <>
        <section className="before-login other-before-dashboard">
          <div className="white-bg">
            {/* <label className="common-lable">
              How many games a year do you coach?
            </label>
            <div className="check_input row">
              <div className="check_box">
                <select name="gpy" value={this.state.gpy} onChange={this.onHandleChange} className="custom-select">
                  <option value="" selected disabled>Select games you play</option>
                  <option
                    value="5"
                    selected={this.state.gpy === "5" ? "selected" : ""}
                  >
                    5
                  </option>
                  <option
                    value="10"
                    selected={this.state.gpy === "10" ? "selected" : ""}
                  >
                    10
                  </option>
                  <option
                    value="15"
                    selected={this.state.gpy === "15" ? "selected" : ""}
                  >
                    15
                  </option>
                  <option
                    value="20"
                    selected={this.state.gpy === "20" ? "selected" : ""}
                  >
                    20
                  </option>
                  <option
                    value="25"
                    selected={this.state.gpy === "25" ? "selected" : ""}
                  >
                    25
                  </option>
                  <option
                    value="30"
                    selected={this.state.gpy === "30" ? "selected" : ""}
                  >
                    30
                  </option>
                  <option
                    value="35"
                    selected={this.state.gpy === "35" ? "selected" : ""}
                  >
                    35
                  </option>
                  <option
                    value="40"
                    selected={this.state.gpy === "40" ? "selected" : ""}
                  >
                    40
                  </option>
                  <option
                    value="45"
                    selected={this.state.gpy === "45" ? "selected" : ""}
                  >
                    45
                  </option>
                  <option
                    value="50"
                    selected={this.state.gpy === "50" ? "selected" : ""}
                  >
                    50
                  </option>
                  <option
                    value="55"
                    selected={this.state.gpy === "55" ? "selected" : ""}
                  >
                    55
                  </option>
                  <option
                    value="60"
                    selected={this.state.gpy === "60" ? "selected" : ""}
                  >
                    60
                  </option>
                  <option
                    value="65"
                    selected={this.state.gpy === "65" ? "selected" : ""}
                  >
                    65
                  </option>
                  <option
                    value="70"
                    selected={this.state.gpy === "70" ? "selected" : ""}
                  >
                    70
                  </option>
                  <option
                    value="75"
                    selected={this.state.gpy === "75" ? "selected" : ""}
                  >
                    75
                  </option>
                  <option
                    value="80"
                    selected={this.state.gpy === "80" ? "selected" : ""}
                  >
                    80
                  </option>
                  <option
                    value="85"
                    selected={this.state.gpy === "85" ? "selected" : ""}
                  >
                    85
                  </option>
                  <option
                    value="90"
                    selected={this.state.gpy === "90" ? "selected" : ""}
                  >
                    90
                  </option>
                  <option
                    value="95"
                    selected={this.state.gpy === "95" ? "selected" : ""}
                  >
                    95
                  </option>
                  <option
                    value="100"
                    selected={this.state.gpy === "100" ? "selected" : ""}
                  >
                    100
                  </option>
                </select>
              </div>
            </div> */}

            <label className="common-lable">Do you coach at a facility?</label>
            <div className="check_input row">
              <div className="check_box">
                <div className="check-box mb-0">
                  <div className="checkbox">
                    <input
                      type="radio"
                      id="Male1"
                      name="facilty"
                      onChange={this.onHandleChange}
                      value="yes"
                      checked={this.state.facilty === "yes"}
                    />
                    <label htmlFor="Male1">Yes </label>
                  </div>

                  <div className="checkbox mr-0">
                    <input
                      type="radio"
                      id="Female1"
                      name="facilty"
                      onChange={this.onHandleChange}
                      value="no"
                      checked={this.state.facilty === "no"}
                    />
                    <label htmlFor="Female1">No </label>
                  </div>
                </div>
              </div>
            </div>

            <label className="common-lable">
              Does your team train with devices?
            </label>
            <div className="check_input row">
              <div className="check_box">
                <div className="check-box">
                  <div className="checkbox">
                    <input
                      type="radio"
                      id="Male2"
                      name="devices"
                      value="yes"
                      onChange={this.onHandleChange}
                      checked={this.state.devices === "yes"}
                    />
                    <label htmlFor="Male2">Yes </label>
                  </div>

                  <div className="checkbox mr-0">
                    <input
                      type="radio"
                      id="Female2"
                      name="devices"
                      value="no"
                      onChange={this.onHandleChange}
                      checked={this.state.devices === "no"}
                    />
                    <label htmlFor="Female2">No </label>
                  </div>
                </div>
              </div>
            </div>

            {
              this.state.devices === "yes" &&
              <>

                <label className="common-lable">
                  Which training device you use?
                </label>
                <div className="check_input row">{this.renderDeviceType()}</div>

                {!!this.state.deviceType.find(e => e === "other") &&
                  <><label className="common-lable">
                    If other please explain
                  </label>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter text here" name="deviceTypeOther" value={this.state.deviceTypeOther} onChange={(e)=>this.setState((prevState)=>({...prevState,deviceTypeOther:e.target.value}))}/>
                      <span className="validMsg">{this.validatorDevice.message('', this.state.deviceTypeOther, 'required', { className: 'text-danger' })}</span>
                    </div>
                  </>}

                <label className="common-lable">
                  How do you use these devices?
                </label>
                <div className="check_input row">{this.renderUseDevice()}</div>

                {!!this.state.useDevices.find(e => e === "Other") &&
                  <><label className="common-lable">
                    If other please explain
                  </label>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter text here" value={this.state.useDeviceOther} onChange={(e) => this.setState((prevState) => ({ ...prevState, useDeviceOther: e.target.value }))} />
                      <span className="validMsg">{this.validatorUseDevice.message('', this.state.useDeviceOther, 'required', { className: 'text-danger' })}</span>
                    </div>
                  </>}

                <label className="common-lable">
                  Do you find the device data helpful?
                </label>
                <div className="check_input row">
                  <div className="check_box">
                    <div className="check-box">
                      <div className="checkbox">
                        <input
                          type="radio"
                          id="Male3"
                          name="deviceHelpful"
                          value="yes"
                          onChange={this.onHandleChange}
                          checked={this.state.deviceHelpful === "yes"}
                        />
                        <label htmlFor="Male3">Yes </label>
                      </div>

                      <div className="checkbox mr-0">
                        <input
                          type="radio"
                          id="Female3"
                          name="deviceHelpful"
                          value="no"
                          onChange={this.onHandleChange}
                          checked={this.state.deviceHelpful === "no"}
                        />
                        <label htmlFor="Female3">No </label>
                      </div>
                    </div>
                  </div>
                </div>
                <label className="common-lable">
                  How many times a week do you or your team use a device?
                </label>
                <div className="check_input row">
                  <div className="check_box">
                    <select name="devicesTeam" value={this.state.devicesTeam} onChange={this.onHandleChange} className="custom-select">
                      <option value="" selected disabled>Select session</option>
                      <option
                        value="1"
                        selected={this.state.devicesTeam === "1" ? "selected" : ""}
                      >
                        {" "}
                        Once a week
                      </option>
                      <option
                        value="2"
                        selected={this.state.devicesTeam === "2" ? "selected" : ""}
                      >
                        Multiple times a week
                      </option>
                      <option
                        value="3"
                        selected={this.state.devicesTeam === "3" ? "selected" : ""}
                      >
                        Daily
                      </option>
                      {/* <option
                        value="4"
                        selected={this.state.devicesTeam === "4" ? "selected" : ""}
                      >
                        Many times a week
                      </option> */}
                    </select>
                  </div>
                </div>

              </>
            }
            <div className="flex-align">
              <Link className="btn gray" to="/coach-dashboard">
                Cancel
              </Link>
              <Link
                className="btn light-green"
                href="/"
                onClick={this.saveData}
              >
                Save
              </Link>
            </div>
          </div>
        </section>
        <Preloader flag={loader} />
      </>
    );
  }
}

trainingInfo.propTypes = {
  getTrainingInfo: PropTypes.func.isRequired,
  regInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  _TRAININGINFO: state.trainingInfo,
  _REG: state.personalInfo,
});

export default connect(mapStateToProps, { getTrainingInfo, regInfo })(
  trainingInfo
);

import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_REQUEST,
    GET_PLAYER_SUCCESS,
    GET_PLAYER_ERROR,

    GET_OPTION_REQUEST,
    GET_OPTION_SUCCESS,
    GET_OPTION_ERROR,

    GET_PLAYER_OPTION_REQUEST,
    GET_PLAYER_OPTION_SUCCESS,
    GET_PLAYER_OPTION_ERROR,

    SAVE_PLAYER_ROSTER_REQUEST,
    SAVE_PLAYER_ROSTER_SUCCESS,
    SAVE_PLAYER_ROSTER_ERROR,

    ADD_NEW_PLAYER_REQUEST,
    ADD_NEW_PLAYER_SUCCESS,
    ADD_NEW_PLAYER_ERROR,

    EDIT_NEW_PLAYER_REQUEST,
    EDIT_NEW_PLAYER_SUCCESS,
    EDIT_NEW_PLAYER_ERROR,

    DEL_NEW_PLAYER_REQUEST,
    DEL_NEW_PLAYER_SUCCESS,
    DEL_NEW_PLAYER_ERROR,

    DIS_NEW_PLAYER_REQUEST,
    DIS_NEW_PLAYER_SUCCESS,
    DIS_NEW_PLAYER_ERROR,

    GTE_LINK_PLAYER_REQUEST,
    GTE_LINK_PLAYER_SUCCESS,
    GTE_LINK_PLAYER_ERROR,

    ALL_MAIL_PLAYERINFO_SUCCESS,
    ALL_MAIL_PLAYERINFO_ERROR,
} from '../../../../constants/view/coach/profile/playerInfo.const';
import histrory from '../../../../../History';

export const getRosterPlayer = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/player/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getplayer => {
        dispatch({
            type: GET_PLAYER_SUCCESS,
            payload: getplayer.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const getOption = () => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_OPTION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/list`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getoption => {
        dispatch({
            type: GET_OPTION_SUCCESS,
            payload: getoption.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_OPTION_ERROR, error: error.response.data.message })
        }
    });
};

export const getPlayerOption = () => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_PLAYER_OPTION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/list`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getoption => {
        dispatch({
            type: GET_PLAYER_OPTION_SUCCESS,
            payload: getoption.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_OPTION_ERROR, error: error.response.data.message })
        }
    });
};

export const saveRoster = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: SAVE_PLAYER_ROSTER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/create`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(saveRoster => {
        dispatch({
            type: SAVE_PLAYER_ROSTER_SUCCESS,
            payload: saveRoster.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_PLAYER_ROSTER_ERROR, error: error.response.data.message })
        }
    });
};

export const savePlayerData = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: ADD_NEW_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/player/add`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: ADD_NEW_PLAYER_SUCCESS,
            payload: addnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_NEW_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const editPlayerData = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: EDIT_NEW_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/player/edit`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(editnew => {
        dispatch({
            type: EDIT_NEW_PLAYER_SUCCESS,
            payload: editnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: EDIT_NEW_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteRosterPlayer = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: DEL_NEW_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/player/delete`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(delnew => {
        dispatch({
            type: DEL_NEW_PLAYER_SUCCESS,
            payload: delnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DEL_NEW_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const displaySave = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: DIS_NEW_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/player/edit/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(disnew => {
        dispatch({
            type: DIS_NEW_PLAYER_SUCCESS,
            payload: disnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DIS_NEW_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const getLinkData = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: GTE_LINK_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/rostergroup/player/list/export`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getlink => {
        dispatch({
            type: GTE_LINK_PLAYER_SUCCESS,
            payload: getlink.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GTE_LINK_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const sendAllMailPlayer = (data) => (dispatch) => {
    checkIsValidCoachToken(histrory)
    axios({
        method: "POST",
        url: `${API_Url}bc/coach/roster/playergroup/send/email/allplayer`,
        data: data,
        headers: {
            "x-auth-token": Auth_Coach_Key,
            Accept: "application/json",
        },
    }).then((res) => {
        dispatch({
            type: ALL_MAIL_PLAYERINFO_SUCCESS,
            payload: res.data,
        });
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({
                type: ALL_MAIL_PLAYERINFO_ERROR,
                error: error.response.data.message,
            });
    })
};


// export const getLicenseName = (data) => dispatch => {
//     checkIsValidCoachToken(histrory);
//     dispatch({ type: GET_LICENSE_NAME_REQUEST })
//     axios({
//         method: 'POST',
//         url: `${API_Url}bc/subscription/allocation/product/list`,
//         data: data,
//         headers: {
//             'x-auth-token': Auth_Coach_Key,
//             'Accept': 'application/json'
//         }
//     }).then(getlic => {
//         dispatch({
//             type: GET_LICENSE_NAME_SUCCESS,
//             payload: getlic.data
//         })
//     }).catch((error) => {
//         if (error.response) {
//             console.log("error.response:--", error.response);
//             dispatch({ type: GET_LICENSE_NAME_ERROR, error: error.response.data.message })
//         }
//     });
// };

// export const getLicensePlayer = () => dispatch => {
//     checkIsValidCoachToken(histrory);
//     dispatch({ type: GET_LICENSE_PLAYER_REQUEST })
//     axios({
//         method: 'POST',
//         url: `${API_Url}bc/subscription/player/list`,
//         headers: {
//             'x-auth-token': Auth_Coach_Key,
//             'Accept': 'application/json'
//         }
//     }).then(getplayer => {
//         dispatch({
//             type: GET_LICENSE_PLAYER_SUCCESS,
//             payload: getplayer.data
//         })
//     }).catch((error) => {
//         if (error.response) {
//             console.log("error.response:--", error.response);
//             dispatch({ type: GET_LICENSE_PLAYER_ERROR, error: error.response.data.message })
//         }
//     });
// };

// export const addAssign = (data) => dispatch => {
//     checkIsValidCoachToken(histrory);
//     dispatch({ type: ADD_LICENSE_REQUEST })
//     axios({
//         method: 'POST',
//         url: `${API_Url}bc/subscription/allocate/licence`,
//         data: data,
//         headers: {
//             'x-auth-token': Auth_Coach_Key,
//             'Accept': 'application/json'
//         }
//     }).then(addassign => {
//         dispatch({
//             type: ADD_LICENSE_SUCCESS,
//             payload: addassign.data
//         })
//     }).catch((error) => {
//         if (error.response) {
//             console.log("error.response:--", error.response);
//             dispatch({ type: ADD_LICENSE_ERROR, error: error.response.data.message })
//         }
//     });
// };

// export const updateStatus = (data) => dispatch => {
//     checkIsValidCoachToken(histrory);
//     dispatch({ type: UP_STATUS_REQUEST })
//     axios({
//         method: 'POST',
//         url: `${API_Url}bc/subscription/allocate/licence/status/update`,
//         data: data,
//         headers: {
//             'x-auth-token': Auth_Coach_Key,
//             'Accept': 'application/json'
//         }
//     }).then(upstatus => {
//         dispatch({
//             type: UP_STATUS_SUCCESS,
//             payload: upstatus.data
//         })
//     }).catch((error) => {
//         if (error.response) {
//             console.log("error.response:--", error.response);
//             dispatch({ type: UP_STATUS_ERROR, error: error.response.data.message })
//         }
//     });
// };



import React, { Component } from "react";
import Header from "../containers/header";
import Footer from "../containers/footer";
import { imagesArr } from "../assets/images";
import CommonHeroBanner from "../components/commonHeroBanner";
import OurTeamDec from "../views/popup/ourTeamDec";

export class OurTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomIndex: 0,
      openPopup: false,
    };
  }

  componentDidMount = () => {
    localStorage.removeItem("company");
    localStorage.removeItem("cid");
    localStorage.removeItem("companyColor");
    let defaultColor = [
      {
        variable: "--green-color",
        colorCode: "#7CBD42"
      },
      {
        variable: "--green-color-light",
        colorCode: "#96CF04"
      },
      {
        variable: "--green-color-shadow",
        colorCode: "#7CBD42"
      },
      {
        variable: "--green-color-extra-light",
        colorCode: "#eaf6cc"
      },
    ]
    this.setThemeColor(defaultColor);
  }

  setThemeColor = (colArr) => {
    localStorage.setItem("companyColor", JSON.stringify(colArr));
    for (let i = 0; i < colArr.length; i++) {
      const element = colArr[i];
      document.body.style.setProperty(element.variable, element.colorCode);
    }
  }

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      openPopup: !this.state.openPopup,
    });
  };

  render() {
    const { openPopup, roomIndex } = this.state;
    return (
      <>
        <Header />
        <CommonHeroBanner dataList="" />
        <section className="ourteam">
          <div className="container">
            <div className="heading-title">
              <h2>Front Office</h2>
            </div>
            <div className="ourteam_author">
              <div className="ourteam_author_part">
                <div className="ourteam_img">
                  <img src={imagesArr.ourteam1} alt="ourteam1" />
                </div>
                <h5>Kevin Davidson</h5>
                <h6>
                  CEO of DS Sports Ventures CEO and Co-Founder of BaseballCloud
                </h6>
                <span
                  onClick={() =>
                    this.setState({
                      roomIndex: 0,
                      openPopup: true,
                    })
                  }
                >
                  Bio
                </span>
              </div>
              <div className="ourteam_author_part">
                <div className="ourteam_img">
                  <img src={imagesArr.ourteam2} alt="ourteam2" />
                </div>
                <h5>Corey Whiting</h5>
                <h6>
                  CTO of DS Sports Ventures CTO and Co-Founder of BaseballCloud
                </h6>
                <span
                  onClick={() =>
                    this.setState({
                      roomIndex: 1,
                      openPopup: true,
                    })
                  }
                >
                  Bio
                </span>
              </div>
              <div className="ourteam_author_part">
                <div className="ourteam_img">
                  <img src={imagesArr.ourteam3} alt="ourteam3" />
                </div>
                <h5>Joseph Sleiman</h5>
                <h6>
                  President of DS Sports Ventures Co-Founder of BaseballCloud
                </h6>
                <span
                  onClick={() =>
                    this.setState({
                      roomIndex: 2,
                      openPopup: true,
                    })
                  }
                >
                  Bio
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="white_bg ourteam">
          <div className="heading-title">
            <h2>Management</h2>
          </div>
          <div className="container">
            <div className="staff_inner">
              <div className="staff_inner_part">
                <h6>Annie Cross-Codron</h6>
                <span>
                  COO <br /> annie@dssports.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Betsy Holl</h6>
                <span>
                  CFO <br /> betsy@dssports.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Ryan Reinsel</h6>
                <span>
                  Solutions Architect <br /> ryanr@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Matt Schafer</h6>
                <span>
                  Director of Development <br /> matts@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Jon Updike</h6>
                <span>
                  President of Player <br /> Development <br />
                  updike@dssports.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Rob Sitz</h6>
                <span>
                  President of Baseball <br /> Operations <br />
                  rob@russmatt.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Brooks Robinson</h6>
                <span>
                  Sr. Product Coordinator, <br /> College <br />
                  brooks@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Eric Sexton</h6>
                <span>
                  Sr. Product Coordinator <br /> Amateur/Agency <br />
                  eric@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>William Sleiman</h6>
                <span>
                  Business Operations/Strategic Initiatives<br />
                  ws@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Mary Plemons</h6>
                <span>
                  Sr. Project Manager <br />
                  maryp@baseballcloud.com
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="white_bg ourteam">
          <div className="heading-title">
            <h2>Team</h2>
          </div>
          <div className="container">
            <div className="staff_inner three-parts">
              <div className="staff_inner_part">
                <h6>Kellen Brown</h6>
                <span>
                  Project Manager <br /> kellen@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Danny Chambliss</h6>
                <span>
                  Product Coordinator <br /> danny@baseballcloud.com
                </span>
              </div>

              <div className="staff_inner_part">
                <h6>Eric Albers</h6>
                <span>
                  Senior Unity Engineer <br /> eric.albers@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Will Stern</h6>
                <span>
                  Analytics Coordinator <br /> will@baseballcloud.com
                </span>
              </div>

              <div className="staff_inner_part">
                <h6>Kyle Wisotzkey</h6>
                <span>
                  Technical Artist <br /> kyle.wiso@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Ryan Holland</h6>
                <span>
                  Assistant, R&D <br /> ryanh@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Maurizio Ortiz</h6>
                <span>
                  Director, Latin America <br /> maurizio@baseballcloud.com
                </span>
              </div>
              <div className="staff_inner_part">
                <h6>Andrew Knight</h6>
                <span>
                  College Sales <br /> andrew@baseballcloud.com
                </span>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <OurTeamDec
          flag={openPopup}
          dataIndex={roomIndex}
          close={this.handleClose}
        />
      </>
    );
  }
}

export default OurTeam;

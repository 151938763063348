import React, { Component } from 'react'
import { imagesArr } from '../../../../assets/images';
import Purchase from '../../../popup/purchaseProspectwire';
import MainBoard from "../leaderboard/mainBoard";
import Preloader from '../../../../components/preloader';
import DataTab from './dataTab';
class stats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchase: false,
            loaderCount: 0,
        };
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { summaryData, sendReq } = this.props;
        const { purchase, loaderCount } = this.state;
        return (
            <>
                <DataTab increment={this.increment} decrement={this.decrement} summaryData={summaryData} sendReq={sendReq} setSendReq={this.props.setSendReq} onHandleMstype={this.props.onHandleMstype} onHandleField={this.props.onHandleField} getAllFields={this.props.getAllFields} />

                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                    />
                }
                <Preloader flag={loaderCount > 0 ? true : false} />
            </>
        )
    }
}

export default stats;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import DatePicker from "react-datepicker";
import { imagesArr } from "../../../assets/images";
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../../businesslogic/commonCalls";
import InputMask from 'react-input-mask';
import moment from "moment";
import { saveRecommendedForm } from '../../../saga/actions/common/recommendProspect.action';
import Preloader from "../../../components/preloader";
import * as data from "../../../businesslogic/country.json";

let otherArr = [];
class pwbRecommendProspect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                fName: "",
                lName: "",
                address: "",
                city: "",
                state: "",
                country: "United States",
                zipcode: "",
                gameType: 1,
                momEmail: "",
                dadEmail: "",
                homePhone: "",
                mobilePhone: "",
                playerEmail: "",
                highSchool: "",
                hsGradYear: "",
                gpa: "",
                fallTeam: "",
                summerTeam: "",
                playerTwitterHandle: "",
                birthDate: "",
                bats: "",
                throws: "",
                height: "",
                weight: "",
                playerAbility: "",
                primaryPosition1: "",
                primaryPosition2: "",
                otherPosition: "",
                company: "PW Baseball"
            },
            opencal: false,
            primaryPosArr: [
                {
                    label: "RHP",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "1B",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "SS",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "IF",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "UT",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "LHP",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "2B",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "MIF",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "OF",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "BHP",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "C",
                    isDisable: false,
                    isCheck: false
                },
                {
                    label: "3B",
                    isDisable: false,
                    isCheck: false
                }
            ],
            loader: false,
            stateList: [],
            countryList: data.default.countries
        }
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        data.default.countries.map((a) => {
            if (a.country == "United States") {
                this.setState({
                    stateList: a.states,
                });
            }
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.recommendForm &&
            nextProps.recommendForm.saveRecommendedFormReq &&
            nextProps.recommendForm.saveRecommendedFormData
        ) {
            nextProps.recommendForm.saveRecommendedFormReq = false;
            if (nextProps.recommendForm.saveRecommendedFormData.flag) {
                this.commonCall.successMessage(nextProps.recommendForm.saveRecommendedFormData.message);
                nextProps.recommendForm.saveRecommendedFormData = null;
                this.setState({
                    sendReq: {
                        fName: "",
                        lName: "",
                        address: "",
                        city: "",
                        state: "",
                        country: "United States",
                        zipcode: "",
                        gameType: 1,
                        momEmail: "",
                        dadEmail: "",
                        homePhone: "",
                        mobilePhone: "",
                        playerEmail: "",
                        highSchool: "",
                        hsGradYear: "",
                        gpa: "",
                        fallTeam: "",
                        summerTeam: "",
                        playerTwitterHandle: "",
                        birthDate: "",
                        bats: "",
                        throws: "",
                        height: "",
                        weight: "",
                        playerAbility: "",
                        primaryPosition1: "",
                        primaryPosition2: "",
                        otherPosition: "",
                        company: "PW Baseball"
                    }
                }, () => {
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.recommendForm.saveRecommendedFormData.message);
                nextProps.recommendForm.saveRecommendedFormData = null;
                this.setLoader(false);
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;

        if (name === "primaryPosition1") {
            this.state.primaryPosArr.some((item, i) => {
                if (item.label === value) {
                    otherArr.map((val, i) => {
                        if (val === value) {
                            otherArr.splice(i, 1);
                        }
                    });
                    Object.assign(item, { isCheck: false });
                    Object.assign(item, { isDisable: true });
                } else {
                    Object.assign(item, { isDisable: false });
                }
            });
            this.setState({
                primaryPosArr: this.state.primaryPosArr
            })
        }

        if (name === "otherPosition") {
            let old = otherArr.some((item) => {
                return item === value;
            });
            if (old) {
                this.state.primaryPosArr.some((item, i) => {
                    if (item.label === value) {
                        Object.assign(item, { isCheck: false });
                    }
                });
                otherArr.map((val, i) => {
                    if (val === value) {
                        otherArr.splice(i, 1);
                    }
                });
            } else {
                this.state.primaryPosArr.some((item, i) => {
                    if (item.label === value) {
                        Object.assign(item, { isCheck: true });
                    }
                });
                otherArr.push(value);
            }
        }

        if (name === "country") {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: value
                }
            }, () => {
                this.state.countryList.map((a) => {
                    if (a.country === value) {
                        this.setState({
                            stateList: a.states,
                        });
                    }
                });
            })
        } else if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    this.setState({
                        sendReq: {
                            ...this.state.sendReq,
                            [name]: value
                        }
                    })
                }
            }
        } else if (name === "weight") {
            const re = /^[0-9\b]+$/;
            if (re.test(value) || value.length <= 0) {
                this.setState({
                    sendReq: {
                        ...this.state.sendReq,
                        [name]: value
                    }
                })
            }
        } else {
            this.setState({
                sendReq: {
                    ...this.state.sendReq,
                    [name]: (name === "gameType") ? JSON.parse(value) : (name === "otherPosition") ? otherArr : value
                }
            })
        }
    }

    onChangeDate = (value) => {
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                birthDate: value
            }
        })
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    renderPrimaryPositions = (type) => {
        return (
            this.state.primaryPosArr.map((item, i) => {
                return (
                    <label className="round-radio" key={i}>
                        {item.label}
                        {
                            type === "radio" ?
                                <input type={type} name="primaryPosition1" value={item.label} onChange={(e) => this.onHandleChange(e)} />
                                :
                                <input type={type} name="otherPosition" value={item.label} checked={item.isCheck} onChange={(e) => this.onHandleChange(e)} disabled={item.isDisable} />
                        }
                        <span className="checkmark"></span>
                    </label>
                )
            })

        )
    }

    submitRecommended = () => {
        if (this.validator.allValid()) {
            console.log("this.state.sendReq:--", this.state.sendReq);
            this.setLoader(true);
            this.props.saveRecommendedForm(this.state.sendReq);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { sendReq, loader, countryList, stateList } = this.state;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        const yearsOfGrade = Array.from(new Array(18), (val, index) => (((new Date()).getFullYear() + 11) - index))
        const sorter = (a, b) => {
            if (a !== b) {
                return a - b;
            }
        };
        yearsOfGrade.sort(sorter);
        return (
            <>
                <Header />
                <div className="event-main pwbrecommendprospect">
                    <div className="container">
                        <h1 className="section_title mb-20">Recommend a Prospect</h1>
                        {/* <p className="rp-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cras urna gravida duis in nulla. Nec at bibendum tristique feugiat volutpat amet enim quisque.</p> */}
                        <div className="personal-form">
                            <h4 className="form-title">Personal Information</h4>
                            <div className="form-section">
                                <form>
                                    <div className="row">
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>First Name<span>*</span></label>
                                                <input type="text" name="fName" value={sendReq.fName} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter First name" />
                                                <span className="validMsg">{this.validator.message('First Name', sendReq.fName, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Last Name<span>*</span></label>
                                                <input type="text" name="lName" value={sendReq.lName} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter last name" />
                                                <span className="validMsg">{this.validator.message('last Name', sendReq.lName, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Country<span>*</span></label>
                                                <select className="custom-select" name="country" value={sendReq.country} onChange={(e) => this.onHandleChange(e)}>
                                                    <option value="">Select country</option>
                                                    {
                                                        countryList &&
                                                        countryList.map((item) => {
                                                            return (
                                                                <option value={item.country}>{item.country}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="validMsg">{this.validator.message('Country', sendReq.country, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-75">
                                            <div className="form-group">
                                                <label>Address<span>*</span></label>
                                                <input type="text" name="address" value={sendReq.address} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter address" />
                                                <span className="validMsg">{this.validator.message('address', sendReq.address, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="form-group">
                                                <label>State<span>*</span></label>
                                                <select name="state" value={sendReq.state} onChange={(e) => this.onHandleChange(e)} className="custom-select">
                                                    <option value="">Select state</option>
                                                    {
                                                        stateList &&
                                                        stateList.map((items) => {
                                                            return (
                                                                <option value={items}>{items}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="validMsg">{this.validator.message('state', sendReq.state, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Mom Email</label>
                                                <input type="email" name="momEmail" value={sendReq.momEmail} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter mom email address" />
                                                <span className="validMsg">{this.validator.message('Mom Email', sendReq.momEmail, 'email')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Dad Email</label>
                                                <input type="email" name="dadEmail" value={sendReq.dadEmail} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter dad email address" />
                                                <span className="validMsg">{this.validator.message('Dad Email', sendReq.dadEmail, 'email')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>City<span>*</span></label>
                                                <input type="text" name="city" value={sendReq.city} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter city" />
                                                <span className="validMsg">{this.validator.message('city', sendReq.city, 'required')}</span>
                                            </div>
                                        </div>

                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Home Phone<span>*</span></label>
                                                <InputMask {...this.props} className="form-control" mask="999-999-9999" name="homePhone" value={sendReq.homePhone} onChange={(e) => this.onHandleChange(e)} placeholder="000-000-0000" />
                                                <span className="validMsg">{this.validator.message('Home Phone', sendReq.homePhone, 'required|phone')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Mobile Phone</label>
                                                <InputMask {...this.props} className="form-control" mask="999-999-9999" name="mobilePhone" value={sendReq.mobilePhone} onChange={(e) => this.onHandleChange(e)} placeholder="000-000-0000" />
                                                <span className="validMsg">{this.validator.message('Mobile Phone', sendReq.mobilePhone, 'phone')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Zip<span>*</span></label>
                                                <input type="text" name="zipcode" value={sendReq.zipcode} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter zip" />
                                                <span className="validMsg">{this.validator.message('Zip', sendReq.zipcode, 'required')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="additional-form">
                            <h4 className="form-title">Additional Information</h4>
                            <div className="form-section">
                                <form>
                                    <div className="row">
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Game type<span>*</span></label>
                                                <select name="gameType" value={sendReq.gameType} onChange={(e) => this.onHandleChange(e)} className="custom-select">
                                                    <option value={2}>Softball</option>
                                                    <option value={1}>Baseball</option>
                                                </select>
                                                <span className="validMsg">{this.validator.message('Game type', sendReq.gameType, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Player Email<span>*</span></label>
                                                <input type="email" name="playerEmail" value={sendReq.playerEmail} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter player email address" />
                                                <span className="validMsg">{this.validator.message('Player Email', sendReq.playerEmail, 'required|email')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>High School<span>*</span>(or will be attending)</label>
                                                <input type="text" name="highSchool" value={sendReq.highSchool} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter highschool details" />
                                                <span className="validMsg">{this.validator.message('High School', sendReq.highSchool, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>HS Grad Year<span>*</span></label>
                                                <select name="hsGradYear" value={sendReq.hsGradYear} onChange={(e) => this.onHandleChange(e)} className="custom-select">
                                                    <option value="">Select grad</option>
                                                    {
                                                        yearsOfGrade &&
                                                        yearsOfGrade.map((item) => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="validMsg">{this.validator.message('HS Grad Year', sendReq.hsGradYear, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>GPA<span>*</span>(4.0 scale)</label>
                                                <InputMask {...this.props} className="form-control" mask="9.9" name="gpa" value={sendReq.gpa} onChange={(e) => this.onHandleChange(e)} placeholder="Enter GPA" />
                                                <span className="validMsg">{this.validator.message('gpa', sendReq.gpa, 'required|numeric')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Fall Team</label>
                                                <input type="text" name="fallTeam" value={sendReq.fallTeam} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter fall team" />
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Player Twitter Handle</label>
                                                <input type="text" name="playerTwitterHandle" value={sendReq.playerTwitterHandle} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter player twitter handle" />
                                            </div>
                                        </div>

                                        <div className="w-33">
                                            <div className="form-group">
                                                <div className="form-group ">
                                                    <label>Birth Date<span>*</span>(i.e. 07/15/2004)</label>
                                                    <div className="bc_relative">
                                                        <DatePicker
                                                            ref={(c) => (this._calendar = c)}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => (
                                                                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                                    <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                                        {years.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                                        {months.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                                </div>
                                                            )}
                                                            selected={sendReq.birthDate}
                                                            maxDate={moment().subtract(1, 'days').toDate()}
                                                            onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                            onChange={this.onChangeDate}
                                                            placeholderText="Enter your birth date"
                                                        />
                                                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                                            this.state.opencal = !this.state.opencal;
                                                            this._calendar.setOpen(this.state.opencal);
                                                        }} />
                                                    </div>
                                                    <span className="validMsg">{this.validator.message('Date of Birth', sendReq.birthDate, 'required')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Bats<span>*</span></label>
                                                <select name="bats" value={sendReq.bats} onChange={(e) => this.onHandleChange(e)} className="custom-select">
                                                    <option value="">Select bats</option>
                                                    <option value="Right">Right</option>
                                                    <option value="Left">Left</option>
                                                    <option value="Switch">Switch</option>
                                                </select>
                                                <span className="validMsg">{this.validator.message('Bats', sendReq.bats, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Throws<span>*</span></label>
                                                <select name="throws" value={sendReq.throws} onChange={(e) => this.onHandleChange(e)} className="custom-select">
                                                    <option value="">Select throws</option>
                                                    <option value="Right">Right</option>
                                                    <option value="Left">Left</option>
                                                    <option value="Both">Both</option>
                                                </select>
                                                <span className="validMsg">{this.validator.message('throws', sendReq.throws, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Describe player's playing ability<span>*</span></label>
                                                <input type="text" name="playerAbility" value={sendReq.playerAbility} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Write here....." />
                                                <span className="validMsg">{this.validator.message('player ability', sendReq.playerAbility, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Height<span>*</span></label>
                                                <select name="height" value={sendReq.height} onChange={(e) => this.onHandleChange(e)} className="custom-select">
                                                    <option value="">Select height</option>
                                                    <option value="3-0">3-0</option>
                                                    <option value="3-1">3-1</option>
                                                    <option value="3-2">3-2</option>
                                                    <option value="3-3">3-3</option>
                                                    <option value="3-4">3-4</option>
                                                    <option value="3-5">3-5</option>
                                                    <option value="3-6">3-6</option>
                                                    <option value="3-7">3-7</option>
                                                    <option value="3-8">3-8</option>
                                                    <option value="3-9">3-9</option>
                                                    <option value="3-10">3-10</option>
                                                    <option value="3-11">3-11</option>
                                                    <option value="4-0">4-0</option>
                                                    <option value="4-1">4-1</option>
                                                    <option value="4-2">4-2</option>
                                                    <option value="4-3">4-3</option>
                                                    <option value="4-4">4-4</option>
                                                    <option value="4-5">4-5</option>
                                                    <option value="4-6">4-6</option>
                                                    <option value="4-7">4-7</option>
                                                    <option value="4-8">4-8</option>
                                                    <option value="4-9">4-9</option>
                                                    <option value="4-10">4-10</option>
                                                    <option value="4-11">4-11</option>
                                                    <option value="5-0">5-0</option>
                                                    <option value="5-1">5-1</option>
                                                    <option value="5-2">5-2</option>
                                                    <option value="5-3">5-3</option>
                                                    <option value="5-4">5-4</option>
                                                    <option value="5-5">5-5</option>
                                                    <option value="5-6">5-6</option>
                                                    <option value="5-7">5-7</option>
                                                    <option value="5-8">5-8</option>
                                                    <option value="5-9">5-9</option>
                                                    <option value="5-10">5-10</option>
                                                    <option value="5-11">5-11</option>
                                                    <option value="6-0">6-0</option>
                                                    <option value="6-1">6-1</option>
                                                    <option value="6-2">6-2</option>
                                                    <option value="6-3">6-3</option>
                                                    <option value="6-4">6-4</option>
                                                    <option value="6-5">6-5</option>
                                                    <option value="6-6">6-6</option>
                                                    <option value="6-7">6-7</option>
                                                    <option value="6-8">6-8</option>
                                                    <option value="6-9">6-9</option>
                                                    <option value="6-10">6-10</option>
                                                    <option value="6-11">6-11</option>
                                                    <option value="7-0">7-0</option>
                                                    <option value="7-1">7-1</option>
                                                    <option value="7-2">7-2</option>
                                                    <option value="7-3">7-3</option>
                                                </select>
                                                <span className="validMsg">{this.validator.message('height', sendReq.height, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="w-33">
                                            <div className="form-group">
                                                <label>Weight<span>*</span></label>
                                                <input type="text" name="weight" value={sendReq.weight} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter weight" />
                                                <span className="validMsg">{this.validator.message('Weight', sendReq.weight, 'required|numeric')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="other-form">
                            <h4 className="form-title">Personal Information</h4>
                            <div className="form-section">
                                <form>
                                    <div className="row">
                                        <div className="w-50">
                                            <div className="form-group">
                                                <label>Primary Position<span>*</span></label>
                                                <div className="radio-bg">
                                                    {this.renderPrimaryPositions("radio")}
                                                </div>
                                                <span className="validMsg">{this.validator.message('Primary Position', sendReq.primaryPosition1, 'required')}</span>
                                            </div>
                                        </div>

                                        <div className="w-50">
                                            <div className="form-group">
                                                <label>Other Position(S)</label>
                                                <div className="radio-bg">
                                                    {this.renderPrimaryPositions("checkbox")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <div className="form-group">
                                                <label>Who is submitting this form<span>*</span> </label>
                                                <div className="radio-bg">
                                                    <label className="round-radio">Parent
                                                        <input type="radio" name="primaryPosition2" value="Parent" onChange={(e) => this.onHandleChange(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="round-radio">Player
                                                        <input type="radio" name="primaryPosition2" value="Player" onChange={(e) => this.onHandleChange(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="round-radio">Coach
                                                        <input type="radio" name="primaryPosition2" value="Coach" onChange={(e) => this.onHandleChange(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="round-radio">Other
                                                        <input type="radio" name="primaryPosition2" value="Other" onChange={(e) => this.onHandleChange(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <span className="validMsg">{this.validator.message('submitting person', sendReq.primaryPosition2, 'required')}</span>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="text-center">
                            <button class="btn" onClick={() => this.submitRecommended()}>Submit</button>
                        </div>
                    </div>
                </div>
                <br />
                <Footer />
                <Preloader flag={loader} />
            </>
        )
    }
}

pwbRecommendProspect.propTypes = {
    saveRecommendedForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    recommendForm: state.recommendProspect,
});
export default connect(mapStateToProps, { saveRecommendedForm })(pwbRecommendProspect);
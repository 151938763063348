import {
    SET_ROLE_REQUEST,
    SET_ROLE_SUCCESS,
    SET_ROLE_ERROR,
} from '../../../constants/authantication/signup/step3.const';

const initialState = {
    roleReq: false,
    roleData: null,
    roleError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Set Role

        case SET_ROLE_REQUEST:
            return {
                ...state,
                roleReq: true
            };
        case SET_ROLE_SUCCESS:
            return {
                ...state,
                roleData: action.payload,
            };
        case SET_ROLE_ERROR:
            return {
                ...state,
                roleError: action.error,
            };

        default:
            return state;
    }
}
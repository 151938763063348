import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import Strength from '../../../popup/strength';
// import { Player } from 'video-react';
import ReactPlayer from 'react-player';
import { getPersonalInfo, sendConnectionReq, saveCommittedFields } from '../../../../saga/actions/views/company/personalInfo.action';
import moment from "moment";
import { getSumOfRegisterTeam, getAvailableSport, commonHeader } from "../../../../businesslogic/content";
import commonCalls from "../../../../businesslogic/commonCalls";
import ReactTooltip from 'react-tooltip';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";
import { Link } from 'react-router-dom';
import PwLock from "./pwLock";
import PurchaseProspectwire from '../../../popup/purchaseProspectwire';
import Slider from "react-slick";
class personalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            personalData: [],
            strength: false,
            playing: false,
            playerid: "",
            manageButton: true,
            shareBtn: false,
            purchase: false
        };
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.props.onRef(this);
        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];
        if (!localStorage.getItem("cid") || url.pathname.split("/")[3] || url.pathname.split("/")[4]) {
            localStorage.setItem("cid", url.pathname.split("/")[3])
            localStorage.setItem("company", url.pathname.split("/")[4]);
            this.setThemeColor(commonHeader.events.find(_ => _.key === url.pathname.split("/")[4]).colors);
        }
        this.setState({
            playerid: pid,
            manageButton: (pid === localStorage.getItem("playerId") || !localStorage.getItem("webglToken")) ? false : true
        }, () => {
            this.props.increment();
            this.props.getPersonalInfo(pid);
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.personalInfo &&
            nextProps.personalInfo.getPersonalInfoReq &&
            nextProps.personalInfo.getPersonalInfoData
        ) {
            nextProps.personalInfo.getPersonalInfoReq = false;
            console.log(" nextProps.personalInfo.getPersonalInfoData:--", nextProps.personalInfo.getPersonalInfoData);
            if (nextProps.personalInfo.getPersonalInfoData.flag) {
                this.setState({
                    personalData: nextProps.personalInfo.getPersonalInfoData.result
                }, () => {
                    nextProps.personalInfo.getPersonalInfoData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.personalInfo.getPersonalInfoData.message);
                nextProps.personalInfo.getPersonalInfoData = null;
                this.props.decrement();
            }
        }

        if (nextProps.personalInfo &&
            nextProps.personalInfo.setConnectionReq &&
            nextProps.personalInfo.setConnection
        ) {
            nextProps.personalInfo.setConnectionReq = false;
            console.log(" nextProps.personalInfo.setConnection:--", nextProps.personalInfo.setConnection);
            if (nextProps.personalInfo.setConnection.flag) {
                nextProps.personalInfo.setConnection = null;
                this.props.getPersonalInfo(this.state.playerid);
            } else {
                this.commonCall.errorMessage(nextProps.personalInfo.setConnection.message);
                nextProps.personalInfo.setConnection = null;
                this.props.decrement();
            }
        }

        if (nextProps.personalInfo &&
            nextProps.personalInfo.committedDataReq &&
            nextProps.personalInfo.committedData
        ) {
            nextProps.personalInfo.committedDataReq = false;
            console.log(" nextProps.personalInfo.committedData:--", nextProps.personalInfo.committedData);
            if (nextProps.personalInfo.committedData.flag) {
                nextProps.personalInfo.committedData = null;
                this.props.getPersonalInfo(this.state.playerid);
            } else {
                this.commonCall.errorMessage(nextProps.personalInfo.committedData.message);
                nextProps.personalInfo.committedData = null;
                this.props.decrement();
            }
        }
    }



    setThemeColor = (colArr) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
    }

    rankingStrength = () => {
        this.setState({
            strength: true,
        })
    }

    handleClose = () => {
        this.setState({
            strength: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    purchasePW = () => {
        this.setState({
            purchase: true,
        })
    }

    handlePurchaseClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    setConnection = (pid, follow) => {
        this.props.increment();
        this.props.sendConnectionReq({ followingId: pid, isFollow: !follow, companyId: localStorage.getItem("cid") });
    }

    renderProfile = () => {
        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];
        this.setState({
            manageButton: (pid === localStorage.getItem("playerId") || !localStorage.getItem("webglToken")) ? false : true
        }, () => {
            this.props.increment();
            this.props.getPersonalInfo(pid);
        })
    }

    saveCommitted = () => {
        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];
        this.setState({
            playerid: pid,
        }, () => {
            this.props.increment();
            this.props.saveCommittedFields({ committed: this.state.personalData.committed });
        })
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            personalData: {
                ...this.state.personalData,
                committed: value
            }
        })
    }

    render() {
        const { personalData, strength, manageButton, shareBtn, purchase, playerid } = this.state;
        let url = new URL(document.location);
        let siteUrl = url.origin;
        var team = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        };
        var upcomingevent = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        };
        var playervideo = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        };
        return (
            <>
                <ReactTooltip className="tooltip" type='dark' html={true} />
                <div className="personal-info">
                    <div className="pi-box">
                        <div className="user-img">
                            <div className="media">
                                <img src={personalData.profileUrl ? personalData.profileUrl : imagesArr.default_user} />
                                <div className="media-info">
                                    <h5>{personalData.fname + " " + personalData.lname}</h5>
                                    {
                                        manageButton ?
                                            <button className="btn" onClick={() => this.setConnection(personalData._id, personalData.follow)}>{personalData.follow ? "Unfollow" : "Follow"}</button>
                                            :
                                            <div className={`share-icon ${shareBtn ? 'active' : ''}`}>
                                                <Link className="btn blue_btn mr-0" onClick={() => this.setState({ shareBtn: !shareBtn })}>Share</Link>
                                                <ul>
                                                    <li><FacebookShareButton url={`${siteUrl}/pw-profile-info/${personalData._id}`} children={<FacebookIcon size={32} round={true} />} /></li>
                                                    <li> <EmailShareButton url={`${siteUrl}/pw-profile-info/${personalData._id}`} subject="Baseballcloud" children={<EmailIcon size={32} round={true} />} /></li>
                                                    <li><TwitterShareButton url={`${siteUrl}/pw-profile-info/${personalData._id}`} children={<TwitterIcon size={32} round={true} />} /></li>
                                                </ul>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pi-box">
                        <div className="user-info">
                            <div className="pi-input">
                                <div className="form-group">
                                    <label>City/State :</label>
                                    <input type="text" className="form-control" value={(personalData.city === "" && personalData.State === "") ? "-" : personalData.city + ", " + personalData.State} disabled />
                                </div>
                            </div>
                            <div className="pi-input">
                                <div className="form-group">
                                    <label>Positions :
                                        {/* {
                                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                                ""
                                                :
                                                <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                        <b>What is it?</b>
                                        <p>The maximum speed of the sweet spot of the barrel of the bat during the 
                                        swing. The sweet spot is 20% of the bat length from the tip of the bat.</p>
                                        </span>"/>
                                        } */}
                                    </label>
                                    {
                                        // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" ?
                                            <input type="text" className="form-control" value={personalData.position ? personalData.position : "-"} disabled />
                                            // :
                                            // <>
                                            //     <input type="text" className="form-control" value="" disabled />
                                            //     <div className="loc-section" onClick={() => this.purchasePW()}>
                                            //         <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                            //     </div>
                                            //     {/* <PwLock lock={imagesArr.lock} image={imagesArr.lock_pw} showLock={true} /> */}
                                            // </>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pi-box">

                        <div className="user-info">
                            <div className="pi-input">
                                <div className="form-group">
                                    <label>Age :</label>
                                    <input type="text" className="form-control" value={personalData.age ? personalData.age : "-"} disabled />
                                </div>
                            </div>
                            <div className="pi-input">
                                <div className="form-group">
                                    <label>Grad year :</label>
                                    <input type="text" className="form-control" value={personalData.gradeYear ? personalData.gradeYear : "-"} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pi-box">

                        <div className="user-info">
                            <div className="pi-input">
                                <div className="form-group">
                                    <label>Height/Weight :</label>
                                    <input type="text" className="form-control" value={personalData.height + " / " + personalData.weight} disabled />
                                </div>
                            </div>
                            <div className="pi-input">
                                <div className="form-group">
                                    <label>Bats/Throws :</label>
                                    <input type="text" className="form-control" value={(personalData.bat === "" && personalData.throw === "") ? "-" : personalData.bat + " / " + personalData.throw} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="score-info">
                    <div className="score-box">
                        <div>
                            <h3 className="score-box-titel">My Snapshot</h3>
                            <div className="rank-form">
                                <div className="rank-group">
                                    <div className="rank-col col-2">
                                        <div className="form-group">
                                            <label>National Ranking :
                                                {/* {
                                                    localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" && localStorage.getItem("subscribe") !== "Premium" &&
                                                    <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                                            <b>What is it?</b>
                                                <p>See where you rank amongst players from across the country!</p>
                                                </span>"/>
                                                } */}
                                            </label>
                                            <div className="input-group">
                                                {
                                                    // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" ?
                                                        <>
                                                            <input className="form-control" type="text" value={personalData?.national?.pwRecord ? personalData?.national?.pwRecord : "-"} disabled />
                                                            <input className="form-control" type="text" value={personalData?.national?.position ? personalData?.national?.position : "-"} disabled />
                                                        </>
                                                        // :
                                                        // <>
                                                        //     <input className="form-control" type="text" value="AB" disabled />
                                                        //     <input className="form-control" type="text" value="12" disabled />

                                                        // </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* {
                                        localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" && localStorage.getItem("subscribe") !== "Premium" &&
                                        <div className="loc-section" onClick={() => this.purchasePW()}>
                                            <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                        </div>
                                    } */}
                                    <div className="rank-col col-2">
                                        <div className="form-group">
                                            <label>State Ranking :
                                                {/* {
                                                    localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" && localStorage.getItem("subscribe") !== "Premium" &&
                                                    <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                                            <b>What is it?</b>
                                                <p>See where you rank amongst players from your home state!</p>
                                                </span>"/>
                                                } */}
                                            </label>
                                            <div className="input-group">
                                                {
                                                    // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") || localStorage.getItem("subscribe") === "Premium" ?
                                                        <>
                                                            <input className="form-control" type="text" value={personalData?.state?.pwRecord ? personalData?.state?.pwRecord : "-"} disabled />
                                                            <input className="form-control" type="text" value={personalData?.state?.position ? personalData?.state?.position : "-"} disabled />
                                                        </>
                                                        // :
                                                        // <>
                                                        //     <input className="form-control" type="text" value="AB" disabled />
                                                        //     <input className="form-control" type="text" value="12" disabled />
                                                        // </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rank-group">
                                    <div className="rank-col">
                                        <div className="form-group">
                                            <label>Committed</label>
                                            {
                                                // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Free" || personalData?._id === localStorage.getItem("userID")) ?
                                                    <input className="form-control" onChange={(e) => this.onHandleChange(e)} onBlur={() => this.saveCommitted()} type="text" value={personalData?.committed} disabled={(personalData?._id === localStorage.getItem("userID") && localStorage.getItem("webglToken")) ? false : true} />
                                                    // :
                                                    // <>
                                                    //     <input className="form-control" type="text" value="1234" disabled />
                                                    //     <div className="loc-section" onClick={() => this.purchasePW()}>
                                                    //         <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                                    //     </div>
                                                    // </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="rank-group">
                                    <div className="rank-col col-3">
                                        <div className="form-group">
                                            <label>GPA
                                                {/* {localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" &&
                                                    <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                        <b>What is it?</b><p>The maximum speed of the sweet spot of the barrel of the bat during the swing. The sweet spot is 20% of the bat length from the tip of the bat.</p></span>"/>
                                                } */}
                                            </label>
                                            {
                                                // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") ?
                                                    <>
                                                        <input className="form-control" type="text" value={personalData?.GPA ? personalData?.GPA : "-"} disabled />
                                                    </>
                                                    // :
                                                    // <>
                                                    //     <input className="form-control" type="text" value="1234" disabled />
                                                    //     <div className="loc-section" onClick={() => this.purchasePW()}>
                                                    //         <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                                    //     </div>
                                                    // </>
                                            }
                                        </div>
                                    </div>
                                    <div className="rank-col col-3">
                                        <div className="form-group">
                                            <label>SAT</label>
                                            <input className="form-control" type="text" value={personalData?.sat ? personalData?.sat : "-"} disabled />
                                        </div>
                                    </div>
                                    <div className="rank-col col-3">
                                        <div className="form-group">
                                            <label>ACT</label>
                                            <input className="form-control" type="text" value={personalData?.act ? personalData?.act : "-"} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="score-box">
                        <h3 className="score-box-titel">Power Score</h3>
                        <div className="team-score">
                            <h3 className="text-center score-box-titel">Your Team</h3>
                            <Slider {...team}>
                                {
                                    personalData?.team &&
                                        personalData?.team.length !== 0 ?
                                        personalData?.team.map((item, i) => {
                                            return (
                                                <div className="team-info">
                                                    <img src={item?.parentTeam?.teamLogo?.filePath ? item?.parentTeam?.teamLogo?.filePath : imagesArr.default_user} alt="player_1" />
                                                    <h4>{item?.parentTeam?.name ? item?.parentTeam?.name : ""}</h4>
                                                </div>
                                            )
                                        })
                                        :
                                        <p className="text-center">No data found</p>
                                }
                            </Slider>
                            <div className="power-score">
                                <div className="form-group">
                                    <label>Power Score</label>
                                    <div className="input-btn">
                                        <input className="form-control" type="text" value={personalData?.pwRecord ? personalData?.pwRecord : "-"} disabled />
                                        {
                                            localStorage.getItem("webglToken") &&
                                            <button className="btn" onClick={() => this.rankingStrength('add')} disabled>Coming Soon</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="score-box">
                        <h3 className="score-box-titel">Upcoming Events
                            {/* {
                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium") ?
                                    ""
                                    :
                                    <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                        <b>What is it?</b><p>See what events you are registered for here</p></span>"/>
                            } */}
                        </h3>
                        <div className="schedule">
                            {
                                // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium") ?
                                    <Slider {...upcomingevent}>
                                        {
                                            personalData?.event &&
                                                personalData?.event.length !== 0 ?
                                                personalData?.event.map((item, i) => {
                                                    return (
                                                        <div className="event_box" key={i}>
                                                            <div className="event_card">
                                                                <div className="event_card_img">
                                                                    <img src={item?.eventImages?.filePath ? item?.eventImages?.filePath : imagesArr.default_user} alt="team logo" />
                                                                    <span class="event_card_address">
                                                                        {item?.location &&
                                                                            item?.location.city ?
                                                                            item?.location.city :
                                                                            item?.location.streetAddress ?
                                                                                item?.location && item?.location.streetAddress.split(",")[0] :
                                                                                item?.location.stateRegion}
                                                                    </span>
                                                                </div>
                                                                <div className="event_card_desc">
                                                                    <div className="event_card_logo">
                                                                        <img src={item?.eventLogo && item?.eventLogo.filePath ? item?.eventLogo.filePath : imagesArr.default_user} alt="team logo" />
                                                                    </div>
                                                                    <div className="event_card_titles">
                                                                        <h3>{item?.eventTitle}</h3>
                                                                        <div class="event_card_dates">
                                                                            <p class="date_icon">{moment(item?.startDate).format("DD-MMM")} - {moment(item?.endDate).format("DD-MMM")}</p>
                                                                            <p class="team_icons">{item?.payment.feesByGroups.length ? getSumOfRegisterTeam(item?.payment.feesByGroups) : 0} Player Registered</p>
                                                                            <p class="team_icons">{item?.payment.feesByGroups.length ? getAvailableSport(item?.payment.feesByGroups) : 0} Available Spots</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="event_box">
                                                    <div className="event_card">
                                                        <div className="event_card_img">
                                                            <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/2/f45b8fcb0f5968710c94bc0f83c4747f.gif" alt="team logo" style={{ objectFit: 'contain' }} />
                                                            <span class="event_card_address">Los angeles, US State</span>
                                                        </div>
                                                        <div className="event_card_desc">
                                                            <div className="event_card_logo">
                                                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/1/6e2427b4e3b016a9d0ee1fec2ee67c13.jpg" alt="team logo" />
                                                            </div>
                                                            <div className="event_card_titles">
                                                                <h3>National Event</h3>
                                                                <div class="event_card_dates">
                                                                    <p class="date_icon">9 Mar - 23 Mar</p>
                                                                    <p class="team_icons">10 Teams</p>
                                                                    <p class="team_icons">20 Available Spots</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </Slider>
                                    // :
                                    // <>
                                    //     <div className="event_box">
                                    //         <div className="event_card">
                                    //             <div className="event_card_img">
                                    //                 <img src={imagesArr.press_room_img3} alt="team logo" />
                                    //                 <span class="event_card_address">Los angeles, US State</span>
                                    //             </div>
                                    //             <div className="event_card_desc">
                                    //                 <div className="event_card_logo">
                                    //                     <img src={imagesArr.about_slide_3} alt="team logo" />
                                    //                 </div>
                                    //                 <div className="event_card_titles">
                                    //                     <h3>National Event</h3>
                                    //                     <div class="event_card_dates">
                                    //                         <p class="date_icon">9 Mar - 23 Mar</p>
                                    //                         <p class="team_icons">10 Teams</p>
                                    //                         <p class="team_icons">20 Available Spots</p>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //             <div className="lock_view">
                                    //                 <div className="logo_lock">
                                    //                     <div className="logo_img">
                                    //                         <img src={localStorage.getItem("company") === "pwb" ? imagesArr.lock_pw : imagesArr.lock_pws} />
                                    //                     </div>
                                    //                     <div className="lock_img" onClick={() => this.purchasePW()}>
                                    //                         <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </>
                            }
                        </div>
                    </div>
                    <div className="score-box">
                        <h3 className="score-box-titel">My Gallery</h3>
                        <div className="player-video">
                            {
                                personalData?.cVideos &&
                                    personalData?.cVideos.length !== 0 ?
                                    <Slider {...playervideo}>
                                        {
                                            personalData?.cVideos.map((item, i) => {
                                                return (
                                                    <div className="video-bg" style={{ width: "100%" }}>
                                                        <ReactPlayer
                                                            className='react-player'
                                                            url={item.cVideos.vId.filePath}
                                                            controls
                                                            width='100%'
                                                            height='282px'
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                    :
                                    <p className="text-center">No data found</p>
                            }
                        </div>
                    </div>
                </div>
                {
                    strength &&
                    <Strength
                        flag={strength}
                        close={this.handleClose}
                        increment={this.props.increment}
                        decrement={this.props.decrement}
                    />
                }
                {
                    purchase &&
                    <PurchaseProspectwire
                        flag={purchase}
                        close={this.handlePurchaseClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

personalInfo.propTypes = {
    getPersonalInfo: PropTypes.func.isRequired,
    sendConnectionReq: PropTypes.func.isRequired,
    saveCommittedFields: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    personalInfo: state.personalSummaryInfo
});

export default connect(mapStateToProps, { getPersonalInfo, sendConnectionReq, saveCommittedFields })(personalInfo);

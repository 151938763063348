import axios from "axios";
import { API_Url, Auth_Player_Key, checkIsValidPlayerToken } from "../../../../helpers/authToken";
import { GAMESTATS_ERROR, GAMESTATS_REG, GAMESTATS_SUCCESS } from "../../../../constants/view/player/profile/gameStats.const";
import histrory from '../../../../../History';

export const getGameStatsInfo = () => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/player/score`,
    headers: {
      "x-auth-token": Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: GAMESTATS_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: GAMESTATS_ERROR,
          error: error.response.data.message,
        });
    });
}

export const postGameStatsInfo = (data) => (dispatch) => {
  checkIsValidPlayerToken(histrory)
  return axios({
    method: "POST",
    url: `${API_Url}bc/player/score`,
    data: data,
    headers: {
      "x-auth-token": Auth_Player_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: GAMESTATS_REG,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: GAMESTATS_ERROR,
          error: error.response.data.message,
        });
    });
}

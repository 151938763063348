import axios from 'axios';
import { Auth_Coach_Key, Auth_Player_Key, API_Url, checkIsValidCoachToken, checkIsValidPlayerToken } from '../../helpers/authToken';
import {
    RECEIVE_BILLING_REQUEST,
    RECEIVE_BILLING_SUCCESS,
    RECEIVE_BILLING_ERROR,

    RECEIVE_PAYMENT_REQUEST,
    RECEIVE_PAYMENT_SUCCESS,
    RECEIVE_PAYMENT_ERROR,

    ADD_CARD_REQUEST,
    ADD_CARD_SUCCESS,
    ADD_CARD_ERROR,

    DELETE_CARD_REQUEST,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_ERROR,

    CHECK_CARD_TYPE_REQUEST,
    CHECK_CARD_TYPE_SUCCESS,
    CHECK_CARD_TYPE_ERROR,

    MAKE_DEFAULT_CARD_REQUEST,
    MAKE_DEFAULT_CARD_SUCCESS,
    MAKE_DEFAULT_CARD_ERROR,

} from '../../constants/common/billing.const';
import histrory from '../../../History';


export const fetchPaymentInfo = (userType) => (dispatch) => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: RECEIVE_PAYMENT_REQUEST })
    axios({
        method: "GET",
        url: `${API_Url}bc/subscription/card`,
        headers: {
            "x-auth-token": (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            Accept: "application/json",
        },
    })
        .then((get) => {
            dispatch({
                type: RECEIVE_PAYMENT_SUCCESS,
                payload: get.data.result
            });
        })
        .catch((error) => {
            if (error.response && error.response.status === 401)
                dispatch({ type: RECEIVE_PAYMENT_ERROR, error: error.response.data.message });
        });
};


export const fetchBillingInfo = (data, userType) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: RECEIVE_BILLING_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/my/payment`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(receive => {
        dispatch({
            type: RECEIVE_BILLING_SUCCESS,
            payload: receive.data.result
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: RECEIVE_BILLING_ERROR, error: error.response.data.message })
        }
    });
};

export const addCard = (sendData, userType) => dispatch => {
    console.log("post addcard data:--", sendData);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: ADD_CARD_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/card`,
        data: sendData,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(add => {
        dispatch({
            type: ADD_CARD_SUCCESS,
            payload: add.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_CARD_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteCard = (data, userType) => dispatch => {
    // console.log("card_id--->",data );
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: DELETE_CARD_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/deletecard`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(card => {
        // console.log("delet card:--", card);
        dispatch({
            type: DELETE_CARD_SUCCESS,
            payload: card.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DELETE_CARD_ERROR, error: error.response.data.message })
        }
    });
};

export const checkType = (data, userType) => dispatch => {
    // console.log("card_id--->",data );
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: CHECK_CARD_TYPE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/check/deletecard`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(chktype => {
        // console.log("delet chktype:--", chktype);
        dispatch({
            type: CHECK_CARD_TYPE_SUCCESS,
            payload: chktype.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: CHECK_CARD_TYPE_ERROR, error: error.response.data.message })
        }
    });
};

export const makeDefaultCard = (data, userType) => dispatch => {
    // console.log("card_id--->",data );
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: MAKE_DEFAULT_CARD_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/card/make/default`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(makedefault => {
        // console.log("delet makedefault:--", makedefault);
        dispatch({
            type: MAKE_DEFAULT_CARD_SUCCESS,
            payload: makedefault.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: MAKE_DEFAULT_CARD_ERROR, error: error.response.data.message })
        }
    });
};




export const ADD_PARENT_REQUEST = 'ADD_PARENT_REQUEST';
export const ADD_PARENT_SUCCESS = 'ADD_PARENT_SUCCESS';
export const ADD_PARENT_ERROR = 'ADD_PARENT_ERROR';

export const LIST_PARENT_REQUEST = 'LIST_PARENT_REQUEST';
export const LIST_PARENT_SUCCESS = 'LIST_PARENT_SUCCESS';
export const LIST_PARENT_ERROR = 'LIST_PARENT_ERROR';

export const EDIT_LINKED_PARENT_REQUEST = 'EDIT_LINKED_PARENT_REQUEST';
export const EDIT_LINKED_PARENT_SUCCESS = 'EDIT_LINKED_PARENT_SUCCESS';
export const EDIT_LINKED_PARENT_ERROR = 'EDIT_LINKED_PARENT_ERROR';

export const DISCONNECT_PARENT_REQUEST = 'DISCONNECT_PARENT_REQUEST';
export const DISCONNECT_PARENT_SUCCESS = 'DISCONNECT_PARENT_SUCCESS';
export const DISCONNECT_PARENT_ERROR = 'DISCONNECT_PARENT_ERROR';

export const PHONE_VERIFY_PARENT_REQUEST = 'PHONE_VERIFY_PARENT_REQUEST';
export const PHONE_VERIFY_PARENT_SUCCESS = 'PHONE_VERIFY_PARENT_SUCCESS';
export const PHONE_VERIFY_PARENT_ERROR = 'PHONE_VERIFY_PARENT_ERROR';


export const GET_LEAGUE_DETAILS_REQUEST = 'GET_LEAGUE_DETAILS_REQUEST';
export const GET_LEAGUE_DETAILS_SUCCESS = 'GET_LEAGUE_DETAILS_SUCCESS';
export const GET_LEAGUE_DETAILS_ERROR   = 'GET_LEAGUE_DETAILS_ERROR';

export const GET_PARTICIPATION_LEAGUE_REQUEST = 'GET_PARTICIPATION_LEAGUE_REQUEST';
export const GET_PARTICIPATION_LEAGUE_SUCCESS = 'GET_PARTICIPATION_LEAGUE_SUCCESS';
export const GET_PARTICIPATION_LEAGUE_ERROR   = 'GET_PARTICIPATION_LEAGUE_ERROR';

export const GET_LEAGUE_EVENTS_REQUEST = 'GET_LEAGUE_EVENTS_REQUEST';
export const GET_LEAGUE_EVENTS_SUCCESS = 'GET_LEAGUE_EVENTS_SUCCESS';
export const GET_LEAGUE_EVENTS_ERROR   = 'GET_LEAGUE_EVENTS_ERROR';

export const LEAGUE_EVENT_SELECT_REQUEST = 'LEAGUE_EVENT_SELECT_REQUEST';
export const LEAGUE_EVENT_SELECT_SUCCESS = 'LEAGUE_EVENT_SELECT_SUCCESS';
export const LEAGUE_EVENT_SELECT_ERROR   = 'LEAGUE_EVENT_SELECT_ERROR';

export const CHECK_LEAGUE_ROSTER_REQUEST = 'CHECK_LEAGUE_ROSTER_REQUEST';
export const CHECK_LEAGUE_ROSTER_SUCCESS = 'CHECK_LEAGUE_ROSTER_SUCCESS';
export const CHECK_LEAGUE_ROSTER_ERROR   = 'CHECK_LEAGUE_ROSTER_ERROR';

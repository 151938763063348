import React,{memo} from 'react'

function RedirectPwProfilePopup({ flag, close, redirectPwProfileHandler,playerInfo }) {
    return (
        <div>
        <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog pwprofile_popup">
                <div className="modal-content popup_540">
                    <div className='inner_popup'>
                        <div className="close_popup" onClick={close}>
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h3>{`Do you want to Redirect ${playerInfo?.PlayerName} Pw Profile?`}</h3>
                        <div className="profile_btn">
                            <button className='btn mr-10' onClick={redirectPwProfileHandler}>Yes</button>
                            <button className='gray_btn' onClick={close}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    )
}

export default memo(RedirectPwProfilePopup)
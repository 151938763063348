import React, { Component } from 'react';
import { Link } from "react-router-dom";
export default class location extends Component {

    toLocation = () => {
        var url = "https://maps.google.com/?q=" + this.props.dataList.location.latitude + "," + this.props.dataList.location.longitude;
        window.open(url);
    }

    render() {
        const { dataList } = this.props;
        const src = dataList?.location ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyBIWNIuBhh-9yA3DdSZGUZ-revs4vH_Www&zoom=10&q=${dataList.location.latitude},${dataList.location.longitude}` : ""
        return (
            <div className="web_description_box">
                <div className="web_desc_two_title d-flex align-items-center flex-wrap justify-content-between">
                    <h3>Location</h3>
                    <Link onClick={this.toLocation} className=" d-flex align-items-center common_export_icon nav-link">View on Google Map</Link>
                </div>
                <p>{dataList?.location && dataList.location.streetAddress}</p>
                <div class="map_wrapper rounded-lg overflow-hidden">
                    <iframe title="videoFrame" src={src} width="100%" height="232" frameborder="0">
                    </iframe>
                </div>
            </div>
        )
    }
}

import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_COLLEGE_SEARCH_REQUEST,
    GET_PLAYER_COLLEGE_SEARCH_SUCCESS,
    GET_PLAYER_COLLEGE_SEARCH_ERROR,

    GET_SUGGETION_REQUEST,
    GET_SUGGETION_SUCCESS,
    GET_SUGGETION_ERROR,

    POST_COLLEGELIST_ERROR,
    POST_COLLEGELIST_SUCCESS,
    GET_COLLEGELIST_SUCCESS,
    GET_COLLEGELIST_ERROR
} from '../../../../constants/authantication/signup/player/collage.const';
import histrory from '../../../../../History';

export const playerTeamList = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_COLLEGE_SEARCH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}college/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(search => {
        dispatch({
            type: GET_PLAYER_COLLEGE_SEARCH_SUCCESS,
            payload: search.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_PLAYER_COLLEGE_SEARCH_ERROR, error: error.response.data.message })
    });
};

export const playerSuggetionList = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_SUGGETION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}college/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(search => {
        dispatch({
            type: GET_SUGGETION_SUCCESS,
            payload: search.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_SUGGETION_ERROR, error: error.response.data.message })
    });
};

export const getPlayerTeamList = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/player/collage`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(res => {
        console.log("save college:--", res);
        dispatch({
            type: GET_COLLEGELIST_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_COLLEGELIST_ERROR, error: error.response.data.message })
    });
};


export const savePlayerTeamList = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/collage`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(res => {
        console.log("save college:--", res);
        dispatch({
            type: POST_COLLEGELIST_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: POST_COLLEGELIST_ERROR, error: error.response.data.message })
    });
};

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,

    NOTIFICATION_LOGIN_REQUEST,
    NOTIFICATION_LOGIN_SUCCESS,
    NOTIFICATION_LOGIN_ERROR,

} from '../../constants/authantication/login.const';

const initialState = {
    loginReq: false,
    loginData: null,
    loginError: null,
    claimMsg: null,

    userCredReq: false,
    userCredData: null,
    userCredError: null,

    DCUserCredReq: false,
    DCUserCredData: null,
    DCUserCredError: null,

    notificationReq: false,
    notificationData: null,
    notificationError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Notification token firebase  
        case NOTIFICATION_LOGIN_REQUEST:
            return {
                ...state,
                notificationReq: true,
            };
        case NOTIFICATION_LOGIN_SUCCESS:
            return {
                ...state,
                notificationData: action.payload,
            };
        case NOTIFICATION_LOGIN_ERROR:
            return {
                ...state,
                notificationError: action.error,
            };

        // Login req    
        case LOGIN_REQUEST:
            return {
                ...state,
                loginReq: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginData: action.payload,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.error,
            };

        // User cred req    
        case 'PW_CRED_REQUEST':
            return {
                ...state,
                userCredReq: true,
            };
        case 'PW_CRED_SUCCESS':
            return {
                ...state,
                userCredData: action.payload,
            };
        case 'PW_CRED_ERROR':
            return {
                ...state,
                userCredError: action.error,
            };

        // DC Cred req    
        case 'DC_CRED_REQUEST':
            return {
                ...state,
                DCUserCredReq: true,
            };
        case 'DC_CRED_SUCCESS':
            return {
                ...state,
                DCUserCredData: action.payload,
            };
        case 'DC_CRED_ERROR':
            return {
                ...state,
                DCUserCredError: action.error,
            };

        case 'CLAIM_MSG':
            return {
                ...state,
                claimMsg: action.payload,
            };
            

        default:
            return state;
    }
}
import {
    GET_COACH_REQUEST,
    GET_COACH_SUCCESS,
    GET_COACH_ERROR,

    ADD_NEW_COACH_REQUEST,
    ADD_NEW_COACH_SUCCESS,
    ADD_NEW_COACH_ERROR,

    DEL_NEW_COACH_REQUEST,
    DEL_NEW_COACH_SUCCESS,
    DEL_NEW_COACH_ERROR,

    ASSIGN_HEAD_COACH_REQUEST,
    ASSIGN_HEAD_COACH_SUCCESS,
    ASSIGN_HEAD_COACH_ERROR,

    OTHER_TEAM_COACH_REQUEST,
    OTHER_TEAM_COACH_SUCCESS,
    OTHER_TEAM_COACH_ERROR,

    GET_ROSTER_LIST_COACH_REQUEST,
    GET_ROSTER_LIST_COACH_SUCCESS,
    GET_ROSTER_LIST_COACH_ERROR,

    SAVE_ROSTER_LIST_COACH_REQUEST,
    SAVE_ROSTER_LIST_COACH_SUCCESS,
    SAVE_ROSTER_LIST_COACH_ERROR,

    DEL_ROSTER_LIST_COACH_REQUEST,
    DEL_ROSTER_LIST_COACH_SUCCESS,
    DEL_ROSTER_LIST_COACH_ERROR,
} from '../../../../constants/view/coach/profile/coachInfo.const';

const initialState = {
    getCoachReq: false,
    getCoachData: null,
    getCoachError: null,

    addNewCoachReq: false,
    addNewCoachData: null,
    addNewCoachError: null,

    delNewCoachReq: false,
    delNewCoachData: null,
    delNewCoachError: null,

    assignHeadCoachReq: false,
    assignHeadCoachData: null,
    assignHeadCoachError: null,

    otherTeamCoachReq: false,
    otherTeamCoachData: null,
    otherTeamCoachError: null,

    rosterListCoachReq: false,
    rosterListCoachData: null,
    rosterListCoachError: null,

    rosterSaveCoachReq: false,
    rosterSaveCoachData: null,
    rosterSaveCoachError: null,

    rosterDelCoachReq: false,
    rosterDelCoachData: null,
    rosterDelCoachError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // roster delete coach req    
        case DEL_ROSTER_LIST_COACH_REQUEST:
            return {
                ...state,
                rosterDelCoachReq: true,
            };
        case DEL_ROSTER_LIST_COACH_SUCCESS:
            return {
                ...state,
                rosterDelCoachData: action.payload,
            };
        case DEL_ROSTER_LIST_COACH_ERROR:
            return {
                ...state,
                rosterDelCoachError: action.error,
            };

        // roster save coach req    
        case SAVE_ROSTER_LIST_COACH_REQUEST:
            return {
                ...state,
                rosterSaveCoachReq: true,
            };
        case SAVE_ROSTER_LIST_COACH_SUCCESS:
            return {
                ...state,
                rosterSaveCoachData: action.payload,
            };
        case SAVE_ROSTER_LIST_COACH_ERROR:
            return {
                ...state,
                rosterSaveCoachError: action.error,
            };

        // rosterlist coach req    
        case GET_ROSTER_LIST_COACH_REQUEST:
            return {
                ...state,
                rosterListCoachReq: true,
            };
        case GET_ROSTER_LIST_COACH_SUCCESS:
            return {
                ...state,
                rosterListCoachData: action.payload,
            };
        case GET_ROSTER_LIST_COACH_ERROR:
            return {
                ...state,
                rosterListCoachError: action.error,
            };

        // other team coach req    
        case OTHER_TEAM_COACH_REQUEST:
            return {
                ...state,
                otherTeamCoachReq: true,
            };
        case OTHER_TEAM_COACH_SUCCESS:
            return {
                ...state,
                otherTeamCoachData: action.payload,
            };
        case OTHER_TEAM_COACH_ERROR:
            return {
                ...state,
                otherTeamCoachError: action.error,
            };

        // head coach req    
        case ASSIGN_HEAD_COACH_REQUEST:
            return {
                ...state,
                assignHeadCoachReq: true,
            };
        case ASSIGN_HEAD_COACH_SUCCESS:
            return {
                ...state,
                assignHeadCoachData: action.payload,
            };
        case ASSIGN_HEAD_COACH_ERROR:
            return {
                ...state,
                assignHeadCoachError: action.error,
            };

        // get player req    
        case GET_COACH_REQUEST:
            return {
                ...state,
                getCoachReq: true,
            };
        case GET_COACH_SUCCESS:
            return {
                ...state,
                getCoachData: action.payload,
            };
        case GET_COACH_ERROR:
            return {
                ...state,
                getCoachError: action.error,
            };

        // add coach req    
        case ADD_NEW_COACH_REQUEST:
            return {
                ...state,
                addNewCoachReq: true,
            };
        case ADD_NEW_COACH_SUCCESS:
            return {
                ...state,
                addNewCoachData: action.payload,
            };
        case ADD_NEW_COACH_ERROR:
            return {
                ...state,
                addNewCoachError: action.error,
            };

        // delete coach req    
        case DEL_NEW_COACH_REQUEST:
            return {
                ...state,
                delNewCoachReq: true,
            };
        case DEL_NEW_COACH_SUCCESS:
            return {
                ...state,
                delNewCoachData: action.payload,
            };
        case DEL_NEW_COACH_ERROR:
            return {
                ...state,
                delNewCoachError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import moment from 'moment';
class shopingCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredActiveData: [],
            search: ""
        }
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    renderProduct = (data) => {
        return (
            data.length !== 0 ?
                data.map((item, i) => {
                    return (
                        <div className="team-flex">
                            <div className="team-data">
                                <div className="team-img">
                                    <img src={item?.rewardBanner?.filePath ? item.rewardBanner.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" />
                                </div>
                                <div>
                                    <h3>{item.name}</h3>
                                    <p className="m-0">${item.price}</p>
                                </div>
                            </div>
                            <div className="radiobox">
                                <input type="checkbox" id="productID" name="products" onClick={() => this.props.onHandleProduct(item)}
                                    checked={
                                        this.props.eventJoin.products.find((d) => d._id === item._id)
                                            ? true
                                            : false
                                    } />
                                <span for="productID"></span>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    renderPaymentList = () => {
        return (
            this?.props?.finalProducts?.map((item, i) => {
                return (
                    <div className="product-pre">
                        <img src={item.rewardBanner.filePath} alt="event" />
                        <div className="product-detail">
                            <div className="name">
                                <h3>{item.name}</h3>
                                {
                                    item.startDate && item.endDate &&
                                    <h6> {moment(item.startDate).format('DD-MMM')} - {moment(item.endDate).format('DD-MMM')}</h6>
                                }
                            </div>
                            <div className="price">
                                <h3>{item.price === 0 ? "Free!" : "$" + item.price}</h3>
                            </div>
                        </div>
                    </div >
                )
            })
        )
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    render() {
        const { eventJoin, dataList, finalProducts } = this.props;
        let totalAmount = finalProducts.reduce(function (sum, current) {
            return sum + current.price;
        }, 0);

        let filters = dataList?.payment?.products.filter((item) => {
            return (
                item.name
                    .toLowerCase()
                    .indexOf(this.state.search.toLowerCase()) !== -1
            );
        });
        this.state.filteredActiveData = filters;

        return (
            <div className="event-form">
                <div className="join-form">
                    <div className="shoping-cart">
                        <div className="product-list">
                            {
                                this.state.filteredActiveData.length !== 0 &&
                                <div className="product-search">
                                    <div className="form-group">
                                        <input type="text" name="search" onChange={(e) => this.onHandleChange(e)} class="form-control" placeholder="Search here..." />
                                    </div>
                                    <div className="scrollbar_team">
                                        <div className="select_team">
                                            {this.renderProduct(this.state.filteredActiveData)}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="product-info">
                                <div className="product-scroll">
                                    {this.renderPaymentList()}
                                </div>
                                <div className="note-total">
                                    <div className="note">
                                        <p>Notes: {localStorage.getItem("company") === "pws" ? "Camps" : "Showcase"} details to follow!</p>
                                    </div>
                                    <div className="total">
                                        <h3>${totalAmount}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default shopingCart;
import React, { Component } from "react";
import { imagesArr } from "../../assets/images";
import { Player } from 'video-react';

export class pressRoomDec extends Component {
  render() {
    const { flag, dataIndex } = this.props;
    if (flag) {
      document.body.classList.add("modal-open");
    }
    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className="onlymodal_close"
                onClick={() => this.props.close()}
              >
                <img src={imagesArr.modal_close} alt="modal_close" />
              </div>
              <div className="modal-body">
                <div className="press_team">
                  <h4>
                    {dataIndex === 0 &&
                      "New BaseballCloud tool helps Brian Kenny and Carlos Pena explain Effective Velocity"}
                    {dataIndex === 1 &&
                      "BaseballCloud tool featured in Fox article in anticipation of COVID-shortened MLB season"}
                    {dataIndex === 2 &&
                      "BaseballCloud officially launches 3D Baseball Tool at the 2021 ABCA Convention"}
                    {dataIndex === 3 &&
                      "P3’s ‘Premier Collegiate Development League’ is kicking off in the summer of 2021 with the help of BaseballCloud products"}
                    {dataIndex === 4 &&
                      "BaseballCloud’s third ABCA appearance will look a lot different than the first two"}
                    {dataIndex === 5 &&
                      "DSSV creates softball counterpart to its national tournament and showcase brand, Prospect Wire"}
                    {dataIndex === 6 &&
                      "BaseballCloud 2020 fall interns land positions in MLB analytics departments"}
                    {dataIndex === 7 &&
                      "BaseballCloud stars in Joe Lemire’s SportTechie article series focusing on ‘digital scouting’ in baseball"}
                    {dataIndex === 8 &&
                      "BaseballCloud provides data analysis software to multiple Atlantic League clubs"}
                    {dataIndex === 9 &&
                      "BaseballCloud’s Ryan Reinsel joins the SportTechie Webinar hosted by Joe Lemire to discuss the current state of data and technology in MLB"}
                    {dataIndex === 10 &&
                      "PitchR was recently used on MLB Network by Carlos Pena to help explain Effective Velocity concepts by Perry Husband"}
                    {dataIndex === 11 &&
                      "BaseballCloud officially launches 3D Baseball Tool at the 2021 ABCA Convention"}
                    {dataIndex === 12 &&
                      "BaseballCloud 2020 fall interns land positions in MLB analytics departments"}
                    {dataIndex === 13 &&
                      "The Frontier League and BaseballCloud/Yakkertech Announce Exclusive Data Partnership"}
                  </h4>
                  <div className="press_team_scroll">
                    <span>
                      {dataIndex === 0 && "BaseballCloud Staff"}
                      {dataIndex === 1 && "Lauren Timmermann"}
                      {dataIndex === 2 && "BaseballCloud Staff"}
                      {dataIndex === 3 && "BaseballCloud Staff"}
                      {dataIndex === 4 && "BaseballCloud Staff"}
                      {dataIndex === 5 && "BaseballCloud Staff"}
                      {dataIndex === 6 && "BaseballCloud Staff"}
                      {dataIndex === 7 && "Joe Lemire"}
                      {dataIndex === 8 && "BaseballCloud Staff"}
                      {dataIndex === 9 && "BaseballCloud Staff"}
                      {dataIndex === 10 && "BaseballCloud Staff"}
                      {dataIndex === 11 && "BaseballCloud Staff"}
                      {dataIndex === 12 && "BaseballCloud Staff"}
                      {dataIndex === 13 && "Kevin Davidson"}
                    </span>
                    {dataIndex === 13 &&
                      <>
                        <span> CEO I BaseballCloud </span>
                        <span>kd@dssports.com</span>
                        <span>407.376.5043 </span>
                       
                      </>
                    }
                   
                    <span className="dates">
                      {dataIndex === 0 && "November 13th, 2020"}
                      {dataIndex === 1 && "August 26th, 2020"}
                      {dataIndex === 2 && "January 9th, 2021"}
                      {dataIndex === 3 && "December 16th, 2020"}
                      {dataIndex === 4 && "January 6th, 2021"}
                      {dataIndex === 5 && "December 16th, 2020"}
                      {dataIndex === 6 && "December 14th, 2020"}
                      {dataIndex === 7 && "June 3th, 2020"}
                      {dataIndex === 8 && "May 7th, 2021"}
                      {dataIndex === 9 && "April 14th, 2021"}
                      {dataIndex === 10 && "March 31st, 2021"}
                      {dataIndex === 11 && "January 9th, 2021"}
                      {dataIndex === 12 && "December 14th, 2020"}
                    </span>
                    <div className="press_room_modal_img">
                      {dataIndex === 0 && (
                        <img
                          src={imagesArr.press_room_thumb_modal1}
                          alt="press_room_thumb_modal1"
                        />
                      )}
                      {dataIndex === 1 && (
                        <img
                          src={imagesArr.press_room_thumb_modal2}
                          alt="press_room_thumb_modal2"
                        />
                      )}
                      {dataIndex === 2 && (
                        <img
                          src={imagesArr.press_room_thumb_modal3}
                          alt="press_room_thumb_modal3"
                        />
                      )}
                      {dataIndex === 3 && (
                        <img
                          src={imagesArr.press_room_thumb_modal4}
                          alt="press_room_thumb_modal4"
                        />
                      )}
                      {dataIndex === 4 && (
                        <img
                          src={imagesArr.press_room_thumb_modal5}
                          alt="press_room_thumb_modal5"
                        />
                      )}
                      {dataIndex === 5 && (
                        <img
                          src={imagesArr.press_room_thumb_modal6}
                          alt="press_room_thumb_modal6"
                        />
                      )}
                      {dataIndex === 6 && (
                        <img
                          src={imagesArr.press_room_thumb_modal7}
                          alt="press_room_thumb_modal7"
                        />
                      )}
                      {dataIndex === 7 && (
                        <img
                          src={imagesArr.press_room_thumb_modal8}
                          alt="press_room_thumb_modal8"
                        />
                      )}
                      {dataIndex === 11 && (
                        <img
                          src={imagesArr.press_room_thumb_modal9}
                          alt="press_room_thumb_modal9"
                        />
                      )}
                      {dataIndex === 12 && (
                        <img
                          src={imagesArr.press_room_thumb_modal10}
                          alt="press_room_thumb_modal10"
                        />
                      )}
                    </div>
                    <p>
                      {dataIndex === 0 && (
                        <>
                          <p>
                            MLB Now has been coined “the show for the thinking
                            fan” by host Brian Kenny, and for good reason. After
                            a half hour of discussing offseason rumors and
                            transactions, Kenny decided to go into one of the
                            most advanced concepts in baseball today, the idea
                            of effective velocity.
                          </p>
                          <p>
                            With the help of former MLB slugger Carlos Pena, who
                            used these analytical findings throughout his
                            career, the show began to introduce the ideas of EV
                            and pitch tunneling to their audience. Luckily, they
                            had BaseballCloud visuals there to assist.
                          </p>
                          <p>
                            PitchR, a fully custom 3D product coming soon to
                            baseballcloud.com, was able to recreate Trevor
                            Bauer’s arsenal on a virtual field, so viewers could
                            see each pitch coming to home plate at once. By
                            adding hoops between the rubber and the plate, Pena
                            was more easily able to explain what big league
                            hitters see on a day-to-day basis, and why pitchers
                            like Bauer are able to induce so many ‘bad swings.’
                          </p>
                          <p>
                            This clip only shows a percentage of what PitchR is
                            able to do, but check out the entire segment below
                            for a preview of the product and an incredibly
                            engaging discussion between Kenny and Pena.
                          </p>
                          <p>
                            <a href="https://www.youtube.com/watch?v=H3g6ymJNofM&feature=youtu.be" target="blank">https://www.youtube.com/watch?v=H3g6ymJNofM&feature=youtu.be</a>
                          </p>
                        </>
                      )}
                      {dataIndex === 1 && (
                        <>
                          <p>
                            Lauren Timmermann of Fox Business wrote a piece
                            about the advancements of technology in baseball and
                            the new Hawk-Eye systems that were installed in
                            major league ballparks for the 2020 season.
                          </p>
                          <p>
                            Her article also highlighted our BallR product and
                            talked about the importance of organizations being
                            able to quickly spot flaws in their pitchers during
                            the shortened season. Organizations couldn’t afford
                            for pitchers to struggle multiple outings in a row
                            with such fewer games this season, so BallR was used
                            as a tool by teams to help pitchers stay consistent
                            for each of their appearances. Check out the full
                            article below.
                          </p>
                          <p>
                            <a
                              target="blank"
                              href="https://www.foxbusiness.com/sports/mlb-continues-to-push-for-tech-advancements-amidst-changing-leadership"
                            >
                              https://www.foxbusiness.com/sports/mlb-continues-to-push-for-tech-advancements-amidst-changing-leadership
                            </a>
                          </p>
                        </>
                      )}
                      {dataIndex === 2 && (
                        <>
                          <p>
                            Today marks the official release of the latest
                            software product to hit BaseballCloud’s suite, BallR
                            (pronounced Baller). This tool focuses on the
                            moments right before and after the baseball leaves
                            the pitcher’s hand. How is it gripped? How is it
                            spinning off the fingertips? How do these metrics
                            impact the rest of the pitch path and results at
                            home plate? All of these questions, and thousands
                            more, can be answered and explained using this
                            innovative 3D solution.
                          </p>
                          <p>
                            Users will be able to upload their data and
                            immediately see each pitch they threw spinning as it
                            did during the game or bullpen session. They can
                            take advantage of a full 360 view of the ball and
                            add on visuals, like the clock below, to help them
                            better understand more complicated metrics like spin
                            direction, seam orientation, and gyro angle.
                          </p>
                          <p>
                            The goal of BallR is to empower pitching coaches and
                            pitchers like never before. It is one thing to view
                            all of these incredible new metrics on a
                            spreadsheet, but it becomes an entirely different
                            educational experience when users can log into
                            BaseballCloud and see real pitches come to life,
                            accompanied by coach and player notes, charts, and
                            graphs.
                          </p>
                          <p>
                            BallR is available for purchase now on the
                            BaseballCloud website. The BaseballCloud staff is
                            happy to answer any questions about the product,
                            which can be submitted through the site as well. Be
                            on the lookout for the release of BaseballCloud’s
                            next 3D product, PitchR, in the months ahead.
                          </p>
                        </>
                      )}
                      {dataIndex === 3 && (
                        <>
                          <p>
                            BaseballCloud is proud to announce its newest
                            partnership with Premier Pitching Performance (also
                            known as P3). The P3 team has developed an all-new
                            collegiate summer baseball model that emphasizes
                            player development. Rather than partaking in a
                            traditional 50-60 game marathon of a summer
                            schedule, these players will play fewer games and
                            train with some of the best developmental minds in
                            baseball on non-game days.
                          </p>
                          <p>
                            Running from June 14 - August 6, 2021, this 30-game
                            schedule will be hosted at GCS Credit Union Ballpark
                            in Sauget, Illinois, home of the Gateway Grizzlies.
                            Data will be captured in-game at the stadium to
                            accompany P3’s data tracking at their facility in
                            St. Louis.
                          </p>
                          <p>
                            P3 specializes in world-class performance
                            assessments and training, and the use of technology
                            plays a large role in the development of their
                            individualized plans for players. BaseballCloud is
                            excited to serve as P3’s data storage and
                            distribution solution, and can’t wait to help these
                            high level players reach their potential with
                            products like BCTeam, BallR, and PitchR.
                          </p>
                          <p>
                            For more information on P3 and their summer league,
                            visit
                            <a
                              href="https://premierpitching.com/"
                              target="blank"
                            >
                              premierpitching.
                            </a>
                          </p>
                        </>
                      )}
                      {dataIndex === 4 && (
                        <>
                          <p>
                            The ABCA Convention is a can’t miss event each year
                            for baseball coaches and companies. Top minds in the
                            game share their expertise on a main stage in a
                            giant theater. Thousands of baseball-focused
                            companies aggregate in a massive room to show off
                            their products and services. Baseball coaches and
                            players from all walks of life form their own hot
                            stove sessions in the hallways of the hotel.
                            Relationships are renewed and formed every year in
                            this short 4-day span, that often results in more
                            beers and stories shared than hours slept.
                          </p>
                          <p>
                            But right now, 8,000 baseball people coming together
                            in one place is unfortunately not possible. For the
                            first time in its 77-year history, the American
                            Baseball Coaches Association is hosting a fully
                            virtual convention. Expo theater presentations,
                            trade show conversations, and coaching clinics will
                            all take place via Zoom. The ABCA staff has done a
                            tremendous job putting the event together on short
                            notice, and BaseballCloud is excited to be a part of
                            this truly unprecedented convention.
                          </p>
                          <p>
                            BaseballCloud will be leading off ABCA’s Expo
                            Theater presentations on Thursday, January 7 with a
                            40-minute video highlighting the company and each of
                            its new products.
                          </p>
                          <p>
                            BC’s involvement is far from over after this
                            presentation, as the following three days will be
                            jammed packed with product demos and special guests
                            (including some MLB All-Stars) inside the virtual
                            trade show booth.
                          </p>
                          <p>
                            Be sure to visit ABCA’s site,
                            <a
                              href="https://abca.org/convention"
                              target="blank"
                            >
                              https://abca.org/convention
                            </a>
                            , to learn more about the convention and to register
                            for the virtual event.
                          </p>
                        </>
                      )}
                      {dataIndex === 5 && (
                        <>
                          <p>
                            DS Sports Ventures, the holding company behind
                            BaseballCloud and SoftballCloud, is excited to add
                            to its portfolio of event and showcase companies.
                            Prospect Wire Baseball, which was acquired by DSSV
                            in early 2019, has been wildly successful for many
                            years across the country. PW currently hosts
                            hundreds of tournaments and national showcase events
                            for thousands of youth and high school baseball
                            players each year. PW is also known as the pioneer
                            of integrating data and technology into amateur
                            events to be used for scouting and recruiting.
                          </p>
                          <p>
                            Prospect Wire Softball aims to provide the same
                            service to the amateur softball community when it is
                            officially launched in 2021. The youth softball
                            space does not feature the same number of options as
                            the baseball industry does, so many teams are forced
                            to settle for subpar events each season.
                          </p>
                          <p>
                            By building a team of scouts and evaluators trusted
                            by the softball industry, and leveraging
                            relationships with the most powerful organizations
                            in travel softball, PW Softball will be well
                            positioned to capture major market share starting in
                            2021. The long-term goal of the newly formed
                            organization is to create a digital ecosystem that
                            revolutionizes the flow, access, and quality of
                            information within the game of softball. Utilizing
                            software solutions like SoftballCloud, PW Softball
                            is hoping to be the driving force behind the sport
                            catching up to the advancements already made in the
                            game of baseball.
                          </p>
                          <p>
                            Be on the lookout for Prospect Wire Softball’s new
                            website coming soon. Follow PW Softball
                            @PWireSoftball on Twitter today to see updates on
                            the upcoming 2021 schedule of events.
                          </p>
                        </>
                      )}
                      {dataIndex === 6 && (
                        <>
                          <p>
                            BaseballCloud has always taken pride in its
                            internship program providing an opportunity for
                            students looking to get their foot in the door in
                            the baseball industry. During the pandemic, many
                            students lost their jobs or internships with pro
                            organizations because of the cancellation of the
                            minor league season. Over this past summer and fall,
                            BC decided to expand its internship program to allow
                            more people to apply due to increased demand. This
                            expansion allowed the company to produce more
                            content for the blog and complete a wider range of
                            research projects that were not possible with the
                            team in place prior to the internship.
                          </p>
                          <p>
                            Since the completion of BC’s fall internship at the
                            end of November, two interns have landed jobs with
                            pro organizations. Jonah Simon will be joining the
                            Detroit Tigers in an analyst role during this
                            offseason, and Wyatt Kleinberg will join the Los
                            Angeles Dodgers in a player development position
                            this spring training.
                          </p>
                          <p>
                            BaseballCloud wants to continue to provide an
                            opportunity for students and others to gain
                            experience in the baseball space. The internship
                            program provides unique access to valuable
                            resources, and the company wants to continue to push
                            the limits with its products by staying up-to-date
                            with the newest technology and ideologies in the
                            game. Interns are encouraged to publish their
                            research findings as frequently as they want on the
                            BC blog, and have the opportunity to be hands-on
                            with the software in an effort to help improve
                            customer experience and enhance their own work.
                          </p>
                          <p>
                            Congratulations to Jonah and Wyatt on the
                            outstanding accomplishments, and BaseballCloud
                            wishes you the best of luck this upcoming season!
                          </p>
                          <p>
                            For more information on BaseballCloud’s internship
                            opportunities, visit @BaseballCloudUS on Twitter or
                            baseballcloud.com/blog.
                          </p>
                        </>
                      )}
                      {dataIndex === 7 && (
                        <>
                          <p>
                            The entire baseball world was thrown for a loop by
                            the abrupt end of the 2020 amateur season. High
                            school seniors who were hoping to attract
                            last-minute collegiate offers with strong play in
                            their final games were robbed of that opportunity.
                            Draft-eligible players and their agents have had to
                            change their game plans due to fewer rounds, and pro
                            organizations are releasing minor league players up
                            to 50 at a time due to budget cuts and MiLB
                            restructuring.
                          </p>
                          <p>
                            Things were no different at BaseballCloud. The
                            company was poised for a huge 2020 season after
                            bringing on over 100 new college clients between
                            August and January, and was forced to pivot as
                            baseball came to a halt. Fortunately, our software
                            capabilities and industry connections put us in a
                            prime position to help nearly every side of the game
                            navigate this unprecedented challenge.
                          </p>
                          <p>
                            We know that the use of data and technology in
                            baseball is more popular than ever, but the national
                            lockdown has shown its value in ways we’ve never
                            seen before. In our conversations with SportTechie,
                            we wanted to emphasize the role that data will play
                            under these unique circumstances.
                          </p>
                          <p>
                            Jon Updike, BaseballCloud’s President of Digital
                            Scouting and Player Development Solutions, is
                            featured heavily in the article. The man who has
                            made the term ‘digital scouting’ popular in the
                            baseball realm gave a bit of insight into his
                            experience with the New York Mets, and how data is
                            playing a major role in the organization’s most
                            important decisions.
                          </p>
                          <p>
                            “For a long time there was a lot of fear in the
                            scouting community that the metrics were going to
                            take it over. What we’ve learned—at least the better
                            clubs have learned—is that it’s a combination. If
                            what your scouts see lines up with the numbers,
                            well, you take the player. A great example of that
                            is (Mets 2020 3rd Round Pick) Matt Allan.” - Jon
                            Updike
                          </p>
                          <p>
                            For more from Updike, CEO Kevin Davidson, and BC
                            partners like 108 Performance’s Eugene Bleecker, be
                            sure to check out the rest of the story below.
                          </p>
                          <p>
                            <a href="https://www.sporttechie.com/mlb-baseball-scouting-technology-covid-19-pandemic-draft-prospects" target="blank">https://www.sporttechie.com/mlb-baseball-scouting-technology-covid-19-pandemic-draft-prospects</a>
                          </p>
                        </>
                      )}
                      {dataIndex === 8 && (
                        <>
                          <p>
                            BaseballCloud is excited to announce a partnership with the Lexington Legends and West Virginia Power of the Atlantic League. BaseballCloud is providing its entire suite of cutting edge analytics software to these two clubs, opening new doors in player development and fan engagement.
                          </p>
                          <p>
                            This deal hits close to home for BaseballCloud CEO, Kevin Davidson, who played for the Legends in 2003 and 2004 while climbing through the ranks in the Astros organization.
                          </p>
                          <p>
                            “Some of my best baseball memories come from my time in Lexington. To have a chance to partner with this club means a lot to me and the rest of the company. No doubt this is the right group to lead the charge in growing the use of technology at this level.”
                          </p>
                          <p>
                            The Atlantic League has officially been designated as MLB’s first “partner league,” meaning they will “meet regularly with MLB to discuss joint marketing and promotional opportunities, including the leagues’ shared goal of providing baseball to communities throughout the United States.”
                          </p>
                          <p>The Legends and Power open their seasons on May 28th. Be sure to catch some Atlantic League action this summer, and look out for BaseballCloud’s logo on all Legends uniforms this season.</p>

                        </>
                      )}
                      {dataIndex === 9 && (
                        <>
                          <p>
                            Every MLB club has an analytics department, but they vary in size and how much influence they have on personnel decisions, scouting, and player development. But it’s now impossible to contend for a World Series without troves of data and the right insights to know what matters most. We’ll discuss what a modern MLB franchise should be doing with technology and how those “R&D departments” can use cutting-edge infrastructure and tools to boost performance and put winning teams on the field. This recent SportTechie feature story is a good primer to the live conversation that we’ll have with industry experts.
                          </p>
                          <p>
                            Featured Speakers (Additional speakers to be announced)
                          </p>
                          <p>
                            Greg Cain is Vice President of Baseball Data for Major League Baseball (MLB). Greg is responsible for data systems that capture, process, distribute and display baseball data. Skilled in navigating disparate technology teams, Cain brings together various technology resources throughout MLB – including software engineering, data operations, data science, machine learning, ballpark operations and infrastructure – to bring data from the field of play to fans, teams, business partners and media around the world. As a technologist, Cain previously worked on additional data systems behind MLB-built properties for the NHL, MLS, and among other properties.  After starting at MLB in 2011 working on the MLB mobile app, Cain founded the Baseball Data team to develop and deploy the Statcast system launching league wide in 2015 and updated in 2020.
                          </p>
                          <p>
                            Ari Kaplan, Director of AI Evangelism and Strategy at DataRobot, is a leading figure in sports analytics and technology. During 30 seasons innovating analytics within MLB front offices, he created the Chicago Cubs analytics department and served as assistant to the GM of the Orioles 2013-2018. He currently teaches courses in analytics at SMWW and at DataRobot works with MLB, NBA, NHL, Indy Racing, and Premier League football clubs. In business he was President of the worldwide Oracle users group during a period of high growth including the acquisition of MySQL, Java, and PeopleSoft. He has been the recipient of Caltech's "Alumnus of the Decade" and Crain's Chicago Business "40 Under 40" awards.
                          </p>
                          <p>Ryan Reinsel is the CPO at BaseballCloud and the creative mind behind the baseball industry's newest data visualization software, BallR and PitchR. Equipped with a Fine Arts degree and a passion for technology, Ryan is a proven innovator at creating contextual applications to drive player development forward. Prior to BaseballCloud, Ryan has held positions in multiple sectors of baseball including a role in Research and Player Development for the Chicago Cubs at their Arizona complex. He was also the Pitching & Data Coordinator at JSerra Catholic High School and Director of Player Development for Elite Baseball in Orange County, CA.</p>

                        </>
                      )}
                      {dataIndex === 10 && (
                        <>
                         <div>
                         {/* <video width="320" height="240" controls>
                            <source src="../../assets/images/video.mov"/>
                            Your browser does not support the video tag.
                          </video> */}
                          <div className="video-bg">
                          <Player playsInline src={imagesArr.video} />
                        </div>
                         </div>
                        </>
                      )}
                      {dataIndex === 11 && (
                        <>
                          <p>
                          Today marks the official release of the latest software product to hit BaseballCloud’s suite, BallR (pronounced Baller). This tool focuses on the moments right before and after the baseball leaves the pitcher’s hand. How is it gripped? How is it spinning off the fingertips? How do these metrics impact the rest of the pitch path and results at home plate? All of these questions, and thousands more, can be answered and explained using this innovative 3D solution.
                          </p>
                          <p>
                          Users will be able to upload their data and immediately see each pitch they threw spinning as it did during the game or bullpen session. They can take advantage of a full 360 view of the ball and add on visuals, like the clock below, to help them better understand more complicated metrics like spin direction, seam orientation, and gyro angle.
                          </p>
                          <p>
                          If users have video of their pitches, they will be able to overlay their spinning baseball onto the video, to immediately add context to each delivery.
                          </p>
                          <p>
                          The goal of BallR is to empower pitching coaches and pitchers like never before. It is one thing to view all of these incredible new metrics on a spreadsheet, but it becomes an entirely different educational experience when users can log into BaseballCloud and see real pitches come to life, accompanied by coach and player notes, charts, and graphs.

                          </p>
                          <p>BallR is available for purchase now on the BaseballCloud website. The BaseballCloud staff is happy to answer any questions about the product, which can be submitted through the site as well. Be on the lookout for the release of BaseballCloud’s next 3D product, PitchR, in the months ahead. </p>

                        </>
                      )}
                      {dataIndex === 12 && (
                        <>
                          <p>
                          BaseballCloud has always taken pride in its internship program providing an opportunity for students looking to get their foot in the door in the baseball industry. During the pandemic, many students lost their jobs or internships with pro organizations because of the cancellation of the minor league season. Over this past summer and fall, BC decided to expand its internship program to allow more people to apply due to increased demand. This expansion allowed the company to produce more content for the blog and complete a wider range of research projects that were not possible with the team in place prior to the internship.</p>
                          <p>
                          Since the completion of BC’s fall internship at the end of November, two interns have landed jobs with pro organizations. Jonah Simon will be joining the Detroit Tigers in an analyst role during this offseason, and Wyatt Kleinberg will join the Los Angeles Dodgers in a player development position this spring training. 
                          </p>
                          <p>
                          BaseballCloud wants to continue to provide an opportunity for students and others to gain experience in the baseball space. The internship program provides unique access to valuable resources, and the company wants to continue to push the limits with its products by staying up-to-date with the newest technology and ideologies in the game. Interns are encouraged to publish their research findings as frequently as they want on the BC blog, and have the opportunity to be hands-on with the software in an effort to help improve customer experience and enhance their own work.
                          </p>
                          <p>
                          Congratulations to Jonah and Wyatt on the outstanding accomplishments, and BaseballCloud wishes you the best of luck this upcoming season!
                          </p>
                          <p>For more information on BaseballCloud’s internship opportunities, visit @BaseballCloudUS on Twitter or baseballcloud.com/blog.</p>

                        </>
                      )}
                      {dataIndex === 13 && (
                        <>
                        
                     <div className="sub_title">FOR IMMEDIATE RELEASE</div>
                          <h5>
                            Phoenix, Arizona (April 12, 2022) – BaseballCloud and Yakkertech, who
                            provide data solutions for hundreds of collegiate programs and multiple
                            professional organizations, today announced that they have entered a multiyear exclusive partnership with the Frontier League, the longest-running
                            active professional independent baseball league in the United States and a
                            Major League Baseball Professional Partner League.
                          </h5>
                          <p>
                            Across both international and domestic markets, BaseballCloud and Yakkertech, who merged in
                            2021, will have exclusive capture and distribution rights for official Frontier League data
                            collected via Yakkertech’s optical ball tracking system.
                          </p>
                          <p>
                            The League will distribute and provide data to enhance insights when signing players. The
                            coaching staffs will have the opportunity to create player development systems using
                            BaseballCloud’s premier product suite. “The Frontier League is excited to partner with
                            Yakkertech and Baseball Cloud to expand technology in our stadium, and enhance our fan’s
                            experience, while better showcasing our players skills.”- Brian Lyter, President, Board of
                            Directors for the Frontier League.
                          </p>
                          <p>
                            As the only fully-optical data capturing solution available in this market, Yakkertech will provide
                            a new level of analytics previously unavailable to the Frontier League organizations. Hundreds
                            of ball flight metrics and real-time data will be collected on every play of the season and
                            distributed to sports betting operators, MLB teams, as well as broadcast feeds and fan
                            engagement initiatives.
                          </p>
                          <p>Yakkertech and BaseballCloud, in cooperation with the Frontier League, are eager to show how
                            this data can bring value and new and exciting opportunities in other areas of the baseball
                            experience as technology continues to advance.
                          </p>
                          <p>
                            “For the past six years, we have had a front-row seat to see how in-game data has played a role
                            in player valuation and development. We have also seen how the fan experience has evolved
                            using HawkEye and Statcast at the big league level,” says BaseballCloud CEO Kevin Davidson.
                            “The speed and accuracy of our systems allow us to replicate this experience and build on it
                            with a wide range of new ideas. There is a tremendous opportunity for independent baseball to
                            reach a new generation of players and fans, and we are thrilled to help the Frontier League take
                            full advantage.”
                          </p>
                          <p>
                            BaseballCloud and Yakkertech will work with the Frontier League to bring live data to fans,
                            starting with metrics on scoreboards and TV broadcasts and growing into in-stadium mobile
                            apps and games. Future projects include broadcasting and instant replay enhancements,
                            umpiring solutions, player biomechanics capture, and virtual reality experiences.
                          </p>
                          <p>
                            The group will also be partnering with the Frontier League to engage the community in many
                            ways. These projects include creating summer analytics internship positions in each market,
                            and a high school event series that features data capturing at Frontier League stadiums to help
                            local players get scouted and recruited.
                          </p>
                          <h5>About BaseballCloud</h5>
                          <p>
                            BaseballCloud was created in 2017 in response to the growing divide between the quantity of
                            data available and the quality of available tools used to analyze it. BCTeam provides college
                            coaches and players an easy way to make sure of ball flight data. The firm's most recent
                            releases, BallR, PitchR, and AnalyzR, allow for custom reporting solutions and 3D recreations of
                            game and practice events. Please visit <a href="https://www.baseballcloud.com" target="blank">www.baseballcloud.com</a> for more information.
                          </p>
                          <h5>About Yakkertech</h5>
                          <p>
                            Yakkertech is a Phoenix-based sports performance software development company that
                            specializes in end-to-end vision solutions that provide insight into live and aggregate player
                            metrics. We have the goal to foster equal opportunities for athletes and organizations by
                            providing them the trusted tools they need to grow on an even playing field in today’s datadriven world. We define our success by their success, and so a mutually fruitful partnership with
                            them is our top priority. Please visit <a href="https://www.yakkertech.com" target="blank">www.yakkertech.com</a> for more information.
                          </p>
                          <h5>About Frontier League</h5>
                          <p>
                            The Frontier League is an official Partner League of Major League Baseball and the largest of
                            its kind in professional baseball, featuring 16 teams from the Atlantic Ocean to the Mississippi
                            River and from the Ohio River to the St. Lawrence Seaway. The Frontier League has moved
                            over 1,000 players to MLB Teams in its 29-year history. The 2022 Frontier League season
                            opens on Thursday, May 12. Please visit <a href="https://www.frontierleague.com" target="blank">www.frontierleague.com</a> for more information.
                          </p>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default pressRoomDec;

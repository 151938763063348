import {
    GET_PLAYER_TEAM_INFO_REQUEST,
    GET_PLAYER_TEAM_INFO_SUCCESS,
    GET_PLAYER_TEAM_INFO_ERROR,

    GET_TEAM_PROFILE_SUCCESS,
    GET_TEAM_PROFILE_REQUEST,
    GET_TEAM_PROFILE_ERROR

} from '../../../../constants/view/company/pwRanking/teamProfile.const'

const initialState = {
    getPlayerTeamReq: false,
    getPlayerTeamData: null,
    getPlayerTeamError: null,

    getTeamProfileReq: false,
    getTeamProfileSuccess: null,
    getTeamProfileError: null,

    getScoutAwardTabReq:false,
    getScoutAwardTabSuc:null,
    getScoutAwardTabError:null,

    getTeamScoutFeedReq:false,
    getTeamScoutFeedSuc:null,
    getTeamScoutFeedError:null,

};

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_TEAM_PROFILE_REQUEST:
            return {
                ...state,
                getTeamProfileReq: true,
            };

        case GET_TEAM_PROFILE_SUCCESS:
            console.log(GET_TEAM_PROFILE_SUCCESS);
            return {
                ...state,
                getTeamProfileSuccess: action.payload,
            };
        case GET_TEAM_PROFILE_ERROR:
            return {
                ...state,
                getTeamProfileError: action.error,
            };
        // Get Player Scouting req    
        case GET_PLAYER_TEAM_INFO_REQUEST:
            return {
                ...state,
                getPlayerTeamReq: true,
            };

        case GET_PLAYER_TEAM_INFO_SUCCESS:
            return {
                ...state,
                getPlayerTeamData: action.payload,
            };
        case GET_PLAYER_TEAM_INFO_ERROR:
            return {
                ...state,
                getPlayerTeamError: action.error,
            };


        // get scout award tab detail   
        case 'GET_SCOUT_AWARD_TAB_REQUEST':
            return {
                ...state,
                getScoutAwardTabReq: true,
            };

        case 'GET_SCOUT_AWARD_TAB_SUCCESS':
            return {
                ...state,
                getScoutAwardTabSuc: action.payload,
            };
        case 'GET_SCOUT_AWARD_TAB_ERROR':
            return {
                ...state,
                getScoutAwardTabError: action.error,
            };

            
        // get team scout feed data
        case 'GET_TEAM_SCOUT_FEED_REQUEST':
            return {
                ...state,
                getTeamScoutFeedReq: true,
            };

        case 'GET_TEAM_SCOUT_FEED_SUCCESS':
            return {
                ...state,
                getTeamScoutFeedSuc: action.payload,
            };
        case 'GET_TEAM_SCOUT_FEED_ERROR':
            return {
                ...state,
                getTeamScoutFeedError: action.error,
            };



        default:
            return state;
    }
}

import {
    GET_TEAM_RANKING_SUCCESS,
    GET_TEAM_RANKING_REQUEST,
    GET_TEAM_RANKING_ERROR,
} from '../../../../../constants/view/company/pwRanking/rankings/teamRanking.const';

const initialState = {
    sendTeamRankReq: {
        page: 0,
        limit: 0,
        type: 1,
        rankType: 1,
        ageGroup: 8,
        position: "",
        gradeYear: "",
        state: "",
        teamName: "",
        companyId: localStorage.getItem("cid")
    },
    optionData: [],
    getTeamReq: false,
    getTeamSuccess: null,
    getTeamError: null,

    getAllTeamListReq: false,
    getAllTeamListSuccess: null,
    getAllTeamListError: null,

    getAllRankingTeamListReq: false,
    getAllRankingTeamListSuccess: null,
    getAllRankingTeamListError: null,
}

export default function (state = initialState, action) {
    switch (action.type) {

        case `GET_ALL_RANKING_TEAM_LIST_REQUEST`:
            return {
                ...state,
                getAllRankingTeamListReq: true,
            };

        case `GET_ALL_RANKING_TEAM_LIST_SUCCESS`:
            return {
                ...state,
                getAllRankingTeamListSuccess: action.payload,
            };
        case `GET_ALL_RANKING_TEAM_LIST_ERROR`:
            return {
                ...state,
                getAllRankingTeamListError: action.error,
            };

        case `GET_ALL_LEADERBOARD_TEAM_LIST_REQUEST`:
            return {
                ...state,
                getAllTeamListReq: true,
            };

        case `GET_ALL_LEADERBOARD_TEAM_LIST_SUCCESS`:
            return {
                ...state,
                getAllTeamListSuccess: action.payload,
            };
        case `GET_ALL_LEADERBOARD_TEAM_LIST_ERROR`:
            return {
                ...state,
                getAllTeamListError: action.error,
            };

        case GET_TEAM_RANKING_REQUEST:
            return {
                ...state,
                getTeamReq: true,
            };

        case GET_TEAM_RANKING_SUCCESS:
            console.log(GET_TEAM_RANKING_SUCCESS);
            return {
                ...state,
                getTeamSuccess: action.payload,
            };
        case GET_TEAM_RANKING_ERROR:
            return {
                ...state,
                getTeamError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../components/commonHeroBanner';
import Confirmation from '../popup/confirmation';
import Slider from "react-slick";
import commonCalls from "../../businesslogic/commonCalls";
import { commonHeader } from "../../businesslogic/content";
import Preloader from '../../components/preloader';
import { getActiveList, deleteSubscription, upgradeList, getCoachRosterList } from '../../saga/actions/common/subscription.action';
import { getProductList, getAdverticement, getEventDash, getTeamList, changeMyTeamData } from '../../saga/actions/common/api.action';
import { checkLoginValidation } from '../../saga/helpers/authToken';
import UpgradeSubscribe from '../popup/UpgradeSubscribe';
import SuccessUpgrade from '../popup/successUpgrade';
import Cookies from 'js-cookie';
import moment from "moment";
import EventSlider from './component/eventSlider';
import { isIOS, isMobileOnly, isMobile } from 'react-device-detect';
import DownloadApp from '../popup/downloadApp';
class coachDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamArr: [],
            coachName: "",
            teamRecord: "",
            loader: false,
            teamName: "",
            facilty: "",
            activeFilter: {
                limit: 0
            },
            product: {
                fullDetail: true
            },
            productList: [],
            activeList: "",
            delId: "",
            deletePopup: false,
            date: "",
            adsArr: [],
            upgradeArr: [],
            upgradePopup: false,
            subId: "",
            successPopup: false,
            insta: "",
            twt: "",
            subCoach: true,
            isInvite: false,
            isImport: false,
            gameType: 1,
            rosterList: [],
            myTeamList: [],
            reloadData: false,
            firstLoad: true,
            rosterGroup: localStorage.getItem("rosterId") ? localStorage.getItem("rosterId") : "",
            sendEvents: {
                type: 1,
                page: 0,
                limit: 0,
                eventTeamType: 1,
                startDate: "",
                endDate: "",
                state: "",
                companies: "",
                rosterTeamId: localStorage.getItem("rosterId") ? localStorage.getItem("rosterId") : "",
                myEvent: 1,
                currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
            },
            eventList: [],
            companyKey: localStorage.getItem("company"),
            appPopUp: false,
            coachLevel: 0,
            isMLB:false,
            organization:false,
            parentTeamLogo: "",
            isCmsUser: false,
            isEosPlayer: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setLoader(true);
        this.commonCall.removeStorage("coachTab");
        localStorage.removeItem("recapInfo");
        this.props.getActiveList(this.state.activeFilter, "Coach");
        this.props.getProductList(this.state.product, "Coach");
        this.props.getAdverticement({ moduleId: 1 }, "Coach");

        // if (localStorage.getItem("company")) {
        this.props.getEventDash(this.state.sendEvents);
        // }
        if (this.state.firstLoad) {
            this.setState({
                firstLoad: false
            }, () => {
                if (this.props.productInfo &&
                    this.props.productInfo.getMyTeamListData) {
                    this.props.productInfo.getMyTeamListData = null
                }
                this.props.getTeamList('Coach');
            })
        }

        this.setState({
            appPopUp: isMobile ? true : false
        })
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.login.claimMsg) {
            this.commonCall.successMessage(nextProps.login.claimMsg);
            nextProps.login.claimMsg = null
        }
        
        if (nextProps.profileDetails &&
            nextProps.profileDetails.headReq &&
            nextProps.profileDetails.headData
        ) {
            if (nextProps.profileDetails.headData.result.profile) {
                console.log("nextProps.profileDetails.headData:--", nextProps.profileDetails.headData);
                this.setState({
                    teamArr: nextProps.profileDetails.headData.result.profile.parentTeam,
                    parentTeamLogo: nextProps.profileDetails.headData.result.profile.parentTeam && nextProps.profileDetails.headData.result.profile.parentTeam.teamLogo ? nextProps.profileDetails.headData.result.profile.parentTeam.teamLogo.filePath : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png',
                    insta: nextProps.profileDetails.headData.result.profile.parentTeam && nextProps.profileDetails.headData.result.profile.parentTeam["social-link"] && nextProps.profileDetails.headData.result.profile.parentTeam["social-link"].insta,
                    twt: nextProps.profileDetails.headData.result.profile.parentTeam && nextProps.profileDetails.headData.result.profile.parentTeam["social-link"] && nextProps.profileDetails.headData.result.profile.parentTeam["social-link"].twt,
                    coachName: nextProps.profileDetails.headData.result.fname + " " + nextProps.profileDetails.headData.result.lname,
                    teamRecord: nextProps.profileDetails.headData.result.profile.team.length !== 0 && nextProps.profileDetails.headData.result.profile.team[0].hasOwnProperty('teamRecord') ? nextProps.profileDetails.headData.result.profile.team[0].teamRecord : "",
                    teamName: nextProps.profileDetails.headData.result.profile.parentTeam && nextProps.profileDetails.headData.result.profile.parentTeam.name,
                    facilty: {
                        facilty: nextProps.profileDetails.headData.result.profile.facilty,
                        faciltyName: nextProps.profileDetails.headData.result.profile.faciltyName,
                        faciltyLogo: nextProps.profileDetails.headData.result.profile.faciltyLogo,
                        faciltyAddress: nextProps.profileDetails.headData.result.profile.faciltyAddress &&
                            nextProps.profileDetails.headData.result.profile.faciltyAddress.street + ", " +
                            nextProps.profileDetails.headData.result.profile.faciltyAddress.city + ", " +
                            nextProps.profileDetails.headData.result.profile.faciltyAddress.state + ", " +
                            nextProps.profileDetails.headData.result.profile.faciltyAddress.zipcode,
                        facInstagramLink: nextProps.profileDetails.headData.result.profile.facInstagramLink,
                        facTwitterLink: nextProps.profileDetails.headData.result.profile.facTwitterLink
                    },
                    subCoach: nextProps.profileDetails.headData.result.profile.subCoach,
                    isInvite: nextProps.profileDetails.headData.result.profile.invite,
                    isImport: nextProps.profileDetails.headData.result.profile.hasOwnProperty('isImport') ? nextProps.profileDetails.headData.result.profile.isImport === 1 ? true : false : false,
                    coachLevel: nextProps.profileDetails.headData.result.profile.parentTeam && nextProps.profileDetails.headData.result.profile.parentTeam.type,
                    isMLB: nextProps.profileDetails.headData.result.profile && nextProps.profileDetails.headData.result.profile.isMLB,
                    organization: nextProps.profileDetails.headData.result.profile.hasOwnProperty('organization') ? true : false,
                    isCmsUser: nextProps.profileDetails.headData.result.profile.hasOwnProperty('cmsUser') ? nextProps.profileDetails.headData.result.profile.cmsUser : false,
                    isEosPlayer: nextProps.profileDetails.headData.result.profile.hasOwnProperty('isEosPlayer') ? nextProps.profileDetails.headData.result.profile.isEosPlayer : false
                }, () => {
                    if (this.state.isEosPlayer && nextProps?.profileDetails?.headData?.result?.profile?.eosRoster) {
                        localStorage.setItem("manageRid", nextProps.profileDetails.headData.result.profile.eosRoster)
                    }
                    if (this.props.subInfo &&
                        this.props.subInfo.rosterCoachData) {
                        this.props.subInfo.rosterCoachData = null;
                    }
                    if (!this.state.firstLoad) {
                        this.props.getTeamList('Coach');
                    }
                    this.props.getCoachRosterList();

                    setTimeout(() => {
                        this.setLoader(false);
                    }, 1500);
                })
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.getActiveReq &&
            nextProps.subInfo.getActiveData
        ) {
            nextProps.subInfo.getActiveReq = false;
            console.log("nextProps.subInfo.getActiveData:--", nextProps.subInfo.getActiveData);
            if (nextProps.subInfo.getActiveData.flag) {
                this.setState({
                    activeList: nextProps.subInfo.getActiveData.result.docs
                }, () => {
                    nextProps.subInfo.getActiveData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.subInfo.getActiveData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.delActiveReq &&
            nextProps.subInfo.delActiveData
        ) {
            nextProps.subInfo.delActiveReq = false;
            console.log("nextProps.subInfo.delActiveData:--", nextProps.subInfo.delActiveData);
            if (nextProps.subInfo.delActiveData.flag) {
                document.body.classList.remove('modal-open');
                this.setState({
                    delId: "",
                    deletePopup: false
                }, () => {
                    this.props.getActiveList(this.state.activeFilter, "Coach");
                })
            } else {
                nextProps.subInfo.delActiveData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.productInfo && nextProps.productInfo.productList) {
            if (nextProps.productInfo.productList.flag) {
                this.setState((prevState) => ({
                    ...prevState,
                    productList: nextProps.productInfo.productList.result.data ? nextProps.productInfo.productList.result.data : []
                }), () => {
                    let getAnal = this.state.productList.some((d) => d.productId.name === "AnalyzR");
                    let getBallr = this.state.productList.some((d) => d.productId.name === "BallR");
                    let getBCTeam = this.state.productList.some((d) => d.productId.name === "BCTeam");
                    let getPitchr = this.state.productList.some((d) => d.productId.name === "PitchR");
                    if (getAnal) {
                        localStorage.setItem("analProduct", true);
                    }
                    if (getBallr) {
                        localStorage.setItem("ballrProduct", true);
                    }
                    if (getBCTeam) {
                        localStorage.setItem("bcteamProduct", true);
                    }
                    if (getPitchr) {
                        localStorage.setItem("pitchrProduct", true);
                    }
                    nextProps.productInfo.productList = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.productInfo &&
            nextProps.productInfo.getAdsReq &&
            nextProps.productInfo.getAdsData
        ) {
            nextProps.productInfo.getAdsReq = false;
            console.log("nextProps.productInfo.getAdsData:--", nextProps.productInfo.getAdsData);
            if (nextProps.productInfo.getAdsData.flag) {
                this.setState({
                    adsArr: nextProps.productInfo.getAdsData.result.data
                }, () => {
                    nextProps.productInfo.getAdsData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.productInfo.getAdsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.getUpgradeReq &&
            nextProps.subInfo.getUpgradeData
        ) {
            nextProps.subInfo.getUpgradeReq = false;
            console.log("nextProps.subInfo.getUpgradeData:--", nextProps.subInfo.getUpgradeData);
            if (nextProps.subInfo.getUpgradeData.flag) {
                this.setState({
                    upgradeArr: nextProps.subInfo.getUpgradeData.result.data,
                    upgradePopup: !this.state.upgradePopup
                }, () => {
                    nextProps.subInfo.getUpgradeData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.subInfo.getUpgradeData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.subInfo &&
            nextProps.subInfo.rosterCoachReq &&
            nextProps.subInfo.rosterCoachData
        ) {
            nextProps.subInfo.rosterCoachReq = false;
            console.log("nextProps.subInfo.rosterCoachData:--", nextProps.subInfo.rosterCoachData);
            if (nextProps.subInfo.rosterCoachData.flag) {
                let setTeam;
                let rosterData;
                if (localStorage.getItem("rosterId")) {
                    rosterData = nextProps.subInfo.rosterCoachData.result.data.find(e => e._id === localStorage.getItem("rosterId"));
                    setTeam = {
                        teamLogo: {
                            filePath: rosterData.teamLogo ? rosterData.teamLogo : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'
                        },
                        name: rosterData.rosterName || "",
                        'social-link': {
                            insta: rosterData['social-link']?.insta || "",
                            twt: rosterData['social-link']?.twt || ""
                        },
                        shoplink: rosterData.shoplink || ""
                    }
                }

                this.setState({
                    teamArr: localStorage.getItem("rosterId") ? setTeam : this.state.teamArr,
                    insta: localStorage.getItem("rosterId") ? rosterData['social-link']?.insta : this.state.teamArr && this.state.teamArr["social-link"] && this.state.teamArr["social-link"].insta,
                    twt: localStorage.getItem("rosterId") ? rosterData['social-link']?.twt : this.state.teamArr && this.state.teamArr["social-link"] && this.state.teamArr["social-link"].twt,
                    teamRecord: localStorage.getItem("rosterId") ? rosterData?.teamRecord : this.state.teamRecord,
                    rosterList: nextProps.subInfo.rosterCoachData.result.data
                }, () => {
                    nextProps.subInfo.rosterCoachData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.subInfo.rosterCoachData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.productInfo &&
            nextProps.productInfo.getEventDashReq &&
            nextProps.productInfo.getEventDashData
        ) {
            nextProps.productInfo.getEventDashReq = false;
            console.log("nextProps.productInfo.getEventDashData:--", nextProps.productInfo.getEventDashData);
            if (nextProps.productInfo.getEventDashData.flag) {
                this.setState({
                    eventList: nextProps.productInfo.getEventDashData.result.docs
                }, () => {
                    nextProps.productInfo.getEventDashData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.productInfo.getEventDashData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.productInfo &&
            nextProps.productInfo.getMyTeamListReq &&
            nextProps.productInfo.getMyTeamListData
        ) {
            nextProps.productInfo.getMyTeamListReq = false;
            console.log("nextProps.productInfo.getMyTeamListData.result.data:-----", nextProps.productInfo.getMyTeamListData.result.data);
            if (nextProps.productInfo.getMyTeamListData.flag) {
                if (nextProps.productInfo.getMyTeamListData.result.data.length !== 0) {
                    this.setState({
                        myTeamList: nextProps.productInfo.getMyTeamListData.result.data
                    }, () => {
                        this.props.getCoachRosterList();
                        nextProps.productInfo.getMyTeamListData = null;
                        if (this.state.reloadData) {
                            this.setState({
                                reloadData: false
                            }, () => {
                                window.location.reload();
                                this.setLoader(false);
                            })
                        }
                    })
                } else {
                    nextProps.productInfo.getMyTeamListData = null;
                }
            }
        }

        if (nextProps.productInfo &&
            nextProps.productInfo.changeMyTeamListReq &&
            nextProps.productInfo.changeMyTeamListData
        ) {
            console.log("changeMyTeamListData:---", nextProps.productInfo.changeMyTeamListData);
            nextProps.productInfo.changeMyTeamListReq = false;
            if (nextProps.productInfo.changeMyTeamListData.flag) {
                nextProps.productInfo.changeMyTeamListData = null;
                this.props.getTeamList('Coach');
            }
        }

    }

    renderEventDash = () => {
        this.props.getEventDash(this.state.sendEvents);
    }

    nextPage = (name) => {
        this.commonCall.setStorage("coachTab", name, flag => {
            if (flag) {
                this.props.history.push('/coach-setting');
            }
        });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    setLink = (link) => {
        if (link) {
            window.open(link, '_blank');
        }
    }

    gotoLanding = (type) => {
        if (type === "ballr") {
            localStorage.setItem("scrollLanding", "");
            this.props.history.push("/ballr");
        } else {
            localStorage.setItem("scrollLanding", "");
            this.props.history.push("/analyzr");
        }
    }

    getUpgrade = (sid) => {
        this.setState({
            subId: sid
        }, () => {
            this.setLoader(true);
            this.props.upgradeList({ subscription_id: sid }, "Coach");
        })
    }

    renderActiveList = () => {
        return (
            this.state.activeList.length !== 0 &&
            this.state.activeList.map((list, i) => {
                if (list.productId.productCode !== "ANALYZR") {
                    return (
                        <tr key={i}>
                            <th className="title">{list.productId.name} {list.packageId !== null ? this.setPlan(list.priceId, list.packageId.plans).type === 1 ? "Monthly" : "Yearly" : ""} Subscription -  {list.packageId !== null ? list.packageId.type + " " + list.packageId.role : ""}</th>
                            {
                                list.stripeLogId &&
                                list.stripeLogId.stripeLog &&
                                list.stripeLogId.stripeLog.status === "trialing" &&
                                list.packageId &&
                                list.packageId.trialPeriod > 0 &&
                                <td style={{ color: 'red' }}> Free Trial {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_start * 1000).format("DD MMM, YYYY")} - {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_end * 1000).format("DD MMM, YYYY")}</td>
                            }
                            <td>{(list.isActive && list.isExpire) ? "Good through " : "Renew "}  {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
                            <td className="status_link">
                                {
                                    list.isActive &&
                                    <Link className="green-status">Active</Link>
                                }
                                {
                                    list.packageId && this.setPlan(list.priceId, list.packageId.plans).type === 2 &&
                                        list.packageId.type === "Gold" ?
                                        ""
                                        :
                                        <Link className="blue-status" onClick={() => this.getUpgrade(list._id)}>Upgrade</Link>
                                }
                            </td>
                        </tr >
                    );
                }
            })
            // :
            // <p className="text-center mb-0">There is no subscription active yet. <Link onClick={() => this.gotoLanding('ballr')}>Click Here</Link> to buy now.</p>
        )
    }

    nextPayment = (plan, data) => {
        let nextData = {
            packid: data._id,
            plans: plan[0],
            title: data.role + " - " + data.type + " Package"
        }
        this.commonCall.setStorage("payPlans", nextData, flag => {
            if (flag) {
                if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
                    this.props.history.push('/login');
                } else if (localStorage.getItem("coachToken")) {
                    this.props.history.push('/coach-payment');
                } else {
                    this.props.history.push('/player-payment');
                }
            }
        });
    }

    setPlan = (typeId, plans) => {
        var isPlans = "";
        plans.length > 0 &&
            plans.forEach((data) => {
                if (data.stripePlanId === typeId) return (isPlans = data);
            });
        return isPlans;
    }

    cancelSub = (subId, expiryDate) => {
        this.setState({
            delId: subId,
            deletePopup: true,
            date: expiryDate
        })
    }

    closePopup = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            delId: "",
            deletePopup: false
        })
    }

    confirmPopup = () => {
        this.setLoader(true);
        this.props.deleteSubscription({
            subscription_id: this.state.delId
        }, "Coach");
    }

    redirectNew = (link) => {
        if (link) {
            window.open(link, '_blank');
        }
    }

    renderAdds = () => {
        return (
            this.state.adsArr.length != 0 ?
                this.state.adsArr.map((list, i) => {
                    return (
                        <Link className="item" onClick={() => this.redirectNew(list.destination)}>
                            {
                                list.media.hasOwnProperty('_id') ?
                                    <img src={list.media.filePath} alt="slide1" />
                                    :
                                    <p>Ads</p>
                            }
                        </Link>
                    );
                })
                :
                <Link className="item">
                    <p>Ads</p>
                </Link>
        )
    }

    onHandleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            upgradePopup: !this.state.upgradePopup,
            upgradeArr: [],
            subId: ""
        })
    }

    onHandleOk = () => {
        this.setState({
            upgradePopup: !this.state.upgradePopup,
            upgradeArr: [],
            subId: "",
            successPopup: true
        })
    }

    onHandleCloseSuccess = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            successPopup: !this.state.successPopup,
        }, () => {
            this.props.getActiveList(this.state.activeFilter, "Coach");
        })
    }

    redirectWebgl = (name, url) => {
        if (name === "BCTeam") {
            Cookies.set('gameType', localStorage.getItem("gameType"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            localStorage.setItem("webglUrl", url);
            this.props.history.push("/bcteamapp");
        } else if (name === "BallR") {
            localStorage.setItem("webglUrl", url);
            this.props.history.push("/ballrapp");
        } else if (name === "PitchR") {
            localStorage.setItem("webglUrl", url);
            this.props.history.push("/pitchrapp");
        } else {
            Cookies.remove('analyzrSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            localStorage.setItem("webglUrl", url);
            this.props.history.push("/analyzrapp");
        }

    }



    redirectToSeat = (i) => {
        localStorage.setItem("seatIndex", i);
        this.props.history.push("/coach-license");
    }

    closeImport = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isImport: false
        })
    }

    nextEditPage = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isImport: false
        }, () => {
            this.props.history.push("/coach-setting")
        })
    }

    handleChange = (e) => {
        const { value } = e.target;
        let rosterTeam = this.state.rosterList.find((r) => r._id === value)
        localStorage.setItem("rosterId", rosterTeam._id);
        this.setState((prevState) => ({
            ...prevState,
            rosterGroup: value,
            teamArr: {
                teamLogo: {
                    filePath: rosterTeam.teamLogo ? rosterTeam.teamLogo : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'
                },
                name: rosterTeam.rosterName || "",
                'social-link': {
                    insta: rosterTeam['social-link']?.insta || "",
                    twt: rosterTeam['social-link']?.twt || ""
                },
                shoplink: rosterTeam.shoplink || "",
            },
            teamRecord: rosterTeam.teamRecord || "",
            insta: rosterTeam['social-link']?.insta || "",
            twt: rosterTeam['social-link']?.twt || "",
            sendEvents: {
                ...prevState.sendEvents,
                rosterTeamId: value
            },
        }), () => {
            // if (localStorage.getItem("company")) {
            this.props.getEventDash(this.state.sendEvents);
            // }
        })
    }

    redirectDetails = (data) => {
        var isloggedIn = checkLoginValidation();
        if (isloggedIn) {
            if (this.state.sendEvents.type === 2) {
                this.setCompany(data);
            } else {
                this.props.history.push(`/event-detail/${data._id}`);
            }
        } else {
            this.props.history.push("/login");
        }
    }

    setCompany = (data) => {
        if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== data.companies._id) {
            let companyname = data.companies.name === "GOBTournament" ? "Greater Orlando Baseball" : data.companies.name;
            let item = commonHeader.events.filter((e) => e.label === companyname)[0];
            if (item.key !== "gob" && item.key !== "utb") {
                localStorage.setItem("cid", data.companies._id)
                localStorage.setItem("company", item.key)
                localStorage.setItem("companyColor", JSON.stringify(item.colors));
                localStorage.setItem("recapInfo", JSON.stringify(data))
                this.props.history.push(`/${localStorage.getItem('company')}-event-info/${data._id}`);
            }
        } else {
            if (localStorage.getItem('company') !== "gob" && localStorage.getItem('company') !== "utb") {
                localStorage.setItem("recapInfo", JSON.stringify(data))
                this.props.history.push(`/${localStorage.getItem('company')}-event-info/${data._id}`);
            }
        }
    }

    eventFilter = (name, value) => {
        this.setState((prevState) => ({
            ...prevState,
            sendEvents: {
                ...prevState.sendEvents,
                [name]: value
            },
        }), () => {
            // if (localStorage.getItem("company")) {
            this.setLoader(true);
            this.props.getEventDash(this.state.sendEvents);
            // }
        })
    }

    changeTeam = (e) => {
        this.setState({
            reloadData: true,
            rosterList: [],
            rosterGroup: "",
            rosterArr: null
        }, () => {
            this.setLoader(true);
            localStorage.removeItem("rosterId");
            this.props.changeMyTeamData({ parentTeam: e.target.value }, "Coach");
        })
    }

    closeApp = () => {
        document.body.classList.remove('modal-open');
        this.setState((prevState) => ({
            ...prevState,
            appPopUp: false
        }))
    }

    closeCmsUser = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isCmsUser: false
        })
    }

    cmsUserNext = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isCmsUser: false
        }, () => {
            this.props.history.push("/coach-setting")
        })
    }

    closeEosPlayer = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isEosPlayer: false
        }, () => {
            localStorage.removeItem("manageRid");
        })
    }

    eosPlayerNext = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isEosPlayer: false
        }, () => {
            this.commonCall.setStorage("coachTab", "roster", flag => {
                if (flag) {
                    this.props.history.push('/coach-setting');
                }
            });
        })
    }

    render() {

        const { appPopUp, teamArr, coachName, isCmsUser, isEosPlayer, myTeamList, rosterGroup, companyKey, isImport, subCoach, gameType, teamRecord, loader, teamName, facilty, deletePopup, upgradePopup, upgradeArr, subId, successPopup, isInvite } = this.state
        var settings = {
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: true,
        };
        var product = {
            dots: false,
            speed: 500,
            slidesToShow: this.state.productList.length > 4 ? 1 : 4,
            slidesToScroll: 1,
            infinite: false,
            initialSlide: 0,
            arrows: this.state.productList.length > 4 ? true : false,
            autoplay: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1445,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                    }
                },
            ]
        };
        console.log("gameType:---", gameType);
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                <section className="coachdashboard event-dashboard">
                    <div className="container">
                        {this.state.rosterList.length !== 0 &&
                            <div className="mb-15">
                                <div className="event-roster-select">
                                    <label className="common-lable">Choose Roster Group:</label>
                                    <select className="custom-select" name="rosterGroup" value={rosterGroup} onChange={this.handleChange}>
                                        <option value="" selected disabled>Choose Roster Group</option>
                                        {this.state.rosterList.map((l, i) => {
                                            return (
                                                <option value={l._id} key={i}>{l.rosterName}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>}
                        <div className="common-dashbord mt-15">

                            {/* {
                                facilty &&
                                    facilty.faciltyName ?
                                    <div className="common-div">
                                        <h3>
                                            {facilty && facilty.faciltyName ? facilty.faciltyName : "Your Facility"}
                                        </h3>
                                        <div className="team_logo">
                                            <img src={facilty && facilty.faciltyLogo && facilty.faciltyLogo.filePath ? facilty.faciltyLogo.filePath : imagesArr.dashboard_user} alt="dashboard_user" />
                                        </div>
                                        <h4>
                                            {facilty && facilty.faciltyAddress && facilty.faciltyAddress}
                                        </h4>
                                        <div className="dash_social social-icon">
                                            <Link className="insta social_but" onClick={() => this.setLink(facilty.facInstagramLink ? "https://www.instagram.com/" + facilty.facInstagramLink : "")} disabled={!facilty.facInstagramLink ? true : false} ><i className="fab fa-instagram"></i></Link>
                                            <Link className="twitter social_but" onClick={() => this.setLink(facilty.facTwitterLink ? "https://twitter.com/" + facilty.facTwitterLink : "")} disabled={!facilty.facTwitterLink ? true : false}><i className="fab fa-twitter"></i></Link>
                                        </div>
                                        <Link className="btn light-green w-100" onClick={() => this.nextPage('facility')}>Edit Facility</Link>
                                    </div>
                                    :
                                    <div className="common-div">
                                        <div className="facalitiy_logo">
                                            <img src={imagesArr.facalitiy_logo} alt="facalitiy_logo" />
                                        </div>
                                        <Link className="btn light-green w-100" onClick={() => this.nextPage('facility')}>+ Add Facility</Link>
                                    </div>
                            } */}

                            <div className="dashbord-center">
                                <div className="slider-common">
                                    <Slider {...settings}>
                                        {this.renderAdds()}
                                    </Slider>
                                </div>
                                <div className={`upload_file ${subCoach && 'subcoach-access'}`}>
                                    {!isInvite &&
                                        <><Link className={`upload_inner ${teamArr && teamArr.shoplink ? "store-padding" : ""}`} onClick={() => this.nextPage('roster')}>
                                            <div className="upload_img">
                                                {
                                                    (companyKey === "pwb" || companyKey === null) &&
                                                    <img className="active" src={imagesArr.green_teamwork} alt="teamwork" />
                                                }
                                                {
                                                    companyKey === "pws" &&
                                                    <img className="active" src={imagesArr.aquamarine_teamwork} alt="teamwork" />
                                                }
                                                {
                                                    companyKey === "gob" &&
                                                    <img className="active" src={imagesArr.blue_teamwork} alt="teamwork" />
                                                }
                                                {
                                                    companyKey === "utb" &&
                                                    <img className="active" src={imagesArr.blue_light_teamwork} alt="teamwork" />
                                                }
                                                <img className="deactive" src={imagesArr.white_teamwork} alt="teamwork" />
                                            </div>
                                            <h5>Team Roster</h5>
                                        </Link>
                                            <Link className={`upload_inner ${teamArr && teamArr.shoplink ? "store-padding" : ""}`} to={"/coach-uploadcsv"}>
                                                <div className="upload_img">
                                                    {
                                                        (companyKey === "pwb" || companyKey === null) &&
                                                        <img className="active" src={imagesArr.green_upload} alt="upload" />
                                                    }
                                                    {
                                                        companyKey === "pws" &&
                                                        <img className="active" src={imagesArr.aquamarine_upload} alt="upload" />
                                                    }
                                                    {
                                                        companyKey === "gob" &&
                                                        <img className="active" src={imagesArr.blue_upload} alt="upload" />
                                                    }
                                                    {
                                                        companyKey === "utb" &&
                                                        <img className="active" src={imagesArr.blue_light_upload} alt="upload" />
                                                    }
                                                    <img className="deactive" src={imagesArr.white_upload} alt="teamwork" />
                                                </div>
                                                <h5>Upload Data</h5>
                                            </Link>
                                        </>}
                                    {
                                        !subCoach &&
                                        <Link className={`upload_inner ${teamArr && teamArr.shoplink ? "store-padding" : ""}`} onClick={() => this.nextPage('billing')}>
                                            <div className="upload_img">
                                                {
                                                    (companyKey === "pwb" || companyKey === null) &&
                                                    <img className="active" src={imagesArr.green_subscription} alt="subscription" />
                                                }
                                                {
                                                    companyKey === "pws" &&
                                                    <img className="active" src={imagesArr.aquamarine_subscription} alt="subscription" />
                                                }
                                                {
                                                    companyKey === "gob" &&
                                                    <img className="active" src={imagesArr.blue_subscription} alt="subscription" />
                                                }
                                                {
                                                    companyKey === "utb" &&
                                                    <img className="active" src={imagesArr.blue_light_subscription} alt="subscription" />
                                                }
                                                <img className="deactive" src={imagesArr.white_subscription} alt="teamwork" />
                                            </div>
                                            <h5>Manage Subscriptions</h5>
                                        </Link>
                                    }
                                </div>
                            </div>
                            <div className="common-div">
                                {/* <h3>Team Roster</h3> */}
                                <h4 className="p-black">
                                    {
                                        myTeamList &&
                                            myTeamList.length >= 2 ?
                                            <select className="custom-select" onChange={(e) => this.changeTeam(e)}>
                                                {
                                                    myTeamList.map((list, i) => {
                                                        return (
                                                            list.parentTeam && <option selected={list.default} value={list.parentTeam._id}> {list.parentTeam.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            :
                                            <div className="team-name">
                                                <h3>{teamArr ? teamArr.name : "No Team"}</h3>
                                            </div>
                                    }
                                </h4>

                                {
                                    !isInvite ?
                                        <>
                                            <div className="team_logo">
                                                <img src={(this.state.coachLevel === 2 || this.state.coachLevel === 3 || this.state.isMLB || this.state.organization)
                                                    ? this.state.parentTeamLogo
                                                    : teamArr && teamArr.teamLogo
                                                        ? teamArr.teamLogo.filePath
                                                        : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'} alt="team_logo" />
                                            </div>

                                            {(this.state.coachLevel !== 2 && this.state.coachLevel !== 3 && !this.state.isMLB && !this.state.organization) && <div><h4>social links</h4></div>}
                                            {/* <p className="p-black">
                                    Coach <span>: {coachName}</span>
                                </p> */}
                                            {(this.state.coachLevel !== 2 && this.state.coachLevel !== 3 && !this.state.isMLB && !this.state.organization) &&
                                                <>
                                                    <div className="dash_social social-icon">
                                                        <Link
                                                            className="insta social_but"
                                                            onClick={() => this.setLink(teamArr && teamArr['social-link'] && teamArr['social-link'].insta ? "https://www.instagram.com/" + teamArr['social-link'].insta : "")}
                                                            disabled={this.state.insta ? false : true}>
                                                            <i className="fab fa-instagram"></i>
                                                        </Link>
                                                        <Link
                                                            className="twitter social_but"
                                                            onClick={() => this.setLink(teamArr && teamArr['social-link'] && teamArr['social-link'].twt ? "https://twitter.com/" + teamArr['social-link'].twt : "")}
                                                            disabled={this.state.twt ? false : true}>
                                                            <i className="fab fa-twitter"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="form-group" data-left="You" data-right="Opp.">
                                                        <input type="text" name="you" value={teamRecord ? teamRecord : ""} className="form-control" id="usr" placeholder="00-00" readOnly />
                                                    </div>
                                                </>
                                            }
                                            <div className="btn-group">
                                                {/* <Link className="btn light-green" onClick={() => this.nextPage('team')}>Edit Team</Link> */}
                                                {
                                                    teamArr && teamArr.shoplink &&
                                                    <a className="btn blue_btn" href={teamArr.shoplink} target="_blank"><i class="fas fa-shopping-bag"></i>Store</a>
                                                }
                                            </div>

                                        </>
                                        :
                                        <div>You are waiting for joining.</div>
                                }
                            </div >
                        </div >
                    </div >
                </section>
                {(this.state.coachLevel !== 2 && this.state.coachLevel !== 3 && !this.state.isMLB && !this.state.organization) &&
                    <section className="products">
                        <div className="container">
                            <div className="white-bg">
                                <div className="roster-event-tab">
                                    <h3>My Events</h3>
                                    <div className="roster-event-tab-info">
                                        <div className="list-group-scroll">
                                            <ul className="list-group">
                                                <li class={`list-group-item ${this.state.sendEvents.type === 2 ? "active" : ""} `} onClick={() => this.eventFilter("type", 2)}>Past</li>
                                                <li class={`list-group-item ${this.state.sendEvents.type === 1 ? "active" : ""} `} onClick={() => this.eventFilter("type", 1)}>Upcoming</li>
                                            </ul>
                                        </div>
                                        {/* <ul class="list-group">
                                        <li class={`list-group-item ${this.state.sendEvents.eventTeamType === 1 ? "active" : ""} `} onClick={()=>this.eventFilter("eventTeamType",1)}>Tournament</li>
                                        <li class={`list-group-item ${this.state.sendEvents.eventTeamType === 2 ? "active" : ""} `} onClick={()=>this.eventFilter("eventTeamType",2)} >Showcase</li>
                                    </ul> */}
                                    </div>
                                </div>
                                <div className="event_slider">
                                    <div className="all_event">
                                        <EventSlider
                                            eventList={this.state.eventList}
                                            eventType={this.state.sendEvents.type}
                                            setLoader={this.setLoader}
                                            renderEventDash={this.renderEventDash}
                                            redirectDetails={this.redirectDetails} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>}
                {
                    this.state.productList.length != 0 && <section className="products">
                        <div className="container">
                            <div className="white-bg">
                                <h3> My Products</h3>
                                <div className="produc-common">
                                    <Slider {...product}>
                                        {this.state.productList.length != 0 &&
                                            this.state.productList.sort((a, b) => (a.productId.name > b.productId.name ? 1 : -1)).map((e, item) => {
                                                return (
                                                    <div className="item" key={item}>
                                                        <div className="items">
                                                            <div className="team_img">
                                                                {
                                                                    e.productId.name !== "BCTeam" &&
                                                                    <img src={e.productId.logo.filePath} alt="ballrteam" />
                                                                }
                                                                {
                                                                    e.productId.name === "BCTeam" &&
                                                                    <img src={teamArr?.coach && teamArr?.coach?.profile["game-type"] === "2" ? e?.productId?.secondary_logo ? e.productId.secondary_logo.filePath : imagesArr.default_user : e?.productId?.logo ? e.productId.logo.filePath : imagesArr.default_user} alt="ballrteam" />
                                                                }
                                                                <span>{e.productId.name}</span>
                                                            </div>
                                                            <div className="team_dec">
                                                                <div className="team_name">
                                                                    <h6>{e.productId.name === "BCTeam" && teamArr?.coach && teamArr?.coach.profile["game-type"] === "2" ? "SCTeam" : e.productId.name}</h6>

                                                                </div>
                                                                {/* {
                                                                    e.productId.name === "AnalyzR" &&
                                                                    <span>{e.credits} Credit(s) Available</span>
                                                                }
                                                                {
                                                                    e.licTotal !== 0 &&
                                                                    <span>{e.licRemain}/{e.licTotal} Seats Available</span>
                                                                } */}
                                                                {
                                                                    e.productId.name === "AnalyzR" &&
                                                                    <a className="manage" href="mailto:Eric@baseballcloud.com">Contact Us</a>
                                                                    // <Link className="manage" onClick={() => this.gotoLanding('analyzr')}>Purchase Credits</Link>
                                                                }
                                                                {
                                                                    e.licTotal !== 0 && !subCoach && e.productId.name !== "AnalyzR" &&
                                                                    <Link className="manage" onClick={() => this.redirectToSeat(item)}>Manage Seats</Link>
                                                                }
                                                                {/* <Link className="btn light-green w-100" onClick={() => this.redirectWebgl(e.productId.name, e.productId.url)}>Open {e.productId.name === "BCTeam" && teamArr.coach && teamArr.coach.profile["game-type"] === "2" ? "SCTeam" : e.productId.name}</Link> */}
                                                                <Link className="btn light-green w-100" onClick={() => this.redirectWebgl(e.productId.name, e.productId.url)}>Open</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                    {/* {this.state.productList.length === 0 &&
                                    <p className="text-center mb-0">You haven't subscribed to any plan yet!</p>
                                } */}
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {
                    (!subCoach && this.state.activeList.length !== 0) &&
                    <section className="products">
                        <div className="container">
                            <div className="white-bg">
                                <h3>Active subscription</h3>
                                <p className="mb-0">Your Active Subscription</p>
                                <div className="table-responsive-xl">
                                    <table className="table">
                                        <tbody>
                                            {this.renderActiveList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <Footer />
                <Confirmation
                    flag={deletePopup}
                    type="subcription"
                    close={this.closePopup}
                    confirm={this.confirmPopup}
                    date={this.state.date}
                />
                {
                    upgradePopup &&
                    <UpgradeSubscribe
                        flag={upgradePopup}
                        data={upgradeArr}
                        close={this.onHandleClose}
                        sid={subId}
                        ok={this.onHandleOk}
                        type="Coach"
                    />
                }
                {
                    successPopup &&
                    <SuccessUpgrade
                        flag={successPopup}
                        close={this.onHandleCloseSuccess}
                    />
                }
                {
                    isImport &&
                    <Confirmation
                        flag={isImport}
                        type="isImport"
                        close={this.closeImport}
                        confirm={this.nextEditPage}
                    />
                }
                {
                    isCmsUser &&
                    <Confirmation
                        flag={isCmsUser}
                        type="isCmsUser"
                        name={coachName}
                        close={this.closeCmsUser}
                        confirm={this.cmsUserNext}
                    />
                }
                {
                    isEosPlayer &&
                    <Confirmation
                        flag={isEosPlayer}
                        type="isEosPlayer"
                        close={this.closeEosPlayer}
                        confirm={this.eosPlayerNext}
                    />
                }
                {appPopUp && <DownloadApp
                    flag={appPopUp}
                    close={this.closeApp}
                />}
                <Preloader flag={loader} />
            </>
        );
    }
}

coachDashboard.propTypes = {
    getActiveList: PropTypes.func.isRequired,
    deleteSubscription: PropTypes.func.isRequired,
    getProductList: PropTypes.func.isRequired,
    getAdverticement: PropTypes.func.isRequired,
    upgradeList: PropTypes.func.isRequired,
    getCoachRosterList: PropTypes.func.isRequired,
    getEventDash: PropTypes.func.isRequired,
    getTeamList: PropTypes.func.isRequired,
    changeMyTeamData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileDetails: state.headerData,
    subInfo: state.subscriptionInfo,
    productInfo: state.api,
    login: state.loginData,
});

export default connect(mapStateToProps, { getTeamList, changeMyTeamData, getActiveList, deleteSubscription, getProductList, getAdverticement, upgradeList, getEventDash, getCoachRosterList })(coachDashboard);

import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../components/commonHeroBanner';
import PersonalInfo from './profile/personalInfo';
import FacilityInfo from './profile/facilityInfo';
import RosterInfo from './profile/rosterInfo';
import TrainingInfo from './profile/trainingInfo';
import TeamInfo from './profile/teamInfo';
import PlayerInfo from './profile/PlayerInfo';
import CoachInfo from './profile/CoachInfo';
import commonCalls from "../../businesslogic/commonCalls";
import BillingInfo from './profile/billingInfo';
import LinkedPlayer from './profile/LinkedPlayer';
import { checkLoginValidation } from '../../saga/helpers/authToken';


class coachSetting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            teamInfo: false,
            coachInfo: false,
            teamName: "",
            coachLevel: "",
            staffInfo: false,
            isMLB:false
        }

        this.commonCall = new commonCalls();
    }
    componentDidUpdate(){
        if(localStorage.getItem("coachTab")){
            this.commonCall.getStorage("coachTab", data => {
                if (data !== null) {
                    this.setState({
                        tabs: data === 'facility' ? 1 : data === 'profile' ? 0 : data === 'roster' ? 2 : data === 'training' ? 3 : data === 'team' ? 4 : data === 'billing' ? 7 : 0
                    }, () => {
                        this.commonCall.removeStorage("coachTab");
                    })
                }
            });
        }
    }
    componentDidMount() {
       
        window.scrollTo(0, 0);
        this.getRouterTab();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileDetails &&
            nextProps.profileDetails.headReq &&
            nextProps.profileDetails.headData
        ) {
            if (nextProps.profileDetails.headData.result.profile) {
                console.log("nextProps.profileDetails.headData:--", nextProps.profileDetails.headData);
                let profile = nextProps.profileDetails.headData.result.profile
                this.setState({
                    // teamInfo: profile.hasOwnProperty('parentTeam') ? true : false,
                    // coachInfo: profile.hasOwnProperty('parentTeam') ? profile.parentTeam.coach === profile.user ? true : false : false

                    // teamInfo: profile.invite ? true : false,
                    // coachInfo: profile.subCoach
                    coachLevel: nextProps.profileDetails.headData.result.profile.parentTeam && nextProps.profileDetails.headData.result.profile.parentTeam.type,
                    teamInfo: (!profile.invite && profile.team && profile.team.length) ? true : false,
                    coachInfo: profile.subCoach,
                    teamName: profile?.parentTeam ? profile.parentTeam.name : "",
                    staffInfo: (profile.team && profile.team.length) ? true : false,
                    isMLB:profile.isMLB && profile.organization ? true : false
                })
            }
        }
    }

    componentWillMount() {
        let url = new URL(document.location);
        let getPath = url.pathname.split("/")[2];
        if (getPath) {
            var isloggedIn = checkLoginValidation();
            if (!isloggedIn) {
                this.commonCall.setStorage("coachTab", 'roster', flag => {
                    if (flag) {
                        this.props.history.push("/login");
                    }
                })
            } else {
                this.setState({
                    tabs: Number(getPath)
                }, () => {
                    this.commonCall.removeStorage("coachTab");
                })
            }
        }else{
            this.commonCall.getStorage("coachTab", data => {
                if (data !== null) {
                    console.log("flag::data:---", data);
                    this.setState({
                        tabs: data === 'facility' ? 1 : data === 'profile' ? 0 : data === 'roster' ? 2 : data === 'training' ? 3 : data === 'team' ? 4 : data === 'billing' ? 7 : 0
                    }, () => {
                        this.commonCall.removeStorage("coachTab");
                    })
                }
            });
        }
    }

    getRouterTab = () => {
        const { match, location, history } = this.props
        if (location.search === "?tab=roster") {
            this.setState({
                tabs: 2
            }, () => {
                this.commonCall.removeStorage("coachTab");
            })
        }
    }

    handleTabs = (e) => {
        localStorage.removeItem("manageRid");
        this.setState({
            tabs: e
        })
    }

    render() {
        const { tabs, teamInfo, coachInfo, teamName, coachLevel, staffInfo ,isMLB} = this.state;
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                <section className="main_pro_tab">
                    <div className="container">
                        <div className="edit_pro_scroll">
                            <ul className="edit_pro_inner">
                                <li className={`${tabs === 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                    <Link>Personal Info</Link>
                                </li>
                                {(coachLevel !== 2 && coachLevel !== 3 && isMLB === false) && <li className={`${tabs === 8 ? "active" : ""}`} onClick={() => this.handleTabs(8)}>
                                    <Link>Linked Players</Link>
                                </li>}
                                {/* <li className={`${tabs === 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                    <Link>Facility Info</Link>
                                </li>
                                {teamInfo && <li className={`${tabs === 4 ? "active" : ""}`} onClick={() => this.handleTabs(4)}>
                                    <Link>Team Info</Link>
                                </li>} */}
                                {
                                    teamInfo &&
                                    <li className={`${tabs === 5 ? "active" : ""}`} onClick={() => this.handleTabs(5)}>
                                        <Link>My Players</Link>
                                    </li>
                                }
                                {
                                    teamInfo &&
                                    <li className={`${tabs === 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                        <Link>Roster Info</Link>
                                    </li>
                                }
                                {
                                    staffInfo &&
                                    <li className={`${tabs === 6 ? "active" : ""}`} onClick={() => this.handleTabs(6)}>
                                        <Link>Staff</Link>
                                    </li>
                                }
                                {(coachLevel !== 2 && coachLevel !== 3 && isMLB === false) && <li className={`${tabs === 3 ? "active" : ""}`} onClick={() => this.handleTabs(3)}>
                                    <Link>Signup Questions</Link>
                                </li>}
                                {!coachInfo && isMLB === false &&
                                    <li className={`${tabs === 7 ? "active" : ""}`} onClick={() => this.handleTabs(7)}>
                                        <Link>Billing</Link>
                                    </li>
                                }
                            </ul>
                        </div>

                        {tabs === 0 && <PersonalInfo />}
                        {tabs === 1 && <FacilityInfo />}
                        {tabs === 2 && <RosterInfo coachLevel={coachLevel} />}
                        {tabs === 3 && <TrainingInfo />}
                        {tabs === 4 && <TeamInfo />}
                        {tabs === 5 && <PlayerInfo />}
                        {tabs === 6 && <CoachInfo teamName={teamName} coachLevel={coachLevel} />}
                        {tabs === 7 && <BillingInfo />}
                        {tabs === 8 && <LinkedPlayer />}

                    </div>
                </section>
                <Footer />
            </>
        );
    }
}


coachSetting.propTypes = {
};

const mapStateToProps = state => ({
    profileDetails: state.headerData,
});

export default connect(mapStateToProps, {})(coachSetting);


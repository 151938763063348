import React, { Component } from "react";
import firebase from 'firebase';
import { isSafari, isMobile } from 'react-device-detect';

class firebaseConnection extends Component {

    // local credensial in my accoutn chandreshfrontend@gmail.com

    // apiKey: "AIzaSyAppVMox2AG9YXPOuWyKckG-IyAWcFE5NE",
    // authDomain: "angular-concept-d4179.firebaseapp.com",
    // databaseURL: "https://angular-concept-d4179.firebaseio.com",
    // projectId: "angular-concept-d4179",
    // storageBucket: "angular-concept-d4179.appspot.com",
    // messagingSenderId: "774169814605",
    // appId: "1:774169814605:web:b52f0089d0f8c804b2de04",
    // measurementId: "G-EKEGQD7FCM",

    // apiKey: process.env.REACT_APP_FIRE_APIKEY,
    // authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIRE_DATABASE_URL,
    // projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIRE_APP_ID,
    // measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID

    firebaseConnect = () => {
        var config = {
            apiKey: process.env.REACT_APP_FIRE_APIKEY,
            authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIRE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIRE_APP_ID,
            measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID
        };

        if (!isSafari && !isMobile) {
            console.log("isSafari:--", isSafari);
            if (!firebase.apps.length) {
                firebase.initializeApp(config);
                console.log("======== html inst firebase ===============", firebase);

                // Retrieve an instance of Firebase Messaging so that it can handle background messages.
                window.messaging = firebase.messaging();
            }
        }

    };

}

export default firebaseConnection;

import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from "../../assets/images";
import { Link } from "react-router-dom";
import CommonHeroBanner from "../../components/commonHeroBanner";
import { Player } from 'video-react';
import commonCalls from "../../businesslogic/commonCalls";
import { getPackage, buyPackage } from '../../saga/actions/landing/analyzr.action';
import Preloader from '../../components/preloader';
import { checkLoginValidation } from '../../saga/helpers/authToken';
import Cookies from 'js-cookie';

class AnalyzR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      loader: false,
      lowestPrice: ""
    }
    this.commonCall = new commonCalls();
    this.learnmore = React.createRef();
    this.pricingRef = React.createRef();
  }

  componentDidMount = () => {
    if (localStorage.getItem("scrollLanding") !== null || Cookies.get("scrollLanding")) {
      setTimeout(() => {
        this.gotoPricing();
        localStorage.removeItem("scrollLanding");
        Cookies.remove("scrollLanding");
      }, 500);
    }

    this.setLoader(true);
    this.props.getPackage();

  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.analyzrDetails &&
      nextProps.analyzrDetails.analyzrPackReq &&
      nextProps.analyzrDetails.analyzrPackData
    ) {
      nextProps.analyzrDetails.analyzrPackReq = false;
      console.log("nextProps.analyzrDetails.analyzrPackData:---", nextProps.analyzrDetails.analyzrPackData);
      if (nextProps.analyzrDetails.analyzrPackData.flag) {
        let getPlan = nextProps.analyzrDetails.analyzrPackData.result;

        let price = this.commonCall.getMaxOfKeys(getPlan.plans, ['amount'])

        this.setState({
          plans: getPlan.plans,
          lowestPrice: price
        }, () => {
          this.setLoader(false);
          nextProps.analyzrDetails.analyzrPackData = null;
        })
      } else {
        this.setLoader(false);
        nextProps.analyzrDetails.analyzrPackData = null;
      }
    }

    if (nextProps.analyzrDetails &&
      nextProps.analyzrDetails.buyPackReq &&
      nextProps.analyzrDetails.buyPackData
    ) {
      nextProps.analyzrDetails.buyPackReq = false;
      if (nextProps.analyzrDetails.buyPackData.flag) {
        let getUrl = nextProps.analyzrDetails.buyPackData.result.result;
        window.open(getUrl.url, '_blank');
        this.setLoader(false);
        nextProps.analyzrDetails.buyPackData = null;
      } else {
        this.setLoader(false);
        nextProps.analyzrDetails.buyPackData = null;
      }
    }
  }

  nextPayment = (planId) => {
    this.setLoader(true);

    let nextData = {
      planId: planId,
      product: "AnalyzR"
    }

    localStorage.setItem("analyzr", "pack");
    this.commonCall.setStorage("payPlans", nextData, flag => {
      if (flag) {
        if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
          this.props.history.push('/signup');
        } else if (localStorage.getItem("coachToken")) {
          this.props.buyPackage({ planId: planId });
        } else {
          this.props.buyPackage({ planId: planId });
        }
      }
    });
  }

  gotoLernmore() {
    let LernmoreSecTop = this.learnmore.current.offsetTop
    let headerHeight = document.getElementById('bc-header').offsetHeight
    window.scrollTo({
      top: (LernmoreSecTop - (headerHeight - 24)),
      behavior: 'smooth'
    });
  }

  gotoPricing() {
    let pricingSecTop = this.pricingRef.current.offsetTop
    let headerHeight = document.getElementById('bc-header').offsetHeight
    window.scrollTo({
      top: (pricingSecTop - (headerHeight - 24)),
      behavior: 'smooth'
    });
  }

  renderBillingList = () => {
    return (
      this.state.plans.map((plan, i) => {
        return (
          <tr key={i}>
            <td>{plan.credits}</td>
            <td><p dangerouslySetInnerHTML={{ __html: plan.description }}></p></td>
            <td><div className="price-buy"><div className="price-tag">${plan.amount}</div><button type="button" className="btn" onClick={() => this.nextPayment(plan._id)}>Buy</button></div></td>
          </tr>
        )
      })

    );
  };

  redirectTemplate = () => {
    var isloggedIn = checkLoginValidation();
    this.setLoader(true);
    localStorage.setItem("webglUrl", process.env.REACT_APP_ANALYZR_LINK);
    Cookies.set('analyzrSession', 'yes', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    if (isloggedIn) {
      setTimeout(() => {
        this.setLoader(false);
        this.props.history.push('/analyzrapp');
      }, 1000);
    } else {
      localStorage.setItem("analyzrSession", true);
      setTimeout(() => {
        this.setLoader(false);
        this.props.history.push('/login');
      }, 1000);
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  render() {
    const { loader, lowestPrice } = this.state;
    return (
      <>
        <Header />
        {/* Upgrade landing page start */}
        <section className="hero-banner">
          <div className="container">
            <div className="analyzr-main">
              <div className="analyzr-img">
                <img src={imagesArr.analyzr} />
              </div>
              <div className="analyzr-info">
                <img src={imagesArr.headinglogo5} alt="headinglogo5" />
                <h1>The Industry’s Most Affordable Analytics Department</h1>
                {/* <p>Get started for just ${lowestPrice}</p> */}
                <div className="hero-btn">
                  <a className="btn" onClick={this.gotoLernmore.bind(this)}>Learn More</a>
                  <a className="btn" onClick={this.gotoPricing.bind(this)}>View Pricing</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="get-started" ref={this.learnmore}>
            <p>Don’t settle for basic numerical analysis. Immediately turn your raw data files into a complete visual explanation of your performance, and leverage your reports to take your game to the next level. </p>
            <a className="btn" onClick={this.gotoPricing.bind(this)}>Get Started</a>
          </div>
          <section className="commen_product">
            <div className="">
              <div className="heading-title">
                <h2>No More Spreadsheets.</h2>
                <p>Upload your raw data and advanced reports appear in PDF format just seconds later, allowing you to analyze and share immediately.</p>
              </div>
              <div className="video-bg">
                <Player playsInline src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/analyzr.mp4" />
              </div>
            </div>
          </section>
          <div className="analyzr-info-section">
            <div className="common-class">
              <div className="heading-logo-title">
                <h4>For Players.</h4>
                <p>Collect data. Get recruited.</p>
                <p>Use your data like never before. AnalyzR can transform any data you’ve collected in training or in games into the most powerful recruiting tool on the market.</p>
                <a className="btn" onClick={this.gotoPricing.bind(this)}>Get Started</a>
              </div>
              <div className="for_way">
                <img src={imagesArr.forplayer} />
              </div>
            </div>
            <div className="common-class">
              <div className="for_way">
                <img src={imagesArr.colleges} />
              </div>
              <div className="heading-logo-title">
                <h4>For Colleges.</h4>
                <p>Maximize your investment.</p>
                <p>Pair AnalyzR with your BCTeam account to cover all of your data needs. Create opponent scouting reports. Monetize your data camps. You name it, we’ve got it. </p>
                <a className="btn" onClick={this.gotoPricing.bind(this)}>Get Started</a>
              </div>

            </div>
            <div className="common-class">
              <div className="heading-logo-title">
                <h4>For Agents.</h4>
                <p>Add another weapon to your arsenal.</p>
                <p>Gain and keep your edge with our custom agency solutions now. </p>
                <a className="btn" onClick={this.gotoPricing.bind(this)}>Get Started</a>
              </div>
              <div className="for_way">
                <img src={imagesArr.agents} />
              </div>
            </div>
            <div className="common-class">

              <div className="for_way">
                <img src={imagesArr.facilities} />
              </div>
              <div className="heading-logo-title">
                <h4>For Facilities.</h4>
                <p>Save time. Develop like never before.</p>
                <p>Spend more time training and less time crunching numbers. Collect data, assess, and improve right away. </p>
                <a className="btn" onClick={this.gotoPricing.bind(this)}>Get Started</a>
              </div>
            </div>
            <div className="common-class">
              <div className="heading-logo-title">
                <h4>For Events.</h4>
                <p>Add value. Increase revenue.</p>
                <p> Use technology to boost attendance and returns at your tournaments, camps, and showcase events. Show players how they stack up vs. their peers, and how they compare to players at the next level.</p>
                <a className="btn" onClick={this.gotoPricing.bind(this)}>Get Started</a>
              </div>
              <div className="for_way">
                <img src={imagesArr.events} />
              </div>
            </div>
          </div>
          <div className="brand-logo">
            <div className="heading-title custom-title">
              <h2>Integrate and Celebrate.</h2>
              <p>The following data sources are compatible with AnalyzR Templates.</p>
              <p>Additional device types can be accomodated through our custom builds. </p>
            </div>
            <div className="brand-logo">
              <Link>
                <img src={imagesArr.brand_logo1} alt="brand_logo1" />
              </Link>
              <Link>
                <img src={imagesArr.brand_logo2} alt="brand_logo2" />
              </Link>
              <Link>
                <img src={imagesArr.brand_logo3} alt="brand_logo3" />
              </Link>
              <Link>
                <img src={imagesArr.brand_logo4} alt="brand_logo4" />
              </Link>
              <Link>
                <img src={imagesArr.brand_logo5} alt="brand_logo5" />
              </Link>
            </div>
          </div>
          <div className="choose-tamplate">
            <div className="heading-title custom-title">
              <h2>Choose From Over 50 Templates</h2>
              <p>Use one or multiple devices to generate dozens of templates built to digest data from all event types.</p>
            </div>
            <div className="choose-img-part">
              <div className="choose-img-info">
                <h4>Scouting</h4>
                <img src={imagesArr.scouting} />
              </div>
              <div className="choose-img-info">
                <h4>Bullpen</h4>
                <img src={imagesArr.bullpen} />
              </div>
              <div className="choose-img-info">
                <h4>Game</h4>
                <img src={imagesArr.game} />
              </div>
              <div className="choose-img-info">
                <h4>Batting Practice</h4>
                <img src={imagesArr.batting_practice} />
              </div>
              <div className="choose-img-info">
                <h4>Scrimmage</h4>
                <img src={imagesArr.scrimmage} />
              </div>
              <div className="choose-img-info">
                <h4>Camp</h4>
                <img src={imagesArr.camp} />
              </div>
            </div>
          </div>
          <div className="reporting-soluction">
            <div className="heading-title custom-title">
              <h2>The Game’s Only Fully Custom Reporting Solution</h2>
            </div>
            <h5>Can’t find what you’re looking for in our templates? Just tell us what you’re looking to get out of your data. Our team will do the rest. </h5>
            <p>You think it, we build it. Don’t spend thousands of dollars or several years learning to code.</p>
            <p>Combine multiple data sources into one report. See how data from sleep tracking and weight training devices impact in-game metrics.  Add 60 times and defensive grades to custom showcase reports. The possibilities are endless.</p>
            <p>Once your custom report is created, it turns into a template that can be quickly generated as many times as needed at an affordable rate. </p>
            <div className="reporting-soluction">
              <img src={imagesArr.reporting_1} />
              <img src={imagesArr.reporting_2} />
            </div>
            <div className="text-center">
              <a className="btn" onClick={() => this.redirectTemplate()}>Get a Quote!</a>
            </div>
          </div>
          <div className="pricing">
            <div className="heading-title custom-title">
              <h2>Pricing</h2>
            </div>
            <p>AnalyzR Template Reports are priced using a credit system. Reports range from 1 to 10 credits, depending on data source, event type, and report detail</p>
            <p>Custom Reports are priced based on requested visuals, data sources, and turnaround time.  The AnalyzR team will get back to you with a quote within 48 hours of your custom request.</p>
          </div>
          <div className="credits-table" ref={this.pricingRef}>
            <div className="heading-title custom-title">
              <h2>Credits</h2>
            </div>
            <div className="table-responsive-xl">
              <table>
                <thead>
                  <tr>
                    <th>Credits</th>
                    <th>Description</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderBillingList()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Upgrade landing page end */}

        <Footer />
        <Preloader flag={loader} />
      </>
    );
  }
}

AnalyzR.propTypes = {
  getPackage: PropTypes.func.isRequired,
  buyPackage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  analyzrDetails: state.analyzrDetails,
});

export default connect(mapStateToProps, { getPackage, buyPackage })(AnalyzR);

// export default AnalyzR;

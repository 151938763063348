import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import { imagesArr } from '../../../assets/images';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import { teamList } from '../../../saga/actions/authantication/signup/player/team.action';
import commonCalls from "../../../businesslogic/commonCalls";

class teamInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamListArr: [],
            teamArr: {
                parentTeam: ""
            },
            filter: {
                page: 1,
                limit: 10,
                search: ""
            },
            loader: false,
            selectedId: ""
        }
        this.commonCall = new commonCalls();
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        if (this.state.filter.search.length >=2) {
            this.setLoader(true);
            this.props.teamList(this.state.filter);
        }
        this.setTeam();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.teamDetails &&
            nextProps.teamDetails.searchPlayerTeamRes &&
            nextProps.teamDetails.searchPlayerTeamData
        ) {
            nextProps.teamDetails.searchPlayerTeamRes = false;
            console.log("nextProps from team:----", nextProps.teamDetails.searchPlayerTeamData);
            if (this.state.filter.search) {
                return this.setState({
                    teamListArr: nextProps.teamDetails.searchPlayerTeamData.parentTeamList,
                }, () => {
                    nextProps.teamDetails.searchPlayerTeamData = null;
                    this.setLoader(false);
                })
            }

            if (nextProps.teamDetails.searchPlayerTeamData.hasOwnProperty('myParentTeam') && nextProps.teamDetails.searchPlayerTeamData.myParentTeam.length !== 0) {

                if (this.state.filter.page === 1) {
                    for (let i = 0; i < nextProps.teamDetails.searchPlayerTeamData.myParentTeam.length; i++) {
                        nextProps.teamDetails.searchPlayerTeamData.parentTeamList.unshift(nextProps.teamDetails.searchPlayerTeamData.myParentTeam[i])
                    }

                    this.setState((prevState) => ({
                        ...prevState,
                        teamArr: {
                            parentTeam: this.state.teamArr.parentTeam.length === 0 ? nextProps.teamDetails.searchPlayerTeamData.myParentTeam[0]._id : this.state.teamArr.parentTeam,
                        },
                        selectedId: this.state.teamArr.parentTeam.length === 0 ? nextProps.teamDetails.searchPlayerTeamData.myParentTeam[0]._id : this.state.selectedId,
                    }))
                }

                let val = nextProps.teamDetails.searchPlayerTeamData.parentTeamList;

                let temp = []
                val.map((item) => {
                    temp.push(item)
                });
                var joined = this.state.teamListArr.concat(temp);
                console.log("joined:-", joined);

                this.setState((prevState) => ({
                    ...prevState,
                    teamListArr: joined,
                    filter: {
                        ...prevState.filter,
                        page: this.state.filter.page + 1
                    }
                }), () => {
                    nextProps.teamDetails.searchPlayerTeamData = null;
                    this.setLoader(false);
                })
            } else {
                let val = nextProps.teamDetails.searchPlayerTeamData.parentTeamList;

                let temp = []
                val.map((item) => {
                    temp.push(item)
                });
                var joined = this.state.teamListArr.concat(temp);
                console.log("joined:-", joined);

                this.setState((prevState) => ({
                    ...prevState,
                    teamListArr: joined,
                    filter: {
                        ...prevState.filter,
                        page: this.state.filter.page + 1
                    }
                }), () => {
                    nextProps.teamDetails.searchPlayerTeamData = null;
                    this.setLoader(false);
                })
            }


        }
    }

    setTeam = () => {
        this.commonCall.getStorage("team", data => {
            if (data !== null) {
                console.log("flag::data:---", data);
                this.setState({
                    selectedId: data,
                    teamArr: {
                        parentTeam: data
                    },
                })
            }
        });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "parentTeam") {
            this.setState((prevState) => ({
                ...prevState,
                teamArr: {
                    ...prevState.teamArr,
                    [name]: value
                },
                selectedId: value
            }));
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    page: 1,
                    limit: 10,
                    search: value
                },
            }), () => {
                if (value.length !== 0) {
                    if (value.length >= 2) {
                        this.setLoader(true);
                        this.props.teamList(this.state.filter);
                    }
                } else {
                    this.state.teamListArr = [];
                    this.props.teamDetails.searchPlayerTeamData = null;
                    // this.setLoader(true);
                    // this.props.teamList(this.state.filter);
                }
            })
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.teamList(this.state.filter);
    }

    renderTeamList = () => {
        return (
            this.state.teamListArr.length !== 0 ?
                this.state.teamListArr.map((list, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={list.teamLogo ? list.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{list.name}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="radio"
                                    id="parentTeam"
                                    value={list._id}
                                    name="parentTeam"
                                    onChange={(e) => this.onHandleChange(e)}
                                    checked={list._id === this.state.selectedId ? true : false}
                                />
                                <span for="parentTeam"></span>
                            </div>
                        </div>
                    );
                }) :
                <h4 style={{ textAlign: 'center' }}>No Data Found</h4>
        )
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setLoader(true);
            this.props.teamList(this.state.filter);
        }
    }

    nextStep = () => {
        if (this.state.teamArr.parentTeam) {
            this.commonCall.setStorage("team", this.state.teamArr.parentTeam, flag => {
                if (flag) {
                    this.props.history.push('/player-coachInfo');
                }
            });
        } else {
            this.commonCall.errorMessage("Please select team name");
        }
    }

    cancelStep = () => {
        localStorage.removeItem("team");
        this.props.history.push('/player-dashboard');
    }

    render() {
        const { filter } = this.state
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="before-login leaveinfo">
                    <div className="container">
                        <div className="white-bg playes-team">
                            <div className="title-name mb-0">
                                <h2 className="mb-0">
                                    Select Your Team
                                </h2>
                            </div>
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your team" name="search" value={filter.search} onChange={(e) => this.onHandleChange(e)} />
                                        {/* <Link onClick={this.onSearch} className="serch-btn"><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="scrollbar_team" onScroll={this.getScroll}>
                                <div className="select_team ml-0">
                                    {this.renderTeamList()}
                                </div>
                            </div>
                            <div className="flex-align">
                                <Link className="btn gray" onClick={() => this.cancelStep()}>Cancel</Link>
                                <Link className="btn" onClick={() => this.nextStep()}>Next</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

teamInfo.propTypes = {
    teamList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    teamDetails: state.teamPlayerDetails
});

export default connect(mapStateToProps, { teamList })(teamInfo);

import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken, checkIsValidFanToken } from '../../../helpers/authToken';
import {
    PLAYER_BG_REQUEST,
    PLAYER_BG_SUCCESS,
    PLAYER_BG_ERROR,

    LEAVE_TEAM_REQUEST,
    LEAVE_TEAM_SUCCESS,
    LEAVE_TEAM_ERROR,
} from '../../../constants/view/player/dashboard.const';
import histrory from '../../../../History';

export const saveBGphoto = (data,token) => dispatch => {
    console.log("data:--", data);
    token ? checkIsValidFanToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: PLAYER_BG_REQUEST });
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/cardBG`,
        data: data,
        headers: {
            'x-auth-token': token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(photo => {
        console.log("photo:---- ", photo);
        dispatch({
            type: PLAYER_BG_SUCCESS,
            payload: photo.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: PLAYER_BG_ERROR, error: error.response.data.message })
        }
    });
};

export const leaveTeam = () => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: LEAVE_TEAM_REQUEST });
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/leaveTeam`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(leave => {
        console.log("leave:---- ", leave);
        dispatch({
            type: LEAVE_TEAM_SUCCESS,
            payload: leave.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: LEAVE_TEAM_ERROR, error: error.response.data.message })
        }
    });
};


import {
  TEAMINFO_SUCCESS,
  TEAMINFO_REQUEST,
  TEAMINFO_ERROR,

  REASSIGN_REQUEST,
  REASSIGN_SUCCESS,
  REASSIGN_ERROR,

  POST_REASSIGN_ERROR,
  POST_REASSIGN_SUCCESS
} from "../../../../constants/view/coach/profile/teamInfo.const";

const initialState = {
  teamInfoData: null,
  teamInfoError: null,

  reInviteReq: false,
  reInviteData: null,
  reInviteError: null,

  postReassignData: null,
  postReassignError: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEAMINFO_SUCCESS:
      return {
        ...state,
        teamInfoData: action.payload,
      };
      break;
    case TEAMINFO_ERROR:
      return {
        ...state,
        teamInfoData: action.error,
      };
      break;

    case REASSIGN_REQUEST:
      return {
        ...state,
        reInviteReq: true,
      };
    case REASSIGN_SUCCESS:
      return {
        ...state,
        reInviteData: action.payload,
      };
    case REASSIGN_ERROR:
      return {
        ...state,
        reInviteError: action.error,
      };
    case POST_REASSIGN_SUCCESS:
      return {
        ...state,
        postReassignData: action.payload,
      };
    case POST_REASSIGN_ERROR:
      return {
        ...state,
        postReassignError: action.error,
      };
    default:
      return state;
      break;
  }
}

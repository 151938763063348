import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../containers/header';
import Footer from '../../containers/footer';

import { getPlayerInfo, getPlayerList } from '../../saga/actions/common/accountCreation.action';

import PlayerList from './playerList';
import Player from './claimForm/player';
import Preloader from '../../components/preloader';
import Gradian from './claimForm/gradian';
import { GET_PLAYER_INFO_SUCCESS } from '../../saga/constants/common/accountCreation.const';
import AddPlayerClaim from "../../views/popup/addPlayerClaim";
import { GET_PLAYER_LIST_SUCCESS } from '../../saga/constants/common/accountCreation.const';
import LoginUser from '../../views/popup/loginUser';


const initialState = {
    rosterReq: {
        rosterTeamId: "",
        currentUserId: ""
    },
    rosterAllDeatils: null,
    claimForm: 1,
    claimAccount: false,
    selectedRow: null,
    loader: false,
    openRoster: false,
    canCreatePlayer: false,
    canCreateParent: "",
    justNowCreateParent: false,
    openLogin: false
};

const reducer = (state, payload) => ({ ...state, ...payload });
function AccountCreation() {
    const dispatch = useDispatch();
    const [state, setState] = useReducer(reducer, initialState);
    const { playerList: _PL } = useSelector((state) => state.accountCreation)

    useEffect(() => {
        let url = new URL(document.location);
        let rid = url.pathname.split("/")[2];
        let canCreatePlayer = url.pathname.split("/")[3]
        setState({
            ...state,
            rosterReq: {
                rosterTeamId: rid,
                currentUserId: localStorage.getItem("userID") !== null ? localStorage.getItem("userID") : ""
            },
            loader: true,
        })
        getPlayerList({ rosterTeamId: rid, currentUserId: localStorage.getItem("userID") !== null ? localStorage.getItem("userID") : "" })(dispatch);
    }, [])


    useEffect(() => {
        if (_PL) {
            setState({
                ...state,
                rosterAllDeatils: _PL.result,
                selectedRow: state.justNowCreateParent ? _PL.result.players.find(_ => _._id === state.selectedRow._id) : state.selectedRow,
                loader: false,
                justNowCreateParent: false,
                canCreatePlayer: _PL.result.allowCreatenew
            })
            dispatch({
                type: GET_PLAYER_LIST_SUCCESS,
                payload: null
            })
        }
    }, [_PL])

    const claimForm = (tab, index) => {
        setState({
            ...state,
            claimForm: tab,
        })
        dispatch({
            type: GET_PLAYER_INFO_SUCCESS,
            payload: null
        })

        let isParent = state.selectedRow?.hasOwnProperty("parentId") ? state.selectedRow?.parentId?.length != 0 ? true : false : false;
        if ((tab == 2 || tab == 3) && (!isParent || !state.selectedRow?.parentId[index])) {
            return null;
        } else {
            let personalReq = {
                ObjectId: tab === 1 ? state.selectedRow.mapUser._id : state.selectedRow.parentId[index].user,
                type: tab === 1 ? 1 : 2,
                _id: state.selectedRow._id
            }
            getPlayerInfo(personalReq)(dispatch);
        }

        //version 1
        // let personalReq = {
        //     ObjectId: tab === 1 ? state.selectedRow.mapUser._id : state.selectedRow.parentId[index].user,
        //     type: tab === 1 ? 1 : 2,
        //     _id: state.selectedRow._id
        // }
        // getPlayerInfo(personalReq)(dispatch);
    }

    const showGradian1 = () => { return state.selectedRow.parentId ? state.selectedRow.parentId[0] ? true : false : false }
    const showGradian2 = () => { return state.selectedRow.parentId ? state.selectedRow.parentId[1] ? true : false : false }

    const calculate_age_between = (dob1) => {
        let today = new Date(),
            //birthay has 'Dec 25 1998'
            dob = new Date(dob1),
            //difference in milliseconds
            diff = today.getTime() - dob.getTime(),
            //convert milliseconds into years
            years = Math.floor(diff / 31556736000),
            //1 day has 86400000 milliseconds
            days_diff = Math.floor((diff % 31556736000) / 86400000),
            //1 month has 30.4167 days
            months = Math.floor(days_diff / 30.4167),
            days = Math.floor(days_diff % 30.4167);
        if (years <= 16 && months === 0 && days === 0) {
            return true
        } else {
            return false
        }
        // var ageDifMs = Date.now() - new Date(dob1).getTime();
        // var ageDate = new Date(ageDifMs); // miliseconds from epoch
        // return (Math.abs(ageDate.getUTCFullYear() - 1970) >= 15 && Math.abs(ageDate.getUTCFullYear() - 1970) <= 18);
    }

    const onHandleClose = () => {
        document.body.classList.remove("modal-open");
        setState({
            ...state,
            openRoster: !state.openRoster
        })
    };

    const closeLogin = () => {
        setState({
            ...state,
            openLogin: false
        })
    }

    const oldUserAuthenticationHandler = () => {
        let url = new URL(document.location);
        let rid = url.pathname.split("/")[2];
        getPlayerList({ rosterTeamId: rid, currentUserId: localStorage.getItem("userID") !== null ? localStorage.getItem("userID") : "" })(dispatch);
        setState({
            ...state,
            openLogin: false,
            claimAccount : false,
            loader: true,
        })
    }

    return (
        <>
            <Header />
            <div className='account_creation'>
                {state.claimAccount &&
                    <>
                        <div className='overlay_div'></div>
                        <div className='right_section active'>
                            <div className='cross-btn' onClick={() => setState({ ...state, claimAccount: false })}>
                                <i class="fa fa-times"></i>
                            </div>
                            <div className='account_tab'>

                                {state?.selectedRow?.mapUser ? <a style={{ pointerEvents: state.selectedRow?.claimAcc ? 'none' : 'auto' }} class={`btn trans mr-15 ${state?.claimForm == 1 ? "active" : ""}  `} onClick={() => claimForm(1)}>Player</a> : ""}
                                <a style={{ pointerEvents: state?.selectedRow?.hasOwnProperty('parentId') ? state?.selectedRow?.parentId[0] ? state?.selectedRow?.parentId[0]?.claimAcc ? 'none' : 'auto' : 'auto' : 'auto' }} class={`btn trans mr-15 ${state?.claimForm == 2 ? "active" : ""} `} onClick={() => claimForm(2, 0)}>Guardian 1</a>
                                <a style={{ pointerEvents: state?.selectedRow?.hasOwnProperty('parentId') ? state?.selectedRow?.parentId[1] ? state?.selectedRow?.parentId[1]?.claimAcc ? 'none' : 'auto' : 'auto' : 'auto' }} class={`btn trans ${state?.claimForm == 3 ? "active" : ""} `} onClick={() => claimForm(3, 1)}>Guardian 2</a>


                                {/* version 2
                            {calculate_age_between(state.canCreateParent) ?
                                showGradian1() ? <a class={`btn trans mr-15 ${state.claimForm == 1 ? "active" : ""}  `} onClick={() => claimForm(1)}>Player</a> : ""
                                : <a class={`btn trans mr-15 ${state.claimForm == 1 ? "active" : ""}  `} onClick={() => claimForm(1)}>Player</a>}
                            {showGradian1() ? <a class={`btn trans mr-15 ${state.claimForm == 2 ? "active" : ""} `} onClick={() => claimForm(2, 0)}>Guardian 1</a> :
                                calculate_age_between(state.canCreateParent) ? <a class={`btn trans mr-15 ${state.claimForm == 2 ? "active" : ""} `} onClick={() => claimForm(2, 0)}>Guardian 1</a> : ""
                            }
                            {showGradian2() && <a class={`btn trans ${state.claimForm == 3 ? "active" : ""} `} onClick={() => claimForm(3, 1)}>Guardian 2</a>} */}
                            </div>
                            {state.claimForm == 1 &&
                                <>
                                    {calculate_age_between(state.canCreateParent) ?
                                        showGradian1() ? <Player state={state} setState={setState} /> : <p className='account_creation_error'>Please fill out Guardian 1 info before proceeding.</p>
                                        : <Player state={state} setState={setState} />
                                    }
                                </>
                            }

                            {state.claimForm == 2 &&
                                <Gradian state={state} setState={setState} />
                            }

                            {state.claimForm == 3 &&
                                <Gradian state={state} setState={setState} />
                            }
                        </div>


                        {state.openLogin &&
                            <LoginUser
                                flag={state.openLogin}
                                state={state}
                                close={closeLogin}
                                oldUserAuthenticationHandler={oldUserAuthenticationHandler}
                            />
                        }

                    </>
                }

                <div className='container d-flex'>
                    <div className='left_section'>
                        <div className='team_info'>
                            <div className='logo'><img src={state.rosterAllDeatils?.teamLogo ? state.rosterAllDeatils?.teamLogo : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'} /></div>
                            <div className='team_desc'>
                                <p><b>Team Name : </b>{state.rosterAllDeatils?.rosterName ? state.rosterAllDeatils?.rosterName : "-"}</p>
                                <p><b>Age Group : </b>{state.rosterAllDeatils?.ageGroup ? (state.rosterAllDeatils?.ageGroup == 19 ? '18+' : state.rosterAllDeatils?.ageGroup) : "-"}</p>
                                <p><b>Sport : </b>{state.rosterAllDeatils?.["game-type"] ? state.rosterAllDeatils?.["game-type"] == 1 ? "Baseball" : "Softball" : "-"}</p>
                            </div>
                        </div>
                        <PlayerList data={state.rosterAllDeatils} setState={setState} state={state} />
                        {state.canCreatePlayer && <button className='btn light-green mt-20' onClick={() => setState({ ...state, openRoster: true })}>Add New</button>}
                    </div>
                </div>
            </div>
            <Footer />
            <Preloader flag={state.loader} />
            {state.openRoster && (
                <AddPlayerClaim
                    flag={state.openRoster}
                    close={onHandleClose}
                    rosterAllDeatils={state.rosterAllDeatils}
                    state={state}
                    setState={setState}
                />
            )}
        </>
    )
}

export default AccountCreation

import axios from "axios";
import { API_Url, Auth_Coach_Key, checkIsValidCoachToken } from "../../../../helpers/authToken";
import {
  FACILITYINFO_ERROR,
  FACILITYINFO_SUCCESS,
} from "../../../../constants/view/coach/profile/facilityInfo.const";
import {
  REGISTERINFO_SUCCESS
} from "../../../../constants/view/coach/profile/personalInfo.const";
import histrory from '../../../../../History';


export const getFacility = () => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/coach/facility`,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: FACILITYINFO_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: FACILITYINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

export const postFacility = (link) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/facility`,
    data: link,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: FACILITYINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

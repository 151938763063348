import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    GET_TEAM_SEARCH_REQUEST,
    GET_TEAM_SEARCH_SUCCESS,
    GET_TEAM_SEARCH_ERROR,

    UP_TEAM_IMAGE_REQUEST,
    UP_TEAM_IMAGE_SUCCESS,
    UP_TEAM_IMAGE_ERROR,

    ADD_TEAM_REQUEST,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_ERROR,

    SAVE_TEAM_REQUEST,
    SAVE_TEAM_SUCCESS,
    SAVE_TEAM_ERROR,

    SAVE_CLAIM_REQUEST,
    SAVE_CLAIM_SUCCESS,
    SAVE_CLAIM_ERROR,

    GET_CLAIM_REQUEST,
    GET_CLAIM_SUCCESS,
    GET_CLAIM_ERROR,

    GET_TEAM_FLOW_REQUEST,
    GET_TEAM_FLOW_SUCCESS,
    GET_TEAM_FLOW_ERROR,
} from '../../../../constants/authantication/signup/coach/team.const';
import histrory from '../../../../../History';

export const teamList = (data) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_TEAM_SEARCH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parentTeam/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(searchteam => {
        dispatch({
            type: GET_TEAM_SEARCH_SUCCESS,
            payload: searchteam.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_TEAM_SEARCH_ERROR, error: error.response.data.message })
    });
};

export const uploadTeamImage = (fileObj) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: UP_TEAM_IMAGE_REQUEST })

    let formData = new FormData();
    formData.append('file', fileObj.imgFile);

    axios({
        method: 'POST',
        url: `${API_Url}file/upload?documentType=${fileObj.documentType}`,
        data: formData,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    }).then(image => {
        dispatch({
            type: UP_TEAM_IMAGE_SUCCESS,
            payload: image.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: UP_TEAM_IMAGE_ERROR, error: error.response.data.message })
    });
};

export const addTeam = (data) => dispatch => {
    let fdata;
    if (data["shoplink"].length !== 0) {
        fdata = {
            name: data["name"],
            teamLogo: data["teamLogo"],
            description: data["description"],
            teamRecord: data["yourscore"] + "-" + data["opponentscore"],
            "social-link": {
                twt: data["twt"],
                insta: data["insta"]
            },
            shoplink: data["shoplink"],
            isMarchandise: false
        }
    } else {
        fdata = {
            name: data["name"],
            teamLogo: data["teamLogo"],
            description: data["description"],
            teamRecord: data["yourscore"] + "-" + data["opponentscore"],
            "social-link": {
                twt: data["twt"],
                insta: data["insta"]
            },
            isMarchandise: data["isMarchandise"]
        }
    }
    checkIsValidCoachToken(histrory)
    dispatch({ type: ADD_TEAM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parentTeam/create`,
        data: fdata,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(team => {
        dispatch({
            type: ADD_TEAM_SUCCESS,
            payload: team.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: ADD_TEAM_ERROR, error: error.response.data.message })
    });
};

export const saveClaim = (data) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_CLAIM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/teamclaim`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(team => {
        console.log("team:---- ", team);
        dispatch({
            type: SAVE_CLAIM_SUCCESS,
            payload: team.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_CLAIM_ERROR, error: error.response.data.message })
    });
};

export const saveTeam = (data) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_TEAM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/team`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(team => {
        dispatch({
            type: SAVE_TEAM_SUCCESS,
            payload: team.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_TEAM_ERROR, error: error.response.data.message })
    });
};

export const getClaim = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_CLAIM_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/teamclaim`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(getclaim => {
        dispatch({
            type: GET_CLAIM_SUCCESS,
            payload: getclaim.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_CLAIM_ERROR, error: error.response.data.message })
    });
};

export const getTeamFlow = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_TEAM_FLOW_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/team`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(getflow => {
        dispatch({
            type: GET_TEAM_FLOW_SUCCESS,
            payload: getflow.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_TEAM_FLOW_ERROR, error: error.response.data.message })
    });
};
import React, { Component } from 'react';
import { imagesArr } from "../../../../../assets/images";

export default class socialMediaLink extends Component {
    render() {
        const { dataList } = this.props;
        return (
            <div className="web_description_fullborderbox">
                {/* <div className="web_desc_title">
                    <h3>Event Company</h3>
                </div> */}
                <div className="web_description_box_20">
                    <div className="companyLogos">
                        <img src={dataList?.dsCompanies && dataList.dsCompanies.companyLogo && dataList.dsCompanies.companyLogo[0].filePath} alt="cpmpany logo" />
                    </div>
                    <div className="company_social_link">
                        <a target="_blank" class="fblink nav-link" href={dataList?.dsCompanies && dataList.dsCompanies.facebookUrl}>
                            <i class="fab fa-facebook-f"></i>
                            {dataList?.dsCompanies && dataList.dsCompanies.facebookLink}
                        </a>
                        <a target="_blank" class="twtlink nav-link" href={dataList?.dsCompanies && dataList.dsCompanies.twitterUrl}>
                            <i class="fab fa-twitter"></i>
                            {dataList?.dsCompanies && dataList.dsCompanies.twitterLink}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

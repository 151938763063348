import React, {
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";

import commonCalls from "../../../businesslogic/commonCalls";
import PhoneVerify from '../../popup/phoneVerify';
import Preloader from '../../../components/preloader';
import ImageCrop from '../../popup/imageCrop';
import AddLinkedPlayer from "../../popup/addLinkedPlayer";
import Confirmation from '../../../views/popup/confirmation';

import { getCountryCode, getZipcodeInfo, postFileUpload } from "../../../saga/actions/common/api.action";
import { parentList, editParent, disconnectParent } from "../../../saga/actions/views/player/profile/parentInfo.action";

import { ZIPCODE_SUCCESS } from '../../../saga/constants/common/api.const';
import { ADD_PARENT_SUCCESS, DISCONNECT_PARENT_SUCCESS, EDIT_LINKED_PARENT_ERROR, EDIT_LINKED_PARENT_SUCCESS } from "../../../saga/constants/view/player/profile/parentInfo.const";
import { fetchInfo } from "../../../saga/actions/views/player/profile/personalInfo.action";
import Geosuggest from "react-geosuggest";
import { StatesObj } from "../../../businesslogic/content";

const initialState = {
    profileUrl: "",
    personalLogoId: "",
    cropPopup: false,
    renderFile: "",
    imgObj: "",
    loader: false,
    calOpen: false,
    addPlayerPopup: false,
    playerOption: [],
    listPlayer: [],
    verifyPopup: false,
    verifyToken: "",
    uid: "",
    confirmation: "",
    allowDiscon : true,
    record: {
        fullName: "",
        dob: "",
        phone: "",
        email: "",
        bio: "",
        zipcode: "",
        city: "",
        cCode: "+1",
        state: "",
        gameType: "",
        "in-high-school": "no",
        _id: ""
    },
    cancleRecord:{
        fullName: "",
        dob: "",
        phone: "",
        email: "",
        bio: "",
        zipcode: "",
        city: "",
        cCode: "+1",
        state: "",
        gameType: "",
        "in-high-school": "no",
        _id: ""
    },
    isEditZipcode: false
};

const reducer = (state, payload) => ({ ...state, ...payload });

function LinkedPlayer() {
    const [personal, setPersonal] = useReducer(reducer, initialState);
    const commonCall = new commonCalls();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const dispatch = useDispatch();
    const {
        profileUrl,
        personalLogoId,
        cropPopup,
        renderFile,
        imgObj,
        loader,
        calOpen,
        addPlayerPopup,
        playerOption,
        listPlayer,
        verifyPopup,
        verifyToken,
        uid,
        confirmation,
        record
    } = personal
    const { countryCodeData: _CC } = useSelector((state) => state.api);
    const { saveParent: _PARENT } = useSelector((state) => state.parentInfo);

    const { playerList: _LP, disconnectParent: _DISCONNECT, editParent: _EDIT, editParentError: _ERROR } = useSelector((state) => state.parentInfo);
    const { headData: _USER } = useSelector((state) => state.headerData);
    const { zipcodeData: _zipcode, fileUploadData: _LOGO } = useSelector(
        (state) => state.api
    );
    const { getInfoData: _PD } = useSelector((state) => state.playerPersonalInfo);



    useEffect(() => {
        setLoader(true);
        parentList()(dispatch);
        getCountryCode()(dispatch);
        fetchInfo()(dispatch);
    }, []);

    useEffect(() => {
        let arr = [];
        _LP.map((item, i) => {
            arr.push({ value: item._id, label: item.fname + ' ' + item.lname })
        });
        setPersonal({
            listPlayer: _LP,
            playerOption: _LP.length !== 0 ? arr : []
        })
        setLoader(false);
    }, [_LP])

    useEffect(() => {
        if (_LOGO) {
            console.log("logo:--", _LOGO);
            setPersonal({
                personalLogoId: _LOGO._id,
                profileUrl: _LOGO.filePath,
                imgObj: "",
                cropPopup: false,
                renderFile: ""
            });
            document.body.classList.remove('modal-open');
            setLoader(false);
        }
    }, [_LOGO]);

    useEffect(() => {
        if (_zipcode) {
            if (_zipcode.flag) {
                setPersonal({
                    ...personal,
                    record: {
                        ...personal.record,
                        state: _zipcode.result.state,
                        city: _zipcode.result.city,
                        zipcode: _zipcode.result.zipcodestr
                    }
                });
                setLoader(false);
            } else {
                commonCall.errorMessage(_zipcode.message);
                setPersonal({
                    ...personal,
                    record: {
                        ...personal.record,
                        state: "",
                        city: "",
                    }
                });
                dispatch({
                    type: ZIPCODE_SUCCESS,
                    payload: null,
                });
                setLoader(false);
            }
        }
    }, [_zipcode]);

    useEffect(() => {
        if (_DISCONNECT) {
            if (_DISCONNECT.flag) {
                document.body.classList.remove('modal-open');
                commonCall.successMessage(_DISCONNECT.message);
                parentList()(dispatch);
                setLoader(false);
                setPersonal({
                    ...personal,
                    confirmation: false,
                    record: {
                        fullName: "",
                        dob: "",
                        phone: "",
                        email: "",
                        bio: "",
                        zipcode: "",
                        city: "",
                        cCode: "+1",
                        state: "",
                        gameType: "",
                        "in-high-school": "no",
                        _id: ""
                    }
                })
                dispatch({
                    type: DISCONNECT_PARENT_SUCCESS,
                    payload: null
                })
            }
        }
        if (_EDIT) {
            if (_EDIT.flag) {
                commonCall.successMessage(_EDIT.message);
                setLoader(false);
                if (_EDIT.result.hasOwnProperty('mobile_no_update')) {
                    setPersonal({
                        verifyPopup: true,
                        verifyToken: _EDIT.result.updatePhoneToken,
                        isEditZipcode: false
                    });
                } else {
                    setPersonal({
                        isEditZipcode: false
                    })
                    parentList()(dispatch);
                }
                dispatch({
                    type: EDIT_LINKED_PARENT_SUCCESS,
                    payload: null
                })

            } else {
                commonCall.errorMessage(_EDIT.message)
                setLoader(false);
                dispatch({
                    type: EDIT_LINKED_PARENT_SUCCESS,
                    payload: null
                })
            }
        }
        if (_ERROR) {
            commonCall.errorMessage(_ERROR.message)
            setLoader(false);
            dispatch({ type: EDIT_LINKED_PARENT_ERROR, error: null })
        }
        if (_PARENT) {
            if (_PARENT.flag) {
                parentList()(dispatch);
                dispatch({
                    type: ADD_PARENT_SUCCESS,
                    payload: null
                })
            }
        }
    }, [_DISCONNECT, _EDIT, _ERROR, _PARENT])

    useEffect(() => {
        if (_USER) {
            console.log("_USER:---", _USER);
            if (_USER.flag) {
                setPersonal({
                    ...personal,
                    uid: _USER.result._id
                });
                setLoader(false);
            }
        }
    }, [_USER]);

    useEffect(() => {
        if (_PD) {
            setPersonal({
                ...personal,
                allowDiscon: calculate_age(_PD.dob)
            });
        }
    }, [_PD])

    const calculate_age = (dob1) => {
        var ageDifMs = Date.now() - new Date(dob1).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970) <= 16;
    }

    const setLoader = (val) => {
        setPersonal({
            loader: val
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    setPersonal({
                        ...personal,
                        record: {
                            ...personal.record,
                            [name]: value,
                        }
                    });
                } else return false;
            } else return false;
        } if (e.target.name === "weight") {
            const re = /^[0-9\b]+$/;
            if (e.target.value.length <= 3) {
                if (re.test(e.target.value) || e.target.value.length <= 0) {
                    setPersonal({
                        ...personal,
                        record: {
                            ...personal.record,
                            [name]: value,
                        }
                    });
                }
            }
        }
        else if (e.target.name === "height") {
            const foot = e.target.value.split("'");
            const inches = e.target.value.split(`"`);
            if (foot[1] && inches[0]) {
                const inch = parseInt(foot[1].split(`"`)[0])
                if (inches[1]) {
                    if (inches[1] === "0" || inches[1] === "1" && inch === 1) setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'${inch}${inches[1]}"` } })
                } else {
                    if (inch > 9 && inch < 12) {
                        if (record.height.includes(`"`) && !e.target.value.includes(`"`)) setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'${foot[1][0]}"` } })
                        else setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'${inch}"` } })
                    } else if (inch >= 0 && inch <= 9) {
                        const prev = record.height.split("'")[1]
                        if (record.height.includes(`"`) && !(prev.includes('10') || prev.includes('11'))) setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'` } })
                        else if (prev.includes('10') || prev.includes('11')) setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'${foot[1]}` } })
                        else setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'${foot[1]}"` } })
                    } else if (isNaN(inch)) setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${foot[0]}'` } })
                }
            }
            else if (inches[0] && record.height.includes(`"`) && inches[1] !== "") { setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `` } }) }
            else if (foot[0] && !record.height.includes("'") && foot[1] !== "") { setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${e.target.value}'` } }) }
            else if (foot[0] && record.height.includes("'") && foot[1] !== "") { setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `` } }) }
            else { setPersonal({ ...personal, record: { ...personal.record, [e.target.name]: `${e.target.value}` } }) }

        } else {
            setPersonal({
                ...personal,
                record: {
                    ...personal.record,
                    [name]: value,
                }
            });
        }
    };

    const changePhon = (e) => {
        const { name, value } = e.target;
        const re = /^[0-9\b]+$/;
        if (value.length <= 12) {
            if (re.test(value) || value.length <= 0) {
                setPersonal({
                    ...personal,
                    record: {
                        ...personal.record,
                        [name]: value,
                    }
                });
            }
        }
    }

    const changeImage = (event) => {
        let file = event.target.files[0];
        console.log("file:--", URL.createObjectURL(file));
        if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png") {
            return commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
        }
        setPersonal({
            ...personal,
            imgObj: file,
            cropPopup: true,
            renderFile: URL.createObjectURL(file)
        });
    };

    const handleClose = () => {
        document.body.classList.remove('modal-open');
        setPersonal({
            cropPopup: false
        });
    }

    const onHadleObject = (obj) => {
        setLoader(true);
        postFileUpload(obj.file, 16)(dispatch);
    }

    const handleSumbit = () => {
        let post_FI;
        let name = record.fullName.split(" ")
        post_FI = {
            parentId: record._id,
            fname: name[0],
            lname: name[1] ? name[1] : "",
            email: record.email,
            phone: record.phone,
            cCode: "+1",
            profilePic: personalLogoId,
            dob: moment(record.dob).toDate(),
            "game-type": record.gameType,
            address: {
                city: record.city,
                state: record.state,
                zipcode: record.zipcode,
            },
            'in-high-school': record['in-high-school']
        };
        handlePostData(post_FI);
    };

    const handlePostData = async (data) => {
        console.log("data:---", data);
        try {
            if (validator.current.allValid()) {
                setLoader(true);
                await editParent(data, "Coach")(dispatch);
            } else {
                validator.current.showMessages(true);
                forceUpdate(1);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const closeImage = () => {
        setPersonal({
            profileUrl: "",
        });
    }

    const closeVerify = () => {
        document.body.classList.remove('modal-open');
        setPersonal({
            verifyPopup: false,
        });
        parentList()(dispatch);
    }

    const getZip = (zipcode) => {
        if (zipcode.length !== 0) {
            setLoader(true);
            getZipcodeInfo(zipcode)(dispatch);
        }
    }

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const onChange = (dob) => {
        setPersonal({
            ...personal,
            record: {
                ...personal.record,
                dob: dob,
            }
        });
    }

    const onlyNumber = event => {
        const keycode = event.which;
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };

    const closeAddPlayer = () => {
        document.body.classList.remove('modal-open');
        setPersonal({
            addPlayerPopup: false
        });
    }

    const renderFeild = (id) => {
        let fill = listPlayer.find(p => p._id === id);
        fill.city = fill.address.city;
        fill.state = fill.address.state;
        fill.zipcode = fill.address.zipcode;
        fill.fullName = fill.fname + " " + fill.lname;
        fill.dob = fill.hasOwnProperty("dob") ? fill.dob ? new Date(fill.dob) : "" : "";
        setPersonal({ ...personal, record: fill, profileUrl: fill.profilePic && fill.profilePic.filePath })
    }

    const onHandleClose = () => {
        document.body.classList.remove('modal-open');
        setPersonal({
            confirmation: false
        })
    }

    const nextHandle = () => {
        setLoader(true);
        disconnectParent({ playerId: record._id }, "Coach")(dispatch)
    }

    const renderParentList = () =>{
            return (
                personal.listPlayer.map((list, i) => {
                    return (
                        <>
                            <tr>
                                {/* <th>{i + 1}</th> */}
                                <td>{list.fname + " " + list.lname}</td>
                                <td>{list.email}</td>
                                <td> 
                                <img src={imagesArr.eyeopen} onClick={() => renderFeild(list._id)} alt="eye" /></td>
                            </tr>
                        </>
                    );
                }) 
            )
    }

    const handleSelectGeoSuggest = (place) => {
        if (place) {
          var componentForm = {
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name',
          };
    
          for (var i = 0; i < place.gmaps.address_components.length; i++) {
            var addressType = place.gmaps.address_components[i].types[0];
            if (componentForm[addressType]) {
              var val = place.gmaps.address_components[i][componentForm[addressType]];
              if (val)
                componentForm[addressType] = val
            }
          }
          var location = {
            streetAddress: place.gmaps.formatted_address,
            city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
            stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
            postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
            latitude: place.location.lat || 42.2538048,
            longitude: place.location.lng || -83.5735806
            }

            if (location.stateRegion) {
                setPersonal({
                    ...personal,
                    record: {
                        ...personal.record,
                        state: location.stateRegion,
                        city: location.city,
                        zipcode: location.postalCode
                    },
                    isEditZipcode : location.postalCode ? false : true
                });
            } else {
                commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
                setPersonal({
                    ...personal,
                    record: {
                        ...personal.record,
                        state: "",
                        zipcode: "",
                    },
                    isEditZipcode : false
                });
            }
        } else {
            setPersonal({
                ...personal,
                record: {
                    ...personal.record,
                    city: "",
                }
            });
        }
      }

    const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    return (
        <>
            {record._id ?
                <section className="before-dashboard">
                    <div className="white-bg">
                        <form>
                            <div className="w-100">
                                <div className="linkplayer-filter justify-content-end">
                                    {/* <div className="form-group mb-0 ">
                                        <Select
                                            value={record._id ? playerOption.find(p => p.value === record._id) : null}
                                            options={playerOption}
                                            onChange={(e) => renderFeild(e)}
                                        />
                                    </div> */}
                                    <div className="d-flex">
                                        <button className="btn btn-label" onClick={(e) => { e.preventDefault(); setPersonal({ addPlayerPopup: true }) }}>Add Parent</button>
                                        {!personal.allowDiscon && record._id && <Link className="btn btn-label" onClick={(e) => setPersonal({ confirmation: true })}>Disconnect Parent</Link>}
                                    </div>
                                </div>
                            </div>
                            <div className="edit-50">
                                <div className="w-50">

                                    <label className="common-lable">Upload profile pic</label>
                                    <div className="dragdrop-img">
                                        <div className="create_event_fileUpload file_custom_upload text-center">
                                            <i className="fas fa-cloud-upload-alt"></i>
                                            {!profileUrl && <p>Click to upload profile picture</p>}
                                            {/* .heic,.HEIC, */}
                                            <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={changeImage} />
                                            {
                                                profileUrl &&
                                                <div className="preview_img">
                                                    <i className="fas fa-times" onClick={closeImage}> </i>
                                                    <img className="preview_img_inner" src={profileUrl} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <span className="validMsg">{validator.current.message("Profile pic", profileUrl, "required")} </span> */}
                                    <label className="common-lable">Full name</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" name="fullName" placeholder="Full Name" value={record.fullName} onChange={handleChange} disabled />
                                    </div>
                                    <span className="validMsg">
                                        {validator.current.message("Full name", record.fullName, "required")}
                                    </span>
                                    <label className="common-lable">Date of Birth</label>
                                    <div className="form-group mb-0">
                                        <div className="bc_relative">
                                            <DatePicker
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                            {months.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                    </div>
                                                )}
                                                selected={record.dob}
                                                maxDate={moment().subtract(1, 'days').toDate()}
                                                onChangeRaw={(e) => handleDateChangeRaw(e)}
                                                onChange={(e) => onChange(e)}
                                                placeholderText="Enter your birth date"
                                                disabled={true}
                                                open={calOpen}
                                            />
                                            <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" onClick={() => setPersonal({ ...personal, calOpen: !calOpen })} />
                                        </div>
                                    </div>
                                    {/* <span className="validMsg">
                    {validator.current.message("Date of Birth", record.dob, "required")}
                  </span> */}
                                    <label className="common-lable">Mobile number</label>
                                    <div className="form-group mb-0">
                                        <div className="svg_icon">
                                            <div className="input-group">
                                                <div className="counrty-no">
                                                    <select className="custom-select" name="cCode" value={record.cCode} onChange={handleChange}>
                                                        <option value="+1" selected={record.cCode === "+1" ? "selected" : ""}>
                                                            +1
                                                        </option>
                                                        {/* {_CC &&
                              _CC.length !== 0 &&
                              _CC.map((e, index) => {
                                return (<option key={index} value={e.code}>{e.code}</option>)
                              })
                            } */}
                                                    </select>
                                                </div>
                                                <input type="text" className="form-control" id="usr" placeholder="Enter mobile number" name="phone" value={record.phone} onChange={changePhon} onKeyPress={onlyNumber} />
                                                <img src={imagesArr.smartphone} alt="eye" />
                                            </div>
                                            {/* <span className="validMsg">
                        {validator.current.message("Mobile number", record.phone, "required|phone")}
                      </span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-50">
                                    <div className="lable-btn-group">
                                        <label className="common-lable">Email address</label>
                                    </div>
                                    <div className="form-group mb-0">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="usr"
                                            placeholder="Enter email address"
                                            value={record.email}
                                            onChange={handleChange}
                                            disabled
                                            name="email"
                                        />
                                    </div>
                                    <span className="validMsg">
                                        {validator.current.message("Email", record.email, "required")}
                                    </span>

                                    <label className="common-lable">
                                        City <span>( To Look Up State and Zipcode )</span>
                                    </label>
                                    <div className="form-group mb-0">
                                        <div className="serch-box">
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                id="zipcode"
                                                placeholder="Zip Code"
                                                value={record.zipcode}
                                                name="zipcode"
                                                onChange={handleChange}
                                            />
                                            <Link onClick={() => getZip(record.zipcode)} className="serch-btn"> Next</Link> */}
                                            
                                            <Geosuggest
                                                // ref={el => this._geoSuggest = el}
                                                onSuggestSelect={handleSelectGeoSuggest}
                                                initialValue={record.city || ""}
                                                autoComplete="off"
                                                types={['(cities)']}
                                            />
                                            <span className="validMsg">
                                                {validator.current.message("City", record.city, "required")}
                                            </span>
                                        </div>
                                    </div>

                                    <label className="common-lable">State</label>
                                    <div className="form-group mb-0">
                                        <select className="custom-select" name="state" value={record.state} onChange={handleChange}>
                                            <option value="">N/A</option>
                                            {Object.keys(StatesObj).map((key) => {
                                                return (
                                                    <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                                                )
                                            })}
                                        </select>
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            placeholder="State"
                                            value={record.state}
                                            onChange={handleChange}
                                            name="state"
                                            disabled
                                        /> */}
                                        <span className="validMsg">
                                            {validator.current.message("State", record.state, "required")}
                                        </span>
                                    </div>


                                    <label className="common-lable">Zip Code</label>
                                    <div className="form-group mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="zipcode"
                                            placeholder="Zip code"
                                            value={record.zipcode}
                                            onChange={handleChange}
                                            name="zipcode"
                                            disabled={personal.isEditZipcode ? false : true}
                                        />
                                        <span className="validMsg">
                                            {validator.current.message("Zipcode", record.zipcode, "required")}
                                        </span>
                                    </div>
                               
                                </div>
                            </div>
                            <div className="flex-align">
                                <button className="btn gray" onClick={() => setPersonal({ ...personal, record: personal.cancleRecord, profileUrl: "" })}>Cancel</button>
                            </div>
                            {/* <div className="flex-align">
                                <Link className="btn gray" to="/player-dashboard">Cancel</Link>
                                <Link className="btn light-green" onClick={handleSumbit}>
                                    Save
                                </Link>
                            </div> */}
                        </form>
                    </div>
                </section>
                :
                <div>
                    <section className="before-dashboard">
                        <div className="white-bg">
                           
                               
                                    {/* <div className="w-50">
                                        <Select
                                            value={record._id ? playerOption.find(p => p.value === record._id) : null}
                                            options={playerOption}
                                            onChange={(e) => renderFeild(e)}
                                            className="mb-15"
                                        />
                                    </div> */}
                                    
                                        <div className="text-right">
                                            <button className="btn" onClick={(e) => { e.preventDefault(); setPersonal({ addPlayerPopup: true }) }}>Add Parent</button>
                                        </div>
                                    
                                    <div className="w-100">
                                        {personal.listPlayer.length ?
                                            <>
                                                <div className="table-responsive-xl ">
                                                    <table className="table parent-info-table">
                                                        <thead>
                                                            <tr>
                                                                {/* <th scope="col">No.</th> */}
                                                                <th scope="col">Parent Name</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {renderParentList()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <p className="text-center p-50 mb-0">No parent profile linked. Click Add Parent to link.</p>
                                        }
                                    </div>
                           
                        </div>
                    </section>
                </div>}
            <Preloader flag={loader} />
            <ImageCrop
                imgObj={imgObj}
                flag={cropPopup}
                close={handleClose}
                renderImg={renderFile}
                getObj={onHadleObject}
            />
            {
                verifyPopup &&
                <PhoneVerify
                    flag={verifyPopup}
                    close={closeVerify}
                    token={verifyToken}
                    uid={uid}
                    load={setLoader}
                    type="Player"
                    for="parent"
                    email={record.email}
                    phone={record.cCode + record.phone}
                />
            }
            {addPlayerPopup &&
                <AddLinkedPlayer
                    flag={addPlayerPopup}
                    close={closeAddPlayer}
                    setLoader={setLoader}
                    type="Player"
                />}

            {confirmation && <Confirmation
                flag={confirmation}
                close={onHandleClose}
                type="disconnect"
                name={record.fullName}
                closeCancel={onHandleClose}
                closeNext={nextHandle}
            />}
        </>
    )
}

export default LinkedPlayer;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCard, fetchPaymentInfo } from "../../saga/actions/common/billing.action";
import commonCalls from "../../businesslogic/commonCalls";
import Preloader from "../../components/preloader";
import InputMask from 'react-input-mask';


class addNewCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendCard: {
                fullname: "",
                number: "",
                exp_month: "",
                exp_year: "",
                cvc: "",
                isDefault: false,
            },
            loader: false,
            mask: "9999 9999 9999 9999"
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }


    componentWillReceiveProps = (nextProps) => {
        if (nextProps._ADDCARD && nextProps._ADDCARD.addCardData) {
            console.log("_ADDCARD--->", nextProps._ADDCARD.addCardData)
            if (nextProps._ADDCARD.addCardData.flag) {
                this.commonCall.successMessage(nextProps._ADDCARD.addCardData.message);
                nextProps._ADDCARD.addCardData = null;
                if (this.props.type === "Coach") {
                    this.props.fetchPaymentInfo("Coach");
                }
                else {
                    this.props.fetchPaymentInfo("Player");
                }
                this.setState({
                    sendCard: {
                        fullname: "",
                        number: "",
                        exp_month: "",
                        exp_year: "",
                        cvc: "",
                        isDefault: false,
                    }
                })
                this.setLoader(false);


            } else {
                this.setLoader(false);
                this.commonCall.errorMessage(nextProps._ADDCARD.addCardData.message);
                nextProps._ADDCARD.addCardData = null;
            }
        }
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        if (name === "fullname") {
            this.setState((prevState) => ({
                ...prevState,
                sendCard: {
                    ...prevState.sendCard,
                    [name]: value
                }
            }));
        };

        if (name === "number") {
            if (/^3[47]/.test(value)) {
                this.setState({
                    mask: "9999 999999 99999"
                })
            } else {
                this.setState({
                    mask: "9999 9999 9999 9999"
                })
            }
            this.setState((prevState) => ({
                ...prevState,
                sendCard: {
                    ...prevState.sendCard,
                    [name]: value
                }
            }));
        }

        if (name === "cvc") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 4) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendCard: {
                            ...prevState.sendCard,
                            [name]: value
                        }
                    }));
                }
            }
        }

        if (name === "exp_month") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 2 && value <= 12 && value !== '00' && value !== '0') {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendCard: {
                            ...prevState.sendCard,
                            [name]: value
                        }
                    }));
                }
            }
        }

        if (name === "exp_year") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 4) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendCard: {
                            ...prevState.sendCard,
                            [name]: value
                        }
                    }));
                }
            }
        }

        if (name === "isDefault") {
            this.setState((prevState) => ({
                ...prevState,
                sendCard: {
                    ...prevState.sendCard,
                    [name]: !this.state.sendCard.isDefault
                }
            }));
        }

    }

    saveData = () => {
        if (this.validator.allValid()) {

            if (this.renderCardName(this.state.sendCard.number) === "Visa" ||
                this.renderCardName(this.state.sendCard.number) === "Mastercard" ||
                this.renderCardName(this.state.sendCard.number) === "Discover") {
                if (this.state.sendCard.number.replace(/[^0-9 ]/g, "").trim().length >= 19) {
                    this.setLoader(true);
                    this.state.sendCard.number = this.state.sendCard.number.replace(/[^0-9 ]/g, "").trim()
                    console.log("this.state.sendCard", this.state.sendCard);
                    if (this.props.type === "Coach") {
                        this.props.addCard(this.state.sendCard, "Coach");
                    }
                    else {
                        this.props.addCard(this.state.sendCard, "Player");
                    }
                    this.props.close();
                } else {
                    this.commonCall.errorMessage("Enter valid card number");
                }
            } else {
                if (this.renderCardName(this.state.sendCard.number) === "AMEX") {
                    if (this.state.sendCard.number.replace(/[^0-9 ]/g, "").trim().length >= 17) {
                        this.setLoader(true);
                        this.state.sendCard.number = this.state.sendCard.number.replace(/[^0-9 ]/g, "").trim()
                        console.log("this.state.sendCard", this.state.sendCard);
                        if (this.props.type === "Coach") {
                            this.props.addCard(this.state.sendCard, "Coach");
                        }
                        else {
                            this.props.addCard(this.state.sendCard, "Player");
                        }
                        this.props.close();
                    } else {
                        this.commonCall.errorMessage("Enter valid card number");
                    }
                }
            }



        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    renderCardName = (number) => {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard
        var re = new RegExp("^5[1-8][0-9][0-5]|2[1-5][0-9][0-5]");
        if (number.match(re) != null)
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";
    }

    render() {
        const { flag, type } = this.props;
        const { sendCard, loader } = this.state;
        if (flag) {
            document.body.classList.add('modal-open');
        }



        return (
            <>
                <section className={`common-modal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog">
                        <div className="modal-content pay-billing-addcard">
                            <div className="green-header modal-header">
                                <h3>Add New Card</h3>
                                <div className="modal_close" ><img src={imagesArr.white_modal_close} alt="modal_close" onClick={() => this.props.close()} /></div>
                            </div>
                            <div className="modal-body">
                                <div className="team-body-inner">
                                    <label className="common-lable">Card Holder Name</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" placeholder="Enter Card holder name" name="fullname" value={sendCard.fullname} onChange={this.handleChange} />
                                        <span className="validMsg">{this.validator.message('Full Name', sendCard.fullname, 'required')}</span>
                                    </div>
                                    <label className="common-lable">Card Number</label>
                                    <div className="form-group mb-0">
                                        <div className="svg_icon">
                                            <div className="input-group">
                                                <InputMask {...this.props} className="form-control" mask="9999 9999 9999 9999 9999" name="number" value={sendCard.number} onChange={this.handleChange} placeholder="Enter Card Number" />
                                                {/* <input type="text" className="form-control" id="usr" placeholder="Enter Card number" name="number" value={sendCard.number} onChange={this.handleChange} /> */}
                                                <img src={imagesArr.credit_card} alt="card" />

                                            </div>
                                            <span className="validMsg">{this.validator.message('Card Number', sendCard.number, 'required')} </span>
                                        </div>
                                    </div>
                                    {/* <label className="common-lable">Expires Date</label>
                                    <div className="form-group mb-0">
                                        <input type="date" className="form-control" id="usr" placeholder="06/2024" name="expireDate" value={this.state.expireDate} onChange={this.handleChange} />
                                    </div> */}
                                    <div className="form-group mb-0">
                                        <div className="select_box_inner">
                                            <label className="common-lable">Expiration Month</label>
                                            <div className="form-group mb-0">
                                                <input type="text" name="exp_month" value={sendCard.exp_month} onChange={this.handleChange} className="form-control" id="usr" placeholder="Enter Month" />
                                            </div>
                                            <span className="validMsg">{this.validator.message('Month', sendCard.exp_month, 'required')}</span>
                                        </div>
                                        <div className="select_box_inner">
                                            <label className="common-lable">Exp. Year</label>
                                            <div className="form-group mb-0">
                                                <input type="text" name="exp_year" value={sendCard.exp_year} onChange={this.handleChange} className="form-control" id="usr" placeholder="Enter Year" />
                                            </div>
                                            <span className="validMsg">{this.validator.message('Year', sendCard.exp_year, 'required')}</span>
                                        </div>
                                    </div>

                                    <label className="common-lable">CVV</label>
                                    <div className="form-group mb-0">
                                        <input type="password" className="form-control" id="usr" placeholder="Enter CVV" name="cvc" value={sendCard.cvc} onChange={this.handleChange} />
                                    </div>
                                    <span className="validMsg">{this.validator.message('cvv', sendCard.cvc, 'required')}</span>

                                    <div className="form-group-checkbox">
                                        <input type="checkbox" id="html" name="isDefault" value={sendCard.isDefault} onChange={this.handleChange} />
                                        <label for="html">Make this my default payment method</label>
                                    </div>
                                </div>
                                <div className="flex-align next-button">
                                    <Link className="btn light-green" onClick={this.saveData}>Save Card</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

addNewCard.propTypes = {
    addCard: PropTypes.func.isRequired,
    fetchPaymentInfo: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    _ADDCARD: state.billingInfo,
});


export default connect(mapStateToProps, { addCard, fetchPaymentInfo })(addNewCard);

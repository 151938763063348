import React, { Component } from 'react';
import { imagesArr } from "../../assets/images";
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../businesslogic/commonCalls";

class addPlayerManually extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sendReq: {
        fname: "",
        lname: "",
        email: ""
      }
    };
    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount = () => {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
      console.log("this.props.record:--", this.props.record);
      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          fname: this.props.record ? this.props.record.fname : "",
          lname: this.props.record ? this.props.record.lname : "",
          email: this.props.record ? this.props.record.email : ""
        },
      }));
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      sendReq: {
        ...prevState.sendReq,
        [name]: value
      },
    }));
  }

  saveRoster = () => {
    if (this.validator.allValid()) {
      if (this.props.type === "add") {
        if (!!this.props.rosterList.find((item) => item.email === this.state.sendReq.email)) {
          return this.commonCall.errorMessage("Email already exits.")
        }
      } else {
        if (!!this.props.rosterList.find((e, j) => { if (j !== this.props.indexVal) { if (e.email === this.state.sendReq.email) { return true } else return false } })) {
          return this.commonCall.errorMessage("Email already exits.")
        }
      }
      this.props.handleSaveRoster(this.state.sendReq);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const { sendReq } = this.state;
    const { flag, type } = this.props;

    return (
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>{type === "add" ? "Add" : "Edit"} Player</h3>
              <div className="modal_close" onClick={this.props.close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="team-body-inner">
                <div className="flex-align row">
                  <div className="form-group">
                    <label className="common-lable">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      name="fname"
                      placeholder="First Name"
                      value={sendReq.fname}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <span className="validMsg">{this.validator.message("First Name", sendReq.fname, "required")}</span>
                  </div>
                  <div className="form-group">
                    <label className="common-lable">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      name="lname"
                      placeholder="Last Name"
                      value={sendReq.lname}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <span className="validMsg">{this.validator.message("Last Name", sendReq.lname, "required")}</span>
                  </div>
                </div>
                <div className="form-group">
                  <label className="common-lable">Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="usr"
                    name="email"
                    placeholder="Enter email address"
                    value={sendReq.email}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <span className="validMsg">{this.validator.message("Email", sendReq.email, "required|email")}</span>
                </div>
              </div>
              <div className="flex-align next-button">
                <Link className="btn light-green" onClick={() => this.saveRoster()}>
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default addPlayerManually;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import { imagesArr } from '../../../../assets/images';
import Shop from "../../../../views/company/components/common/shop";
import ProudSponsors from "../../../../views/company/components/common/proudSponsors";
import { getOptionFilter, bracketFilter, findDivisionArr } from '../../../../saga/actions/views/company/pwb_pws/recapInfo.action';
import { Bracket } from "react-tournament-bracket";
import Preloader from "../../../../components/preloader";
import moment from "moment";
import commonCalls from "../../../../businesslogic/commonCalls";
import { getEventDetails } from '../../../../saga/actions/common/eventDetails.action';

class pwsBracketInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            eventObj: [],
            filterObject: [],
            bracketData: [],
            sendReq: {
                eventId: "",
                ageGroup: "",
                bracket: "",
                divisionId: "",
                gameType: "",
                pool: ""
            },
            divisionArr: [],
            poolArr: [],
            sheduleLive: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        // this.setState({
        //     eventObj: localStorage.getItem("recapInfo") ? JSON.parse(localStorage.getItem("recapInfo")) : [],
        //     sendReq: {
        //         ...this.state.sendReq,
        //         eventId: localStorage.getItem("recapInfo") ? JSON.parse(localStorage.getItem("recapInfo"))._id : "",
        //     }
        // }, () => {
        //     this.increment();
        //     this.props.getOptionFilter({ eventId: this.state.eventObj._id }, 'PWS');
        // })

        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        this.getEventDetails(eventId);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            console.log("nextProps.eventDetails:--", nextProps.eventDetails.getDetailsData);
            let fdata = nextProps.eventDetails.getDetailsData.result.data[0];
            if (nextProps.eventDetails.getDetailsData.flag) {
                this.setState({
                    eventObj: fdata ? fdata : [],
                    sendReq: {
                        ...this.state.sendReq,
                        eventId: fdata ? fdata._id : "",
                    }
                }, () => {
                    nextProps.eventDetails.getDetailsData = null;
                    this.setLoader(true);
                    this.props.getOptionFilter({ eventId: this.state.eventObj._id }, 'PWS');
                })

           
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.pwsOptionReq &&
            nextProps.eventRecapInfo.pwsOptionData
        ) {
            console.log("pwsOptionData:--", nextProps.eventRecapInfo.pwsOptionData);
            nextProps.eventRecapInfo.pwsOptionReq = false;
            if (nextProps.eventRecapInfo.pwsOptionData.flag) {
                this.setState({
                    filterObject: nextProps.eventRecapInfo.pwsOptionData.result,
                    sheduleLive: this.state.eventObj.sheduleLive
                }, () => {
                    nextProps.eventRecapInfo.pwsOptionData = null;
                    this.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.pwsOptionData.message);
                nextProps.eventRecapInfo.pwsOptionData = null;
                this.decrement();
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.pwsBracketReq &&
            nextProps.eventRecapInfo.pwsBracketData
        ) {
            console.log("pwsBracketData:--", nextProps.eventRecapInfo.pwsBracketData);
            nextProps.eventRecapInfo.pwsBracketReq = false;
            if (nextProps.eventRecapInfo.pwsBracketData.flag) {
                this.setState({
                    bracketData: nextProps.eventRecapInfo.pwsBracketData.result,
                    sheduleLive: nextProps.eventRecapInfo.pwsBracketData.result.sheduleLive
                }, () => {
                    nextProps.eventRecapInfo.pwsBracketData = null;
                    this.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.pwsBracketData.message);
                nextProps.eventRecapInfo.pwsBracketData = null;
                this.decrement();
            }
        }

        if (nextProps.eventRecapInfo &&
            nextProps.eventRecapInfo.pwsBracketDivReq &&
            nextProps.eventRecapInfo.pwsBracketDivData
        ) {
            console.log("pwsBracketDivData:--", nextProps.eventRecapInfo.pwsBracketDivData);
            nextProps.eventRecapInfo.pwsBracketDivReq = false;
            if (nextProps.eventRecapInfo.pwsBracketDivData.flag) {
                this.setState({
                    divisionArr: nextProps.eventRecapInfo.pwsBracketDivData.result.data,
                    poolArr: nextProps.eventRecapInfo.pwbBracketDivData.result.poolList
                }, () => {
                    nextProps.eventRecapInfo.pwsBracketDivData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventRecapInfo.pwsBracketDivData.message);
                nextProps.eventRecapInfo.pwsBracketDivData = null;
                this.setLoader(false);
            }
        }
    }

    componentDidUpdate = () => {
        document.querySelectorAll("g > svg").forEach(i => {
            var div1Paras = i.getElementsByTagName('g');
            if (div1Paras.length === 3) {
                // aa statc svg jyare 2 j round hoy like semifnial and final tyare j aa batava nu 
                // i.outerHTML = '<svg width="400" height="130" viewBox="0 0 200 82" x="950" y="195"><text x="100" y="8" text-anchor="middle" style="fill: rgb(131, 146, 165); font-size: 11px; display: none; font-family: &quot;Helvetica Medium&quot;;">2/17/2022</text><rect x="0" y="12" width="200" height="22.5" fill="white" rx="3" ry="3"></rect><g><rect x="0" y="12" height="22.5" width="200" fill-opacity="0" style="fill: white; stroke-width: 1; stroke-linejoin: round; stroke: rgb(150, 207, 4);"><title>A1</title></rect><g><defs><clipPath id="0d535569-0fb1-45e7-8a85-c9fd7cd5ec24"><rect x="0" y="12" width="165" height="22.5"></rect></clipPath></defs><g clip-path="url(#0d535569-0fb1-45e7-8a85-c9fd7cd5ec24)"><text x="5" y="28" style="fill: rgb(131, 146, 165); font-size: 13px; font-family: &quot;Helvetica Medium&quot;; font-style: italic;"><title>A1</title>Winner Team</text></g></g><text x="185" y="28" text-anchor="middle" style="font-size: 13px; display: none; font-family: &quot;Helvetica Medium&quot;;"></text></g></svg>'

                // aa static design jyare 3 round hoy ne pachi final batava no hoy tyare batava ni
                i.outerHTML = '<svg width="400" height="130" viewBox="0 0 200 82" x="1400" y="455"><text x="100" y="8" text-anchor="middle" style="fill: rgb(131, 146, 165); font-size: 11px; display: none; font-family: &quot;Helvetica Medium&quot;;">2/17/2022</text><rect x="0" y="12" width="200" height="11" fill="white" rx="3" ry="3"></rect><rect x="0" y="12" width="200" height="22.5" fill="white" rx="3" ry="3"></rect><g><rect x="0" y="12" height="22.5" width="200" fill-opacity="0" style="fill: white; stroke-width: 1; stroke-linejoin: round; stroke: rgb(150, 207, 4);"><title>A1</title></rect><g><defs><clipPath id="3611ceda-e153-4b4f-882d-4d0710c297f5"><rect x="0" y="12" width="165" height="22.5"></rect></clipPath></defs><g clip-path="url(#3611ceda-e153-4b4f-882d-4d0710c297f5)"><text x="5" y="28" style="fill: rgb(131, 146, 165); font-size: 13px; font-family: &quot;Helvetica Medium&quot;; font-style: italic;"><title>A1</title>Winner Team</text></g></g><text x="185" y="28" text-anchor="middle" style="font-size: 13px; display: none; font-family: &quot;Helvetica Medium&quot;;"></text></g><line x1="0" y1="34.5" x2="200" y2="34.5" style="stroke: black; stroke-width: 0;"></line></svg>';
            }
        })

        document.querySelectorAll("g > rect").forEach(i => {
            i.style.fill = "white"
            i.style.strokeWidth = "1"
            i.style.strokeLinejoin = "round"
            i.style.stroke = "rgb(150, 207, 4)"
            return i;
        })

        document.querySelectorAll("g > path").forEach(i => {
            i.style.stroke = "rgb(150, 207, 4)"
            return i;
        })
    }

    getEventDetails = (eventId) => {
        if (localStorage.getItem("webglToken") !== null) {
            this.props.getEventDetails(eventId, true);
        } else {
            this.props.getEventDetails({
                eventId: eventId
            }, false);
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    increment = () => {
        this.setLoader(true);
    }

    decrement = () => {
        this.setLoader(false);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: value
            }
        }, () => {
            if (name === "ageGroup") {
                this.state.sendReq.divisionId = "";
                this.setLoader(true);
                this.props.findDivisionArr(this.state.sendReq.eventId, this.state.sendReq.ageGroup, 'PWS')
            }
        })
    }

    filterBracket = () => {
        this.increment();
        this.props.bracketFilter(this.state.sendReq, 'PWS');
    }

    submitData = () => {
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                gameType: "",
                pool: ""
            }
        }, () => {
            this.filterBracket()
        })
    }

    getBackType = (type) => {
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                bracket: type
            }
        }, () => {
            this.filterBracket();
        })
    }

    onHandleChangeGameType = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: value
            }
        }, () => {
            if (this.state.sendReq.gameType === "Matchup" && this.state.poolArr.length === 0) {
                this.filterBracket();
            } else {
                this.setState({
                    sendReq: {
                        ...this.state.sendReq,
                        pool: ""
                    }
                }, () => {
                    this.filterBracket();
                })
            }
            // if (this.state.sendReq.gameType !== "Matchup" && this.state.poolArr.length !== 0) {
            //     this.setState({
            //         sendReq: {
            //             ...this.state.sendReq,
            //             pool: ""
            //         }
            //     })
            //     this.filterBracket();
            // } else {
            //     if (this.state.poolArr.length === 0) {
            //         this.filterBracket();
            //     }
            // }
        })
    }

    onHandleChangePool = (e) => {
        const { name, value } = e.target;
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                [name]: value
            }
        }, () => {
            this.filterBracket();
        })
    }

    clearAll = () => {
        this.setState({
            sendReq: {
                ...this.state.sendReq,
                gameType: "",
                pool: ""
            }
        }, () => {
            this.filterBracket();
        })
    }

    render() {
        const { loader, eventObj, filterObject, bracketData, sendReq, divisionArr, poolArr, sheduleLive } = this.state;

        return (
            <>
                <Header />
                <div className="bracket-info">
                    <div className="hotelinfo-section event-main">
                        <div className="team-banner-section">
                            <img src={eventObj && eventObj.eventImages && eventObj.eventImages.filePath ? eventObj.eventImages.filePath : imagesArr.eventinfo_banner} alt="event banner" />
                            <div className="banner-details">
                                <div className="event_card_logo">
                                    <img src={eventObj && eventObj.eventLogo && eventObj.eventLogo.filePath ? eventObj.eventLogo.filePath : imagesArr.event_info_bannerlogo} alt="event logo" />
                                </div>
                                <div className="event_card_titles">
                                    <h3>{eventObj && eventObj.eventTitle}</h3>
                                    <div className="event_card_dates">
                                        <p className="map_icon">{eventObj && eventObj.location && eventObj.location.streetAddress}</p>
                                        <p className="date_icon">{eventObj && moment(eventObj.startDate).format("DD MMM YYYY") + " - " + moment(eventObj.endDate).format("DD MMM YYYY")}</p>
                                        <p className="team_icons">{eventObj && eventObj.teamLimit} Teams</p>
                                    </div>
                                </div>
                            </div>
                            <div className="arrow">
                                <img src={imagesArr.round_arrow} />
                            </div>
                            <div className="shap">
                                <img src={imagesArr.shap} />
                            </div>
                        </div>
                    </div>
                    <div className="white_bg">
                        <div className="container">
                            <div className={`${eventObj.sheduleLive ? "champions-Score" : "no-schedule"} `}>
                                {
                                    eventObj.sheduleLive ?
                                        <div className="select_btn">
                                            <div className="row">
                                                <div className={`${divisionArr.length !== 0 ? "w-25" : "w-50"}`}>
                                                    <select name="ageGroup" value={sendReq.ageGroup} onChange={(e) => this.handleChange(e)} className="custom-select">
                                                        <option value="" disabled>Select age</option>
                                                        {
                                                            filterObject?.ageGroup &&
                                                            filterObject.ageGroup.map((opt, i) => {
                                                                return (
                                                                    <option value={opt}>{opt}U</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {

                                                    divisionArr.length !== 0 &&
                                                    <div className="w-25">
                                                        <select name="divisionId" value={sendReq.divisionId} onChange={(e) => this.handleChange(e)} className="custom-select">
                                                            <option value="" disabled>Select divisions</option>
                                                            {divisionArr.map((opt, i) => {
                                                                return (
                                                                    <option value={opt._id}>{opt.title}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                }
                                                <div className="w-50">
                                                    <button className="btn submit-arrow" onClick={() => this.submitData()} disabled={(sendReq.ageGroup && divisionArr.length === 0) ? false : sendReq.divisionId ? false : true}>Submit <span><i className="fa fa-arrow-right"></i></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                    // <p className="text-center">Revised Schedule Coming Soon</p>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        eventObj.sheduleLive && sheduleLive ?
                            <>
                                <div className="poll_standings">
                                    <div className="container">
                                        <h1 className="section_title">Pool Standings</h1>
                                        <div className="recap-table-info">
                                            <div className="table-responsive-xl">
                                                {
                                                    bracketData.poolStanding &&
                                                        bracketData.poolStanding.length !== 0 ?
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th colspan="4" className="text-center">{sendReq.ageGroup}U</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Team</th>
                                                                    <th>W</th>
                                                                    <th>L</th>
                                                                    <th>T</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {bracketData.poolStanding.map((pool) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{pool.team}</td>
                                                                            <td>{pool.win}</td>
                                                                            <td>{pool.lose}</td>
                                                                            <td>{pool.ties}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <p className='text-center'>No data found</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-20">
                                    <div className="container">
                                        <h1 className="section_title">Schedule</h1>
                                        {
                                            bracketData.Schedule &&
                                            bracketData.Schedule.length !== 0 &&
                                            <div className="champions-Score schedule-filter p-0 mt-20">
                                                <div className="select_btn mt-0">
                                                    <div className="row">
                                                        <div className="w-50">
                                                            <button className="btn submit-arrow" onClick={() => this.clearAll()}>See All</button>
                                                        </div>
                                                        <div className={`${poolArr.length !== 0 && sendReq.gameType === "Matchup" ? "w-25" : "w-50"}`}>
                                                            <select name="gameType" value={sendReq.gameType} onChange={(e) => this.onHandleChangeGameType(e)} className="custom-select">
                                                                <option value="" disabled>Game Type</option>
                                                                <option value="All">ALL</option>
                                                                <option value="Bracket">Bracket</option>
                                                                <option value="Matchup">Matchup</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            poolArr.length !== 0 &&
                                                            sendReq.gameType === "Matchup" &&
                                                            <div className="w-25">
                                                                <select name="pool" value={sendReq.pool} onChange={(e) => this.onHandleChangePool(e)} className="custom-select">
                                                                    <option value="" disabled>Pool</option>
                                                                    {
                                                                        poolArr.map((pl) => {
                                                                            return (
                                                                                <option value={pl._id}>{pl.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="recap-table-info mb-60">
                                            <div className="table-responsive-xl">
                                                {
                                                    bracketData.Schedule &&
                                                        bracketData.Schedule.length !== 0 ?
                                                        bracketData.Schedule.map((tbl, i) => {
                                                            return (
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th colspan="10" className="text-center">{tbl.date}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Division</th>
                                                                            <th>Start time {bracketData.timeZoneType ? `(${bracketData.timeZoneType})` : ""}</th>
                                                                            <th>Location</th>
                                                                            <th>Team</th>
                                                                            <th colspan="2">Score</th>
                                                                            <th>Team</th>
                                                                            {/* <th colspan="2">Score</th> */}
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            tbl.match.map((match) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{match.division ? match.division : "-"}</td>
                                                                                        <td>{match.startTime ? match.startTime : "-"}</td>
                                                                                        <td>{match.location ? match.location + ", " + match.field : "-"}</td>
                                                                                        <td>{match.home_team ? match.home_team : "-"}</td>
                                                                                        <td>{match.homeTeamScore ? match.homeTeamScore : match.homeTeamScore === 0 ? "0" : "-"}</td>
                                                                                        <td>{match.awayTeamScore ? match.awayTeamScore : match.awayTeamScore === 0 ? "0" : "-"}</td>
                                                                                        <td>{match.away_team ? match.away_team : "-"}</td>
                                                                                        {/* <td>{match.awayTeamScore ? match.awayTeamScore : "-"}</td> */}
                                                                                        {/* <td>{match.awayTeamScore ? match.awayTeamScore : "-"}</td> */}
                                                                                        <td></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            )
                                                        })

                                                        :
                                                        <p className='text-center'>No data found</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bracket_chart_section">
                                    <div className="container">
                                        <h1 className="section_title">Bracket</h1>
                                        <div className="bracket-tab-btn">
                                            <div className="select-type text-center">
                                                <button type="button" className={`btn trans ${sendReq.bracket === "Gold" ? "active" : ""}`} onClick={() => bracketData.length !== 0 ? this.getBackType("Gold") : ""}>Gold</button>
                                                <button type="button" className={`btn trans ${sendReq.bracket === "Silver" ? "active" : ""}`} onClick={() => bracketData.length !== 0 ? this.getBackType("Silver") : ""}>Silver</button>
                                                <button type="button" className={`btn trans ${sendReq.bracket === "Bronze" ? "active" : ""}`} onClick={() => bracketData.length !== 0 ? this.getBackType("Bronze") : ""}>Bronze</button>
                                            </div>
                                        </div>
                                        <div className="bracket_chart">
                                            <div className='bracket_chart_info'>
                                                <div className='full_bracket_chart text-center'>
                                                    {
                                                        bracketData.qsf &&
                                                            bracketData.qsf.length !== 0 ?
                                                            bracketData.qsf[0].key.map((match) => {
                                                                return (
                                                                    <Bracket
                                                                        game={match}
                                                                        hoveredTeamId={null}
                                                                        lineInfo={{ yOffset: -10, separation: 10, homeVisitorSpread: 15 }}
                                                                        gameDimensions={{ width: 300, height: 130 }}
                                                                        roundSeparatorWidth={50}
                                                                        svgPadding={0}
                                                                        styles={
                                                                            {
                                                                                backgroundColor: 'white',
                                                                                scoreBackground: 'white',
                                                                                winningScoreBackground: 'white',
                                                                                teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                                                                                teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                                                                                gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                                                                                gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                                                                                teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                                                                            }
                                                                        }
                                                                    />
                                                                )
                                                            })
                                                            :
                                                            bracketData.bracket &&
                                                                bracketData.bracket.length !== 0 ?
                                                                bracketData.bracket[0].key.map((match) => {
                                                                    return (
                                                                        <Bracket
                                                                            game={match}
                                                                            hoveredTeamId={null}
                                                                            lineInfo={{ yOffset: -10, separation: 10, homeVisitorSpread: 15 }}
                                                                            gameDimensions={{ width: 300, height: 130 }}
                                                                            roundSeparatorWidth={50}
                                                                            svgPadding={0}
                                                                            styles={
                                                                                {
                                                                                    backgroundColor: 'white',
                                                                                    scoreBackground: 'white',
                                                                                    winningScoreBackground: 'white',
                                                                                    teamNameStyle: { fill: '#8392A5', fontSize: 13, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                                                                                    teamScoreStyle: { fill: '', fontSize: 13, display: 'none', fontFamily: "Helvetica Medium" },
                                                                                    gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                                                                                    gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                                                                                    teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                                                                                }
                                                                            }
                                                                        />
                                                                    )
                                                                })
                                                                :
                                                                <p className='text-center'>No data found</p>
                                                    }
                                                </div>
                                                {
                                                    bracketData.teamName &&
                                                    <div className='winner_team'>
                                                        <img src={bracketData.teamLogo ? bracketData.teamLogo : imagesArr.default_user} />
                                                        <p>{bracketData.teamName}</p>
                                                        <span>Champion</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="no-schedule-age">
                                <p className="text-center">Revised Schedule Coming Soon</p>
                            </div>
                    }
                    <div className="upcomingevent_section">
                        <Shop cType="pwb" />
                    </div>
                    <ProudSponsors increment={this.increment} decrement={this.decrement} />
                </div>
                <Preloader flag={loader} />
                <Footer />
            </>
        )
    }
}

pwsBracketInfo.propTypes = {
    getOptionFilter: PropTypes.func.isRequired,
    bracketFilter: PropTypes.func.isRequired,
    findDivisionArr: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventRecapInfo: state.recapInfo,
    eventDetails: state.eventDetails,
});

export default connect(mapStateToProps, { getEventDetails, getOptionFilter, bracketFilter, findDivisionArr })(pwsBracketInfo);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import commonCalls from "../../../../businesslogic/commonCalls";
import { imagesArr } from "../../../../assets/images";
import { getSponsorList } from '../../../../saga/actions/common/api.action';
class proudSponsors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sponsorList: []
        }
    }

    componentDidMount = () => {
        this.props.increment();
        this.props.getSponsorList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.sponsorInfo &&
            nextProps.sponsorInfo.sponsorsReq &&
            nextProps.sponsorInfo.sponsorsData
        ) {
            nextProps.sponsorInfo.sponsorsReq = false;
            if (nextProps.sponsorInfo.sponsorsData.flag) {
                this.setState({
                    sponsorList: nextProps.sponsorInfo.sponsorsData.result.data
                }, () => {
                    nextProps.sponsorInfo.sponsorsData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.sponsorInfo.sponsorsData.message);
                nextProps.sponsorInfo.sponsorsData = null;
                this.props.decrement();
            }
        }
    }

    redirectLink = (link) => {
        window.open(link, "_blank");
    }

    render() {
        const { sponsorList } = this.state;
        return (
            <div className="proud-sponsors">
                <div className="container">
                    <h1 className="section_title">Proud Sponsors </h1>
                    <div className="product-sponsor-list">
                        {
                            sponsorList &&
                            sponsorList.map((list, i) => {
                                return (
                                    <div className="product-sponsor-box" key={i} onClick={() => this.redirectLink(list.link)}>
                                        <img src={list.media && list.media.filePath} alt="Proud Sponsors" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

proudSponsors.propTypes = {
    getSponsorList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    sponsorInfo: state.api,
});

export default connect(mapStateToProps, { getSponsorList })(proudSponsors);

export const COM_FEATURE_LEAGUES_REQUEST = 'COM_FEATURE_LEAGUES_REQUEST';
export const COM_FEATURE_LEAGUES_SUCCESS = 'COM_FEATURE_LEAGUES_SUCCESS';
export const COM_FEATURE_LEAGUES_ERROR   = 'COM_FEATURE_LEAGUES_ERROR';

export const COM_UP_COMING_LEAGUES_REQUEST = 'COM_UP_COMING_LEAGUES_REQUEST';
export const COM_UP_COMING_LEAGUES_SUCCESS = 'COM_UP_COMING_LEAGUES_SUCCESS';
export const COM_UP_COMING_LEAGUES_ERROR   = 'COM_UP_COMING_LEAGUES_ERROR';

export const COM_OPTION_LEAGUES_REQUEST = 'COM_OPTION_LEAGUES_REQUEST';
export const COM_OPTION_LEAGUES_SUCCESS = 'COM_OPTION_LEAGUES_SUCCESS';
export const COM_OPTION_LEAGUES_ERROR   = 'COM_OPTION_LEAGUES_ERROR';

export const COM_LEAGUES_STATE_REQUEST = 'COM_LEAGUES_STATE_REQUEST';
export const COM_LEAGUES_STATE_SUCCESS = 'COM_LEAGUES_STATE_SUCCESS';
export const COM_LEAGUES_STATE_ERROR   = 'COM_LEAGUES_STATE_ERROR';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import commonCalls from "../../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { playerList, LinkedPlayerList, AddLinkedPlayerByFan } from '../../../../saga/actions/authantication/signup/fan/parent.action';
import Select from 'react-select';
import AddLinkedPlayer from "../../../../views/popup/addLinkedPlayer";
import InviteChild from "../../../../views/popup/inviteChild";

class parent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                search: "",
                page: 0,
                limit: 0
            },
            players: [],
            optionArr: [],
            linkedObj: {
                player: "",
                team: "",
                coach: ""
            },
            loader: false,
            linkedList: [],
            addPlayerPopup: false,
            inviteChild: false,
            imgObj: "",
            cropPopup: false,
            renderFile: "",
            filePro: null,
            fileType: "",
            manageZipcode: false,
            tempData: [],
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }
    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.onRef(this);
        this.setLoader(true);
        this.props.playerList(this.state.filter);
        this.props.LinkedPlayerList();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.parentInfo &&
            nextProps.parentInfo.playerListRes &&
            nextProps.parentInfo.playerList
        ) {
            nextProps.parentInfo.playerListRes = false;
            let arr = [];
            if (nextProps.parentInfo.playerList.hasOwnProperty("docs")) {

                nextProps.parentInfo.playerList.docs.map((item, i) => {
                    arr.push({ value: item._id, label: item.fname + " " + item.lname })
                });
                this.setState((prevState) => ({
                    ...prevState,
                    players: nextProps.parentInfo.playerList.docs,
                    optionArr: arr,
                }), () => {
                    this.setLoader(false);
                    nextProps.parentInfo.playerList = null
                });

            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    players: [],
                    optionArr: [],
                }))
            }

        }

        if (nextProps.parentInfo &&
            nextProps.parentInfo.linkedPlayerRes &&
            nextProps.parentInfo.linkedPlayerData
        ) {
            nextProps.parentInfo.linkedPlayerRes = false;
            this.setState((prevState) => ({
                ...prevState,
                linkedList: nextProps.parentInfo.linkedPlayerData.data,
            }), () => {
                this.setLoader(false);
                nextProps.parentInfo.linkedPlayerData = null
            });

        }

        if (nextProps.parentInfo &&
            nextProps.parentInfo.addPlayerRes &&
            nextProps.parentInfo.addPlayerData
        ) {
            nextProps.parentInfo.addPlayerRes = false;
            this.setLoader(false);
            this.closeAddPlayer();
            if (nextProps.parentInfo.addPlayerData.flag) {
                this.commonCall.successMessage(nextProps.parentInfo.addPlayerData.message);
            } else {
                this.commonCall.errorMessage(nextProps.parentInfo.addPlayerData.message);
            }
            nextProps.parentInfo.addPlayerData = null;
            this.props.LinkedPlayerList();
        }

        if (nextProps.parentInfo &&
            nextProps.parentInfo.addLinkedPlayerRes &&
            nextProps.parentInfo.addLinkedPlayerData
        ) {
            nextProps.parentInfo.addLinkedPlayerRes = false;
            this.setLoader(false);
            if (nextProps.parentInfo.addLinkedPlayerData.flag) {
                this.setState((prevState) => ({
                    ...prevState,
                    linkedObj: {
                        player: "",
                        team: "",
                        coach: ""
                    },
                }));
                this.commonCall.successMessage(nextProps.parentInfo.addLinkedPlayerData.message);
                nextProps.parentInfo.addLinkedPlayerData = null;
            } else {
                this.commonCall.errorMessage(nextProps.parentInfo.addLinkedPlayerData.message);
                nextProps.parentInfo.addLinkedPlayerData = null;
            }
            this.props.LinkedPlayerList();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    handleChange = (option) => {
        this.setState((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                search: option
            },
            players: [],
            optionArr: []
        }), () => {
            this.setLoader(true);
            if (this.props.parentInfo.playerList) {
                this.props.parentInfo.playerList = null
            }

            this.props.playerList(this.state.filter);
        });
    };

    setOption = (val, pid) => {
        let record = this.state.players.find(e => e._id === val.value);
        this.setState((prevState) => ({
            ...prevState,
            linkedObj: {
                player: record._id,
                team: record.profile ? record.profile.team[0] ? record.profile.team[0].parentTeam : "" : "",
                coach: record.profile ? record.profile.team[0] ? record.profile.team[0].coach : "" : ""
            },
        }));
    }

    linkedPlayer = () => {
        if (this.validator.allValid()) {
            const { player } = this.state.linkedObj;
            this.props.AddLinkedPlayerByFan({ playerId: player })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    closeAddPlayer = () => {
        document.body.classList.remove('modal-open');
        this.setState((prevState) => ({
            ...prevState,
            addPlayerPopup: false
        }));
    }

    openChild = (req) => {
        this.setState((prevState) => ({
            ...prevState,
            tempData: req,
            addPlayerPopup: false,
            inviteChild: true
        }));
    }

    closeInviteChild = () => {
        this.setState((prevState) => ({
            ...prevState,
            inviteChild: false,
            addPlayerPopup: true,
            tempData: []
        }));
    }

    saveInviteChild = () => {
        document.body.classList.remove('modal-open');
        this.setState((prevState) => ({
            ...prevState,
            tempData: [],
            inviteChild: false,
        }), () => {
            this.setLoader(true);
            this.props.LinkedPlayerList();
        });
    }

    renderLinkedList = () => {
        return (
            this.state.linkedList.length !== 0 ? this.state.linkedList.map((list, i) => {
                return (
                    <tr>
                        <td>{list.fname + ' ' + list.lname}</td>
                        <td>{list.email}</td>
                    </tr>
                )
            }) :
                <tr>
                    <td colSpan="2" className="text-center no-data"><p>Please add at least one player here by searching from above or invite them to our system.</p></td>
                </tr>
        )
    }

    validationUpdate = (cb) => {
        if (this.state.linkedList.length !== 0) {
            cb(true);
        } else {
            cb(false);
        }
    }
    render() {
        const { addPlayerPopup, inviteChild, tempData } = this.state;
        const { player, team, coach } = this.state.linkedObj;


        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name">
                            <h2>Hi {this.props.profileData && this.props.profileData.fname && this.props.profileData.fname + " " + this.props.profileData.lname}</h2>
                            <p>We have a few more QUESTIONS for you.</p>
                        </div>
                        <label className="common-lable">Player name</label>
                        <div className="check_box">
                            <Select
                                value={player && this.state.optionArr.find(e => e.value === player)}
                                options={this.state.optionArr}
                                onInputChange={this.handleChange}
                                onChange={(e) => this.setOption(e)}
                            />
                        </div>
                        <span className="validMsg">{this.validator.message('player', player, 'required')}</span>

                        <label class="common-lable">Team name</label>
                        <div className="form-group">
                            <input type="text" className="form-control" name="teamname" id="teamname" placeholder="Team name" value={team && team.name} disabled />
                        </div>
                        <label class="common-lable">Coach name</label>
                        <div className="form-group">
                            <input type="text" className="form-control" name="coachname" id="coachname" placeholder="Coach name" value={coach && coach.fname + ' ' + coach.lname} disabled />
                        </div>
                        <div className="flex-align">
                            <a class="btn light-green" onClick={() => this.linkedPlayer()}>Link Player</a>
                        </div>
                        <div className="sheprapter">
                            <p>OR</p>
                            <div className="border"></div>
                        </div>
                        <div className="flex-align">
                            <a class="btn light-green" onClick={() => this.setState({ addPlayerPopup: true })}>+ Add Player</a>
                        </div>
                        <div className="parent-table mt-15 pt-15">
                            <div className="table-responsive-xl">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderLinkedList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                {addPlayerPopup &&
                    <AddLinkedPlayer
                        flag={addPlayerPopup}
                        close={this.closeAddPlayer}
                        setLoader={this.setLoader}
                        type="Fan"
                        openChild={this.openChild}
                    />}
                {
                    inviteChild &&
                    <InviteChild
                        flag={inviteChild}
                        tempData={tempData}
                        close={this.closeInviteChild}
                        save={this.saveInviteChild}
                        setLoader={this.setLoader}
                    />
                }
            </>
        )
    }
}

parent.propTypes = {
    playerList: PropTypes.func.isRequired,
    LinkedPlayerList: PropTypes.func.isRequired,
    AddLinkedPlayerByFan: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    parentInfo: state.parentDetails,
});

export default connect(mapStateToProps, {
    playerList,
    LinkedPlayerList,
    AddLinkedPlayerByFan
})(parent);
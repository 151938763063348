import React, {
  useRef,
  useState,
  useReducer,
  useEffect,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";

import { pendingCoupenCode, pendingPaymentDash } from "../../saga/actions/common/api.action";
import { fetchPaymentIntent } from "../../saga/actions/common/eventDetails.action";
import { PENDING_COUPEN_CODE_SUCCESS, PENDING_PAYMENT_DASH_SUCCESS } from "../../saga/constants/common/api.const";
import { FETCH_PAYMENT_INTENT_SUCCESS } from "../../saga/constants/common/eventDetails.const";

import commonCalls from "../../businesslogic/commonCalls";
import Preloader from "../../components/preloader";
import CreditCard from "../company/components/payment/creditCard";

const initialState = {
  "upload-roster": {
    phone: "",
    lname: "",
    number: "",
    position: "",
    position2: "",
    dob: "",
    fname: "",
    email: "",
    country_code: "+1",
    role: "Player",
    isAccept: false,
    isMail: false
  },
  facilityRoster: [],
  teamRoster: [],
  opencal: false,

  loader: false,
  intentReq: {
    paymentType: 1,
    amount: 0,
    objetId: ""
  },
  couponCodeReq: {
    event: "",
    amount: 0,
    couponCode: ""
  },
  paymentReq: {
    metaPayment: "",
    applicantId: "",
    amount: "",
    couponCode: ""
  },
  creditCard: false

};

const reducer = (state, payload) => ({ ...state, ...payload });

function AddRoster({
  flag,
  close,
  remainDetail,
  eventId
}) {
  const history = useHistory();
  const commonCall = new commonCalls();
  const dispatch = useDispatch();
  const [event, setEvent] = useReducer(reducer, initialState);

  const { pendingCoupenCodeData: _CC, pendingPaymentDashData: _PAY } = useSelector((state) => state.api);
  const eventDeatils = useSelector((state) => state.eventDetails);

  useEffect(() => {
    if (flag) {
      document.body.classList.add("modal-open");
    }
  }, [flag]);


  useEffect(() => {
    if (remainDetail && eventId) {
      setEvent({
        ...event,
        intentReq: {
          ...event.intentReq,
          paymentType: 1,
          amount: remainDetail.remainingAmt,
          objetId: eventId
        },
        couponCodeReq: {
          ...event.couponCodeReq,
          event: eventId,
          amount: remainDetail.remainingAmt,
        },
        paymentReq: {
          ...event.paymentReq,
          applicantId: remainDetail._id,
          amount: remainDetail.remainingAmt,
        },
      })
    }
  }, [remainDetail, eventId])

  useEffect(() => {
    if (_CC) {
      if (_CC?.flag) {
        setEvent({
          ...event,
          intentReq: {
            ...event.intentReq,
            amount: _CC.result.newFeesTournament,
          },
          paymentReq: {
            ...event.paymentReq,
            amount: _CC.result.newFeesTournament,
            couponCode: event.couponCodeReq.couponCode
          },
          loader: false
        })
      } else {
        setEvent({
          ...event,
          loader: false
        })
        commonCall.errorMessage(_CC.message)
      }
      dispatch({
        type: PENDING_COUPEN_CODE_SUCCESS,
        payload: null,
      });
    }
  }, [_CC])

  useEffect(() => {
    if (eventDeatils &&
      eventDeatils.getPaymentIntentData
    ) {
      if (eventDeatils.getPaymentIntentData.flag) {
        localStorage.setItem(
          "stripeIntent",
          eventDeatils.getPaymentIntentData.result.data
        );
        setEvent({
          ...event,
          paymentMethod: 1,
          creditCard: true,
          loader: false
        })
        // close();
        dispatch({
          type: FETCH_PAYMENT_INTENT_SUCCESS,
          payload: null,
        });
      } else {
        commonCall.errorMessage(eventDeatils.getPaymentIntentData.message);
        dispatch({
          type: FETCH_PAYMENT_INTENT_SUCCESS,
          payload: null,
        });
        setLoader(false);
      }
    }
  }, [eventDeatils])


  useEffect(() => {
    if (_PAY) {
      if (_PAY.flag) {
        close();
        history.push(`/event-joined/${eventId}`);
      } else {
        setLoader(false)
        commonCall.errorMessage(_PAY.message);
        dispatch({
          type: PENDING_PAYMENT_DASH_SUCCESS,
          payload: null,
        });
      }
    }
  }, [_PAY])

  const handleCoupon = (e) => {
    setEvent({
      ...event,
      couponCodeReq: {
        ...event.couponCodeReq,
        couponCode: e.target.value
      }
    })
  }

  const applyCode = () => {
    setLoader(true);
    pendingCoupenCode(event.couponCodeReq)(dispatch);
  }

  const chkValid = (e) => {
    if (event.intentReq.paymentType !== 0) {
      var payment = {
        amount: event.intentReq.amount,
        objetId: event.intentReq.objetId,
        module: 1,
        method: "stripe",
      };
      console.log("payment:---", payment);
      setLoader(true);
      fetchPaymentIntent(payment)(dispatch);
    }
  }



  const onHandleClose = () => {
    setEvent({
      ...event,
      creditCard: false
    }, () => {
      document.body.remove("modal-open");
    })
  }

  const stripePaymentError = (error) => {
    commonCall.errorMessage(error);
    setEvent({
      ...event,
      loader: false,
    })
  };


  const stripePaymentSuccess = (payment) => {
    let req = {
      ...event.paymentReq,
      metaPayment: payment,
    }
    setEvent({
      ...event,
      // creditCard: false,
      paymentReq: req,
    })
    // document.body.classList.remove("modal-open");
    pendingPaymentDash(req)(dispatch);
  };

  const onSave = () => {
    let req = {
      ...event.paymentReq,
      metaPayment: undefined,
    }
    pendingPaymentDash(req)(dispatch);
  }

  const setLoader = (val) => {
    setEvent({
      ...event,
      loader: val
    })
  }


  return (

    <>
      {!event.creditCard &&
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="green-header modal-header">
                <h3>Payment</h3>
                <div className="modal_close" onClick={close}>
                  <img src={imagesArr.white_modal_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body">
                <div className="team-body-inner">

                  <div className="">
                    <div class="form-group">
                      <label className="common-lable">Payment Type</label>
                      <input type="text" name="paymentType" class="form-control" disabled value={"Full Payment ( $" + event.intentReq.amount + " )"} />
                    </div>
                  </div>


                  <div className="">
                    <div className="form-group">
                      <label className="common-lable">Apply Coupon Code</label>
                      <div className="input-btn d-flex">
                        <input type="text" name="couponCode" className="form-control" value={event.couponCodeReq.couponCode} onChange={handleCoupon} placeholder="Enter coupon code" />
                        <Link className="btn light-green ml-10" onClick={() => applyCode()} >Apply</Link>
                      </div>
                    </div>
                  </div>

                  {event.intentReq.amount !== 0 ?
                    <div className="">
                      <div className="form-group">
                        <label className="common-lable">Payment Method</label>
                        <div className="check_input">
                          <div className="check_box p-0 w-full">
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                name="creditcard"
                                onChange={(e) => chkValid(e)}
                              />
                              <label>Credit Card </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className="flex-align">
                      <Link className="btn light-green" onClick={onSave}>Submit</Link>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>}
      {
        event.creditCard &&
        <CreditCard
          flag={event.creditCard}
          close={onHandleClose}
          stripePaymentSuccess={stripePaymentSuccess}
          stripePaymentError={stripePaymentError}
          amount={event.intentReq.amount}
          setLoader={setLoader}
        />
      }
      <Preloader flag={event.loader} />
    </>
  );
}

export default AddRoster;

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import Footer from "../../../../containers/footer";
import commonCalls from "../../../../businesslogic/commonCalls";
import { buyScoutPackage, getEventDetails, getParticipating, eventRegistrationPayment, getAgeVerification } from '../../../../saga/actions/common/eventDetails.action';
import Preloader from "../../../../components/preloader";
import EventRow from './details/eventRow';
import EventInfo from './details/eventInfo';
import Location from './details/location';
import SocialMediaLink from './details/socialMediaLink';
import CollegeSecLink from './details/collegeSecLink';
import HotelInfo from './details/hotelInfo';
import { getMinOfKeys, commonHeader } from "../../../../businesslogic/content";
import Confirmation from '../../../popup/confirmation';
import { imagesArr } from '../../../../assets/images';
import { getPerksLink } from '../../../../saga/actions/common/api.action';

class eventDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventId: "",
            loader: false,
            eventDetailData: null,
            participationList: [],
            loadHeader: false,
            teamArr: [],
            eligiblePopup: false,
            eligibleText: "",
            eligibleButoon: "",
            regRosterButton: "",
            hideRoster: false,
            perksLink: "",
            rosterList: [],
            eventJoin: {
                event: "",
                isManager: true,
                managerName: "",
                managerEmail: "",
                managerPhone: "",
                waiversImg: "",
                insuranceImg: "",
                paymentType: 0,
                couponCode: "",
                metaPayment: {},
                paymentMethod: "",
                amount: 0,
                question: "",
                products: [],
                positionRegistration: false,
                pitcherRegistration: false,
                twoWayRegistration: false,
                analyzrReportpay: false,
                ageGroup: "",
                ageGroupTo: "",
                waiverUrlClick: "no",
                slectedGroupFeeId: "",
                companyThrough: localStorage.getItem("company") === "dc" ? 2 : 1,
            },

        };

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        localStorage.removeItem("eventDetail");
        localStorage.removeItem("finalAmount");
        localStorage.removeItem("paymentType");
        localStorage.removeItem("noAge");
        localStorage.removeItem("zeroAmount");
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        this.setState({
            eventId: eventId
        }, () => {
            this.getDetails();
            // this.props.getPerksLink(localStorage.getItem("coachToken") ? 'Coach' : localStorage.getItem("playerToken") ? 'Player' : 'Fan');
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            console.log("nextProps.eventDetails:--", nextProps.eventDetails.getDetailsData);
            if (nextProps.eventDetails.getDetailsData.flag) {
                let fdata = nextProps.eventDetails.getDetailsData.result.data[0];
                this.setState({
                    eventDetailData: fdata
                }, () => {
                    if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== fdata.companies._id) {
                        let companyname = fdata.companies.name === "GOBTournament" ? "Greater Orlando Baseball" : fdata.companies.name;
                        let item = commonHeader.events.filter((e) => e.label === companyname)[0];
                        if (localStorage.getItem("company") !== 'dc') {
                            localStorage.setItem("cid", fdata.companies._id)
                            localStorage.setItem("company", item.key)
                            localStorage.setItem("companyColor", JSON.stringify(item.colors));
                        }
                        this.setState({
                            loadHeader: true
                        })
                    } else {
                        this.setState({
                            loadHeader: true
                        })
                    }
                    let price = getMinOfKeys(this.state.eventDetailData.payment.feesByGroups, ['feesOfGroup']);
                    // if (this.state.eventDetailData.isCollegeShowcase && localStorage.getItem("webglToken") !== null) {
                    //     this.props.getPerksLink(localStorage.getItem("coachToken") ? 'Coach' : localStorage.getItem("playerToken") ? 'Player' : 'Fan');
                    // }
                    this.state.eventDetailData.payment.feesTournament = price;

                    nextProps.eventDetails.getDetailsData = null;
                    this.props.getParticipating(this.state.eventId);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getParticipationReq &&
            nextProps.eventDetails.getParticipationData
        ) {
            nextProps.eventDetails.getParticipationReq = false;
            if (nextProps.eventDetails.getParticipationData.flag) {
                this.setState({
                    participationList: nextProps.eventDetails.getParticipationData.result.data
                }, () => {
                    nextProps.eventDetails.getParticipationData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getParticipationData.message);
                nextProps.eventDetails.getParticipationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.commonData) {
            if (nextProps.profileDetails.commonData.flag) {
                let profile = nextProps.profileDetails.commonData.result.profile
                this.setState({
                    teamArr: profile.team,
                    hideRoster: (!profile.invite && profile.team && profile.team.length) ? true : false,
                })
            }
        }

        if (nextProps.perkInfo &&
            nextProps.perkInfo.getPerksInfoReq &&
            nextProps.perkInfo.getPerksInfoData
        ) {
            nextProps.perkInfo.getPerksInfoReq = false;
            console.log("nextProps.perkInfo.getPerksInfoData:--", nextProps.perkInfo.getPerksInfoData);
            if (nextProps.perkInfo.getPerksInfoData.flag) {
                this.setState({
                    perksLink: nextProps.perkInfo.getPerksInfoData.result.CurrentToken
                }, () => {
                    nextProps.perkInfo.getPerksInfoData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.perkInfo.getPerksInfoData.message);
                nextProps.perkInfo.getPerksInfoData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.eventRegistrationReq &&
            nextProps.eventDetails.eventRegistrationData
        ) {
            nextProps.eventDetails.eventRegistrationReq = false;
            console.log("eventRegistrationData:--", nextProps.eventDetails.eventRegistrationData);
            if (nextProps.eventDetails.eventRegistrationData.flag) {
                nextProps.eventDetails.eventRegistrationData = null;
                this.commonCall.successMessage("your request has been sent and you will be notified of the decision shortly");

                this.getDetails();
                this.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.buyScoutPackage
        ) {
            console.log("buyScoutPackage:--", nextProps.eventDetails.buyScoutPackage);
            if (nextProps.eventDetails.buyScoutPackage.flag) {
                this.commonCall.successMessage(nextProps.eventDetails.buyScoutPackage.message);
                nextProps.eventDetails.buyScoutPackage = null;
                this.getDetails();
                this.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.buyScoutPackage.message);
                nextProps.eventDetails.buyScoutPackage = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        if (localStorage.getItem("webglToken") !== null) {
            this.props.getEventDetails(this.state.eventId, true);
        } else {
            this.props.getEventDetails({
                eventId: this.state.eventId
            }, false);
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    eligibleNext = (item) => {
        console.log("item:-----", item);
        if (this.state.eventDetailData.settings.redirectURL || item.redirectURL) {
            let linkURL = this.state.eventDetailData.settings.redirectURL ? this.state.eventDetailData.linkUrl : item.linkUrl;
            window.open(linkURL, '_blank');
        } else {
            let ageGroupMsg = this.state.hideRoster ?
                item.isUsed ?
                    <>
                        <h2>Let’s create your roster</h2>
                        <p>Looks like you need to create a new roster. You can’t add the same roster to one event. Add your next roster now to continue registering.</p>
                    </>
                    :
                    <>
                        <h2>Let’s create your first roster</h2>
                        <p>Click below to access the Roster Management Tool. As a Coach, you can have multiple teams under your account. Let's create your first roster. Click below to complete this step and finish registration. You can add your players now or later, and they are not required to register; only a team is required for now.</p>
                    </>
                :
                <p>You are waiting for joining team.Please try again later.</p>;
            this.setState({
                eligiblePopup: true,
                eligibleText: ageGroupMsg,
                eligibleButoon: item.isUsed ? "Create Your Roster" : "Create Your First Roster",
            })
        }
    }

    closePopup = () => {
        this.setState({
            eligiblePopup: false,
            eligibleText: "",
            eligibleButoon: "",
        }, () => {
            document.body.classList.remove('modal-open');
            if (this.state.hideRoster) {
                localStorage.setItem("noAge", this.state.eventId);
                this.commonCall.setStorage("coachTab", "roster", flag => {
                    if (flag) {
                        this.props.history.push('/coach-setting');
                    }
                });
            }
        })
    }

    close = () => {
        this.setState({
            eligiblePopup: false,
            eligibleText: "",
            eligibleButoon: "",
        }, () => {
            document.body.classList.remove('modal-open');
        })
    }

    requestToRegister = (data) => {
        this.setLoader(true);
        this.props.eventRegistrationPayment(data);
    }

    buyScoutPackage = (data) => {
        this.setLoader(true);
        this.props.buyScoutPackage(data);
    }

    render() {
        const { loader, eventDetailData, participationList, eventId, loadHeader, teamArr, eligiblePopup, eligibleText, eligibleButoon, perksLink, regRosterButton,
            regRosterPopup, eventJoin, rosterList } = this.state;
        return (
            // loadHeader &&
            <div>
                <Header />
                <CommonHeroBanner dataList={eventDetailData} />
                <div className="event_details">
                    <div className="container">
                        <EventRow
                            teamArr={teamArr}
                            dataList={eventDetailData}
                            isHideJoinButton={false}
                            eventId={eventId}
                            complete={false}
                            directPay={false}
                            showAmount={
                                eventDetailData?.eventTeamType === 2 ? false : true
                            }
                            requestToRegister={this.requestToRegister}
                            buyScoutPackage={this.buyScoutPackage}
                        />
                        <div className="row">
                            {
                                // eventDetailData.length !== 0 &&
                                <EventInfo
                                    dataList={eventDetailData}
                                    pList={participationList}
                                    eligibleNext={this.eligibleNext}
                                    teamArr={teamArr}
                                    eventId={eventId}
                                    isHideJoinButton={false}
                                    requestToRegister={this.requestToRegister}
                                    closePopup={this.closePopup}
                                />
                            }

                            <div className="event_right_side">
                                <Location dataList={eventDetailData} />

                                {!eventDetailData?.isCollegeShowcase ? (
                                    <SocialMediaLink dataList={eventDetailData} />
                                ) : (
                                    <CollegeSecLink dataList={eventDetailData} />
                                )}

                                {!eventDetailData?.isCollegeShowcase ? (
                                    eventDetailData?.hotels &&
                                    eventDetailData?.hotels.length > 0 && (
                                        <HotelInfo hotelList={eventDetailData?.hotels} />
                                    )
                                ) : (
                                    <div
                                        className="mb-30"
                                        onClick={() => localStorage.getItem("webglToken") !== null ? window.open(`https://www.dctravelrewards.com`, "_blank") : ""}>
                                        <div className="web_hotel_slide">
                                            <img
                                                src={imagesArr.travellogo}
                                                alt="hotel"
                                                class="rounded"
                                            />
                                            {/* <div class="web_hotel_address"><p>{item.hotelAddress}</p></div> */}
                                        </div>
                                    </div>
                                )}
                                <div className="web_description_fullborderbox">
                                    <div className="web_description_box_20 web_hotel_download">
                                        <div className="download_btn">
                                            {/* <a
                                                href="https://play.google.com/store/apps/details?id=com.baseballcloud.diamondconnect"
                                                target="_blank"
                                                className="android_link"
                                            >
                                                <img src={imagesArr.playstore} />
                                            </a> */}
                                            <a
                                                href="https://apps.apple.com/us/app/diamondconnect-mobile/id1605935504"
                                                target="_blank"
                                                className="ios_link"
                                            >
                                                <img src={imagesArr.appstore} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
                {eligiblePopup && (
                    <Confirmation
                        flag={eligiblePopup}
                        type="eligible"
                        close={this.closePopup}
                        closePopup={this.close}
                        eligibleText={eligibleText}
                        eligibleButoon={eligibleButoon}
                    />
                )}

            </div>
        );
    }
}


eventDetail.propTypes = {
    getEventDetails: PropTypes.func.isRequired,
    getParticipating: PropTypes.func.isRequired,
    getPerksLink: PropTypes.func.isRequired,
    eventRegistrationPayment: PropTypes.func.isRequired,
    getAgeVerification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
    profileDetails: state.headerData,
    perkInfo: state.api,
});
var eventDetailComponent = connect(mapStateToProps, { buyScoutPackage, getPerksLink, getEventDetails, getParticipating, eventRegistrationPayment, getAgeVerification })(eventDetail);
export default withRouter(eventDetailComponent);

export const FAN_COACH_LIST_REQUEST = 'FAN_COACH_LIST_REQUEST';
export const FAN_COACH_LIST_SUCCESS = 'FAN_COACH_LIST_SUCCESS';
export const FAN_COACH_LIST_ERROR   = 'FAN_COACH_LIST_ERROR';

export const FAN_ADD_COACH_REQUEST = 'FAN_ADD_COACH_REQUEST';
export const FAN_ADD_COACH_SUCCESS = 'FAN_ADD_COACH_SUCCESS';
export const FAN_ADD_COACH_ERROR   = 'FAN_ADD_COACH_ERROR';

export const FAN_COMPLETE_REQUEST = 'FAN_COMPLETE_REQUEST';
export const FAN_COMPLETE_SUCCESS = 'FAN_COMPLETE_SUCCESS';
export const FAN_COMPLETE_ERROR   = 'FAN_COMPLETE_ERROR';
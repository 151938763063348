import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { imagesArr } from '../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../components/leftAuthImage';
import CommonHeroBanner from '../components/commonHeroBanner';
import OtpInput from 'react-otp-input';
import commonCalls from "../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { resendCode, verifyCode, getVerifyProfile } from '../saga/actions/authantication/verify.action';
import Preloader from '../components/preloader';

let timerOn = true;

class verify extends Component {
    constructor(props) {

        super(props);
        this.state = {
            otp: "",
            token: "",
            mobile: "",
            second: "",
            timeOut: false,
            loader: false
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.commonCall.getStorage("userStep1", data => {
            if (data !== null) {
                this.setState({
                    mobile: data.cCode + data.phone
                }, () => {
                    this.timer(30, false);
                })
            }
        });
        this.getToken();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.verifyData &&
            nextProps.verifyData.playerProfileData
        ) {
            nextProps.verifyData.playerProfileData = null;
        }


        if (nextProps.verifyData) {
            if (nextProps.verifyData.resendData) {
                if (nextProps.verifyData.rendReq) {
                    nextProps.verifyData.rendReq = false;
                    this.commonCall.successMessage(nextProps.verifyData.resendData.message);
                    nextProps.verifyData.resendData = null;
                    this.setLoader(false);
                }
            }

            if (nextProps.verifyData.verifyData && nextProps.verifyData.verifyReq) {
                if (nextProps.verifyData.verifyData.flag) {
                    nextProps.verifyData.verifyReq = false;
                    this.commonCall.setAuthToken("authToken", nextProps.verifyData.verifyData.result.token, flag => {
                        if (flag) {
                            localStorage.setItem("tempRole",nextProps.verifyData.verifyData.result.role)
                            nextProps.verifyData.verifyData = null;
                            this.setLoader(false);
                            this.props.getVerifyProfile();
                        }
                    });
                } else {
                    this.commonCall.errorMessage(nextProps.verifyData.verifyData.message);
                    nextProps.verifyData.verifyData = null;
                    this.setLoader(false);
                }
            }

            if (nextProps.verifyData.verifyProfileReq) {
                if (nextProps.verifyData.verifyProfileData) {
                    nextProps.verifyData.verifyProfileReq = false;
                    let setToken = nextProps.verifyData.verifyProfileData.result.profile.role === "Coach" ? "coachToken" :
                        nextProps.verifyData.verifyProfileData.result.profile.role === "Player" ? "playerToken" : "authToken";

                    localStorage.setItem("webglToken", localStorage.getItem("authToken"));

                    this.commonCall.setAuthToken(setToken, localStorage.getItem("authToken"), flag => {
                        if (flag) {
                            if (nextProps.verifyData.verifyProfileData.result.profile.role) {
                                if (nextProps.verifyData.verifyProfileData.result.profile.role === "Coach") {
                                    nextProps.verifyData.verifyProfileData = null;
                                    this.props.history.push('/coach-signup4');
                                } else {
                                    nextProps.verifyData.verifyProfileData = null;
                                    this.props.history.push('/player-signup4');
                                }
                            } else {
                                this.commonCall.successMessage("Registration successful");
                                nextProps.verifyData.verifyProfileData = null;
                                this.props.history.push('/signup3');
                            }
                        }
                    });

                }
            }
        }
    }

    getToken = () => {
        let url = new URL(document.location);
        let userToken = url.pathname.split("/")[2];
        this.setState({
            token: userToken
        });
    }

    handleChange = otp => {
        this.setState({ otp });
    }

    timer = (remaining, apicall) => {
        if (apicall) {
            let data = {
                verifyToken: this.state.token,
            }
            this.setLoader(true);
            this.props.resendCode(data);
        }

        var s = remaining % 60;
        s = s < 10 ? '0' + s : s;

        this.setState({
            second: s,
            timeOut: false
        })

        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(() => {
                this.remainTime(remaining)
            }, 1000);
            return;
        }
        // Do timeout stuff here
        this.setState({ timeOut: true })
    }

    remainTime = (time) => {
        this.timer(time, false)
    }

    sendOTP = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            let data = {
                verifyToken: this.state.token,
                otp: this.state.otp
            }
            this.setLoader(true);
            this.props.verifyCode(data);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>Verification Code</h3>
                                    <p>Please type the verification code send to <span>{this.state.mobile}</span></p>
                                </div>
                                <form className="verification_code">
                                    <div className="form-group">
                                        <OtpInput
                                            value={this.state.otp}
                                            onChange={this.handleChange}
                                            numInputs={4}
                                            inputStyle="form-control"
                                            isInputNum={true}
                                        />
                                    </div>
                                    <span className="validMsg">{this.validator.message('otp', this.state.otp, 'required')}</span>
                                    <div className="resend">
                                        {
                                            this.state.timeOut ?
                                                <Link className="resend" onClick={() => this.timer(30, true)}>Resend OTP</Link>
                                                :
                                                <p>Resend OTP in {this.state.second}</p>
                                        }
                                    </div>
                                    <button type="button" className="btn w-100 text-center" onClick={this.sendOTP} disabled={this.state.timeOut ? true : false}>Verify</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

verify.propTypes = {
    resendCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    getVerifyProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
});

export default connect(mapStateToProps, { resendCode, verifyCode, getVerifyProfile })(verify);
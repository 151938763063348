import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import Cookies from 'js-cookie';
import Preloader from '../../components/preloader';

class analyzr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            webGLUrl: "",
            loader: false,
        }

    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        Cookies.set('bcSession', localStorage.getItem("webglToken"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        if (localStorage.getItem("webglUrl") !== null && localStorage.getItem("webglUrl") !== "undefined") {
            this.setState({
                webGLUrl: localStorage.getItem("webglUrl")
            })
        } else {
            this.setState({
                webGLUrl: process.env.REACT_APP_ANALYZR_LINK
            })
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state;
        return (
            <div className="webgl-main">
                <Header type="webgl" />
                {/* <CommonHeroBanner dataList="" /> */}
                <section className="bcteam_webgl_section">
                    <iframe scrolling="yes" onLoad={() => this.setLoader(false)} src={this.state.webGLUrl} frameborder="0" allowfullscreen></iframe>
                </section>
                {/* <Footer /> */}
                <Preloader flag={loader} />
            </div>
        );
    }
}

export default analyzr;

export const GET_PLAYER_LIST_SUCCESS = 'GET_PLAYER_LIST_SUCCESS';
export const GET_PLAYER_LIST_ERROR = 'GET_PLAYER_LIST_ERROR';

export const AGE_TEAM_LIST_SUCCESS = 'AGE_TEAM_LIST_SUCCESS';
export const AGE_TEAM_LIST_ERROR = 'AGE_TEAM_LIST_ERROR';

export const GET_PLAYER_INFO_SUCCESS = 'GET_PLAYER_INFO_SUCCESS';
export const GET_PLAYER_INFO_ERROR = 'GET_PLAYER_INFO_ERROR';

export const POST_PLAYER_INFO_SUCCESS = 'POST_PLAYER_INFO_SUCCESS';
export const POST_PLAYER_INFO_ERROR = 'POST_PLAYER_INFO_ERROR';

export const EDIT_PLAYER_INFO_SUCCESS = 'EDIT_PLAYER_INFO_SUCCESS';
export const EDIT_PLAYER_INFO_ERROR = 'EDIT_PLAYER_INFO_ERROR';

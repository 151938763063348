import {
    GET_PLAYER_RANKING_PAGE_SUCCESS,
    GET_PLAYER_RANKING_PAGE_REQUEST,
    GET_PLAYER_RANKING_PAGE_ERROR,

    GET_ALL_PLAYER_RANKING_LIST_SUCCESS,
    GET_ALL_PLAYER_RANKING_LIST_REQUEST,
    GET_ALL_PLAYER_RANKING_LIST_ERROR

} from '../../../../../constants/view/company/pwRanking/rankings/playerRanking.const';

const initialState = {
    getPlayerRankingReq: false,
    getPlayerRankingSuccess: null,
    getPlayerRankingError: null,

    getAllPlayerRankingListReq: false,
    getAllPlayerRankingListSuccess: null,
    getAllPlayerRankingListError: null,
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_ALL_PLAYER_RANKING_LIST_REQUEST:
            return {
                ...state,
                getAllPlayerRankingListReq: true,
            };

        case GET_ALL_PLAYER_RANKING_LIST_SUCCESS:
            return {
                ...state,
                getAllPlayerRankingListSuccess: action.payload,
            };
        case GET_ALL_PLAYER_RANKING_LIST_ERROR:
            return {
                ...state,
                getAllPlayerRankingListError: action.error,
            };

        case GET_PLAYER_RANKING_PAGE_REQUEST:
            return {
                ...state,
                getPlayerRankingReq: true,
            };

        case GET_PLAYER_RANKING_PAGE_SUCCESS:
            return {
                ...state,
                getPlayerRankingSuccess: action.payload,
            };
        case GET_PLAYER_RANKING_PAGE_ERROR:
            return {
                ...state,
                getPlayerRankingError: action.error,
            };

        default:
            return state;
    }
}
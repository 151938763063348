import axios from 'axios';
import { Auth_Key, Auth_Coach_Key, Auth_Player_Key, Auth_Fan_Key, API_Url, Authorization, checkIsValidToken, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidFanToken, checkIsValidCommiToken, Auth_Commi_Key } from '../../helpers/authToken';
import {
    GET_HEAD_PROFILE_REQUEST,
    GET_HEAD_PROFILE_SUCCESS,
    GET_HEAD_PROFILE_ERROR,

    GET_HEAD_PLAYER_PROFILE_REQUEST,
    GET_HEAD_PLAYER_PROFILE_SUCCESS,
    GET_HEAD_PLAYER_PROFILE_ERROR,

    GET_HEAD_FAN_PROFILE_REQUEST,
    GET_HEAD_FAN_PROFILE_SUCCESS,
    GET_HEAD_FAN_PROFILE_ERROR,

    GET_HEAD_COMMI_PROFILE_REQUEST,
    GET_HEAD_COMMI_PROFILE_SUCCESS,
    GET_HEAD_COMMI_PROFILE_ERROR,

    GET_DOB_PLAYER_PROFILE_REQUEST,
    GET_DOB_PLAYER_PROFILE_SUCCESS,
    GET_DOB_PLAYER_PROFILE_ERROR,

    GET_CHANGE_PASSWORD_ERROR,
    GET_CHANGE_PASSWORD_SUCCESS,
    GET_CHANGE_PASSWORD_REQUEST,

    GET_RESET_PASSWORD_REQUEST,
    GET_RESET_PASSWORD_SUCCESS,
    GET_RESET_PASSWORD_ERROR,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR
} from '../../constants/containers/header.const';
import histrory from '../../../History';

export const getProfile = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_HEAD_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/coach/profile`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(chkrole => {
        dispatch({
            type: GET_HEAD_PROFILE_SUCCESS,
            payload: chkrole.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_HEAD_PROFILE_ERROR, error: error })
        }
    });
};

export const getPlayerProfile = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_HEAD_PLAYER_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/player/profile`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(player => {
        dispatch({
            type: GET_HEAD_PLAYER_PROFILE_SUCCESS,
            payload: player.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_HEAD_PLAYER_PROFILE_ERROR, error: error })
        }
    });
};

export const getFanProfile = () => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: GET_HEAD_FAN_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/fan/profile`,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(fan => {
        dispatch({
            type: GET_HEAD_FAN_PROFILE_SUCCESS,
            payload: fan.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_HEAD_FAN_PROFILE_ERROR, error: error })
        }
    });
};

export const getCommiProfile = () => dispatch => {
    checkIsValidCommiToken(histrory)
    dispatch({ type: GET_HEAD_COMMI_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/user/commissioner/profile`,
        headers: {
            'x-auth-token': Auth_Commi_Key,
            'Accept': 'application/json'
        }
    }).then(commi => {
        dispatch({
            type: GET_HEAD_COMMI_PROFILE_SUCCESS,
            payload: commi.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_HEAD_COMMI_PROFILE_ERROR, error: error })
        }
    });
};

export const getPlayerDobProfile = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_DOB_PLAYER_PROFILE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/player/profile`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(player => {
        dispatch({
            type: GET_DOB_PLAYER_PROFILE_SUCCESS,
            payload: player.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_DOB_PLAYER_PROFILE_ERROR, error: error })
        }
    });
};


export const postChangePassword = (data, userType) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : (userType === "Player") ? checkIsValidPlayerToken(histrory) : userType === "BC Commissioner" ? checkIsValidCommiToken(histrory) : checkIsValidFanToken(histrory);
    // dispatch({ type: GET_HEAD_PROFILE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/user/password/change`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : (userType === "Player") ? Auth_Player_Key : userType === "BC Commissioner" ? Auth_Commi_Key : Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        dispatch({
            type: GET_CHANGE_PASSWORD_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 400) {
            dispatch({ type: GET_CHANGE_PASSWORD_ERROR, error: error.response.data })
        }
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_HEAD_PROFILE_ERROR, error: error })
        }
    });
}

export const resetPassword = (data, userType) => dispatch => {
    userType === "Fan" ? checkIsValidFanToken(histrory) : checkIsValidPlayerToken(histrory);
    // dispatch({ type: GET_HEAD_PROFILE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/fan/password/change/parent`,
        data: data,
        headers: {
            'x-auth-token': userType === "Fan" ? Auth_Fan_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        dispatch({
            type: GET_RESET_PASSWORD_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 400) {
            dispatch({ type: GET_RESET_PASSWORD_ERROR, error: error.response.data })
        }
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_RESET_PASSWORD_ERROR, error: error })
        }
    });
}

export const logOut = (userType, data) => dispatch => {
    // dispatch({ type: GET_HEAD_PROFILE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}user/mobile/logout`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : (userType === "Player") ? Auth_Player_Key : userType === "BC Commissioner" ? Auth_Commi_Key : Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        dispatch({
            type: LOGOUT_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 400) {
            dispatch({ type: LOGOUT_ERROR, error: error.response.data })
        }
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: LOGOUT_ERROR, error: error })
        }
    });
}


import React, { Component } from "react";
import { Route, Router, BrowserRouter, Redirect, useRouteMatch, Link, Switch } from "react-router-dom";
import history from "./History";

// Landing pages
import BasebollCloud from "./landing/BasebollCloud";
import BollR from "./landing/product/BollR";
import BcTeam from "./landing/product/BcTeam";
import PitchR from "./landing/product/PitchR";
import AnalyzR from "./landing/product/AnalyzR";
import OvR from "./landing/product/OvR";
import diamondConnect from "./landing/company/diamondConnect";
import prospectWireBaseball from "./landing/company/prospectWireBaseball";
import prospectWireSoftball from "./landing/company/prospectWireSoftball";
import greaterOrkandoBaseball from "./landing/company/greaterOrkandoBaseball";
import usaTravelBall from "./landing/company/usaTravelBall";
import pioneerBaseball from './landing/company/pioneerBaseball'

import AboutUs from "./containers/AboutUs";
import PressRoom from "./containers/PressRoom";
import OurTeam from "./containers/OurTeam";
import PrivacyPolicy from "./containers/privacyPolicy";
import TermsConditions from "./containers/termsConditions";

// Authantication pages
import login from "./authentication/login";
import step1 from "./authentication/signup/step1";
import step2 from "./authentication/signup/step2";
import parentInfo from "./authentication/signup/parentInfo";
import step3 from "./authentication/signup/step3";
import forgot from "./authentication/forgot";
import verifyCMSAuth from "./authentication/verifyCMSAuth";
import verify from "./authentication/verify";
import fverify from "./authentication/fverify";
import reset from "./authentication/reset";

// After Login Coach Pages
import coachStep4 from "./authentication/signup/coach/step4";
import coachDashboard from "./views/coach/index";
import coachSetting from "./views/coach/setting";
import coachPayment from "./views/coach/payment";
import coachBilling from "./views/coach/billing";
import coachSubscription from "./views/coach/subscription";
import coachUploadcsv from "./views/coach/uploadcsv";
import coachCreateEvent from "./views/coach/uploadDetails/createEvent";
import coachDataSource from "./views/coach/uploadDetails/dataSource";
import coachLinkData from "./views/coach/uploadDetails/linkData";
import coachDeviceData from "./views/coach/uploadDetails/device";
import coachEditDeviceData from "./views/coach/uploadDetails/editDeviceData";
import coachEventModel from "./views/coach/uploadDetails/eventModel";
import coachuploadVideo from "./views/coach/uploadDetails/uploadVideo";
import coachLicense from "./views/coach/licenseassignment";
import coachPerks from "./views/coach/perks";
import playerPerks from "./views/player/perks";
import scoutingLinkData from "./views/coach/uploadDetails/scoutingLinkData";

// After Login Player Pages
import playerStep4 from "./authentication/signup/player/step4";
import playerDashboard from "./views/player/index";
import playerSetting from "./views/player/setting";
import playerPayment from "./views/player/payment";
import playerBilling from "./views/player/billing";
import playerSubscription from "./views/player/subscription";
import playerUploadcsv from "./views/player/uploadcsv";
import playerCreateEvent from "./views/player/uploadDetails/createEvent";
import playerDataSource from "./views/player/uploadDetails/dataSource";
import playerLinkData from "./views/player/uploadDetails/linkData";
import playerDeviceData from "./views/player/uploadDetails/device";
import mycart from "./views/player/mycart";
import teamInfo from "./views/player/profile/teamInfo";
import coachInfo from "./views/player/profile/coachInfo";
import playerEventModel from "./views/player/uploadDetails/eventModel";
import playeruploadVideo from "./views/player/uploadDetails/uploadVideo";
import playerEditDeviceData from "./views/player/uploadDetails/editDeviceData";

import sharecart from "./views/player/sharecart";

// After Login Fan Pages
import fanStep4 from "./authentication/signup/fan/step4";
import fanDashboard from "./views/fan/index";
import fanSetting from "./views/fan/setting";
import fanMycart from "./views/fan/fanMycart";
import fanCreateEvent from "./views/fan/uploadDetails/createEvent";
import fanDataSource from "./views/fan/uploadDetails/dataSource";
import fanLinkData from "./views/fan/uploadDetails/linkData";
import fanDeviceData from "./views/fan/uploadDetails/device";
import fanUploadVideo from "./views/fan/uploadDetails/uploadVideo";
import fanEventModel from "./views/fan/uploadDetails/eventModel";
import fanEditDeviceData from "./views/fan/uploadDetails/editDeviceData";
import fanPayment from "./views/fan/payment";
import fanPerks from "./views/fan/perks";

// After Login Coach Pages
import commiDashboard from "./views/commissioner/index";

// Product WebGL Pages
import bollrWebGL from "./views/webGL/bollrWebGL";
import pitchrWebGL from "./views/webGL/pitchrWebGL";
import bcteam from "./views/webGL/bcteam";
import analyzr from "./views/webGL/analyzr";

// Stripe response
import success from "./views/stripeResponse/success";
import error from "./views/stripeResponse/error";

// dc company pages
import contactUs from "./views/company/dc/contactUs";
import Event from "./views/company/dc/event";
import CollegeShowcase from "./views/company/dc/collegeShowcase";
import dcEventInfo from "./views/company/dc/event/eventInfo";
import dcEventRule from "./views/company/dc/eventRule";

// pwb company pages
import pwbPastChamp from "./views/company/pwb/pastChampion";
import pwbTournament from "./views/company/pwb/tournament";
import pwbShowcase from "./views/company/pwb/showcase";
import pwbRecommendProspect from "./views/company/pwb/recommendProspect";
import pwbEventRecap from "./views/company/pwb/event/eventRecap";
import pwbEventInfo from "./views/company/pwb/event/eventInfo";
import pwbHotel from "./views/company/pwb/hotel/hotel";
import pwbHotelInfo from "./views/company/pwb/hotel/hotelInfo";
import pwbAbout from "./views/company/pwb/about";
import pwbLeague from "./views/company/pwb/league";
import pwbBracketInfo from "./views/company/pwb/event/bracketInfo";
import pwbEventRule from "./views/company/pwb/eventRule";

// pws company pages
import pwsTournament from "./views/company/pws/tournament";
import pwsShowcase from "./views/company/pws/showcase";
import pwsRecommendProspect from "./views/company/pws/recommendProspect";
import pwsEventRecap from "./views/company/pws/event/eventRecap";
import pwsEventInfo from "./views/company/pws/event/eventInfo";
import pwsHotel from "./views/company/pws/hotel/hotel";
import pwsHotelInfo from "./views/company/pws/hotel/hotelInfo";
import pwsAbout from "./views/company/pws/about";
import pwsLeague from "./views/company/pws/league";
import pwsBracketInfo from "./views/company/pws/event/bracketInfo";
import pwsEventRule from "./views/company/pws/eventRule";

// gob company pages
import gobTournament from "./views/company/gob/tournament";
import gobLeague from "./views/company/gob/league";
import gobInsurance from "./views/company/gob/insurance";
import gobHotel from "./views/company/gob/hotel/hotel";
import gobHotelInfo from "./views/company/gob/hotel/hotelInfo";
import gobAbout from "./views/company/gob/about";
import gobEventRule from "./views/company/gob/eventRule";
import gobEventInfo from "./views/company/gob/event/eventInfo";

// utb company pages
import utbTournament from "./views/company/utb/tournament";
import utbLeague from "./views/company/utb/league";
import utbInsurance from "./views/company/utb/insurance";
import utbHotel from "./views/company/utb/hotel/hotel";
import utbHotelInfo from "./views/company/utb/hotel/hotelInfo";
import utbAbout from "./views/company/utb/about";

// pioneer company pages
import pioneerShowcase from "./views/company/pioneer/showcase";
import pioneerEventRecap from "./views/company/pioneer/event/eventRecap";
import pioneerEventRecapInfo from "./views/company/pioneer/event/eventInfo";

// league components pages
import leagueDetail from "./views/company/components/leagues/leagueDetail";
import leagueSelection from "./views/company/components/leagues/leagueSelection";
import leaguePayment from "./views/company/components/leagues/leaguePayment";
import leagueJoin from "./views/company/components/common/leagueJoin";
import leagueRirectPay from "./views/company/components/leagues/leagueRirectPay";

// event components pages
import eventDetail from "./views/company/components/events/eventDetail";
import eventPayment from "./views/company/components/events/eventPayment";
import thankYou from "./views/company/components/common/thankYou";
import eventDayofPayment from "./views/company/components/events/eventDayofPayment";
import eventMultiplePay from "./views/company/components/events/eventMultiplePay";
import thankYouForMultiPay from "./views/company/components/common/thankYouForMultiPay";

import allPastEvent from "./views/company/components/common/allPastEvent";
import allEvent from "./views/company/components/common/allEvent";
import coachMyEvent from "./views/coach/myEvent";
import playerMyEvent from "./views/coach/myEvent";

import pageNotFound from "./views/pageNotFound";
import acceptReq from "./views/acceptReq";
import acceptReqPlayer from "./views/acceptReqPlayer";
import testing from "./views/testing";
import redirect from "./views/redirect";

// pw ranking module
import pwRankingHome from "./views/company/pwRanking/index";
import teamRanking from "./views/company/pwRanking/rankings/teamRanking";
import playerRanking from "./views/company/pwRanking/rankings/playerRanking";

import teamProfile from "./views/company/pwRanking/teamProfile";
import profileSummary from "./views/company/pwRanking/profileSummary";
import playerInfo from "./views/company/pwRanking/summary/playerInfo";
import advancedSearch from "./views/company/pwRanking/advancedSearch";
import fanUploadcsv from "./views/fan/uploadcsv";

import mobileBracketInfo from "./views/company/components/common/mobileBracketInfo";
import accountCreation from "./components/accountCreation/index";


class Routers extends Component {
    render() {
        return (
            <BrowserRouter history={history}>
                <Switch>
                    <Route exact path="/" component={diamondConnect} />
                    <Route path="/ballr" component={BollR} />
                    <Route path="/bcteam" component={BcTeam} />
                    <Route path="/pitchr" component={PitchR} />
                    <Route path="/analyzr" component={AnalyzR} />
                    <Route path="/ovr" component={OvR} />

                    <Route path="/dcStats" component={diamondConnect} />
                    <Route path="/pwb" component={prospectWireBaseball} />
                    <Route path="/pws" component={prospectWireSoftball} />
                    <Route path="/gob" component={greaterOrkandoBaseball} />
                    <Route path="/utb" component={usaTravelBall} />
                    <Route path="/pioneer" component={pioneerBaseball} />

                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/press-room" component={PressRoom} />
                    <Route path="/our-team" component={OurTeam} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-conditions" component={TermsConditions} />

                    <LoginRoute exact Route path="/login" component={login} />
                    <Route path="/forgot" component={forgot} />
                    <Route path="/reset/:id" component={reset} />
                    <Route path="/signup" component={step1} />
                    <Route path="/signup2" component={step2} />
                    <Route path="/parentinfo" component={parentInfo} />
                    <Route path="/verifyCMSAuth/:role/:token" component={verifyCMSAuth} />


                    <Route path="/verify/:id" component={verify} />
                    <Route path="/fverify/:id" component={fverify} />
                    <Route exact path="/join/request/:id" component={acceptReq} />
                    <Route exact path="/join/request/player/:id" component={acceptReqPlayer} />
                    <HomeRoute exact Route path="/signup3" component={step3} />

                    <CoachRoute exact Route path="/coach-signup4" component={coachStep4} />
                    <CoachRoute exact Route path="/coach-dashboard" component={coachDashboard} />
                    <CoachRoute exact Route path="/coach-setting" component={coachSetting} />
                    <Route exact Route path="/coach-setting/:tab" component={coachSetting} />
                    <PaymentRoute exact Route path="/coach-payment" component={coachPayment} />
                    <CoachRoute path="/coach-billing" component={coachBilling} />
                    <CoachRoute path="/coach-subscription" component={coachSubscription} />
                    <CoachRoute path="/coach-uploadcsv" component={coachUploadcsv} />
                    <CoachRoute path="/coach-event" component={coachCreateEvent} />
                    <CoachRoute path="/coach-source" component={CoachSource} />
                    <CoachRoute path="/coach-linkdata" component={CoachLink} />
                    <CoachRoute path="/coach-device" component={CoachDevice} />
                    <CoachRoute path="/coach-event-model" component={CoachEventModel} />
                    <CoachRoute path="/coach-uploadvideo" component={CoachUploadVideo} />
                    <CoachRoute path="/coach-edit-device" component={CoachEditDeviceData} />
                    <CoachRoute path="/coach-license" component={coachLicense} />
                    <CoachRoute path="/coach-myevent" component={coachMyEvent} />
                    <CoachRoute path="/coach-perks" component={coachPerks} />
                    <CoachRoute path="/coach-scoutinglink" component={CoachScoutingLink} />

                    <PlayerRoute exact Route path="/player-signup4" component={playerStep4} />
                    <PlayerRoute exact Route path="/player-dashboard" component={playerDashboard} />
                    <PlayerRoute exact Route path="/player-setting" component={playerSetting} />
                    <PaymentRoute exact Route path="/player-payment" component={playerPayment} />
                    <PlayerRoute path="/player-billing" component={playerBilling} />
                    <PlayerRoute path="/player-subscription" component={playerSubscription} />
                    <PlayerRoute path="/player-uploadcsv" component={playerUploadcsv} />
                    <PlayerRoute path="/player-event" component={playerCreateEvent} />
                    <PlayerRoute path="/player-source" component={PlayerSource} />
                    <PlayerRoute path="/player-linkdata" component={PlayerLink} />
                    <PlayerRoute path="/player-device" component={PlayerDevice} />
                    <PlayerRoute path="/player-teamInfo" component={teamInfo} />
                    <PlayerRoute path="/player-coachInfo" component={coachInfo} />
                    <PlayerRoute path="/player-event-model" component={PlayerEventModel} />
                    <PlayerRoute path="/player-uploadvideo" component={PlayerUploadVideo} />
                    <PlayerRoute path="/player-edit-device" component={PlayerEditDeviceData} />
                    <PlayerRoute path="/player-myevent" component={playerMyEvent} />
                    <PlayerRoute path="/player-perks" component={playerPerks} />

                    <PlayerRoute path="/player-cart" component={mycart} />
                    <Route path="/share-cart/:id" component={sharecart} />

                    <FanRoute exact Route path="/fan-signup4" component={fanStep4} />
                    <FanRoute exact Route path="/fan-dashboard" component={fanDashboard} />
                    <FanRoute exact Route path="/fan-setting" component={fanSetting} />
                    <FanRoute path="/fan-cart/:id" component={fanMycart} />
                    <FanRoute path="/fan-uploadcsv" component={fanUploadcsv} />
                    <FanRoute path="/fan-event" component={fanCreateEvent} />
                    <FanRoute path="/fan-source" component={FanSource} />
                    <FanRoute path="/fan-linkdata" component={FanLink} />
                    <FanRoute path="/fan-device" component={FanDevice} />
                    <FanRoute path="/fan-uploadvideo" component={FanUploadVideo} />
                    <FanRoute path="/fan-event-model" component={FanEventModel} />
                    <FanRoute path="/fan-edit-device" component={FanEditDeviceData} />
                    <PaymentRoute exact Route path="/fan-payment" component={fanPayment} />
                    <FanRoute path="/fan-perks" component={fanPerks} />

                    <CommiRoute exact Route path="/commi-dashboard" component={commiDashboard} />

                    <ProductRoute exact Route path="/ballrapp" component={bollrWebGL} />
                    <ProductRoute exact Route path="/pitchrapp" component={pitchrWebGL} />
                    <ProductRoute exact Route path="/bcteamapp" component={bcteam} />
                    <ProductRoute exact Route path="/analyzrapp" component={analyzr} />

                    <Route path="/success" component={success} />
                    <Route path="/error" component={error} />

                    <Route path="/dc-event" component={Event} />
                    <Route path="/dc-collegeShowcase" component={CollegeShowcase} />
                    <Route path="/dc-contactUs" component={contactUs} />
                    <Route path="/dc-event-info" component={dcEventInfo} />
                    <Route path="/dc-rules" component={dcEventRule} />

                    <Route path="/pwb-pastCham" component={pwbPastChamp} />
                    <Route path="/pwb-tournament" component={pwbTournament} />
                    <Route path="/pwb-showcase" component={pwbShowcase} />
                    <Route path="/pwb-recommend-prospect" component={pwbRecommendProspect} />
                    <Route path="/pwb-event-recap" component={pwbEventRecap} />
                    <Route path="/pwb-event-info" component={EventRecapInfo} />
                    <Route path="/pwb-hotel" component={pwbHotel} />
                    <Route path="/pwb-hotel-info" component={EventHotelInfo} />
                    <Route path="/pwb-about" component={pwbAbout} />
                    <Route path="/pwb-league" component={pwbLeague} />
                    <Route path="/pwb-bracket" component={PwbEventRecapBracketInfo} />
                    <Route path="/pwb-rules" component={pwbEventRule} />

                    <Route path="/pws-tournament" component={pwsTournament} />
                    <Route path="/pws-showcase" component={pwsShowcase} />
                    <Route path="/pws-recommend-prospect" component={pwsRecommendProspect} />
                    <Route path="/pws-event-recap" component={pwsEventRecap} />
                    <Route path="/pws-event-info" component={pwsEventInfo} />
                    <Route path="/pws-hotel" component={pwsHotel} />
                    <Route path="/pws-hotel-info" component={pwsHotelInfo} />
                    <Route path="/pws-about" component={pwsAbout} />
                    <Route path="/pws-league" component={pwsLeague} />
                    <Route path="/pws-bracket" component={PwsEventRecapBracketInfo} />
                    <Route path="/pws-rules" component={pwsEventRule} />

                    <Route path="/gob-tournament" component={gobTournament} />
                    <Route path="/gob-league" component={gobLeague} />
                    <Route path="/gob-insurance" component={gobInsurance} />
                    <Route path="/gob-hotel" component={gobHotel} />
                    <Route path="/gob-hotel-info" component={gobHotelInfo} />
                    <Route path="/gob-about" component={gobAbout} />
                    <Route path="/gob-rules" component={gobEventRule} />
                    <Route path="/gob-event-info" component={gobEventInfo} />

                    <Route path="/utb-tournament" component={utbTournament} />
                    <Route path="/utb-league" component={utbLeague} />
                    <Route path="/utb-insurance" component={utbInsurance} />
                    <Route path="/utb-hotel" component={utbHotel} />
                    <Route path="/utb-hotel-info" component={utbHotelInfo} />
                    <Route path="/utb-about" component={utbAbout} />

                    <Route path="/pioneer-showcase" component={pioneerShowcase} />
                    <Route path="/pioneer-event-recap" component={pioneerEventRecap} />
                    <Route path="/pioneer-event-info" component={pioneerEventRecapInfo} />

                    <Route path="/league-detail" component={LeagueDetails} />
                    <Route path="/league-selection" component={LeagueSelection} />
                    <Route path="/league-form" component={LeagueForms} />
                    <Route path="/league-joined" component={LeagueJoined} />
                    <Route path="/league-payment" component={LeagueDirectPay} />

                    <Route path="/event-detail" component={EventDetails} />
                    <Route path="/event-form" component={EventForms} />
                    <Route path="/event-joined" component={EventJoined} />
                    <Route path="/event-payment" component={EventDirectPay} />
                    <Route path="/event-multiple-pay" component={eventMultiplePay} />
                    <Route path="/multi-event-joined" component={MultiEventJoined} />

                    <Route path="/all-past-event" component={allPastEvent} />
                    <Route path="/all-live-event" component={allEvent} />


                    <Route path="/pw-leaderboard" component={pwRankingHome} />
                    <Route path="/pw-team-ranking" component={teamRanking} />
                    <Route path="/pw-player-ranking" component={playerRanking} />

                    <Route path="/pw-team-profile/:id" component={teamProfile} />
                    <Route path="/pw-profile-info/:id" component={profileSummary} />
                    <Route path="/pw-player-info" component={playerInfo} />
                    <Route path="/pw-advanced-search" component={advancedSearch} />

                    <Route path="/bracket-info" component={mobileBracketInfo} />
                    <Route path="/account-creation/:id/:button" component={accountCreation} />

                    <Route exact Route path="/testing" component={testing} />
                    <Route exact path="/redirect" component={redirect} />

                    <Route path="/404" component={pageNotFound} />
                    <Route path='*' exact>
                        <Redirect to="/404" />
                    </Route>
                </Switch>
            </BrowserRouter >
        );
    }
}

function LeagueDetails() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:ldid`} component={leagueDetail} />
    </>
}

function LeagueSelection() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:lsid`} component={leagueSelection} />
    </>
}

function LeagueForms() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:lfid`} component={leaguePayment} />
    </>
}

function LeagueJoined() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:lcid`} component={leagueJoin} />
    </>
}

function LeagueDirectPay() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/" /></Route>
        <Route exact path={`${path}/:ldpid`} component={leagueRirectPay} />
    </>
}

function EventRecapInfo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/pwb-event-recap" /></Route>
        <Route exact path={`${path}/:evrid`} component={pwbEventInfo} />
    </>
}

function PwbEventRecapBracketInfo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/pwb-event-recap" /></Route>
        <Route exact path={`${path}/:evbid`} component={pwbBracketInfo} />
    </>
}

function PwsEventRecapBracketInfo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/pws-event-recap" /></Route>
        <Route exact path={`${path}/:evbid`} component={pwsBracketInfo} />
    </>
}

function EventHotelInfo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/pwb-hotel" /></Route>
        <Route exact path={`${path}/:evhid`} component={pwbHotelInfo} />
    </>
}

function EventDetails() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:evid`} component={eventDetail} />
    </>
}

function EventDirectPay() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/" /></Route>
        <Route exact path={`${path}/:edpid`} component={eventDayofPayment} />
    </>
}

function EventForms() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:efid`} component={eventPayment} />
    </>
}

function EventJoined() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:ecid`} component={thankYou} />
    </>
}


function MultiEventJoined() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/login" /></Route>
        <Route exact path={`${path}/:payid`} component={thankYouForMultiPay} />
    </>
}


function ProductRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("coachToken") || localStorage.getItem("playerToken") || localStorage.getItem("fanToken") || localStorage.getItem("commiToken") ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function CoachSource() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:id`} component={coachDataSource} />
    </>
}

function CoachDevice() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:did`} component={coachDeviceData} />
    </>
}

function CoachUploadVideo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:cvid`} component={coachuploadVideo} />
    </>
}

function CoachEditDeviceData() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:cedid`} component={coachEditDeviceData} />
    </>
}

function CoachEventModel() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:cemid`} component={coachEventModel} />
    </>
}

function CoachLink() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:eid`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:eid/:sid`} component={coachLinkData} />
    </>
}

function CoachScoutingLink() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/coach-uploadcsv" /></Route>
        <Route exact path={`${path}/:sid`} component={scoutingLinkData} />
    </>
}


function PlayerSource() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:id`} component={playerDataSource} />
    </>
}

function PlayerDevice() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:did`} component={playerDeviceData} />
    </>
}

function PlayerLink() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:eid`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:eid/:sid`} component={playerLinkData} />
    </>
}

function PlayerUploadVideo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:pvid`} component={playeruploadVideo} />
    </>
}

function PlayerEditDeviceData() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:pedid`} component={playerEditDeviceData} />
    </>
}

function PlayerEventModel() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/player-uploadcsv" /></Route>
        <Route exact path={`${path}/:pemid`} component={playerEventModel} />
    </>
}

function FanSource() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:id`} component={fanDataSource} />
    </>
}

function FanLink() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:eid`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:eid/:sid`} component={fanLinkData} />
    </>
}

function FanDevice() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:did`} component={fanDeviceData} />
    </>
}


function FanUploadVideo() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:pvid`} component={fanUploadVideo} />
    </>
}

function FanEventModel() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:pemid`} component={fanEventModel} />
    </>
}

function FanEditDeviceData() {
    let { path } = useRouteMatch();
    return <>
        <Route exact path={`${path}`}><Redirect to="/fan-uploadcsv" /></Route>
        <Route exact path={`${path}/:pedid`} component={fanEditDeviceData} />
    </>
}


function HomeRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("authToken") ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function CoachRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("coachToken") ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function CommiRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("commiToken") ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function FanRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("fanToken") ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function PlayerRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("playerToken") ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}


function PaymentRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("payPlans") ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function LoginRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("coachToken") ? (
                    <Redirect
                        to={{
                            pathname: "/coach-signup4",
                            state: { from: props.location },
                        }}
                    />
                ) : localStorage.getItem("playerToken") ? (
                    <Redirect
                        to={{
                            pathname: "/player-signup4",
                            state: { from: props.location },
                        }}
                    />
                ) : localStorage.getItem("fanToken") ? (
                    <Redirect
                        to={{
                            pathname: "/fan-signup4",
                            state: { from: props.location },
                        }}
                    />
                ) : localStorage.getItem("commiToken") ? (
                    <Redirect
                        to={{
                            pathname: "/commi-dashboard",
                            state: { from: props.location },
                        }}
                    />
                ) :
                    <div>
                        <Component {...props} />
                    </div>
            }
        />
    );
}

export default Routers;

import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    COM_PAST_CHAMPION_REQUEST,
    COM_PAST_CHAMPION_SUCCESS,
    COM_PAST_CHAMPION_ERROR,

    COM_CHAMPION_EVENT_REQUEST,
    COM_CHAMPION_EVENT_SUCCESS,
    COM_CHAMPION_EVENT_ERROR,
} from '../../constants/common/pastChampion.const';
import histrory from '../../../History';

export const pastChampions = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_PAST_CHAMPION_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/pw-historical-champions/filter`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(champion => {
        dispatch({
            type: COM_PAST_CHAMPION_SUCCESS,
            payload: champion.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_PAST_CHAMPION_ERROR, error: error.response.data.message })
        }
    });
};

export const championsEvents = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_CHAMPION_EVENT_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/pw-historical-champions/event`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allevent => {
        dispatch({
            type: COM_CHAMPION_EVENT_SUCCESS,
            payload: allevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_CHAMPION_EVENT_ERROR, error: error.response.data.message })
        }
    });
};




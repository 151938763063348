import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { imagesArr } from "../../assets/images";
import { Link } from "react-router-dom";
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { AddPlayer } from "../../saga/actions/authantication/signup/fan/parent.action";

class inviteChild extends Component {

    constructor(props) {
        super(props)
        this.state = {
            "game-type": "",
            "gra-year": "",
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.parentInfo &&
            nextProps.parentInfo.addPlayerChildRes &&
            nextProps.parentInfo.addPlayerChildData
        ) {
            console.log("nextProps.parentInfo.addPlayerChildData:--", nextProps.parentInfo.addPlayerChildData);
            nextProps.parentInfo.addPlayerChildRes = false;
            if (nextProps.parentInfo.addPlayerChildData.flag) {
                this.commonCall.successMessage(nextProps.parentInfo.addPlayerChildData.message);
                nextProps.parentInfo.addPlayerChildData = null;
                this.props.save();
            } else {
                this.commonCall.errorMessage(nextProps.parentInfo.addPlayerChildData.message);
                nextProps.parentInfo.addPlayerChildData = null;
            }
        }
    }

    submitData = () => {
        if (this.validator.allValid()) {
            this.props.tempData["game-type"] = this.state["game-type"];
            this.props.tempData["gra-year"] = this.state["gra-year"];
            console.log("tempData:--", this.props.tempData);
            this.props.AddPlayer(this.props.tempData, 'CHILD');
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { flag } = this.props;
        const years = Array.from(new Array(14), (val, index) => ((new Date()).getFullYear() + index));
        return (
            <section className={`common-modal ${flag ? "show" : ""}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="green-header modal-header">
                            <h3>Player</h3>
                            <div className="modal_close" onClick={() => this.props.close()}>
                                <img src={imagesArr.white_modal_close} alt="modal_close" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="team-body-inner">

                                <div className="add-player">
                                    <div className='player-info'>
                                        <img src={imagesArr.ncsaLogoFullColor} />
                                        <p><b>Prospect Wire teams with Next College Student Athlete to help athletes with the dream of competing in college.</b></p>
                                    </div>
                                    <ul>
                                        <li><p>Receive a <b>FREE recruiting profile</b> visible to over 35,000 college coaches </p></li>
                                        <li><p>An NCSA recruiting expert will reach out to offer a <b>FREE recruiting assessment</b></p></li>
                                        <li><p>Receive a <b>FREE recruiting game plan</b> personalized to your child's abilities, wants and needs</p></li>
                                    </ul>
                                    <div className='player-select'>
                                        <div className='w-50'>
                                            <div className="form-group mb-0">
                                                <label className="common-lable">Primary Sport</label>
                                                <select className="form-control" name="game-type" onChange={(e) => this.setState({ "game-type": e.target.value })} value={this.state["game-type"]} id="usr" >
                                                    <option selected value="">-Select-</option>
                                                    <option value={1}>Baseball</option>
                                                    <option value={2}>Sofball</option>
                                                </select>
                                                <span className="validMsg">
                                                    {this.validator.message("Primary Sport", this.state["game-type"], "required")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='w-50'>
                                            <div className="form-group mb-0">
                                                <label className="common-lable">High School Grad Year </label>
                                                <select className="form-control" name="gra-year" onChange={(e) => this.setState({ "gra-year": e.target.value })} value={this.state["gra-year"]} id="usr" >
                                                    <option selected value="">-Select-</option>
                                                    {
                                                        years &&
                                                        years.map((y) => {
                                                            return (
                                                                <option value={y}>{y}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="validMsg">
                                                    {this.validator.message("High School Grad Year", this.state["gra-year"], "required")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <ul>
                                        <li><p>By clicking “yes” and submitting, you agree to receive personalized follow-up and marketing messages from NCSA by email, phone and automated text. Consent is not a condition of purchase. Standard rates apply.</p></li>
                                    </ul>

                                </div>

                            </div>
                            <div className="flex-align next-button">
                                <Link className="btn light-green" onClick={() => this.submitData()}>
                                    Save
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

inviteChild.propTypes = {
    AddPlayer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    parentInfo: state.parentDetails,
});

export default connect(mapStateToProps, { AddPlayer })(inviteChild);

// export default inviteChild;

import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../../../helpers/authToken';
import {
    GET_ADVANCED_SEARCH_REQUEST,
    GET_ADVANCED_SEARCH_SUCCESS,
    GET_ADVANCED_SEARCH_ERROR,

    GET_FILTER_DATA_REQUEST,
    GET_FILTER_DATA_SUCCESS,
    GET_FILTER_DATA_ERROR,
} from '../../../../constants/view/company/pwRanking/advancedSearch.const';
import histrory from '../../../../../History';

export const getAdvancedSearch = (data) => dispatch => {
    // let mode = localStorage.getItem("webglToken");
    // let setHeader;
    // if (mode) {
    //     setHeader = {
    //         'x-auth-token': Auth_Company_Key,
    //         'Accept': 'application/json'
    //     }
    //     checkIsValidCompanyToken(histrory);
    // } else {
    //     setHeader = {
    //         'Accept': 'application/json'
    //     }
    // }
    dispatch({ type: GET_ADVANCED_SEARCH_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}pwUser/advance/search/list/temp`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(search => {
        dispatch({
            type: GET_ADVANCED_SEARCH_SUCCESS,
            payload: search.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: GET_ADVANCED_SEARCH_ERROR, error: error.response.data.message })
        }
    });
};

export const getFilterValue = () => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_FILTER_DATA_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/filterList" : "pwUser/woAuth/filterList"}`,
        headers: setHeader
    }).then(filter => {
        dispatch({
            type: GET_FILTER_DATA_SUCCESS,
            payload: filter.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: GET_FILTER_DATA_ERROR, error: error.response.data.message })
        }
    });
};



import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    GET_FAC_REQUEST,
    GET_FAC_SUCCESS,
    GET_FAC_ERROR,

    GET_FAC_SEARCH_REQUEST,
    GET_FAC_SEARCH_SUCCESS,
    GET_FAC_SEARCH_ERROR,

    UP_FAC_IMAGE_REQUEST,
    UP_FAC_IMAGE_SUCCESS,
    UP_FAC_IMAGE_ERROR,

    SAVE_FAC_REQUEST,
    SAVE_FAC_SUCCESS,
    SAVE_FAC_ERROR,
} from '../../../../constants/authantication/signup/coach/facility.const';
import histrory from '../../../../../History';

export const facilityList = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_FAC_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/facilty`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(facility => {
        dispatch({
            type: GET_FAC_SUCCESS,
            payload: facility.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_FAC_ERROR, error: error.response.data.message })
    });
};

export const searchZipcode = (data) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_FAC_SEARCH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}setting/territory/zipcode/${data}`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(search => {
        dispatch({
            type: GET_FAC_SEARCH_SUCCESS,
            payload: search.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_FAC_SEARCH_ERROR, error: error.response.data.message })
    });
};

export const uploadFacImage = (fileObj) => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: UP_FAC_IMAGE_REQUEST })

    let formData = new FormData();
    formData.append('file', fileObj.imgFile);

    axios({
        method: 'POST',
        url: `${API_Url}file/upload?documentType=${fileObj.documentType}`,
        data: formData,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    }).then(image => {
        console.log("iamges:--", image);
        dispatch({
            type: UP_FAC_IMAGE_SUCCESS,
            payload: image.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: UP_FAC_IMAGE_ERROR, error: error.response.data.message })
    });
};

export const saveFacility = (data) => dispatch => {
    console.log("data:----action:---", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_FAC_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/facilty`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(fac => {
        console.log("fac:----", fac);
        dispatch({
            type: SAVE_FAC_SUCCESS,
            payload: fac.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_FAC_ERROR, error: error.response.data.message })
    });
};
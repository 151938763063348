export const PERSONALINFO_REQUEST = 'PERSONALINFO_REQUEST';
export const PERSONALINFO_SUCCESS = 'PERSONALINFO_SUCCESS';
export const PERSONALINFO_ERROR   = 'PERSONALINFO_ERROR';

export const REGISTERINFO_REQUEST ="REGISTERINFO_REQUEST";
export const REGISTERINFO_SUCCESS ="REGISTERINFO_SUCCESS";
export const REGISTERINFO_ERROR   ="REGISTERINFO_ERROR";




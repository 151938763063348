import axios from 'axios';
import { Auth_Coach_Key, Auth_Fan_Key, API_Url, checkIsValidCoachToken, checkIsValidFanToken } from '../../../../helpers/authToken';
import {
    GET_FAN_BASIC_REQUEST,
    GET_FAN_BASIC_SUCCESS,
    GET_FAN_BASIC_ERROR,

    GET_SEARCH_REQUEST,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,

    UP_COVER_IMAGE_REQUEST,
    UP_COVER_IMAGE_SUCCESS,
    UP_COVER_IMAGE_ERROR,

    SAVE_FAN_BASIC_REQUEST,
    SAVE_FAN_BASIC_SUCCESS,
    SAVE_FAN_BASIC_ERROR,

    GET_INVITE_COACH_REQUEST,
    GET_INVITE_COACH_SUCCESS,
    GET_INVITE_COACH_ERROR,

    GET_INVITE_SUB_COACH_REQUEST,
    GET_INVITE_SUB_COACH_SUCCESS,
    GET_INVITE_SUB_COACH_ERROR,

    ACCEPT_COACH_NOTIFY_REQUEST,
    ACCEPT_COACH_NOTIFY_SUCCESS,
    ACCEPT_COACH_NOTIFY_ERROR,
} from '../../../../constants/authantication/signup/fan/basic.const';
import histrory from '../../../../../History';

export const getFanBasicDetails = () => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: GET_FAN_BASIC_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/fan/parent/basic`,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: GET_FAN_BASIC_SUCCESS,
            payload: basic.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_FAN_BASIC_ERROR, error: error.response.data.message })
    });
};

export const coverFileUpload = (file, type) => (dispatch) => {
    dispatch({ type: UP_COVER_IMAGE_REQUEST })
    const formData = new FormData();
    formData.append("file", file);
    return axios({
        method: "POST",
        url: `${API_Url}file/upload?documentType=${type}`,
        data: formData,
    }).then((res) => {
        dispatch({
            type: UP_COVER_IMAGE_SUCCESS,
            payload: res.data.result,
        });
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({
                type: UP_COVER_IMAGE_ERROR,
                error: error.response.data.message,
            });
    });
};

export const saveFanBasic = (data) => dispatch => {
    console.log("saveFanBasic:--", data);
    checkIsValidFanToken(histrory)
    dispatch({ type: SAVE_FAN_BASIC_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/fan/parent/basic`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json',
        }
    }).then(basic => {
        console.log("basic:---- ", basic);
        dispatch({
            type: SAVE_FAN_BASIC_SUCCESS,
            payload: basic.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_FAN_BASIC_ERROR, error: error.response.data.message })
    });
};

export const getCoachInvitation = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_INVITE_COACH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/coach/invitation`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(invite => {
        dispatch({
            type: GET_INVITE_COACH_SUCCESS,
            payload: invite.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_INVITE_COACH_ERROR, error: error.response.data.message })
    });
};

export const getSubCoachInvitation = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_INVITE_SUB_COACH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/invitaion`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(subinvite => {
        dispatch({
            type: GET_INVITE_SUB_COACH_SUCCESS,
            payload: subinvite.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_INVITE_SUB_COACH_ERROR, error: error.response.data.message })
    });
};

export const saveCoachAccept = (data) => dispatch => {
    console.log("saveBasic:--", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: ACCEPT_COACH_NOTIFY_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/invitaion`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(acceptCoach => {
        console.log("acceptCoach:---- ", acceptCoach);
        dispatch({
            type: ACCEPT_COACH_NOTIFY_SUCCESS,
            payload: acceptCoach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: ACCEPT_COACH_NOTIFY_ERROR, error: error.response.data.message })
    });
};



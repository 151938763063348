import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { checkLoginValidation } from "../../../../../saga/helpers/authToken";
import Confirmation from "../../../../popup/confirmation";
import commonCalls from "../../../../../businesslogic/commonCalls";
import { connect } from "react-redux";
import {
  getProfile,
  getPlayerProfile,
  getFanProfile,
} from "../../../../../saga/actions/containers/header.action";
import {
  eventRegistrationPayment,
  fetchPaymentIntent,
  downloadScoutPackage,
} from "../../../../../saga/actions/common/eventDetails.action";
import CreditCard from "../../payment/creditCard";
import Preloader from "../../../../../components/preloader";
import ClaimAccount from "../../../../popup/claimAccount";
import CreateChildAccount from "../../../../popup/createChildAccount";

class eventRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closePopup: false,
      hostName: "",
      dob: "",
      eventJoin: {
        event: "",
        isManager: true,
        managerName: "",
        managerEmail: "",
        managerPhone: "",
        waiversImg: "",
        insuranceImg: "",
        paymentType: 0,
        couponCode: "",
        metaPayment: {},
        paymentMethod: "",
        amount: 0,
        question: "",
        products: [],
        positionRegistration: false,
        pitcherRegistration: false,
        twoWayRegistration: false,
        analyzrReportpay: false,
        ageGroup: "",
        ageGroupTo: "",
        waiverUrlClick: "no",
        slectedGroupFeeId: "",
        companyThrough: localStorage.getItem("company") === "dc" ? 2 : 1,
      },
      loader: false,
      creditCard: false,
      claimPopup: false,
      createChildPopup: false,
    };
    this.commonCall = new commonCalls();
  }

  componentDidMount = () => {
    let url = new URL(document.location);
    let host = url.hostname;
    if (localStorage.getItem("coachToken") !== null) {
      this.props.getProfile();
    }
    if (localStorage.getItem("playerToken") !== null) {
      this.props.getPlayerProfile();
    }
    if (localStorage.getItem("fanToken") !== null) {
      this.props.getFanProfile();
    }
    this.setState({
      hostName: host,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.profileDetails &&
      nextProps.profileDetails.headData
      // nextProps.profileDetails.headReq
    ) {
      // nextProps.profileDetails.headReq = false;
      if (nextProps.profileDetails.headData.result.profile) {
        let profile = nextProps.profileDetails.headData.result.profile;
        this.setState(
          {
            dob: profile.dob,
          },
          () => {
            // nextProps.profileDetails.headData = null
          }
        );
      }
    }

    if (
      nextProps.profileDetails &&
      nextProps.profileDetails.headPlayerData
      // nextProps.profileDetails.headPlayerReq
    ) {
      // nextProps.profileDetails.headPlayerReq = false;
      if (nextProps.profileDetails.headPlayerData.result.profile) {
        let profile = nextProps.profileDetails.headPlayerData.result.profile;
        this.setState(
          {
            dob: profile.dob,
          },
          () => {
            nextProps.profileDetails.headPlayerData = null;
          }
        );
      }
    }

    if (
      nextProps.profileDetails &&
      nextProps.profileDetails.headFanData
      // nextProps.profileDetails.headFanReq
    ) {
      // nextProps.profileDetails.headFanReq = false;
      if (nextProps.profileDetails.headFanData.result.profile) {
        let profile = nextProps.profileDetails.headFanData.result.profile;
        this.setState(
          {
            dob: profile.dob,
          },
          () => {
            // nextProps.profileDetails.headFanData = null
          }
        );
      }
    }

    if (
      nextProps.eventDetails &&
      nextProps.eventDetails.getPaymentIntentReq &&
      nextProps.eventDetails.getPaymentIntentData
    ) {
      nextProps.eventDetails.getPaymentIntentReq = false;
      if (nextProps.eventDetails.getPaymentIntentData.flag) {
        localStorage.setItem(
          "stripeIntent",
          nextProps.eventDetails.getPaymentIntentData.result.data
        );
        nextProps.eventDetails.getPaymentIntentData = null;
        this.setState(
          {
            creditCard: true,
            loader: false,
          },
          () => {
            this.setLoader(false);
          }
        );
      } else {
        this.commonCall.errorMessage(
          nextProps.eventDetails.getPaymentIntentData.message
        );
        nextProps.eventDetails.getPaymentIntentData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.eventDetails && nextProps.eventDetails.downloadScoutPackage) {
      if (nextProps.eventDetails.downloadScoutPackage.flag) {
        const link = document.createElement("a");
        link.href = nextProps.eventDetails.downloadScoutPackage.result.data;
        link.click();
        this.setLoader(false);
        nextProps.eventDetails.downloadScoutPackage = null;
      } else {
        this.commonCall.errorMessage(
          nextProps.eventDetails.downloadScoutPackage.message
        );
        nextProps.eventDetails.downloadScoutPackage = null;
        this.setLoader(false);
      }
    }
  };

  calculate_age_between = (dob1) => {
    let today = new Date(),
      //birthay has 'Dec 25 1998'
      dob = new Date(dob1),
      //difference in milliseconds
      diff = today.getTime() - dob.getTime(),
      //convert milliseconds into years
      years = Math.floor(diff / 31556736000),
      //1 day has 86400000 milliseconds
      days_diff = Math.floor((diff % 31556736000) / 86400000),
      //1 month has 30.4167 days
      months = Math.floor(days_diff / 30.4167),
      days = Math.floor(days_diff % 30.4167);
    if (years === 14 && months >= 0 && days >= 0) return true;
    else if (years >= 14 && years <= 17) {
      return true;
    } else {
      return false;
    }
    // var ageDifMs = Date.now() - new Date(dob1).getTime();
    // var ageDate = new Date(ageDifMs); // miliseconds from epoch
    // return (Math.abs(ageDate.getUTCFullYear() - 1970) >= 15 && Math.abs(ageDate.getUTCFullYear() - 1970) <= 18);
  };

  requestRegister = (data) => {
    let url = new URL(document.location);
    let eventId = url.pathname.split("/")[2];
    this.setState(
      {
        eventJoin: {
          ...this.state.eventJoin,
          event: eventId,
        },
      },
      () => {
        this.setLoader(true);
        this.props.eventRegistrationPayment(this.state.eventJoin);
      }
    );
  };

  payment = () => {
    var isloggedIn = checkLoginValidation();
    if (isloggedIn) {
      if (this.props.dataList?.hasPlayerList || localStorage.getItem("playerToken")) {
        if (this.props.dataList.settings.redirectURL) {
          window.open(this.props.dataList.linkUrl, "_blank");
        } else {
          let svalid_date = moment(this.props.dataList.startDate).format(
            "MM/DD/YYYY"
          );
          let evalid_date = moment(this.props.dataList.regClosedDate).format(
            "MM/DD/YYYY"
          );

          let chkdate = moment(svalid_date).isAfter(evalid_date);

          if (chkdate) {
            if (
              this.props.dataList.eventTeamType === 1 &&
              this.props.teamArr.length === 0
            ) {
              this.commonCall.errorMessage(
                "You need to tie up with one team before doing event registration."
              );
            } else {
              // if (!this.calculate_age_between(this.state.dob) && this.props.dataList.isCollegeShowcase) {
              //     this.commonCall.errorMessage("College students are only eligible to do registration for this event. To register eligible age group is 14U to 17U.");
              // } else {
              this.props.history.push(`/event-form/${this.props.eventId}`);
              // }
            }
          } else {
            this.setState({
              closePopup: true,
            });
          }
        }
      } else {
        this.openCreateChild();
      }
    } else {
      localStorage.setItem("eventDetail", this.props.eventId);
      this.props.history.push("/login");
    }
  };

  payDirect = () => {
    var isloggedIn = checkLoginValidation();
    if (isloggedIn) {
      let svalid_date = moment(this.props.dataList.startDate).format(
        "MM/DD/YYYY"
      );
      let evalid_date = moment(this.props.dataList.regClosedDate).format(
        "MM/DD/YYYY"
      );

      let chkdate = moment(svalid_date).isAfter(evalid_date);

      if (chkdate) {
        this.props.paymentIntent();
      } else {
        this.setState({
          closePopup: true,
        });
      }
    } else {
      localStorage.setItem("eventDetail", this.props.eventId);
      localStorage.setItem("eventDirectPayment", true);
      this.props.history.push("/login");
    }
  };

  isShowJoin = () => {
    const event = this.props.dataList;
    const profile = this.props?.profileDetails?.headData?.result?.profile;
    // let svalid_date = moment().format("MM/DD/YYYY");
    // let evalid_date = moment(event.regClosedDate).format("MM/DD/YYYY");
    // let chkdate = moment(svalid_date).isSameOrBefore(evalid_date);

    if (event.eventTeamType === 1) {
      if (
        localStorage.getItem("playerToken") ||
        localStorage.getItem("rosterToken")
      ) {
        return null;
      } else {
        if (event.regCountDown === "Registration Closed") {
          if (this.props.complete) return;
          return <p className="mb-0">Registration closed.</p>;
        } else {
          if (
            event.selPer === 4 &&
            localStorage.getItem("webglToken") !== null
          ) {
            return this.props.isHideJoinButton ? (
              ""
            ) : (
              <button
                className="btn light-green"
                style={{ cursor: "not-allowed" }}
              >
                Joined
              </button>
            );
          } else {
            if (!this.props.directPay) {
              if (event.eventPriority === 1) {
                return this.props.isHideJoinButton ? (
                  ""
                ) : (
                  <button className="btn light-green" onClick={this.payment}>
                    Register
                  </button>
                );
              }
            } else {
              return (
                <button
                  className="btn light-green"
                  onClick={() => this.payDirect()}
                >
                  Pay
                </button>
              );
            }
          }
        }
      }
    }
    if (
      event.eventTeamType === 2 &&
      (((profile?.role === "Coach" &&
        (profile?.parentTeam?.type == 4 || profile?.parentTeam?.type == 1)) ||
        localStorage.getItem("role")==="Parent") || localStorage.getItem("playerToken") )
    ) {
      // if (localStorage.getItem("coachToken")) {
      //     return null;
      // } else {
      if (event.regCountDown === "Registration Closed") {
        if (this.props.complete) return;
        return <p className="mb-0">Registration closed.</p>;
      } else {
        if (event.selPer === 4 && localStorage.getItem("webglToken") !== null) {
          return this.props.isHideJoinButton && !localStorage.getItem("playerToken") ? (
            ""
          ) : (
            <>
            {event.selPer == 4 && localStorage.getItem("playerToken") ? (
              <button
                className="btn light-green"
                style={{ cursor: "not-allowed" }}
              >
                Joined
              </button>
            ):(
              <button className="btn light-green" onClick={this.payment}>
                Register
              </button>
            )}
            </>
          );
        } else {
          if (!this.props.directPay) {
            if (event.eventPriority === 1) {
              return this.props.isHideJoinButton ? (
                ""
              ) : (
                <button className="btn light-green" onClick={this.payment}>
                  Register
                </button>
              );
            } else {
              return event.regRequest === 0 ? (
                <button
                  className="btn light-green"
                  onClick={this.requestRegister}
                >
                  Request to Register
                </button>
              ) : event.regRequest === 1 ? (
                <button
                  className="btn light-green"
                  style={{ cursor: "not-allowed" }}
                >
                  Request Pending
                </button>
              ) : this.props.isHideJoinButton ? (
                ""
              ) : (
                <button className="btn light-green" onClick={this.payment}>
                  Register
                </button>
              );
            }
          } else {
            return (
              <button
                className="btn light-green"
                onClick={() => this.payDirect()}
              >
                Pay
              </button>
            );
          }
        }
      }
      // }
    }
  };

  requestRegister = () => {
    let url = new URL(document.location);
    let eventId = url.pathname.split("/")[2];
    this.setState(
      {
        ...this.state,
        eventJoin: {
          ...this.state.eventJoin,
          event: eventId,
        },
      },
      () => {
        this.props.requestToRegister(this.state.eventJoin);
      }
    );
  };

  clickClosePopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      closePopup: false,
    });
  };

  redirectRecapinfo = () => {
    if (localStorage.getItem("company") === "pws") {
      if (
        this.props.dataList.settings["3rdPartyUrl"] &&
        this.props.dataList.settings["is3rdPartyUrl"]
      ) {
        window.open(this.props.dataList.settings["3rdPartyUrl"], "_blank");
      } else {
        localStorage.setItem("recapInfo", JSON.stringify(this.props.dataList));
        this.props.history.push({
          pathname: `/${localStorage.getItem("company")}-event-info/${
            this.props.dataList._id
          }`,
        });
      }
    } else {
      localStorage.setItem("recapInfo", JSON.stringify(this.props.dataList));
      this.props.history.push({
        pathname: `/${localStorage.getItem("company")}-event-info/${
          this.props.dataList._id
        }`,
      });
    }
  };

  digitalScout = () => {
    var isloggedIn = checkLoginValidation();
    if (isloggedIn) {
      if (
        !this.props.dataList.scoutPacketBuy &&
        !this.props.dataList.settings.isScoutFree
      ) {
        var payment = {
          amount: this.props.dataList.settings.scoutPrice,
          objetId: this.props.dataList._id,
          module: 2,
          method: "stripe",
        };
        console.log("payment:---", payment);
        this.setLoader(true);
        this.props.fetchPaymentIntent(payment);
        // this.props.onHandleChange(e)
      } else {
        this.setLoader(true);
        this.props.downloadScoutPackage(this.props.dataList._id);
      }
    } else {
      localStorage.setItem("eventDetail", this.props.eventId);
      this.props.history.push("/login");
    }
  };

  stripePaymentSuccess = (payment) => {
    this.setState(
      {
        creditCard: false,
        loader: false,
      },
      () => {
        document.body.classList.remove("modal-open");
        let req = {
          amount: this.props.dataList.settings.scoutPrice,
          metaPayment: payment,
          event: this.props.dataList._id,
          type: 1,
        };
        this.props.buyScoutPackage(req);
      }
    );
  };

  stripePaymentError = (error) => {
    this.commonCall.errorMessage(error);
    this.setLoader(false);
  };

  onHandleClose = () => {
    this.setState(
      {
        creditCard: false,
      },
      () => {
        document.body.classList.remove("modal-open");
      }
    );
  };

  setLoader = (val) => {
    this.setState({
      loader: val,
    });
  };

  openCliamAccount = () => {
    this.setState({
      claimPopup: true,
    });
  };

  closeClaimAccount = () => {
    this.setState({
      claimPopup: false,
    });
  };

  openCreateChild = () => {
    this.setState({
      createChildPopup: true,
    });
  };

  closeAddChildPopup = () => {
    document.body.classList.remove("show");
    this.setState({
      createChildPopup: false,
    });
  };

  render() {
    const {
      dataList,
      complete,
      showAmount,
      directPay,
      viewSchedulingBtn,
      profileDetails,
    } = this.props;
    const { closePopup, hostName, creditCard, loader, createChildPopup } =
      this.state;
    const location =
      dataList?.location && dataList?.location.streetAddress.split(",");
    let totalTeam = dataList?.payment?.feesByGroups.reduce(function (
      sum,
      current
    ) {
      return sum + current.teamLimit;
    },
    0);
    // let svalid_date = moment().format("MM/DD/YYYY");
    // let evalid_date = moment(dataList?.regClosedDate).format("MM/DD/YYYY");
    // let chkdate = moment(svalid_date).isBefore(evalid_date);

    return (
      <>
        <div className="web_eventOuterList">
          <div className="web_eventlist_wrapper d-flex flex-wrap align-items-center">
            <div
              className={
                dataList &&
                (localStorage.getItem("company") === "pioneer" ||
                  dataList?.isNewCompanyEvent)
                  ? "web_eventList_img web_tryout_img"
                  : "web_eventList_img"
              }
            >
              <img
                src={
                  dataList &&
                  dataList?.eventImages &&
                  dataList?.eventImages.filePath
                }
                alt="event banner"
              />
              {dataList?.location &&
                (dataList?.location.city ||
                  dataList?.location.streetAddress ||
                  dataList?.location.stateRegion) && (
                  <span className="event_card_address">
                    {dataList?.location && dataList?.location.city
                      ? dataList?.location.city
                      : dataList?.location.streetAddress
                      ? location[0]
                      : dataList?.location.stateRegion}
                  </span>
                )}
            </div>
            <div className="web_eventList_content">
              <div className="web_eventList_data">
                <div class="web_eventList_logo">
                  <img
                    src={
                      dataList &&
                      dataList?.eventLogo &&
                      dataList?.eventLogo.filePath
                    }
                    alt="event logo"
                  />
                </div>
                <div className="web_eventList_detail">
                  <h1>{dataList && dataList?.eventTitle}</h1>
                  {/* {
                                        directPay
                                        || (dataList?.eventPriority === 1 && dataList?.eventTeamType === 1)
                                         ?
                                            <h1>{dataList && dataList.eventTitle}ggg</h1>
                                            :
                                            <h1 onClick={chkdate && this.isShowJoin() && dataList.selPer !== 4 ? this.payment : ""}>{dataList && dataList.eventTitle}iuuu</h1>
                                    } */}
                  <div className="web_eventList_icons d-flex flex-wrap">
                    <span class="common_softball_icon d-flex align-items-center">
                      {dataList && dataList?.eventType === 1
                        ? "Baseball"
                        : "Softball"}
                    </span>
                    <span class="common_date_icon d-flex align-items-center">
                      {moment(dataList?.startDate).format("DD-MMM-YYYY")} -{" "}
                      {moment(dataList?.endDate).format("DD-MMM-YYYY")}
                    </span>
                    <span class="common_team_icon d-flex align-items-center">
                      {totalTeam}{" "}
                      {dataList?.eventTeamType === 1 ? "Teams" : "Players"}
                    </span>
                  </div>
                </div>
              </div>
              {/* {
                                dataList?.eventTeamType === 1 &&
                                dataList?.publish &&
                                localStorage.getItem("company") !== "gob" &&
                                !viewSchedulingBtn && */}

              {this.props.directPay && !this.props.alreadyPaid ? (
                <div className="web_eventList_join">
                  <h2>
                    <span></span> $
                    {this.props.directAmout
                      ? this.props.directAmout
                      : dataList?.payment?.feesTournament}
                  </h2>
                  <button
                    className="btn light-green"
                    onClick={() => this.payDirect()}
                  >
                    Pay
                  </button>
                </div>
              ) : (
                <div className="web_eventList_join">
                  {dataList?.eventTeamType === 1 ? (
                    <>
                      {dataList?.sheduleLive &&
                        localStorage.getItem("company") !== "gob" &&
                        !viewSchedulingBtn && (
                          <button
                            className="btn light-green"
                            onClick={() => this.redirectRecapinfo()}
                          >
                            Tournament Hub
                          </button>
                        )}

                      {dataList?.sheduleLive &&
                        dataList?.settings.scoutPacketPublish &&
                        dataList?.settings.scoutPackage && (
                          <button
                            className="btn light-green"
                            onClick={() => this.digitalScout()}
                          >
                            Digital Scout Packet{" "}
                            {!dataList?.settings.isScoutFree &&
                            dataList?.settings.scoutPrice
                              ? `($${dataList?.settings.scoutPrice})`
                              : ""}
                          </button>
                        )}

                      <button
                        className="btn light-green"
                        onClick={() => this.openCliamAccount()}
                      >
                        Claim Your PLAYERS Accounts Create Guardian Accounts
                      </button>
                    </>
                  ) : dataList?.eventTeamType === 2 ? (
                    !this.props.directPay && !this.props.alreadyPaid ? (
                      <>
                        {!complete &&
                          showAmount &&
                          dataList.regCountDown !== "Registration Closed" && (
                            <h2>
                              <span>Starting At</span> $
                              {dataList?.payment?.feesTournament}
                            </h2>
                          )}
                        {this.isShowJoin()}
                        {dataList?.settings.scoutPacketPublish &&
                          dataList?.settings.scoutPackage && (
                            <button
                              className="btn light-green"
                              onClick={() => this.digitalScout()}
                            >
                              Digital Scout Packet{" "}
                              {!dataList?.settings.isScoutFree &&
                              dataList?.settings.scoutPrice
                                ? `(${dataList?.settings.scoutPrice}$)`
                                : ""}
                            </button>
                          )}
                        {complete &&
                          profileDetails?.headData?.result?.profile?.role ===
                            "Player" && (
                            <button class="btn light-green">Joined</button>
                          )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {this.props.directPay && this.props.alreadyPaid && (
                    <button
                      className="btn light-green"
                      style={{ cursor: "not-allowed" }}
                    >
                      Joined
                    </button>
                  )}
                </div>
              )}
              {/* } */}
              {/* {
                                dataList?.eventTeamType === 1 &&
                                dataList?.settings.scoutPacketPublish && dataList?.settings.scoutPackage &&
                                <div className="web_eventList_join"> <button className="btn light-green" onClick={() => this.digitalScout()}>Digital Scout Package</button></div>
                            } */}

              {/* {
                                this.props.directPay && !this.props.alreadyPaid &&
                                <div className="web_eventList_join">
                                    <h2><span></span> ${this.props.directAmout ? this.props.directAmout : dataList?.payment?.feesTournament}</h2>
                                    <button className="btn light-green" onClick={() => this.payDirect()} >Pay</button>
                                </div>
                            } */}
              {/* {
                                this.props.directPay && this.props.alreadyPaid &&
                                <button className="btn light-green" style={{ cursor: 'not-allowed' }} >Joined</button>
                            } */}
            </div>
          </div>
        </div>
        {closePopup && (
          <Confirmation
            flag={closePopup}
            type="regiClose"
            close={this.clickClosePopup}
          />
        )}
        {createChildPopup && (
          <CreateChildAccount
            flag={createChildPopup}
            close={this.closeAddChildPopup}
          />
        )}
        {creditCard && (
          <CreditCard
            flag={creditCard}
            close={this.onHandleClose}
            stripePaymentSuccess={this.stripePaymentSuccess}
            stripePaymentError={this.stripePaymentError}
            amount={25}
            setLoader={this.setLoader}
          />
        )}
        {this.state.claimPopup && (
          <ClaimAccount
            flag={this.state.claimPopup}
            close={this.closeClaimAccount}
            dataList={dataList}
          />
        )}

        <Preloader flag={loader} />
      </>
    );
  }
}

eventRow.propTypes = {
  getProfile: PropTypes.func.isRequired,
  getPlayerProfile: PropTypes.func.isRequired,
  getFanProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileDetails: state.headerData,
  eventDetails: state.eventDetails,
  loginDetails: state.loginData,
});

export default withRouter(
  connect(mapStateToProps, {
    getProfile,
    getPlayerProfile,
    getFanProfile,
    eventRegistrationPayment,
    fetchPaymentIntent,
    downloadScoutPackage,
  })(eventRow)
);

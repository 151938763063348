import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../../../../helpers/authToken';
import {
    GET_MAIN_BOARD_LIST_REQUEST,
    GET_MAIN_BOARD_LIST_SUCCESS,
    GET_MAIN_BOARD_LIST_ERROR,

    GET_FIELDS_REQUEST,
    GET_FIELDS_SUCCESS,
    GET_FIELDS_ERROR,
} from '../../../../../constants/view/company/pwRanking/leaderboard/mainBoard.const';
import histrory from '../../../../../../History';

export const getMainBoard = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_MAIN_BOARD_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/leaderboard/list" : "pwUser/woAuth/leaderboard/list"}`,
        data: data,
        headers: setHeader
    }).then(mainboard => {
        dispatch({
            type: GET_MAIN_BOARD_LIST_SUCCESS,
            payload: mainboard.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_MAIN_BOARD_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const getFields = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_FIELDS_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}${mode ? `pwUser/leaderboard/field/list?metricType=${data.metricType}&metricSubType=${data.metricSubType}&companyId=${data.companyId}` : `pwUser/leaderboard/field/list?metricType=${data.metricType}&metricSubType=${data.metricSubType}&companyId=${data.companyId}`}`,
        headers: setHeader
    }).then(mainboard => {
        dispatch({
            type: GET_FIELDS_SUCCESS,
            payload: mainboard.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_FIELDS_ERROR, error: error.response.data.message })
        }
    });
};



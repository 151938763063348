import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from '../../../saga/actions/containers/header.action';
import {
  getPersonalInfo,
  postPersonalInfo,
} from "../../../saga/actions/views/coach/profile/personalInfo.action";
import { REGISTERINFO_SUCCESS } from '../../../saga/constants/view/coach/profile/personalInfo.const';
import { FILEUPLOAD_SUCCESS, ZIPCODE_SUCCESS } from '../../../saga/constants/common/api.const';
import { getCountryCode, getZipcodeInfo, postFileUpload } from "../../../saga/actions/common/api.action";
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from "simple-react-validator";
import PhoneVerify from '../../popup/phoneVerify';
import moment from "moment";
import Preloader from '../../../components/preloader';
import ImageCrop from '../../popup/imageCrop';
import DatePicker from "react-datepicker";
import ChangePassword from "../../popup/changePassword";
import Geosuggest from "react-geosuggest";
import { StatesObj } from "../../../businesslogic/content";

const initialState = {
  fullName: "",
  dob: "",
  phone: "",
  email: "",
  bio: "",
  zipcode: "",
  city: "",
  cCode: "+1",
  state: "",
  gameType: "",
  profileUrl: "",
  fetching: false,
  personalLogoId: "",
  verifyPopup: false,
  verifyToken: "",
  uid: "",
  cropPopup: false,
  renderFile: "",
  imgObj: "",
  loader: false,
  isImport: false,
  calOpen: false,
  openChangePassword: false,
  isDOBupdate: false,
  streetAddress: "",
  isEditZipcode: false,
  isMLB:false
};

const reducer = (state, payload) => ({ ...state, ...payload });

function PersonalInfo() {
  const [personal, setPersonal] = useReducer(reducer, initialState);
  const commonCall = new commonCalls();
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const {
    fullName,
    dob,
    phone,
    email,
    bio,
    zipcode,
    city,
    cCode,
    state,
    gameType,
    profileUrl,
    fetching,
    personalLogoId,
    verifyPopup,
    verifyToken,
    uid,
    cropPopup,
    renderFile,
    imgObj,
    loader,
    isImport,
    calOpen,
    openChangePassword,
    isDOBupdate,
    isMLB
  } = personal;
  const { personalInfoData: _PI, registerInfoData: _REG } = useSelector((state) => state.personalInfo);
  const { headData: _USER } = useSelector((state) => state.headerData);
  const { countryCodeData: _CC } = useSelector((state) => state.api);

  const { zipcodeData: _zipcode, fileUploadData: _LOGO } = useSelector(
    (state) => state.api
  );

  useEffect(() => {
    setLoader(true);
    getPersonalInfo()(dispatch);
    getCountryCode()(dispatch);
  }, []);

  useEffect(() => {
    if (_PI) {
      console.log("_pi:---", _PI.dob);
      if (_PI.fname) {
        setPersonal({
          fullName: _PI.fname + " " + _PI.lname,
          dob: _PI.hasOwnProperty("dob") ? _PI.dob ? new Date(_PI.dob) : "" : "",
          isDOBupdate: _PI.hasOwnProperty("dob") ? _PI.dob ? true : false : false,
          phone: _PI.phone,
          email: _PI.email,
          bio: _PI.bio,
          zipcode: _PI?.address?.zipcode,
          state: _PI?.address?.state,
          city: _PI?.address?.city,
          profileUrl: _PI.profileUrl && _PI.profileUrl.filePath,
          personalLogoId: _PI.profileUrl && _PI.profileUrl._id,
          cCode: _PI.cCode,
          gameType: _PI["game-type"],
          fetching: true,
          isImport: _PI.hasOwnProperty("isImport") ? _PI.isImport === 1 ? true : false : false,
          isMLB:_PI.organization && _PI.isMLB ? true : false
        });
      }
      setLoader(false);
    }
  }, [_PI]);

  useEffect(() => {
    if (_USER) {
      console.log("_USER:---", _USER);
      if (_USER.flag) {
        setPersonal({
          ...personal,
          uid: _USER.result._id
        });
      }
    }
  }, [_USER]);

  useEffect(() => {
    if (_REG) {
      if (_REG.flag) {
        console.log("responce::---", _REG);
        commonCall.successMessage(_REG.message);
        setLoader(false);
        if (_REG.result.hasOwnProperty('mobile_no_update')) {
          setPersonal({
            verifyPopup: true,
            verifyToken: _REG.result.updatePhoneToken,
            isEditZipcode: false
          });
        } else {
          setPersonal({
            isDOBupdate: false,
            isEditZipcode: false
          })
          getProfile()(dispatch);
        }
      } else if (!_REG.flag) {
        commonCall.errorMessage(_REG.message)
        setLoader(false);
      }
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: null,
      });
    }
  }, [_REG])

  useEffect(() => {
    if (_LOGO) {
      console.log("logo:--", _LOGO);
      setPersonal({
        personalLogoId: _LOGO._id,
        profileUrl: _LOGO.filePath,
        imgObj: "",
        cropPopup: false,
        renderFile: ""
      });
      document.body.classList.remove('modal-open');
      setLoader(false);
      dispatch({
        type: FILEUPLOAD_SUCCESS,
        payload: null,
      });
    }
  }, [_LOGO]);

  useEffect(() => {
    if (_zipcode) {
      if (_zipcode.flag) {
        setPersonal({
          state: _zipcode.result.state,
          city: _zipcode.result.city,
          zipcode: _zipcode.result.zipcodestr
        });
        setLoader(false);
      } else {
        commonCall.errorMessage(_zipcode.message);
        setPersonal({
          state: "",
          city: "",
        });
        dispatch({
          type: ZIPCODE_SUCCESS,
          payload: null,
        });
        setLoader(false);
      }
    }
  }, [_zipcode]);

  const setLoader = (val) => {
    setPersonal({
      loader: val
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "zipcode") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 9) {
        if (re.test(value) || value.length <= 0) {
          setPersonal({
            ...personal,
            [name]: value,
          });
        }
      }
    } else if (name === "phone") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 12) {
        if (re.test(value) || value.length <= 0) {
          setPersonal({
            ...personal,
            [name]: value,
          });
        }
      }
    } else {
      setPersonal({
        ...personal,
        [name]: value,
      });
    }
  };

  const changeImage = (event) => {
    let file = event.target.files[0];
    console.log("file:--", URL.createObjectURL(file));
    if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png") {
      return commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
    }
    setPersonal({
      ...personal,
      imgObj: file,
      cropPopup: true,
      renderFile: URL.createObjectURL(file)
    });
  };

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    setPersonal({
      cropPopup: false
    });
  }

  const onHadleObject = (obj) => {
    setLoader(true);
    postFileUpload(obj.file, 16)(dispatch);
  }

  const handleSumbit = () => {
    let post_FI;
    let name = fullName.split(" ")
    post_FI = {
      fname: name[0],
      lname: name[1] ? name[1] : "",
      email: email,
      phone: phone,
      cCode: "+1",
      profilePic: personalLogoId,
      bio: bio,
      dob: moment(dob).toDate(),
      "game-type": gameType,
      address: {
        city: city,
        state: state,
        zipcode: zipcode,
      },
    };
    // if (!isImport) { delete post_FI.dob }

    console.log("post_FI:---", post_FI);
    handlePostData(post_FI);
  };

  const handlePostData = async (data) => {
    console.log("data:---", data);
    try {
      if (validator.current.allValid()) {
        setLoader(true);
        await postPersonalInfo(data)(dispatch);
      } else {
        validator.current.showMessages(true);
        forceUpdate(1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeImage = () => {
    setPersonal({
      profileUrl: "",
      personalLogoId: ""
    });
  }

  const closeVerify = () => {
    document.body.classList.remove('modal-open');
    setPersonal({
      verifyPopup: false,
    });
    getPersonalInfo()(dispatch);
  }

  const getZip = (zipcode) => {
    if (zipcode.length !== 0) {
      setLoader(true);
      getZipcodeInfo(zipcode)(dispatch);
    }
  }

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  const onChange = (dob) => {
    setPersonal({
      ...personal,
      dob: dob,
    });
  }

  const closeChangePass = () => {
    document.body.classList.remove("modal-open");
    setPersonal({
      ...personal,
      openChangePassword: false
    });
  };

  const handleSelectGeoSuggest = (place) => {
    if (place) {
      var componentForm = {
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
      };

      for (var i = 0; i < place.gmaps.address_components.length; i++) {
        var addressType = place.gmaps.address_components[i].types[0];
        if (componentForm[addressType]) {
          var val = place.gmaps.address_components[i][componentForm[addressType]];
          if (val)
            componentForm[addressType] = val
        }
      }
      var location = {
        streetAddress: place.gmaps.formatted_address,
        city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
        postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
        latitude: place.location.lat || 42.2538048,
        longitude: place.location.lng || -83.5735806
      }

      if (location.stateRegion) {
        setPersonal({
          state: location.stateRegion,
          city: location.city,
          zipcode: location.postalCode,
          isEditZipcode : location.postalCode ? false : true
        });
      } else {
        commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
        setPersonal({
          state: "",
          zipcode: "",
          isEditZipcode : false
        });
      }
    } else {
      setPersonal({
        city: "",
      });
    }
  }

  const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return (
    <>
      <section className="before-dashboard">
        <div className="white-bg">
          <form>
            <div className="edit-50">
              <div className="w-50">
                <label className="common-lable">Upload profile pic</label>
                <div className="dragdrop-img">
                  <div className="create_event_fileUpload file_custom_upload text-center">
                    <i className="fas fa-cloud-upload-alt"></i>
                    {!profileUrl && <p>Click to upload profile picture</p>}
                    {/* .heic,.HEIC, */}
                    <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={changeImage} />
                    {
                      profileUrl &&
                      <div className="preview_img">
                        <i className="fas fa-times" onClick={closeImage}> </i>
                        <img className="preview_img_inner" src={profileUrl} />
                      </div>
                    }
                  </div>
                </div>
                {/* <span className="validMsg">
                  {validator.current.message("Profile pic", profileUrl, "required")}
                </span> */}
                <label className="common-lable">Full name</label>
                <div className="form-group mb-0">
                  <input type="text" className="form-control" id="usr" name="fullName" placeholder="Full Name" value={fullName} onChange={handleChange} />
                </div>
                <span className="validMsg">
                  {validator.current.message("Full name", fullName, "required")}
                </span>
                <label className="common-lable">Date of Birth</label>
                <div className="form-group mb-0">
                  <div className="bc_relative">
                    <DatePicker
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                      }) => (
                        <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                          <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                          <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                            {years.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                            {months.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                        </div>
                      )}
                      selected={dob}
                      maxDate={moment().subtract(1, 'days').toDate()}
                      onChangeRaw={(e) => handleDateChangeRaw(e)}
                      onChange={(e) => onChange(e)}
                      placeholderText="Enter your birth date"
                      disabled={(!isImport && isDOBupdate) ? true : false}
                      open={calOpen}
                    />
                    <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" onClick={() => !isImport ? "" : setPersonal({ ...personal, calOpen: !calOpen })} />
                  </div>
                </div>
                <span className="validMsg">
                  {validator.current.message("Date of Birth", dob, "required")}
                </span>
                <label className="common-lable">Mobile number</label>
                <div className="form-group mb-0">
                  <div className="svg_icon">
                    <div className="input-group">
                      <div className="counrty-no">
                        <select className="custom-select" name="cCode" value={cCode} onChange={handleChange}>
                          <option value="+1" selected={cCode === "+1" ? "selected" : ""}>
                            +1
                          </option>
                          {/* {_CC &&
                            _CC.length !== 0 &&
                            _CC.map((e, index) => {
                              return (<option key={index} value={e.code}>{e.code}</option>)
                            })
                          } */}
                        </select>
                      </div>
                      <input type="text" className="form-control" id="usr" placeholder="Enter mobile number" name="phone" value={phone} onChange={handleChange} />
                      <img src={imagesArr.smartphone} alt="eye" />
                    </div>
                    {/* <span className="validMsg">
                      {validator.current.message("Mobile number", phone, "required|phone")}
                    </span> */}
                  </div>
                </div>
                {
                  isMLB === false &&
                  <>
                    <label className="common-lable">
                      What sport do you manage or coach?
                    </label>
                    <div className="check_box">
                      <div className="checkbox">
                        <input type="radio" id="1" name="gameType" value="1" checked={gameType === "1" && true} onChange={handleChange} />
                        <label htmlFor="Baseball">Baseball </label>
                      </div>
                      <div className="checkbox mr-0">
                        <input type="radio" id="2" name="gameType" value="2" checked={gameType === "2" && true} onChange={handleChange} />
                        <label htmlFor="Softball">Softball </label>
                      </div>
                    </div>
                    <span className="validMsg">
                      {validator.current.message(
                        "Sport",
                        gameType,
                        "required"
                      )}
                    </span>
                  </>
                }
              </div>
              <div className="w-50">
                <label className="common-lable">Email address</label>
                <div className="form-group mb-0">
                  <div className="serch-box">
                    <input
                      type="email"
                      className="form-control"
                      id="usr"
                      placeholder="Enter email address"
                      value={email}
                      disabled
                      name="email"
                    />

                    <Link className="serch-btn input-email-btn" onClick={() => setPersonal({ ...personal, openChangePassword: true })}>Change Password</Link>
                  </div>
                </div>
                <span className="validMsg">
                  {validator.current.message(
                    "Email",
                    email,
                    "required"
                  )}
                </span>
                {/* <label className="common-lable">Describe yourself</label>
                <textarea
                  id="subject"
                  rows="6"
                  name="Message"
                  placeholder="Tell us about yourself"
                  className="form-control"
                  value={bio}
                  onChange={handleChange}
                  name="bio"
                ></textarea>
                {/* <span className="validMsg">
                  {validator.current.message("Description", bio, "required")}
                </span> */}


                <label className="common-lable">
                  City <span>( To Look Up State and Zipcode )</span>
                </label>
                <div className="form-group mb-0">
                  <div className="serch-box">
                    <Geosuggest
                      // ref={el => this._geoSuggest = el}
                      onSuggestSelect={handleSelectGeoSuggest}
                      initialValue={city || ""}
                      autoComplete="off"
                      types={['(cities)']}
                    />
                    {/* <input`
                      type="text"
                      className="form-control"
                      id="zipcode"
                      placeholder="Zip Code"
                      value={zipcode}
                      name="zipcode"
                      onChange={handleChange}
                    />
                    <Link onClick={() => getZip(zipcode)} className="serch-btn">  Next</Link> */}
                    <span className="validMsg">{validator.current.message("City", city, "required")}</span>
                  </div>
                </div>
                <label className="common-lable">State</label>
                <div className="form-group mb-0">
                  <select className="custom-select" name="state" value={state} onChange={handleChange}>
                    <option value="">N/A</option>
                    {Object.keys(StatesObj).map((key) => {
                      return (
                        <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                      )
                    })}
                  </select>
                  {/* <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder="State"
                    value={state}
                    onChange={handleChange}
                    name="state"
                    disabled
                  /> */}
                  <span className="validMsg">
                    {validator.current.message("State", state, "required")}
                  </span>
                </div>
                <label className="common-lable">Zip Code</label>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    placeholder="Zip code"
                    value={zipcode}
                    onChange={handleChange}
                    name="zipcode"
                    disabled={personal.isEditZipcode ? false : true}
                  />
                  <span className="validMsg">
                    {validator.current.message("Zipcode", zipcode, "required")}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-align">
              <Link className="btn gray" to="/coach-dashboard">Cancel</Link>
              <Link className="btn light-green" onClick={handleSumbit}>
                Save
              </Link>
            </div>
          </form>
        </div>
      </section>
      <Preloader flag={loader} />
      {
        verifyPopup &&
        <PhoneVerify
          flag={verifyPopup}
          close={closeVerify}
          token={verifyToken}
          uid={uid}
          load={setLoader}
          type="Coach"
          for=""
          email={email}
          phone={cCode + phone}
        />
      }
      <ImageCrop
        imgObj={imgObj}
        flag={cropPopup}
        close={handleClose}
        renderImg={renderFile}
        getObj={onHadleObject}
      />
      {
        openChangePassword &&
        <ChangePassword
          flag={openChangePassword}
          close={closeChangePass}
          type="Coach"
        />
      }
    </>
  );
}

export default PersonalInfo;

import React, { Component } from 'react';
import { imagesArr } from "../../../../assets/images";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { getTeamRanking, getAllTeamList } from '../../../../saga/actions/views/company/pwRanking/rankings/teamRanking.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import Purchase from '../../../popup/purchaseProspectwire';
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const DropdownIndicator = (
    props
) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={imagesArr.black_slight_search} />
        </components.DropdownIndicator>
    );
};
class teamRanking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teamRankingList: [],
            purchase: false,
            tempFlg: true,
            teamOption: [],
            teamListReq: {
                company: localStorage.getItem("cid")
            }
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.getTeamRankingList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.teamRankingInfo &&
            nextProps.teamRankingInfo.getTeamReq &&
            nextProps.teamRankingInfo.getTeamSuccess
        ) {
            nextProps.teamRankingInfo.getTeamReq = false;
            if (nextProps.teamRankingInfo.getTeamSuccess.flag) {
                console.log("teamranking:-----", nextProps.teamRankingInfo.getTeamSuccess.result);
                this.setState({
                    teamRankingList: nextProps.teamRankingInfo.getTeamSuccess.result.data
                }, () => {
                    nextProps.teamRankingInfo.getTeamSuccess = null;
                    if (this.state.teamOption.length !== 0) {
                        this.props.decrement();
                    } else {
                        this.props.getAllTeamList('LEADERBOARD', this.state.teamListReq);
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.teamRankingInfo.getTeamSuccess.message);
                nextProps.teamRankingInfo.getTeamSuccess = null;
                this.props.decrement();
            }
        }

        if (nextProps.teamRankingInfo &&
            nextProps.teamRankingInfo.getAllTeamListReq &&
            nextProps.teamRankingInfo.getAllTeamListSuccess
        ) {
            nextProps.teamRankingInfo.getAllTeamListReq = false;
            if (nextProps.teamRankingInfo.getAllTeamListSuccess.flag) {
                console.log("teamranking list:-----", nextProps.teamRankingInfo.getAllTeamListSuccess.result);
                let result = nextProps.teamRankingInfo.getAllTeamListSuccess.result;
                let arr = [];
                result.data.map((item, i) => {
                    arr.push({ value: item._id, label: item.rosterName })
                });
                this.setState({
                    teamOption: arr
                }, () => {
                    nextProps.teamRankingInfo.getAllTeamListSuccess = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.teamRankingInfo.getAllTeamListSuccess.message);
                nextProps.teamRankingInfo.getAllTeamListSuccess = null;
                this.props.decrement();
            }
        }
    }

    getTeamRankingList = () => {
        this.props.increment();
        this.props.getTeamRanking(this.props.teamRankingInfo.sendTeamRankReq);
    }

    filterChange = (name, perams) => {
        if (name === "rankType" && perams === 1) {
            this.props.teamRankingInfo.sendTeamRankReq = {
                ...this.props.teamRankingInfo.sendTeamRankReq,
                state: ""
            };
        }
        this.props.teamRankingInfo.sendTeamRankReq = {
            ...this.props.teamRankingInfo.sendTeamRankReq,
            [name]: perams
        };
        this.getTeamRankingList();
    }

    redirectUrl = (id) => {
        this.props.history.push(`/pw-team-profile/${id}`);
    }

    renderTeamRanking = () => {
        return (
            this.state.teamRankingList &&
                this.state.teamRankingList.length !== 0 ?
                this.state.teamRankingList.map((item, i) => {
                    if (i < 3) {
                        return (
                            <div className="team-ranking-item" onClick={() => item.rosteName ? this.redirectUrl(item._id) : ""}>
                                <div>{i + 1}. {item.rosteName ? item.rosteName : "-"}</div>
                                <div className="team-rank">{item.state ? item.state : "-"}</div>
                            </div >
                        )
                    }
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    goto = (path) => {
        localStorage.setItem("rankTType", this.props.teamRankingInfo.sendTeamRankReq.rankType);
        localStorage.setItem("ageGroup", this.props.teamRankingInfo.sendTeamRankReq.ageGroup);
        localStorage.setItem("state", this.props.teamRankingInfo.sendTeamRankReq.state);
        localStorage.setItem("teamName", JSON.stringify(this.props.teamRankingInfo.optionData));
        this.props.history.push(path);
    }

    purchaseProspectwire = () => {
        this.setState({
            purchase: true,
        })
    }

    handleClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    // onHandleChange = (e) => {
    //     const { name, value } = e.target;
    //     this.props.teamRankingInfo.sendTeamRankReq = {
    //         ...this.props.teamRankingInfo.sendTeamRankReq,
    //         [name]: value
    //     };
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         tempFlg: !this.state.tempFlg
    //     }))
    // }

    setOption = (val) => {
        console.log("val:---", val);
        this.redirectUrl(val.value)
        // this.props.teamRankingInfo.sendTeamRankReq = {
        //     ...this.props.teamRankingInfo.sendTeamRankReq,
        //     teamName: val.label
        // };
        // this.props.teamRankingInfo.optionData = val;
        // this.setState((prevState) => ({
        //     ...prevState,
        //     tempFlg: !this.state.tempFlg
        // }), () => {
        //     this.getTeamRankingList();
        // })
    }

    render() {
        const { purchase, teamOption } = this.state;
        const { stateList } = this.props;
        const { sendTeamRankReq, optionData } = this.props.teamRankingInfo;

        return (
            <>
                <div>

                </div>
                <div className="leaderboards-team-ranking">
                    <div className="custom-player-search mb-20">
                        <Select
                            defaultValue={optionData}
                            value={optionData}
                            options={teamOption}
                            onChange={(e) => this.setOption(e)}
                            closeMenuOnSelect={true}
                            className="ranking-select"
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            placeholder={"Team search"}
                        />
                        {/* <input className="form-control" name="teamName" onChange={(e) => this.onHandleChange(e)} value={sendTeamRankReq.teamName} placeholder="Team search" /> */}
                        {/* <img className="search-icon" src={imagesArr.search} onClick={() => this.getTeamRankingList()} /> */}
                    </div>
                    <div className="leaderboards-team">
                        <div className="p-20">
                            <h1 className="section_title">Team Rankings</h1>
                            <div className="list-group-scroll">
                                <ul className="list-group mt-15 mb-12">
                                    <li className={`list-group-item ${sendTeamRankReq.rankType === 1 ? "active" : ""}`} onClick={() => this.filterChange('rankType', 1)}>National</li>
                                    <li className={`list-group-item ${sendTeamRankReq.rankType === 2 ? "active" : ""}`} onClick={() => this.filterChange('rankType', 2)}>State</li>
                                </ul>
                            </div>
                            {
                                sendTeamRankReq.rankType === 2 &&
                                <div className="pb-15">
                                    <label>State</label>
                                    <select className="custom-select" name="state" value={sendTeamRankReq.state} onChange={(e) => this.filterChange('state', e.target.value)}>
                                        <option value="">State</option>
                                        {stateList &&
                                            stateList.map((item) => {
                                                return (<option value={item.state}>{item.state}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            <label>Age Group</label>
                            <select className="custom-select" name="ageGroup" value={sendTeamRankReq.ageGroup} onChange={(e) => this.filterChange('ageGroup', e.target.value)}>
                                <option value="8" className="list-group-item">8U</option>
                                <option value="9" className="list-group-item">9U</option>
                                <option value="10" className="list-group-item">10U</option>
                                <option value="11" className="list-group-item">11U</option>
                                <option value="12" className="list-group-item">12U</option>
                                <option value="13" className="list-group-item">13U</option>
                                <option value="14" className="list-group-item">14U</option>
                                <option value="15" className="list-group-item">15U</option>
                                <option value="16" className="list-group-item">16U</option>
                                <option value="17" className="list-group-item">17U</option>
                                <option value="18" className="list-group-item">18U</option>
                                <option value="19" className="list-group-item">18U+</option>
                            </select>
                        </div>
                        <div className="team-ranking-list">
                            {this.renderTeamRanking()}
                        </div>
                        <div className="see-more-btn">
                            <Link className="btn">
                                {
                                    // localStorage.getItem("subscribe") && localStorage.getItem("subscribe") !== "Scout" ?
                                    //     <div onClick={() => this.purchaseProspectwire()}>
                                    //         <span >See More</span>
                                    //         <span className="lock-icon bg-grey">
                                    //             <img src={imagesArr.lock_icon} alt="lock_icon" />
                                    //         </span>
                                    //     </div>
                                    //     :
                                        <div onClick={() => this.goto('/pw-team-ranking')}>
                                            <span>See More</span>
                                        </div>
                                }
                            </Link>
                        </div>
                    </div>
                </div>
                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                        type="PW"
                    />
                }
            </>
        )
    }
}

teamRanking.propTypes = {
    getTeamRanking: PropTypes.func.isRequired,
    getAllTeamList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    teamRankingInfo: state.teamRanking
});

export default withRouter(connect(mapStateToProps, { getTeamRanking, getAllTeamList })(teamRanking));
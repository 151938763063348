import axios from 'axios';
import { Auth_Coach_Key, Auth_Player_Key, API_Url, Authorization, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidFanToken } from '../../helpers/authToken';
import {
    SEND_PAYMENT_REQUEST,
    SEND_PAYMENT_SUCCESS,
    SEND_PAYMENT_ERROR,
} from '../../constants/common/payment.const';
import histrory from '../../../History';

export const purchase = (data, userType, token) => dispatch => {
    console.log("data:--", data);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: SEND_PAYMENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}${localStorage.getItem("popupUrl") === "/pw-leaderboard" ? "pwProduct/web/prospectWire/new" : "bc/subscription/new"}`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(pack => {
        console.log("pack:--", pack);
        dispatch({
            type: SEND_PAYMENT_SUCCESS,
            payload: pack.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SEND_PAYMENT_ERROR, error: error.response.data.message })
        }
    });
};



import React from 'react'
import { useDispatch } from 'react-redux'
import { getPlayerInfo } from '../../saga/actions/common/accountCreation.action';
import { GET_PLAYER_INFO_SUCCESS } from '../../saga/constants/common/accountCreation.const';

function PlayerList({ data, setState, state }) {
    const dispatch = useDispatch();

    const calculate_age_between = (dob1) => {
        let today = new Date(),
            //birthay has 'Dec 25 1998'
            dob = new Date(dob1),
            //difference in milliseconds
            diff = today.getTime() - dob.getTime(),
            //convert milliseconds into years
            years = Math.floor(diff / 31556736000),
            //1 day has 86400000 milliseconds
            days_diff = Math.floor((diff % 31556736000) / 86400000),
            //1 month has 30.4167 days
            months = Math.floor(days_diff / 30.4167),
            days = Math.floor(days_diff % 30.4167);
        if (years <= 16 && months === 0 && days === 0) {
            return true
        } else {
            return false
        }
        // var ageDifMs = Date.now() - new Date(dob1).getTime();
        // var ageDate = new Date(ageDifMs); // miliseconds from epoch
        // return (Math.abs(ageDate.getUTCFullYear() - 1970) >= 15 && Math.abs(ageDate.getUTCFullYear() - 1970) <= 18);
    }

    const claimPopup = (tab, info, user, index) => {
        dispatch({
            type: GET_PLAYER_INFO_SUCCESS,
            payload: null
        })
        let isParent = info?.hasOwnProperty("parentId") ? info?.parentId && info?.parentId?.length != 0 ? true : false : false;

        setState({
            ...state,
            claimAccount: true,
            claimForm: tab,
            selectedRow: info,
            loader: user === "parent" && (!isParent || !info.parentId[index]) ? false : user === "player" ? false : true,
            canCreateParent: info.mapUser ? info.mapUser.profile.hasOwnProperty("dob") ? info.mapUser.profile.dob : "" : "",
        })
        if (user === "parent" && (!isParent || !info.parentId[index])) {
           return null;
        }else{
            let personalReq = {
                ObjectId: user === "player" ? info.mapUser._id : info.parentId[index].user,
                type: user === "player" ? 1 : 2,
                _id: info._id
            }
            getPlayerInfo(personalReq)(dispatch);
        }

        //version 1
        // if (calculate_age_between(info.mapUser.profile.hasOwnProperty("dob") ? info.mapUser.profile.dob : "") && isParent) {       
        //     setState({
        //         ...state,
        //         claimAccount: true,
        //         claimForm: tab,
        //         selectedRow: info,
        //         loader: true,
        //         canCreateParent: info.mapUser.profile.hasOwnProperty("dob") ? info.mapUser.profile.dob : ""
        //     })
        //     let personalReq = {
        //         ObjectId: user === "player" ? info.mapUser._id : info.parentId[index].user,
        //         type: user === "player" ? 1 : 2,
        //         _id: info._id
        //     }
        //     getPlayerInfo(personalReq)(dispatch);
        // }
        // else if (!calculate_age_between(info.mapUser.profile.hasOwnProperty("dob") ? info.mapUser.profile.dob : "")) {
        //     setState({
        //         ...state,
        //         claimAccount: true,
        //         claimForm: tab,
        //         selectedRow: info,
        //         loader: true,
        //         canCreateParent: info.mapUser.profile.hasOwnProperty("dob") ? info.mapUser.profile.dob : ""
        //     })
        //     let personalReq = {
        //         ObjectId: user === "player" ? info.mapUser._id : info.parentId[index].user,
        //         type: user === "player" ? 1 : 2,
        //         _id: info._id
        //     }
        //     getPlayerInfo(personalReq)(dispatch);
        // } else {
        //     setState({
        //         ...state,
        //         claimAccount: true,
        //         claimForm: 2,
        //         selectedRow: info,
        //         canCreateParent: info.mapUser.profile.hasOwnProperty("dob") ? info.mapUser.profile.dob : "",
        //         justNowCreateParent: true
        //     })
        //     return null;
        // }
    }

    return (
        <>
            <div className='account_creation_table'>
                <div className="table-responsive-xl">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" colspan="2">#</th>
                                <th scope="col" colspan="2">First Name</th>
                                <th scope="col" colspan="2">Last Name</th>
                                {/* <th scope="col" colspan="2">Email</th>
                                <th scope="col" colspan="2">Mobile</th> */}
                                <th scope="col" colspan="2">Player Account Claimed</th>
                                <th scope="col" colspan="2">Guardian 1 Account Claimed</th>
                                <th scope="col" colspan="2">Guardian 2 Account Claimed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.players?.length ?
                                data?.players.map((_, i) => {
                                    return (
                                        <tr className={`${_.verfifyAcc === 1 ? 'grey' : _.verfifyAcc === 2 ? 'active' : ''}`}>
                                             <td colspan="2">{_?.number ? `${_?.number}` : '-'}</td>
                                            <td colspan="2">{_.mapUser ? _.mapUser.fname ? _.mapUser.fname : "-" : "-"} </td>
                                            <td colspan="2">{_.mapUser ? _.mapUser.lname ? _.mapUser.lname : "-" : "-"}</td>
                                            {/* <td colspan="2">{_.mapUser ? _.mapUser.email ? _.mapUser.email : "-" : "-"}</td>
                                            <td colspan="2">{_.mapUser ? _.mapUser.phone ? _.mapUser.phone : "-" : "-"}</td> */}
                                            <td colspan="2">
                                                <div className="checkbox">
                                                    <input type="checkbox"
                                                        id="players"
                                                        name="playerPopup"
                                                        className={`${_.mapUser ? "" : "disabled"}`}
                                                        checked={_.claimAcc ? true : false}
                                                        disabled={_.mapUser ? false : true}
                                                        onClick={(e) => _.claimAcc ? "" : claimPopup(1, _, "player")} />
                                                    <span className={`square ${_.mapUser ? "" : "disabled"}`}></span>
                                                </div>
                                            </td>
                                            <td colspan="2">
                                                <div className="checkbox">
                                                    <input type="checkbox" id="players" name="gradian1"
                                                        // className={`${_.parentId ? _.parentId[0] ? "" : "disabled" : "disabled"}`}
                                                        checked={_.parentId ? _.parentId[0] ? _.parentId[0].claimAcc : false : false}
                                                        // disabled={_.parentId ? _.parentId[0] ? false : true : true}
                                                        onClick={(e) => _?.parentId && _?.parentId[0] && _?.parentId[0]?.claimAcc ? "" : claimPopup(2, _, "parent", 0)} />
                                                    <span className={`square`}></span>
                                                    {/* <span className={`square ${_.parentId ? _.parentId[0] ? "" : "disabled" : "disabled"}`}></span> */}
                                                </div>
                                            </td>
                                            <td colspan="2" >
                                                <div className="checkbox">
                                                    <input type="checkbox" id="players" name="gradian2"
                                                        // className={`${_.parentId ? _.parentId[1] ? "" : "disabled" : "disabled"}`}
                                                        checked={_.parentId ? _.parentId[1] ? _.parentId[1].claimAcc : false : false}
                                                        // disabled={_.parentId ? _.parentId[1] ? false : true : true}
                                                        onClick={(e) => _?.parentId && _?.parentId[1] && _?.parentId[1]?.claimAcc ? "" : claimPopup(3, _, "parent", 1)} />
                                                    <span className={`square`}></span>
                                                    {/* <span className={`square ${_.parentId ? _.parentId[1] ? "" : "disabled" : "disabled"}`}></span> */}
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="16" style={{ textAlign: 'center' }}>No data found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                </div>
            </div>
        </>
    )
}

export default PlayerList

import {
    PARENT_LP_LIST_REQUEST,
    PARENT_LP_LIST_SUCCESS,
    PARENT_LP_LIST_ERROR,

    PLAYER_TOKEN_REQUEST,
    PLAYER_TOKEN_SUCCESS,
    PLAYER_TOKEN_ERROR,
 } from '../../../constants/view/fan/dashboard.const';
 
 const initialState = {
     lpListReq: false,
     lpListData: [],
     lpListError: null,

     playerTokenReq: false,
     playerTokenData: null,
     playerTokenError: null 
 };
 
 export default function (state = initialState, action) {
     switch (action.type) {
 
         // get linked player list    
         case PARENT_LP_LIST_REQUEST:
             return {
                 ...state,
                 lpListReq: true,
             };
         case PARENT_LP_LIST_SUCCESS:
             return {
                 ...state,
                 lpListData: action.payload,
             };
         case PARENT_LP_LIST_ERROR:
             return {
                 ...state,
                 lpListError: action.error,
             };

         // get player token   
         case PLAYER_TOKEN_REQUEST:
             return {
                 ...state,
                 playerTokenReq: true,
             };
         case PLAYER_TOKEN_SUCCESS:
             return {
                 ...state,
                 playerTokenData: action.payload,
             };
         case PLAYER_TOKEN_ERROR:
             return {
                 ...state,
                 playerTokenError: action.error,
             };

         default:
             return state;
     }
 }
 

import React, { Component } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPaymentIntent } from '../../../../../saga/actions/common/eventDetails.action';
class payment extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.validatorPayment = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        // var payment = {
        //     amount: this.props.dataList.payment.feesLeague,
        //     objetId: this.props.dataList._id,
        //     module: 2,
        //     method: "stripe",
        // };
        // this.props.fetchPaymentIntent(payment);
    }

    chkValid = (e) => {
        if (this.validatorPayment.allValid()) {
            if (this.props.eventJoin.paymentType !== 0) {
                var payment = {
                    amount: this.props.eventJoin.amount,
                    objetId: this.props.dataList._id,
                    module: 1,
                    method: "stripe",
                };
                console.log("payment:---", payment);
                this.props.setLoader(true);
                this.props.fetchPaymentIntent(payment);
                // this.props.onHandleChange(e)
            }
        } else {
            this.validatorPayment.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { eventJoin, dataList, managePayMethod , isPayLater} = this.props;
        var isPaymentDisabled = true;
        if (
            dataList.payment.paymentType.length === 1 &&
            dataList.payment.paymentType.indexOf(2) !== -1
        ) {
            isPaymentDisabled = true;
        } else {
            isPaymentDisabled = false;
        }
        return (
            <div className="event-form">
                <div className="join-form">
                    <h4>Payment</h4>
                    <form>
                        <div className="form-group">
                            <label>Payment Type</label>
                            <select className="custom-select" name="paymentType" value={eventJoin.paymentType} onChange={(e) => this.props.onHandleChange(e)}>
                                <option value="">Please select payment type</option>
                                {dataList &&
                                    dataList.payment &&
                                    dataList.payment.paymentType.indexOf(1) !== -1 ? (
                                    <option value={1}>
                                        Full Payment ( ${dataList.payment.feesLeague} )
                                    </option>
                                ) : null}
                                {dataList &&
                                    dataList.payment &&
                                    dataList.payment.paymentType.indexOf(2) !== -1 ? (
                                    <option value={2}>
                                        Day Of Event ( ${dataList.payment.feesLeague} )
                                    </option>
                                ) : null}
                                {dataList &&
                                    dataList.payment &&
                                    dataList.payment.paymentType.indexOf(3) !== -1 &&
                                    <option value={3}>
                                        Pay Installment ( ${dataList.payment.downpaidAmt} )
                                    </option>
                                }
                            </select>
                            <span className="validMsg">{this.validatorPayment.message("payment Type", eventJoin.paymentType, "required")}</span>
                        </div>
                        {
                            eventJoin.paymentType === 1 &&
                            <div className="form-group">
                                <label>Apply Coupon Code</label>
                                <div className="input-btn">
                                    <input type="text" name="couponCode" value={eventJoin.couponCode} onChange={(e) => this.props.onHandleChange(e)} className="form-control" placeholder="Enter coupon code" />
                                    <Link className="btn light-green" onClick={() => this.props.applyCode()}>Apply</Link>
                                </div>
                            </div>
                        }
                        {
                            !managePayMethod &&
                                eventJoin &&
                                 !isPayLater &&
                                (eventJoin.paymentType === 1 || eventJoin.paymentType === 3) ?
                                <div className="form-group">
                                    <label className="common-lable">Payment Method</label>
                                    {dataList &&
                                        dataList.payment &&
                                        (dataList.payment.paymentMethod.indexOf(1) !== -1 || dataList.payment.paymentType.indexOf(3) !== -1) &&
                                        <div className="check_input">
                                            <div className="check_box">
                                                <div className="checkbox">
                                                    <input
                                                        disabled={
                                                            isPaymentDisabled ||
                                                            eventJoin.paymentType === 2
                                                        }
                                                        type="checkbox"
                                                        name="creditcard"
                                                        value={eventJoin.paymentMethod}
                                                        onChange={(e) => this.chkValid(e)}
                                                        checked={
                                                            eventJoin.paymentMethod === 1
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <label>Credit Card </label>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                : ""
                        }
                        {
                            managePayMethod &&
                            <Link className="btn light-green" onClick={() => this.props.registerEvent()}>Submit</Link>
                        }
                    </form>
                </div>
            </div>
        )
    }
}

payment.propTypes = {
    fetchPaymentIntent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
});
var paymentComponent = connect(mapStateToProps, { fetchPaymentIntent })(payment);
export default withRouter(paymentComponent);

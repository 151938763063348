import React, { Component } from 'react';
import Header from '../containers/header';
import Footer from '../containers/footer';
import CommonHeroBanner from '../components/commonHeroBanner';

class pagenotfound extends Component {
    render() {
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <Footer />
            </>
        );
    }
}

export default pagenotfound;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../../components/leftAuthImage';
import CommonHeroBanner from '../../components/commonHeroBanner';
// import { instagramLogin } from '../../saga/actions/common/api.action';
import Preloader from '../../components/preloader';
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../businesslogic/commonCalls";
import { signupStep2 } from '../../saga/actions/authantication/signup/step2.action';

class parentInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            pfname: "",
            plname: "",
            pemail: "",
            pphone: "",
            sendData: []
        }

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.commonCall.getStorage("userStep1", data => {
            console.log("data:--", data);
            if (data !== null) {
                this.setState({
                    sendData: data
                }, () => {
                    this.setLoader(false);
                })
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.setting) {
            if (nextProps.setting.regiStep2Req &&
                nextProps.setting.regiStep2Data
            ) {
                nextProps.setting.regiStep2Req = false;
                if (nextProps.setting.regiStep2Data.flag) {
                    this.setLoader(false);
                    this.commonCall.successMessage(nextProps.setting.regiStep2Data.message);
                    this.props.history.push(`/verify/${nextProps.setting.regiStep2Data.result.verifyToken}`);
                    nextProps.setting.regiStep2Data = null;
                } else {
                    this.setLoader(false);
                    this.commonCall.errorMessage(nextProps.setting.regiStep2Data.message);
                    nextProps.setting.regiStep2Data = null;
                }
            }
        }
    }

    onChangeFn = (e) => {
        const { name, value } = e.target;
        if (name === "pphone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState({
                        [name]: value
                    })
                }
            }
        } else {
            this.setState({
                [name]: value
            })
        }
    };

    submitParent = () => {
        if (this.validator.allValid()) {
            let data = {
                fname: this.state.sendData.fname,
                lname: this.state.sendData.lname,
                email: this.state.sendData.email,
                password: this.state.sendData.password,
                dob: this.state.sendData.dob,
                cCode: "+1",
                phone: this.state.sendData.phone,
                type: this.state.sendData.type,
                parentInfo: {
                    fullName: this.state.pfname + " " + this.state.plname,
                    email: this.state.pemail,
                    phone: this.state.pphone,
                    cCode: "+1"
                }
            }
            console.log("submitParent:--", data);
            this.setLoader(true);
            this.props.signupStep2(data);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>Parent Info</h3>
                                    <p>You must 16 years of age to create a user account. Please enter parent info as you aren't 16 year old.</p>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <label>Parents First Name</label>
                                        <input type="text" name="pfname" value={this.state.pfname} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter your parents first name" />
                                        <span className="validMsg">{this.validator.message('Parents First Name', this.state.pfname, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Parents Last Name</label>
                                        <input type="text" name="plname" value={this.state.plname} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter your parents last name" />
                                        <span className="validMsg">{this.validator.message('Parents Last Name', this.state.plname, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text" name="pemail" value={this.state.pemail} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter your email address" />
                                        <span className="validMsg">{this.validator.message('Email Address', this.state.pemail, 'required|email')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile number</label>
                                        <div className="svg_icon">
                                            <div className="input-group">
                                                <div className="counrty-no">
                                                    <select className="custom-select" name="cCode">
                                                        <option value="+1">+1</option>
                                                    </select>
                                                </div>
                                                <input type="text" className="form-control" name="pphone" value={this.state.pphone} onChange={this.onChangeFn} id="usr" placeholder="Enter your mobile number" />
                                                <img src={imagesArr.smartphone} alt="eye" />
                                            </div>
                                            <span className="validMsg">{this.validator.message('Mobile number', this.state.pphone, 'required|phone')}</span>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <Link className="btn w-100 text-center" onClick={() => this.submitParent()}>Submit</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        )
    }
}

parentInfo.propTypes = {
    signupStep2: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    setting: state.step2,
});

export default connect(mapStateToProps, { signupStep2 })(parentInfo);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { imagesArr } from "../../../../assets/images";
import moment from "moment";
import ProductList from "../../../company/components/common/productList";
import PwLock from "../../../company/components/common/pwLock";
import ReactTooltip from 'react-tooltip';
import { getScoutingFeed } from '../../../../saga/actions/views/company/pwRanking/profileSummary.action';
import ReactPaginate from 'react-paginate';

class media extends Component {

    constructor(props) {
        super(props);
        this.state = {

            // mediaData: null,
            // eventMediaData: null,

            mediaApiResponse: [],
            eventInfo: null,

            mentions: null,
            recap: null,

            scoutTabReq: {
                id: "",
                page: 0,
                limit: 0,
                companyId: localStorage.getItem("cid"),
                tab: "scout-feed"
            },
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
        localStorage.removeItem("recapInfo");
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.mediaprofileInfo &&
            nextProps.mediaprofileInfo.getScoutingReq &&
            nextProps.mediaprofileInfo.getScoutingData
        ) {
            nextProps.mediaprofileInfo.getScoutingReq = false;
            if (nextProps.mediaprofileInfo.getScoutingData.flag) {
                let docs = nextProps.mediaprofileInfo.getScoutingData.result.docs;
                this.setState({
                    mentions: docs
                }, () => {
                    nextProps.mediaprofileInfo.getScoutingData = null;
                    this.props.decrement();
                })
            } else {
                nextProps.mediaprofileInfo.getScoutingData = null;
                this.props.decrement();
            }
        }
    }

    getSummary = (data) => {
        this.setState({
            mediaApiResponse: data,
            eventInfo: data.docs[this.state.scoutTabReq.page],
            scoutTabReq: {
                ...this.state.scoutTabReq, limit: data.total, page: data.page,
                id: data.docs[this.state.scoutTabReq.page]?._id
            },
            recap: data.recap
        }, () => {
            // if (localStorage.getItem("eventReport")) {
            this.props.increment();
            // let received = JSON.parse(localStorage.getItem("eventReport"));

            let url = new URL(document.location);
            let pid = url.pathname.split("/")[2];

            const payload = {
                ...this.state.scoutTabReq,
                id: this.state.scoutTabReq.id,
                userId: pid
            }
            this.props.getScoutingFeed(payload);
            // }
        })
    }

    redirectInfo = (recap) => {
        localStorage.setItem("recapInfo", JSON.stringify(recap));
        let route = process.env.REACT_APP_WEB_LINK + "/pw-player-info";
        window.open(route, "_blank");
    }

    renderPlayer = () => {
        return (
            this.state.recap &&
                this.state.recap.length !== 0 ?
                this.state.recap.map((recap, i) => {
                    return (
                        <div className="player-list-item" onClick={() => this.redirectInfo(recap)}>
                            <div className="player-profile">
                                <img src={recap.event ? recap.event.eventImages.filePath ? recap.event.eventImages.filePath : imagesArr.default_user : imagesArr.default_user} alt="player" />
                            </div>
                            <div className="media-player-info">
                                <h4>{recap.title}</h4>
                                <p>{moment(recap.date).format("MMMM DD YYYY")}</p>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    renderMessage = () => {

        return (
            this.state.mentions && this.state.mentions?.length !== 0 ?
                this.state.mentions.map((item, i) => {
                    return (
                        <div className="media" key={i}>
                            <img src={item.sId && item.sId.profileUrl ? item.sId.profileUrl : imagesArr.default_user} alt="media" />
                            <div className="media-contant">
                                <h4>{item.sId ? item.sId.fname + " " + item.sId.lname : " - "}</h4>
                                <div className="media-type">
                                    <div className="media-img">
                                        {
                                            item.file &&
                                                item.mTy === 2 ?
                                                <img src={item.file.filePath} alt="team logo" />
                                                :
                                                item.mTy === 3 ?
                                                    <audio controls>
                                                        <source src={item.file.filePath} type="audio/ogg" />
                                                    </audio>
                                                    : item.mTy === 6 ?
                                                        <div className="file-formate">
                                                            <div className="file-icon">
                                                                <img src={imagesArr.pdf_icon} alt="media" />
                                                            </div>
                                                            <div className="file-name">
                                                                <h4>{this.getExtantion(item.file.filePath)}</h4>
                                                                <p>512.2KB</p>
                                                            </div>
                                                        </div>
                                                        : item.mTy === 4 ?
                                                            <div>
                                                                <video src={item.file.filePath} type="video" controls style={{ width: "100%", height: "200px" }}></video>
                                                            </div>
                                                            : ""
                                        }
                                        {
                                            item.msg &&
                                            <p className="text" dangerouslySetInnerHTML={{
                                                __html: this.mentionText(item.msg)
                                            }}></p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">Sorry this event has no scout notes</p>
        )

        // if (this.state.eventMediaData.length !== 0) {
        //     return (
        //         this.state.eventMediaData &&
        //             this.state.eventMediaData.length !== 0 ?
        //             this.state.eventMediaData.map((item, i) => {
        //                 return (
        //                     <div className="media" key={i}>
        //                         <img src={item.sId.profileUrl ? item.sId.profileUrl : imagesArr.default_user} alt="media" />
        //                         <div className="media-contant">
        //                             <h4>{item.sId.fname + " " + item.sId.lname}</h4>
        //                             <div className="media-type">
        //                                 <div className="media-img">
        //                                     {
        //                                         item.file &&
        //                                             item.mTy === 2 ?
        //                                             <img src={item.file.filePath} alt="team logo" />
        //                                             :
        //                                             item.mTy === 3 ?
        //                                                 <audio controls>
        //                                                     <source src={item.file.filePath} type="audio/ogg" />
        //                                                 </audio>
        //                                                 : item.mTy === 6 ?
        //                                                     <div className="file-formate">
        //                                                         <div className="file-icon">
        //                                                             <img src={imagesArr.pdf_icon} alt="media" />
        //                                                         </div>
        //                                                         <div className="file-name">
        //                                                             <h4>{this.getExtantion(item.file.filePath)}</h4>
        //                                                             <p>512.2KB</p>
        //                                                         </div>
        //                                                     </div>
        //                                                     : item.mTy === 4 ?
        //                                                         <div>
        //                                                             <video src={item.file.filePath} type="video" controls style={{ width: "100%", height: "200px" }}></video>
        //                                                         </div>
        //                                                         : ""
        //                                     }
        //                                     {
        //                                         item.msg &&
        //                                         <p className="text" dangerouslySetInnerHTML={{
        //                                             __html: this.mentionText(item.msg)
        //                                         }}></p>
        //                                     }
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 )
        //             })
        //             :
        //             <p className="text-center">Sorry this event has no scout notes</p>
        //     )
        // } else {
        //     return (
        //         this.state.mediaData &&
        //             this.state.mediaData.docs &&
        //             this.state.mediaData.docs.length !== 0 ?
        //             this.state.mediaData.docs.map((item, i) => {
        //                 return (
        //                     <div className="media" key={i}>
        //                         <img src={item.sId && item.sId.profileUrl ? item.sId.profileUrl : imagesArr.default_user} alt="media" />
        //                         <div className="media-contant">
        //                             <h4>{item.sId ? item.sId.fname + " " + item.sId.lname : " - "}</h4>
        //                             <div className="media-type">
        //                                 <div className="media-img">
        //                                     {
        //                                         item.file &&
        //                                             item.mTy === 2 ?
        //                                             <img src={item.file.filePath} alt="team logo" />
        //                                             :
        //                                             item.mTy === 3 ?
        //                                                 <audio controls>
        //                                                     <source src={item.file.filePath} type="audio/ogg" />
        //                                                 </audio>
        //                                                 : item.mTy === 6 ?
        //                                                     <div className="file-formate">
        //                                                         <div className="file-icon">
        //                                                             <img src={imagesArr.pdf_icon} alt="media" />
        //                                                         </div>
        //                                                         <div className="file-name">
        //                                                             <h4>{this.getExtantion(item.file.filePath)}</h4>
        //                                                             <p>512.2KB</p>
        //                                                         </div>
        //                                                     </div>
        //                                                     : item.mTy === 4 ?
        //                                                         <div>
        //                                                             <video src={item.file.filePath} type="video" controls style={{ width: "100%", height: "200px" }}></video>
        //                                                         </div>
        //                                                         : ""
        //                                     }
        //                                     {
        //                                         item.msg &&
        //                                         <p className="text" dangerouslySetInnerHTML={{
        //                                             __html: this.mentionText(item.msg)
        //                                         }}></p>
        //                                     }
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 )
        //             })
        //             :
        //             <p className="text-center">Sorry this event has no scout notes</p>
        //     )
        // }

    }

    renderTitle = () => {
        // if (localStorage.getItem("eventReport")) {
        //     let received = JSON.parse(localStorage.getItem("eventReport"));
        //     return (
        //         <div className="scouting-heading">
        //             <div className="scouting-title">
        //                 <img src={received.event.eventLogo.filePath ? received.event.eventLogo.filePath : imagesArr.default_user} alt="player" />{received.event.eventTitle}
        //             </div>
        //         </div>
        //     )
        // } else {
        return (
            <div className="scouting-heading">
                <div className="scouting-title">
                    <img src={this.state.eventInfo.eventLogo.filePath ? this.state.eventInfo.eventLogo.filePath : imagesArr.default_user} alt="player" />{this.state.eventInfo.eventTitle}
                </div>
            </div>
        )
        // }
    }

    mentionText = (text) => {
        // let finalText = "";
        // if (text.split("<")[1]) {
        //     finalText = "<span>" + text.split("<")[1].split(">")[0] + "</span>" + text.split("<")[1].split(">")[1];
        // } else {
        //     finalText = text;
        // }
        return text;
    }

    getExtantion = (file) => {
        var fileExtension = file.split('/').pop();
        return fileExtension;
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const nextEvent = this.state.mediaApiResponse.docs[selectedPage];

        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];

        this.setState((prevState) => ({
            ...prevState,
            eventInfo: nextEvent,
            scoutTabReq: {
                ...prevState.scoutTabReq,
                page: selectedPage + 1,
                id: nextEvent._id,
                userId: pid
            },
        }), () => {

            this.props.teamProfileInfo.getTeamScoutFeedSuc = null;
            this.props.increment();
            this.props.getScoutingFeed(this.state.scoutTabReq);

            // let received = JSON.parse(localStorage.getItem("eventReport"));
            // this.props.mediaprofileInfo.mediaPageReq = {
            //     ...this.props.mediaprofileInfo.mediaPageReq,
            //     id: received.event._id,
            // };
            // this.props.getPWScoutingFeed(this.props.mediaprofileInfo.mediaPageReq);
        });
    };

    render() {
        const { eventInfo, mediaApiResponse } = this.state;

        return (
            mediaApiResponse &&
            <>
                <ReactTooltip className="tooltip" type='dark' html={true} />
                <div className="media-content">
                    <div className="player-list">
                        <h3 class="section_title">Mentions
                            {(!localStorage.getItem("subscribe")) ?
                                ""
                                :
                                <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                        <b>What is it?</b>
                              <p>Here you will see what Event Recaps your name appeared in</p>
                              </span>"/>
                            }
                        </h3>
                        <div className="player-list-group">
                            {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                                this.renderPlayer()
                                :
                                <>
                                    <p className="text-center">You have no mentions at this time. Sign up for a Prospect Wire Showcase.</p>
                                </>
                            }
                        </div>
                        {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                            ""
                            :
                            <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                        }
                    </div>
                    <div className="PW-scouting-feed">
                        <h3 class="section_title">PW Scouting Feed
                            {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                                ""
                                :
                                <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                        <b>What is it?</b>
                              <p>Here is where you will see the notes the PW scouts wrote about you</p>
                              </span>"/>
                            }
                        </h3>
                        {
                            eventInfo &&
                            (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") &&
                            this.renderTitle()
                        }
                        {/* {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                            ""
                            :
                            <div className="scouting-heading">
                                <div className="scouting-title">
                                    <img src="https://artoon-taxuz.s3.amazonaws.com/1/c403c578d916a5d9b7c3c191523c3139.jpg" alt="player" />Event Name
                                </div>
                            </div>
                        } */}
                        <div className="scouting-feed-group">
                            <div className="scouting-feed-media">
                                {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                                    this.renderMessage()
                                    :
                                    <>
                                        <p className="text-center">Your feed is empty.</p>
                                    </>
                                }
                            </div>
                            {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                                ""
                                :
                                <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                            }
                        </div>

                        {this.state.mediaApiResponse?.docs?.length !== 0 &&
                            <div className="Paginate mt-20">
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.scoutTabReq.limit}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={1}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <ProductList />
            </>
        )
    }
}

media.propTypes = {
    getPWScoutingFeed: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    mediaprofileInfo: state.profileSummaryInfo,
    teamProfileInfo: state.teamProfileInfo
});

export default withRouter(connect(mapStateToProps, { getScoutingFeed })(media));
// export default withRouter(media);
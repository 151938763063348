import React, { Component } from 'react'
import Header from "../../../containers/header"
import Footer from "../../../containers/footer"
import { imagesArr } from "../../../assets/images"
import Slider from "react-slick";
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import ContactUs from "../../../views/company/components/common/contactUs";
import TeamInfo from '../../popup/teamInfo';
import Shop from "../../../views/company/components/common/shop";
import { ourTeamContent } from '../../../businesslogic/content';
import Preloader from '../../../components/preloader';

export default class gobAbout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
            popupData: [],
            loaderCount: 0
        };
    }

    componentDidMount = () => {
    }

    setPopupContent = (data) => {
        this.setState({
            openPopup: true,
            popupData: data
        })
    }

    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            openPopup: false,
            popupData: []
        })
    }

    renderContent = () => {
        return (
            ourTeamContent &&
            ourTeamContent.about &&
            ourTeamContent.about.gob &&
            ourTeamContent.about.gob.map((item, i) => {
                return (
                    <div key={i} className="w-25" >
                        <div className="team-box">
                            <h5>{item.title}
                                {
                                    item.description.length !== 0 &&
                                    <span className="page-icon">
                                        <i class="fa fa-file" aria-hidden="true" onClick={() => this.setPopupContent(item)}></i>
                                    </span>
                                }
                            </h5>
                            <span>{item.position}</span>
                            <p>{item.email}</p>
                        </div>
                    </div>
                )
            })
        )
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { openPopup, popupData, loaderCount } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            centerPadding: '258px',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        centerMode: true,
                        centerPadding: '90px',
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '0px',
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (
            <div >
                <Header />
                <div className="event-main">
                    <div className="container-fluid">
                        <div className="about-slider">
                            <Slider {...settings}>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.gob_about_slide_1} alt="user profile" />
                                        <h5>Jenny Castiglione</h5>
                                        <h6>Vipers Manager</h6>
                                        <p>We have had two teams that have played in Greater Orlando Baseball tournaments for three years and it has been an outstanding experience, which is why we continue to play tournaments in this organization! GOB has always been great with their scheduling and communication for tournaments. We greatly enjoy playing at the different venues and it is always a fun experience for the players! </p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.gob_about_slide_2} alt="user profile" />
                                        <h5>Monica Huff</h5>
                                        <h6>Ocala Elite Baseball</h6>
                                        <p>We are a small nonprofit organization and we do not charge much to our players. We love the value we receive from these tournaments. Specifically the season series where we can maximize our playing time for less. We are able to play twice as many tournaments because of these deals.</p>
                                        <p>We have tried to play some other sanctions in the area, but found the organization of the events and communication from the directors to be frustrating. Then we found GOB….. and it was a refreshing change. Jennifer and Mike have been super great helping me organize our travel schedule and in learning the ins and outs of registrations and rules etc. They are always friendly and respond quickly to any questions I have. They have also been very accommodating when we have had special requests on game times when we have needed it.</p>
                                        <p>We at Ocala Elite will always support GOB Tournaments. </p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.gob_about_slide_3} alt="user profile" />
                                        <h5>Andy Baker</h5>
                                        <h6>Oviedo Monsters</h6>
                                        <p>I coach 2 different travel teams and we have used GOB for the past 4 years.
                                            GOB has always provided a great experience for our teams, the kids and their families.
                                            The facilities have always been up to the standard along with their umpires, other competitive teams.
                                            GOB tournament directors have always provided on the spot information when needed and are always accommodating to any type of needs or assistance.
                                            GOB tourneys do a great job of selecting quality umpires and staff along with making sure there is plenty of good  competition for the boys to compete with.
                                            We will continue to use GOB as long as we can.
                                        </p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.gob_about_slide_4} alt="user profile" />
                                        <h5>Coach Edgar</h5>
                                        <h6>BAT Baseball</h6>
                                        <p>Here are some of the main reasons our organization plays in GOB tournaments. Long term contact and relationship with Mike and Jennifer. The ease of entry as well as the affordability of the events. GOB allows teams to take a step into Travel Baseball with relative ease and a step up in competition from League play. Allowing teams to compete in a more competitive environment and a structure that allows for teams to be ranked is a big reason for people to play with GOB.</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="history-vision">
                        <div className="container">

                            <div className="history-vision-info">
                                <div className="hv-box">
                                    <h5 className="section_title_left">Who We Are</h5>
                                    <p>Greater Orlando Baseball, formerly known as Nations of Greater Orlando, was founded in January of 2009.  The goal was to provide local, affordable tournaments to the Central Florida market by offering a 4 game guarantee each and every weekend.  The idea is for teams to get more baseball and more reps on a weekend even if they aren’t in the “Big Game.”   Our underdawg games keep teams from going home after one game on Sunday.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">What We Do</h5>
                                    <p>Greater Orlando Baseball manages several events per year throughout Seminole, Orange, Osceola, Polk, Citrus, Volusia and Lake Counties.  We offer a variety of locations each weekend to give the option of traveling or staying close to home.   We offer baseball tournament play from 8u to HS divisions.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">Why We Do It</h5>
                                    <p>We believe all players, regardless of where they are in their development, deserve a chance to  compete in a competitive tournament environment.  Providing a 4 game guarantee allows all teams the opportunity to play more baseball and not leave defeated after one game on Sunday.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About Our Events</h5>
                                    <p>All of our 2 day events come with a 4 game guarantee.  We offer events nearly every weekend of the year to give teams a wide variety of events and locations all over Central Florida to choose from.  We do our best to create personal relationships with our coaches and strive to provide excellent customer service on a weekly basis.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About our Growth</h5>
                                    <p>Greater Orlando Baseball has expanded from an event or two per month in Seminole County to events 47 weekends per year over a 7 county radius.  What started as a handful of local teams has grown into teams coming from all around the state.  Several times per year we also host teams from out of state and even a few international teams come to play with us.  Greater Orlando Baseball was purchased by DS Sports Ventures in August 2018.   We are proud to be part of the DS Sports family and look forward to the exciting things to come.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="our-team">
                        <div className="container">
                            <h1 className="section_title">Our Team</h1>
                            {/* <p className="text-center mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cras urna gravida duis in nulla. Nec at bibendum tristique feugiat volutpat amet enim quisque.</p> */}
                            <div className="team-list">
                                <div className="row">
                                    {this.renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactUs cType="PW Baseball" increment={this.increment} decrement={this.decrement} />
                </div>
                <div className="upcomingevent_section">
                    <Shop cType="gob" />
                </div>
                <ProudSponsors increment={this.increment} decrement={this.decrement} />
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
                {
                    openPopup &&
                    <TeamInfo
                        flag={openPopup}
                        close={this.handleClose}
                        content={popupData}
                    />
                }
            </div>
        )
    }
}

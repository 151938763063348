import {
    COM_PLAYER_SPOTLIGHT_REQUEST,
    COM_PLAYER_SPOTLIGHT_SUCCESS,
    COM_PLAYER_SPOTLIGHT_ERROR,
} from '../../constants/landing/company.const';

const initialState = {
    playerSpotlightReq: false,
    playerSpotlightData: null,
    playerSpotlightError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Player spotlight req    
        case COM_PLAYER_SPOTLIGHT_REQUEST:
            return {
                ...state,
                playerSpotlightReq: true,
            };
        case COM_PLAYER_SPOTLIGHT_SUCCESS:
            return {
                ...state,
                playerSpotlightData: action.payload,
            };
        case COM_PLAYER_SPOTLIGHT_ERROR:
            return {
                ...state,
                playerSpotlightError: action.error,
            };

        default:
            return state;
    }
}
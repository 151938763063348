import {
    GET_PLAYER_TEAM_SEARCH_REQUEST,
    GET_PLAYER_TEAM_SEARCH_SUCCESS,
    GET_PLAYER_TEAM_SEARCH_ERROR,

    SAVE_PLAYER_TEAM_REQUEST,
    SAVE_PLAYER_TEAM_SUCCESS,
    SAVE_PLAYER_TEAM_ERROR,

    SAVE_SKIP_PLAYER_REQUEST,
    SAVE_SKIP_PLAYER_SUCCESS,
    SAVE_SKIP_PLAYER_ERROR,
} from '../../../../constants/authantication/signup/player/team.const';

const initialState = {
    searchPlayerTeamRes: false,
    searchPlayerTeamData: null,
    searchPlayerTeamDataError: null,

    addPlayerTeamRes: false,
    addPlayerTeamData: null,
    addPlayerTeamDataError: null,

    skipStepPlayerRes: false,
    skipStepPlayerData: null,
    skipStepPlayerDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Zipcode Search Data
        case GET_PLAYER_TEAM_SEARCH_REQUEST:
            return {
                ...state,
                searchPlayerTeamRes: true
            };
        case GET_PLAYER_TEAM_SEARCH_SUCCESS:
            return {
                ...state,
                searchPlayerTeamData: action.payload,
            };
        case GET_PLAYER_TEAM_SEARCH_ERROR:
            return {
                ...state,
                searchPlayerTeamDataError: action.error,
            };

        // save team
        case SAVE_PLAYER_TEAM_REQUEST:
            return {
                ...state,
                addPlayerTeamRes: true
            };
        case SAVE_PLAYER_TEAM_SUCCESS:
            return {
                ...state,
                addPlayerTeamData: action.payload,
            };
        case SAVE_PLAYER_TEAM_ERROR:
            return {
                ...state,
                addPlayerTeamDataError: action.error,
            };

        // skip player
        case SAVE_SKIP_PLAYER_REQUEST:
            return {
                ...state,
                skipStepPlayerRes: true
            };
        case SAVE_SKIP_PLAYER_SUCCESS:
            return {
                ...state,
                skipStepPlayerData: action.payload,
            };
        case SAVE_SKIP_PLAYER_ERROR:
            return {
                ...state,
                skipStepPlayerDataError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { imagesArr } from '../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../components/leftAuthImage';
import CommonHeroBanner from '../components/commonHeroBanner';
import { getCode } from '../saga/actions/authantication/signup/step2.action';
import { forgotData } from '../saga/actions/authantication/forgot.action';
import commonCalls from "../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import Preloader from '../components/preloader';

class forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ptype: "password",
            phone: "",
            cCode: "+1",
            loader: false,
            email: ""     
        }

        this.commonCall = new commonCalls();
        this.validatorphone = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatoremail = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        this.props.getCode();
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.setting &&
            nextProps.setting.settingData
        ) {
            this.setState({
                codeArr: nextProps.setting.settingData.countryCode.settingValue
            }, () => nextProps.setting.settingData = null)
        }

        if (nextProps.forgotDetails &&
            nextProps.forgotDetails.forgotReq &&
            nextProps.forgotDetails.forgotData
        ) {
            nextProps.forgotDetails.forgotReq = false;
            if (nextProps.forgotDetails.forgotData.flag) {
                this.commonCall.successMessage(nextProps.forgotDetails.forgotData.message);
                let no = this.state.cCode + this.state.phone;
                let email = this.state.email;
                this.commonCall.setStorage("mobile", no, flag => {
                    if (flag) {
                        this.commonCall.setStorage("email",email,flag=>{
                            if (flag) {
                                this.setLoader(false);
                                this.props.history.push(`/fverify/${nextProps.forgotDetails.forgotData.result.resetPasswordToken}`);
                                nextProps.forgotDetails.forgotData = null;
                            }
                        })
                    }
                });
            } else {
                this.commonCall.errorMessage(nextProps.forgotDetails.forgotData.message);
                nextProps.forgotDetails.forgotData = null;
                this.setLoader(false);
            }
        }
    }

    onChangeFn = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
              if (re.test(value) || value.length <= 0) {
                this.setState({
                    [name]: value
                })
              }
            }
          } else {
            this.setState({
                [name]: value
            })
          }
        this.validatorphone.hideMessages();
        this.validatoremail.hideMessages();
    };

    resetOtp = (e) => {
        e.preventDefault();

        if (this.validatorphone.allValid() || this.validatoremail.allValid()) {
            let data = {
                phone: this.state.phone,
                email: this.state.email
            }
            this.setLoader(true);
            this.props.forgotData(data);
        } else {
            this.validatorphone.showMessages();
            this.validatoremail.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>Forgot Password</h3>
                                    <p>Please enter your Mobile number</p>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <div className="svg_icon">
                                            <div className="input-group">
                                                <div className="counrty-no">
                                                    <select className="custom-select" name="cCode" value={this.state.cCode} onChange={this.onChangeFn}>
                                                        {
                                                            this.state.codeArr &&
                                                            this.state.codeArr.map((
                                                                obj,
                                                                i
                                                            ) => {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        defaultValue={this.state.cCode}
                                                                        selected={
                                                                            this.state.cCode === obj.code
                                                                                ? "selected"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {obj.code}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <input type="text" className="form-control" name="phone" value={this.state.phone} onChange={this.onChangeFn} id="usr" placeholder="Enter mobile number" />
                                                <img src={imagesArr.smartphone} alt="eye" />
                                            </div>
                                            <span className="validMsg">{this.validatorphone.message('Mobile number', this.state.phone, 'required|phone')}</span>
                                        </div>
                                    </div>
                                    <h3 style={{ textAlign: "center" , marginBottom: "20px" }}>OR</h3>
                                    <div className="heading-for">
                                        <p>Please enter your Email address</p>
                                    </div>
                                    <div className="form-group">
                                        <div className="svg_icon">
                                            <div className="input-group">
                                                <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.onChangeFn} id="usr" placeholder="Enter email address" />
                                            </div>
                                            <span className="validMsg">{this.validatoremail.message('Email', this.state.email, 'required|email')}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Link className="btn w-100 text-center" onClick={this.resetOtp}>Get OTP</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

forgot.propTypes = {
    getCode: PropTypes.func.isRequired,
    forgotData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    setting: state.step2,
    forgotDetails: state.forgotData
});

export default connect(mapStateToProps, { getCode, forgotData })(forgot);

import { TRAININGINFO_REQUEST, TRAININGINFO_SUCCESS, TRAININGINFO_ERROR, REGINFO_REQUEST, REGINFO_SUCCESS, REGINFO_ERROR } from '../../../../constants/view/coach/profile/trainingInfo.const';


const initialState = {
    trainingInfoData: null,
    trainingInfoError: null,

    // regInfoReq: false,
    regInfoData:null,
    regInfoError:null,
};


export default function (state = initialState, action) {
    switch (action.type) {

        case TRAININGINFO_SUCCESS:
            return {
                ...state,
                trainingInfoData: action.payload,
            };

        case TRAININGINFO_ERROR:
            return {
                ...state,
                trainingInfoError: action.error,
            };

        // case REGINFO_REQUEST:
        //     return {
        //         ...state,
        //         regInfoReq: true,
        //     };

        case REGINFO_SUCCESS:
            return {
                ...state,
                regInfoData: action.payload,
            };

        case REGINFO_ERROR:
            return {
                ...state,
                regInfoError: action.error,
            };


        default:
            return state;
    }
}

export const PLAYER_CART_REQUEST = 'PLAYER_CART_REQUEST';
export const PLAYER_CART_SUCCESS = 'PLAYER_CART_SUCCESS';
export const PLAYER_CART_ERROR   = 'PLAYER_CART_ERROR';

export const DEL_VIDEO_REQUEST = 'DEL_VIDEO_REQUEST';
export const DEL_VIDEO_SUCCESS = 'DEL_VIDEO_SUCCESS';
export const DEL_VIDEO_ERROR   = 'DEL_VIDEO_ERROR';

export const PLAYER_CART_VIDEO_REQUEST = 'PLAYER_CART_VIDEO_REQUEST';
export const PLAYER_CART_VIDEO_SUCCESS = 'PLAYER_CART_VIDEO_SUCCESS';
export const PLAYER_CART_VIDEO_ERROR   = 'PLAYER_CART_VIDEO_ERROR';


export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_ERROR   = 'VIDEO_LIST_ERROR';

import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { imagesArr } from '../../../assets/images';
import commonCalls from '../../../businesslogic/commonCalls';
import Preloader from '../../../components/preloader';
import { postFileUpload } from '../../../saga/actions/common/api.action';
import { addVideo, getVideoList, delVideo } from '../../../saga/actions/views/player/cart.action';
import { FILEUPLOAD_SUCCESS } from '../../../saga/constants/common/api.const';
import { DEL_VIDEO_SUCCESS, PLAYER_CART_SUCCESS, PLAYER_CART_VIDEO_ERROR, PLAYER_CART_VIDEO_SUCCESS, VIDEO_LIST_SUCCESS } from '../../../saga/constants/view/player/cart.const';
import VideoPlayer from '../../popup/videoPlayer';
import Confirmation from '../../popup/confirmation';

const initialState = {
    videoId: "",
    file: "",
    title: "",
    loader: false,
    videoArr: [],
    delPopup: false,
    delId: "",
    setVideo: false
};

const reducer = (state, payload) => ({ ...state, ...payload });

function AddVideo({ flag, close, videoList, uid }) {
    const dispatch = useDispatch();
    const commonCall = new commonCalls();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [video, setVideo] = useReducer(reducer, initialState);
    const { fileUploadData: _LOGO } = useSelector((state) => state.api);
    const { videoList: _GET_VIDEO, delVideoData: _DEL_VIDEO, videoData: _CREATE, viedoError: _CREATERROR } = useSelector((state) => state.cartInfo);
    const [videoPopup, setVideoPopup] = useState({
        isOpen: false,
        showVideo: [],
    })

    useEffect(() => {
        if (flag) {
            document.body.classList.add("modal-open");
            getVideoList({ playerId: uid })(dispatch);
        }
    }, [flag]);

    useEffect(() => {
        if (_LOGO) {
            setVideo({
                ...video,
                videoId: _LOGO._id
            })
            setLoader(false);
            dispatch({
                type: FILEUPLOAD_SUCCESS,
                error: null
            })
        }
    }, [_LOGO])

    useEffect(() => {
        if (video.file) {
            setLoader(true);
            postFileUpload(video.file, 19)(dispatch);
        }
    }, [video.file])

    useEffect(() => {
        if (_GET_VIDEO) {
            if (_GET_VIDEO.result.cVideos.length !== 0) {
                let arr = [];
                for (let i = _GET_VIDEO.result.cVideos.length - 1; i >= 0; i--) {
                    arr.push(_GET_VIDEO.result.cVideos[i])
                }
                setVideo({
                    ...video,
                    videoArr: arr,
                })
                setLoader(false);
            } else {
                setVideo({
                    ...video,
                    videoArr: _GET_VIDEO.result.cVideos,
                })
                setLoader(false);
            }
            dispatch({
                type: VIDEO_LIST_SUCCESS,
                payload: null
            })
        }
    }, [_GET_VIDEO])

    useEffect(() => {
        if (_DEL_VIDEO) {
            console.log("_DEL_VIDEO:--", _DEL_VIDEO);
            if (_DEL_VIDEO.flag) {
                commonCall.successMessage(_DEL_VIDEO.message);
                dispatch({
                    type: DEL_VIDEO_SUCCESS,
                    payload: null
                })
                document.body.classList.remove("video-open");
                setVideo({
                    ...video,
                    delPopup: false,
                    delId: "",
                })
                getVideoList({ playerId: uid })(dispatch);
            } else {
                commonCall.errorMessage(_DEL_VIDEO.message);
                dispatch({
                    type: DEL_VIDEO_SUCCESS,
                    payload: null
                })
            }
        }
    }, [_DEL_VIDEO])

    useEffect(() => {
        if (_CREATE) {
            if (_CREATE.flag) {
                validator.current.hideMessages();
                let arr = [];
                for (let i = _CREATE.result.data.length - 1; i >= 0; i--) {
                    arr.push(_CREATE.result.data[i])
                }
                setVideo({
                    ...video,
                    videoArr: arr,
                })
                commonCall.successMessage(
                    _CREATE.message
                );
                dispatch({ type: PLAYER_CART_VIDEO_SUCCESS, payload: null })
            }
            else if (!_CREATE.flag) {
                commonCall.errorMessage(_CREATE.message);
            }


        }
    }, [_CREATE])

    const setLoader = (val) => {
        setVideo({
            loader: val
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVideo({
            ...video,
            [name]: value
        })
    }
    const Validate = (oInput) => {
        const _validFileExtensions = [".mp4", ".mov", ".avi", ".flv"];
        if (oInput.type == "file") {
            const sFileName = oInput.value;
            if (sFileName.length > 0) {
                let blnValid = false;
                for (var j = 0; j < _validFileExtensions.length; j++) {
                    var sCurExtension = _validFileExtensions[j];
                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }
                if (!blnValid) return false;
            }
        }
        return true;
    }
    const handleVideo = (e) => {
        let file = e.target.files[0];
        if (!Validate(e.target)) {
            return commonCall.errorMessage("Accept only MP4,MOV,AVI,FLV file");
        }
        if (file.size >= 52428800) {
            return commonCall.errorMessage("File is too big, Please select a file less than 50MB");
        }
        setVideo({
            ...video,
            file: file
        })
    }

    const onSave = () => {
        console.log("validator.current:--", validator.current);
        if (validator.current.allValid()) {
            console.log("video:--", video);
            let dVideo = {
                title: video.title,
                videoId: video.videoId
            }
            addVideo(dVideo,localStorage.getItem("rosterToken"))(dispatch);
            setVideo({
                ...video,
                file: "",
                title: "",
                videoId: ""
            })
            // getVideoList({ playerId: uid })(dispatch);
            // validator.current.showMessages(false);
            // forceUpdate(0);
            // close();
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }

    const openVideoPopup = (e) => {
        setVideoPopup({
            ...videoPopup,
            isOpen: true,
            showVideo: [e]

        })
    }

    const closePopup = () => {
        document.body.classList.remove('video-open');
        setVideoPopup({
            ...videoPopup,
            isOpen: false,
            showVideo: []
        })
    }

    const onCloseDelete = () => {
        document.body.classList.remove('video-open');
        setVideoPopup({
            ...videoPopup,
            isOpen: false,
            showVideo: []
        })
    }

    const deleteVideoPopup = (id) => {
        setVideo({
            ...video,
            delPopup: true,
            delId: id,
        })
    }

    const closeVideoPopup = () => {
        document.body.classList.remove("video-open");
        setVideo({
            ...video,
            delPopup: false,
            delId: "",
        })
    }

    const deleteVideo = () => {
        console.log("video:--", video.delId);
        setLoader(true);
        delVideo({ videoId: video.delId },localStorage.getItem("rosterToken"))(dispatch);
    }

    return (
        <>
            <section className={`common-modal ${flag ? "show" : ""}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="green-header modal-header">
                            <h3>Add Video</h3>
                            <div className="modal_close" onClick={close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                        </div>
                        <div className="modal-body">
                            <div className="add-vide-inner">
                                <label className="common-lable">Upload Video</label>
                                <div className="selectfile">
                                    <div className="create_event_fileUpload file_custom_upload text-center">
                                        <p>Browse..</p>
                                        <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".mp4,.mov" onChange={handleVideo} />
                                    </div>
                                    <p>{video.file.name ? video.file.name : "No file selected"}</p>
                                </div>
                                < span className="validMsg">{validator.current.message("Video file", video.videoId, "required")}</span>

                                <label className="common-lable">Video</label>
                                <div className="form-group">
                                    <input type="text" name="title" className="form-control" placeholder="Video Name" value={video.title} onChange={handleChange} />
                                </div>
                                <span className="validMsg">{validator.current.message("Video title", video.title, "required")}</span>

                                <div className="flex-align next-button">
                                    <Link className="btn light-green" onClick={onSave}>Save</Link>
                                </div>

                                <ul className="video-thumbnail">
                                    {video.videoArr.length != 0 ?
                                        video.videoArr.map((e, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="video_img">
                                                        <img src={e.vId.thumbnailUrl[0]} alt="video_slide1" />
                                                        <Link className="video-icon" onClick={() => openVideoPopup(e._id)}>
                                                            <i className="fas fa-caret-right"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="video_dec">
                                                        <h6>{e.title}</h6>
                                                    </div>
                                                    <div className="delete-video" onClick={() => deleteVideoPopup(e._id)}>
                                                        <img src={imagesArr.deletes} alt="deletes" />
                                                    </div>
                                                </li>
                                            )
                                        })
                                        :
                                        <p>
                                            Upload and share your game practice videos.
                                        </p>
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            { videoPopup.isOpen && <VideoPlayer flag={videoPopup.isOpen} videoPoster={video.videoArr.length !== 0 && video.videoArr} showVideo={videoPopup.showVideo} close={closePopup} autoPlay muted />}
            <Preloader flag={video.loader} />
            {
                video.delPopup &&
                <Confirmation
                    flag={video.delPopup}
                    close={closeVideoPopup}
                    type="video"
                    ok={deleteVideo}
                />
            }
        </>
    );
}

export default AddVideo;

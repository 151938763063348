import React from 'react'

function Schedule({ allRecapDescription, poolArr, poolId, gameType, onHandleChange }) {

    return (
        <>
            {allRecapDescription?.scheduleLive ? <div className="pt-20">
                <div className="container">
                    <h1 className="section_title">Schedule</h1>
                    {
                        <div className="champions-Score schedule-filter p-0 mt-20">
                            <div className="select_btn mt-0">
                                <div className="row">
                                    <div className="w-25">
                                        <select name="gameType" value={gameType} className="custom-select" onChange={(e) => onHandleChange(e)}>
                                            <option value="All">All</option>
                                            <option value="Bracket">Bracket</option>
                                        </select>
                                    </div>
                                    {/* {
                                        poolArr?.length !== 0 &&
                                        gameType === "Matchup" &&
                                        <div className="w-25">
                                            <select name="poolId" value={poolId} className="custom-select" onChange={(e) => onHandleChange(e)}>
                                                <option value="">All</option>
                                                {
                                                    poolArr.map((pl) => {
                                                        return (
                                                            <option value={pl._id}>{pl.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    } */}
                                </div>

                            </div>
                        </div>
                    }
                    <div className="recap-table-info mb-60 pt-30">
                        <div className="table-responsive-xl">
                            {
                                allRecapDescription?.matchList &&
                                    allRecapDescription?.matchList.length !== 0 ?
                                    allRecapDescription?.matchList.map((tbl, i) => {
                                        return (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colspan="10" className="text-center">{tbl.date}</th>
                                                    </tr>
                                                    <tr>
                                                        {/* <th>Division</th> */}
                                                        <th>Start time {allRecapDescription.timeZoneType ? `(${allRecapDescription.timeZoneType})` : ""}
                                                            {/* to End time {allRecapDescription.timeZoneType ? `(${allRecapDescription.timeZoneType})` : ""} */}
                                                        </th>
                                                        <th>Location</th>
                                                        <th>Team</th>
                                                        <th colspan="2">Score</th>
                                                        <th>Team</th>
                                                        {/* <th colspan="2">Score</th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tbl.match.map((match) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td>{match.division ? match.division : "-"}</td> */}
                                                                    <td>{match.startTime ? match.startTime : "-"}</td>
                                                                    <td>{match.location ? match.location + ", " + match.field : "-"}</td>
                                                                    <td>{match.home_team ? match.home_team : "-"}</td>
                                                                    <td>{match.homeTeamScore ? match.homeTeamScore : match.homeTeamScore === 0 ? "0" : "-"}</td>
                                                                    <td>{match.awayTeamScore ? match.awayTeamScore : match.awayTeamScore === 0 ? "0" : "-"}</td>
                                                                    <td>{match.away_team ? match.away_team : "-"}</td>
                                                                    {/* <td>{match.awayTeamScore ? match.awayTeamScore : "-"}</td> */}
                                                                    {/* <td>{match.awayTeamScore ? match.awayTeamScore : "-"}</td> */}
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        )
                                    })
                                    :
                                    <p className='text-center'>No data found</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
                : <p className="text-center">Revised Schedule Coming Soon</p>
            }
        </>
    )
}

export default Schedule
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { geLinkedPlayerList, getPlayerToken } from '../../../saga/actions/views/fan/dashboard.action';
import { imagesArr } from '../../../assets/images';
import {
	EmailShareButton,
	EmailIcon,
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
} from "react-share";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { getActiveList, deleteSubscription, upgradeList } from '../../../saga/actions/common/subscription.action';
import { getEventDash, getProductList, getAdverticement, postFileUpload } from '../../../saga/actions/common/api.action';
import EventSlider from '../component/eventSlider';
import { checkLoginValidation } from '../../../saga/helpers/authToken';
import moment from 'moment';
import Cookies from 'js-cookie';
import Confirmation from '../../popup/confirmation';
import { saveBGphoto } from "../../../saga/actions/views/player/dashboard.action";
import Preloader from '../../../components/preloader';
import commonCalls from '../../../businesslogic/commonCalls';
import UpgradeSubscribe from '../../popup/UpgradeSubscribe';
import ResetPassword from '../../popup/resetPassword';
import { getFanProfile } from '../../../saga/actions/containers/header.action';
import { isMobile } from 'react-device-detect';
import DownloadApp from '../../popup/downloadApp';
import { commonHeader } from "../../../businesslogic/content";
class parentDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			role: "",
			loader: false,
			file: null,
			address: "",
			playerName: "",
			profileLogo: "",
			teamArr: "",
			teamRecord: "",
			userId: "",
			coachName: "",
			skipFlg: false,
			isImport: false,
			adsArr: [],
			linlekPlayerList: [],
			selectedPlayer: "",
			siteUrl: "",
			eventList: [],
			productList: [],
			activeFilter: {
				limit: 0
			},
			activeList: [],
			playerToken: "",
			sendEvents: {
				type: 1,
				page: 0,
				limit: 0,
				eventTeamType: 2,
				startDate: "",
				endDate: "",
				state: "",
				companies: "",
				rosterTeamId: "",
				currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
			},
			product: {
				fullDetail: true
			},
			delId: "",
			deletePopup: false,
			date: "",
			upgradeArr: [],
			upgradePopup: false,
			subId: "",
			successPopup: false,
			shareBtn: false,
			openTeamConfirm: false,

			invite: "",
			invitePopup: false,
			leaveTeamPopup: false,
			resetPassword: false,
			appPopUp: false
		}
		this.commonCall = new commonCalls();
	}

	componentDidMount() {
		let url = new URL(document.location);

		this.setLoader(true);
		window.scrollTo(0, 0);
		this.props.geLinkedPlayerList();
		this.props.getAdverticement({ moduleId: 3 }, "Fan");
		this.setState({
			siteUrl: url.origin,
			appPopUp: isMobile ? true : false
		})
		this.props.getFanProfile();
	}

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.parentDetails &&
			nextProps.parentDetails.lpListData.length !== 0 &&
			nextProps.parentDetails.lpListReq
		) {
			nextProps.parentDetails.lpListReq = false;
			console.log("nextProps.parentDetails.lpListData", nextProps.parentDetails.lpListData);
			if (nextProps.parentDetails.lpListData.length) {
				this.setState({
					linlekPlayerList: nextProps.parentDetails.lpListData,
					selectedPlayer: localStorage.getItem('lpid') ? localStorage.getItem('lpid') : nextProps.parentDetails.lpListData.length ? nextProps.parentDetails.lpListData[0]._id : ""
				}, () => {
					if (nextProps.parentDetails.lpListData.length) {
						localStorage.setItem('lpid', localStorage.getItem('lpid') || nextProps.parentDetails.lpListData[0]._id);
						let fetch = localStorage.getItem('lpid') ? nextProps.parentDetails.lpListData.find(e => e._id === localStorage.getItem('lpid')) : nextProps.parentDetails.lpListData[0]
						this.renderPlayerInfo(fetch);
					}
				})
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.fileUploadData
		) {
			console.log("nextProps.:---", nextProps.fileUpload.fileUploadData);

			this.setState({
				file: nextProps.fileUpload.fileUploadData.filePath,
			}, () => {
				this.props.saveBGphoto({
					cardBGImg: nextProps.fileUpload.fileUploadData._id
				}, localStorage.getItem("rosterToken"))
				nextProps.fileUpload.fileUploadData = null;
			})
		}

		if (nextProps.dashboardDetails &&
			nextProps.dashboardDetails.bgPhotoReq &&
			nextProps.dashboardDetails.bgPhotoData
		) {
			console.log("nextProps.:---", nextProps.dashboardDetails.bgPhotoData);
			nextProps.dashboardDetails.bgPhotoReq = false;
			this.setLoader(false);
			this.commonCall.successMessage(nextProps.dashboardDetails.bgPhotoData.message);
			nextProps.dashboardDetails.bgPhotoData = null;
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.getActiveReq &&
			nextProps.subInfo.getActiveData
		) {
			nextProps.subInfo.getActiveReq = false;
			console.log("nextProps.subInfo.getActiveData:--", nextProps.subInfo.getActiveData);
			if (nextProps.subInfo.getActiveData.flag) {
				this.setState({
					activeList: nextProps.subInfo.getActiveData.result.docs
				}, () => {
					nextProps.subInfo.getActiveData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.subInfo.getActiveData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.getAdsReq &&
			nextProps.fileUpload.getAdsData
		) {
			nextProps.fileUpload.getAdsReq = false;
			console.log("nextProps.fileUpload.getAdsData:--", nextProps.fileUpload.getAdsData);
			if (nextProps.fileUpload.getAdsData.flag) {
				this.setState({
					adsArr: nextProps.fileUpload.getAdsData.result.data
				}, () => {
					nextProps.fileUpload.getAdsData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.fileUpload.getAdsData = null;
				this.setLoader(false);
			}
		}


		if (nextProps.parentDetails &&
			nextProps.parentDetails.playerTokenReq &&
			nextProps.parentDetails.playerTokenData
		) {
			nextProps.parentDetails.playerTokenReq = false;
			console.log("nextProps.parentDetails.playerTokenData:--", nextProps.parentDetails.playerTokenData);
			if (nextProps.parentDetails.playerTokenData.flag) {
				this.setState({
					playerToken: nextProps.parentDetails.playerTokenData.result.token
				}, () => {
					localStorage.setItem("rosterToken", this.state.playerToken)
					localStorage.setItem("webglToken", this.state.playerToken)
					// if (localStorage.getItem("company")) {
					this.props.getEventDash(this.state.sendEvents, localStorage.getItem("rosterToken"));
					// }
					this.props.getProductList(this.state.product, "Fan", localStorage.getItem("rosterToken"));
					this.props.getActiveList(this.state.activeFilter, "Fan", localStorage.getItem("rosterToken"));
					nextProps.parentDetails.playerTokenData = null;
				})
			} else {
				nextProps.parentDetails.playerTokenData = null;
				setTimeout(() => {
					this.setLoader(false);
				}, 1500);
			}
		}

		if (nextProps.fileUpload &&
			nextProps.fileUpload.getEventDashReq &&
			nextProps.fileUpload.getEventDashData
		) {
			nextProps.fileUpload.getEventDashReq = false;
			console.log("nextProps.fileUpload.getEventDashData:--", nextProps.fileUpload.getEventDashData);
			if (nextProps.fileUpload.getEventDashData.flag) {
				this.setState({
					eventList: nextProps.fileUpload.getEventDashData.result.docs
				}, () => {
					nextProps.fileUpload.getEventDashData = null;
					setTimeout(() => {
						this.setLoader(false);
					}, 1500);
				})
			} else {
				nextProps.fileUpload.getEventDashData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.fileUpload && nextProps.fileUpload.productList) {
			if (nextProps.fileUpload.productList.flag) {
				this.setState((prevState) => ({
					...prevState,
					productList: nextProps.fileUpload.productList.result.data ? nextProps.fileUpload.productList.result.data : []
				}), () => {
					console.log("productList:--", this.state.productList);
					let getAnal = this.state.productList.some((d) => d.productId.name === "AnalyzR");
					let getBallr = this.state.productList.some((d) => d.productId.name === "BallR");
					let getBCTeam = this.state.productList.some((d) => d.productId.name === "BCTeam");
					let getPitchr = this.state.productList.some((d) => d.productId.name === "PitchR");
					if (getAnal) {
						localStorage.setItem("analProduct", true);
					}
					if (getBallr) {
						localStorage.setItem("ballrProduct", true);
					}
					if (getBCTeam) {
						localStorage.setItem("bcteamProduct", true);
					}
					if (getPitchr) {
						localStorage.setItem("pitchrProduct", true);
					}
					nextProps.fileUpload.productList = null;
					this.setLoader(false);
				})
			}
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.delActiveReq &&
			nextProps.subInfo.delActiveData
		) {
			nextProps.subInfo.delActiveReq = false;
			console.log("nextProps.subInfo.delActiveData:--", nextProps.subInfo.delActiveData);
			if (nextProps.subInfo.delActiveData.flag) {
				document.body.classList.remove('modal-open');
				this.setState({
					delId: "",
					deletePopup: false
				}, () => {
					this.props.getActiveList(this.state.activeFilter, "Fan", localStorage.getItem("rosterToken"));
				})
			} else {
				nextProps.subInfo.delActiveData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.subInfo &&
			nextProps.subInfo.getUpgradeReq &&
			nextProps.subInfo.getUpgradeData
		) {
			nextProps.subInfo.getUpgradeReq = false;
			console.log("nextProps.subInfo.getUpgradeData:--", nextProps.subInfo.getUpgradeData);
			if (nextProps.subInfo.getUpgradeData.flag) {
				this.setState({
					upgradeArr: nextProps.subInfo.getUpgradeData.result.data,
					upgradePopup: !this.state.upgradePopup
				}, () => {
					nextProps.subInfo.getUpgradeData = null;
					this.setLoader(false);
				})
			} else {
				nextProps.subInfo.getUpgradeData = null;
				this.setLoader(false);
			}
		}

		if (nextProps.profileDetails &&
			nextProps.profileDetails.headFanData
		) {
			if (nextProps.profileDetails.headFanData.result.profile) {
				this.setState({
					resetPassword: nextProps.profileDetails.headFanData.result.profile.ressetpasswordpop
				})
			}
		}
	}

	renderPlayerInfo = (data) => {
		this.setState(() => ({
			address:data && data.address,
			playerName:data && data.user.fname + " " + data.user.lname,
			playerId:data && data.user._id,
			profileLogo:data && data.profileUrl,
			teamArr:data && data.hasOwnProperty('parentTeam') && data.parentTeam,
			teamRecord:data && data.team.length !== 0 ? data.team[0].teamRecord : "",
			file:data && data.cardBGImg && data.cardBGImg.filePath ? data.cardBGImg.filePath : null,
			userId:data && data._id,
			invite:data && data.invite,
			coachName:data && data.team.length !== 0 ? data.team[0].coach.fname + " " + data.team[0].coach.lname : "",
			skipFlg:data && data.hasOwnProperty('skipTeam') ? data.skipTeam : false,
			isImport:data && data.hasOwnProperty('isImport') ? data.isImport === 1 ? true : false : false
		}), () => {
			this.props.parentDetails.lpListData = []
			this.props.getPlayerToken({ playerId: data.user._id })
		})
	}

	renderAdds = () => {
		return (
			this.state.adsArr.length !== 0 ?
				this.state.adsArr.map((list, i) => {
					return (
						<Link className="item" onClick={() => this.redirectNew(list.destination)}>
							{
								list.media.hasOwnProperty('_id') ?
									<img src={list.media.filePath} alt="slide1" />
									:
									<p>Ads</p>
							}
						</Link>
					);
				})
				:
				<Link className="item">
					<p>Ads</p>
				</Link>
		)
	}

	redirectPlayerCart = (name) => {
		localStorage.setItem("rostername", name)
		this.props.props.history.push(`/fan-cart/${this.state.playerId}`)
	}

	setLoader = (val) => {
		this.setState({
			loader: val
		})
	}

	setPlan = (typeId, data) => {
		var isPlans = "";
		data && data.plans.length > 0 &&
			data.plans.forEach((data) => {
				if (data.stripePlanId === typeId) return (isPlans = data);
			});
		return isPlans;
	}

	getUpgrade = (sid) => {
		this.setState({
			subId: sid
		}, () => {
			this.setLoader(true);
			this.props.upgradeList({ subscription_id: sid }, "Fan", localStorage.getItem("rosterToken"));
		})
	}

	onHandleUpload = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.onloadend = () => { };
		reader.readAsDataURL(file);

		setTimeout(() => {
			this.setLoader(true);
			this.props.postFileUpload(file, 18);
		}, 1000);
	}

	cancelSub = (subId, expiryDate) => {
		this.setState({
			delId: subId,
			deletePopup: true,
			date: expiryDate
		})
	}

	closePopup = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			delId: "",
			deletePopup: false
		})
	}

	confirmPopup = () => {
		this.setLoader(true);
		this.props.deleteSubscription({
			subscription_id: this.state.delId
		}, "Fan", localStorage.getItem("rosterToken"));
	}

	renderActiveList = () => {
		return (
			this.state.activeList.length !== 0 &&
			this.state.activeList.map((list, i) => {
				return (
					<tr key={i}>
						<th className="title">{list.productId.name} {this.setPlan(list.priceId, list.packageId).type === 1 ? "Monthly" : "Yearly"} Subscription -  {list.packageId && list.packageId.type + " " + list.packageId.role}</th>
						{
							list.stripeLogId &&
							list.stripeLogId.stripeLog &&
							list.stripeLogId.stripeLog.status === "trialing" &&
							list.packageId &&
							list.packageId.trialPeriod > 0 &&
							<td style={{ color: 'red' }}> Free Trial {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_start * 1000).format("DD MMM, YYYY")} - {moment(list.stripeLogId && list.stripeLogId.stripeLog && list.stripeLogId.stripeLog.trial_end * 1000).format("DD MMM, YYYY")}</td>
						}
						<td>{(list.isActive && list.isExpire) ? "Good through " : "Renew "} {moment(list.exprireDate).format("DD MMM")}, {moment(list.exprireDate).format("YYYY")}</td>
						<td className="status_link">
							{
								list.packageId && list.packageId.role === "Player" &&
								<>
									{
										list.isActive &&
										<Link className="green-status">Active</Link>
									}

									{
										this.setPlan(list.priceId, list.packageId).type === 2 &&
											list.packageId.type === "Gold" ?
											""
											:
											<Link className="blue-status" onClick={() => this.getUpgrade(list._id)}>Upgrade</Link>
									}
								</>
							}
						</td>
						<td className="payment_link">
							{
								list.packageId && list.packageId.role === "Coach" &&
								<span>Assigned by {list.userId.fname + " " + list.userId.lname}</span>
							}

							{/* {
									list.isActive && !list.isExpire &&
									<Link onClick={() => this.cancelSub(list.lastSubStripeId, list.exprireDate)}>Cancel Subscription</Link>
								}
								{
									!list.isActive && list.isExpire &&
									<Link style={{ pointerEvents: 'none', color: "lightgray" }}>Cancel Subscription</Link>
								}
								{
									list.isActive && list.isExpire &&
									<Link style={{ color: "#0cbf3e" }} onClick={() => this.nextPayment(list.packageId)}>Renew Now</Link>
								} */}
						</td>
					</tr>
				);
			})
			// :
			// <p className="text-center mb-0">There is no subscription active yet. <Link onClick={() => this.gotoLanding()}>Click Here</Link> to buy now.</p>
		)
	}

	gotoLanding = () => {
		localStorage.setItem("scrollLanding", "");
		this.props.props.history.push("/ballr");
	}

	changePlayer = (e) => {
		const { value } = e.target;
		let playerInfo = this.state.linlekPlayerList.find((r) => r._id === value)
		localStorage.setItem("lpid", playerInfo._id);
		this.setState((prevState) => ({
			...prevState,
			selectedPlayer: value,
			loader: true
		}), () => {
			this.renderPlayerInfo(playerInfo);
		})
	}

	redirectDetails = (data) => {
		var isloggedIn = checkLoginValidation();
		if (isloggedIn) {
			if (this.state.sendEvents.type === 2) {
				this.setCompany(data);
			} else {
				this.props.props.history.push(`/event-detail/${data._id}`);
			}
		} else {
			this.props.history.push("/login");
		}
	}

	setCompany = (data) => {
		if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== data.companies._id) {
			let companyname = data.companies.name === "GOBTournament" ? "Greater Orlando Baseball" : data.companies.name;
			let item = commonHeader.events.filter((e) => e.label === companyname)[0];
			if (item.key !== "gob" && item.key !== "utb") {
				localStorage.setItem("cid", data.companies._id)
				localStorage.setItem("company", item.key)
				localStorage.setItem("companyColor", JSON.stringify(item.colors));
				localStorage.setItem("recapInfo", JSON.stringify(data))
				let company = (localStorage.getItem('company') === "gob" || localStorage.getItem('company') === "utb" || localStorage.getItem('company') === "pwb") ? "pwb" : "pws"
				this.props.props.history.push(`/${company}-event-info/${data._id}`);
			}
		} else {
			if (localStorage.getItem('company') !== "gob" && localStorage.getItem('company') !== "utb") {
				localStorage.setItem("recapInfo", JSON.stringify(data))
				let company = (localStorage.getItem('company') === "gob" || localStorage.getItem('company') === "utb" || localStorage.getItem('company') === "pwb") ? "pwb" : "pws"
				this.props.props.history.push(`/${company}-event-info/${data._id}`);
			}
		}
	}

	redirectWebgl = (name, url) => {
		if (name === "BCTeam") {
			Cookies.set('gameType', localStorage.getItem("gameType"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
			localStorage.setItem("webglUrl", url);
			this.props.props.history.push("/bcteamapp");
		} else if (name === "BallR") {
			localStorage.setItem("webglUrl", url);
			this.props.props.history.push("/ballrapp");
		} else {
			localStorage.setItem("webglUrl", url);
			this.props.props.history.push("/analyzrapp");
		}
	}

	eventFilter = (name, value) => {
		this.setState((prevState) => ({
			...prevState,
			sendEvents: {
				...prevState.sendEvents,
				[name]: value
			},
		}), () => {
			// if (localStorage.getItem("company")) {
			this.setLoader(true);
			this.props.getEventDash(this.state.sendEvents, localStorage.getItem("rosterToken"));
			// }
		})
	}

	onHandleClose = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			upgradePopup: !this.state.upgradePopup,
			upgradeArr: [],
			subId: ""
		})
	}

	onHandleOk = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			upgradePopup: !this.state.upgradePopup,
			upgradeArr: [],
			subId: "",
			successPopup: true
		})
	}

	closeResetPassword = () => {
		document.body.classList.remove('modal-open');
		this.setState({
			resetPassword: false
		})
	}

	closeApp = () => {
		document.body.classList.remove('modal-open');
		this.setState((prevState) => ({
			...prevState,
			appPopUp: false
		}))
	}

	render() {
		const { appPopUp, resetPassword, address, playerName, coachName, teamArr, teamRecord, file, shareBtn, userId, siteUrl, skipFlg, invite, linlekPlayerList, selectedPlayer, deletePopup, upgradePopup, upgradeArr, subId } = this.state

		var settings = {
			dots: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			arrows: true,
		};

		var product = {
			dots: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: false,
			initialSlide: 0,
			arrows: true,
			autoplay: false,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1445,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: true,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,
						variableWidth: true,
					}
				},
			]
		};

		return (
			linlekPlayerList.length !== 0 ?
				<>
					<section className="fan-dashboard">
						<div className="container">
							<div className="mb-15">
								<div className="event-roster-select">
									<label className="common-lable">Choose Player:</label>
									<select className="custom-select" name="selectedPlayer" value={selectedPlayer} onChange={(e) => this.changePlayer(e)}>
										<option value="" selected disabled>Choose Player</option>
										{linlekPlayerList.map((l, i) => {
											return (
												<option value={l._id} key={i}>{l.user.fname + " " + l.user.lname}</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className="common-dashbord mt-15">
								<div className="common-div">
									<h3> Your Team </h3>
									{
										teamArr && invite &&
										<>
											<div className="team_logo">
												<img src={teamArr && teamArr.teamLogo ? teamArr.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team_logo" />
											</div>
											<h4 className="p-black">{teamArr && teamArr.name}</h4>
											<div className="form-group" data-left="You" data-right="Opp.">
												<input type="text" name="you" value={teamRecord} className="form-control" id="usr" placeholder="00-00" readOnly />
											</div>
										</>

									}
									{
										teamArr && !invite &&
										<h4 className="p-black">
											We are still waiting for Coach {coachName} to approve your roster spot
										</h4>
									}

									{
										!teamArr && invite && !skipFlg &&
										<h4 className="p-black">
											You're not currently rostered on a team
										</h4>
									}

									{
										skipFlg && !teamArr &&
										<h4 className="p-black">
											You're not currently rostered on a team
										</h4>
									}

									<div className="profile-btn btn-group">
										{
											teamArr && teamArr.shoplink &&
											<a className="btn blue_btn w-100" href={teamArr.shoplink} target="_blank"><i class="fas fa-shopping-bag"></i>Store</a>
										}
									</div>
								</div>
								<div className="dashbord-center">
									<div className="slider-common">
										<Slider {...settings}>
											{this.renderAdds()}
										</Slider>
									</div>
									<div className="upload_file subcoach-access">
										<Link className="upload_inner" to={"/fan-uploadcsv"}>
											<div className="upload_img">
												<img src={imagesArr.white_upload} alt="teamwork" />
											</div>
											<h5>Upload Data</h5>
										</Link>
										<Link className="upload_inner" to={"/fan-setting"}>
											<div className="upload_img">
												<img src={imagesArr.edit_profile} alt="edit_profile" />
											</div>
											<h5>Edit Profile</h5>
										</Link>
									</div>
								</div>
								<div className="common-div profile-card">
									<div className="mainprofile">
										<div className="img-profile">
											{
												file ?
													<img src={file} alt="playerprofile" />
													: ""
											}
										</div>
										<div className="dragdrop-img">
											<div className="file_profile_upload text-center">
												<i className="fas fa-camera"></i>
												{/* .heic,.HEIC, */}
												<input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={this.onHandleUpload} />
											</div>
										</div>
										<div className="profiletext">
											<div className="playerprofileicon">
												<img src={teamArr && invite && teamArr.teamLogo ? teamArr.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="profile" />
											</div>
											<div className="profiletitle">
												<h5 className="text-uppercase">{playerName}</h5>
												{
													teamArr && invite &&
													<>
														<span>Team:</span>
														<h6>{teamArr && teamArr.name}</h6>
													</>
												}
											</div>
											{
												teamArr && invite &&
												<p className="playerclip">{address ? address.state + "," + address.city + "," + address.zipcode : ""}</p>
											}
										</div>
										<div className="profile-buttons">
											<Link className="btn light-green" onClick={() => this.redirectPlayerCart(teamArr?.name)}>View</Link>
											<div className={`share-icon ${shareBtn ? 'active' : ''}`}>
												<Link className="btn blue_btn mr-0" onClick={() => this.setState({ shareBtn: !shareBtn })}>Share</Link>
												<ul>
													<li><FacebookShareButton url={`${siteUrl}/share-cart/${userId}`} children={<FacebookIcon size={32} round={true} />} /></li>
													<li> <EmailShareButton url={`${siteUrl}/share-cart/${userId}`} subject="Baseballcloud" children={<EmailIcon size={32} round={true} />} /></li>
													<li><TwitterShareButton url={`${siteUrl}/share-cart/${userId}`} children={<TwitterIcon size={32} round={true} />} /></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="products">
						<div className="container">
							<div className="white-bg">
								<div className="roster-event-tab">
									<h3>My Events</h3>
									<div className="roster-event-tab-info">
										<div className="list-group-scroll">
											<ul className="list-group">
												<li class={`list-group-item ${this.state.sendEvents.type === 2 ? "active" : ""} `} onClick={() => this.eventFilter("type", 2)}>Past</li>
												<li class={`list-group-item ${this.state.sendEvents.type === 1 ? "active" : ""} `} onClick={() => this.eventFilter("type", 1)}>Upcoming</li>
											</ul>
										</div>
										<div className="list-group-scroll">
											<ul className="list-group">
												<li class={`list-group-item ${this.state.sendEvents.eventTeamType === 1 ? "active" : ""} `} onClick={() => this.eventFilter("eventTeamType", 1)}>Tournament</li>
												<li class={`list-group-item ${this.state.sendEvents.eventTeamType === 2 ? "active" : ""} `} onClick={() => this.eventFilter("eventTeamType", 2)} >Showcase</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="event_slider">
									<div className="all_event">
										<EventSlider
											eventList={this.state.eventList}
											redirectDetails={this.redirectDetails} />

									</div>
								</div>
							</div>
						</div>
					</section>
					{this.state.productList.length !== 0 && <section className="products">
						<div className="container">
							<div className="white-bg">
								<h3> My Products</h3>
								<div className="produc-common">
									<Slider {...product}>
										{this.state.productList.length !== 0 &&
											this.state.productList.map((e, item) => {
												return (
													<div className="item" key={item}>
														<div className="items">
															<div className="team_img">
																{
																	e.productId.name !== "BCTeam" &&
																	<img src={e.productId.logo !== null ? e.productId.logo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="ballrteam" />
																}
																{
																	e.productId.name === "BCTeam" &&
																	<img src={teamArr.coach && teamArr.coach.profile["game-type"] === "2" ?
																		e?.productId?.secondary_logo ? e.productId.secondary_logo.filePath : imagesArr.default_user : e.productId.logo !== null ?
																			e.productId.logo.filePath : imagesArr.default_user} alt="ballrteam" />
																}
																<span>{e.productId.name}</span>
															</div>
															<div className="team_dec">
																<div className="team_name">
																	<h6>{e.productId.name === "BCTeam" && teamArr.coach && teamArr.coach.profile["game-type"] === "2" ? "SCTeam" : e.productId.name}</h6>
																</div>
																<span>{e.role === "Coach" ? "" : "Expires on " + e.stripeLogId.hasOwnProperty('stripeLog') ? moment(e.stripeLogId && e.stripeLogId.stripeLog && e.stripeLogId.stripeLog.current_period_end * 1000).format("LL") : ""}</span>
																<Link className="btn light-green w-100" onClick={() => this.redirectWebgl(e.productId.name, e.productId.url)}>Open {e.productId.name === "BCTeam" && teamArr.coach && teamArr.coach.profile["game-type"] === "2" ? "SCTeam" : e.productId.name}</Link>
															</div>
														</div>
													</div>
												)
											})
										}
									</Slider>
									{this.state.productList.length === 0 &&
										<p className="text-center mb-0">You haven't subscribed to any plan yet!</p>
									}
								</div>
							</div>
						</div>
					</section>}
					{this.state.activeList.length !== 0 && <section className="products">
						<div className="container">
							<div className="white-bg">
								<h3>Active subscription</h3>
								<p className="mb-0">Your Active Subscription</p>
								<div className="table-responsive-xl">
									<table className="table">
										<tbody>
											{this.renderActiveList()}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</section>}
					<Confirmation
						flag={deletePopup}
						type="subcription"
						close={this.closePopup}
						confirm={this.confirmPopup}
						date={this.state.date}
					/>
					{
						upgradePopup &&
						<UpgradeSubscribe
							flag={upgradePopup}
							data={upgradeArr}
							close={this.onHandleClose}
							sid={subId}
							ok={this.onHandleOk}
							type="Fan"
						/>
					}
					{
						resetPassword &&
						<ResetPassword
							flag={resetPassword}
							close={this.closeResetPassword}
							type="Fan"
						/>
					}
					{appPopUp && <DownloadApp
						flag={appPopUp}
						close={this.closeApp}
					/>}
					<Preloader flag={this.state.loader} />
				</> :
				<div className="fan-dashboard">
					<div className="container">
						<div className="white-bg">
							<h3 className="dashboard-text">Dashboard</h3>
						</div>
					</div>
				</div>
		)
	}
}
parentDashboard.propTypes = {
	geLinkedPlayerList: PropTypes.func.isRequired,
	getAdverticement: PropTypes.func.isRequired,
	getActiveList: PropTypes.func.isRequired,
	getPlayerToken: PropTypes.func.isRequired,
	getEventDash: PropTypes.func.isRequired,
	getProductList: PropTypes.func.isRequired,
	upgradeList: PropTypes.func.isRequired,
	deleteSubscription: PropTypes.func.isRequired,
	postFileUpload: PropTypes.func.isRequired,
	saveBGphoto: PropTypes.func.isRequired,
	getFanProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	profileDetails: state.headerData,
	parentDetails: state.fanDashboard,
	fileUpload: state.api,
	subInfo: state.subscriptionInfo,
	dashboardDetails: state.dashboardInfo,
})

export default connect(mapStateToProps, { postFileUpload, saveBGphoto, getActiveList, getAdverticement, geLinkedPlayerList, getPlayerToken, getEventDash, getProductList, deleteSubscription, upgradeList, getFanProfile })(parentDashboard);

import { OTHERINFO_REQUEST, OTHERINFO_SUCCESS, OTHERINFO_ERROR, POSTOTHERINFO_REQUEST, POSTOTHERINFO_SUCCESS, POSTOTHERINFO_ERROR, COLLEGEINFO_REQUEST, COLLEGEINFO_SUCCESS, COLLEGEINFO_ERROR } from "../../../../constants/view/player/profile/otherInfo.const";


const initialState = {
    otherInfoData: null,
    otherInfoError: null,

    collegeInfoData: null,
    collegeInfoError: null,
    collegeInfoObj: {},

    postOtherInfoData: null,
    postOtherInfoError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case OTHERINFO_SUCCESS:
            return {
                ...state,
                otherInfoData: action.payload,
            };

        case OTHERINFO_ERROR:
            return {
                ...state,
                otherInfoError: action.error,
            };

        case COLLEGEINFO_SUCCESS:
            return {
                ...state,
                collegeInfoData: action.payload,
            };

        case COLLEGEINFO_ERROR:
            return {
                ...state,
                collegeInfoError: action.error,
            };

        case POSTOTHERINFO_SUCCESS:
            return {
                ...state,
                postOtherInfoData: action.payload,
            };

        case POSTOTHERINFO_ERROR:
            return {
                ...state,
                postOtherInfoError: action.error,
            };

        default:
            return state;
    }
}
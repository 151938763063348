import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

class askHeadCoach extends Component {
    render() {
        const { flag } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="onlymodal_close" onClick={() => this.props.close()}><img src={imagesArr.modal_close} alt="modal_close" /></div>
                            <div className="modal-body">
                                <div className="sccess_team">
                                    <p><h4>
                                        Are you the program owner or administrator?
                                    </h4></p>
                                    <div className="flex-align">
                                        <Link className="btn" onClick={() => this.props.addNewProgram()}>Yes</Link>
                                        <Link className="btn gray" onClick={() => this.props.noHead()}>No</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default askHeadCoach;
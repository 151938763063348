import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { playerTrainingList } from '../../../../saga/actions/authantication/signup/player/training.action';
import Preloader from '../../../../components/preloader';

class training extends Component {

    constructor(props) {
        super(props)
        this.state = {
            trainingArr: {
                "devices": "yes",
                "train-device": "yes",
                "device-type": [],
                "train-device-type": [],
                "device-type-other": "",
                "train-device-type-other": "",
            },
            dType: [
                "rapsodo", "blast", "flight", "track", "hit", "dk", "yakkertech", "other"
            ],
            loader: false,
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorDeviceOther = new SimpleReactValidator({ autoForceUpdate: this });
        this.validatorTrainOther = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        this.props.onRef(this);
        this.setLoader(true);
        this.props.playerTrainingList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.trainingDetails &&
            nextProps.trainingDetails.trainingPlayerRes &&
            nextProps.trainingDetails.trainingPlayerData
        ) {
            nextProps.trainingDetails.trainingPlayerRes = false;
            if (nextProps.trainingDetails.trainingPlayerData.hasOwnProperty('_id')) {
                if (nextProps.trainingDetails.trainingPlayerData.fieldArr.devices === "yes") {
                    if (nextProps.trainingDetails.trainingPlayerData.fieldArr['train-device'] === "yes") {
                        this.setState({
                            trainingArr: {
                                ...nextProps.trainingDetails.trainingPlayerData.fieldArr
                            },
                        }, () => {
                            nextProps.trainingDetails.trainingPlayerData = null;
                            this.setLoader(false);
                        })
                    } else {
                        this.setState((prevState) => ({
                            trainingArr: {
                                ...prevState.trainingArr,
                                devices: nextProps.trainingDetails.trainingPlayerData.fieldArr.devices,
                                "train-device": nextProps.trainingDetails.trainingPlayerData.fieldArr['train-device'],
                                "device-type": nextProps.trainingDetails.trainingPlayerData.fieldArr['device-type'],
                            },
                        }), () => {
                            nextProps.trainingDetails.trainingPlayerData = null;
                            this.setLoader(false);
                        })
                    }
                } else {
                    if (nextProps.trainingDetails.trainingPlayerData.fieldArr['train-device'] === "yes") {
                        this.setState((prevState) => ({
                            trainingArr: {
                                ...prevState.trainingArr,
                                devices: nextProps.trainingDetails.trainingPlayerData.fieldArr.devices,
                                "train-device": nextProps.trainingDetails.trainingPlayerData.fieldArr['train-device'],
                                "train-device-type": nextProps.trainingDetails.trainingPlayerData.fieldArr['train-device-type'],
                            },
                        }), () => {
                            nextProps.trainingDetails.trainingPlayerData = null;
                            this.setLoader(false);
                        })
                    } else {
                        this.setState((prevState) => ({
                            trainingArr: {
                                ...prevState.trainingArr,
                                devices: nextProps.trainingDetails.trainingPlayerData.fieldArr.devices,
                                "train-device": nextProps.trainingDetails.trainingPlayerData.fieldArr['train-device'],
                            },
                        }), () => {
                            nextProps.trainingDetails.trainingPlayerData = null;
                            this.setLoader(false);
                        })
                    }
                }
            } else {
                this.setLoader(false);
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "devices" ||
            name === "train-device"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    [name]: value
                },
            }));
        }
    }

    renderDeviceType = () => {
        return (
            this.state.dType &&
            this.state.dType.map((item, i) => (
                <div className="check_box true">
                    <div className="check-box">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id={i}
                                value={item}
                                name={item}
                                checked={
                                    this.state.trainingArr['device-type'].find((d) => d === item)
                                        ? true
                                        : false
                                }
                                onClick={(e) => this.handleChangeDtype(e, item)}
                            />
                            <label htmlFor="dtype">
                                {item === "rapsodo" ?
                                    "Rapsodo" : item === "blast" ?
                                        "Blast Motion" : item === "flight" ?
                                            "FlightScope" : item === "track" ?
                                                "Trackman" : item === "hit" ?
                                                    "HitTrax" : item === "dk" ?
                                                        "Diamond Kinetics" : item === "yakkertech" ?
                                                            "Yakkertech" : item === "other" ?
                                                                "Other" : ""
                                }
                            </label>
                        </div>

                    </div>
                </div>
            ))
        )
    }

    renderFacilityDeviceType = () => {
        return (
            this.state.dType &&
            this.state.dType.map((item, i) => (
                <div className="check_box true">
                    <div className="check-box">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id={i}
                                value={item}
                                name={item}
                                checked={
                                    this.state.trainingArr['train-device-type'] ? this.state.trainingArr['train-device-type'].find((d) => d === item)
                                        ? true
                                        : false : false
                                }
                                onClick={(e) => this.handleChangeFtype(e, item)}
                            />
                            <label htmlFor="dtype">
                                {item === "rapsodo" ?
                                    "Rapsodo" : item === "blast" ?
                                        "Blast Motion" : item === "flight" ?
                                            "FlightScope" : item === "track" ?
                                                "Trackman" : item === "hit" ?
                                                    "HitTrax" : item === "dk" ?
                                                        "Diamond Kinetics" : item === "yakkertech" ?
                                                            "Yakkertech" : item === "other" ?
                                                                "Other" : ""
                                }
                            </label>
                        </div>

                    </div>
                </div>
            ))
        )
    }

    handleChangeFtype = (e, item) => {
        if (this.state.trainingArr['train-device-type'].find((d) => d === item)) {
            // subtraction
            let tempArray = this.state.trainingArr['train-device-type'].filter((d) => d !== item);
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['train-device-type']: tempArray
                },
            }));
        } else {
            // addition
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['train-device-type']: [...prevState.trainingArr['train-device-type'], item]
                },
            }));
        }
    };

    handleChangeDtype = (e, item) => {
        if (this.state.trainingArr['device-type'].find((d) => d === item)) {
            // subtraction
            let tempArray = this.state.trainingArr['device-type'].filter((d) => d !== item);
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['device-type']: tempArray
                },
            }));
        } else {
            // addition
            this.setState((prevState) => ({
                ...prevState,
                trainingArr: {
                    ...prevState.trainingArr,
                    ['device-type']: [...prevState.trainingArr['device-type'], item]
                },
            }));
        }
    };

    validationUpdate = (cb) => {
        if (this.state.trainingArr['devices'] === "yes") {
            if (this.state.trainingArr['train-device'] === "yes") {
                if (this.validator.allValid()) {
                    if ((!!this.state.trainingArr['device-type'].find(e => e === "other") && !this.validatorDeviceOther.allValid()) || (!!this.state.trainingArr['train-device-type'].find(e => e === "other") && !this.validatorTrainOther.allValid())) {
                        this.validatorDeviceOther.showMessages(true);
                        this.validatorTrainOther.showMessages(true);
                        this.forceUpdate();
                    } else {
                        this.state.trainingArr["device-type-other"] = !!this.state.trainingArr['device-type'].find(e => e === "other") ? this.state.trainingArr["device-type-other"] : ""
                        this.state.trainingArr["train-device-type-other"] = !!this.state.trainingArr['train-device-type'].find(e => e === "other") ? this.state.trainingArr["train-device-type-other"] : ""
                        cb(this.state.trainingArr);
                    }
                } else {
                    this.validator.showMessages();
                    this.forceUpdate();
                }
            } else {
                if (!!this.state.trainingArr['device-type'].find(e => e === "other") && !this.validatorDeviceOther.allValid()) {
                    this.validatorDeviceOther.showMessages(true);
                    this.forceUpdate();
                } else {
                    let data = {
                        devices: this.state.trainingArr.devices,
                        "train-device": this.state.trainingArr['train-device'],
                        "device-type": this.state.trainingArr['device-type'],
                        "device-type-other": !!this.state.trainingArr['device-type'].find(e => e === "other") ? this.state.trainingArr["device-type-other"] : "",
                        "train-device-type-other": !!this.state.trainingArr['train-device-type'].find(e => e === "other") ? this.state.trainingArr["train-device-type-other"] : ""
                    }
                    cb(data);
                }
            }
        } else {
            if (this.state.trainingArr['train-device'] === "yes") {
                if (!!this.state.trainingArr['train-device-type'].find(e => e === "other") && !this.validatorTrainOther.allValid()) {
                    this.validatorTrainOther.showMessages(true);
                    this.forceUpdate();
                } else {
                    let data = {
                        devices: this.state.trainingArr.devices,
                        "train-device": this.state.trainingArr['train-device'],
                        "train-device-type": this.state.trainingArr['train-device-type'],
                        "device-type-other": !!this.state.trainingArr['device-type'].find(e => e === "other") ? this.state.trainingArr["device-type-other"] : "",
                        "train-device-type-other": !!this.state.trainingArr['train-device-type'].find(e => e === "other") ? this.state.trainingArr["train-device-type-other"] : ""
                    }
                    cb(data);
                }
            } else {
                let data = {
                    devices: this.state.trainingArr.devices,
                    "train-device": this.state.trainingArr['train-device'],
                    "device-type-other": !!this.state.trainingArr['device-type'].find(e => e === "other") ? this.state.trainingArr["device-type-other"] : "",
                    "train-device-type-other": !!this.state.trainingArr['train-device-type'].find(e => e === "other") ? this.state.trainingArr["train-device-type-other"] : ""
                }
                cb(data);
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { profileData } = this.props;
        const { trainingArr, loader } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname},
                        </h2>
                            <p>
                                Tell us about your training
                        </p>
                        </div>
                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">Do you own or train with a training device?</label>
                                <div className="check-box mb-0">
                                    <div className="checkbox" >
                                        <input type="radio" id="Baseball" value="yes" name="devices" onChange={(e) => this.onHandleChange(e)} checked={trainingArr['devices'] === "yes" ? true : false} />
                                        <label for="Baseball">Yes </label>
                                    </div>

                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Softball" value="no" name="devices" onChange={(e) => this.onHandleChange(e)} checked={trainingArr['devices'] === "no" ? true : false} />
                                        <label for="Softball">No </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('own device', trainingArr['devices'], 'required')}</span>
                            </div>
                        </div>
                        {
                            trainingArr['devices'] === "yes" &&
                            <>
                                <label className="common-lable">Which training device do you use?</label>
                                <div className="check_input row">
                                    {this.renderDeviceType()}
                                </div>
                                {/* <span className="validMsg">{this.validator.message('device type', trainingArr["device-type"], 'required')}</span> */}

                                {!!trainingArr["device-type"].find(e => e === "other") &&
                                    <><label className="common-lable">
                                        If other please explain
                                    </label>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter text here" value={trainingArr["device-type-other"]} onChange={(e) => this.setState((prevState) => ({ ...prevState, trainingArr: { ...prevState.trainingArr, "device-type-other": e.target.value } }))} />
                                            <span className="validMsg">{this.validatorDeviceOther.message('', trainingArr["device-type-other"], 'required', { className: 'text-danger' })}</span>
                                        </div>
                                    </>}
                            </>
                        }

                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">Do you train with a device at your baseball facility?</label>
                                <div className="check-box mb-0">
                                    <div className="checkbox" >
                                        <input type="radio" id="Baseball" value="yes" name="train-device" onChange={(e) => this.onHandleChange(e)} checked={trainingArr['train-device'] === "yes" ? true : false} />
                                        <label for="Baseball">Yes </label>
                                    </div>

                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Softball" value="no" name="train-device" onChange={(e) => this.onHandleChange(e)} checked={trainingArr['train-device'] === "no" ? true : false} />
                                        <label for="Softball">No </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('train device', trainingArr['train-device'], 'required')}</span>
                            </div>
                        </div>

                        {
                            trainingArr['train-device'] === "yes" &&
                            <>
                                <label className="common-lable">Which training device do you use?</label>
                                <div className="check_input row">
                                    {this.renderFacilityDeviceType()}
                                </div>
                                {/* <span className="validMsg">{this.validator.message('device type', trainingArr["device-type"], 'required')}</span> */}

                                {!!trainingArr["train-device-type"].find(e => e === "other") &&
                                    <><label className="common-lable">
                                        If other please explain
                                    </label>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter text here" value={trainingArr["train-device-type-other"]} onChange={(e) => this.setState((prevState) => ({ ...prevState, trainingArr: { ...prevState.trainingArr, "train-device-type-other": e.target.value } }))} />
                                            <span className="validMsg">{this.validatorTrainOther.message('', trainingArr["train-device-type-other"], 'required', { className: 'text-danger' })}</span>
                                        </div>
                                    </>}
                            </>
                        }
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

training.propTypes = {
    playerTrainingList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    trainingDetails: state.trainingPlayerDetails,
});

export default connect(mapStateToProps, { playerTrainingList })(training);

import React, { Component } from 'react'
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import { imagesArr } from '../../../assets/images';
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import Shop from "../../../views/company/components/common/shop";
import Preloader from '../../../components/preloader';
export default class gobInsurance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0,
        };
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { loaderCount } = this.state;
        return (
            <>
                <Header />
                <div className="event-main">
                    <div className="container">
                        <div className="insurance-info text-center">
                            <h1 className="section_title">Insurance</h1>
                            <p className="mt-15">Your team must be registered with Greater Orlando Baseball (free) in order to buy insurance.
                            Please click the link to register (free) your team first:</p>

                            <div className="insurance-box">
                                <img src={imagesArr.insurance} className="insurance_img" />
                                <i class="fa fa-link" aria-hidden="true"></i>
                                <a>Click here for free registration with Greater Orlando Baseball</a>
                                <p><span>**</span> IF YOU HAVE ALREADY REGISTERED YOUR TEAM WITH GREATER ORLANDO BASEBALL, CLICK THE BLUE REGISTER BUTTON ABOVE TO PURCHASE INSURANCE <span>**</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="upcomingevent_section">
                        <Shop cType="gob" />
                    </div>
                    <ProudSponsors increment={this.increment} decrement={this.decrement} />
                </div>
                <Preloader flag={loaderCount > 0 ? true : false} />
                <Footer />
            </>
        )
    }
}

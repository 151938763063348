import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { imagesArr } from "../../assets/images";
import Preloader from '../../components/preloader';
import { getTeamListByAge } from "../../saga/actions/common/accountCreation.action";
import { AGE_TEAM_LIST_SUCCESS } from "../../saga/constants/common/accountCreation.const";

const initialState = {
  loader: false,
  teamList: [],
  rosterId: "",
  coach: null,
  age: "",
  team: ""
}

const reducer = (state, payload) => ({ ...state, ...payload })

function ClaimAccount({flag, close,dataList}) {

  const dispatch = useDispatch();
  const history = useHistory();
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [state, setState] = useReducer(reducer, initialState); 
  const { ageTeamList: _TL } = useSelector((state) => state.accountCreation);

  useEffect(() => {
    if (_TL) {
      setState({
        ...state,
        teamList: _TL.data,
        loader: false
      })
      dispatch({
        type: AGE_TEAM_LIST_SUCCESS,
        payload: null
      })
    }
  }, [_TL])
  
  const handleAge = (e) => {
    let req = {
      eventId: dataList._id,
      slectedGroupFeeId: e.target.value
    }
    setState({
      ...state,
      rosterId: "",
      coach: "",
      loader: true,
      age: e.target.value
    })
    getTeamListByAge(req)(dispatch);
  }

  const handleTeam = (e) => {
    setState({
      ...state,
      rosterId: e.target.value,
      coach: state.teamList.find(_=> _.rosterTeamId._id === e.target.value)
    })
  }

  const setLoader = (val) => {
    setState({
      ...state,
      loader: val
    })
  }

  const onSave = () =>{
    if (validator.current.allValid()) {
      history.push(`/account-creation/${state.rosterId}/0`)
    }else{
      validator.current.showMessages();
      forceUpdate(1);
    }
  }

  return (
    <>
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>Claim Your Account</h3>
              <div className="modal_close" onClick={close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="claimHeading">
                <h3>Lets Connect You To The Account Your Coach Created</h3>
                <p>Select The Age Group Your Team Is Playing In For This Event</p>
              </div>
              <div className="team-body-inner">
                <div className="form-group mb-0">
                  <div className="add_roster">
                    <label className="common-lable">Age group</label>
                  </div>
                  <select className="custom-select" name="age" value={state.age} onChange={handleAge}  >
                  <option value="" selected disabled>Select your age</option>
                    {dataList.payment.feesByGroups.map(_ => {
                      return (
                        <option value={_._id}>{_.ageGroup === 19 ? "18U+" : _.ageGroup + "U"} - {_.ageGroupTo === 19 ? "18U+" : _.ageGroupTo + "U"}</option>
                      )
                    })
                    }
                  </select>
                  <span className="validMsg">{validator.current.message("age group", state.age, "required", { className: "text-danger" })}</span>
                </div>

                <div className="form-group mb-0">
                  <div className="add_roster">
                    <label className="common-lable">Team</label>
                  </div>
                  <select className="custom-select" name="rosterId" value={state.rosterId} onChange={handleTeam}  >
                  <option value="" selected disabled>Select your team</option>
                    {state.teamList.map(_ => {
                      return (
                        <option value={_.rosterTeamId._id}>{_.rosterTeamId.rosterName}</option>
                      )
                    })
                    }
                  </select>
                  <span className="validMsg">{validator.current.message("team", state.rosterId, "required", { className: "text-danger" })}</span>
                </div>
                <div className="form-group">
                    <label className="common-lable">Coach</label>
                    <input
                      type="text"
                      name="fname"
                      value={state.coach && state.coach.user.fname + " "  + state.coach.user.lname}
                      // onChange={this.onHandleChange}
                      className="form-control"
                      placeholder="Coach"
                      disabled
                    />
                  </div>
          
              </div>
              <div className="flex-align next-button">
                <Link className="btn light-green" onClick={() => onSave()}>
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Preloader flag={state.loader} />
    </>
  );
}
export default ClaimAccount;


import {
    GET_PLAYER_PERSONAL_INFO_REQUEST,
    GET_PLAYER_PERSONAL_INFO_SUCCESS,
    GET_PLAYER_PERSONAL_INFO_ERROR,

    SET_CALCULATE_DATA_REQUEST,
    SET_CALCULATE_DATA_SUCCESS,
    SET_CALCULATE_DATA_ERROR,

    GET_CALCULATE_DATA_REQUEST,
    GET_CALCULATE_DATA_SUCCESS,
    GET_CALCULATE_DATA_ERROR,

    SET_CONNECTION_REQ_REQUEST,
    SET_CONNECTION_REQ_SUCCESS,
    SET_CONNECTION_REQ_ERROR,

    SAVE_COMMITTED_REQUEST,
    SAVE_COMMITTED_SUCCESS,
    SAVE_COMMITTED_ERROR,
} from '../../../constants/view/company/personalInfo.const';

const initialState = {
    getPersonalInfoReq: false,
    getPersonalInfoData: null,
    getPersonalInfoError: null,

    setCalculateDataReq: false,
    setCalculateData: null,
    setCalculateDataError: null,

    getCalculateDataReq: false,
    getCalculateData: null,
    getCalculateDataError: null,

    setConnectionReq: false,
    setConnection: null,
    setConnectionError: null,

    committedDataReq: false,
    committedData: null,
    committedDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Complete committed data req    
        case SAVE_COMMITTED_REQUEST:
            return {
                ...state,
                committedDataReq: true,
            };

        case SAVE_COMMITTED_SUCCESS:
            return {
                ...state,
                committedData: action.payload,
            };
        case SAVE_COMMITTED_ERROR:
            return {
                ...state,
                committedDataError: action.error,
            };

        // Set Connection Data req    
        case SET_CONNECTION_REQ_REQUEST:
            return {
                ...state,
                setConnectionReq: true,
            };

        case SET_CONNECTION_REQ_SUCCESS:
            return {
                ...state,
                setConnection: action.payload,
            };
        case SET_CONNECTION_REQ_ERROR:
            return {
                ...state,
                setConnectionError: action.error,
            };

        // Get Calculate Data req    
        case GET_CALCULATE_DATA_REQUEST:
            return {
                ...state,
                getCalculateDataReq: true,
            };

        case GET_CALCULATE_DATA_SUCCESS:
            return {
                ...state,
                getCalculateData: action.payload,
            };
        case GET_CALCULATE_DATA_ERROR:
            return {
                ...state,
                getCalculateDataError: action.error,
            };

        // Set Calculate Data req    
        case SET_CALCULATE_DATA_REQUEST:
            return {
                ...state,
                setCalculateDataReq: true,
            };

        case SET_CALCULATE_DATA_SUCCESS:
            return {
                ...state,
                setCalculateData: action.payload,
            };
        case SET_CALCULATE_DATA_ERROR:
            return {
                ...state,
                setCalculateDataError: action.error,
            };

        // Get Player Profile req    
        case GET_PLAYER_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                getPersonalInfoReq: true,
            };

        case GET_PLAYER_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                getPersonalInfoData: action.payload,
            };
        case GET_PLAYER_PERSONAL_INFO_ERROR:
            return {
                ...state,
                getPersonalInfoError: action.error,
            };

        default:
            return state;
    }
}
import axios from "axios";
import {
  TEAMINFO_ERROR,
  TEAMINFO_SUCCESS,

  REASSIGN_REQUEST,
  REASSIGN_SUCCESS,
  REASSIGN_ERROR,
  POST_REASSIGN_SUCCESS,
  POST_REASSIGN_ERROR
} from "../../../../constants/view/coach/profile/teamInfo.const";
import { REGISTERINFO_SUCCESS } from "../../../../constants/view/coach/profile/personalInfo.const";
import { API_Url, Auth_Coach_Key, checkIsValidCoachToken } from "../../../../helpers/authToken";
import histrory from '../../../../../History';

export const getTeamInfo = () => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/coach/team-info`,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: TEAMINFO_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: TEAMINFO_ERROR,
          error: error.response.data.message,
        });
    })
};

export const postTeamInfo = (data) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/team-info`,
    data: data,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: TEAMINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

export const reassignInvite = () => (dispatch) => {
  checkIsValidCoachToken(histrory)
  dispatch({ type: REASSIGN_REQUEST })
  axios({
    method: "GET",
    url: `${API_Url}bc/coach/reassign/info`,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: REASSIGN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: REASSIGN_ERROR,
          error: error.response.data.message,
        });
    })
};

export const postReassignInvite = (data) => (dispatch) => {
  checkIsValidCoachToken(histrory)
  axios({
    method: "POST",
    url: `${API_Url}bc/coach/reassign`,
    data:data,
    headers: {
      "x-auth-token": Auth_Coach_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: POST_REASSIGN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
      {
        dispatch({
          type: POST_REASSIGN_ERROR,
          error: error.response.data,
        });
    }
    if (error.response && error.response.status === 400)
        dispatch({
          type: POST_REASSIGN_ERROR,
          error: error.response.data,
        });
    })
};
import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from '../../assets/images';

class bcHeader extends Component {

    redirectToSeat = () => {
        localStorage.setItem("seatIndex", "0");
        this.props.history.push("/coach-license");
    }

    render() {
        const { profileInfo, show, subCoach, subMenu, petaMenu, userType, teamArr, invite, notifyCount, subCoachInvite, userDropdown, gameType, showGameType } = this.props;
        return (
            <nav className="navbar navbar-expand-lg mainNav">
                <div className="container">
                    <div className="navbar_sec">
                    <Link className="navbar-brand" onClick={() => this.props.backToLanding("logo")}>
                            <img src={imagesArr.logo} alt="logo" />   
                        {/* {
                            !gameType ?
                                        <img src={imagesArr.logo} alt="logo" />    
                                :
                                <img src={showGameType === "2" ? imagesArr.SoftballCloudLogo : imagesArr.logo} alt="logo"/>
                               
                        } */}
                   
                    </Link>
                        {
                            show ? <img src={showGameType === "2" ? imagesArr.scWhitePostLogin : imagesArr.bcWhitePostLogin} alt="logo" />
                                : <img src={showGameType === "2" ? imagesArr.SoftballCloudLogo : imagesArr.bclogo} alt="logo" />
                        }
                    </div>
                    {/* <Link className="navbar-brand" style={{cursor:"default"}}>
                        {
                                <img src={imagesArr.SportsCloud} alt="logo" />
                        }
                    </Link> */}
                    <div className='d-flex'>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="https://baseballcloud.force.com/diamondconnect/s/" target="blank">Help</a>
                            </li>
                            {
                                show &&
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={() => this.props.backToHome()}>Dashboard</Link>
                                </li>
                            }
                            {/* <li className="nav-item hide-desktop">
                                <span className={`arrow ${subMenu === 'About' ? 'active' : ''}`} onClick={() => this.props.handleMenu('About')}><i className="fas fa-chevron-right"></i></span>
                                <Link className="nav-link">About</Link>
                                {this.props.renderAboutMenu("mobile")}
                            </li>
                            <li className="nav-item hide-desktop">
                                <span className={`arrow ${subMenu === 'Products' ? 'active' : ''}`} onClick={() => this.props.handleMenu('Products')}><i className="fas fa-chevron-right"></i></span>
                                <Link className="nav-link">Products</Link>
                                {this.props.renderProductMenu("mobile")}
                            </li> */}
                            <li className="nav-item hide-desktop">
                                <span className={`arrow ${subMenu === 'Event' ? 'active' : ''}`} onClick={() => this.props.handleMenu('Event')}><i className="fas fa-chevron-right"></i></span>
                                <Link className="nav-link">Events</Link>
                                {this.props.renderEventMenu("mobile")}
                            </li>
                            {/* <li className="nav-item hide-desktop powered-by" >
                            <p>products powered by <img src={imagesArr.main_logo} alt="main_logo" /></p>
                        </li> */}
                        </ul>
                        {/* <div className="powered-by">
                            <p>products powered by <img src={imagesArr.main_logo} alt="main_logo" /></p>
                        </div> */}
                    </div>
                    <div className="header_align">
                        {
                            (userType === "Coach" || userType === "Player" || userType === "Fan" || userType === "Parent") &&
                            <img className="header-bar" src={imagesArr.bar} />
                        }
                        {
                            (userType === "Coach" || userType === "Player" || userType === "Fan" || userType === "Parent") &&
                            <>
                                <div className="notification_alert">
                                    <Link>
                                        <i class="fas fa-bell"></i>
                                        {
                                            notifyCount !== 0 &&
                                            <span>{notifyCount}</span>
                                        }
                                    </Link>
                                    <div className="notification_dropdown">
                                        <ul className="notification_scroll" onScroll={this.props.getScroll}>
                                            {this.props.renderNotification()}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            show ?
                                <>
                                    <div className={userDropdown ? ' avatar-dropdown-menu open' : 'avatar-dropdown-menu'}>
                                        <div className={"login-user"} onClick={() => this.props.serDropDownFlg()}>
                                            <div className="avatar-image">
                                                <img src={profileInfo.uimg ? profileInfo.uimg : 'https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png'} alt="profile" />
                                            </div>{profileInfo.uname}<i className="fas fa-caret-down"></i>
                                        </div>
                                        <div className="avatar-dropdown-menu-items">
                                            <ul>
                                                {
                                                    userType === "Coach" &&
                                                    <>
                                                        <li>
                                                            <Link onClick={() => this.props.handlePetaMenu('Profile')} className={`user-dropdown ${petaMenu === 'Profile' ? 'active' : ''}`}>Edit Profile</Link>
                                                            <ul className={`user-submenu ${petaMenu === 'Profile' ? 'active' : ''}`}>
                                                                <li><Link onClick={() => this.props.changePassFlg()}>Change Password</Link></li>
                                                                <li><Link onClick={() => this.props.nextPage('profile')}>Edit Profile</Link></li>
                                                                <li><Link to={"/coach-perks"}>Perks</Link></li>
                                                                {!subCoachInvite && <li><Link to={"/coach-uploadcsv"}>Upload CSV</Link></li>}
                                                            </ul>
                                                        </li>
                                                        {
                                                            localStorage.getItem("company") &&
                                                            <li>
                                                                <Link to={'/coach-myevent'}>My Events</Link>
                                                            </li>
                                                        }
                                                        {!subCoachInvite && <li>
                                                            <Link onClick={() => this.props.nextPage('roster')}>Edit Roster</Link>
                                                        </li>}
                                                        {
                                                            !subCoach &&
                                                            <>

                                                                {/* <li>
                                                                        <Link to={"/coach-billing"}>Billing</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to={"/coach-subscription"}>Manage subscription</Link>
                                                                    </li> */}
                                                                <li>
                                                                    <Link onClick={() => this.redirectToSeat()}>Manage Seats</Link>
                                                                </li>
                                                            </>
                                                        }
                                                        <li>
                                                            <Link onClick={() => this.props.supportZendesk()}>Support</Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => this.props.logout()}>Logout</Link>
                                                        </li>
                                                    </>
                                                }
                                                {
                                                    userType === "Player" &&
                                                    <>
                                                        <li>
                                                            <Link onClick={() => this.props.handlePetaMenu('Profile')} className={`user-dropdown ${petaMenu === 'Profile' ? 'active' : ''}`}>Edit Profile</Link>
                                                            <ul className={`user-submenu ${petaMenu === 'Profile' ? 'active' : ''}`}>
                                                                <li><Link onClick={() => this.props.changePassFlg()}>Change Password</Link></li>
                                                                <li><Link onClick={() => this.props.nextPage('profile')}>Edit Profile</Link></li>
                                                                <li><Link to={"/player-cart"}>Edit Card</Link></li>
                                                                {
                                                                    teamArr && invite ?
                                                                        <li><Link onClick={() => this.props.leaveTeamConfirm()}>Leave Team</Link></li>
                                                                        :
                                                                        <li><Link>Leave Team</Link></li>
                                                                }
                                                                <li><Link to={"/player-perks"}>Perks</Link></li>
                                                                <li><Link to={"/player-uploadcsv"}>Upload CSV</Link></li>
                                                            </ul>
                                                        </li>
                                                        {
                                                            localStorage.getItem("company") &&
                                                            <li>
                                                                <Link to={'/player-myevent'}>My Events</Link>
                                                            </li>
                                                        }
                                                        {/* <li>
                                                                <Link to={"/player-billing"}>Billing</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={"/player-subscription"}>Manage subscription</Link>
                                                            </li> */}
                                                        <li>
                                                            <Link onClick={() => this.props.supportZendesk()}>Support</Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => this.props.logout()}>Logout</Link>
                                                        </li>
                                                    </>
                                                }
                                                {
                                                    (userType === "Fan" || userType === "Parent") &&
                                                    <>
                                                        <li>
                                                            <Link onClick={() => this.props.handlePetaMenu('Profile')} className={`user-dropdown ${petaMenu === 'Profile' ? 'active' : ''}`}>Edit Profile</Link>
                                                            <ul className={`user-submenu ${petaMenu === 'Profile' ? 'active' : ''}`}>
                                                                <li><Link onClick={() => this.props.changePassFlg()}>Change Password</Link></li>
                                                                <li><Link onClick={() => this.props.nextPage('profile')}>Edit Profile</Link></li>
                                                                <li><Link to={"/fan-perks"}>Perks</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => this.props.supportZendesk()}>Support</Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => this.props.logout()}>Logout</Link>
                                                        </li>
                                                    </>
                                                }
                                                 {
                                                    userType === "BC Commissioner" &&
                                                    <>
                                                        <li>
                                                            <ul className={`user-submenu ${petaMenu === 'Profile' ? 'active' : ''}`}>
                                                                <li><Link onClick={() => this.props.changePassFlg()}>Change Password</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => this.props.supportZendesk()}>Support</Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => this.props.logout()}>Logout</Link>
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {localStorage.getItem("commiToken") === null ? <img className="header-bar" src={imagesArr.bar} /> : ""}
                                </>
                                :
                                <>
                                    <Link to={'/signup'} className="btn trans">Sign Up</Link>
                                    <Link to={'/login'} className="btn">Sign In</Link>
                                </>
                        }
                    </div>
                    
                    {localStorage.getItem("commiToken") === null ? this.props.renderAllMenu() : ""}
                    <button className="navbar-toggler" onClick={this.props.toggleMenuBar.bind(this, 'bc')}><span className="navbar-toggler-icon"></span></button>
                    </div>
                </div>
            </nav>
        )
    }
}

export default withRouter(bcHeader);

export const GET_TRAINING_REQUEST = 'GET_TRAINING_REQUEST';
export const GET_TRAINING_SUCCESS = 'GET_TRAINING_SUCCESS';
export const GET_TRAINING_ERROR = 'GET_TRAINING_ERROR';

export const SAVE_TRAINING_REQUEST = 'SAVE_TRAINING_REQUEST';
export const SAVE_TRAINING_SUCCESS = 'SAVE_TRAINING_SUCCESS';
export const SAVE_TRAINING_ERROR = 'SAVE_TRAINING_ERROR';






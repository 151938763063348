import axios from 'axios';
import { API_Url, Auth_Coach_Key, Auth_Player_Key, Authorization, checkIsValidCoachToken, checkIsValidPlayerToken } from '../../helpers/authToken';
import {
    GET_ANALYZR_PACKAGE_REQUEST,
    GET_ANALYZR_PACKAGE_SUCCESS,
    GET_ANALYZR_PACKAGE_ERROR,

    BUY_ANALYZR_PACKAGE_REQUEST,
    BUY_ANALYZR_PACKAGE_SUCCESS,
    BUY_ANALYZR_PACKAGE_ERROR,

} from '../../constants/landing/analyzr.const';
import history from '../../../History';

export const getPackage = () => dispatch => {
    dispatch({ type: GET_ANALYZR_PACKAGE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/setting/list/analyzr/package/web`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(pack => {
        dispatch({
            type: GET_ANALYZR_PACKAGE_SUCCESS,
            payload: pack.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_ANALYZR_PACKAGE_ERROR, error: error.response.data.message })
        }
    });
};

export const buyPackage = (data) => dispatch => {
    localStorage.getItem('coachToken') ? checkIsValidCoachToken(history) : checkIsValidPlayerToken(history);
    dispatch({ type: BUY_ANALYZR_PACKAGE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/analyzr/new`,
        data: data,
        headers: {
            'x-auth-token': localStorage.getItem('coachToken') ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(pack => {
        dispatch({
            type: BUY_ANALYZR_PACKAGE_SUCCESS,
            payload: pack.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: BUY_ANALYZR_PACKAGE_ERROR, error: error.response.data.message })
        }
    });
};


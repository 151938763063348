export const GET_TEAM_RANKING_SUCCESS = "GET_TEAM_RANKING_SUCCESS";
export const GET_TEAM_RANKING_REQUEST = "GET_TEAM_RANKING_REQUEST";
export const GET_TEAM_RANKING_ERROR = "GET_TEAM_RANKING_ERROR";

export const GET_ALL_TEAM_LIST_SUCCESS = "GET_ALL_TEAM_LIST_SUCCESS";
export const GET_ALL_TEAM_LIST_REQUEST = "GET_ALL_TEAM_LIST_REQUEST";
export const GET_ALL_TEAM_LIST_ERROR = "GET_ALL_TEAM_LIST_ERROR";

export const GET_ALL_RANKING_TEAM_LIST_SUCCESS = "GET_ALL_RANKING_TEAM_LIST_SUCCESS";
export const GET_ALL_RANKING_TEAM_LIST_REQUEST = "GET_ALL_RANKING_TEAM_LIST_REQUEST";
export const GET_ALL_RANKING_TEAM_LIST_ERROR = "GET_ALL_RANKING_TEAM_LIST_ERROR";
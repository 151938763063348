import {
  ZIPCODE_ERROR,
  ZIPCODE_SUCCESS,
  FILEUPLOAD_ERROR,
  FILEUPLOAD_SUCCESS,
  COVER_FILEUPLOAD_SUCCESS,
  COVER_FILEUPLOAD_ERROR,

  SAVE_COVER_PHOTO_REQUEST,
  SAVE_COVER_PHOTO_SUCCESS,
  SAVE_COVER_PHOTO_ERROR,

  GET_COUNTRYCODE_ERROR,
  GET_COUNTRYCODE_SUCCESS,

  INSTA_REQUEST,
  INSTA_SUCCESS,
  INSTA_ERROR,

  GET_PRODUCT_LIST_ERROR,
  GET_PRODUCT_LIST_SUCCESS,

  ACCEPT_REQUEST,
  ACCEPT_SUCCESS,
  ACCEPT_ERROR,

  NOTIFY_DECLINE_REQUEST,
  NOTIFY_DECLINE_SUCCESS,
  NOTIFY_DECLINE_ERROR,

  ACCEPT_REQ_PLAYER_REQUEST,
  ACCEPT_REQ_PLAYER_SUCCESS,
  ACCEPT_REQ_PLAYER_ERROR,

  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_ERROR,

  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,

  NOTIFY_COUNT_REQUEST,
  NOTIFY_COUNT_SUCCESS,
  NOTIFY_COUNT_ERROR,

  NOTIFY_ACCEPT_REQUEST,
  NOTIFY_ACCEPT_SUCCESS,
  NOTIFY_ACCEPT_ERROR,

  READ_NOTIFY_REQUEST,
  READ_NOTIFY_SUCCESS,
  READ_NOTIFY_ERROR,

  DELETE_NOTIFY_REQUEST,
  DELETE_NOTIFY_SUCCESS,
  DELETE_NOTIFY_ERROR,

  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
  GET_ADS_ERROR,

  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,

  GET_PW_COMPANY_REQUEST,
  GET_PW_COMPANY_SUCCESS,
  GET_PW_COMPANY_ERROR,

  COM_EVENT_DASH_REQUEST,
  COM_EVENT_DASH_SUCCESS,
  COM_EVENT_DASH_ERROR,

  NOTIFY_ACCEPT_BY_HEADCOACH_SUCCESS,
  NOTIFY_ACCEPT_BY_HEADCOACH_REQUEST,
  NOTIFY_ACCEPT_BY_HEADCOACH_ERROR,

  FETCH_SPOTLIGHT_REQUEST,
  FETCH_SPOTLIGHT_SUCCESS,
  FETCH_SPOTLIGHT_ERROR,

  FETCH_SPONSORS_REQUEST,
  FETCH_SPONSORS_SUCCESS,
  FETCH_SPONSORS_ERROR,

  NOTIFY_ACCEPT_LINKED_PLAYER_REQUEST,
  NOTIFY_ACCEPT_LINKED_PLAYER_SUCCESS,
  NOTIFY_ACCEPT_LINKED_PLAYER_ERROR,

  NOTIFY_ACCEPT_FAN_REQUEST,
  NOTIFY_ACCEPT_FAN_SUCCESS,
  NOTIFY_ACCEPT_FAN_ERROR,

  GET_MYTEAM_LIST_REQUEST,
  GET_MYTEAM_LIST_SUCCESS,
  GET_MYTEAM_LIST_ERROR,

  CHANGE_MYTEAM_LIST_REQUEST,
  CHANGE_MYTEAM_LIST_SUCCESS,
  CHANGE_MYTEAM_LIST_ERROR,

  GET_VIDEO_DETAILS_REQUEST,
  GET_VIDEO_DETAILS_SUCCESS,
  GET_VIDEO_DETAILS_ERROR,

  GET_PERKS_INFO_REQUEST,
  GET_PERKS_INFO_SUCCESS,
  GET_PERKS_INFO_ERROR,

  GET_PW_PACK_REQUEST,
  GET_PW_PACK_SUCCESS,
  GET_PW_PACK_ERROR,

  LEAVE_EVENT_REQUEST,
  LEAVE_EVENT_SUCCESS,
  LEAVE_EVENT_ERROR,

  PENDING_PAYMENT_DASH_SUCCESS,
  PENDING_PAYMENT_DASH_REQUEST,
  PENDING_PAYMENT_DASH_ERROR,

  PENDING_COUPEN_CODE_SUCCESS,
  PENDING_COUPEN_CODE_ERROR,
  PENDING_COUPEN_CODE_REQUEST,

  APPLE_TEST_SUCCESS,
  APPLE_TEST_ERROR,
  APPLE_TEST_REQUEST,
} from "../../constants/common/api.const";

const initialState = {
  zipcodeData: null,
  zipcodeError: null,
  fileUploadData: null,
  fileUploadError: null,
  coverUploadData: null,
  coverUploadError: null,

  saveCoverRes: false,
  saveCoverData: null,
  saveCoverDataError: null,

  countryCodeData: [],
  countryCodeError: null,

  instagramReq: false,
  instagramData: null,
  instagramError: null,

  productList: [],
  productError: null,

  acceptReq: false,
  acceptData: null,
  acceptError: null,

  acceptPlayerReq: false,
  acceptPlayerData: null,
  acceptPlayerError: null,

  contactReq: false,
  contactData: null,
  contactError: null,

  notifyReq: false,
  notifyData: null,
  notifyError: null,

  notifyCountReq: false,
  notifyCountData: null,
  notifyCountError: null,

  notifyAcceptReq: false,
  notifyAcceptData: null,
  notifyAcceptError: null,

  notifyDeclineReq: false,
  notifyDeclineData: null,
  notifyDeclineError: null,

  notifyAcceptByCoachReq: false,
  notifyAcceptByCoachData: null,
  notifyAcceptByCoachError: null,

  readNotifyReq: false,
  readNotifyData: null,
  readNotifyError: null,

  deleteNotifyReq: false,
  deleteNotifyData: null,
  deleteNotifyError: null,

  getAdsReq: false,
  getAdsData: null,
  getAdsError: null,

  getEventDashReq: false,
  getEventDashData: null,
  getEventDashError: null,

  getCompanyInfoReq: false,
  getCompanyInfoData: null,
  getChange: null,
  getCompanyInfoError: null,

  getCompanyPWInfoReq: false,
  getCompanyPWInfoData: null,
  getCompanyPWInfoError: null,

  getCompanyDCInfoReq: false,
  getCompanyDCInfoData: null,
  getCompanyDCInfoError: null,

  spotlightReq: false,
  spotlightData: null,
  spotlightError: null,

  sponsorsReq: false,
  sponsorsData: null,
  sponsorsError: null,

  notifyAcceptLinkedPlayerReq: false,
  notifyAcceptLinkedPlayerData: null,
  notifyAcceptLinkedPlayerError: null,

  notifyAcceptFanReq: false,
  notifyAcceptFanData: null,
  notifyAcceptFanError: null,

  getMyTeamListReq: false,
  getMyTeamListData: null,
  getMyTeamListError: null,

  changeMyTeamListReq: false,
  changeMyTeamListData: null,
  changeMyTeamListError: null,

  getVideoDataReq: false,
  getVideoDataData: null,
  getVideoDataError: null,

  getPerksInfoReq: false,
  getPerksInfoData: null,
  getPerksInfoError: null,

  getPWPackReq: false,
  getPWPackData: null,
  getPWPackError: null,

  getLeaveEventReq: false,
  getLeaveEventData: null,
  getLeaveEventError: null,

  getLeaderboardStatesReq: false,
  getLeaderboardStatesData: null,
  getLeaderboardStatesError: null,

  getPlayerRankingStatesReq: false,
  getPlayerRankingStatesData: null,
  getPlayerRankingStatesError: null,

  getTeamRankingStatesReq: false,
  getTeamRankingStatesData: null,
  getTeamRankingStatesError: null,

  pendingPaymentDashReq: false,
  pendingPaymentDashData: null,
  pendingPaymentDashError: null,

  pendingCoupenCodeReq: false,
  pendingCoupenCodeData: null,
  pendingCoupenCodeError: null,

  appleTestReq: false,
  appleTestData: null,
  appleTestError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // get apple testing
    case APPLE_TEST_REQUEST:
      return {
        ...state,
        appleTestReq: true,
      };
    case APPLE_TEST_SUCCESS:
      return {
        ...state,
        appleTestData: action.payload,
      };
    case APPLE_TEST_ERROR:
      return {
        ...state,
        appleTestError: action.error,
      };

    // get all team ranking list event
    case `GET_TEAM_RANKING_STATES_DETAILS_REQUEST`:
      return {
        ...state,
        getTeamRankingStatesReq: true,
      };
    case `GET_TEAM_RANKING_STATES_DETAILS_SUCCESS`:
      return {
        ...state,
        getTeamRankingStatesData: action.payload,
      };
    case `GET_TEAM_RANKING_STATES_DETAILS_ERROR`:
      return {
        ...state,
        getTeamRankingStatesError: action.error,
      };

    // get all player ranking list event
    case `GET_PLAYER_RANKING_STATES_DETAILS_REQUEST`:
      return {
        ...state,
        getPlayerRankingStatesReq: true,
      };
    case `GET_PLAYER_RANKING_STATES_DETAILS_SUCCESS`:
      return {
        ...state,
        getPlayerRankingStatesData: action.payload,
      };
    case `GET_PLAYER_RANKING_STATES_DETAILS_ERROR`:
      return {
        ...state,
        getPlayerRankingStatesError: action.error,
      };

    // get all states list event
    case `GET_LEADERBOARD_STATES_DETAILS_REQUEST`:
      return {
        ...state,
        getLeaderboardStatesReq: true,
      };
    case `GET_LEADERBOARD_STATES_DETAILS_SUCCESS`:
      return {
        ...state,
        getLeaderboardStatesData: action.payload,
      };
    case `GET_LEADERBOARD_STATES_DETAILS_ERROR`:
      return {
        ...state,
        getLeaderboardStatesError: action.error,
      };

    // get leave event
    case LEAVE_EVENT_REQUEST:
      return {
        ...state,
        getLeaveEventReq: true,
      };
    case LEAVE_EVENT_SUCCESS:
      return {
        ...state,
        getLeaveEventData: action.payload,
      };
    case LEAVE_EVENT_ERROR:
      return {
        ...state,
        getLeaveEventError: action.error,
      };

    // get pw+ pachakge
    case GET_PW_PACK_REQUEST:
      return {
        ...state,
        getPWPackReq: true,
      };
    case GET_PW_PACK_SUCCESS:
      return {
        ...state,
        getPWPackData: action.payload,
      };
    case GET_PW_PACK_ERROR:
      return {
        ...state,
        getPWPackError: action.error,
      };

    // Perks req    
    case GET_PERKS_INFO_REQUEST:
      return {
        ...state,
        getPerksInfoReq: true,
      };
    case GET_PERKS_INFO_SUCCESS:
      return {
        ...state,
        getPerksInfoData: action.payload,
      };
    case GET_PERKS_INFO_ERROR:
      return {
        ...state,
        getPerksInfoError: action.error,
      };

    case GET_VIDEO_DETAILS_REQUEST:
      return {
        ...state,
        getVideoDataReq: true,
      };
    case GET_VIDEO_DETAILS_SUCCESS:
      return {
        ...state,
        getVideoDataData: action.payload,
      };
    case GET_VIDEO_DETAILS_ERROR:
      return {
        ...state,
        getVideoDataError: action.error,
      };

    case GET_MYTEAM_LIST_REQUEST:
      return {
        ...state,
        getMyTeamListReq: true,
      };
    case GET_MYTEAM_LIST_SUCCESS:
      return {
        ...state,
        getMyTeamListData: action.payload,
      };
    case GET_MYTEAM_LIST_ERROR:
      return {
        ...state,
        getMyTeamListError: action.error,
      };

    case CHANGE_MYTEAM_LIST_REQUEST:
      return {
        ...state,
        changeMyTeamListReq: true,
      };
    case CHANGE_MYTEAM_LIST_SUCCESS:
      return {
        ...state,
        changeMyTeamListData: action.payload,
      };
    case CHANGE_MYTEAM_LIST_ERROR:
      return {
        ...state,
        changeMyTeamListError: action.error,
      };

    case ZIPCODE_SUCCESS:
      return {
        ...state,
        zipcodeData: action.payload,
      };
    case ZIPCODE_ERROR:
      return {
        ...state,
        zipcodeError: action.error,
      };
    case FILEUPLOAD_SUCCESS:
      return {
        ...state,
        fileUploadData: action.payload,
      };
    case FILEUPLOAD_ERROR:
      return {
        ...state,
        fileUploadError: action.error,
      };
    case COVER_FILEUPLOAD_SUCCESS:
      return {
        ...state,
        coverUploadData: action.payload,
      };
    case COVER_FILEUPLOAD_ERROR:
      return {
        ...state,
        coverUploadError: action.error,
      };

    // Save cover photo
    case SAVE_COVER_PHOTO_REQUEST:
      return {
        ...state,
        saveCoverRes: true
      };
    case SAVE_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        saveCoverData: action.payload,
      };
    case SAVE_COVER_PHOTO_ERROR:
      return {
        ...state,
        saveCoverDataError: action.error,
      };

    case GET_COUNTRYCODE_SUCCESS:
      return {
        ...state,
        countryCodeData: action.payload
      }
    case GET_COUNTRYCODE_ERROR:
      return {
        ...state,
        countryCodeError: action.error
      }


    // Instagram req    
    case INSTA_REQUEST:
      return {
        ...state,
        instagramReq: true,
      };
    case INSTA_SUCCESS:
      return {
        ...state,
        instagramData: action.payload,
      };
    case INSTA_ERROR:
      return {
        ...state,
        instagramError: action.error,
      };

    //product list 
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
      };
    case GET_PRODUCT_LIST_ERROR:
      return {
        ...state,
        productError: action.error,
      };
    // Accept req    
    case ACCEPT_REQUEST:
      return {
        ...state,
        acceptReq: true,
      };
    case ACCEPT_SUCCESS:
      return {
        ...state,
        acceptData: action.payload,
      };
    case ACCEPT_ERROR:
      return {
        ...state,
        acceptError: action.error,
      };

    // Accept Player req    
    case ACCEPT_REQ_PLAYER_REQUEST:
      return {
        ...state,
        acceptPlayerReq: true,
      };
    case ACCEPT_REQ_PLAYER_SUCCESS:
      return {
        ...state,
        acceptPlayerData: action.payload,
      };
    case ACCEPT_REQ_PLAYER_ERROR:
      return {
        ...state,
        acceptPlayerError: action.error,
      };

    // Contact req    
    case CONTACT_REQUEST:
      return {
        ...state,
        contactReq: true,
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        contactData: action.payload,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        contactError: action.error,
      };

    // Notification req    
    case NOTIFICATION_REQUEST:
      return {
        ...state,
        notifyReq: true,
      };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifyData: action.payload,
      };
    case NOTIFICATION_ERROR:
      return {
        ...state,
        notifyError: action.error,
      };

    // Notification Count req    
    case NOTIFY_COUNT_REQUEST:
      return {
        ...state,
        notifyCountReq: true,
      };
    case NOTIFY_COUNT_SUCCESS:
      return {
        ...state,
        notifyCountData: action.payload,
      };
    case NOTIFY_COUNT_ERROR:
      return {
        ...state,
        notifyCountError: action.error,
      };

    // Notification Accept req    
    case NOTIFY_ACCEPT_REQUEST:
      return {
        ...state,
        notifyAcceptReq: true,
      };
    case NOTIFY_ACCEPT_SUCCESS:
      return {
        ...state,
        notifyAcceptData: action.payload,
      };
    case NOTIFY_ACCEPT_ERROR:
      return {
        ...state,
        notifyAcceptError: action.error,
      };

    // Notification Decline req    
    case NOTIFY_DECLINE_REQUEST:
      return {
        ...state,
        notifyDeclineReq: true,
      };
    case NOTIFY_DECLINE_SUCCESS:
      return {
        ...state,
        notifyDeclineData: action.payload,
      };
    case NOTIFY_DECLINE_ERROR:
      return {
        ...state,
        notifyDeclineError: action.error,
      };

    // Notification Accept by Head Coach req
    case NOTIFY_ACCEPT_BY_HEADCOACH_REQUEST:
      return {
        ...state,
        notifyAcceptByCoachReq: true,
      };
    case NOTIFY_ACCEPT_BY_HEADCOACH_SUCCESS:
      return {
        ...state,
        notifyAcceptByCoachData: action.payload,
      };
    case NOTIFY_ACCEPT_BY_HEADCOACH_ERROR:
      return {
        ...state,
        notifyAcceptByCoachError: action.error,
      };

    // Notification Accept linked player
    case NOTIFY_ACCEPT_LINKED_PLAYER_REQUEST:
      return {
        ...state,
        notifyAcceptLinkedPlayerReq: true,
      };
    case NOTIFY_ACCEPT_LINKED_PLAYER_SUCCESS:
      return {
        ...state,
        notifyAcceptLinkedPlayerData: action.payload,
      };
    case NOTIFY_ACCEPT_LINKED_PLAYER_ERROR:
      return {
        ...state,
        notifyAcceptLinkedPlayerError: action.error,
      };


    // Notification Accept by fan
    case NOTIFY_ACCEPT_FAN_REQUEST:
      return {
        ...state,
        notifyAcceptFanReq: true,
      };
    case NOTIFY_ACCEPT_FAN_SUCCESS:
      return {
        ...state,
        notifyAcceptFanData: action.payload,
      };
    case NOTIFY_ACCEPT_FAN_ERROR:
      return {
        ...state,
        notifyAcceptFanError: action.error,
      };

    // Notification Read req    
    case READ_NOTIFY_REQUEST:
      return {
        ...state,
        readNotifyReq: true,
      };
    case READ_NOTIFY_SUCCESS:
      return {
        ...state,
        readNotifyData: action.payload,
      };
    case READ_NOTIFY_ERROR:
      return {
        ...state,
        readNotifyError: action.error,
      };

    // Notification Delete req    
    case DELETE_NOTIFY_REQUEST:
      return {
        ...state,
        deleteNotifyReq: true,
      };
    case DELETE_NOTIFY_SUCCESS:
      return {
        ...state,
        deleteNotifyData: action.payload,
      };
    case DELETE_NOTIFY_ERROR:
      return {
        ...state,
        deleteNotifyError: action.error,
      };

    // Adds req    
    case GET_ADS_REQUEST:
      return {
        ...state,
        getAdsReq: true,
      };
    case GET_ADS_SUCCESS:
      return {
        ...state,
        getAdsData: action.payload,
      };
    case GET_ADS_ERROR:
      return {
        ...state,
        getAdsError: action.error,
      };

    // get company name req    
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        getCompanyInfoReq: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        getCompanyInfoData: action.payload,
        getChange: action.payload
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        getCompanyInfoError: action.error,
      };

    // get company pw name req    
    case 'GET_PW_COMPANY_REQUEST':
      return {
        ...state,
        getCompanyPWInfoReq: true,
      };
    case 'GET_PW_COMPANY_SUCCESS':
      return {
        ...state,
        getCompanyPWInfoData: action.payload,
      };
    case 'GET_PW_COMPANY_ERROR':
      return {
        ...state,
        getCompanyPWInfoError: action.error,
      };

    // get company dc name req    
    case 'GET_DC_COMPANY_REQUEST':
      return {
        ...state,
        getCompanyDCInfoReq: true,
      };
    case 'GET_DC_COMPANY_SUCCESS':
      return {
        ...state,
        getCompanyDCInfoData: action.payload,
      };
    case 'GET_DC_COMPANY_ERROR':
      return {
        ...state,
        getCompanyDCInfoError: action.error,
      };

    // get event dash req    
    case COM_EVENT_DASH_REQUEST:
      return {
        ...state,
        getEventDashReq: true,
      };
    case COM_EVENT_DASH_SUCCESS:
      return {
        ...state,
        getEventDashData: action.payload,
      };
    case COM_EVENT_DASH_ERROR:
      return {
        ...state,
        getEventDashError: action.error,
      };

    // fetch company spotlight    
    case FETCH_SPOTLIGHT_REQUEST:
      return {
        ...state,
        spotlightReq: true,
      };
    case FETCH_SPOTLIGHT_SUCCESS:
      return {
        ...state,
        spotlightData: action.payload,
      };
    case FETCH_SPOTLIGHT_ERROR:
      return {
        ...state,
        spotlightError: action.error,
      };

    // fetch company sponsors    
    case FETCH_SPONSORS_REQUEST:
      return {
        ...state,
        sponsorsReq: true,
      };
    case FETCH_SPONSORS_SUCCESS:
      return {
        ...state,
        sponsorsData: action.payload,
      };
    case FETCH_SPONSORS_ERROR:
      return {
        ...state,
        sponsorsError: action.error,
      };

    // for pay remaining payment on dashborad
    case PENDING_PAYMENT_DASH_REQUEST:
      return {
        ...state,
        pendingPaymentDashReq: true,
      };
    case PENDING_PAYMENT_DASH_SUCCESS:
      return {
        ...state,
        pendingPaymentDashData: action.payload,
      };
    case PENDING_PAYMENT_DASH_ERROR:
      return {
        ...state,
        pendingPaymentDashError: action.error,
      };

    // for apply counpen code on dashborad
    case PENDING_COUPEN_CODE_REQUEST:
      return {
        ...state,
        pendingCoupenCodeReq: true,
      };
    case PENDING_COUPEN_CODE_SUCCESS:
      return {
        ...state,
        pendingCoupenCodeData: action.payload,
      };
    case PENDING_COUPEN_CODE_ERROR:
      return {
        ...state,
        pendingCoupenCodeError: action.error,
      };

    default:
      return state;
  }
}
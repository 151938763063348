import React, { Component } from 'react';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import { imagesArr } from '../../../../assets/images';
class playerInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recapInfo: [],
        };
    }

    componentDidMount = () => {
        this.setState({
            recapInfo: JSON.parse(localStorage.getItem("recapInfo"))
        })
    }

    mentionText = (text) => {
        var pattern = /\B@[a-z0-9_-]+/gi;
        if (text) {
            let findMention = text.match(pattern);
            if (findMention) {
                let finalArr = [];

                let mention = text.split("@");
                mention.map((item, i) => {
                    let split = item.split(" ");
                    if (split[0].length !== 0) {
                        split.map((items, j) => {
                            if (j === 0) {
                                let textHtml = "<span>" + items + "</span> ";
                                finalArr.push(textHtml);
                            } else {
                                let textHtml = items + " ";
                                finalArr.push(textHtml);
                            }
                        })
                    }
                })
                let joinText = finalArr.join('')
                return joinText;
            } else {
                return text;
            }
        }
    }

    render() {
        const { recapInfo } = this.state;
        return (
            <>
                <Header />
                <div className="main-ranking mt-40">
                    <div className="container">
                        <div className="playerinfo-section mb-70">
                            <h2>{recapInfo.title}</h2>
                            <div className="user-img">
                                <img src={recapInfo?.media?.filePath ? recapInfo.media.filePath : imagesArr.default_user} alt="team logo" />
                            </div>
                            <div className="player-detail">
                                {
                                    recapInfo.recapText &&
                                        recapInfo.recapText.length !== 0 ?
                                        <p className="text" dangerouslySetInnerHTML={{
                                            __html: recapInfo.recapText
                                        }}></p>
                                        :
                                        <p className="text-center">No data found</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default playerInfo;
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Preloader from '../../../components/preloader';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import ReactPaginate from "react-paginate";
import AddCoach from "../../popup/addCoach";
import HeadCoach from "../../popup/headCoach";
import Confirmation from "../../popup/confirmation";
import { getRosterCoach, deleteRosterCoach, assignHeadCoach, deleteRosterHeadCoach } from '../../../saga/actions/views/coach/profile/coachInfo.action';

export class CoachInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      coachList: [],
      addCoach: false,
      addType: "",
      sendData: "",
      index: "",
      did: "",
      deletePopup: false,
      filter: {
        search: "",
        page: 1,
        limit: 10
      },
      searchText: "",
      recIndex: "",
      coachId: "",
      coachConfirmation: false,
      coachName: "",

      // pagination variable diclaration
      offset: 0,
      perPage: 10,
      currentPage: "",
      index: "",

      disCoach: false,
      disData: [],
      coachType: 1,
      delCoachPopup: false
    };

    // pagination handler function
    this.handlePageClick = this.handlePageClick.bind(this);

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.setLoader(true);
    this.props.getRosterCoach(this.state.filter);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coachDetails &&
      nextProps.coachDetails.getCoachReq &&
      nextProps.coachDetails.getCoachData
    ) {
      console.log("nextProps.getCoachData:-----", nextProps.coachDetails.getCoachData);
      nextProps.coachDetails.getCoachReq = false;
      if (nextProps.coachDetails.getCoachData.flag) {
        this.setState({
          pageCount: Math.ceil(nextProps.coachDetails.getCoachData.result.total / this.state.perPage),
          coachList: nextProps.coachDetails.getCoachData.result.docs,
        }, () => {
          nextProps.coachDetails.getCoachData = null;
          this.setLoader(false);
        })
      } else {
        // this.commonCall.errorMessage(nextProps.coachDetails.getCoachData.message);
        nextProps.coachDetails.getCoachData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.coachDetails &&
      nextProps.coachDetails.delNewCoachReq &&
      nextProps.coachDetails.delNewCoachData
    ) {
      console.log("nextProps.delNewCoachData:-----", nextProps.coachDetails.delNewCoachData);
      nextProps.coachDetails.delNewCoachReq = false;
      if (nextProps.coachDetails.delNewCoachData.flag) {
        this.commonCall.successMessage(nextProps.coachDetails.delNewCoachData.message);
        nextProps.coachDetails.delNewCoachData = null;
        this.setState({
          did: "",
          deletePopup: false
        }, () => {
          document.body.classList.remove("modal-open");
          this.props.getRosterCoach(this.state.filter);
        })
      } else {
        // this.commonCall.errorMessage(nextProps.coachDetails.delNewCoachData.message);
        nextProps.coachDetails.delNewCoachData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.coachDetails &&
      nextProps.coachDetails.assignHeadCoachReq &&
      nextProps.coachDetails.assignHeadCoachData
    ) {
      console.log("nextProps.assignHeadCoachData:-----", nextProps.coachDetails.assignHeadCoachData);
      nextProps.coachDetails.assignHeadCoachReq = false;
      if (nextProps.coachDetails.assignHeadCoachData.flag) {
        // this.commonCall.successMessage(nextProps.coachDetails.assignHeadCoachData.message);
        nextProps.coachDetails.assignHeadCoachData = null;
        this.setState({
          recIndex: "",
          coachId: "",
          coachConfirmation: false
        }, () => {
          document.body.classList.remove("modal-open");
          this.props.getRosterCoach(this.state.filter);
        })
      } else {
        // this.commonCall.errorMessage(nextProps.coachDetails.assignHeadCoachData.message);
        nextProps.coachDetails.assignHeadCoachData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.coachDetails &&
      nextProps.coachDetails.rosterDelCoachReq &&
      nextProps.coachDetails.rosterDelCoachData
    ) {
      console.log("nextProps.rosterDelCoachData:-----", nextProps.coachDetails.rosterDelCoachData);
      nextProps.coachDetails.rosterDelCoachReq = false;
      if (nextProps.coachDetails.rosterDelCoachData.flag) {
        this.commonCall.successMessage(nextProps.coachDetails.rosterDelCoachData.message);
        nextProps.coachDetails.rosterDelCoachData = null;
        this.closeCoachPopup();
      } else {
        this.commonCall.errorMessage(nextProps.coachDetails.rosterDelCoachData.message);
        nextProps.coachDetails.rosterDelCoachData = null;
        this.setLoader(false);
      }
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  chkHandleChange = (e, i, list) => {
    if (!JSON.parse(e.target.value)) {
      this.setState({
        recIndex: i,
        coachId: list._id,
        coachConfirmation: true,
        coachName: list.fname + " " + list.lname
      })
    } else {
      this.state.coachList.forEach((item, j) => {
        if (i === j) {
          Object.assign(item, { isheadCoach: !item.isheadCoach });
        }
      });
      this.setState({
        coachList: this.state.coachList
      }, () => {
        this.setLoader(true);
        this.props.assignHeadCoach({ coachId: list._id, flag: false })
      })
    }
  }

  disHeadCoach = (data, coachType) => {
    this.setState({
      disCoach: true,
      disData: data,
      coachType: coachType
    })
  }

  delHeadCoach = (data, coachType, delId) => {
    this.setState({
      delCoachPopup: true,
      disData: data._id,
      coachType: coachType,
      did: delId._id
    })
  }

  renderCoachList = () => {
    return (
      this.state.coachList.length !== 0 ?
        this.state.coachList.map((list, i) => {
          return (
            <>
              <tr>
                <td>{list.fname + " " + list.lname}</td>
                <td>{list.email}</td>
                <td>{list.phone ? list.phone : "-"}</td>
                {(this.props.coachLevel !== 2 && this.props.coachLevel !== 3) && <td>{list.isAccept ? "Yes" : "No"}</td>}
                <td className="common_btn toggle-btn">
                  {
                    list.isAccept ?
                      <label class="switch switch-default switch-pill switch-primary">
                        <input type="checkbox" value={list.isheadCoach} checked={list.isheadCoach} className="switch-input" onChange={(e) => this.chkHandleChange(e, i, list)} />
                        <span class="switch-label"></span>
                        <span class="switch-handle"></span>
                      </label>
                      :
                      "-"
                  }
                </td>
                {/* <td>{list.rosters.length ? list.rosters.map(e=>e.rosterName).join(",") : "-"}</td> */}
                {(this.props.coachLevel !== 2 && this.props.coachLevel !== 3) &&
                  <><td>
                    <div className="head_coach">
                      {list.headCoach.length ?
                        list.headCoach
                          .map((e) => (
                            <span>
                              {e.rosterName}
                              <i class="fa fa-times cross-btn" onClick={() => this.delHeadCoach(list, 1, e)}></i>
                            </span>
                          ))
                        : ""}
                      {
                        list.isAccept &&
                        <Link className="edit_icon" onClick={() => this.disHeadCoach(list, 1)}><i class="fas fa-pencil-alt"></i>Edit</Link>
                      }
                    </div>
                  </td>
                    <td>
                      <div className="head_coach">
                        {list.coachArr.length ?
                          list.coachArr
                            .map((e) => (
                              <span>
                                {e.rosterName}
                                <i class="fa fa-times cross-btn" onClick={() => this.delHeadCoach(list, 2, e)}></i>
                              </span>
                            ))
                          : ""}
                        {
                          list.isAccept &&
                          <Link className="edit_icon" onClick={() => this.disHeadCoach(list, 2)}><i class="fas fa-pencil-alt"></i>Edit</Link>
                        }
                      </div>
                    </td>
                  </>}
                <td className="common_btn">
                  <Link className="blue_btn w-100" onClick={() => this.addNewCoach('edit', list, i)}>Edit</Link>
                </td>
                <td className="common_btn">
                  <Link className="red_btn w-100" onClick={() => this.delCoach(list)}> Delete</Link>
                </td>
              </tr>
            </>
          );
        }) :
        <tr>
          <td colSpan="6" className="text-center">No Data Found</td>
        </tr>
    )
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [name]: value
      },
    }));
  };

  resetSearch = () => {
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        search: "",
        page: 1,
        limit: 10
      },
    }), () => {
      this.setLoader(true);
      this.props.getRosterCoach(this.state.filter);
    });
  }

  handlePageClick = (e) => {
    console.log("e:----------------------------", e);

    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.setState((prevState) => ({
          ...prevState,
          filter: {
            search: "",
            page: selectedPage + 1,
            limit: this.state.perPage
          },
        }), () => {
          this.setLoader(true);
          this.props.getRosterCoach(this.state.filter);
        });
      }
    );
  };

  addNewCoach = (type, data, i) => {
    this.setState({
      addCoach: true,
      addType: type,
      sendData: type === "edit" ? data : "",
      index: i
    })
  }

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      addCoach: false,
      addType: ""
    }, () => {
      this.setLoader(true);
      this.props.getRosterCoach(this.state.filter);
    })
  }

  delCoach = (data) => {
    console.log("data:--", data);
    this.setState({
      did: data._id,
      deletePopup: true
    })
  }

  closePopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      did: "",
      deletePopup: false
    }, () => {
      this.setLoader(true);
      this.props.getRosterCoach(this.state.filter);
    })
  }

  confirmPopup = () => {
    // console.log(":this.state.did:---", this.state.did);
    this.setLoader(true);
    this.props.deleteRosterCoach({ _id: this.state.did })
  }

  closeCoachPopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      delCoachPopup: false,
      disData: [],
      coachType: 1,
      did: ""
    }, () => {
      this.setLoader(true);
      this.props.getRosterCoach(this.state.filter);
    })
  }

  confirmCoachPopup = () => {
    this.setLoader(true);
    this.props.deleteRosterHeadCoach({
      _id: this.state.disData,
      coachType: this.state.coachType,
      rosterTeamId: this.state.did
    })
  }

  onSearch = () => {
    console.log("search:---", this.state.filter);
    this.setLoader(true);
    this.props.getRosterCoach(this.state.filter);
  }

  closeCoach = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      recIndex: "",
      coachId: "",
      coachConfirmation: false
    })
  }

  hanldeCoach = () => {
    this.state.coachList.forEach((item, j) => {
      if (this.state.recIndex === j) {
        Object.assign(item, { isheadCoach: !item.isheadCoach });
      }
    });
    this.setState({
      coachList: this.state.coachList
    }, () => {
      this.setLoader(true);
      this.props.assignHeadCoach({ coachId: this.state.coachId, flag: true })
    })
  }

  closeDisPopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      disCoach: false,
      disData: [],
      coachType: 1
    }, () => {
      this.setLoader(true);
      this.props.getRosterCoach(this.state.filter);
    })
  }

  render() {
    const { loader, filter, addCoach, addType, sendData, index, coachList, deletePopup, coachConfirmation, coachName, disCoach, disData, coachType, delCoachPopup } = this.state;
    return (
      <>
        <section className="before-dashboard">
          <div className="white-bg">
            <div className="facilitytab add_playerinfo">
              <div className="pleayer_info_features">
                <div className="serach_filter">
                  <div className="form-group mb-0">
                    <input className="form-control search-bar" type="text" name="search" value={filter.search} onChange={(e) => this.handleChange(e)} placeholder="Search" />
                  </div>
                  <Link className="btn" onClick={() => this.onSearch()}>Search</Link>
                  <Link className="red_btn" onClick={() => this.resetSearch()}>Reset</Link>
                </div>
                <div className="add_player">
                  <Link className="btn" onClick={() => this.addNewCoach('add')}>Add New Staff</Link>
                </div>
              </div>
              <div className="tabsfacility-view">
                <div className="manage_roster">
                  <div className="table-responsive-xl roster-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Coach Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          {(this.props.coachLevel !== 2 && this.props.coachLevel !== 3) && <th scope="col">Registerd with DC</th>}
                          <th scope="col">Admin</th>
                          {/* <th scope="col">Roster</th> */}
                          {(this.props.coachLevel !== 2 && this.props.coachLevel !== 3) &&
                            <><th scope="col" className="head_coach_title">Head Coach</th>
                              <th scope="col" className="head_coach_title">Coach</th></>}
                          <th colSpan="2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderCoachList()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {coachList.length !== 0 && (
                  <div className="pagination">
                    <ReactPaginate
                      previousLabel={<i class="fas fa-chevron-left"></i>}
                      nextLabel={<i class="fas fa-chevron-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {
          disCoach &&
          <HeadCoach
            flag={disCoach}
            close={this.closeDisPopup}
            disData={disData}
            coachType={coachType}
          />
        }
        {
          delCoachPopup &&
          <Confirmation
            flag={delCoachPopup}
            type="delCoachPopup"
            close={this.closeCoachPopup}
            ok={this.confirmCoachPopup}
          />
        }
        {
          addCoach &&
          <AddCoach
            flag={addCoach}
            close={this.handleClose}
            type={addType}
            editData={sendData}
            coachList={coachList}
            index={index}
            teamName={this.props.teamName}
          />
        }
        {
          deletePopup &&
          <Confirmation
            flag={deletePopup}
            type="subCoach"
            close={this.closePopup}
            ok={this.confirmPopup}
          />
        }
        {
          coachConfirmation &&
          <Confirmation
            flag={coachConfirmation}
            close={this.closeCoach}
            type="headCoach"
            ok={this.hanldeCoach}
            coachName={coachName}
          />
        }
        <Preloader flag={loader} />
      </>
    );
  }
}

CoachInfo.propTypes = {
  getRosterCoach: PropTypes.func.isRequired,
  deleteRosterCoach: PropTypes.func.isRequired,
  assignHeadCoach: PropTypes.func.isRequired,
  deleteRosterHeadCoach: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  coachDetails: state.coachInfo,
});

export default connect(mapStateToProps, { getRosterCoach, deleteRosterCoach, assignHeadCoach, deleteRosterHeadCoach })(CoachInfo);

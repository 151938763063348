import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import RankingHeroBanner from '../../../components/rankingHeroBanner';
import PlayerRanking from "./leaderboard/playerRanking";
import MainBoard from "./leaderboard/mainBoard";
import TeamRanking from "./leaderboard/teamRanking";
import Preloader from '../../../components/preloader';
import PurchaseProspectwire from './../../popup/purchaseProspectwire';
import { commonHeader } from "../../../businesslogic/content";
import { withRouter } from "react-router-dom";
import { getCompanyPWInfo, getAllStates } from '../../../saga/actions/common/api.action';
import { getUserCred } from '../../../saga/actions/authantication/login.action';
import queryString from "query-string";
import Cookies from 'js-cookie';
import commonCalls from "../../../businesslogic/commonCalls";

class pwRankingHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0,
            pathName: "",
            purchase: false,
            packType: "",
            firstLoad: false,
            stateList: []
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        let url = new URL(document.location);
        let getPath = url.pathname.split("/")[1];

        let peram = queryString.parse(this.props.location.search);
        if (peram.type && peram.key) {
            let item = commonHeader.events.filter((e) => e.key === peram.key)[0];
            this.increment();
            this.props.getCompanyPWInfo(item.label, 'PW');
        }
        this.setState({
            pathName: getPath
        }, () => {
            this.props.getAllStates('LEADERBOARD');
        })

    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonData &&
            nextProps.commonData.getCompanyPWInfoReq &&
            nextProps.commonData.getCompanyPWInfoData
        ) {
            console.log("nextProps.commonData.getCompanyPWInfoData:--", nextProps.commonData.getCompanyPWInfoData);
            nextProps.commonData.getCompanyPWInfoReq = false;
            if (nextProps.commonData.getCompanyPWInfoData.flag) {
                localStorage.setItem("cid", nextProps.commonData.getCompanyPWInfoData.result._id);
                let result = nextProps.commonData.getCompanyPWInfoData.result;

                this.props.mainBoardInfo.sendReq.companyId = result._id;
                this.props.mainBoardInfo.sendFieldReq.companyId = result._id;
                this.props.profileSummaryInfo.sendSummaryReq.companyId = result._id;

                let companyname = result.name === "GOBTournament" ? "Greater Orlando Baseball" : result.name
                let item = commonHeader.events.filter((e) => e.label === companyname)[0];
                localStorage.setItem("cid", result._id)
                localStorage.setItem("company", item.key)
                localStorage.setItem("companyColor", JSON.stringify(item.colors));
                this.setThemeColor(item.colors, flag => {
                    if (flag) {
                        let knowToken = queryString.parse(this.props.location.search)
                        if (knowToken.token) {
                            nextProps.commonData.getCompanyPWInfoData = null;
                            this.props.getUserCred({ token: knowToken.token }, 'PW');
                        } else {
                            this.setState({
                                purchase: true,
                                packType: 'PW',
                            }, () => {
                                this.decrement();
                                nextProps.commonData.getCompanyPWInfoData = null;
                            })
                        }
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.commonData.getCompanyPWInfoData.message);
                nextProps.commonData.getCompanyPWInfoData = null;
                this.decrement();
            }
        }

        if (nextProps.loginDetails &&
            nextProps.loginDetails.userCredReq &&
            nextProps.loginDetails.userCredData
        ) {
            console.log("nextProps.loginDetails.userCredData:---", nextProps.loginDetails.userCredData);
            nextProps.loginDetails.userCredReq = false;
            if (nextProps.loginDetails.userCredData.flag) {
                let result = nextProps.loginDetails.userCredData.result;
                let setToken = result.role === "Coach" ? "coachToken" :
                    result.role === "Player" ? "playerToken" :
                        (result.role === "Fan" || result.role === "Parent") ? "fanToken" : "authToken";

                if (setToken === "coachToken") {
                    localStorage.removeItem("playerToken");
                    localStorage.removeItem("fanToken");
                } else if (setToken === "playerToken") {
                    localStorage.removeItem("coachToken");
                    localStorage.removeItem("fanToken");
                } else {
                    localStorage.removeItem("coachToken");
                    localStorage.removeItem("playerToken");
                }
                let knowToken = queryString.parse(this.props.location.search)
                localStorage.setItem("webglToken", knowToken.token);
                Cookies.set('bcSession', knowToken.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
                this.commonCall.setAuthToken(setToken, knowToken.token, flag => {
                    if (flag) {
                        nextProps.loginDetails.userCredData = null;
                        this.props.history.push(`/pw-leaderboard${this.commonCall.removeURLParameter(this.props.location.search, 'token')}`);
                        window.location.reload();
                    }
                });
            } else {
                this.commonCall.errorMessage(nextProps.loginDetails.userCredData.message);
                nextProps.loginDetails.userCredData = null;
                this.decrement();
            }
        }

        if (nextProps.commonData &&
            nextProps.commonData.getLeaderboardStatesReq &&
            nextProps.commonData.getLeaderboardStatesData
        ) {
            console.log("nextProps.commonData.getLeaderboardStatesData:---", nextProps.commonData.getLeaderboardStatesData);
            nextProps.commonData.getLeaderboardStatesReq = false;
            if (nextProps.commonData.getLeaderboardStatesData.flag) {
                let result = nextProps.commonData.getLeaderboardStatesData.result
                this.setState({
                    stateList: result.data
                })
            } else {
                this.commonCall.errorMessage(nextProps.commonData.getLeaderboardStatesData.message);
                nextProps.commonData.getLeaderboardStatesData = null;
            }
        }
    }

    setThemeColor = (colArr, cb) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
        cb(true);
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    handlePurchaseClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    render() {
        const { loaderCount, pathName, purchase, packType, stateList } = this.state;
        return (
            <>
                <Header callIncrement={this.increment} />
                <div className="main-ranking mb-36">
                    {/* {
                        pathName !== "pw-leaderboard" &&
                        <RankingHeroBanner increment={this.increment} decrement={this.decrement} />
                    } */}
                    <h1 className={`section_title ${pathName === "pw-leaderboard" ? "mt-15" : ""}`}>PW Leaderboards</h1>
                    <div className="container">
                        <div className="main-leaderboards">
                            <PlayerRanking increment={this.increment} decrement={this.decrement} stateList={stateList} />
                            <MainBoard increment={this.increment} decrement={this.decrement} />
                            <TeamRanking increment={this.increment} decrement={this.decrement} stateList={stateList} />
                        </div>
                    </div>
                </div>

                <Footer />

                <Preloader flag={loaderCount > 0 ? true : false} />
                {
                    purchase &&
                    <PurchaseProspectwire
                        flag={purchase}
                        close={this.handlePurchaseClose}
                        type={packType}
                    />
                }
            </>
        )
    }
}

pwRankingHome.propTypes = {
    getCompanyPWInfo: PropTypes.func.isRequired,
    getUserCred: PropTypes.func.isRequired,
    getAllStates: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    commonData: state.api,
    mainBoardInfo: state.mainBoardInfo,
    profileSummaryInfo: state.profileSummaryInfo,
    loginDetails: state.loginData,
});

const pwRankingHomeComponent = connect(mapStateToProps, { getCompanyPWInfo, getUserCred, getAllStates })(pwRankingHome);
export default withRouter(pwRankingHomeComponent);
// export default pwRankingHome;
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import JoinLeague from './joinForm/joinLeague';
import Payment from './joinForm/payment';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import { imagesArr } from "../../../../assets/images";
import commonCalls from "../../../../businesslogic/commonCalls";
import { getRosterOption, fetchPaymentIntent, eventRegistrationPayment, rendLeagueCoupanCode } from '../../../../saga/actions/common/eventDetails.action';
import { getLeagueDetails } from '../../../../saga/actions/common/leagueDetails.action';
import { postFileUpload } from "../../../../saga/actions/common/api.action";
import Preloader from "../../../../components/preloader";
import CreditCard from "../payment/creditCard";
import LeagueRow from './details/leagueRow';

class leaguePayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            eventId: "",
            loader: false,
            eventDetailData: [],
            rosterList: [],
            eventJoin: {
                league: "",
                isManager: true,
                managerName: "",
                managerEmail: "",
                managerPhone: "",
                waiversImg: "",
                insuranceImg: "",
                paymentType: 0,
                couponCode: "",
                metaPayment: {},
                paymentMethod: "",
                amount: 0,
                question: ""
            },
            managePayMethod: false,
            insuranceName: "",
            isShowPayment: true,
            showPaymentButton: false,
            creditCard: false,
            disableStep: "",
            isPayLater: false,
        }
        this.joinEvent = null;
        this.eventPay = null;
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        this.setState({
            eventId: eventId,
            eventJoin: {
                ...this.state.eventJoin,
                league: eventId,
            }
        }, () => {
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueDetailsReq &&
            nextProps.leagueDetails.leagueDetailsData
        ) {
            nextProps.leagueDetails.leagueDetailsReq = false;
            console.log("nextProps.leagueDetails:--", nextProps.leagueDetails.leagueDetailsData);
            if (nextProps.leagueDetails.leagueDetailsData.flag) {
                let fdata = nextProps.leagueDetails.leagueDetailsData.result;
                this.setState({
                    eventDetailData: fdata,
                    eventJoin: {
                        ...this.state.eventJoin,
                        question: fdata.questions && fdata.questions,
                        isManager: fdata.settings.haveTeamManger,
                        amount: fdata.payment.feesLeague,
                        downpaidAmt: fdata.payment.downpaidAmt
                    },
                }, () => {
                    if (!this.state.eventDetailData.settings.haveTeamRoster &&
                        !this.state.eventDetailData.settings.haveWaiver &&
                        !this.state.eventDetailData.settings.isShowInsurance &&
                        this.state.eventDetailData.questions.length === 0) {
                        this.setState({
                            disableStep: 0,
                            tabs: 1,
                        })
                    }

                    if (fdata.payment.paymentType.length === 1 &&
                        fdata.payment.paymentType.indexOf(2) !== -1
                    ) {
                        this.setState({
                            isShowPayment: false,
                        });
                    }
                    nextProps.leagueDetails.leagueDetailsData = null;
                    if (this.state.eventDetailData.settings.haveTeamRoster) {
                        this.props.getRosterOption({ teamRoster: 1, league: this.state.eventId });
                    } else {
                        this.setLoader(false);
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueDetailsData.message);
                nextProps.leagueDetails.leagueDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getRosterOptReq &&
            nextProps.eventDetails.getRosterOptData
        ) {
            nextProps.eventDetails.getRosterOptReq = false;
            console.log("nextProps.eventDetails:--", nextProps.eventDetails.getRosterOptData);
            if (nextProps.eventDetails.getRosterOptData.flag) {
                this.setState({
                    rosterList: nextProps.eventDetails.getRosterOptData.result.data,
                }, () => {
                    nextProps.eventDetails.getRosterOptData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getRosterOptData.message);
                nextProps.eventDetails.getRosterOptData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getPaymentIntentReq &&
            nextProps.eventDetails.getPaymentIntentData
        ) {
            nextProps.eventDetails.getPaymentIntentReq = false;
            if (nextProps.eventDetails.getPaymentIntentData.flag) {
                localStorage.setItem(
                    "stripeIntent",
                    nextProps.eventDetails.getPaymentIntentData.result.data
                );
                this.setState({
                    eventJoin: {
                        ...this.state.eventJoin,
                        paymentMethod: this.state.eventJoin.paymentMethod === 1 ? "" : 1,
                    },
                    creditCard: true
                });
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getPaymentIntentData.message);
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.commonApiDetails &&
            nextProps.commonApiDetails.fileUploadData) {
            console.log("nextProps.commonApiDetails.fileUploadData:--", nextProps.commonApiDetails.fileUploadData);
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    insuranceImg: nextProps.commonApiDetails.fileUploadData,
                },
                insuranceName: nextProps.commonApiDetails.fileUploadData.originalname,
            }, () => {
                nextProps.commonApiDetails.fileUploadData = null;
                this.setLoader(false);
            });
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.eventRegistrationReq &&
            nextProps.eventDetails.eventRegistrationData
        ) {
            nextProps.eventDetails.eventRegistrationReq = false;
            console.log("eventRegistrationData:--", nextProps.eventDetails.eventRegistrationData);
            if (nextProps.eventDetails.eventRegistrationData.flag) {
                let tid = nextProps.eventDetails.eventRegistrationData.result._id;
                this.commonCall.successMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                var data = {
                    transactionId: tid,
                    amount: this.state.eventJoin.amount,
                    paymentType: this.state.eventJoin.paymentType,
                    card: "**** **** **** 4242"
                }
                localStorage.setItem("transaction", JSON.stringify(data));
                this.props.history.push(`/league-joined/${this.state.eventJoin.league}`);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getLeagueCoupanCodeReq &&
            nextProps.eventDetails.getLeagueCoupanCodeData
        ) {
            nextProps.eventDetails.getLeagueCoupanCodeReq = false;
            console.log("getLeagueCoupanCodeData:--", nextProps.eventDetails.getLeagueCoupanCodeData);
            if (nextProps.eventDetails.getLeagueCoupanCodeData.flag) {
                this.state.eventDetailData.payment.feesLeague = nextProps.eventDetails.getLeagueCoupanCodeData.result.newFeesLeague;
                this.state.eventJoin.amount = nextProps.eventDetails.getLeagueCoupanCodeData.result.newFeesLeague;
                nextProps.eventDetails.getLeagueCoupanCodeData = null;
                // var payment = {
                //     amount: this.state.eventDetailData.payment.feesLeague,
                //     objetId: this.state.eventDetailData._id,
                //     module: 2,
                //     method: "stripe",
                // };
                // this.props.fetchPaymentIntent(payment);
                this.setLoader(false);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getLeagueCoupanCodeData.message);
                nextProps.eventDetails.getLeagueCoupanCodeData = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        this.props.getLeagueDetails(this.state.eventId, true);
    }

    goBackToEventDetail = () => {
        this.props.history.push(`/league-detail/${this.state.eventId}`);
    };

    handleTabs = (e) => {
        if (e === 0) {
            this.setState({
                tabs: e
            })
        } else {
            this.joinEvent.validationUpdate(flag => {
                console.log("flag:----", flag);
                if (flag) {
                    this.setState({
                        tabs: e
                    })
                }
            });
        }
    }

    onHandleChange = (e) => {
        const { name, value, files } = e.target;
        console.log("value:---", value);
        if (name === "insuranceImg") {
            this.setLoader(true);
            this.props.postFileUpload(files[0], 24);
        } else if (name === "managerPhone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState({
                        eventJoin: {
                            ...this.state.eventJoin,
                            [name]: value,
                        }
                    });
                }
            }
        } else if (name === "creditcard") {
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    paymentMethod: this.state.eventJoin.paymentMethod === 1 ? "" : 1,
                },
                creditCard: true
            });
        } else if (name === "paymentType") {
            var amount = 0;
            var paymentType = JSON.parse(value);
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    [name]: paymentType,
                }
            }, () => {
                if (paymentType === 1 || paymentType === 2)
                    amount = this.state.eventDetailData.payment.feesLeague;
                else if (paymentType === 3)
                    amount =  this.state.eventDetailData.payment.downpaidAmt;
                else amount = 0;

                this.setState({
                    eventJoin: {
                        ...this.state.eventJoin,
                        amount: amount,
                    },
                    managePayMethod: paymentType === 2 ? true : false,
                }, () => {
                    if (paymentType === 1 || paymentType === 3) {
                        if (paymentType === 3 && this.state.eventDetailData.payment.downpaidAmt === 0)
                            return this.setState({
                                isPayLater: true,
                            })
                        else
                            return this.setState({
                                isPayLater: false,
                            }, () => {
                                // var payment = {
                                //     amount: amount,
                                //     objetId: this.state.eventDetailData._id,
                                //     module: 1,
                                //     method: "stripe",
                                // };
                                // console.log("payment:---", payment);
                                // this.setLoader(true);
                                // this.props.fetchPaymentIntent(payment);
                            });
                    } else {
                        this.setState({
                            isPayLater: true,
                        });
                    }
                })
            });
        } else {
            this.setState({
                eventJoin: {
                    ...this.state.eventJoin,
                    [name]: (name === "isManager") ? JSON.parse(value) : value,
                },
                managePayMethod: this.state.eventJoin.paymentType === 2 ? true : false,
            });
        }
    }

    removeInsurance = () => {
        this.setState({
            eventJoin: {
                ...this.state.eventJoin,
                insuranceImg: "",
            },
            insuranceName: "",
        });
    }

    handleQA = (e) => {
        const { name, value } = e.target;
        var questions = this.state.eventJoin.question;
        questions.forEach((question) => {
            if (question._id === name) question.answer = value;
        });
        this.setState({
            eventJoin: {
                ...this.state.eventJoin,
                question: questions,
            },
        });
    }

    applyCode = () => {
        let sendReq = {
            leagueId: this.state.eventId,
            couponCode: this.state.eventJoin.couponCode,
        }

        this.setLoader(true);
        this.props.rendLeagueCoupanCode(sendReq);
    }

    renderSwitch = () => {
        const { tabs, eventDetailData, rosterList, eventJoin, isShowPayment, insuranceName, managePayMethod } = this.state;
        switch (tabs) {
            case 0:
                return (
                    <JoinLeague
                        onRef={e => this.joinEvent = e}
                        dataList={eventDetailData}
                        rosterList={rosterList}
                        eventJoin={eventJoin}
                        // isShowPayment={isShowPayment}
                        onHandleChange={this.onHandleChange}
                        insuranceName={insuranceName}
                        removeInsurance={this.removeInsurance}
                        handleQA={this.handleQA}
                        registerEvent={this.registerEvent}
                    />
                );
            case 1:
                return (
                    <Payment
                        onRef={e => this.eventPay = e}
                        dataList={eventDetailData}
                        eventJoin={eventJoin}
                        onHandleChange={this.onHandleChange}
                        managePayMethod={managePayMethod}
                        registerEvent={this.registerEvent}
                        applyCode={this.applyCode}
                        setLoader={this.setLoader}
                        isPayLater={this.state.isPayLater}
                    />
                );
            default: {
            }
        }
    }

    renderStepBar = () => {
        return (
            <div class="time-line">
                {
                    this.state.isShowPayment ?
                        <div class="timeline-scoll">
                            <ul class="timeline-inner">
                                {
                                    this.state.disableStep !== 0 &&
                                    <li className={`${this.state.tabs === 0 ? "select" : this.state.tabs >= 0 ? "active" : ""}`} onClick={() => this.state.disableStep === 0 ? "" : this.handleTabs(0)}><a>1</a></li>
                                }
                                <li className={`${this.state.tabs === 1 ? "select" : this.state.tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}><a>{this.state.disableStep !== 0 ? "2" : "1"}</a></li>
                            </ul>
                        </div>
                        : ""
                }
                <div className="all_btn_step">
                    <Link className="btn blue_btn" onClick={() => (this.state.disableStep !== 0 && this.state.tabs === 1) ? this.handleTabs(0) : this.goBackToEventDetail()}>Back</Link>
                    {
                        this.state.isShowPayment &&
                        this.state.tabs === 0 &&
                        <Link className="btn light-green" onClick={() => this.handleTabs(1)}>Next</Link>
                    }
                    {!this.state.isShowPayment &&
                        <Link className="btn light-green" onClick={() => this.registerEvent()}>Submit</Link>
                    }
                    {
                        this.state.isPayLater && this.state.tabs >= 1 &&
                        <Link className="btn light-green" onClick={() => this.registerEvent()}>Submit</Link>
                    }
                </div>
            </div>
        )
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    onHandleClose = () => {
        this.setState({
            creditCard: false
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    stripePaymentSuccess = (payment) => {
        this.setState({
            creditCard: false,
            showPaymentButton: true,
            eventJoin: {
                ...this.state.eventJoin,
                metaPayment: payment,
            }
        }, () => {
            document.body.classList.remove("modal-open");
            this.registerEvent();
        })
    };

    stripePaymentError = (error) => {
        this.commonCall.errorMessage(error);
        this.setLoader(false);
    };

    registerEvent = () => {
        this.joinEvent.validationUpdate(flag => {
            console.log("flag:----", flag, this.state.eventJoin);
            if (flag) {
                if (this.state.eventJoin.paymentType !== 3) delete this.state.eventJoin.downpaidAmt;
                console.log("this.state.eventJoin:--", this.state.eventJoin);
                this.props.eventRegistrationPayment(this.state.eventJoin);
            } else {
                this.setLoader(false);
            }
        });
    }

    render() {
        const { tabs, loader, eventDetailData, eventId, isShowPayment, showPaymentButton, creditCard } = this.state;
        return (
            <div className="event_details">
                <Header />
                <CommonHeroBanner dataList={eventDetailData} />
                <div className="container">
                    <LeagueRow dataList={eventDetailData} isHideJoinButton={true} eventId={eventId} complete={false} isHideAddEventButton={true} />
                    <div className="event-container">
                        <div class="main-timeline">
                            {this.renderStepBar()}
                            {this.renderSwitch()}
                            {this.renderStepBar()}
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
                {
                    creditCard &&
                    <CreditCard
                        flag={creditCard}
                        close={this.onHandleClose}
                        stripePaymentSuccess={this.stripePaymentSuccess}
                        stripePaymentError={this.stripePaymentError}
                        amount={eventDetailData.payment && eventDetailData.payment.feesLeague}
                        setLoader={this.setLoader}
                    />
                }
            </div>
        )
    }
}

leaguePayment.propTypes = {
    getLeagueDetails: PropTypes.func.isRequired,
    getRosterOption: PropTypes.func.isRequired,
    postFileUpload: PropTypes.func.isRequired,
    fetchPaymentIntent: PropTypes.func.isRequired,
    eventRegistrationPayment: PropTypes.func.isRequired,
    rendLeagueCoupanCode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leagueDetails: state.leagueDetails,
    eventDetails: state.eventDetails,
    commonApiDetails: state.api
});
var leaguePaymentComponent = connect(mapStateToProps, { rendLeagueCoupanCode, getLeagueDetails, getRosterOption, postFileUpload, fetchPaymentIntent, eventRegistrationPayment })(leaguePayment);
export default withRouter(leaguePaymentComponent);

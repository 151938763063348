import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { rosterListByParentTeam } from '../../../../saga/actions/authantication/signup/coach/roster.action';
import Preloader from '../../../../components/preloader';
import AskHeadCoach from '../../../../views/popup/askHeadCoachForTeam';
import NoHeadCoach from '../../../../views/popup/noHeadCoach';
import AddRosterTeam from '../../../../views/popup/addRosterTeam';
import { saveRoster } from '../../../../saga/actions/authantication/signup/coach/roster.action';

class Roster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rosterArr: [],
            filter: {
                page: 1,
                limit: 10,
                name: "",
                parentTeam: "",
            },
            createRoster: {
                programName: "",
                rosterName: "",
                type: 1,
                competitionLevel: "Youth",
                ageGroup: "",
            },
            shopFlg: "no",
            openRoster: false,
            headCoachPopUp: false,
            noHeadCoachPopUp: false,
            skipRoster: false
        }
    }

    componentWillMount() {
        this.props.onRef(this);
        this.setLoader(true);
        this.setState((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                parentTeam: this.props.parentTeamId,
            }
        }),()=>{
            this.props.rosterListByParentTeam(this.state.filter);
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.rosterDetails &&
            nextProps.rosterDetails.rosterListRes &&
            nextProps.rosterDetails.rosterListData
        ) {
            nextProps.rosterDetails.rosterListRes = false;
            if (nextProps.rosterDetails.rosterListData.flag) {
                if (this.state.filter.name) {
                    return this.setState({
                        rosterArr: nextProps.rosterDetails.rosterListData.result.docs,
                    }, () => {
                        nextProps.rosterDetails.rosterListData = null;
                        this.setLoader(false);
                    })
                }
                if (nextProps.rosterDetails.rosterListData.result.docs.length) {
                    if (nextProps.rosterDetails.rosterListData.result.hasOwnProperty('myTeamList') && nextProps.rosterDetails.rosterListData.result.myTeamList.length !== 0) {
                        let val = nextProps.rosterDetails.rosterListData.result.docs;

                        let temp = []
                        val.map((item) => {
                            temp.push(item)
                        });
                        var joined = this.state.rosterArr.concat(temp);
                        for (let i = 0; i < nextProps.rosterDetails.rosterListData.result.myTeamList.length; i++) {
                            let element = nextProps.rosterDetails.rosterListData.result.myTeamList[i]
                            if (!joined.find(_ => _._id === element._id)) {
                                joined.unshift(nextProps.rosterDetails.rosterListData.result.myTeamList[i])
                            }
                        }

                        this.setState((prevState) => ({
                            rosterArr: joined,
                            selectedId: nextProps.rosterDetails.rosterListData.result.myTeamList[0]._id,
                            createRoster: {
                                ...prevState.createRoster,
                                programName: nextProps.rosterDetails.rosterListData.result.programData.name
                            },

                        }), () => {
                            nextProps.rosterDetails.rosterListData = null;
                            this.setLoader(false);
                        })
                    } else {
                        let val = nextProps.rosterDetails.rosterListData.result.docs;

                        let temp = []
                        val.map((item) => {
                            temp.push(item)
                        });
                        var joined = this.state.rosterArr.concat(temp);
                        this.setState((prevState) => ({
                            rosterArr: joined,
                            createRoster: {
                                ...prevState.createRoster,
                                programName: nextProps.rosterDetails.rosterListData.result.programData.name
                            },
                        }), () => {
                            nextProps.rosterDetails.rosterListData = null;
                            this.setLoader(false);
                        });
                    }
                }
                else {
                    let val = nextProps.rosterDetails.rosterListData.result.docs;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.rosterArr.concat(temp);
                    this.setState((prevState) => ({
                        rosterArr: joined,
                        createRoster: {
                            ...prevState.createRoster,
                            programName: nextProps.rosterDetails.rosterListData.result.programData.name
                        },
                    }), () => {
                        nextProps.rosterDetails.rosterListData = null;
                        this.setLoader(false);
                    });
                }
            }
        } else {
            this.setLoader(false);
            nextProps.rosterDetails.rosterListData = null;
        }

    }

    setSelectValue = (val, cb) => {
        this.setState({
            rosterArr: val,
        }, () => cb(true));
    }

    onHandleChange = (e,checked) => {
        
        const { name, value } = e.target;
        if (name === "roster") {
            this.setState((prevState) => ({
                ...prevState,
                selectedId: checked ? value : ""
            }));
        }

        if (name === "rosterName" || name === "competitionLevel" || name === 'ageGroup') {
            this.setState((prevState) => ({
                ...prevState,
                createRoster:{
                    ...prevState.createRoster,
                    [name]: value,
                    ageGroup : name === "competitionLevel" ? "" : value
                }
            }));
        }
        if (name === "type") {
            this.setState((prevState) => ({
                ...prevState,
                createRoster:{
                    ...prevState.createRoster,
                    [name]: JSON.parse(value)
                }
            }));
        }
        if (name === "name") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    page: 1,
                    limit: 10,
                    name: value
                },
            }), () => {
                if (value.length !== 0) {
                    this.setLoader(true);
                    this.props.rosterListByParentTeam(this.state.filter);
                } else {
                    this.state.rosterArr = [];
                    this.setLoader(true);
                    this.props.rosterListByParentTeam(this.state.filter);
                }
            });
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.rosterListByParentTeam(this.state.filter);
    }

    renderRosterList = () => {
        return (
            this.state.rosterArr.length !== 0 ?
                this.state.rosterArr.map((roster, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={roster.teamLogo ? roster.teamLogo : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{roster.rosterName && roster.rosterName}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="checkbox"
                                    id="Baseball"
                                    value={roster._id}
                                    name="roster"
                                    onChange={(e) => this.onHandleChange(e,e.target.checked)}
                                    checked={roster._id === this.state.selectedId ? true : false}
                                />
                                <span for="Baseball"></span>
                            </div>
                        </div>
                    );
                }) :
                <h4 className="text-center">No Data Found</h4>
        );
    }

    validationUpdate = (cb) => {
        if (this.state.selectedId) {
            cb({ data: { rosterTeamId: this.state.selectedId }, flag: true,skipRoster: this.state.skipRoster });
        } else {
            cb({ data: '', flag: false ,skipRoster: this.state.skipRoster});
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    page: this.state.filter.page + 1
                }
            }), () => {
                this.setLoader(true);
                this.props.rosterListByParentTeam(this.state.filter);
            })
        }
    }

    handleOpenHeadCoach = () =>{
        this.setState({ headCoachPopUp: !this.state.headCoachPopUp })
    }

    closeHeadCoachPopup = () =>{
        document.body.classList.remove('modal-open');
        this.setState({
            headCoachPopUp: false,
        })
    }

    onHandleClose = () =>{
        document.body.classList.remove('modal-open');
        this.setState({ 
            openRoster: false,
         })
    }
    
    addNewRoster = ()=>{
        this.setState({ 
            openRoster: !this.state.openRoster,
            headCoachPopUp: false,
         })
    }

    noHead = () =>{
        this.setState({
            headCoachPopUp: false,
            noHeadCoachPopUp: !this.state.noHeadCoachPopUp
         })
    }

    nextStep = () => {
        document.body.classList.remove('modal-open');

        this.setState({
            skipRoster: true,
            noHeadCoachPopUp: false
        },()=>{
            this.props.nextStep();
        })
    }

    saveRoster = () => {
        let data = {
            teamRoster: this.state.createRoster.type === 1 ? this.state.createRoster : [],
            facilityRoster: this.state.createRoster.type === 2 ? this.state.createRoster : []
        }
        this.props.rosterDetails.saveRosterData = null;

        this.props.saveRoster(data);
    }

    render() {
        const { profileData } = this.props;
        const { loader, filter } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname},
                            </h2>
                            <p>
                                Find your facility or team Roster Group by typing in their name below.
                            </p>
                        </div>

                        <div className="add_team check_input">
                            <Link onClick={this.handleOpenHeadCoach} className="svg-btn trans"><img src={imagesArr.addteam} alt="Add New Team" />Add New Roster</Link>

                            <div className="form-group mb-0 w-100">
                                <div className="serch-box">
                                    <input type="text" className="form-control" id="usr" placeholder="Search for your roster" name="name" value={filter.name} onChange={(e) => this.onHandleChange(e)} />
                                    {/* <Link className="serch-btn" onClick={this.onSearch}><img src={imagesArr.search} alt="search" /></Link> */}
                                </div>
                            </div>
                        </div>

                        <div className="scrollbar_team" onScroll={this.getScroll}>
                            <div className="select_team ml-0">
                                {this.renderRosterList()}
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.headCoachPopUp &&
                    <AskHeadCoach
                        flag={this.state.headCoachPopUp}
                        close={this.closeHeadCoachPopup}
                        addNewRoster={this.addNewRoster}
                        noHead={this.noHead}
                    />
                }

                {
                    this.state.noHeadCoachPopUp &&
                    <NoHeadCoach
                        flag={this.state.noHeadCoachPopUp}
                        nextStep={this.nextStep}
                    />
                }

                {
                    this.state.openRoster &&
                    <AddRosterTeam
                        onRef={e => this.addTeamPopup = e}
                        flag={this.state.openRoster}
                        close={this.onHandleClose}
                        rosterInput={this.state.createRoster}
                        // shopFlg={this.state.shopFlg}
                        onChange={this.onHandleChange}
                        onAddRoster={this.saveRoster}
                        imageUrl={this.state.teamLogo}
                        // removeImg={this.imageRemove}
                        load={this.setLoader}
                    // handleChange={this.handleChange}
                    />
                }

                <Preloader flag={loader} />
            </>
        );
    }
}

Roster.propTypes = {
    coachList: PropTypes.func.isRequired,
    saveRoster: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    rosterDetails: state.rosterDetails
});

export default connect(mapStateToProps, { saveRoster,rosterListByParentTeam })(Roster);

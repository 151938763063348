import React, { Component } from 'react';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import DivisionSlider from "../../../views/company/components/slider/divisionSlider";
import UpComingEvents from "../../../views/company/components/common/upComingEvents";
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import PastChampionsInfo from "../../../views/company/components/common/pastChampions";
import Shop from "../../../views/company/components/common/shop";
import Preloader from '../../../components/preloader';

class utbTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0
        }
    }

    componentDidMount = () => {
        localStorage.removeItem("textName");
    }

    increment = (active) => {
        this.setState(prevState => {
            return { loaderCount: active ? active :  prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { loaderCount } = this.state;
        return (
            <div>
                <>
                    <Header />
                    <div className="event-main">
                        <DivisionSlider increment={this.increment} decrement={this.decrement} />

                        {/* upcoming Event */}

                        <UpComingEvents isPresent={3} filterEventType={false} filterCompany={false} eventTeamType={1} cType="utb" textName="Tournaments" increment={this.increment} decrement={this.decrement} eventLabel={true} eventLinkLabel={true} filterForm={true} hideChkBox={true} noOfRec={6} eventType=""/>

                        {/* Past Champions */}

                        {/* <PastChampionsInfo increment={this.increment} decrement={this.decrement} /> */}

                        {/* Shop */}

                        <div className="upcomingevent_section">
                            <Shop cType="utb" />
                        </div>

                        {/* Proud Sponsors */}

                        <ProudSponsors increment={this.increment} decrement={this.decrement}/>


                    </div>
                    <Footer />
                    <Preloader flag={loaderCount > 0 ? true : false} />
                </>
            </div>
        );
    }
}

export default utbTournament;


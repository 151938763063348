import { imagesArr } from '../assets/images';

export const commonHeader = {
    about: [
        {
            label: "About",
            route: "/about-us",
            key: "about"
        },
        {
            label: "Our Team",
            route: "/our-team",
            key: "team"
        },
        {
            label: "Press Room",
            route: "/press-room",
            key: "press-room"
        },
        {
            label: "Blog",
            route: "https://baseballcloud.blog",
            key: "blog"
        },
        {
            label: "Contact Us",
            route: "https://baseballcloud.blog",
            key: "contact"
        },
    ],
    products: [
        {
            label: "Diamond Connect",
            route: "/dc",
            key: "dc",
            icon: imagesArr.menu_dc
        },
        {
            label: "AnalyzR",
            route: "/analyzr",
            key: "analyzr",
            icon: imagesArr.menu_analyzr
        },
        {
            label: "BallR",
            route: "/ballr",
            key: "ballr",
            icon: imagesArr.menu_ballr
        },
        {
            label: "BCTeam",
            route: "/bcteam",
            key: "bcteam",
            icon: imagesArr.menu_bcteam
        },
        {
            label: "PitchR",
            route: "/pitchr",
            key: "pitchr",
            icon: imagesArr.menu_pitchr
        },



        // {
        //     label: "OvR",
        //     route: "/ovr",
        //     key: "ovr",
        //     icon: ""
        // }
    ],
    events: [
        {
            label: "Diamond Connect",
            route: "/dc-event",
            icon: imagesArr.dc_logo_black,
            key: "dc",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#eaf6cc"
                },
            ]
        },
        {
            label: "Prospect Wire Baseball",
            route: "/pwb",
            icon: imagesArr.prospectwire,
            key: "pwb",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#eaf6cc"
                },
            ]
        },
        {
            label: "Prospect Wire Softball",
            route: "/pws",
            icon: imagesArr.prospectwiresoftball,
            key: "pws",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#66C9BA"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#76CDC0"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#A3D7CF"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#cfeee9"
                },
            ]
        },
        {
            label: "Russmatt Baseball",
            route: "https://russmatt.com",
            icon: imagesArr.russmattbaseball,
            key: "rb",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#eaf6cc"
                },
            ]
        },
        {
            label: "Russmatt Softball",
            route: "http://russmattsoftball.com",
            icon: imagesArr.russmattsoftball,
            key: "rs",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#eaf6cc"
                },
            ]
        },
        {
            label: "Greater Orlando Baseball",
            route: "/gob",
            icon: imagesArr.greaterorkandobaseball,
            key: "gob",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#222160"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#222160"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#27266C"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#a2a1bc"
                },
            ]
        },
        {
            label: "USA Travel Ball",
            route: "https://baseball.sincsports.com/TTContent.aspx?tab=1&tid=USATBWC",
            icon: imagesArr.usatravelball,
            key: "utb",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#1a315c"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#31466c"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#8d98ae"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#a3adbe"
                },
            ]
        },
        {
            label: "Pioneer League",
            route: "/pioneer",
            icon: imagesArr.pioneer_logo_black,
            key: "pioneer",
            colors: [
                {
                    variable: "--green-color",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-light",
                    colorCode: "#96CF04"
                },
                {
                    variable: "--green-color-shadow",
                    colorCode: "#7CBD42"
                },
                {
                    variable: "--green-color-extra-light",
                    colorCode: "#eaf6cc"
                },
            ]
        },
        // {
        //     label: "Gem Softball",
        //     route: "/uts",
        //     icon: imagesArr.gemtravelball,
        //     key: "uts",
        //     colors: [
        //         {
        //             variable: "--green-color",
        //             colorCode: "#7CBD42"
        //         },
        //         {
        //             variable: "--green-color-light",
        //             colorCode: "#96CF04"
        //         },
        //         {
        //             variable: "--green-color-shadow",
        //             colorCode: "#7CBD42"
        //         },
        //         {
        //             variable: "--green-color-extra-light",
        //             colorCode: "#eaf6cc"
        //         },
        //     ]
        // },
    ]
}

// let url = new URL(document.location);
// let menus;
// if (url.hostname === "devbc.diamondconnect.com") {
//     menus = [
//         {
//             label: "Leagues",
//             route: "/pwb-league"
//         },
//         {
//             label: "Tournaments",
//             route: "/pwb-tournament"
//         },
//         {
//             label: "Showcases",
//             route: "/pwb-showcase"
//         },
//         {
//             label: "Event Recap",
//             route: "/pwb-event-recap"
//         },
//         {
//             label: "Hotels",
//             route: "/pwb-hotel"
//         },
//         {
//             label: "About",
//             route: "/pwb-about"
//         },
//     ]
// } else {
//     menus = [
//         {
//             label: "PW+",
//             route: "/pw-leaderboard"
//         },
//         {
//             label: "Leagues",
//             route: "/pwb-league"
//         },
//         {
//             label: "Tournaments",
//             route: "/pwb-tournament"
//         },
//         {
//             label: "Showcases",
//             route: "/pwb-showcase"
//         },
//         {
//             label: "Event Recap",
//             route: "/pwb-event-recap"
//         },
//         {
//             label: "Hotels",
//             route: "/pwb-hotel"
//         },
//         {
//             label: "About",
//             route: "/pwb-about"
//         },
//     ]
// }
export const companyHeader = [
    {
        key: "dc", // diamond connect
        name: "Diamond Connect",
        logo: imagesArr.diamondconnect,
        route: "/dc-event",
        menu: [
            {
                label: "Events",
                route: "/dc-event"
            },
            {
                label: "College Showcase",
                route: "/dc-collegeShowcase"
            },
            {
                label: "Download App",
                // route: "/pwb-tournament"
            },
            {
                label: "Contact Us",
                route: "/dc-contactUs"
            },
            {
                label: "DC Rules",
                route: "/dc-rules"
            },
        ],
        socialLinks: {
            twitter: "https://twitter.com/DCappUS",
            instagram: "https://www.instagram.com/dcapp_us/",
            facebook: "https://www.facebook.com/profile.php?id=100085569381904"
        },
        footerClass: "dc_footer_logo"
    },
    {
        key: "pwb", // Prospect Wire
        name: "Prospect Wire",
        logo: imagesArr.company_logo_1,
        route: "/pwb",
        menu: [
            {
                label: "PW+",
                route: "/pw-leaderboard"
            },
            {
                label: "Past Champions",
                route: "/pwb-pastCham"
            },
            {
                label: "Leagues",
                route: "/pwb-league"
            },
            {
                label: "Tournaments",
                route: "/pwb-tournament"
            },
            {
                label: "Showcases/Camps",
                route: "/pwb-showcase"
            },
            {
                label: "Event Recap",
                route: "/pwb-event-recap"
            },
            {
                label: "Hotels",
                route: "/pwb-hotel"
            },
            {
                label: "About",
                route: "/pwb-about"
            },
            {
                label: "PWB Rules",
                route: "/pwb-rules"
            },
        ],
        button: [
            {
                label: "Shop",
                icon: "fa fa-shopping-bag",
                className: "btn trans",
                link: "https://teamlocker.squadlocker.com/#/lockers/prospect-wire-294094?_k=kdxe6p"
            },
            {
                label: "Recommended a Prospect",
                icon: "",
                className: "btn",
                route: "/pwb-recommend-prospect"
            },
        ],
        socialLinks: {
            twitter: "https://twitter.com/ProspectWire",
            instagram: "https://www.instagram.com/prospectwirebaseball",
            facebook: "https://www.facebook.com/ProspectWire"
        },
        footerClass: "pwb_footer_logo"
    },
    {
        key: "pws", // Prospect Wire 
        name: "Prospect Wire Softball",
        logo: imagesArr.company_logo_2,
        route: "/pws",
        menu: [
            {
                label: "PWS+",
                route: "/pw-leaderboard"
            },
            {
                label: "Leagues",
                route: "/pws-league"
            },
            {
                label: "Tournaments",
                route: "/pws-tournament"
            },
            {
                label: "Camps",
                route: "/pws-showcase"
            },
            {
                label: "Event Recap",
                route: "/pws-event-recap"
            },
            {
                label: "Hotels",
                route: "/pws-hotel"
            },
            {
                label: "About",
                route: "/pws-about"
            },
            {
                label: "PWS Rules",
                route: "/pws-rules"
            },
        ],
        button: [
            {
                label: "Shop",
                icon: "fa fa-shopping-bag",
                className: "btn trans",
                link: "https://teamlocker.squadlocker.com/#/lockers/prospect-wire-softball?_k=d57bt7"
            },
            {
                label: "Recommended a Prospect",
                icon: "",
                className: "btn",
                route: "/pws-recommend-prospect"
            },
        ],
        socialLinks: {
            twitter: "https://twitter.com/PWireSoftball",
            instagram: "https://www.instagram.com/prospectwiresoftball",
            facebook: "https://www.facebook.com/Prospect-Wire-Softball-100778911931393"
        },
        footerClass: "pws_footer_logo"
    },
    {
        key: "gob", // Greater Orkando Basebal
        name: "Greater Orlando Baseball",
        logo: imagesArr.company_logo_3,
        route: "/gob",
        menu: [
            {
                label: "Tournaments",
                route: "/gob-tournament"
            },
            {
                label: "Leagues",
                route: "/gob-league"
            },
            // {
            //     label: "Insurance",
            //     route: "/gob-insurance"
            // },
            {
                label: "Hotels",
                route: "/gob-hotel"
            },
            {
                label: "About",
                route: "/gob-about"
            },
            {
                label: "GOB Rules",
                route: "/gob-rules"
            },
        ],
        button: [
            {
                label: "Shop",
                icon: "fa fa-shopping-bag",
                className: "btn trans",
                link: "https://teamlocker.squadlocker.com/#/lockers/greater-orlando-baseball-303500?_k=tlo70u"
            },
        ],
        socialLinks: {
            twitter: "https://twitter.com/GTB_Baseball",
            instagram: "https://www.instagram.com/explore/tags/greaterorlandobaseball",
            facebook: "https://www.facebook.com/GreaterOrlandoBaseball"
        },
        footerClass: "gob_footer_logo"
    },
    {
        key: "utb", // USA Travel Ball
        name: "USA Travel Ball",
        logo: imagesArr.company_logo_4,
        route: "/utb",
        menu: [
            {
                label: "Tournaments",
                route: "/utb-tournament"
            },
            {
                label: "Leagues",
                route: "/utb-league"
            },
            {
                label: "Insurance",
                route: "/utb-insurance"
            },
            {
                label: "Hotels",
                route: "/utb-hotel"
            },
            {
                label: "About",
                route: "/utb-about"
            },
        ],
        button: [
            {
                label: "Shop",
                icon: "fa fa-shopping-bag",
                className: "btn trans",
                link: ""
            },
        ],
        socialLinks: {
            twitter: "https://twitter.com/USATravelBall1",
            instagram: "https://www.instagram.com/usatravelball/",
            facebook: "https://www.facebook.com/usatravelball"
        },
        footerClass: "utb_footer_logo"
    },
    {
        key: "pioneer", // Prospect Wire
        name: "Pioneer",
        logo: imagesArr.company_logo_6,
        route: "/pioneer",
        menu: [
            {
                label: "Tryouts",
                route: "/pioneer-showcase"
            },
            {
                label: "Event Recap",
                route: "/pioneer-event-recap"
            },
        ],
        button: [
            // {
            //     label: "Shop",
            //     icon: "fa fa-shopping-bag",
            //     className: "btn trans",
            //     link: ""
            // },
            // {
            //     label: "Recommended a Prospect",
            //     icon: "",
            //     className: "btn",
            //     route: "/pwb-recommend-prospect"
            // },
        ],
        socialLinks: {
            twitter: "https://twitter.com/ProspectWire",
            instagram: "https://www.instagram.com/prospectwirebaseball",
            facebook: "https://www.facebook.com/ProspectWire"
        },
        footerClass: "pioneer_footer_logo"
    },
]

export const commonPagesContent = {
    socialmedia: [
        {
            key: "bc", // BaseballCloud
            title: "DiamondConnect",
            desc: "Please create an account which will allow you to log into all DiamondConnect Products.",
        },
        {
            key: "dc", // DiamondConnect
            title: "DiamondConnect",
            desc: "Please create an account which will allow you to log into all DiamondConnect Products.",
        },
        {
            key: "pwb", // Prospect Wire
            title: "Prospectwire",
            desc: "Please create an account which will allow you to log into all Prospect wire Products.",
        },
        {
            key: "pws", // Prospect Wire Softball
            title: "Prospectwire Softball",
            desc: "Please create an account which will allow you to log into all Prospectwire Softball Products.",
        },
        {
            key: "gob", // Greater Orkando Baseball
            title: "Greater Orlando Baseball",
            desc: "Please create an account which will allow you to log into all Greater Orlando Baseball Products.",
        },
        {
            key: "utb", // USA Travel Ball
            title: "USA Travel Ball",
            desc: "Please create an account which will allow you to log into all USA Travel Ball Products.",
        },
        {
            key: "rb", // Russmatt Baseball
            title: "Russmatt Baseball",
            desc: "Please create an account which will allow you to log into all Russmatt Baseball Products.",
        },
        {
            key: "rs", // Russmatt Softball
            title: "Russmatt Softball",
            desc: "Please create an account which will allow you to log into all Russmatt Softball Products.",
        },
        {
            key: "uts", // USA Travel Ball
            title: "USA Travel Ball",
            desc: "Please create an account which will allow you to log into all USA Travel Ball Products.",
        }
    ],
    signup: [
        {
            key: "bc", // BaseballCloud
            title: "Welcome to BaseballCloud",
            desc: "thanks for signing up for the all new BaseballCloud.",
        },
        {
            key: "pwb", // Prospect Wire
            title: "Welcome to Prospect wire",
            desc: "thanks for signing up for the all new Prospect wire.",
        },
        {
            key: "pws", // Prospect Wire Softball
            title: "Prospectwire Softball",
            desc: "thanks for signing up for the all new Prospectwire Softball.",
        },
        {
            key: "gob", // Greater Orkando Baseball
            title: "Greater Orlando Baseball",
            desc: "thanks for signing up for the all new Greater Orlando Baseball.",
        },
        {
            key: "utb", // USA Travel Ball
            title: "USA Travel Ball",
            desc: "thanks for signing up for the all new USA Travel Ball.",
        },
        {
            key: "rb", // Russmatt Baseball
            title: "Russmatt Baseball",
            desc: "thanks for signing up for the all new Russmatt Baseball.",
        },
        {
            key: "rs", // Russmatt Softball
            title: "Russmatt Softball",
            desc: "thanks for signing up for the all new Russmatt Softball.",
        },
        {
            key: "uts", // USA Travel Ball
            title: "USA Travel Ball",
            desc: "thanks for signing up for the all new USA Travel Ball.",
        }
    ]
}

export const ourTeamContent = {
    about: {
        pwb: [
            {
                title: "Brian Werner ",
                position: "President",
                email: "brian@prospectwire.com",
                description: `
                <p>Brian Werner started with Prospect Wire in October of 2008, serving as one of the scouts for the All Florida Games in Vierra. In the fall of 2010, Brian was promoted to Tournament Director. In 2011, Brian became the National Tournament Director, when Prospect Wire expanded to Arizona & Virginia, and eventually California in 2016. In 2020, Brian was named Vice President, helping to oversee expansion of events, while continuing to function as acting Tournament Director for the company. To start 2021, Brian was officially named the 3rd President in PW history. </p>
                <p>During his time with Prospect Wire, Brian has served in a variety of roles, performing various functions at all levels of the organization. Brian assisted in the development of the first PW Scouting Department, writing reports and covering individual workouts & showcases for the company. Upon moving into his role as National Tournament Director, Brian was responsible for marketing events, registering teams, collecting rosters & payments, as well as producing & updating the schedule for all events. </p>
                <p>Today, Brian oversees all aspects of PW Baseball’s operations, with the help of his core staff. Brian’s focus continues to remain on the day to day tournament operations, helping to bring new & innovative technology to our current events. Brian also is focused on bringing Prospect Wire to new states in the future through PW Franchising. After Frank Gates, Brian is Prospect Wire’s longest tenured employee. </p>
                <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Worked for the NY Yankees Player Development & Scouting Department from 1999-2004.  Assisted in draft preparation, player travel, & roster management for minor league teams. Attended MLB Scouting School in 2003; served as the Director of Baseball Operations prior to leaving in 2004.</li>
                        <li>Coached HS Baseball from 2005-2009 (Seminole HS, Boca Ciega HS)</li>
                        <li>Property Manager & Licensed Real Estate Agent (2005-2009)</li>
                        <li>Writing & Speech Tutor, St. Petersburg College (2009-Present)</li>
                    </ul>
                </div>
                <div class="top-title text-left"><p class="text-underline">EDUCATION & ATHLETIC CAREER</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Graduated from the University of South Florida (1998-1999) with Bachelor of Science in Communication.</li>
                        <li>Member of USF Bulls Baseball program (1998-1999)</li>
                        <li>Attended Clemson University (1994-1997)</li>
                        <li>2 Time College World Series Participant (1995, 1996)</li>
                        <li>Graduated HS from Miami Sunset Sr.</li>
                        <li>3rd Team All State (1994), 2 time 1st Team All District (1993, 1994)</li>
                    </ul>
                </div>
                <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Born & Raised in Miami, FL</li>
                        <li>Met current wife (Lori) in 2014; married on Groundhog’s Day in 2019</li>
                        <li>Lives in Tampa Bay, FL since 1997</li>
                        <li>Enjoys traveling to different countries, hiking & spending time in the mountains</li>
                    </ul>
                </div>
                `
            },
            {
                title: "Mike Easom ",
                position: "Vice President, Logistics & Operations",
                email: "mike@prospectwire.com",
                description: ""
            },
            {
                title: "Frank Gates ",
                position: "Vice President, Housing & Operations",
                email: "frank@prospectwire.com",
                description: ""
            },
            // {
            //     title: "Juan Fontela ",
            //     position: "National Director, Showcases & Scouting",
            //     email: "juan@prospectwire.com",
            //     description: `
            //     <p>Juan began working for Prospect Wire in 2017, serving as a scorekeeper/video scout. He quickly progressed to one of Prospect Wire’s most trusted on field scouts, specializing in promoting catchers. Juan has an incredible ability to relate to players and gain their trust, making it easier for him to do his job as a scout. In 2020, Juan was promoted to Assistant Showcase Director. To start 2021, Juan was promoted again to National Showcase Director, in charge of overseeing the development of Prospect Wire’s new & improved Showcase events. Juan also continues to have a heavy scouting influence during Prospect Wire’s tournaments.</p>
            //     <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Assistant Coach, Jefferson High School - 2018 State Champions</li>
            //         </ul>
            //     </div>
            //     <div class="top-title text-left"><p class="text-underline">EDUCATION & ATHLETIC CAREER</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Graduated from Jefferson HS - catcher on baseball team</li>
            //         </ul>
            //     </div>
            //     <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Juan was born & raised in Tampa, FL and continues to reside in the area.</li>
            //         </ul>
            //     </div>
            //     `
            // },
            // {
            //     title: "Griffin  George",
            //     position: "Account Manager, DiamondConnect",
            //     email: "griffin@dssports.com",
            //     description: ""
            // },
            {
                title: "Nick Susie",
                position: "National Tournament Director",
                email: "Nick@prospectwire.com",
                description: ""
            },
            {
                title: "PW Admin",
                position: "Tournament Administration",
                email: "admin@prospectwire.com",
                description: ""
            },
            // {
            //     title: "Stephen Martin ",   
            //     position: "Event Operations, East Coast",
            //     email: "stephen@prospectwire.com",
            //     description: `
            //     <p>Stephen came to Prospect Wire in 2018, serving as a Scorekeeper/Video Scout. Stephen quickly became one of Prospect Wire’s more trusted evaluators & staff members. In 2019, Stephen added event operations to his PW resume, assisting in the set-up & breakdown at each event. Martin continues to serve as a video scout for Prospect Wire, covering mainly the East Coast events, but has also scouted in the Midwest, West Coast & Northeast. In 2021, Martin was named Manager of Operations for the East Coast, where his role will be heavily focused on the set up, maintenance & breakdown of each tournament. Martin also assists in managing Prospect Wire’s event inventory.</p>
            //     <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Began working in Education in Hillsborough County in 2018</li>
            //             <li>Began scouting for various tournament companies in 2015</li>
            //         </ul>
            //     </div>
            //     <div class="top-title text-left"><p class="text-underline">EDUCATION & ATHLETIC CAREER</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Olivet Nazarene University - RHP</li>
            //             <li>Played Independent Baseball after graduating college</li>
            //             <li>Chamberlain HS (Tampa, FL)</li>
            //         </ul>
            //     </div>
            //     <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Born & raised, currently resides in Tampa, FL</li>
            //         </ul>
            //     </div>
            //     `
            // },
            // {
            //     title: "Bryan Burgess ",
            //     position: "Event Operations, East/West Coast",
            //     email: "burgess@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Ernie Padron ",
            //     position: "Scouting Coordinator, East Coast",
            //     email: "ernie@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Billy Colome ",
            //     position: "Event Scouting, East Coast",
            //     email: "billy@prospectwire.com",
            //     description: `
            //         <div class="top-title text-center"><p class="text-underline">BILLY COLOME</p></div>
            //         <p>Billy has been with Prospect Wire since 2013, serving in a variety of roles on the field. Starting as one of the Elite 100 Showcase Assistants, Billy was part of the initial group of PW Scouts. Billy served as a FlightScope Tech for PW, while mainly providing scouting coverage at events in the Southeast. Billy is currently a Regional Scout for Prospect Wire and brings years of on field coaching experience to the table.</p>
            //         <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Coached baseball at East Bay HS in Tampa, FL for 6 years</li>
            //                 <li>Coached at Hillsborough Community College in Tampa, FL for 8 years</li>
            //                 <li>Hillsborough County Teacher for 15 years</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">EDUCATION & PLAYING CAREER</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Independent Minor League Player (3 years in the Frontier & Northern Leagues) with the Canton Crocodiles, Sioux Falls Canaries & the Springfield Capitals.</li>
            //                 <li>Extended Spring Training - Cleveland Indians</li>
            //                 <li>Broward Community College - career .351 average. All American in 1997.</li>
            //                 <li>College of Charleston - 2 time All American with career .384 batting average. Had a 29 game hitting streak that still stands as a school & Southern Conference record.</li>
            //                 <li>Graduated HS from Miami Southridge Sr.</li>
            //                 <li>Special Note - Was American Legion teammates (1994-1995) with current PW President Brian Werner down in Miami growing up.</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Born & Raised in Miami, FL and currently resides in Tampa, FL</li>
            //                 <li>Married to Heather Colome with 3 kids (Brianna, Cooper & Tess)</li>
            //             </ul>
            //         </div>
            //     `
            // },
            // {
            //     title: "Steve Perkio ",
            //     position: "Event Scouting, Midwest",
            //     email: "steve@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Mario Castellanos ",
            //     position: "Assistant Showcase Director",
            //     email: "mario@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Dave Pankenier ",
            //     position: "Scouting Coordinator, West Coast",
            //     email: "dave@prospectwire.com",
            //     description: `
            //     <p>Dave Pankenier is one of only 2 members of Prospect Wire’s staff to be certified as an MLB Scout (2005). Originally joining the staff in 2012 when Prospect Wire started running events in Arizona, Dave has served as PW’s main scout for west coast events (Arizona & California). He produces videos & short reports for social media, as well as for player profiles on the website. Dave is an excellent mentor for young scouting staff, helping them learn the scouting lingo, as well as what to focus on during a tournament. His current title is Regional Scouting Coordinator for the West Coast.</p>
            //     <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Assistant Coach/Recruiting Coordinator, Chandler-Gilbert CC (2008-Present)</li>
            //                 <li>Graduate, MLB Scouting School (2005)</li>
            //                 <li>Northern League Tryouts & Draft Co-Coordinator (2004)</li>
            //                 <li>Associate Baseball Scout, Kansas City Royals, NY Mets, Houston Astros & Arizona Diamondbacks (2002-Present)</li>
            //                 <li>Chicago Cubs Scout Team - Head Coach (2001)</li>
            //                 <li>North High School Head Coach (1994-1999)</li>
            //                 <li>North New Jersey Coach of the Year (1988, 1990)</li>
            //                 <li>Member of the Selection Committee, Garden State Games (1989-1991)</li>
            //                 <li>Bayley Ellard High School Head Coach (1986-1991)</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">EDUCATION & PLAYING CAREER</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Elon College - NAIA D1 Football National Champion (1981)</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Originally from East Hanover, NJ, he moved to Arizona in 1991</li>
            //                 <li>Owner of Building Baseball Careers, LLC</li>
            //                 <li>Partner in 7 Tool Athletics, LLC</li>
            //                 <li>Married Tina Pankenier</li>
            //                 <li>Enjoys staying in touch with former players. He started a mentorship program for the Chandler-Gilbert catchers.</li>
            //                 <li>Considers himself a lifelong learner, enjoys research and reading in his spare time.</li>
            //                 <li>Dave also enjoys handicapping Horse Racing, and his favorite sport is hockey</li>
            //             </ul>
            //         </div>
            //     `
            // },
           
            // {
            //     title: "Jeromie Spillman ",
            //     position: "Event Scouting, West Coast",
            //     email: "jeromie@prospectwire.com",
            //     description: `
            //     <p>Jeromie started with Prospect Wire in the summer of 2011, primarily iScoring games. With an incredible work ethic, Spillman quickly proved he was valuable in multiple areas. Soon after, he began assuming the role of site supervisor at events in Arizona, and eventually California. With a strong baseball background, Spillman began taking on the role of Video Scout at all PW West Coast events. Spillman continues to be one of Prospect Wire’s most trusted scouts, along with continuing to serve as Field Manager at various locations in Arizona & California.</p>
            //     <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Licensed Real Estate Agent (2018-Present)</li>
            //                 <li>Assistant Coach, Phoenix College (2015-Present) - 2017 NJCAA DII Baseball WS Finalist</li>
            //                 <li>High School Teacher (2004-Present)</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">EDUCATION & PLAYING CAREER</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>University of Phoenix, Masters in Secondary Education</li>
            //                 <li>MLB Spring Training BP Pitcher - Texas Rangers (2003)</li>
            //                 <li>Drafted, 37th Round, 2000 MLB Draft (Blue Jays)</li>
            //                 <li>Grand Canyon University, Bachelor of Science in Physical Education - Conference Champions (1999) & 2nd Team All Conference Selection
            //                 </li>
            //                 <li>Eastern Arizona College (Liberal Arts) - member of first baseball program in school history</li>
            //                 <li>Palo Verde HS (Tucson, AZ) - 2 sport athlete in baseball & basketball (All League, All State)</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Born in Tucson, AZ but moved around due to being in a military family. </li>
            //                 <li>Married to Lupe Spillman with 3 kids: Ashton, Aya & Olivia, along with Brutus, their Boxer</li>
            //                 <li>Enjoys traveling with his family & spending quality time together</li>
            //             </ul>
            //         </div>
            //     `
            // },
            // {
            //     title: "Austin Brown",
            //     position: "Event Operations, West Coast",
            //     email: "Austin@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Donovan McMullen ",
            //     position: "Event Operations, West Coast",
            //     email: "Donovan@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Jordan Engel ",
            //     position: "Event Operations, East Coast",
            //     email: "jordan@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Danny Close ",
            //     position: "Event Operations, East Coast",
            //     email: "danny@prospectwire.com",
            //     description: `<p>Danny is one of Prospect Wire’s longest tenured event staff. Starting in 2011, Danny’s main focus has been as manager of the Customer Service Team at Prospect Wire’s tournaments. Danny’s responsibilities include the management of the gate staff, overseeing the intake & accounting of gate entrance fees, as well as Complex Manager at many events. Danny is usually one of the first faces people see when coming to a Prospect Wire tournament, providing information to teams and spectators such as schedule updates, field locations and local eating & entertainment establishments.</p>
            //     <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Teacher, Hillsborough High School (2011-Present)</li>
            //                 <li>Assistant Coach, Hillsborough HS Baseball (2012-Present)</li>
            //                 <li>Bartender at various Tampa Bay locations (24 years)</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">EDUCATION & PLAYING CAREER</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>St. Petersburg College, Bachelor’s Degree</li>
            //                 <li>Robinson High School - 3 year Baseball Letterman (1987-1989)</li>
            //             </ul>
            //         </div>
            //         <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Born, raised & resides in South Tampa, FL</li>
            //                 <li>Avid supporter of the Tampa Bay Bucs, Tampa Bay Lightning and Tampa Bay Rays</li>
            //                 <li>Favorite players growing up:  Chipper Jones & Lou Whitaker</li>
            //                 <li>Favorite Current Player:  Trea Turner</li>
            //             </ul>
            //         </div>
            //     `
            // },
            // {
            //     title: "Matt Logan ",
            //     position: "Event Operations, East Coast",
            //     email: "mlo@prospectwire.com",
            //     description: ""
            // },
            // {
            //     title: "Bennett Moger",
            //     position: "Graphic Design Specialist, Tournaments",
            //     email: "bmoger16@gmail.com",
            //     description: ""
            // },
            // {
            //     title: "Betsy Holl ",
            //     position: "Chief Financial Officer, Accounting",
            //     email: "betsy@dssports.com",
            //     description: ""
            // },
            // {
            //     title: "Daniel Perez ",
            //     position: "Event Scouting, East Coast",
            //     email: "daniel@prospectwire.com",
            //     description: `<p>Daniel started with Prospect Wire in the summer of 2016 as a scorekeeper for tournaments. With a high level of professionalism in everything he does, Perez quickly made himself valuable in multiple areas for the company. While serving primarily in a scouting capacity, Perez additionally served as a complex manager, assisting with day to day event operations. Before long, Perez was trusted with overseeing entire events in various locations. Daniel has a high level of patience, making him the perfect fit to help maintain the flow of a tournament or showcase from an on the ground standpoint.</p>
            //     <div class="top-title text-left"><p class="text-underline">PRIOR TO PW</p></div>
            //     <div class="title-list-type">
            //         <ul>
            //             <li>Pitching Coach, Hillsborough Community College (2019-Present)</li>
            //             <li>Volunteer Assistant, Emmanuel College (2018)</li>
            //             <li>Sports Information Assistant, Florida A&M University Baseball</li>
            //         </ul>
            //     </div>
            //     <div class="top-title text-left"><p class="text-underline">EDUCATION & PLAYING CAREER</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Florida State University, Masters in Sports Psychology</li>
            //                 <li>Georgia Southwestern State University, LHP</li>
            //                 <li>George M. Steinbrenner High School (Tampa, FL)</li>
            //             </ul>
            //         </div>
            //     <div class="top-title text-left"><p class="text-underline">PERSONAL INFO</p></div>
            //         <div class="title-list-type">
            //             <ul>
            //                 <li>Born & Raised in Tampa, FL</li>
            //                 <li>Currently resides in South Tampa, FL</li>
            //             </ul>
            //         </div>`
            // },

            {
                title: "Kristy Schmidt",
                position: "Accounting",
                email: "kristy@dssports.com",
                description: ""
            },
            {
                title: "Brett Andrzejewski",
                position: "National Showcase Director",
                email: "Brett@prospectwire.com",
                description: ""
            },
            {
                title: "Billy Colome",
                position: "National Crosschecker, East Coast",
                email: "Billy@prospectwire.com",
                description: ""
            },
            {
                title: "Dave Pankenier",
                position: "National Crosschecker, West Coast",
                email: "Dave@prospectwire.com",
                description: ""
            },
            {
                title: "Justin Varitek",
                position: "Coordinator, Business Development",
                email: "Justin@russmatt.com",
                description: ""
            },
            {
                title: "Steve Perkio",
                position: "Scouting Coordinator, Midwest",
                email: "Steve@prospectwire.com",
                description: ""
            },
            {
                title: "Bryce Twiggs",
                position: "DiamondConnect Account Manager",
                email: "Bryce@diamondconnect.com",
                description: ""
            },
        ],
        pws: [
            {
                title: "Scott Smith",
                position: "National Fastpitch Director",
                email: "",
                description: ""
            },
            {
                title: "David McCorkle",
                position: "National Director of Operations",
                email: "",
                description: ""
            },
            {
                title: "Bo Vinton",
                position: "National Director of Business Development",
                email: "",
                description: ""
            },
            {
                title: "Ryan Taylor",
                position: "Senior Director of Fastpitch (Midwest)",
                email: "",
                description: ""
            },
            {
                title: "Kristi Malpass",
                position: "Regional Tournament Director (Texas)",
                email: "",
                description: ""
            },
            {
                title: "Greg Dickell",
                position: "Regional Tournament Director (Midwest)",
                email: "",
                description: ""
            },
        ],
        gob: [
            {
                title: "Jennifer Reeves",
                position: "President",
                email: "jennifer@dssports.com",
                description: ""
            },
            {
                title: "Mike Jackson",
                position: "Site Director/Administrative Assistant",
                email: "mike@dssports.com",
                description: ""
            },
            {
                title: "James Arnett",
                position: "UIC/Site Director",
                email: "jamesarnett73@yahoo.com",
                description: ""
            },
        ],
    },
    showcase: {
        pwb: [
            {
                key: 5,
                image: imagesArr.showcase_3,
                title: "All American Games",
                description: `<p>Analytics, Data Solutions, Active Insights combined with the latest Ball Flight Technology, Traditional Scouting, and First Class type of events is the proper way to describe ProspectWire Showcases/Camps.</p>
                <p>ProspectWire Showcases/Camps have recently partnered with BaseballCloud, an ecosystem of performance data across all baseball levels. BaseballCloud creates value and solutions for data that are focused on player development and the player identification process. Getting yourself familiarized with their products will guarantee you success on the next level of your career.</p>
                <div class="title-list-type">
                    <h4>BaseballCloud comes with a wide range of products:</h4>
                    <ul>
                        <li><span class="text-larg">AnalyzR:</span> Cutting edge reporting tool.</li>
                        <li><span class="text-larg">BallR:</span> 3D Ball visualization software focusing on pitch release and spin metrics.</li>
                        <li><span class="text-larg">PitchR:</span> Virtual software to visualize all types of data collected on a custom 3D Field.</li>
                        <li><span class="text-larg">BCTeam:</span> Innovative Software dedicated to transforming Data into Leaderboards.</li>
                    </ul>
                </div>
                <p>We are also proud to have DiamondKinetics as one of our partners. In all of our events, you will have the opportunity to use their SwingTracker SwingSensor and the PitchTracker Smart Baseball. Whether you are a player, parent, or coach, you will find a wide range of resources to help you achieve your goals with these tools.</p>
                <p>You will also have the opportunity to have your recruiting video edited and produced by one of the industry leaders for this kind of material. We guarantee that with this video, your chances of getting seen and recruited will increase substantially.</p>
                <p>Finally, you will be PWCertified, which means that we will provide you with a certificate with all your metrics and data at the end of the event. And we will be blasting all of your plays through the ProspectWire Social Platforms.</p>
                <p>BaseballCloud is the only analytics software system created to allow amateur baseball players to access and interpret their data on a centralized platform. The explosion of data and advances in technology have created a demand for analytics like never before. Our cloud-based software allows players at all levels to access, consolidate, and truly understand their data through critical metrics and custom visualization tools. This software is focused on educating players, scouts, and coaches on how and why data is so impactful within baseball. BaseballCloud’s uniquely engineered system brings professional data to the amateur player.</p>
                <p>BaseballCloud’s new online marketplace will serve as baseball’s first-ever ‘App Store,’ featuring several products to choose from based on player and program needs. Upload your data one time, and immediately view it in one or all of these hubs.</p>
                <p><span class="text-larg">AnalyzR:</span>With these reports, you will be able to see how you performed in Bullpen, BP, and game settings, as well as how they stacked up to other players in their class that day. You will see how your metrics compare to the D1 level or how they look next to players at a particular school. If you want to see how your metrics fit into the schools you have targeted, we can also give you a clear idea. For us with these reports, the most important thing is to provide context, so if you see it for the first time, you leave knowing with a full sense of understanding how that data compares to others.</p>
                <img src="${imagesArr.S_Analyzer}">
                <p><span class="text-larg">BallR:</span>Use it to easily visually explain and analyze ball metrics with quick inputs in real-time. Upload your ball tracking data to unlock the full set of industry-leading features such as fingerprint location, design pitches, craft arsenals, track progress—all in 3D. From dashboards to 3D baseballs. BallR makes advanced concepts actionable and accessible for little league players to professional coaches, from video and fingerprints to predictive ball flight and result models.</p>
                <img src="${imagesArr.S_Ballr}">
                <p><span class="text-larg">PitchR:</span>Take complicated data sets from multiple devices and make them digestible, with an intuitive interface and entertaining graphics for coaches and players alike. Compare yourself to your past, your future, your opponent, or even major league players. Our game is played in 3D. It’s time we analyze like it. Where BallR tells us the story of how spin is created and what it looks like, PitchR shows us how that spin affects its behavior in flight.</p>
                <img src="${imagesArr.S_Pitchar}">
                <p><span class="text-larg">BCTeam:</span>BC Team converts team data into a user-friendly format with features such as player trending analysis, visualization tools, and player outlier identification. This state of the art software brings professionally engineered features and customized visuals to your fingertips.</p>
                <img src="${imagesArr.S_BcTeam}">
                <p>Our products are starting to gain a lot of ground on the media and momentum across College Baseball and Professional Baseball.</p>
                <p><div class="top-title text-left"><b class="text-underline">MLB Network:</b></div>Tunneling Effective Velocity. Click <a href="https://www.youtube.com/watch?v=H3g6ymJNofM" target="_blank">here</a> to watch the video.</p>
                <p><div class="top-title text-left"><b class="text-underline">Fox Business:</b></div>MLB Focuses on tech. Click <a href="https://www.foxbusiness.com/sports/mlb-continues-to-push-for-tech-advancements-amidst-changing-leadership" target="_blank">here</a> to read the article.</p>
                <p><div class="top-title text-left"><b class="text-underline">SportTechie:</b></div>The Future of Baseball Scouting: Every Day is Friday. Click <a href="https://www.sporttechie.com/mlb-baseball-scouting-technology-covid-19-pandemic-draft-prospects" target="_blank">here</a> to read the article.</p>
                <p><div class="top-title text-left"><b class="text-underline">SportTechie:</b></div>The Future of Baseball Scouting: Every Day is Friday. Click <a href="https://www.sporttechie.com/mlb-baseball-scouting-technology-covid-19-pandemic-draft-prospects" target="_blank">here</a> to read the article.</p>
                <h4><b>Don't miss your chance to get exposed to these products.</b></h4>
                <img src="${imagesArr.S_DK_Logo}">
                <p>The Diamond Kinetics suite of baseball apps enhances the learning and training experience for players of all skill levels, from youth to professional, and helps you take your training to the next level, whether it’s in the batter’s box or on the mound.</p>
                <p>SwingTracker the most precise and complete swing analysis and development tool available today. Their PitchTracker SmartBaseball revolutionizes the training experience with science­-based motion analytics and easy-to-understand metrics, data, and pitching analysis tools previously available only with expensive hardware systems.</p>
                <p>PW & DK Official Partnership Video <a href="https://twitter.com/diamondkinetics/status/1295875123216556034?s=21%C2%A0" target="_blank">here.</a></p>
                <img src="${imagesArr.S_DK_Product}">
                `
            },
            {
                key: 1,
                image: imagesArr.showcase_1,
                title: "Data-Driven Showcases/Camps",
                description: `<p>The Prospect Wire Data-Driven Showcases/Camps aims to be a leading showcase for players around the country, where you will have the opportunity to show your skills to several college coaches and pro scouts. We will also capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Showcases/Camps will allow you to expose yourself to the new technology trends around baseball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>College Coaches &amp; Pro Scouts in attendance</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and BaseballCloud followers.</p>`
            },
            {
                key: 3,
                image: imagesArr.showcase_2,
                title: "NextGen Showcases/Camps",
                description: `<p>The Prospect Wire NextGen Showcases/Camps aims to be a leading showcase for U13 & U14 players around the country. We will be identifying players for our All American Games, Regional World Series Games & Data games. This event will also be the perfect platform, so you can start getting a feeling of what a showcase is all about. Trust us; you will love it.</p>
                <p>We will capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Showcases/Camps will allow you to expose yourself to the new technology trends around baseball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event. There is no way you want to miss this event. </p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and BaseballCloud followers. </p>`
            },
            {
                key: 4,
                image: imagesArr.showcase_5,
                title: "Team Workouts",
                description: `<p>The Prospect Wire Data-Driven Team Workouts is designed to be a showcase for Schools, and Travel Ball organizations around the country, where your players will have the opportunity to show their skills. We will also capture every play they make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with baseball’s new metrics will give you an unparalleled experience and access to your player’s Data.</p>
                <p>The Team Workouts will allow you to expose your organization to the new technology trends around baseball since this is one of the most advanced and analytical events out there. We will send you a certificate with all of your players’ metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast through Social Media, giving you exposure to all of Prospect Wire and BaseballCloud followers.</p>`
            },
            {
                key: 2,
                image: imagesArr.showcase_4,
                title: "Data Games",
                description: `<p>The Prospect Wire Data Games aims to be a leading showcase for elite prospects around the country, where you will have the opportunity to show your skills to several college coaches and pro scouts. We will also capture every play you make throughout the event with Yakkertech, DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>In addition to what we just mentioned, we will be hosting a clinic the night before the event with coaches for every position that will share their knowledge and expertise with the players. For us in Prospect Wire, it is all about the feedback that players and parents can get from this experience, so we will make sure that everybody gets  their piece of knowledge that they can put into practice for the rest of their career. </p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>College Coaches &amp; Pro Scouts in attendance</li>
                        <li>7 to 9 innings of controlled games.</li>
                        <li>Pitchers throw one inning or five hitters. And a bullpen session of 20 pitches.</li>
                        <li>Hitters get 3 or 4 at-bats. And a BP Session of 2 rounds.</li>
                        <li>We are going to be hosting a Baseball Clinic the night before the event.&nbsp; All the players are invited to join us.&nbsp; We will be doing small workouts and drills where the coaches will be interacting with the players and the parents, providing feedback. Mindset, Analytics, Hitting, Fielding, Catching will be the topics that we will be discussing.</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and BaseballCloud followers.&nbsp;</p>`
            },
        ],
        pws: [
            {
                key: 1,
                image: imagesArr.pws_showcase_1,
                title: "Data-Driven Camps",
                description: `<p>The Prospect Wire Data-Driven Camps aims to be a leading showcase for players around the country, where you will have the opportunity to show your skills to several college coaches. We will also capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of SoftballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Camps will allow you to expose yourself to the new technology trends around softball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Camps. (click here, and it will take you to the explanation)</li>
                        <li>College Coaches and Professional Scouts in attendance</li>
                        <li>Grad Class Games for in game data capture</li>
                        <li>Analyzer Report</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and SoftballCloud followers.</p>`
            },
            {
                key: 2,
                image: imagesArr.pws_showcase_4,
                title: "Prospect Wire Data-Driven Identifier",
                description: `<p>The Prospect Wire Data-Driven Identifier aims to be a leading showcase for players around the country, where you will have the opportunity to show your skills to several college coaches and pro scouts. We will also capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of SoftballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Camps will allow you to expose yourself to the new technology trends around softball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Camps. (click here, and it will take you to the explanation)</li>
                        <li>College Coaches & Pro Scouts in attendance</li>
                        <li>Analyzer Report</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and SoftballCloud followers.</p>`
            },
            {
                key: 3,
                image: imagesArr.pws_showcase_2,
                title: "NextGen Camps",
                description: `<p>The Prospect Wire NextGen Camps aims to be a leading showcase for 14U players around the country. We will be identifying players for our All American Games, Regional World Series Games & Data games. This event will also be the perfect platform, so you can start getting a feeling of what a showcase is all about. Trust us; you will love it.</p>
                <p>We will capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of SoftballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Camps will allow you to expose yourself to the new technology trends around baseball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event. There is no way you want to miss this event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Camps. (click here, and it will take you to the explanation)</li>
                        <li>Grad Class Games</li>
                        <li>Analyzer Report</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and SoftballCloud followers.</p>`
            },
            {
                key: 4,
                image: imagesArr.pws_showcase_5,
                title: "Team Workouts",
                description: `<p>The Prospect Wire Data-Driven Team Workouts is designed to be a showcase for Schools, and Travel Ball organizations around the country, where your players will have the opportunity to show their skills. We will also capture every play they make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of SoftballCloud, digesting and generating concise feedback with baseball’s new metrics will give you an unparalleled experience and access to your player’s Data.</p>
                <p>The Team Workouts will allow you to expose your organization to the new technology trends around softball since this is one of the most advanced and analytical events out there. We will send you a certificate with all of your players’ metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Camps. (click here, and it will take you to the explanation)</li>
                        <li>Analyzer Report</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast through Social Media, giving you exposure to all of Prospect Wire and SoftballCloud followers.</p>`
            },
            {
                key: 5,
                image: imagesArr.pws_showcase_3,
                title: "All American Games",
                description: `<p>All American Games Coming 2022</p>`
            },
        ],
        pioneer: [
            {
                key: 5,
                image: imagesArr.showcase_3,
                title: "All American Games",
                description: `<p>Analytics, Data Solutions, Active Insights combined with the latest Ball Flight Technology, Traditional Scouting, and First Class type of events is the proper way to describe ProspectWire Showcases/Camps.</p>
                <p>ProspectWire Showcases/Camps have recently partnered with BaseballCloud, an ecosystem of performance data across all baseball levels. BaseballCloud creates value and solutions for data that are focused on player development and the player identification process. Getting yourself familiarized with their products will guarantee you success on the next level of your career.</p>
                <div class="title-list-type">
                    <h4>BaseballCloud comes with a wide range of products:</h4>
                    <ul>
                        <li><span class="text-larg">AnalyzR:</span> Cutting edge reporting tool.</li>
                        <li><span class="text-larg">BallR:</span> 3D Ball visualization software focusing on pitch release and spin metrics.</li>
                        <li><span class="text-larg">PitchR:</span> Virtual software to visualize all types of data collected on a custom 3D Field.</li>
                        <li><span class="text-larg">BCTeam:</span> Innovative Software dedicated to transforming Data into Leaderboards.</li>
                    </ul>
                </div>
                <p>We are also proud to have DiamondKinetics as one of our partners. In all of our events, you will have the opportunity to use their SwingTracker SwingSensor and the PitchTracker Smart Baseball. Whether you are a player, parent, or coach, you will find a wide range of resources to help you achieve your goals with these tools.</p>
                <p>You will also have the opportunity to have your recruiting video edited and produced by one of the industry leaders for this kind of material. We guarantee that with this video, your chances of getting seen and recruited will increase substantially.</p>
                <p>Finally, you will be PWCertified, which means that we will provide you with a certificate with all your metrics and data at the end of the event. And we will be blasting all of your plays through the ProspectWire Social Platforms.</p>
                <p>BaseballCloud is the only analytics software system created to allow amateur baseball players to access and interpret their data on a centralized platform. The explosion of data and advances in technology have created a demand for analytics like never before. Our cloud-based software allows players at all levels to access, consolidate, and truly understand their data through critical metrics and custom visualization tools. This software is focused on educating players, scouts, and coaches on how and why data is so impactful within baseball. BaseballCloud’s uniquely engineered system brings professional data to the amateur player.</p>
                <p>BaseballCloud’s new online marketplace will serve as baseball’s first-ever ‘App Store,’ featuring several products to choose from based on player and program needs. Upload your data one time, and immediately view it in one or all of these hubs.</p>
                <p><span class="text-larg">AnalyzR:</span>With these reports, you will be able to see how you performed in Bullpen, BP, and game settings, as well as how they stacked up to other players in their class that day. You will see how your metrics compare to the D1 level or how they look next to players at a particular school. If you want to see how your metrics fit into the schools you have targeted, we can also give you a clear idea. For us with these reports, the most important thing is to provide context, so if you see it for the first time, you leave knowing with a full sense of understanding how that data compares to others.</p>
                <img src="${imagesArr.S_Analyzer}">
                <p><span class="text-larg">BallR:</span>Use it to easily visually explain and analyze ball metrics with quick inputs in real-time. Upload your ball tracking data to unlock the full set of industry-leading features such as fingerprint location, design pitches, craft arsenals, track progress—all in 3D. From dashboards to 3D baseballs. BallR makes advanced concepts actionable and accessible for little league players to professional coaches, from video and fingerprints to predictive ball flight and result models.</p>
                <img src="${imagesArr.S_Ballr}">
                <p><span class="text-larg">PitchR:</span>Take complicated data sets from multiple devices and make them digestible, with an intuitive interface and entertaining graphics for coaches and players alike. Compare yourself to your past, your future, your opponent, or even major league players. Our game is played in 3D. It’s time we analyze like it. Where BallR tells us the story of how spin is created and what it looks like, PitchR shows us how that spin affects its behavior in flight.</p>
                <img src="${imagesArr.S_Pitchar}">
                <p><span class="text-larg">BCTeam:</span>BC Team converts team data into a user-friendly format with features such as player trending analysis, visualization tools, and player outlier identification. This state of the art software brings professionally engineered features and customized visuals to your fingertips.</p>
                <img src="${imagesArr.S_BcTeam}">
                <p>Our products are starting to gain a lot of ground on the media and momentum across College Baseball and Professional Baseball.</p>
                <p><div class="top-title text-left"><b class="text-underline">MLB Network:</b></div>Tunneling Effective Velocity. Click <a href="https://www.youtube.com/watch?v=H3g6ymJNofM" target="_blank">here</a> to watch the video.</p>
                <p><div class="top-title text-left"><b class="text-underline">Fox Business:</b></div>MLB Focuses on tech. Click <a href="https://www.foxbusiness.com/sports/mlb-continues-to-push-for-tech-advancements-amidst-changing-leadership" target="_blank">here</a> to read the article.</p>
                <p><div class="top-title text-left"><b class="text-underline">SportTechie:</b></div>The Future of Baseball Scouting: Every Day is Friday. Click <a href="https://www.sporttechie.com/mlb-baseball-scouting-technology-covid-19-pandemic-draft-prospects" target="_blank">here</a> to read the article.</p>
                <p><div class="top-title text-left"><b class="text-underline">SportTechie:</b></div>The Future of Baseball Scouting: Every Day is Friday. Click <a href="https://www.sporttechie.com/mlb-baseball-scouting-technology-covid-19-pandemic-draft-prospects" target="_blank">here</a> to read the article.</p>
                <h4><b>Don't miss your chance to get exposed to these products.</b></h4>
                <img src="${imagesArr.S_DK_Logo}">
                <p>The Diamond Kinetics suite of baseball apps enhances the learning and training experience for players of all skill levels, from youth to professional, and helps you take your training to the next level, whether it’s in the batter’s box or on the mound.</p>
                <p>SwingTracker the most precise and complete swing analysis and development tool available today. Their PitchTracker SmartBaseball revolutionizes the training experience with science­-based motion analytics and easy-to-understand metrics, data, and pitching analysis tools previously available only with expensive hardware systems.</p>
                <p>PW & DK Official Partnership Video <a href="https://twitter.com/diamondkinetics/status/1295875123216556034?s=21%C2%A0" target="_blank">here.</a></p>
                <img src="${imagesArr.S_DK_Product}">
                `
            },
            {
                key: 1,
                image: imagesArr.showcase_1,
                title: "Data-Driven Showcases/Camps",
                description: `<p>The Prospect Wire Data-Driven Showcases/Camps aims to be a leading showcase for players around the country, where you will have the opportunity to show your skills to several college coaches and pro scouts. We will also capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Showcases/Camps will allow you to expose yourself to the new technology trends around baseball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>College Coaches &amp; Pro Scouts in attendance</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and BaseballCloud followers.</p>`
            },
            {
                key: 3,
                image: imagesArr.showcase_2,
                title: "NextGen Showcases/Camps",
                description: `<p>The Prospect Wire NextGen Showcases/Camps aims to be a leading showcase for U13 & U14 players around the country. We will be identifying players for our All American Games, Regional World Series Games & Data games. This event will also be the perfect platform, so you can start getting a feeling of what a showcase is all about. Trust us; you will love it.</p>
                <p>We will capture every play you make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>The Data-Driven Showcases/Camps will allow you to expose yourself to the new technology trends around baseball since this is one the most advanced and analytical events out there. We will send you a certificate with all of your metrics after the event. There is no way you want to miss this event. </p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and BaseballCloud followers. </p>`
            },
            {
                key: 4,
                image: imagesArr.showcase_5,
                title: "Team Workouts",
                description: `<p>The Prospect Wire Data-Driven Team Workouts is designed to be a showcase for Schools, and Travel Ball organizations around the country, where your players will have the opportunity to show their skills. We will also capture every play they make throughout the event with DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with baseball’s new metrics will give you an unparalleled experience and access to your player’s Data.</p>
                <p>The Team Workouts will allow you to expose your organization to the new technology trends around baseball since this is one of the most advanced and analytical events out there. We will send you a certificate with all of your players’ metrics after the event.</p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast through Social Media, giving you exposure to all of Prospect Wire and BaseballCloud followers.</p>`
            },
            {
                key: 2,
                image: imagesArr.showcase_4,
                title: "Data Games",
                description: `<p>The Prospect Wire Data Games aims to be a leading showcase for elite prospects around the country, where you will have the opportunity to show your skills to several college coaches and pro scouts. We will also capture every play you make throughout the event with Yakkertech, DiamondKinetics, and the New Generation of Stalker Pro Radar Guns. But that is not all; with the expertise of BaseballCloud, digesting and generating concise feedback with your metrics will give you an unparalleled experience and access to your Data.</p>
                <p>In addition to what we just mentioned, we will be hosting a clinic the night before the event with coaches for every position that will share their knowledge and expertise with the players. For us in Prospect Wire, it is all about the feedback that players and parents can get from this experience, so we will make sure that everybody gets  their piece of knowledge that they can put into practice for the rest of their career. </p>
                <div class="top-title text-left"><p class="text-underline">General Information:</p></div>
                <div class="title-list-type">
                    <ul>
                        <li>Pro-Style Workout Showcases/Camps. (click here, and it will take you to the explanation)</li>
                        <li>College Coaches &amp; Pro Scouts in attendance</li>
                        <li>7 to 9 innings of controlled games.</li>
                        <li>Pitchers throw one inning or five hitters. And a bullpen session of 20 pitches.</li>
                        <li>Hitters get 3 or 4 at-bats. And a BP Session of 2 rounds.</li>
                        <li>We are going to be hosting a Baseball Clinic the night before the event.&nbsp; All the players are invited to join us.&nbsp; We will be doing small workouts and drills where the coaches will be interacting with the players and the parents, providing feedback. Mindset, Analytics, Hitting, Fielding, Catching will be the topics that we will be discussing.</li>
                        <li>Add Ons. (click here, and it will take you to the add-ons)</li>
                    </ul>
                </div>
                <p>We will use all of our technological capabilities to track every event that happens in the game. The best part? The best highlights will be blast thru Social Media, which will give you exposure to all of Prospect Wire and BaseballCloud followers.&nbsp;</p>`
            },
        ]
    }

}

export const mainBoardFilter = {
    1: [
        "Speed",
        "Agility",
        "Lower Body",
        "Upper Body",
        "Positional",
        "Base Running",
        "Pitching"
    ],
    2: [
        "Pitching",
        "Hitting",
        "Positional",
    ],
    3: [
        "Pitching",
        "Hitting"
    ],
}

export const advacedSearchColumn = {
    performance: [
        {
            label: "10 Yard Dash",
            name: "10YardDash",
        },
        {
            label: "60 Yard Dash",
            name: "60YardDash",
        },
        {
            label: "5-10-5",
            name: "5-10-5",
        },
        {
            label: "Broad Jump",
            name: "broadJump",
        },
        {
            label: "Vertical Jump",
            name: "verticalJump",
        },
        {
            label: "Chin Ups",
            name: "chinUps",
        },
        {
            label: "Pull Ups",
            name: "pullUps",
        },
        {
            label: "Sit Ups",
            name: "sitUps",
        },
        {
            label: "Push Ups",
            name: "pushUps",
        },
        {
            label: "Plank Test",
            name: "plankTest",
        },
        {
            label: "Hand Grip Strength",
            name: "handGripStrenght",
        },
        {
            label: "Catcher Pop Time",
            name: "catcherPopTime",
        },
        {
            label: "Home to 1st",
            name: "homeToFirst",
        },
        {
            label: "Home to 2nd",
            name: "homeToSecond",
        },
        {
            label: "Home to Home",
            name: "homeToHome",
        },
        {
            label: "Bullpen Strike %",
            name: "bullpenStrikePercentage",
        },
        {
            label: "Outfield Velocity",
            name: "outfieldVelocity",
        },
        {
            label: "Infield Velocity",
            name: "infieldvelocity",
        },
        {
            label: "Catcher Velocity",
            name: "catcherVelocity",
        },
        {
            label: "Catcher Pop Time(2B)",
            name: "catcherPopTime2b",
        },
        {
            label: "Catcher Pop Time(3B)",
            name: "catcherPopTime3b",
        },
        {
            label: "Exit Velo.",
            name: "exitVelocity",
        },
        {
            label: "Fastball Velocity",
            name: "fastBallVelocity",
        },
        {
            label: "Curveball Velocity",
            name: "curveBallVelocity",
        },
        {
            label: "Slider Velocity",
            name: "sliderVelocity",
        },
        {
            label: "Changeup Velocity",
            name: "changeUpVelocity",
        },
        {
            label: "Fastball Spin Rate",
            name: "fastBallSpinRate",
        },
        {
            label: "Curveball Spin Rate",
            name: "curveBallSpinRate",
        },
        {
            label: "Slider Spin Rate",
            name: "sliderSpinRate",
        },
        {
            label: "Changeup Spin Rate",
            name: "changeUpSpinRate",
        },
        {
            label: "Bench Press",
            name: "benchPress",
        },
        {
            label: "Incline Press",
            name: "inclinePress",
        },
        {
            label: "Back Squat",
            name: "backSquat",
        },
        {
            label: "Front Squat",
            name: "frontSquat",
        },
        {
            label: "Deadlift",
            name: "deadlift",
        },
        {
            label: "Hang Clean",
            name: "hangClean",
        },
        {
            label: "Power Clean",
            name: "powerClean",
        },
        {
            label: "81 Feet",
            name: "81Feet",
        }
    ],
    pitching: [
        {
            label: "Max Velocity",
            name: "maxVelocity",
        },
        {
            label: "Avg. Velocity",
            name: "avgVelocity",
        },
        {
            label: "Max Spin Rate",
            name: "maxSpinRate",
        },
        {
            label: "Avg. Spin Rate",
            name: "avgSpinRate",
        },
        {
            label: "Spin Efficiency",
            name: "spinEfficiency",
        },
        {
            label: "Avg. Tilt (Spin Direction)",
            name: "avgTilt",
        },
        {
            label: "ERA",
            name: "ERA",
        },
        {
            label: "Strikeouts (K)",
            name: "K",
        },
        {
            label: "WHIP",
            name: "WHIP",
        },
        {
            label: "Saves",
            name: "saves",
        },
        {
            label: "GP",
            name: "GP",
        },
        {
            label: "Walks (BB)",
            name: "BB",
        },
        {
            label: "GS",
            name: "GS",
        },
        {
            label: "IP",
            name: "IP",
        }
    ],
    hitting: [
        {
            label: "Max Exit Velocity",
            name: "maxExitVelocity",
        },
        {
            label: "Avg. Exit Velocity",
            name: "avgExitVelocity",
        },
        {
            label: "Avg. Launch Angle",
            name: "avgLaunchAngle",
        },
        {
            label: "Max Distance",
            name: "maxDistance",
        },
        {
            label: "Bat Speed",
            name: "batSpeed",
        },
        {
            label: "Attack Angle",
            name: "attackAngle",
        },
        {
            label: "Runs Scored (R)",
            name: "R",
        },
        {
            label: "Runs Batted In (RBI)",
            name: "RBI",
        },
        {
            label: "Stolen Bases (SB)",
            name: "SB",
        },
        {
            label: "OBP",
            name: "OBP",
        },
        {
            label: "HR",
            name: "HR",
        },
        {
            label: "1B",
            name: "1B",
        },
        {
            label: "2B",
            name: "2B",
        },
        {
            label: "BA/AVG",
            name: "BA",
        },
        {
            label: "GP",
            name: "GP",
        },
        {
            label: "AB",
            name: "AB",
        },
        {
            label: "OPS",
            name: "OPS",
        }
    ],
    playerInfo: [
        {
            name: "Picture",
            column_name: "Picture",
        },
        {
            name: "Name",
            column_name: "Name",
        },
        {
            name: "Position",
            column_name: "Position",
        },
        // {
        //     name: "Number",
        //     column_name: "Number",
        // },
        {
            name: "Height",
            column_name: "Height",
        },
        {
            name: "Weight",
            column_name: "Weight",
        },
        {
            name: "Grad Year",
            column_name: "Grad Year",
        },
        {
            name: "Team",
            column_name: "Team",
        },
        {
            name: "GPA",
            column_name: "GPA",
        }
    ]
}

export const getMaxOfKeys = (values, keys) => {
    return Math.max.apply(Math, values.map(function (obj) {
        return Math.max.apply(Math, keys.map(function (key) {
            return obj[key];
        }));
    }));
}

export const getMinOfKeys = (values, keys) => {
    return Math.min.apply(Math, values.map(function (obj) {
        return Math.min.apply(Math, keys.map(function (key) {
            return obj[key];
        }));
    }));
}

export const getSumOfRegisterTeam = (feesByGroups) => {
    let totalTeam = feesByGroups.reduce(function (sum, current) {
        return sum + current.teamReg;
    }, 0);
    return totalTeam;
}

export const getAvailableSport = (feesByGroups) => {
    let totalRegiTeam = feesByGroups.reduce(function (sum, current) {
        return sum + current.teamReg;
    }, 0);

    let totalTeam = feesByGroups.reduce(function (sum, current) {
        return sum + current.teamLimit;
    }, 0);
    return (totalTeam - totalRegiTeam);
}

export const StatesObj = {
    AB: { state: 'Alberta', stateRegion: 'AB', isUpcoming: false },
    AK: { state: 'Alaska', stateRegion: 'AK', isUpcoming: false },
    AL: { state: 'Alabama', stateRegion: 'AL', isUpcoming: false },
    AR: { state: 'Arkansas', stateRegion: 'AR', isUpcoming: false },
    AS: { state: 'American Samoa', stateRegion: 'AS', isUpcoming: false },
    AZ: { state: 'Arizona', stateRegion: 'AZ', isUpcoming: false },
    BC: { state: 'British Columbia', stateRegion: 'BC', isUpcoming: false },
    CA: { state: 'California', stateRegion: 'CA', isUpcoming: false },
    CO: { state: 'Colorado', stateRegion: 'CO', isUpcoming: false },
    CT: { state: 'Connecticut', stateRegion: 'CT', isUpcoming: false },
    DC: { state: 'District Of Columbia', stateRegion: 'DC', isUpcoming: false },
    DE: { state: 'Delaware', stateRegion: 'DE', isUpcoming: false },
    FL: { state: 'Florida', stateRegion: 'FL', isUpcoming: false },
    FM: { state: 'Federated States Of Micronesia', stateRegion: 'FM', isUpcoming: false },
    GA: { state: 'Georgia', stateRegion: 'GA', isUpcoming: false },
    GU: { state: 'Guam', stateRegion: 'GU', isUpcoming: false },
    HI: { state: 'Hawaii', stateRegion: 'HI', isUpcoming: false },
    IA: { state: 'Iowa', stateRegion: 'IA', isUpcoming: false },
    ID: { state: 'Idaho', stateRegion: 'ID', isUpcoming: false },
    IL: { state: 'Illinois', stateRegion: 'IL', isUpcoming: false },
    IN: { state: 'Indiana', stateRegion: 'IN', isUpcoming: false },
    KS: { state: 'Kansas', stateRegion: 'KS', isUpcoming: false },
    KY: { state: 'Kentucky', stateRegion: 'KY', isUpcoming: false },
    LA: { state: 'Louisiana', stateRegion: 'LA', isUpcoming: false },
    MA: { state: 'Massachusetts', stateRegion: 'MA', isUpcoming: false },
    MB: { state: 'Manitoba', stateRegion: 'MB', isUpcoming: false },
    MD: { state: 'Maryland', stateRegion: 'MD', isUpcoming: false },
    ME: { state: 'Maine', stateRegion: 'ME', isUpcoming: false },
    MH: { state: 'Marshall Islands', stateRegion: 'MH', isUpcoming: false },
    MI: { state: 'Michigan', stateRegion: 'MI', isUpcoming: false },
    MN: { state: 'Minnesota', stateRegion: 'MN', isUpcoming: false },
    MO: { state: 'Missouri', stateRegion: 'MO', isUpcoming: false },
    MP: { state: 'Northern Mariana Islands', stateRegion: 'MP', isUpcoming: false },
    MS: { state: 'Mississippi', stateRegion: 'MS', isUpcoming: false },
    MT: { state: 'Montana', stateRegion: 'MT', isUpcoming: false },
    NB: { state: 'New Brunswick', stateRegion: 'NB', isUpcoming: false },
    NC: { state: 'North Carolina', stateRegion: 'NC', isUpcoming: false },
    ND: { state: 'North Dakota', stateRegion: 'ND', isUpcoming: false },
    NE: { state: 'Nebraska', stateRegion: 'NE', isUpcoming: false },
    NH: { state: 'New Hampshire', stateRegion: 'NH', isUpcoming: false },
    NJ: { state: 'New Jersey', stateRegion: 'NJ', isUpcoming: false },
    NL: { state: 'Newfoundland', stateRegion: 'NL', isUpcoming: false },
    NM: { state: 'New Mexico', stateRegion: 'NM', isUpcoming: false },
    NS: { state: 'Nova Scotia', stateRegion: 'NS', isUpcoming: false },
    NT: { state: 'Northwest Territories', stateRegion: 'NT', isUpcoming: false },
    NU: { state: 'Nunavut', stateRegion: 'NU', isUpcoming: false },
    NV: { state: 'Nevada', stateRegion: 'NV', isUpcoming: false },
    NY: { state: 'New York', stateRegion: 'NY', isUpcoming: false },
    OH: { state: 'Ohio', stateRegion: 'OH', isUpcoming: false },
    OK: { state: 'Oklahoma', stateRegion: 'OK', isUpcoming: false },
    ON: { state: 'Ontario', stateRegion: 'ON', isUpcoming: false },
    OR: { state: 'Oregon', stateRegion: 'OR', isUpcoming: false },
    PA: { state: 'Pennsylvania', stateRegion: 'PA', isUpcoming: false },
    PE: { state: 'Prince Edward Island', stateRegion: 'PE', isUpcoming: false },
    PR: { state: 'Puerto Rico', stateRegion: 'PR', isUpcoming: false },
    PW: { state: 'Palau', stateRegion: 'PW', isUpcoming: false },
    QC: { state: 'Quebec', stateRegion: 'QC', isUpcoming: false },
    RI: { state: 'Rhode Island', stateRegion: 'RI', isUpcoming: false },
    SC: { state: 'South Carolina', stateRegion: 'SC', isUpcoming: false },
    SD: { state: 'South Dakota', stateRegion: 'SD', isUpcoming: false },
    SK: { state: 'Saskatchewan', stateRegion: 'SK', isUpcoming: false },
    TN: { state: 'Tennessee', stateRegion: 'TN', isUpcoming: false },
    TX: { state: 'Texas', stateRegion: 'TX', isUpcoming: false },
    UT: { state: 'Utah', stateRegion: 'UT', isUpcoming: false },
    VA: { state: 'Virginia', stateRegion: 'VA', isUpcoming: false },
    VI: { state: 'Virgin Islands', stateRegion: 'VI', isUpcoming: false },
    VT: { state: 'Vermont', stateRegion: 'VT', isUpcoming: false },
    WA: { state: 'Washington', stateRegion: 'WA', isUpcoming: false },
    WI: { state: 'Wisconsin', stateRegion: 'WI', isUpcoming: false },
    WV: { state: 'West Virginia', stateRegion: 'WV', isUpcoming: false },
    WY: { state: 'Wyoming', stateRegion: 'WY', isUpcoming: false },
    YT: { state: 'Yukon Territory', stateRegion: 'YT', isUpcoming: false },
}

import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import Select, { components } from 'react-select';
import { getAgeDivData } from '../../saga/actions/common/slider.action';
import commonCalls from "../../businesslogic/commonCalls";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '4em',
  color: '$gray-btn',
  display: 'inline-block',
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: '1',
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};
const customStyles = {
  control: styles => ({ ...styles, backgroundColor: '#F8F6F7' }),
}
const DropdownIndicator = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={imagesArr.black_slight_search} />
    </components.DropdownIndicator>
  );
};
class EventFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dropdownData: [],
    };
    this.commonCall = new commonCalls();
  }

  componentDidMount = () => {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sliderInfo &&
      nextProps.sliderInfo.getAgeDivReq &&
      nextProps.sliderInfo.getAgeDivData
    ) {
      nextProps.sliderInfo.getAgeDivReq = false;
      console.log("nextProps.getAgeDivData:--", nextProps.sliderInfo.getAgeDivData);
      if (nextProps.sliderInfo.getAgeDivData.flag) {
        let result = nextProps.sliderInfo.getAgeDivData.result;
        this.setState({
          dropdownData: result,
        }, () => {
          nextProps.sliderInfo.getAgeDivData = null;
          this.props.decrement();
        })
      } else {
        this.commonCall.errorMessage(nextProps.sliderInfo.getAgeDivData.message);
        nextProps.sliderInfo.getAgeDivData = null;
        this.props.decrement();
      }
    }
  }

  formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  setOption = (val) => {
    console.log("val:--", val);
    this.props.onOptionChange(val.value);
    this.props.increment(1);
    this.props.getAgeDivData({ eventId: val.value })
  }

  render() {
    const { flag, eventList, divList, sendReq } = this.props;
    const { dropdownData } = this.state;
    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog filter-popup" style={{ maxWidth: "592px" }}>
            <div className="modal-content">
              <div className="modal-header justify-content-end">

                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.gray_modal_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body">
                <div className="filter-popup-info">
                  <form>
                    <div className="form-group">
                      <label className="common-lable">Event</label>
                      <Select
                        // defaultValue={list.bc_user_id ? { value: list.bc_user_id._id, label: list.bc_user_id.fname + " " + list.bc_user_id.lname } : ""}
                        options={eventList}
                        formatGroupLabel={this.formatGroupLabel}
                        onChange={(e) => this.setOption(e)}
                        menuPlacement="auto"
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="common-lable">Age Group</label>
                      <select name="ageGroup" value={sendReq.ageGroup} onChange={(e) => this.props.onChange(e)} className="custom-select">
                        <option value="" selected disabled>Select Age Group</option>
                        {
                          dropdownData &&
                          dropdownData.ageGroupList &&
                          dropdownData.ageGroupList.length !== 0 &&
                          dropdownData.ageGroupList.map((age) => {
                            return (
                              <option value={age.age_group} selected={sendReq.ageGroup === age.age_group ? "selected" : ""}>{age.age_group}U</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="common-lable">Division</label>
                      <select name="Division" value={sendReq.Division} onChange={(e) => this.props.onChange(e)} className="custom-select">
                        <option value="" selected disabled>Select Division</option>
                        {
                          dropdownData &&
                          dropdownData.divisionList &&
                          dropdownData.divisionList.length !== 0 &&
                          dropdownData.divisionList.map((div) => {
                            return (
                              <option value={div._id} selected={sendReq.Division === div._id ? "selected" : ""}>{div.title}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </form>
                </div>
                <div className="flex-align next-button">
                  <Link className="btn light-green" onClick={() => this.props.onFilter()}>
                    Filter
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    );
  }
}

EventFilter.propTypes = {
  getAgeDivData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sliderInfo: state.sliderInfo,
});

export default withRouter(connect(mapStateToProps, { getAgeDivData })(EventFilter));

// export default EventFilter;

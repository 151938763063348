export const COM_RECAP_INFO_REQUEST = 'COM_RECAP_INFO_REQUEST';
export const COM_RECAP_INFO_SUCCESS = 'COM_RECAP_INFO_SUCCESS';
export const COM_RECAP_INFO_ERROR   = 'COM_RECAP_INFO_ERROR';

export const COM_RECAP_DIVISION_REQUEST = 'COM_RECAP_DIVISION_REQUEST';
export const COM_RECAP_DIVISION_SUCCESS = 'COM_RECAP_DIVISION_SUCCESS';
export const COM_RECAP_DIVISION_ERROR   = 'COM_RECAP_DIVISION_ERROR';

export const COM_RECAP_DESCRIPTION_REQUEST = 'COM_RECAP_DESCRIPTION_REQUEST';
export const COM_RECAP_DESCRIPTION_SUCCESS = 'COM_RECAP_DESCRIPTION_SUCCESS';
export const COM_RECAP_DESCRIPTION_ERROR   = 'COM_RECAP_DESCRIPTION_ERROR';

export const COM_RECAP_AGE_GROUP_REQUEST = 'COM_RECAP_AGE_GROUP_REQUEST';
export const COM_RECAP_AGE_GROUP_SUCCESS = 'COM_RECAP_AGE_GROUP_SUCCESS';
export const COM_RECAP_AGE_GROUP_ERROR   = 'COM_RECAP_AGE_GROUP_ERROR';

export const COM_RECAP_INFORMATION_REQUEST = 'COM_RECAP_INFORMATION_REQUEST';
export const COM_RECAP_INFORMATION_SUCCESS = 'COM_RECAP_INFORMATION_SUCCESS';
export const COM_RECAP_INFORMATION_ERROR   = 'COM_RECAP_INFORMATION_ERROR';

export const GET_RECAP_FILTER_DATA_REQUEST = 'GET_RECAP_FILTER_DATA_REQUEST';
export const GET_RECAP_FILTER_DATA_SUCCESS = 'GET_RECAP_FILTER_DATA_SUCCESS';
export const GET_RECAP_FILTER_DATA_ERROR   = 'GET_RECAP_FILTER_DATA_ERROR';

export const GET_RECAP_SHOWCASE_REQUEST = 'GET_RECAP_SHOWCASE_REQUEST';
export const GET_RECAP_SHOWCASE_SUCCESS = 'GET_RECAP_SHOWCASE_SUCCESS';
export const GET_RECAP_SHOWCASE_ERROR   = 'GET_RECAP_SHOWCASE_ERROR';

export const COM_ALL_RECAP_DESCRIPTION_REQUEST = 'COM_ALL_RECAP_DESCRIPTION_REQUEST';
export const COM_ALL_RECAP_DESCRIPTION_SUCCESS = 'COM_ALL_RECAP_DESCRIPTION_SUCCESS';
export const COM_ALL_RECAP_DESCRIPTION_ERROR   = 'COM_ALL_RECAP_DESCRIPTION_ERROR';

export const COM_STATS_LEADER_HITTING_REQUEST = 'COM_STATS_LEADER_HITTING_REQUEST';
export const COM_STATS_LEADER_HITTING_SUCCESS = 'COM_STATS_LEADER_HITTING_SUCCESS';
export const COM_STATS_LEADER_HITTING_ERROR   = 'COM_STATS_LEADER_HITTING_ERROR';

export const COM_STATS_LEADER_PITCHING_REQUEST = 'COM_STATS_LEADER_PITCHING_REQUEST';
export const COM_STATS_LEADER_PITCHING_SUCCESS = 'COM_STATS_LEADER_PITCHING_SUCCESS';
export const COM_STATS_LEADER_PITCHING_ERROR   = 'COM_STATS_LEADER_PITCHING_ERROR';
import {
    GET_PLAYER_LIST_SUCCESS,
    GET_PLAYER_LIST_ERROR,
    
    AGE_TEAM_LIST_SUCCESS,
    AGE_TEAM_LIST_ERROR,
    
    GET_PLAYER_INFO_SUCCESS,
    GET_PLAYER_INFO_ERROR,
    
    POST_PLAYER_INFO_SUCCESS,
    POST_PLAYER_INFO_ERROR,
    
    EDIT_PLAYER_INFO_SUCCESS,
    EDIT_PLAYER_INFO_ERROR
    } from '../../constants/common/accountCreation.const';
    
    const initialState = {
        playerList: null,
        playerListError: null,
    
        ageTeamList: null,
        ageTeamListError: null,
    
        getPlayerInfo: null,
        getPlayerInfoError: null,
    
        postPlayerInfo: null,
        postPlayerInfoError: null,
        
        editPlayerInfo: null,
        editPlayerInfoError: null
    
    };
    
    export default function (state = initialState, action) {
        switch (action.type) {
    
            // player List
            case GET_PLAYER_LIST_SUCCESS:
                return {
                    ...state,
                    playerList: action.payload,
                };
    
            case GET_PLAYER_LIST_ERROR:
                return {
                    ...state,
                    playerListError: action.error,
                };
    
            // age wise team list
            case AGE_TEAM_LIST_SUCCESS:
                return {
                    ...state,
                    ageTeamList: action.payload,
                };
    
            case AGE_TEAM_LIST_ERROR:
                return {
                    ...state,
                    ageTeamListError: action.error,
                };
    
    
            // get player info for claim
            case GET_PLAYER_INFO_SUCCESS:
                return {
                    ...state,
                    getPlayerInfo: action.payload,
                };
    
            case GET_PLAYER_INFO_ERROR:
                return {
                    ...state,
                    getPlayerInfoError: action.error,
                };
    
            // add player info for claim
            case POST_PLAYER_INFO_SUCCESS:
                return {
                    ...state,
                    postPlayerInfo: action.payload,
                };
    
            case POST_PLAYER_INFO_ERROR:
                return {
                    ...state,
                    postPlayerInfoError: action.error,
                };
    
           // edit player info for claim
           case EDIT_PLAYER_INFO_SUCCESS:
            return {
                ...state,
                editPlayerInfo: action.payload,
            };
    
        case EDIT_PLAYER_INFO_ERROR:
            return {
                ...state,
                editPlayerInfoError: action.error,
            };
    
            default:
                return state;
        }
    }
    

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const USER_CRED_REQUEST = 'USER_CRED_REQUEST';
export const USER_CRED_SUCCESS = 'USER_CRED_SUCCESS';
export const USER_CRED_ERROR = 'USER_CRED_ERROR';

export const INSTA_REQUEST = 'INSTA_REQUEST';
export const INSTA_SUCCESS = 'INSTA_SUCCESS';
export const INSTA_ERROR = 'INSTA_ERROR';

export const NOTIFICATION_LOGIN_REQUEST = 'NOTIFICATION_LOGIN_REQUEST';
export const NOTIFICATION_LOGIN_SUCCESS = 'NOTIFICATION_LOGIN_SUCCESS';
export const NOTIFICATION_LOGIN_ERROR = 'NOTIFICATION_LOGIN_ERROR';

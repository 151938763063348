import React, { Component } from 'react'

export default class redirect extends Component {
    componentDidMount = () => {
        this.props.history.push('/');
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

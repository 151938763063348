import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import CommonHeroBanner from "../../components/commonHeroBanner";
import { imagesArr } from "../../assets/images";
import AddNewCard from "../../views/popup/addNewCard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchPaymentInfo,
  fetchBillingInfo,
  deleteCard,
  checkType,
  makeDefaultCard
} from "../../saga/actions/common/billing.action";
import Preloader from "../../components/preloader";
import commonCalls from "../../businesslogic/commonCalls";
import moment from "moment";
import ErrorMessage from "../../views/popup/errorMessage";

class coachBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentList: [],
      billingList: [],
      loader: false,
      addCardPopUp: false,
      confirmPopUp: false,
      filterBill: {
        page: 0,
        limit: 0,
      },
      deleteInfo: "",
      removeId: "",
      delMsgData: ""
    };
    this.commonCall = new commonCalls();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setLoader(true);
    this.props.fetchPaymentInfo("Coach");
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.paymentInfoData) {
      console.log("PAYMENTDATA----->", nextProps._BILLINGDATA.paymentInfoData);
      if (nextProps._BILLINGDATA.paymentInfoData) {
        this.setState(
          {
            paymentList: nextProps._BILLINGDATA.paymentInfoData.data,
          },
          () => {
            nextProps._BILLINGDATA.paymentInfoData = null;
            this.props.fetchBillingInfo(this.state.filterBill, "Coach");
          }
        );
      }
    }

    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.billingInfoData) {
      console.log("BILLINGDATA----->", nextProps._BILLINGDATA.billingInfoData);
      if (nextProps._BILLINGDATA.billingInfoData) {
        this.setState(
          {
            billingList: nextProps._BILLINGDATA.billingInfoData.docs,
          },
          () => {
            nextProps._BILLINGDATA.billingInfoData = null;
            this.setLoader(false);
          }
        );
      }
    }

    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.deleteCardData) {
      console.log("DELETECARD--->", nextProps._BILLINGDATA.deleteCardData)
      if (nextProps._BILLINGDATA.deleteCardData.flag) {
        document.body.classList.remove("modal-open");
        this.setState({
          confirmPopUp: false,
        }, () => {
          this.commonCall.successMessage(nextProps._BILLINGDATA.deleteCardData.message);
          nextProps._BILLINGDATA.deleteCardData = null;
          this.setLoader(false);
          this.props.fetchPaymentInfo("Coach");
        });
      } else {
        nextProps._BILLINGDATA.deleteCardData = null;
        this.commonCall.errorMessage(nextProps._BILLINGDATA.deleteCardData.message);
        this.setLoader(false);
      }
    }

    if (nextProps._BILLINGDATA && nextProps._BILLINGDATA.billingInfoData) {
      console.log("BILLINGDATA----->", nextProps._BILLINGDATA.billingInfoData);
      if (nextProps._BILLINGDATA.billingInfoData) {
        this.setState(
          {
            billingList: nextProps._BILLINGDATA.billingInfoData.docs,
          },
          () => {
            nextProps._BILLINGDATA.billingInfoData = null;
            this.setLoader(false);
          }
        );
      }
    }

    if (nextProps._BILLINGDATA &&
      nextProps._BILLINGDATA.checkTypeCardReq &&
      nextProps._BILLINGDATA.checkTypeCardData
    ) {
      nextProps._BILLINGDATA.checkTypeCardReq = false;
      console.log("checkTypeCardData--->", nextProps._BILLINGDATA.checkTypeCardData);
      if (nextProps._BILLINGDATA.checkTypeCardData.flag) {
        this.setState({
          delMsgData: nextProps._BILLINGDATA.checkTypeCardData.result,
          confirmPopUp: true,
        }, () => {
          nextProps._BILLINGDATA.checkTypeCardData = null;
          this.setLoader(false);
        })
      } else {
        nextProps._BILLINGDATA.checkTypeCardData = null;
        this.setLoader(false);
      }
    }

    if (nextProps._BILLINGDATA &&
      nextProps._BILLINGDATA.makeDefaultCardReq &&
      nextProps._BILLINGDATA.makeDefaultCardData
    ) {
      nextProps._BILLINGDATA.makeDefaultCardReq = false;
      if (nextProps._BILLINGDATA.makeDefaultCardData.flag) {
        nextProps._BILLINGDATA.makeDefaultCardData = null;
        this.props.fetchPaymentInfo("Coach");
      } else {
        nextProps._BILLINGDATA.makeDefaultCardData = null;
        this.setLoader(false);
      }
    }
  };

  handleDelete = (id, dataType) => {
    console.log("id:-----type:----", id, dataType);
    this.setLoader(true);
    this.props.deleteCard({ card_id: id, type: dataType }, "Coach");
  };

  handleMakeDefaultCard = (id) => {
    this.setLoader(true);
    this.props.makeDefaultCard({ card_id: id }, "Coach");
  }

  renderPaymentList = () => {
    return this.state.paymentList.length !== 0 ? (
      this.state.paymentList.map((e) => {
        return (
          <tr key={e.id} >
            <td>
              <div className="card_holder_lable" >
                <p >
                  {e.billing_details.name} XXXXXX-{e.card.last4}
                </p>
                {e.metadata.isDefault === "false" ? "" : <Link>Default</Link>}
              </div>
            </td>
            <td>
              <p>
                {e.card.exp_month}, {e.card.exp_year}
              </p>
            </td>
            <td>
              <div className="action-btn">
                {
                  e.metadata.isDefault === "false" &&
                  <Link
                    className="green-status"
                    onClick={() => this.handleMakeDefaultCard(e.id)}
                  >
                    Make Default
                  </Link>
                }
                <Link
                  className="red-status"
                  onClick={() => this.handleDeleteCard(e.id)}
                >
                  Delete
                </Link>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5">
          <h3 style={{ textAlign: "center" }}>No Data Found </h3>
        </td>
      </tr>
    );
  };

  setPlan = (typeId, plans) => {
    var isPlans = "";
    plans.length > 0 &&
      plans.forEach((data) => {
        if (data.stripePlanId === typeId) return (isPlans = data);
      });
    return isPlans;
  }

  renderBillingList = () => {
    return this.state.billingList.length !== 0 ? (
      this.state.billingList.map((e) => {
        return (
          <tr>
            <td>
              {
                e.productId.productCode !== "ANALYZR" ?
                  <p>
                    {e.packageId !== null ? e.productId.name : ""}{" "}
                    {e.packageId !== null ? this.setPlan(e.priceId, e.packageId.plans).type === 1 ? "Monthly" : "Yearly" : ""}{" "}
                    Subscription -  {e.packageId !== null ? e.packageId.type + " " + e.packageId.role : ""}
                  </p>
                  :
                  <p>
                    AnalyzR {e.stripeLog.metadata.credit ? "- "+ e.stripeLog.metadata.credit + " credit" : ""}
                  </p>
              }
            </td>
            <td>
              <p>$ {e.price}.00</p>
            </td>
            <td>
              {
                e.productId.productCode !== "ANALYZR" ?
                  <p>
                    {moment(e.stripeLog.current_period_start * 1000).format("LL")}
                  </p>
                  :
                  <p>
                    {moment(e.createdAt).format("MM/DD/YYYY")}
                  </p>
              }
            </td>
            <td>
              {
                e.productId.productCode !== "ANALYZR" ?
                  <p>
                    {moment(e.stripeLog.current_period_end * 1000).format("LL")}
                  </p> : "-"
              }
            </td>
            <td>
              {e.productId.productCode !== "ANALYZR" ? e.stripeLog.status === "active" ? (
                <Link className="green-status">Active</Link>
              ) : (
                <Link className="red-status">Expire</Link>
              )
                : "-"}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5">
          <h3 style={{ textAlign: "center" }}>No Data Found </h3>
        </td>
      </tr>
    );
  };

  openAddCard = () => {
    this.setState({
      addCardPopUp: true,
    });
  };

  handleDeleteCard = (deleteCardId) => {
    this.setState({
      deleteInfo: deleteCardId,
    }, () => {
      this.setLoader(true);
      this.props.checkType({ card_id: deleteCardId }, "Coach");
    });
  };

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      addCardPopUp: false,
    });
  };

  onHandleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      confirmPopUp: false,
    });
  };

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }


  render() {
    const { addCardPopUp, confirmPopUp, loader } = this.state;
    return (
      <>
        <Header />
        <CommonHeroBanner dataList="" />
        <section className="payment_section billing_payment">
          <div className="container">
            <div className="billing_paymethod white-bg">
              <div className="payment-title">
                <h2>Payment Method</h2>
                <Link
                  className="pay_add_btn"
                  onClick={(e) => {
                    this.openAddCard();
                  }}
                >
                  {" "}
                  + Add Card
                </Link>
              </div>
              <div className="payment_list">
                <div className="payment_table">
                  <table>
                    <thead>
                      <tr>
                        <th>Card Holder</th>
                        <th>Expire Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPaymentList()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="billing_paymethod white-bg mb-0">
              <div className="payment-title">
                <h2>Billing History</h2>
              </div>
              <div className="payment_list">
                <div className="billing_table">
                  <table>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Charge</th>
                        <th>Date of Order</th>
                        <th>Date of Expire</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderBillingList()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ErrorMessage
          flag={confirmPopUp}
          close={this.onHandleClose}
          deleteId={this.state.deleteInfo}
          delete={this.handleDelete}
          delMsgData={this.state.delMsgData}
        />

        <AddNewCard
          flag={addCardPopUp}
          close={this.handleClose}
          type="Coach"
        />

        <Footer />
        <Preloader flag={loader} />
      </>
    );
  }
}

coachBilling.propTypes = {
  fetchPaymentInfo: PropTypes.func.isRequired,
  fetchBillingInfo: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  checkType: PropTypes.func.isRequired,
  makeDefaultCard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  _BILLINGDATA: state.billingInfo,
});

export default connect(mapStateToProps, {
  fetchPaymentInfo,
  fetchBillingInfo,
  deleteCard,
  checkType,
  makeDefaultCard
})(coachBilling);

import axios from 'axios';
import { Auth_Coach_Key, Auth_Player_Key, API_Url, Authorization, checkIsValidCoachToken, checkIsValidPlayerToken, checkIsValidFanToken, Auth_Fan_Key } from '../../helpers/authToken';
import {
    GET_ACTIVE_SUB_REQUEST,
    GET_ACTIVE_SUB_SUCCESS,
    GET_ACTIVE_SUB_ERROR,

    GET_EXPIRE_SUB_REQUEST,
    GET_EXPIRE_SUB_SUCCESS,
    GET_EXPIRE_SUB_ERROR,

    DELETE_ACTIVE_SUB_REQUEST,
    DELETE_ACTIVE_SUB_SUCCESS,
    DELETE_ACTIVE_SUB_ERROR,

    GET_UPGRADE_REQUEST,
    GET_UPGRADE_SUCCESS,
    GET_UPGRADE_ERROR,

    SAVE_UPGRADE_REQUEST,
    SAVE_UPGRADE_SUCCESS,
    SAVE_UPGRADE_ERROR,

    GET_ROSTER_LIST_COACH_REQUEST,
    GET_ROSTER_LIST_COACH_SUCCESS,
    GET_ROSTER_LIST_COACH_ERROR,

    GET_ROSTER_LIST_PLAYER_REQUEST,
    GET_ROSTER_LIST_PLAYER_SUCCESS,
    GET_ROSTER_LIST_PLAYER_ERROR,
} from '../../constants/common/subscription.const';
import histrory from '../../../History';

export const getActiveList = (data, userType,token) => dispatch => {
    console.log("data:--", data, userType);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_ACTIVE_SUB_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/list/active`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(active => {
        console.log("active:--", active);
        dispatch({
            type: GET_ACTIVE_SUB_SUCCESS,
            payload: active.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_ACTIVE_SUB_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteSubscription = (data, userType, token) => dispatch => {
    console.log("data:--", data);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: DELETE_ACTIVE_SUB_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/cancel`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(deletesub => {
        console.log("deletesub:--", deletesub);
        dispatch({
            type: DELETE_ACTIVE_SUB_SUCCESS,
            payload: deletesub.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DELETE_ACTIVE_SUB_ERROR, error: error.response.data.message })
        }
    });
};

export const getExpireList = (data, userType) => dispatch => {
    console.log("data:--", data);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: GET_EXPIRE_SUB_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/list/expire`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(expire => {
        console.log("expire:--", expire);
        dispatch({
            type: GET_EXPIRE_SUB_SUCCESS,
            payload: expire.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_EXPIRE_SUB_ERROR, error: error.response.data.message })
        }
    });
};

export const upgradeList = (data, userType, token) => dispatch => {
    console.log("data:--", data, userType);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: GET_UPGRADE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/upgrade/package/list`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : token ? token : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(upgrade => {
        dispatch({
            type: GET_UPGRADE_SUCCESS,
            payload: upgrade.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_UPGRADE_ERROR, error: error.response.data.message })
        }
    });
};

export const saveUpgrade = (data, userType) => dispatch => {
    console.log("data:--", data, userType);
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : userType === "Player" ? checkIsValidPlayerToken(histrory) : checkIsValidFanToken(histrory);
    dispatch({ type: SAVE_UPGRADE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/upgrade/package/new`,
        data: data,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : localStorage.getItem("rosterToken") ? localStorage.getItem("rosterToken") : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(upgrade => {
        dispatch({
            type: SAVE_UPGRADE_SUCCESS,
            payload: upgrade.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_UPGRADE_ERROR, error: error.response.data.message })
        }
    });
};

export const getCoachRosterList = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_ROSTER_LIST_COACH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/coach/dashboard/roster/list`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(chkrole => {
        dispatch({
            type: GET_ROSTER_LIST_COACH_SUCCESS,
            payload: chkrole.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_ROSTER_LIST_COACH_ERROR, error: error })
        }
    });
};

export const getPlayerRosterList = (data) => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: GET_ROSTER_LIST_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/dashboard/roster/list`,
        data:data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(chkrole => {
        dispatch({
            type: GET_ROSTER_LIST_PLAYER_SUCCESS,
            payload: chkrole.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_ROSTER_LIST_PLAYER_ERROR, error: error })
        }
    });
};

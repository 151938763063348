import {
    GET_LEAGUE_DETAILS_REQUEST,
    GET_LEAGUE_DETAILS_SUCCESS,
    GET_LEAGUE_DETAILS_ERROR,

    GET_PARTICIPATION_LEAGUE_REQUEST,
    GET_PARTICIPATION_LEAGUE_SUCCESS,
    GET_PARTICIPATION_LEAGUE_ERROR,

    GET_LEAGUE_EVENTS_REQUEST,
    GET_LEAGUE_EVENTS_SUCCESS,
    GET_LEAGUE_EVENTS_ERROR,

    LEAGUE_EVENT_SELECT_REQUEST,
    LEAGUE_EVENT_SELECT_SUCCESS,
    LEAGUE_EVENT_SELECT_ERROR,

    CHECK_LEAGUE_ROSTER_REQUEST,
    CHECK_LEAGUE_ROSTER_SUCCESS,
    CHECK_LEAGUE_ROSTER_ERROR,
} from '../../constants/common/leagueDetails.const';

const initialState = {
    leagueDetailsReq: false,
    leagueDetailsData: null,
    leagueDetailsError: null,

    leagueParticipationReq: false,
    leagueParticipationData: null,
    leagueParticipationError: null,

    leagueEventsReq: false,
    leagueEventsData: null,
    leagueEventsError: null,

    leagueEventSelectReq: false,
    leagueEventSelectData: null,
    leagueEventSelectError: null,

    checkLeagueConfirmReq: false,
    checkLeagueConfirmData: null,
    checkLeagueConfirmError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Check League Confirmation req    
        case CHECK_LEAGUE_ROSTER_REQUEST:
            return {
                ...state,
                checkLeagueConfirmReq: true,
            };

        case CHECK_LEAGUE_ROSTER_SUCCESS:
            return {
                ...state,
                checkLeagueConfirmData: action.payload,
            };
        case CHECK_LEAGUE_ROSTER_ERROR:
            return {
                ...state,
                checkLeagueConfirmError: action.error,
            };

        // Leagues Details req    
        case GET_LEAGUE_DETAILS_REQUEST:
            return {
                ...state,
                leagueDetailsReq: true,
            };

        case GET_LEAGUE_DETAILS_SUCCESS:
            return {
                ...state,
                leagueDetailsData: action.payload,
            };
        case GET_LEAGUE_DETAILS_ERROR:
            return {
                ...state,
                leagueDetailsError: action.error,
            };

        // Leagues Participation req    
        case GET_PARTICIPATION_LEAGUE_REQUEST:
            return {
                ...state,
                leagueParticipationReq: true,
            };

        case GET_PARTICIPATION_LEAGUE_SUCCESS:
            return {
                ...state,
                leagueParticipationData: action.payload,
            };
        case GET_PARTICIPATION_LEAGUE_ERROR:
            return {
                ...state,
                leagueParticipationError: action.error,
            };

        // Leagues Events req    
        case GET_LEAGUE_EVENTS_REQUEST:
            return {
                ...state,
                leagueEventsReq: true,
            };

        case GET_LEAGUE_EVENTS_SUCCESS:
            return {
                ...state,
                leagueEventsData: action.payload,
            };
        case GET_LEAGUE_EVENTS_ERROR:
            return {
                ...state,
                leagueEventsError: action.error,
            };

        // Leagues Event Select req    
        case LEAGUE_EVENT_SELECT_REQUEST:
            return {
                ...state,
                leagueEventSelectReq: true,
            };

        case LEAGUE_EVENT_SELECT_SUCCESS:
            return {
                ...state,
                leagueEventSelectData: action.payload,
            };
        case LEAGUE_EVENT_SELECT_ERROR:
            return {
                ...state,
                leagueEventSelectError: action.error,
            };


        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from '../../assets/images';
import { Link } from "react-router-dom";
import CommonHeroBanner from "../../components/commonHeroBanner";
import { Player } from 'video-react';
import { getPack } from '../../saga/actions/landing/bollr.action';
import commonCalls from "../../businesslogic/commonCalls";
import Preloader from '../../components/preloader';
import Slider from "react-slick";

class PitchR extends Component {

  constructor(props) {
    super(props);
    this.state = {
      webPack: {
        role: "",
        productCode: "PITCHR"
      },
      monthPrice: "",
      userRole: "",
      subCoach: false,
      pack: [],
      planType: 1,
      loader: false,
      showIndex: [],
      nav1: null,
      nav2: null
    }

    this.commonCall = new commonCalls();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
    //   this.setState({
    //     userRole: "Player",
    //     webPack: {
    //       role: "Player",
    //       productCode: "PITCHR"
    //     },
    //   }, () => {
    //     this.setLoader(true);
    //     this.props.getPack(this.state.webPack);
    //   })
    // }
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.profileDetails &&
      nextProps.profileDetails.headReq &&
      nextProps.profileDetails.headData
    ) {
      this.setState((prevState) => ({
        ...prevState,
        userRole: "Coach",
        webPack: {
          ...prevState.webPack,
          role: nextProps.profileDetails.headData.result.profile.role
        },
        subCoach: nextProps.profileDetails.headData.result.profile.subCoach
      }), () => {
        this.props.bollrDetails.packData = null;
        // this.setLoader(true);
        // this.props.getPack(this.state.webPack);
      });
    }

    if (nextProps.profileDetails &&
      nextProps.profileDetails.headPlayerReq &&
      nextProps.profileDetails.headPlayerData
    ) {
      this.setState((prevState) => ({
        ...prevState,
        userRole: "Player",
        webPack: {
          ...prevState.webPack,
          role: nextProps.profileDetails.headPlayerData.result.profile.role
        },
      }), () => {
        this.props.bollrDetails.packData = null;
        // this.setLoader(true);
        // this.props.getPack(this.state.webPack);
      });
    }

    if (nextProps.bollrDetails &&
      nextProps.bollrDetails.packReq &&
      nextProps.bollrDetails.packData
    ) {
      nextProps.bollrDetails.packReq = false;
      if (nextProps.bollrDetails.packData.data.length !== 0) {
        const pl = nextProps.bollrDetails.packData.data.map(item => {
          item.selectedType = 1;
          return item
        })
        this.getPlans(pl, data => {
          var temp, size;
          size = data.length;
          for (var i = 0; i < size; i++) {
            for (var j = i + 1; j < size; j++) {
              if (data[i].amount > data[j].amount) {
                temp = data[i];
                data[i] = data[j];
                data[j] = temp;
              }
            }
          }
          this.setState({
            pack: pl,
            monthPrice: data[0],
          }, () => {
            nextProps.bollrDetails.packData = null;
            this.setLoader(false);
          })
        });
      } else {
        nextProps.bollrDetails.packData = null;
        this.setLoader(false);
      }
    }
  }

  getPlans = (plans, cb) => {
    let pArr = [];
    plans.length > 0 &&
      plans.forEach((data) => {
        data.plans.forEach((item) => {
          if (item.type === 1) {
            item.trialPeriod = data.trialPeriod
            pArr.push(item);
          }
        })
      })
    cb(pArr);
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  getRolePack = (role) => {
    if (this.props.bollrDetails.packData) {
      this.props.bollrDetails.packData = null;
    }

    this.setState({
      userRole: role,
      webPack: {
        role: role,
        productCode: "PITCHR"
      },
    }, () => {
      this.setLoader(true);
      this.props.getPack(this.state.webPack);
    })
  }

  renderPackage = () => {
    const { pack } = this.state;
    return (
      pack &&
      pack.map((list, i) => {
        return (

          <div className="price_part" key={i}>
            {this.renderPlansList(list)}
          </div >
        )
      })
    );
  }

  renderPlansList = (data) => {
    const { pack, showIndex, userRole, subCoach } = this.state;

    return (
      data &&
      data.plans &&
      data.plans.map((list, i) => {
        return (
          data.selectedType === list.type &&
          <div className={`main-price ${showIndex.find((d) => d === list._id) ? "highlight" : ""}`}>
            <div className="price-title">{data.type}</div>
            <div className="price_value">
              {this.getTrial(pack, data.type).trialPeriod > 0 &&
                <p className="price_trial">{this.getTrial(pack, data.type).trialPeriod} day free trial included!</p>
              }
              <h4>${list.amount}</h4>
              <div className="payradio-button">
                <label className="round-radio">
                  Per Month
                  <input type="checkbox" id="team" name="plans" value="1" checked={data.selectedType === 1 ? true : false} onClick={() => this.getChangePlans(pack, data._id, 1)} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="payradio-button">
                <label className="round-radio">
                  Per Year
                  <input type="checkbox" id="facility" name="plans" value="2" checked={data.selectedType === 2 ? true : false} onClick={() => this.getChangePlans(pack, data._id, 2)} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <Link
                onClick={() => (data.status && !subCoach) ? (localStorage.getItem("coachToken") && userRole === "Coach") ? this.nextPayment(this.setPlan(data.plans, data.selectedType), data) : (localStorage.getItem("playerToken") && userRole === "Player") ? this.nextPayment(this.setPlan(data.plans, data.selectedType), data) : (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) ? this.nextPayment(this.setPlan(data.plans, data.selectedType), data) : "" : ""}
                className="btn"
                disabled={(data.status && !subCoach) ? (localStorage.getItem("coachToken") && userRole === "Coach") ? false : (localStorage.getItem("playerToken") && userRole === "Player") ? false : (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) ? false : true : true}
              >
                Get it now!
              </Link>
            </div>
            <div className="accordion">
              <div className="card-header">
                <Link onClick={() => this.showDetails(list._id)}>Features <i className="fas fa-chevron-down"></i></Link>
              </div>
              <div className="card-body">
                {this.renderPackageDescription(data.featureIds)}
              </div>
            </div>
          </div >
        )
      })

    )
  }

  getTrial = (plans, type) => {
    var isPlans = "";
    plans.length > 0 &&
      plans.forEach((data) => {
        if (data.type === type) return (isPlans = data);
      });
    return isPlans;
  }

  getChangePlans = (pack, id, type) => {
    pack.map(item => {
      if (item._id === id) {
        Object.assign(item, { selectedType: type });
      }
    })
    this.setState({
      pack: pack
    })
  }

  setPlan = (plans, type) => {
    var isPlans = "";
    plans.length > 0 &&
      plans.forEach((data) => {
        if (data.type === type) return (isPlans = data);
      });
    return isPlans;
  }

  nextPayment = (plan, data) => {
    let nextData = {
      packid: data._id,
      plans: plan,
      title: data.role + " - " + data.type + " Package",
      trialPeriod: data.trialPeriod
    }
    localStorage.setItem("popupUrl", "/pitchrapp");
    localStorage.setItem("rolPay", this.state.userRole)
    this.commonCall.setStorage("payPlans", nextData, flag => {
      if (flag) {
        if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken")) {
          this.props.history.push('/signup');
        } else if (localStorage.getItem("coachToken")) {
          this.props.history.push('/coach-payment');
        } else {
          this.props.history.push('/player-payment');
        }
      }
    });
  }

  showDetails = (index) => {
    if (this.state.showIndex.find((d) => d === index)) {
      // subtraction
      let tempArray = this.state.showIndex.filter((d) => d !== index);
      this.setState((prevState) => ({
        ...prevState,
        showIndex: tempArray,
      }));
    } else {
      // addition
      this.setState((prevState) => ({
        ...prevState,
        showIndex: [...prevState.showIndex, index],
      }));
    }

  }

  renderPackageDescription = (data) => {
    return (
      <>
        <div className="card-body-inner">
          {
            data &&
            data.map((flist, i) => {
              return (
                <p><img src={imagesArr.check} alt="check" />{flist.name}</p>
              )
            })
          }
        </div>
      </>
    )
  }

  render() {
    const { loader, userRole } = this.state;
    var findslider = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };
    return (
      <div className="pitchr-page">
        <Header />
        {/* <CommonHeroBanner dataList="" /> */}
        {/* <section className="commen_product">
          <div className="container">
            <div className="heading-title">
              <h2>Launching Early 2021</h2>
            </div>
            <div className="video-bg">
              <Player playsInline src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/pitchR.mp4" />
            </div>
          </div>
        </section> */}

        {/* pricing section Start */}
        {/* <section className="pricing_section" id="pricing-plans">
          <div className="container">
            <div className="heading-title">
              <h2 className="mb-0">
                Pricing
              </h2>
            </div>
          </div>
          <div className="container">
            <div className="price-btn">
              <span className={`btn ${userRole === "Player" ? "active" : ""}`} onClick={() => this.getRolePack("Player")}>Player</span>
              <span className={`btn ${userRole === "Coach" ? "active" : ""}`} onClick={() => this.getRolePack("Coach")}>Coach</span>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {this.renderPackage()}
            </div>
          </div>
        </section> */}
        {/* pricing section End */}
        <section className="hero-banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="hero-dec">
                  <div className="heading-logo"><img src={imagesArr.headinglogo3} alt="headinglogo3" /></div>
                  <h1>The Ultimate Hub of Data Visualization</h1>
                  <div className="hero-btn">
                    <a className="btn" href="mailto:info@baseballcloud.com">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="game-analyze">
          <div className="container">
            <h2>Our Game Is Played in 3D. It’s Time to Analyze in 3D.</h2>
            <div className="row">
              <div className="col-4">
                <div className="game-analyze-box">
                  <img src={imagesArr.game_analyze_2} />
                  <h5>View Pitches</h5>
                  <p>PitchR gives you a full 360 degree view of pitches thrown in bullpens and in games.</p>
                </div>
              </div>
              <div className="col-4">
                <div className="game-analyze-box">
                  <img src={imagesArr.game_analyze_1} />

                  <h5>View Batted Balls</h5>
                  <p>Don’t settle for a traditional 2D spray chart. See your ball flight trends from the batter’s box. </p>
                </div>
              </div>
              <div className="col-4">
                <div className="game-analyze-box">
                  <img src={imagesArr.game_analyze_3} />
                  <h5>All on Your Home Field</h5>
                  <p>PitchR stadiums can be fully customized, down to the smallest detail. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tab-slider">
          <div className="container">
            <h2>Develop Like Never Before</h2>
            <p>Move freely around the field to find the best visual cues for your players. </p>
            <Slider
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={5}
              swipeToSlide={false}
              focusOnSelect={true}
              className="slider-nav"
              autoplay={true}
              autoplaySpeed={5000}
            >
              <div>
                <h3>Plate</h3>
              </div>
              <div>
                <h3>Mound</h3>
              </div>
              <div>
                <h3>Side</h3>
              </div>
              <div>
                <h3>Outfield</h3>
              </div>
              <div>
                <h3>Overhead</h3>
              </div>
            </Slider>
            <Slider
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              arrows={true}
              className="slider-img"
              autoplay={true}
              autoplaySpeed={5000}
            >
              <div>
                <div className="slider-tab-img"><img src={imagesArr.develop_plate} /></div>
              </div>
              <div>
                <div className="slider-tab-img"><img src={imagesArr.develop_mound} /></div>
              </div>
              <div>
                <div className="slider-tab-img"><img src={imagesArr.develop_side} /></div>
              </div>
              <div>
                <div className="slider-tab-img"><img src={imagesArr.develop_outfield} /></div>
              </div>
              <div>
                <div className="slider-tab-img"><img src={imagesArr.develop_overhead} /></div>
              </div>
            </Slider>
          </div>
        </section>
        <section className="find-slider">
          <div className="container">
            <div className="slider-text row">
              <div className="slider-content">
                <Slider {...findslider}>
                  <div className="item">
                    <div className="find-slider-box">
                      <div className="find-img">
                        <img src={imagesArr.Find_1} />
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="find-slider-box">
                      <div className="find-img">
                        <img src={imagesArr.Find_2} />
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="find-slider-box">
                      <div className="find-img">
                        <img src={imagesArr.Find_3} />
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="find-slider-box">
                      <div className="find-img">
                        <img src={imagesArr.Find_4} />
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
              <div className="find-text">
                <h4>Find What You’re Looking For</h4>
                <p>Advanced filters allow you to identify trends, answer performance-related questions, and simplify information for coaches and players. </p>
              </div>

            </div>
          </div>
        </section>
        <section className="place-home">
          <div className="container">
            <div className="slider-text row">
              <div className="place-home-info">
                <h4>There’s No Place Like Home</h4>
                <p>Build out custom stadiums to view data in your home environment. Use this full recreation of your facility for broadcasting, fan engagement, and recruiting in addition to player development.</p>
              </div>
              <div className="place-home-img">
                <Slider {...findslider}>
                  <div className="item">
                    <div className="find-img">
                      <img src={imagesArr.place_home_1} />
                    </div>
                  </div>
                  <div className="item">
                    <div className="find-img">
                      <img src={imagesArr.place_home_2} />
                    </div>
                  </div>
                  <div className="item">
                    <div className="find-img">
                      <img src={imagesArr.place_home_3} />
                    </div>
                  </div>
                  <div className="item">
                    <div className="find-img">
                      <img src={imagesArr.place_home_4} />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section className="pricing-pitchr">
          <div className="container">
            <div className="price-bg">
              <h2>Pricing</h2>
              <p>PitchR 1.0 is available now. Reach out to your account manager or contact us below for a quote. </p>
              <a className="btn" href="mailto:info@baseballcloud.com">Contact Us</a>
            </div>
          </div>
        </section>
        <Footer />
        <Preloader flag={loader} />
      </div>
    );
  }
}

PitchR.propTypes = {
  getPack: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profileDetails: state.headerData,
  bollrDetails: state.bollrDetails,
});

export default connect(mapStateToProps, { getPack })(PitchR);

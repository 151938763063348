import React, { Component } from "react";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from "../../assets/images";
import { Link } from "react-router-dom";
import CommonHeroBanner from "../../components/commonHeroBanner";
import { Player } from 'video-react';
import Slider from "react-slick";

export class BcTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    }
  }
  redirectBcTeamLogin = () => {
    window.open("https://app.baseballcloud.com/login", '_blank');
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  render() {
    var findslider = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: false,
    };
    return (
      <>
        <div className="bcteam-page">
          <Header />
          {/* <CommonHeroBanner dataList="" /> */}
          {/* <section className="commen_product">
            <div className="container">
              <div className="heading-title">
              <h2>Contact our team for more information.</h2>
              <br></br>
              <h2>Current BCTeam client's <Link onClick={()=> this.redirectBcTeamLogin()}>login in here</Link></h2>
            </div>
              <div className="video-bg">
                <Player playsInline src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/bcteam.mp4" />
              </div>
            </div>
          </section> */}

          <section className="hero-banner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12"><div className="hero-dec">
                  <div className="heading-logo"><img src={imagesArr.bcscteam} alt="headinglogo3" /></div>
                  <h1>All-Encompassing Player Portal</h1>
                  <div className="hero-btn">
                    <a className="btn" href="mailto:support@baseballcloud.com">Contact Us</a>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section className="tab-slider">
            <div className="container">
              <h2>Your Very Own Analytics Department</h2>
              <p>Store and utilize all data collected in games, scrimmages, bullpens, and BP in one centralized location.</p>
              <Slider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                slidesToShow={5}
                swipeToSlide={false}
                focusOnSelect={true}
                className="slider-nav"
                autoplay={true}
              autoplaySpeed={5000}
              >
                <div>
                  <h3>Player Profiles</h3>
                </div>
                <div>
                  <h3>Team Summary</h3>
                </div>
                <div>
                  <h3>Game Reports</h3>
                </div>
                <div>
                  <h3>Practice Reports</h3>
                </div>
                <div>
                  <h3>Comparison</h3>
                </div>

              </Slider>
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={true}
                className="slider-img"
                autoplay={true}
              autoplaySpeed={5000}
              >
                <div>
                  <div className="slider-tab-img"><img src={imagesArr.playerprofiles} /></div>
                </div>
                <div>
                  <div className="slider-tab-img"><img src={imagesArr.teamsummary} /></div>
                </div>
                <div>
                  <div className="slider-tab-img"><img src={imagesArr.gamereports} /></div>
                </div>
                <div>
                  <div className="slider-tab-img"><img src={imagesArr.practicereports} /></div>
                </div>
                <div>
                  <div className="slider-tab-img"><img src={imagesArr.comparison} /></div>
                </div>
              </Slider>

            </div>
          </section>
          
          <section className="place-home">
            <div className="container">
              <div className="row">
                <div className="place-home-img">
                  <img src={imagesArr.oneportalendlessdevices} />
                </div>
                <div className="place-home-info">
                  <h4>One Portal.<br/>Endless Devices.</h4>
                  <p>Don’t go to five different sites to view information on one player. Upload data from your in-game system, bat sensors, and bullpen devices to one place and see how the data correlates. </p>
                </div>
              </div>
            </div>
          </section>

          <section className="game-analyze">
            <div className="container">
              <h2>Use Advanced Filters to Track Progress</h2>
              <div className="row">
                <div className="col-4">
                  <div className="game-analyze-box">
                    <img src={imagesArr.customezone} />
                    <h5>Custom Zones</h5>
                    <p>See where your players are having success using preset filters or custom zones.</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="game-analyze-box">
                    <img src={imagesArr.matchups} />
                    <h5>Matchups</h5>
                    <p>Use filters like handedness to see how your players perform in different matchups.</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="game-analyze-box">
                    <img src={imagesArr.effectiveness} />
                    <h5>Effectiveness</h5>
                    <p>Determine where a pitcher’s offerings are most effective in the zone.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="place-home">
            <div className="container">
              <div className="row">
                <div className="place-home-img">
                  <img src={imagesArr.immediateturnaround} />
                </div>
                <div className="place-home-info">
                  <h4>Immediate Turnaround</h4>
                  <p>Data is in the cloud within seconds of the last pitch, allowing players to benefit from instant feedback, and coaches to prepare for the next game of the series right away. </p>
                </div>
              </div>
            </div>
          </section>
          
          <section className="pricing-pitchr">
            <div className="container">
              <div className="price-bg">
                <h2>Pricing</h2>
                <p>BCTeam is available now. Reach out to your account manager or contact us below for a quote. </p>
                <a className="btn" href="mailto:info@baseballcloud.com">Contact Us</a>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default BcTeam;

import React, { Component } from 'react'
import Slider from "react-slick";
import { imagesArr } from "../../../../../assets/images";

export default class hotelInfo extends Component {

    renderHotels = () => {
        // return (
        //     this.props.hotelList &&
        //     this.props.hotelList.map((item, i) => {
        //         console.log("item:--", item);
        //         return (
        //             <div key={i} onClick={() => window.open(item.link, "_blank")}>
        //                 <div className="web_hotel_slide">
        //                     <img src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg" alt="hotel" class="rounded" />
        //                     <div class="web_hotel_address"><p>{item.hotelAddress}</p></div>
        //                 </div>
        //             </div>
        //         )
        //     })
        // )
    }

    render() {
        var Hotels = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <div className="web_description_box">
                <div class="web_desc_title"><h3>Hotels</h3></div>
                <div className="web_hotel_slidermain">
                    <Slider {...Hotels}>
                        <div>
                            <div className="web_hotel_slide">
                                <img src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg" alt="hotel" class="rounded" />
                                <div class="web_hotel_address"><p>surat , gujrat </p></div>
                            </div>
                        </div>
                        <div>
                            <div className="web_hotel_slide">
                                <img src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg" alt="hotel" class="rounded" />
                                <div class="web_hotel_address"><p>surat , gujrat </p></div>
                            </div>
                        </div>
                        <div>
                            <div className="web_hotel_slide">
                                <img src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg" alt="hotel" class="rounded" />
                                <div class="web_hotel_address"><p>surat , gujrat </p></div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        )
    }
}

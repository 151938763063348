
export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST';
export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS';
export const GET_PLAYER_ERROR = 'GET_PLAYER_ERROR';

export const GET_OPTION_REQUEST = 'GET_OPTION_REQUEST';
export const GET_OPTION_SUCCESS = 'GET_OPTION_SUCCESS';
export const GET_OPTION_ERROR = 'GET_OPTION_ERROR';

export const GET_PLAYER_OPTION_REQUEST = 'GET_PLAYER_OPTION_REQUEST';
export const GET_PLAYER_OPTION_SUCCESS = 'GET_PLAYER_OPTION_SUCCESS';
export const GET_PLAYER_OPTION_ERROR = 'GET_PLAYER_OPTION_ERROR';

export const SAVE_PLAYER_ROSTER_REQUEST = 'SAVE_PLAYER_ROSTER_REQUEST';
export const SAVE_PLAYER_ROSTER_SUCCESS = 'SAVE_PLAYER_ROSTER_SUCCESS';
export const SAVE_PLAYER_ROSTER_ERROR = 'SAVE_PLAYER_ROSTER_ERROR';

export const ADD_NEW_PLAYER_REQUEST = ' ADD_NEW_PLAYER_REQUEST';
export const ADD_NEW_PLAYER_SUCCESS = ' ADD_NEW_PLAYER_SUCCESS';
export const ADD_NEW_PLAYER_ERROR = ' ADD_NEW_PLAYER_ERROR';

export const EDIT_NEW_PLAYER_REQUEST = ' EDIT_NEW_PLAYER_REQUEST';
export const EDIT_NEW_PLAYER_SUCCESS = ' EDIT_NEW_PLAYER_SUCCESS';
export const EDIT_NEW_PLAYER_ERROR = ' EDIT_NEW_PLAYER_ERROR';

export const DEL_NEW_PLAYER_REQUEST = ' DEL_NEW_PLAYER_REQUEST';
export const DEL_NEW_PLAYER_SUCCESS = ' DEL_NEW_PLAYER_SUCCESS';
export const DEL_NEW_PLAYER_ERROR = ' DEL_NEW_PLAYER_ERROR';

export const DIS_NEW_PLAYER_REQUEST = ' DIS_NEW_PLAYER_REQUEST';
export const DIS_NEW_PLAYER_SUCCESS = ' DIS_NEW_PLAYER_SUCCESS';
export const DIS_NEW_PLAYER_ERROR = ' DIS_NEW_PLAYER_ERROR';

export const GTE_LINK_PLAYER_REQUEST = ' GTE_LINK_PLAYER_REQUEST';
export const GTE_LINK_PLAYER_SUCCESS = ' GTE_LINK_PLAYER_SUCCESS';
export const GTE_LINK_PLAYER_ERROR = ' GTE_LINK_PLAYER_ERROR';

export const ALL_MAIL_PLAYERINFO_SUCCESS = ' ALL_MAIL_PLAYERINFO_SUCCESS';
export const ALL_MAIL_PLAYERINFO_ERROR = ' ALL_MAIL_PLAYERINFO_ERROR';

import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken, checkIsValidFanToken, Auth_Fan_Key } from '../../../../helpers/authToken';
import {
    ADD_LINKED_PLAYER_REQUEST,
    ADD_LINKED_PLAYER_SUCCESS,
    ADD_LINKED_PLAYER_ERROR,
    LIST_LINKED_PLAYER_REQUEST,
    LIST_LINKED_PLAYER_SUCCESS,
    LIST_LINKED_PLAYER_ERROR,
    EDIT_LINKED_PLAYER_REQUEST,
    EDIT_LINKED_PLAYER_SUCCESS,
    EDIT_LINKED_PLAYER_ERROR,
    DISCONNECT_PLAYER_REQUEST,
    DISCONNECT_PLAYER_SUCCESS,
    DISCONNECT_PLAYER_ERROR,
    PHONE_VERIFY_PLAYER_REQUEST,
    PHONE_VERIFY_PLAYER_SUCCESS,
    PHONE_VERIFY_PLAYER_ERROR
} from '../../../../constants/view/coach/profile/linkedPlayerInfo.const';
import histrory from '../../../../../History';

export const saveLinkPlayer = (data,userType) => dispatch => {
    (userType === "Coach" ? checkIsValidCoachToken(histrory) : checkIsValidFanToken(histrory));
    dispatch({ type: ADD_LINKED_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/linked/player/add`,
        data: data,
        headers: {
            'x-auth-token': userType === "Coach" ? Auth_Coach_Key : Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: ADD_LINKED_PLAYER_SUCCESS,
            payload: addnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_LINKED_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const listLinkedPlayer = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: LIST_LINKED_PLAYER_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/fan/linked/player/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: LIST_LINKED_PLAYER_SUCCESS,
            payload: addnew.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: LIST_LINKED_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const editLinkedPlayer = (data,userType) => dispatch => {
    (userType === "Coach" ? checkIsValidCoachToken(histrory) : checkIsValidFanToken(histrory));
    dispatch({ type: EDIT_LINKED_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/fan/player/basic/edit`,
        data: data,
        headers: {
            'x-auth-token': userType === "Coach" ? Auth_Coach_Key : Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(edit => {
        dispatch({
            type: EDIT_LINKED_PLAYER_SUCCESS,
            payload: edit.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: EDIT_LINKED_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const disconnectPlayer = (data,userType) => dispatch => {
    (userType === "Coach" ? checkIsValidCoachToken(histrory) : checkIsValidFanToken(histrory));
    dispatch({ type: DISCONNECT_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/fan/player/disconnect`,
        data: data,
        headers: {
            'x-auth-token': userType === "Coach" ? Auth_Coach_Key : Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: DISCONNECT_PLAYER_SUCCESS,
            payload: addnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DISCONNECT_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const phoneVerifyForLinkedPlayer = (data,userType) => dispatch => {
    (userType === "Coach" ? checkIsValidCoachToken(histrory) : checkIsValidFanToken(histrory));
    dispatch({ type: PHONE_VERIFY_PLAYER_REQUEST })
    axios({
        method: 'POST', 
        url: `${API_Url}bc/fan/player/basic_verfiy`,
        data: data,
        headers: {
            'x-auth-token': userType === "Coach" ? Auth_Coach_Key : Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(verify => {
        dispatch({
            type: PHONE_VERIFY_PLAYER_SUCCESS,
            payload: verify.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: PHONE_VERIFY_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

import React, { useEffect, useRef, useState } from "react";
import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { imagesArr } from "../../assets/images";
import commonCalls from "../../businesslogic/commonCalls";
import { getRosterOption } from "../../saga/actions/common/eventDetails.action";
import { GET_ROSTER_GROUP_LIST_SUCCESS } from "../../saga/constants/common/eventDetails.const";

const initialState = {
  rosterList: [],
};

const reducer = (state, payload) => ({ ...state, ...payload });

function RegisterRosterPopup({ flag, close, req, eventJoin, onHandleChange ,ok }) {
  const dispatch = useDispatch();
  const commonCall = new commonCalls();
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [state, setState] = useReducer(reducer, initialState);
  const { rosterList } = state;

  const { getRosterOptData: _ROSTER } = useSelector(
    (state) => state.eventDetails
  );

  useEffect(() => {
    let sendRosterReq = {
      teamRoster: 1,
      ageGroup: req.ageGroup,
      ageGroupTo: req.ageGroupTo,
      event: req.eventId,
    };
    getRosterOption(sendRosterReq)(dispatch);
  }, []);

  useEffect(() => {
    if (_ROSTER) {
      if (_ROSTER.flag) {
        setState({
          ...state,
          rosterList: _ROSTER.result.data,
        });
        dispatch({
          type: GET_ROSTER_GROUP_LIST_SUCCESS,
          payload: null,
        });
      } else {
        commonCall.errorMessage(_ROSTER.message);
        dispatch({
          type: GET_ROSTER_GROUP_LIST_SUCCESS,
          payload: null,
        });
        // this.setLoader(false);
      }
    }
  }, [_ROSTER]);

//   const handleChange = (e) => {
//     setEventJoin({
//         ...eventJoin,
//         eventJoin: {
//             ...eventJoin.eventJoin,
//             rosterTeamId: e.target.value,
//         }
//     })
//   };

  return (
    <section className={`common-modal ${flag ? "show" : ""}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="green-header modal-header">
            <h3>Roster Groups</h3>
            <div className="modal_close" onClick={close}>
              <img src={imagesArr.white_modal_close} alt="modal_close" />
            </div>
          </div>
          <div className="modal-body">
            <div className="team-body-inner">
              <div className="form-group">
                <label className="common-lable">Roster Groups</label>
                <select
                  className="custom-select"
                  name="rosterTeamId"
                  value={eventJoin.eventJoin.rosterTeamId}
                  onChange={(e)=>onHandleChange(e.target.value)}
                >
                  <option value="">Select roster group</option>
                  {rosterList &&
                    rosterList.map((item, i) => {
                      return (
                        <option value={item._id}>{item.rosterName}</option>
                      );
                    })}
                </select>
                <span style={{ color: "red" }}>
                {validator.current.message(
                  "Roster group",
                  eventJoin.eventJoin.rosterTeamId,
                  "required"
                )}
              </span>
              </div>
              <div className="flex-align next-button">
                <button className="btn gray" onClick={close}>
                  Cancel
                </button>
                <button className="btn light-green" onClick={ok}>
                  OK
                </button>
              </div>
              
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterRosterPopup;

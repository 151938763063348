import { GAMEGROUND_SUCCESS,GAMEGROUND_ERROR,GAMEGROUND_REG } from '../../../../constants/view/player/profile/gameBackground.const';

const initialState = {
    gameGroundInfo : null,
    gameGroundError : null,
    ragisterInfo : null
}

export default function(state = initialState , action){

        switch (action.type) {
            case GAMEGROUND_SUCCESS:
                return{
                    ...state,
                    gameGroundInfo : action.payload
                }
            case GAMEGROUND_ERROR:
                return{
                    ...state,
                    gameGroundError : action.payload
                }
            break;
            case GAMEGROUND_REG:
                return{
                    ...state,
                    ragisterInfo:action.payload
                }
                break;
            default:
                return state;
                break;
        }
}
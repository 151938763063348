import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getLink, getOption, setRosterMap, sourceTeamDetails, sourceTeamMap } from '../../../saga/actions/common/uploadcsv.action';
import Preloader from '../../../components/preloader';
import Select from 'react-select';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '4em',
    color: '$gray-btn',
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '1',
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

class playerLinkData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            linkData: {
                bcevent_id: "",
                bcsource_id: ""
            },
            linkList: [],
            optionsArr: [],
            optionReq: {
                text: ""
            },
            mapArr: {
                bcsource_id: "",
                player_id: "",
                bc_user_id: ""
            },
            isTeam: false,
            allTeam: [],
            sendTeamSource: {
                bcsource_id: "",
                away_team: "",
                home_team: ""
            }
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.getIds();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getLinkReq &&
            nextProps.uploadCsvInfo.getLinkData
        ) {
            console.log("nextProps from getLinkData:----", nextProps.uploadCsvInfo.getLinkData);
            nextProps.uploadCsvInfo.getLinkReq = false;
            if (nextProps.uploadCsvInfo.getLinkData.flag) {
                this.setState({
                    linkList: nextProps.uploadCsvInfo.getLinkData.result.data,
                    isTeam: nextProps.uploadCsvInfo.getLinkData.result.data.length !== 0 ? nextProps.uploadCsvInfo.getLinkData.result.data[0].hasOwnProperty("team") : false
                }, () => {
                    this.props.getOption(this.state.optionReq, "Player");
                    nextProps.uploadCsvInfo.getLinkData = null;
                    this.setLoader(false);
                })
            } else {
                nextProps.uploadCsvInfo.getLinkData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getOptionReq &&
            nextProps.uploadCsvInfo.getOptionData
        ) {
            console.log("nextProps from getOptionData:----", nextProps.uploadCsvInfo.getOptionData);
            nextProps.uploadCsvInfo.getOptionReq = false;
            if (nextProps.uploadCsvInfo.getOptionData.flag) {
                let arr = [];
                arr.push({ value: "exclude", label: "Select BC profile" })
                nextProps.uploadCsvInfo.getOptionData.result.data.map((item, i) => {
                    arr.push({ value: item._id, label: item.fname + " " + item.lname })
                });

                this.setState({
                    optionsArr: arr
                }, () => {
                    nextProps.uploadCsvInfo.getOptionData = null;
                    this.setLoader(false);
                    // if (this.state.allTeam.length === 0) {
                        // this.props.sourceTeamDetails(this.state.linkData, "Player");
                    // } else {
                        // this.setLoader(false);
                    // }
                })
            } else {
                this.setState({
                    optionsArr: []
                }, () => {
                    this.commonCall.errorMessage(nextProps.uploadCsvInfo.getOptionData.message);
                    nextProps.uploadCsvInfo.getOptionData = null;
                    setTimeout(() => {
                        this.setLoader(false);
                    }, 1500);
                })
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.sourceTeamInfoReq &&
            nextProps.uploadCsvInfo.sourceTeamInfoData
        ) {
            nextProps.uploadCsvInfo.sourceTeamInfoReq = false;
            if (nextProps.uploadCsvInfo.sourceTeamInfoData.flag) {
                let gData = nextProps.uploadCsvInfo.sourceTeamInfoData.result;
                this.setState((prevState) => ({
                    ...prevState,
                    allTeam: gData.allTeam,
                    sendTeamSource: {
                        ...prevState.sendTeamSource,
                        home_team: gData.home_team,
                        away_team: gData.away_team
                    }
                }), () => {
                    nextProps.uploadCsvInfo.sourceTeamInfoData = null;
                    this.setLoader(false);
                })
            } else {
                // this.commonCall.errorMessage(nextProps.uploadCsvInfo.sourceTeamInfoData.message);
                nextProps.uploadCsvInfo.sourceTeamInfoData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getRmapReq &&
            nextProps.uploadCsvInfo.getRmapData
        ) {
            console.log("nextProps from getRmapData:----", nextProps.uploadCsvInfo.getRmapData);
            nextProps.uploadCsvInfo.getRmapReq = false;
            if (nextProps.uploadCsvInfo.getRmapData.flag) {
                // this.commonCall.successMessage(nextProps.uploadCsvInfo.getRmapData.message);
                nextProps.uploadCsvInfo.getRmapData = null;
                this.props.getLink(this.state.linkData, "Player");
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.sourceTeamMapReq &&
            nextProps.uploadCsvInfo.sourceTeamMapData
        ) {
            console.log("nextProps from sourceTeamMapData:----", nextProps.uploadCsvInfo.sourceTeamMapData);
            nextProps.uploadCsvInfo.sourceTeamMapReq = false;
            if (nextProps.uploadCsvInfo.sourceTeamMapData.flag) {
                // this.commonCall.successMessage(nextProps.uploadCsvInfo.sourceTeamMapData.message);
                nextProps.uploadCsvInfo.sourceTeamMapData = null;
                this.props.getLink(this.state.linkData, "Player");
            }
        }
    }

    getIds = () => {
        let url = new URL(document.location);
        let eid = url.pathname.split("/")[2];
        let sid = url.pathname.split("/")[3];

        this.setState((prevState) => ({
            ...prevState,
            linkData: {
                ...prevState.linkData,
                bcevent_id: eid,
                bcsource_id: sid
            },
            mapArr: {
                ...prevState.mapArr,
                bcsource_id: sid
            },
            sendTeamSource: {
                ...prevState.sendTeamSource,
                bcsource_id: sid
            }
        }), () => {
            if (this.props.uploadCsvInfo.getLinkData) {
                this.props.uploadCsvInfo.getLinkData = null;
            }
            console.log("this.props:-----", this.props.uploadCsvInfo);
            this.props.getLink(this.state.linkData, "Player");
        });
    }

    onChangeTeam = (e) => {
        const { name, value } = e.target;
        if (name === "home_team") {
            this.setState((prevState) => ({
                ...prevState,
                sendTeamSource: {
                    ...prevState.sendTeamSource,
                    home_team: value,
                    away_team: this.getOtherValue(value)
                },
            }), () => {
                this.sendTeamMap();
            });
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sendTeamSource: {
                    ...prevState.sendTeamSource,
                    home_team: this.getOtherValue(value),
                    away_team: value
                },
            }), () => {
                this.sendTeamMap();
            });
        }

    }

    sendTeamMap = () => {
        this.setLoader(true);
        this.props.sourceTeamMap(this.state.sendTeamSource, "Player");
    }

    getOtherValue = (value) => {
        let isDone = [];
        this.state.allTeam.map((item) => {
            if (item !== value) {
                isDone = item
            }
        });
        return isDone;
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            sourceData: {
                ...prevState.sourceData,
                [name]: value
            },
        }));
    }

    handleChange = (option) => {
        console.log(`Option selected:`, this.state.optionReq);
        this.setState((prevState) => ({
            ...prevState,
            optionReq: {
                ...prevState.optionReq,
                text: option,
            },
        }), () => {
            if (option.length !== 0) {
                console.log(`Option selected:`, this.state.optionReq);
                this.props.getOption(this.state.optionReq, "Player");
            } else {
                this.props.uploadCsvInfo.getOptionData = null;
                this.props.getOption(this.state.optionReq, "Player");
            }
        })
    };

    formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    setOption = (val, pid) => {
        console.log("val:--", val, pid);
        this.setState((prevState) => ({
            ...prevState,
            mapArr: {
                ...prevState.mapArr,
                player_id: pid,
                bc_user_id: val.value
            },
        }), () => {
            console.log("set:-----", this.state.mapArr);
            // this.setLoader(true);
            this.props.setRosterMap(this.state.mapArr, "Player");
        });

    }

    renderLinkData = () => {
        return (
            this.state.linkList.length !== 0 ?
                this.state.linkList.map((list, i) => {
                    return (
                        <tr key={i}>
                            {this.state.isTeam && <td scope="col">{list.team ? list.team : "-"}</td>}
                            <td>{list.name}</td>
                            <td>
                                <Select
                                    defaultValue={list.bc_user_id ? { value: list.bc_user_id._id, label: list.bc_user_id.fname + " " + list.bc_user_id.lname } : ""}
                                    options={this.state.optionsArr}
                                    formatGroupLabel={this.formatGroupLabel}
                                    onInputChange={this.handleChange}
                                    onChange={(e) => this.setOption(e, list.player_id)}
                                    menuPlacement="auto"
                                />
                            </td>
                        </tr>
                    );
                })
                :
                <tr>
                    <td colSpan="2" className="text-center">No Data Found</td>
                </tr>
        )
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, sourceData, isTeam, allTeam, sendTeamSource } = this.state;

        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {/* Uploadcsv Link data */}
                <section className="uploadcsv-linkdata uploadcsv">
                    <div className="container">
                        <div className={`white-bg tablecontent ${isTeam ? "linkTableThree" : "linkTableTwo"}`}>
                            <div className="tableinfo">
                                <h3>Link Data</h3>
                                <p className="mb-0">
                                    Match player names to connect data to the correct BaseballCloud profile. This step is not necessary for creating AnalyzR reports. Also, you do not need to connect opposing players to a profile.
                                </p>
                                {/* {
                                    isTeam &&
                                    <div className="new_add_file row">
                                        <div className="w-50">
                                            <div className="check_inputbox form-group ">
                                                <label className="common-lable">Home Team</label>
                                                <select name="home_team" value={sendTeamSource.home_team} onChange={(e) => this.onChangeTeam(e)} className="custom-select">
                                                    <option value={sendTeamSource.home_team} disabled>{sendTeamSource.home_team}</option>
                                                    {
                                                        allTeam &&
                                                        allTeam.map((item) => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <div className="check_inputbox form-group ">
                                                <label className="common-lable">Away Team</label>
                                                <select name="away_team" value={sendTeamSource.away_team} onChange={(e) => this.onChangeTeam(e)} className="custom-select">
                                                    <option value={sendTeamSource.away_team} disabled>{sendTeamSource.away_team}</option>
                                                    {
                                                        allTeam &&
                                                        allTeam.map((item) => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                } */}
                            </div>
                            <div className="table-responsive-xl">
                                <table>
                                    <thead>
                                        <tr>
                                            {isTeam && <th scope="col">Team</th>}
                                            <th scope="col">Name</th>
                                            <th scope="col">BC Profile</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderLinkData()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex-align">
                            <Link className="btn gray" to={'/player-uploadcsv'}>Submit</Link>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

playerLinkData.propTypes = {
    getLink: PropTypes.func.isRequired,
    getOption: PropTypes.func.isRequired,
    setRosterMap: PropTypes.func.isRequired,
    sourceTeamDetails: PropTypes.func.isRequired,
    sourceTeamMap: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default connect(mapStateToProps, { getLink, getOption, setRosterMap, sourceTeamDetails, sourceTeamMap })(playerLinkData);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';

class successMessage extends Component {
    render() {
        const { flag, profileInfo } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="onlymodal_close" onClick={() => this.props.close()}><img src={imagesArr.modal_close} alt="modal_close" /></div>
                            <div className="modal-body">
                                <div className="sccess_team">
                                    <div className="thumb_up">
                                        <img src={imagesArr.thumb_up} alt="modal_close" />
                                    </div>
                                    <h4>
                                        Thanks Coach {flag ? profileInfo.fname : ""}
                                    </h4>
                                    <p>We will get back to you within 24 hours with more information.<br />Thanks</p>
                                    <div className="flex-align">
                                        <Link className="btn" onClick={() => this.props.close()}>Close</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default successMessage;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { coachList } from '../../../../saga/actions/authantication/signup/fan/coach.action';
import Preloader from '../../../../components/preloader';

class coach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            coachArr: [],
            filter: {
                parentTeam: this.props.pTeamId,
                page: 1,
                limit: 10,
                name: ""
            },
            selectedId: []
        }
    }

    componentWillMount() {
        this.props.onRef(this);
        this.setLoader(true);
        this.props.coachList(this.state.filter);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.coachDetails &&
            nextProps.coachDetails.searchCoachRes &&
            nextProps.coachDetails.searchCoachData
        ) {
            nextProps.coachDetails.searchCoachRes = false;
            if (nextProps.coachDetails.searchCoachData.flag) {
                if (this.state.filter.name) {
                    return this.setState({
                        coachArr: nextProps.coachDetails.searchCoachData.result.teams,
                    }, () => {
                        nextProps.coachDetails.searchCoachData = null;
                        this.setLoader(false);
                    })
                }
                if (nextProps.coachDetails.searchCoachData.result.hasOwnProperty('myTeam') && nextProps.coachDetails.searchCoachData.result.myTeam.length !== 0) {
                    let val = nextProps.coachDetails.searchCoachData.result.teams;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.coachArr.concat(temp);
                    for (let i = 0; i < nextProps.coachDetails.searchCoachData.result.myTeam.length; i++) {
                        let element = nextProps.coachDetails.searchCoachData.result.myTeam[i]
                        if (!this.state.coachArr.find(e => e._id === element._id)) {
                        joined.unshift(element)
                        }
                    }

                    this.setState({
                        coachArr: joined,
                        selectedId: this.state.selectedId.length ? this.state.selectedId : nextProps.coachDetails.searchCoachData.result.myTeam.map(e => { return e._id }),
                    }, () => {
                        nextProps.coachDetails.searchCoachData = null;
                        this.setLoader(false);
                    })
                } else {
                    let val = nextProps.coachDetails.searchCoachData.result.teams;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.coachArr.concat(temp);
                    this.setState({
                        coachArr: joined
                    }, () => {
                        nextProps.coachDetails.searchCoachData = null;
                        this.setLoader(false);
                    });
                }
            }
        } else {
            this.setLoader(false);
            nextProps.coachDetails.searchCoachData = null;
        }

    }

    setSelectValue = (val, cb) => {
        this.setState({
            coachArr: val,
        }, () => cb(true));
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "coach") {
            if (!!this.state.selectedId.find(e => e === value)) {
                this.state.selectedId.splice(this.state.selectedId.findIndex(e => e === value), 1)
                this.setState((prevState) => ({
                    ...prevState,
                    selectedId: this.state.selectedId,
                }));
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    selectedId: [...this.state.selectedId, value],
                }));
            }
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    page: 1,
                    limit: 10,
                    name: value
                },
            }), () => {
                if (value.length !== 0) {
                    this.setLoader(true);
                    this.props.coachList(this.state.filter);
                } else {
                    this.state.coachArr = [];
                    this.props.coachDetails.searchTeamData = null;
                    this.setLoader(true);
                    this.props.coachList(this.state.filter);
                }
            });
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.coachList(this.state.filter);
    }

    renderCoachList = () => {
        return (
            this.state.coachArr.length !== 0 ?
                this.state.coachArr.map((list, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={list.coach.profileUrl ? list.coach.profileUrl : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{list.coach && list.coach.fname + " " + list.coach.lname}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="checkbox"
                                    id="Baseball"
                                    value={list._id}
                                    name="coach"
                                    onChange={(e) => this.onHandleChange(e)}
                                    checked={!!this.state.selectedId.find((d) => d === list._id) ? true : false }
                                />
                                <span for="Baseball"></span>
                            </div>
                        </div>
                    );
                }) :
                <h4 className="text-center">No Data Found</h4>
        );
    }

    validationUpdate = (cb) => {
        if (this.state.selectedId.length !== 0) {
            cb({ data: { team: this.state.selectedId }, flag: true });
        } else {
            cb({ data: '', flag: false });
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    page: this.state.filter.page + 1
                }
            }), () => {
                this.setLoader(true);
                this.props.coachList(this.state.filter);
            })
        }
    }

    render() {
        const { loader, filter, invite } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg playes-team">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {this.props.profileData && this.props.profileData.fname && this.props.profileData.fname + " " + this.props.profileData.lname},
                            </h2>
                            <p>
                                We found some coaches in your area. Select the coaches you would like to follow, or skip.
                            </p>
                        </div>
                        {
                            !invite &&
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your coach" name="search" value={filter.name} onChange={this.onHandleChange} />
                                        {/* <Link className="serch-btn" onClick={this.onSearch}><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="scrollbar_team" onScroll={this.getScroll}>
                            <div className="select_team ml-0">
                                {this.renderCoachList()}
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

coach.propTypes = {
    coachList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    coachDetails: state.coachFanDetails
});

export default connect(mapStateToProps, { coachList })(coach);
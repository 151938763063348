import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

class addTeam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openTeam: false,
            file: null,
            slink: ""
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.shop_validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        this.props.onRef(this);
    }

    setTextBox = (type) => {
        this.setState({ slink: type });
    }

    addTeamValidation = (cb) => {
        if (this.validator.allValid()) {
            cb(true);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            cb(false);
        }
    }

    submitData = () => {
        if (this.validator.allValid()) {
          this.props.onAddRoster();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { flag, rosterInput, imageUrl, shopFlg } = this.props;
        console.log("rosterInput",rosterInput);
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3>Setup Your Roster</h3>
                                <div className="modal_close" onClick={this.props.close}><img src={imagesArr.modal_close} alt="modal_close" /></div>
                            </div>
                            <div className="modal-body">
                                <div className="team-body-inner">
                                    <label className="common-lable">Program Name</label>
                                    <div className="form-group mb-0">
                                        <input type="text" name="name" value={rosterInput.programName}  className="form-control" id="usr" placeholder="Enter your program name" />
                                    </div>

                                    <div className="check_input row">

                                        <div className="check_inputbox">
                                            <label className="common-lable">Roster Name</label>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Enter roster name" id="usr" name="rosterName" value={rosterInput.rosterName} onChange={(e)=>this.props.onChange(e)}  />
                                                <span className="validMsg">{this.validator.message("Roster Name", rosterInput.rosterName, "required", { className: "text-danger" })}</span>
                                            </div>
                                        </div>


                                        <div className="check_inputbox">
                                            <label className="common-lable">
                                                Is this roster for a team or facility?
                                            </label>
                                            <div className="form-group">
                                                <div className="multi-radio">
                                                    <div className="check_inputradio">
                                                        <label className="round-radio"> Team
                                                            <input type="radio" id="team" name="type"  value={1} checked={rosterInput.type === 1 ? true : false} onChange={(e)=>this.props.onChange(e)} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div className="check_inputradio">
                                                        <label className="round-radio"> Facility
                                                            <input type="radio" id="facility" name="type" value={2} checked={rosterInput.type === 2 ? true : false} onChange={(e)=>this.props.onChange(e)} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                       


                                    <div className="check_input row">
                                        <div className="check_inputbox">
                                            <label className="common-lable">Competition Level</label>
                                            <select name="competitionLevel" className="custom-select" value={rosterInput.competitionLevel} onChange={(e)=>this.props.onChange(e)}>
                                                <option value="" selected disabled>Competition Level</option>
                                                <option value="Youth" >Youth</option>
                                                <option value="High School" >High School</option>
                                                <option value="College" >College</option>
                                                <option value="Independent" >Independent</option>
                                                <option value="MLB" >MLB</option>
                                            </select>
                                        </div>


                                        { (rosterInput.competitionLevel === "Youth" || rosterInput.competitionLevel === "High School") && 
                                        <div className="check_inputbox">
                                            <label className="common-lable">Age Group</label>
                                            <select name="ageGroup" className="custom-select" value={rosterInput.ageGroup} onChange={(e)=>this.props.onChange(e)}>
                                                <option value="" selected disabled>Select age group</option>
                                                {rosterInput.competitionLevel === "Youth" &&
                                                    <>
                                                        <option value="6">6U</option>
                                                        <option value="7">7U</option>
                                                        <option value="8">8U</option>
                                                        <option value="9">9U</option>
                                                        <option value="10">10U</option>
                                                        <option value="11">11U</option>
                                                        <option value="12">12U</option>
                                                        <option value="13">13U</option>
                                                        <option value="14">14U</option> </>}
                                                {rosterInput.competitionLevel === "High School" &&
                                                    <>
                                                        <option value="15">15U</option>
                                                        <option value="16">16U</option>
                                                        <option value="17">17U</option>
                                                        <option value="18">18U</option> </>}
                                                {/* <option value="Independent">Independent</option>
                  <option value="International">International</option>
                  <option value="miLB">MiLB</option> */}
                                            </select>
                                            {/* <span className="validMsg">{validatorAgeGrp.current.message("Age Group", rosterInput.ageGroup, "required", { className: "text-danger" })}</span> */}
                                        </div>}




                                    </div>

                             
                                </div>
                                <div className="flex-align next-button">
                                    <Link className="btn" onClick={() => this.submitData()}>Next</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

addTeam.propTypes = {
};

const mapStateToProps = state => ({
    teamDetails: state.teamDetails
});

export default connect(mapStateToProps, {})(addTeam);
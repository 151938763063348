export const GET_PLAYER_BASIC_REQUEST = 'GET_PLAYER_BASIC_REQUEST';
export const GET_PLAYER_BASIC_SUCCESS = 'GET_PLAYER_BASIC_SUCCESS';
export const GET_PLAYER_BASIC_ERROR = 'GET_PLAYER_BASIC_ERROR';

export const GET_PLAYER_INVITATION_REQUEST = 'GET_PLAYER_INVITATION_REQUEST';
export const GET_PLAYER_INVITATION_SUCCESS = 'GET_PLAYER_INVITATION_SUCCESS';
export const GET_PLAYER_INVITATION_ERROR = 'GET_PLAYER_INVITATION_ERROR';

export const SAVE_PLAYER_BASIC_REQUEST = 'SAVE_PLAYER_BASIC_REQUEST';
export const SAVE_PLAYER_BASIC_SUCCESS = 'SAVE_PLAYER_BASIC_SUCCESS';
export const SAVE_PLAYER_BASIC_ERROR = 'SAVE_PLAYER_BASIC_ERROR';

export const SAVE_PLAYER_ACCEPT_REQUEST = 'SAVE_PLAYER_ACCEPT_REQUEST';
export const SAVE_PLAYER_ACCEPT_SUCCESS = 'SAVE_PLAYER_ACCEPT_SUCCESS';
export const SAVE_PLAYER_ACCEPT_ERROR = 'SAVE_PLAYER_ACCEPT_ERROR';


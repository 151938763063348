import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    COMPANY_LIST_REQUEST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_ERROR,

    COM_TOP_EVENT_REQUEST,
    COM_TOP_EVENT_SUCCESS,
    COM_TOP_EVENT_ERROR,

    COM_UPCOMING_EVENT_REQUEST,
    COM_UPCOMING_EVENT_SUCCESS,
    COM_UPCOMING_EVENT_ERROR,

    COM_PAST_EVENT_REQUEST,
    COM_PAST_EVENT_SUCCESS,
    COM_PAST_EVENT_ERROR,

    COM_EVENT_STATE_REQUEST,
    COM_EVENT_STATE_SUCCESS,
    COM_EVENT_STATE_ERROR,

    COM_USER_EVENT_STATE_REQUEST,
    COM_USER_EVENT_STATE_SUCCESS,
    COM_USER_EVENT_STATE_ERROR,

} from '../../constants/common/events.const';
import histrory from '../../../History';
import moment from "moment";

export const getCompanyList = (data, packType) => (dispatch) => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
      setHeader = {
        'x-auth-token': Auth_Company_Key,
        'Accept': 'application/json'
      }
      checkIsValidCompanyToken(histrory);
    } else {
      setHeader = {
        'Accept': 'application/json'
      }
    }
    dispatch({ type: COMPANY_LIST_REQUEST })
    axios({
      method: 'GET',
      url: `${EVENT_API_Url}Company/companies/list`,
      headers: setHeader
    }).then(pack => {
      console.log("pack:----", pack);
      dispatch({
        type: COMPANY_LIST_SUCCESS,
        payload: pack.data.result.data
      })
    }).catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({ type: COMPANY_LIST_ERROR, error: error.response.data })
    });
  };

  
export const topComEvents = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_TOP_EVENT_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/web/list?isPresent=${data.isPresent}&companies=${data.companies}&eventType=${data.eventType}&isCollegeShowcase=${data.isCollegeShowcase}`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allevent => {
        dispatch({
            type: COM_TOP_EVENT_SUCCESS,
            payload: allevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_TOP_EVENT_ERROR, error: error.response.data.message })
        }
    });
};

export const upcomingComEvents = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_UPCOMING_EVENT_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/web/list?isPresent=${data.isPresent}&companies=${data.companies}&state=${data.state}&eventTeamType=${data.eventTeamType}&startDate=${data.startDate ? moment.utc(moment(data.startDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString() : ""}&endDate=${data.endDate ? moment.utc(moment(data.endDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString() : ""}&eventType=${data.eventType}&limit=${data.limit}&page=${data.page}&isCollegeShowcase=${data.isCollegeShowcase}&currentDate=${data.currentDate}`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allevent => {
        dispatch({
            type: COM_UPCOMING_EVENT_SUCCESS,
            payload: allevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_UPCOMING_EVENT_ERROR, error: error.response.data.message })
        }
    });
};

export const pastComEvents = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_PAST_EVENT_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/web/list?isPresent=${data.isPresent}&companies=${data.companies}&state=${data.state}&startDate=${data.startDate ? moment.utc(moment(data.startDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString() : ""}&endDate=${data.endDate ? moment.utc(moment(data.endDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString() : ""}&eventType=${data.eventType}&eventTeamType=${data.eventTeamType}&limit=${data.limit}&page=${data.page}&isCollegeShowcase=${data.isCollegeShowcase}&currentDate=${data.currentDate}`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allevent => {
        dispatch({
            type: COM_PAST_EVENT_SUCCESS,
            payload: allevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_PAST_EVENT_ERROR, error: error.response.data.message })
        }
    });
};

export const getStatesForEvent = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_EVENT_STATE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/state/list`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allevent => {
        dispatch({
            type: COM_EVENT_STATE_SUCCESS,
            payload: allevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_EVENT_STATE_ERROR, error: error.response.data.message })
        }
    });
};

export const getStatesForUserEvent = (data) => dispatch => {
    console.log("data:--", data);
    checkIsValidCompanyToken(histrory);
    dispatch({ type: COM_USER_EVENT_STATE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/profile/state/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(alluserevent => {
        dispatch({
            type: COM_USER_EVENT_STATE_SUCCESS,
            payload: alluserevent.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_USER_EVENT_STATE_ERROR, error: error.response.data.message })
        }
    });
};

export const getStatesForPastEvent = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}showcase/state/list`,
    }).then(res => {
        dispatch({
            type: "STATE_LIST_PAST_EVENT",
            payload: res.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: "STATE_LIST_PAST_EVENT_ERROR", error: error.response.data.message })
        }
    });
};

export const getTournamentList = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/tournament`,
        data: data
    }).then(res => {
        dispatch({
            type: "TOURNAMENT_LIST_FILTER",
            payload: res.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: "TOURNAMENT_LIST_FILTER_ERROR", error: error.response.data.message })
        }
    });
};

export const getPastChampionList = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}showcase/pastChampion`,
        data: data
    }).then(res => {
        dispatch({
            type: "PAST_CHAMP_LIST",
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: "PAST_CHAMP_LIST_ERROR", error: error.response.data.message })
        }
    });
};

export const getTornamentListFilter = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/pw-historical-champions/eventTitle`,
        data: data
    }).then(res => {
        dispatch({
            type: "TOURNAMENT_FILTER_LIST",
            payload: res.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: "TOURNAMENT_FILTER_LIST_ERROR", error: error.response.data.message })
        }
    });
};

export const getAllPastChampList = (data) => dispatch => {
    console.log("data:--", data);
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/pw-historical-champions/filter/v2`,
        data: data
    }).then(res => {
        dispatch({
            type: "ALL_PAST_CHAMP_LIST",
            payload: res.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: "ALL_PAST_CHAMP_LIST_ERROR", error: error.response.data.message })
        }
    });
};



import axios from 'axios';
import { Auth_Key, API_Url, Authorization, checkIsValidToken } from '../../helpers/authToken';
import {
    GET_PACKAGE_REQUEST,
    GET_PACKAGE_SUCCESS,
    GET_PACKAGE_ERROR,
} from '../../constants/landing/bollr.const';

export const getPack = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: GET_PACKAGE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/setting/list/package/web`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(pack => {
        dispatch({
            type: GET_PACKAGE_SUCCESS,
            payload: pack.data.result
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PACKAGE_ERROR, error: error.response.data.message })
        }
    });
};


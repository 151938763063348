import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Player } from 'video-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getVideoDetail } from '../saga/actions/common/api.action';

class rankingHeroBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoPath: "",
        }
    }

    componentDidMount = () => {
        // this.props.increment();
        this.props.getVideoDetail();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.videoInfo &&
            nextProps.videoInfo.getVideoDataReq &&
            nextProps.videoInfo.getVideoDataData
        ) {
            nextProps.videoInfo.getVideoDataReq = false;
            console.log("nextProps.videoInfo.getVideoDataData:--", nextProps.videoInfo.getVideoDataData);
            if (nextProps.videoInfo.getVideoDataData.flag) {
                this.setState({
                    videoPath: nextProps.videoInfo.getVideoDataData.result.filePath,
                }, () => {
                    nextProps.videoInfo.getVideoDataData = null;
                    // this.props.decrement();
                });
            }
        }

    }

    render() {
        return (
            <>
            <div className="video-bg mb-70">
                <Player playsInline src={this.state.videoPath} />
            </div>
            </>
        )
    }
}

rankingHeroBanner.propTypes = {
    getVideoDetail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    videoInfo: state.api,
});

export default connect(mapStateToProps, { getVideoDetail })(rankingHeroBanner);
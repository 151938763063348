import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../../components/leftAuthImage';
import CommonHeroBanner from '../../components/commonHeroBanner';
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../businesslogic/commonCalls";
import { getCode, signupStep2 } from '../../saga/actions/authantication/signup/step2.action';
import moment from "moment";
import DatePicker from "react-datepicker";
import Preloader from '../../components/preloader';
// import DatePicker from 'react-date-picker';

class step2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {
                fname: "",
                lname: "",
                email: "",
                password: "",
                dob: "",
                socialId: "",
                type: "",
                cCode: "+1",
                phone: "",
                parentInfo: {
                    fullName: "",
                    email: "",
                    phone: "",
                    cCode: ""
                }
            },
            fullname: "",
            codeArr: "",
            disable: false,
            ptype: "password",
            opencal: false,
            loader: false,
            startDate: new Date()
        }

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.hasOwnProperty('email')) {
            let name = params.name.split(" ");
            this.setState({
                fullname: params.name,
                userInfo: {
                    ...this.state.userInfo,
                    fname: name[0] ? name[0] : "",
                    lname: name[1] ? name[1] : "",
                    email: params.email
                }
            })
        }
    }

    componentWillMount() {
        this.props.getCode();
        this.commonCall.getStorage("socialMedia", data => {
            if (data !== null) {
                this.setState((prevState) => ({
                    ...prevState,
                    userInfo: {
                        ...prevState.userInfo,
                        fname: (data.fname) ? data.fname + " " + data.lname : "",
                        lname: data.lname,
                        email: data.email ? data.email : "",
                        socialId: data.socialId,
                        type: data.type,
                    },
                    disable: data.type === 5 || data.type === 4 ? false : true,
                    fullname: (data.fname) ? data.fname + " " + data.lname : ""
                }));
            }

        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.setting) {
            if (nextProps.setting.settingData) {
                this.setState({
                    codeArr: nextProps.setting.settingData.countryCode.settingValue
                }, () => { nextProps.setting.settingData = null })
            }

            if (nextProps.setting.regiStep2Req &&
                nextProps.setting.regiStep2Data
            ) {
                nextProps.setting.regiStep2Req = false;
                if (nextProps.setting.regiStep2Data.flag) {
                    this.setLoader(false);
                    this.commonCall.successMessage(nextProps.setting.regiStep2Data.message);
                    this.props.history.push(`/verify/${nextProps.setting.regiStep2Data.result.verifyToken}`);
                    nextProps.setting.regiStep2Data = null;
                } else {
                    this.setLoader(false);
                    this.commonCall.errorMessage(nextProps.setting.regiStep2Data.message);
                    nextProps.setting.regiStep2Data = null;
                }
            }

        }
    }

    onChangeFn = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState({
                        userInfo: {
                            ...this.state.userInfo,
                            [name]: value
                        }
                    })
                }
            }
        } else if (name === "fullname") {
            this.setState({
                [name]: value
            })
        } else {
            this.setState({
                userInfo: {
                    ...this.state.userInfo,
                    [name]: value
                }
            })
        }

    };

    onChangeDate = (value) => {
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                dob: value
            }
        })
    }

    signUp = (e) => {
        e.preventDefault();
        let name = this.state.fullname.split(" ");
        if (this.validator.allValid()) {
            if (name[0] && name[1]) {
                this.setState({
                    userInfo: {
                        ...this.state.userInfo,
                        fname: name[0] ? name[0] : "",
                        lname: name[1] ? name[1] : "",
                    }
                }, () => {
                    let data = {
                        fname: this.state.userInfo.fname.trim(),
                        lname: this.state.userInfo.lname.trim(),
                        email: this.state.userInfo.email,
                        password: this.state.userInfo.password,
                        dob: moment(this.state.userInfo.dob).format("MM/DD/YYYY"),
                        cCode: "+1",
                        phone: this.state.userInfo.phone,
                        type: this.state.userInfo.type,
                        parentInfo: {
                            fullName: this.state.userInfo.parentInfo.fullName,
                            email: this.state.userInfo.parentInfo.email,
                            phone: this.state.userInfo.parentInfo.phone,
                            cCode: this.state.userInfo.parentInfo.cCode
                        }
                    }

                    if (this.calculate_age(this.state.userInfo.dob)) {
                        this.commonCall.setStorage("userStep1", data, flag => {
                            if (flag) {
                                this.props.history.push("/parentinfo");
                            }
                        });
                    } else {
                        this.commonCall.setStorage("userStep1", data, flag => {
                            if (flag) {
                                this.setLoader(true);
                                this.props.signupStep2(this.state.userInfo);
                            }
                        });
                    }
                })
            } else {
                this.commonCall.errorMessage("Enter valid fullname.");
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    calculate_age = (dob1) => {
        var ageDifMs = Date.now() - new Date(dob1).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970) <= 16;
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const user = this.state.userInfo;
        const { loader, fullname } = this.state
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <form>
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input type="text" name="fullname" value={fullname} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter your full name" />
                                        <span className="validMsg">{this.validator.message('Full Name', fullname, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text" name="email" value={user.email} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter your email" disabled={this.state.disable ? this.state.disable : false} />
                                        <span className="validMsg">{this.validator.message('Email', user.email, 'required|email')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Date of Birth</label>
                                        <div className="bc_relative">
                                            <DatePicker
                                                ref={(c) => (this._calendar = c)}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                            {months.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                    </div>
                                                )}
                                                selected={user.dob}
                                                maxDate={moment().subtract(1, 'days').toDate()}
                                                onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                onChange={this.onChangeDate}
                                                placeholderText="Enter your birth date"
                                            />
                                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                                this.state.opencal = !this.state.opencal;
                                                this._calendar.setOpen(this.state.opencal);
                                            }} />
                                        </div>
                                        <span className="validMsg">{this.validator.message('Date of Birth', user.dob, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <div className="svg_icon">
                                            <input type={this.state.ptype} name="password" value={user.password} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter password" />
                                            <span className="validMsg">{this.validator.message('Password', user.password, 'required')}</span>
                                            {this.state.ptype == "password" ? (
                                                <img src={imagesArr.eye} onClick={() => this.setState({ ptype: "text" })} alt="eye" />
                                            ) : (
                                                <img src={imagesArr.eyeopen} onClick={() => this.setState({ ptype: "password" })} alt="eye" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile number</label>
                                        <div className="svg_icon">
                                            <div className="input-group">
                                                <div className="counrty-no">
                                                    <select className="custom-select" name="cCode" value={user.cCode} onChange={this.onChangeFn}>
                                                        <option value="+1" selected={user.cCode === "+1" ? "selected" : ""}>
                                                            +1
                                                        </option>
                                                        {/* {
                                                            this.state.codeArr &&
                                                            this.state.codeArr.map(function (
                                                                obj,
                                                                i
                                                            ) {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        defaultValue={user.cCode}
                                                                        selected={
                                                                            user.cCode === obj.code
                                                                                ? "selected"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {obj.code}
                                                                    </option>
                                                                );
                                                            })
                                                        } */}
                                                    </select>
                                                </div>
                                                <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.onChangeFn} id="usr" placeholder="Enter mobile number" />
                                                <img src={imagesArr.smartphone} alt="eye" />
                                            </div>
                                            <span className="validMsg">{this.validator.message('Mobile number', user.phone, 'required|phone')}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn w-100 text-center" onClick={this.signUp}>Sign Up</button>
                                    </div>
                                    <div className="form-group mb-0 text-center">
                                        <p>By Signing up, you agree to the  |  <Link to="/terms-conditions" target="_blank">Terms</Link> & <Link to="/privacy-policy" target="_blank">privacy policy.</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

step2.propTypes = {
    getCode: PropTypes.func.isRequired,
    signupStep2: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    setting: state.step2,
});

export default connect(mapStateToProps, { getCode, signupStep2 })(step2);
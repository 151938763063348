import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { saveCoachData, otherTeamCoach } from '../../saga/actions/views/coach/profile/coachInfo.action';
import Preloader from '../../components/preloader';
import Confirmation from "./confirmation";

export class AddCoach extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sendReq: {
        fname: "",
        lname: "",
        cCode: "+1",
        phone: "",
        email: "",
        isAccept: false
      },
      editId: "",
      popupText: "",
      popupOpen: false,
      coachId: "",
    };

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
      if (this.props.type === "edit") {
        let data = this.props.editData;
        console.log("data:----", data);
        this.setState((prevState) => ({
          ...prevState,
          sendReq: {
            fname: data.fname,
            lname: data.lname,
            cCode: "+1",
            phone: data.phone,
            email: data.email,
            isAccept: data.isAccept
          },
          editId: data._id
        }));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coachDetails &&
      nextProps.coachDetails.addNewCoachReq &&
      nextProps.coachDetails.addNewCoachData
    ) {
      console.log("nextProps.addNewCoachData:-----", nextProps.coachDetails.addNewCoachData);
      nextProps.coachDetails.addNewCoachReq = false;
      if (nextProps.coachDetails.addNewCoachData.flag) {
        let result = nextProps.coachDetails.addNewCoachData.result;
        if (result.popup) {
          this.setState({
            popupText: result.fname + " " + result.lname + " is already coach of " + result.profile.parentTeam.name,
            popupOpen: result.popup,
            coachId: result._id
          }, () => {
            nextProps.coachDetails.addNewCoachData = null;
            this.setLoader(false);
          })
        } else {
          this.commonCall.successMessage(nextProps.coachDetails.addNewCoachData.message);
          nextProps.coachDetails.addNewCoachData = null;
          this.setLoader(false);
          this.props.close();
        }
      } else {
        this.commonCall.errorMessage(nextProps.coachDetails.addNewCoachData.message);
        nextProps.coachDetails.addNewCoachData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.coachDetails &&
      nextProps.coachDetails.otherTeamCoachReq &&
      nextProps.coachDetails.otherTeamCoachData
    ) {
      console.log("nextProps.otherTeamCoachData:-----", nextProps.coachDetails.otherTeamCoachData);
      nextProps.coachDetails.otherTeamCoachReq = false;
      if (nextProps.coachDetails.otherTeamCoachData.flag) {
        this.setState({
          popupText: "",
          popupOpen: false
        }, () => {
          nextProps.coachDetails.otherTeamCoachData = null;
          this.setLoader(false);
          this.props.close();
        })
      } else {
        this.commonCall.errorMessage(nextProps.coachDetails.otherTeamCoachData.message);
        nextProps.coachDetails.otherTeamCoachData = null;
        this.setLoader(false);
      }
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  onHandleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 12) {
        if (re.test(value) || value.length <= 0) {
          this.setState((prevState) => ({
            ...prevState,
            sendReq: {
              ...prevState.sendReq,
              [name]: value
            },
          }));
        }
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          [name]: value
        },
      }));
    }
  }

  saveCoach = () => {
    if (this.validator.allValid()) {
      console.log(this.state.sendReq);
      if (this.props.type === "add") {
        if (!!this.props.coachList.find((e) => e.email === this.state.sendReq.email)) {
          return this.commonCall.errorMessage("Email already exits.")
        }
        this.setLoader(true);
        this.props.saveCoachData(this.state.sendReq);
      }
      else {
        if (!!this.props.coachList.find((e, j) => { if (j !== this.props.index) { if (e.email === this.state.sendReq.email) { return true } else return false } })) {
          return this.commonCall.errorMessage("Email already exits.")
        }
        let fdata = this.state.sendReq;
        fdata._id = this.state.editId;
        console.log("fdata:--", fdata);
        this.setLoader(true);
        this.props.saveCoachData(fdata);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  closePopup = () => {
    this.setState({
      popupText: "",
      popupOpen: false
    })
  }

  confirmPopup = () => {
    console.log("yes confirm");
    this.setLoader(true);
    this.props.otherTeamCoach({ coachId: this.state.coachId });
  }

  render() {
    const { sendReq, loader, popupOpen, popupText } = this.state;
    const { flag, type, teamName } = this.props;
    return (
      <>
        {
          !popupOpen &&
          <section className={`common-modal ${flag ? "show" : ""}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="green-header modal-header">
                  <h3>{type === "add" ? "Add" : "Edit"} Staff</h3>
                  <div className="modal_close" onClick={this.props.close}>
                    <img src={imagesArr.white_modal_close} alt="modal_close" />
                  </div>
                </div>
                <div className="modal-body">
                  <div className="team-body-inner">
                    <div className="flex-align row">
                      <div className="form-group">
                        <label className="common-lable">First Name</label>
                        <input
                          type="text"
                          name="fname"
                          value={sendReq.fname}
                          onChange={this.onHandleChange}
                          className="form-control"
                          placeholder="First Name"
                          disabled={sendReq.isAccept ? true : false}
                        />
                        <span className="validMsg">
                          {this.validator.message(
                            "first name",
                            sendReq.fname,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="common-lable">Last Name</label>
                        <input
                          type="text"
                          name="lname"
                          value={sendReq.lname}
                          onChange={this.onHandleChange}
                          className="form-control"
                          placeholder="Last Name"
                          disabled={sendReq.isAccept ? true : false}
                        />
                        <span className="validMsg">
                          {this.validator.message(
                            "last name",
                            sendReq.lname,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex-align row">
                      <div className="form-group">
                        <label className="common-lable">Phone Number</label>
                        <div className="svg_icon">
                          <div className="input-group">
                            <div className="counrty-no">
                              <select className="custom-select" name="cCode" value={sendReq.cCode} onChange={this.onHandleChange}>
                                <option value="+1" selected={
                                  sendReq.cCode === "+1"
                                    ? "selected"
                                    : ""
                                }>
                                  +1
                                </option>
                              </select>
                            </div>
                            <input
                              type="text"
                              name="phone"
                              value={sendReq.phone}
                              onChange={this.onHandleChange}
                              className="form-control"
                              placeholder="Enter phone number"
                            />
                            <img src={imagesArr.smartphone} alt="eye" />
                          </div>
                          {/* <span className="validMsg">
                          {this.validator.message(
                            "phone number",
                            sendReq.phone,
                            "required|phone"
                          )}
                        </span> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="common-lable">Email Address</label>
                        <input
                          type="text"
                          name="email"
                          value={sendReq.email}
                          onChange={this.onHandleChange}
                          className="form-control"
                          placeholder="Enter email address"
                          disabled={sendReq.isAccept ? true : false}
                        />
                        <span className="validMsg">
                          {this.validator.message(
                            "Email Address",
                            sendReq.email,
                            "required|email"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex-align next-button">
                    <Link className="btn light-green" onClick={() => this.saveCoach()}>
                      Save
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
        {
          popupOpen &&
          <Confirmation
            flag={popupOpen}
            popupText={popupText}
            type="existCoach"
            close={this.closePopup}
            ok={this.confirmPopup}
            teamName={teamName}
          />
        }
        <Preloader flag={loader} />
      </>
    );
  }
}

AddCoach.propTypes = {
  saveCoachData: PropTypes.func.isRequired,
  otherTeamCoach: PropTypes.func.isRequired,
  // editPlayerData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  coachDetails: state.coachInfo,
});

export default connect(mapStateToProps, { saveCoachData, otherTeamCoach })(AddCoach);


export const LIST_PLAYER_REQUEST = 'LIST_PLAYER_REQUEST';
export const LIST_PLAYER_SUCCESS = 'LIST_PLAYER_SUCCESS';
export const LIST_PLAYER_ERROR = 'LIST_PLAYER_ERROR';

export const LINKED_PLAYER_LIST_REQUEST = 'LINKED_PLAYER_LIST_REQUEST';
export const LINKED_PLAYER_LIST_SUCCESS = 'LINKED_PLAYER_LIST_SUCCESS';
export const LINKED_PLAYER_LIST_ERROR = 'LINKED_PLAYER_LIST_ERROR';

export const ADD_PLAYER_REQUEST = 'ADD_PLAYER_REQUEST';
export const ADD_PLAYER_SUCCESS = 'ADD_PLAYER_SUCCESS';
export const ADD_PLAYER_ERROR = 'ADD_PLAYER_ERROR';

export const ADD_LINKED_PLAYER_PARENT_REQUEST = 'ADD_LINKED_PLAYER_PARENT_REQUEST';
export const ADD_LINKED_PLAYER_PARENT_SUCCESS = 'ADD_LINKED_PLAYER_PARENT_SUCCESS';
export const ADD_LINKED_PLAYER_PARENT_ERROR = 'ADD_LINKED_PLAYER_PARENT_ERROR';


export const COMPLETE_PARENT_REQUEST = 'COMPLETE_PARENT_REQUEST';
export const COMPLETE_PARENT_SUCCESS = 'COMPLETE_PARENT_SUCCESS';
export const COMPLETE_PARENT_ERROR = 'COMPLETE_PARENT_ERROR';
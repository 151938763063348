import {
    COM_RECOMMENDED_FORM_REQUEST,
    COM_RECOMMENDED_FORM_SUCCESS,
    COM_RECOMMENDED_FORM_ERROR,
} from '../../constants/common/recommendProspect.const';

const initialState = {
    saveRecommendedFormReq: false,
    saveRecommendedFormData: null,
    saveRecommendedFormError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Recommended form req    
        case COM_RECOMMENDED_FORM_REQUEST:
            return {
                ...state,
                saveRecommendedFormReq: true,
            };

        case COM_RECOMMENDED_FORM_SUCCESS:
            return {
                ...state,
                saveRecommendedFormData: action.payload,
            };
        case COM_RECOMMENDED_FORM_ERROR:
            return {
                ...state,
                saveRecommendedFormError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import Footer from "../../../../containers/footer";
import commonCalls from "../../../../businesslogic/commonCalls";
import { getLeagueDetails, getLeagueParticipating } from '../../../../saga/actions/common/leagueDetails.action';
import { fetchPaymentIntent, getInstallmentDetails, registerInstallment, eventRegistrationPayment } from '../../../../saga/actions/common/eventDetails.action';
import Preloader from "../../../../components/preloader";
import LeagueRow from './details/leagueRow';
import LeagueInfo from './details/leagueInfo';
import Location from './details/location';
import SocialMediaLink from './details/socialMediaLink';
import CreditCard from "../payment/creditCard";
import { commonHeader } from "../../../../businesslogic/content";

class leagueRirectPay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leagueId: "",
            loader: false,
            leagueDetailData: [],
            participationList: [],
            teamArr: [],
            loadHeader: false,
            eventJoin: {
                league: "",
                isManager: true,
                managerName: "",
                managerEmail: "",
                managerPhone: "",
                waiversImg: "",
                insuranceImg: "",
                paymentType: 3,
                couponCode: "",
                metaPayment: {},
                paymentMethod: "",
                amount: 0,
                question: ""
            },
            sendInstallment: {
                objectId: "",
                module: "",
                paymentType: 3,
                paymentMethod: 1,
                amount: "",
                installments: {},
                metaPayment: {},
                notificationId: ""
            },
            sendTotify: {
                notificationId: ""
            },
            alreadyPaid: false,
            creditCard: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let leagueId = url.pathname.split("/")[2];
        this.setState({
            leagueId: leagueId,
            sendTotify: {
                notificationId: url.search ? url.search.split("=")[1] : ""
            },
            sendInstallment: {
                ...this.state.sendInstallment,
                objectId: leagueId,
                notificationId: url.search ? url.search.split("=")[1] : ""
            },
        }, () => {
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueDetailsReq &&
            nextProps.leagueDetails.leagueDetailsData
        ) {
            nextProps.leagueDetails.leagueDetailsReq = false;
            console.log("nextProps.leagueDetails:--", nextProps.leagueDetails.leagueDetailsData);
            if (nextProps.leagueDetails.leagueDetailsData.flag) {
                this.setState({
                    leagueDetailData: nextProps.leagueDetails.leagueDetailsData.result,
                    eventJoin: {
                        ...this.state.eventJoin,
                        league: this.state.leagueId,
                        amount: nextProps.leagueDetails.leagueDetailsData.result.payment.feesLeague
                    },
                }, () => {
                    if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== this.state.leagueDetailData.dsCompanies._id) {
                        let cname = this.state.leagueDetailData.dsCompanies.name === "GOBTournament" ? "Greater Orlando Baseball" : this.state.leagueDetailData.dsCompanies.name;
                        let item = commonHeader.events.filter((e) => e.label === cname)[0];
                        localStorage.setItem("cid", this.state.leagueDetailData.dsCompanies._id)
                        localStorage.setItem("company", item.key)
                        localStorage.setItem("companyColor", JSON.stringify(item.colors));
                        this.setState({
                            loadHeader: true
                        })
                    } else {
                        this.setState({
                            loadHeader: true
                        })
                    }

                    nextProps.leagueDetails.leagueDetailsData = null;
                    this.props.getLeagueParticipating(this.state.leagueId);
                    // this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueDetailsData.message);
                nextProps.leagueDetails.leagueDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueParticipationReq &&
            nextProps.leagueDetails.leagueParticipationData
        ) {
            nextProps.leagueDetails.leagueParticipationReq = false;
            if (nextProps.leagueDetails.leagueParticipationData.flag) {
                this.setState({
                    participationList: nextProps.leagueDetails.leagueParticipationData.result.data
                }, () => {
                    nextProps.leagueDetails.leagueParticipationData = null;
                    if (this.state.sendTotify.notificationId) {
                        this.props.getInstallmentDetails(this.state.sendTotify);
                    } else {
                        this.setLoader(false);
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueParticipationData.message);
                nextProps.leagueDetails.leagueParticipationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getInstallmentDetailsReq &&
            nextProps.eventDetails.getInstallmentDetailsData
        ) {
            console.log("nextProps.eventDetails.getInstallmentDetailsData:--", nextProps.eventDetails.getInstallmentDetailsData);
            nextProps.eventDetails.getInstallmentDetailsReq = false;
            if (nextProps.eventDetails.getInstallmentDetailsData.flag) {
                let fdata = nextProps.eventDetails.getInstallmentDetailsData.result;
                if (fdata.alreadyPaid) {
                    this.setState({
                        alreadyPaid: fdata.alreadyPaid
                    }, () => {
                        nextProps.eventDetails.getInstallmentDetailsData = null;
                        this.setLoader(false);
                    })
                } else {
                    this.setState({
                        sendInstallment: {
                            ...this.state.sendInstallment,
                            module: "league",
                            amount: (fdata.paymentType === 2 || fdata.paymentType === 4) ? fdata.payLater.price : fdata.installment.price,
                            installments: fdata.paymentType === 3 ? fdata.installment : {},
                            paymentType: fdata.paymentType,
                            rosterTeamId: fdata.rosterTeamId
                        },
                        alreadyPaid: fdata.alreadyPaid
                    }, () => {
                        nextProps.eventDetails.getInstallmentDetailsData = null;
                        this.setLoader(false);
                    })
                }
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getInstallmentDetailsData.message);
                nextProps.eventDetails.getInstallmentDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getPaymentIntentReq &&
            nextProps.eventDetails.getPaymentIntentData
        ) {
            nextProps.eventDetails.getPaymentIntentReq = false;
            if (nextProps.eventDetails.getPaymentIntentData.flag) {
                localStorage.setItem(
                    "stripeIntent",
                    nextProps.eventDetails.getPaymentIntentData.result.data
                );
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setState({
                    creditCard: true
                }, () => {
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getPaymentIntentData.message);
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.eventRegistrationReq &&
            nextProps.eventDetails.eventRegistrationData
        ) {
            nextProps.eventDetails.eventRegistrationReq = false;
            console.log("eventRegistrationData:--", nextProps.eventDetails.eventRegistrationData);
            if (nextProps.eventDetails.eventRegistrationData.flag) {
                let tid = nextProps.eventDetails.eventRegistrationData.result._id;
                this.commonCall.successMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                var data = {
                    transactionId: tid,
                    amount: this.state.eventJoin.amount,
                    paymentType: 1,
                    card: "**** **** **** 4242"
                }
                localStorage.setItem("transaction", JSON.stringify(data));
                this.props.history.push(`/league-joined/${this.state.eventJoin.league}`);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.payInstallmentReq &&
            nextProps.eventDetails.payInstallmentData
        ) {
            nextProps.eventDetails.payInstallmentReq = false;
            console.log("payInstallmentData:--", nextProps.eventDetails.payInstallmentData);
            if (nextProps.eventDetails.payInstallmentData.flag) {
                let tid = nextProps.eventDetails.payInstallmentData.result._id;
                var data = {
                    transactionId: tid,
                    amount: this.state.sendInstallment.amount,
                    paymentType: nextProps.eventDetails.payInstallmentData.result.paymentType, 
                    card: "**** **** **** 4242"
                }
                nextProps.eventDetails.payInstallmentData = null;
                localStorage.setItem("transaction", JSON.stringify(data));
                this.props.history.push(`/league-joined/${this.state.leagueId}`);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.payInstallmentData.message);
                nextProps.eventDetails.payInstallmentData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.commonData) {
            if (nextProps.profileDetails.commonData.flag) {
                this.setState({
                    teamArr: nextProps.profileDetails.commonData.result.profile.team
                })
            }
        }
    }

    getDetails = () => {
        if (localStorage.getItem("webglToken") !== null) {
            this.props.getLeagueDetails(this.state.leagueId, true);
        } else {
            this.props.getLeagueDetails({
                leagueId: this.state.leagueId
            }, false);
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    stripeIntent = () => {
        var payment;
        if (this.state.sendTotify.notificationId) {
            payment = {
                amount: this.state.sendInstallment.amount,
                objetId: this.state.leagueDetailData._id,
                module: 1,
                method: "stripe",
            };
        } else {
            payment = {
                amount: this.state.leagueDetailData.payment.feesLeague,
                objetId: this.state.leagueDetailData._id,
                module: 1,
                method: "stripe",
            };
        }
        console.log("payment:-pp", payment);
        this.setLoader(true);
        this.props.fetchPaymentIntent(payment);
    }

    onHandleClose = () => {
        this.setState({
            creditCard: false
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    stripePaymentSuccess = (payment) => {
        this.setState({
            creditCard: false,
            eventJoin: {
                ...this.state.eventJoin,
                metaPayment: payment,
            },
            sendInstallment: {
                ...this.state.sendInstallment,
                metaPayment: payment,
            }
        }, () => {
            document.body.classList.remove("modal-open");
            this.registerEvent();
        })
    };

    stripePaymentError = (error) => {
        this.commonCall.errorMessage(error);
        this.setLoader(false);
    };

    registerEvent = () => {
        if (this.state.sendTotify.notificationId) {
            console.log("this.state.sendInstallment:--", this.state.sendInstallment);
            this.props.registerInstallment(this.state.sendInstallment);
        } else {
            console.log("this.state.eventJoin:--", this.state.eventJoin);
            this.props.eventRegistrationPayment(this.state.eventJoin);
        }
    }

    render() {
        const { loader, leagueDetailData, participationList, leagueId, teamArr, loadHeader, sendInstallment, alreadyPaid, creditCard } = this.state;
        return (
            loadHeader &&
            <div>
                <Header />
                <CommonHeroBanner dataList={leagueDetailData} />
                <div className="event_details">
                    <div className="container">
                        <LeagueRow
                            directPay={true}
                            paymentIntent={this.stripeIntent}
                            teamArr={teamArr}
                            dataList={leagueDetailData}
                            isHideJoinButton={false}
                            leagueId={leagueId}
                            complete={false}
                            isHideAddEventButton={false}
                            showAmount={localStorage.getItem("webglToken") ? true : false}
                            directAmout={sendInstallment.amount}
                            alreadyPaid={alreadyPaid}
                        />
                        <div className="row">
                            {
                                leagueDetailData.length !== 0 &&
                                <LeagueInfo dataList={leagueDetailData} pList={participationList} />
                            }
                            <div className="event_right_side">
                                <Location dataList={leagueDetailData} />
                                <SocialMediaLink dataList={leagueDetailData} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
                {
                    creditCard &&
                    <CreditCard
                        flag={creditCard}
                        close={this.onHandleClose}
                        stripePaymentSuccess={this.stripePaymentSuccess}
                        stripePaymentError={this.stripePaymentError}
                        amount={leagueDetailData.payment && leagueDetailData.payment.feesLeague}
                        setLoader={this.setLoader}
                    />
                }
            </div>
        )
    }
}

leagueRirectPay.propTypes = {
    getLeagueDetails: PropTypes.func.isRequired,
    getLeagueParticipating: PropTypes.func.isRequired,
    getInstallmentDetails: PropTypes.func.isRequired,
    fetchPaymentIntent: PropTypes.func.isRequired,
    registerInstallment: PropTypes.func.isRequired,
    eventRegistrationPayment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leagueDetails: state.leagueDetails,
    eventDetails: state.eventDetails,
    profileDetails: state.headerData,
});
var leagueRirectPayComponent = connect(mapStateToProps, { getLeagueDetails, getLeagueParticipating, fetchPaymentIntent, eventRegistrationPayment, getInstallmentDetails, registerInstallment })(leagueRirectPay);
export default withRouter(leagueRirectPayComponent);
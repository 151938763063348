import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { Link } from "react-router-dom";
import { imagesArr } from '../../assets/images';
import LandingVideo from '../../views/popup/landingVideo';
import { getPack } from '../../saga/actions/landing/bollr.action';
import commonCalls from "../../businesslogic/commonCalls";
import Preloader from '../../components/preloader';
import Slider from "react-slick";
import { Player } from 'video-react';

class BollR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIndex: [],
            tabs: 0,
            webPack: {
                role: "",
                productCode: "BALLR"
            },
            videoPopup: false,
            pack: [],
            planType: 1,
            loader: false,
            showVideo: "",
            userRole: "",
            fIndex: [],
            subCoach: false,
            monthPrice: "",
        }
        this.commonCall = new commonCalls();
        this.learnmore = React.createRef();
        this.pricingRef = React.createRef();
    }

    handleTabs = (e) => {
        this.setState({
            tabs: e
        })
    }

    openVideoPopup = (video) => {
        this.setState({
            videoPopup: true,
            showVideo: video
        })
    }

    closePopup = () => {
        document.body.classList.remove('video-open');
        this.setState({
            videoPopup: false
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        if (localStorage.getItem("scrollLanding") !== null) {
            setTimeout(() => {
                this.gotoPricing();
                localStorage.removeItem("scrollLanding");
            }, 500);
        }

        let currentHash = document.location.hash

        if (currentHash) {
            this.gotoPricing()
        }
        if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken") && !localStorage.getItem("rosterToken")) {
            this.setState({
                userRole: "Player",
                webPack: {
                    role: "Player",
                    productCode: "BALLR"
                },
            }, () => {
                this.setLoader(true);
                this.props.getPack(this.state.webPack);
            })
        }
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileDetails &&
            nextProps.profileDetails.headReq &&
            nextProps.profileDetails.headData
        ) {
            this.setState((prevState) => ({
                ...prevState,
                userRole: "Coach",
                webPack: {
                    ...prevState.webPack,
                    role: nextProps.profileDetails.headData.result.profile.role
                },
                subCoach: nextProps.profileDetails.headData.result.profile.subCoach
            }), () => {
                this.props.bollrDetails.packData = null;
                this.setLoader(true);
                this.props.getPack(this.state.webPack);
            });
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.headPlayerReq &&
            nextProps.profileDetails.headPlayerData
        ) {
            this.setState((prevState) => ({
                ...prevState,
                userRole: "Player",
                webPack: {
                    ...prevState.webPack,
                    role: nextProps.profileDetails.headPlayerData.result.profile.role
                },
            }), () => {
                this.props.bollrDetails.packData = null;
                this.setLoader(true);
                this.props.getPack(this.state.webPack);
            });
        }

        if (nextProps.profileDetails &&
            nextProps.profileDetails.headFanData &&
            nextProps.profileDetails.headFanReq
        ) {
            if (nextProps.profileDetails.headFanData.result.profile) {
                this.setState((prevState) => ({
                    ...prevState,
                    userRole: "Player",
                    webPack: {
                        ...prevState.webPack,
                        role: "Player"
                    },
                }), () => {
                    this.props.bollrDetails.packData = null;
                    this.setLoader(true);
                    this.props.getPack(this.state.webPack);
                })
            }
        }

        if (nextProps.bollrDetails &&
            nextProps.bollrDetails.packReq &&
            nextProps.bollrDetails.packData
        ) {
            nextProps.bollrDetails.packReq = false;
            if (nextProps.bollrDetails.packData.data.length !== 0) {
                const pl = nextProps.bollrDetails.packData.data.map(item => {
                    item.selectedType = 1;
                    return item
                })
                this.getPlans(pl, data => {
                    var temp, size;
                    size = data.length;
                    for (var i = 0; i < size; i++) {
                        for (var j = i + 1; j < size; j++) {
                            if (data[i].amount > data[j].amount) {
                                temp = data[i];
                                data[i] = data[j];
                                data[j] = temp;
                            }
                        }
                    }
                    this.setState({
                        pack: pl,
                        monthPrice: data[0],
                    }, () => {
                        nextProps.bollrDetails.packData = null;
                        this.setLoader(false);
                    })
                });
            } else {
                nextProps.bollrDetails.packData = null;
                this.setLoader(false);
            }
        }
    }

    // go to Learnmore section
    gotoLernmore() {
        let LernmoreSecTop = this.learnmore.current.offsetTop
        let headerHeight = document.getElementById('bc-header').offsetHeight
        window.scrollTo({
            top: (LernmoreSecTop - (headerHeight - 24)),
            behavior: 'smooth'
        });
    }

    // go to pricing section
    gotoPricing() {
        let pricingSecTop = this.pricingRef.current.offsetTop
        let headerHeight = document.getElementById('bc-header').offsetHeight
        window.scrollTo({
            top: (pricingSecTop - (headerHeight - 24)),
            behavior: 'smooth'
        });
    }

    showDetails = (index) => {
        if (this.state.showIndex.find((d) => d === index)) {
            // subtraction
            let tempArray = this.state.showIndex.filter((d) => d !== index);
            this.setState((prevState) => ({
                ...prevState,
                showIndex: tempArray,
            }));
        } else {
            // addition
            this.setState((prevState) => ({
                ...prevState,
                showIndex: [...prevState.showIndex, index],
            }));
        }

    }

    getPlans = (plans, cb) => {
        let pArr = [];
        plans.length > 0 &&
            plans.forEach((data) => {
                data.plans.forEach((item) => {
                    if (item.type === 1) {
                        item.trialPeriod = data.trialPeriod
                        pArr.push(item);
                    }
                })
            })
        cb(pArr);
    }

    setPlan = (plans, type) => {
        var isPlans = "";
        plans.length > 0 &&
            plans.forEach((data) => {
                if (data.type === type) return (isPlans = data);
            });
        return isPlans;
    }

    getChangePlans = (pack, id, type) => {
        pack.map(item => {
            if (item._id === id) {
                Object.assign(item, { selectedType: type });
            }
        })
        this.setState({
            pack: pack
        })
    }

    getTrial = (plans, type) => {
        var isPlans = "";
        plans.length > 0 &&
            plans.forEach((data) => {
                if (data.type === type) return (isPlans = data);
            });
        return isPlans;
    }

    renderPlansList = (data) => {
        const { pack, showIndex, userRole, subCoach } = this.state;

        return (
            data &&
            data.plans &&
            data.plans.map((list, i) => {
                return (
                    data.selectedType === list.type &&
                    <div className={`main-price ${showIndex.find((d) => d === list._id) ? "highlight" : ""}`}>
                        <div className="price-title">{data.type === "DiamondKineticsPitcherTracker" ? "Diamond Kinetics PitchTracker" : data.type}</div>
                        <div className="price_value">
                            {this.getTrial(pack, data.type).trialPeriod > 0 &&
                                <p className="price_trial">{this.getTrial(pack, data.type).trialPeriod} day free trial included!</p>
                            }
                            <h4>${list.amount}</h4>
                            <div className="payradio-button">
                                <label className="round-radio">
                                    Per Month
                                    <input type="checkbox" id="team" name="plans" value="1" checked={data.selectedType === 1 ? true : false} onClick={() => this.getChangePlans(pack, data._id, 1)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="payradio-button">
                                <label className="round-radio">
                                    Per Year
                                    <input type="checkbox" id="facility" name="plans" value="2" checked={data.selectedType === 2 ? true : false} onClick={() => this.getChangePlans(pack, data._id, 2)} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <Link
                                onClick={() => (data.status && !subCoach) ? (localStorage.getItem("coachToken") && userRole === "Coach") ?
                                    this.nextPayment(this.setPlan(data.plans, data.selectedType), data) :
                                    (localStorage.getItem("playerToken") && userRole === "Player") ?
                                        this.nextPayment(this.setPlan(data.plans, data.selectedType), data) :
                                        (localStorage.getItem("rosterToken") && userRole === "Player") ?
                                            this.nextPayment(this.setPlan(data.plans, data.selectedType), data) :
                                            (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken") && !localStorage.getItem("rosterToken")) ?
                                                this.nextPayment(this.setPlan(data.plans, data.selectedType), data) : "" : ""}
                                className="btn"
                                disabled={(data.status && !subCoach) ? (localStorage.getItem("coachToken") && userRole === "Coach") ? false :
                                    (localStorage.getItem("playerToken") && userRole === "Player") ? false :
                                        (localStorage.getItem("rosterToken") && userRole === "Player") ? false :
                                            (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken") && !localStorage.getItem("rosterToken")) ? false : true : true}
                            // disabled={true}
                            >
                                Get it now!
                            </Link>
                        </div>
                        <div className="accordion">
                            <div className="card-header">
                                <Link onClick={() => this.showDetails(list._id)}>Features <i className="fas fa-chevron-down"></i></Link>
                            </div>
                            <div className="card-body">
                                {this.renderPackageDescription(data.featureIds)}
                            </div>
                        </div>
                    </div >
                )
            })

        )
    }

    renderPackage = () => {
        const { pack } = this.state;
        return (
            pack &&
            pack.map((list, i) => {
                return (

                    <div className="price_part" key={i}>
                        {this.renderPlansList(list)}
                    </div >
                )
            })
        );
    }

    renderPackageDescription = (data) => {
        return (
            <>
                <div className="card-body-inner">
                    {
                        data &&
                        data.map((flist, i) => {
                            return (
                                <p><img src={imagesArr.check} alt="check" />{flist.name}</p>
                            )
                        })
                    }
                </div>
                {/* <div className="card-body-view">
                    <span className="box-view">Views</span>
                    <p><img src={imagesArr.check} alt="check" />4 Up, 6 Up, Arsenal</p>
                </div>
                <div className="card-body-view">
                    <span className="box-view">Point of Contact</span>
                    <p><img src={imagesArr.check} alt="check" />5 Fingerprints</p>
                    <p><img src={imagesArr.check} alt="check" />Fingerprint Data</p>
                    <p><img src={imagesArr.check} alt="check" />Analysis Of Metrics</p>
                </div> */}
            </>
        )
    }

    nextPayment = (plan, data) => {
        let nextData = {
            packid: data._id,
            plans: plan,
            title: data.role + " - " + data.type + " Package",
            trialPeriod: data.trialPeriod
        }
        localStorage.setItem("popupUrl", "/ballrapp");
        localStorage.setItem("rolPay", this.state.userRole)
        this.commonCall.setStorage("payPlans", nextData, flag => {
            if (flag) {
                if (!localStorage.getItem("coachToken") && !localStorage.getItem("playerToken") && !localStorage.getItem("rosterToken")) {
                    this.props.history.push('/signup');
                } else if (localStorage.getItem("coachToken")) {
                    this.props.history.push('/coach-payment');
                } else if (localStorage.getItem("rosterToken")) {
                    this.props.history.push('/fan-payment');
                } else {
                    this.props.history.push('/player-payment');
                }
            }
        });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getRolePack = (role) => {
        if (this.props.bollrDetails.packData) {
            this.props.bollrDetails.packData = null;
        }

        this.setState({
            userRole: role,
            webPack: {
                role: role,
                productCode: "BALLR"
            },
        }, () => {
            this.setLoader(true);
            this.props.getPack(this.state.webPack);
        })
    }

    render() {
        const { loader, subCoach, monthPrice } = this.state;
        const tabs = this.state.tabs;

        var ballrslider = {
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: true,
        };
        var blogslider = {
            dots: false,
            speed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: true,
        };
        return (
            <>
                <Header />
                {/* hero banner start */}
                <section className="hero-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="hero_img">
                                    <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/ballr.gif" alt="hero_gif" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="hero-dec">
                                    <div className="hero-logo"><img src={imagesArr.headinglogo2} alt="ballr_logo" /></div>
                                    <h1>
                                        The release is just the beginning
                                    </h1>
                                    <p style={{ marginBottom: '20px' }}>
                                        From ${monthPrice.length !== 0 ? monthPrice.amount : 0}/mo
                                    </p>
                                    {monthPrice.trialPeriod > 0 &&
                                        <p>
                                            Sign up today for a {monthPrice.trialPeriod} day free trial
                                        </p>
                                    }
                                    <div className="hero-btn">
                                        <Link className="btn" onClick={this.gotoLernmore.bind(this)}>Learn More</Link>
                                        <Link className="btn" onClick={this.gotoPricing.bind(this)}>View Pricing</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* hero banner End */}
                {/* buy section start  */}
                <section ref={this.learnmore}>
                    <div className="container">
                        <div className="buy_text">
                            <p>
                                BallR is the most advanced baseball and softball visualization tool. 360 degree and seam only view. Expansive features for spin, seam orientation, axis, and grids. Live data and video integration. Fingerprint location. Graphs and charts. Only on BaseballCloud.
                            </p>
                            <Link className="btn" onClick={this.gotoPricing.bind(this)} >Buy</Link>
                        </div>
                    </div>
                </section>
                {/* buy section End */}
                {/* one way see section start */}
                <section className="one_way_section">
                    <div className="white_bg">
                        <div className="container">
                            <div className="heading-title mb-0">
                                <h2>
                                    See the ball in a whole new way.
                                </h2>
                                <p className="new-way mb-0">
                                    BallR gives you unparalelled player development tools at your fingertips. Literally. Compare pitches, filter by events, add video, analyze fingerprints, and graph data all on the same screen.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ballr_slider white_bg">
                        <div className="container">
                            <Slider {...ballrslider}>
                                <div className="item">
                                    <img src={imagesArr.ballr_slider1} alt="ballr_slider1" />
                                </div>
                                <div className="item">
                                    <img src={imagesArr.ballr_slider2} alt="ballr_slider2" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="ballr_slider">
                        <div className="container">
                            <Slider {...blogslider}>
                                <div className="irem">
                                    <div className="container">
                                        <div className="for_way_section">
                                            <div className="heading-for">
                                                <h3>
                                                    For Players.
                                                </h3>
                                                <span>Save your bullets. Build pitches in 3D.</span>
                                                <p className="mb-0">
                                                    See your data in a whole new way. Easily see ways to understand spin metrics, compare pitches, and visualize grips on your own dashboard.
                                                </p>
                                            </div>
                                            <div className="for_way_part">
                                                <div className="for_way">
                                                    <img src={imagesArr.for_way1} alt="for_way1" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="for_way_section">
                                            <div className="for_way_part">
                                                <div className="for_way">
                                                    <img src={imagesArr.for_way2} alt="for_way2" />
                                                </div>
                                            </div>
                                            <div className="heading-for">
                                                <h3>
                                                    For Coaches.
                                                </h3>
                                                <span>Weaponize your staff overnight.</span>
                                                <p className="mb-0">
                                                    Efficiently educate, suggest grips, and track your pitcher’s progress through the coach dashboard.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="for_way_section">
                                            <div className="heading-for">
                                                <h3>
                                                    For Facilities.
                                                </h3>
                                                <span>Give your army the higher mound.</span>
                                                <p className="mb-0">
                                                    Individual player and facility profiles, custom features, and direct data integration is at your fingertips.
                                                </p>
                                            </div>
                                            <div className="for_way_part">
                                                <div className="for_way">
                                                    <img src={imagesArr.for_way3} alt="for_way3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="irem">
                                    <div className="container">
                                        <div className="for_way_section">
                                            <div className="heading-for">
                                                <h3>
                                                    Data + Video
                                                </h3>
                                                <span>Live Data + CSV Upload Capability.</span>
                                                <p className="mb-0">
                                                    BallR digests live data, historical data, and video and shows it all on one screen.
                                                </p>
                                            </div>
                                            <div className="for_way_part">
                                                <div className="for_way">
                                                    <img src={imagesArr.for_way4} alt="for_way4" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="for_way_section">
                                            <div className="for_way_part">
                                                <div className="for_way">
                                                    <img src={imagesArr.for_way5} alt="for_way5" />
                                                </div>
                                            </div>
                                            <div className="heading-for">
                                                <h3>
                                                    360° + 3D
                                                </h3>
                                                <span>No more single views.</span>
                                                <p className="mb-0">
                                                    Analyze and educate from every angle with preset cameras or with your own custom point of view.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="for_way_section">
                                            <div className="heading-for">
                                                <h3>
                                                    Easy Visuals
                                                </h3>
                                                <span>Complex concepts made simple.</span>
                                                <p className="mb-0">
                                                    Gain a better understanding of how your pitches spin with industry leading visuals that make sense of your data.
                                                </p>
                                            </div>
                                            <div className="for_way_part">
                                                <div className="for_way">
                                                    <img src={imagesArr.for_way6} alt="for_way6" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                {/* one way see section End */}
                {/* graph section Strat */}
                <section className="white_bg">
                    <div className="container">
                        <div class="heading-title custom-title">
                            <h2>Standard graphs. Advanced metrics.</h2>
                            <p className="new-way">Add context to visuals with charts and graphs that are tied to your data. See movement charts, scatter plots, radar graphs, strike zones, and more for further analysis.</p>
                        </div>
                        <div className="graph_inner_section">
                            <div className="graph-img">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/ball_graphs.gif" alt="ball_graphs" />
                            </div>
                            <div className="graph-text">
                                <div className="heading-for">
                                    <h3>
                                        It’s personal. Down to the fingerprint.
                                    </h3>
                                    <p>
                                        See where your fingers are located and catalog grips for every pitch. Analyzing each pitch is now within reach.
                                    </p>
                                    <p>
                                        Our patented Point of Contact algorithms calculates advanced metrics and shows you which grips affect ball metrics.
                                    </p>
                                    {/* <Link to="" className="btn">Buy</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* graph section End */}
                {/* Video section Start */}
                <section>
                    <div className="container">
                        <div className="heading-title custom-title">
                            <h2>
                                You’ll never see video the same.
                            </h2>
                            <p className="mb-0">
                                Sync up to 4 videos per pitch with your ball flight data to maximize context and get the most out of your analysis. From release to an entire delivery, BallR gives you the ability to see it together with our industry leading ball overlay functionality.
                            </p>
                        </div>
                        <div className="for_way_video">
                            <div className="video-bg">
                                {/* <img src={imagesArr.video_bg} alt="video_bg" /> */}
                                {/* <Link className="play-btn">
                                    <img src={imagesArr.play} alt="play" />
                                </Link> */}
                                <Player playsInline src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/ballR_web_video.mp4" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Video section End */}
                {/* Leave laptop section Start */}
                <section className="white_bg">
                    <div className="container">
                        <div className="leave_way_section">
                            <div className="leave_way">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/leave_laptop.gif" alt="leave_laptop" />
                            </div>
                            <div className="heading-for">
                                <h3>
                                    Leave the laptop and go mobile.
                                </h3>
                                <p>
                                    From the office to the lab to the field, take BallR with you on the go. Your arsenal is now in your pocket.
                                </p>
                                <p className="mb-0">
                                    * Functionality varies depending on internet connectivity to see real time data and video.
                                </p>
                            </div>
                        </div>
                        <div className="leave_way_section">
                            <div className="heading-for">
                                <h3>
                                    We redesigned Pitch Design.
                                </h3>
                                <p>The most thorough predictive ball flight model in the industry. Weather data. Magnus effect. Seam orientation.</p>
                                <p>Create new pitches, calculate movement, and see hitter probabilities with just lifting a finger. </p>
                                <span>Inputs:</span>
                                <p>Velocity, Spin Rate, Spin Direction, Seam Orientation, Vertical Release Angle, Horizontal Release Angle, Zip Code.</p>
                                <span>Outputs:</span>
                                <p className="mb-0">Full pitch path including Vertical Break, Horizontal Break, Pitch Location Height, Pitch Location Side, Swing, Swing and Miss, and Barrel probabilities.</p>
                            </div>
                            <div className="leave_way">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/picth_desgin.gif" alt="picth_desgin" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Leave laptop section End */}
                {/* brand-logo section start */}
                <section>
                    <div className="container">
                        <div className="heading-title custom-title">
                            <h2>
                                Integrate and Celebrate.
                            </h2>
                            <p>
                                We digest the following data sources into BallR:
                            </p>
                        </div>
                        <div className="brand-logo">
                            <Link>
                                <img src={imagesArr.brand_logo1} alt="brand_logo1" />
                            </Link>
                            <Link>
                                <img src={imagesArr.brand_logo2} alt="brand_logo2" />
                            </Link>
                            <Link>
                                <img src={imagesArr.brand_logo3} alt="brand_logo3" />
                            </Link>
                            <Link>
                                <img src={imagesArr.brand_logo4} alt="brand_logo4" />
                            </Link>
                            <Link>
                                <img src={imagesArr.brand_logo5} alt="brand_logo5" />
                            </Link>
                        </div>
                    </div>
                </section>
                {/* brand-logo section End */}
                {/* other-dec section Start */}
                <section className="other-dec white_bg">
                    <div className="container">
                        {/* <p>
                            Directly access your data via API via the DK PitchTracker Ball and continue the bullpen inside of <br />
                            DK’s own BallR module.
                        </p> */}
                        {/* <div className="diamond-kinetics">
                            <img src={imagesArr.diamond_kinetics} alt="diamond_kinetics" />
                        </div> */}
                        {/* <p>
                            Create pitches to simulate inside of WinReality and experience your own simulated <br /> pitches in virtual reality.
                        </p> */}
                        {/* <div className="diamond-kinetics">
                            <img src={imagesArr.diamond_kinetics2} alt="diamond_kinetics2" />
                        </div> */}
                        <div className="heading-title mb-0">
                            <h2>
                                Feature Snapshots
                            </h2>
                            <p>
                                Take a look at some of the features and tools inside of BallR.
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="video_part">
                                <div className="video_img">
                                    <img src={imagesArr.diamond_video1} alt="diamond_video1" />
                                    <Link onClick={() => this.openVideoPopup(imagesArr.diamond_video1)}><i className="fas fa-caret-right"></i></Link>
                                </div>
                                <h5>Single Up</h5>
                            </div>
                            <div className="video_part">
                                <div className="video_img">
                                    <img src={imagesArr.diamond_video2} alt="diamond_video2" />
                                    <Link onClick={() => this.openVideoPopup(imagesArr.diamond_video2)}><i className="fas fa-caret-right"></i></Link>
                                </div>
                                <h5>Multi View</h5>
                            </div>
                            <div className="video_part">
                                <div className="video_img">
                                    <img src={imagesArr.diamond_video3} alt="diamond_video3" />
                                    <Link onClick={() => this.openVideoPopup(imagesArr.diamond_video3)}><i className="fas fa-caret-right"></i></Link>
                                </div>
                                <h5>Point of Contact</h5>
                            </div>
                            <div className="video_part">
                                <div className="video_img">
                                    <img src={imagesArr.diamond_video4} alt="diamond_video4" />
                                    <Link onClick={() => this.openVideoPopup(imagesArr.diamond_video4)}><i className="fas fa-caret-right"></i></Link>
                                </div>
                                <h5>Video</h5>
                            </div>
                        </div>
                    </div>
                </section>
                {/* other-dec section End */}
                {/* pricing section Start */}
                <section className="pricing_section" id="pricing-plans" ref={this.pricingRef}>
                    <div className="container">
                        <div className="heading-title">
                            <h2 className="mb-0">
                                Pricing
                            </h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="price-btn">
                            <span className={`btn ${this.state.userRole === "Player" ? "active" : ""}`} onClick={() => this.getRolePack("Player")}>Player</span>
                            <span className={`btn ${this.state.userRole === "Coach" ? "active" : ""}`} onClick={() => this.getRolePack("Coach")}>Coach</span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {this.renderPackage()}
                        </div>
                    </div>
                </section>
                {/* pricing section End */}
                <Footer />
                {this.state.videoPopup &&
                    <LandingVideo flag={this.state.videoPopup} close={this.closePopup} video={this.state.showVideo} autoPlay muted />}
                <Preloader flag={loader} />
            </>
        );
    }
}

BollR.propTypes = {
    getPack: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileDetails: state.headerData,
    bollrDetails: state.bollrDetails,
});

export default connect(mapStateToProps, { getPack })(BollR);

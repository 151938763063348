import {
    SEND_PAYMENT_REQUEST,
    SEND_PAYMENT_SUCCESS,
    SEND_PAYMENT_ERROR,
} from '../../constants/common/payment.const';

const initialState = {
    paymentReq: false,
    paymentData: null,
    paymentError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Pachage req    
        case SEND_PAYMENT_REQUEST:
            return {
                ...state,
                paymentReq: true,
            };
            
        case SEND_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentData: action.payload,
            };
        case SEND_PAYMENT_ERROR:
            return {
                ...state,
                paymentError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";

export class ourTeamDec extends Component {
  render() {
    const { flag, dataIndex } = this.props;
    if (flag) {
      document.body.classList.add("modal-open");
    }
    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className="onlymodal_close"
                onClick={() => this.props.close()}
              >
                <img src={imagesArr.modal_close} alt="modal_close" />
              </div>
              <div className="modal-body our_team_before">
                <div className="our_team">
                  <div className="our_team_inner">
                    <div className="our_team_part">
                      <div className="ourteam_img">
                        {dataIndex === 0 && (
                          <img src={imagesArr.ourteam1} alt="ourteam1" />
                        )}
                        {dataIndex === 1 && (
                          <img src={imagesArr.ourteam2} alt="ourteam2" />
                        )}
                        {dataIndex === 2 && (
                          <img src={imagesArr.ourteam3} alt="ourteam3" />
                        )}
                      </div>
                    </div>
                    <div className="our_team_part our_team_inner_part">
                      <h5>
                        {dataIndex === 0 && "Kevin Davidson"}
                        {dataIndex === 1 && "Corey Whiting "}
                        {dataIndex === 2 && "Joseph Sleiman"}
                      </h5>
                      <span>
                        {dataIndex === 0 && "CEO of DS Sports Ventures"}
                        {dataIndex === 1 &&
                          "CTO of DS Sports Ventures and CTO/President of BaseballCloud"}
                        {dataIndex === 2 &&
                          "President of DS Sport Ventures LLC"}
                      </span>
                      <p>
                        {dataIndex === 0 && (
                          <>
                            <p>
                              Kevin Davidson or “K.D.” as he is affectionately
                              known, is the acting CEO of Orlando based DS
                              Sports Ventures, LLC. DS Sports Ventures (DSSV),
                              was originally founded in 2017 as a software and
                              technology based company during the incubation
                              period of KD’s vision, Baseball Cloud, LLC. In a
                              short period of time, DSSV has quickly morphed
                              into a baseball-based private equity conglomerate
                              garnering national attention by swiftly acquiring
                              and now managing several of the nation’s largest
                              and most influential baseball businesses and
                              product lines.
                            </p>
                            <p>
                              Baseball Cloud was born from a goal to change the
                              way data is viewed, processed and utilized by
                              providing players, coaches, trainers and scouts a
                              platform to access data in a centralized and
                              resourceful location. Educating the sports market
                              on how data can revolutionize talent and
                              performance is the foundation for creating this
                              groundbreaking software. Over 60 major colleges in
                              the United States have implemented the technology
                              that DS Sports Ventures and Baseball Cloud are
                              responsible for developing, with demand growing
                              daily.
                            </p>
                            <p>
                              KD is a graduate of Rollins College (’02) and
                              spent the better of 7 seasons in the Houston
                              Astros organization as a catcher. He played as
                              high as AAA and was the 2003 Astros Minor League
                              Player of the Year. KD was always known as a
                              tenacious competitor with unparalleled aptitude
                              for the game he has dedicated his life to.
                            </p>
                            <p>
                              When an injury cut his career short in 2007, it is
                              no surprise that KD took the transition into
                              corporate America in stride, as he embarked on a
                              new career as a financial advisor. KD used his
                              competitive nature, sharp communication skills and
                              networking ability to quickly build a book of
                              business that boasted some of the biggest stars in
                              Major League Baseball.
                            </p>
                            <p>
                              KD eventually made his way back to the diamond,
                              but this time as a manager in the Florida
                              Collegiate Summer League, of which he is now the
                              Chairman of the Board.. After winning the last of
                              3 League Championships, setting the record for
                              wins in a season, and becoming the winningest
                              manager in league history, he decided to retire to
                              focus his energy and effort on building DS Sports
                              Ventures.
                            </p>
                            <p>
                              It was a reluctant, but happy end to a decorated
                              coaching career that spanned 9 seasons and saw
                              over 70 of his former players go on to sign
                              professional contracts, many of which are still
                              playing today.
                            </p>
                            <p>
                              KD has been recognized and honored by being named
                              to “Orlando’s Top 40 under 40” business people,
                              and helped raise over seven million dollars for
                              local charities. KD’s mark on the game of baseball
                              in the state of Florida is undeniable, and has
                              quickly expanded to a national footprint as he
                              leads DS Sports Ventures to the forefront of the
                              data revolution in baseball.
                            </p>
                          </>
                        )}
                        {dataIndex === 1 && (
                          <>
                            <p>
                              Corey Whiting is the CTO of DS Sports Ventures and
                              CTO/President of BaseballCloud. He oversees all
                              facets of software development and projects
                              pertaining to BaseballCloud products. He built the
                              original beta version and now manages the
                              development team which continually integrates new
                              features and capabilities.
                            </p>
                            <p>
                              Within DSSV Corey provides technology integration
                              and development assistance to all companies
                              underneath its umbrella. He provides insightful
                              and innovative tech solutions to all areas of
                              business, which include writing the original
                              genetic algorithm of scheduling for RussMatt
                              Tournaments. He also manages customer and data
                              collection at events within DSSV.
                            </p>
                            <p>
                              Prior to joining the DSSV family, Corey was
                              employed as a software engineer at TeamSnap, a
                              sports and management development platform, and
                              STATS, a world leader in sports data collection.
                              At TeamSnap he developed team, league, and club
                              management software. While at STATS, Corey
                              developed a win probability prediction system for
                              MLB media clients. Other projects included a
                              SportVU camera tracking system that provides
                              analytics to all NBA teams.
                            </p>
                            <p>
                              Corey’s vast educational career led him to where
                              he is today. He earned a Bachelors in Computer
                              Science at Duke University, an MBA at Rollins
                              College and an MS in Computer Science at the
                              University of Chicago. In between his engineering
                              studies he played college baseball at Duke and
                              Rollins. He then went on to play professionally
                              for a year with the Traverse City Beach Bums.
                            </p>
                            <p>
                              When he’s not designing the next biggest platform,
                              Corey loves to golf as his favorite past time, He
                              also enjoys flying, fishing and Duke basketball.
                              He is married to his best friend, Elizabeth and
                              has a newborn daughter named, Victoria.
                            </p>
                          </>
                        )}
                        {dataIndex === 2 && (
                          <>
                            <p>
                              Joseph Sleiman is the President of DS Sport
                              Ventures, LLC, (DSSV), a software and technology
                              private equity company rooted in baseball and
                              founded in 2017. While in retirement, Sleiman
                              became involved in the baseball world, his
                              ultimate passion. He opened up an instruction,
                              training and practice facility called H3. He would
                              also sponsor tournaments, teams and several
                              charities throughout several years of raising his
                              3 boys. It’s at H3 where he would meet and
                              befriend Kevin Davidson, beginning their journey
                              deeper into baseball and ultimately starting DSSV
                              together.
                            </p>
                            <p>
                              Joseph has a decorated entrepreneurial background,
                              which spans over 40 years. He has held several
                              board positions from museums to retail companies
                              and is still currently involved in operating
                              multiple companies. He enjoys being an avid
                              investor in companies that share his vision.
                            </p>
                            <p>
                              Another love of Sleiman’s was the billboard
                              Industry, which moved him to Orlando from
                              Jacksonville in the 1980s. His collection of
                              successful billboard companies included Dolphin
                              Outdoor, International Outdoor, Gator Outdoor and
                              Shomee Outdoor. Gator Outdoor was the largest
                              independent outdoor company in the Southeast
                              region of the U.S. It was sold in 1998 to Outdoor
                              Systems and Sleiman made history having been paid
                              the 2nd highest multiple ever in the sign
                              industry.
                            </p>
                            <p>
                              Continuing with his entrepreneurial spirit, he
                              founded Commercial Brokers of American 30 years
                              ago, which is currently operating with over 1
                              billion dollars in sales and purchases to date.
                            </p>
                            <p>
                              Sleiman is the Co-Founder and managing partner of
                              Sleiman Enterprises which was started in
                              Jacksonville, Florida in 1973. Today, Sleiman
                              Enterprises is the 3rd largest retail developer in
                              the state of Florida with over 6 million
                              commercial square feet of retail space. Currently,
                              he is involved in day to day decision making
                              involving leasing, development and acquisitions.
                            </p>
                            <p>
                              He attended the University of Florida where his
                              business and operating ventures began by starting
                              the first sidewalk florist. Upon finishing college
                              and moving back to Jacksonville, he owned and
                              operated the Ramada Inn, Jacksonville Beach. The
                              hotel became voted the No. 1 Resort Hotel in North
                              Florida for 5 years in a row.
                            </p>
                            <p>
                              Sleiman was born and raised in Jacksonville,
                              Florida. He is married to Paula Sleiman and has 3
                              sons, Joseph,Jr., William and Alexander.
                            </p>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ourTeamDec;

export const OTHERINFO_REQUEST = 'OTHERINFO_REQUEST';
export const OTHERINFO_SUCCESS = 'OTHERINFO_SUCCESS';
export const OTHERINFO_ERROR = 'OTHERINFO_ERROR';

export const COLLEGEINFO_REQUEST = 'COLLEGEINFO_REQUEST';
export const COLLEGEINFO_SUCCESS = 'COLLEGEINFO_SUCCESS';
export const COLLEGEINFO_ERROR = 'COLLEGEINFO_ERROR';

export const POSTOTHERINFO_REQUEST = "POSTOTHERINFO_REQUEST";
export const POSTOTHERINFO_SUCCESS = "POSTOTHERINFO_SUCCESS";
export const POSTOTHERINFO_ERROR = "POSTOTHERINFO_ERROR";
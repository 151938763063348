import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import Footer from "../../../../containers/footer";
import commonCalls from "../../../../businesslogic/commonCalls";
import { getEventDetails, getParticipating, fetchPaymentIntent, eventRegistrationPayment, eventPaymentComplete, getRosterAmount, getInstallmentDetails, registerInstallment } from '../../../../saga/actions/common/eventDetails.action';
import Preloader from "../../../../components/preloader";
import EventRow from './details/eventRow';
import EventInfo from './details/eventInfo';
import Location from './details/location';
import SocialMediaLink from './details/socialMediaLink';
import HotelInfo from './details/hotelInfo';
import { commonHeader } from '../../../../businesslogic/content';
import CreditCard from "../payment/creditCard";
import { getMinOfKeys } from "../../../../businesslogic/content";

class eventDayofPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventId: "",
            loader: false,
            eventDetailData: [],
            eventJoin: {
                event: "",
                isManager: true,
                managerName: "",
                managerEmail: "",
                managerPhone: "",
                waiversImg: "",
                insuranceImg: "",
                paymentType: 2,
                couponCode: "",
                metaPayment: {},
                paymentMethod: "",
                amount: 0,
                question: "",
                products: []
            },
            firstLoad: true,
            loadHeader: false,
            sendInstallment: {
                objectId: "",
                module: "",
                paymentType: 3,
                paymentMethod: 1,
                amount: "",
                installments: {},
                metaPayment: {},
                notificationId: ""
            },
            sendTotify: {
                notificationId: ""
            },
            alreadyPaid: false
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        localStorage.removeItem("installment");
        this.setState({
            eventId: eventId,
            sendTotify: {
                notificationId: url.search ? url.search.split("=")[1] : ""
            },
            sendInstallment: {
                ...this.state.sendInstallment,
                objectId: eventId,
                notificationId: url.search ? url.search.split("=")[1] : ""
            },
        }, () => {
            if (!localStorage.getItem("webglToken")) { localStorage.setItem("nid", this.state.sendInstallment.notificationId) }
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            console.log("nextProps.eventDetails-payment------------------:--", nextProps.eventDetails.getDetailsData);
            if (nextProps.eventDetails.getDetailsData.flag) {
                let eData = nextProps.eventDetails.getDetailsData.result.data[0];
                this.setState({
                    eventDetailData: eData,
                    eventJoin: {
                        ...this.state.eventJoin,
                        event: this.state.eventId,
                    },
                    rosterTeamId: eData.rosterTeamId
                }, () => {
                    if (localStorage.getItem("cid") === null || localStorage.getItem("cid") !== eData.companies._id) {
                        let item = commonHeader.events.filter((e) => e.label === eData.companies.name)[0];
                        localStorage.setItem("cid", eData.companies._id)
                        localStorage.setItem("company", item.key)
                        localStorage.setItem("companyColor", JSON.stringify(item.colors));
                        this.setState({
                            loadHeader: true
                        })
                    } else {
                        this.setState({
                            loadHeader: true
                        })
                    }
                    // let price = getMinOfKeys(this.state.eventDetailData.payment.feesByGroups, ['feesOfGroup']);
                    // this.state.eventDetailData.payment.feesTournament = price;
                    // this.state.eventJoin.amount = price;

                    nextProps.eventDetails.getDetailsData = null;
                    this.props.getParticipating(this.state.eventId);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getParticipationReq &&
            nextProps.eventDetails.getParticipationData
        ) {
            nextProps.eventDetails.getParticipationReq = false;
            console.log("nextProps.eventDetails:--", nextProps.eventDetails.getParticipationData);
            if (nextProps.eventDetails.getParticipationData.flag) {
                this.setState({
                    participationList: nextProps.eventDetails.getParticipationData.result.data
                }, () => {
                    nextProps.eventDetails.getParticipationData = null;
                    if (localStorage.getItem("webglToken")) {
                        if (this.state.eventDetailData.eventTeamType === 1) {
                            this.props.getRosterAmount({ eventId: this.state.eventId, rosterId: this.state.rosterTeamId });
                        } else {
                            this.props.getInstallmentDetails(this.state.sendTotify);
                        }
                    } else {
                        let price = getMinOfKeys(this.state.eventDetailData.payment.feesByGroups, ['feesOfGroup']);
                        this.state.eventDetailData.payment.feesTournament = price;
                        this.state.eventJoin.amount = price;
                        this.setLoader(false);
                    }
                    // this.setHeaderDayofEvent();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getParticipationData.message);
                nextProps.eventDetails.getParticipationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getRosterAmountReq &&
            nextProps.eventDetails.getRosterAmountData
        ) {
            console.log("nextProps.eventDetails.getRosterAmountData:--", nextProps.eventDetails.getRosterAmountData);
            nextProps.eventDetails.getRosterAmountReq = false;
            if (nextProps.eventDetails.getRosterAmountData.flag) {
                let price = nextProps.eventDetails.getRosterAmountData.result.feesOfGroup;
                this.state.eventDetailData.payment.feesTournament = price;
                this.state.eventJoin.amount = price;
                nextProps.eventDetails.getRosterAmountData = null;
                if (this.state.sendTotify.notificationId) {
                    this.props.getInstallmentDetails(this.state.sendTotify);
                } else {
                    this.setLoader(false);
                }
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getRosterAmountData.message);
                nextProps.eventDetails.getRosterAmountData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getInstallmentDetailsReq &&
            nextProps.eventDetails.getInstallmentDetailsData
        ) {
            console.log("nextProps.eventDetails.getInstallmentDetailsData:--", nextProps.eventDetails.getInstallmentDetailsData);
            nextProps.eventDetails.getInstallmentDetailsReq = false;
            if (nextProps.eventDetails.getInstallmentDetailsData.flag) {
                let fdata = nextProps.eventDetails.getInstallmentDetailsData.result;
                if (fdata.alreadyPaid) {
                    this.setState({
                        alreadyPaid: fdata.alreadyPaid
                    }, () => {
                        nextProps.eventDetails.getInstallmentDetailsData = null;
                        this.setLoader(false);
                    })
                } else {
                    this.setState({
                        sendInstallment: {
                            ...this.state.sendInstallment,
                            module: "event",
                            amount: (fdata.paymentType === 2 || fdata.paymentType === 4 || fdata.paymentType === 1) ? fdata.payLater.price : fdata.installment.price,
                            installments: fdata.paymentType === 3 ? fdata.installment : {},
                            paymentType: fdata.paymentType,
                            rosterTeamId: fdata.rosterTeamId
                        },
                        alreadyPaid: fdata.alreadyPaid
                    }, () => {
                        nextProps.eventDetails.getInstallmentDetailsData = null;
                        this.setLoader(false);
                    })
                }
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getInstallmentDetailsData.message);
                nextProps.eventDetails.getInstallmentDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.getPaymentIntentReq &&
            nextProps.eventDetails.getPaymentIntentData
        ) {
            nextProps.eventDetails.getPaymentIntentReq = false;
            if (nextProps.eventDetails.getPaymentIntentData.flag) {
                localStorage.setItem(
                    "stripeIntent",
                    nextProps.eventDetails.getPaymentIntentData.result.data
                );
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setState({
                    creditCard: true
                }, () => {
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getPaymentIntentData.message);
                nextProps.eventDetails.getPaymentIntentData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.eventRegistrationReq &&
            nextProps.eventDetails.eventRegistrationData
        ) {
            nextProps.eventDetails.eventRegistrationReq = false;
            console.log("eventRegistrationData:--", nextProps.eventDetails.eventRegistrationData);
            if (nextProps.eventDetails.eventRegistrationData.flag) {
                nextProps.eventDetails.eventRegistrationData = null;
                var data = {
                    event: this.state.eventJoin.event,
                    couponCode: this.state.eventJoin.couponCode,
                    paymentType: this.state.eventJoin.paymentType,
                    paymentMethod: this.state.eventJoin.paymentType,
                    amount: this.state.eventJoin.amount,
                    installments: this.state.eventDetailData.payment.installments,
                    metaPayment: this.state.eventJoin.metaPayment,
                    rosterTeamId: this.state.sendInstallment.rosterTeamId
                };
                console.log("end complete call:---", data);
                this.props.eventPaymentComplete(data);
                this.props.history.push(`/event-joined/${this.state.eventJoin.event}`);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.eventRegistrationData.message);
                nextProps.eventDetails.eventRegistrationData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.payInstallmentReq &&
            nextProps.eventDetails.payInstallmentData
        ) {
            nextProps.eventDetails.payInstallmentReq = false;
            console.log("payInstallmentData:--", nextProps.eventDetails.payInstallmentData);
            if (nextProps.eventDetails.payInstallmentData.flag) {
                var data = {
                    event: this.state.sendInstallment.objectId,
                    couponCode: "",
                    paymentType: this.state.sendInstallment.paymentType,
                    paymentMethod: 1,
                    amount: this.state.sendInstallment.amount,
                    installments: this.state.sendInstallment.installments,
                    metaPayment: nextProps.eventDetails.payInstallmentData.result.metaPayment,
                    rosterTeamId: this.state.sendInstallment.rosterTeamId
                };
                nextProps.eventDetails.payInstallmentData = null;
                console.log("end complete call:---", data);
                this.props.eventPaymentComplete(data);
                this.props.history.push(`/event-joined/${this.state.eventJoin.event}`);
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.payInstallmentData.message);
                nextProps.eventDetails.payInstallmentData = null;
                this.setLoader(false);
            }
        }

    }

    getDetails = () => {
        if (localStorage.getItem("webglToken") !== null) {
            this.props.getEventDetails(this.state.eventId, true);
        } else {
            this.props.getEventDetails({
                eventId: this.state.eventId
            }, false);
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    setHeaderDayofEvent = () => {
        let companyId = this.state.eventDetailData.companies && this.state.eventDetailData.companies._id;
        if (companyId) {
            let companyKey = this.state.eventDetailData.eventType &&
                this.state.eventDetailData.companies.name === "Prospect Wire" ?
                this.state.eventDetailData.eventType === 1 ? "pwb" : "pws" :
                this.state.eventDetailData.companies.name === "GOBTournament" ? "gob" : "utb";
            if (companyKey) {
                let getCompany = commonHeader.events.filter(_ => _.key === companyKey)[0];
                this.setThemeColor(getCompany.colors);
                localStorage.setItem("company", getCompany.key);
                localStorage.setItem("cid", companyId);
                this.setState({
                    firstLoad: false
                })
            }
        }
    }

    setThemeColor = (colArr) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
    }

    stripeIntent = () => {
        var payment;
        if (this.state.sendTotify.notificationId) {
            payment = {
                amount: this.state.sendInstallment.amount,
                objetId: this.state.eventDetailData._id,
                module: 1,
                method: "stripe",
            };
        } else {
            payment = {
                amount: this.state.eventDetailData.payment.feesTournament,
                objetId: this.state.eventDetailData._id,
                module: 1,
                method: "stripe",
            };
        }
        console.log("payment:-pp", payment);
        this.setLoader(true);
        this.props.fetchPaymentIntent(payment);
    }

    onHandleClose = () => {
        this.setState({
            creditCard: false
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    stripePaymentSuccess = (payment) => {
        this.setState({
            creditCard: false,
            eventJoin: {
                ...this.state.eventJoin,
                metaPayment: payment,
            },
            sendInstallment: {
                ...this.state.sendInstallment,
                metaPayment: payment,
            }
        }, () => {
            document.body.classList.remove("modal-open");
            this.registerEvent();
        })
    };

    stripePaymentError = (error) => {
        this.commonCall.errorMessage(error);
        this.setLoader(false);
    };

    registerEvent = () => {
        if (this.state.sendTotify.notificationId) {
            console.log("this.state.sendInstallment:--", this.state.sendInstallment);
            localStorage.setItem("installment", this.state.sendInstallment.installments.installid);
            this.props.registerInstallment(this.state.sendInstallment);
        } else {
            console.log("this.state.eventJoin:--", this.state.eventJoin);
            this.props.eventRegistrationPayment(this.state.eventJoin);
        }
    }

    render() {

        const { loader, eventDetailData, eventId, creditCard, firstLoad, participationList, loadHeader, sendInstallment, alreadyPaid } = this.state;

        return (
            loadHeader &&
            <div>
                {
                    eventDetailData &&
                    <Header />
                }
                <CommonHeroBanner dataList={eventDetailData} />
                <div className="event_details">
                    <div className="container">
                        <EventRow dataList={eventDetailData} isHideJoinButton={false} eventId={eventId} complete={false} directPay={true} showAmount={localStorage.getItem("webglToken") ? true : false} paymentIntent={this.stripeIntent} directAmout={sendInstallment.amount} alreadyPaid={alreadyPaid} viewSchedulingBtn={sendInstallment.notificationId} />
                        <div className="row">
                            {
                                eventDetailData && eventDetailData.length !== 0 &&
                                <EventInfo dataList={eventDetailData} pList={participationList} directPay={true} paymentIntent={this.stripeIntent} />
                            }
                            <div className="event_right_side">
                                <Location dataList={eventDetailData} />

                                <SocialMediaLink dataList={eventDetailData} />

                                {
                                    eventDetailData.hotels &&
                                    eventDetailData.hotels.length > 0 &&
                                    <HotelInfo hotelList={eventDetailData.hotels} />
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {
                    eventDetailData &&
                    <Footer />
                }
                <Preloader flag={loader} />
                {
                    creditCard &&
                    <CreditCard
                        flag={creditCard}
                        close={this.onHandleClose}
                        stripePaymentSuccess={this.stripePaymentSuccess}
                        stripePaymentError={this.stripePaymentError}
                        amount={eventDetailData.payment && eventDetailData.payment.feesTournament}
                        setLoader={this.setLoader}
                    />
                }
            </div>

        )
    }
}

eventDayofPayment.propTypes = {
    getEventDetails: PropTypes.func.isRequired,
    fetchPaymentIntent: PropTypes.func.isRequired,
    eventRegistrationPayment: PropTypes.func.isRequired,
    eventPaymentComplete: PropTypes.func.isRequired,
    getParticipating: PropTypes.func.isRequired,
    getRosterAmount: PropTypes.func.isRequired,
    getInstallmentDetails: PropTypes.func.isRequired,
    registerInstallment: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
});
var eventDayofPaymentComponent = connect(mapStateToProps, { getRosterAmount, getEventDetails, getParticipating, fetchPaymentIntent, eventRegistrationPayment, eventPaymentComplete, getInstallmentDetails, registerInstallment })(eventDayofPayment);
export default withRouter(eventDayofPaymentComponent);
import moment from 'moment';
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import commonCalls from '../../../businesslogic/commonCalls';
import { imagesArr } from '../../../assets/images';

import { editPlayerInfo, getPlayerInfo, getPlayerList } from '../../../saga/actions/common/accountCreation.action';
import { postPictureUpload } from '../../../saga/actions/views/player/profile/personalInfo.action';
import { PICTUREUPLOAD_SUCCESS } from '../../../saga/constants/view/player/profile/personalInfo.const';
import { EDIT_PLAYER_INFO_SUCCESS, GET_PLAYER_INFO_SUCCESS } from '../../../saga/constants/common/accountCreation.const';

const initialState = {
    edit: {
        _id: "",
        phone: "",
        email: "",
        cCode: "",
        fname: "",
        lname: "",
        number: "",
        dob: "",
        position: "",
        claimAcc: true,
        type: 2,
        objectId: "",
        profileUrl: "",
        profileId: "",
        position2: "",
    },
    "game-type": "",
    opencal: "",
    isDOBUpdate: false,
    openLogin: false,
    IsClaimAcc: false
}

const reducer = (state, payload) => ({ ...state, ...payload })

function Player({ state, setState }) {
    const dispatch = useDispatch();
    const commonCall = new commonCalls();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [player, setPlayer] = useReducer(reducer, initialState);
    const { getPlayerInfo: _INFO, editPlayerInfo: _EDIT } = useSelector(state => state.accountCreation);
    const { pictureUploadData: _UPLOAD } = useSelector(state => state.playerPersonalInfo);
    const ref = useRef();
    const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    useEffect(() => {
        if (_INFO) {
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    _id: _INFO._id,
                    phone: _INFO.phone,
                    email: _INFO.email,
                    cCode: _INFO.cCode,
                    fname: _INFO.fname,
                    lname: _INFO.lname,
                    number: _INFO.number,
                    dob: _INFO?.profile?.hasOwnProperty("dob") ? _INFO.profile.dob : "",
                    position: _INFO.position,
                    claimAcc: true,
                    type: 2,
                    objectId: _INFO.mapUser,
                },
                isDOBUpdate: _INFO.profile?.hasOwnProperty("dob") ? _INFO.profile.dob ? new Date(_INFO.profile.dob) : false : false,
                "game-type": _INFO["game-type"],
                IsClaimAcc: _INFO.claimAcc
            })

            setState({
                ...state,
                loader: false
            })
            dispatch({
                type: GET_PLAYER_INFO_SUCCESS,
                payload: null
            })
        }else{
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    _id: state.selectedRow._id,
                },
            })
        }
    }, [_INFO,state.justNowCreateParent])

    useEffect(() => {
        if (_UPLOAD) {
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    profileUrl: _UPLOAD.filePath,
                    profileId: _UPLOAD._id,
                }
            })
            setState({
                ...state,
                loader: false
            })
            dispatch({
                type: PICTUREUPLOAD_SUCCESS,
                payload: null,
            });
        }
    }, [_UPLOAD])

    useEffect(() => {
        if (_EDIT) {
            if (_EDIT.flag) {
                
                toast.success(_EDIT.message, { position: toast.POSITION.TOP_RIGHT })
                if (state.justNowCreateParent) {
                    setState({
                        ...state,
                        claimForm: 1,
                    })
                    let personalReq = {
                        ObjectId:  state.selectedRow.mapUser._id,
                        type:  1 ,
                        _id: state.selectedRow._id
                    }
                    getPlayerInfo(personalReq)(dispatch);
                }else{
                    setState({
                        ...state,
                        claimForm: 1,
                        claimAccount: false,
                        loader: false
                    })
                }
                let url = new URL(document.location);
                let rid = url.pathname.split("/")[2];
                getPlayerList({ rosterTeamId: rid })(dispatch);
                dispatch({
                    type: EDIT_PLAYER_INFO_SUCCESS,
                    payload: null
                })
            } else {
                setState({
                    ...state,
                    loader: false
                })
                toast.error(_EDIT.message, { position: toast.POSITION.TOP_RIGHT })
                dispatch({
                    type: EDIT_PLAYER_INFO_SUCCESS,
                    payload: null
                })
            }
        }
    }, [_EDIT])



    const handleChange = (e) => {
        setPlayer({
            ...player,
            edit: {
                ...player.edit,
                [e.target.name]: e.target.value
            }
        })
    }

    const onlyNumber = event => {
        const keycode = event.which;
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };

    const changeImage = (event) => {
        let file = event.target.files[0];
        if (file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "png") {
            return commonCall.errorMessage("Accept only JPG,JPEG,PNG file");
        }
        setPlayer({
            ...player,
            imgObj: file,
        });
        setState({
            ...state,
            loader: true
        })
        postPictureUpload(file, 16)(dispatch);

    };

    const onSave = () => {
        if (validator.current.allValid()) {
            setState({
                ...state,
                loader: true
            })
            editPlayerInfo(player.edit)(dispatch)
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const closeLogin = () =>{
        setPlayer({
            ...player,
            openLogin: false
        })
    }

    return (
        <>
            <div className={`form_section ${player.IsClaimAcc ? 'disable' : ''}`}>
                <form>
                    {/* <div className='player_name'>
                <p>#9  JOHN DOE</p>
            </div> */}
                    <div className='title'>
                        <h3>Guardian {state.claimForm == 2 ? 1 : 2} Account</h3>
                        {!player.IsClaimAcc && <button type='button' class="btn light-green" href="" onClick={() => setState({ ...state, openLogin: true })}>Sign In</button>}
                    </div>
                    <div className='form-input'>
                        <label className="common-lable">First Name</label>
                        <div className="form-group mb-0">
                            <input type="text" className="form-control" name="fname" placeholder="Enter first Name" value={player.edit.fname} onChange={handleChange} disabled={player.IsClaimAcc}/>
                        </div>
                        <span className="validMsg">{validator.current.message("first name", player.edit.fname, "required", { className: "text-danger" })}</span>

                    </div>
                    <div className='form-input'>
                        <label className="common-lable">Last Name</label>
                        <div className="form-group mb-0">
                            <input type="text" className="form-control" name="lname" placeholder="Enter last Name" value={player.edit.lname} onChange={handleChange} disabled={player.IsClaimAcc}/>
                        </div>
                        <span className="validMsg">{validator.current.message("lname name", player.edit.lname, "required", { className: "text-danger" })}</span>

                    </div>
                    <div className='form-input'>
                        <label className="common-lable">Email</label>
                        <div className="form-group mb-0">
                            <input type="text" className="form-control" name="email" placeholder="Enter email" value={player.edit.email} onChange={handleChange} disabled={player.IsClaimAcc}/>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label class="common-lable">Mobile Number</label>
                        <div class="svg_icon">
                            <div class="input-group">
                                <div class="counrty-no">
                                    <select class="custom-select" name="cCode" value={player.edit.cCode} onChange={handleChange} >
                                        <option value="+1">+1</option>
                                    </select>
                                </div>
                                <input type="text" class="form-control" name="phone" id="usr" maxlength="12" placeholder="Enter phone number" value={player.edit.phone} onChange={handleChange} onKeyPress={onlyNumber} disabled={player.IsClaimAcc}/>
                                <img src={imagesArr.smartphone} alt="eye" />
                            </div>
                        </div>
                    </div>


                    <div class="form-group mb-0">
                        <label class="common-lable">Birthdate</label>
                        <div className="bc_relative">
                            <DatePicker
                                ref={ref}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                }) => (
                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                            {years.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                            {months.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                    </div>
                                )}
                                selected={player.edit.dob ? new Date(player.edit.dob) : player.edit.dob}
                                maxDate={moment().subtract(1, 'days').toDate()}
                                //   onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                onChange={(date) => setPlayer({ ...player, edit: { ...player.edit, dob: date } })}
                                className="form-control"
                                placeholderText="Enter birth date"
                                disabled={player.IsClaimAcc ? true : player.isDOBUpdate}
                                />

                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                player.opencal = !player.opencal;
                                ref.current.setOpen(player.opencal);
                            }} />
                        </div>
                    </div>

                    <div className='mt-15'>
                        <a class="btn light-green" onClick={onSave} disabled={player.IsClaimAcc}>Save</a>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Player

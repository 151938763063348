import {
    RECEIVE_PAYMENT_REQUEST,
    RECEIVE_PAYMENT_SUCCESS,
    RECEIVE_PAYMENT_ERROR,

    RECEIVE_BILLING_REQUEST,
    RECEIVE_BILLING_SUCCESS,
    RECEIVE_BILLING_ERROR,

    ADD_CARD_REQUEST,
    ADD_CARD_SUCCESS,
    ADD_CARD_ERROR,

    DELETE_CARD_REQUEST,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_ERROR,

    CHECK_CARD_TYPE_REQUEST,
    CHECK_CARD_TYPE_SUCCESS,
    CHECK_CARD_TYPE_ERROR,
    
    MAKE_DEFAULT_CARD_REQUEST,
    MAKE_DEFAULT_CARD_SUCCESS,
    MAKE_DEFAULT_CARD_ERROR,

} from '../../constants/common/billing.const';

const initialState = {
    paymentInfoReq: false,
    paymentInfoData: null,
    paymentInfoError: null,

    billingInfoReq: false,
    billingInfoData: null,
    billingInfoError: null,

    addCardReq: false,
    addCardData: null,
    addCardError: null,

    deleteCardReq: false,
    deleteCardData: null,
    deletCardError: null,

    checkTypeCardReq: false,
    checkTypeCardData: null,
    checkTypeCardError: null,

    makeDefaultCardReq: false,
    makeDefaultCardData: null,
    makeDefaultCardError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {


        case RECEIVE_PAYMENT_REQUEST:
            return {
                ...state,
                paymentInfoReq: true,
            };

        case RECEIVE_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentInfoData: action.payload,
            };

        case RECEIVE_PAYMENT_ERROR:
            return {
                ...state,
                paymentInfoError: action.error,
            };



        case RECEIVE_BILLING_REQUEST:
            return {
                ...state,
                billingInfoReq: true,
            };

        case RECEIVE_BILLING_SUCCESS:
            return {
                ...state,
                billingInfoData: action.payload,
            };

        case RECEIVE_BILLING_ERROR:
            return {
                ...state,
                billingInfoError: action.error,
            };



        case ADD_CARD_REQUEST:
            return {
                ...state,
                addCardReq: true,
            };

        case ADD_CARD_SUCCESS:
            return {
                ...state,
                addCardData: action.payload,
            };

        case ADD_CARD_ERROR:
            return {
                ...state,
                addCardError: action.error,
            };



        case DELETE_CARD_REQUEST:
            return {
                ...state,
                deleteCardReq: true,
            };

        case DELETE_CARD_SUCCESS:
            return {
                ...state,
                deleteCardData: action.payload,
            };

        case DELETE_CARD_ERROR:
            return {
                ...state,
                deletCardError: action.error,
            };

        case CHECK_CARD_TYPE_REQUEST:
            return {
                ...state,
                checkTypeCardReq: true,
            };

        case CHECK_CARD_TYPE_SUCCESS:
            return {
                ...state,
                checkTypeCardData: action.payload,
            };

        case CHECK_CARD_TYPE_ERROR:
            return {
                ...state,
                checkTypeCardError: action.error,
            };

        case MAKE_DEFAULT_CARD_REQUEST:
            return {
                ...state,
                makeDefaultCardReq: true,
            };

        case MAKE_DEFAULT_CARD_SUCCESS:
            return {
                ...state,
                makeDefaultCardData: action.payload,
            };

        case MAKE_DEFAULT_CARD_ERROR:
            return {
                ...state,
                makeDefaultCardError: action.error,
            };

        default:
            return state;
    }
}
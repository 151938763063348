import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    COM_RECOMMENDED_FORM_REQUEST,
    COM_RECOMMENDED_FORM_SUCCESS,
    COM_RECOMMENDED_FORM_ERROR,
} from '../../constants/common/recommendProspect.const';
import histrory from '../../../History';

export const saveRecommendedForm = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_RECOMMENDED_FORM_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}recommend-prospect/detail`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(saveProspect => {
        console.log("saveProspect:--", saveProspect);
        dispatch({
            type: COM_RECOMMENDED_FORM_SUCCESS,
            payload: saveProspect.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_RECOMMENDED_FORM_ERROR, error: error.response.data.message })
        }
    });
};



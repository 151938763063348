import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

class addTeam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openTeam: false,
            file: null,
            slink: ""
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.shop_validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        this.props.onRef(this);
    }

    setTextBox = (type) => {
        this.setState({ slink: type });
    }

    addTeamValidation = (cb) => {
        if (this.validator.allValid()) {
            cb(true);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            cb(false);
        }
    }

    submitData = () => {
        if (this.validator.allValid()) {
            if (this.props.shopFlg === "yes") {
                if (this.shop_validator.allValid()) {
                    this.props.onAddTeam();
                } else {
                    this.shop_validator.showMessages();
                    this.forceUpdate();
                }
            } else {
                this.props.teamInput.shoplink = "";
                this.props.onAddTeam();
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { flag, teamInput, imageUrl, shopFlg } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3>Setup Your Program</h3>
                                <div className="modal_close" onClick={this.props.close}><img src={imagesArr.modal_close} alt="modal_close" /></div>
                            </div>
                            <div className="modal-body">
                                <div className="team-body-inner">
                                    <label className="common-lable">Name of your program</label>
                                    <div className="form-group mb-0">
                                        <input type="text" name="name" value={teamInput.name} onChange={(e) => this.props.onChange('name', e.target.value)} className="form-control" id="usr" placeholder="Enter your program name" />
                                        <span className="validMsg">{this.validator.message('Program name', teamInput.name, 'required')}</span>
                                    </div>

                                    <label className="common-lable">Upload Program Logo</label>
                                    <div className="create_event_fileUpload file_custom_upload text-center">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        {!imageUrl && <p>Click to upload logo</p>}
                                        {/* ,.heic,.HEIC */}
                                        <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={(e) => this.props.handleChange(e)} />
                                        {
                                            imageUrl &&
                                            <div className="preview_img">
                                                <i className="fas fa-times" onClick={() => this.props.removeImg()}></i>
                                                <img className="preview_img_inner" src={imageUrl} />
                                            </div>
                                        }
                                    </div>

                                    <label className="common-lable">Tell us about your program</label>
                                    <textarea id="subject" rows="3" name="description" value={teamInput.description} onChange={(e) => this.props.onChange('description', e.target.value)} placeholder="Tell us about your program" className="form-control"></textarea>
                                    {/* <span className="validMsg">{this.validator.message('team description', teamInput.description, 'required')}</span> */}

                                    <label className="common-lable">Program Record</label>
                                    <div className="flex-align row">
                                        <div className="form-group">
                                            <input type="text" name="yourscore" value={teamInput.yourscore} onChange={(e) => this.props.onChange('yourscore', e.target.value)} className="form-control" id="usr" placeholder="Win" />
                                            {/* <span className="validMsg">{this.validator.message('team record', teamInput.yourscore, 'required|numeric')}</span> */}
                                        </div>
                                        <div className="form-group mr-0">
                                            <input type="text" name="opponentscore" value={teamInput.opponentscore} onChange={(e) => this.props.onChange('opponentscore', e.target.value)} className="form-control" id="usr" placeholder="Loss" />
                                            {/* <span className="validMsg">{this.validator.message('team record', teamInput.opponentscore, 'required|numeric')}</span> */}
                                        </div>
                                    </div>

                                    <label className="common-lable">Link Your Social Accounts</label>
                                    <div className="flex-align">
                                        <Link onClick={() => this.setTextBox('twitter')} className="twitter social_btn w-100">
                                            <img src={imagesArr.twitter} alt="twitter" />Link Twitter account
                                        </Link>
                                        <Link onClick={() => this.setTextBox('insta')} className="insta social_btn w-100 mr-0">
                                            <img src={imagesArr.insta} alt="insta" />Link Instagram account
                                        </Link>
                                    </div>

                                    {
                                        this.state.slink &&
                                        <>
                                            {
                                                this.state.slink === "twitter" ?
                                                    <>
                                                        <label className="common-lable">Enter Twitter Link</label>
                                                        <div className="form-group mb-0">
                                                            <input type="text" name="twt" value={teamInput['social-link'].twt} onChange={(e) => this.props.onChange('twt', e.target.value)} className="form-control" id="usr" placeholder="@Name" />
                                                        </div>
                                                        {/* <span className="validMsg">{this.validator.message('Twitter Link', teamInput['social-link'].twt, 'required')}</span> */}
                                                    </>
                                                    :
                                                    <>
                                                        <label className="common-lable">Enter Instagram Link</label>
                                                        <div className="form-group mb-0">
                                                            <input type="text" name="insta" value={teamInput['social-link'].insta} onChange={(e) => this.props.onChange('insta', e.target.value)} className="form-control" id="usr" placeholder="Username" />
                                                        </div>
                                                        {/* <span className="validMsg">{this.validator.message('Instagram Link', teamInput['social-link'].insta, 'required')}</span> */}
                                                    </>
                                            }
                                        </>
                                    }


                                    <label className="common-lable">Do you have a Program Merchandise Store?</label>
                                    <div className="check_input row">
                                        <div className="check_box">
                                            <div className="check-box">
                                                <div className="checkbox mr-0">
                                                    <input type="radio" value="yes" name="shopFlg" onChange={(e) => this.props.onChange('shopFlg', e.target.value)} checked={shopFlg === "yes" ? true : false} />
                                                    <label >Yes</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_box">
                                            <div className="check-box">
                                                <div className="checkbox mr-0">
                                                    <input type="radio" value="no" name="shopFlg" onChange={(e) => this.props.onChange('shopFlg', e.target.value)} checked={shopFlg === "no" ? true : false} />
                                                    <label >No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        shopFlg === "yes" ?
                                            <>
                                                <label className="common-lable">Program Store URL</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" name="shoplink" value={teamInput.shoplink} onChange={(e) => this.props.onChange('shoplink', e.target.value)} className="form-control" id="usr" placeholder="Enter Porgram Store Url" />
                                                </div>
                                                <span className="validMsg">{this.shop_validator.message('store url', teamInput.shoplink, 'required')}</span>
                                            </>
                                            :
                                            <>
                                                <label className="common-lable">Are you interested in creating Program Merchandise Store?</label>
                                                <div className="check_input row">
                                                    <div className="check_box">
                                                        <div className="check-box">
                                                            <div className="checkbox mr-0">
                                                                <input type="radio" value={true} name="isMarchandise" onChange={(e) => this.props.onChange('isMarchandise', true)} checked={teamInput.isMarchandise ? true : false}  />
                                                                <label >Yes</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="check_box">
                                                        <div className="check-box">
                                                            <div className="checkbox mr-0">
                                                                <input type="radio" value={false} name="isMarchandise" onChange={(e) => this.props.onChange('isMarchandise', false)} checked={!teamInput.isMarchandise ? true : false} />
                                                                <label >No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className="flex-align next-button">
                                    <Link className="btn" onClick={() => this.submitData()}>Next</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

addTeam.propTypes = {
};

const mapStateToProps = state => ({
    teamDetails: state.teamDetails
});

export default connect(mapStateToProps, {})(addTeam);
import React, { Component } from 'react';
import AcceptreqHeader from '../containers/acceptreqHeader';
import AcceptHeroBanner from '../components/acceptHeroBanner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { acceptRequestPlayer } from "../saga/actions/common/api.action";
import Preloader from '../components/preloader';
import commonCalls from "../businesslogic/commonCalls";

class acceptReqPlayer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            sendArr: {
                rosterId: ""
            }
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let url = new URL(document.location);
        let id = url.pathname.split("/")[4];
        console.log("url:---", id);
        this.setState({
            sendArr: {
                rosterId: id
            }
        }, () => {
            this.setLoader(true);
            this.props.acceptRequestPlayer(this.state.sendArr);
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonDetails &&
            nextProps.commonDetails.acceptPlayerReq &&
            nextProps.commonDetails.acceptPlayerData
        ) {
            nextProps.commonDetails.acceptPlayerReq = false;
            if (nextProps.commonDetails.acceptPlayerData.flag) {
                this.commonCall.successMessage(nextProps.commonDetails.acceptPlayerData.message);
                nextProps.commonDetails.acceptPlayerData = null;
                this.setLoader(false);
            } else {
                nextProps.commonDetails.acceptPlayerData = null;
                this.setLoader(false);
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state
        return (
            <>
                <AcceptreqHeader />
                <AcceptHeroBanner />
                <Preloader flag={loader} />
            </>
        );
    }
}

acceptReqPlayer.propTypes = {
    acceptRequestPlayer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    commonDetails: state.api,
});

export default connect(mapStateToProps, { acceptRequestPlayer })(acceptReqPlayer);

import React, { Component } from 'react';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { Link } from "react-router-dom";
import { imagesArr } from '../assets/images';

class BasebollCloud extends Component {
    constructor(props) {
        super(props);
        this.learnmore = React.createRef();
        this.productsection = React.createRef();
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        localStorage.removeItem("company");
        localStorage.removeItem("cid");
        localStorage.removeItem("companyColor");
        let defaultColor = [
            {
                variable: "--green-color",
                colorCode: "#7CBD42"
            },
            {
                variable: "--green-color-light",
                colorCode: "#96CF04"
            },
            {
                variable: "--green-color-shadow",
                colorCode: "#7CBD42"
            },
            {
                variable: "--green-color-extra-light",
                colorCode: "#eaf6cc"
            },
        ]
        this.setThemeColor(defaultColor);
        if (localStorage.getItem("scrollFooter")) {
            setTimeout(() => {
                this.gotoContactUs();
            }, 500);
        }
    }

    setThemeColor = (colArr) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
    }

    gotoContactUs = () => {
        const footer = document.getElementById("footer");
        if (footer) {
            footer.scrollIntoView({ behavior: 'smooth' });
            document.body.classList.remove("show-menu");
            localStorage.removeItem("scrollFooter");
        }
    }

    // go to Learnmore section
    // gotoLernmore () {
    //     let LernmoreSecTop = this.learnmore.current.offsetTop
    //     let headerHeight = document.getElementById('bc-header').offsetHeight
    //     window.scrollTo({
    //         top: (LernmoreSecTop - (headerHeight - 24)),
    //         behavior: 'smooth'
    //       });
    // }
    // go to Product section
    // gotoProductsection () {
    //     let productSecTop = this.productsection.current.offsetTop
    //     let headerHeight = document.getElementById('bc-header').offsetHeight
    //     window.scrollTo({
    //         top: (productSecTop - (headerHeight - 24)),
    //         behavior: 'smooth'
    //     });
    // }

    render() {
        return (
            <div>
                <Header />
                <section className="main-hero">
                    <div className="container">
                        <div className="main-hero-text">
                            <div className="heading-logo newLogo">
                                <img src={imagesArr.headinglogo9} alt="headinglogo9" />
                                <img src={imagesArr.headinglogo10} alt="headinglogo10" />
                            </div>
                            <h1>New Age Solutions for <br /> Old School Problems.</h1>
                        </div>
                    </div>
                </section>
                <section ref={this.learnmore}>
                    <div className="container">
                        <div className="buy_text">
                            <div className="heading-title mb-0">
                                <h2>
                                    Your final stop for data solutions.
                                </h2>
                                <p>
                                    Every player, coach, and program is different. Whether you’re implementing technology for the first time or looking to take your knowledge of analytics to the next level, BaseballCloud is here for you.
                                </p>
                                {/* <Link className="btn" onClick={this.gotoProductsection.bind(this)}>View Products</Link> */}
                            </div>
                            <p>
                                Take full advantage of your tech investments with BaseballCloud’s groundbreaking software solutions. Getting started is now more affordable and easier than ever before.
                            </p>
                            <p>
                                Save Time. Save Money. Maximize Player Development.
                            </p>
                            <div className="heading-title-img">
                                <img src={imagesArr.headingimg} alt="development" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="white_bg">
                    <div className="container">
                        <div className="other-size">
                            <h3>The First App Store <br /> for Player Development</h3>
                        </div>
                        <div className="common-appstore">
                            <div className="heading-for">
                                <h4>Get started with a BaseballCloud account</h4>
                                <p>
                                    To get started with our tools sign up for a BaseballCloud account. This will allow you to upload data to one central location and access it in multiple products. Roster Management, Data Upload, and Analysis can all be handled from your account.
                                </p>
                                <p className="mb-0">
                                    Once you're logged in, let us create the perfect package for you based on your budget, hardware capabilities, and coaching style. Find what works for you by utilizing everything from our player platform, custom PDF reports, 3D trajectory, and much more! Available in both desktop and mobile.
                                </p>
                            </div>
                            <div className="border_way">
                                <img src={imagesArr.development} alt="development" />
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={this.productsection}>
                    <div className="container">
                        <div className="common-class">
                            <div className="for_way bg-none">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/landing/playerdevelopment1.gif" alt="playerdevelopment1" />
                            </div>
                            <div className="heading-logo-title">
                                <div className="heading-logo w-100"><img src={imagesArr.headinglogo5} alt="headinglogo5" /></div>
                                <h4>
                                    Any data, any report. <br /> Anytime.
                                </h4>
                                {/* <span>Coming Soon</span> */}
                                <div className="heading-btn pt-40">
                                    <Link to="/analyzr" className="btn">Learn More</Link>
                                    {/* <Link to="" className="btn trans">View Pricing</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="common-class bg-none">
                            <div className="heading-logo-title">
                                <div className="heading-logo"><img src={imagesArr.headinglogo2} alt="headinglogo2" /></div>
                                <h4>
                                    The release is just <br /> the beginning
                                </h4>
                                <span>From $4.99/mo</span>
                                <div className="heading-btn">
                                    <Link to="/ballR" className="btn">Learn More</Link>
                                    <Link to="/ballR#pricing-plans" className="btn trans">Buy Now</Link>
                                </div>
                            </div>
                            <div className="for_way bg-none">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/ballr.gif" alt="playerdevelopment2" />
                            </div>
                        </div>

                        <div className="common-class bg-none">
                            <div className="for_way bg-none">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/6/d818ded2eb5487cdd27117e677b3fdfd.gif" alt="playerdevelopment" />
                            </div>
                            <div className="heading-logo-title">
                                <div className="heading-logo w-100"><img src={imagesArr.headinglogo1} alt="headinglogo1" /></div>
                                <h4>
                                    Your automated player <br /> development platform.
                                </h4>
                                <span>Contact our Sales Team</span>
                                <div className="heading-btn">
                                    <Link to="/bcteam" className="btn">Learn More</Link>
                                    {/* <Link to="" className="btn trans">View Pricing</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="common-class">

                            <div className="heading-logo-title">
                                <div className="heading-logo"><img src={imagesArr.headinglogo3} alt="headinglogo3" /></div>
                                <h4>
                                    You play in 3D.<br /> Start analyzing in it.
                                </h4>
                                {/* <span>Coming Soon</span> */}
                                <div className="heading-btn pt-40">
                                    <Link to="/pitchr" className="btn">Learn More</Link>
                                    {/* <Link to="" className="btn trans">View Pricing</Link> */}
                                </div>
                            </div>
                            <div className="for_way">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/landing/pitchr.gif" alt="pitchr" />
                            </div>
                        </div>
                        {/* <div className="common-class">
                            <div className="for_way">
                                <img src={imagesArr.playerdevelopment1} alt="playerdevelopment1" />
                            </div>
                            <div className="heading-logo-title">
                                <div className="heading-logo"><img src={imagesArr.headinglogo4} alt="headinglogo4" /></div>
                                <h4>
                                    Plan your training, <br /> Track your results
                                </h4>
                                <span>From $9.99/mo</span>
                                <div className="heading-btn">
                                    <Link to="" className="btn">Learn More</Link>
                                    <Link to="" className="btn trans">View Pricing</Link>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="common-class mb-0">
                            <div className="heading-logo-title">
                                <div className="heading-logo"><img src={imagesArr.headinglogo6} alt="headinglogo6" /></div>
                                <h4>
                                    Overlay data onto video. <br /> Autoclip instantly.
                                </h4>
                                <span>Coming 2021</span>
                                <div className="heading-btn">
                                    <Link to="/ovr" className="btn">Learn More</Link>
                                </div>
                            </div>
                            <div className="for_way">
                                <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/landing/ovr.gif" alt="ovr" />
                            </div>
                        </div> */}
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default BasebollCloud;
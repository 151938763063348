import React, { Component } from 'react'
import Slider from "react-slick";
import { imagesArr } from "../../../../../assets/images";

export default class hotelInfo extends Component {

    renderHotels = () => {
        return (
            this.props.hotelList &&
            this.props.hotelList.map((item, i) => {
                return (
                    <div key={i} onClick={() => window.open(item.link, "_blank")}>
                        <div className="web_hotel_slide">
                            <img src={item.stayToplay ? "https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg" : imagesArr.travellogo} alt="hotel" class="rounded" />
                            <div class={item.stayToplay ? "web_hotel_address" : "web_hotel_address dc_city_text"}><p>{item.hotelAddress}</p></div>
                        </div>
                    </div>
                )
            })
        )
    }

    render() {
        var Hotels = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <div className="web_description_box">
                <div class="web_desc_title"><h3>Hotels</h3></div>
                <div className="web_hotel_slidermain">
                    <Slider {...Hotels}>
                        {this.renderHotels()}
                    </Slider>
                </div>
            </div>
        )
    }
}

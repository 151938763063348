import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import { useDispatch, useSelector } from 'react-redux';
import RankingHeroBanner from '../../../../components/rankingHeroBanner';
import { getTeamRanking, getAllTeamList } from '../../../../saga/actions/views/company/pwRanking/rankings/teamRanking.action';
import { getAllStates } from '../../../../saga/actions/common/api.action';
import { GET_TEAM_RANKING_STATES_DETAILS_SUCCESS } from '../../../../saga/constants/common/api.const';
import { GET_TEAM_RANKING_SUCCESS, GET_ALL_RANKING_TEAM_LIST_SUCCESS } from '../../../../saga/constants/view/company/pwRanking/rankings/teamRanking.const';
import { imagesArr } from '../../../../assets/images';
import Preloader from '../../../../components/preloader';
import PurchaseProspectwire from '../../../popup/purchaseProspectwire';
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const DropdownIndicator = (
    props
) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={imagesArr.black_slight_search} />
        </components.DropdownIndicator>
    );
};

function TeamRanking(props) {

    const [teamList, setTeamList] = useState([])
    const [type, setType] = useState({
        page: 0,
        limit: 0,
        type: 1,
        rankType: localStorage.getItem("rankTType") ? JSON.parse(localStorage.getItem("rankTType")) : 1,
        ageGroup: localStorage.getItem("ageGroup") ? JSON.parse(localStorage.getItem("ageGroup")) : 1,
        position: "",
        gradeYear: "",
        state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
        teamName: localStorage.getItem("teamName") ? JSON.parse(localStorage.getItem("teamName")).label : "",
        companyId: localStorage.getItem("cid")
    })
    const [loader, setLoader] = useState(0);
    const [purchase, setPurchase] = useState(false);
    const [teamOption, setTeamOption] = useState([]);
    const [optionData, setOptionData] = useState(localStorage.getItem("teamName") ? JSON.parse(localStorage.getItem("teamName")) : []);
    const [stateList, setStateList] = useState([])

    const { getTeamSuccess: selectedData, getAllRankingTeamListSuccess: teamListData } = useSelector(state => state.teamRanking)
    const { getTeamRankingStatesData: statelistData } = useSelector((state) => state.api);

    useEffect(() => {
        increment();
        dispatch(getTeamRanking(type))
    }, []);

    useEffect(() => {
        if (selectedData) {
            if (selectedData.flag) {
                setTeamList(selectedData.result.data)
                dispatch({
                    type: GET_TEAM_RANKING_SUCCESS,
                    payload: null,
                });
                if (teamOption.length !== 0) {
                    decrement();
                } else {
                    dispatch(getAllTeamList('RANKING'))
                }
            } else {
                dispatch({
                    type: GET_TEAM_RANKING_SUCCESS,
                    payload: null,
                });
                decrement();
            }
        }
    }, [selectedData])

    useEffect(() => {
        if (teamListData) {
            if (teamListData.flag) {
                let result = teamListData.result;
                let arr = [];
                result.data.map((item, i) => {
                    arr.push({ value: item._id, label: item.rosterName })
                });
                setTeamOption(arr)
                dispatch({
                    type: GET_ALL_RANKING_TEAM_LIST_SUCCESS,
                    payload: null,
                });
                if (stateList.length !== 0) {
                    decrement();
                } else {
                    dispatch(getAllStates('TEAM_RANKING'))
                }
            } else {
                dispatch({
                    type: GET_ALL_RANKING_TEAM_LIST_SUCCESS,
                    payload: null,
                });
                decrement();
            }
        }
    }, [teamListData])

    useEffect(() => {
        if (statelistData) {
            if (statelistData.flag) {
                let result = statelistData.result;
                setStateList(result.data)
                dispatch({
                    type: GET_TEAM_RANKING_STATES_DETAILS_SUCCESS,
                    payload: null,
                });
                decrement();
            } else {
                dispatch({
                    type: GET_TEAM_RANKING_STATES_DETAILS_SUCCESS,
                    payload: null,
                });
                decrement();
            }
        }
    }, [statelistData])

    const increment = () => {
        setLoader(1)
    }

    const decrement = () => {
        setLoader(-1)
    }

    const updateCategory = e => {
        increment();
        setType((prevState) => ({
            ...prevState,
            rankType: Number(e.target.value),
            state: Number(e.target.value) === 1 ? "" : type.state
        }))
        dispatch(getTeamRanking({
            page: 0,
            limit: 0,
            type: 1,
            rankType: Number(e.target.value),
            ageGroup: type.ageGroup,
            position: "",
            gradeYear: "",
            state: Number(e.target.value) === 1 ? "" : type.state,
            teamName: type.teamName,
            companyId: localStorage.getItem("cid")
        }))
        return () => {
            decrement();
        }
    }

    const updateState = e => {
        increment();
        setType((prevState) => ({
            ...prevState,
            state: e.target.value
        }))
        dispatch(getTeamRanking({
            page: 0,
            limit: 0,
            type: 1,
            rankType: type.rankType,
            ageGroup: type.ageGroup,
            position: "",
            gradeYear: "",
            state: e.target.value,
            teamName: type.teamName,
            companyId: localStorage.getItem("cid")
        }))
        return () => {
            decrement();
        }
    }

    const changeValue = e => {
        console.log(e.target.value)
        increment();
        setType((nextState) => ({
            ...nextState,
            ageGroup: e.target.value
        }))
        dispatch(getTeamRanking({
            page: 0,
            limit: 0,
            type: 1,
            rankType: Number(type.rankType),
            ageGroup: e.target.value,
            position: "",
            gradeYear: "",
            state: type.state,
            teamName: type.teamName,
            companyId: localStorage.getItem("cid")
        }))
        return () => {
            decrement();
        }
    }

    const dispatch = useDispatch()

    const goto = (id) => {
        props.history.push(`/pw-team-profile/${id}`);
    }

    const purchasePW = () => {
        setPurchase(true);
    }

    const handlePurchaseClose = () => {
        setPurchase(false);
        document.body.classList.remove("modal-open");
    }

    const setOption = (val) => {
        console.log("val:---", val);
        goto(val.value)
        // setType((prevState) => ({
        //     ...prevState,
        //     teamName: val.label,
        // }))
        // setOptionData(val);
        // increment();
        // dispatch(getTeamRanking({
        //     page: 0,
        //     limit: 0,
        //     type: 1,
        //     rankType: Number(type.rankType),
        //     ageGroup: type.ageGroup,
        //     position: type.position,
        //     gradeYear: type.gradeYear,
        //     state: type.state,
        //     teamName: val.label,
        //     companyId: type.companyId
        // }))
    }

    return (
        <>
            <Header />
            {/* <RankingHeroBanner increment={increment} decrement={decrement} /> */}

            <div className="team-ranking-section pb-70 pt-70" >
                <div className="container">
                    <div className="team-ranking">
                        <div className="team-rankings-info">
                            <h3 className="section_title">Team Rankings</h3>
                            <div className="national">
                                <Select
                                    defaultValue={optionData}
                                    value={optionData}
                                    options={teamOption}
                                    onChange={(e) => setOption(e)}
                                    closeMenuOnSelect={true}
                                    className="ranking-select"
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                    placeholder={"Team search"}
                                />
                            </div>
                            <div className="national">
                                <select className="custom-select" name="rankType" value={type.rankType} onChange={updateCategory}>
                                    <option value="1" className={`list-group-item ${type.rankType === 1 ? "active" : ""}`}>National</option>
                                    <option value="2" className={`list-group-item ${type.rankType === 2 ? "active" : ""}`}>State</option>
                                </select>
                            </div>
                            {
                                type.rankType === 2 &&
                                <div className="national">
                                    <select className="custom-select" name="state" value={type.state} onChange={updateState}>
                                        <option value="">State</option>
                                        {stateList &&
                                            stateList.map((item) => {
                                                return (<option value={item.state}>{item.state}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            <div className="national">
                                <select className="custom-select" name="ageGroup" value={type.ageGroup} onChange={changeValue}>
                                    <option value="8" className="list-group-item">8U</option>
                                    <option value="9" className="list-group-item">9U</option>
                                    <option value="10" className="list-group-item">10U</option>
                                    <option value="11" className="list-group-item">11U</option>
                                    <option value="12" className="list-group-item">12U</option>
                                    <option value="13" className="list-group-item">13U</option>
                                    <option value="14" className="list-group-item">14U</option>
                                    <option value="15" className="list-group-item">15U</option>
                                    <option value="16" className="list-group-item">16U</option>
                                    <option value="17" className="list-group-item">17U</option>
                                    <option value="18" className="list-group-item">18U</option>
                                </select>
                                {/* <ul className="list-group mb-15" onClick={changeValue}>
                                    <li className={`list-group-item ${type.ageGroup === 8 ? "active" : ""}`} value="8">8U</li>
                                    <li className={`list-group-item ${type.ageGroup === 9 ? "active" : ""}`} value="9">9U</li>
                                    <li className={`list-group-item ${type.ageGroup === 10 ? "active" : ""}`} value="10">10U</li>
                                    <li className={`list-group-item ${type.ageGroup === 11 ? "active" : ""}`} value="11">11U</li>
                                    <li className={`list-group-item ${type.ageGroup === 12 ? "active" : ""}`} value="12">12U</li>
                                </ul> */}
                            </div>

                        </div>
                        <div className="national-rankings-info">
                            <h3 className="section_title">{type.ageGroup == 19 ? "18U+" : type.ageGroup + "U"} {type.rankType == "1" ? 'National' : 'State'} Rankings</h3>
                            {
                                // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") ?
                                //     <div className="ranking-logo">
                                //         <div className="national-rank-item">
                                //             <div className="national-rank-img">
                                //                 <img src={imagesArr.cart_user} />
                                //                 <span>01</span>
                                //             </div>
                                //         </div>
                                //         <div className="national-rank-item">
                                //             <div className="national-rank-img">
                                //                 <img src={imagesArr.dashboard_user} />
                                //                 <span>02</span>
                                //             </div>
                                //         </div>
                                //         <div className="national-rank-item">
                                //             <div className="national-rank-img">
                                //                 <img src={imagesArr.cart_user} />
                                //                 <span>03</span>
                                //             </div>
                                //         </div>
                                //     </div>
                                //     :
                                    (type.rankType) && (teamList && teamList.length !== 0) ?
                                        <div className="ranking-logo">
                                            {teamList.map((item, i) => {
                                                return (
                                                    <div className="national-rank-item">
                                                        <div className="national-rank-img">
                                                            {item.teamLogo ?
                                                                <img src={item.teamLogo} alt="national ranking" />
                                                                :
                                                                <img src={imagesArr.default_user} alt="national ranking" />
                                                            }
                                                            {((i + 1) > 9) ? <span>{i + 1}</span> : <span>0{i + 1}</span>}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        :
                                        <p className="text-center pt-10">No data found</p>
                            }
                        </div>
                    </div>

                    <div className="ranking-table">
                        <div className="table-responsive-xl">
                            <table className="table">
                                <thead>
                                    <th>Rank</th>
                                    <th>Team Name</th>
                                    <th>State</th>
                                    <th>PW Record</th>
                                </thead>
                                <tbody>
                                    {
                                        // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") ?
                                        //     <>
                                        //         <tr>
                                        //             <td className="p-0" colSpan="4">
                                        //                 <div className="ranking-banner">
                                        //                     <div className="ranking-banner-logo">
                                        //                         <img src={imagesArr.ranking_banner_img} alt="ranking banner" />
                                        //                         <p>Available Exclusivly Through the DiamondConnect App</p>
                                        //                         <div className="see-more-btn">
                                        //                             <a class="btn ranking-btn" href="">See More</a>
                                        //                         </div>
                                        //                         <span className="lock-icon" onClick={() => purchasePW()}><img src={imagesArr.lock_icon} alt="lock icon" /></span>
                                        //                     </div>
                                        //                 </div>
                                        //                 <div className="lock_view">
                                        //                     <div className="logo_lock">
                                        //                         <div className="logo_img">
                                        //                             <img src={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} />
                                        //                         </div>
                                        //                         <div className="lock_text">
                                        //                             <p>PW+ displays the top five rankings in all categories.<span onClick={() => purchasePW()}>Subscribe </span>to one of our Premium Packages to <span onClick={() => purchasePW()}>see more.</span></p>
                                        //                         </div>
                                        //                         <div className="lock_img" onClick={() => purchasePW()}>
                                        //                             <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                        //                         </div>
                                        //                     </div>
                                        //                 </div>
                                        //             </td>
                                        //         </tr>
                                        //     </>
                                        //     :
                                            (teamList && teamList.length !== 0) ?
                                                <>
                                                    {
                                                        teamList.map((item, i) => {
                                                            return (
                                                                <tr onClick={() => goto(item._id)}>
                                                                    {((i + 1) > 9) ?
                                                                        <td>{i + 1}</td>
                                                                        :
                                                                        <td>0{i + 1}</td>
                                                                    }
                                                                    <td className="team-name" >
                                                                        <span>
                                                                            <img src={item.teamLogo ? item.teamLogo : imagesArr.default_user} alt="national ranking" />
                                                                            {item.rosteName ? item.rosteName : "-"}
                                                                        </span>
                                                                    </td>
                                                                    <td>{item.state ? item.state : "-"}</td>
                                                                    <td>{item.PWRecord ? item.PWRecord : "-"}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <tr>
                                                    <td colspan="4">
                                                        <p className="text-center">No data found</p>
                                                    </td>
                                                </tr>

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <Preloader flag={loader > 0 ? true : false} />
            {
                purchase &&
                <PurchaseProspectwire
                    flag={purchase}
                    close={handlePurchaseClose}
                    type="PW"
                />
            }
        </>
    )
}

export default TeamRanking;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pastChampions, championsEvents } from '../../../../saga/actions/common/pastChampion.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import ReactPaginate from "react-paginate";

class pastChampionsInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendData: {
                page: 0,
                limit: 10,
                Event: "",
                Division: "",
                Year: ""
            },
            championsList: [],
            eventList: [],

            // pagination variable diclaration
            offset: 0,
            perPage: 10,
            currentPage: "",
            pageCount: ""
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.props.increment();
        this.props.pastChampions(this.state.sendData);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.pastChampionDetails &&
            nextProps.pastChampionDetails.pastChampionReq &&
            nextProps.pastChampionDetails.pastChampionData
        ) {
            nextProps.pastChampionDetails.pastChampionReq = false;
            console.log("nextProps.pastChampionDetails:--", this.state.perPage);
            if (nextProps.pastChampionDetails.pastChampionData.flag) {
                this.setState({
                    pageCount: Math.ceil(nextProps.pastChampionDetails.pastChampionData.result.total / this.state.perPage),
                    championsList: nextProps.pastChampionDetails.pastChampionData.result.docs
                }, () => {
                    nextProps.pastChampionDetails.pastChampionData = null;
                    this.props.championsEvents();
                })
            } else {
                this.commonCall.errorMessage(nextProps.pastChampionDetails.pastChampionData.message);
                nextProps.pastChampionDetails.pastChampionData = null;
                this.props.decrement();
            }
        }

        if (nextProps.pastChampionDetails &&
            nextProps.pastChampionDetails.championEventReq &&
            nextProps.pastChampionDetails.championEventData
        ) {
            nextProps.pastChampionDetails.championEventReq = false;
            console.log("nextProps.championEventData:--", nextProps.pastChampionDetails.championEventData);
            if (nextProps.pastChampionDetails.championEventData.flag) {
                this.setState({
                    eventList: nextProps.pastChampionDetails.championEventData.result.data
                }, () => {
                    nextProps.pastChampionDetails.championEventData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.pastChampionDetails.championEventData.message);
                nextProps.pastChampionDetails.championEventData = null;
                this.props.decrement();
            }
        }
    }

    renderList = () => {
        return (
            this.state.championsList.length !== 0 ?
                this.state.championsList.map((list, i) => {
                    return (
                        <tr>
                            <td>{list.Event}</td>
                            <td>{list.Division}</td>
                            <td>
                                <ul>
                                    {
                                        Object.entries(list.Year).map((key, value) => {
                                            if (key[0] && key[1]) {
                                                return (
                                                    <li>{key[0]}  |  {key[1]}</li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan="3"><p className="text-center m-0 past-champions-nodeta">No data found</p></td>
                </tr>
        )
    }

    handlePageClick = (e) => {
        console.log("e:----------------------------", e);

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState((prevState) => ({
            ...prevState,
            sendData: {
                ...prevState.sendData,
                page: selectedPage + 1,
                limit: Number(this.state.perPage),
            },
            currentPage: selectedPage,
            offset: offset,
        }), () => {
            console.log(this.state.sendData);
            this.props.increment();
            this.props.pastChampions(this.state.sendData);
        });
    };

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
            sendData: {
                ...prevState.sendData,
                page: 1,
                limit: Number(value),
            },
            currentPage: 0
        }), () => {
            console.log("set per page:----", this.state.sendData);
            this.props.increment();
            this.props.pastChampions(this.state.sendData);
        })
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            sendData: {
                ...prevState.sendData,
                [name]: value,
            }
        }))
    }

    callFilter = () => {
        this.setState((prevState) => ({
            ...prevState,
            sendData: {
                ...prevState.sendData,
                page: 1,
                limit: Number(this.state.perPage),
            },
            currentPage: 0
        }), () => {
            this.props.increment();
            this.props.pastChampions(this.state.sendData);
        })

    }

    resetFilter = () => {
        this.setState((prevState) => ({
            ...prevState,
           sendData: {
                page: 0,
                limit: 10,
                Event: "",
                Division: "",
                Year: ""
            },
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount: ""
        }), () => {
            this.props.increment();
            this.props.pastChampions(this.state.sendData);
        })
    }

    render() {
        const { sendData, eventList } = this.state;
        const years = Array.from(new Array(14), (val, index) => ((new Date()).getFullYear() - index))
        const sorter = (a, b) => {
            if (a !== b) {
                return a - b;
            }
        };
        years.sort(sorter);
        return (
            <div className="past-champions">
                <div className="container">
                    <h1 className="section_title">Past Champions</h1>
                    <form>
                        <div className="filter-champion">
                            <div className="form-group ">
                                <select className="custom-select" name="Event" value={sendData.Event} onChange={this.onHandleChange}>
                                    <option value="">Event</option>
                                    {
                                        eventList &&
                                        eventList.map((item, i) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group ">
                                <select className="custom-select" name="Division" value={sendData.Division} onChange={this.onHandleChange}>
                                    <option value="" >Age Group</option>
                                    <option value="8U">8U</option>
                                    <option value="9U">9U</option>
                                    <option value="10U">10U</option>
                                    <option value="11U">11U</option>
                                    <option value="12U">12U</option>
                                    <option value="13U">13U</option>
                                    <option value="14U">14U</option>
                                    <option value="15U">15U</option>
                                    <option value="16U">16U</option>
                                    <option value="17U">17U</option>
                                    <option value="18U">18U</option>
                                </select>
                            </div>
                            <div className="form-group ">
                                <select className="custom-select" name="Year" value={sendData.Year} onChange={this.onHandleChange}>
                                    <option value="" >Year</option>
                                    {
                                        years &&
                                        years.map((item) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <span className="see_post">
                                <Link onClick={() => this.resetFilter()}>See All</Link>
                            </span>
                            <div className="form-group">
                                <a class="btn" onClick={() => this.callFilter()}>Filter</a>
                            </div>
                        </div>
                    </form>
                    <div className="past-champions-table">
                        <div className="table-responsive-xl">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Event</th>
                                        <th scope="col">Age Group</th>
                                        <th scope="col">Winner</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderList()}
                                </tbody>
                            </table>
                            <div className="pagenation-search">
                                <div className="page-search">
                                    Row per page : <select className="custom-select" name="perPage" value={this.state.perPage} onChange={(e) => this.onChange(e)}>
                                        <option value="5" selected={this.state.perPage === "5" ? true : false} >5</option>
                                        <option value="10" selected={this.state.perPage === "10" ? true : false}  >10</option>
                                        <option value="15" selected={this.state.perPage === "15" ? true : false}  >15</option>
                                        <option value="20" selected={this.state.perPage === "20" ? true : false}  >20</option>
                                    </select>
                                </div>
                                <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={1}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"champion-pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
pastChampionsInfo.propTypes = {
    pastChampions: PropTypes.func.isRequired,
    championsEvents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    pastChampionDetails: state.pastChampionDetails,
});

export default connect(mapStateToProps, { pastChampions, championsEvents })(pastChampionsInfo);
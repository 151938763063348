import axios from 'axios';
import { Auth_Coach_Key, Auth_Player_Key, API_Url, Authorization, checkIsValidCoachToken, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_BASIC_REQUEST,
    GET_BASIC_SUCCESS,
    GET_BASIC_ERROR,

    GET_SEARCH_REQUEST,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,

    UP_IMAGE_REQUEST,
    UP_IMAGE_SUCCESS,
    UP_IMAGE_ERROR,

    SAVE_BASIC_REQUEST,
    SAVE_BASIC_SUCCESS,
    SAVE_BASIC_ERROR,

    GET_INVITE_COACH_REQUEST,
    GET_INVITE_COACH_SUCCESS,
    GET_INVITE_COACH_ERROR,

    GET_INVITE_SUB_COACH_REQUEST,
    GET_INVITE_SUB_COACH_SUCCESS,
    GET_INVITE_SUB_COACH_ERROR,

    ACCEPT_COACH_NOTIFY_REQUEST,
    ACCEPT_COACH_NOTIFY_SUCCESS,
    ACCEPT_COACH_NOTIFY_ERROR,
    CANCEL_COACH_NOTIFY_REQUEST,
    CANCEL_COACH_NOTIFY_SUCCESS,
    CANCEL_COACH_NOTIFY_ERROR,
} from '../../../../constants/authantication/signup/coach/basic.const';
import histrory from '../../../../../History';

export const getBasicDetails = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_BASIC_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/basic`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: GET_BASIC_SUCCESS,
            payload: basic.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_BASIC_ERROR, error: error.response.data.message })
    });
};

export const searchZipcode = (data, userType) => dispatch => {
    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);
    dispatch({ type: GET_SEARCH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}setting/territory/zipcode/${data}`,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(search => {
        dispatch({
            type: GET_SEARCH_SUCCESS,
            payload: search.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_SEARCH_ERROR, error: error.response.data.message })
    });
};

export const uploadImage = (fileObj, userType) => dispatch => {
    console.log("fileObj:--", fileObj);

    (userType === "Coach") ? checkIsValidCoachToken(histrory) : checkIsValidPlayerToken(histrory);

    dispatch({ type: UP_IMAGE_REQUEST })

    let formData = new FormData();
    formData.append('file', fileObj.imgFile);

    axios({
        method: 'POST',
        url: `${API_Url}file/upload?documentType=${fileObj.documentType}`,
        data: formData,
        headers: {
            'x-auth-token': (userType === "Coach") ? Auth_Coach_Key : Auth_Player_Key,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    }).then(image => {
        console.log("images:--", image);
        dispatch({
            type: UP_IMAGE_SUCCESS,
            payload: image.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: UP_IMAGE_ERROR, error: error.response.data.message })
    });
};

export const saveBasic = (data) => dispatch => {
    console.log("saveBasic:--", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_BASIC_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/basic`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(basic => {
        console.log("basic:---- ", basic);
        dispatch({
            type: SAVE_BASIC_SUCCESS,
            payload: basic.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_BASIC_ERROR, error: error.response.data.message })
    });
};

export const getCoachInvitation = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_INVITE_COACH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/coach/invitation`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(invite => {
        dispatch({
            type: GET_INVITE_COACH_SUCCESS,
            payload: invite.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_INVITE_COACH_ERROR, error: error.response.data.message })
    });
};

export const getSubCoachInvitation = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_INVITE_SUB_COACH_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/invitaion`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(subinvite => {
        dispatch({
            type: GET_INVITE_SUB_COACH_SUCCESS,
            payload: subinvite.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_INVITE_SUB_COACH_ERROR, error: error.response.data.message })
    });
};

export const saveCoachAccept = (data) => dispatch => {
    console.log("saveBasic:--", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: ACCEPT_COACH_NOTIFY_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/invitaion`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(acceptCoach => {
        console.log("acceptCoach:---- ", acceptCoach);
        dispatch({
            type: ACCEPT_COACH_NOTIFY_SUCCESS,
            payload: acceptCoach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: ACCEPT_COACH_NOTIFY_ERROR, error: error.response.data.message })
    });
};

export const cancelCoachAccept = (data) => dispatch => {
    console.log("cancelBasic:--", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: CANCEL_COACH_NOTIFY_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/reject/invitation`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(cancelCoach => {
        console.log("acceptCoach:---- ", cancelCoach);
        dispatch({
            type: CANCEL_COACH_NOTIFY_SUCCESS,
            payload: cancelCoach.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: CANCEL_COACH_NOTIFY_ERROR, error: error.response.data.message })
    });
};




import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import CommonHeroBanner from '../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import { getLicense, updateStatus, deleteAssign, deleteCoachAssign } from "../../saga/actions/views/coach/license.action";
import Preloader from '../../components/preloader';
import AssignLicense from '../../views/popup/assignLicense';
import moment from "moment";
import commonCalls from "../../businesslogic/commonCalls";
import Confirmation from '../popup/confirmation';


class coachLicense extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: localStorage.getItem("seatIndex") ? JSON.parse(localStorage.getItem("seatIndex")) : 0,
            getLic: {
                fullDetail: true
            },
            loader: false,
            licenseArr: [],
            listData: "",
            openAssign: false,
            isEdited: false,
            fetchLicense: {},
            subScriptionId: "",
            comfirmPopUp: false,
            userId: "",
            subTab: "player",
            showTab: false,
            sub_id: "",
            gameType: "1"
        }
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.setState({
            showTab: true
        })
        this.props.getLicense(this.state.getLic);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.licenseData &&
            nextProps.licenseData.licenseReq &&
            nextProps.licenseData.licenseData
        ) {
            console.log("nextProps.licenseData.licenseData:--", nextProps.licenseData.licenseData);
            nextProps.licenseData.licenseReq = false;
            if (nextProps.licenseData.licenseData.flag) {
                if (nextProps.licenseData.licenseData.result.data.length !== 0) {
                    this.setState({
                        licenseArr: nextProps.licenseData.licenseData.result.data,
                        listData: nextProps.licenseData.licenseData.result.data[this.state.tabs],
                        gameType: localStorage.getItem("gameType")
                    }, () => {
                        nextProps.licenseData.licenseData = null;
                        if (this.state.showTab) {
                            this.setState({
                                subTab: (this.state.listData.productId.name === "BallR" || this.state.listData.productId.name === "BCTeam") ? "coach" : "player",
                                sub_id: this.state.listData._id
                            }, () => {
                                this.setState({
                                    showTab: false
                                })
                            })
                        }
                        this.setLoader(false);
                    })
                } else {
                    nextProps.licenseData.licenseData = null;
                    this.setLoader(false);
                }
            } else {
                nextProps.licenseData.licenseData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.licenseData &&
            nextProps.licenseData.upStatusReq &&
            nextProps.licenseData.upStatusData
        ) {
            nextProps.licenseData.upStatusReq = false;
            if (nextProps.licenseData.upStatusData.flag) {
                this.commonCall.successMessage(nextProps.licenseData.upStatusData.message);
                nextProps.licenseData.upStatusData = null;
                this.props.getLicense(this.state.getLic);
            } else {
                nextProps.licenseData.upStatusData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.licenseData &&
            nextProps.licenseData.deleteLicenseReq &&
            nextProps.licenseData.deleteLicenseData
        ) {
            console.log("data:-----", nextProps.licenseData.deleteLicenseData);
            nextProps.licenseData.deleteLicenseReq = false;
            if (nextProps.licenseData.deleteLicenseData.flag) {
                this.commonCall.successMessage(nextProps.licenseData.deleteLicenseData.message);
                nextProps.licenseData.deleteLicenseData = null;
                document.body.classList.remove("modal-open");
                this.setState((prevState) => ({
                    ...prevState,
                    comfirmPopUp: false,
                    userId: "",
                    subScriptionId: ""
                }))
                this.props.getLicense(this.state.getLic);
            } else {
                nextProps.licenseData.deleteLicenseData = null;
                this.setLoader(false);
            }
        }
    }

    handleTabs = (e, data) => {
        this.setState({
            tabs: e,
            listData: data,
            subTab: (data.productId.name === "BallR" || data.productId.name === "BCTeam") ? "coach" : "player",
        }, () => {
            this.setState({
                sub_id: this.state.licenseArr[this.state.tabs]._id
            })
        })
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    closePopup = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            openAssign: false,
            isEdited: false,
            subScriptionId: "",
            userId: ""
        }, () => {
            this.setLoader(true);
            this.props.getLicense(this.state.getLic);
        })
    }

    updateStatus = (data, subId) => {
        let sendData = {
            subscription_id: subId,
            user_id: data.user._id,
            isActive: !data.isActive
        }
        this.setLoader(true);
        this.props.updateStatus(sendData)
    }

    renderLicList = (data) => {
        let licenseList = this.state.subTab !== "coach" ? data.licGiven.length !== 0 ? data.licGiven : [] : data.licGivenCoach.length !== 0 ? data.licGivenCoach : []
        return (
            licenseList.length != 0 ?
                licenseList.map((list, i) => {
                    return (
                        <tr key={i}>
                            <td colspan="2">{list.user ? list.user.fname + " " + list.user.lname : "-"}</td>
                            <td colspan="2"> {list.user ? list.user.email : "-"} </td>
                            <td colspan="2"> {list.indefiniteAccess ? "-" : moment(list.toDate).format('MM/YYYY')}</td>
                            <td colspan="2"> {list.indefiniteAccess ? "-" : moment(list.assignDate).format('MM/YYYY')} </td>
                            <td colspan="2" className="tablecheckbox">
                                <div className="custom-checkbox">
                                    <label>
                                        <input type="checkbox" checked={list.isActive ? true : false} onClick={() => this.updateStatus(list, data._id)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                            <td className="edit-deleter">
                                <a class="green-status" onClick={() => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        isEdited: true,
                                        openAssign: true,
                                        fetchLicense: list,
                                        subScriptionId: data._id
                                    }))
                                }}>Edit</a>
                                <a class="red-status" onClick={() => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        comfirmPopUp: true,
                                        subScriptionId: data._id,
                                        userId: list.user._id
                                    }))
                                }}>Delete</a>
                            </td>
                        </tr>
                    );
                })
                :
                <tr>
                    <td colSpan="11" className="text-center">No Data Found</td>
                </tr>

        )
    }

    closeAssignPopup = () => {
        document.body.classList.remove("modal-open");
        this.setState((prevState) => ({
            ...prevState,
            comfirmPopUp: false,
            userId: "",
            subScriptionId: ""
        }))
    }

    deleteAssignData = () => {
        this.setLoader(true);
        let deleteAssign = {
            subscription_id: this.state.subScriptionId,
            user_id: this.state.userId
        }
        if (this.state.subTab !== "coach") return this.props.deleteAssign(deleteAssign);
        else return this.props.deleteCoachAssign(deleteAssign);
    }

    changeSubTab = (type) => {
        this.setState((prevState) => ({
            ...prevState,
            subTab: type
        }))
    }

    render() {
        const { tabs, loader, licenseArr, listData, openAssign, subTab, sub_id, gameType } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main_pro_tab licencetabs">
                    <div className="container">
                        <div className="edit_pro_scroll">
                            <ul className="edit_pro_inner">
                                {
                                    licenseArr.map((list, i) => {
                                        return (
                                            <li className={`${tabs === i ? "active" : ""}`} onClick={() => this.handleTabs(i, list)}>
                                                <Link>{list.productId.name === "BCTeam" && gameType === "2" ? "SCTeam" : list.productId.name}</Link>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        {
                            listData &&
                            <section className="licenceassignment-bollr uploadcsv before-dashboard mt-0">
                                <div className="white-bg tablecontent">
                                    {(listData.productId.name === "BallR" || listData.productId.name === "BCTeam") &&
                                        <div className="tab-bar">
                                            <ul>
                                                <li><a className={`${this.state.subTab === "coach" && "active"}`} onClick={() => this.changeSubTab("coach")}>Coach</a></li>
                                                <li><a className={`${this.state.subTab === "player" && "active"}`} onClick={() => this.changeSubTab("player")}>Player</a></li>
                                            </ul>
                                        </div>}


                                    <div className="tableinfo">
                                        <div>
                                            {this.state.subTab === "player"
                                                ? <h3>{listData.productId.name === "BCTeam" && gameType === "2" ? "SCTeam" : listData.productId.name}-License <span>{listData.licRemain + "/" + listData.licTotal + " Seats Available"}</span></h3>
                                                : <h3>{listData.productId.name === "BCTeam" && gameType === "2" ? "SCTeam" : listData.productId.name}-License <span>{listData.licRemainCoach + "/" + listData.licTotalCoach + " Seats Available"}</span></h3>
                                            }
                                        </div>
                                        <div className="rightsection">
                                            <Link className="btn light-green btn-addnewevent" onClick={() => this.setState({ openAssign: !openAssign })}>Assign License</Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive-xl">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" colspan="2">Name</th>
                                                    <th scope="col" colspan="2">Email</th>
                                                    <th scope="col" colspan="2">License Expired on</th>
                                                    <th scope="col" colspan="2">Assigned on</th>
                                                    <th scope="col" colspan="2">Active</th>
                                                    <th scope="col" colspan="2">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderLicList(listData)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        }
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
                {
                    openAssign &&
                    <AssignLicense
                        flag={openAssign}
                        close={this.closePopup}
                        isEdited={this.state.isEdited}
                        data={this.state.fetchLicense}
                        subScriptionId={this.state.subScriptionId}
                        subTab={this.state.subTab}
                        sub_id={sub_id}
                    />
                }
                {
                    this.state.comfirmPopUp &&
                    <Confirmation
                        flag={this.state.comfirmPopUp}
                        close={this.closeAssignPopup}
                        type="license"
                        ok={this.deleteAssignData}
                    />
                }
            </>
        );
    }
}

coachLicense.propTypes = {
    getLicense: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
    deleteAssign: PropTypes.func.isRequired,
    deleteCoachAssign: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    licenseData: state.licenseInfo,
});

export default connect(mapStateToProps, { getLicense, updateStatus, deleteAssign, deleteCoachAssign })(coachLicense);

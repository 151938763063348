import React, { Component } from "react";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import CommonHeroBanner from "../../components/commonHeroBanner";

class success extends Component {
    render() {
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <div className="container">
                    <div className="payment-container">
                        <div className="payment-thanq">
                            <div className="thanq-title">
                                <h4>Thank You!</h4>
                                <p>
                                    Your payment has been done successfully.
                                </p>
                                <a class="btn" onClick={() => this.props.history.push('/analyzrapp')}>Go to AnalyzR</a>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default success;
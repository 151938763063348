import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, checkIsValidCompanyToken } from '../../../helpers/authToken';
import {
    GET_PLAYER_PERSONAL_INFO_REQUEST,
    GET_PLAYER_PERSONAL_INFO_SUCCESS,
    GET_PLAYER_PERSONAL_INFO_ERROR,

    SET_CALCULATE_DATA_REQUEST,
    SET_CALCULATE_DATA_SUCCESS,
    SET_CALCULATE_DATA_ERROR,

    GET_CALCULATE_DATA_REQUEST,
    GET_CALCULATE_DATA_SUCCESS,
    GET_CALCULATE_DATA_ERROR,

    SET_CONNECTION_REQ_REQUEST,
    SET_CONNECTION_REQ_SUCCESS,
    SET_CONNECTION_REQ_ERROR,

    SAVE_COMMITTED_REQUEST,
    SAVE_COMMITTED_SUCCESS,
    SAVE_COMMITTED_ERROR,
} from '../../../constants/view/company/personalInfo.const';
import histrory from '../../../../History';

export const saveCommittedFields = (data) => dispatch => {
    dispatch({ type: SAVE_COMMITTED_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}pwUser/committed`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(committed => {
        dispatch({
            type: SAVE_COMMITTED_SUCCESS,
            payload: committed.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SAVE_COMMITTED_ERROR, error: error.response.data.message })
        }
    });
};

export const sendConnectionReq = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: SET_CONNECTION_REQ_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/user/following" : "pwUser/user/following"}`,
        data: data,
        headers: setHeader
    }).then(connection => {
        dispatch({
            type: SET_CONNECTION_REQ_SUCCESS,
            payload: connection.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SET_CONNECTION_REQ_ERROR, error: error.response.data.message })
        }
    });
};

export const getPersonalInfo = (id) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_PLAYER_PERSONAL_INFO_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}${mode ? `pwUser/userInfo/${id}/${localStorage.getItem("cid")}` : `pwUser/woAuth/userInfo/${id}/${localStorage.getItem("cid")}`}`,
        headers: setHeader
    }).then(info => {
        dispatch({
            type: GET_PLAYER_PERSONAL_INFO_SUCCESS,
            payload: info.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PLAYER_PERSONAL_INFO_ERROR, error: error.response.data.message })
        }
    });
};

export const sendCalculate = (data) => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: SET_CALCULATE_DATA_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}${mode ? "pwUser/user/calculate" : "pwUser/user/calculate"}`,
        data: data,
        headers: setHeader
    }).then(info => {
        dispatch({
            type: SET_CALCULATE_DATA_SUCCESS,
            payload: info.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SET_CALCULATE_DATA_ERROR, error: error.response.data.message })
        }
    });
};

export const getCalculate = () => dispatch => {
    let mode = localStorage.getItem("webglToken");
    let setHeader;
    if (mode) {
        setHeader = {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
        checkIsValidCompanyToken(histrory);
    } else {
        setHeader = {
            'Accept': 'application/json'
        }
    }
    dispatch({ type: GET_CALCULATE_DATA_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}${mode ? "pwUser/user/calculateData" : "pwUser/user/calculateData"}`,
        headers: setHeader
    }).then(info => {
        dispatch({
            type: GET_CALCULATE_DATA_SUCCESS,
            payload: info.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_CALCULATE_DATA_ERROR, error: error.response.data.message })
        }
    });
};



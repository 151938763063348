import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_PLAYER_TEAM_SEARCH_REQUEST,
    GET_PLAYER_TEAM_SEARCH_SUCCESS,
    GET_PLAYER_TEAM_SEARCH_ERROR,

    SAVE_PLAYER_TEAM_REQUEST,
    SAVE_PLAYER_TEAM_SUCCESS,
    SAVE_PLAYER_TEAM_ERROR,

    SAVE_SKIP_PLAYER_REQUEST,
    SAVE_SKIP_PLAYER_SUCCESS,
    SAVE_SKIP_PLAYER_ERROR,
} from '../../../../constants/authantication/signup/player/team.const';
import histrory from '../../../../../History';

export const teamList = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_PLAYER_TEAM_SEARCH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parentTeam/player/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(team => {
        console.log("team:--", team);
        dispatch({
            type: GET_PLAYER_TEAM_SEARCH_SUCCESS,
            payload: team.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_PLAYER_TEAM_SEARCH_ERROR, error: error.response.data.message })
    });
};

export const savePlayerTeam = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_PLAYER_TEAM_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/parentTeam`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(team => {
        console.log("terwam:----", team)
        dispatch({
            type: SAVE_PLAYER_TEAM_SUCCESS,
            payload: team.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_PLAYER_TEAM_ERROR, error: error.response.data.message })
    });
};

export const skipStep = (data) => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_SKIP_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/skip/team`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(skip => {
        console.log("terwam:----", skip)
        dispatch({
            type: SAVE_SKIP_PLAYER_SUCCESS,
            payload: skip.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_SKIP_PLAYER_ERROR, error: error.response.data.message })
    });
};


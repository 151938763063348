import React, { Component } from "react";
import { imagesArr } from "../../assets/images";

class TeamInfo extends Component {
  componentDidMount() {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
    }
  }

  render() {
    const { flag, content } = this.props;
    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog team-info-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.white_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body ">
                <div className="team-box">
                  <h5>{content.title}</h5>
                  <span>{content.position}</span>
                  <p>{content.email}</p>
                </div>
                <div className="team-info-popup-scroll" dangerouslySetInnerHTML={{
                  __html: content.description
                }}>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog team-info-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.white_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body ">
                <div className="team-box">
                  <h5>Maurizio Ortiz</h5>
                  <span>Director, Latin America</span>
                  <p>maurizio@baseballcloud.com</p>
                </div>
                <div className="team-info-popup-scroll">
                  <div className="top-title text-center">
                    <p className="text-underline">Brian Werner Bio</p>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum et tortor orci risus enim quam metus integer purus. Elementum non proin in purus est sit duis amet sit. Purus aenean laoreet id phasellus adipiscing etiam phasellus in mauris. Ac senectus netus diam sed sit. Nunc pretium amet sit a, eu. Arcu et elementum odio a sed enim. Lobortis in purus egestas curabitur elementum, amet et nisi, turpis. Aliquam id bibendum diam vehicula rutrum vitae. Potenti duis sem vitae aenean. Sit est tincidunt ante bibendum felis id arcu. </p>

                  <div className="title-list-type">
                    <h4>Prior to PW (Education & Athletics)</h4>
                    <ul>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                      <li>Started at Prospect Wire in 2008, serving as a Scout for the All Florida Games.</li>
                    </ul>
                  </div>
                  <p>During time with PW, I have served in almost every facet of the company’s operations. 2nd longest tenured employee (Frank Gates is the longest).
                  </p>
                  <div className="list-type">
                    <ul>
                      <li>Started at Prospect Wire in 2008, <span className="color-red">serving as a Scout for the All Florida Games.</span></li>
                      <li>Started at Prospect Wire in 2008, <span className="color-red">serving as a Scout for the All Florida Games.</span></li>
                      <li>Started at Prospect Wire in 2008, <span className="color-red">serving as a Scout for the All Florida Games.</span></li>
                      <li>Started at Prospect Wire in 2008, <span className="color-red">serving as a Scout for the All Florida Games.</span></li>
                      <li>Started at Prospect Wire in 2008, <span className="color-blue">serving as a Scout for the All Florida Games.</span></li>
                      <li>Started at Prospect Wire in 2008, <span className="color-blue">serving as a Scout for the All Florida Games.</span></li>
                      <li>Started at Prospect Wire in 2008, <span className="color-blue">serving as a Scout for the All Florida Games.</span></li>
                    </ul>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum et tortor orci risus enim quam metus integer purus. Elementum non proin in purus est sit duis amet sit. Purus aenean laoreet id phasellus adipiscing etiam phasellus in mauris. Ac senectus netus diam sed sit. Nunc pretium amet sit a, eu. Arcu et elementum odio a sed enim. Lobortis in purus egestas curabitur elementum, <b>amet et nisi, turpis. Aliquam id bibendum diam vehicula rutrum vitae. Potenti duis sem vitae aenean. Sit est tincidunt ante bibendum felis id arcu.</b></p>

                  <div className="year-list">
                    <table>
                      <tr>
                        <td>1981</td>
                        <td><span className="">Elon</span> College NAIA D1 Football National Champions</td>
                      </tr>
                      <tr>
                        <td>2008-Present</td>
                        <td>Assistant Coach, Recruiting Coordinator Chandler-Gilbert CC</td>
                      </tr>
                    </table>
                    <p>Scouting Career</p>
                    <table>
                      <tr>
                        <td>2008-Present</td>
                        <td><span>Chandler-Gilbert</span> CC Recruiting Coordinator</td>
                      </tr>
                      <tr>
                        <td>2002-Present</td>
                        <td>Associate & Part Time Baseball Scout
                        Kansas City Royals, New York Mets,
                       Houston Astros, Arizona Diamondbacks</td>
                      </tr>
                    </table>
                    <p>Originally from East Hanover,  New Jersey, moved to AZ in 1991<br />
                      Owner of Building Baseball Careers LLC<br />
                      Partner in 7 Tool Athletics LLC<br />
                      I start with PW in July 2012 scoring games moved to scouting players & taking video with short reports in AZ & California events<br />
                      I am married to Tina
                      </p>
                  </div>
                  <img src={imagesArr.banner_img1} />
                  <p><span className="text-larg">BallR:</span> Use it to easily visually explain and analyze ball metrics with quick inputs in real-time. Upload your ball tracking data to unlock the full set of industry-leading features such as fingerprint location, design pitches, craft arsenals, track progress—all in 3D. From dashboards to 3D baseballs. BallR makes advanced concepts <span className="border-bottom-red">actionable and accessible</span> for little league players to professional coaches, from video and fingerprints to predictive ball flight and result <a href=""> models.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      </>
    );
  }
}

export default TeamInfo;

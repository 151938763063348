export const GET_PLAYER_GROUND_REQUEST = 'GET_PLAYER_GROUND_REQUEST';
export const GET_PLAYER_GROUND_SUCCESS = 'GET_PLAYER_GROUND_SUCCESS';
export const GET_PLAYER_GROUND_ERROR = 'GET_PLAYER_GROUND_ERROR';

export const SAVE_PLAYER_GROUND_REQUEST = 'SAVE_PLAYER_GROUND_REQUEST';
export const SAVE_PLAYER_GROUND_SUCCESS = 'SAVE_PLAYER_GROUND_SUCCESS';
export const SAVE_PLAYER_GROUND_ERROR = 'SAVE_PLAYER_GROUND_ERROR';






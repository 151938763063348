import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    SAVE_TRAINING_REQUEST,
    SAVE_TRAINING_SUCCESS,
    SAVE_TRAINING_ERROR,

    GET_TRAINING_REQUEST,
    GET_TRAINING_SUCCESS,
    GET_TRAINING_ERROR,
} from '../../../../constants/authantication/signup/coach/training.const';
import histrory from '../../../../../History';

export const saveTraining = (data) => dispatch => {
    console.log("data:----action:---", data);
    checkIsValidCoachToken(histrory)
    dispatch({ type: SAVE_TRAINING_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/coach/training`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json',
        }
    }).then(training => {
        console.log("training:----", training);
        dispatch({
            type: SAVE_TRAINING_SUCCESS,
            payload: training.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_TRAINING_ERROR, error: error.response.data.message })
    });
};

export const trainingList = () => dispatch => {
    checkIsValidCoachToken(histrory)
    dispatch({ type: GET_TRAINING_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/coach/training`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(training => {
        // console.log("training:----", training);
        dispatch({
            type: GET_TRAINING_SUCCESS,
            payload: training.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_TRAINING_ERROR, error: error.response.data.message })
    });
};


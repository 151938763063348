import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { teamList } from '../../../../saga/actions/authantication/signup/player/team.action';
import Preloader from '../../../../components/preloader';

class team extends Component {

    constructor(props) {
        super(props)
        this.state = {
            teamListArr: [],
            selectedId: '',
            teamArr: {
                parentTeam: ""
            },
            filter: {
                page: 1,
                limit: 10,
                search: ""
            },
            loader: false,
            parentTeam: [],
            invite: false,
        }
    }

    componentWillMount() {
        this.props.onRef(this);

        if (this.props.inviteFlag) {
            this.setState({
                invite: this.props.inviteFlag,
                parentTeam: this.props.data
            }, () => {
                this.setState((prevState) => ({
                    ...prevState,
                    teamArr: {
                        ...prevState.teamArr,
                        parentTeam: this.state.parentTeam._id
                    }
                }));
            })
        } else {
            if (this.state.filter.search >= 2) {
                this.setLoader(true);
                this.props.teamList(this.state.filter);
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.teamDetails &&
            nextProps.teamDetails.searchPlayerTeamRes &&
            nextProps.teamDetails.searchPlayerTeamData
        ) {
            nextProps.teamDetails.searchPlayerTeamRes = false;
            if (nextProps.teamDetails.searchPlayerTeamData.hasOwnProperty('notification') && nextProps.teamDetails.searchPlayerTeamData.notification.length !== 0) {
                this.setState({
                    invite: true,
                    parentTeam: nextProps.teamDetails.searchPlayerTeamData.notification.parentTeam
                }, () => {
                    this.setLoader(false);
                    this.setState((prevState) => ({
                        ...prevState,
                        teamArr: {
                            ...prevState.teamArr,
                            parentTeam: this.state.parentTeam._id
                        }
                    }));
                    nextProps.teamDetails.searchPlayerTeamData = null;
                })
            } else {

                if (this.state.filter.search) {
                    return this.setState({
                        teamListArr: nextProps.teamDetails.searchPlayerTeamData.parentTeamList,
                    }, () => {
                        nextProps.teamDetails.searchPlayerTeamData = null;
                        this.setLoader(false);
                    })
                }

                if (nextProps.teamDetails.searchPlayerTeamData.hasOwnProperty('myParentTeam') && nextProps.teamDetails.searchPlayerTeamData.myParentTeam.length !== 0) {

                    if (this.state.filter.page === 1) {
                        for (let i = 0; i < nextProps.teamDetails.searchPlayerTeamData.myParentTeam.length; i++) {
                            nextProps.teamDetails.searchPlayerTeamData.parentTeamList.unshift(nextProps.teamDetails.searchPlayerTeamData.myParentTeam[i])
                        }

                        this.setState((prevState) => ({
                            ...prevState,
                            teamArr: {
                                parentTeam: this.state.teamArr.parentTeam.length === 0 ? nextProps.teamDetails.searchPlayerTeamData.myParentTeam[0]._id : this.state.teamArr.parentTeam,
                            },
                            selectedId: this.state.teamArr.parentTeam.length === 0 ? nextProps.teamDetails.searchPlayerTeamData.myParentTeam[0]._id : this.state.selectedId,
                        }))
                    }

                    let val = nextProps.teamDetails.searchPlayerTeamData.parentTeamList;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.teamListArr.concat(temp);

                    this.setState((prevState) => ({
                        ...prevState,
                        teamListArr: joined,
                        filter: {
                            ...prevState.filter,
                            page: this.state.filter.page + 1
                        }
                    }), () => {
                        nextProps.teamDetails.searchPlayerTeamData = null;
                        this.setLoader(false);
                    })
                } else {

                    let val = nextProps.teamDetails.searchPlayerTeamData.parentTeamList;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.teamListArr.concat(temp);

                    this.setState((prevState) => ({
                        ...prevState,
                        teamListArr: joined,
                        filter: {
                            ...prevState.filter,
                            page: this.state.filter.page + 1
                        }
                    }), () => {
                        nextProps.teamDetails.searchPlayerTeamData = null;
                        this.setLoader(false);
                    });
                }
            }
        }
    }

    handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            filter: {
                page: 1,
                limit: 10,
                search: value
            },
        }), () => {
            if (value.length !== 0) {
                if (value.length >= 2) {
                    this.setLoader(true);
                    this.props.teamList(this.state.filter);
                }
            } else {
                // this.state.teamListArr = [];
                this.setState((prevState) => ({
                    ...prevState,
                    teamListArr: []
                }))
                this.props.teamDetails.searchPlayerTeamData = null;
                // this.setLoader(true);
                // this.props.teamList(this.state.filter);
            }
        })
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.teamList(this.state.filter);
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "parentTeam") {
            this.setState((prevState) => ({
                ...prevState,
                teamArr: {
                    ...prevState.teamArr,
                    [name]: value
                },
                selectedId: value
            }));
        }

    }

    renderTeamList = () => {
        return (
            this.state.invite ?
                <div className="team-flex">
                    <div className="team-data">
                        <div className="team-img"><img src={this.state.parentTeam.teamLogo ? this.state.parentTeam.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                        <h4>{this.state.parentTeam.name}</h4>
                    </div>
                    <div className="radiobox">
                        <input
                            type="radio"
                            id="parentTeam"
                            value={this.state.parentTeam._id}
                            name="parentTeam"
                            onChange={(e) => this.onHandleChange(e)}
                            checked
                        />
                        <span for="parentTeam"></span>
                    </div>
                </div>
                :
                this.state.teamListArr.length !== 0 ?
                    this.state.teamListArr.map((list, i) => {
                        return (
                            <div className="team-flex" key={i}>
                                <div className="team-data">
                                    <div className="team-img"><img src={list.teamLogo ? list.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                    <h4>{list.name}</h4>
                                </div>
                                <div className="radiobox">
                                    <input
                                        type="radio"
                                        id="parentTeam"
                                        value={list._id}
                                        name="parentTeam"
                                        onChange={(e) => this.onHandleChange(e)}
                                        checked={list._id === this.state.selectedId ? true : false}
                                    />
                                    <span for="parentTeam"></span>
                                </div>
                            </div>
                        );
                    }) :
                    <h4 style={{ textAlign: 'center' }}>No Data Found</h4>
        );
    }

    validationUpdate = (cb) => {
        if (this.state.teamArr.parentTeam) {
            cb({ data: this.state.teamArr, flag: true });
        } else {
            cb({ data: '', flag: false });
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setLoader(true);
            this.props.teamList(this.state.filter);
        }
    }

    render() {
        const { profileData } = this.props;
        const { loader, filter, invite } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg playes-team">
                        <div className="title-name mb-0">
                            <h2>
                                Hi {profileData.fname},
                            </h2>
                            <p>
                                Find your team by searching below. If your team is not listed below click skip.
                            </p>
                        </div>
                        {
                            !invite &&
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your team" name="search" value={filter.search} onChange={this.handleSearch} />
                                        {/* <Link className="serch-btn" onClick={this.onSearch}><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="scrollbar_team" onScroll={this.getScroll}>
                            <div className="select_team ml-0">
                                {this.renderTeamList()}
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        );
    }
}

team.propTypes = {
    teamList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    teamDetails: state.teamPlayerDetails
});

export default connect(mapStateToProps, { teamList })(team);

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import { imagesArr } from "../../../../assets/images";
import EventRow from '../events/details/eventRow';
import { getMultipleEvent } from '../../../../saga/actions/common/eventDetails.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import Preloader from "../../../../components/preloader";
class thankYouForMultiPay extends Component {

    constructor(props) {
        super(props)
        this.state = {
            payId: "",
            loader: false,
            eventsDetailData: [],
            transactionId: "2432655675e464324",
            amount: localStorage.getItem("finalAmount") ? JSON.parse(localStorage.getItem("finalAmount")) : 0,
            paymentType: 1,
            card: "**** **** **** 4242",
            paymentMath: ""
        }
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let payId = url.pathname.split("/")[2];
        // localStorage.removeItem("eventDirectPayment")
        // localStorage.removeItem("nid")
        this.setState({
            payId: payId,
            amount: localStorage.getItem("finalAmount") ? JSON.parse(localStorage.getItem("finalAmount")) : 0,
            paymentType: 1,
        }, () => {
            var payMath = "Full Payment $" + this.state.amount;
            this.setState({
                paymentMath: payMath
            }, () => {
                this.getDetails();
            })
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getMultipleEvent
        ) {

            nextProps.eventDetails.getDetailsReq = false;
            console.log("nextProps.eventDetails:--", nextProps.eventDetails.getMultipleEvent);
            if (nextProps.eventDetails.getMultipleEvent.flag) {
                this.setState({
                    eventsDetailData: nextProps.eventDetails.getMultipleEvent.result.event,
                }, () => {
                    nextProps.eventDetails.getMultipleEvent = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.multipleEventPayment
        ) {
            console.log("multipleEventPayment:--", nextProps.eventDetails.multipleEventPayment);
            if (nextProps.eventDetails.multipleEventPayment.flag) {
                let res = nextProps.eventDetails.multipleEventPayment.result;
                localStorage.setItem("finalAmount", res.amount);
                localStorage.setItem("paymentType", 1);
                var payMath;
                    payMath = "Full Payment $" + res.amount;
               
                    this.setState({
                        amount: res.amount,
                        paymentType: 1,
                        card: res.metaPayment.hasOwnProperty("payment_method_details") ? "**** **** **** " + res.metaPayment.payment_method_details.card.last4 : "**** **** **** 4242",
                        paymentMath: payMath
                    }, () => {
                        nextProps.eventDetails.multipleEventPayment = null;
                        this.setLoader(false);
                    });
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.multipleEventPayment.message);
                nextProps.eventDetails.multipleEventPayment = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        this.props.getMultipleEvent({ notificationId: this.state.payId });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    renderEventList = () => {
        return (
            this.state.eventsDetailData.length ?
                this.state.eventsDetailData.map(_ => {
                    return (
                        <div className='event_list_item'>
                            <div className='event_logo'>
                                <img src={_.event?.eventLogo?.filePath} alt="event logo" />
                            </div>
                            <div className='event_detail'>
                                <h5>{_.event?.eventTitle}</h5>
                                <p>${_.price}</p>
                            </div>
                        </div>
                    )
                })
                :
                <div className='event_list_item' >
                    <p style={{ textAlign: "center" }}>No Event</p></div>
        )

    }

    render() {
        const { loader, eventsDetailData, payId, paymentType, amount, transactionId, card, paymentMath } = this.state;
        console.log("this.state.eventsDetailData",this.state.eventsDetailData);
        return (
            <div className="event_details ">
                <Header />
                <CommonHeroBanner dataList={""} />
                <div className='event_multiple_Pay'>
                    <div className="container ">
                        <div className='event_list_info'>
                            <div className='title'>
                                <h1 class="section_title">Event List</h1>
                            </div>
                            <div className='event_list'>
                                {this.renderEventList()}
                            </div>
                        </div>
                        <div className="event-container event_list_info">
                            <div className="event-thanq">
                                <div className="thanq-title">
                                    <h4>Thank You!</h4>
                                    <p> Your payment was successful and you have joined the event
                                        {/* {paymentType === 2 ? "Thanks for registering. Your payment is due the day of the event." : "Thanks for registering."} */}
                                    </p>
                                </div>
                                {
                                    eventsDetailData.length !== 0 &&
                                    <div className="table-responsive-xl">
                                        <table>
                                            <tr>
                                                <th>Payment Method:</th>
                                                <td>{paymentMath}</td>
                                            </tr>
                                            {
                                                !localStorage.getItem("zeroAmount") &&
                                                (paymentType === 1 || paymentType === 3) &&
                                                <>
                                                    <tr>
                                                        <th>Payment Type:</th>
                                                        <td>Credit card</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Card Number:</th>
                                                        <td>{card}</td>
                                                    </tr>
                                                </>
                                            }
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </div>
        )
    }
}

thankYouForMultiPay.propTypes = {
    getMultipleEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
});
var thankYouComponent = connect(mapStateToProps, { getMultipleEvent })(thankYouForMultiPay);
export default withRouter(thankYouComponent);
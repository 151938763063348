import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import { imagesArr } from '../../../assets/images';
import CommonHeroBanner from "../../../components/commonHeroBanner";
import 'antd/dist/antd.css';
import { Table, Select } from 'antd';
import AWS from "aws-sdk";
import { getVideoSource, importSourceVideo, getTableData, attachVideo, getRapsodoOption, mappingRapsodo, deleteSellVideo } from '../../../saga/actions/common/uploadcsv.action';
import Preloader from '../../../components/preloader';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import Confirmation from './../../popup/confirmation';
const { Option } = Select;
class coachEventModel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      optionArr: [],
      event: "",
      videoSource: {
        v_source: "",
        event: "",
        v_column: [""]
      },
      tableData: [],
      reqImportSource: null,
      columnData: [],
      rowData: [],
      hidetable: false,
      eventClass: false,
      uploaded: 0,
      eventName: "",
      rapsodoCols: [],
      playerArr: [],
      msgPopup: false,
      resMessage: "",
      fileTypeCols: ""
    }

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.setLoader(true);
    this.getId();
    this.renderColumn();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.uploadCsvInfo &&
      nextProps.uploadCsvInfo.getVideoSourceReq &&
      nextProps.uploadCsvInfo.getVideoSourceData
    ) {
      console.log("nextProps from getVideoSourceData:----", nextProps.uploadCsvInfo.getVideoSourceData);
      nextProps.uploadCsvInfo.getVideoSourceReq = false;
      if (nextProps.uploadCsvInfo.getVideoSourceData.flag) {
        this.setState({
          optionArr: nextProps.uploadCsvInfo.getVideoSourceData.result.data,
          eventName: nextProps.uploadCsvInfo.getVideoSourceData.result.data && nextProps.uploadCsvInfo.getVideoSourceData.result.data[0].event.event_name
        }, () => {
          nextProps.uploadCsvInfo.getVideoSourceData = null;
          this.setLoader(false);
        });
      } else {
        this.commonCall.errorMessage(nextProps.uploadCsvInfo.getVideoSourceData.message);
        nextProps.uploadCsvInfo.getVideoSourceData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.uploadCsvInfo &&
      nextProps.uploadCsvInfo.importVideoSourceReq &&
      nextProps.uploadCsvInfo.importVideoSourceData
    ) {
      console.log("nextProps from importVideoSourceData:----", nextProps.uploadCsvInfo.importVideoSourceData);
      nextProps.uploadCsvInfo.importVideoSourceReq = false;
      if (nextProps.uploadCsvInfo.importVideoSourceData.flag) {
        nextProps.uploadCsvInfo.importVideoSourceData = null;
        let tblData = {
          bcsource_id: this.state.videoSource.v_source,
          event_id: this.state.event
        }
        this.props.getTableData(tblData, "Coach");
      } else {
        this.commonCall.errorMessage(nextProps.uploadCsvInfo.importVideoSourceData.message);
        nextProps.uploadCsvInfo.importVideoSourceData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.uploadCsvInfo &&
      nextProps.uploadCsvInfo.tableVideoDataReq &&
      nextProps.uploadCsvInfo.tableVideoData
    ) {
      console.log("nextProps from tableVideoData:----", nextProps.uploadCsvInfo.tableVideoData);
      nextProps.uploadCsvInfo.tableVideoDataReq = false;
      if (nextProps.uploadCsvInfo.tableVideoData.flag) {
        this.setState({
          tableData: nextProps.uploadCsvInfo.tableVideoData.result.data,
          reqImportSource: (!nextProps.uploadCsvInfo.tableVideoData.result.videoColumnConfig.hasOwnProperty("v_column")) ? null : nextProps.uploadCsvInfo.tableVideoData.result.videoColumnConfig,
          hidetable: true,
          rapsodoCols: nextProps.uploadCsvInfo.tableVideoData.result.hasOwnProperty("rapsodoColumnConfig") ? nextProps.uploadCsvInfo.tableVideoData.result.rapsodoColumnConfig : [],
          fileTypeCols: nextProps.uploadCsvInfo.tableVideoData.result.videoColumnConfig.hasOwnProperty("v_source") ? nextProps.uploadCsvInfo.tableVideoData.result.videoColumnConfig.v_source.file_type : ""
        }, () => {
          let oldData = nextProps.uploadCsvInfo.tableVideoData.result.hasOwnProperty("rapsodoColumnConfig") ? nextProps.uploadCsvInfo.tableVideoData.result.rapsodoColumnConfig : [];
          nextProps.uploadCsvInfo.tableVideoData = null;
          this.returnVal(oldData, res => {
            if (res.flg) {
              this.setState({
                playerArr: res.data
              })
            } else {
              this.commonCall.errorMessage(res.msg);
            }
          });

          if (localStorage.getItem("videoSource")) {
            localStorage.removeItem("videoSource");
            this.props.importSourceVideo(this.state.videoSource, "Coach");
          } else {
            this.renderTableData();
          }
        })
      } else {
        this.commonCall.errorMessage(nextProps.uploadCsvInfo.tableVideoData.message);
        nextProps.uploadCsvInfo.tableVideoData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.uploadCsvInfo &&
      nextProps.uploadCsvInfo.attachVideoDataReq &&
      nextProps.uploadCsvInfo.attachVideoData
    ) {
      console.log("nextProps from attachVideoData:----", nextProps.uploadCsvInfo.attachVideoData);
      nextProps.uploadCsvInfo.attachVideoDataReq = false;
      if (nextProps.uploadCsvInfo.attachVideoData.flag) {
        nextProps.uploadCsvInfo.attachVideoData = null;
        this.commonCall.successMessage('Video uploaded. An email will be sent when the video is ready.');
        let tblData = {
          bcsource_id: this.state.videoSource.v_source,
          event_id: this.state.event
        }
        this.props.getTableData(tblData, "Coach");
      } else {
        this.commonCall.errorMessage(nextProps.uploadCsvInfo.attachVideoData.message);
        nextProps.uploadCsvInfo.attachVideoData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.uploadCsvInfo &&
      nextProps.uploadCsvInfo.mappingRapsodoReq &&
      nextProps.uploadCsvInfo.mappingRapsodoData
    ) {
      console.log("nextProps from mappingRapsodoData:----", nextProps.uploadCsvInfo.mappingRapsodoData);
      nextProps.uploadCsvInfo.mappingRapsodoReq = false;
      if (nextProps.uploadCsvInfo.mappingRapsodoData.flag) {
        this.setState({
          columnData: [],
          rowData: [],
          msgPopup: true,
          resMessage: nextProps.uploadCsvInfo.mappingRapsodoData.message,
        }, () => {
          nextProps.uploadCsvInfo.mappingRapsodoData = null;
          this.renderColumn();
          let tblData = {
            bcsource_id: this.state.videoSource.v_source,
            event_id: this.state.event
          }
          this.props.getTableData(tblData, "Coach");
        })
      } else {
        this.setState({
          columnData: [],
          rowData: [],
          msgPopup: true,
          resMessage: nextProps.uploadCsvInfo.mappingRapsodoData.message,
        }, () => {
          nextProps.uploadCsvInfo.mappingRapsodoData = null;
          this.renderColumn();
          let tblData = {
            bcsource_id: this.state.videoSource.v_source,
            event_id: this.state.event
          }
          this.props.getTableData(tblData, "Coach");
        })
      }
    }

    if (nextProps.uploadCsvInfo &&
      nextProps.uploadCsvInfo.delSellVideoReq &&
      nextProps.uploadCsvInfo.delSellVideoData
    ) {
      nextProps.uploadCsvInfo.delSellVideoReq = false;
      if (nextProps.uploadCsvInfo.delSellVideoData.flag) {
        this.commonCall.successMessage("Video Deleted");
        nextProps.uploadCsvInfo.delSellVideoData = null;
        this.setState({
          columnData: [],
          rowData: [],
        }, () => {
          this.renderColumn();
          let tblData = {
            bcsource_id: this.state.videoSource.v_source,
            event_id: this.state.event
          }
          this.props.getTableData(tblData, "Coach");
        });
      } else {
        this.commonCall.errorMessage(nextProps.uploadCsvInfo.delSellVideoData.message);
        nextProps.uploadCsvInfo.delSellVideoData = null;
        this.setLoader(false);
      }
    }
  }

  returnVal = (oldData, cb) => {
    let backObj = oldData;
    Object.entries(oldData).map(([k, v], i) => {
      this.props.getRapsodoOption(k, "Coach", (res) => {
        if (res.flag) {
          let arr = [];
          res.result.data.map((item, i) => {
            arr.push({ value: item._id, label: item.pitch_number })
          });
          backObj[k] = arr;
          let chk = Object.keys(backObj).every((l) => Array.isArray(backObj[l]));
          if (chk) {
            cb({ flg: true, data: backObj });
          }
        } else {
          cb({ flg: false, msg: res.message });
        }
      })
    })
  }

  renderColumn = () => {
    const columns = [
      {
        title: 'Time',
        dataIndex: 'time',
        sorter: (a, b) => this.stringValueSort(a.time, b.time),
      },
      {
        title: 'BC Pitcher Profile',
        dataIndex: 'pitcher_datraks_id',
        sorter: (a, b) => this.stringValueSort(a.pitcher_datraks_id, b.pitcher_datraks_id),
      },
      {
        title: 'BC Batter Profile',
        dataIndex: 'batter_datraks_id',
        sorter: (a, b) => this.stringValueSort(a.batter_datraks_id, b.batter_datraks_id),
      },
      {
        title: 'Batter Profile',
        dataIndex: 'batter_name',
        sorter: (a, b) => this.stringValueSort(a.batter_name, b.batter_name),
      },
      {
        title: 'Pitcher profile',
        dataIndex: 'pitcher_name',
        sorter: (a, b) => this.stringValueSort(a.pitcher_name, b.pitcher_name),
      },
    ];
    this.setState((prevState) => ({
      ...prevState,
      columnData: columns
    }))
  }

  showVideoPath = (mappObj, column) => {

    if (mappObj.length !== 0) {
      let videoObj = null;
      for (let i = 0; i < mappObj.length; i++) {
        if (mappObj[i].type === column) {
          let getVal = mappObj[i].filePath.split('/');
          videoObj = {
            path: getVal[getVal.length - 1],
            filepath: mappObj[i].filePath
          }
        }
      }
      return videoObj
    }
  }

  setOption = (val, sid, rec, firstVal) => {
    let rendData;

    if (firstVal.length !== 0) {
      rendData = {
        device_id: rec._id,
        bcsource_id: rec.bcsource_id,
        rId: val,
        sId: sid,
        update: true
      }
    } else {
      rendData = {
        device_id: rec._id,
        bcsource_id: rec.bcsource_id,
        rId: val,
        sId: sid,
      }
    }
    console.log("rendData:---", rendData);
    this.setLoader(true);
    this.props.mappingRapsodo(rendData, "Coach");
  }

  deleteVideo = (obj) => {
    let delReq = {
      recordId: obj.mappId,
      type: obj.columnTYpe
    }
    this.setLoader(true);
    this.props.deleteSellVideo(delReq, "Coach");
  }

  renderTableData = () => {
    const data = [];
    if (this.state.reqImportSource) {
      this.state.columnData.push({
        title: this.state.fileTypeCols + ' Row',
        dataIndex: 'pitch_number',
        sorter: (a, b) => a.pitch_number - b.pitch_number,
      })

      if (!!this.state.reqImportSource.typeArr.find(e => e === 2)) {
        for (let i = 0; i < this.state.reqImportSource.v_column.length; i++) {
          this.state.columnData.push(
            {
              title: this.state.reqImportSource.v_column[i],
              dataIndex: "video" + i,
              sorter: (a, b) => this.sortFileValue(a["video" + i], b["video" + i]),
              render: obj => (
                <>
                  {!obj.isValue ?
                    <div className="dragdrop-img">
                      <div className="create_event_fileUpload file_custom_upload text-center">
                        (filename or upload)
                        <input
                          name="logofile"
                          onClick={(ev) => { ev.target.value = null }}
                          type="file"
                          className="img_file_input"
                          accept=".mp4,.mov,.avi,.flv,.MP4,.MOV,.AVI,.FLV"
                          onChange={(e) => this.onHandleVideoChange(e, obj.mappId, obj.columnTYpe)}
                        />
                      </div>
                    </div>
                    :
                    <div className="fileview">
                      <span className="fileaudio">
                        <i class="fas fa-file-audio"></i>
                      </span>
                      <span>
                        <a href={obj.isValue && obj.isValue.filepath} target="_blank">
                          {obj.isValue && obj.isValue.path}
                        </a>
                      </span>
                      <span className="filedelate" onClick={() => this.deleteVideo(obj)}>
                        <i class="fa fa-trash"></i>
                      </span>
                    </div>
                  }
                </>
              ),
            }
          )
        }
      }

      if (!!this.state.reqImportSource.typeArr.find(e => e === 1)) {
        this.state.columnData.push(
          {
            title: "Associated Videos",
            dataIndex: "associated_videos",
            sorter: (a, b) => this.sortFileValue(a["associated_videos"], b["associated_videos"]),
            render: obj => (
              <>
                {obj && obj.isValue &&
                  <div className="fileview">
                    <span className="fileaudio"><i class="fas fa-file-audio"></i></span>
                    <span>
                      <a href={obj.isValue.filepath} target="_blank">
                        {obj.isValue.path}
                      </a>
                    </span>
                  </div>
                }
              </>
            ),
          }
        )
      }

      if (this.state.rapsodoCols.length !== 0) {
        Object.entries(this.state.rapsodoCols).map(([k, v]) => {
          this.state.columnData.push({
            className: "select_width",
            title: v,
            dataIndex: k,
            render: (obj, record) => {
              // if (this.state.playerArr.length !== 0) {
              return (
                <Select
                  showSearch
                  defaultValue={obj.value && obj.value[0] ? obj.value[0].value : ""}
                  style={{ width: "100%" }}
                  onChange={(e) => this.setOption(e, obj.sourceId, record, obj.value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.state.playerArr.length !== 0 && this.state.playerArr[obj.sourceId].map(opt => (
                    <Option key={opt.value}>{opt.label}</Option>
                  ))}
                </Select>
              )
              // }
            }
          })
        });
      }

      for (let i = 0; i < this.state.tableData.length; i++) {
        const rowVal = {
          _id: this.state.tableData[i]._id,
          bcsource_id: this.state.tableData[i].bcsource_id,
          time: this.state.tableData[i].time,
          pitcher_datraks_id: this.state.tableData[i].hasOwnProperty("pitcher_datraks_id") ? this.state.tableData[i].pitcher_datraks_id.fname + " " + this.state.tableData[i].pitcher_datraks_id.lname : "-",
          batter_datraks_id: this.state.tableData[i].hasOwnProperty("batter_datraks_id") ? this.state.tableData[i].batter_datraks_id.fname + " " + this.state.tableData[i].batter_datraks_id.lname : "-",
          batter_name: this.state.tableData[i].batter_name,
          pitcher_name: this.state.tableData[i].pitcher_name,
          pitch_number: this.state.tableData[i].pitch_number,
        }

        for (let j = 0; j < this.state.reqImportSource.v_column.length; j++) {

          var matchData = this.showVideoPath(this.state.tableData[i].associated_videos, this.state.reqImportSource.v_column[j]);

          let fileObje = {
            isValue: matchData,
            mappId: this.state.tableData[i]._id,
            columnTYpe: this.state.reqImportSource.v_column[j]
          }

          rowVal["video" + j] = fileObje
        }

        if (!!this.state.tableData[i].associated_videos.find(e => e.addType === 1)) {
          let obj = {
            isValue: {
              path: this.state.tableData[i].associated_videos.find(e => e.addType === 1).filePath.split('output/')[1],
              filepath: this.state.tableData[i].associated_videos.find(e => e.addType === 1).filePath
            },
          }
          rowVal["associated_videos"] = obj;
        }

        let rapsodoVal = this.state.tableData[i].associated_rapsodo;
        if (rapsodoVal) {
          Object.entries(this.state.rapsodoCols).map(([k, v]) => {
            let setObj = {
              sourceId: k,
              value: this.filterRapsodoData(k, rapsodoVal),
            }
            rowVal[k] = setObj;
          });
        } else {
          if (this.state.rapsodoCols.length !== 0) {
            Object.entries(this.state.rapsodoCols).map(([k, v]) => {
              let setObj = {
                sourceId: k,
                value: "",
              }
              rowVal[k] = setObj;
            });
          }
        }
        data.push(rowVal)
      }
      this.setState({
        rowData: data,
        hidetable: false,
        columnData: this.state.columnData,
      }, () => {
        this.setLoader(false);
      })
    }
  }

  filterRapsodoData = (key, data) => {
    let isDone = [];
    if (data.hasOwnProperty("rId")) {
      if (data.rId.bcsource_id === key) {
        isDone = [{ value: data.rId._id, label: data.rId.pitch_number }]
      }
    }
    return isDone;
  }

  getId = () => {
    let url = new URL(document.location);
    let eid = url.pathname.split("/")[2];

    console.log("localStorage.getItem(:------", localStorage.getItem("selectedSource"));

    this.setState({
      event: eid,
    }, () => {
      let data = {
        bcevent_id: this.state.event
      }
      this.props.getVideoSource(data, "Coach");
      if (localStorage.getItem("selectedSource")) {
        this.setState((prevState) => ({
          ...prevState,
          videoSource: {
            ...prevState.videoSource,
            v_source: localStorage.getItem("selectedSource")
          },
        }), () => {
          this.setState({
            columnData: [],
            rowData: [],
          }, () => {
            this.setLoader(true);
            this.renderColumn();
            console.log("videoSource:---", this.state.videoSource);
            let tblData = {
              bcsource_id: this.state.videoSource.v_source,
              event_id: this.state.event
            }
            this.props.getTableData(tblData, "Coach");
          })
        });
      } else {
        let fetchData = JSON.parse(localStorage.getItem("videoSource"));
        this.setState((prevState) => ({
          ...prevState,
          videoSource: {
            ...prevState.videoSource,
            ...fetchData
          }
        }));
      }
    })
  }

  onHandleVideoChange = (e, id, column) => {

    const { videoSource } = this.state;

    let file = e.target.files[0];
    let ext = file.name.match(new RegExp('[^.]+$'))[0];
    let newName = this.newGuid() + "." + ext;

    console.log("newName:---", newName);

    document.body.classList.add("modal-open");
    this.setState({
      eventClass: true,
      uploaded: 0
    })

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESSKEY,
      region: process.env.REACT_APP_REGION,
    });
    console.log("REACT_APP_ACCESSKEY_ID:---", process.env.REACT_APP_ACCESSKEY_ID);

    var bucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET },
    });

    var params = {
      Key: `others/${newName}`,
      ContentType: file.type,
      Body: file,
      Metadata: {
        'x-amz-meta-source_id': videoSource.v_source,
        'x-amz-meta-skip_time': '0',
      },
      ACL: 'public-read'
    };

    console.log("params:---", params);
    AWS.config.httpOptions.timeout = 0;

    if (Number((file.size / (1024 * 1024)).toFixed(2)) > 100) {
      AWS.S3.ManagedUpload.maxTotalParts = 10000;
      var options = { partSize: 100 * 100 * 1024, queueSize: 1 }; // 100 mb up
    } else {
      AWS.S3.ManagedUpload.maxTotalParts = 100;
      var options = { partSize: 100 * 100 * 1024, queueSize: 1 }; // 100 mb down
    }

    // AWS.S3.ManagedUpload.maxTotalParts = 10000;
    // var options = { partSize: 100 * 100 * 1024, queueSize: 1 };

    bucket
      .upload(params, options)
      .on("httpUploadProgress", (evt) => {
        var uploaded = Math.round((evt.loaded / evt.total) * 100);
        console.log(`File uploaded: ${uploaded}%`);
        this.setState({
          uploaded: uploaded
        })
      })
      .send((err, data) => {
        console.log("err", err);
        if (err !== null) {
          return err;
        }
        if (data) {
          document.body.classList.remove("modal-open");
          this.setState({
            eventClass: false,
            columnData: [],
            rowData: [],
          }, () => {
            this.setLoader(true);
            this.renderColumn();
            let sendData = {
              recordId: id,
              type: column,
              filePath: data.Location
            }
            console.log("sendData:---", sendData);
            this.props.attachVideo(sendData, "Coach");
          })
        }
      });
  }

  handleChange = (sorter) => {
    // console.log('sorter', sorter);
  };

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  onHandleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      videoSource: {
        ...prevState.videoSource,
        [name]: value
      },
    }), () => {
      if (value) {
        this.setState({
          columnData: [],
          rowData: [],
        }, () => {
          this.setLoader(true);
          this.renderColumn();
          console.log("videoSource:---", this.state.videoSource);
          let tblData = {
            bcsource_id: this.state.videoSource.v_source,
            event_id: this.state.event
          }
          this.props.getTableData(tblData, "Coach");
        })
      }
    });
  }

  onCancel = () => {
    localStorage.removeItem("videoSource");
    localStorage.removeItem("showVideo");
    localStorage.removeItem("selectedSource");
    this.props.history.push("/coach-uploadcsv");
  }

  newGuid = () => {
    return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  stringValueSort = (obj1Value, obj2Value) => {
    // console.log("obj1Value:---", obj1Value);
    const firstString = obj1Value.toUpperCase();
    const secondString = obj2Value.toUpperCase();
    if (firstString > secondString) return 1;
    return -1;
  };

  sortFileValue = (obj1Value, obj2Value) => {
    // console.log("obj1Value:---", obj1Value);
    const firstString = obj1Value && obj1Value.isValue ? obj1Value.isValue.path.toUpperCase() : "";
    const secondString = obj2Value && obj2Value.isValue ? obj2Value.isValue.path.toUpperCase() : "";
    if (firstString > secondString) return 1;
    // return -1;
  }

  closePopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      msgPopup: false,
      resMessage: ""
    })
  }

  render() {
    let { loader, optionArr, videoSource, columnData, rowData, hidetable, eventClass, uploaded, eventName, msgPopup, resMessage } = this.state;

    return (
      <>
        <Header />
        {/* <CommonHeroBanner dataList="" /> */}
        {/* Uploadcsv Event Modal table */}
        <section className="uploadcsv-eventmodal uploadcsv uploadcsv-datasource">
          <div className="container-fluid">
            <div className="white-bg tablecontent">
              <div className="tableinfo">
                <div>
                  <h3>{eventName}</h3>
                  <p className="mb-0">Event details table</p>
                </div>
                <div className="select-timestamp">
                  <select name="v_source" value={videoSource.v_source} onChange={this.onHandleChange} className="custom-select" disabled={localStorage.getItem("selectedSource") === videoSource.v_source && true}>
                    <option value="">Choose timestamp source</option>
                    {
                      optionArr &&
                      optionArr.map((list, i) => {
                        return (
                          <option value={list._id} selected={localStorage.getItem("selectedSource") === list._id ? true : false}>{list.file_type}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="table-responsive-xl">
                <div className="table">
                  {
                    !hidetable &&
                    <Table columns={columnData} dataSource={rowData} onChange={this.handleChange} pagination={false} />
                  }
                </div>
              </div>
            </div>
            <div className="flex-align">
              <Link className="btn gray" onClick={() => this.onCancel()}>
                Back to Event List
              </Link>
            </div>
          </div>
        </section>
        {
          eventClass &&
          <>
            <div className="video_upload_progressbar">
              <img src={imagesArr.loader} />Please wait, Video uploading progress completes {uploaded}%
            </div>
          </>
        }
        <Footer />
        <Preloader flag={loader} />
        {
          msgPopup &&
          <Confirmation
            flag={msgPopup}
            type="eventModelMsg"
            close={this.closePopup}
            msg={resMessage}
          />
        }
      </>
    );
  }
}

coachEventModel.propTypes = {
  getVideoSource: PropTypes.func.isRequired,
  importSourceVideo: PropTypes.func.isRequired,
  getTableData: PropTypes.func.isRequired,
  attachVideo: PropTypes.func.isRequired,
  getRapsodoOption: PropTypes.func.isRequired,
  mappingRapsodo: PropTypes.func.isRequired,
  deleteSellVideo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  uploadCsvInfo: state.uploadCsvInfo,
});


const coachEventModelComponent = connect(mapStateToProps, { deleteSellVideo, getVideoSource, importSourceVideo, getTableData, attachVideo, getRapsodoOption, mappingRapsodo })(coachEventModel);
export default withRouter(coachEventModelComponent);

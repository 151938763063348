import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { searchZipcode, uploadImage } from '../../../../saga/actions/authantication/signup/coach/basic.action';
import { getPlayerBasicDetails } from '../../../../saga/actions/authantication/signup/player/basic.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import Preloader from '../../../../components/preloader';
import ImageCrop from '../../../../views/popup/imageCrop';
import InputMask from 'react-input-mask';
import ParentInfo from '../../../../views/popup/parentInfo';
import { imagesArr } from '../../../../assets/images';
import Geosuggest from 'react-geosuggest';
import { StatesObj } from '../../../../businesslogic/content';

class basic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            basicArr: {
                profilePic: "",
                bio: "",
                "game-type": "1",
                address: {
                    city: "",
                    state: "",
                    zipcode: ""
                },
                height: "",
                weight: "",
                "in-high-school": "yes",
                "gra-year": "",
                school: "",
                "curr-gpa": "",
                recruiting: "no",
                "year-exp": "",
                parentInfo: {
                    cCode: "+1",
                    email: "",
                    fullName: "",
                    phone: ""
                },
                sat: "",
                act: ""
            },
            fname: "",
            lname: "",
            manageZipcode: false,
            loader: false,
            descLenth: 0,
            cropPopup: false,
            renderFile: "",
            imgObj: "",
            parentPopup: false,
            isEditZipcode: false
        }

        let options = [];
        for (var Y = 2035; Y >= 1980; Y--) {
            options.push(Y)
        }
        this.years = options;
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.schoolValidator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        this.props.onRef(this);
        this.setLoader(true);
        this.props.getPlayerBasicDetails();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.basicDetails &&
            nextProps.basicDetails.searchRes &&
            nextProps.basicDetails.searchData
        ) {
            nextProps.basicDetails.searchRes = false;
            if (nextProps.basicDetails.searchData.flag) {

                let data = nextProps.basicDetails.searchData;
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        address: {
                            ...prevState.basicArr.address,
                            city: data.result.city,
                            state: data.result.state,
                            zipcode: data.result.zipcodestr
                        }
                    },

                    manageZipcode: true
                }), () => {
                    nextProps.basicDetails.searchData = null;
                    this.setLoader(false);
                });
            } else {
                this.setState({
                    manageZipcode: false
                }, () => {
                    this.commonCall.errorMessage(nextProps.basicDetails.searchData.message)
                    nextProps.basicDetails.searchData = null;
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.imageRes &&
            nextProps.basicDetails.imageData
        ) {
            nextProps.basicDetails.imageRes = false;
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    profilePic: nextProps.basicDetails.imageData._id
                },
                file: nextProps.basicDetails.imageData.filePath,
                imgObj: "",
                cropPopup: false,
                renderFile: ""
            }), () => {
                nextProps.basicDetails.imageData = null;
                document.body.classList.remove('modal-open');
                this.setLoader(false);
            });
        }

        if (nextProps.basicData &&
            nextProps.basicData.playerBasicRes &&
            nextProps.basicData.playerBasicData
        ) {
            nextProps.basicData.playerBasicRes = false;
            let name = Object.keys(nextProps.basicData.playerBasicData.parentInfo).length ? nextProps.basicData.playerBasicData.parentInfo.fullName.split(" ") : null
            if (nextProps.basicData.playerBasicData.hasOwnProperty('_id')) {
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        profilePic: nextProps.basicData.playerBasicData.fieldArr.profilePic && nextProps.basicData.playerBasicData.fieldArr.profilePic._id,
                        // bio: nextProps.basicData.playerBasicData.fieldArr.bio,
                        "game-type": nextProps.basicData.playerBasicData.fieldArr['game-type'],
                        "year-exp": nextProps.basicData.playerBasicData.fieldArr["year-exp"],
                        address: {
                            ...prevState.basicArr.address,
                            city: nextProps.basicData.playerBasicData.fieldArr.address.city,
                            state: nextProps.basicData.playerBasicData.fieldArr.address.state,
                            zipcode: nextProps.basicData.playerBasicData.fieldArr.address.zipcode
                        },
                        act: nextProps.basicData.playerBasicData.fieldArr.act,
                        sat: nextProps.basicData.playerBasicData.fieldArr.sat,
                    },

                    // descLenth: nextProps.basicData.playerBasicData.fieldArr.bio.length,
                    manageZipcode: true,
                    file: nextProps.basicData.playerBasicData.fieldArr.profilePic && nextProps.basicData.playerBasicData.fieldArr.profilePic.filePath
                }), () => {
                    if (this.props.profileData && this.calculate_age(this.props.profileData.profile.dob)) {
                        this.setState((prevState) => ({
                            ...prevState,
                            basicArr: {
                                ...prevState.basicArr,
                                height: nextProps.basicData.playerBasicData.fieldArr.height,
                                weight: nextProps.basicData.playerBasicData.fieldArr.weight,
                                "in-high-school": nextProps.basicData.playerBasicData.fieldArr["in-high-school"],
                                "gra-year": nextProps.basicData.playerBasicData.fieldArr["gra-year"],
                                school: nextProps.basicData.playerBasicData.fieldArr.school,
                                "curr-gpa": nextProps.basicData.playerBasicData.fieldArr["curr-gpa"],
                                recruiting: nextProps.basicData.playerBasicData.fieldArr.recruiting,
                                parentInfo: nextProps.basicData.playerBasicData.parentInfo,
                                act: nextProps.basicData.playerBasicData.fieldArr.act,
                                sat: nextProps.basicData.playerBasicData.fieldArr.sat,
                            },
                            fname: name ? name[0] : "",
                            lname: name ? name[1] ? name[1] : "" : "",
                        }), () => {
                            nextProps.basicData.playerBasicData = null;
                            this.setLoader(false);
                        })
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            basicArr: {
                                ...prevState.basicArr,
                                height: nextProps.basicData.playerBasicData.fieldArr.height,
                                weight: nextProps.basicData.playerBasicData.fieldArr.weight,
                                "in-high-school": nextProps.basicData.playerBasicData.fieldArr["in-high-school"],
                                "gra-year": nextProps.basicData.playerBasicData.fieldArr["gra-year"],
                                school: nextProps.basicData.playerBasicData.fieldArr.school,
                                act: nextProps.basicData.playerBasicData.fieldArr.act,
                                sat: nextProps.basicData.playerBasicData.fieldArr.sat,
                            },
                        }), () => {
                            nextProps.basicData.playerBasicData = null;
                            this.setLoader(false);
                        })
                    }

                });
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        parentInfo: nextProps.basicData.playerBasicData.parentInfo
                    },
                    fname: name ? name[0] : "",
                    lname: name ? name[1] ? name[1] : "" : "",
                }), () => {
                    nextProps.basicData.playerBasicData = null;
                    this.setLoader(false);
                })
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "game-type") {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    [name]: value
                },
            }));
        }

        // if (name === "bio") {
        //     if (value.length <= 200) {
        //         this.setState((prevState) => ({
        //             ...prevState,
        //             basicArr: {
        //                 ...prevState.basicArr,
        //                 [name]: value
        //             },
        //             descLenth: value.length
        //         }));
        //     }
        // }

        if (name === "city" ||
            name === "state"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    address: {
                        ...prevState.basicArr.address,
                        [name]: value
                    }
                },
            }));
        }

        if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        basicArr: {
                            ...prevState.basicArr,
                            address: {
                                ...prevState.basicArr.address,
                                [name]: value
                            }
                        },
                    }));
                }
            }
        }

        if (name === "logofile") {
            let file = e.target.files[0];
            if (file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "png") {
                return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG file");
            }
            this.setState({
                imgObj: file,
                cropPopup: true,
                renderFile: URL.createObjectURL(file)
            });
        }
    }

    parentHandle = (e) => {
        const { name, value } = e.target;
        if (name === "fname" || name === "lname") {
            this.setState((prevState) => ({
                ...prevState,
                [name]: value
            }));
        } else if (name === "phone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState({
                        ...this.state,
                        basicArr: {
                            ...this.state.basicArr,
                            parentInfo: {
                                ...this.state.basicArr.parentInfo,
                                [name]: value
                            }
                        },
                    });
                } else return false;
            } else return false;
        } else {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    parentInfo: {
                        ...prevState.basicArr.parentInfo,
                        [name]: value
                    }
                },
            }));
        }
    }

    onParentSave = () => {
        this.state.basicArr.parentInfo.fullName = this.state.fname + " " + this.state.lname
        this.setState((prevState) => ({
            ...prevState,
            basicArr: {
                ...prevState.basicArr,
                parentInfo: this.state.basicArr.parentInfo
            },
        }));
    }

    handleAboutChange = (e) => {
        const { name, value } = e.target;
        if (name === "weight") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 3) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        basicArr: {
                            ...this.state.basicArr,
                            [name]: value
                        },
                    }))
                }
            }
        }
        else if (e.target.name === "height") {
            const foot = e.target.value.split("'");
            const inches = e.target.value.split(`"`);
            if (foot[1] && inches[0]) {
                const inch = parseInt(foot[1].split(`"`)[0])
                if (inches[1]) {
                    if (inches[1] === "0" || inches[1] === "1" && inch === 1) this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'${inch}${inches[1]}"` } }))
                } else {
                    if (inch > 9 && inch < 12) {
                        if (this.state.basicArr.height.includes(`"`) && !e.target.value.includes(`"`)) this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'${foot[1][0]}"` } }))
                        else this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'${inch}"` } }))
                    } else if (inch >= 0 && inch <= 9) {
                        const prev = this.state.basicArr.height.split("'")[1]
                        if (this.state.basicArr.height.includes(`"`) && !(prev.includes('10') || prev.includes('11'))) this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'` } }))
                        else if (prev.includes('10') || prev.includes('11')) this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'${foot[1]}` } }))
                        else this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'${foot[1]}"` } }))
                    } else if (isNaN(inch)) this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${foot[0]}'` } }))
                }
            }
            else if (inches[0] && this.state.basicArr.height.includes(`"`) && inches[1] !== "") { this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `` } })) }
            else if (foot[0] && !this.state.basicArr.height.includes("'") && foot[1] !== "") { this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${e.target.value}'` } })) }
            else if (foot[0] && this.state.basicArr.height.includes("'") && foot[1] !== "") { this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `` } })) }
            else { this.setState((prevState) => ({ ...prevState, basicArr: { ...this.state.basicArr, [e.target.name]: `${e.target.value}`, } })) }

        }
        else {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...this.state.basicArr,
                    [name]: value
                },
                parentPopup: name === "recruiting" && value === "yes" ? true : false
            }))
        }
    }

    onlyNumber = event => {
        const keycode = event.which;
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };

    handleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            cropPopup: false
        });
    }

    onHadleObject = (obj) => {
        let data = {
            documentType: 16,
            imgFile: obj.file
        }
        this.setLoader(true);
        this.props.uploadImage(data, "Player");
    }

    removeImg = () => {
        this.setState({
            file: null
        })
    }

    validationUpdate = (cb) => {
        if (this.state.basicArr['in-high-school'] === "yes") {
            if (!this.schoolValidator.allValid() || !this.validator.allValid()) {
                this.validator.showMessages();
                this.schoolValidator.showMessages();
                this.forceUpdate();
            } else {
                if ((this.state.basicArr.recruiting === "yes" && this.state.basicArr.parentInfo) && (!this.state.basicArr.parentInfo.fullName || !this.state.basicArr.parentInfo.email)) {
                    return this.commonCall.errorMessage("Please fill up parent details");
                }
                this.registerBasicInfo(cb);
            }
        }
        else {
            if (this.validator.allValid()) {
                this.registerBasicInfo(cb);
            } else {
                this.validator.showMessages();
                this.forceUpdate();
                cb({ data: '', flag: false });
            }
        }
    }

    registerBasicInfo = (cb) => {
        this.state.basicArr.heightNumeric = Number(this.state.basicArr.height.replace("'", ".").replace("\"", ""))
        cb({ data: this.state.basicArr, flag: true });
        // this.setState((prevState) => ({
        //     ...prevState,
        //     basicArr: {
        //         profilePic: "",
        //         bio: "",
        //         "game-type": "1",
        //         address: {
        //             city: "",
        //             state: "",
        //             zipcode: ""
        //         }
        //     },
        // })); 
        if (this.calculate_age(this.props.profileData.profile.dob)) {
            cb({ data: this.state.basicArr, flag: true });
        } else {
            let fdata = {
                profilePic: this.state.basicArr.profilePic,
                "game-type": this.state.basicArr["game-type"],
                address: {
                    city: this.state.basicArr.address.city,
                    state: this.state.basicArr.address.state,
                    zipcode: this.state.basicArr.address.zipcode,
                },
                height: this.state.basicArr.height,
                weight: this.state.basicArr.weight,
                "in-high-school": this.state.basicArr['in-high-school'],
                "gra-year": this.state.basicArr['gra-year'],
                school: this.state.basicArr.school,
                "year-exp": this.state.basicArr["year-exp"],
            }
            cb({ data: fdata, flag: true });
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    getZipcode = (val) => {
        if (val.length !== 0) {
            this.setLoader(true);
            this.props.searchZipcode(val, "Player");
        }
    }

    closeParentPopUp = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            parentPopup: false
        })
    }

    calculate_age_between = (dob1) => {
        let today = new Date(),
            //birthay has 'Dec 25 1998'
            dob = new Date(dob1),
            //difference in milliseconds
            diff = today.getTime() - dob.getTime(),
            //convert milliseconds into years
            years = Math.floor(diff / 31556736000),
            //1 day has 86400000 milliseconds
            days_diff = Math.floor((diff % 31556736000) / 86400000),
            //1 month has 30.4167 days
            months = Math.floor(days_diff / 30.4167),
            days = Math.floor(days_diff % 30.4167);
        if (years === 15 && months >= 0 && days >= 0) {
            return true
        } else if (years === 18 && months == 0 && days == 0) {
            return true
        } else if (years >= 14 && years < 18) {
            return true
        } else {
            return false
        }
        // var ageDifMs = Date.now() - new Date(dob1).getTime();
        // var ageDate = new Date(ageDifMs); // miliseconds from epoch
        // return (Math.abs(ageDate.getUTCFullYear() - 1970) >= 15 && Math.abs(ageDate.getUTCFullYear() - 1970) <= 18);
    }

    calculate_age = (dob1) => {
        var ageDifMs = Date.now() - new Date(dob1).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
    }

    handleSelectGeoSuggest = (place) => {
        if (place) {
          var componentForm = {
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name',
          };
    
          for (var i = 0; i < place.gmaps.address_components.length; i++) {
            var addressType = place.gmaps.address_components[i].types[0];
            if (componentForm[addressType]) {
              var val = place.gmaps.address_components[i][componentForm[addressType]];
              if (val)
                componentForm[addressType] = val
            }
          }
          var location = {
            streetAddress: place.gmaps.formatted_address,
            city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
            stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
            postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
            latitude: place.location.lat || 42.2538048,
            longitude: place.location.lng || -83.5735806
          }
    
          if (location.stateRegion) {

              this.setState((prevState) => ({
                  ...prevState,
                  basicArr: {
                      ...prevState.basicArr,
                      address: {
                          ...prevState.basicArr.address,
                          state: location.stateRegion,
                          city: location.city,
                          zipcode: location.postalCode
                      }
                  },
                  isEditZipcode: location.postalCode ? false : true
              }));


          } else {
              this.commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
              this.setState((prevState) => ({
                  ...prevState,
                  basicArr: {
                      ...prevState.basicArr,
                      address: {
                          state: "",
                          zipcode: "",
                      }
                  },
                  isEditZipcode: false
              }));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    address: {
                        city: "",
                    }
                }
            }));
        }
      }

    render() {
        const { basicArr, manageZipcode, file, loader, descLenth, imgObj, cropPopup, renderFile } = this.state
        const { profileData } = this.props

        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name">
                            <h2>
                                Hi {profileData && profileData.fname && profileData.fname},
                            </h2>
                            <p>
                                We have a few more QUESTIONS for you.
                            </p>
                        </div>

                        <label className="common-lable">Upload profile picture</label>
                        <div className="dragdrop-img">
                            <div className="create_event_fileUpload file_custom_upload text-center">
                                <i className="fas fa-cloud-upload-alt"></i>
                                {!file && <p>Click to upload profile picture</p>}
                                {/* .heic,.HEIC */}
                                <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={this.onHandleChange} />
                                {file &&
                                    <div className="preview_img">
                                        <i className="fas fa-times" onClick={() => this.removeImg()}></i>
                                        <img className="preview_img_inner" src={file} />
                                    </div>
                                }
                            </div>
                        </div>

                        {/* <label className="common-lable">Describe your baseball goals?</label>
                        <div className="text-area-counter">
                            <textarea id="subject" rows="4" name="bio" value={basicArr.bio} onChange={this.onHandleChange} placeholder="Tell us about your baseball goals" className="form-control"></textarea>
                            <span className="textarea__count">{descLenth}/200</span>
                        </div>
                        <span className="validMsg">{this.validator.message('Describe Yourself', basicArr.bio, 'required')}</span> */}

                        <div className="check_input row">
                            <div className="check_inputbox">
                                <label className="common-lable">Height</label>
                                <div className="serch-box">
                                    <div className="form-group mb-0">
                                        <InputMask {...this.props} className="form-control" placeholder="Height" name="height" inputmode="numeric" value={basicArr.height} onChange={this.handleAboutChange} onKeyPress={this.onlyNumber} autoComplete="off" />
                                        {/* <input type="text" className="form-control" id="usr" placeholder="Height" name="height" value={basicArr.height} onChange={this.handleAboutChange} onKeyPress={this.onlyNumber} autoComplete="off" /> */}
                                    </div>
                                    <span className="validMsg">{this.validator.message('Height', basicArr.height, 'required', { className: 'text-danger' })}</span>
                                    <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                                        Ft.
                                    </Link>
                                </div>
                            </div>
                            <div className="check_inputbox">
                                <label className="common-lable">Weight</label>
                                <div className="serch-box">
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" placeholder="Weight" name="weight" value={basicArr.weight} onChange={this.handleAboutChange} />
                                    </div>
                                    <span className="validMsg">{this.validator.message('weight', basicArr.weight, 'required', { className: 'text-danger' })}</span>
                                    <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                                        Pound
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">Are you in high school?</label>
                                <div className="check-box mb-0">
                                    <div className="checkbox" >
                                        <input type="radio" id="Baseball" value="yes" name="in-high-school" checked={this.state.basicArr["in-high-school"] === "yes" && true} onChange={this.handleAboutChange} />
                                        <label for="Baseball">Yes </label>
                                    </div>

                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Softball" value="no" name="in-high-school" checked={this.state.basicArr["in-high-school"] === "no" && true} onChange={this.handleAboutChange} />
                                        <label for="Softball">No </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('', this.state.basicArr["in-high-school"], 'required', { className: 'text-danger' })}</span>
                            </div>


                            {
                                this.state.basicArr["in-high-school"] === "yes" &&
                                this.calculate_age(this.props.profileData && this.props.profileData.profile.dob) &&
                                <>
                                    <div className="check_inputbox">
                                        <label className="common-lable">What year do you graduate high school?</label>
                                        <select name="gra-year" className="custom-select" onChange={this.handleAboutChange}>
                                            <option selected id="0" disabled>Select Year</option>
                                            {
                                                this.years.map((year, index) => {
                                                    return <option key={`year${index}`} selected={this.state.basicArr["gra-year"] == year && "selected"} value={year}>{year}</option>
                                                })
                                            }
                                        </select>
                                        {this.state.basicArr["in-high-school"] === "yes" && <span className="validMsg">{this.schoolValidator.message('Year', this.state.basicArr["gra-year"], 'required', { className: 'text-danger' })}</span>}
                                    </div>

                                </>
                            }


                            {
                                this.calculate_age(this.props.profileData && this.props.profileData.profile.dob) &&
                                <div className="check_inputbox">
                                    <label className="common-lable">Name of school</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" placeholder="Enter school name" name="school" value={this.state.basicArr.school} onChange={this.handleAboutChange} />
                                        {this.state.basicArr["in-high-school"] === "yes" && <span className="validMsg">{this.schoolValidator.message('School Name', this.state.basicArr.school, 'required', { className: 'text-danger' })}</span>}
                                    </div>
                                </div>
                            }
                            {
                                !this.calculate_age(this.props.profileData && this.props.profileData.profile.dob) &&
                                <div className="check_inputbox">
                                    <label className="common-lable">Name of school</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" id="usr" placeholder="Enter school name" name="school" value={this.state.basicArr.school} onChange={this.handleAboutChange} />
                                        {this.state.basicArr["in-high-school"] === "yes" && <span className="validMsg">{this.schoolValidator.message('School Name', this.state.basicArr.school, 'required', { className: 'text-danger' })}</span>}
                                    </div>
                                </div>
                            }

                            {
                                this.state.basicArr["in-high-school"] === "yes" && this.calculate_age(this.props.profileData && this.props.profileData.profile.dob) &&
                                <div className="check_inputbox basic_info">
                                    <div className="check_inputbox">
                                        <label className="common-lable">GPA</label>
                                        {/* <select name="city" className="custom-select" name="curr-gpa" onChange={this.handleAboutChange}>
                                            <option selected="selected" id="0" disabled>Select GPA</option>
                                            <option id="2.5-2.9" value="2.5-2.9" selected={this.state.basicArr["curr-gpa"] === "2.5-2.9" && "selected"}>{"2.5-2.9"}</option>
                                            <option id="3.0-3.4" value="3.0-3.4" selected={this.state.basicArr["curr-gpa"] === "3.0-3.4" && "selected"}>{"3.0-3.4"}</option>
                                            <option id="3.5-3.9" value="3.5-3.9" selected={this.state.basicArr["curr-gpa"] === "3.5-3.9" && "selected"}>{"3.5-3.9"}</option>
                                            <option id="4.0-4.4" value="4.0-4.4" selected={this.state.basicArr["curr-gpa"] === "4.0-4.4" && "selected"}>{"4.0-4.4"}</option>
                                            <option id="4.5-5.0" value="4.5-5.0" selected={this.state.basicArr["curr-gpa"] === "4.5-5.0" && "selected"}>{"4.5-5.0"}</option>
                                        </select>
                                        {this.state.basicArr["in-high-school"] === "yes" && <span className="validMsg">{this.schoolValidator.message('Current gpa', this.state.basicArr["curr-gpa"], 'required', { className: 'text-danger' })}</span>} */}
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" id="usr" placeholder="GPA" value={this.state.basicArr["curr-gpa"]} name="curr-gpa" onChange={this.handleAboutChange} />
                                        </div>
                                        {/* <span className="validMsg">{this.schoolValidator.message('gpa', this.state.basicArr["curr-gpa"], 'required|numeric', { className: 'text-danger' })}</span> */}
                                    </div>
                                    <div className="check_inputbox">
                                        <label className="common-lable">SAT</label>
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" id="usr" placeholder="SAT" value={this.state.basicArr.sat} name="sat" onChange={this.handleAboutChange} />
                                        </div>
                                        {/* <span className="validMsg">{this.schoolValidator.message('sat', this.state.basicArr.sat, 'required|numeric', { className: 'text-danger' })}</span> */}
                                    </div>
                                    <div className="check_inputbox">
                                        <label className="common-lable">ACT</label>
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" id="usr" placeholder="ACT" value={this.state.basicArr.act} name="act" onChange={this.handleAboutChange} />
                                        </div>
                                        {/* <span className="validMsg">{this.schoolValidator.message('act', this.state.basicArr.act, 'required|numeric', { className: 'text-danger' })}</span> */}
                                    </div>
                                </div>
                            }


                        </div>

                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">What sport do you play?</label>
                                <div className="check-box mb-0">
                                    <div className="checkbox" >
                                        <input type="radio" id="Baseball" value="1" name="game-type" onChange={this.onHandleChange} checked={basicArr['game-type'] === "1" ? true : false} />
                                        <label for="Baseball">Baseball </label>
                                    </div>
                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Softball" value="2" name="game-type" onChange={this.onHandleChange} checked={basicArr['game-type'] === "2" ? true : false} />
                                        <label for="Softball">Softball </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('Sport Coach', basicArr['game-type'], 'required')}</span>
                            </div>
                        </div>

                        <div className="check_input row">
                            <div className="check_inputbox ground_inner">
                                <label className="common-lable">How long have you played {basicArr['game-type'] === "1" ? "baseball" : "softball"}?</label>
                                <select name="year-exp" value={basicArr['year-exp']} onChange={(e) => this.handleAboutChange(e)} className="custom-select">
                                    <option value="" selected disabled>Select year</option>
                                    <option value="1-3" selected={basicArr['year-exp'] === "1-3" ? "selected" : ""}>1-3 Years</option>
                                    <option value="4-6" selected={basicArr['year-exp'] === "4-6" ? "selected" : ""}>4-6 Years</option>
                                    <option value="7-9" selected={basicArr['year-exp'] === "7-9" ? "selected" : ""}>7-9 Years</option>
                                    <option value="10-12" selected={basicArr['year-exp'] === "10-12" ? "selected" : ""}>10-12 Years</option>
                                </select>
                                <span className="validMsg">{this.validator.message('played years', basicArr['year-exp'], 'required')}</span>
                            </div>
                            <div className="check_inputbox">
                                <label className="common-lable">City <span>( To Look Up State and Zipcode )</span></label>
                                <div className="form-group mb-0">
                                    <div className="serch-box">
                                        <Geosuggest
                                            // ref={el => this._geoSuggest = el}
                                            onSuggestSelect={this.handleSelectGeoSuggest}
                                            initialValue={basicArr.address.city || ""}
                                            autoComplete="off"
                                            types={['(cities)']}
                                        />
                                        {/* <input type="text" name="zipcode" value={basicArr.address.zipcode} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Zip Code" />
                                        <Link onClick={() => this.getZipcode(basicArr.address.zipcode)} className="serch-btn">
                                            Next
                                        </Link> */}
                                    </div>
                                    <span className="validMsg">{this.validator.message('city', basicArr.address.city, 'required')}</span>
                                </div>
                            </div>
                            {
                                // manageZipcode &&
                                <>
                                   
                                    <div className="check_inputbox">
                                        <label className="common-lable">State</label>
                                        <div className="form-group mb-0">
                                            <select className="custom-select" name="state" value={basicArr.address.state} onChange={this.onHandleChange}>
                                                <option value="">N/A</option>
                                                {Object.keys(StatesObj).map((key) => {
                                                    return (
                                                        <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                                                    )
                                                })}
                                            </select>
                                            {/* <input type="text" name="state" value={basicArr.address.state} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="State" disabled /> */}
                                        </div>
                                        <span className="validMsg">{this.validator.message('state', basicArr.address.state, 'required')}</span>
                                    </div>

                                    <div className="check_inputbox">
                                        <label className="common-lable">Zip Code</label>
                                        <div className="form-group mb-0">
                                            <input type="text" name="zipcode" value={basicArr.address.zipcode} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Zip code" disabled={this.state.isEditZipcode ? false : true} />
                                        </div>
                                        <span className="validMsg">{this.validator.message('zipcode', basicArr.address.zipcode, 'required')}</span>
                                    </div>
                                </>}

                            {
                                (this.state.basicArr["in-high-school"] === "yes" && this.calculate_age_between(this.props.profileData && this.props.profileData.profile.dob)) &&
                                <div className="check_box">
                                    <label className="common-lable">Do you want to play (baseball/softball) in college?</label>
                                    <div className="check-box mb-0">
                                        <div className="checkbox" >
                                            <input type="radio" id="int1" value="yes" name="recruiting" checked={this.state.basicArr["recruiting"] === "yes" && true} onChange={this.handleAboutChange} />
                                            <label for="int1">Yes </label>
                                        </div>

                                        <div className="checkbox mr-0">
                                            <input type="radio" id="int2" value="no" name="recruiting" checked={this.state.basicArr["recruiting"] === "no" && true} onChange={this.handleAboutChange} />
                                            <label for="int2">No </label>
                                        </div>
                                    </div>
                                    <span className="validMsg">{this.validator.message('', this.state.basicArr["recruiting"], 'required', { className: 'text-danger' })}</span>
                                </div>}
                            {
                                (this.state.basicArr["in-high-school"] === "yes" && this.calculate_age_between(this.props.profileData && this.props.profileData.profile.dob)) &&
                                this.state.basicArr["recruiting"] === "yes" &&
                                <div className='player-basic-info add-player'>
                                    <div className='player-info'>
                                        <img src={imagesArr.ncsaLogoFullColor} />
                                        <p><b>Prospect Wire teams with Next College Student Athlete to help athletes with the dream of competing in college.</b></p>
                                    </div>
                                    <ul>
                                        <li><p>Receive a <b>FREE recruiting profile</b> visible to over 35,000 college coaches </p></li>
                                        <li><p>An NCSA recruiting expert will reach out to offer a <b>FREE recruiting assessment</b></p></li>
                                        <li><p>Receive a <b>FREE recruiting game plan</b> personalized to your child's abilities, wants and needs</p></li>
                                        <li><p>By clicking “yes” and submitting, you agree to receive personalized follow-up and marketing messages from NCSA by email, phone and automated text. Consent is not a condition of purchase. Standard rates apply.</p></li>
                                    </ul>
                                </div>
                            }
                        </div>

                        {/* {manageZipcode &&
                            <div className="check_input row">
                                <div className="check_inputbox">
                                    <label className="common-lable">City</label>
                                    <div className="form-group mb-0">
                                        <input type="text" name="city" value={basicArr.address.city} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="City" readOnly />
                                    </div>
                                    <span className="validMsg">{this.validator.message('city', basicArr.address.city, 'required')}</span>
                                </div>
                                <div className="check_inputbox">
                                    <label className="common-lable">State</label>
                                    <div className="form-group mb-0">
                                        <input type="text" name="state" value={basicArr.address.state} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="State" readOnly />
                                    </div>
                                    <span className="validMsg">{this.validator.message('state', basicArr.address.state, 'required')}</span>
                                </div>
                            </div>} */}

                    </div>
                </section>
                {(this.state.parentPopup && this.state.basicArr["recruiting"] === "yes") &&
                    <ParentInfo
                        flag={this.state.parentPopup}
                        close={this.closeParentPopUp}
                        basicArr={basicArr.parentInfo}
                        fname={this.state.fname}
                        lname={this.state.lname}
                        onChange={this.parentHandle}
                        onParentSave={this.onParentSave}
                    />}
                <ImageCrop
                    imgObj={imgObj}
                    flag={cropPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />
                <Preloader flag={loader} />
            </>
        );
    }
}

basic.propTypes = {
    searchZipcode: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    getPlayerBasicDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    basicDetails: state.basicDetails,
    basicData: state.basicPlayerDetails,
});

export default connect(mapStateToProps, { searchZipcode, uploadImage, getPlayerBasicDetails })(basic);

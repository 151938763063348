export const GET_PLAYER_COLLEGE_SEARCH_REQUEST = 'GET_PLAYER_COLLEGE_SEARCH_REQUEST';
export const GET_PLAYER_COLLEGE_SEARCH_SUCCESS = 'GET_PLAYER_COLLEGE_SEARCH_SUCCESS';
export const GET_PLAYER_COLLEGE_SEARCH_ERROR = 'GET_PLAYER_COLLEGE_SEARCH_ERROR';

export const GET_SUGGETION_REQUEST = 'GET_SUGGETION_REQUEST';
export const GET_SUGGETION_SUCCESS = 'GET_SUGGETION_SUCCESS';
export const GET_SUGGETION_ERROR = 'GET_PLAYER_COLLEGE_SEARCH_ERROR';

export const POST_COLLEGELIST_REQUEST = 'POST_COLLEGELIST_REQUEST';
export const POST_COLLEGELIST_SUCCESS = 'POST_COLLEGELIST_SUCCESS';
export const POST_COLLEGELIST_ERROR = 'POST_COLLEGELIST_ERROR';

export const GET_COLLEGELIST_REQUEST = 'GET_COLLEGELIST_REQUEST';
export const GET_COLLEGELIST_SUCCESS = 'GET_COLLEGELIST_SUCCESS';
export const GET_COLLEGELIST_ERROR = 'GET_COLLEGELIST_ERROR';
import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    SAVE_PLAYER_COMPLETE_REQUEST,
    SAVE_PLAYER_COMPLETE_SUCCESS,
    SAVE_PLAYER_COMPLETE_ERROR,
} from '../../../../constants/authantication/signup/player/complete.const';
import histrory from '../../../../../History';

export const savePlayerComplete = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: SAVE_PLAYER_COMPLETE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/player/complete`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(complete => {
        console.log("complete:----", complete);
        dispatch({
            type: SAVE_PLAYER_COMPLETE_SUCCESS,
            payload: complete.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SAVE_PLAYER_COMPLETE_ERROR, error: error.response.data.message })
    });
};


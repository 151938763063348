import React, { Component } from "react";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from "../../assets/images";
import { Link } from "react-router-dom";
import CommonHeroBanner from "../../components/commonHeroBanner";
import { Player } from 'video-react';

class OvR extends Component {
  render() {
    return (
      <>
        <Header />
        <CommonHeroBanner dataList="" />
        <section className="commen_product">
          <div className="container">
            <div className="heading-title">
              <h2>Launching Early 2021</h2>
            </div>
            <div className="video-bg">
              <Player playsInline src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/video/ovr.mp4" />
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default OvR;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { imagesArr } from "../../assets/images";


function ParentInfo({ flag, close, basicArr, fname, lname, onChange ,onParentSave}) {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  useEffect(() => {
    if (flag) {
      document.body.classList.add("modal-open");
    }
  }, []);


  const onSave = () => {
    if (validator.current.allValid()) {
      onParentSave();
      close();
    }
    else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  }


  return (
    <>
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>Parent Info</h3>
              <div className="modal_close" onClick={close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="team-body-inner">

                <div className="form-group">
                  <label className="common-lable">First Name</label>
                  <input
                    type="text"
                    name="fname"
                    value={fname}
                    onChange={onChange}
                    className="form-control"
                    id="usr"
                    placeholder="First Name"
                  />
                  <span className="validMsg">
                    {validator.current.message("first name", fname, "required")}
                  </span>
                </div>
                <div className="form-group">
                  <label className="common-lable">Last Name</label>
                  <input
                    type="text"
                    name="lname"
                    value={lname}
                    onChange={onChange}
                    className="form-control"
                    id="usr"
                    placeholder="Last Name"
                  />
                  <span className="validMsg">
                    {validator.current.message("last name", lname, "required")}
                  </span>
                </div>


                <div className="form-group">
                  <label className="common-lable">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={basicArr?.email}
                    onChange={onChange}
                    className="form-control"
                    id="usr"
                    placeholder="Enter email address"
                  />
                  <span className="validMsg">
                    {validator.current.message("email Address", basicArr?.email, "required|email")}
                  </span>
                </div>

                <div className="form-group">
                  <label>Mobile number</label>
                  <div className="svg_icon">
                    <div className="input-group">
                      <div className="counrty-no">
                        <select className="custom-select" name="cCode">
                          <option value="+1">+1</option>
                        </select>
                      </div>
                      <input type="text" 
                      className="form-control"
                       name="phone"
                        value={basicArr?.phone}
                         onChange={onChange}
                          id="usr"
                           placeholder="Enter your mobile number" />
                      <img src={imagesArr.smartphone} alt="eye" />
                    </div>
                    <span className="validMsg">{validator.current.message('Mobile number', basicArr?.phone, 'required|phone')}</span>
                  </div>
                </div>

              </div>
              <div className="flex-align next-button">
                <Link className="btn light-green" onClick={onSave}>
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ParentInfo;

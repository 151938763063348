import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { DKAuthantication, getDKAuthantication, getDKDisconnectAccount } from '../../../saga/actions/common/uploadcsv.action';
import Preloader from '../../../components/preloader';
class diamondKinetics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                email: "",
                password: ""
            },
            dkCredFlag: false,
            dkUid: ""
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        this.setLoader(true);
        this.props.getDKAuthantication("Fan",localStorage.getItem("rosterToken"));

    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.DKAuthReq &&
            nextProps.uploadCsvInfo.DKAuthData
        ) {
            nextProps.uploadCsvInfo.DKAuthReq = false;
            if (nextProps.uploadCsvInfo.DKAuthData.flag) {
                this.commonCall.successMessage(nextProps.uploadCsvInfo.DKAuthData.message);
                nextProps.uploadCsvInfo.DKAuthData = null;
                this.setState((prevState) => ({
                    ...prevState,
                    sendReq: {
                        ...prevState.sendReq,
                        email: "",
                        password: ""
                    },
                }), () => {
                    this.props.getDKAuthantication("Fan",localStorage.getItem("rosterToken"));
                });
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.DKAuthData.message);
                nextProps.uploadCsvInfo.DKAuthData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getDKAuthReq &&
            nextProps.uploadCsvInfo.getDKAuthData
        ) {
            nextProps.uploadCsvInfo.getDKAuthReq = false;
            if (nextProps.uploadCsvInfo.getDKAuthData.flag) {
                this.setState({
                    dkCredFlag: nextProps?.uploadCsvInfo?.getDKAuthData?.result?.dkCredentials?.connectedFlag,
                    dkUid: nextProps?.uploadCsvInfo?.getDKAuthData?.result?.dkCredentials?.uuid
                }, () => {
                    nextProps.uploadCsvInfo.getDKAuthData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.getDKAuthData.message);
                nextProps.uploadCsvInfo.getDKAuthData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.getDKDisconnectReq &&
            nextProps.uploadCsvInfo.getDKDisconnectData
        ) {
            console.log("disconnect:----", nextProps.uploadCsvInfo.getDKDisconnectData);
            nextProps.uploadCsvInfo.getDKDisconnectReq = false;
            if (nextProps.uploadCsvInfo.getDKDisconnectData.flag) {
                this.setState({
                    dkCredFlag: false,
                    dkUid: ""
                }, () => {
                    this.commonCall.successMessage(nextProps.uploadCsvInfo.getDKDisconnectData.message);
                    nextProps.uploadCsvInfo.getDKDisconnectData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.getDKDisconnectData.message);
                nextProps.uploadCsvInfo.getDKDisconnectData = null;
                this.setLoader(false);
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;

        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: value
            },
        }));
    }

    onHandleSubmit = () => {
        if (this.validator.allValid()) {
            this.setLoader(true);
            this.props.DKAuthantication(this.state.sendReq, "Fan", localStorage.getItem("rosterToken"))
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    disconnectAccount = () => {
        this.setLoader(true);
        this.props.getDKDisconnectAccount("Fan",localStorage.getItem("rosterToken"));
    }

    render() {
        const { sendReq, loader, dkCredFlag, dkUid } = this.state;
        return (
            <>
                <section className="uploadcsv" style={{ marginTop: '0px' }}>
                    <div className="container">
                        <div className="white-bg tablecontent">
                            <div className="diamondkinetics-form">
                                {
                                    dkUid ?
                                        <>
                                            <h5>DK account UUID</h5>
                                            <form>
                                                <div className="form-group">
                                                    <input type="text" name="uuid" value={dkUid} className="form-control" disabled />
                                                </div>
                                                <div className="form-group text-center">
                                                    <a className="btn" onClick={() => this.disconnectAccount()}>Disconnect Account</a>
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                            <h5>Diamond Kinetics Auth.</h5>
                                            <form>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="email" name="email" value={sendReq.email} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter your email" />
                                                    <span className="validMsg">{this.validator.message('Email', sendReq.email, 'required')}</span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" name="password" value={sendReq.password} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter your password" />
                                                    <span className="validMsg">{this.validator.message('Password', sendReq.password, 'required')}</span>
                                                </div>
                                                <div className="form-group text-center">
                                                    <a className="btn " onClick={() => this.onHandleSubmit()}>Submit</a>
                                                </div>
                                                {
                                                    dkCredFlag &&
                                                    <p className="text-center">* You are connected to diamond kinetics</p>
                                                }
                                            </form>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
            </>
        )
    }
}

diamondKinetics.propTypes = {
    DKAuthantication: PropTypes.func.isRequired,
    getDKAuthantication: PropTypes.func.isRequired,
    getDKDisconnectAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default withRouter(connect(mapStateToProps, { DKAuthantication, getDKAuthantication, getDKDisconnectAccount })(diamondKinetics));

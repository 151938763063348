export const GETINFO_REQUEST = 'GETINFO_REQUEST';
export const GETINFO_SUCCESS = 'GETINFO_SUCCESS';
export const GETINFO_ERROR   = 'GETINFO_ERROR';

export const ZIPINFO_REQUEST = 'ZIPINFO_REQUEST';
export const ZIPINFO_SUCCESS = 'ZIPINFO_SUCCESS';
export const ZIPINFO_ERROR   = 'ZIPINFO_ERROR';

export const POSTINFO_REQUEST ="POSTINFO_REQUEST";
export const POSTINFO_SUCCESS ="POSTINFO_SUCCESS";
export const POSTINFO_ERROR   ="POSTINFO_ERROR";

export const PICTUREUPLOAD_REQUEST = 'PICTUREUPLOAD_REQUEST';
export const PICTUREUPLOAD_SUCCESS = 'PICTUREUPLOAD_SUCCESS';
export const PICTUREUPLOAD_ERROR = "PICTUREUPLOAD_ERROR";
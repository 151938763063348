import {
    GET_FAN_BASIC_REQUEST,
    GET_FAN_BASIC_SUCCESS,
    GET_FAN_BASIC_ERROR,

    GET_SEARCH_REQUEST,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,

    UP_COVER_IMAGE_REQUEST,
    UP_COVER_IMAGE_SUCCESS,
    UP_COVER_IMAGE_ERROR,

    SAVE_FAN_BASIC_REQUEST,
    SAVE_FAN_BASIC_SUCCESS,
    SAVE_FAN_BASIC_ERROR,

    GET_INVITE_COACH_REQUEST,
    GET_INVITE_COACH_SUCCESS,
    GET_INVITE_COACH_ERROR,

    GET_INVITE_SUB_COACH_REQUEST,
    GET_INVITE_SUB_COACH_SUCCESS,
    GET_INVITE_SUB_COACH_ERROR,

    ACCEPT_COACH_NOTIFY_REQUEST,
    ACCEPT_COACH_NOTIFY_SUCCESS,
    ACCEPT_COACH_NOTIFY_ERROR,

    CANCEL_COACH_NOTIFY_REQUEST,
    CANCEL_COACH_NOTIFY_SUCCESS,
    CANCEL_COACH_NOTIFY_ERROR,
} from '../../../../constants/authantication/signup/fan/basic.const';

const initialState = {
    fanBasicRes: false,
    fanBasicData: null,
    fanBasicDataError: null,

    searchRes: false,
    searchData: null,
    searchDataError: null,

    coverImageRes: false,
    coverImageData: null,
    coverImageDataError: null,

    saveFanReq: false,
    saveFanData: null,
    saveFanDataError: null,

    getInviteCoachRes: false,
    getInviteCoachData: null,
    getInviteCoachDataError: null,

    getInviteSubCoachRes: false,
    getInviteSubCoachData: null,
    getInviteSubCoachDataError: null,

    acceptInviteCoachRes: false,
    acceptInviteCoachData: null,
    acceptInviteCoachDataError: null,

    cancelInviteCoachRes: false,
    cancelInviteCoachData: null,
    cancelInviteCoachDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Basic Data
        case GET_FAN_BASIC_REQUEST:
            return {
                ...state,
                fanBasicRes: true
            };
        case GET_FAN_BASIC_SUCCESS:
            return {
                ...state,
                fanBasicData: action.payload,
            };
        case GET_FAN_BASIC_ERROR:
            return {
                ...state,
                fanBasicDataError: action.error,
            };


        // Get Zipcode Search Data
        case GET_SEARCH_REQUEST:
            return {
                ...state,
                searchRes: true
            };
        case GET_SEARCH_SUCCESS:
            return {
                ...state,
                searchData: action.payload,
            };
        case GET_SEARCH_ERROR:
            return {
                ...state,
                searchDataError: action.error,
            };

        // upload Image Search Data
        case UP_COVER_IMAGE_REQUEST:
            return {
                ...state,
                coverImageRes: true
            };
        case UP_COVER_IMAGE_SUCCESS:

            return {
                ...state,
                coverImageData: action.payload,
            };
        case UP_COVER_IMAGE_ERROR:
            return {
                ...state,
                coverImageDataError: action.error,
            };

        // upload Image Search Data
        case SAVE_FAN_BASIC_REQUEST:
            return {
                ...state,
                saveFanReq: true
            };
        case SAVE_FAN_BASIC_SUCCESS:
            return {
                ...state,
                saveFanData: action.payload,
            };
        case SAVE_FAN_BASIC_ERROR:
            return {
                ...state,
                saveFanDataError: action.error,
            };

        // get invite Data
        case GET_INVITE_COACH_REQUEST:
            return {
                ...state,
                getInviteCoachRes: true
            };
        case GET_INVITE_COACH_SUCCESS:
            return {
                ...state,
                getInviteCoachData: action.payload,
            };
        case GET_INVITE_COACH_ERROR:
            return {
                ...state,
                getInviteCoachDataError: action.error,
            };

        // get invite sub coach Data
        case GET_INVITE_SUB_COACH_REQUEST:
            return {
                ...state,
                getInviteSubCoachRes: true
            };
        case GET_INVITE_SUB_COACH_SUCCESS:
            return {
                ...state,
                getInviteSubCoachData: action.payload,
            };
        case GET_INVITE_SUB_COACH_ERROR:
            return {
                ...state,
                getInviteSubCoachDataError: action.error,
            };

        // accept invite Data
        case ACCEPT_COACH_NOTIFY_REQUEST:
            return {
                ...state,
                acceptInviteCoachRes: true
            };
        case ACCEPT_COACH_NOTIFY_SUCCESS:
            return {
                ...state,
                acceptInviteCoachData: action.payload,
            };
        case ACCEPT_COACH_NOTIFY_ERROR:
            return {
                ...state,
                acceptInviteCoachDataError: action.error,
            };

         // accept invite Data
         case CANCEL_COACH_NOTIFY_REQUEST:
            return {
                ...state,
                cancelInviteCoachRes: true
            };
        case CANCEL_COACH_NOTIFY_SUCCESS:
            return {
                ...state,
                cancelInviteCoachData: action.payload,
            };
        case CANCEL_COACH_NOTIFY_ERROR:
            return {
                ...state,
                cancelInviteCoachDataError: action.error,
            };

        default:
            return state;
    }
}
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import InputMask from 'react-input-mask';

import { imagesArr } from '../../../assets/images';
import commonCalls from '../../../businesslogic/commonCalls';

import { claimProfile, editPlayerInfo, getPlayerList } from '../../../saga/actions/common/accountCreation.action';
import { postPictureUpload, zipCodeInfo } from '../../../saga/actions/views/player/profile/personalInfo.action';
import { EDIT_PLAYER_INFO_SUCCESS, GET_PLAYER_INFO_SUCCESS } from '../../../saga/constants/common/accountCreation.const';
import { PICTUREUPLOAD_SUCCESS } from '../../../saga/constants/view/player/profile/personalInfo.const';
import { Link } from 'react-router-dom';
import { getZipcodeInfo } from '../../../saga/actions/common/api.action';
import { ZIPCODE_SUCCESS } from '../../../saga/constants/common/api.const';
import Geosuggest from 'react-geosuggest';
import { StatesObj } from '../../../businesslogic/content';


const initialState = {
    edit: {
        _id: "",
        phone: "",
        email: "",
        cCode: "",
        fname: "",
        lname: "",
        number: "",
        dob: "",
        position: "",
        claimAcc: true,
        type: 0,
        objectId: "",
        profileUrl: "",
        profileId: "",
        position2: "",
        height: "",
        weight: "",
        useHanded: "",
        'batting-pos': [],
        "curr-gpa": "",
        "gra-year": "",
        sat: "",
        act: "",
        recruiting: "no",
        address: {
            zipcode: "",
            city: "",
            state: "",
        },
    },
    "game-type": "",
    opencal: "",
    isDOBUpdate: true,
    isEmailUpdate: true,
    'in-high-school': "no",
    openLogin: false,
    IsClaimAcc: false,
    isCoach: true,
    isEditZipcode: false
}

const reducer = (state, payload) => ({ ...state, ...payload })

function Player({ state, setState }) {
    const dispatch = useDispatch();
    const commonCall = new commonCalls();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [player, setPlayer] = useReducer(reducer, initialState);
    const { getPlayerInfo: _INFO, editPlayerInfo: _EDIT } = useSelector(state => state.accountCreation);
    const { pictureUploadData: _UPLOAD } = useSelector(state => state.playerPersonalInfo);
    const { zipcodeData: _zipcode } = useSelector((state) => state.api);
    const ref = useRef();
    const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    let options = [];
    for (var Y = 2035; Y >= 1980; Y--) {
        options.push(Y)
    }

    useEffect(() => {
        if (_INFO) {
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    _id: _INFO._id,
                    phone: _INFO.phone,
                    email: _INFO.email.length === 1 ? "" : _INFO.email,
                    cCode: _INFO.cCode,
                    fname: _INFO.fname,
                    lname: _INFO.lname,
                    number: _INFO.number,
                    dob: _INFO.dob,
                    position: _INFO.position,
                    claimAcc: true,
                    type: 1,
                    objectId: _INFO.mapUser,
                    profileUrl: _INFO.profileUrl,
                    profileId: _INFO.profileId,
                    position2: _INFO.position2,
                    height: _INFO.height,
                    weight: _INFO.weight,
                    useHanded: _INFO.useHanded,
                    ["batting-pos"]: _INFO["batting-pos"],
                    recruiting: _INFO.recruiting,
                    'in-high-school': _INFO?.hasOwnProperty("in-high-school") ? _INFO['in-high-school'] : "no",
                    "curr-gpa": _INFO["curr-gpa"],
                    "gra-year": _INFO["gra-year"],
                    sat: _INFO.sat,
                    act: _INFO.act,
                    address: {
                        zipcode: _INFO.address && _INFO.address.zipcode,
                        city: _INFO.address && _INFO.address.city,
                        state: _INFO.address && _INFO.address.state,
                    }
                },
                allowRecruting: _INFO.recruiting,
                "game-type": _INFO["game-type"],
                isDOBUpdate: _INFO?.hasOwnProperty("dob") ? _INFO.dob ? new Date(_INFO.dob) : false : false,
                isEmailUpdate: _INFO?.hasOwnProperty("email") ? _INFO.email ? new Date(_INFO.email) : false : false,
                IsClaimAcc: _INFO.claimAcc,
                isCoach: localStorage.getItem("coachToken") ? true : false
            })
            setState({
                ...state,
                loader: false,
                canCreateParent: _INFO?.hasOwnProperty("dob") ? _INFO.dob : ""
            })
            dispatch({
                type: GET_PLAYER_INFO_SUCCESS,
                payload: null
            })
        }
    }, [_INFO])

    useEffect(() => {
        if (_UPLOAD) {
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    profileUrl: _UPLOAD.filePath,
                    profileId: _UPLOAD._id,
                }
            })
            setState({
                ...state,
                loader: false
            })
            dispatch({
                type: PICTUREUPLOAD_SUCCESS,
                payload: null,
            });
        }
    }, [_UPLOAD])

    useEffect(() => {
        if (_EDIT) {
            if (_EDIT.flag) {
                toast.success(_EDIT.message, { position: toast.POSITION.TOP_RIGHT })
                setState({
                    ...state,
                    claimForm: 1,
                    claimAccount: false,
                    loader: false
                })
                let url = new URL(document.location);
                let rid = url.pathname.split("/")[2];
                getPlayerList({ rosterTeamId: rid })(dispatch);
                dispatch({
                    type: EDIT_PLAYER_INFO_SUCCESS,
                    payload: null
                })
            } else {
                setState({
                    ...state,
                    loader: false
                })
                toast.error(_EDIT.message, { position: toast.POSITION.TOP_RIGHT })
                dispatch({
                    type: EDIT_PLAYER_INFO_SUCCESS,
                    payload: null
                })
            }
        }
    }, [_EDIT])

    useEffect(() => {
        if (_zipcode) {
            if (_zipcode.flag) {
                setPlayer({
                    ...player,
                    edit: {
                        ...player.edit,
                        address: {
                            ...player.edit.address,
                            zipcode: _zipcode.result.zipcodestr,
                            city: _zipcode.result.city,
                            state: _zipcode.result.state,
                        }
                    }
                });
                setState({
                    ...state,
                    loader: false
                })
                dispatch({
                    type: ZIPCODE_SUCCESS,
                    payload: null,
                });
            } else {
                commonCall.errorMessage(_zipcode.message);
                setState({
                    ...state,
                    loader: false
                })
                dispatch({
                    type: ZIPCODE_SUCCESS,
                    payload: null,
                });
            }
        }
    }, [_zipcode])

    const handleChange = (e) => {
        if (e.target.name === "allowRecruting") {
            setPlayer({
                ...player,
                allowRecruting: e.target.value
            })
        }
        else if (e.target.name === "zipcode" || e.target.name === "state" || e.target.name === "city") {
            if (e.target.name === "zipcode") {
                const re = /^[0-9\b]+$/;
                if (e.target.value.length <= 9) {
                    if (re.test(e.target.value) || e.target.value.length <= 0) {
                        setPlayer({
                            ...player,
                            edit: {
                                ...player.edit,
                                address: {
                                    ...player.edit.address,
                                    [e.target.name]: e.target.value
                                }
                            }
                        })
                    }
                }
            } else {
                setPlayer({
                    ...player,
                    edit: {
                        ...player.edit,
                        address: {
                            ...player.edit.address,
                            [e.target.name]: e.target.value
                        }
                    }
                })
            }

        }
        else if (e.target.name === "weight") {
            const re = /^[0-9\b]+$/;
            if (e.target.value.length <= 3) {
                if (re.test(e.target.value) || e.target.value.length <= 0) {
                    setPlayer({
                        ...player,
                        edit: {
                            ...player.edit,
                            [e.target.name]: e.target.value,
                        }
                    });
                }
            }
        } else if (e.target.name === "height") {
            const foot = e.target.value.split("'");
            const inches = e.target.value.split(`"`);
            if (foot[1] && inches[0]) {
                const inch = parseInt(foot[1].split(`"`)[0])
                if (inches[1]) {
                    if (inches[1] === "0" || inches[1] === "1" && inch === 1) setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'${inch}${inches[1]}"` } })
                } else {
                    if (inch > 9 && inch < 12) {
                        if (player.edit.height.includes(`"`) && !e.target.value.includes(`"`)) setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'${foot[1][0]}"` } })
                        else setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'${inch}"` } })
                    } else if (inch >= 0 && inch <= 9) {
                        const prev = player.edit.height.split("'")[1]
                        if (player.edit.height.includes(`"`) && !(prev.includes('10') || prev.includes('11'))) setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'` } })
                        else if (prev.includes('10') || prev.includes('11')) setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'${foot[1]}` } })
                        else setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'${foot[1]}"` } })
                    } else if (isNaN(inch)) setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${foot[0]}'` } })
                }
            }
            else if (inches[0] && player.edit.height.includes(`"`) && inches[1] !== "") { setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `` } }) }
            else if (foot[0] && !player.edit.height.includes("'") && foot[1] !== "") { setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${e.target.value}'` } }) }
            else if (foot[0] && player.edit.height.includes("'") && foot[1] !== "") { setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `` } }) }
            else { setPlayer({ ...player, edit: { ...player.edit, [e.target.name]: `${e.target.value}`, } }) }
        } else if (e.target.name === "number") {
            const re = /^[0-9\b]+$/;
            if (e.target.value.length <= 2) {
                if (re.test(e.target.value) || e.target.value.length <= 0) {
                    setPlayer({
                        ...player,
                        edit: {
                            ...player.edit,
                            [e.target.name]: e.target.value
                        }
                    })
                }
            }
        }
        else {
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    const onlyNumber = event => {
        const keycode = event.which;
        if (keycode === 13) {
        } else if (
            !(
                event.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            event.preventDefault();
    };

    const changeImage = (event) => {
        let file = event.target.files[0];
        if (file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "png") {
            return commonCall.errorMessage("Accept only JPG,JPEG,PNG file");
        }
        setPlayer({
            ...player,
            imgObj: file,
        });
        setState({
            ...state,
            loader: true
        })
        claimProfile(file, 16)(dispatch);
    };

    const onSave = () => {
        if (validator.current.allValid()) {
            setState({
                ...state,
                loader: true
            })
            player.edit.recruiting = player.allowRecruting;
            // player.edit.heightNumeric = Number(player.edit.height.replace("'", ".").replace("\"", ""));
            let ht=Number(player.edit.height.replace("'", ".").replace("\"", "")).toString().split("");
            let numeric = (parseInt(Number(ht[0])) * 12) + parseInt(ht[2]?Number(ht[2]):0);
            player.edit.heightNumeric=numeric;
            editPlayerInfo(player.edit)(dispatch)
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const calculate_age = (dob1) => {
        var ageDifMs = Date.now() - new Date(dob1).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
    }


    const calculate_age_between = (dob1) => {
        let today = new Date(),
            //birthay has 'Dec 25 1998'
            dob = new Date(dob1),
            //difference in milliseconds
            diff = today.getTime() - dob.getTime(),
            //convert milliseconds into years
            years = Math.floor(diff / 31556736000),
            //1 day has 86400000 milliseconds
            days_diff = Math.floor((diff % 31556736000) / 86400000),
            //1 month has 30.4167 days
            months = Math.floor(days_diff / 30.4167),
            days = Math.floor(days_diff % 30.4167);
        if (years === 15 && months >= 0 && days >= 0) {
            return true
        } else if (years === 18 && months == 0 && days == 0) {
            return true
        }
        else if (years >= 14 && years < 18) {
            return true
        } else {
            return false
        }
        // var ageDifMs = Date.now() - new Date(dob1).getTime();
        // var ageDate = new Date(ageDifMs); // miliseconds from epoch
        // return (Math.abs(ageDate.getUTCFullYear() - 1970) >= 15 && Math.abs(ageDate.getUTCFullYear() - 1970) <= 18);
    }



    const removeProfile = () => {
        setPlayer({
            ...player,
            edit: {
                ...player.edit,
                profileUrl: null,
            }
        });
    }

    const getZipcode = () => {
        if (player.edit.address.zipcode.length !== 0) {
            setState({
                ...state,
                loader: true
            })
            getZipcodeInfo(player.edit.address.zipcode)(dispatch)
        }
    }

    const handleSelectGeoSuggest = (place) => {
        if (place) {
            var componentForm = {
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name',
            };

            for (var i = 0; i < place.gmaps.address_components.length; i++) {
                var addressType = place.gmaps.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.gmaps.address_components[i][componentForm[addressType]];
                    if (val)
                        componentForm[addressType] = val
                }
            }
            var location = {
                streetAddress: place.gmaps.formatted_address,
                city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
                stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
                postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
                latitude: place.location.lat || 42.2538048,
                longitude: place.location.lng || -83.5735806
            }

            if (location.stateRegion) {
                setPlayer({
                    ...player,
                    edit: {
                        ...player.edit,
                        address: {
                            ...player.edit.address,
                            state: location.stateRegion,
                            city: location.city,
                            zipcode: location.postalCode
                        }
                    },
                    isEditZipcode : location.postalCode ? false : true
                });
            } else {
                commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
                setPlayer({
                    ...player,
                    edit: {
                        ...player.edit,
                        address: {
                            ...player.edit.address,
                            state: "",
                            zipcode: "",
                        }
                    },
                    isEditZipcode: false
                });
            }
        } else {
            setPlayer({
                ...player,
                edit: {
                    ...player.edit,
                    address: {
                        ...player.edit.address,
                        city: "",
                    }
                }
            });
        }
    }

    return (
        <>
            <div className={`form_section ${player.IsClaimAcc ? 'disable' : ''}`}>
                <form>
                    {/* <div className='player_name'>
                <p>#9  JOHN DOE</p>
            </div> */}
                    <div className="dragdrop-img">
                        <div className="create_event_fileUpload file_custom_upload text-center">
                            <i className="fas fa-cloud-upload-alt"></i>
                            {!player.edit.profileUrl && <p> Click to upload profile picture</p>}
                            {/* .heic,.HEIC */}
                            <input name="logofile" type="file" className="img_file_input" onClick={(ev) => { ev.target.value = null }} aaccept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={changeImage} disabled={player.IsClaimAcc} />

                            {player.edit.profileUrl && <div className="preview_img">
                                <i className="fas fa-times" onClick={player.IsClaimAcc ? "" : removeProfile}></i>
                                <img className="preview_img_inner" src={player.edit.profileUrl} />
                            </div>}
                        </div>
                    </div>
                    <div className='title'>
                        {!player.IsClaimAcc &&
                            <>
                                <h3>Already have an account? Claim your roster spot</h3>
                                <button type='button' class="btn" onClick={() => setState({ ...state, openLogin: true })}>Sign In</button>
                            </>}
                    </div>

                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">  
                            <label class="common-lable">First Name</label>
                            <input type="text" class="form-control" id="usr" name="fname" placeholder="Enter first Name" value={player.edit.fname} onChange={handleChange} disabled={player.isCoach ? player.IsClaimAcc ? true : false : true} />
                            <span className="validMsg">{validator.current.message("first name", player.edit.fname, "required", { className: "text-danger" })}</span>
                        </div>

                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Last Name</label>
                            <input type="text" class="form-control" id="usr" name="lname" placeholder="Enter last name" value={player.edit.lname} onChange={handleChange} disabled={player.isCoach ? player.IsClaimAcc ? true : false : true} />
                            <span className="validMsg">{validator.current.message("last name", player.edit.lname, "required", { className: "text-danger" })}</span>
                        </div>
                    </div>

                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Height</label>
                            <div className="serch-box">
                                <div className="form-group mb-0">
                                    <InputMask className="form-control" name="height" placeholder="Height" inputmode="numeric" onChange={handleChange} value={player.edit.height} onKeyPress={onlyNumber} autoComplete="off" disabled={player.IsClaimAcc} />
                                    {/* <span className="validMsg"> {validator.current.message("height", player.height, "required")} </span> */}
                                </div>
                                <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                                    Ft.
                                </Link>
                            </div>
                            <span className="validMsg">{validator.current.message("height", player.edit.height, "required", { className: "text-danger" })}</span>
                        </div>

                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Weight</label>
                            <div className="serch-box">
                                <div className="form-group mb-0">
                                    <input type="text" className="form-control" id="usr" name="weight" placeholder="Weight" onChange={handleChange} value={player.edit.weight} disabled={player.IsClaimAcc} />
                                    {/* <span className="validMsg">{validator.current.message("Weight", player.weight, "required")} </span> */}
                                </div>
                                <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                                    Pound
                                </Link>
                            </div>
                            <span className="validMsg">{validator.current.message("weight", player.edit.weight, "required", { className: "text-danger" })}</span>
                        </div>
                    </div>

                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Throw</label>
                            <div className="form-group mb-0">
                                <select className="custom-select" name="useHanded" value={player.edit.useHanded} onChange={handleChange} disabled={player.IsClaimAcc}>
                                    <option value="" selected disabled>Select Hand</option>
                                    <option value="right" selected={player.edit.useHanded === "right" ? "selected" : ""}>  Right  </option>
                                    <option value="left" selected={player.edit.useHanded === "left" ? "selected" : ""}> Left</option>
                                    <option value="both" selected={player.edit.useHanded === "both" ? "selected" : ""} >    Both </option>
                                </select>
                                <span className="validMsg">
                                    {validator.current.message("throw", player.edit.useHanded, "required")}
                                </span>
                            </div>
                        </div>

                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Bat</label>
                            <div className="form-group mb-0">
                                <select
                                    className="custom-select"
                                    name="batting-pos"
                                    value={player.edit['batting-pos'].length !== 0 ? player.edit['batting-pos'][0] : ''}
                                    onChange={(e) => { setPlayer({ ...player, edit: { ...player.edit, 'batting-pos': [e.target.value] } }) }}
                                    disabled={player.IsClaimAcc}>
                                    <option value="" selected disabled>Select bat</option>
                                    <option value="Left" selected={player.edit['batting-pos'].length !== 0 && player.edit['batting-pos'][0] === "Left" ? "selected" : ""}>  Left  </option>
                                    <option value="Switch" selected={player.edit['batting-pos'].length !== 0 && player.edit['batting-pos'][0] === "Switch" ? "selected" : ""}> Switch</option>
                                    <option value="Right" selected={player.edit['batting-pos'].length !== 0 && player.edit['batting-pos'][0] === "Right" ? "selected" : ""}>     Right  </option>
                                </select>
                                <span className="validMsg">
                                    {validator.current.message("bat", player.edit['batting-pos'][0], "required")}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Phone Number</label>
                            <div class="svg_icon">
                                <div class="input-group">
                                    <div class="counrty-no">
                                        <select class="custom-select" name="cCode" value={player.edit.cCode} onChange={handleChange} >
                                            <option value="+1">+1</option>
                                        </select>
                                    </div>
                                    <input type="text" class="form-control" name="phone" id="usr" maxlength="12" placeholder="Enter phone number" value={player.edit.phone} onChange={handleChange} onKeyPress={onlyNumber} disabled={player.IsClaimAcc} />
                                    <img src={imagesArr.smartphone} alt="eye" />
                                </div>
                            </div>
                            {/* <span className="validMsg">{validator.current.message("phone number", player.edit.phone, "required", { className: "text-danger" })}</span> */}
                        </div>
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Email Address</label>
                            <input type="text" class="form-control" id="usr" name="email" placeholder="Enter email address" value={player.edit.email} onChange={handleChange} disabled={player.IsClaimAcc} />
                            <span className="validMsg">{validator.current.message("email", player.edit.email, "required", { className: "text-danger" })}</span>
                        </div>
                    </div>
                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">What sport do you play ?</label>
                            <div class="check_box disabled">
                                <div class="checkbox p-0">
                                    <input type="radio" id="1" name="gameType" value="1" checked={player["game-type"] == "1" ? true : false} disabled />
                                    <label for="Baseball">Baseball </label>
                                </div>
                                <div class="checkbox mr-0 p-0">
                                    <input type="radio" id="2" name="gameType" value="2" checked={player["game-type"] === "2" ? true : false} disabled />
                                    <label for="Softball">Softball </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Jersey Number</label>
                            <input type="text" class="form-control" id="usr" name="number" placeholder="Enter jersey number" value={player.edit.number} onChange={handleChange} onKeyPress={onlyNumber} disabled={player.isCoach ? player.IsClaimAcc ? true : false : true} />
                            <span className="validMsg">{validator.current.message("number", player.edit.number, "required", { className: "text-danger" })}</span>
                        </div>
                    </div>
                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Primary Position</label>
                            <select class="custom-select" name="position" value={player.edit.position} onChange={handleChange} disabled={player.IsClaimAcc}>
                                <option value="" disabled="">Select position</option>
                                <option value="Pitcher">Pitcher</option>
                                <option value="Catcher">Catcher</option>
                                <option value="1B">1B</option>
                                <option value="2B">2B</option>
                                <option value="3B">3B</option>
                                <option value="SS">SS</option>
                                <option value="Infield">Infield</option>
                                <option value="Outfield">Outfield</option>
                                <option value="Utility">Utility</option>
                            </select>
                            <span className="validMsg">{validator.current.message("position", player.edit.position, "required", { className: "text-danger" })}</span>
                        </div>
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Birthdate</label>
                            <div className="bc_relative">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={ref}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                    {years.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                            </div>
                                        )}
                                        selected={player.edit.dob ? new Date(player.edit.dob) : player.edit.dob}
                                        maxDate={moment().subtract(1, 'days').toDate()}
                                        //   onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(date) => setPlayer({ ...player, edit: { ...player.edit, dob: date } })}
                                        className="form-control"
                                        placeholderText="Enter birth date"
                                        disabled={player.IsClaimAcc ? true : player.isDOBUpdate}
                                    />
                                    <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                        player.opencal = !player.opencal;
                                        ref.current.setOpen(player.opencal);
                                    }} />
                                </div>
                                <span className="validMsg">{validator.current.message("birth date", player.edit.dob, "required", { className: "text-danger" })}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex-align row">
                            <div class="form-group w-50 mb-0">
                                <label class="common-lable">Are you in high school?</label>
                                <div className="check_box d-flex">
                                    <div className="checkbox p-0">
                                        <input
                                            type="radio"
                                            id=""
                                            name="in-high-school"
                                            value="yes"
                                            checked={player.edit['in-high-school'] === "yes" && true}
                                            onChange={handleChange}
                                            disabled={player.IsClaimAcc}
                                        />
                                        <label htmlFor="Baseball">Yes </label>
                                    </div>
                                    <div className="checkbox mr-0 p-0">
                                        <input
                                            type="radio"
                                            id=""
                                            name="in-high-school"
                                            value="no"
                                            checked={player.edit['in-high-school'] === "no" && true}
                                            onChange={handleChange}
                                            disabled={player.IsClaimAcc}
                                        />
                                        <label htmlFor="Softball">No </label>
                                    </div>
                                    {/* <span className="validMsg">{validator.current.message('high school', player['in-high-school'], 'required')}</span> */}
                                </div>
                            </div>

                            <div class="form-group w-50 mb-0">
                                <label class="common-lable">Secondary Position</label>
                                <select class="custom-select" name="position2" value={player.edit.position2} onChange={handleChange} disabled={player.IsClaimAcc}>
                                    <option value="">N/A</option>
                                    <option value="Pitcher">Pitcher</option>
                                    <option value="Catcher">Catcher</option>
                                    <option value="1B">1B</option>
                                    <option value="2B">2B</option>
                                    <option value="3B">3B</option>
                                    <option value="SS">SS</option>
                                    <option value="Infield">Infield</option>
                                    <option value="Outfield">Outfield</option>
                                    <option value="Utility">Utility</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex-align row">
                            <div className="form-group w-50 mb-0">
                                <label className="common-lable">City <span>( To Look Up State and Zipcode )</span></label>
                                <div className="serch-box">
                                    {/* <input
                                        type="text"
                                        className="form-control"
                                        id="zipcode"
                                        placeholder="Zip Code"
                                        name="zipcode"
                                        maxLength={9}
                                        value={player.edit.address.zipcode}
                                        onChange={handleChange}
                                    />
                                    <Link onClick={() => getZipcode()} className="serch-btn"> Next</Link> */}
                                    <Geosuggest
                                        // ref={el => this._geoSuggest = el}
                                        onSuggestSelect={handleSelectGeoSuggest}
                                        initialValue={player.edit.address.city || ""}
                                        autoComplete="off"
                                        types={['(cities)']}
                                    />
                                    <span className="validMsg">{validator.current.message('City', player.edit.address.city, 'required')}</span>
                                </div>
                            </div>

                            <div className="form-group  w-25 mb-0">
                                <label className="common-lable">State</label>

                                <select className="custom-select" name="state" value={player.edit.address.state} onChange={handleChange}>
                                    <option value="">N/A</option>
                                    {Object.keys(StatesObj).map((key) => {
                                        return (
                                            <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                                        )
                                    })}
                                </select>

                                {/*                     
                                <input
                                    type="text"
                                    className="form-control"
                                    id="state"
                                    value={player.edit.address.state}
                                    placeholder="State"
                                    onChange={handleChange}
                                    name="state"
                                    disabled
                                /> */}
                                <span className="validMsg">{validator.current.message('State', player.edit.address.state, 'required')}</span>

                            </div>

                            <div className="form-group w-25 mb-0">
                                <label className="common-lable">Zip Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="zipcode"
                                    name="zipcode"
                                    value={player.edit.address.zipcode}
                                    placeholder="Zip code"
                                    onChange={handleChange}
                                    disabled={player.isEditZipcode ? false : true}
                                />
                                <span className="validMsg">{validator.current.message('zipcode', player.edit.address.zipcode, 'required')}</span>
                            </div>
                        </div>

                        {player.edit['in-high-school'] === "yes" && calculate_age(player.edit.dob) &&
                            <>
                                {/* <div className="form-group w-50 mb-0">
                                    <label className="common-lable">What is your current GPA?</label>
                                    <select className="custom-select" name="curr-gpa" value={player.edit["curr-gpa"]} onChange={handleChange}>
                                        <option selected="selected" id="0" disabled>Select GPA</option>
                                        <option id="2.5-2.9" value="2.5-2.9">{"2.5-2.9"}</option>
                                        <option id="3.0-3.4" value="3.0-3.4">{"3.0-3.4"}</option>
                                        <option id="3.5-3.9" value="3.5-3.9">{"3.5-3.9"}</option>
                                        <option id="4.0-4.4" value="4.0-4.4">{"4.0-4.4"}</option>
                                        <option id="4.5-5.0" value="4.5-5.0">{"4.5-5.0"}</option>
                                    </select>
                                    <span className="validMsg">
                                        {this.schoolValidator.message("Throw", player["curr-gpa"], "required")}
                                    </span>
                                </div> */}

                                <div className="form-group   mb-0">
                                    <label className="common-lable">What year do you graduate high school?</label>
                                    <select name="gra-year" className="custom-select" value={player.edit["gra-year"]} onChange={handleChange} disabled={player.IsClaimAcc}>
                                        <option selected="selected" id="0" value='' disabled>Select Grade Year</option>
                                        {
                                            options.map((year, index) => {
                                                return <option key={`year${index}`} value={year}>{year}</option>
                                            })
                                        }
                                    </select>
                                    <span className="validMsg">
                                        {/* {this.schoolValidator.message("Year", player["gra-year"], "required")} */}
                                    </span>
                                </div>


                                <div class="flex-align row">
                                    <div className="form-group w-33 p-0-14">
                                        <label className="common-lable">GPA </label>
                                        <input className="form-control" type="text" name="curr-gpa" placeholder="GPA" onChange={handleChange} value={player.edit["curr-gpa"] ? player.edit["curr-gpa"] : ""} disabled={player.IsClaimAcc} />
                                        {/* <span className="validMsg">
                              {this.schoolValidator.message("GPA", player["curr-gpa"], "required|numeric")}
                            </span> */}
                                    </div>
                                    <div className="form-group w-33 p-0-14">
                                        <div className="form-group">
                                            <label className="common-lable">SAT</label>
                                            <input className="form-control" type="text" name="sat" placeholder="SAT" onChange={handleChange} value={player.edit.sat} disabled={player.IsClaimAcc} />
                                            {/* <span className="validMsg">
                              {this.schoolValidator.message("sat", player.sat, "required|numeric")}
                            </span> */}
                                        </div>
                                    </div>
                                    <div className="form-group w-33 p-0-14">
                                        <div className="form-group">
                                            <label className="common-lable">ACT</label>
                                            <input className="form-control" type="text" name="act" placeholder="ACT" onChange={handleChange} value={player.edit.act} disabled={player.IsClaimAcc} />
                                            {/* <span className="validMsg">
                              {this.schoolValidator.message("act", player.act, "required|numeric")}
                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {(player.edit['in-high-school'] === "yes" && calculate_age_between(player.edit.dob)) &&
                            <>
                                <div class="form-group mb-0">
                                    <label className="common-lable">Do you want to play (baseball/softball) in college?</label>
                                    <div className="check_box disabled">
                                        <div className="checkbox">
                                            <input
                                                type="radio"
                                                id=""
                                                name="allowRecruting"
                                                value="yes"
                                                checked={player['allowRecruting'] === "yes" && true}
                                                onChange={handleChange}
                                                disabled={player.IsClaimAcc}
                                            />
                                            <label htmlFor="Baseball">Yes </label>
                                        </div>
                                        <div className="checkbox mr-0">
                                            <input
                                                type="radio"
                                                id=""
                                                name="allowRecruting"
                                                value="no"
                                                checked={player['allowRecruting'] === "no" && true}
                                                disabled={player.IsClaimAcc ? true : player.edit['recruiting'] === "yes" ? true : false}
                                                onChange={handleChange}
                                            />
                                            <label style={{ backgroundColor: player.edit['recruiting'] === "yes" ? "#E5E9F2" : "" }} htmlFor="Softball">No </label>
                                        </div>
                                    </div>
                                </div>

                            </>
                        }

                        {
                            (player.edit['in-high-school'] === "yes" && calculate_age_between(player.edit.dob)) &&
                            player['allowRecruting'] === "yes" &&
                            <div class="form-group mb-0">
                                <div className='player-basic-info add-player'>
                                    <div className='player-info'>
                                        <img src={imagesArr.ncsaLogoFullColor} />
                                        <p><b>Prospect Wire teams with Next College Student Athlete to help athletes with the dream of competing in college.</b></p>
                                    </div>
                                    <ul>
                                        <li><p>Receive a <b>FREE recruiting profile</b> visible to over 35,000 college coaches </p></li>
                                        <li><p>An NCSA recruiting expert will reach out to offer a <b>FREE recruiting assessment</b></p></li>
                                        <li><p>Receive a <b>FREE recruiting game plan</b> personalized to your child's abilities, wants and needs</p></li>
                                        <li><p>By clicking “yes” and submitting, you agree to receive personalized follow-up and marketing messages from NCSA by email, phone and automated text. Consent is not a condition of purchase. Standard rates apply.</p></li>
                                    </ul>
                                </div>
                            </div>
                        }

                    </div>
                    <div className='mt-15'>
                        <a class="btn light-green" onClick={onSave} disabled={player.IsClaimAcc}>Save</a>
                    </div>
                </form>
            </div>


        </>
    )
}

export default Player

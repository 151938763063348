
export const GET_LICENSE_REQUEST = 'GET_LICENSE_REQUEST';
export const GET_LICENSE_SUCCESS = 'GET_LICENSE_SUCCESS';
export const GET_LICENSE_ERROR   = 'GET_LICENSE_ERROR';

export const UP_STATUS_REQUEST = 'UP_STATUS_REQUEST';
export const UP_STATUS_SUCCESS = 'UP_STATUS_SUCCESS';
export const UP_STATUS_ERROR   = 'UP_STATUS_ERROR';

export const ADD_LICENSE_REQUEST = 'ADD_LICENSE_REQUEST';
export const ADD_LICENSE_SUCCESS = 'ADD_LICENSE_SUCCESS';
export const ADD_LICENSE_ERROR   = 'ADD_LICENSE_ERROR';

export const UPDATE_LICENSE_REQUEST = 'UPDATE_LICENSE_REQUEST';
export const UPDATE_LICENSE_SUCCESS = 'UPDATE_LICENSE_SUCCESS';
export const UPDATE_LICENSE_ERROR   = 'UPDATE_LICENSE_ERROR';

export const DELETE_LICENSE_REQUEST = 'DELETE_LICENSE_REQUEST';
export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE_SUCCESS';
export const DELETE_LICENSE_ERROR   = 'DELETE_LICENSE_ERROR';

export const GET_LICENSE_NAME_REQUEST = 'GET_LICENSE_NAME_REQUEST';
export const GET_LICENSE_NAME_SUCCESS = 'GET_LICENSE_NAME_SUCCESS';
export const GET_LICENSE_NAME_ERROR   = 'GET_LICENSE_NAME_ERROR';

export const GET_LICENSE_PLAYER_REQUEST = 'GET_LICENSE_PLAYER_REQUEST';
export const GET_LICENSE_PLAYER_SUCCESS = 'GET_LICENSE_PLAYER_SUCCESS';
export const GET_LICENSE_PLAYER_ERROR   = 'GET_LICENSE_PLAYER_ERROR';

import React, { Component } from 'react'
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';

class productList extends Component {
    render() {
        return (
            <><div className="product-list-group">
                <div className="product-list">
                    <div className="product-list-box">
                        {/* <div className="product-list-img">
                            <img src={imagesArr.productlist}/>
                            <div className="lock-tag">
                                <img src={imagesArr.lock_icon}/>
                            </div>
                        </div> */}
                        <div className="product-list-info">
                            {/* <h4>BC Scouting</h4> */}
                            <img src={imagesArr.dc_logo_black} className="dimond_connect_logo"/>
                            <Link to="/" className="btn">Learn More</Link>
                        </div>
                    </div>
                    <div className="product-list-box">
                        {/* <div className="product-list-img">
                            <img src={imagesArr.productlist}/>
                            <div className="lock-tag">
                                <img src={imagesArr.lock_icon}/>
                            </div>
                        </div> */}
                        <div className="product-list-info">
                            <img src={imagesArr.menu_bcteam}/>
                            <Link to="/bcteam" className="btn">Learn More</Link>
                        </div>
                    </div>
                    <div className="product-list-box">
                        {/* <div className="product-list-img">
                            <img src={imagesArr.productlist}/>
                            <div className="lock-tag">
                                <img src={imagesArr.lock_icon}/>
                            </div>
                        </div> */}
                        <div className="product-list-info">
                            <img src={imagesArr.menu_ballr}/>
                            <Link to="/ballr" className="btn">Learn More</Link>
                        </div>
                    </div>
                    <div className="product-list-box">
                        {/* <div className="product-list-img">
                            <img src={imagesArr.productlist}/>
                            <div className="lock-tag">
                                <img src={imagesArr.lock_icon}/>
                            </div>
                        </div> */}
                        <div className="product-list-info">
                            <img src={imagesArr.menu_analyzr}/>
                            <Link to="/analyzr" className="btn">Learn More</Link>
                        </div>
                    </div>
                    <div className="product-list-box">
                        {/* <div className="product-list-img">
                            <img src={imagesArr.productlist}/>
                            <div className="lock-tag">
                                <img src={imagesArr.lock_icon}/>
                            </div>
                        </div> */}
                        <div className="product-list-info">
                            <img src={imagesArr.menu_pitchr}/>
                            <Link to="/pitchr" className="btn">Learn More</Link>
                        </div>
                    </div>
                    
                </div>
                </div>
            </>
        )
    }
}

export default productList;

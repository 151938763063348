import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../../components/leftAuthImage';
import CommonHeroBanner from '../../components/commonHeroBanner';
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getVerifyProfile } from '../../saga/actions/authantication/verify.action';
import { setRole } from '../../saga/actions/authantication/signup/step3.action';
import Preloader from '../../components/preloader';
import Cookies from 'js-cookie';
import { commonPagesContent } from '../../businesslogic/content';

class step3 extends Component {

    constructor(props) {

        super(props);
        this.state = {
            role: localStorage.getItem("tempRole") ? localStorage.getItem("tempRole") === "" ? "Coach" : localStorage.getItem("tempRole") : "Coach",
            fname: "",
            fullname: "",
            loader: false
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.setLoader(true);
        this.props.getVerifyProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.verifyData &&
            nextProps.verifyData.verifyProfileReq &&
            nextProps.verifyData.verifyProfileData
        ) {
            nextProps.verifyData.verifyProfileReq = false;
            this.setState({
                fname: nextProps.verifyData.verifyProfileData.result.fname,
                fullname: nextProps.verifyData.verifyProfileData.result.fname + " " + nextProps.verifyData.verifyProfileData.result.lname
            }, () => {
                this.setLoader(false);
                nextProps.verifyData.verifyProfileData = null
            })
        }

        if (nextProps.setRoleData &&
            nextProps.setRoleData.roleReq &&
            nextProps.setRoleData.roleData
        ) {
            nextProps.setRoleData.roleReq = false
            if (!nextProps.setRoleData.roleData.result.isCompleted) {
                localStorage.setItem("webglToken", localStorage.getItem("authToken"));
                Cookies.set('bcSession', localStorage.getItem("authToken"), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
                if (nextProps.setRoleData.roleData.result.role === "Coach") {
                    this.commonCall.setAuthToken("coachToken", localStorage.getItem("authToken"), flag => {
                        if (flag) {
                            localStorage.removeItem("authToken");
                            this.setLoader(false);
                            nextProps.setRoleData.roleData = null;
                            this.props.history.push('/coach-signup4');
                        }
                    });
                } else if (nextProps.setRoleData.roleData.result.role === "Player") {
                    this.commonCall.setAuthToken("playerToken", localStorage.getItem("authToken"), flag => {
                        if (flag) {
                            localStorage.removeItem("authToken");
                            this.setLoader(false);
                            nextProps.setRoleData.roleData = null;
                            this.props.history.push('/player-signup4');
                        }
                    });
                } else {
                    this.commonCall.setAuthToken("fanToken", localStorage.getItem("authToken"), flag => {
                        if (flag) {
                            localStorage.removeItem("authToken");
                            this.setLoader(false);
                            nextProps.setRoleData.roleData = null;
                            this.props.history.push('/fan-signup4');
                        }
                    });
                }
            }
        }

    }

    setRole = () => {
        this.setLoader(true);
        this.props.setRole({ role: this.state.role })
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    setText = (key) => {
        if (key === null) {
            key = "bc";
        }
        var isText = "";
        commonPagesContent && commonPagesContent.signup.length > 0 &&
            commonPagesContent.signup.forEach((data) => {
                if (data.key === key) return (isText = data);
            });
        return isText;
    }

    render() {
        const { loader } = this.state;
        let resData = this.setText(localStorage.getItem('company'));
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>{resData.title} <span>{this.state.fname},</span></h3>
                                    <p>Hey, {this.state.fullname} {resData.desc}</p>
                                </div>
                                <div className="select_team">
                                    <div className="radiobox">
                                        <input type="radio" id="Coach" name="role" value="Coach" onClick={localStorage.getItem("tempRole") ? localStorage.getItem("tempRole") === "" ? () => this.setState({ role: 'Coach' }) : "" : () => this.setState({ role: 'Coach' })} checked={this.state.role === "Coach" ? true : false} />
                                        <label for="Coach">Coach or Manager</label>
                                    </div>
                                    <div className="radiobox">
                                        <input type="radio" id="Player" name="role" value="Player" onClick={localStorage.getItem("tempRole") ? localStorage.getItem("tempRole") === "" ? () => this.setState({ role: 'Player' }) : "" : () => this.setState({ role: 'Player' })} checked={this.state.role === "Player" ? true : false} />
                                        <label for="Player">Player</label>
                                    </div>
                                    <div className="radiobox mb-0">
                                        <input type="radio" id="Fan" name="role" value="Fan" onClick={localStorage.getItem("tempRole") ? localStorage.getItem("tempRole") === "" ? () => this.setState({ role: 'Fan' }) : "" : () => this.setState({ role: 'Fan' })} checked={this.state.role === "Fan" ? true : false} />
                                        <label for="Fan">Parent, Fan, Individual</label>
                                    </div>
                                </div>

                                <Link className="btn" onClick={() => this.setRole()}>Next</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}


step3.propTypes = {
    getVerifyProfile: PropTypes.func.isRequired,
    setRole: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
    setRoleData: state.setRole,
});

export default connect(mapStateToProps, { getVerifyProfile, setRole })(step3);

import axios from 'axios';
import { Auth_Player_Key, API_Url, Authorization, checkIsValidPlayerToken } from '../../../../helpers/authToken';
import {
    GET_QUESTION_REQUEST,
    GET_QUESTION_ERROR,
    GET_QUESTION_SUCCESS,

    POST_QUESTION_ERROR,
    POST_QUESTION_REQUEST,
    POST_QUESTION_SUCCESS
} from '../../../../constants/authantication/signup/player/question.const';
import histrory from '../../../../../History';

export const savePlayerQuestion = (data) => dispatch => {
    console.log("data:----action:---", data);
    checkIsValidPlayerToken(histrory)
    dispatch({ type: POST_QUESTION_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/process/player/score`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json',
        }
    }).then(res => {
        dispatch({
            type: POST_QUESTION_SUCCESS,
            payload: res.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: POST_QUESTION_ERROR, error: error.response.data })
    });
};

export const getQuestionList = () => dispatch => {
    checkIsValidPlayerToken(histrory)
    dispatch({ type: GET_QUESTION_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/registration/process/player/score`,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(res => {
        console.log("Question:----", res);
        dispatch({
            type: GET_QUESTION_SUCCESS,
            payload: res.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: GET_QUESTION_ERROR, error: error.response.data })
    });
};


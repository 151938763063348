
var Auth_Key = localStorage.getItem('authToken') !== null ? localStorage.getItem('authToken') : null;
var Auth_Coach_Key = localStorage.getItem('coachToken') !== null ? localStorage.getItem('coachToken') : null;
var Auth_Player_Key = localStorage.getItem('playerToken') !== null ? localStorage.getItem('playerToken') : null;
var Auth_Company_Key = localStorage.getItem('webglToken') !== null ? localStorage.getItem('webglToken') : null;
var Auth_Login_Key = localStorage.getItem("loginToken") !== null ? localStorage.getItem("loginToken") : localStorage.getItem('webglToken'); 
var Auth_Fan_Key = localStorage.getItem('fanToken') !== null ? localStorage.getItem('fanToken') : null;
var Auth_Commi_Key = localStorage.getItem('commiToken') !== null ? localStorage.getItem('commiToken') : null;

var API_Url = process.env.REACT_APP_END_POINT; // process.env.REACT_APP_END_POINT // http://192.168.0.66:3018/api/ 
var EVENT_API_Url = process.env.REACT_APP_END_POINT;
const Authorization = (history) => {
    history.push({ pathname: '/login' })
    localStorage.removeItem("coachToken");
    localStorage.removeItem("playerToken");
    localStorage.removeItem("fanToken");
    localStorage.removeItem("commiToken");
    localStorage.removeItem("authToken");
    localStorage.removeItem("webglToken");
    localStorage.removeItem("loginToken")
    window.location.reload()
}

const checkIsValidToken = (history) => {
    if (localStorage.getItem('authToken') === null) {
        history.push({ pathname: '/login' });
        localStorage.removeItem("authToken");
        window.location.reload()
    }
    else {
        Auth_Key = localStorage.getItem('authToken') !== null ? localStorage.getItem('authToken') : null
    }
}

const checkIsValidCoachToken = (history) => {
    if (localStorage.getItem('coachToken') === null) {
        history.push({ pathname: '/login' })
        localStorage.removeItem("coachToken");
        window.location.reload()
    }
    else {
        Auth_Coach_Key = localStorage.getItem('coachToken') !== null ? localStorage.getItem('coachToken') : null
    }
}

const checkIsValidPlayerToken = (history) => {
    if (localStorage.getItem('playerToken') === null) {
        history.push({ pathname: '/login' })
        localStorage.removeItem("playerToken");
        window.location.reload()
    }
    else {
        Auth_Player_Key = localStorage.getItem('playerToken') !== null ? localStorage.getItem('playerToken') : null
    }
}

const checkIsValidCompanyToken = (history) => {
    if (localStorage.getItem('webglToken') === null) {
        history.push({ pathname: '/login' });
        localStorage.removeItem("webglToken");
        localStorage.removeItem("loginToken");
        window.location.reload()
    }
    else {
        Auth_Company_Key = localStorage.getItem('webglToken') !== null ? localStorage.getItem('webglToken') : null
        Auth_Login_Key =  localStorage.getItem("loginToken") !== null ? localStorage.getItem("loginToken") : localStorage.getItem('webglToken');
    }
}

const checkIsValidFanToken = (history) => {
    if (localStorage.getItem('fanToken') === null) {
        history.push({ pathname: '/login' })
        localStorage.removeItem("fanToken");
        window.location.reload()
    }
    else {
        Auth_Fan_Key = localStorage.getItem('fanToken') !== null ? localStorage.getItem('fanToken') : null
    }
}

const checkIsValidCommiToken = (history) => {
    if (localStorage.getItem('commiToken') === null) {
        history.push({ pathname: '/login' })
        localStorage.removeItem("commiToken");
        window.location.reload()
    }
    else {
        Auth_Commi_Key = localStorage.getItem('commiToken') !== null ? localStorage.getItem('commiToken') : null
    }
}

const checkLoginValidation = () => {
    if (localStorage.getItem('webglToken')) {
        return true;
    }
    else {
        return false;
    }
}

export {
    Auth_Key,
    Auth_Coach_Key,
    Auth_Player_Key,
    Auth_Company_Key,
    Auth_Login_Key,
    Auth_Fan_Key,
    Auth_Commi_Key,
    API_Url,
    EVENT_API_Url,
    Authorization,
    checkIsValidToken,
    checkIsValidCoachToken,
    checkIsValidPlayerToken,
    checkIsValidCompanyToken,
    checkLoginValidation,
    checkIsValidFanToken,
    checkIsValidCommiToken
}
import React, { Component } from 'react';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../../components/leftAuthImage';
import CommonHeroBanner from '../../components/commonHeroBanner';
import firebaseConnection from "../../businesslogic/firebaseConnection";
import commonCalls from "../../businesslogic/commonCalls";
import SnapChatRegister from '../../businesslogic/SnapChatRegister';
import firebase from 'firebase';
import { socialCheck } from '../../saga/actions/authantication/signup/step1.action';
import { instagramLogin } from '../../saga/actions/common/api.action';
import Preloader from '../../components/preloader';
import Cookies from 'js-cookie';
import { commonPagesContent } from '../../businesslogic/content';

class step1 extends Component {

    constructor(props) {

        super(props);
        this.state = {
            firebaseObj: "",
            loader: false
        }

        this.firebaseApi = new firebaseConnection();
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        this.setLoader(true);
        this.firebaseApi.firebaseConnect();
        this.commonCall.removeStorage("socialMedia");
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setLoader(false);
        }, 1000);
        // this.getInstagramCode();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.socialData &&
            nextProps.socialData.socialReq &&
            nextProps.socialData.socialData
        ) {
            nextProps.socialData.socialReq = false;
            let productType = nextProps.socialData.socialData.result.products.some((item) => {
                return item === "DC"
            });

            localStorage.setItem("webglToken", nextProps.socialData.socialData.result.token);
            Cookies.set('bcSession', nextProps.socialData.socialData.result.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

            if (productType) {
                let setToken = nextProps.socialData.socialData.result.role === "Coach" ? "coachToken" :
                    nextProps.socialData.socialData.result.role === "Player" ? "playerToken" : "authToken";

                this.commonCall.setAuthToken(setToken, nextProps.socialData.socialData.result.token, flag => {
                    if (flag) {
                        this.setLoader(false);
                        if (nextProps.socialData.socialData.result.role === "Coach") {
                            this.commonCall.successMessage("Login Successfully");
                            this.props.history.push('/coach-dashboard');
                        } else if (nextProps.socialData.socialData.result.role === "Player") {
                            this.commonCall.successMessage("Login Successfully");
                            this.props.history.push('/player-dashboard');
                        } else if (nextProps.socialData.socialData.result.role === "Fan") {
                            this.commonCall.errorMessage("Invalid login details");
                        } else {
                            this.props.history.push('/signup3');
                        }
                    }
                });
                nextProps.socialData.socialData = null;
                nextProps.socialData.actionData = null;
            } else {
                let setToken = nextProps.socialData.socialData.result.role === "Coach" ? "coachToken" :
                    nextProps.socialData.socialData.result.role === "Player" ? "playerToken" : "authToken";

                this.commonCall.setAuthToken(setToken, nextProps.socialData.socialData.result.token, flag => {
                    if (flag) {
                        this.setLoader(false);
                        if (nextProps.socialData.socialData.result.role === "Coach") {
                            this.commonCall.successMessage("Login Successfully");
                            this.props.history.push('/coach-signup4');
                        } else if (nextProps.socialData.socialData.result.role === "Player") {
                            this.commonCall.successMessage("Login Successfully");
                            this.props.history.push('/player-signup4');
                        } else if (nextProps.socialData.socialData.result.role === "Fan") {
                            this.commonCall.errorMessage("Invalid login details");
                        } else {
                            this.commonCall.setStorage("socialMedia", nextProps.socialData.actionData, flag => {
                                if (flag) {
                                    this.props.history.push('/signup2');
                                }
                            });
                        }
                    }
                });
                nextProps.socialData.socialData = null;
                nextProps.socialData.actionData = null;
            }
        }

        if (nextProps.socialData &&
            nextProps.socialData.socialReq &&
            nextProps.socialData.socialDataError
        ) {
            nextProps.socialData.socialReq = false;
            this.commonCall.setStorage("socialMedia", nextProps.socialData.actionData, flag => {
                if (flag) {
                    this.setLoader(false);
                    nextProps.socialData.socialData = null;
                    nextProps.socialData.actionData = null;
                    this.props.history.push('/signup2');
                }
            });
        }

        if (nextProps.commonData &&
            nextProps.commonData.instagramReq &&
            nextProps.commonData.instagramData
        ) {
            nextProps.commonData.instagramReq = false;
            let data = {
                fname: "",
                lname: "",
                email: "",
                socialId: nextProps.commonData.instagramData.data.user_id,
                type: 4
            }
            this.props.socialCheck(data);
            nextProps.commonData.instagramData = null;
        }
    }

    // getInstagramCode = () => {
    //     let url = new URL(document.location);
    //     if (url.search !== "") {
    //         let getPath = url.search.split("=")[1];
    //         let data = {
    //             client_id: this.commonCall.instagramCred.client_id,
    //             client_secret: this.commonCall.instagramCred.client_secret,
    //             grant_type: "authorization_code",
    //             redirect_uri: this.commonCall.instagramCred.redirect_regi_uri,
    //             code: getPath
    //         }
    //         this.setLoader(true);
    //         this.props.instagramLogin(data)
    //     }
    // }

    socialMediaLogin = (data) => {
        let self = this;
        if (data === 'fb') {
            var provider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(provider).then(function (result) {
                let name = result.user.displayName.split(" ");
                let fdata = {
                    fname: name[0] ? name[0] : "",
                    lname: name[1] ? name[1] : "",
                    email: result.user.email ? result.user.email : "",
                    socialId: result.user.uid,
                    type: 1
                }
                self.setLoader(true);
                self.props.socialCheck(fdata);
            }).catch(function (error) {
            });
        }
        // else if (data === "insta") {
        //     let url = "https://api.instagram.com/oauth/authorize?client_id=" + this.commonCall.instagramCred.client_id +
        //         "&redirect_uri=" + encodeURIComponent(this.commonCall.instagramCred.redirect_regi_uri) + "&scope=user_profile&response_type=code";
        //     window.open(url, '_blank');
        // } else {
        //     // Snapchat code
        //     let name = data.me.displayName.split(" ");
        //     let fdata = {
        //         fname: name[0] ? name[0] : "",
        //         lname: name[1] ? name[1] : "",
        //         email: "",
        //         socialId: data.me.externalId,
        //         type: 5
        //     }
        //     this.setLoader(true);
        //     this.props.socialCheck(fdata);
        // }
    }

    setText = (key) => {
        if (key === null) {
            key = "bc";
        }
        console.log("key:---", key);
        var isText = "";
        commonPagesContent && commonPagesContent.socialmedia.length > 0 &&
            commonPagesContent.socialmedia.forEach((data) => {
                if (data.key === key) return (isText = data);
            });
        return isText;
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state;
        let resData = this.setText(localStorage.getItem('company'));
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec socialmedia-login">
                                <div className="heading-for">
                                    <h3>{resData.title}</h3>
                                    <p>{resData.desc}</p>
                                    {/* <p className="p-black">If you already have an account please log in below.</p> */}
                                    {/* <p className="login_alert_text">Please sign up using your email. Social sign up will be available shortly upon approval.</p> */}
                                </div>
                                <div className="login-btn">
                                    <Link onClick={() => this.socialMediaLogin('fb')} className="facebook social_btn w-100"><img src={imagesArr.fb} alt="fb" />Facebook</Link>
                                    {/* <Link onClick={() => this.socialMediaLogin('insta')} className="insta social_btn w-100"><img src={imagesArr.insta} alt="insta" />Instagram</Link>
                                    <SnapChatRegister snapLogin={this.socialMediaLogin} load={this.setLoader} /> */}
                                </div>
                                <div className="header_align">
                                    <Link className="btn" to={'/login'}>Sign In</Link>
                                    <Link className="btn trans" to={'/signup2'}>Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

step1.propTypes = {
    socialCheck: PropTypes.func.isRequired,
    instagramLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    socialData: state.socialCheck,
    commonData: state.api
});

export default connect(mapStateToProps, { socialCheck, instagramLogin })(step1);

import axios from 'axios';
import { Auth_Coach_Key, Auth_Fan_Key, API_Url, checkIsValidCoachToken, checkIsValidFanToken } from '../../../../helpers/authToken';
import {
    LIST_PLAYER_REQUEST,
    LIST_PLAYER_SUCCESS,
    LIST_PLAYER_ERROR,

    LINKED_PLAYER_LIST_REQUEST,
    LINKED_PLAYER_LIST_SUCCESS,
    LINKED_PLAYER_LIST_ERROR,

    ADD_PLAYER_REQUEST,
    ADD_PLAYER_SUCCESS,
    ADD_PLAYER_ERROR,

    ADD_LINKED_PLAYER_PARENT_REQUEST,
    ADD_LINKED_PLAYER_PARENT_SUCCESS,
    ADD_LINKED_PLAYER_PARENT_ERROR,

    COMPLETE_PARENT_REQUEST,
    COMPLETE_PARENT_SUCCESS,
    COMPLETE_PARENT_ERROR
} from '../../../../constants/authantication/signup/fan/parent.const';
import histrory from '../../../../../History';

export const playerList = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: LIST_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parent/player/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: LIST_PLAYER_SUCCESS,
            payload: basic.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: LIST_PLAYER_ERROR, error: error.response.data.message })
    });
};

export const LinkedPlayerList = () => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: LINKED_PLAYER_LIST_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}bc/fan/parent/temp/link/player`,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: LINKED_PLAYER_LIST_SUCCESS,
            payload: basic.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: LINKED_PLAYER_LIST_ERROR, error: error.response.data.message })
    });
};

export const AddPlayer = (data, type) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: `ADD_${type}_PLAYER_REQUEST` })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/fan/linked/player/add`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: `ADD_${type}_PLAYER_SUCCESS`,
            payload: basic.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: `ADD_${type}_PLAYER_ERROR`, error: error.response.data.message })
    });
};

export const AddLinkedPlayerByFan = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: ADD_LINKED_PLAYER_PARENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parent/linked/player/add`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: ADD_LINKED_PLAYER_PARENT_SUCCESS,
            payload: basic.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: ADD_LINKED_PLAYER_PARENT_ERROR, error: error.response.data.message })
    });
};

export const saveParentComplete = (data) => dispatch => {
    checkIsValidFanToken(histrory)
    dispatch({ type: COMPLETE_PARENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/parent/complete`,
        data: data,
        headers: {
            'x-auth-token': Auth_Fan_Key,
            'Accept': 'application/json'
        }
    }).then(basic => {
        dispatch({
            type: COMPLETE_PARENT_SUCCESS,
            payload: basic.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: COMPLETE_PARENT_ERROR, error: error.response.data.message })
    });
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import { imagesArr } from '../../../assets/images';
import { Link } from 'react-router-dom';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import { updateTeam } from '../../../saga/actions/authantication/signup/player/coach.action';
import { subCoachList } from '../../../saga/actions/views/player/profile/coachInfo.action';
import commonCalls from "../../../businesslogic/commonCalls";

class coachInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamArr: [],
            selectedId: "",
            filter: {
                parentTeam: "",
                page: 0,
                limit: 0,
                search: ""
            },
            team: "",
            loader: false,
        }
        this.commonCall = new commonCalls();
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.commonCall.getStorage("team", data => {
            if (data !== null) {
                this.setState({
                    filter: {
                        parentTeam: data,
                        page: 0,
                        limit: 0,
                        search: ""
                    }
                }, () => {
                    this.getCoach();
                    this.setLoader(true);
                    this.props.subCoachList(this.state.filter);
                })
            }
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.coachList &&
            nextProps.coachList.searchCoachRes &&
            nextProps.coachList.searchCoachData
        ) {
            console.log("nextProps.coachList.searchCoachData:---", nextProps.coachList.searchCoachData);
            nextProps.coachList.searchCoachRes = false;

            if (this.state.filter.search) {
                return this.setState({
                    teamArr: nextProps.coachList.searchCoachData.result.allTeam,
                }, () => {
                    nextProps.coachList.searchCoachData = null;
                    this.setLoader(false);
                })
            }

            if (nextProps.coachList.searchCoachData.result.hasOwnProperty('myTeam') && nextProps.coachList.searchCoachData.result.myTeam.length !== 0) {
                for (let i = 0; i < nextProps.coachList.searchCoachData.result.myTeam.length; i++) {
                    nextProps.coachList.searchCoachData.result.allTeam.unshift(nextProps.coachList.searchCoachData.result.myTeam[i])
                }

                console.log("nextProps.coachList.searchCoachData.result.myTeam:--", nextProps.coachList.searchCoachData.result.myTeam);
                this.setState({
                    teamArr: nextProps.coachList.searchCoachData.result.allTeam,
                    selectedId: nextProps.coachList.searchCoachData.result.myTeam[0]._id,
                    team: nextProps.coachList.searchCoachData.result.myTeam[0]._id,
                }, () => {
                    nextProps.coachList.searchCoachData = null;
                    this.setLoader(false);
                })
            } else {
                this.setState({
                    teamArr: nextProps.coachList.searchCoachData.result.allTeam
                }, () => {
                    nextProps.coachList.searchCoachData = null;
                    this.setLoader(false);
                });
            }
        }

        if (nextProps.coachDetails &&
            nextProps.coachDetails.upCoachRes &&
            nextProps.coachDetails.upCoachData
        ) {
            console.log("nextProps.coachDetails.upCoachData:---", nextProps.coachDetails.upCoachData);
            nextProps.coachDetails.upCoachRes = false;
            if (nextProps.coachDetails.upCoachData.flag) {
                this.commonCall.successMessage(nextProps.coachDetails.upCoachData.message);
                nextProps.coachDetails.upCoachData = null;
                this.setLoader(false);
                localStorage.removeItem("team");
                localStorage.removeItem("coach");
                this.props.history.push('/player-dashboard');
            } else {
                this.commonCall.errorMessage(nextProps.coachDetails.upCoachData.message);
                nextProps.coachDetails.upCoachData = null;
                this.setLoader(false);
            }
        }
    }

    getCoach = () => {
        this.commonCall.getStorage("coach", data => {
            if (data !== null) {
                this.setState({
                    selectedId: data,
                    team: data,
                })
            }
        });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    onHandleChange = (e) => {
        console.log("this.state.filter.parentTeam:--", this.state.filter.parentTeam);
        const { name, value } = e.target;
        if (name === "team") {
            this.setState((prevState) => ({
                ...prevState,
                [name]: value,
                selectedId: value
            }));
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    parentTeam: this.state.filter.parentTeam,
                    page: 1,
                    limit: 10,
                    search: value,
                },
            }), () => {
                if (value.length !== 0) {
                    this.setLoader(true);
                    this.props.subCoachList(this.state.filter);
                } else {
                    this.state.teamArr = [];
                    this.props.coachList.searchCoachData = null;
                    this.setLoader(true);
                    this.props.subCoachList(this.state.filter);
                }
            });
        }
    }

    onSearch = () => {
        this.setLoader(true);
        this.props.subCoachList(this.state.filter);
    }

    renderCoachList = () => {
        return (
            this.state.teamArr.length !== 0 ?
                this.state.teamArr.map((list, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={list.coach ? list.coach.profileUrl : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{list.coach && list.coach.fname + " " + list.coach.lname}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="radio"
                                    id="Baseball"
                                    value={list._id}
                                    name="team"
                                    onChange={(e) => this.onHandleChange(e)}
                                    checked={list._id === this.state.selectedId ? true : false}
                                />
                                <span for="Baseball"></span>
                            </div>
                        </div>
                    );
                })
                :
                <h4 style={{ textAlign: 'center' }}>No Data Found</h4>
        );
    }

    submitTeam = () => {
        if (this.state.team) {
            this.commonCall.setStorage("coach", this.state.team, flag => {
                if (flag) {
                    let data = {
                        team: this.state.team,
                        parentTeam: this.state.filter.parentTeam
                    }
                    console.log("data:---", data);
                    this.setLoader(true);
                    this.props.updateTeam(data);
                }
            });
        } else {
            this.commonCall.errorMessage("Please select coach name");
        }
    }

    render() {
        const { filter } = this.state
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="before-login leaveinfo">
                    <div className="container">
                        <div className="white-bg playes-team">
                            <div className="title-name mb-0">
                                <h2 className="mb-0">
                                    Select Your Coach
                                </h2>
                            </div>
                            <div className="add_team check_input">
                                <div className="form-group mb-0 w-100">
                                    <div className="serch-box">
                                        <input type="text" className="form-control" id="usr" placeholder="Search for your coach" name="search" value={filter.search} onChange={this.onHandleChange} />
                                        {/* <Link onClick={this.onSearch} className="serch-btn"><img src={imagesArr.search} alt="search" /></Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="scrollbar_team">
                                <div className="select_team ml-0">
                                    {this.renderCoachList()}
                                </div>
                            </div>
                            <div className="flex-align">
                                <Link className="btn gray" to={"/player-teamInfo"}>Back</Link>
                                <Link className="btn" onClick={() => this.submitTeam()}>Submit</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

coachInfo.propTypes = {
    subCoachList: PropTypes.func.isRequired,
    updateTeam: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    coachDetails: state.coachPlayerDetails,
    coachList: state.subCoachInfo
});

export default connect(mapStateToProps, { subCoachList, updateTeam })(coachInfo);
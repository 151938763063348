import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { imagesArr } from '../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../components/leftAuthImage';
import CommonHeroBanner from '../components/commonHeroBanner';
import commonCalls from "../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { resetPass } from '../saga/actions/authantication/verify.action';
import Preloader from '../components/preloader';

class reset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ptype: "password",
            cptype: "password",
            npass: "",
            cpass: "",
            token: "",
            loader: false
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        this.getToken();
    }

    componentDidMount() { 
		window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.verifyData &&
            nextProps.verifyData.resetReq &&
            nextProps.verifyData.resetData
        ) {
            nextProps.verifyData.resetReq = false;
            this.commonCall.successMessage(nextProps.verifyData.resetData.message);
            nextProps.verifyData.resetData = null;
            this.setLoader(false);
            this.props.history.push('/login');
        }
    }

    getToken = () => {
        let url = new URL(document.location);
        let userToken = url.pathname.split("/")[2];
        this.setState({
            token: userToken
        });
    }

    onChangeFn = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    };

    resetData = () => {
        if (this.validator.allValid()) {
            if (this.state.npass === this.state.cpass) {
                let data = {
                    resetPasswordToken: this.state.token,
                    password: this.state.npass
                }
                this.setLoader(true);
                this.props.resetPass(data)
            } else {
                this.commonCall.errorMessage("Password must be same.");
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>Reset Password</h3>
                                    <p>Please Enter Your New Password</p>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <div className="svg_icon">
                                            <input type={this.state.ptype} name="npass" value={this.state.npass} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Enter New Password" />
                                            {this.state.ptype == "password" ? (
                                                <img src={imagesArr.eye} onClick={() => this.setState({ ptype: "text" })} alt="eye" />
                                            ) : (
                                                    <img src={imagesArr.eyeopen} onClick={() => this.setState({ ptype: "password" })} alt="eye" />
                                                )}
                                        </div>
                                        <span className="validMsg">{this.validator.message('New Password', this.state.npass, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm New Password</label>
                                        <div className="svg_icon">
                                            <input type={this.state.cptype} name="cpass" value={this.state.cpass} onChange={this.onChangeFn} className="form-control" id="usr" placeholder="Confirm New Password" />
                                            {this.state.cptype == "password" ? (
                                                <img src={imagesArr.eye} onClick={() => this.setState({ cptype: "text" })} alt="eye" />
                                            ) : (
                                                    <img src={imagesArr.eyeopen} onClick={() => this.setState({ cptype: "password" })} alt="eye" />
                                                )}
                                        </div>
                                        <span className="validMsg">{this.validator.message('Confirm New Password', this.state.cpass, 'required')}</span>
                                    </div>
                                    <div className="form-group">
                                        <Link className="btn w-100 text-center" onClick={() => this.resetData()}>Save</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

reset.propTypes = {
    resetPass: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
});

export default connect(mapStateToProps, { resetPass })(reset);

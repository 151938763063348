import {
    COMPANY_LIST_REQUEST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_ERROR,

    COM_TOP_EVENT_REQUEST,
    COM_TOP_EVENT_SUCCESS,
    COM_TOP_EVENT_ERROR,

    COM_UPCOMING_EVENT_REQUEST,
    COM_UPCOMING_EVENT_SUCCESS,
    COM_UPCOMING_EVENT_ERROR,

    COM_PAST_EVENT_REQUEST,
    COM_PAST_EVENT_SUCCESS,
    COM_PAST_EVENT_ERROR,

    COM_EVENT_STATE_REQUEST,
    COM_EVENT_STATE_SUCCESS,
    COM_EVENT_STATE_ERROR,

    COM_USER_EVENT_STATE_REQUEST,
    COM_USER_EVENT_STATE_SUCCESS,
    COM_USER_EVENT_STATE_ERROR,
} from '../../constants/common/events.const';

const initialState = {
    companyListReq: false,
    companyList: [],
    companyListError: null,

    topEventReq: false,
    topEventData: null,
    topEventError: null,

    upcomingEventReq: false,
    upcomingEventData: null,
    upcomingEventError: null,

    pastEventReq: false,
    pastEventData: null,
    pastEventError: null,

    stateEventReq: false,
    stateEventData: null,
    stateEventError: null,

    stateUserEventReq: false,
    stateUserEventData: null,
    stateUserEventError: null,

    statePastEvent: [],
    statePastEventError: null,

    tournamentList: [],
    tournamentListError: null,

    pastChampionList: [],
    pastChampionError: null,

    tournamentFilterList: [],
    tournamentFilterError: null,

    allPastChampList: [],
    allPastChampListError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // get company list
        case COMPANY_LIST_REQUEST:
            return {
                ...state,
                companyListReq: true,
            };
        case COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companyList: action.payload,
            };
        case COMPANY_LIST_ERROR:
            return {
                ...state,
                companyListError: action.error,
            };

        // top events req    
        case COM_TOP_EVENT_REQUEST:
            return {
                ...state,
                topEventReq: true,
            };

        case COM_TOP_EVENT_SUCCESS:
            return {
                ...state,
                topEventData: action.payload,
            };
        case COM_TOP_EVENT_ERROR:
            return {
                ...state,
                topEventError: action.error,
            };

        // upcoming events req    
        case COM_UPCOMING_EVENT_REQUEST:
            return {
                ...state,
                upcomingEventReq: true,
            };

        case COM_UPCOMING_EVENT_SUCCESS:
            return {
                ...state,
                upcomingEventData: action.payload,
            };
        case COM_UPCOMING_EVENT_ERROR:
            return {
                ...state,
                upcomingEventError: action.error,
            };

        // past events req    
        case COM_PAST_EVENT_REQUEST:
            return {
                ...state,
                pastEventReq: true,
            };

        case COM_PAST_EVENT_SUCCESS:
            return {
                ...state,
                pastEventData: action.payload,
            };
        case COM_PAST_EVENT_ERROR:
            return {
                ...state,
                pastEventError: action.error,
            };


        // state event req    
        case COM_EVENT_STATE_REQUEST:
            return {
                ...state,
                stateEventReq: true,
            };

        case COM_EVENT_STATE_SUCCESS:
            return {
                ...state,
                stateEventData: action.payload,
            };
        case COM_EVENT_STATE_ERROR:
            return {
                ...state,
                stateEventError: action.error,
            };

        // state user event req    
        case COM_USER_EVENT_STATE_REQUEST:
            return {
                ...state,
                stateUserEventReq: true,
            };

        case COM_USER_EVENT_STATE_SUCCESS:
            return {
                ...state,
                stateUserEventData: action.payload,
            };
        case COM_USER_EVENT_STATE_ERROR:
            return {
                ...state,
                stateUserEventError: action.error,
            };

        //State list for past champion
        case "STATE_LIST_PAST_EVENT":
            return {
                ...state,
                statePastEvent: action.payload,
            };
        case "STATE_LIST_PAST_EVENT_ERROR":
            return {
                ...state,
                statePastEventError: action.error,
            };

        //Tournament list fir past champion filter
        case "TOURNAMENT_LIST_FILTER":
            return {
                ...state,
                tournamentList: action.payload,
            };
        case "TOURNAMENT_LIST_FILTER_ERROR":
            return {
                ...state,
                tournamentListError: action.error,
            };

        //PAst Champion list
        case "PAST_CHAMP_LIST":
            return {
                ...state,
                pastChampionList: action.payload,
            };
        case "PAST_CHAMP_LIST_ERROR":
            return {
                ...state,
                pastChampionError: action.error,
            };

        //get all tournament list
        case "TOURNAMENT_FILTER_LIST":
            return {
                ...state,
                tournamentFilterList: action.payload,
            };
        case "TOURNAMENT_FILTER_LIST_ERROR":
            return {
                ...state,
                tournamentFilterError: action.error,
            };

        // all past champion list
        case "ALL_PAST_CHAMP_LIST":
            return {
                ...state,
                allPastChampList: action.payload,
            };
        case "ALL_PAST_CHAMP_LIST_ERROR":
            return {
                ...state,
                allPastChampListError: action.error,
            };

        default:
            return state;
    }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import commonCalls from "../../../businesslogic/commonCalls";
import { connect } from "react-redux";
import ReactAutocomplete from 'react-autocomplete';
import {
  fetchInfo,
  zipCodeInfo,
  postInfo,
  postPictureUpload,
} from "../../../saga/actions/views/player/profile/personalInfo.action";
import { getCountryCode } from "../../../saga/actions/common/api.action";
import { getPlayerProfile } from '../../../saga/actions/containers/header.action';
import { getCollegeInfo } from "../../../saga/actions/views/player/profile/otherInfo.action";

import Preloader from '../../../components/preloader';
import moment from "moment";
import PhoneVerify from '../../popup/phoneVerify';
import ImageCrop from '../../popup/imageCrop';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import ChangePassword from "../../popup/changePassword";
import ParentInfo from '../../../views/popup/parentInfo';
import { playerSuggetionList } from '../../../saga/actions/authantication/signup/player/collage.action';
import Geosuggest from "react-geosuggest";
import { StatesObj } from "../../../businesslogic/content";

class personalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      fullName: "",
      profilePic: "",
      profileUrl: "",
      // dob: "",
      phone: "",
      email: "",
      bio: "",
      cCode: "",
      _id: "",
      gameType: "",
      zipcode: "",
      city: "",
      state: "",
      personalLogo: "",
      loader: false,
      verifyPopup: false,
      verifyToken: "",
      uid: "",
      cropPopup: false,
      renderFile: "",
      imgObj: "",
      countryCode: [],
      opencal: false,
      isImport: false,
      "in-high-school": "no",
      "allowHighSch": "no",
      recruiting: "no",
      allowRecruting: "yes",
      "school": "",
      height: "",
      weight: "",
      useHanded: "",
      position: "",
      position2: "",
      "batting-pos": [],
      "year-exp": "",
      openChangePassword: false,
      parentPopup: false,
      parentInfo: {
        cCode: "+1",
        email: "",
        fullName: "",
        phone: ""
      },
      fname: "",
      lname: "",
      search: {
        page: 1,
        limit: 10,
        search: "",
      },
      collegeList: [],
      myCollage: {
        "fav-colleges": []
      },
      listCollage: [],
      suggestions: [],
      isDOBupdate: false,
      "curr-gpa": "",
      "gra-year": "",
      sat: "",
      act: "",
      isEditZipcode: false
    };
    let options = [];
    for (var Y = 2035; Y >= 1980; Y--) {
      options.push(Y)
    }
    this.years = options;
    this.handleChange = this.handleChange.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.schoolValidator = new SimpleReactValidator({ autoForceUpdate: this });
    this.commonCall = new commonCalls();
  }

  componentWillMount() {
    this.setLoader(true);
    this.props.fetchInfo();
    this.props.getCountryCode();
    this.props.getCollegeInfo(this.state.search);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps._PERSONALINFO.getInfoData) {
      console.log(nextProps._PERSONALINFO.getInfoData)
      if (nextProps._PERSONALINFO.getInfoData.fname) {
        let name = Object.hasOwnProperty("parentInfo") ? Object.keys(nextProps._PERSONALINFO.getInfoData.parentInfo).length ? nextProps._PERSONALINFO.getInfoData.parentInfo.fullName.split(" ") : null : null;
        this.setState({
          fname: nextProps._PERSONALINFO.getInfoData.fname,
          lname: nextProps._PERSONALINFO.getInfoData.lname,
          fullName: nextProps._PERSONALINFO.getInfoData.fname + " " + nextProps._PERSONALINFO.getInfoData.lname,
          profileUrl: nextProps._PERSONALINFO.getInfoData.profilePic && nextProps._PERSONALINFO.getInfoData.profilePic.filePath,
          personalLogo: nextProps._PERSONALINFO.getInfoData.profilePic && nextProps._PERSONALINFO.getInfoData.profilePic._id,
          dob: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("dob") ? nextProps._PERSONALINFO.getInfoData.dob ? new Date(nextProps._PERSONALINFO.getInfoData.dob) : "" : "",
          isDOBupdate: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("dob") ? nextProps._PERSONALINFO.getInfoData.dob ? true : false : false,
          phone: nextProps._PERSONALINFO.getInfoData.phone,
          email: nextProps._PERSONALINFO.getInfoData.email,
          // bio: nextProps._PERSONALINFO.getInfoData.bio,
          cCode: nextProps._PERSONALINFO.getInfoData.cCode,
          _id: nextProps._PERSONALINFO.getInfoData._id,
          gameType: nextProps._PERSONALINFO.getInfoData["game-type"],
          isEditZipcode: nextProps._PERSONALINFO.getInfoData.address && nextProps._PERSONALINFO.getInfoData.address.zipcode ? false : true,
          zipcode: nextProps._PERSONALINFO.getInfoData.address && nextProps._PERSONALINFO.getInfoData.address.zipcode,
          city: nextProps._PERSONALINFO.getInfoData.address && nextProps._PERSONALINFO.getInfoData.address.city,
          state: nextProps._PERSONALINFO.getInfoData.address && nextProps._PERSONALINFO.getInfoData.address.state,
          isImport: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("isImport") ? nextProps._PERSONALINFO.getInfoData.isImport === 1 ? true : false : false,
          "in-high-school": nextProps._PERSONALINFO.getInfoData.hasOwnProperty("in-high-school") ? nextProps._PERSONALINFO.getInfoData['in-high-school'] : "no",
          allowHighSch: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("in-high-school") ? nextProps._PERSONALINFO.getInfoData['in-high-school'] : "no",
          "batting-pos": nextProps._PERSONALINFO.getInfoData.hasOwnProperty("batting-pos") ? nextProps._PERSONALINFO.getInfoData['batting-pos'] : [],
          school: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("school") ? nextProps._PERSONALINFO.getInfoData['school'] : "",
          height: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("height") ? nextProps._PERSONALINFO.getInfoData['height'] : "",
          weight: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("weight") ? nextProps._PERSONALINFO.getInfoData['weight'] : "",
          useHanded: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("useHanded") ? nextProps._PERSONALINFO.getInfoData['useHanded'] : "",
          position: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("position") ? nextProps._PERSONALINFO.getInfoData['position'] : "",
          position2: nextProps._PERSONALINFO.getInfoData.hasOwnProperty("position2") ? nextProps._PERSONALINFO.getInfoData['position2'] : "",
          recruiting: nextProps._PERSONALINFO.getInfoData['recruiting'],
          allowRecruting: nextProps._PERSONALINFO.getInfoData['recruiting'],
          parentInfo: nextProps._PERSONALINFO.getInfoData.parentInfo,
          fname: name ? name[0] : "",
          lname: name ? name[1] ? name[1] : "" : "",
          "curr-gpa": nextProps._PERSONALINFO.getInfoData["curr-gpa"],
          "gra-year": nextProps._PERSONALINFO.getInfoData["gra-year"],
          "year-exp": nextProps._PERSONALINFO.getInfoData.hasOwnProperty("year-exp") ? nextProps._PERSONALINFO.getInfoData['year-exp'] : "",
          sat: nextProps._PERSONALINFO.getInfoData.sat,
          act: nextProps._PERSONALINFO.getInfoData.act
        },
          () => {
            nextProps._PERSONALINFO.getInfoData = null;
            this.setLoader(false);
          }
        );
      } else {
        this.setLoader(false);
      }
    }

    if (nextProps._CC.countryCodeData) {
      this.setState({
        countryCode: nextProps._CC.countryCodeData
      }, () => {
        nextProps._CC.countryCodeData = null;
        this.setLoader(false);
      })
    }

    if (nextProps._PICTUREUPLOAD) {
      console.log("nextProps._PICTUREUPLOAD:----", nextProps._PICTUREUPLOAD);
      this.setState({
        personalLogo: nextProps._PICTUREUPLOAD._id,
        profileUrl: nextProps._PICTUREUPLOAD.filePath,
        imgObj: "",
        cropPopup: false,
        renderFile: ""
      }, () => {
        document.body.classList.remove('modal-open');
        this.setLoader(false);
      })
    }

    if (nextProps._ZIPCODEINFO) {
      console.log("nextProps._ZIPCODEINFO:--", nextProps._ZIPCODEINFO);
      if (nextProps._ZIPCODEINFO.flag) {
        this.setState({
          zipcode: nextProps._ZIPCODEINFO.result.zipcodestr,
          city: nextProps._ZIPCODEINFO.result.city,
          state: nextProps._ZIPCODEINFO.result.state,
        }, () => {
          this.setLoader(false);
        });
      } else {
        this.commonCall.errorMessage(
          nextProps._ZIPCODEINFO.message
        );
        nextProps._ZIPCODEINFO.zipcodeData = null;
        this.setLoader(false);
      }
    }

    if (nextProps._POSTINFO.postInfoData) {
      console.log("nextProps._POSTINFO------>", nextProps._POSTINFO)
      if (nextProps._POSTINFO.postInfoData.flag) {
        this.commonCall.successMessage(nextProps._POSTINFO.postInfoData.message);
        if (nextProps._POSTINFO.postInfoData.result.hasOwnProperty('mobile_no_update')) {
          this.setState({
            verifyPopup: true,
            verifyToken: nextProps._POSTINFO.postInfoData.result.updatePhoneToken
          }, () => {
            nextProps._POSTINFO.postInfoData = null;
            this.setLoader(false);
          })
        } else {
          this.setLoader(false);
          nextProps._POSTINFO.postInfoData = null;
          this.props.fetchInfo();
          this.props.getPlayerProfile();
        }
        this.setState({
          collegeList: [],
          isDOBupdate: false,
          search: {
            page: 1,
            limit: 10,
            search: ""
          }
        }, () => {
          this.props.otherDetails.collegeInfoData = null;
          this.props.getCollegeInfo(this.state.search);
        })

      } else if (!nextProps._POSTINFO.postInfoData.flag) {
        this.commonCall.errorMessage(nextProps._POSTINFO.postInfoData.message);
        nextProps._POSTINFO.postInfoData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.profileDetails &&
      nextProps.profileDetails.headPlayerData
    ) {
      if (nextProps.profileDetails.headPlayerData.flag) {
        console.log("nextProps.profileDetails.headPlayerData:--", nextProps.profileDetails.headPlayerData.result);
        this.setState({
          uid: nextProps.profileDetails.headPlayerData.result._id
        })
      }
    }

    if (nextProps.otherDetails.collegeInfoData) {
      console.log("nextProps.otherDetails.collegeInfoData:---", nextProps.otherDetails.collegeInfoData);
      if (nextProps.otherDetails.collegeInfoData.myCollage.length != 0) {
        if (this.state.search.search) {
          return this.setState((prevState) => ({
            ...prevState,
            collegeList: nextProps.otherDetails.collegeInfoData.collagesList,
          }), () => {
            nextProps.otherDetails.collegeInfoData = null;
            this.setLoader(false);
          })
        }
        console.log("myCollage:---", this.state.myCollage);

        let arr = [];
        if (this.state.search.page === 1) {
          for (let i = nextProps.otherDetails.collegeInfoData.myCollage.length - 1; i >= 0; i--) {
            nextProps.otherDetails.collegeInfoData.collagesList.unshift(nextProps.otherDetails.collegeInfoData.myCollage[i])
            arr.push(nextProps.otherDetails.collegeInfoData.myCollage[i]._id);
          }
          this.setState({
            listCollage: this.state.listCollage.length === 0 ? nextProps.otherDetails.collegeInfoData.myCollage : this.state.listCollage,
            myCollage: {
              "fav-colleges": this.state.listCollage.length === 0 ? arr : this.state.myCollage['fav-colleges']
            },
          })
        }

        let listData = nextProps.otherDetails.collegeInfoData.collagesList;
        let temp = [];

        listData.map((item) => {
          temp.push(item)
        });
        var joined = this.state.collegeList.concat(temp);

        this.setState((prevState) => ({
          ...prevState,
          collegeList: joined,
          search: {
            ...prevState.search,
            page: this.state.search.page + 1
          }
        }), () => {
          nextProps.otherDetails.collegeInfoData = null;
          this.setLoader(false);
        });
      } else {
        this.setState((prevState) => ({
          ...prevState,
          collegeList: nextProps.otherDetails.collegeInfoData.collagesList,
          search: {
            ...prevState.search,
            page: this.state.search.page + 1
          }
        }), () => {
          nextProps.otherDetails.collegeInfoData = null;
          this.setLoader(false);
        })
      }
    }

    if (nextProps.collageDetails &&
      nextProps.collageDetails.searchSuggestionRes &&
      nextProps.collageDetails.searchSuggestionData
    ) {
      nextProps.collageDetails.searchSuggestionRes = false;
      let val = nextProps.collageDetails.searchSuggestionData.result.collagesList;
      let arr = [];
      val.map((item, i) => {
        arr.push({ name: item.name })
      });

      this.setState({
        suggestions: val
      }, () => {
        nextProps.collageDetails.searchSuggestionData = null;
        this.setLoader(false);
      })
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;


    if (name === "zipcode") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 9) {
        if (re.test(value) || value.length <= 0) {
          this.setState({
            ...this.state,
            [name]: value,
          });
        } else return false;
      } else return false;
    } else if (name === "phone") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 12) {
        if (re.test(value) || value.length <= 0) {
          this.setState({
            ...this.state,
            [name]: value,
          });
        } else return false;
      } else return false;
    }

    if (e.target.name === "weight") {
      const re = /^[0-9\b]+$/;
      if (e.target.value.length <= 3) {
        if (re.test(e.target.value) || e.target.value.length <= 0) {
          this.setState({
            [e.target.name]: e.target.value,
          });
        }
      }
    } else if (e.target.name === "height") {
      const foot = e.target.value.split("'");
      const inches = e.target.value.split(`"`);
      if (foot[1] && inches[0]) {
        const inch = parseInt(foot[1].split(`"`)[0])
        if (inches[1]) {
          if (inches[1] === "0" || inches[1] === "1" && inch === 1) this.setState({ [e.target.name]: `${foot[0]}'${inch}${inches[1]}"` })
        } else {
          if (inch > 9 && inch < 12) {
            if (this.state.height.includes(`"`) && !e.target.value.includes(`"`)) this.setState({ [e.target.name]: `${foot[0]}'${foot[1][0]}"` })
            else this.setState({ [e.target.name]: `${foot[0]}'${inch}"` })
          } else if (inch >= 0 && inch <= 9) {
            const prev = this.state.height.split("'")[1]
            if (this.state.height.includes(`"`) && !(prev.includes('10') || prev.includes('11'))) this.setState({ [e.target.name]: `${foot[0]}'` })
            else if (prev.includes('10') || prev.includes('11')) this.setState({ [e.target.name]: `${foot[0]}'${foot[1]}` })
            else this.setState({ [e.target.name]: `${foot[0]}'${foot[1]}"` })
          } else if (isNaN(inch)) this.setState({ [e.target.name]: `${foot[0]}'` })
        }
      }
      else if (inches[0] && this.state.height.includes(`"`) && inches[1] !== "") { this.setState({ [e.target.name]: `` }) }
      else if (foot[0] && !this.state.height.includes("'") && foot[1] !== "") { this.setState({ [e.target.name]: `${e.target.value}'` }) }
      else if (foot[0] && this.state.height.includes("'") && foot[1] !== "") { this.setState({ [e.target.name]: `` }) }
      else { this.setState({ [e.target.name]: `${e.target.value}`, }) }

    } else {
      this.setState({
        ...this.state,
        [name]: value,
        parentPopup: name === "allowRecruting" && value === "yes" ? true : false
      });
    }
  };

  parentHandle = (e) => {
    const { name, value } = e.target;
    if (name === "fname" || name === "lname") {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === "phone") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 12) {
        if (re.test(value) || value.length <= 0) {
          this.setState({
            ...this.state,
            parentInfo: {
              ...this.state.parentInfo,
              [name]: value
            }
          });
        } else return false;
      } else return false;
    } else {
      this.setState((prevState) => ({
        ...prevState,
        parentInfo: {
          ...prevState.parentInfo,
          [name]: value
        }
      }));
    }
  }

  onParentSave = () => {
    this.state.parentInfo.fullName = this.state.fname + " " + this.state.lname
    this.setState((prevState) => ({
      ...prevState,
      parentInfo: this.state.parentInfo
    }));
  }

  changeImage = (event) => {
    let file = event.target.files[0];
    if (file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.toLowerCase().match(new RegExp('[^.]+$'))[0] !== "png") {
      return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG file");
    }
    this.setState({
      ...this.state,
      imgObj: file,
      cropPopup: true,
      renderFile: URL.createObjectURL(file)
    });
  };

  handleClose = () => {
    document.body.classList.remove('modal-open');
    this.setState({
      cropPopup: false
    });
  }

  onHadleObject = (obj) => {
    this.setLoader(true);
    this.props.postPictureUpload(obj.file, 16);
  }

  removePicture = () => {
    this.setState({
      profileUrl: null,
    });
  };

  postData = () => {
    if (this.state['in-high-school'] === "yes") {
      if (!this.schoolValidator.allValid() || !this.validator.allValid()) {
        this.validator.showMessages();
        this.schoolValidator.showMessages();
        this.forceUpdate();
      } else {
        if ((this.state.recruiting === "yes" && this.state.parentInfo) && (!this.state.parentInfo.fullName || !this.state.parentInfo.email)) {
          return this.commonCall.errorMessage("Please fill up parent details");
        }
        this.registerInfo();
      }
    } else {
      if (this.validator.allValid()) {
        this.registerInfo();
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  registerInfo = async () => {
    let register;
    let name = this.state.fullName.split(" ")
    register = {
      fname: name[0],
      lname: name[1] ? name[1] : "",
      email: this.state.email,
      dob: moment(this.state.dob).toDate(),
      phone: this.state.phone,
      // bio: this.state.bio,
      zipcode: this.state.zipcode,
      city: this.state.city,
      cCode: "+1",
      state: this.state.state,
      profilePic: this.state.personalLogo,
      "game-type": this.state.gameType,
      address: {
        zipcode: this.state.zipcode,
        city: this.state.city,
        state: this.state.state,
      },
      'in-high-school': this.state.allowHighSch,
      'batting-pos': this.state['batting-pos'],
      recruiting: this.state.allowRecruting,
      school: this.state.school,
      height: this.state.height,
      weight: this.state.weight,
      useHanded: this.state.useHanded,
      position: this.state.position,
      position2: this.state.position2,
      parentInfo: this.state.parentInfo,
      heightNumeric: Number(this.state.height.replace("'", ".").replace("\"", "")),
      "fav-colleges": this.state.myCollage['fav-colleges'],
      "curr-gpa": this.state["curr-gpa"],
      "gra-year": this.state["gra-year"],
      "year-exp": this.state["year-exp"],
      sat: this.state.sat,
      act: this.state.act
    };
    // if (!this.state.isImport) { delete register.dob }

    console.log("register:--", register);
    this.setLoader(true);
    await this.props.postInfo(register);
  }

  getZipcode = () => {
    if (this.state.zipcode.length !== 0) {
      this.setLoader(true);
      this.props.zipCodeInfo(this.state.zipcode)
    }
  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  closeVerify = () => {
    document.body.classList.remove('modal-open');
    this.setState({
      verifyPopup: false,
    })
    this.props.fetchInfo();
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  onChange = (dob) => {
    this.setState({
      ...this.state,
      dob: dob,
    });
  }

  onlyNumber = event => {
    const keycode = event.which;
    if (keycode === 13) {
    } else if (
      !(
        event.shiftKey === false &&
        (keycode === 8 ||
          keycode === 37 ||
          keycode === 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    )
      event.preventDefault();
  };

  closeChangePass = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      openChangePassword: false
    })
  };

  calculate_age_between = (dob1) => {
    let today = new Date(),
      //birthay has 'Dec 25 1998'
      dob = new Date(dob1),
      //difference in milliseconds
      diff = today.getTime() - dob.getTime(),
      //convert milliseconds into years
      years = Math.floor(diff / 31556736000),
      //1 day has 86400000 milliseconds
      days_diff = Math.floor((diff % 31556736000) / 86400000),
      //1 month has 30.4167 days
      months = Math.floor(days_diff / 30.4167),
      days = Math.floor(days_diff % 30.4167);
    if (years === 15 && months >= 0 && days >= 0) {
      return true
    } else if (years === 18 && months == 0 && days == 0) {
      return true
    }
    else if (years >= 14 && years < 18) {
      return true
    } else {
      return false
    }
    // var ageDifMs = Date.now() - new Date(dob1).getTime();
    // var ageDate = new Date(ageDifMs); // miliseconds from epoch
    // return (Math.abs(ageDate.getUTCFullYear() - 1970) >= 15 && Math.abs(ageDate.getUTCFullYear() - 1970) <= 18);
  }

  calculate_age = (dob1) => {
    var ageDifMs = Date.now() - new Date(dob1).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
  }

  closeParentPopUp = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      parentPopup: false
    })
  }

  setValue = (e) => {
    console.log("e:----", e.target.value);
    if (e.target.value.length !== 0) {
      this.setState({
        value: e.target.value
      }, () => {
        this.setLoader(true);
        this.props.playerSuggetionList({
          page: 1,
          limit: 50,
          search: e.target.value
        });
      })
    } else {
      this.setState((prevState) => ({
        ...prevState,
        value: e.target.value,
        search: {
          page: 1,
          limit: 10,
          search: ""
        },
        suggestions: []
      }), () => {
        this.state.collegeList = [];
        this.props.otherDetails.collegeInfoData = null;
        this.setLoader(true);
        this.props.getCollegeInfo(this.state.search);
      })
    }

  }


  selectedVal = (value) => {
    console.log("value:--", value);
    this.setState((prevState) => ({
      ...prevState,
      value: value,
      search: {
        page: 1,
        limit: 10,
        search: value
      },
    }), () => {
      this.setLoader(true);
      this.props.getCollegeInfo(this.state.search);
    })
  }

  handleOption = (e, item, data) => {
    if (this.state.myCollage['fav-colleges'].find((d) => d === item)) {
      // subtraction
      let tempArray = this.state.myCollage['fav-colleges'].filter((d) => d !== item);
      let tempCollage = this.state.listCollage.filter((d) => d._id !== item);

      this.setState((prevState) => ({
        ...prevState,
        myCollage: {
          ...prevState.myCollage,
          ['fav-colleges']: tempArray
        },
        listCollage: tempCollage
      }), () => console.log("this.state.listCollage:--", this.state.listCollage));
    } else {
      // addition
      if (this.state.listCollage.length >= 10) {
        e.preventDefault()
        e.currentTarget.checked = false;
        return this.commonCall.errorMessage("You can't select more than 10 colleges.");
      } else {
        this.setState((prevState) => ({
          ...prevState,
          myCollage: {
            ...prevState.myCollage,
            ['fav-colleges']: [...prevState.myCollage['fav-colleges'], item]
          },
          listCollage: [...prevState.listCollage, data]
        }), () => console.log("this.state.listCollage:--", this.state.listCollage));
      }

    }
  }

  renderCollageList = () => {
    return (
      this.state.collegeList.length != 0 &&
      this.state.collegeList.map((e) => {
        return (
          <div className="team-flex" key={e._id}>
            <div className="team-data">
              <div className="team-img"><img src={e.image && e.image.filePath ? e.image.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="collage0" /></div>
              <h4>{e.name}</h4>
            </div>
            <div className="radiobox" >
              <input type="checkbox" checked={this.state.myCollage['fav-colleges'].find((d) => { if (d === e._id) return "checked" })} value={e._id} name="selectedCollage" onChange={(d) => this.handleOption(d, e._id, e)} />
              <span for="Baseball"></span>
            </div>
          </div>
        );
      })
    )
  }

  getScroll = (event) => {
    let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
    if (bottom) {
      this.setLoader(true);
      this.props.getCollegeInfo(this.state.search);
    }
  }

  handleSelectGeoSuggest = (place) => {
    if (place) {
      var componentForm = {
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
      };

      for (var i = 0; i < place.gmaps.address_components.length; i++) {
        var addressType = place.gmaps.address_components[i].types[0];
        if (componentForm[addressType]) {
          var val = place.gmaps.address_components[i][componentForm[addressType]];
          if (val)
            componentForm[addressType] = val
        }
      }
      var location = {
        streetAddress: place.gmaps.formatted_address,
        city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
        postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
        latitude: place.location.lat || 42.2538048,
        longitude: place.location.lng || -83.5735806
      }

      if (location.stateRegion) {
        this.setState({
          state: location.stateRegion,
          city: location.city,
          zipcode: location.postalCode,
          isEditZipcode : location.postalCode ? false : true

        });
      } else {
        this.commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
        this.setState({
          state: "",
          zipcode: "",
          isEditZipcode : false
        });
      }
    } else {
      this.setState({
        city: "",
      });
    }
  }

  render() {
    const { listCollage, loader, verifyPopup, verifyToken, uid, imgObj, cropPopup, renderFile, openChangePassword } = this.state;
    const inputProps = {
      placeholder: 'Search for your college'
    };
    const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    return (
      <>
        <section className="before-dashboard">
          <div className="white-bg">
            <form>
              <div className="edit-50">
                <div className="w-50">
                  <label className="common-lable">Upload profile pic</label>
                  <div className="dragdrop-img">
                    <div className="create_event_fileUpload file_custom_upload text-center">
                      <i className="fas fa-cloud-upload-alt"></i>
                      {!this.state.profileUrl && <p>Click to upload profile picture</p>}
                      {/* .heic,.HEIC */}
                      <input
                        name="logofile"
                        onClick={(ev) => { ev.target.value = null }}
                        type="file"
                        className="img_file_input"
                        accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png"
                        onChange={this.changeImage}
                      />
                      {
                        this.state.profileUrl &&
                        <div className="preview_img">
                          <i className="fas fa-times" onClick={() => this.removePicture()} ></i>
                          <img
                            className="preview_img_inner"
                            src={this.state.profileUrl}
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <label className="common-lable">Full name</label>
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      name="fullName"
                      placeholder="Full name"
                      value={this.state.fullName}
                      onChange={this.handleChange}
                    />
                    <span className="validMsg">
                      {this.validator.message("Full name", this.state.fullName, "required")}
                    </span>

                  </div>
                  <span className="validMsg">
                    {this.validator.message("Full name", this.state.fullName, "required")}
                  </span>
                  <label className="common-lable">Date of birth</label>
                  <div className="form-group mb-0">
                    <div className="bc_relative">
                      <DatePicker
                        ref={(c) => (this._calendar = c)}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                            <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                            <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                          </div>
                        )}
                        selected={this.state.dob}
                        maxDate={moment().subtract(1, 'days').toDate()}
                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                        onChange={(e) => this.onChange(e)}
                        placeholderText="Enter your birth date"
                        disabled={(!this.state.isImport && this.state.isDOBupdate) ? true : false}
                      />
                      <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                        this.state.opencal = !this.state.opencal;
                        this._calendar.setOpen(this.state.opencal);
                      }} />
                    </div>
                    <span className="validMsg">{this.validator.message('Dob', this.state.dob, 'required')}</span>
                    {/* <div className="svg_icon">
                      <input
                        type="text"
                        className="form-control"
                        id="dob"
                        placeholder="Date of birth"
                        name="dob"
                        value={this.state.dob}
                        onChange={this.handleChange}
                        disabled
                      />
                      <img src={imagesArr.calendar} alt="calendar" />
                    </div> */}
                  </div>
                  <label className="common-lable">Mobile number</label>
                  <div className="form-group mb-0">
                    <div className="svg_icon">
                      <div className="input-group">
                        <div className="counrty-no">
                          <select className="custom-select" value={this.state.cCode}>
                            <option value="+1" selected={this.state.cCode === "+1" ? "selected" : ""}>
                              +1
                            </option>
                            {/* {this.state.countryCode.length !== 0 &&
                              this.state.countryCode.map((e, index) => {
                                return (<option key={index} value={e.code}>{e.code}</option>)
                              })
                            } */}
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Enter mobile number"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleChange}
                        />
                        <img src={imagesArr.smartphone} alt="eye" />
                      </div>
                      {/* <span className="validMsg">{this.validator.message('Mobile number', this.state.phone, 'required|phone')}</span> */}
                    </div>
                  </div>

                  <label className="common-lable">
                    What sport do you play?
                  </label>
                  <div className="check_box">
                    <div className="checkbox">
                      <input
                        type="radio"
                        id="Baseball"
                        name="gameType"
                        value="1"
                        checked={this.state.gameType === "1" && true}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="Baseball">Baseball </label>
                    </div>
                    <div className="checkbox mr-0">
                      <input
                        type="radio"
                        id="Softball"
                        name="gameType"
                        value="2"
                        checked={this.state.gameType === "2" && true}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="Softball">Softball </label>
                    </div>
                    <span className="validMsg">{this.validator.message('Sport', this.state.gameType, 'required')}</span>
                  </div>

                  <label className="common-lable">
                    How long have you played {this.state.gameType === "1" ? "baseball" : "softball"}?
                  </label>
                  <div className="form-group mb-0">
                    <select className="custom-select" name="year-exp" value={this.state['year-exp']} onChange={this.handleChange} >
                      <option value="" selected disabled>Select year</option>
                      <option value="1-3" selected={this.state['year-exp'] === "1-3" ? "selected" : ""}>1-3 Years</option>
                      <option value="4-6" selected={this.state['year-exp'] === "4-6" ? "selected" : ""}>4-6 Years</option>
                      <option value="7-9" selected={this.state['year-exp'] === "7-9" ? "selected" : ""}>7-9 Years</option>
                      <option value="10-12" selected={this.state['year-exp'] === "10-12" ? "selected" : ""}>10-12 Years</option>
                    </select>
                  </div>
                  <span className="validMsg">{this.validator.message('Played', this.state['year-exp'], 'required')}</span>



                  <label className="common-lable">Are you in high school?</label>
                  <div className="check_box">
                    <div className="checkbox">
                      <input
                        type="radio"
                        id=""
                        name="allowHighSch"
                        value="yes"
                        checked={this.state['allowHighSch'] === "yes" && true}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="Baseball">Yes </label>
                    </div>
                    <div className="checkbox mr-0">
                      <input
                        type="radio"
                        id=""
                        name="allowHighSch"
                        value="no"
                        checked={this.state['allowHighSch'] === "no" && true}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="Softball">No </label>
                    </div>
                    <span className="validMsg">{this.validator.message('high school', this.state['allowHighSch'], 'required')}</span>
                  </div>


                  <label className="common-lable">Name of school</label>
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      id="school"
                      name="school"
                      placeholder="Name of school"
                      value={this.state.school}
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.state['allowHighSch'] === "yes" && <span className="validMsg">{this.schoolValidator.message("school", this.state.school, "required")}</span>}



                  {this.state['allowHighSch'] === "yes" && this.calculate_age(this.state.dob) &&
                    <>
                      {/* <div>
                        <label className="common-lable">What is your current GPA?</label>
                        <div className="form-group mb-0">
                          <select className="custom-select" name="curr-gpa" onChange={this.handleChange}>
                            <option selected="selected" id="0" disabled>Select GPA</option>
                            <option id="2.5-2.9" value="2.5-2.9" selected={this.state["curr-gpa"] === "2.5-2.9" && "selected"}>{"2.5-2.9"}</option>
                            <option id="3.0-3.4" value="3.0-3.4" selected={this.state["curr-gpa"] === "3.0-3.4" && "selected"}>{"3.0-3.4"}</option>
                            <option id="3.5-3.9" value="3.5-3.9" selected={this.state["curr-gpa"] === "3.5-3.9" && "selected"}>{"3.5-3.9"}</option>
                            <option id="4.0-4.4" value="4.0-4.4" selected={this.state["curr-gpa"] === "4.0-4.4" && "selected"}>{"4.0-4.4"}</option>
                            <option id="4.5-5.0" value="4.5-5.0" selected={this.state["curr-gpa"] === "4.5-5.0" && "selected"}>{"4.5-5.0"}</option>
                          </select>
                          <span className="validMsg">
                            {this.schoolValidator.message("Throw", this.state["curr-gpa"], "required")}
                          </span>
                        </div>
                      </div> */}

                      <div>
                        <label className="common-lable">What year do you graduate high school?</label>
                        <div className="form-group mb-0">
                          <select name="gra-year" className="custom-select" onChange={this.handleChange}>
                            <option selected="selected" id="0" disabled>Select Grade Year</option>
                            {
                              this.years.map((year, index) => {
                                return <option key={`year${index}`} selected={this.state["gra-year"] == year && "selected"} value={year}>{year}</option>
                              })
                            }
                          </select>
                          <span className="validMsg">
                            {this.schoolValidator.message("Year", this.state["gra-year"], "required")}
                          </span>
                        </div>
                      </div>

                      <div className='edit-50'>
                        <div className="w-33 p-0-14">
                          <div className="form-group">
                            <label className="common-lable">GPA </label>
                            <input className="form-control" type="text" name="curr-gpa" placeholder="GPA" onChange={this.handleChange} value={this.state["curr-gpa"] ? this.state["curr-gpa"] : ""} />
                            {/* <span className="validMsg">
                              {this.schoolValidator.message("GPA", this.state["curr-gpa"], "required|numeric")}
                            </span> */}
                          </div>
                        </div>
                        <div className="w-33 p-0-14">
                          <div className="form-group">
                            <label className="common-lable">SAT</label>
                            <input className="form-control" type="text" name="sat" placeholder="SAT" onChange={this.handleChange} value={this?.state?.sat ? this.state.sat : ""} />
                            {/* <span className="validMsg">
                              {this.schoolValidator.message("sat", this.state.sat, "required|numeric")}
                            </span> */}
                          </div>
                        </div>
                        <div className="w-33 p-0-14">
                          <div className="form-group">
                            <label className="common-lable">ACT</label>
                            <input className="form-control" type="text" name="act" placeholder="ACT" onChange={this.handleChange} value={this?.state?.act ? this.state.act : ""} />
                            {/* <span className="validMsg">
                              {this.schoolValidator.message("act", this.state.act, "required|numeric")}
                            </span> */}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {(this.state.allowHighSch === "yes" && this.calculate_age_between(this.state.dob)) &&
                    <>
                      <label className="common-lable">Do you want to play (baseball/softball) in college?</label>
                      <div className="check_box">
                        <div className="checkbox">
                          <input
                            type="radio"
                            id=""
                            name="allowRecruting"
                            value="yes"
                            checked={this.state['allowRecruting'] === "yes" && true}
                            onChange={this.handleChange}
                          />
                          <label htmlFor="Baseball">Yes </label>
                        </div>
                        <div className="checkbox mr-0">
                          <input
                            type="radio"
                            id=""
                            name="allowRecruting"
                            value="no"

                            checked={this.state['allowRecruting'] === "no" && true}
                            onChange={this.handleChange}
                            disabled={this.state['recruiting'] === "yes" ? true : false}
                          />
                          <label style={{ backgroundColor: this.state['recruiting'] === "yes" ? "#E5E9F2" : "" }} htmlFor="Softball">No </label>
                        </div>
                      </div>
                    </>}

                  {
                    (this.state.allowHighSch === "yes" && this.calculate_age_between(this.state.dob)) &&
                    this.state['allowRecruting'] === "yes" &&
                    <div className='player-basic-info add-player'>
                      <div className='player-info'>
                        <img src={imagesArr.ncsaLogoFullColor} />
                        <p><b>Prospect Wire teams with Next College Student Athlete to help athletes with the dream of competing in college.</b></p>
                      </div>
                      <ul>
                        <li><p>Receive a <b>FREE recruiting profile</b> visible to over 35,000 college coaches </p></li>
                        <li><p>An NCSA recruiting expert will reach out to offer a <b>FREE recruiting assessment</b></p></li>
                        <li><p>Receive a <b>FREE recruiting game plan</b> personalized to your child's abilities, wants and needs</p></li>
                        <li><p>By clicking “yes” and submitting, you agree to receive personalized follow-up and marketing messages from NCSA by email, phone and automated text. Consent is not a condition of purchase. Standard rates apply.</p></li>
                      </ul>
                    </div>
                  }
                </div>

                <div className="w-50">
                  <label className="common-lable">Email address</label>
                  <div className="form-group mb-0">
                    <div className="serch-box">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email address"
                        value={this.state.email}
                        onChange={this.handleChange}
                        name="email"
                        disabled
                      />
                      <Link className="serch-btn input-email-btn" onClick={() => this.setState({ openChangePassword: true })}>Change Password</Link>
                    </div>
                  </div>

                  {/* <label className="common-lable">Describe your baseball goals?</label>
                  <textarea
                    id="bio"
                    rows="6"
                    placeholder="Tell us about your baseball goals"
                    className="form-control"
                    value={this.state.bio}
                    onChange={this.handleChange}
                    name="bio"
                  ></textarea>
                  <span className="validMsg">{this.validator.message('Description', this.state.bio, 'required')}</span> */}

                  <div className='edit-50'>
                    <div className="w-50">
                      <label className="common-lable">Height</label>
                      <div className="serch-box">
                        <div className="form-group mb-0">
                          <InputMask {...this.props} className="form-control" name="height" placeholder="Height" inputmode="numeric" onChange={this.handleChange} value={this.state.height} onKeyPress={this.onlyNumber} autoComplete="off" />
                          <span className="validMsg"> {this.validator.message("height", this.state.height, "required")} </span>
                        </div>
                        <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                          Ft.
                        </Link>
                      </div>
                    </div>

                    <div className="w-50">
                      <label className="common-lable">Weight</label>
                      <div className="serch-box">
                        <div className="form-group mb-0">
                          <input type="text" className="form-control" id="usr" name="weight" placeholder="Weight" onChange={this.handleChange} value={this.state.weight} />
                          <span className="validMsg">{this.validator.message("Weight", this.state.weight, "required")} </span>
                        </div>
                        <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                          Pound
                        </Link>
                      </div>
                    </div>

                    <div className="w-50">
                      <label className="common-lable">Throw</label>
                      <div className="form-group mb-0">
                        <select className="custom-select" name="useHanded" value={this.state.useHanded} onChange={this.handleChange} >
                          <option value="" selected disabled>Select Hand</option>
                          <option value="right" selected={this.state.useHanded === "right" ? "selected" : ""}>  Right  </option>
                          <option value="left" selected={this.state.useHanded === "left" ? "selected" : ""}> Left</option>
                          <option value="both" selected={this.state.useHanded === "both" ? "selected" : ""} >    Both </option>
                        </select>
                        <span className="validMsg">
                          {this.validator.message("Throw", this.state.useHanded, "required")}
                        </span>
                      </div>
                    </div>
                    <div className="w-50">
                      <label className="common-lable">Bat</label>
                      <div className="form-group mb-0">
                        <select className="custom-select" name="batting-pos" value={this.state['batting-pos'].length !== 0 ? this.state['batting-pos'][0] : ''} onChange={(e) => { this.setState({ 'batting-pos': [e.target.value] }) }} >
                          <option value="" selected disabled>Select bat</option>
                          <option value="Left" selected={this.state['batting-pos'].length !== 0 && this.state['batting-pos'][0] === "Left" ? "selected" : ""}>  Left  </option>
                          <option value="Switch" selected={this.state['batting-pos'].length !== 0 && this.state['batting-pos'][0] === "Switch" ? "selected" : ""}> Switch</option>
                          <option value="Right" selected={this.state['batting-pos'].length !== 0 && this.state['batting-pos'][0] === "Right" ? "selected" : ""}>     Right  </option>
                        </select>
                        <span className="validMsg">
                          {this.validator.message("Bat", this.state['batting-pos'].length !== 0 && this.state['batting-pos'][0], "required")}
                        </span>
                      </div>
                    </div>
                    <div className="w-50">
                      <label className="common-lable">Primary Position</label>
                      <div className="form-group mb-0">
                        <select className="custom-select" name="position" value={this.state.position} onChange={this.handleChange} >
                          <option value="" selected disabled>Select position</option>
                          <option value="Pitcher" selected={this.state.position === "Pitcher" ? "selected" : ""}>  Pitcher  </option>
                          <option value="Catcher" selected={this.state.position === "Catcher" ? "selected" : ""}> Catcher</option>
                          <option value="1B" selected={this.state.position === "1B" ? "selected" : ""}>     1B  </option>
                          <option value="2B" selected={this.state.position === "2B" ? "selected" : ""}  > 2B </option>
                          <option value="3B" selected={this.state.position === "3B" ? "selected" : ""}  > 3B </option>
                          <option value="SS" selected={this.state.position === "SS" ? "selected" : ""}>   SS  </option>
                          <option value="Infield" selected={this.state.position === "Infield" ? "selected" : ""} >Infield </option>
                          <option value="Outfield" selected={this.state.position === "Outfield" ? "selected" : ""}>   Outfield  </option>
                          <option value="Utility" selected={this.state.position === "Utility" ? "selected" : ""} >    Utility </option>
                        </select>
                        <span className="validMsg">
                          {this.validator.message("Primary Position", this.state.position, "required")}
                        </span>
                      </div>
                    </div>
                    <div className="w-50">
                      <label className="common-lable">Secondary Position</label>
                      <div className="form-group mb-0">
                        <select className="custom-select" name="position2" value={this.state.position2} onChange={this.handleChange}>
                          <option value="">N/A</option>
                          <option value="Pitcher" selected={this.state.position2 === "Pitcher" ? "selected" : ""}>  Pitcher  </option>
                          <option value="Catcher" selected={this.state.position2 === "Catcher" ? "selected" : ""}> Catcher</option>
                          <option value="1B" selected={this.state.position2 === "1B" ? "selected" : ""}>     1B  </option>
                          <option value="2B" selected={this.state.position2 === "2B" ? "selected" : ""}  > 2B </option>
                          <option value="3B" selected={this.state.position2 === "3B" ? "selected" : ""}  > 3B </option>
                          <option value="SS" selected={this.state.position2 === "SS" ? "selected" : ""}>   SS  </option>
                          <option value="Infield" selected={this.state.position2 === "Infield" ? "selected" : ""} >Infield </option>
                          <option value="Outfield" selected={this.state.position2 === "Outfield" ? "selected" : ""}>   Outfield  </option>
                          <option value="Utility" selected={this.state.position2 === "Utility" ? "selected" : ""} >    Utility </option>
                        </select>
                        {/* <span className="validMsg">{this.validator.message("Secondary position", this.state.position2, "required")}</span> */}
                      </div>
                    </div>
                  </div>


                  <label className="common-lable">City <span>( To Look Up State and Zipcode )</span></label>
                  <div className="form-group mb-0">
                    <div className="serch-box">
                    <Geosuggest
                      // ref={el => this._geoSuggest = el}
                      onSuggestSelect={this.handleSelectGeoSuggest}
                      initialValue={this.state.city || ""}
                      autoComplete="off"
                      types={['(cities)']}
                    />
                      {/* <input
                        type="text"
                        className="form-control"
                        id="zipcode"
                        placeholder="Zip Code"
                        name="zipcode"
                        value={this.state.zipcode}
                        onChange={this.handleChange}
                      />
                      <Link onClick={() => this.getZipcode()}  className="serch-btn">Next</Link> */}
                      <span className="validMsg">{this.validator.message('City', this.state.city, 'required')}</span>
                    </div>
                  </div>

                  <label className="common-lable">State</label>
                  <div className="form-group mb-0">
                    <select className="custom-select" name="state" value={this.state.state} onChange={this.handleChange}>
                      <option value="">N/A</option>
                      {Object.keys(StatesObj).map((key) => {
                        return (
                          <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                        )
                      })}
                    </select>
                    {/* <input
                      type="text"
                      className="form-control"
                      id="state"
                      value={this.state.state}
                      placeholder="State"
                      onChange={this.handleChange}
                      name="state"
                      disabled
                    /> */}
                    <span className="validMsg">{this.validator.message('State', this.state.state, 'required')}</span>

                  </div>

                  <label className="common-lable">Zip Code</label>
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      name="zipcode"
                      value={this.state.zipcode}
                      placeholder="Zip code"
                      onChange={this.handleChange}
                      disabled={this.state.isEditZipcode ? false : true}
                      />
                    <span className="validMsg">{this.validator.message('Zipcode', this.state.zipcode, 'required')}</span>
                  </div>


                  <div className="player_list_collage">
                    <div className="form-group mb-0">
                      <div className="serch-box">

                        <ReactAutocomplete
                          inputProps={inputProps}
                          items={this.state.suggestions}
                          shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                          getItemValue={item => item.name}
                          renderItem={(item, highlighted) =>
                            <div
                              className="inner-option"
                              style={{ backgroundColor: highlighted ? '#eee' : 'transparent', width: '100%' }}
                            >
                              {item.name}
                            </div>
                          }
                          value={this.state.value}
                          onChange={e => this.setValue(e)}
                          onSelect={value => this.selectedVal(value)}
                          menuStyle={{
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 1)',
                            padding: '15px',
                            fontSize: '90%',
                            position: 'absolute',
                            overflow: 'auto',
                            top: '100%',
                            left: 0,
                            right: 0,
                            maxHeight: '250px',
                            zIndex: 9,
                            cursor: 'pointer'
                          }}
                        />

                        {/* <input type="text" className="form-control" id="usr" name="search" placeholder="Search for your collage" value={search.search} onChange={this.handleSearch} /> */}
                        {/* <Link onClick={this.searchCollege} type="button" className="serch-btn"><img src={imagesArr.search} alt="search" /></Link> */}
                      </div>
                    </div>
                    {listCollage.length !== 0 ?
                      <>
                        <div className="otherbrand-logo">
                          <label className="common-lable w-100">Your Favorite College</label>
                          {
                            listCollage.map((list, i) => {
                              return (
                                <div className="brand-logo-inner" key={i}>
                                  <div className="brand-logo-main">
                                    <img src={list.image && list.image.filePath ? list.image.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="collage_name1" />
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </>
                      :
                      <>
                        <div style={{ padding: '0px 15px' }}>
                          <label className="common-lable w-100">Your Favorite College</label>
                          <p>Select college from below.</p>
                        </div>
                      </>
                    }

                    {
                      this.state.collegeList.length === 0 &&
                      <h4 style={{ textAlign: 'center' }}>No Data Found</h4>
                    }
                    <div className="scrollbar_team" onScroll={this.getScroll}>
                      <div className="select_team">
                        {this.renderCollageList()}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="flex-align">
                <Link className="btn gray" to={"/player-dashboard"}>
                  Cancel
                </Link>
                <Link
                  className="btn light-green"
                  To="/"
                  onClick={this.postData}
                >
                  Save
                </Link>
              </div>
            </form>
          </div>
        </section>
        <Preloader flag={loader} />
        {
          verifyPopup &&
          <PhoneVerify
            flag={verifyPopup}
            close={this.closeVerify}
            token={verifyToken}
            uid={uid}
            load={this.setLoader}
            type="Player"
            for=""
            email={this.state.email}
            phone={this.state.cCode + this.state.phone}
          />
        }

        <ImageCrop
          imgObj={imgObj}
          flag={cropPopup}
          close={this.handleClose}
          renderImg={renderFile}
          getObj={this.onHadleObject}
        />
        {(this.state.parentPopup && this.state.allowRecruting === "yes" && !this.state.parentInfo?.email) &&
          <ParentInfo
            flag={this.state.parentPopup}
            close={this.closeParentPopUp}
            basicArr={this.state.parentInfo}
            fname={this.state.fname}
            lname={this.state.lname}
            onChange={this.parentHandle}
            onParentSave={this.onParentSave}
          />}

        {
          openChangePassword &&
          <ChangePassword
            flag={openChangePassword}
            close={this.closeChangePass}
            type="Player"
          />
        }
      </>
    );
  }
}

personalInfo.propTypes = {
  fetchInfo: PropTypes.func.isRequired,
  zipCodeInfo: PropTypes.func.isRequired,
  postInfo: PropTypes.func.isRequired,
  postPictureUpload: PropTypes.func.isRequired,
  getPlayerProfile: PropTypes.func.isRequired,
  getCollegeInfo: PropTypes.func.isRequired,
  playerSuggetionList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  _PERSONALINFO: state.playerPersonalInfo,
  _ZIPCODEINFO: state.playerPersonalInfo.zipcodeData,
  _PICTUREUPLOAD: state.playerPersonalInfo.pictureUploadData,
  _POSTINFO: state.playerPersonalInfo,
  _CC: state.api,
  profileDetails: state.headerData,
  otherDetails: state.otherInfo,
  collageDetails: state.collagePlayerDetails,
});

export default connect(mapStateToProps, {
  playerSuggetionList,
  fetchInfo,
  zipCodeInfo,
  postInfo,
  postPictureUpload,
  getCountryCode,
  getPlayerProfile,
  getCollegeInfo
})(personalInfo);

export const TEAMINFO_REQUEST = "TEAMINFO_REQUEST";
export const TEAMINFO_SUCCESS = "TEAMINFO_SUCCESS";
export const TEAMINFO_ERROR = "TEAMINFO_ERROR";

export const REASSIGN_REQUEST = "REASSIGN_REQUEST";
export const REASSIGN_SUCCESS = "REASSIGN_SUCCESS";
export const REASSIGN_ERROR = "REASSIGN_ERROR";

export const POST_REASSIGN_REQUEST = "POST_REASSIGN_REQUEST";
export const POST_REASSIGN_SUCCESS = "POST_REASSIGN_SUCCESS";
export const POST_REASSIGN_ERROR = "POST_REASSIGN_ERROR";

import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";
import {
  getFacility,
  postFacility,

} from "../../../saga/actions/views/coach/profile/facilityInfo.action";
import commonCalls from "../../../businesslogic/commonCalls";
import { getZipcodeInfo, postFileUpload } from "../../../saga/actions/common/api.action";
import { REGISTERINFO_SUCCESS } from '../../../saga/constants/view/coach/profile/personalInfo.const';
import { ZIPCODE_SUCCESS } from '../../../saga/constants/common/api.const';
import SimpleReactValidator from 'simple-react-validator';
import Preloader from '../../../components/preloader';
import ImageCrop from '../../popup/imageCrop';

const initialState = {
  facilityName: "",
  file: null,
  street: "",
  zipcode: "",
  state: "",
  city: "",
  instagramLink: '',
  twitterLink: '',
  isOpenTwitter: false,
  isOpenInsta: false,
  facilityLogoId: "",
  loader: false,
  cropPopup: false,
  renderFile: "",
  imgObj: "",
};

const reducer = (state, payload) => ({ ...state, ...payload });

export default function FacilityInfo() {
  const [facility, setFacility] = useReducer(reducer, initialState);
  const commonCall = new commonCalls();
  const validator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const { zipcode, facilityName, file, street, state, city, instagramLink, twitterLink, isOpenTwitter, isOpenInsta, facilityLogoId, loader, cropPopup, renderFile, imgObj } = facility;
  const { facilityInfoData: _FI } = useSelector((state) => state.facilityInfo);
  const { registerInfoData: _REG } = useSelector((state) => state.personalInfo);
  const { zipcodeData: _zipcode, fileUploadData: _LOGO } = useSelector((state) => state.api);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    getFacility()(dispatch);
  }, []);

  useEffect(() => {
    if (_FI) {
      if (_FI.faciltyName) {
        setFacility({
          facilityName: _FI.faciltyName,
          file: _FI.faciltyLogo && _FI.faciltyLogo.filePath,
          street: _FI.faciltyAddress !== null && _FI.faciltyAddress.hasOwnProperty('street') ? _FI.faciltyAddress.street : "",
          zipcode: _FI.faciltyAddress !== null && _FI.faciltyAddress.hasOwnProperty('zipcode') ? _FI.faciltyAddress.zipcode : "",
          state: _FI.faciltyAddress !== null && _FI.faciltyAddress.hasOwnProperty('state') ? _FI.faciltyAddress.state : "",
          city: _FI.faciltyAddress !== null && _FI.faciltyAddress.hasOwnProperty('city') ? _FI.faciltyAddress.city : "",
          instagramLink: _FI.facInstagramLink,
          twitterLink: _FI.facTwitterLink,
          facilityLogoId: _FI.faciltyLogo && _FI.faciltyLogo._id,
          fetching: true,
        });
      }
      setLoader(false);
    }
  }, [_FI]);

  useEffect(() => {
    if (_LOGO) {
      setFacility({
        facilityLogoId: _LOGO._id,
        file: _LOGO.filePath,
        imgObj: "",
        cropPopup: false,
        renderFile: ""
      });
      document.body.classList.remove('modal-open');
      setLoader(false);
    }
  }, [_LOGO])

  useEffect(() => {
    if (_REG) {
      if (_REG.flag) { commonCall.successMessage(_REG.message) }
      else if (!_REG.flag) { commonCall.errorMessage(_REG.message) }
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: null,
      });
    }
  }, [_REG])

  useEffect(() => {
    if (_zipcode) {
      if (_zipcode.flag) {
        setFacility({
          state: _zipcode.result.state,
          city: _zipcode.result.city,
          zipcode: _zipcode.result.zipcodestr,
        });
      } else {
        commonCall.errorMessage(_zipcode.message);
        setFacility({
          state: '',
          city: '',
        });
        dispatch({
          type: ZIPCODE_SUCCESS,
          payload: null,
        });
      }
    }
  }, [_zipcode]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "zipcode") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 9) {
        if (re.test(value) || value.length <= 0) {
          setFacility({
            [name]: value
          })
        }
      }
    } else {
      setFacility({
        [name]: value
      })
    }
  }

  const handleChange = (event) => {
    let file = event.target.files[0];
    if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png") {
      return commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
    }
    setFacility({
      imgObj: file,
      cropPopup: true,
      renderFile: URL.createObjectURL(file)
    });

  };

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    setFacility({
      cropPopup: false
    });
  }

  const onHadleObject = (obj) => {
    setLoader(true);
    postFileUpload(obj.file, 16)(dispatch);
  }

  const handleRemoveImage = () => {
    setFacility({
      file: null,
      facilityLogoId: null
    });
  };

  const handleTwitterEvent = () => {
    let twLink = {
      facTwitterLink: twitterLink,
    };
    setFacility({
      facTwitterLink: twitterLink,
      isOpenTwitter: false
    });
  };

  const handleInstagramEvent = () => {
    let instaLink = {
      facInstagramLink: instagramLink,
    };
    setFacility({
      facInstagramLink: instagramLink,
      isOpenInsta: false
    });
  };

  const handleSumbit = () => {
    let post_FI;
    post_FI = {
      facilty: "yes",
      faciltyAddress: {
        street: street,
        zipcode: zipcode,
        state: state,
        city: city,
      },
      faciltyName: facilityName,
      faciltyLogo: facilityLogoId,
      facTwitterLink: twitterLink,
      facInstagramLink: instagramLink,
    };
    handlePostData(post_FI);
  };

  const handlePostData = (data) => {
    try {
      if (validator.current.allValid()) {
        setLoader(true);
        postFacility(data)(dispatch);
        getFacility()(dispatch);
      } else {
        validator.current.showMessages(true);
        forceUpdate(1)
      }
    } catch (e) {
      console.log(e);
    }

  }

  const setLoader = (val) => {
    setFacility({
      loader: val
    });
  }

  return (
    <>
      <section className="before-dashboard">
        <div className="white-bg">
          <label className="common-lable">Name of facility</label>
          <div className="form-group mb-0">
            <input type="text" className="form-control" id="usr" placeholder="Enter facility name" name="facilityName" value={facilityName} onChange={handleFormChange} />
            <span className="validMsg">{validator.current.message('Facility Name', facilityName, 'required', { className: 'text-danger' })}</span>
          </div>
          <label className="common-lable">Upload facility logo</label>
          <div className="dragdrop-img">
            <div className="create_event_fileUpload file_custom_upload text-center">
              <i className="fas fa-cloud-upload-alt"></i>
              {!file && <p>Click to upload</p>}
              {/* .heic,.HEIC */}
              <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={handleChange} />
              {
                file &&
                <div className="preview_img">
                  <i className="fas fa-times" onClick={handleRemoveImage}></i>
                  <img className="preview_img_inner" src={file} />
                </div>
              }
            </div>
          </div>
          <span className="validMsg">{validator.current.message('Facility logo', file, 'required', { className: 'text-danger' })}</span>

          <label className="common-lable">Street address</label>
          <div className="form-group mb-0">
            <input type="text" className="form-control" id="usr" placeholder="Enter street address" name="street" value={street} onChange={handleFormChange} />
            <span className="validMsg">{validator.current.message('street', street, 'required', { className: 'text-danger' })}</span>
          </div>

          <div className="select_box row">
            <div className="select_box_inner">
              <label className="common-lable">Zip Code <span>( To Look Up City and State )</span></label>
              <div className="form-group mb-0">
                <div className="serch-box">
                  <input type="text" className="form-control" id="" placeholder="Zip Code" name="zipcode" value={zipcode} onChange={handleFormChange} />
                  <Link onClick={() => (zipcode.length !== 0) ? getZipcodeInfo(zipcode)(dispatch) : ""} className="serch-btn">
                    {/* <img src={imagesArr.search} alt="search" /> */}
                    Next
                  </Link>
                  <span className="validMsg">{validator.current.message('zipcode', zipcode, 'required|numeric', { className: 'text-danger' })}</span>
                </div>
              </div>
            </div>
            <div className="select_box_inner">
              <label className="common-lable">State</label>
              <div className="form-group mb-0">
                <input type="text" className="form-control" id="" readOnly name="State" placeholder="State" value={state} />
                <span className="validMsg">{validator.current.message('state', state, 'required', { className: 'text-danger' })}</span>
              </div>
            </div>
            <div className="select_box_inner">
              <label className="common-lable">City</label>
              <div className="form-group mb-0">
                <input type="text" className="form-control" id="" readOnly name="city" placeholder="City" value={city} />
                <span className="validMsg">{validator.current.message('city', city, 'required', { className: 'text-danger' })}</span>
              </div>
            </div>
          </div>
          <label className="common-lable">Link Your Social Accounts</label>
          <div className="check_input row">
            <div className="check_box add_filed">
              <div to="" className="twitter social_btn w-100" onClick={() => { setFacility({ isOpenTwitter: true }) }}>
                <img src={imagesArr.twitter} alt="twitter" />
                {
                  _FI && twitterLink ?
                    "@" + twitterLink
                    :
                    "Link Twitter account"
                }
              </div>
              {isOpenTwitter &&
                <div className="form-group">
                  <div className="serch-box">
                    <input type="text" className="form-control" id="usr" placeholder="@Name" value={twitterLink} name="twitterLink" onChange={handleFormChange} />
                    <div to="" className="serch-btn" onClick={handleTwitterEvent}>save</div>
                  </div>
                </div>
              }
            </div>
            <div className="check_box add_filed">
              <div to="" className="insta social_btn w-100 mr-0" onClick={() => { setFacility({ isOpenInsta: true }) }}>
                <img src={imagesArr.insta} alt="insta" />
                {
                  _FI && instagramLink ?
                    instagramLink
                    :
                    "Link Instagram account"
                }
              </div>
              {isOpenInsta &&
                <div className="form-group">
                  <div className="serch-box">
                    <input type="text" className="form-control" id="usr" placeholder="Username" value={instagramLink} name="instagramLink" onChange={handleFormChange} />
                    <div to="" className="serch-btn" onClick={handleInstagramEvent}>save</div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="flex-align">
            <Link className="btn gray" to="/coach-dashboard">Cancel</Link>
            <Link className="btn light-green" onClick={handleSumbit} >
              Save
            </Link>
          </div>
        </div>
      </section>
      <Preloader flag={loader} />
      <ImageCrop
        imgObj={imgObj}
        flag={cropPopup}
        close={handleClose}
        renderImg={renderFile}
        getObj={onHadleObject}
      />
    </>
  );
}

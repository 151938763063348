import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class acceptHeroBanner extends Component {
    render() {
        return (
            <>
                <section className="commomn-bg" style={{ minHeight: "100vh" }}>
                    <div className="container">
                        <div className="commomn-bg-text thanks-for"><h1>Thanks for accepting the request.</h1><Link to="/login">Click here to login now.</Link></div>
                    </div>
                </section>
            </>
        );
    }
}

export default acceptHeroBanner;

import React, { Component } from 'react';
import { Player } from 'video-react';
import Slider from "react-slick";
import { getCart } from "../../saga/actions/views/player/cart.action";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class videoPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoArr: []
        }
    }
    
    componentDidMount() {
        if (this.props.flag) {
            document.body.classList.add('video-open');
            this.videoPlay()
        }
    }

    videoPlay = () => {
        let arr = [];
        for (let i = 0; i < this.props.videoPoster.length; i++) {
            if (this.props.showVideo.indexOf(this.props.videoPoster[i]._id) != -1) {
                arr.push(this.props.videoPoster[i]);
            }
        }
        for (let i = 0; i < this.props.videoPoster.length; i++) {
            if (this.props.showVideo.indexOf(this.props.videoPoster[i]._id) == -1) {
                arr.push(this.props.videoPoster[i]);
            }
        }
        this.setState({
            videoArr: arr
        })
    }

    render() {
        let { flag } = this.props;
        var videoslider = {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: true,
            infinite: true,
        };

        return (
            <>
                <section className={`videomodal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog">
                        <div className="video-inner">
                            <div className="modal_close" onClick={() => this.props.close()}><i class="fas fa-times"></i></div>
                            <Slider {...videoslider}>
                                {this.state.videoArr.length != 0 &&
                                    this.state.videoArr.map(e => {
                                        return (
                                            <div className="item" >
                                                <Player playsInline poster={e.vId.thumbnailUrl[0]} src={e.vId.filePath} {...this.props} />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

videoPlayer.propTypes = {
    getCart: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
    cartDetails: state.cartInfo,
});

export default connect(mapStateToProps, { getCart })(videoPlayer);

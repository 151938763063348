import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from "../../../../assets/images";
import EventFilter from '../../../popup/eventFilter';
import commonCalls from "../../../../businesslogic/commonCalls";
import { divisionEvents, getEventDivisionScore, getDivisionList, getLiveEventScore, emptyLiveEventScore } from '../../../../saga/actions/common/slider.action';
import Preloader from '../../../../components/preloader';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
class divisionSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
            eventList: [],
            sendReq: {
                ageGroup: "",
                division: "",
                eventId: "",
                home: true,
                id: localStorage.getItem("cid")
            },
            divisionList: [],
            eventName: "",
            divList: [],
            mangeSlider: true,
            isSroll: false,
            filterClick: false,
            loaderCount: 0,
            showScore: true,
            liveEventScoreList: [],
            backUpScoreList: [],
            eventId: "",
            compName:"",
            skeletonLength: [1, 2, 3, 4, 5, 6, 7, 8]
        };
        this.commonCall = new commonCalls();
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount = async () => {
        window.addEventListener('scroll', this.handleScroll, true);
        this.increment();
        await this.props.getLiveEventScore({...this.state.sendReq,id:localStorage.getItem("cid")});
        setInterval(() => this.props.getLiveEventScore({...this.state.sendReq,id:localStorage.getItem("cid")}), 30000)
    }
    componentWillUnmount(){
        this.props.emptyLiveEventScore()
    }

    handleScroll = () => {
        // if (window.scrollY <= 95) {
        //     this.setState({ mangeSlider: false, isSroll: true })
        // } else {
        //     this.setState({ mangeSlider: true, isSroll: false })
        // }
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.sliderInfo &&
            nextProps.sliderInfo.divisionSliderReq &&
            nextProps.sliderInfo.divisionSliderData
        ) {
            console.log("nextProps.sliderInfo.divisionSliderData:--", nextProps.sliderInfo.divisionSliderData);
            nextProps.sliderInfo.divisionSliderReq = false;
            let fdata = nextProps.sliderInfo.divisionSliderData.result.data;
            let eventData = nextProps.sliderInfo.divisionSliderData.result.event;
            if (nextProps.sliderInfo.divisionSliderData.flag && fdata.length !== 0) {
                if (this.state.sendReq.home) {
                    this.setState((prevState) => ({
                        ...prevState,
                        divisionList: fdata,
                        eventName: eventData ? eventData.eventTitle : "",
                        showScore: Object.keys(eventData).length !== 0 ? true : false
                    }), () => {
                        nextProps.sliderInfo.divisionSliderData = null;
                        if (this.state.eventList.length === 0) {
                            this.props.divisionEvents(localStorage.getItem("cid"));
                        } else {
                            this.decrement();
                            this.onHandleClose();
                        }
                    });
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        divisionList: fdata,
                        eventName: eventData ? eventData.eventTitle : "",
                        showScore: Object.keys(eventData).length !== 0 ? true : false
                    }), () => {
                        nextProps.sliderInfo.divisionSliderData = null;
                        if (this.state.eventList.length === 0) {
                            this.props.divisionEvents(localStorage.getItem("cid"));
                        } else {
                            this.decrement();
                            this.onHandleClose();
                        }
                    });
                }
            } else {
                if (this.state.filterClick) {
                    let errData = fdata.length;
                    let errMsg;
                    if (errData !== 0) {
                        errMsg = nextProps.sliderInfo.divisionSliderData.message;
                    } else {
                        errMsg = "Data not found";
                    }
                    this.commonCall.errorMessage(errMsg);
                    nextProps.sliderInfo.divisionSliderData = null;
                    this.decrement();
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        divisionList: [],
                        eventName: eventData ? eventData.eventTitle : "",
                        showScore: Object.keys(eventData).length !== 0 ? true : false
                    }), () => {
                        nextProps.sliderInfo.divisionSliderData = null;
                        this.props.divisionEvents(localStorage.getItem("cid"));
                    });
                }
            }
        }

        if (nextProps.sliderInfo &&
            nextProps.sliderInfo.divisionEventReq &&
            nextProps.sliderInfo.divisionEventData
        ) {
            nextProps.sliderInfo.divisionEventReq = false;
            console.log("nextProps.divisionEventData:--", nextProps.sliderInfo.divisionEventData);
            if (nextProps.sliderInfo.divisionEventData.flag) {
                let arr = [];
                nextProps.sliderInfo.divisionEventData.result.data.map((item, i) => {
                    arr.push({ value: item._id, label: item.eventTitle })
                });

                this.setState({
                    eventList: arr
                }, () => {
                    nextProps.sliderInfo.divisionEventData = null;
                    this.decrement();
                    // this.props.getDivisionList();
                })
            } else {
                this.commonCall.errorMessage(nextProps.sliderInfo.divisionEventData.message);
                nextProps.sliderInfo.divisionEventData = null;
                this.decrement();
            }
        }

        // if (nextProps.sliderInfo &&
        //     nextProps.sliderInfo.divSliderListReq &&
        //     nextProps.sliderInfo.divSliderListData
        // ) {
        //     nextProps.sliderInfo.divSliderListReq = false;
        //     console.log("nextProps.divSliderListData:--", nextProps.sliderInfo.divSliderListData);
        //     if (nextProps.sliderInfo.divSliderListData.flag) {
        //         let allData = nextProps.sliderInfo.divSliderListData.result.data;
        //         this.setState({
        //             divList: allData,
        //         }, () => {
        //             nextProps.sliderInfo.divSliderListData = null;
        //             this.decrement();
        //         })
        //     } else {
        //         this.commonCall.errorMessage(nextProps.sliderInfo.divSliderListData.message);
        //         nextProps.sliderInfo.divSliderListData = null;
        //         this.decrement();
        //     }
        // }

        if (nextProps.sliderInfo &&
            nextProps.sliderInfo.liveEventScoreReq &&
            nextProps.sliderInfo.liveEventScoreData
        ) {
            nextProps.sliderInfo.liveEventScoreReq = false;
            console.log("nextProps.liveEventScoreData:--", nextProps.sliderInfo.liveEventScoreData);
            if (nextProps.sliderInfo.liveEventScoreData.flag) {
                let allData = nextProps.sliderInfo.liveEventScoreData.result.data;
                let compName=nextProps.sliderInfo.liveEventScoreData.result?.compName[0]?.companyName;
                if (this.state.eventId) {
                    let filterIndex = this.state.backUpScoreList.findIndex(_ => _.eventId === this.state.eventId);
                    this.state.backUpScoreList[filterIndex] = allData[0];
                    this.setState((prevState) => ({
                        ...prevState,
                        liveEventScoreList: this.state.backUpScoreList,
                        compName:compName,
                        eventId: ""
                    }), () => {
                        nextProps.sliderInfo.liveEventScoreData = null;
                        this.decrement();
                    })
                } else {
                    this.setState({
                        liveEventScoreList: allData,
                        compName:compName,
                        backUpScoreList: allData,
                        showScore: allData.length !== 0 ? true : false,
                    }, () => {
                        nextProps.sliderInfo.liveEventScoreData = null;
                        this.decrement();
                    })
                }
            } else {
                this.commonCall.errorMessage(nextProps.sliderInfo.liveEventScoreData.message);
                nextProps.sliderInfo.liveEventScoreData = null;
                this.decrement();
            }
        }
    }

    openPopup = () => {
        this.setState({
            openPopup: true
        })
    }

    onHandleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            openPopup: false,
            sendReq: {
                ageGroup: "",
                Division: "",
                eventId: "",
                home: true,
                id: localStorage.getItem("cid")
            },
        })
    }

    renderDivisionList = () => {
        return (
            this.state.divisionList &&
            this.state.divisionList.map((item, i) => {
                return (
                    <div className="showcase-box" key={i}>
                        <div className="showcase-info">
                            <div className="showcase-title">
                                <h5>{item.division} {item.ageGroup}U</h5>
                            </div>
                            <div className="showcase-text">
                                <div className="showcase-text-vs">
                                    <div className="d-flex">
                                        <h4>{item.homeTeam ? item.homeTeam : "-"}</h4><h4>{item.homeTeamScore}</h4>
                                    </div>
                                    <p>VS</p>
                                    <div className="d-flex">
                                        <h4 className="mb-0">{item.away_team ? item.away_team : "-"}</h4><h4 className="mb-0">{item.awayTeamScore}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    renderScoreList = (score, event) => {
        return (
            // event === this.state.eventId ?
            //     this.renderSkeletonLoder()
            //     :
            <div className="showcase-box" >
                <div className="showcase-info">
                    <div className="event-name">
                        <h3>{score.eventTitle ? score.eventTitle.length >= 28 ? score.eventTitle.substring(0, 20) + "..." : score.eventTitle : ""}</h3>
                    </div>
                    <div className="showcase-title d-flex justify-content-between">
                        <h5>{score.division} {score.ageGroup}U <sup> {score.delay ? "Delayed" : (score.InningNumber) + (score.InningNumber == 1 ? "st" : score.InningNumber == 2 ? "nd" : score.InningNumber == 3 ? "rd" : "th") + " inning"}  </sup></h5>
                        <h5>{score.startDate}</h5>
                    </div>
                    <div className="showcase-text">
                        <div className="showcase-text-vs">
                            <div className="d-flex">
                                <h4>{score.homeTeam ? score.homeTeam : "-"}</h4><h4>{score.homeTeamScore}</h4>
                            </div>
                            <p>VS</p>
                            <div className="d-flex">
                                <h4 className="mb-0">{score.awayTeam ? score.awayTeam : "-"}</h4><h4 className="mb-0">{score.awayTeamScore}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                [name]: name === "ageGroup" ? JSON.parse(value) : value
            },
        }));
    }

    setOption = (value) => {
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                eventId: value,
                home: false
            },
        }));
    }

    sendFilter = () => {
        console.log("sendReq:---", this.state.sendReq);
        this.increment();

        this.setState({
            filterClick: true
        }, () => {
            setTimeout(() => {
                this.props.getEventDivisionScore(this.state.sendReq);
            }, 1000);
        })
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    handleAgeDivChange = (e, eventId) => {
        const { name, value } = e.target;
        this.state.liveEventScoreList[this.state.liveEventScoreList.findIndex(_ => _.eventId === eventId)].match = [];
        this.setState((prevState) => ({
            sendReq: {
                ...prevState.sendReq,
                ageGroup: this.state.sendReq.eventId !== eventId ? "" : this.state.sendReq.ageGroup,
                division: this.state.sendReq.eventId !== eventId ? "" : this.state.sendReq.division,
            },
        }), () => {
            this.setState((prevState) => ({
                eventId: eventId,
                sendReq: {
                    ...prevState.sendReq,
                    eventId: eventId,
                    [name.includes("ageGroup") ? "ageGroup" : "division"]: value,
                },
                [name]: value,
                liveEventScoreList: this.state.liveEventScoreList
            }), () => {
                this.increment();
                this.props.getLiveEventScore(this.state.sendReq);
            })
        })
    }

    renderSkeletonLoder = () => {
        return this.state.skeletonLength.map(() => {
            return (
                <div className='showcase-box'>
                    <div className='scoreLoader_item'>
                        <div className="event-name">
                            <Skeleton />
                        </div>
                        <div className='showcase-title d-flex justify-content-between'>
                            <Skeleton />
                            <Skeleton />

                        </div>
                        <div className='div_showcase-text'>
                            <div className="showcase-text-vs">
                                <div className="d-flex justify-content-between">
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                                <p className='m-0'>VS</p>
                                <div className="d-flex justify-content-between">
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    getAbbreviation=(inputString)=> {
        if (inputString === "Prospect Wire Baseball") {
          return "pwb";
        } else if (inputString === "Prospect Wire Softball") {
          return "pws";
        } else if (inputString === "GOBTournament") {
          return "gob";
        } else {
          return "pwb";
        }
      }

    render() {
        const { openPopup, eventList, eventName, divList, sendReq, mangeSlider, isSroll, divisionList, loaderCount, showScore, liveEventScoreList,compName } = this.state;
        return (
            <>
                {
                    // isSroll &&
                    // showScore && liveEventScoreList.length !== 0 &&
                    <div className={`division-btn ${localStorage.getItem("webglToken") ? "" : "withoutAuth-division-btn"}`} onClick={() => this.setState({ mangeSlider: !this.state.mangeSlider })}>
                        <i className={this.state.mangeSlider ? "fa fa-angle-left" : "fa fa-angle-right"} aria-hidden="true"></i>
                        <span className="tooltiptext">See Scores</span>
                    </div>
                }
                {
                   this.getAbbreviation(compName) === localStorage.getItem("company") &&  mangeSlider && liveEventScoreList.length !== 0 &&
                    <div className="showcase-slider">
                        <div className="container-fluid">
                            {/*showcase  */}
                            <div className="showcase-division">
                                {/* <div className="showcase-box">
                                                    <div className="showcase-info central-showcase">
                                                        <div className="setting-bar-icon">
                                                            <Link onClick={() => this.openPopup()}><img src={imagesArr.setting_bar} alt="setting_bar" /></Link>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between showcase-info-selectbox'>
                                                            <div class="form-group">

                                                                <select name={`ageGroup${i}`} class="custom-select" value={this.state[`ageGroup${i}`]} onChange={(e) => this.handleAgeDivChange(e, _.eventId)} disabled={this.state.eventId ? true : false}>
                                                                    <option value="" disabled="">AgeGroup</option>
                                                                    {_.ageGroupList.map(age => {
                                                                        return (
                                                                            <option value={age.ageGroup} >{age.ageGroup}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <select name={`division${i}`} class="custom-select" value={this.state[`division${i}`]} onChange={(e) => this.handleAgeDivChange(e, _.eventId)} disabled={this.state.eventId ? true : false}>
                                                                    <option value="" disabled="">Division</option>
                                                                    {_.divisionList.map(age => {
                                                                        return (
                                                                            <option value={age._id} >{age.title}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="showcase-text">
                                                            <h3>{_.eventTitle ? _.eventTitle.length >= 28 ? _.eventTitle.substring(0, 20) + "..." : _.eventTitle : ""}</h3>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {console.log("liveEventScoreList",liveEventScoreList,loaderCount,showScore)}
                                {loaderCount > 0 && liveEventScoreList.length == 0 &&
                                    this.renderSkeletonLoder()}
                                {(showScore && liveEventScoreList.length !== 0) &&
                                    liveEventScoreList.map((_, i) => {
                                        return (
                                            <>
                                                {this.renderScoreList(_)}
                                            </>)
                                    })}
                                {/* {_.eventId === this.state.eventId && _.match.length === 0 && this.renderSkeletonLoder()} */}
                            </div>
                        </div>
                    </div>
                }
                {/* {
                    mangeSlider &&
                    <div className="showcase-slider">
                        <div className="container-fluid">
                            <div className="showcase-division">
                                <div className="showcase-box">
                                    <div className="showcase-info central-showcase">
                                        <div className="setting-bar-icon">
                                            <Link onClick={() => this.openPopup()}><img src={imagesArr.setting_bar} alt="setting_bar" /></Link>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between showcase-info-selectbox'>
                                            <div class="form-group">
                                                
                                                <select name="ageGroup" class="custom-select">
                                                    <option value="" disabled="">AgeGroup</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <select name="ageGroup" class="custom-select">
                                                    <option value="" disabled="">Division</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="showcase-text">
                                            <h3>{eventName ? eventName.length >= 28 ? eventName.substring(0, 28) + "..." : eventName : ""}</h3>
                                        </div>
                                    </div>
                                </div>
                                {this.renderDivisionList()}
                            </div>
                        </div>
                    </div>
                } */}
                {
                    openPopup &&
                    <EventFilter
                        flag={openPopup}
                        close={this.onHandleClose}
                        sendReq={sendReq}
                        eventList={eventList && eventList}
                        divList={divList && divList}
                        onChange={this.onHandleChange}
                        onOptionChange={this.setOption}
                        onFilter={this.sendFilter}
                        increment={this.increment}
                        decrement={this.decrement}
                    />
                }
                {/* <Preloader flag={loaderCount > 0 ? true : false} /> */}
            </>
        )
    }
}

divisionSlider.propTypes = {
    divisionEvents: PropTypes.func.isRequired,
    getEventDivisionScore: PropTypes.func.isRequired,
    getDivisionList: PropTypes.func.isRequired,
    emptyLiveEventScore:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    sliderInfo: state.sliderInfo,
});

export default connect(mapStateToProps, { divisionEvents, getEventDivisionScore, getDivisionList, getLiveEventScore ,emptyLiveEventScore})(divisionSlider);

export const GET_EVENT_DETAILS_REQUEST = 'GET_EVENT_DETAILS_REQUEST';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_ERROR   = 'GET_EVENT_DETAILS_ERROR';

export const GET_PARTICIPATION_EVENT_REQUEST = 'GET_PARTICIPATION_EVENT_REQUEST';
export const GET_PARTICIPATION_EVENT_SUCCESS = 'GET_PARTICIPATION_EVENT_SUCCESS';
export const GET_PARTICIPATION_EVENT_ERROR   = 'GET_PARTICIPATION_EVENT_ERROR';

export const GET_ROSTER_GROUP_LIST_REQUEST = 'GET_ROSTER_GROUP_LIST_REQUEST';
export const GET_ROSTER_GROUP_LIST_SUCCESS = 'GET_ROSTER_GROUP_LIST_SUCCESS';
export const GET_ROSTER_GROUP_LIST_ERROR   = 'GET_ROSTER_GROUP_LIST_ERROR';

export const FETCH_PAYMENT_INTENT_REQUEST = 'FETCH_PAYMENT_INTENT_REQUEST';
export const FETCH_PAYMENT_INTENT_SUCCESS = 'FETCH_PAYMENT_INTENT_SUCCESS';
export const FETCH_PAYMENT_INTENT_ERROR   = 'FETCH_PAYMENT_INTENT_ERROR';

export const EVENT_REGISTRATION_PAYMENT_REQUEST = 'EVENT_REGISTRATION_PAYMENT_REQUEST';
export const EVENT_REGISTRATION_PAYMENT_SUCCESS = 'EVENT_REGISTRATION_PAYMENT_SUCCESS';
export const EVENT_REGISTRATION_PAYMENT_ERROR   = 'EVENT_REGISTRATION_PAYMENT_ERROR';

export const EVENT_PAYMENT_COMPLETE_REQUEST = 'EVENT_PAYMENT_COMPLETE_REQUEST';
export const EVENT_PAYMENT_COMPLETE_SUCCESS = 'EVENT_PAYMENT_COMPLETE_SUCCESS';
export const EVENT_PAYMENT_COMPLETE_ERROR   = 'EVENT_PAYMENT_COMPLETE_ERROR';

export const GET_TEAM_AGE_VEFICATION_REQUEST = 'GET_TEAM_AGE_VEFICATION_REQUEST';
export const GET_TEAM_AGE_VEFICATION_SUCCESS = 'GET_TEAM_AGE_VEFICATION_SUCCESS';
export const GET_TEAM_AGE_VEFICATION_ERROR   = 'GET_TEAM_AGE_VEFICATION_ERROR';

export const GET_ROSTER_AGE_AMOUNT_REQUEST = 'GET_ROSTER_AGE_AMOUNT_REQUEST';
export const GET_ROSTER_AGE_AMOUNT_SUCCESS = 'GET_ROSTER_AGE_AMOUNT_SUCCESS';
export const GET_ROSTER_AGE_AMOUNT_ERROR   = 'GET_ROSTER_AGE_AMOUNT_ERROR';

export const SET_EVENT_REGI_AGE_GROUP_REQUEST = 'SET_EVENT_REGI_AGE_GROUP_REQUEST';
export const SET_EVENT_REGI_AGE_GROUP_SUCCESS = 'SET_EVENT_REGI_AGE_GROUP_SUCCESS';
export const SET_EVENT_REGI_AGE_GROUP_ERROR   = 'SET_EVENT_REGI_AGE_GROUP_ERROR';

export const GET_COUPAN_CODE_AMOUNT_REQUEST = 'GET_COUPAN_CODE_AMOUNT_REQUEST';
export const GET_COUPAN_CODE_AMOUNT_SUCCESS = 'GET_COUPAN_CODE_AMOUNT_SUCCESS';
export const GET_COUPAN_CODE_AMOUNT_ERROR   = 'GET_COUPAN_CODE_AMOUNT_ERROR';

export const GET_LEAGUE_COUPAN_CODE_AMOUNT_REQUEST = 'GET_LEAGUE_COUPAN_CODE_AMOUNT_REQUEST';
export const GET_LEAGUE_COUPAN_CODE_AMOUNT_SUCCESS = 'GET_LEAGUE_COUPAN_CODE_AMOUNT_SUCCESS';
export const GET_LEAGUE_COUPAN_CODE_AMOUNT_ERROR   = 'GET_LEAGUE_COUPAN_CODE_AMOUNT_ERROR';

export const GET_INSTALLMENT_DETAILS_REQUEST = 'GET_INSTALLMENT_DETAILS_REQUEST';
export const GET_INSTALLMENT_DETAILS_SUCCESS = 'GET_INSTALLMENT_DETAILS_SUCCESS';
export const GET_INSTALLMENT_DETAILS_ERROR   = 'GET_INSTALLMENT_DETAILS_ERROR';

export const EVENT_INSTALLMENT_PAYMENT_REQUEST = 'EVENT_INSTALLMENT_PAYMENT_REQUEST';
export const EVENT_INSTALLMENT_PAYMENT_SUCCESS = 'EVENT_INSTALLMENT_PAYMENT_SUCCESS';
export const EVENT_INSTALLMENT_PAYMENT_ERROR   = 'EVENT_INSTALLMENT_PAYMENT_ERROR';

export const GET_MULTIPLE_EVENT_SUCCESS = 'GET_MULTIPLE_EVENT_SUCCESS';
export const GET_MULTIPLE_EVENT_ERROR   = 'GET_MULTIPLE_EVENT_ERROR';

export const MULTIPLE_EVENT_PAYMENT_SUCCESS = 'MULTIPLE_EVENT_PAYMENT_SUCCESS';
export const MULTIPLE_EVENT_PAYMENT_ERROR   = 'MULTIPLE_EVENT_PAYMENT_ERROR';

export const BAY_SCOUT_PACKAGE_SUCCESS = 'BAY_SCOUT_PACKAGE_SUCCESS';
export const BAY_SCOUT_PACKAGE_ERROR   = 'BAY_SCOUT_PACKAGE_ERROR';

export const DOWNLOAD_SCOUT_PACKAGE_SUCCESS = 'DOWNLOAD_SCOUT_PACKAGE_SUCCESS';
export const DOWNLOAD_SCOUT_PACKAGE_ERROR   = 'DOWNLOAD_SCOUT_PACKAGE_ERROR';

export const GET_EVENT_RULE_SUCCESS = 'GET_EVENT_RULE_SUCCESS';
export const GET_EVENT_RULE_ERROR = 'GET_EVENT_RULE_ERROR';

export const GET_EVENT_LINKED_PLAYER_LIST_REQ = "GET_EVENT_LINKED_PLAYER_LIST_REQ";
export const GET_EVENT_LINKED_PLAYER_LIST_SUCCESS="GET_EVENT_LINKED_PLAYER_LIST_SUCCESS";
export const GET_EVENT_LINKED_PLAYER_LIST_ERROR="GET_EVENT_LINKED_PLAYER_LIST_ERROR";

export const GET_COST_BY_AGE_REQUEST="GET_COST_BY_AGE_REQUEST";
export const GET_COST_BY_AGE_SUCCESS="GET_COST_BY_AGE_SUCCESS";
export const GET_COST_BY_AGE_ERROR="GET_COST_BY_AGE_ERROR";

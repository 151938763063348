import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import { getBasicDetails } from '../../../../saga/actions/authantication/signup/coach/basic.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import ImageCrop from '../../../../views/popup/imageCrop';
import { StatesObj } from '../../../../businesslogic/content';
import Geosuggest from 'react-geosuggest';

class basic extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditZipcode: false
        }
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.onRef(this);
        this.props.load(true);
        this.props.getBasicDetails();
    }

    componentWillReceiveProps(nextProps) {

    }

    removeImg = () => {
        this.setState({
            file: null
        })
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.props.onChange(name, value)
    }

    validationUpdate = (cb) => {
        if (this.validator.allValid()) {
            cb(true);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            cb(false);
        }
    }

    handleSelectGeoSuggest = (place) => {
        if (place) {
            var componentForm = {
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name',
            };

            for (var i = 0; i < place.gmaps.address_components.length; i++) {
                var addressType = place.gmaps.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.gmaps.address_components[i][componentForm[addressType]];
                    if (val)
                        componentForm[addressType] = val
                }
            }
            var location = {
                streetAddress: place.gmaps.formatted_address,
                city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
                stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
                postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
                latitude: place.location.lat || 42.2538048,
                longitude: place.location.lng || -83.5735806
            }

            if (location.stateRegion) {
                this.props.handleStateChange(location.city, location.stateRegion, location.postalCode)
                this.setState({
                    isEditZipcode: location.postalCode ? false : true
                })
            } else {
                this.commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
                this.props.handleStateChange(location.city, "", "")
                this.setState({
                    isEditZipcode: false
                })
            }
        } else {
            this.props.handleStateChange("", this.props.basicInput.address.state, this.props.basicInput.address.zipcode)
        }
    }

    render() {

        const { profileData, basicInput, manageZipcode, imageUrl, descLenth } = this.props

        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name">
                            <h2>
                                Hi {profileData && profileData.fname && profileData.fname + " " + profileData.lname},
                            </h2>
                            <p>
                                We have a few more QUESTIONS for you.
                            </p>
                        </div>
                        <label className="common-lable">Upload profile picture</label>
                        <div className="dragdrop-img">
                            <div className="create_event_fileUpload file_custom_upload text-center">
                                <i className="fas fa-cloud-upload-alt"></i>
                                {!imageUrl && <p> Click to upload profile picture</p>}
                                {/* .heic,.HEIC */}
                                <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" aaccept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={this.props.handleChange} />
                                {
                                    imageUrl &&
                                    <div className="preview_img">
                                        <i className="fas fa-times" onClick={() => this.props.removeImg()}></i>
                                        <img className="preview_img_inner" src={imageUrl} />
                                    </div>
                                }
                            </div>
                        </div>

                        {/* <label className="common-lable">Describe yourself </label>
                        <div className="text-area-counter">
                            <textarea id="subject" rows="4" name="bio" value={basicInput.bio} onChange={this.onHandleChange} placeholder="Tell us about yourself" className="form-control"></textarea>
                            <span className="textarea__count">{descLenth}/200</span>
                        </div>
                        {/* <span className="validMsg">{this.validator.message('Describe Yourself', basicInput.bio, 'required')}</span> */}

                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">What sport do you manage or coach?</label>
                                <div className="check-box">
                                    <div className="checkbox" >
                                        <input type="radio" id="Baseball" value="1" name="game-type" onChange={this.onHandleChange} checked={basicInput['game-type'] === "1" ? true : false} />
                                        <label htmlFor="Baseball">Baseball </label>
                                    </div>

                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Softball" value="2" name="game-type" onChange={this.onHandleChange} checked={basicInput['game-type'] === "2" ? true : false} />
                                        <label htmlFor="Softball">Softball </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('Sport Coach', basicInput['game-type'], 'required')}</span>
                            </div>
                            <div className="check_inputbox">
                                <label className="common-lable">City <span>( To Look Up State and Zipcode )</span></label>
                                <div className="form-group mb-0">
                                    <div className="serch-box">
                                        <Geosuggest
                                            // ref={el => this._geoSuggest = el}
                                            onSuggestSelect={this.handleSelectGeoSuggest}
                                            initialValue={basicInput.address.city}
                                            autoComplete="off"
                                            types={['(cities)']}
                                        />
                                        {/* <input type="text" name="zipcode" value={basicInput.address.zipcode} onChange={this.onHandleChange} className="form-control" id="usr" placeholder="Zip Code" />
                                        <Link className="serch-btn" onClick={() => (basicInput.address.zipcode.length !== 0) ? this.props.getZipcode() : ""}>
                                            Next
                                        </Link> */}
                                    </div>
                                    <span className="validMsg">{this.validator.message('team city', basicInput.address.city, 'required')}</span>
                                </div>
                            </div>
                        </div>
                        {
                            // manageZipcode &&
                            <div className="check_input row">
                                <div className="check_inputbox">
                                    <label className="common-lable">State</label>
                                    <div className="form-group mb-0">
                                    <select className="custom-select" name="state" value={basicInput.address.state} onChange={this.onHandleChange}>
                                        <option value="">N/A</option>
                                        {Object.keys(StatesObj).map((key) => {
                                            return (
                                                <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                                            )
                                        })}
                                    </select>
                                        {/* <input type="text" className="form-control" name="state" value={basicInput.address.state} onChange={this.onHandleChange} id="usr" placeholder="State" disabled /> */}
                                    </div>
                                    <span className="validMsg">{this.validator.message('team state', basicInput.address.state, 'required')}</span>
                                </div>
                                <div className="check_inputbox">
                                    <label className="common-lable">Zip code</label>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" name="zipcode" value={basicInput.address.zipcode} onChange={this.onHandleChange} id="usr" placeholder="Zip code"  disabled={this.state.isEditZipcode ? false : true} />
                                    </div>
                                    <span className="validMsg">{this.validator.message('team zipcode', basicInput.address.zipcode, 'required')}</span>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </>
        )
    }
}

basic.propTypes = {
    getBasicDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    basicDetails: state.basicDetails
});

export default connect(mapStateToProps, { getBasicDetails })(basic);


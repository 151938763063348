import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../../helpers/authToken';
import {
    GET_COACH_REQUEST,
    GET_COACH_SUCCESS,
    GET_COACH_ERROR,

    ADD_NEW_COACH_REQUEST,
    ADD_NEW_COACH_SUCCESS,
    ADD_NEW_COACH_ERROR,

    DEL_NEW_COACH_REQUEST,
    DEL_NEW_COACH_SUCCESS,
    DEL_NEW_COACH_ERROR,

    ASSIGN_HEAD_COACH_REQUEST,
    ASSIGN_HEAD_COACH_SUCCESS,
    ASSIGN_HEAD_COACH_ERROR,

    OTHER_TEAM_COACH_REQUEST,
    OTHER_TEAM_COACH_SUCCESS,
    OTHER_TEAM_COACH_ERROR,

    GET_ROSTER_LIST_COACH_REQUEST,
    GET_ROSTER_LIST_COACH_SUCCESS,
    GET_ROSTER_LIST_COACH_ERROR,

    SAVE_ROSTER_LIST_COACH_REQUEST,
    SAVE_ROSTER_LIST_COACH_SUCCESS,
    SAVE_ROSTER_LIST_COACH_ERROR,

    DEL_ROSTER_LIST_COACH_REQUEST,
    DEL_ROSTER_LIST_COACH_SUCCESS,
    DEL_ROSTER_LIST_COACH_ERROR,
} from '../../../../constants/view/coach/profile/coachInfo.const';
import histrory from '../../../../../History';

export const deleteRosterHeadCoach = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: DEL_ROSTER_LIST_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/roster/assign/remove`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(delData => {
        dispatch({
            type: DEL_ROSTER_LIST_COACH_SUCCESS,
            payload: delData.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: DEL_ROSTER_LIST_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const saveRosterCoach = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: SAVE_ROSTER_LIST_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/roster/assign`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(rlist => {
        dispatch({
            type: SAVE_ROSTER_LIST_COACH_SUCCESS,
            payload: rlist.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: SAVE_ROSTER_LIST_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const getRosterList = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_ROSTER_LIST_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/roster/assign/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(rlist => {
        dispatch({
            type: GET_ROSTER_LIST_COACH_SUCCESS,
            payload: rlist.data
        })
    }).catch((error) => {
        if (error.response) {
            dispatch({ type: GET_ROSTER_LIST_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const otherTeamCoach = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: OTHER_TEAM_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/add/existing/coach`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(otherTeam => {
        dispatch({
            type: OTHER_TEAM_COACH_SUCCESS,
            payload: otherTeam.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: OTHER_TEAM_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const assignHeadCoach = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: ASSIGN_HEAD_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/make/head/coach`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(headCoach => {
        dispatch({
            type: ASSIGN_HEAD_COACH_SUCCESS,
            payload: headCoach.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ASSIGN_HEAD_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const getRosterCoach = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/team/list/v2`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getcoach => {
        dispatch({
            type: GET_COACH_SUCCESS,
            payload: getcoach.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteRosterCoach = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: DEL_NEW_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/delete`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(delnew => {
        console.log("delnew:---", delnew);
        dispatch({
            type: DEL_NEW_COACH_SUCCESS,
            payload: delnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DEL_NEW_COACH_ERROR, error: error.response.data.message })
        }
    });
};

export const saveCoachData = (data) => dispatch => {
    console.log("data:---", data);
    checkIsValidCoachToken(histrory);
    dispatch({ type: ADD_NEW_COACH_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/coach/addcoach/v2`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: ADD_NEW_COACH_SUCCESS,
            payload: addnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_NEW_COACH_ERROR, error: error.response.data.message })
        }
    });
};


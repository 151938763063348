import axios from 'axios';
import { Auth_Coach_Key, API_Url, Authorization, checkIsValidCoachToken } from '../../../helpers/authToken';
import {
    GET_LICENSE_REQUEST,
    GET_LICENSE_SUCCESS,
    GET_LICENSE_ERROR,

    GET_LICENSE_NAME_REQUEST,
    GET_LICENSE_NAME_SUCCESS,
    GET_LICENSE_NAME_ERROR,

    GET_LICENSE_PLAYER_REQUEST,
    GET_LICENSE_PLAYER_SUCCESS,
    GET_LICENSE_PLAYER_ERROR,

    ADD_LICENSE_REQUEST,
    ADD_LICENSE_SUCCESS,
    ADD_LICENSE_ERROR,

    DELETE_LICENSE_REQUEST,
    DELETE_LICENSE_ERROR,
    DELETE_LICENSE_SUCCESS,
    
    UP_STATUS_REQUEST,
    UP_STATUS_SUCCESS,
    UP_STATUS_ERROR,

    UPDATE_LICENSE_REQUEST,
    UPDATE_LICENSE_ERROR,
    UPDATE_LICENSE_SUCCESS
} from '../../../constants/view/coach/license.const';
import histrory from '../../../../History';

export const getLicense = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocation/product/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getlic => {
        dispatch({
            type: GET_LICENSE_SUCCESS,
            payload: getlic.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

export const getLicenseName = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_LICENSE_NAME_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocation/product/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getlic => {
        dispatch({
            type: GET_LICENSE_NAME_SUCCESS,
            payload: getlic.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LICENSE_NAME_ERROR, error: error.response.data.message })
        }
    });
};

export const getLicensePlayer = () => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_LICENSE_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/player/list`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getplayer => {
        dispatch({
            type: GET_LICENSE_PLAYER_SUCCESS,
            payload: getplayer.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LICENSE_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const getLicenseCoach = () => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: GET_LICENSE_PLAYER_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/coach/list`,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getplayer => {
        dispatch({
            type: GET_LICENSE_PLAYER_SUCCESS,
            payload: getplayer.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LICENSE_PLAYER_ERROR, error: error.response.data.message })
        }
    });
};

export const addAssign = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: ADD_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(addassign => {
        dispatch({
            type: ADD_LICENSE_SUCCESS,
            payload: addassign.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

export const addCoachAssign = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: ADD_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence/subCoach`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(addassign => {
        dispatch({
            type: ADD_LICENSE_SUCCESS,
            payload: addassign.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

export const updateStatus = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: UP_STATUS_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence/status/update`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(upstatus => {
        dispatch({
            type: UP_STATUS_SUCCESS,
            payload: upstatus.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: UP_STATUS_ERROR, error: error.response.data.message })
        }
    });
};


export const editAssign = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: UPDATE_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence/update`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(upstatus => {
        dispatch({
            type: UPDATE_LICENSE_SUCCESS,
            payload: upstatus.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: UPDATE_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

export const editCoachAssign = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: UPDATE_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence/subCoach/update`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(upstatus => {
        dispatch({
            type: UPDATE_LICENSE_SUCCESS,
            payload: upstatus.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: UPDATE_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteAssign = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: DELETE_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence/delete`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(upstatus => {
        dispatch({
            type: DELETE_LICENSE_SUCCESS,
            payload: upstatus.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DELETE_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

export const deleteCoachAssign = (data) => dispatch => {
    checkIsValidCoachToken(histrory);
    dispatch({ type: DELETE_LICENSE_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/subscription/allocate/licence/subCoach/delete`,
        data: data,
        headers: {
            'x-auth-token': Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(upstatus => {
        dispatch({
            type: DELETE_LICENSE_SUCCESS,
            payload: upstatus.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DELETE_LICENSE_ERROR, error: error.response.data.message })
        }
    });
};

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import Basic from './setting/basic';
import Team from './setting/team';
import Coach from './setting/coach';
import Parent from './setting/parent';
import { getFanProfile } from '../../../saga/actions/authantication/verify.action';
import { saveFanBasic } from '../../../saga/actions/authantication/signup/fan/basic.action';
import { saveTeam } from '../../../saga/actions/authantication/signup/fan/team.action';
import { saveCoach, saveFanComplete } from '../../../saga/actions/authantication/signup/fan/coach.action';
import { saveParentComplete } from '../../../saga/actions/authantication/signup/fan/parent.action';
import Preloader from '../../../components/preloader';
import commonCalls from '../../../businesslogic/commonCalls';
import Confirmation from '../../../views/popup/confirmation';
class fanStep4 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            fillTab: 0,
            loader: false,
            fanProfileData: [],
            newPerentTeam: "",
            skipCoachPopup: false,
            skipTeamPopup: false,
            type: "Fan"
        }
        this.basicInfo = null;
        this.teamInfo = null;
        this.coachInfo = null;
        this.parentInfo = null;
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setLoader(true);
        this.props.getFanProfile();
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.verifyData.fanProfileData) {
            this.props.verifyData.fanProfileData = null;
        }

        if (nextProps.verifyData &&
            nextProps.verifyData.fanProfileReq &&
            nextProps.verifyData.fanProfileData
        ) {
            console.log("nextProps.verifyData.fanProfileData.result:--", nextProps.verifyData.fanProfileData.result);
            nextProps.verifyData.fanProfileReq = false;
            this.setState({
                fanProfileData: nextProps.verifyData.fanProfileData.result,
                type: nextProps.verifyData.fanProfileData.result.profile.role
            })
            if (nextProps.verifyData.fanProfileData.result.profile.isCompleted) {
                nextProps.verifyData.fanProfileData = null
                this.setLoader(false);
                this.commonCall.successMessage("Login Successfully");
                this.props.history.push('/fan-dashboard');
            } else {
                let profileData = nextProps.verifyData.fanProfileData.result.profile;
                if (profileData.hasOwnProperty('formData') && profileData.formData.length !== 0) {
                    profileData.formData.map((item, i) => {
                        if (item.sequence === 1) {
                            this.setState({ tabs: 1, fillTab: 1 }, () => {
                                this.setLoader(false);
                                nextProps.verifyData.fanProfileData = null
                            })
                        } else if (item.sequence === 2) {
                            this.setState({ tabs: 2, fillTab: 2, newPerentTeam: nextProps.verifyData.fanProfileData.result.profile.followingTeam }, () => {
                                this.setLoader(false);
                                nextProps.verifyData.fanProfileData = null
                            })
                        } else if (item.sequence === 3) {
                            this.setState({ tabs: 3, fillTab: 3 }, () => {
                                this.setLoader(false);
                                nextProps.verifyData.fanProfileData = null
                            })
                        } else {
                            nextProps.verifyData.fanProfileData = null
                            this.setLoader(false);
                            this.props.history.push('/fan-dashboard');
                        }
                    });
                } else {
                    this.setState({ tabs: 0, fillTab: 0 }, () => {
                        this.setLoader(false);
                        nextProps.verifyData.fanProfileData = null
                    })
                }
            }
        }

        if (nextProps.basicFanDetails &&
            nextProps.basicFanDetails.saveFanReq &&
            nextProps.basicFanDetails.saveFanData
        ) {
            console.log("nextProps.basicFanDetails.saveFanData:--", nextProps.basicFanDetails.saveFanData);
            nextProps.basicFanDetails.saveFanReq = false;
            if (nextProps.basicFanDetails.saveFanData.flag) {
                this.setState({
                    tabs: 1,
                    fillTab: 1,
                }, () => {
                    nextProps.basicFanDetails.saveFanData = null;
                    this.setLoader(false);
                })
            }
        }
        if (nextProps.teamDetails &&
            nextProps.teamDetails.saveTeamRes &&
            nextProps.teamDetails.saveTeamData
        ) {
            nextProps.teamDetails.saveTeamRes = false;
            if (nextProps.teamDetails.saveTeamData.flag) {
                this.setState({
                    tabs: 2,
                    fillTab: 2,
                    newPerentTeam: nextProps.teamDetails.saveTeamData.result.followingTeam
                }, () => {
                    this.setLoader(false);
                    nextProps.teamDetails.saveTeamData = null
                })
            }
        }

        if (nextProps.coachDetails &&
            nextProps.coachDetails.saveCoachRes &&
            nextProps.coachDetails.saveCoachData
        ) {
            nextProps.coachDetails.saveCoachRes = false;
            if (nextProps.coachDetails.saveCoachData.flag) {
                nextProps.coachDetails.saveCoachData = null;
                this.props.saveFanComplete();
            }
        }

        if (nextProps.coachDetails &&
            nextProps.coachDetails.completeFanRes &&
            nextProps.coachDetails.completeFanData
        ) {
            nextProps.coachDetails.completeFanRes = false;
            if (nextProps.coachDetails.completeFanData.flag) {
                this.commonCall.successMessage(nextProps.coachDetails.completeFanData.message);
                nextProps.coachDetails.completeFanData = null;
                this.setLoader(false);
                if (localStorage.getItem("payPlans")) {
                    if (localStorage.getItem("rolPay") === "Fan") {
                        this.props.history.push('/fan-payment');
                    } else {
                        localStorage.removeItem("rolPay");
                        localStorage.removeItem("dcStats");
                        localStorage.removeItem("payPlans");
                        localStorage.removeItem("analyzr");
                        this.props.history.push('/fan-dashboard');
                    }
                } else {
                    this.props.history.push('/fan-dashboard');
                }
            }
        }

        if (nextProps.parentDetails &&
            nextProps.parentDetails.completeParentRes &&
            nextProps.parentDetails.completeParentData
        ) {
            nextProps.parentDetails.completeParentRes = false;
            if (nextProps.parentDetails.completeParentData.flag) {
                this.commonCall.successMessage(nextProps.parentDetails.completeParentData.message);
                nextProps.parentDetails.completeParentData = null;
                this.setLoader(false);
                if (localStorage.getItem("payPlans")) {
                    if (localStorage.getItem("rolPay") === "Fan") {
                        this.props.history.push('/fan-payment');
                    } else {
                        localStorage.removeItem("rolPay");
                        localStorage.removeItem("dcStats");
                        localStorage.removeItem("payPlans");
                        this.props.history.push('/fan-dashboard');
                    }
                } else {
                    this.props.history.push('/fan-dashboard');
                }
            }
        }

    }

    handleTabs = (e) => {
        if (this.state.fillTab <= e) {
            this.setState({
                tabs: this.state.fillTab
            })
        } else {
            this.setState({
                tabs: e
            })
        }
    }

    nextStep = () => {
        if (this.state.tabs === 0) {
            this.basicInfo.validationUpdate((flag, data) => {
                if (flag) {
                    console.log("data:---", data);
                    this.setLoader(true);
                    this.props.saveFanBasic(data);
                }
            });
        }
        if (this.state.type === "Fan") {
            if (this.state.tabs === 1) {
                this.teamInfo.validationUpdate(obj => {
                    if (obj.flag) {
                        console.log("data:---",);
                        this.setLoader(true);
                        this.props.saveTeam(obj.data);
                    } else {
                        this.commonCall.errorMessage("Please select team name");
                    }
                });
            }
            if (this.state.tabs === 2) {
                this.coachInfo.validationUpdate(obj => {
                    if (obj.flag) {
                        console.log("data:---",);
                        this.setLoader(true);
                        this.props.saveCoach(obj.data);
                    } else {
                        this.commonCall.errorMessage("Please select coach name");
                    }
                });
            }
        } else if (this.state.type === "Parent") {
            if (this.state.tabs === 1) {
                this.parentInfo.validationUpdate(obj => {
                    if (obj) {
                        console.log("data:---",);
                        this.setLoader(true);
                        this.props.saveParentComplete();
                    } else {
                        this.commonCall.errorMessage("Please add at least one player.");
                    }
                });
            }
        }
    }

    nextSkip = (name) => {
        this.setState({
            [name]: true
        })
    }

    onHandleSkipClose = (name) => {
        document.body.classList.remove('modal-open');
        this.setState({
            [name]: false,
        })
    }

    nextSkipHandle = (name) => {
        document.body.classList.remove('modal-open');
        this.setState({
            [name]: false
        }, () => {
            this.setLoader(true);
            this.props.saveFanComplete();
        })
    }

    handleStep = (type) => {
        this.setState({
            type: type
        })
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { tabs, loader, skipCoachPopup, skipTeamPopup } = this.state;
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-timeline">
                    <div className="container">
                        <div className="time-line">
                            <div className="timeline-scoll">
                                <ul className="timeline-inner">
                                    <li className={`${tabs === 0 ? "select" : tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                        <Link>1</Link>
                                    </li>
                                    {this.state.type === "Fan" &&
                                        <>
                                            <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                                <Link>2</Link>
                                            </li>
                                            <li className={`${tabs === 2 ? "select" : tabs > 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                                <Link>3</Link>
                                            </li>
                                        </>}
                                    {this.state.type === "Parent" &&
                                        <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                            <Link>2</Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="all_btn_step">
                                {
                                    (this.state.type === "Fan" && (tabs === 1 || tabs === 2)) &&
                                    <Link className="btn blue_btn" onClick={() => this.nextSkip(tabs === 1 ? 'skipTeamPopup' : 'skipCoachPopup')}>Skip</Link>
                                }
                                <Link className="btn light-green" onClick={this.nextStep}>Next</Link>
                            </div>
                        </div>

                        {tabs === 0 &&
                            <Basic
                                onRef={e => this.basicInfo = e}
                                handleStep={this.handleStep}
                                profileData={this.state.fanProfileData}
                            />
                        }
                        {this.state.type === "Fan" &&
                            <>
                                {tabs === 1 && <Team
                                    onRef={e => this.teamInfo = e}
                                    profileData={this.state.fanProfileData}
                                />}

                                {tabs === 2 &&
                                    <Coach
                                        onRef={e => this.coachInfo = e}
                                        pTeamId={this.state.newPerentTeam}
                                        profileData={this.state.fanProfileData}
                                    />}
                            </>

                        }

                        {this.state.type === "Parent" &&
                            tabs === 1 &&
                            <Parent
                                onRef={e => this.parentInfo = e}
                                profileData={this.state.fanProfileData}
                            />
                        }


                        <div className="time-line">
                            <div className="timeline-scoll">
                                <ul className="timeline-inner">
                                    <li className={`${tabs === 0 ? "select" : tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                        <Link>1</Link>
                                    </li>
                                    {this.state.type === "Fan" &&
                                        <>
                                            <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                                <Link>2</Link>
                                            </li>
                                            <li className={`${tabs === 2 ? "select" : tabs > 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                                <Link>3</Link>
                                            </li>
                                        </>}
                                    {this.state.type === "Parent" &&
                                        <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                            <Link>2</Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="all_btn_step">
                                {
                                    (this.state.type === "Fan" && (tabs === 1 || tabs === 2)) &&
                                    <Link className="btn blue_btn" onClick={() => this.nextSkip(tabs === 1 ? 'skipTeamPopup' : 'skipCoachPopup')}>Skip</Link>
                                }
                                <Link className="btn light-green" onClick={this.nextStep}>Next</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Preloader flag={loader} />
                <Footer />
                {
                    skipTeamPopup &&
                    <Confirmation
                        flag={skipTeamPopup}
                        close={() => this.onHandleSkipClose('skipTeamPopup')}
                        type="skipTeam"
                        closeCancel={() => this.onHandleSkipClose('skipTeamPopup')}
                        closeNext={this.nextSkipHandle}
                    />
                }
                {
                    skipCoachPopup &&
                    <Confirmation
                        flag={skipCoachPopup}
                        close={() => this.onHandleSkipClose('skipCoachPopup')}
                        type="skipCoach"
                        closeCancel={() => this.onHandleSkipClose('skipCoachPopup')}
                        closeNext={this.nextSkipHandle}
                    />
                }

            </>
        )
    }
}

fanStep4.propTypes = {
    getFanProfile: PropTypes.func.isRequired,
    saveFanBasic: PropTypes.func.isRequired,
    saveTeam: PropTypes.func.isRequired,
    saveCoach: PropTypes.func.isRequired,
    saveFanComplete: PropTypes.func.isRequired,
    saveParentComplete: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
    basicFanDetails: state.basicFanDetails,
    teamDetails: state.teamFanDetails,
    coachDetails: state.coachFanDetails,
    parentDetails: state.parentDetails,
});

export default connect(mapStateToProps, { getFanProfile, saveFanBasic, saveTeam, saveCoach, saveFanComplete, saveParentComplete })(fanStep4);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEventRegistrationAge,getCostByAge } from '../../../../../saga/actions/common/eventDetails.action';
import Preloader from "../../../../../components/preloader";
import SimpleReactValidator from 'simple-react-validator';
import Select from "react-select";

class registrationAgegroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredActiveData: [],
            search: "",
            loader: false,
            pricing: {
                pitcher: {
                    fee: "",
                    label: "Pitcher Registration"
                },
                position: {
                    fee: "",
                    label: "Position Player Registration"
                },
                twoWay: {
                    fee: "",
                    label: "2 Way Player Registration"
                }
            },
            feesOfGroup: "",
            slectedGroupFeeId: ""
        }

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        this.props.onRef(this);
        if(localStorage.getItem("role") === "Player"){
            this.setLoader(true);
            this.props.getEventRegistrationAge({ event: this.props.eventId })
            this.setLoader(false);
        }else{
            this.props.getCostByAge(this.props.regiProduct ? {event: this.props.eventId,playerId:this.props?.playerId?.value} :{  event: this.props.eventId })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.setEventRegiAgeReq &&
            nextProps.eventDetails.setEventRegiAgeData
        ) {
            nextProps.eventDetails.setEventRegiAgeReq = false;
            console.log("nextProps.onHandlePrice:--", nextProps.eventDetails.setEventRegiAgeData);
            let data = nextProps.eventDetails.setEventRegiAgeData.result;
            this.setState({
                pricing: {
                    ...this.state.pricing,
                    pitcher: {
                        ...this.state.pricing.pitcher,
                        fee: data.pitcherFee,
                    },
                    position: {
                        ...this.state.pricing.position,
                        fee: data.positionFee,
                    },
                    twoWay: {
                        ...this.state.pricing.twoWay,
                        fee: data.twoWayFee,
                    }
                },
                feesOfGroup: data.feesOfGroup,
                slectedGroupFeeId: data._id
            }, () => {
                this.props.changeAgeGroup({ ageGroup: data.ageGroup, ageGroupTo: data.ageGroupTo, paymentType: data.paymentType });
                nextProps.eventDetails.setEventRegiAgeData = null;
                this.setLoader(false);
            })
            if (nextProps.eventDetails.setEventRegiAgeData.flag) {
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.setEventRegiAgeData.message);
                nextProps.eventDetails.setEventRegiAgeData = null;
                this.setLoader(false);
            }
        }

    if(nextProps.eventDetails &&
        nextProps.eventDetails.getCostByAgeReq &&
        nextProps.eventDetails.getCostByAgeData){
            nextProps.eventDetails.getCostByAgeReq = false;
            let data = nextProps.eventDetails.getCostByAgeData.result;
            this.setState({
                pricing: {
                    ...this.state.pricing,
                    pitcher: {
                        ...this.state.pricing.pitcher,
                        fee: data.pitcherFee,
                    },
                    position: {
                        ...this.state.pricing.position,
                        fee: data.positionFee,
                    },
                    twoWay: {
                        ...this.state.pricing.twoWay,
                        fee: data.twoWayFee,
                    }
                },
                feesOfGroup: data.feesOfGroup,
                slectedGroupFeeId: data._id
            }, () => {
                this.props.changeAgeGroup({ ageGroup: data.ageGroup, ageGroupTo: data.ageGroupTo, paymentType: data.paymentType });
                nextProps.eventDetails.getCostByAgeData = null;
                this.setLoader(false);
            })
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    validationUpdate = (cb) => {
        if (this.validator.allValid()) {
            cb(true, this.state.feesOfGroup, this.state.slectedGroupFeeId);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            cb(false);
        }
    }
    render() {
        const { eventJoin, dataList, linkedPlayerListOpt, playerId } = this.props;
        const { loader, pricing } = this.state;
        return (
            <>
                <div className="event-form">
                    <div className="join-form">
                        <h4>Join the Event</h4>
                        <div className='inner_join_form'>
                            {
                                // dataList.eventPriority === 1 &&
                                <div className="product-price-list mb-15">
                                    <h5>Event Registration Options:</h5>
                                    <div className="product-price-group">
                                        <div className="product-name">
                                            <div class="radiobox">
                                                <input type="radio" id="regiProduct" name="regiProduct" onChange={() => this.props.onHandleRegiProduct('option', 'Position', pricing.position)} checked={this.props.regiProduct === "Position" ? true : false} /><span for="regiProduct"></span>
                                            </div>
                                            <label>{pricing.position.label}</label>
                                        </div>
                                        <div className="product-price">
                                            <h6>${pricing.position.fee}</h6>
                                        </div>
                                    </div>
                                    <div className="product-price-group">
                                        <div className="product-name">
                                            <div class="radiobox">
                                                <input type="radio" id="regiProduct" name="regiProduct" onChange={() => this.props.onHandleRegiProduct('option', 'Pitcher', pricing.pitcher)} checked={this.props.regiProduct === "Pitcher" ? true : false} /><span for="regiProduct"></span>
                                            </div>
                                            <label>{pricing.pitcher.label}</label>
                                        </div>
                                        <div className="product-price">
                                            <h6>${pricing.pitcher.fee}</h6>
                                        </div>
                                    </div>
                                    <div className="product-price-group">
                                        <div className="product-name">
                                            <div class="radiobox">
                                                <input type="radio" id="regiProduct" name="regiProduct" onChange={() => this.props.onHandleRegiProduct('option', '2Way', pricing.twoWay)} checked={this.props.regiProduct === "2Way" ? true : false} /><span for="regiProduct"></span>
                                            </div>
                                            <label>{pricing.twoWay.label}</label>
                                        </div>
                                        <div className="product-price">
                                            <h6>${pricing.twoWay.fee}</h6>
                                        </div>
                                    </div>
                                    <span className="validMsg">{this.validator.message("Registration Options", this.props.regiProduct, "required")}</span>
                                </div>
                            }
                            {
                                (dataList?.eventTeamType === 2 && ((this.props.profileDetails.headData?.result?.profile?.role === "Coach" && (this.props.profileDetails.headData?.result?.profile?.parentTeam?.type == 4 || this.props.profileDetails.headData?.result?.profile?.parentTeam?.type == 1)) || localStorage.getItem("role")==="Parent")) ?
                                (<div className="product-price-list price_list_join_form  pb-30">
                                    <h5 className='mb-6'>Select Player to Register for Showcase:</h5>
                                    <Select
                                    placeholder={<div>Select Player</div>}
                                        value={this.props?.playerId?.value ? linkedPlayerListOpt.find(p => p.value === this.props?.playerId?.value) : null}
                                        options={linkedPlayerListOpt}
                                        onChange={this.props.changeRegisrationPlayer}
                                        />
                                        <span className="validMsg">{this.validator.message("Register for Showcase", this.props?.playerId?.value, "required")}</span>
                                        </div>) : null
                            }

                            {
                                dataList.supRep &&
                                dataList.analyzrReport &&
                                <div className="product-price-list price_list_join_form  pb-30">
                                    <h5>AnalyzR Report:</h5>
                                    <div className="product-price-group">
                                        <div className="product-name">
                                            <div class="radiobox">
                                                <input type="radio" id="eventRegi" name="analyzrReport" onChange={() => this.props.onHandleRegiProduct('add', 'yes', dataList)} checked={this.props.analReport === "yes" ? true : false} /><span for="eventRegi"></span>
                                            </div>
                                            <label>Add Report</label>
                                        </div>
                                        <div className="product-price">
                                            <h6>{!dataList.chargeReport ? dataList.feeReport === 0 ? "Free" : "$" + dataList.feeReport : "Free"}</h6>
                                        </div>
                                    </div>
                                    {
                                        !dataList.chargeReport &&
                                        <div className="product-price-group">
                                            <div className="product-name">
                                                <div class="radiobox">
                                                    <input type="radio" id="eventRegi" name="analyzrReport" onChange={() => this.props.onHandleRegiProduct('remove', 'no')} checked={this.props.analReport === "no" ? true : false} /><span for="eventRegi"></span>
                                                </div>
                                                <label>No, Thank You</label>
                                            </div>
                                        </div>
                                    }
                                    <span className="validMsg">{this.validator.message("AnalyzR Report", this.props.analReport, "required")}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Preloader flag={loader} />
            </>
        )
    }
}

registrationAgegroup.propTypes = {
    getEventRegistrationAge: PropTypes.func.isRequired,
    getCostByAge:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
    profileDetails: state.headerData,
});
var registrationAgegroupComponent = connect(mapStateToProps, { getEventRegistrationAge,getCostByAge })(registrationAgegroup);
export default withRouter(registrationAgegroupComponent);
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";

class ThankYou extends Component {
  componentDidMount() {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
    }
  }

  render() {
    const { flag, registeredCount } = this.props;

    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog thank-info">
            <div className="modal-content">
              <div className="modal-header">

                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.gray_modal_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body ">
                <div className="thank-box">
                  <h4>Thank You</h4>
                  <p>You have selected {registeredCount} event  for registration.</p>
                </div>
                <div className="flex-align next-button">
                  <Link className="btn light-green" onClick={this.props.back}>League Detail</Link>
                  <Link className="btn gray" onClick={this.props.close}>Edit Selection</Link>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    );
  }
}

export default ThankYou;

import React, { memo } from 'react';
import { imagesArr } from '../../../../../assets/images';

function Recap({ allRecapDescription }) {

    return (
        <>
            <div className="recap-info-tab">
                <div className="container">
                    <div className="playerinfo-section">
                         <>
                            <h2>{allRecapDescription?.eventObj?.title}</h2>
                            <div className="user-img">
                                <img src={allRecapDescription?.eventObj?.media?.filePath ? allRecapDescription?.eventObj?.media.filePath : imagesArr.default_user} alt="team logo" />
                            </div>
                            <div className="player-detail">
                                <p className="text" dangerouslySetInnerHTML={{
                                    __html: allRecapDescription?.eventObj?.recapText && allRecapDescription?.eventObj?.recapText
                                }}></p>
                            </div>
                        </> 
                    </div>
                </div>
               
            </div>
        </>
    )
}

export default memo(Recap)
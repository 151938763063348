import axios from 'axios';
import { Auth_Company_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    COM_CONTACTUS_FORM_REQUEST,
    COM_CONTACTUS_FORM_SUCCESS,
    COM_CONTACTUS_FORM_ERROR,

    EVENT_CONTACT_US_SUCCESS,
    EVENT_CONTACT_US_ERROR
} from '../../constants/common/contactUs.const';
import histrory from '../../../History';

export const saveContactForm = (data) => dispatch => {
    dispatch({ type: COM_CONTACTUS_FORM_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}contactus/detail`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(saveContact => {
        dispatch({
            type: COM_CONTACTUS_FORM_SUCCESS,
            payload: saveContact.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_CONTACTUS_FORM_ERROR, error: error.response.data.message })
        }
    });
};

export const eventContactUs = (data) => dispatch => {
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/contactus`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(saveContact => {
        dispatch({
            type: EVENT_CONTACT_US_SUCCESS,
            payload: saveContact.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: EVENT_CONTACT_US_ERROR, error: error.response.data.message })
        }
    });
};




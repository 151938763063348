import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { imagesArr } from '../../../assets/images';
import moment from "moment";
import DatePicker from "react-datepicker";
import { createEvent } from '../../../saga/actions/common/uploadcsv.action';
import Preloader from '../../../components/preloader';

class fanCreateEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createEvent: {
                date: "",
                event_type: "Game",
                event_name: "",
                umpire_name:"",
            },
            opencal: false,
            loader: false,
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.saveEventReq &&
            nextProps.uploadCsvInfo.saveEventData
        ) {
            console.log("nextProps from create event:----", nextProps.uploadCsvInfo.saveEventData);
            nextProps.uploadCsvInfo.saveEventReq = false;
            if (nextProps.uploadCsvInfo.saveEventData.flag) {
                this.setLoader(false);
                this.commonCall.successMessage(nextProps.uploadCsvInfo.saveEventData.message);
                this.props.history.push(`/fan-source/${nextProps.uploadCsvInfo.saveEventData.result._id}`);
                nextProps.uploadCsvInfo.saveEventData = null;
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            createEvent: {
                ...prevState.createEvent,
                [name]: value
            },
        }));
    }

    onChangeDate = (value) => {
        this.setState((prevState) => ({
            ...prevState,
            createEvent: {
                ...prevState.createEvent,
                // date: moment.utc(moment(value).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString()
                date: value
            },
        }));
    }

    onSave = () => {
        if (this.validator.allValid()) {
            let newObj = { ...this.state.createEvent };
            newObj.date = moment(newObj.date).format("MM-DD-YYYY");
            newObj.umpire_name = newObj.event_type !== 'Game' ? '' : newObj.umpire_name
            this.setLoader(true);
            this.props.createEvent(newObj, "Fan", localStorage.getItem("rosterToken"));
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { createEvent, loader } = this.state;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                <section className="uploadcsv-create uploadcsv">
                    <div className="container">
                        <div className="white-bg">
                            <h3>Create Event</h3>
                            {/* <p className="mb-0">New create event and add data</p> */}
                            <form>
                                <div className="row info-wrap">
                                    <div className="w-50">
                                        <label className="common-lable">Event Name</label>
                                        <div className="form-group mb-0">
                                            <input type="text" name="event_name" value={createEvent.event_name} onChange={this.onHandleChange} className="form-control" placeholder="Enter Event Name" />
                                        </div>
                                        <span className="validMsg">{this.validator.message('Event Name', createEvent.event_name, 'required')}</span>
                                    </div>
                                    <div className="w-50 pr-0">
                                        <label className="common-lable">Event Date</label>
                                        <div className="bc_relative">
                                            <DatePicker
                                                ref={(c) => (this._calendar = c)}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                            {months.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                                    </div>
                                                )}
                                                maxDate={moment().toDate()}
                                                selected={createEvent.date && moment(createEvent.date).toDate()}
                                                onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                                onChange={this.onChangeDate}
                                                placeholderText="Enter Event Date"
                                            />
                                            <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                                this.state.opencal = !this.state.opencal;
                                                this._calendar.setOpen(this.state.opencal);
                                            }} />
                                        </div>
                                        <span className="validMsg">{this.validator.message('Event Date', createEvent.date, 'required')}</span>
                                    </div>
                                    {createEvent.event_type === 'Game' && <div className="w-50">
                                        <label className="common-lable">Umpire Name</label>
                                        <div className="form-group mb-0">
                                            <input type="text" name="umpire_name" value={createEvent.umpire_name} onChange={this.onHandleChange} className="form-control" placeholder="Enter Umpire Name" />
                                        </div>
                                    </div>}
                                    <div className="w-100">
                                        <label className="common-lable">Select Event Type</label>
                                        <div className="form-group mb-0">
                                            <div className="multi-radio">
                                                <div className="check_inputradio">
                                                    <label className="round-radio">Game
                                                        <input type="radio" id="game" name="event_type" value={createEvent.event_type} onChange={this.onHandleChange} value="Game" checked={createEvent.event_type === "Game" ? true : false} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="check_inputradio">
                                                    <label className="round-radio">Scrimmage
                                                        <input type="radio" id="scrimmage" name="event_type" value={createEvent.event_type} onChange={this.onHandleChange} value="Scrimmage" checked={createEvent.event_type === "Scrimmage" ? true : false} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="check_inputradio">
                                                    <label className="round-radio">Bullpen
                                                        <input type="radio" id="bullpen" name="event_type" value={createEvent.event_type} onChange={this.onHandleChange} value="Bullpen" checked={createEvent.event_type === "Bullpen" ? true : false} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="check_inputradio">
                                                    <label className="round-radio">BP
                                                        <input type="radio" id="bp" name="event_type" value={createEvent.event_type} onChange={this.onHandleChange} value="BP" checked={createEvent.event_type === "BP" ? true : false} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="validMsg">{this.validator.message('Event Type', createEvent.event_type, 'required')}</span>
                                    </div>
                                </div>
                                <div className="flex-align">
                                    <Link className="btn light-green" onClick={() => this.onSave()}>Submit</Link>
                                    <Link className="btn gray" to={"/fan-uploadcsv"}>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

fanCreateEvent.propTypes = {
    createEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

export default connect(mapStateToProps, { createEvent })(fanCreateEvent);

import React, { useEffect, useReducer, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";
import ReassignTeam from "../../popup/reassignTeam";
import commonCalls from "../../../businesslogic/commonCalls";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamInfo,
  postTeamInfo,
  reassignInvite
} from "../../../saga/actions/views/coach/profile/teamInfo.action";
import { postFileUpload } from "../../../saga/actions/common/api.action";
import { REGISTERINFO_SUCCESS } from "../../../saga/constants/view/coach/profile/personalInfo.const";
import Preloader from '../../../components/preloader';
import ImageCrop from '../../popup/imageCrop';
import { POST_REASSIGN_SUCCESS, TEAMINFO_SUCCESS } from "../../../saga/constants/view/coach/profile/teamInfo.const";

const initialState = {
  teamName: "",
  file: "",
  aboutTeam: "",
  you: "",
  opp: "",
  facTwitterLink: "",
  facInstagramLink: "",
  fileUploadLogoId: "",
  parentTeamId: "",
  teamId: "",
  loader: false,
  isEdited: "",
  shopFlg: "no",
  shoplink: ""
};

const cropInit = {
  cropPopup: false,
  renderFile: "",
  imgObj: "",
}

const reducer = (state, payload) => ({ ...state, ...payload });

function TeamInfo() {
  const commonCall = new commonCalls();
  const validator = useRef(new SimpleReactValidator());
  const shop_validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [team, setTeam] = useReducer(reducer, initialState);
  const {
    teamName,
    file,
    aboutTeam,
    you,
    opp,
    facInstagramLink,
    facTwitterLink,
    fileUploadLogoId,
    parentTeamId,
    teamId,
    isEdited,
    loader,
    shopFlg,
    shoplink
  } = team;
  const { teamInfoData: _TI, postReassignData: _CREATE } = useSelector((state) => state.teamInfo);
  const { fileUploadData: _LOGO } = useSelector((state) => state.api);
  const { registerInfoData: _REG } = useSelector((state) => state.personalInfo);
  const [openTwitterLink, setOpenTwitterLink] = useState(false);
  const [openInstaLink, setOpenInstaLink] = useState(false);
  const [openReassignTeam, setOpenReassignTeam] = useState(false);
  const [crop, setCrop] = useState(false, cropInit);
  const {
    cropPopup,
    renderFile,
    imgObj,
  } = crop;
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    getTeamInfo()(dispatch);
  }, []);

  useEffect(() => {
    if (_TI) {
      console.log("_TI:--", _TI);
      if (_TI.hasOwnProperty('parentTeam')) {
        let arr = _TI.team.length !== 0 && _TI.team[0].teamRecord && _TI.team[0].teamRecord.split("-");
        setTeam({
          teamName: _TI.parentTeam.name,
          aboutTeam: _TI.parentTeam.description,
          facInstagramLink: _TI.parentTeam.hasOwnProperty('social-link') ? _TI.parentTeam["social-link"] && _TI.parentTeam["social-link"].insta : "",
          facTwitterLink: _TI.parentTeam.hasOwnProperty('social-link') ? _TI.parentTeam["social-link"] && _TI.parentTeam["social-link"].twt : "",
          you: _TI.team.length !== 0 ? _TI.team[0].teamRecord && arr.length !== 0 && arr[0] : "",
          opp: _TI.team.length !== 0 ? _TI.team[0].teamRecord && arr.length !== 0 && arr[1] : "",
          file: _TI.parentTeam.teamLogo && _TI.parentTeam.teamLogo.filePath,
          fileUploadLogoId: _TI.parentTeam.teamLogo && _TI.parentTeam.teamLogo._id,
          parentTeamId: _TI.parentTeam._id,
          teamId: _TI.team.length !== 0 && _TI.team[0]._id,
          isEdited: _TI.allowToEdit,
          shoplink: _TI.parentTeam.shoplink,
          shopFlg: _TI.parentTeam.shoplink ? "yes" : "no"
        });
      }
      setLoader(false);
      dispatch({
        type: TEAMINFO_SUCCESS,
        payload: null,
      });
    }
  }, [_TI]);

  useEffect(() => {
    if (_REG) {
      if (_REG.flag) {
        commonCall.successMessage(_REG.message);
        getTeamInfo()(dispatch);
      } else if (!_REG.flag) {
        commonCall.errorMessage(_REG.message);
      }
      dispatch({
        type: REGISTERINFO_SUCCESS,
        payload: null,
      });
      setLoader(false);
    }
    if (_CREATE) {
      if (_CREATE.flag) {
        console.log("_CREATE.message:--", _CREATE.message);
        commonCall.successMessage(_CREATE.message);
        dispatch({
          type: POST_REASSIGN_SUCCESS,
          payload: null,
        });
      }
    }
  }, [_REG, _CREATE]);

  useEffect(() => {
    if (_LOGO) {
      setTeam({
        fileUploadLogoId: _LOGO._id,
        file: _LOGO.filePath,
      });
      setCrop({
        imgObj: "",
        cropPopup: false,
        renderFile: ""
      });
      document.body.classList.remove('modal-open');
      setLoader(false);
    }
  }, [_LOGO]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "you" ||
      name === "opp"
    ) {
      const re = /^[0-9\b]+$/;
      if (re.test(value) || value.length <= 0) {
        setTeam({
          ...team,
          [name]: value,
        });
      }
    } else {
      setTeam({
        ...team,
        [name]: value,
      });
    }
  };

  const changeImage = (event) => {
    let file = event.target.files[0];
    if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png") {
      return commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
    }
    setCrop({
      imgObj: file,
      cropPopup: true,
      renderFile: URL.createObjectURL(file)
    });
  };

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    setCrop({
      cropPopup: false
    });
  }

  const onHadleObject = (obj) => {
    setLoader(true);
    postFileUpload(obj.file, 10)(dispatch);
  }

  const removeImage = () => {
    setTeam({
      file: null,
    });
  };

  const handleTwitterEvent = () => {
    setTeam({
      ...team,
      facTwitterLink: facTwitterLink,
    });
    setOpenTwitterLink(false);

  };

  const handleInstagramEvent = () => {

    setTeam({
      ...team,
      facInstagramLink: facInstagramLink,
    });
    setOpenInstaLink(false);

  };

  const handleSubmit = () => {
    let dTeam = {
      "parentTeamId": parentTeamId,
      "teamId": teamId,
      "name": teamName,
      "teamLogo": fileUploadLogoId,
      "description": aboutTeam,
      "teamRecord": you + "-" + opp,
      "social-link": {
        "insta": facInstagramLink,
        "twt": facTwitterLink
      },
      shoplink: shopFlg === "yes" ? shoplink : ""
    }

    handlePostData(dTeam);
  };

  const handlePostData = (data) => {
    try {
      if (validator.current.allValid()) {
        if (shopFlg === "yes") {
          if (shop_validator.current.allValid()) {
            setLoader(true);
            postTeamInfo(data)(dispatch);
            getTeamInfo()(dispatch);
          } else {
            shop_validator.current.showMessages(true);
            forceUpdate(1);
          }
        } else {
          setLoader(true);
          postTeamInfo(data)(dispatch);
          getTeamInfo()(dispatch);
        }
      } else {
        validator.current.showMessages(true);
        forceUpdate(1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onHandleClose = () => {
    document.body.classList.remove("modal-open");
    setOpenReassignTeam(false);
  };

  const setLoader = (val) => {
    setTeam({
      loader: val
    });
  }

  return (
    <>
      <section className="before-dashboard">
        <div className="white-bg">
          <div className="reassign_team">
            <label className="common-lable mt-0">Name Of Your Team</label>
            {
              !isEdited == false &&
              <Link
                onClick={() => setOpenReassignTeam(true)}
              >
                Reassign Team
            </Link>
            }
          </div>
          <div className="form-group mb-0">
            <input
              type="text"
              className="form-control"
              placeholder="Name Of Your Team"
              name="teamName"
              value={teamName}
              onChange={handleChange}
              disabled={!isEdited && true}
            />
            <span className="validMsg">
              {validator.current.message(
                "Team Name",
                teamName,
                "required|alpha_num_dash_space",
                { className: "text-danger" }
              )}
            </span>
          </div>
          <label className="common-lable">Upload Team Logo</label>
          <div className="dragdrop-img">
            <div className="create_event_fileUpload file_custom_upload text-center">
              <i className="fas fa-cloud-upload-alt"></i>
              <p>Click or drop Documents</p>
              {/* .heic,.HEIC, */}
              <input
                name="logofile"
                onClick={(ev) => { ev.target.value = null }}
                type="file"
                className="img_file_input"
                accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png"
                onChange={changeImage}
                disabled={!isEdited && true}
              />
              {file &&
                <div className="preview_img">
                  <i className="fas fa-times" onClick={removeImage}></i>
                  <img className="preview_img_inner" src={file} />
                </div>
              }
            </div>
          </div>
          <span className="validMsg">
            {validator.current.message("Team Logo", file, "required", {
              className: "text-danger",
            })}
          </span>
          <label className="common-lable">Tell Us About Your Team</label>
          <textarea
            id="subject"
            rows="6"
            value={aboutTeam}
            onChange={handleChange}
            name="aboutTeam"
            placeholder="Tell Us About Your Team"
            className="form-control"
            disabled={!isEdited && true}
          ></textarea>
          {/* <span className="validMsg">
            {validator.current.message("About Team", aboutTeam, "required", {
              className: "text-danger",
            })}
          </span> */}
          <label className="common-lable">Team Record</label>
          <div className="check_input row">
            <div className="check_inputbox">
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="00"
                  value={you}
                  name="you"
                  onChange={handleChange}
                  disabled={!isEdited && true}
                />
                {/* <span className="validMsg">
                  {validator.current.message("Score", you, "required", {
                    className: "text-danger",
                  })}
                </span> */}
              </div>
            </div>
            <div className="check_inputbox">
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="00"
                  value={opp}
                  name="opp"
                  onChange={handleChange}
                  disabled={!isEdited && true}
                />
                {/* <span className="validMsg">
                  {validator.current.message(
                    "Opponent Score",
                    opp,
                    "required",
                    { className: "text-danger" }
                  )}
                </span> */}
              </div>
            </div>
          </div>

          <label className="common-lable">Link Your Social Accounts</label>
          <div className="check_input row">
            <div className="check_box add_filed">
              <Link
                onClick={() => setOpenTwitterLink(true)}
                className="twitter social_btn w-100"
              >
                <img src={imagesArr.twitter} alt="twitter" />
                {
                  team.facTwitterLink ?
                    "@" + team.facTwitterLink
                    :
                    "Link Twitter account"
                }
              </Link>
              {isEdited && openTwitterLink && (
                <div className="form-group">
                  <div className="serch-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="@Name"
                      value={facTwitterLink}
                      name="facTwitterLink"
                      onChange={handleChange}
                      disabled={!isEdited && true}
                    />
                    <Link onClick={handleTwitterEvent} className="serch-btn">
                      save
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="check_box add_filed">
              <Link
                onClick={() => setOpenInstaLink(true)}
                className="insta social_btn w-100 mr-0"
              >
                <img src={imagesArr.insta} alt="insta" />
                {
                  team.facInstagramLink ?
                    team.facInstagramLink
                    :
                    "Link Instagram account"
                }
              </Link>
              {isEdited && openInstaLink && (
                <div className="form-group">
                  <div className="serch-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={facInstagramLink}
                      name="facInstagramLink"
                      onChange={handleChange}
                      disabled={!isEdited && true}
                    />
                    <Link onClick={handleInstagramEvent} className="serch-btn">
                      save
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>

          <label className="common-lable">Do you have a team store?</label>
          <div className="check_input row">
            <div className="check_box">
              <div className="checkbox">
                <input
                  type="radio"
                  name="shopFlg"
                  onChange={handleChange}
                  value="yes"
                  checked={shopFlg === "yes"}
                />
                <label>Yes </label>
              </div>
              <div className="checkbox mr-0">
                <input
                  type="radio"
                  name="shopFlg"
                  onChange={handleChange}
                  value="no"
                  checked={shopFlg === "no"}
                />
                <label>No </label>
              </div>
            </div>
          </div>
          {
            shopFlg === "yes" &&
            <>
              <label className="common-lable">Team Store URL</label>
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Team Store Url"
                  name="shoplink"
                  value={shoplink}
                  onChange={handleChange}
                  disabled={!isEdited && true}
                />
              </div>
              <span className="validMsg">
                {shop_validator.current.message(
                  "Store url",
                  shoplink,
                  "required",
                  { className: "text-danger" }
                )}
              </span>
            </>
          }

          {!isEdited == false && <div className="flex-align">
            <Link className="btn gray" to="/coach-dashboard">
              Cancel
            </Link>
            <Link className="btn light-green" onClick={handleSubmit}>
              Save
            </Link>
          </div>}
        </div>
      </section>
      <Preloader flag={loader} />
      {openReassignTeam && (
        <ReassignTeam flag={openReassignTeam} close={onHandleClose} type="1" />
      )}
      <ImageCrop
        imgObj={imgObj}
        flag={cropPopup}
        close={handleClose}
        renderImg={renderFile}
        getObj={onHadleObject}
      />
    </>
  );
}

export default TeamInfo;

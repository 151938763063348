import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import commonCalls from "../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { imagesArr } from '../../../assets/images';
import { saveSource, saveRapsodoSource } from '../../../saga/actions/common/uploadcsv.action';
import Preloader from '../../../components/preloader';

class coachDataSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            sourceData: {
                file_type: "",
                file_path: [""],
                event: ""
            },
            rapsodoData: {
                file_type: "",
                file_path: [""],
                file_subtype: "",
                event: ""
            },
            videoType: '1',
            showVideo: false,
            videoSource: {
                v_source: "",
                event: "",
                v_column: [""]
            },
            file_subtype: ""
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.browValidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.fieldvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.rapValidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.savValidator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        this.setLoader(true);
        window.scrollTo(0, 0);
        this.getId();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.saveSourceReq &&
            nextProps.uploadCsvInfo.saveSourceData
        ) {
            console.log("nextProps from saveSourceData:----", nextProps.uploadCsvInfo.saveSourceData);
            nextProps.uploadCsvInfo.saveSourceReq = false;
            if (nextProps.uploadCsvInfo.saveSourceData.flag) {
                this.setLoader(false);
                this.commonCall.successMessage(nextProps.uploadCsvInfo.saveSourceData.message);
                if (localStorage.getItem("eventType") === "Scouting") {
                    this.props.history.push(`/coach-scoutinglink/${nextProps.uploadCsvInfo.saveSourceData.result._id}`);
                } else {
                    this.props.history.push(`/coach-linkdata/${this.state.sourceData.event}/${nextProps.uploadCsvInfo.saveSourceData.result._id}`);
                }
                nextProps.uploadCsvInfo.saveSourceData = null;
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.saveSourceData.message);
                nextProps.uploadCsvInfo.saveSourceData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.uploadCsvInfo &&
            nextProps.uploadCsvInfo.saveRapsodoSourceReq &&
            nextProps.uploadCsvInfo.saveRapsodoSourceData
        ) {
            console.log("nextProps from saveRapsodoSourceData:----", nextProps.uploadCsvInfo.saveRapsodoSourceData);
            nextProps.uploadCsvInfo.saveRapsodoSourceReq = false;
            if (nextProps.uploadCsvInfo.saveRapsodoSourceData.flag) {
                this.setLoader(false);
                this.commonCall.successMessage(nextProps.uploadCsvInfo.saveRapsodoSourceData.message);
                this.props.history.push(`/coach-linkdata/${this.state.rapsodoData.event}/${nextProps.uploadCsvInfo.saveRapsodoSourceData.result._id}`);
                nextProps.uploadCsvInfo.saveRapsodoSourceData = null;
            } else {
                this.commonCall.errorMessage(nextProps.uploadCsvInfo.saveRapsodoSourceData.message);
                nextProps.uploadCsvInfo.saveRapsodoSourceData = null;
                this.setLoader(false);
            }
        }
    }

    getId = () => {
        let url = new URL(document.location);
        let eid = url.pathname.split("/")[2];

        this.setState((prevState) => ({
            ...prevState,
            sourceData: {
                ...prevState.sourceData,
                event: eid
            },
            rapsodoData: {
                ...prevState.rapsodoData,
                event: eid
            },
            showVideo: localStorage.getItem("showVideo") !== null ? JSON.parse(localStorage.getItem("showVideo")) : false,
            videoSource: {
                ...prevState.videoSource,
                event: eid,
            },
        }), () => {
            this.setLoader(false);
        });
    }

    onHandleChange = (e, i) => {
        const { name, value } = e.target;
        if (name === "file_path") {
            let file = e.target.files[0];
            const arr = [...this.state.sourceData.file_path];
            arr[i] = file;
            this.setState((prevState) => ({
                ...prevState,
                sourceData: {
                    ...prevState.sourceData,
                    file_path: arr
                },
            }));
        } else if (name === "videoType") {
            this.setState((prevState) => ({
                ...prevState,
                [name]: value
            }));
        } else if (name === "v_column") {
            const arr = [...this.state.videoSource.v_column];
            arr[i] = value;
            this.setState((prevState) => ({
                ...prevState,
                videoSource: {
                    ...prevState.videoSource,
                    v_column: arr
                },
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sourceData: {
                    ...prevState.sourceData,
                    [name]: value
                },
                rapsodoData: {
                    ...prevState.rapsodoData,
                    [name]: value
                },
            }));
        }
    }

    onHandleRapChange = (e, i) => {
        const { name, value } = e.target;
        if (name === "file_path") {
            let file = e.target.files[0];
            const arr = [...this.state.rapsodoData.file_path];
            arr[i] = file;
            this.setState((prevState) => ({
                ...prevState,
                rapsodoData: {
                    ...prevState.rapsodoData,
                    file_path: arr
                },
            }));

            // let file = e.target.files[0];
            // this.setState((prevState) => ({
            //     ...prevState,
            //     rapsodoData: {
            //         ...prevState.rapsodoData,
            //         [name]: file
            //     },
            // }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                rapsodoData: {
                    ...prevState.rapsodoData,
                    [name]: value
                },
            }));
        }
    }

    onSave = () => {
        if (this.validator.allValid()) {
            if (this.state.sourceData.file_type === "FlightScope" ||
                this.state.sourceData.file_type === "TrackMan" ||
                this.state.sourceData.file_type === "Yakkertech" ||
                this.state.sourceData.file_type === "Blast" ||
                this.state.sourceData.file_type === "TrackMan Portable Hitting" ||
                this.state.sourceData.file_type === "TrackMan Portable Pitching" ||
                this.state.sourceData.file_type === "Pocket Radar" ||
                this.state.sourceData.file_type === "Stalker Radar" ||
                this.state.sourceData.file_type === "Hittrax") {
                if (this.browValidator.allValid()) {
                    console.log("this.state.sourceData:---", this.state.sourceData);
                    this.setLoader(true);
                    this.props.saveSource(this.state.sourceData, "Coach");
                } else {
                    this.browValidator.showMessages();
                    this.forceUpdate();
                }
            }

            if (this.state.sourceData.file_type === "Rapsodo") {
                if (this.rapValidator.allValid()) {
                    console.log("this.state.rapsodoData:---", this.state.rapsodoData);
                    this.setLoader(true);
                    this.props.saveRapsodoSource(this.state.rapsodoData, "Coach");
                } else {
                    this.rapValidator.showMessages();
                    this.forceUpdate();
                }
            }

            if (this.state.sourceData.file_type === "Video") {
                console.log("this.state.videoType:---", this.state.videoType);
                if (this.state.videoType === '1') {
                    this.props.history.push(`/coach-uploadvideo/${this.state.sourceData.event}`);
                }

                if (this.state.videoType === '2') {
                    if (this.fieldvalidator.allValid()) {
                        console.log("this.state.videoSource:---", this.state.videoSource);
                        localStorage.setItem("videoSource", JSON.stringify(this.state.videoSource));
                        this.props.history.push(`/coach-event-model/${this.state.videoSource.event}`);
                    } else {
                        this.fieldvalidator.showMessages();
                        this.forceUpdate();
                    }
                }
            }

            if (this.state.sourceData.file_type === "Savant") {
                if (this.savValidator.allValid()) {
                    let sendData = {
                        event: this.state.sourceData.event,
                        file_path: this.state.sourceData.file_path,
                        file_type: this.state.sourceData.file_type + " " + this.state.file_subtype
                    }
                    this.setLoader(true);
                    this.props.saveSource(sendData, "Coach");
                } else {
                    this.savValidator.showMessages();
                    this.forceUpdate();
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onCancel = () => {
        localStorage.removeItem("showVideo");
        this.props.history.push('/coach-uploadcsv');
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    addSourceBrowse = () => {
        if (this.state.sourceData.file_path.length < 20) {
            this.setState((prevState) => ({
                ...prevState,
                sourceData: {
                    ...prevState.sourceData,
                    file_path: [...this.state.sourceData.file_path, ""]
                },
            }));
        }
    }

    removeSourceBrowse = (i) => {
        const delArr = [...this.state.sourceData.file_path];
        delArr.splice(i, 1);
        this.setState((prevState) => ({
            ...prevState,
            sourceData: {
                ...prevState.sourceData,
                file_path: delArr
            },
        }));
    }

    addRapsodoBrowse = () => {
        if (this.state.rapsodoData.file_path.length < 20) {
            this.setState((prevState) => ({
                ...prevState,
                rapsodoData: {
                    ...prevState.rapsodoData,
                    file_path: [...this.state.rapsodoData.file_path, ""]
                },
            }));
        }
    }

    removeRapsodoBrowse = (i) => {
        const delArr = [...this.state.rapsodoData.file_path];
        delArr.splice(i, 1);
        this.setState((prevState) => ({
            ...prevState,
            rapsodoData: {
                ...prevState.rapsodoData,
                file_path: delArr
            },
        }));
    }

    addField = () => {
        this.setState((prevState) => ({
            ...prevState,
            videoSource: {
                ...prevState.videoSource,
                v_column: [...this.state.videoSource.v_column, ""]
            },
        }));
    }

    removeField = (i) => {
        const delArr = [...this.state.videoSource.v_column];
        delArr.splice(i, 1);
        this.setState((prevState) => ({
            ...prevState,
            videoSource: {
                ...prevState.videoSource,
                v_column: delArr
            },
        }));
    }

    onHandleSavChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    render() {
        const { loader, sourceData, videoType, showVideo, videoSource, rapsodoData, file_subtype } = this.state

        return (
            <>
                <Header />
                {/* <CommonHeroBanner dataList="" /> */}
                {/* Uploadcsv Datasource */}
                <section className="uploadcsv-datasource uploadcsv">
                    <div className="container">
                        <div className="white-bg">
                            <h3>Data Source</h3>
                            {/* <p className="mb-0">Add data source</p> */}
                            <form>
                                <div className="row info-wrap">
                                    <div className="check_input w-100">
                                        <div className="check_inputbox form-group">
                                            <label className="common-lable">Device</label>
                                            <select name="file_type" value={sourceData.file_type} onChange={this.onHandleChange} className="custom-select">
                                                <option value="" selected disabled>Select Device</option>
                                                <option value="Yakkertech">Yakkertech</option>
                                                <option value="TrackMan">TrackMan</option>
                                                {localStorage.getItem("eventType") !== "Scouting" &&
                                                    <>
                                                        <option value="TrackMan Portable Hitting">TrackMan Portable Hitting</option>
                                                        <option value="TrackMan Portable Pitching">TrackMan Portable Pitching</option>
                                                        <option value="Rapsodo">Rapsodo</option>
                                                        <option value="Blast">Blast</option>
                                                        <option value="Hittrax">Hittrax</option>
                                                        <option value="FlightScope">FlightScope</option>
                                                        <option value="Savant">Savant</option>
                                                        <option value="Pocket Radar">Pocket Radar</option>
                                                        <option value="Stalker Radar">Stalker Radar</option>
                                                        {showVideo &&
                                                            <option value="Video">Video</option>}
                                                    </>


                                                }
                                            </select>
                                            <span className="validMsg">{this.validator.message('Device', sourceData.file_type, 'required')}</span>
                                        </div>
                                    </div>

                                    {
                                        sourceData.file_type === "Savant" &&
                                        <div className="new_add_file w-100">
                                            <div className="uploadradio-button">
                                                <label className="round-radio">
                                                    Pitching
                                                    <input type="radio" name="file_subtype" checked={file_subtype === "Pitching" ? true : false} value="Pitching" onChange={(e) => this.onHandleSavChange(e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="round-radio">
                                                    Hitting
                                                    <input type="radio" name="file_subtype" checked={file_subtype === "Hitting" ? true : false} value="Hitting" onChange={(e) => this.onHandleSavChange(e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <span className="validMsg">{this.savValidator.message('file type', file_subtype, 'required')}</span>
                                        </div>
                                    }

                                    {
                                        (sourceData.file_type === "FlightScope" ||
                                            sourceData.file_type === "TrackMan" ||
                                            sourceData.file_type === "Yakkertech" ||
                                            sourceData.file_type === "Blast" ||
                                            sourceData.file_type === "TrackMan Portable Hitting" ||
                                            sourceData.file_type === "TrackMan Portable Pitching" ||
                                            sourceData.file_type === "Hittrax" ||
                                            sourceData.file_type === "Pocket Radar" ||
                                            sourceData.file_type === "Stalker Radar" ||
                                            sourceData.file_type === "Savant") &&
                                        <>
                                            {
                                                localStorage.getItem("eventType") !== "Scouting" ?
                                                    sourceData.file_path.map((item, i) => {
                                                        return (
                                                            <div className="form-group w-100" key={i}>
                                                                <div className="icon-input">
                                                                    <div className="w-100 selectfile selectfile-upload">
                                                                        <div className="dragdrop-img">
                                                                            <div className="create_event_fileUpload file_custom_upload text-center">
                                                                                <p>Browse</p>
                                                                                <input name="file_path" onChange={(e) => this.onHandleChange(e, i)} type="file" className="img_file_input" accept=".csv,.Xlsx,.xlsx,.CSV" onClick={(event) => { event.target.value = null }} />
                                                                            </div>
                                                                        </div>
                                                                        <p>{item ? item.name : "No of file selected"}</p>
                                                                    </div>
                                                                    {
                                                                        i === 0 && sourceData.file_path.length < 20 ?
                                                                            <Link onClick={() => this.addSourceBrowse()}>
                                                                                <img src={imagesArr.claim_plus} alt="cliamplus" />
                                                                            </Link>
                                                                            :
                                                                            <Link onClick={() => this.removeSourceBrowse(i)}>
                                                                                <img src={imagesArr.claim_minus} alt="claimminus" />
                                                                            </Link>
                                                                    }
                                                                </div>
                                                                <span className="validMsg">{this.browValidator.message('Browse', item, 'required')}</span>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <>
                                                        <div className="w-100 selectfile">
                                                            <div className="dragdrop-img">
                                                                <div className="create_event_fileUpload file_custom_upload text-center">
                                                                    <p>Browse</p>
                                                                    <input name="file_path" onChange={(e) => this.onHandleChange(e, 0)} type="file" className="img_file_input" accept=".csv,.Xlsx,.xlsx,.CSV" onClick={(event) => { event.target.value = null }} />
                                                                </div>
                                                            </div>
                                                            <p>{sourceData.file_path.length ? sourceData.file_path[0]?.name : "No of file selected"}</p>
                                                        </div>
                                                        <span className="validMsg">{this.browValidator.message('Browse', sourceData.file_path[0], 'required')}</span>
                                                    </>

                                            }

                                            {/* <div className="w-100 selectfile">
                                                <div className="dragdrop-img">
                                                    <div className="create_event_fileUpload file_custom_upload text-center">
                                                        <p>Browse</p>
                                                        <input name="file_path" onChange={this.onHandleChange} type="file" className="img_file_input" accept=".csv,.Xlsx,.xlsx,.CSV" multiple onClick={(event)=> { event.target.value = null }} />
                                                    </div>
                                                </div>
                                                <p>{sourceData.file_path ? sourceData.file_path.name : "No of file selected"}</p>
                                            </div>
                                            <span className="validMsg">{this.browValidator.message('Browse', sourceData.file_path, 'required')}</span> */}
                                        </>
                                    }
                                    {
                                        sourceData.file_type === "Rapsodo" &&
                                        <>
                                            <div className="new_add_file w-100">
                                                <div className="uploadradio-button">
                                                    <label className="round-radio">
                                                        Pitching Lite
                                                        <input type="radio" name="file_subtype" value="Pitching Lite" onChange={this.onHandleRapChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="round-radio">
                                                        Pitching Pro
                                                        <input type="radio" name="file_subtype" value="Pitching Pro" onChange={this.onHandleRapChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="round-radio">
                                                        Hitting Lite
                                                        <input type="radio" name="file_subtype" value="Hitting Lite" onChange={this.onHandleRapChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="round-radio">
                                                        Hitting Pro
                                                        <input type="radio" name="file_subtype" value="Hitting Pro" onChange={this.onHandleRapChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <span className="validMsg">{this.rapValidator.message('Rapsodo Type', rapsodoData.file_subtype, 'required')}</span>
                                            </div>

                                            {
                                                rapsodoData.file_path.map((item, i) => {
                                                    return (
                                                        <div className="form-group w-100" key={i}>
                                                            <div className="icon-input">
                                                                <div className="w-100 selectfile selectfile-upload">
                                                                    <div className="dragdrop-img">
                                                                        <div className="create_event_fileUpload file_custom_upload text-center">
                                                                            <p>Browse</p>
                                                                            <input name="file_path" onChange={(e) => this.onHandleRapChange(e, i)} type="file" className="img_file_input" accept=".csv,.Xlsx,.xlsx,.CSV" onClick={(event) => { event.target.value = null }} />
                                                                        </div>
                                                                    </div>
                                                                    <p>{item ? item.name : "No of file selected"}</p>
                                                                </div>
                                                                {
                                                                    i === 0 && rapsodoData.file_path.length < 20 ?
                                                                        <Link onClick={() => this.addRapsodoBrowse()}>
                                                                            <img src={imagesArr.claim_plus} alt="cliamplus" />
                                                                        </Link>
                                                                        :
                                                                        <Link onClick={() => this.removeRapsodoBrowse(i)}>
                                                                            <img src={imagesArr.claim_minus} alt="claimminus" />
                                                                        </Link>
                                                                }
                                                            </div>
                                                            <span className="validMsg">{this.rapValidator.message('Browse', item, 'required')}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <div className="w-100 selectfile">
                                                <div className="dragdrop-img">
                                                    <div className="create_event_fileUpload file_custom_upload text-center">
                                                        <p>Browse</p>
                                                        <input name="file_path" onChange={this.onHandleRapChange} type="file" className="img_file_input" accept=".csv,.Xlsx,.xlsx,.CSV" onClick={(event)=> { event.target.value = null }} />
                                                    </div>
                                                </div>
                                                <p>{rapsodoData.file_path ? rapsodoData.file_path.name : "No of file selected"}</p>
                                            </div>
                                            <span className="validMsg">{this.rapValidator.message('Browse', rapsodoData.file_path, 'required')}</span> */}
                                        </>
                                    }
                                    {
                                        sourceData.file_type === "Video" &&
                                        <>
                                            <div className="check_input w-100">
                                                <div className="check_inputbox form-group">
                                                    <select name="videoType" value={videoType} onChange={this.onHandleChange} className="custom-select">
                                                        <option value="1" >One video for entire event</option>
                                                        <option value="2" >One video for pitch/swing</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        sourceData.file_type === "Video" &&
                                        videoType === "2" &&
                                        <>
                                            {
                                                videoSource.v_column.length !== 0 &&
                                                videoSource.v_column.map((field, i) => {
                                                    return (
                                                        <>
                                                            <div className="form-group w-100">
                                                                <div className="icon-input">
                                                                    <input type="text" name="v_column" value={field} onChange={(e) => this.onHandleChange(e, i)} className="form-control" placeholder="Video Column" />
                                                                    {
                                                                        i === 0 ?
                                                                            <Link onClick={() => this.addField()}>
                                                                                <img src={imagesArr.claim_plus} alt="cliamplus" />
                                                                            </Link>
                                                                            :
                                                                            <Link onClick={() => this.removeField(i)}>
                                                                                <img src={imagesArr.claim_minus} alt="claimminus" />
                                                                            </Link>
                                                                    }
                                                                </div>
                                                                <span className="validMsg">{this.fieldvalidator.message('', field, 'required')}</span>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    }

                                    {/* {
                                        (sourceData.file_type === "FlightScope" ||
                                            sourceData.file_type === "TrackMan" ||
                                            sourceData.file_type === "HawkEye" ||
                                            sourceData.file_type === "Yakkertech" ||
                                            sourceData.file_type === "Blast" ||
                                            sourceData.file_type === "TrackMan Portable Hitting" ||
                                            sourceData.file_type === "TrackMan Portable Pitching" ||
                                            sourceData.file_type === "Hittrax" ||
                                            sourceData.file_type === "Pocket Radar" ||
                                            sourceData.file_type === "Stalker Radar" ||
                                            sourceData.file_type === "Rapsodo" ||
                                            sourceData.file_type === "Savant") && localStorage.getItem("eventType") !== "Scouting" &&
                                        <>
                                            <label className="common-lable">Video</label>
                                            {
                                                this.state.videoObj.map((item, i) => {
                                                        return (
                                                            <div className="check_inputbox form-group w-100" key={i}>
                                                                <label className="common-lable">Camera Angle</label>
                                                                <select className="custom-select" name="cameraAngle" value={this.state.videoObj[i].cameraAngle} onChange={(e) => this.onHandleCameraChange(e, i)}>
                                                                    <option value="" selected disabled>Camera Angle</option>
                                                                    <option value="High Home">High Home</option>
                                                                    <option value="Low Home">Low Home</option>
                                                                    <option value="Centerfield">Centerfield</option>
                                                                    <option value="Left Hitter">Left Hitter</option>
                                                                    <option value="Right Hitter">Right Hitter</option>
                                                                    <option value="Left Pitcher">Left Pitcher</option>
                                                                    <option value="Right Pitcher">Right Pitcher</option>
                                                                </select>
                                                                {
                                                                    this.state.videoObj[i].videoPath.name &&
                                                                    <span className="validMsg">{this.videoValidator.message('Camera Angle', this.state.videoObj[i].cameraAngle, 'required')}</span>
                                                                }
                                                                <div className="icon-input mt-10">
                                                                    <div className="w-100 selectfile selectfile-upload">
                                                                        <div className="dragdrop-img">
                                                                            <div className="create_event_fileUpload file_custom_upload text-center">
                                                                                <p>Browse</p>
                                                                                <input name="file_path" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".mp4,.mov,.avi,.flv,.MP4,.MOV,.AVI,.FLV" onChange={(e) => this.handleVideo(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <p>{this.state.videoObj[i].videoPath.name ? this.state.videoObj[i].videoPath.name : "No of file selected"}</p>
                                                                    </div>
                                                                    {
                                                                        i === 0 ?
                                                                            <Link onClick={() => this.addVideoBrowse()}>
                                                                                <img src={imagesArr.claim_plus} alt="cliamplus" />
                                                                            </Link>
                                                                            :
                                                                            <Link onClick={() => this.removeVideoBrowse(i)}>
                                                                                <img src={imagesArr.claim_minus} alt="claimminus" />
                                                                            </Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                        </>
                                    } */}
                                    {/* <span className="validMsg">{this.videoValidator.message('video', this.state.videoObj[i].videoPath.name, 'required')}</span> */}

                                </div>

                                <div className="flex-align">
                                    <Link className="btn light-green" onClick={() => this.onSave()}>Submit</Link>
                                    <Link className="btn gray" onClick={() => this.onCancel()}>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

coachDataSource.propTypes = {
    saveSource: PropTypes.func.isRequired,
    saveRapsodoSource: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    uploadCsvInfo: state.uploadCsvInfo,
});

const sourceComponent = connect(mapStateToProps, { saveSource, saveRapsodoSource })(coachDataSource);
export default withRouter(sourceComponent);

export const GET_PLAYER_TEAM_SEARCH_REQUEST = 'GET_PLAYER_TEAM_SEARCH_REQUEST';
export const GET_PLAYER_TEAM_SEARCH_SUCCESS = 'GET_PLAYER_TEAM_SEARCH_SUCCESS';
export const GET_PLAYER_TEAM_SEARCH_ERROR = 'GET_PLAYER_TEAM_SEARCH_ERROR';

export const SAVE_PLAYER_TEAM_REQUEST = 'SAVE_PLAYER_TEAM_REQUEST';
export const SAVE_PLAYER_TEAM_SUCCESS = 'SAVE_PLAYER_TEAM_SUCCESS';
export const SAVE_PLAYER_TEAM_ERROR = 'SAVE_PLAYER_TEAM_ERROR';

export const SAVE_SKIP_PLAYER_REQUEST = 'SAVE_SKIP_PLAYER_REQUEST';
export const SAVE_SKIP_PLAYER_SUCCESS = 'SAVE_SKIP_PLAYER_SUCCESS';
export const SAVE_SKIP_PLAYER_ERROR = 'SAVE_SKIP_PLAYER_ERROR';



import React, { useState } from 'react'
import Footer from '../../../containers/footer'
import Header from '../../../containers/header'
import Preloader from '../../../components/preloader';
import { useHistory } from 'react-router-dom';
import AdsSlider from "../components/slider/adsSlider";
import EventRuleDetails from '../components/common/eventRuleDetails';

function Event() {
    const history = useHistory();
    const [loaderCount, setLoader] = useState(0);
    const [bannerReq, setBannerReq] = useState({
        moduleId: 1
    })

    const increment = () => {
        setLoader(loaderCount + 1);
    }

    const decrement = () => {
        setLoader(loaderCount - 1);
    }

    return (
        <div>
            <>
                <Header />
                <div className="event-main">
                      {/* banner section */}

                      <AdsSlider increment={increment} decrement={decrement} bannerReq={bannerReq} />

                    {/* Rules section */}
                      <EventRuleDetails increment={increment} decrement={decrement}/>

                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
            </>
        </div>
    )
}

export default Event
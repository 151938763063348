import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";



function DownloadApp({ flag, close }) {

  useEffect(() => {
    if (flag) {
      document.body.classList.add("modal-open");
    }
  }, []);

  return (
    <>
        <section className={`common-modal ${flag ? "show" : ""}`} style={{ background: "rgba(0,0,0,0.2)", }}>
          <div className="modal-dialog mobileapp-popup" style={{ maxWidth: "991px" }}>
            <div className="modal-content" style={{ background: "" }}>
              <div className="modal-header justify-content-end">

                <div className="modal_close" onClick={close}>
                  <img src={imagesArr.white_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body">
                <div className="downloadapp-content">
                  <div className="mobile-app">
                    <img src={imagesArr.mobileapp} />
                  </div>
                  <div className="download-info">
                    <img className="logo" src={imagesArr.dimond_connect} />
                    <div className="bar"></div>
                    <img src={imagesArr.mobileapp} className="mobile-view" />
                    <h3>Download our app on your smart device and play more</h3>
                    <div className="store-img">
                      <a href=""><img src={imagesArr.appstore} /></a>
                      {/* <a href=""><img src={imagesArr.playstore} /></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}

export default DownloadApp;

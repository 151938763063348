import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { imagesArr } from '../assets/images';
import { Link } from 'react-router-dom';
import LeftAuthImage from '../components/leftAuthImage';
import CommonHeroBanner from '../components/commonHeroBanner';
import OtpInput from 'react-otp-input';
import commonCalls from "../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { resendVerifyCode, fverifyCode } from '../saga/actions/authantication/verify.action';
import Preloader from '../components/preloader';

let timerOn = true;
class fverify extends Component {
    constructor(props) {

        super(props);
        this.state = {
            otp: "",
            token: "",
            mobile: "",
            email: "",
            second: "",
            timeOut: false,
            loader: false
        }
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.commonCall = new commonCalls();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.commonCall.getStorage("mobile", data => {
            if (data !== null) {
                this.setState({
                    mobile: data
                }, () => {
                    this.commonCall.getStorage("email", data => {
                        if (data !== null) {
                            this.setState({
                                email: data
                            }, () => {
                                this.timer(30, false);
                            })
                        }
                    })
                })
            }
        });
        this.getToken();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.verifyData &&
            nextProps.verifyData.fresendReq &&
            nextProps.verifyData.fresendData
        ) {
            nextProps.verifyData.fresendReq = false;
            this.commonCall.successMessage(nextProps.verifyData.fresendData.message);
            nextProps.verifyData.fresendData = null;
            this.setLoader(false);
        }

        if (nextProps.verifyData &&
            nextProps.verifyData.fverifyReq &&
            nextProps.verifyData.fverifyData
        ) {
            nextProps.verifyData.fverifyReq = false;
            this.commonCall.successMessage(nextProps.verifyData.fverifyData.message);
            this.setLoader(false);
            this.commonCall.removeStorage("mobile");
            this.commonCall.removeStorage("email");
            this.props.history.push(`/reset/${this.state.token}`);
            nextProps.verifyData.fverifyData = null;
        }

        if (nextProps.verifyData &&
            nextProps.verifyData.fverifyReq &&
            nextProps.verifyData.fverifyError
        ) {
            nextProps.verifyData.fverifyReq = false;
            this.setLoader(false);
            this.commonCall.errorMessage(nextProps.verifyData.fverifyError);
            nextProps.verifyData.fverifyError = null;
        }
    }

    getToken = () => {
        let url = new URL(document.location);
        let userToken = url.pathname.split("/")[2];
        this.setState({
            token: userToken
        });
    }

    handleChange = otp => {
        this.setState({ otp });
    }

    timer = (remaining, apicall) => {
        if (apicall) {
            let data = {
                resetPasswordToken: this.state.token,
            }
            this.setLoader(true);
            this.props.resendVerifyCode(data);
        }

        var s = remaining % 60;
        s = s < 10 ? '0' + s : s;

        this.setState({
            second: s,
            timeOut: false
        })

        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(() => {
                this.remainTime(remaining)
            }, 1000);
            return;
        }
        // Do timeout stuff here
        this.setState({ timeOut: true })
    }

    remainTime = (time) => {
        this.timer(time, false)
    }

    sendOTP = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            let data = {
                resetPasswordToken: this.state.token,
                otp: this.state.otp
            }
            this.setLoader(true);
            this.props.fverifyCode(data);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader } = this.state
        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-login">
                    <div className="container">
                        <div className="common-login">
                            <LeftAuthImage />
                            <div className="common-dec">
                                <div className="heading-for">
                                    <h3>Verification Code</h3>
                                    {(this.state.mobile && this.state.email && this.state.mobile !== "+1" ) ? <p>Please type the verification code send to <span>{this.state.mobile}</span> or <span>{this.state.email}</span></p>
                                    :(this.state.mobile && this.state.mobile !== "+1" ) ? <p>Please type the verification code send to <span>{this.state.mobile}</span></p> 
                                     : (this.state.email) && <p>Please type the verification code send to <span>{this.state.email}</span></p>}
                                </div>
                                <form className="verification_code">
                                    <div className="form-group">
                                        <OtpInput
                                            value={this.state.otp}
                                            onChange={this.handleChange}
                                            numInputs={4}
                                            inputStyle="form-control"
                                            isInputNum={true}
                                        />
                                    </div>
                                    <span className="validMsg">{this.validator.message('otp', this.state.otp, 'required')}</span>
                                    <div className="resend">
                                        {
                                            this.state.timeOut ?
                                                <Link className="resend" onClick={() => this.timer(30, true)}>Resend OTP</Link>
                                                :
                                                <p>Resend OTP in {this.state.second}</p>
                                        }
                                    </div>
                                    <button type="button" className="btn w-100 text-center" onClick={this.sendOTP} disabled={this.state.timeOut ? true : false}>Verify</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
            </>
        );
    }
}

fverify.propTypes = {
    resendVerifyCode: PropTypes.func.isRequired,
    fverifyCode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
});

export default connect(mapStateToProps, { resendVerifyCode, fverifyCode })(fverify);

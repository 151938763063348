import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from "../../assets/images";
import { getCoachTeamList, saveCoachDetails } from '../../saga/actions/views/company/pwRanking/profileSummary.action';
import * as data from "../../businesslogic/country.json";
import SimpleReactValidator from 'simple-react-validator';
class coachdetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                fname: "",
                lname: "",
                email: "",
                phone: "",
                teamName: "",
                city: "",
                state: ""
            },
            pid: "",
            teamList: [],
            stateList: []
        };

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount = () => {
        if (this.props.flag) {
            document.body.classList.add("modal-open");
            if (localStorage.getItem("webglToken")) {
                data.default.countries.map((a) => {
                    if (a.country == "United States") {
                        this.setState({
                            stateList: a.states,
                        }, () => {
                            if (this.props.popupStat === "edit") {
                                this.setState({
                                    sendReq: {
                                        fname: this.props.data.fname,
                                        lname: this.props.data.lname,
                                        email: this.props.data.email,
                                        phone: this.props.data.phone,
                                        teamName: this.props.data.teamName,
                                        city: this.props.data.city,
                                        state: this.props.data.state
                                    },
                                    pid: this.props.data._id
                                })
                            }
                            this.props.increment();
                            this.props.getCoachTeamList({ search: "" });
                        });
                    }
                });
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileInfo &&
            nextProps.profileInfo.getCoachTeamListReq &&
            nextProps.profileInfo.getCoachTeamListData
        ) {
            nextProps.profileInfo.getCoachTeamListReq = false;
            if (nextProps.profileInfo.getCoachTeamListData.flag) {
                this.setState({
                    teamList: nextProps.profileInfo.getCoachTeamListData.result.data
                }, () => {
                    nextProps.profileInfo.getCoachTeamListData = null;
                    this.props.decrement();
                })
            } else {
                nextProps.profileInfo.getCoachTeamListData = null;
                this.props.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.saveCoachDetailReq &&
            nextProps.profileInfo.saveCoachDetailData
        ) {
            nextProps.profileInfo.saveCoachDetailReq = false;
            if (nextProps.profileInfo.saveCoachDetailData.flag) {
                nextProps.profileInfo.saveCoachDetailData = null;
                this.props.decrement();
                this.props.close();
            } else {
                nextProps.profileInfo.saveCoachDetailData = null;
                this.props.decrement();
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendReq: {
                            ...prevState.sendReq,
                            [name]: value
                        }
                    }))
                }
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                sendReq: {
                    ...prevState.sendReq,
                    [name]: value
                }
            }))
        }
    }

    onlyNumber = (e) => {
        const keycode = e.which;
        if (keycode === 13) {
        } else if (
            !(
                e.shiftKey === false &&
                (keycode === 8 ||
                    keycode === 37 ||
                    keycode === 39 ||
                    (keycode >= 48 && keycode <= 57))
            )
        )
            e.preventDefault();
    };

    onSave = () => {
        if (this.props.popupStat === "edit") {
            this.state.sendReq.id = this.state.pid;
        }
        if (this.validator.allValid()) {
            this.props.increment();
            this.props.saveCoachDetails(this.state.sendReq, this.props.popupStat);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    render() {
        const { flag } = this.props;
        const { sendReq, teamList, stateList } = this.state;
        return (
            <>
                <section className={`common-modal ${flag ? "show" : ""}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>Coach Detail</h3>
                                <div className="modal_close" onClick={this.props.close}>
                                    <img src={imagesArr.white_modal_close} alt="modal_close" />
                                </div>
                            </div>
                            <div className="modal-body ">
                                <div className="team-body-inner">
                                    <form>
                                        <div class="form-group mb-0">
                                            <label class="common-lable">First Name</label>
                                            <input type="text" name="fname" value={sendReq.fname} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter first name" />
                                            <span className="validMsg">{this.validator.message('first name', sendReq.fname, 'required')}</span>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label class="common-lable">Last Name</label>
                                            <input type="text" name="lname" value={sendReq.lname} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter last name" />
                                            <span className="validMsg">{this.validator.message('lname name', sendReq.lname, 'required')}</span>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label class="common-lable">Email Address</label>
                                            <input type="email" name="email" value={sendReq.email} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter email address" style={{ cursor: this.props.popupStat === "edit" ? "not-allowed" : "auto" }} disabled={this.props.popupStat === "edit" ? true : false} />
                                            <span className="validMsg">{this.validator.message('email address', sendReq.email, 'required|email')}</span>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label class="common-lable">Phone Number</label>
                                            <input type="text" name="phone" value={sendReq.phone} onChange={(e) => this.onHandleChange(e)} onKeyPress={(e) => this.onlyNumber(e)} className="form-control" placeholder="Enter phone number" />
                                            <span className="validMsg">{this.validator.message('phone number', sendReq.phone, 'required')}</span>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="common-lable">Team Name</label>
                                            <select className="custom-select" name="teamName" value={sendReq.teamName} onChange={(e) => this.onHandleChange(e)} style={{ cursor: this.props.popupStat === "edit" ? "not-allowed" : "auto" }} disabled={this.props.popupStat === "edit" ? true : false}>
                                                <option value="">Select team</option>
                                                {
                                                    teamList &&
                                                    teamList.map((item, i) => {
                                                        return (
                                                            <option value={item.name}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="validMsg">{this.validator.message('team name', sendReq.teamName, 'required')}</span>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="common-lable">State</label>
                                            <select className="custom-select" name="state" value={sendReq.state} onChange={(e) => this.onHandleChange(e)}>
                                                <option value="">Select state</option>
                                                {
                                                    stateList &&
                                                    stateList.map((item, j) => {
                                                        return (
                                                            <option value={item}>{item}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="validMsg">{this.validator.message('state', sendReq.state, 'required')}</span>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label class="common-lable">City</label>
                                            <input type="text" name="city" value={sendReq.city} onChange={(e) => this.onHandleChange(e)} className="form-control" placeholder="Enter city" />
                                            <span className="validMsg">{this.validator.message('city', sendReq.city, 'required')}</span>
                                        </div>

                                        <div className="flex-align next-button">
                                            <a className="btn light-green" onClick={() => this.onSave()}>Save</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        );
    }

}

coachdetail.propTypes = {
    getCoachTeamList: PropTypes.func.isRequired,
    saveCoachDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileInfo: state.profileSummaryInfo
});

export default connect(mapStateToProps, { getCoachTeamList, saveCoachDetails })(coachdetail);

import axios from "axios";
import { API_Url, Auth_Fan_Key, checkIsValidFanToken } from "../../../../helpers/authToken";
import {
  FAN_PERSONALINFO_SUCCESS,
  FAN_PERSONALINFO_ERROR,
  FAN_REGISTERINFO_SUCCESS,
  FAN_REGISTERINFO_ERROR,
  PHONE_VERIFY_FAN_SUCCESS,
  PHONE_VERIFY_FAN_ERROR
} from "../../../../constants/view/fan/setting/personalInfo.const";
import histrory from '../../../../../History';


export const getPersonalInfo = () => (dispatch) => {
    checkIsValidFanToken(histrory)
  axios({
    method: "GET",
    url: `${API_Url}bc/fan/basic`,
    headers: {
      "x-auth-token": Auth_Fan_Key,
      Accept: "application/json",
    },
  })
    .then((personal) => {
      dispatch({
        type: FAN_PERSONALINFO_SUCCESS,
        payload: personal.data.result,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: FAN_PERSONALINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

export const postPersonalInfo = (registerData) => (dispatch) => {
    checkIsValidFanToken(histrory)
  return axios({
    method: "POST",
    url: `${API_Url}bc/fan/basic/edit`,
    data: registerData,
    headers: {
      "x-auth-token": Auth_Fan_Key,
      Accept: "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: FAN_REGISTERINFO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401)
        dispatch({
          type: FAN_REGISTERINFO_ERROR,
          error: error.response.data.message,
        });
    });
};

export const phoneVerifyByFan = (data) => dispatch => {
  checkIsValidFanToken(histrory);
  axios({
      method: 'POST',
      url: `${API_Url}bc/fan/basic_verfiy`,
      data: data,
      headers: {
          'x-auth-token': Auth_Fan_Key,
          'Accept': 'application/json'
      }
  }).then(verify => {
      dispatch({
          type: PHONE_VERIFY_FAN_SUCCESS,
          payload: verify.data
      })
  }).catch((error) => {
      if (error.response) {
          console.log("error.response:--", error.response);
          dispatch({ type: PHONE_VERIFY_FAN_ERROR, error: error.response.data.message })
      }
  });
};
import React, { Component, useEffect, useReducer, useRef, useState } from 'react'
import { imagesArr } from '../../../assets/images';
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from "../../../businesslogic/commonCalls";
import { GAMEGROUND_REG } from "../../../saga/constants/view/player/profile/gameBackground.const";
import { getGameBackgroundInfo, getGameStatsInfo, PostGameBackground } from "../../../saga/actions/views/player/profile/gameBackground.action";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../../components/preloader';

const initialState =
{
    "year-exp": "",
    "batting-pos": [],
    "fielding-pos": [],
    loader: false
};

const reducer = (state, payload) => ({ ...state, ...payload });


function GameBackground() {
    const [ground, setGround] = useReducer(reducer, initialState);
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const commonCall = new commonCalls();
    const dispatch = useDispatch();
    const { gameGroundInfo: _GRI, ragisterInfo: _REG } = useSelector(state => state.gameGroundInfo)
    const [groundPos, setGroundPos] = useState({
        fieldingPos: ["CF", "LF", "RF", "2B", "SS", "3B", "P", "1B", "C"],
        battingPos: ["Left", "Switch", "Right"]
    })

    useEffect(() => {
        setLoader(true)
        getGameBackgroundInfo()(dispatch);
    }, [])

    useEffect(() => {
        if (_GRI) {
            if (_GRI["year-exp"]) {
                setGround({
                    "year-exp": _GRI["year-exp"],
                    "batting-pos": _GRI["batting-pos"],
                    "fielding-pos": _GRI["fielding-pos"]
                })
                setLoader(false)
            } else {
                setLoader(false)
            }
        }
    }, [_GRI])

    useEffect(() => {
        if (_REG) {
            if (_REG.flag) {
                commonCall.successMessage(_REG.message)
                getGameBackgroundInfo()(dispatch);
            } else if (!_REG.flag) {
                commonCall.errorMessage(_REG.message)
            }
            dispatch({
                type: GAMEGROUND_REG,
                payload: null,
            });
        }
    }, [_REG])

    const handleChangePos = (e, pos) => {
        setGround({
            ...ground,
            [pos]: [e]
        })
    }

    const handleFieldPos = (e, pos) => {
        if (ground[pos].find(item => item === e)) 
        {
            setGround({
                ...ground,
                [pos]: ground[pos].filter(item => { if (item !== e) { return item; } })
            })
        }
        else{
            setGround({
                ...ground,
                [pos]: [...ground[pos],e]
            })
        }
    }

    const handleChangeYear = (e) => {
        setGround({
            ...ground,
            "year-exp": e.target.value,
        })
    }

    const renderFieldingPos = () => {
        return (
            groundPos.fieldingPos &&
            groundPos.fieldingPos.map((item, i) => (
                <>
                    <span className={item === "CF" ? 'cf' : item === "LF" ? "lf" : item === "RF" ? "rf" : item === "2B" ? "tow" : item === "SS" ? "ss" : item === "3B" ? "three" : item === "P" ? "gp" : item === "1B" ? "ones" : item === "C" ? "see" : ""}>
                        <div className="inner_ground">
                            <input type="checkbox" id={i} value={item} name="fielding-pos" checked={ground['fielding-pos'].find((d) => d === item) ? true : false}
                                onClick={() => handleFieldPos(item, 'fielding-pos')} />
                            <div className="inner_span">{item}</div>
                        </div>
                    </span>
                </>
            ))
        )
    }

    const renderBattingPos = () => {
        return (
            groundPos.battingPos &&
            groundPos.battingPos.map((item, i) => (
                <>
                    <div className="checkbox">
                        <input type="radio" id="" value={item} name="battingPos" checked={ground['batting-pos'].find((d) => d === item) ? true : false}
                            onClick={() => handleChangePos(item, 'batting-pos')} />
                        <label for="Baseball">{item}</label>
                    </div>
                </>
            ))
        )
    }

    const onSave = async () => {
        if (validator.current.allValid()) {
            setLoader(true);
            await PostGameBackground(ground)(dispatch);
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }

    const setLoader = (val) => {
        setGround({
            loader: val
        })
    }

    return (
        <>
            <section className="before-login other-before-dashboard">
                <div className="white-bg">
                    <div className="row ground">
                        <div className="ground_inner">
                            <label className="common-lable">How long have you played baseball?</label>
                            <select name="city" value={ground["year-exp"]} onChange={handleChangeYear}  name="year-exp" className="custom-select">
                                <option value="" selected disabled>Select year</option>
                                <option value="1-3" selected={ground['year-exp'] === "1-3" ? "selected" : ""}>1-3 Years</option>
                                <option value="4-6" selected={ground['year-exp'] === "4-6" ? "selected" : ""}>4-6 Years</option>
                                <option value="7-9" selected={ground['year-exp'] === "7-9" ? "selected" : ""}>7-9 Years</option>
                                <option value="10-12" selected={ground['year-exp'] === "10-12" ? "selected" : ""}>10-12 Years</option>
                            </select>
                            <span className="validMsg">{validator.current.message('Year', ground["year-exp"], 'required', { className: 'text-danger' })}</span>

                            <label className="common-lable">Select your position</label>
                            <div className="ground_bg">
                                <div className="ground_img">
                                    <img src={imagesArr.ground1} alt="ground1" />
                                    {renderFieldingPos()}
                                </div>
                            </div>
                            <span className="validMsg">{validator.current.message('Fielding Pos', ground["fielding-pos"], 'required', { className: 'text-danger' })}</span>

                        </div>
                        <div className="ground_inner">
                            <label className="common-lable">Which side of the plate do you bat from?</label>
                            <div className="ground_bg">
                            <div className="button_transform">
                                    <div className="check_input">
                                        <div className="check_box">
                                            <div className="check-box">
                                                {renderBattingPos()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ground_img">
                                    <img src={imagesArr.ground2} alt="ground1" />
                                    {ground['batting-pos'].find((d) => d === "Right") && 
                                        <span className="r">
                                            <div className="inner_ground">
                                                <input type="checkbox" checked/>
                                                <div className="inner_span">R</div>
                                            </div>
                                        </span>
                                        }
                                        {ground['batting-pos'].find((d) => d === "Left") && 
                                        <span className="l">
                                            <div className="inner_ground">
                                                <input type="checkbox" checked/>
                                                <div className="inner_span">L</div>
                                            </div>
                                        </span>
                                        }
                                        {ground['batting-pos'].find((d) => d === "Switch") && 
                                        <>
                                        <span className="sl">
                                            <div className="inner_ground">
                                                <input type="checkbox" checked/>
                                                <div className="inner_span">R</div>
                                            </div>
                                        </span>
                                        <span className="sr">
                                            <div className="inner_ground">
                                                <input type="checkbox" checked/>
                                                <div className="inner_span">L</div>
                                            </div>
                                        </span>
                                        </>
                                        }
                                </div>
                            </div>
                            <span className="validMsg">{validator.current.message('Batting Pos', ground["batting-pos"], 'required', { className: 'text-danger' })}</span>

                        </div>
                    </div>
                    <div className="flex-align">
                        <Link className="btn gray" to={"/player-dashboard"}>Cancel</Link>
                        <Link className="btn light-green" onClick={onSave}>Save</Link>
                    </div>
                </div>
            </section>
            <Preloader flag={ground.loader} />
        </>
    );
}

export default GameBackground;

export const COM_TOP_EVENT_REQUEST = 'COM_TOP_EVENT_REQUEST';
export const COM_TOP_EVENT_SUCCESS = 'COM_TOP_EVENT_SUCCESS';
export const COM_TOP_EVENT_ERROR = 'COM_TOP_EVENT_ERROR';

export const COM_UPCOMING_EVENT_REQUEST = 'COM_UPCOMING_EVENT_REQUEST';
export const COM_UPCOMING_EVENT_SUCCESS = 'COM_UPCOMING_EVENT_SUCCESS';
export const COM_UPCOMING_EVENT_ERROR = 'COM_UPCOMING_EVENT_ERROR';

export const COM_PAST_EVENT_REQUEST = 'COM_PAST_EVENT_REQUEST';
export const COM_PAST_EVENT_SUCCESS = 'COM_PAST_EVENT_SUCCESS';
export const COM_PAST_EVENT_ERROR = 'COM_PAST_EVENT_ERROR';

export const COM_EVENT_STATE_REQUEST = 'COM_EVENT_STATE_REQUEST';
export const COM_EVENT_STATE_SUCCESS = 'COM_EVENT_STATE_SUCCESS';
export const COM_EVENT_STATE_ERROR = 'COM_EVENT_STATE_ERROR';

export const COM_USER_EVENT_STATE_REQUEST = 'COM_USER_EVENT_STATE_REQUEST';
export const COM_USER_EVENT_STATE_SUCCESS = 'COM_USER_EVENT_STATE_SUCCESS';
export const COM_USER_EVENT_STATE_ERROR = 'COM_USER_EVENT_STATE_ERROR';

export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_ERROR = "COMPANY_LIST_ERROR";
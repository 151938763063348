import React, { Component } from 'react';
import { imagesArr } from "../../../../../assets/images";
export default class leagueInfo extends Component {

    getDivision = (division) => {
        if (division) {
            return division
                .map((div) => {
                    return div.title;
                })
                .join(",");
        } else {
            return "";
        }
    }

    renderTeamList = () => {
        console.log("this.props.pList:--", this.props.pList);
        return (
            this.props.pList &&
            this.props.pList.map((list, i) => {
                return (
                    <tr>
                        <td>{i + 1}</td>
                        <td>
                            <div className="teams-logo">
                                <span><img src={list.team.parentTeam && list.team.parentTeam.hasOwnProperty('teamLogo') ? list.team.parentTeam.teamLogo.filePath : imagesArr.default_user} alt="Teams" /></span>
                                <span className="teams-text">{list.team.parentTeam.name}</span>
                            </div>
                        </td>
                        <td>{list.rosterTeamId.length !== 0 ? list.rosterTeamId[0].rosterName : "-"}</td>
                        <td>{list.team.coach.fname + " " + list.team.coach.lname}</td>
                        <td>{list.team.coach.profile.address ? list.team.coach.profile.address.state : ""}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        const { dataList, pList } = this.props;
        return (
            <div className="event_left_side">
                <div className="web_description_box">
                    <div class="web_desc_title"><h3>Details</h3></div>
                    <div className="web_list_data d-flex flex-wrap">
                        <div className="web_list_data_box text-center"><label>Event Type</label><h3>Bundle</h3></div>
                        <div className="web_list_data_box text-center"><label>Division</label><h3>{this.getDivision(dataList?.div)}</h3></div>
                        <div className="web_list_data_box text-center"><label>Age Group</label><h3>{dataList?.ageGroup + "U" + " - " + dataList?.ageGroupTo + "U"}</h3></div>
                        <div className="web_list_data_box">
                            <label>Location</label>
                            <h3>
                                {
                                    (dataList?.location && (dataList?.location.city || dataList?.location.streetAddress || dataList?.location.stateRegion)) &&
                                        dataList?.location &&
                                        dataList?.location.city ?
                                        dataList?.location.city :
                                        dataList?.location.streetAddress ?
                                            dataList?.location && dataList?.location.streetAddress.split(",")[0] :
                                            dataList?.location.stateRegion
                                }
                            </h3>
                        </div>
                    </div>
                    <div class="web_desc_title"><h3>Description</h3></div>
                    <p dangerouslySetInnerHTML={{
                        __html: dataList?.information && dataList.information.leagueDet
                    }}>
                        {/* {dataList.information && dataList.information.leagueDet} */}
                    </p>
                </div>
                <div className="web_description_box">
                    <div class="web_desc_title"><h3>Tournament Format</h3></div>
                    <p dangerouslySetInnerHTML={{
                        __html: dataList?.information && dataList.information.leagueFormat
                    }}>
                        {/* {dataList.information && dataList.information.leagueFormat} */}
                    </p>
                </div>
                <div className="web_description_box">
                    <div class="web_desc_title"><h3>Tournament Rules</h3></div>
                    <p dangerouslySetInnerHTML={{
                        __html: dataList?.information && dataList.information.leagueRule
                    }}>
                        {/* {dataList.information && dataList.information.leagueRule} */}
                    </p>
                </div>
                {
                    pList.length > 0 &&
                    <div className="web_description_box table-box">
                        <div class="web_desc_title"><h3>Participating Teams</h3></div>
                        <div className="participating-teams-table">
                            <div className="table-responsive-xl">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">No.</th>
                                            <th scope="col">Teams</th>
                                            <th scope="col">Roster Name</th>
                                            <th scope="col">Coaches</th>
                                            <th scope="col">State</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTeamList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

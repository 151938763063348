import {
    COM_CONTACTUS_FORM_REQUEST,
    COM_CONTACTUS_FORM_SUCCESS,
    COM_CONTACTUS_FORM_ERROR,

    EVENT_CONTACT_US_SUCCESS,
    EVENT_CONTACT_US_ERROR
} from '../../constants/common/contactUs.const';

const initialState = {
    saveContactFormReq: false,
    saveContactFormData: null,
    saveContactFormError: null,

    eventContactUsData: null,
    eventContactUsError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Recommended form req    
        case COM_CONTACTUS_FORM_REQUEST:
            return {
                ...state,
                saveContactFormReq: true,
            };

        case COM_CONTACTUS_FORM_SUCCESS:
            return {
                ...state,
                saveContactFormData: action.payload,
            };
        case COM_CONTACTUS_FORM_ERROR:
            return {
                ...state,
                saveContactFormError: action.error,
            };


        // event Contact us
        case EVENT_CONTACT_US_SUCCESS:
            return {
                ...state,
                eventContactUsData: action.payload,
            };

        case EVENT_CONTACT_US_ERROR:
            return {
                ...state,
                eventContactUsError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react'
import Header from "../../../containers/header"
import Footer from "../../../containers/footer"
import { imagesArr } from "../../../assets/images"
import Slider from "react-slick";
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import ContactUs from "../../../views/company/components/common/contactUs";
import TeamInfo from '../../popup/teamInfo';
import Shop from "../../../views/company/components/common/shop";
import { ourTeamContent } from '../../../businesslogic/content';
import Preloader from '../../../components/preloader';
export default class pwsAbout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
            popupData: [],
            loaderCount: 0
        };
    }

    componentDidMount = () => {
    }

    setPopupContent = (data) => {
        this.setState({
            openPopup: true,
            popupData: data
        })
    }

    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            openPopup: false,
            popupData: []
        })
    }

    renderContent = () => {
        return (
            ourTeamContent &&
            ourTeamContent.about &&
            ourTeamContent.about.pws &&
            ourTeamContent.about.pws.map((item, i) => {
                return (
                    <div key={i} className="w-25" >
                        <div className="team-box">
                            <h5>{item.title}
                                {
                                    item.description.length !== 0 &&
                                    <span className="page-icon">
                                        <i class="fa fa-file" aria-hidden="true" onClick={() => this.setPopupContent(item)}></i>
                                    </span>
                                }
                            </h5>
                            <span>{item.position}</span>
                            <p>{item.email}</p>
                        </div>
                    </div>
                )
            })
        )
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { openPopup, popupData, loaderCount } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            centerPadding: '258px',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        centerMode: true,
                        centerPadding: '90px',
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '0px',
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (
            <div >
                <Header />
                <div className="event-main">
                    <div className="container-fluid">
                        <div className="about-slider">
                        <Slider {...settings}>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_1} alt="user profile" />
                                        <h5>Bryan Huie, Owner</h5>
                                        <h6>Baseball’s Next Level (Tucson, AZ)</h6>
                                        <p>Prospect Wire has been a tremendous help to our BNL players and families. Their events are fantastic with numerous colleges in attendance to see our players. Thanks again Prospect Wire. We always look forward to playing in your next event.</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_2} alt="user profile" />
                                        <h5>Ryan Robinson, Owner</h5>
                                        <h6>Next Level Baseball (Tallahassee, FL)</h6>
                                        <p>Prospect Wire events always come as advertised, and are a tremendous value to organizations and players. If you are looking for highly organized events, with great competition and exposure...Prospect Wire delivers every time!!!</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_3} alt="user profile" />
                                        <h5>Eric Gardner, Director</h5>
                                        <h6>Marucci Athletics (Peoria, AZ)</h6>
                                        <p>We can't say enough how excited we are to continue to build our relationship with Prospect Wire while attending their events across the southwest. Entering our 10th year in Prospect Wire events, Brian Werner has created a first-class experience for our players, coaches and families. We are fortunate to have built relationships with the Prospect Wire staff as they are all true baseball people with a commitment to serve that is second to none. The ability for our players to have access to the best competition in baseball has provided the opportunities for our players to gain local and national exposure that has created a gateway to the next level.</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_4} alt="user profile" />
                                        <h5>Josh Glassey, Director</h5>
                                        <h6>CBA Wave (San Diego CA)</h6>
                                        <p>CBA Wave always looks forward to the Prospect Wire events. They are very organized and they always get great competition for us to play. They worked very hard to bring multiple premier events to California, allowing organizations who spend a ton of money on travel to other states, the chance to stay home, while still playing top level competition.</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_5} alt="user profile" />
                                        <h5>Jamie Matlock, Director</h5>
                                        <h6>Blueprint Baseball (Murfreesboro, TN)</h6>
                                        <p>Our experiences with Prospect Wire are unmatched by any organization. The player promotion, quality competition & customer service all in top notch venues are just a few things that set apart Prospect Wire from others. Anybody can run a tournament, but Prospect Wire makes it an event!</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="history-vision">
                        <div className="container">

                            <div className="history-vision-info">
                                <div className="hv-box">
                                    <h5 className="section_title_left">Who We Are</h5>
                                    <p>Prospect Wire was founded in 2021 in a partnership with Kevin Davidson & DS Sports Ventures and 5 Tool Sports. With DS Sports came the introduction to SoftballCloud, a community focused on enhancing amateur softball through the use of data. Similar to the Apple iCloud, SoftballCloud was designed to be a one stop shop for all analytic data capturing devices. Along with SoftballCloud, Prospect Wire is excited for the launch of the new DiamondConnect App, a platform that will unify all of DS Sports Ventures companies, and more importantly, allow Prospect Wire to franchise out to different states in a more efficient and controlled manner.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">What We Do</h5>
                                    <p>Prospect Wire runs a variety of events ranging from individual player Camps, team tournaments, ID Camps, to All American Games and more. These events allow us to capture a database of player information that is easily transferable to evaluators in the industry. Since the beginning, our database has been housed at Prospectwire.com, but in 2021, it will be moving to the DiamondConnect software application. In the last few years. </p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">Why We Do It</h5>
                                    <p>When it comes to high school sports in general, quality information is scattered all over the place. Many times, worthy players are lost in the shuffle of larger events. Our focus is on creating an environment that allows players to Camps their skills with the use of verifiable data and advanced scouting.  Many players graduate from high school without a scholarship waiting. Many take the walk on route just for the chance to be a college softball player. Prospect Wire is here to provide a variety of opportunities for players of this nature to cross the eyes of a college recruiter. We are here to give back to the game that gave so much to us.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About Our Events</h5>
                                    <p>Prospect Wire offers a wide variety of events including Camps, Combines, Tournaments, Identifiers, Data Collection, Team Workouts and Tryouts. There are small, weekend events, as well as large scale multi-day tournaments with teams coming in from multiple states. Attending a Prospect Wire event feels different than most other companies. We take multiple steps to ensure our spectators & teams enjoy their experience. Our camps, identifiers and data days feed into larger events, such as the Bombers Exposure Weekend, Gold Cup, and Lonestar Data Days. All of our data capture events will feature advanced analytic capturing equipment powered by SoftballCloud.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About our Future</h5>
                                    <p>The merger between Prospect Wire Softball and 5 Tool Sports will enhance the way fastpitch players are recruited. By combining top quality events with the best data collection technology players will have a platform to Camps their skills in live play and provide college coaches with detailed analytics and verifiable data.  The goal is to bring Prospect Wire Softball events to over a dozen states by 2026. Infusing the newest technology, along with old school scouting, Prospect Wire Softball will provide a first class experience to our customers.</p>
                                </div>

                            </div>

                            <div className="rating-system">
                                <h1 className="section_title_left">About the PW Rating System</h1>
                                <div className="ratting-system-list">
                                    <div className="row">
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Players first.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Reliable reports.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Organized events.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Scouted heavily.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Personal assistance.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Exceptional opportunity</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Candid feedback.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Talent rich.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="our-team">
                        <div className="container">
                            <h1 className="section_title">Our Team</h1>
                            {/* <p className="text-center mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cras urna gravida duis in nulla. Nec at bibendum tristique feugiat volutpat amet enim quisque.</p> */}
                            <div className="team-list">
                                <div className="row">
                                    {this.renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactUs cType="PW Softball" increment={this.increment} decrement={this.decrement} />
                </div>
                <div className="upcomingevent_section">
                    <Shop cType="pws" />
                </div>
                <ProudSponsors increment={this.increment} decrement={this.decrement} />
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
                {
                    openPopup &&
                    <TeamInfo
                        flag={openPopup}
                        close={this.handleClose}
                        content={popupData}
                    />
                }
            </div>
        )
    }
}

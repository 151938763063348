import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFanBasicDetails, coverFileUpload } from '../../../../saga/actions/authantication/signup/fan/basic.action';
import { postFileUpload, getZipcodeInfo } from '../../../../saga/actions/common/api.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import ImageCrop from '../../../../views/popup/imageCrop';
import Preloader from '../../../../components/preloader';
import CoverImageCrop from '../../../../views/popup/coverImageCrop';
import { StatesObj } from '../../../../businesslogic/content';
import Geosuggest from 'react-geosuggest';

class basic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            basicArr: {
                profilePic: "",
                profileWall: "",
                address: {
                    city: "",
                    state: "",
                    zipcode: ""
                },
                role: "Fan"
            },
            loader: false,
            imgObj: "",
            cropPopup: false,
            cropCoverPopup:false,
            renderFile: "",
            filePro: null,
            fileType: "",
            manageZipcode: false,
            isEditZipcode: false
        }

        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.onRef(this);
        this.setLoader(true);
        this.props.getFanBasicDetails();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.basicFanDetails &&
            nextProps.basicFanDetails.fanBasicRes &&
            nextProps.basicFanDetails.fanBasicData
        ) {
            nextProps.basicFanDetails.fanBasicRes = false;
            if (nextProps.basicFanDetails.fanBasicData.hasOwnProperty('_id')) {
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        profilePic: (nextProps.basicFanDetails.fanBasicData.fieldArr.profilePic) ? nextProps.basicFanDetails.fanBasicData.fieldArr.profilePic._id : "",
                        profileWall: (nextProps.basicFanDetails.fanBasicData.fieldArr.profileWall) ? nextProps.basicFanDetails.fanBasicData.fieldArr.profileWall._id : "",
                        role: nextProps.basicFanDetails.fanBasicData.fieldArr.role,
                        address: {
                            ...prevState.basicArr.address,
                            city: nextProps.basicFanDetails.fanBasicData.fieldArr.address.city,
                            state: nextProps.basicFanDetails.fanBasicData.fieldArr.address.state,
                            zipcode: nextProps.basicFanDetails.fanBasicData.fieldArr.address.zipcode
                        }
                    },
                    manageZipcode: true,
                    filePro: (nextProps.basicFanDetails.fanBasicData.fieldArr.profilePic) ? nextProps.basicFanDetails.fanBasicData.fieldArr.profilePic.filePath : "",
                    file: (nextProps.basicFanDetails.fanBasicData.fieldArr.profileWall) ? nextProps.basicFanDetails.fanBasicData.fieldArr.profileWall.filePath : ""
                }), () => {
                    this.setLoader(false);
                    nextProps.basicFanDetails.fanBasicData = null
                });
            } else {
                this.setLoader(false);
                nextProps.basicFanDetails.fanBasicData = null
            }
        }

        if (nextProps.commonApi &&
            nextProps.commonApi.fileUploadData
        ) {
            console.log("nextProps.commonApi.fileUploadData:--", nextProps.commonApi.fileUploadData);
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    profilePic: nextProps.commonApi.fileUploadData._id
                },
                filePro: nextProps.commonApi.fileUploadData.filePath,
                imgObj: "",
                cropPopup: false,
                cropCoverPopup: false,
                renderFile: ""
            }), () => {
                document.body.classList.remove('modal-open');
                this.setLoader(false);
                nextProps.commonApi.fileUploadData = null
            });
        }

        if (nextProps.basicFanDetails &&
            nextProps.basicFanDetails.coverImageRes &&
            nextProps.basicFanDetails.coverImageData
        ) {
            nextProps.basicFanDetails.coverImageRes = false;
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    profileWall: nextProps.basicFanDetails.coverImageData._id
                },
                file: nextProps.basicFanDetails.coverImageData.filePath,
                imgObj: "",
                cropPopup: false,
                cropCoverPopup: false,
                renderFile: ""
            }), () => {
                document.body.classList.remove('modal-open');
                this.setLoader(false);
                nextProps.basicFanDetails.coverImageData = null
            });
        }

        if (nextProps.commonApi &&
            nextProps.commonApi.zipcodeData
        ) {
            console.log("nextProps.commonApi.zipcodeData:--", nextProps.commonApi.zipcodeData);
            if (nextProps.commonApi.zipcodeData.flag) {
                let getZipdata = nextProps.commonApi.zipcodeData.result;
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        address: {
                            ...prevState.basicArr.address,
                            state: getZipdata.state,
                            city: getZipdata.city,
                            zipcode:getZipdata.zipcodestr
                        }
                    },
                    manageZipcode: true
                }), () => {
                    this.setLoader(false);
                    nextProps.commonApi.zipcodeData = null;
                });
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        address: {
                            ...prevState.basicArr.address,
                            state: "",
                            city: "",
                        }
                    },
                    manageZipcode: false
                }), () => {
                    this.commonCall.errorMessage(nextProps.commonApi.zipcodeData.message);
                    this.setLoader(false);
                    nextProps.commonApi.zipcodeData = null;
                });
            }
        }
    }

    onHandleChange = (e) => {
        const { name, value } = e.target;
        console.log("role:--", name, value);
        this.setState((prevState) => ({
            ...prevState,
            basicArr: {
                ...prevState.basicArr,
                [name]: value
            },
        }),()=>{
            this.props.handleStep(value)
        });

    }

    onAddressChange = (e) => {
        const { name, value } = e.target;
        console.log("role:--", name, value);
        if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        basicArr: {
                            ...prevState.basicArr,
                            address: {
                                ...prevState.basicArr.address,
                                [name]: value
                            },
                        },
                    }));
                } else return false;
            } else return false;
        }
    }

    handleChange = (e, type,name) => {
        let file = e.target.files[0];
        if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png" && file.name.match(new RegExp('[^.]+$'))[0] !== "heic") {
            return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
        }
        this.setState({
            imgObj: file,
            [name]: true,
            renderFile: URL.createObjectURL(file),
            fileType: type
        });
    }

    imageRemove = (type) => {
        if (type === "profile") {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    profilePic: ""
                },
                filePro: null,
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    profileWall: ""
                },
                file: null,
            }));
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    handleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            cropPopup: false,
            cropCoverPopup: false,
        })
    }

    onHadleObject = (obj) => {
        this.setLoader(true);
        if (this.state.fileType === "profile") {
            this.props.postFileUpload(obj.file, 16)
        } else {
            this.props.coverFileUpload(obj.file, 16)
        }
    }

    getZipcode = () => {
        this.setLoader(true);
        this.props.getZipcodeInfo(this.state.basicArr.address.zipcode);
    }

    validationUpdate = (cb) => {
        if (this.validator.allValid()) {
            if (this.state.basicArr.address.state !== "" || this.state.basicArr.address.city !== "") {
                cb(true, this.state.basicArr);
            } else {
                this.setState({
                    manageZipcode: true
                })
                cb(false);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            cb(false);
        }
    }

    handleSelectGeoSuggest = (place) => {
        if (place) {
          var componentForm = {
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name',
          };
    
          for (var i = 0; i < place.gmaps.address_components.length; i++) {
            var addressType = place.gmaps.address_components[i].types[0];
            if (componentForm[addressType]) {
              var val = place.gmaps.address_components[i][componentForm[addressType]];
              if (val)
                componentForm[addressType] = val
            }
          }
          var location = {
            streetAddress: place.gmaps.formatted_address,
            city: componentForm.locality !== 'long_name' ? componentForm.locality : '',
            stateRegion: componentForm.administrative_area_level_1 !== 'short_name' ? StatesObj[componentForm.administrative_area_level_1]?.state : '',
            postalCode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : "",
            latitude: place.location.lat || 42.2538048,
            longitude: place.location.lng || -83.5735806
          }
    
            if (location.stateRegion) {
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        address: {
                            state: location.stateRegion,
                            city: location.city,
                            zipcode: location.postalCode

                        }
                    },
                    isEditZipcode: location.postalCode ? false : true
                }));
            } else {
                this.commonCall.errorMessage("Add city name of US only to look up Zipcode and State name.");
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        address: {
                            state: "",
                            zipcode: "",
                        }
                    },
                    isEditZipcode: false
                }));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    address: {
                        city: "",
                    }
                }
            }));
        }
      }

    handleStateZipChange = (e) => {
        const { name, value } = e.target;
        if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        basicArr: {
                            ...prevState.basicArr,
                            address: {
                                ...prevState.basicArr.address,
                                [name]: value,
                            }
                        }
                    }));
                }
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    address: {
                        ...prevState.basicArr.address,
                        [name]: value,
                    }
                }
            }));
        }
     
      }

    render() {
        const { basicArr, manageZipcode, file, filePro, loader, imgObj, cropPopup, cropCoverPopup, renderFile } = this.state;
        return (
            <>
                <section className="before-login">
                    <div className="white-bg">
                        <div className="title-name">
                            <h2>
                                Hi {this.props.profileData && this.props.profileData.fname && this.props.profileData.fname + " " + this.props.profileData.lname},
                            </h2>
                            <p>
                                We have a few more QUESTIONS for you.
                            </p>
                        </div>
                        <label className="common-lable">Upload profile picture</label>
                        <div className="dragdrop-img">
                            <div className="create_event_fileUpload file_custom_upload text-center">
                                <i className="fas fa-cloud-upload-alt"></i>
                                {!filePro && <p>Click to upload profile picture</p>}

                                <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={(e) => this.handleChange(e, 'profile','cropPopup')} />
                                {filePro &&
                                    <div className="preview_img">
                                        <i className="fas fa-times" onClick={() => this.imageRemove('profile')}></i>
                                        <img className="preview_img_inner" src={filePro} />
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <label className="common-lable">Upload cover photo</label>
                        <div className="dragdrop-img">
                            <div className="create_event_fileUpload file_custom_upload text-center">
                                <i className="fas fa-cloud-upload-alt"></i>
                                {!file && <p>Click to upload cover photo</p>}

                                <input name="logofile" onClick={(ev) => { ev.target.value = null }} type="file" className="img_file_input" accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" onChange={(e) => this.handleChange(e, 'cover','cropCoverPopup')} />
                                {file &&
                                    <div className="preview_img">
                                        <i className="fas fa-times" onClick={() => this.imageRemove('cover')}></i>
                                        <img className="preview_img_inner" src={file} />
                                    </div>
                                }
                            </div>
                        </div> */}
                        <div className="check_input row">
                            <div className="check_box">
                                <label className="common-lable">Are you a parent of a player or just a fan/individual?</label>
                                <div className="check-box mb-0">
                                    <div className="checkbox" >
                                        <input type="radio" id="Parent" value="Parent" name="role" onChange={(e) => this.onHandleChange(e)} checked={basicArr.role === "Parent" ? true : false} />
                                        <label htmlFor="Parent">Parent </label>
                                    </div>

                                    <div className="checkbox mr-0">
                                        <input type="radio" id="Fan/Individual" value="Fan" name="role" onChange={(e) => this.onHandleChange(e)} checked={basicArr.role === "Fan" ? true : false} />
                                        <label htmlFor="Fan/Individual">Fan / Individual </label>
                                    </div>
                                </div>
                                <span className="validMsg">{this.validator.message('role', basicArr.role, 'required')}</span>
                            </div>
                            <div className="check_inputbox">
                                <label className="common-lable">City <span>( To Look Up State and Zipcode )</span></label>
                                <div className="form-group mb-0">
                                    <div className="serch-box">
                                        <Geosuggest
                                            // ref={el => this._geoSuggest = el}
                                            onSuggestSelect={this.handleSelectGeoSuggest}
                                            initialValue={basicArr.address.city || ""}
                                            autoComplete="off"
                                            types={['(cities)']}
                    />
                                        {/* <input type="text" name="zipcode" value={basicArr.address.zipcode} onChange={(e) => this.onAddressChange(e)} className="form-control" id="usr" placeholder="Zip Code" />
                                        <Link className="serch-btn" onClick={() => (basicArr.address.zipcode.length !== 0) ? this.getZipcode() : ""}>
                                            Next
                                        </Link> */}
                                    </div>
                                    <span className="validMsg">{this.validator.message('team city', basicArr.address.city, 'required')}</span>
                                </div>
                            </div>
                            {
                                // manageZipcode &&
                               <>
                                    <div className="check_inputbox">
                                        <label className="common-lable">State</label>
                                        <div className="form-group mb-0">
                                        <select className="custom-select" name="state" value={basicArr.address.state} onChange={this.handleStateZipChange}>
                                        <option value="">N/A</option>
                                        {Object.keys(StatesObj).map((key) => {
                                            return (
                                                <option value={StatesObj[key].state}> {StatesObj[key].state}  </option>
                                            )
                                        })}
                                    </select>
                                            {/* <input type="text" className="form-control" name="state" value={basicArr.address.state} onChange={(e) => this.onAddressChange(e)} id="usr" placeholder="State" disabled /> */}
                                        </div>
                                        <span className="validMsg">{this.validator.message('team state', basicArr.address.state, 'required')}</span>
                                    </div>
                                    <div className="check_inputbox">
                                        <label className="common-lable">Zip Code</label>
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" name="zipcode" value={basicArr.address.zipcode} onChange={(e) => this.handleStateZipChange(e)} id="usr" placeholder="Zip code" disabled={this.state.isEditZipcode ? false : true} />
                                        </div>
                                        <span className="validMsg">{this.validator.message('team zipcode', basicArr.address.zipcode, 'required')}</span>
                                    </div>
                                </> 
                            }
                        </div>

                    </div>
                </section>
                <Preloader flag={loader} />
                <ImageCrop
                    imgObj={imgObj}
                    flag={cropPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />
                  <CoverImageCrop
                    imgObj={imgObj}
                    flag={cropCoverPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />
            </>
        )
    }
}

basic.propTypes = {
    getFanBasicDetails: PropTypes.func.isRequired,
    postFileUpload: PropTypes.func.isRequired,
    coverFileUpload: PropTypes.func.isRequired,
    getZipcodeInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    basicFanDetails: state.basicFanDetails,
    commonApi: state.api
});

export default connect(mapStateToProps, { getFanBasicDetails, postFileUpload, coverFileUpload, getZipcodeInfo })(basic);

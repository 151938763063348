import React, { Component, useEffect, useReducer, useRef, useState } from 'react'
import { imagesArr } from '../../assets/images';
import { Link } from 'react-router-dom';
import { getCountryCode } from '../../saga/actions/common/api.action';
import { useDispatch, useSelector } from 'react-redux';
import { postReassignInvite, reassignInvite } from '../../saga/actions/views/coach/profile/teamInfo.action';
import SimpleReactValidator from 'simple-react-validator';
import commonCalls from '../../businesslogic/commonCalls';
import { POST_REASSIGN_ERROR } from '../../saga/constants/view/coach/profile/teamInfo.const';

const initialState = {
    cCode: "+1",
    email: "",
    phone: "",
    type: 1
}

const reducer = (state, payload) => ({ ...state, ...payload });


function ReassignTeam({ flag, close, type }) {

    const dispatch = useDispatch();
    const commonCall = new commonCalls();
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [reassign, setReassign] = useReducer(reducer, initialState);
    const { email, phone, cCode } = reassign;
    const { reInviteData: _REINVITE, postReassignData: _CREATE, postReassignError: _CREATERROR } = useSelector((state) => state.teamInfo);
    const { countryCodeData: _CC } = useSelector((state) => state.api);


    useEffect(() => {
        getCountryCode()(dispatch);
        reassignInvite()(dispatch)
    }, [])

    useEffect(() => {
        if (flag) {
            document.body.classList.add("modal-open");
            setReassign({
                ...reassign,
                type: type
            });
        }
    }, [flag]);

    useEffect(() => {
        if (_REINVITE) {
            if (_REINVITE.flag) {
                setReassign({
                    ...reassign,
                    cCode: _REINVITE.result.cCode,
                    email: _REINVITE.result.email,
                    phone: _REINVITE.result.phone,
                    type: type
                });
            }
        }
    }, [_REINVITE]);

    useEffect(() => {
        if (_CREATE) {
            if (_CREATE.flag) {
                close();
            }
            else if (!_CREATE.flag) {
                commonCall.errorMessage(_CREATE.message);

            }
        }
        if (_CREATERROR) {
            if (_CREATERROR.flag) {
                commonCall.successMessage(_CREATERROR.message);
            }
            else if (!_CREATERROR.flag) {
                commonCall.errorMessage(_CREATERROR.message);
            }
            dispatch({
                type: POST_REASSIGN_ERROR,
                error: null,
            })
        }
    }, [_CREATE, _CREATERROR])

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 12) {
                if (re.test(value) || value.length <= 0) {
                    return setReassign({
                        ...reassign,
                        [name]: value,
                    });
                }
            }
        }
        else {
            setReassign({
                ...reassign,
                [name]: value
            })
        }

    }

    const onSave = () => {
        if (validator.current.allValid()) {
            reassign.cCode = "+1";
            console.log("reassign:--", reassign);
            postReassignInvite(reassign)(dispatch);
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }

    return (
        <>
            <section className={`common-modal ${flag ? "show" : ""}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="green-header modal-header">
                            <h3>Reassign Team</h3>
                            <div className="modal_close" onClick={close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                        </div>
                        <div className="modal-body">
                            <div className="team-body-inner">
                                <label className="common-lable">Phone Number</label>
                                <div className="form-group mb-0" name="cCode">
                                    <div className="svg_icon">
                                        <div className="input-group">
                                            <div className="counrty-no">
                                                <select className="custom-select" name="cCode" value={cCode} onChange={handleChange}>
                                                    <option value="+1">+1</option>
                                                    {/* {_CC &&
                                                        _CC.length !== 0 &&
                                                        _CC.map((e, index) => {
                                                            return (<option key={index} value={e.code}>{e.code}</option>)
                                                        })
                                                    } */}
                                                </select>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Enter phone number" name="phone" value={phone} onChange={handleChange} />
                                            <img src={imagesArr.smartphone} alt="eye" />
                                        </div>
                                    </div>
                                </div>
                                {/* <span className="validMsg"> {validator.current.message("Phone", phone, "required|phone")}</span> */}

                                <label className="common-lable">Email Address</label>
                                <div className="form-group mb-0">
                                    <input type="text" className="form-control" placeholder="Enter Email address" name="email" value={email} onChange={handleChange} />
                                </div>
                                <span className="validMsg">{validator.current.message("Email", email, "required|email")}</span>

                            </div>
                            <div className="flex-align next-button">
                                <Link className="btn light-green" onClick={onSave}>Save</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ReassignTeam;

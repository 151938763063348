import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { imagesArr } from '../../assets/images';

class notification extends Component {
    render() {
        const { flag, data, inviteType } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="green-header modal-header">
                                <h3>Notification</h3>
                                <div className="modal_close" onClick={this.props.close}><img src={imagesArr.white_modal_close} alt="modal_close" /></div>
                            </div>
                            <div className="modal-body">
                                <div className="notifiaction-content">
                                    <div className="notifiaction-content-scroll">

                                        {
                                            inviteType === "coachteam" &&
                                            data.map((list, i) => {
                                                return (

                                                    <div className="noti_item">
                                                        <div className="notifiaction-img">
                                                            <img src={list.team && list.team.coach.profileUrl ? list.team.coach.profileUrl : imagesArr.default_user} alt="user_basic" />
                                                        </div>
                                                        <div className="notifiaction-dec">
                                                            <h5>Coach {list.team && list.team.coach.fname + " " + list.team.coach.lname} <span> from Team {list.parentTeam && list.parentTeam.name} has invited you to join their team</span></h5>
                                                            {/* <h5>{list.team && list.team.coach.fname + " " + list.team.coach.lname}, <span>has invited you to join {list.parentTeam && list.parentTeam.name}</span></h5> */}
                                                            <Link className="blue_btn" onClick={this.props.close}>Cancel</Link>
                                                            <Link className="blue_btn" onClick={() => this.props.accept(list)}>Accept</Link>
                                                        </div>
                                                    </div>

                                                );
                                            })
                                        }
                                        {
                                            inviteType === "subcoach" &&
                                            data.map((list, i) => {
                                                return (
                                                    <div className="noti_item">
                                                        <div className="notifiaction-img">
                                                            <img src={list.parentTeam && list.parentTeam.teamLogo && list.parentTeam.teamLogo.filePath ? list.parentTeam.teamLogo.filePath : imagesArr.default_user} alt="user_basic" />
                                                        </div>
                                                        <div className="notifiaction-dec">
                                                            <h5>{list.parentTeam && list.parentTeam.coach.fname + " " + list.parentTeam.coach.lname}, <span>has invited you to join {list.parentTeam && list.parentTeam.name}</span></h5>
                                                            <Link className="blue_btn" onClick={this.props.close}>Cancel</Link>
                                                            <Link className="blue_btn" onClick={() => this.props.accept(list)}>Accept</Link>
                                                        </div>
                                                    </div>

                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default notification;

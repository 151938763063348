import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from "../../assets/images";
import Slider from "react-slick";
import { getUserCred } from '../../saga/actions/authantication/login.action';
import { getCompanyPWInfo } from '../../saga/actions/common/api.action';
import queryString from "query-string";
import Cookies from 'js-cookie';
import commonCalls from "../../businesslogic/commonCalls";
import Preloader from '../../components/preloader';
import PurchaseProspectwire from './../../views/popup/purchaseProspectwire';
import { commonHeader } from "../../businesslogic/content";

class diamondConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderCount: 0,
            purchase: false,
            packType: "",
            loader:true
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        window.addEventListener("load",this.handleLoading());
        let know = queryString.parse(this.props.location.search);
        if (know.type && know.key) {
            let item = commonHeader.events.filter((e) => e.key === know.key)[0];
            this.increment();
            this.props.getCompanyPWInfo(item.label, 'DC');
        }
    }

    componentWillUnmount = () =>{
        window.removeEventListener("load",this.handleLoading())
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.commonData &&
            nextProps.commonData.getCompanyDCInfoReq &&
            nextProps.commonData.getCompanyDCInfoData
        ) {
            console.log("nextProps.commonData.getCompanyDCInfoData:--", nextProps.commonData.getCompanyDCInfoData);
            nextProps.commonData.getCompanyDCInfoReq = false;
            if (nextProps.commonData.getCompanyDCInfoData.flag) {
                localStorage.setItem("cid", nextProps.commonData.getCompanyDCInfoData.result._id);
                let result = nextProps.commonData.getCompanyDCInfoData.result;

                this.props.mainBoardInfo.sendReq.companyId = result._id;
                this.props.mainBoardInfo.sendFieldReq.companyId = result._id;
                this.props.profileSummaryInfo.sendSummaryReq.companyId = result._id;

                let companyname = result.name === "GOBTournament" ? "Greater Orlando Baseball" : result.name
                let item = commonHeader.events.filter((e) => e.label === companyname)[0];
                localStorage.setItem("cid", result._id)
                localStorage.setItem("company", item.key)
                localStorage.setItem("companyColor", JSON.stringify(item.colors));
                this.setThemeColor(item.colors, flag => {
                    if (flag) {
                        let knowToken = queryString.parse(this.props.location.search)
                        if (knowToken.token) {
                            nextProps.commonData.getCompanyDCInfoData = null;
                            this.props.getUserCred({ token: knowToken.token }, 'DC');
                        } else {
                            this.setState({
                                purchase: true,
                                packType: 'dcStats',
                            }, () => {
                                this.decrement();
                                nextProps.commonData.getCompanyDCInfoData = null;
                            })
                        }
                    }
                })
            } else {
                this.commonCall.errorMessage(nextProps.commonData.getCompanyDCInfoData.message);
                nextProps.commonData.getCompanyDCInfoData = null;
                this.decrement();
            }
        }

        if (nextProps.loginDetails &&
            nextProps.loginDetails.DCUserCredReq &&
            nextProps.loginDetails.DCUserCredData
        ) {
            console.log("nextProps.loginDetails.DCUserCredData:---", nextProps.loginDetails.DCUserCredData);
            nextProps.loginDetails.DCUserCredReq = false;
            if (nextProps.loginDetails.DCUserCredData.flag) {
                let result = nextProps.loginDetails.DCUserCredData.result;
                let setToken = result.role === "Coach" ? "coachToken" :
                    result.role === "Player" ? "playerToken" :
                        (result.role === "Fan" || result.role === "Parent") ? "fanToken" : "authToken";

                if (setToken === "coachToken") {
                    localStorage.removeItem("playerToken");
                    localStorage.removeItem("fanToken");
                } else if (setToken === "playerToken") {
                    localStorage.removeItem("coachToken");
                    localStorage.removeItem("fanToken");
                } else {
                    localStorage.removeItem("coachToken");
                    localStorage.removeItem("playerToken");
                }
                let knowToken = queryString.parse(this.props.location.search)
                localStorage.setItem("webglToken", knowToken.token);
                Cookies.set('bcSession', knowToken.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
                this.commonCall.setAuthToken(setToken, knowToken.token, flag => {
                    if (flag) {
                        this.decrement();
                        nextProps.loginDetails.DCUserCredData = null;
                        this.props.history.push(`/dcStats${this.commonCall.removeURLParameter(this.props.location.search, 'token')}`);
                        window.location.reload();
                    }
                });
            } else {
                this.commonCall.errorMessage(nextProps.loginDetails.DCUserCredData.message);
                nextProps.loginDetails.DCUserCredData = null;
                this.decrement();
            }
        }
    }

    handleLoading = () => {
        setTimeout(() => {
            this.setState({
                ...this.state,
                loader: false
            })
        }, 3000);
    }

    setThemeColor = (colArr, cb) => {
        localStorage.setItem("companyColor", JSON.stringify(colArr));
        for (let i = 0; i < colArr.length; i++) {
            const element = colArr[i];
            document.body.style.setProperty(element.variable, element.colorCode);
        }
        cb(true);
    }
    
    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    handlePurchaseClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }

    render() {

        const { loaderCount, purchase, packType } = this.state;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            centerMode: true,
            centerPadding: '500px',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        centerMode: false,
                        centerPadding: "0px",
                        slidesToShow: 2,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        centerMode: false,
                        centerPadding: "0px",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };

        return (
            <>
                <Header />
                <section className="hero-banner">
                    <div className="container">
                        <div className="dc-main">
                            <div className="dc-img">
                                <img src={imagesArr.dc_main_mob} />
                            </div>
                            <div className="dc-info">
                                <img src={imagesArr.dimond_connect} />
                                {/* <img src="/static/media/headinglogo5.b254039a.png" alt="headinglogo5" /> */}
                                <h1>The Future of Baseball and Softball is in your hands.</h1>
                                {/* <div className="hero-btn">
                                    <a className="btn">Learn More</a>
                                    <a className="btn white-btn">Find Event</a>
                                </div> */}
                                <div className="shaprater"></div>
                                <p>Download DiamondConnect Now!</p>
                                <div className="store-btn">
                                    {/* <a href="#"><img src={imagesArr.playstore} /></a> */}
                                    <a href="https://apps.apple.com/us/app/diamondconnect-mobile/id1605935504" target='_blank'><img src={imagesArr.appstore} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mobile-slider">
                    <div className="container">
                        <div className="">
                            <p>Finally, you can now manage every practice and every game in one easy-to-use app. Sign up for events, Share your team or player's achievements socially using the ClubHouse. Spread the word and communicate with your players and parents with DC messaging and alerts.
                            </p>
                            <p>
                                Score your games and share your teams and player's stats throughout DiamondConnect. </p>
                            <div className="shaprater"></div>
                        </div>
                        <Slider {...settings}>

                            <div className="slider-items">
                                <div className="mob-img">
                                    <img src={imagesArr.slider_mob_1} />
                                </div>
                            </div>
                            <div className="slider-items">
                                <div className="mob-img">
                                    <img src={imagesArr.slider_mob_2} />
                                </div>
                            </div>
                            <div className="slider-items">
                                <div className="mob-img">
                                    <img src={imagesArr.slider_mob_3} />
                                </div>
                            </div>


                        </Slider>
                    </div>
                </section>
                <section className="event-handled">
                    <div className="container">
                        <p>Events Handled Like Something From the Future!  Find, register, invite players, score games and much more!!</p>
                        <div className="shaprater"></div>
                        <img src={imagesArr.event_handel} />
                    </div>
                </section>
                <section className="connect-section">
                    <div className="container">
                        <p>Coaches can now create and manage their teams with ease.  Invite your players and take control of your rosters with our easy-to-use roster management tools. </p>
                        <div className="shaprater"></div>

                        <div className="connect-bg">
                            <img src={imagesArr.dimond_connect} />
                            <div className="shaprater"></div>
                            <p>Connect, Communicate and post your results! for Coaches, Players, and Fans.</p>
                            <div className="doubble-mob">

                                <img src={imagesArr.doubble_mob} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="integrated-partners">
                    <div className="container">
                        <div className="row">
                            <div className="info">
                                <h4>Integrated with our Partners</h4>
                                <h6>Get the most out of your data</h6>
                                <p className="m-0">DiamondConnect partnered with the best data companies in baseball.
                                    Now you can use your data like never before.</p>
                                <p>
                                    DiamondConnect can help you transform your data into something meaningful.
                                </p>
                                <div className="partners-info-img">
                                    <img src={imagesArr.partners_1} />
                                    <img src={imagesArr.partners_2} />
                                    <img src={imagesArr.partners_3} />
                                </div>
                            </div>
                            <div className="img-group">
                                <img src={imagesArr.partners_group_2} />
                                <img src={imagesArr.partners_group_1} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="award-group">
                    <div className="container">
                        <div className="row">
                            <div className="img-group">
                                <img src={imagesArr.awards_group} />
                            </div>
                            <div className="info">
                                <h4>Get Noticed</h4>
                                <h6>Work hard and earn your DC Awards</h6>
                                <p>Have yourself a weekend and earn the MVP.
                                </p>
                                <p>
                                    Work hard to unlock your potential and compete to earn your trophies.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="store-section">
                    <div className="container">
                        <div className="store">
                            <img src={imagesArr.dimond_connect} />
                            <div className="shaprater"></div>
                            <h3>Download DiamondConnect Today!</h3>
                            <div className="store-group">
                                {/* <img src={imagesArr.and_store} /> */}
                                <img src={imagesArr.ios_store} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="comming-soon">
                    <div className="">
                        <div className="info">
                            {/* <h5>Coming Soon</h5> */}
                            <p>With over 25,000 prospects, Prospect Wire has become one of the fastest-growing organizers of elite-level high school baseball events across the country.
                                Prospect Wire is excited to announce PW+, a new online dashboard that allows all participants to receive scouting reports, data-driven AnalyzR reports, videos on their PW+ player profiles, and more
                            </p>
                            {/* <button className="btn">
                                Learn More
                            </button> */}
                        </div>
                    </div>
                    <img className="computer-img" src={imagesArr.computer} />
                </section>
                <Footer />
                <Preloader flag={(loaderCount > 0  || this.state.loader) ? true : false} />
                {
                    purchase &&
                    <PurchaseProspectwire
                        flag={purchase}
                        close={this.handlePurchaseClose}
                        type={packType}
                    />
                }
            </>
        )
    }
}

diamondConnect.propTypes = {
    getCompanyPWInfo: PropTypes.func.isRequired,
    getUserCred: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    commonData: state.api,
    loginDetails: state.loginData,
    mainBoardInfo: state.mainBoardInfo,
    profileSummaryInfo: state.profileSummaryInfo,
});

export default connect(mapStateToProps, { getCompanyPWInfo, getUserCred })(diamondConnect);

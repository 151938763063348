export const GET_TEAM_SEARCH_REQUEST = 'GET_TEAM_SEARCH_REQUEST';
export const GET_TEAM_SEARCH_SUCCESS = 'GET_TEAM_SEARCH_SUCCESS';
export const GET_TEAM_SEARCH_ERROR = 'GET_TEAM_SEARCH_ERROR';

export const UP_TEAM_IMAGE_REQUEST = 'UP_TEAM_IMAGE_REQUEST';
export const UP_TEAM_IMAGE_SUCCESS = 'UP_TEAM_IMAGE_SUCCESS';
export const UP_TEAM_IMAGE_ERROR = 'UP_TEAM_IMAGE_ERROR';

export const SAVE_TEAM_REQUEST = 'SAVE_TEAM_REQUEST';
export const SAVE_TEAM_SUCCESS = 'SAVE_TEAM_SUCCESS';
export const SAVE_TEAM_ERROR = 'SAVE_TEAM_ERROR';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR';

export const SAVE_CLAIM_REQUEST = 'SAVE_CLAIM_REQUEST';
export const SAVE_CLAIM_SUCCESS = 'SAVE_CLAIM_SUCCESS';
export const SAVE_CLAIM_ERROR = 'SAVE_CLAIM_ERROR';

export const GET_CLAIM_REQUEST = 'GET_CLAIM_REQUEST';
export const GET_CLAIM_SUCCESS = 'GET_CLAIM_SUCCESS';
export const GET_CLAIM_ERROR = 'GET_CLAIM_ERROR';

export const GET_TEAM_FLOW_REQUEST = 'GET_TEAM_FLOW_REQUEST';
export const GET_TEAM_FLOW_SUCCESS = 'GET_TEAM_FLOW_SUCCESS';
export const GET_TEAM_FLOW_ERROR = 'GET_TEAM_FLOW_ERROR';




import React, { useEffect, useState } from 'react';
import Header from '../containers/header';
import Footer from '../containers/footer';
import { useHistory, useLocation } from 'react-router-dom';
import LeftAuthImage from '../components/leftAuthImage';
import CommonHeroBanner from '../components/commonHeroBanner';
import commonCalls from "../businesslogic/commonCalls";
import Preloader from '../components/preloader';
import Cookies from 'js-cookie';
import { verifyCMSAuth } from '../saga/actions/authantication/verify.action';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PROFILE_SUCCESS } from '../saga/constants/authantication/verify.const';
import { imagesArr } from '../assets/images';
import queryString from "query-string";

function VerifyCMSAuth() {
    const commonCall = new commonCalls();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [role, setRole] = useState("")
    const { profileData: _INFO } = useSelector((state) => state.verifyOtp);

    const location = useLocation();
    const { redirectTo } = queryString.parse(location.search);
    
    useEffect(() => {
      
         setLoader(true);
        localStorage.removeItem("webglToken")
        localStorage.removeItem("loginToken")
        localStorage.removeItem("coachToken")
        localStorage.removeItem("playerToken")
        let url = new URL(document.location);
        let role = url.pathname.split("/")[2];
        let token = url.pathname.split("/")[3];
        localStorage.setItem("webglToken", token);
        localStorage.setItem("loginToken", token);
        Cookies.set('bcSession', token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        let setToken = role === "Coach" ? "coachToken" :
            role === "Player" ? "playerToken" : "authToken";
        setRole(role);
        commonCall.setAuthToken(setToken, token, flag => {
            if (flag) {
                verifyCMSAuth()(dispatch)
            }
        });
    }, [])

    useEffect(() => {
        if (_INFO) {
            if (_INFO.result.profile.isCompleted) {
                dispatch({
                    type: GET_PROFILE_SUCCESS,
                    payload: null
                })
                setLoader(false);
                if (role === "Coach") {
                    history.push(redirectTo ? redirectTo : '/coach-dashboard');
                } else if (role === "Player") {
                    history.push(redirectTo ? redirectTo : '/player-dashboard');
                }
            } else {
                dispatch({
                    type: GET_PROFILE_SUCCESS,
                    payload: null
                })
                if (role === "Coach") {
                    setLoader(false);
                    history.push('/coach-signup4');
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                } else if (role === "Player") {
                    setLoader(false);
                    history.push('/player-signup4');
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }
            }
        }
    }, [_INFO])

    return (
        <>
            <Header />
            <CommonHeroBanner dataList="" />
            <section className="main-login">
                <div className="container">
                    <div className="common-login">
                        <LeftAuthImage />
                        <div className="common-dec">
                            <div className="heading-for">
                                <h3>Sign In</h3>
                            </div>
                            {/* <span className="login_alert_text">Please sign up using your email. Social sign up will be available shortly upon approval.</span> */}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {
                loader &&
                <div className={`loader loaderBg`}>
                    <img src={imagesArr.loader} />
                </div>
            }
        </>
    );
}

export default VerifyCMSAuth;

import React, { Component } from 'react'
import Header from "../../../containers/header"
import Footer from "../../../containers/footer"
import { imagesArr } from "../../../assets/images"
import Slider from "react-slick";
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import ContactUs from "../../../views/company/components/common/contactUs";
import TeamInfo from '../../popup/teamInfo';
import Shop from "../../../views/company/components/common/shop";
import { ourTeamContent } from '../../../businesslogic/content';
import Preloader from '../../../components/preloader';
export default class pwbAbout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
            popupData: [],
            loaderCount: 0
        };
    }

    componentDidMount = () => {
    }

    setPopupContent = (data) => {
        this.setState({
            openPopup: true,
            popupData: data
        })
    }

    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            openPopup: false,
            popupData: []
        })
    }

    renderContent = () => {
        return (
            ourTeamContent &&
            ourTeamContent.about &&
            ourTeamContent.about.pwb &&
            ourTeamContent.about.pwb.map((item, i) => {
                return (
                    <div key={i} className="w-33" >
                        <div className="team-box">
                            <h5>{item.title}
                                {
                                    item.description.length !== 0 &&
                                    <span className="page-icon">
                                        <i class="fa fa-file" aria-hidden="true" onClick={() => this.setPopupContent(item)}></i>
                                    </span>
                                }
                            </h5>
                            <span>{item.position}</span>
                            <p>{item.email}</p>
                        </div>
                    </div>
                )
            })
        )
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }

    render() {
        const { openPopup, popupData, loaderCount } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            centerPadding: '258px',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        centerMode: true,
                        centerPadding: '90px',
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '0px',
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (
            <div >
                <Header />
                <div className="event-main">
                    <div className="container-fluid">
                        <div className="about-slider">
                            <Slider {...settings}>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_1} alt="user profile" />
                                        <h5>Bryan Huie, Owner</h5>
                                        <h6>Baseball’s Next Level (Tucson, AZ)</h6>
                                        <p>Prospect Wire has been a tremendous help to our BNL players and families. Their events are fantastic with numerous colleges in attendance to see our players. Thanks again Prospect Wire. We always look forward to playing in your next event.</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_2} alt="user profile" />
                                        <h5>Ryan Robinson, Owner</h5>
                                        <h6>Next Level Baseball (Tallahassee, FL)</h6>
                                        <p>Prospect Wire events always come as advertised, and are a tremendous value to organizations and players. If you are looking for highly organized events, with great competition and exposure...Prospect Wire delivers every time!!!</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_3} alt="user profile" />
                                        <h5>Eric Gardner, Director</h5>
                                        <h6>Marucci Athletics (Peoria, AZ)</h6>
                                        <p>We can't say enough how excited we are to continue to build our relationship with Prospect Wire while attending their events across the southwest. Entering our 10th year in Prospect Wire events, Brian Werner has created a first-class experience for our players, coaches and families. We are fortunate to have built relationships with the Prospect Wire staff as they are all true baseball people with a commitment to serve that is second to none. The ability for our players to have access to the best competition in baseball has provided the opportunities for our players to gain local and national exposure that has created a gateway to the next level.</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_4} alt="user profile" />
                                        <h5>Josh Glassey, Director</h5>
                                        <h6>CBA Wave (San Diego CA)</h6>
                                        <p>CBA Wave always looks forward to the Prospect Wire events. They are very organized and they always get great competition for us to play. They worked very hard to bring multiple premier events to California, allowing organizations who spend a ton of money on travel to other states, the chance to stay home, while still playing top level competition.</p>
                                    </div>
                                </div>
                                <div className="slider-items">
                                    <div className="about-slider-card text-center">
                                        <img src={imagesArr.about_slide_5} alt="user profile" />
                                        <h5>Jamie Matlock, Director</h5>
                                        <h6>Blueprint Baseball (Murfreesboro, TN)</h6>
                                        <p>Our experiences with Prospect Wire are unmatched by any organization. The player promotion, quality competition & customer service all in top notch venues are just a few things that set apart Prospect Wire from others. Anybody can run a tournament, but Prospect Wire makes it an event!</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="history-vision">
                        <div className="container">

                            <div className="history-vision-info">
                                <div className="hv-box">
                                    <h5 className="section_title_left">Who We Are</h5>
                                    <p>Prospect Wire was founded in 2005 by Matt Bomeisl as a way to give high school baseball players a platform to promote their skills to college coaches and MLB scouts. Starting with individual showcases in South Florida for the first few years, Prospect Wire added a team tournament in 2009 (Florida State Finals), an event that is still run today as the Southeast Championships. In 2011, Prospect Wire made the decision to take their events across the country to Arizona, and brought Jeff Spelman on board to help assist with our introduction to a new set of teams. Jeff founded Team One Baseball in the early 90’s and brought a wealth of showcase experience to the table. In 2016, Prospect Wire once again made the decision to expand, adding a major event in greater Los Angeles (LA Invitational) and following that in 2017 with the addition of the San Diego Invitational. Today, after 17 years in the industry, Prospect Wire continues it’s goal of bringing a unique tournament & showcase experience to players, coaches and families around the country.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">What We Do</h5>
                                    <p>Prospect Wire runs a variety of events ranging from individual player showcases, team tournaments, ID Camps, to All American Games and more. These events allow us to capture a database of player information that is easily transferable to evaluators in the industry. Since the beginning, our database is housed at Prospectwire.com, but in 2021, it will be moving to the DiamondConnect software application. In the last few years, Prospect Wire has become an industry leader in producing high quality player videos at our events. These videos get posted to various social media platforms for college coaches and scouting evaluators to see. </p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">Why We Do It</h5>
                                    <p>When it comes to high school sports in general, quality information is scattered all over the place. Many times, worthy players are lost in the shuffle of larger events. The top 1-2% of baseball players who are bound for the major leagues do not need the assistance of organizations like ours to showcase their skills. Our focus is on the other 98% who can benefit from our platforms. Many players graduate from high school without a scholarship waiting. Many take the walk on route just for the change to be a college baseball player. Prospect Wire is here to provide a variety of opportunities for players of this nature to cross the eyes of a college recruiter. We are here to give back to the game that gave so much to us.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About Our Events</h5>
                                    <p>Prospect Wire offers a wide variety of events, ranging from round robin team tournaments, where teams have a chance to perform without worrying about saving pitching for a playoff run. There are small, weekend events, as well as large scale national tournaments with teams coming in from multiple states. Attending a Prospect Wire event feels different than most other companies. Our customer service level is second to none, as we take multiple steps to ensure our spectators & teams enjoy their experience. Our showcases feed into larger events, such as the PW All American Games, the NextGen All Americans and the Data World Series. All our showcases, and soon our tournaments, will feature advanced analytic data capturing equipment powered by BaseballCloud.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About our Growth</h5>
                                    <p>Prospect Wire has grown exponentially over the last 17 years. Spending the first 4 years focusing on small showcases, Prospect Wire quickly moved into team tournaments, using the ultra talented state of Florida as our main location. With only 22 teams in 2009, the Florida State Finals quickly increased in size, topping 125 teams for the first time in 2014. In 2011, Prospect Wire ran it’s first events out of state, selecting Arizona as the first location to expand to. Since that time, 6 events have been added to the Arizona schedule, with most of them selling out year after year. In 2016, we were encouraged to try to bring a large scale event to California, a state not well known for national baseball tournaments, mainly due to the lack of baseball complexes. Today, both our LA Invitational & San Diego Invitational routinely draw 80-100 teams from all over the west coast.</p>
                                </div>
                                <div className="hv-box">
                                    <h5 className="section_title_left">About our Future</h5>
                                    <p>Prospect Wire experienced a large change in 2019, when Founder & President Matt Bomeisl sold the company to Kevin Davidson & DS Sports Ventures. With DS Sports came the introduction to BaseballCloud, a community focused on enhancing amateur baseball through the use of data. Similar to the Apple iCloud, BaseballCloud was designed to be a one stop shop for all analytic data capturing devices. Along with BaseballCloud, Prospect Wire is excited for the launch of the new DiamondConnect App, a platform that will unify all of DS Sports Ventures companies, and more importantly, allow Prospect Wire to franchise out to different states in a more efficient and controlled manner. The goal is to bring Prospect Wire events to over a dozen states by 2025, while continuing to host National Tournaments & Showcases through our corporate headquarters. Infusing the newest technology, along with old school scouting, Prospect Wire will continue to be an industry leader, while providing a first class experience to our customers.</p>
                                </div>

                            </div>

                            <div className="rating-system">
                                <h1 className="section_title_left">About the PW Rating System</h1>
                                <div className="ratting-system-list">
                                    <div className="row">
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Players first.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Reliable reports.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Organized events.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Scouted heavily.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Personal assistance.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Exceptional opportunity</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Candid feedback.</p>
                                            </div>
                                        </div>
                                        <div className="w-25">
                                            <div className="list-box">
                                                <p>Talent rich.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="our-team">
                        <div className="container">
                            <h1 className="section_title">Our Team</h1>
                            {/* <p className="text-center mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cras urna gravida duis in nulla. Nec at bibendum tristique feugiat volutpat amet enim quisque.</p> */}
                            <div className="team-list">
                                <div className="row">
                                    {this.renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactUs cType="PW Baseball" increment={this.increment} decrement={this.decrement} />
                </div>
                <div className="upcomingevent_section">
                    <Shop cType="pwb" />
                </div>
                <ProudSponsors increment={this.increment} decrement={this.decrement} />
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
                {
                    openPopup &&
                    <TeamInfo
                        flag={openPopup}
                        close={this.handleClose}
                        content={popupData}
                    />
                }
            </div>
        )
    }
}

import React, { Component } from 'react';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import DivisionSlider from "../../../views/company/components/slider/divisionSlider";
import AdsSlider from "../../../views/company/components/slider/adsSlider";
import UpComingEvents from "../../../views/company/components/common/upComingEvents";
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import Shop from "../../../views/company/components/common/shop";
import Preloader from '../../../components/preloader';
import { ourTeamContent } from '../../../businesslogic/content';
import TeamInfo from '../../popup/teamInfo';
import Slider from "react-slick";
import { imagesArr } from '../../../assets/images';
import { Link } from "react-router-dom";

export default class pwsShowcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderCount: 0,
      bannerReq: {
        moduleId: 1
      },
      openPopup: false,
      popupData: []
    }
  }

  componentDidMount = () => {
    localStorage.removeItem("textName");
  }

  increment = (active) => {
    this.setState(prevState => {
      return { loaderCount: active ? active :  prevState.loaderCount + 1}
    })
  }

  decrement = () => {
    this.setState(prevState => {
      return { loaderCount: prevState.loaderCount - 1 }
    })
  }

  setPopupContent = (data) => {
    this.setState({
      openPopup: true,
      popupData: data
    })
  }

  renderContent = () => {
    return (
      ourTeamContent &&
      ourTeamContent.showcase &&
      ourTeamContent.showcase.pws &&
      ourTeamContent.showcase.pws.map((item, i) => {
        return (
          <div onClick={() => this.setPopupContent(item)}>
            <div className="player-box">
              <div className="player-img">
                <img src={item.image} alt="player" />
              </div>
              <div className="player-name">
                <h5>{item.title}</h5>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      openPopup: false,
      popupData: []
    })
  }

  render() {
    const { loaderCount, bannerReq, openPopup, popupData } = this.state;
    var playerslider = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (
      <>
        <Header />
        <div className="event-main showcase-page">

          <DivisionSlider increment={this.increment} decrement={this.decrement} />

          {/* banner section */}

          <AdsSlider increment={this.increment} decrement={this.decrement} bannerReq={bannerReq} />

          <div className="showcase-diffrent">
            <div className="container">
              <h1 className="section_title">What Makes PW Camps Different?</h1>
              <div className="showcase-info">
                <p>Prospect Wire has set out to provide affordable and extremely valuable Camps experiences for athletes looking to play at the next level. Prospect Wire Camps focus on getting the players national exposure along with valuable reports to help player development and advancement. Through Prospect Wire Camps you will become PWCertified, which means we will provide you a certificate that includes all your metrics and data at the end of the event. This PWCertified Certificate is also shared with college coaches throughout the country and those coaches trust that the metrics and data being captured is accurate. We also include all of your PWCertified data and metrics into your personalized athlete profile, this service allows you to see how you rank in the national leaderboard and allow you to customize your profile and easily share with coaches. Prospect Wire staff will also be identifying players at all Camps for invite-only events and the PW All American Games.</p>
                <p>Prospect Wire Camps have partnered with the leading software solution in NCAA SoftballCloud. SoftballCloud creates value and solutions for data that are focused on player development and the player identification process. Getting yourself familiarized with their products will guarantee you success on the next level of your career.</p>
                <p className="text-center"><b>SoftballCloud comes with a wide range of products</b></p>
                <div className="rang-product row">
                  <div className="w-25 bcteam">
                    <div className="product-img">
                      <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/landing/playerdevelopment.gif" />
                      <div className="overlay">
                        <p>Innovative Software dedicated to transforming Data into Leaderboards.</p>
                        <Link to="/bcteam" target="_blank">Learn More</Link>
                      </div>
                    </div>
                    <div className="product-logo">
                      <img src={imagesArr.headinglogo1} />
                    </div>
                  </div>
                  <div className="w-25 ballr">
                    <div className="product-img">
                      <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/ballr/ballr.gif" />
                      <div className="overlay">
                        <p>Innovative Software dedicated to transforming Data into Leaderboards.</p>
                        <Link to="/ballR" target="_blank">Learn More</Link>
                      </div>
                    </div>
                    <div className="product-logo">
                      <img src={imagesArr.headinglogo2} />
                    </div>
                  </div>
                  <div className="w-25 analyzr">
                    <div className="product-img">
                      <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/landing/playerdevelopment1.gif" />
                      <div className="overlay">
                        <p>Innovative Software dedicated to transforming Data into Leaderboards.</p>
                        <Link to="/analyzr" target="_blank">Learn More</Link>
                      </div>
                    </div>
                    <div className="product-logo">
                      <img src={imagesArr.headinglogo5} />
                    </div>
                  </div>
                  <div className="w-25 pitchr">
                    <div className="product-img">
                      <img src="https://diamondconnect.s3.us-east-2.amazonaws.com/website/img/landing/pitchr.gif" />
                      <div className="overlay">
                        <p>Innovative Software dedicated to transforming Data into Leaderboards.</p>
                        <Link to="/pitchr" target="_blank">Learn More</Link>
                      </div>
                    </div>
                    <div className="product-logo">
                      <img src={imagesArr.headinglogo3} />
                    </div>
                  </div>
                  
                </div>
                {/* <ul>
                  <li><b>AnalyzR:</b> Cutting edge reporting tool.</li>
                  <li><b>BallR:</b> 3D Ball visualization software focusing on pitch release and spin metrics.</li>
                  <li><b>PitchR:</b> Virtual software to visualize all types of data collected on a custom 3D Field.</li>
                  <li><b>BCTeam:</b> Innovative Software dedicated to transforming Data into Leaderboards.</li>
                </ul> */}
                <p className="text-center"><b>We are also proud to have partnerships with the following data capturing companies</b></p>
                {/* <ul>
                  <li><b>Diamond Kinetics:</b> Leader in SwingTracker SwingSensor and Pitch Tracker Smart Baseball</li>
                  <li><b>Stalker Sport:</b> The most accurate and trusted pitch velocity, ball spin rate, and ball speed.</li>
                  <li><b>Yakkertech:</b> The most comprehensive and accurate data from ball tracking to player motion</li>
                </ul> */}
                <div className="partnerships">
                  <div className="row align-items-center">
                    <div className="w-25">
                      <div className="partnerships-box">
                        <div className="partnerships-img">
                          <img src={imagesArr.dimond_kinetics} />
                        </div>
                        <div className="overlay">
                          <h4>Diamond Kinetics</h4>
                          <p>Leader in SwingTracker SwingSensor and Pitch Tracker Smart Baseball.</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-25">
                      <div className="partnerships-box">
                        <div className="partnerships-img">
                          <img src={imagesArr.stalker_sports} />
                        </div>
                        <div className="overlay">
                          <h4>Stalker Sport </h4>
                          <p>The most accurate and trusted pitch velocity, ball spin rate, and ball speed.</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-25">
                      <div className="partnerships-box">
                        <div className="partnerships-img">
                          <img src={imagesArr.Alternate_Logo} />
                        </div>
                        <div className="overlay">
                          <h4>Yakkertech</h4>
                          <p>The most comprehensive and accurate data from ball tracking to player motion.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <p>You will also have the opportunity to have your recruiting video edited and produced by one of the industry leaders in sports video production. We guarantee that with this video, your chances of getting seen and recruited will increase substantially. Come find out what makes Prospect Wire Camps the best in the industry!</p>
              </div>
            </div>
          </div>

          <div className="player-info">
            <div className="container-fluid">
              <div className="player-slider">
                <Slider {...playerslider}>
                  {this.renderContent()}
                </Slider>
              </div>
            </div>
          </div>

          {/* upcoming Event */}

          <UpComingEvents isPresent={3} filterEventType={false} filterCompany={false} eventTeamType={2} cType="pws" textName="Camps" increment={this.increment} decrement={this.decrement} eventLabel={true} eventLinkLabel={true} filterForm={false} hideChkBox={false} noOfRec={6} eventType={2} />

          {/* Shop */}

          <div className="upcomingevent_section">
            <Shop cType="pws" />
          </div>

          {/* Proud Sponsors */}

          <ProudSponsors increment={this.increment} decrement={this.decrement}/>

        </div>
        <Footer />
        {
          openPopup &&
          <TeamInfo
            flag={openPopup}
            close={this.handleClose}
            content={popupData}
          />
        }

        <Preloader flag={loaderCount > 0 ? true : false} />
      </>
    )
  }
}

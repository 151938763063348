import {
    GET_HEAD_PROFILE_REQUEST,
    GET_HEAD_PROFILE_SUCCESS,
    GET_HEAD_PROFILE_ERROR,

    GET_HEAD_PLAYER_PROFILE_REQUEST,
    GET_HEAD_PLAYER_PROFILE_SUCCESS,
    GET_HEAD_PLAYER_PROFILE_ERROR,

    GET_HEAD_FAN_PROFILE_REQUEST,
    GET_HEAD_FAN_PROFILE_SUCCESS,
    GET_HEAD_FAN_PROFILE_ERROR,

    GET_HEAD_COMMI_PROFILE_REQUEST,
    GET_HEAD_COMMI_PROFILE_SUCCESS,
    GET_HEAD_COMMI_PROFILE_ERROR,

    GET_DOB_PLAYER_PROFILE_REQUEST,
    GET_DOB_PLAYER_PROFILE_SUCCESS,
    GET_DOB_PLAYER_PROFILE_ERROR,

    GET_CHANGE_PASSWORD_ERROR,
    GET_CHANGE_PASSWORD_SUCCESS,

    GET_RESET_PASSWORD_ERROR,
    GET_RESET_PASSWORD_SUCCESS,
} from '../../constants/containers/header.const';

const initialState = {
    headReq: false,
    headData: null,
    commonData: null,
    headError: null,

    headPlayerReq: false,
    headPlayerData: null,
    headPlayerError: null,

    headFanReq: false,
    headFanData: null,
    headFanError: null,

    headCommiReq: false,
    headCommiData: null,
    headCommiError: null,

    dobPlayerReq: false,
    dobPlayerData: null,
    dobPlayerError: null,

    passwordData: null,
    passwordError: null,

    resetPasswordData: null,
    resetPasswordError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // GET PROFILE data

        case GET_HEAD_PROFILE_REQUEST:
            return {
                ...state,
                headReq: true,
            };
        case GET_HEAD_PROFILE_SUCCESS:
            return {
                ...state,
                headData: action.payload,
                commonData: action.payload
            };
        case GET_HEAD_PROFILE_ERROR:
            return {
                ...state,
                headError: action.error,
            };

        // GET Player PROFILE data

        case GET_HEAD_PLAYER_PROFILE_REQUEST:
            return {
                ...state,
                headPlayerReq: true,
            };
        case GET_HEAD_PLAYER_PROFILE_SUCCESS:
            return {
                ...state,
                headPlayerData: action.payload,
            };
        case GET_HEAD_PLAYER_PROFILE_ERROR:
            return {
                ...state,
                headPlayerError: action.error,
            };

        // GET Fan PROFILE data

        case GET_HEAD_FAN_PROFILE_REQUEST:
            return {
                ...state,
                headFanReq: true,
            };
        case GET_HEAD_FAN_PROFILE_SUCCESS:
            return {
                ...state,
                headFanData: action.payload,
            };
        case GET_HEAD_FAN_PROFILE_ERROR:
            return {
                ...state,
                headFanError: action.error,
            };

        // GET Commissioner PROFILE data

        case GET_HEAD_COMMI_PROFILE_REQUEST:
            return {
                ...state,
                headCommiReq: true,
            };
        case GET_HEAD_COMMI_PROFILE_SUCCESS:
            return {
                ...state,
                headCommiData: action.payload,
            };
        case GET_HEAD_COMMI_PROFILE_ERROR:
            return {
                ...state,
                headCommiError: action.error,
            };

        // GET Player PROFILE data

        case GET_DOB_PLAYER_PROFILE_REQUEST:
            return {
                ...state,
                dobPlayerReq: true,
            };
        case GET_DOB_PLAYER_PROFILE_SUCCESS:
            return {
                ...state,
                dobPlayerData: action.payload,
            };
        case GET_DOB_PLAYER_PROFILE_ERROR:
            return {
                ...state,
                dobPlayerError: action.error,
            };

        //change password
        case GET_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordData: action.payload
            }
        case GET_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                passwordError: action.error
            }

        //reset password
        case GET_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordData: action.payload
            }
        case GET_RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordError: action.error
            }
        default:
            return state;
    }
}
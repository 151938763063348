import {
    GET_DIV_REQUEST,
    GET_DIV_SUCCESS,
    GET_DIV_ERROR,

    SAVE_ROSTER_REQUEST,
    SAVE_ROSTER_SUCCESS,
    SAVE_ROSTER_ERROR,

    GET_ROSTER_REQUEST,
    GET_ROSTER_SUCCESS,
    GET_ROSTER_ERROR,

    GET_ROSTER_BY_PARENT_TEAM_REQUEST,
    GET_ROSTER_BY_PARENT_TEAM_SUCCESS,
    GET_ROSTER_BY_PARENT_TEAM_ERROR,

    POST_ROSTER_BY_PARENT_TEAM_REQUEST,
    POST_ROSTER_BY_PARENT_TEAM_SUCCESS,
    POST_ROSTER_BY_PARENT_TEAM_ERROR
} from '../../../../constants/authantication/signup/coach/roster.const';

const initialState = {
    divisionRes: false,
    divisionData: null,
    divisionDataError: null,

    saveRosterRes: false,
    saveRosterData: null,
    saveRosterDataError: null,

    rosterRes: false,
    rosterData: null,
    rosterDataError: null,

    rosterListRes: false,
    rosterListData: null,
    rosterListDataError: null,

    saveRosterByTeamRes: false,
    saveRosterByTeamData: null,
    saveRosterByTeamDataError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Get Division
        case GET_DIV_REQUEST:
            return {
                ...state,
                divisionRes: true
            };
        case GET_DIV_SUCCESS:
            return {
                ...state,
                divisionData: action.payload,
            };
        case GET_DIV_ERROR:
            return {
                ...state,
                divisionDataError: action.error,
            };

        // Get Roster
        case GET_ROSTER_REQUEST:
            return {
                ...state,
                rosterRes: true
            };
        case GET_ROSTER_SUCCESS:
            return {
                ...state,
                rosterData: action.payload,
            };
        case GET_ROSTER_ERROR:
            return {
                ...state,
                rosterDataError: action.error,
            };

        // save roster
        case SAVE_ROSTER_REQUEST:
            return {
                ...state,
                saveRosterRes: true
            };
        case SAVE_ROSTER_SUCCESS:
            return {
                ...state,
                saveRosterData: action.payload,
            };
        case SAVE_ROSTER_ERROR:
            return {
                ...state,
                saveRosterDataError: action.error,
            };

        // get roster list by parent team
        case GET_ROSTER_BY_PARENT_TEAM_REQUEST:
            return {
                ...state,
                rosterListRes: true
            };
        case GET_ROSTER_BY_PARENT_TEAM_SUCCESS:
            return {
                ...state,
                rosterListData: action.payload,
            };
        case GET_ROSTER_BY_PARENT_TEAM_ERROR:
            return {
                ...state,
                rosterListDataError: action.error,
            };

             // post roster list by parent team
        case POST_ROSTER_BY_PARENT_TEAM_REQUEST:
            return {
                ...state,
                saveRosterByTeamRes: true
            };
        case POST_ROSTER_BY_PARENT_TEAM_SUCCESS:
            return {
                ...state,
                saveRosterByTeamData: action.payload,
            };
        case POST_ROSTER_BY_PARENT_TEAM_ERROR:
            return {
                ...state,
                saveRosterByTeamDataError: action.error,
            };
        default:
            return state;
    }
}
import React, { useState, useEffect, memo } from 'react';
// import { bracketTabInfo } from './eventTab';
import { Bracket as BracketGenerator } from "react-tournament-bracket";
import { imagesArr } from '../../../../../assets/images';

function Bracket({ allRecapDescription, activeTab, eventRecapChartTabHandler, eventTabTitle, bracketTab }) {

    useEffect(() => {
        document.querySelectorAll("g > rect").forEach(i => {
            i.style.fill = "white"
            i.style.strokeWidth = "1"
            i.style.strokeLinejoin = "round"
            i.style.stroke = "rgb(150, 207, 4)"
            return i;
        })
        document.querySelectorAll("g > path").forEach(i => {
            i.style.stroke = "rgb(150, 207, 4)"
            return i;
        })
    }, [allRecapDescription])

    return (
        <>
          {
                allRecapDescription?.scheduleLive ?
                    (allRecapDescription?.isGold || allRecapDescription?.isSilver || allRecapDescription?.isBronze)  ?
                        <div className="bracket-results-tab">
                            <div className="container">
                                <div className="bracket-tab">
                                    <div className="row">
                                        {/* {bracketTabInfo?.map(({ title, id }) => (
                                <div key={id} className="w-33">
                                    <button className={`tab ${bracketTab === id ? "active" : ""}
                                    $
                                    `} 
                                    onClick={() => eventRecapTabHandler(activeTab, eventTabTitle, title)}>{title}</button>
                                </div>
                            ))} */}
                                        <div key={"Gold"} className="w-33">
                                            <button className={`tab ${bracketTab === "Gold" && allRecapDescription.isGold ? "active" : ""} ${allRecapDescription.isGold ? "" : "disable"} `}
                                                onClick={() => eventRecapChartTabHandler(activeTab, eventTabTitle, "Gold")}>Gold</button>
                                        </div>
                                        <div key={"Silver"} className="w-33">
                                            <button className={`tab ${bracketTab === "Silver" ? "active" : ""} ${allRecapDescription.isSilver ? "" : "disable"} `}
                                                onClick={() => eventRecapChartTabHandler(activeTab, eventTabTitle, "Silver")}>Silver</button>
                                        </div>
                                        <div key={"Bronze"} className="w-33">
                                            <button className={`tab ${bracketTab === "Bronze" ? "active" : ""} ${allRecapDescription.isBronze ? "" : "disable"} `}
                                                onClick={() => eventRecapChartTabHandler(activeTab, eventTabTitle, "Bronze")}>Bronze</button>
                                        </div>


                                    </div>
                                </div>
                                <div className="bracket_chart">
                                    {allRecapDescription.chart?.length === 0 && allRecapDescription?.qsf?.length === 0 && <p className='text-center'>No data found</p>}
                                    <div className={`bracket_chart_info ${(allRecapDescription.bracket < 3) ? 'bracket_chart_center' : ''} `}>
                                        <div className='full_bracket_chart text-center'>
                                            {
                                                allRecapDescription.chart &&
                                                    allRecapDescription?.chart[0]?.key?.length === 0 ?
                                                    allRecapDescription?.qsf && allRecapDescription?.qsf?.map(i => i.key.map((match) => {
                                                        return (
                                                            <BracketGenerator
                                                                game={match}
                                                                hoveredTeamId={null}
                                                                lineInfo={{ yOffset: -10, separation: 10, homeVisitorSpread: 15 }}
                                                                gameDimensions={{ width: 300, height: 130 }}
                                                                roundSeparatorWidth={50}
                                                                svgPadding={0}
                                                                styles={
                                                                    {
                                                                        backgroundColor: 'white',
                                                                        scoreBackground: 'white',
                                                                        winningScoreBackground: 'white',
                                                                        teamNameStyle: { fill: '#8392A5', fontSize: 12, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                                                                        teamScoreStyle: { fill: '#8392A5', fontSize: 12, fontFamily: "Helvetica Medium" },
                                                                        gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                                                                        gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                                                                        teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                                                                    }
                                                                }
                                                            />
                                                        )
                                                    }))
                                                    :
                                                    allRecapDescription.chart &&
                                                        allRecapDescription.chart.length !== 0 ?
                                                        allRecapDescription.chart[0].key.map((match) => {
                                                            return (
                                                                <BracketGenerator
                                                                    game={match}
                                                                    hoveredTeamId={null}
                                                                    lineInfo={{ yOffset: -10, separation: 10, homeVisitorSpread: 15 }}
                                                                    gameDimensions={{ width: 300, height: 130 }}
                                                                    roundSeparatorWidth={50}
                                                                    svgPadding={0}
                                                                    styles={
                                                                        {
                                                                            backgroundColor: 'white',
                                                                            scoreBackground: 'white',
                                                                            winningScoreBackground: 'white',
                                                                            teamNameStyle: { fill: '#8392A5', fontSize: 12, textShadow: '', fontFamily: "Helvetica Medium", fontStyle: "normal" },
                                                                            teamScoreStyle: { fill: '#8392A5', fontSize: 12, fontFamily: "Helvetica Medium" },
                                                                            gameNameStyle: { fill: '#8392A5', fontSize: 11, fontFamily: "Helvetica Medium" },
                                                                            gameTimeStyle: { fill: '#8392A5', fontSize: 11, display: 'none', fontFamily: "Helvetica Medium" },
                                                                            teamSeparatorStyle: { stroke: 'black', strokeWidth: 0 }
                                                                        }
                                                                    }
                                                                />
                                                            )
                                                        })
                                                        :
                                                        ""
                                            }
                                        </div>
                                        {/* {
                                    (allRecapDescription?.gameStatus === 7 && allRecapDescription?.winnerTeam) &&
                                        allRecapDescription?.isCoChampions ?
                                        allRecapDescription?.winnerTeam.map(_ => {
                                            return (
                                                <div className='winner_team'>
                                                    <img src={_.teamLogo ? _.teamLogo : imagesArr.default_user} />
                                                    <p>{_.rosterName}</p>
                                                    <span>Co-Champions</span>
                                                </div>
                                            )
                                        })
                                        : allRecapDescription?.winnerTeam && Object.keys(allRecapDescription?.winnerTeam).length !== 0 &&
                                        <div className='winner_team'>
                                            <img src={allRecapDescription.winnerTeam.teamLogo ? allRecapDescription.winnerTeam.teamLogo : imagesArr.default_user} />
                                            <p>{allRecapDescription?.winnerTeam?.rosterName}</p>
                                            <span>Champion</span>
                                        </div>
                                } */}


                                        {
                                            (allRecapDescription?.gameStatus === 7 && allRecapDescription?.winnerTeam) &&
                                            Object.keys(allRecapDescription?.winnerTeam).length !== 0 &&
                                            <div className='winner_team'>
                                                <img src={allRecapDescription.winnerTeam.teamLogo ? allRecapDescription.winnerTeam.teamLogo : imagesArr.default_user} />
                                                <p>{allRecapDescription?.winnerTeam?.rosterName}</p>
                                                <span>Champion</span>
                                            </div>

                                        }
                                        {allRecapDescription?.isCoChampions && allRecapDescription?.winnerTeam ?
                                            allRecapDescription?.winnerTeam.map(_ => {
                                                return (
                                                    <div className='winner_team'>
                                                        <img src={_.teamLogo ? _.teamLogo : imagesArr.default_user} />
                                                        <p>{_.rosterName}</p>
                                                        <span>Co-Champions</span>
                                                    </div>
                                                )
                                            })
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <p className="text-center">No Data Found</p>
                    : <p className="text-center">Revised Schedule Coming Soon</p>
            }

        </>
    )
}

export default memo(Bracket)

import {
    FORGOT_REQUEST,
    FORGOT_SUCCESS,
    FORGOT_ERROR,
} from '../../constants/authantication/forgot.const';

const initialState = {
    forgotReq: false,
    forgotData: null,
    forgotError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        
        // Forgot req    
        case FORGOT_REQUEST:
            return {
                ...state,
                forgotReq: true,
            };
        case FORGOT_SUCCESS:
            return {
                ...state,
                forgotData: action.payload,
            };
        case FORGOT_ERROR:
            return {
                ...state,
                forgotError: action.error,
            };

        default:
            return state;
    }
}
import React, { Component } from 'react';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import DivisionSlider from "../../../views/company/components/slider/divisionSlider";
import AdsSlider from "../../../views/company/components/slider/adsSlider";
import UpComingEvents from "../../../views/company/components/common/upComingEvents";
import ProudSponsors from "../../../views/company/components/common/proudSponsors";
import Shop from "../../../views/company/components/common/shop";
import Preloader from '../../../components/preloader';
import { ourTeamContent } from '../../../businesslogic/content';
import TeamInfo from '../../popup/teamInfo';
import { imagesArr } from '../../../assets/images';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AdditionalInfo from '../components/common/AdditionalInfo';

export default class pioneerShowcase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaderCount: 0,
      bannerReq: {
        moduleId: 2
      },
      openPopup: false,
      popupData: []
    }
  }

  componentDidMount = () => {
    localStorage.removeItem("textName");
  }

  increment = (active) => {
    this.setState(prevState => {
      return { loaderCount: active ? active :  prevState.loaderCount + 1}
    })
  }

  decrement = () => {
    this.setState(prevState => {
      return { loaderCount: prevState.loaderCount - 1 }
    })
  }

  setPopupContent = (data) => {
    this.setState({
      openPopup: true,
      popupData: data
    })
  }

  renderContent = () => {
    return (
      ourTeamContent &&
      ourTeamContent.showcase &&
      ourTeamContent.showcase.pioneer &&
      ourTeamContent.showcase.pioneer.map((item, i) => {
        return (
          <div onClick={() => this.setPopupContent(item)}>
            <div className="player-box">
              <div className="player-img">

                <img src={item.image} alt="player" />
              </div>
              <div className="player-name">
                <h5>{item.title}</h5>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  handleClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      openPopup: false,
      popupData: []
    })
  }

  render() {
    const { loaderCount, bannerReq, openPopup, popupData } = this.state;
    var playerslider = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (
      <>
        <Header />
        <div className="event-main showcase-page">

        <img style={{width: '100%'}} src={imagesArr.pioneer_banner_homepage} alt="pioneer"/>

          {/* <DivisionSlider increment={this.increment} decrement={this.decrement} /> */}

          {/* banner section */}

          {/* <AdsSlider increment={this.increment} decrement={this.decrement} bannerReq={bannerReq} /> */}


          {/* <div className="player-info">
            <div className="container-fluid">
              <div className="player-slider">
                <Slider {...playerslider}>
                  {this.renderContent()}
                </Slider>
              </div>
            </div>

          </div> */}

          {/* upcoming Event */}

          <UpComingEvents
            isPresent={3}
            filterEventType={false}
            filterCompany={false}
            eventTeamType={2}
            cType="pioneer"
            textName="Tryouts"
            increment={this.increment}
            decrement={this.decrement}
            eventLabel={true}
            eventLinkLabel={true}
            filterForm={false}
            hideChkBox={false}
            noOfRec={6}
            eventType={1}
          />

          {/* additional info and FAQ */}

          <AdditionalInfo />

          {/* Proud Sponsors */}

          <ProudSponsors increment={this.increment} decrement={this.decrement} />

        </div>
        <Footer />
        {
          openPopup &&
          <TeamInfo
            flag={openPopup}
            close={this.handleClose}
            content={popupData}
          />
        }

        <Preloader flag={loaderCount > 0 ? true : false} />
      </>
    )
  }
}

import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import commonCalls from "../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import { getPlayerOption, savePlayerData, editPlayerData } from '../../saga/actions/views/coach/profile/playerInfo.action';
import DatePicker from "react-datepicker";
import moment from "moment";
import PlayerRoster from "./playerRoster";
import Preloader from '../../components/preloader';

const animatedComponents = makeAnimated();

export class addPlayer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sendReq: {
        rosterTeamId: [],
        phone: "",
        email: "",
        cCode: "+1",
        fname: "",
        lname: "",
        number: "",
        dob: "",
        position: "",
        position2: "",
        isAccept: false
      },
      opencal: false,
      optionsArr: [],
      optionData: "",
      loader: false,
      addRoster: false,
      editId: ""
    };

    this.commonCall = new commonCalls();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    if (this.props.flag) {
      document.body.classList.add("modal-open");
      if (this.props.type === "edit") {
        let data = this.props.editData;
        console.log("data:----", data);
        this.setState((prevState) => ({
          ...prevState,
          sendReq: {
            ...prevState.sendReq,
            rosterTeamId: data.rosterTeamId,
            phone: data.phone,
            email: data.email,
            cCode: "+1",
            fname: data.fname,
            lname: data.lname,
            number: data.number,
            dob: data.dob,
            position: data.position,
            position2: data.position2,
            isAccept: data.isAccept
          },
          editId: data._id
        }), () => {
          let newVal = [];
          data.rosterTeamId.map((item, i) => {
            newVal.push(item._id)
          });
          this.setState((prevState) => ({
            ...prevState,
            sendReq: {
              ...prevState.sendReq,
              rosterTeamId: newVal,
            },
          }));

          let arrOpt = [];
          data.rosterTeamId.map((item, i) => {
            arrOpt.push({ value: item._id, label: item.rosterName })
          });

          this.setState((prevState) => ({
            ...prevState,
            optionData: arrOpt
          }));
        });
      }
    }
    this.setLoader(true);
    this.props.getPlayerOption();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.playerDetails &&
      nextProps.playerDetails.getPlayerOptionReq &&
      nextProps.playerDetails.getPlayerOptionData
    ) {
      console.log("nextProps.getPlayerOptionData:-----", nextProps.playerDetails.getPlayerOptionData);
      nextProps.playerDetails.getPlayerOptionReq = false;
      if (nextProps.playerDetails.getPlayerOptionData.flag) {
        let arr = [];
        nextProps.playerDetails.getPlayerOptionData.result.data.map((item, i) => {
          arr.push({ value: item._id, label: item.rosterName })
        });
        this.setState({
          optionsArr: arr,
        }, () => {
          nextProps.playerDetails.getPlayerOptionData = null;
          this.setLoader(false);
        })
      } else {
        // this.commonCall.errorMessage(nextProps.playerDetails.getPlayerOptionData.message);
        nextProps.playerDetails.getPlayerOptionData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.addNewPlayerReq &&
      nextProps.playerDetails.addNewPlayerData
    ) {
      console.log("nextProps.addNewPlayerData:-----", nextProps.playerDetails.addNewPlayerData);
      nextProps.playerDetails.addNewPlayerReq = false;
      if (nextProps.playerDetails.addNewPlayerData.flag) {
        this.commonCall.successMessage(nextProps.playerDetails.addNewPlayerData.message);
        nextProps.playerDetails.addNewPlayerData = null;
        this.setLoader(false);
        this.props.close();
      } else {
        this.commonCall.errorMessage(nextProps.playerDetails.addNewPlayerData.message);
        nextProps.playerDetails.addNewPlayerData = null;
        this.setLoader(false);
      }
    }

    if (nextProps.playerDetails &&
      nextProps.playerDetails.editNewPlayerReq &&
      nextProps.playerDetails.editNewPlayerData
    ) {
      console.log("nextProps.editNewPlayerData:-----", nextProps.playerDetails.editNewPlayerData);
      nextProps.playerDetails.editNewPlayerReq = false;
      if (nextProps.playerDetails.editNewPlayerData.flag) {
        this.commonCall.successMessage(nextProps.playerDetails.editNewPlayerData.message);
        nextProps.playerDetails.editNewPlayerData = null;
        this.setLoader(false);
        this.props.close();
      } else {
        this.commonCall.errorMessage(nextProps.playerDetails.editNewPlayerData.message);
        nextProps.playerDetails.editNewPlayerData = null;
        this.setLoader(false);
      }
    }

  }

  setLoader = (val) => {
    this.setState({
      loader: val
    })
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  onHandleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 12) {
        if (re.test(value) || value.length <= 0) {
          this.setState((prevState) => ({
            ...prevState,
            sendReq: {
              ...prevState.sendReq,
              [name]: value
            },
          }));
        }
      }
    } else if (name === "number") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 3) {
        if (re.test(value) || value.length <= 0) {
          this.setState((prevState) => ({
            ...prevState,
            sendReq: {
              ...prevState.sendReq,
              [name]: value
            },
          }));
        }
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          [name]: value
        },
      }));
    }

  }

  onHandleDateChange = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      sendReq: {
        ...prevState.sendReq,
        dob: e
      },
    }));
  }

  handleChange = (option) => {
    console.log(`Option selected:`, option);
  };

  setOption = (val) => {
    console.log("val:--", val);
    if (val !== null) {
      let valArr = [];
      val.map((item, i) => {
        valArr.push(item.value)
      });

      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          rosterTeamId: valArr
        },
        optionData: val
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        optionData: [],
        sendReq: {
          ...prevState.sendReq,
          rosterTeamId: []
        },
      }));
    }
  }

  savePlayer = () => {
    if (this.validator.allValid()) {
      console.log(this.state.sendReq.rosterTeamId);
      if (this.props.type === "add") {
        if (this.props.parentTeam === 2 || this.props.parentTeam === 3) {
          if (!!this.props.playerList.find((e) => e.email === this.state.sendReq.email)) {
            return this.commonCall.errorMessage("Email already exits.")
          }
        }
        this.setLoader(true);
        this.props.savePlayerData(this.state.sendReq);
      } else {
        if (this.props.parentTeam === 2 || this.props.parentTeam === 3) {
          if (!!this.props.playerList.find((e, j) => { if (j !== this.props.index) { if (e.email === this.state.sendReq.email) { return true } else return false } })) {
            return this.commonCall.errorMessage("Email already exits.")
          }
        }
        let fdata = this.state.sendReq;
        fdata._id = this.state.editId;
        this.setLoader(true);
        this.props.editPlayerData(fdata);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  addRoster = () => {
    this.setState({
      addRoster: true
    })
  }

  onHandleClose = () => {
    document.body.classList.remove("video-open");
    this.setState({
      addRoster: false,
    }, () => {
      this.setLoader(true);
      this.props.getPlayerOption();
    })
  }

  render() {
    const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    const { sendReq, optionsArr, loader, addRoster } = this.state;
    const { flag, type,parentTeam } = this.props;

    return (
      <>
        <section className={`common-modal ${flag ? "show" : ""}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="green-header modal-header">
                <h3>{type === "add" ? "Add" : "Edit"} Player</h3>
                <div className="modal_close" onClick={this.props.close}>
                  <img src={imagesArr.white_modal_close} alt="modal_close" />
                </div>
              </div>
              <div className="modal-body">
                <div className="team-body-inner">
                  <div className="form-group mb-0">
                    <div className="add_roster">
                      <label className="common-lable">Roster Name</label>
                      {type === "add" &&
                        <Link className="btn add-roster-btn" onClick={() => this.addRoster()}> Add New</Link>
                      }
                    </div>
                    <Select
                      defaultValue={this.state.optionData}
                      value={this.state.optionData}
                      options={optionsArr}
                      onInputChange={this.handleChange}
                      onChange={(e) => this.setOption(e)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                    />
                    <span className="validMsg">
                      {this.validator.message(
                        "Roster Name",
                        sendReq.rosterTeamId,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="flex-align row">
                    <div className="form-group">
                      <label className="common-lable">First Name</label>
                      <input
                        type="text"
                        name="fname"
                        value={sendReq.fname}
                        onChange={this.onHandleChange}
                        className="form-control"
                        id="usr"
                        placeholder="First Name"
                        disabled={sendReq.isAccept ? true : false}
                      />
                      <span className="validMsg">
                        {this.validator.message(
                          "first name",
                          sendReq.fname,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="common-lable">Last Name</label>
                      <input
                        type="text"
                        name="lname"
                        value={sendReq.lname}
                        onChange={this.onHandleChange}
                        className="form-control"
                        id="usr"
                        placeholder="Last Name"
                        disabled={sendReq.isAccept ? true : false}
                      />
                      <span className="validMsg">
                        {this.validator.message(
                          "last name",
                          sendReq.lname,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  {parentTeam === 2 || parentTeam === 3 ? 
                   <div className="flex-align row">
                   <div className="form-group">
                     <label className="common-lable">Phone Number</label>
                     <div className="svg_icon">
                       <div className="input-group">
                         <div className="counrty-no">
                           <select className="custom-select" name="cCode" value={sendReq.cCode} onChange={this.onHandleChange}>
                             <option value="+1" selected={
                               sendReq.cCode === "+1"
                                 ? "selected"
                                 : ""
                             }>
                               +1
                             </option>
                           </select>
                         </div>
                         <input
                           type="text"
                           name="phone"
                           value={sendReq.phone}
                           onChange={this.onHandleChange}
                           className="form-control"
                           id="usr"
                           placeholder="Enter phone number"
                         />
                         <img src={imagesArr.smartphone} alt="eye" />
                       </div>
                       <span className="validMsg"> 
                         {this.validator.message(
                           "phone number",
                           sendReq.phone,
                           "required|phone"
                         )}
                       </span>
                     </div>
                   </div>
                   <div className="form-group">
                     <label className="common-lable">Email Address</label>
                     <input
                       type="text"
                       name="email"
                       value={sendReq.email}
                       onChange={this.onHandleChange}
                       className="form-control"
                       id="usr"
                       placeholder="Enter email address"
                       disabled={sendReq.isAccept ? true : false}
                     />
                     <span className="validMsg"> 
                       {this.validator.message(
                         "Email Address",
                         sendReq.email,
                         "required|email"
                       )}
                     </span>
                   </div>
                 </div>:""}
                 
                  <div className="flex-align row">
                    <div className="form-group">
                      <label className="common-lable">What sport do you play ?</label>
                      <div className="check_box disabled">
                        <div className="checkbox">
                          <input type="radio" id="1" name="gameType" value="1" checked={localStorage.getItem("gameType") === "1" ? true : false} disabled />
                          <label  htmlFor="Baseball">Baseball </label>
                        </div>
                        <div className="checkbox mr-0">
                          <input type="radio" id="2" name="gameType" value="2" checked={localStorage.getItem("gameType") === "2" ? true : false} disabled />
                          <label htmlFor="Softball">Softball </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="common-lable">Jersey Number</label>
                      <input
                        type="text"
                        name="number"
                        value={sendReq.number}
                        onChange={this.onHandleChange}
                        className="form-control"
                        id="usr"
                        placeholder="Enter jersey number"
                      />
                      <span className="validMsg">
                        {this.validator.message(
                          "number",
                          sendReq.number,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex-align row">
                    <div className="form-group">
                      <label className="common-lable">Primary Position</label>
                      <select
                        className="custom-select"
                        name="position"
                        value={sendReq.position}
                        onChange={this.onHandleChange}
                      >
                        <option value="" selected disabled>Select position</option>
                        <option
                          value="Pitcher"
                          selected={
                            sendReq.position === "Pitcher" ? "selected" : ""
                          }
                        >
                          Pitcher
                        </option>
                        <option
                          value="Catcher"
                          selected={
                            sendReq.position === "Catcher" ? "selected" : ""
                          }
                        >
                          Catcher
                        </option>
                        <option
                          value="1B"
                          selected={
                            sendReq.position === "1B"
                              ? "selected"
                              : ""
                          }
                        >
                          1B
                        </option>
                        <option
                          value="2B"
                          selected={
                            sendReq.position === "2B"
                              ? "selected"
                              : ""
                          }
                        >
                          2B
                        </option>
                        <option
                          value="3B"
                          selected={
                            sendReq.position === "3B"
                              ? "selected"
                              : ""
                          }
                        >
                          3B
                        </option>
                        <option
                          value="SS"
                          selected={
                            sendReq.position === "SS"
                              ? "selected"
                              : ""
                          }
                        >
                          SS
                        </option>
                        <option
                          value="Infield"
                          selected={
                            sendReq.position === "Infield" ? "selected" : ""
                          }
                        >
                          Infield
                        </option>
                        <option
                          value="Outfield"
                          selected={
                            sendReq.position === "Outfield" ? "selected" : ""
                          }
                        >
                          Outfield
                        </option>
                        <option
                          value="Utility"
                          selected={
                            sendReq.position === "Utility" ? "selected" : ""
                          }
                        >
                          Utility
                        </option>
                      </select>
                      {/* <span className="validMsg">
                        {this.validator.message(
                          "Primary position",
                          sendReq.position,
                          "required"
                        )}
                      </span> */}
                    </div>
                    <div className="form-group">
                      <label className="common-lable">Date of Birth</label>
                      <div className="bc_relative">
                        <DatePicker
                          ref={(c) => (this._calendar = c)}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                          }) => (
                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                              <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                              <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                {years.map(option => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                {months.map(option => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                            </div>
                          )}
                          selected={sendReq.dob ? new Date(sendReq.dob) : sendReq.dob}
                          maxDate={moment().subtract(1, 'days').toDate()}
                          onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                          onChange={this.onHandleDateChange}
                          className="form-control"
                          placeholderText="Enter your birth date"
                        // disabled={sendReq.isAccept ? true : false}
                        />

                        <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                          this.state.opencal = !this.state.opencal;
                          this._calendar.setOpen(this.state.opencal);
                        }} />
                      </div>
                      {/* <span className="validMsg">
                        {this.validator.message(
                          "Birthday",
                          sendReq.dob,
                          "required"
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group">
                      <label className="common-lable">Secondary Position</label>
                      <select
                        className="custom-select"
                        name="position2"
                        value={sendReq.position2}
                        onChange={this.onHandleChange}
                      >
                        <option value="">N/A</option>
                        <option
                          value="Pitcher"
                          selected={
                            sendReq.position2 === "Pitcher" ? "selected" : ""
                          }
                        >
                          Pitcher
                        </option>
                        <option
                          value="Catcher"
                          selected={
                            sendReq.position2 === "Catcher" ? "selected" : ""
                          }
                        >
                          Catcher
                        </option>
                        <option
                          value="1B"
                          selected={
                            sendReq.position2 === "1B"
                              ? "selected"
                              : ""
                          }
                        >
                          1B
                        </option>
                        <option
                          value="2B"
                          selected={
                            sendReq.position2 === "2B"
                              ? "selected"
                              : ""
                          }
                        >
                          2B
                        </option>
                        <option
                          value="3B"
                          selected={
                            sendReq.position2 === "3B"
                              ? "selected"
                              : ""
                          }
                        >
                          3B
                        </option>
                        <option
                          value="SS"
                          selected={
                            sendReq.position2 === "SS"
                              ? "selected"
                              : ""
                          }
                        >
                          SS
                        </option>
                        <option
                          value="Infield"
                          selected={
                            sendReq.position2 === "Infield" ? "selected" : ""
                          }
                        >
                          Infield
                        </option>
                        <option
                          value="Outfield"
                          selected={
                            sendReq.position2 === "Outfield" ? "selected" : ""
                          }
                        >
                          Outfield
                        </option>
                        <option
                          value="Utility"
                          selected={
                            sendReq.position2 === "Utility" ? "selected" : ""
                          }
                        >
                          Utility
                        </option>
                      </select>
                      {/* <span className="validMsg">
                        {this.validator.message(
                          "Secondary position",
                          sendReq.position2,
                          "required"
                        )}
                      </span> */}
                    </div>
                  </div>

                </div>
                <div className="flex-align next-button">
                  <Link className="btn light-green" onClick={() => this.savePlayer()}>
                    Save
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          addRoster &&
          <PlayerRoster
            flag={addRoster}
            close={this.onHandleClose}
            type={type}
          />
        }
        <Preloader flag={loader} />
      </>
    );
  }
}

addPlayer.propTypes = {
  getPlayerOption: PropTypes.func.isRequired,
  savePlayerData: PropTypes.func.isRequired,
  editPlayerData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  playerDetails: state.playerInfo,
});

export default connect(mapStateToProps, { getPlayerOption, savePlayerData, editPlayerData })(addPlayer);

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import { imagesArr } from "../../../../assets/images";
import LeagueRow from '../leagues/details/leagueRow';
import { getLeagueDetails } from '../../../../saga/actions/common/leagueDetails.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import Preloader from "../../../../components/preloader";

class leagueJoin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            eventId: "",
            loader: false,
            eventDetailData: [],
            transactionId: "2432655675e464324",
            amount: 0,
            paymentType: 1,
            card: "**** **** **** 4242",
        }
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        this.setState({
            eventId: eventId,
        }, () => {
            if (localStorage.getItem("transaction")) {
                let data = JSON.parse(localStorage.getItem("transaction"));
                this.setState({
                    transactionId: data.transactionId,
                    amount: data.amount,
                    paymentType: data.paymentType,
                    card: data.card,
                });
            }
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueDetailsReq &&
            nextProps.leagueDetails.leagueDetailsData
        ) {
            nextProps.leagueDetails.leagueDetailsReq = false;
            console.log("nextProps.leagueDetails:--", nextProps.leagueDetails.leagueDetailsData);
            if (nextProps.leagueDetails.leagueDetailsData.flag) {
                let fdata = nextProps.leagueDetails.leagueDetailsData.result;
                this.setState({
                    eventDetailData: fdata,
                }, () => {
                    nextProps.leagueDetails.leagueDetailsData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueDetailsData.message);
                nextProps.leagueDetails.leagueDetailsData = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        this.props.getLeagueDetails(this.state.eventId, true);
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, eventDetailData, eventId, paymentType, amount, transactionId, card } = this.state;
        var paymentMath = "Full Payment $" + amount;
        if (paymentType === 1) {
            paymentMath = "Full Payment $" + amount;
        } else if (paymentType === 2 || paymentType === 0) {
            paymentMath = "Day Of Event $" + amount;
        } else if (paymentType === 3) {
            paymentMath = "Pay Installment $" + amount;
        }
        return (
            <div className="event_details">
                <Header />
                <CommonHeroBanner dataList={eventDetailData} />
                <div className="container">
                    <LeagueRow dataList={eventDetailData} isHideJoinButton={true} eventId={eventId} complete={true} isHideAddEventButton={true} />
                    <div className="event-container">
                        <div className="event-thanq">
                            <div className="thanq-title">
                                <h4>Thank You!</h4>
                                <p>
                                    Your payment was successful and your team has joined the event
                                    {/* {paymentType === 2 ? "Thanks for registering. Your payment is due the day of the event." : "Thanks for registering."} */}
                                </p>
                            </div>
                            <div className="table-responsive-xl">
                                <table>
                                    <tr>
                                        <th>Transaction ID:</th>
                                        <td>{transactionId}</td>
                                    </tr>
                                    <tr>
                                        <th>Payment Method:</th>
                                        <td>{paymentMath}</td>
                                    </tr>
                                    {
                                        (paymentType === 1 || paymentType === 3) &&
                                        <>
                                            <tr>
                                                <th>Payment Type:</th>
                                                <td>Credit card</td>
                                            </tr>
                                            <tr>
                                                <th>Card Number:</th>
                                                <td>{card}</td>
                                            </tr>
                                        </>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </div>
        )
    }
}

leagueJoin.propTypes = {
    getLeagueDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leagueDetails: state.leagueDetails,
    eventDetails: state.eventDetails,
});
var leagueJoinComponent = connect(mapStateToProps, { getLeagueDetails })(leagueJoin);
export default withRouter(leagueJoinComponent);
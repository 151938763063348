import axios from 'axios';
import { API_Url, checkIsValidPlayerToken, Auth_Player_Key } from '../../../../helpers/authToken';
import {
    ADD_PARENT_REQUEST,
    ADD_PARENT_ERROR,
    ADD_PARENT_SUCCESS,
    LIST_PARENT_REQUEST,
    LIST_PARENT_ERROR,
    LIST_PARENT_SUCCESS,
    EDIT_LINKED_PARENT_REQUEST,
    EDIT_LINKED_PARENT_SUCCESS,
    EDIT_LINKED_PARENT_ERROR,
    DISCONNECT_PARENT_REQUEST,
    DISCONNECT_PARENT_SUCCESS,
    DISCONNECT_PARENT_ERROR,
    PHONE_VERIFY_PARENT_REQUEST,
    PHONE_VERIFY_PARENT_SUCCESS,
    PHONE_VERIFY_PARENT_ERROR
} from '../../../../constants/view/player/profile/parentInfo.const';
import histrory from '../../../../../History';

export const saveParent = (data) => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: ADD_PARENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/parent/add`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: ADD_PARENT_SUCCESS,
            payload: addnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: ADD_PARENT_ERROR, error: error.response.data.message })
        }
    });
};

export const parentList = (data) => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: LIST_PARENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/parent/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: LIST_PARENT_SUCCESS,
            payload: addnew.data.result.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: LIST_PARENT_ERROR, error: error.response.data.message })
        }
    });
};

export const editParent = (data) => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: EDIT_LINKED_PARENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/parent/basic/edit`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(edit => {
        dispatch({
            type: EDIT_LINKED_PARENT_SUCCESS,
            payload: edit.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: EDIT_LINKED_PARENT_ERROR, error: error.response.data.message })
        }
    });
};

export const disconnectParent = (data) => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: DISCONNECT_PARENT_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/player/parent/disconnect`,
        data: data,
        headers: {
            'x-auth-token':Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(addnew => {
        dispatch({
            type: DISCONNECT_PARENT_SUCCESS,
            payload: addnew.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: DISCONNECT_PARENT_ERROR, error: error.response.data.message })
        }
    });
};

export const phoneVerifyForParent = (data) => dispatch => {
    checkIsValidPlayerToken(histrory);
    dispatch({ type: PHONE_VERIFY_PARENT_REQUEST })
    axios({
        method: 'POST', 
        url: `${API_Url}bc/player/parent/basic_verfiy`,
        data: data,
        headers: {
            'x-auth-token': Auth_Player_Key,
            'Accept': 'application/json'
        }
    }).then(verify => {
        dispatch({
            type: PHONE_VERIFY_PARENT_SUCCESS,
            payload: verify.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: PHONE_VERIFY_PARENT_ERROR, error: error.response.data.message })
        }
    });
};

import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { imagesArr } from "../../assets/images";
import { addPlayerInfo, getPlayerList } from "../../saga/actions/common/accountCreation.action";
import {  POST_PLAYER_INFO_SUCCESS } from "../../saga/constants/common/accountCreation.const";
import { toast } from "react-toastify";

const initialState = {
  add: {
    rosterTeamId: "",
    phone: "",
    email: "",
    cCode: "",
    fname: "",
    lname: "",
    number: "",
    dob: "",
    position: ""
  },
  "game-type": "",
  opencal: "",
}

const reducer = (state, payload) => ({ ...state, ...payload })

function AddPlayerClaim({ flag, close, rosterAllDeatils, state, setState }) {

  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const ref = useRef();

  const [player, setPlayer] = useReducer(reducer, initialState);
  const { postPlayerInfo: _ADD } = useSelector((state) => state.accountCreation);
  const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  useEffect(() => {
    if (rosterAllDeatils) {
      let url = new URL(document.location);
      let rosterId = url.pathname.split("/")[2];
      setPlayer({
        ...player,
        add: {
          rosterTeamId: rosterId,
        },
        "game-type": rosterAllDeatils["game-type"]
      })
    }
  }, [rosterAllDeatils])

  useEffect(() => {
    if (_ADD) {
      if (_ADD.flag) {
        toast.success(_ADD.message, { position: toast.POSITION.TOP_RIGHT })
        setState({
          ...state,
          openRoster: false,
          loader: false
        })
        getPlayerList(state.rosterReq)(dispatch);
        dispatch({
          type: POST_PLAYER_INFO_SUCCESS,
          payload: null
        })
      } else {
        setState({
          ...state,
          loader: false
        })
        toast.error(_ADD.message, { position: toast.POSITION.TOP_RIGHT })
        dispatch({
          type: POST_PLAYER_INFO_SUCCESS,
          payload: null
        })
      }
    }
  }, [_ADD])

  const onlyNumber = event => {
    const keycode = event.which;
    if (keycode === 13) {
    } else if (
      !(
        event.shiftKey === false &&
        (keycode === 8 ||
          keycode === 37 ||
          keycode === 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    )
      event.preventDefault();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "number") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 2) {
        if (re.test(value) || value.length <= 0) {
          setPlayer({
            ...player,
            add: {
              ...player.add,
              [name]: value
            }
          })
        }
      }
    } else {
      setPlayer({
        ...player,
        add: {
          ...player.add,
          [name]: value
        }
      })
    }
  }

  const onSave = () => {
    if (validator.current.allValid()) {
      setState({
        ...state,
        loader: true
    })
      addPlayerInfo(player.add)(dispatch)
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  }

  return (
    <>
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>Add Player</h3>
              <div className="modal_close" onClick={close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="team-body-inner">

                <div class="form-group w-100 mb-0">
                  <label class="common-lable">First Name</label>
                  <input type="text" class="form-control" id="usr" name="fname" placeholder="Enter first Name" value={player.add.fname} onChange={handleChange} />
                  <span className="validMsg">{validator.current.message("first name", player.add.fname, "required", { className: "text-danger" })}</span>
                </div>

                <div class="form-group w-100 mb-0">
                  <label class="common-lable">Last Name</label>
                  <input type="text" class="form-control" id="usr" name="lname" placeholder="Enter last name" value={player.add.lname} onChange={handleChange} />
                  <span className="validMsg">{validator.current.message("last name", player.add.lname, "required", { className: "text-danger" })}</span>
                </div>

                <div class="form-group w-100 mb-0">
                  <label class="common-lable">Jersey Number</label>
                  <input type="text" class="form-control" id="usr" name="number" placeholder="Enter jersey number" value={player.add.number} onChange={handleChange} onKeyPress={onlyNumber} />
                  <span className="validMsg">{validator.current.message("jersey number", player.add.number, "required", { className: "text-danger" })}</span>
                </div>

                {/* <div class="form-group w-50 mb-0">
                            <label class="common-lable">Phone Number</label>
                            <div class="svg_icon">
                                <div class="input-group">
                                    <div class="counrty-no">
                                        <select class="custom-select" name="cCode" value={player.add.cCode} onChange={handleChange} >
                                            <option value="+1">+1</option>
                                        </select>
                                    </div>
                                    <input type="text" class="form-control" name="phone" id="usr" maxlength="12" placeholder="Enter phone number" value={player.add.phone} onChange={handleChange} onKeyPress={onlyNumber} />
                                    <img src={imagesArr.smartphone} alt="eye" />
                                </div>
                            </div>
                        </div> */}

                {/* <div class="form-group w-100 mb-0">
                  <label class="common-lable">Primary Position</label>
                  <select class="custom-select" name="position" value={player.add.position} onChange={handleChange}>
                    <option value="" disabled="">Select position</option>
                    <option value="Pitcher">Pitcher</option>
                    <option value="Catcher">Catcher</option>
                    <option value="1B">1B</option>
                    <option value="2B">2B</option>
                    <option value="3B">3B</option>
                    <option value="SS">SS</option>
                    <option value="Infield">Infield</option>
                    <option value="Outfield">Outfield</option>
                    <option value="Utility">Utility</option>
                  </select>
                  <span className="validMsg">{validator.current.message("primary position", player.add.position, "required", { className: "text-danger" })}</span>
                </div>

                <div class="form-group w-100 mb-0">
                  <label class="common-lable">Email Address</label>
                  <input type="text" class="form-control" id="usr" name="email" placeholder="Enter email address" value={player.add.email} onChange={handleChange} />
                  <span className="validMsg">{validator.current.message("email", player.add.email, "email", { className: "text-danger" })}</span>
                </div> */}


                {/*                     
                    <div class="flex-align row">
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">What sport do you play ?</label>
                            <div class="check_box disabled">
                                <div class="checkbox p-0">
                                    <input type="radio" id="1" name="gameType" value="1" checked={player["game-type"] == "1" ? true : false} disabled />
                                    <label for="Baseball">Baseball </label>
                                </div>
                                <div class="checkbox mr-0 p-0">
                                    <input type="radio" id="2" name="gameType" value="2" checked={player["game-type"] === "2" ? true : false} disabled />
                                    <label for="Softball">Softball </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group w-50 mb-0">
                            <label class="common-lable">Jersey Number</label>
                            <input type="text" class="form-control" id="usr" name="number" placeholder="Enter jersey number" value={player.add.number} onChange={handleChange} onKeyPress={onlyNumber} />
                        </div>
                    </div> */}
                {/* <div class="flex-align row">
                  <div class="form-group w-50 mb-0">
                    <label class="common-lable">Primary Position</label>
                    <select class="custom-select" name="position" value={player.add.position} onChange={handleChange}>
                      <option value="" disabled="">Select position</option>
                      <option value="Pitcher">Pitcher</option>
                      <option value="Catcher">Catcher</option>
                      <option value="1B">1B</option>
                      <option value="2B">2B</option>
                      <option value="3B">3B</option>
                      <option value="SS">SS</option>
                      <option value="Infield">Infield</option>
                      <option value="Outfield">Outfield</option>
                      <option value="Utility">Utility</option>
                    </select>
                    <span className="validMsg">{validator.current.message("primary position", player.add.position, "required", { className: "text-danger" })}</span>
                  </div>
                   <div class="form-group w-50 mb-0">
                            <label class="common-lable">Birthdate</label>
                            <div className="bc_relative">
                                <DatePicker
                                    ref={ref}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                    }) => (
                                        <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                            <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                            <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                {years.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                            <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                {months.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                            <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                        </div>
                                    )}
                                    selected={player.add.dob ? new Date(player.add.dob) : player.add.dob}
                                    maxDate={moment().subtract(1, 'days').toDate()}
                                    //   onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                    onChange={(date) => setPlayer({ ...player, add: { ...player.add, dob: date } })}
                                    className="form-control"
                                    placeholderText="Enter your birth date"
                                />

                                <img src={imagesArr.calendar} className="bc_datepicker-icon" alt="calendar" onClick={() => {
                                    player.opencal = !player.opencal;
                                    ref.current.setOpen(player.opencal);
                                }} />
                            </div>
                        </div>
                </div> */}
                <div>
                  {/* <div class="form-group mb-0">
                            <label class="common-lable">Secondary Position</label>
                            <select class="custom-select" name="position2" value={player.add.position2} onChange={handleChange}>
                                <option value="">N/A</option>
                                <option value="Pitcher">Pitcher</option>
                                <option value="Catcher">Catcher</option>
                                <option value="1B">1B</option>
                                <option value="2B">2B</option>
                                <option value="3B">3B</option>
                                <option value="SS">SS</option>
                                <option value="Infield">Infield</option>
                                <option value="Outfield">Outfield</option>
                                <option value="Utility">Utility</option>
                            </select>
                        </div> */}
                </div>

              </div>
              <div className="flex-align next-button">
                <Link className="btn light-green" onClick={() => onSave()}>
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AddPlayerClaim;


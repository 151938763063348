export const GET_PLAYER_TRAINING_REQUEST = 'GET_PLAYER_TRAINING_REQUEST';
export const GET_PLAYER_TRAINING_SUCCESS = 'GET_PLAYER_TRAINING_SUCCESS';
export const GET_PLAYER_TRAINING_ERROR = 'GET_PLAYER_TRAINING_ERROR';

export const SAVE_PLAYER_TRAINING_REQUEST = 'SAVE_PLAYER_TRAINING_REQUEST';
export const SAVE_PLAYER_TRAINING_SUCCESS = 'SAVE_PLAYER_TRAINING_SUCCESS';
export const SAVE_PLAYER_TRAINING_ERROR = 'SAVE_PLAYER_TRAINING_ERROR';






import React, { useEffect, useState } from 'react';
import moment from "moment";
import Slider from "react-slick";
import { getSumOfRegisterTeam, getAvailableSport } from "../../../businesslogic/content";

var ballrslider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};
const EventSlider = ({ eventList, redirectDetails }) => {
    const [display, setDisplay] = useState(true)

    useEffect(() => {
        setDisplay(false)
    }, [eventList])

    useEffect(() => {
        if (!display) setDisplay(true)
    }, [display])

    if (!display) return null

    return (
        <>
            <Slider {...ballrslider}>
                {eventList.length !== 0 &&
                    eventList.map((e, item) => {
                        const location = e.location && e.location.streetAddress.split(",");
                        return (
                            <div key={item} onClick={() => redirectDetails(e)}>
                                <div className="event_box">
                                    <div className="event_card">
                                        {
                                            e.invited &&
                                            <div className="black-layer">
                                                <p>You haven't fully registered for this event. Click here to finish registration now.</p>
                                            </div>
                                        }
                                        <div class="event_card_img">
                                            <img src={e.eventImages && e.eventImages.filePath ? e.eventImages.filePath : "https://diamondconnect.s3.us-east-2.amazonaws.com/1/c51257cb3f79268ae73e34e3f97903f4.png"} alt="event" />
                                            {

                                                (e.location && (e.location.city || e.location.streetAddress || e.location.stateRegion)) &&
                                                <span className="event_card_address">
                                                    {e.location &&
                                                        e.location.city ?
                                                        e.location.city :
                                                        e.location.streetAddress ?
                                                            location[0] :
                                                            e.location.stateRegion}
                                                </span>
                                            }
                                        </div>
                                          {/* {!chkdate && */}
                                          <div class="event_tags text-right">
                                            {e.regCountDown !== "Registration Closed" ? 'Registration Closes In ' : ""}<span>{e.regCountDown}</span>
                                            </div>
                                        {/* } */}
                                        <div class="event_card_desc">
                                            <div class="event_card_logo">
                                                <img src={e.eventLogo && e.eventLogo.filePath ? e.eventLogo.filePath : "https://diamondconnect.s3.us-east-2.amazonaws.com/1/c51257cb3f79268ae73e34e3f97903f4.png"} alt="event logo" />
                                            </div>
                                            <div class="event_card_titles">
                                                <h3>{e.eventTitle}</h3>
                                                <div class="event_card_dates">
                                                    <p class="date_icon">{moment(e.startDate).format("DD-MMM-YYYY")} - {moment(e.endDate).format("DD-MMM-YYYY")}</p>
                                                    <p class="team_icons">{e.feesByGroups.length ? getSumOfRegisterTeam(e.feesByGroups) : 0} {e.eventTeamType === 1 ? 'Teams Registered' : 'Players Registered'}</p>
                                                    <p class="team_icons">{e.feesByGroups.length ? getAvailableSport(e.feesByGroups) : 0} Available Spots</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

            </Slider>
            {eventList.length === 0 &&
                <p className="text-center mb-0">You’re not registered for any events yet. <a href="#">Download</a> Diamond Connect to find events near you</p>
            }
        </>
    )
}

export default EventSlider
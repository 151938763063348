import React, { useState } from "react";
import { imagesArr } from "../../assets/images";
import AddLinkedPlayer from "./addLinkedPlayer";
import Preloader from "../../components/preloader"

function CreateChildAccount({flag,close}) {
    const [addPlayerPopup,setAddPlayerPopup]=useState(false);
    const [loader,setLoader]=useState(false)
    const closeAddPlayer = () => {
        document.body.classList.remove('modal-open');
        setAddPlayerPopup(false)
        close()
      }
return (
<>
  <section className={`common-modal ${flag ? "show" : "" }`}>
    <div className="modal-dialog create_child_acc_modal">
      <div className="modal-content">
        <div className="modal-header">
          <div className="onlymodal_close" onClick={close}>
            <img src={imagesArr.modal_close} alt="modal_close" />
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-area">
            <h3>Let's Create Your Child’s Player Account</h3>
            <p>Click below to create your Child’s Player account. As a Parent, you can create and manage your children
              under your account. Click below to fill out Player Information and finish registration. You can then claim
              your child’s account via the email invite that was sent. </p>
            <button class="btn light-green" onClick={()=>{
              document.body.classList.add("modal-open");
              setAddPlayerPopup(true)
            }}>Create Player Account</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Preloader flag={loader} />
  {addPlayerPopup &&
        <AddLinkedPlayer
          flag={addPlayerPopup}
          close={closeAddPlayer}
          setLoader={setLoader}
          type="linkedPlayer"
          isNavigate={true}
        />}
</>
);
}

export default CreateChildAccount;
import React, { Component, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { imagesArr } from '../../assets/images';
import { getFanProfile, postChangePassword, resetPassword } from '../../saga/actions/containers/header.action';
import commonCalls from '../../businesslogic/commonCalls';
import { GET_CHANGE_PASSWORD_SUCCESS, GET_CHANGE_PASSWORD_ERROR } from '../../saga/constants/containers/header.const';

function ResetPassword({ flag, close,type }) {
    const validator = useRef(new SimpleReactValidator());
    const commonCall = new commonCalls();
    const [, forceUpdate] = useState();
    const { resetPasswordData: _REG, resetPasswordError: _REGEROR } = useSelector(state => state.headerData);
    const dispatch = useDispatch();
    const [password, setPassword] = useState({
        otype: "password",
        ntype: "password",
        ctype: "password",
        oldPassword: "",
        newPassword: "",
        comformPassword: "",
        notMatch: false,
    });
    useEffect(() => {
        if (flag) {
            document.body.classList.add("modal-open");
        }
    }, [flag]);

    useEffect(() => {
        if (_REG) {
            if (_REG.flag) {
                commonCall.successMessage(_REG.message);
                if (type === "Fan") {
                    getFanProfile()(dispatch);
                }
                close();
                dispatch({
                    type: GET_CHANGE_PASSWORD_SUCCESS,
                    payload: null,
                });
            }
            else if (!_REG.flag) { commonCall.errorMessage(_REG.message) }
            dispatch({
                type: GET_CHANGE_PASSWORD_SUCCESS,
                payload: null,
            });
        }
    }, [_REG])


    useEffect(() => {
        if (_REGEROR) {
            if (_REGEROR.flag) { commonCall.successMessage(_REGEROR.message) }
            else if (!_REGEROR.flag) { commonCall.errorMessage(_REGEROR.message) }
            dispatch({
                type: GET_CHANGE_PASSWORD_ERROR,
                payload: null,
            });
        }
    }, [_REGEROR])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword({
            ...password,
            [name]: value
        })
    }

    const onSave = () => {
        let data;
        setPassword({
            ...password,
            notMatch: false,
        })
        if (validator.current.allValid()) {
            if (password.newPassword === password.comformPassword)
                data = {
                    "oldPassword": password.oldPassword,
                    "newPassword": password.newPassword
                }
            else {
                return setPassword({
                    ...password,
                    notMatch: true,
                })
            }
            resetPassword(data,type)(dispatch);
        } else {
            validator.current.showMessages(true);
            forceUpdate(1);
        }
    }
    return (
        <>
            <section className={`common-modal ${flag ? "show" : ""}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="green-header modal-header">
                            <h3>Reset Password</h3>
                            <div className="modal_close" onClick={close}><img src={imagesArr.white_modal_close} alt="modal_close"  /></div>
                        </div>
                        <div className="modal-body">
                            <div className="team-body-inner">
                                <div className="form-group mb-0">
                                    <label className="common-lable">Enter current password</label>
                                    <div className="svg_icon">
                                        <input type={password.otype} name="oldPassword" value={password.oldPassword} className="form-control" id="usr" placeholder="Current Password" onChange={handleChange} />
                                        {password.otype == "password" ? (
                                            <img src={imagesArr.eye} onClick={() => setPassword({ ...password, otype: "text" })} alt="eye" />
                                        ) : (
                                                <img src={imagesArr.eyeopen} onClick={() => setPassword({ ...password, otype: "password" })} alt="eye" />
                                            )}
                                    </div>
                                    <span className="validMsg">{validator.current.message('Old password', password.oldPassword, 'required')}</span>

                                </div>
                                <div className="form-group mb-0">
                                    <label className="common-lable">New Password</label>
                                    <div className="svg_icon">
                                        <input type={password.ntype} name="newPassword" value={password.newPassword} className="form-control" id="usr" placeholder="Enter New Password" onChange={handleChange} />
                                        {password.ntype == "password" ? (
                                            <img src={imagesArr.eye} onClick={() => setPassword({ ...password, ntype: "text" })} alt="eye" />
                                        ) : (
                                                <img src={imagesArr.eyeopen} onClick={() => setPassword({ ...password, ntype: "password" })} alt="eye" />
                                            )}
                                    </div>
                                    <span className="validMsg">{validator.current.message('New password', password.newPassword, 'required')}</span>

                                </div>
                                <div className="form-group mb-0">
                                    <label className="common-lable">Confirm New Password</label>
                                    <div className="svg_icon">
                                        <input type={password.ctype} name="comformPassword" value={password.comformPassword} className="form-control" id="usr" placeholder="Confirm New Password" onChange={handleChange} />
                                        {password.ctype == "password" ? (
                                            <img src={imagesArr.eye} onClick={() => setPassword({ ...password, ctype: "text" })} alt="eye" />
                                        ) : (
                                                <img src={imagesArr.eyeopen} onClick={() => setPassword({ ...password, ctype: "password" })} alt="eye" />
                                            )}
                                    </div>
                                    <span className="validMsg">{validator.current.message('comform password', password.comformPassword, 'required')}</span>
                                    {password.notMatch && <span className="validMsg">New Password and confirm password must be same</span>}

                                </div>
                            </div>
                            <div className="flex-align next-button">
                                <Link className="btn light-green" onClick={onSave}>Save</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ResetPassword;

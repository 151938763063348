import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from "moment";
import { imagesArr } from '../../../../assets/images';
import { upcomingComLeagues } from '../../../../saga/actions/common/leagues.action';
import commonCalls from "../../../../businesslogic/commonCalls";

class upComingLeague extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                companyId: localStorage.getItem("cid"),
                leagueGames: "",
                noOfLeagueGames: "",
                id: "",
                isPresent: 2,
                startDate: "",
                endDate: "",
                state: "",
                ageGroup: "",
                division: "",
                leagueType: this.props.leagueType,
                currentDate: moment.utc(moment(new Date()).format('DD-MM-YYYY hh:mm:ss'), 'DD-MM-YYYY hh:mm:ss').toISOString()
            },
            leaguesList: []
        };
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.props.increment();
        this.props.upcomingComLeagues(this.state.sendReq);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leaguesInfo &&
            nextProps.leaguesInfo.upcomingLeagueReq &&
            nextProps.leaguesInfo.upcomingLeagueData
        ) {
            console.log("nextProps.leaguesInfo.upcomingLeagueData:---", nextProps.leaguesInfo.upcomingLeagueData);
            nextProps.leaguesInfo.upcomingLeagueReq = false;
            if (nextProps.leaguesInfo.upcomingLeagueData.flag) {
                let fdata = nextProps.leaguesInfo.upcomingLeagueData.result.data[0];
                this.setState({
                    leaguesList: fdata
                }, () => {
                    nextProps.leaguesInfo.upcomingLeagueData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.leaguesInfo.upcomingLeagueData.message);
                nextProps.leaguesInfo.upcomingLeagueData = null;
                this.props.decrement();
            }
        }
    }

    redirectDetails = (data) => {
        this.props.history.push(`/league-detail/${data._id}`);
    }

    renderLeagues = () => {
        return (
            this.state.leaguesList.length !== 0 ?
                this.state.leaguesList.map((list, i) => {
                    const location = list.location && list.location.streetAddress.split(",");
                    if (i < 6) {
                        return (
                            <div className="event_box w-33" key={i} onClick={() => this.redirectDetails(list)}>
                                <div className="event_card">
                                    <div class="event_card_img">
                                        <img src={list.leagueImages && list.leagueImages.filePath} alt="event" />
                                        {

                                            (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                            <span className="event_card_address">
                                                {list.location &&
                                                    list.location.city ?
                                                    list.location.city :
                                                    list.location.streetAddress ?
                                                        location[0] :
                                                        list.location.stateRegion}
                                            </span>
                                        }
                                    </div>
                                    <div class="event_card_desc">
                                        <div class="event_card_logo">
                                            <img src={list.leagueLogo && list.leagueLogo.filePath ? list.leagueLogo.filePath : imagesArr.default_user} alt="event logo" />
                                        </div>
                                        <div class="event_card_titles">
                                            <h3>{list.leagueTitle}</h3>
                                            <div class="event_card_dates">
                                                <p class="date_icon">{moment(list.startDate).format("DD-MMM")} - {moment(list.endDate).format("DD-MMM")}</p>
                                                <p class="team_icons">{list.teamLimit} Teams</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
                :
                <p className="text-center w-100">No leagues found.</p>
        )
    }

    render() {
        return (
            <div className="upcomingevent_section">
                <div className="container">
                    <div className="upcoming_events_title">
                        <h1 className="section_title">Upcoming Leagues </h1>
                    </div>
                    <div className="upcoming_events ">
                        <div className="row justify-content-center">
                            {this.renderLeagues()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

upComingLeague.propTypes = {
    upcomingComLeagues: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leaguesInfo: state.leaguesInfo,
});
var upComingLeagueComponent = connect(mapStateToProps, { upcomingComLeagues })(upComingLeague);
export default withRouter(upComingLeagueComponent);
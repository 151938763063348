import React, {
  Component,
  useRef,
  useState,
  useReducer,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { imagesArr } from "../../assets/images";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import update from "react-addons-update";
import { getCountryCode } from "../../saga/actions/common/api.action";
import DatePicker from "react-datepicker";
import moment from "moment";
import commonCalls from "../../businesslogic/commonCalls";

const initialState = {
  "upload-roster": {
    phone: "",
    lname: "",
    number: "",
    position: "",
    position2: "",
    dob: "",
    fname: "",
    email: "",
    country_code: "+1",
    role: "Player",
    isAccept: false,
    isMail: false
  },
  facilityRoster: [],
  teamRoster: [],
  opencal: false,
};

const reducer = (state, payload) => ({ ...state, ...payload });

function AddRoster({
  flag,
  close,
  type,
  index,
  roster,
  manuallyIndex,
  manuallyRecord,
  updateData,
  handleSaveRoster,
  saveManually,
  record,
  parentTeam
}) {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const commonCall = new commonCalls();
  const dispatch = useDispatch();
  const [manually, setManually] = useReducer(reducer, initialState);
  const {
    phone,
    lname,
    number,
    position,
    position2,
    dob,
    fname,
    email,
    country_code,
    isAccept,
    role,
    isMail
  } = manually["upload-roster"];
  const { facilityRoster, teamRoster, opencal } = manually;

  const { rosterInfoData: _RI } = useSelector((state) => state.rosterInfo);
  const { countryCodeData: _CC } = useSelector((state) => state.api);


  useEffect(() => {
    getCountryCode()(dispatch);
  }, []);

  useEffect(() => {
    if (_RI) {
      setManually({
        facilityRoster: _RI.facilityRoster,
        teamRoster: _RI.teamRoster,
      });
    }
  }, [_RI]);

  useEffect(() => {
    if (manuallyRecord) {
      manuallyRecord.email = manuallyRecord?.email ? manuallyRecord?.email.length === 1 ? "" : manuallyRecord?.email : ""
      setManually({
        "upload-roster": manuallyRecord,
      });
    }
  }, [manuallyRecord]);

  useEffect(() => {
    if (flag) {
      document.body.classList.add("modal-open");
    }
  }, [flag]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 12) {
        if (re.test(value) || value.length <= 0) {
          setManually({
            "upload-roster": {
              ...manually["upload-roster"],
              [name]: value,
            },
          });
        }
      }
    }
    else if (name === "number") {
      const re = /^[0-9\b]+$/;
      if (value.length <= 2) {
        if (re.test(value) || value.length <= 0) {
          setManually({
            "upload-roster": {
              ...manually["upload-roster"],
              [name]: value,
            },
          });
        }
      }
    }
    else {
      setManually({
        "upload-roster": {
          ...manually["upload-roster"],
          [name]: value,
        },
      });
    }
  };

  const handleDateChange = (value) => {
    setManually({
      "upload-roster": {
        ...manually["upload-roster"],
        dob: value,
      },
    });
  }

  const handleSubmit = () => {
    let dRoster;
    if (validator.current.allValid()) {
      dRoster = manually["upload-roster"];
      dRoster.country_code = "+1";
      console.log('record["upload-roster"]', record["upload-roster"])
      if (type === "Add") {
        manually["upload-roster"].isMail = false;
        if (record["upload-roster"].length != 0) {
          if(parentTeam === 2 || parentTeam === 3){
            if (!!record["upload-roster"].find((item) => item.email === dRoster.email)) {
              return commonCall.errorMessage("Email already exits.")
            }
          }
        }
      } else {
        if (parentTeam === 2 || parentTeam === 3) {
          if (!!record["upload-roster"].find((e, j) => { if (j !== manuallyIndex) { if (e.email === dRoster.email) { return true } else return false } })) {
            return commonCall.errorMessage("Email already exits.")
          }
        }
      }
      saveManually(dRoster);
      close();
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const onlyNumber = event => {
    const keycode = event.which;
    if (keycode === 13) {
    } else if (
      !(
        event.shiftKey === false &&
        (keycode === 8 ||
          keycode === 37 ||
          keycode === 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    )
      event.preventDefault();
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return (

    <>
      <section className={`common-modal ${flag ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="green-header modal-header">
              <h3>{type === "Add" ? "Add" : "Edit"} Player</h3>
              <div className="modal_close" onClick={close}>
                <img src={imagesArr.white_modal_close} alt="modal_close" />
              </div>
            </div>
            <div className="modal-body">
              <div className="team-body-inner">
                <div className="flex-align row">
                  <div className="form-group">
                    <label className="common-lable">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      name="fname"
                      placeholder="First Name"
                      value={fname}
                      onChange={handleChange}
                      disabled={type === "Edit" && isAccept ? true : false}
                    />
                    <span className="validMsg">
                      {validator.current.message(
                        "First Name",
                        fname,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="common-lable">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      name="lname"
                      placeholder="Last Name"
                      value={lname}
                      onChange={handleChange}
                      disabled={type === "Edit" && isAccept ? true : false}
                    />
                    <span className="validMsg">
                      {validator.current.message(
                        "Last Name",
                        lname,
                        "required"
                      )}
                    </span>
                  </div>
                </div>
                {parentTeam === 2 || parentTeam === 3 ? 
                  <div className="flex-align row">
                    <div className="form-group">
                      <label className="common-lable">Phone Number</label>
                      <div className="svg_icon">
                        <div className="input-group">
                          <div className="counrty-no">
                            <select className="custom-select" name="country_code" value={country_code} onChange={handleChange}>
                              <option value="+1" selected={country_code === "+1" ? "selected" : ""}>
                                +1
                              </option>
                              {_CC.length !== 0 &&
                                _CC.map((e, index) => {
                                  return (<option key={index} value={e.code}>{e.code}</option>)
                                })
                              }
                            </select>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            id="usr"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={handleChange}
                            onKeyPress={onlyNumber}
                          />
                          <img src={imagesArr.smartphone} alt="eye" />
                        </div>
                        <span className="validMsg"> 
                          {validator.current.message(
                            "Phone No.",
                            phone,
                            "required|phone"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="common-lable">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="usr"
                        name="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={handleChange}
                        disabled={type === "Edit" && isAccept ? true : false}
                      />
                      <span className="validMsg">
                        {validator.current.message(
                          "Email",
                          email,
                          "required|email"
                        )}
                      </span>
                    </div>
                  </div> 
                :""
                }
              
                <div className="flex-align row">
                  <div className="form-group">
                    <label className="common-lable">What sport do you play ?</label>
                    <div className="check_box disabled">
                      <div className="checkbox">
                        <input type="radio" id="1" name="gameType" value="1" checked={localStorage.getItem("gameType") === "1" ? true : false} disabled />
                        <label htmlFor="Baseball">Baseball </label>
                      </div>
                      <div className="checkbox mr-0">
                        <input type="radio" id="2" name="gameType" value="2" checked={localStorage.getItem("gameType") === "2" ? true : false} disabled />
                        <label htmlFor="Softball">Softball </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="common-lable">Jersey Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="usr"
                      name="number"
                      placeholder="Enter jersey number"
                      value={number}
                      onChange={handleChange}
                      onKeyPress={onlyNumber}

                    />
                    <span className="validMsg">
                      {validator.current.message("Number", number, "required")}
                    </span>
                  </div>
                </div>
                <div className="flex-align row">
                  <div className="form-group">
                    <label className="common-lable">Primary Position</label>
                    <select
                      className="custom-select"
                      name="position"
                      value={position}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select position
                      </option>
                      <option
                        value="Pitcher"
                        selected={
                          position === "Pitcher" ? "selected" : ""
                        }
                      >
                        Pitcher
                      </option>
                      <option
                        value="Catcher"
                        selected={
                          position === "Catcher" ? "selected" : ""
                        }
                      >
                        Catcher
                      </option>
                      <option
                        value="1B"
                        selected={
                          position === "1B"
                            ? "selected"
                            : ""
                        }
                      >
                        1B
                      </option>
                      <option
                        value="2B"
                        selected={
                          position === "2B"
                            ? "selected"
                            : ""
                        }
                      >
                        2B
                      </option>
                      <option
                        value="3B"
                        selected={
                          position === "3B"
                            ? "selected"
                            : ""
                        }
                      >
                        3B
                      </option>
                      <option
                        value="SS"
                        selected={
                          position === "SS"
                            ? "selected"
                            : ""
                        }
                      >
                        SS
                      </option>
                      <option
                        value="Infield"
                        selected={
                          position === "Infield" ? "selected" : ""
                        }
                      >
                        Infield
                      </option>
                      <option
                        value="Outfield"
                        selected={
                          position === "Outfield" ? "selected" : ""
                        }
                      >
                        Outfield
                      </option>
                      <option
                        value="Utility"
                        selected={
                          position === "Utility" ? "selected" : ""
                        }
                      >
                        Utility
                      </option>
                    </select>
                    {/* <span className="validMsg">
                      {validator.current.message(
                        "Primary Position",
                        position,
                        "required"
                      )}
                    </span> */}
                  </div>
                  <div className="form-group">
                    <label className="common-lable">Birthdate</label>
                    <div className="bc_relative">
                      {/* <DatePicker
                        selected={dob ? new Date(dob) : dob}
                        maxDate={moment().subtract(1, 'days').toDate()}
                        className="bc_datepicker"
                        onChangeRaw={handleDateChangeRaw}
                        onChange={handleDateChange}
                        placeholderText="Enter your birth date"
                        showMonthDropdown
                        showYearDropdown
                      /> */}

                      <DatePicker
                        // ref={(c) => (this._calendar = c)}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                            <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                            <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                          </div>
                        )}
                        selected={dob ? moment(moment.utc(dob).format('YYYY-MM-DD')).toDate() : dob}
                        maxDate={moment().subtract(1, 'days').toDate()}
                        onChangeRaw={handleDateChangeRaw}
                        onChange={handleDateChange}
                        className="form-control"
                        placeholderText="Enter your birth date"
                      // disabled={type === "Edit" && isAccept ? true : false}
                      />

                      <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" />
                    </div>
                    {/* <span className="validMsg">
                      {validator.current.message(
                        "Birth Date",
                        dob,
                        "required"
                      )}
                    </span> */}
                  </div>
                </div>
                <div className="">
                  <div className="form-group">
                    <label className="common-lable">Secondary Position</label>
                    <select
                      className="custom-select"
                      name="position2"
                      value={position2}
                      onChange={handleChange}
                    >
                      <option value="">N/A</option>
                      <option
                        value="Pitcher"
                        selected={
                          position2 === "Pitcher" ? "selected" : ""
                        }
                      >
                        Pitcher
                      </option>
                      <option
                        value="Catcher"
                        selected={
                          position2 === "Catcher" ? "selected" : ""
                        }
                      >
                        Catcher
                      </option>
                      <option
                        value="1B"
                        selected={
                          position2 === "1B"
                            ? "selected"
                            : ""
                        }
                      >
                        1B
                      </option>
                      <option
                        value="2B"
                        selected={
                          position2 === "2B"
                            ? "selected"
                            : ""
                        }
                      >
                        2B
                      </option>
                      <option
                        value="3B"
                        selected={
                          position2 === "3B"
                            ? "selected"
                            : ""
                        }
                      >
                        3B
                      </option>
                      <option
                        value="SS"
                        selected={
                          position2 === "SS"
                            ? "selected"
                            : ""
                        }
                      >
                        SS
                      </option>
                      <option
                        value="Infield"
                        selected={
                          position2 === "Infield" ? "selected" : ""
                        }
                      >
                        Infield
                      </option>
                      <option
                        value="Outfield"
                        selected={
                          position2 === "Outfield" ? "selected" : ""
                        }
                      >
                        Outfield
                      </option>
                      <option
                        value="Utility"
                        selected={
                          position2 === "Utility" ? "selected" : ""
                        }
                      >
                        Utility
                      </option>
                    </select>
                    {/* <span className="validMsg">
                      {validator.current.message(
                        "Secondary Position",
                        position2,
                        "required"
                      )}
                    </span> */}
                  </div>
                </div>
              </div>
              <div className="flex-align next-button">
                <Link className="btn light-green" onClick={handleSubmit}>
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddRoster;


export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_ERROR = 'RESEND_CODE_ERROR';

export const MOBILE_CODE_REQUEST = 'MOBILE_CODE_REQUEST';
export const MOBILE_CODE_SUCCESS = 'MOBILE_CODE_SUCCESS';
export const MOBILE_CODE_ERROR = 'MOBILE_CODE_ERROR';

export const RESEND_FCODE_REQUEST = 'RESEND_FCODE_REQUEST';
export const RESEND_FCODE_SUCCESS = 'RESEND_FCODE_SUCCESS';
export const RESEND_FCODE_ERROR = 'RESEND_FCODE_ERROR';

export const FVERIFY_CODE_REQUEST = 'FVERIFY_CODE_REQUEST';
export const FVERIFY_CODE_SUCCESS = 'FVERIFY_CODE_SUCCESS';
export const FVERIFY_CODE_ERROR = 'FVERIFY_CODE_ERROR';

export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const GET_VERIFY_PROFILE_REQUEST = 'GET_VERIFY_PROFILE_REQUEST';
export const GET_VERIFY_PROFILE_SUCCESS = 'GET_VERIFY_PROFILE_SUCCESS';
export const GET_VERIFY_PROFILE_ERROR = 'GET_VERIFY_PROFILE_ERROR';

export const GET_PLAYER_PROFILE_REQUEST = 'GET_PLAYER_PROFILE_REQUEST';
export const GET_PLAYER_PROFILE_SUCCESS = 'GET_PLAYER_PROFILE_SUCCESS';
export const GET_PLAYER_PROFILE_ERROR = 'GET_PLAYER_PROFILE_ERROR';

export const GET_FAN_PROFILE_REQUEST = 'GET_FAN_PROFILE_REQUEST';
export const GET_FAN_PROFILE_SUCCESS = 'GET_FAN_PROFILE_SUCCESS';
export const GET_FAN_PROFILE_ERROR = 'GET_FAN_PROFILE_ERROR';

export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';

export const RESET_MOBILE_REQUEST = 'RESET_MOBILE_REQUEST';
export const RESET_MOBILE_SUCCESS = 'RESET_MOBILE_SUCCESS';
export const RESET_MOBILE_ERROR = 'RESET_MOBILE_ERROR';


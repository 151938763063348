import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { Link } from 'react-router-dom';
import AddTeam from '../../../../views/popup/addTeam';
import SuccessMessage from '../../../../views/popup/successMessage';
import ProCollage from '../../../../views/popup/proCollage';
import { addTeam, teamList, saveClaim, getClaim, uploadTeamImage, saveTeam, getTeamFlow } from '../../../../saga/actions/authantication/signup/coach/team.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import SimpleReactValidator from 'simple-react-validator';
import Preloader from '../../../../components/preloader';
import ImageCrop from '../../../../views/popup/imageCrop';
import AskHeadCoach from '../../../../views/popup/askHeadCoachForProgram';
import NoHeadCoach from '../../../../views/popup/noHeadCoach';

let tempArr = [];

class team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openTeam: false,
            openClaim: false,
            teamLogo: null,
            createTeam: {
                name: "",
                teamLogo: "",
                description: "",
                yourscore: "",
                opponentscore: "",
                teamRecord: "",
                "social-link": {
                    twt: "",
                    insta: ""
                },
                shoplink: "",
                isMarchandise: false
            },
            shopFlg: "no",
            filter: {
                page: 1,
                limit: 10,
                search: ""
            },
            teamArr: [],
            teamClaim: [],
            coachFullName: this.props.profileData.fname + " " + this.props.profileData.lname,
            coachEmail: this.props.profileData.email,
            selectedId: "",
            claimPopup: false,
            loader: false,
            parentTeamId: "",
            cropPopup: false,
            renderFile: "",
            imgObj: "",
            teamType: "youth",
            openCollage: false,
            schoolUser: [],
            tName: "",
            headCoachPopUp: false,
            noHeadCoachPopUp: false,
            skipTeam: false,
            skipRoster: false
        }
        this.addTeamPopup = null;
        this.commonCall = new commonCalls();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.validClaim = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.onRef(this);
        if (this.state.filter.search >=2) {
            this.setLoader(true);
            this.props.teamList(this.state.filter);
        }
        this.props.getTeamFlow();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.teamDetails &&
            nextProps.teamDetails.teamImageRes &&
            nextProps.teamDetails.teamImageData
        ) {
            nextProps.teamDetails.teamImageRes = false;
            this.setState((prevState) => ({
                ...prevState,
                createTeam: {
                    ...prevState.createTeam,
                    teamLogo: nextProps.teamDetails.teamImageData._id
                },
                teamLogo: nextProps.teamDetails.teamImageData.filePath,
                imgObj: "",
                cropPopup: false,
                renderFile: ""
            }), () => {
                document.body.classList.remove('modal-open');
                this.setLoader(false);
                nextProps.teamDetails.teamImageData = null
            });
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.saveTeamRes &&
            nextProps.teamDetails.saveTeamData
        ) {
            nextProps.teamDetails.saveTeamRes = false;
            if (nextProps.teamDetails.saveTeamData.flag) {
                this.commonCall.successMessage(nextProps.teamDetails.saveTeamData.message);
                this.setState({
                    selectedId: nextProps.teamDetails.saveTeamData.result._id,
                    parentTeamId: nextProps.teamDetails.saveTeamData.result._id,
                    skipRoster: true
                }, () => {
                    // document.body.classList.remove('modal-open');
                    nextProps.teamDetails.saveTeamData = null;
                    this.setLoader(true);
                    this.props.saveTeam({ parentTeam: this.state.parentTeamId, teamType: 1 });
                    // this.props.teamList(this.state.filter);
                    // this.setLoader(false);
                    // this.onHandleClose();
                })
            } else {
                this.commonCall.errorMessage(nextProps.teamDetails.saveTeamData.message);
                nextProps.teamDetails.saveTeamData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.searchTeamRes &&
            nextProps.teamDetails.searchTeamData
        ) {
            nextProps.teamDetails.searchTeamRes = false;

            if (this.state.filter.search) {
                return this.setState({
                    teamArr: nextProps.teamDetails.searchTeamData.result.parentTeamList,
                }, () => {
                    nextProps.teamDetails.searchTeamData = null;
                    this.setLoader(false);
                })
            }

            if (nextProps.teamDetails.searchTeamData.result.notification.length !== 0) {
                this.setState((prevState) => ({
                    ...prevState,
                    teamArr: [nextProps.teamDetails.searchTeamData.result.notification.parentTeam],
                    selectedId: nextProps.teamDetails.searchTeamData.result.notification.parentTeam._id,
                    parentTeamId: nextProps.teamDetails.searchTeamData.result.notification.parentTeam._id,
                }), () => {
                    nextProps.teamDetails.searchTeamData = null;
                    this.setLoader(false);
                })
            } else {
                if (nextProps.teamDetails.searchTeamData.result.myParentTeam.length !== 0) {
                    if (this.state.filter.page === 1) {
                        for (let i = 0; i < nextProps.teamDetails.searchTeamData.result.myParentTeam.length; i++) {
                            nextProps.teamDetails.searchTeamData.result.parentTeamList.unshift(nextProps.teamDetails.searchTeamData.result.myParentTeam[i])
                        }
                        this.setState((prevState) => ({
                            ...prevState,
                            selectedId: this.state.selectedId.length === 0 ? nextProps.teamDetails.searchTeamData.result.myParentTeam[0]._id : this.state.selectedId,
                            parentTeamId: this.state.parentTeamId.length === 0 ? nextProps.teamDetails.searchTeamData.result.myParentTeam[0]._id : this.state.parentTeamId
                        }))
                    }

                    let val = nextProps.teamDetails.searchTeamData.result.parentTeamList;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.teamArr.concat(temp);

                    this.setState((prevState) => ({
                        ...prevState,
                        teamArr: joined,
                        filter: {
                            ...prevState.filter,
                            page: this.state.filter.page + 1
                        }
                    }), () => {
                        nextProps.teamDetails.searchTeamData = null;
                        this.setLoader(false);
                    })

                } else {
                    let val = nextProps.teamDetails.searchTeamData.result.parentTeamList;

                    let temp = []
                    val.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.teamArr.concat(temp);

                    this.setState((prevState) => ({
                        teamArr: joined,
                        filter: {
                            ...prevState.filter,
                            page: this.state.filter.page + 1
                        }
                    }), () => {
                        this.setLoader(false);
                        nextProps.teamDetails.searchTeamData = null
                    });
                }
            }
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.saveClaimRes &&
            nextProps.teamDetails.saveClaimData
        ) {
            nextProps.teamDetails.saveClaimRes = false;
            if (nextProps.teamDetails.saveClaimData.flag) {
                nextProps.teamDetails.saveClaimData = null;
                this.setState({
                    claimPopup: !this.state.claimPopup,
                }, () => {
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.teamDetails.saveClaimData.message);
                nextProps.teamDetails.saveClaimData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.getClaimRes &&
            nextProps.teamDetails.getClaimData
        ) {
            nextProps.teamDetails.getClaimRes = false;
            if (nextProps.teamDetails.getClaimData.flag) {
                tempArr = [];
                if (nextProps.teamDetails.getClaimData.result.coachArr.length !== 0) {
                    nextProps.teamDetails.getClaimData.result.coachArr.map((item, i) => {
                        tempArr.push({
                            fname: item.fname,
                            lname: item.lname,
                            email: item.email
                        })
                    });
                    this.setState((prevState) => ({
                        ...prevState,
                        teamClaim: {
                            ...prevState.teamClaim,
                            teamName: nextProps.teamDetails.getClaimData.result.teamName,
                            coachArr: tempArr
                        },
                        openClaim: !this.state.openClaim
                    }), () => {
                        nextProps.teamDetails.getClaimData = null;
                    });
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        teamClaim: {
                            ...prevState.teamClaim,
                            teamName: "",
                            coachArr: [
                                {
                                    fname: "",
                                    lname: "",
                                    email: ""
                                }
                            ]
                        },
                        openClaim: !this.state.openClaim
                    }), () => {
                        nextProps.teamDetails.getClaimData = null;
                    });
                }
            }
        }

        if (this.props.teamDetails.getTeamFlowData) {
            this.props.teamDetails.getTeamFlowData = null;
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.getTeamFlowRes &&
            nextProps.teamDetails.getTeamFlowData
        ) {
            nextProps.teamDetails.getTeamFlowRes = false;
            if (nextProps.teamDetails.getTeamFlowData.result.fieldArr.hasOwnProperty('teamType') &&
                nextProps.teamDetails.getTeamFlowData.result.fieldArr.teamType === 2
            ) {
                this.setState({
                    teamType: "collage",
                    schoolUser: nextProps.teamDetails.getTeamFlowData.result.fieldArr.user,
                    tName: nextProps.teamDetails.getTeamFlowData.result.fieldArr.teamName
                }, () => {
                    nextProps.teamDetails.getTeamFlowData = null;
                })
            } else if (nextProps.teamDetails.getTeamFlowData.result.fieldArr.hasOwnProperty('teamType') &&
                nextProps.teamDetails.getTeamFlowData.result.fieldArr.teamType === 3
            ) {
                this.setState({
                    teamType: "pro",
                    schoolUser: nextProps.teamDetails.getTeamFlowData.result.fieldArr.user,
                    tName: nextProps.teamDetails.getTeamFlowData.result.fieldArr.teamName
                }, () => {
                    nextProps.teamDetails.getTeamFlowData = null;
                })
            } else if (nextProps.teamDetails.getTeamFlowData.result.fieldArr.hasOwnProperty('teamType') &&
                nextProps.teamDetails.getTeamFlowData.result.fieldArr.teamType === 1
            ) {
                this.setState({
                    teamType: "school",
                    schoolUser: nextProps.teamDetails.getTeamFlowData.result.fieldArr.user,
                    tName: ""
                }, () => {
                    nextProps.teamDetails.getTeamFlowData = null;
                })
            } else {
                this.setState({
                    teamType: "youth",
                    schoolUser: nextProps.teamDetails.getTeamFlowData.result.fieldArr.user,
                    tName: ""
                }, () => {
                    nextProps.teamDetails.getTeamFlowData = null;
                })
            }
        }
    }

    onHandleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            openTeam: !this.state.openTeam,
            createTeam: {
                name: "",
                teamLogo: "",
                description: "",
                yourscore: "",
                opponentscore: "",
                teamRecord: "",
                "social-link": {
                    twt: "",
                    insta: ""
                },
                shoplink: "",
                isMarchandise: false
            },
            teamLogo: null
        })
    }

    onHandleChange = (name, value, index,checked) => {
        if (
            name === "name" ||
            name === "description" ||
            name === "shoplink" ||
            name === "isMarchandise"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                createTeam: {
                    ...prevState.createTeam,
                    [name]: value
                },
            }));
        }

        if (name === "shopFlg") {
            this.setState({
                [name]: value
            })
        }

        if (name === "yourscore" ||
            name === "opponentscore"
        ) {
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                this.setState((prevState) => ({
                    ...prevState,
                    createTeam: {
                        ...prevState.createTeam,
                        [name]: value
                    },
                }));
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    createTeam: {
                        ...prevState.createTeam,
                        [name]: ""
                    },
                }));
            }
        }

        if (name === "twt" ||
            name === "insta"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                createTeam: {
                    ...prevState.createTeam,
                    "social-link": {
                        ...prevState.createTeam['social-link'],
                        [name]: value
                    }
                },
            }));
        }

        if (name === "search") {
            this.setState((prevState) => ({
                ...prevState,
                filter: {
                    page: 1,
                    limit: 10,
                    search: value
                },
            }), () => {
                if (value.length !== 0) {
                    if (value.length >= 2) {
                        this.setLoader(true);
                        this.props.teamList(this.state.filter);
                    }
                } else {
                    // this.state.teamArr = [];
                    this.setState((prevState) => ({
                        ...prevState,
                        teamArr: []
                    }))
                    this.props.teamDetails.searchTeamData = null;
                    // this.setLoader(true);
                    // this.props.teamList(this.state.filter);
                }
            });
        }

        if (name === "teamName") {
            this.setState((prevState) => ({
                ...prevState,
                teamClaim: {
                    ...prevState.teamClaim,
                    [name]: value
                },
            }));
        }

        if (name === "parentTeamId") {
            this.setState({
                selectedId: checked ? value : "",
                parentTeamId:checked ? value :""
            })
        }

        if (name === "fname" ||
            name === "lname" ||
            name === "email"
        ) {

            const arr = [...this.state.teamClaim.coachArr];
            arr[index][name] = value;
            this.setState((prevState) => ({
                ...prevState,
                teamClaim: {
                    ...prevState.teamClaim,
                    coachArr: arr
                },
            }));
        }

        if (name === "teamType") {
            this.setState((prevState) => ({
                ...prevState,
                [name]: value,
                openCollage: value === "collage" || value === "pro" ? true : false
            }));
        }

    }

    saveTeam = () => {
        this.addTeamPopup.addTeamValidation(flag => {
            if (flag) {
                document.body.classList.remove('modal-open');
                this.setState({
                    openTeam: false
                }, () => {
                    this.setLoader(true);
                    this.props.addTeam(this.state.createTeam);
                })
            }
        });
    }

    imageRemove = () => {
        this.setState({
            teamLogo: null
        })
    }

    getTeam = () => {
        this.setLoader(true);
        this.props.teamList(this.state.filter);
    }

    renderTeamList = () => {
        return (
            this.state.teamArr.length !== 0 ?
                this.state.teamArr.map((list, i) => {
                    return (
                        <div className="team-flex" key={i}>
                            <div className="team-data">
                                <div className="team-img"><img src={list.teamLogo ? list.teamLogo.filePath : "https://artoon-taxuz.s3.amazonaws.com/16/5274950d56e211394f02159e7ee45d5a.png"} alt="team1" /></div>
                                <h4>{list.name}</h4>
                            </div>
                            <div className="radiobox">
                                <input
                                    type="checkbox"
                                    id="Baseball"
                                    value={list._id}
                                    name="parentTeamId"
                                    onChange={(e) => this.onHandleChange('parentTeamId', e.target.value, null, e.target.checked)}
                                    checked={list._id === this.state.selectedId ? true : false}
                                />
                                <span for="Baseball"></span>
                            </div>
                        </div>
                    );
                })
                :
                <h4 style={{ textAlign: 'center' }}>No Data Found</h4>
        );
    }

    renderTeamClaim = () => {
        return (
            this.state.teamClaim &&
            this.state.teamClaim.coachArr.map((list, i) => {
                return (
                    <div className="gray-bg add_claim_inner" key={i}>
                        {
                            i !== 0 &&
                            <div className="removle_claim">
                                <Link className="remove-claim" onClick={() => this.deleteClaim(i)}>
                                    <img src={imagesArr.deletes} alt="search" />
                                </Link>
                            </div>
                        }
                        <div className="check_input row">
                            <div className="check_inputbox">
                                <label className="common-lable">First name</label>
                                <div className="form-group mb-0">
                                    <input type="text" name="fname" value={list.fname} onChange={(e) => this.onHandleChange('fname', e.target.value, i)} className="form-control" id="usr" placeholder="Enter First name" />
                                    <span className="validMsg">{this.validClaim.message('First Name', list.fname, 'required')}</span>
                                </div>
                            </div>
                            <div className="check_inputbox">
                                <label className="common-lable">Last name</label>
                                <div className="form-group mb-0">
                                    <input type="text" name="lname" value={list.lname} onChange={(e) => this.onHandleChange('lname', e.target.value, i)} className="form-control" id="usr" placeholder="Enter Last name" />
                                    <span className="validMsg">{this.validClaim.message('Last Name', list.lname, 'required')}</span>
                                </div>
                            </div>
                        </div>
                        <label className="common-lable">Email addres</label>
                        <div className="form-group mb-0">
                            <input type="text" name="email" value={list.email} onChange={(e) => this.onHandleChange('email', e.target.value, i)} className="form-control" id="usr" placeholder="Enter Email address" />
                            <span className="validMsg">{this.validClaim.message('Email', list.email, 'required|email')}</span>
                        </div>
                    </div >
                );
            })
        );
    }

    addClaimCoach = () => {
        const addArr = [...this.state.teamClaim.coachArr, {
            fname: "",
            lname: "",
            email: ""
        }];
        this.setState((prevState) => ({
            ...prevState,
            teamClaim: {
                ...prevState.teamClaim,
                coachArr: addArr
            },
        }));
    }

    deleteClaim = (index) => {
        const delArr = [...this.state.teamClaim.coachArr];
        delArr.splice(index, 1);
        this.setState((prevState) => ({
            ...prevState,
            teamClaim: {
                ...prevState.teamClaim,
                coachArr: delArr
            },
        }));
    }

    saveClaimTeam = () => {
        if (this.validClaim.allValid()) {
            this.setLoader(true);
            this.props.saveClaim(this.state.teamClaim);
        } else {
            this.validClaim.showMessages();
            this.forceUpdate();
        }
    }

    handleCloseClaim = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            claimPopup: !this.state.claimPopup
        })
    }

    cancelClaimTeam = () => {
        this.setState({
            openClaim: !this.state.openClaim,
            teamClaim: [{
                teamName: "",
                coachArr: [
                    {
                        fname: "",
                        lname: "",
                        email: ""
                    }
                ]
            }]
        })
    }

    validationUpdate = (cb) => {
        let data;
        if (this.state.teamType === "school" || this.state.teamType === "youth") {
            if (this.state.parentTeamId) {
                data = {
                    flag: true,
                    parentTeam: this.state.parentTeamId,
                    type: this.state.teamType === "school" ? "School" : "Youth",
                    skipTeam: this.state.skipTeam
                }
                cb(data);
            } else {
                data = {
                    flag: false,
                    type: this.state.teamType === "school" ? "School" : "Youth",
                    skipTeam: this.state.skipTeam
                }
                cb(data);
            }
        } else {
            if (this.state.tName !== "") {
                data = {
                    flag: true,
                    teamName: this.state.tName,
                    type: this.state.teamType === "collage" ? "collage" : "pro",
                    skipTeam: this.state.skipTeam
                }
                cb(data);
            } else {
                data = {
                    flag: false,
                    type: this.state.teamType === "collage" ? "collage" : "pro",
                    skipTeam: this.state.skipTeam
                }
                cb(data);
            }
        }
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    openClaimCart = () => {
        this.props.getClaim();
    }

    handleChange = (e) => {
        let file = e.target.files[0];
        if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png" && file.name.match(new RegExp('[^.]+$'))[0] !== "heic") {
            return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
        }
        this.setState({
            imgObj: file,
            cropPopup: true,
            renderFile: URL.createObjectURL(file)
        });
    }

    handleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            cropPopup: false
        });
    }

    onHadleObject = (obj) => {
        let data = {
            documentType: 10,
            imgFile: obj.file
        }
        this.setLoader(true);
        this.props.uploadTeamImage(data)
    }

    getScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom) {
            this.setLoader(true);
            this.props.teamList(this.state.filter);
        }
    }

    setProCollege = (name) => {
        document.body.classList.remove('modal-open');
        this.setState({
            tName: name,
            openCollage: false,
        }, () => {
            this.props.saveTeam({ teamName: this.state.tName, teamType: this.state.teamType === "collage" ? 2 : 3 });
            // this.handleCloseCollege()
        })
    }

    handleCloseCollege = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            openCollage: false,
        });
    }

    handleOpenHeadCoach = () =>{
        this.setState({ headCoachPopUp: !this.state.headCoachPopUp })
    }

    addNewProgram = ()=>{
        this.setState({ 
            openTeam: !this.state.openTeam,
            headCoachPopUp: false,
         })
    }

    closeHeadCoachPopup = () =>{
        document.body.classList.remove('modal-open');
        this.setState({
            headCoachPopUp: false,
        })
    }

    noHead = () =>{
        this.setState({
            headCoachPopUp: false,
            noHeadCoachPopUp: !this.state.noHeadCoachPopUp
         })
    }

    nextStep = () => {
        document.body.classList.remove('modal-open');

        this.setState({
            skipTeam: true,
            noHeadCoachPopUp: false
        },()=>{
            this.props.nextStep();
        })
    }

    render() {
        const { chkTeamType } = this.props;
        const { coachFullName, coachEmail, teamClaim, loader, imgObj, cropPopup, renderFile, teamType, openCollage, schoolUser, tName } = this.state;
        return (
            <>
                {
                    this.state.openClaim ?
                        <>
                            <section className="before-login">
                                <div className="white-bg">
                                    <div className="title-name mb-0">
                                        <h2>
                                            Claim your team
                                        </h2>
                                        <p>
                                            Coach {coachFullName}
                                        </p>
                                    </div>
                                    <div className="check_input row">
                                        <div className="check_inputbox">
                                            <label className="common-lable">Team name</label>
                                            <div className="form-group mb-0">
                                                <input type="text" name="teamName" value={teamClaim.teamName} onChange={(e) => this.onHandleChange('teamName', e.target.value)} className="form-control" id="usr" placeholder="Enter team name" />
                                                <span className="validMsg">{this.validClaim.message('Team name', teamClaim.teamName, 'required')}</span>
                                            </div>
                                        </div>
                                        <div className="check_inputbox">
                                            <label className="common-lable">Email</label>
                                            <div className="form-group mb-0">
                                                <input type="text" className="form-control" id="usr" value={coachEmail} placeholder="Enter email" disabled="disabled" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add_claim">
                                        <div className="title_claim">
                                            <h4>Add Assistant Coach(s)</h4>
                                            <Link className="add-claim" onClick={() => this.addClaimCoach()}>
                                                <img src={imagesArr.claim_plus} alt="search" />
                                            </Link>
                                        </div>
                                        {this.renderTeamClaim()}
                                    </div>
                                    <div className="flex-align">
                                        <Link className="btn gray" onClick={() => this.cancelClaimTeam()}>Cancel</Link>
                                        <Link className="btn" onClick={() => this.saveClaimTeam()}>Submit</Link>
                                    </div>
                                </div>
                            </section>
                            {
                                this.state.claimPopup &&
                                <SuccessMessage
                                    profileInfo={this.props.profileData}
                                    close={this.handleCloseClaim}
                                    flag={this.state.claimPopup}
                                />
                            }
                        </>
                        :
                        <section className="before-login">
                            <div className="white-bg">
                                <div className="title-name mb-0">
                                    <h2>
                                        What level do you coach?
                                    </h2>
                                    <div className="checkbox-tab">
                                        <div className="checkbox">
                                            <input type="radio" id="teamType" value={teamType} name="teamType" onClick={(e) => !chkTeamType ? this.onHandleChange('teamType', 'youth') : ""} checked={teamType === "youth" ? true : false} />
                                            <label htmlFor="Softball">Youth</label>
                                        </div>
                                        <div className="checkbox">
                                            <input type="radio" id="teamType" value={teamType} name="teamType" onClick={(e) => !chkTeamType ? this.onHandleChange('teamType', 'school') : ""} checked={teamType === "school" ? true : false} />
                                            <label htmlFor="Softball">High School</label>
                                        </div>
                                        <div className="checkbox" >
                                            <input type="radio" id="teamType" value={teamType} name="teamType" onClick={(e) => this.onHandleChange('teamType', 'collage')} checked={teamType === "collage" ? true : false} />
                                            <label htmlFor="Baseball">College</label>
                                        </div>
                                        <div className="checkbox" >
                                            <input type="radio" id="teamType" value={teamType} name="teamType" onClick={(e) => this.onHandleChange('teamType', 'pro')} checked={teamType === "pro" ? true : false} />
                                            <label htmlFor="Baseball">Professional</label>
                                        </div>
                                    </div>
                                    {(teamType === "school" || teamType === "youth") &&
                                        <h2 className="mb-0">
                                            Select your program or create your new program
                                        </h2>
                                    }
                                </div>
                                {(teamType === "school" || teamType === "youth") &&
                                    <>
                                        <div className="add_team check_input">
                                            <Link onClick={this.handleOpenHeadCoach} className="svg-btn trans"><img src={imagesArr.addteam} alt="Add New Team" />Add New Program</Link>
                                            <div className="form-group mb-0">
                                                <div className="serch-box">
                                                    <input type="text" name="search" className="form-control" onChange={(e) => this.onHandleChange('search', e.target.value)} id="usr" placeholder="Search for your program" />
                                                    {/* <Link className="serch-btn" onClick={() => this.getTeam()}>
                                                <img src={imagesArr.search} alt="search" />
                                            </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scrollbar_team" onScroll={this.getScroll}>
                                            <div className="select_team">
                                                {this.renderTeamList()}
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* <div className="claim-button">
                                    <Link onClick={() => this.openClaimCart()} className="svg-btn"><img src={imagesArr.claim} alt="Add New Team" />Claim your team</Link>
                                    <p>
                                        College coach or Pro Team. Claim your team
                                    </p>
                                </div> */}
                            </div>
                        </section>
                }

                {
                    this.state.openTeam &&
                    <AddTeam
                        onRef={e => this.addTeamPopup = e}
                        flag={this.state.openTeam}
                        close={this.onHandleClose}
                        teamInput={this.state.createTeam}
                        shopFlg={this.state.shopFlg}
                        onChange={this.onHandleChange}
                        onAddTeam={this.saveTeam}
                        imageUrl={this.state.teamLogo}
                        removeImg={this.imageRemove}
                        load={this.setLoader}
                        handleChange={this.handleChange}
                    />
                }

                {
                    this.state.headCoachPopUp &&
                    <AskHeadCoach
                        flag={this.state.headCoachPopUp}
                        close={this.closeHeadCoachPopup}
                        addNewProgram={this.addNewProgram}
                        noHead={this.noHead}
                    />
                }

                {
                    this.state.noHeadCoachPopUp &&
                    <NoHeadCoach
                        flag={this.state.noHeadCoachPopUp}
                        nextStep={this.nextStep}
                    />
                }
                <Preloader flag={loader} />
                <ImageCrop
                    imgObj={imgObj}
                    flag={cropPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />

                <ProCollage
                    flag={openCollage}
                    userData={schoolUser}
                    saveData={this.setProCollege}
                    close={this.handleCloseCollege}
                    tName={tName}
                    type={teamType}
                />
            </>
        )
    }
}

team.propTypes = {
    addTeam: PropTypes.func.isRequired,
    teamList: PropTypes.func.isRequired,
    saveClaim: PropTypes.func.isRequired,
    getClaim: PropTypes.func.isRequired,
    uploadTeamImage: PropTypes.func.isRequired,
    saveTeam: PropTypes.func.isRequired,
    getTeamFlow: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    teamDetails: state.teamDetails,
    rosterDetails: state.rosterDetails
});

export default connect(mapStateToProps, { addTeam, teamList, saveClaim, getClaim, uploadTeamImage, saveTeam, getTeamFlow })(team);
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import { imagesArr } from "../../../../assets/images";
import EventRow from '../events/details/eventRow';
import { getEventDetails } from '../../../../saga/actions/common/eventDetails.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import Preloader from "../../../../components/preloader";
class thankYou extends Component {

    constructor(props) {
        super(props)
        this.state = {
            eventId: "",
            loader: false,
            eventDetailData: [],
            transactionId: "2432655675e464324",
            amount: localStorage.getItem("finalAmount") ? JSON.parse(localStorage.getItem("finalAmount")) : 0,
            paymentType: localStorage.getItem("paymentType") ? JSON.parse(localStorage.getItem("paymentType")) : 1,
            card: "**** **** **** 4242",
            paymentMath: ""
        }
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        localStorage.removeItem("eventDirectPayment")
        localStorage.removeItem("nid")
        this.setState({
            eventId: eventId,
            amount: localStorage.getItem("finalAmount") ? JSON.parse(localStorage.getItem("finalAmount")) : 0,
            paymentType: localStorage.getItem("paymentType") ? JSON.parse(localStorage.getItem("paymentType")) : 1,
        }, () => {
            var payMath = "Full Payment $" + this.state.amount;
            if (this.state.paymentType === 1) {
                payMath = "Full Payment $" + this.state.amount;
            } else if (this.state.paymentType === 2 || this.state.paymentType === 0) {
                payMath = "Day Of Event ";
            } else if (this.state.paymentType === 3) {
                payMath = "Pay Installment $" + this.state.amount;
            } else if (this.state.paymentType === 4) {
                payMath = "Pay Later $" + this.state.amount;
            }
            this.setState({
                paymentMath: payMath
            }, () => {
                this.getDetails();
            })
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            console.log("nextProps.eventDetails:--", nextProps.eventDetails.getDetailsData);
            if (nextProps.eventDetails.getDetailsData.flag) {
                let fdata = nextProps.eventDetails.getDetailsData.result.data[0];
                this.setState({
                    eventDetailData: fdata,
                }, () => {
                    nextProps.eventDetails.getDetailsData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDetails &&
            nextProps.eventDetails.eventPaymentCompleteReq &&
            nextProps.eventDetails.eventPaymentCompleteData
        ) {
            nextProps.eventDetails.eventPaymentCompleteReq = false;
            console.log("eventPaymentCompleteData:--", nextProps.eventDetails.eventPaymentCompleteData);
            if (nextProps.eventDetails.eventPaymentCompleteData.flag) {
                let res = nextProps.eventDetails.eventPaymentCompleteData.result;
                localStorage.setItem("finalAmount", res.amount);
                localStorage.setItem("paymentType", res.paymentType);
                var payMath;
                if (res.paymentType === 1) {
                    payMath = "Full Payment $" + res.amount;
                } else if (res.paymentType === 2 || res.paymentType === 0) {
                    payMath = "Day Of Event ";
                } else if (res.paymentType === 3) {
                    payMath = !localStorage.getItem("installment") ? "Pay Installment $" + res.amount : Array.isArray(localStorage.getItem("installment")) ? "Pay Installment $" + res.amount : "Pay Installment $" + res.installments.find(_ => { if (_._id === localStorage.getItem("installment")) { return _ }; }).price;
                } else if (res.paymentType === 4) {
                    payMath = "Pay Later $" + res.amount;
                }
                if (res.paymentType === 2) {
                    this.setState({
                        transactionId: res._id,
                        amount: res.amount,
                        paymentType: res.paymentType,
                        paymentMath: payMath
                    }, () => {
                        nextProps.eventDetails.eventPaymentCompleteData = null;
                        this.setLoader(false);
                    })
                } else {
                    this.setState({
                        transactionId: res._id,
                        amount: res.amount,
                        paymentType: res.paymentType,
                        card: res.metaPayment.hasOwnProperty("payment_method_details") ? "**** **** **** " + res.metaPayment.payment_method_details.card.last4 : "**** **** **** 4242",
                        paymentMath: payMath
                    }, () => {
                        nextProps.eventDetails.eventPaymentCompleteData = null;
                        this.setLoader(false);
                    });
                }
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.eventPaymentCompleteData.message);
                nextProps.eventDetails.eventPaymentCompleteData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.eventDashboard &&
            nextProps.eventDashboard.pendingPaymentDashReq &&
            nextProps.eventDashboard.pendingPaymentDashData
        ) {

            nextProps.eventDashboard.pendingPaymentDashReq = false;
            console.log("pendingPaymentDashData:--", nextProps.eventDashboard.pendingPaymentDashData);
            if (nextProps.eventDashboard.pendingPaymentDashData.flag) {
                let res = nextProps.eventDashboard.pendingPaymentDashData.result;
                localStorage.setItem("finalAmount", res.amount);
                localStorage.setItem("paymentType", res.paymentType);
                var payMath;
                payMath = "Full Payment $" + res.amount;
                this.setState({
                    transactionId: res._id,
                    amount: res.amount,
                    paymentType: res.paymentType,
                    card: res.metaPayment.hasOwnProperty("payment_method_details") ? "**** **** **** " + res.metaPayment.payment_method_details.card.last4 : "**** **** **** 4242",
                    paymentMath: payMath
                }, () => {
                    nextProps.eventDashboard.pendingPaymentDashData = null;
                    this.setLoader(false);
                });

            } else {
                this.commonCall.errorMessage(nextProps.eventDashboard.pendingPaymentDashData.message);
                nextProps.eventDashboard.pendingPaymentDashData = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        this.props.getEventDetails(this.state.eventId, true);
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    render() {
        const { loader, eventDetailData, eventId, paymentType, amount, transactionId, card, paymentMath } = this.state;
        return (
            <div className="event_details">
                <Header />
                <CommonHeroBanner dataList={eventDetailData} />
                <div className="container">
                    <EventRow dataList={eventDetailData} isHideJoinButton={true} eventId={eventId} complete={true} directPay={false} showAmount={true} />
                    <div className="event-container">
                        <div className="event-thanq">
                            <div className="thanq-title">
                                <h4>Thank You!</h4>
                                <p>
                                    {
                                        eventDetailData.eventTeamType === 1 ?
                                            "Your payment was successful and your team has joined the event"
                                            :
                                            "Your payment was successful and you have joined the event"
                                    }
                                    {/* {paymentType === 2 ? "Thanks for registering. Your payment is due the day of the event." : "Thanks for registering."} */}
                                </p>
                            </div>
                            {
                                eventDetailData.length !== 0 &&
                                <div className="table-responsive-xl">
                                    <table>
                                        <tr>
                                            <th>Transaction ID:</th>
                                            <td>{transactionId}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Method:</th>
                                            <td>{paymentMath}</td>
                                        </tr>
                                        {
                                            !localStorage.getItem("zeroAmount") &&
                                                (paymentType === 1 || paymentType === 3) &&
                                            <>
                                                <tr>
                                                    <th>Payment Type:</th>
                                                    <td>Credit card</td>
                                                </tr>
                                                <tr>
                                                    <th>Card Number:</th>
                                                    <td>{card}</td>
                                                </tr>
                                            </>
                                        }
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </div>
        )
    }
}

thankYou.propTypes = {
    getEventDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
    eventDashboard: state.api
});
var thankYouComponent = connect(mapStateToProps, { getEventDetails })(thankYou);
export default withRouter(thankYouComponent);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { imagesArr } from "../../../../assets/images";
import ProductList from "../../../company/components/common/productList";
import PwLock from "../../../company/components/common/pwLock";
import ReCAPTCHA from "react-google-recaptcha";
import CoachDetail from "../../../popup/CoachDetail";
import Confirmation from "../../../popup/confirmation";
import { saveSocialMediaLink, getLoginResponse, deleteCoachDetails } from '../../../../saga/actions/views/company/pwRanking/profileSummary.action';
import moment from 'moment';
import InputMask from 'react-input-mask';

const TEST_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY;
class infoContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reCaptchaRes: "",
            coachdetail: false,
            sendReq: {
                "social-link": {
                    fid: "",
                    tid: "",
                    sSid: "",
                    insta: ""
                }
            },
            socialLink: [],
            showTxt: "",
            uid: "",
            loginUid: "",
            popupStat: "add",
            editDetail: [],
            pid: "",
            delConfirm: false
        };
    }


    componentDidMount = () => {
        this.props.onRef(this);
        let url = new URL(document.location);
        let getId = url.pathname.split("/")[2];
        this.setState({
            uid: getId,
        }, () => {
            if (localStorage.getItem("webglToken")) {
                this.props.increment();
                this.props.getLoginResponse();
            }
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileInfo &&
            nextProps.profileInfo.saveSocialLinkReq &&
            nextProps.profileInfo.saveSocialLinkData
        ) {
            console.log("nextProps.profileInfo.saveSocialLinkData:---", nextProps.profileInfo.saveSocialLinkData);
            nextProps.profileInfo.saveSocialLinkReq = false;
            if (nextProps.profileInfo.saveSocialLinkData.flag) {
                this.setState({
                    showTxt: ""
                }, () => {
                    nextProps.profileInfo.saveSocialLinkData = null;
                    this.props.decrement();
                    this.props.getSocialList();
                })
            } else {
                nextProps.profileInfo.saveSocialLinkData = null;
                this.props.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.getLoginResReq &&
            nextProps.profileInfo.getLoginResData
        ) {
            nextProps.profileInfo.getLoginResReq = false;
            if (nextProps.profileInfo.getLoginResData.flag) {
                this.setState({
                    loginUid: nextProps.profileInfo.getLoginResData.result._id
                }, () => {
                    nextProps.profileInfo.getLoginResData = null;
                    this.props.decrement();
                })
            } else {
                nextProps.profileInfo.getLoginResData = null;
                this.props.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.delCoachDetailReq &&
            nextProps.profileInfo.delCoachDetailData
        ) {
            nextProps.profileInfo.delCoachDetailReq = false;
            if (nextProps.profileInfo.delCoachDetailData.flag) {
                nextProps.profileInfo.delCoachDetailData = null;
                this.props.decrement();
                this.onHandleClose();
            } else {
                nextProps.profileInfo.delCoachDetailData = null;
                this.props.decrement();
            }
        }
    }

    getContact = (data) => {
        if (data.length !== 0) {
            this.setState((prevState) => ({
                ...prevState,
                sendReq: {
                    ...prevState.sendReq,
                    "social-link": {
                        ...prevState.sendReq["social-link"],
                        fid: data.socialLink.fid,
                        tid: data.socialLink.tid,
                        sSid: data.socialLink.sSid,
                        insta: data.socialLink.insta
                    }
                },
                socialLink: data.socialLink
            }))
        }
    }

    renderPersonal = () => {
        return (
            this.props.summaryData &&
                this.props.summaryData.personalDetail &&
                this.props.summaryData.personalDetail.length !== 0 ?
                <>
                    <div className="personal-details-list">
                        <div className="personal-item">
                            <img src={imagesArr.location_bg_icon} alt="location" />
                            {this.props.summaryData?.personalDetail?.profile?.address?.city + ", " + this.props.summaryData?.personalDetail?.profile?.address?.state}
                        </div>
                    </div>
                    <div className="personal-details-list">
                        <div className="personal-item">
                            <img src={imagesArr.mail_bg_icon} alt="mail" />{this.props.summaryData?.personalDetail?.email}
                        </div>
                    </div>
                    <div className="personal-details-list">
                        <div className="personal-item">
                            <img src={imagesArr.call_icon} alt="contact" />{this.props.summaryData?.personalDetail?.phone ? this.props.summaryData?.personalDetail?.phone : "-"}
                        </div>
                    </div>
                </>
                :
                <p>No data found</p>
        )
    }

    renderCoachName = () => {
        return (
            this.props.summaryData &&
            this.props.summaryData.coachDetail &&
            this.props.summaryData.coachDetail.map((item, i) => {
                return (
                    <div className="personal-item mb-10">
                        <img src={imagesArr.user_icon} alt="user" />{item.coach.fname + " " + item.coach.lname}
                    </div>
                )
            })
        )
    }

    renderCoachEmail = () => {
        return (
            this.props.summaryData &&
            this.props.summaryData.coachDetail &&
            this.props.summaryData.coachDetail.map((item, i) => {
                return (
                    <div className="personal-item mb-10">
                        <img src={imagesArr.mail_bg_icon} alt="user" />{item.coach.email}
                    </div>
                )
            })
        )
    }

    renderCoachPhone = () => {
        return (
            this.props.summaryData &&
            this.props.summaryData.coachDetail &&
            this.props.summaryData.coachDetail.map((item, i) => {
                return (
                    <div className="personal-item mb-10">
                        <img src={imagesArr.call_icon} alt="user" />(649) 011-2310
                    </div>
                )
            })
        )
    }

    renderParent = () => {
        return (
            this.props.summaryData &&
                this.props.summaryData.parentInfo &&
                this.props.summaryData.parentInfo.length !== 0 ?
                <>
                    <div className="personal-details-list">
                        <div className="personal-item">
                            <img src={imagesArr.user_icon} alt="user" />Tina Fletcher
                        </div>
                    </div>
                    <div className="personal-details-list">
                        <div className="personal-item">
                            <img src={imagesArr.mail_bg_icon} alt="mail" />p.mclaughlin@hotmail.com
                        </div>
                    </div>
                    <div className="personal-details-list">
                        <div className="personal-item">
                            <img src={imagesArr.call_icon} alt="contact" />(528) 136-4933
                        </div>
                    </div>
                </>
                :
                <div className="text-center w-100">
                    <p>No data found</p>
                </div>
        )
    }

    onChange = (value) => {
        setTimeout(() => {
            this.setState({
                reCaptchaRes: value
            })
        }, 1000);
    }

    onHandleChange = (ev) => {
        const { name, value } = ev.target;
        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                "social-link": {
                    ...prevState.sendReq["social-link"],
                    [name]: value
                }
            }
        }))
    }

    onSave = () => {
        if (this.state.uid === this.state.loginUid) {
            console.log("this.state:-----", this.state.sendReq);
            this.props.increment();
            this.props.saveSocialMediaLink(this.state.sendReq);
        }
    }

    calculate_age = (dob1) => {
        var ageDifMs = Date.now() - new Date(dob1).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
    }

    calculate_age_between = (dob1) => {
        let today = new Date(),
            //birthay has 'Dec 25 1998'
            dob = new Date(dob1),
            //difference in milliseconds
            diff = today.getTime() - dob.getTime(),
            //convert milliseconds into years
            years = Math.floor(diff / 31556736000),
            //1 day has 86400000 milliseconds
            days_diff = Math.floor((diff % 31556736000) / 86400000),
            //1 month has 30.4167 days
            months = Math.floor(days_diff / 30.4167),
            days = Math.floor(days_diff % 30.4167);
        if (years === 15 && months >= 0 && days >= 0) {
            return true
        } else if (years === 18 && months == 0 && days == 0) {
            return true
        }
        else if (years >= 14 && years < 18) {
            return true
        } else {
            return false
        }
    }

    onRemove = (data) => {
        this.setState({
            pid: data._id,
            delConfirm: !this.state.delConfirm
        })
    }

    onHandleClose = () => {
        this.setState({
            delConfirm: !this.state.delConfirm,
            pid: ""
        }, () => {
            document.body.classList.remove('modal-open');
            this.props.getSocialList();
        })
    }

    nextHandle = () => {
        this.props.increment();
        this.props.deleteCoachDetails(this.state.pid);
    }


    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            coachdetail: this.state.popupStat === "edit" ? !this.state.coachdetail : false,
            popupStat: "add",
            editDetail: []
        }, () => {
            this.props.getSocialList();
        })
    }

    render() {
        const { summaryData } = this.props;
        const { reCaptchaRes, socialLink, showTxt, sendReq, uid, loginUid, coachdetail, delConfirm } = this.state;
        return (
            <>
                <div className="infocontact-content">
                    {
                        !reCaptchaRes &&
                        <div className="social-profile-content">
                            <div className="social-profile">
                                <ReCAPTCHA
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    sitekey={TEST_SITE_KEY}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    }

                    {
                        reCaptchaRes &&
                        <>
                            <div className="social-profile-content">
                                <div className="social-profile">
                                    <h3 className="section_title">My Social Profile</h3>
                                    <div className="social-icon-group mt-30">
                                        <div className="social-icon-list">
                                            <div className="social-icon " onClick={() => showTxt === "fb" ? this.setState({ showTxt: "" }) : this.setState({ showTxt: "fb" })}>
                                                <img src={imagesArr.facebook_icon} alt="facebook" />{socialLink.fid ? socialLink.fid : "-"}
                                            </div>
                                            {
                                                showTxt === "fb" &&
                                                uid === loginUid &&
                                                <div className="form-group">
                                                    <input type="text" name="fid" value={sendReq['social-link'].fid} className="form-control" onChange={(e) => this.onHandleChange(e)} />
                                                    <button className="blue_btn" onClick={() => this.onSave()}>Save</button>
                                                </div>
                                            }
                                        </div>
                                        <div className="social-icon-list">
                                            <div className="social-icon" onClick={() => showTxt === "insta" ? this.setState({ showTxt: "" }) : this.setState({ showTxt: "insta" })}>
                                                <img src={imagesArr.instagram_icon} alt="instagram" />{socialLink.insta ? socialLink.insta : "-"}
                                            </div>
                                            {
                                                showTxt === "insta" &&
                                                uid === loginUid &&
                                                <div className="form-group">
                                                    <input type="text" name="insta" value={sendReq['social-link'].insta} className="form-control" onChange={(e) => this.onHandleChange(e)} />
                                                    <button className="blue_btn" onClick={() => this.onSave()}>Save</button>
                                                </div>
                                            }
                                        </div>
                                        <div className="social-icon-list">
                                            <div className="social-icon" onClick={() => showTxt === "tid" ? this.setState({ showTxt: "" }) : this.setState({ showTxt: "tid" })}>
                                                <img src={imagesArr.twitter_icon} alt="twitter" />{socialLink.tid ? socialLink.tid : "-"}
                                            </div>
                                            {
                                                showTxt === "tid" &&
                                                uid === loginUid &&
                                                <div className="form-group">
                                                    <input type="text" name="tid" value={sendReq['social-link'].tid} className="form-control" onChange={(e) => this.onHandleChange(e)} />
                                                    <button className="blue_btn" onClick={() => this.onSave()}>Save</button>
                                                </div>
                                            }
                                        </div>
                                        <div className="social-icon-list">
                                            <div className="social-icon" onClick={() => showTxt === "sSid" ? this.setState({ showTxt: "" }) : this.setState({ showTxt: "sSid" })}>
                                                <img src={imagesArr.snapchat_icon} alt="snapchat" />{socialLink.sSid ? socialLink.sSid : "-"}
                                            </div>
                                            {
                                                showTxt === "sSid" &&
                                                uid === loginUid &&
                                                <div className="form-group">
                                                    <input type="text" name="sSid" value={sendReq['social-link'].sSid} className="form-control" onChange={(e) => this.onHandleChange(e)} />
                                                    <button className="blue_btn" onClick={() => this.onSave()}>Save</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="personal-details-content">
                                <div className="personal-details">
                                    <h3 className="section_title">Personal Details</h3>
                                    <div className="white-bg">
                                        <div className="edit-50">
                                            <div className="w-50">
                                                <label className="common-lable">Upload profile pic</label>
                                                <div className="dragdrop-img">
                                                    <div className="create_event_fileUpload file_custom_upload text-center">
                                                        <i className="fas fa-cloud-upload-alt"></i>
                                                        <input name="logofile" type="file" className="img_file_input" onClick={(event)=> { event.target.value = null }}
                                                            accept=".jpg,.JPG,.JPEG,.jpeg,.PNG,.png" />
                                                        <div className="preview_img">
                                                            <img className="preview_img_inner" src={this?.props?.summaryData?.personalDetail?.profileUrl ? this.props.summaryData.personalDetail.profileUrl : imagesArr.default_user} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <label className="common-lable">Full name</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" className="form-control" id="usr" name="fullName" placeholder="Full Name" value={this?.props?.summaryData?.personalDetail?.fname ? this.props.summaryData.personalDetail.fname + " " + this.props.summaryData.personalDetail.lname : "-"} disabled />
                                                </div>

                                                <label className="common-lable">Date of Birth</label>
                                                <div className="form-group mb-0">
                                                    <div className="bc_relative">
                                                        <div className="react-datepicker-wrapper">
                                                            <div className="react-datepicker__input-container">
                                                                <input type="text" laceholder="Enter your birth date" disabled value={this?.props?.summaryData?.personalDetail?.profile?.dob ? moment(this.props.summaryData.personalDetail.profile.dob).format("MM/DD/YYYY") : "-"} />
                                                            </div>
                                                        </div>
                                                        <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" />
                                                    </div>
                                                </div>

                                                <label className="common-lable">Mobile number</label>
                                                <div className="form-group mb-0">
                                                    <div className="svg_icon">
                                                        <div className="input-group">
                                                            <div className="counrty-no">
                                                                <select className="custom-select" name="cCode">
                                                                    <option value="+1">+1</option>
                                                                </select>
                                                            </div>
                                                            <input type="text" className="form-control" id="usr" placeholder="Enter mobile number" name="phone" value={this?.props?.summaryData?.personalDetail?.phone ? this.props.summaryData.personalDetail.phone : "-"} disabled />
                                                            <img src={imagesArr.smartphone} alt="eye" />

                                                        </div>
                                                    </div>
                                                </div>

                                                <label className="common-lable">What sport do you manage or coach?</label>
                                                <div className="check_box">
                                                    <div className="checkbox">
                                                        <input type="radio" id="1" name="gameType" checked={this?.props?.summaryData?.personalDetail?.profile["game-type"] ? this.props.summaryData.personalDetail.profile["game-type"] === "1" ? true : false : false} disabled />
                                                        <label for="Baseball">Baseball</label>
                                                    </div>
                                                    <div className="checkbox mr-0">
                                                        <input type="radio" id="2" name="gameType" checked={this?.props?.summaryData?.personalDetail?.profile["game-type"] ? this.props.summaryData.personalDetail.profile["game-type"] === "2" ? true : false : false} disabled />
                                                        <label for="Softball">Softball</label>
                                                    </div>
                                                </div>

                                                <label className="common-lable">Are you in high school?</label>
                                                <div className="check_box">
                                                    <div className="checkbox">
                                                        <input
                                                            type="radio"
                                                            id=""
                                                            name="allowHighSch"
                                                            value="yes"
                                                            disabled
                                                            checked={this?.props?.summaryData?.personalDetail?.profile["in-high-school"] && this?.props?.summaryData?.personalDetail?.profile["in-high-school"] === "yes" ? true : false}
                                                        />
                                                        <label htmlFor="Baseball">Yes </label>
                                                    </div>
                                                    <div className="checkbox mr-0">
                                                        <input
                                                            type="radio"
                                                            id=""
                                                            name="allowHighSch"
                                                            value="no"
                                                            disabled
                                                            checked={this?.props?.summaryData?.personalDetail?.profile["in-high-school"] && this?.props?.summaryData?.personalDetail?.profile["in-high-school"] === "no" ? true : false}
                                                        />
                                                        <label htmlFor="Softball">No </label>
                                                    </div>
                                                </div>

                                                <label className="common-lable">Name of school</label>
                                                <div className="form-group mb-0">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="school"
                                                        name="school"
                                                        placeholder="Name of school"
                                                        value={this?.props?.summaryData?.personalDetail?.profile?.school ? this.props.summaryData.personalDetail.profile.school : "-"}
                                                        disabled
                                                    />
                                                </div>

                                                {
                                                    this?.props?.summaryData?.personalDetail?.profile &&
                                                    this?.props?.summaryData?.personalDetail?.profile["in-high-school"] &&
                                                    this.props.summaryData.personalDetail.profile.hasOwnProperty('recruiting') &&
                                                    this.calculate_age_between(this?.props?.summaryData?.personalDetail?.profile?.dob) &&
                                                    <>
                                                        <label className="common-lable">Are you intrested in College Recruiting?</label>
                                                        <div className="check_box">
                                                            <div className="checkbox">
                                                                <input
                                                                    type="radio"
                                                                    id=""
                                                                    name="allowRecruting"
                                                                    value="yes"
                                                                    disabled
                                                                    checked={this?.props?.summaryData?.personalDetail?.profile["recruiting"] && this?.props?.summaryData?.personalDetail?.profile["recruiting"] === "yes" ? true : false}
                                                                />
                                                                <label htmlFor="Baseball">Yes </label>
                                                            </div>
                                                            <div className="checkbox mr-0">
                                                                <input
                                                                    type="radio"
                                                                    id=""
                                                                    name="allowRecruting"
                                                                    value="no"
                                                                    disabled
                                                                    checked={this?.props?.summaryData?.personalDetail?.profile["recruiting"] && this?.props?.summaryData?.personalDetail?.profile["recruiting"] === "no" ? true : false}
                                                                />
                                                                <label htmlFor="Baseball">No </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    this?.props?.summaryData?.personalDetail?.profile &&
                                                    this?.props?.summaryData?.personalDetail?.profile["in-high-school"] === "yes" &&
                                                    this.calculate_age(this?.props?.summaryData?.personalDetail?.profile?.dob) &&
                                                    <>
                                                        {/* <label className="common-lable">What is your current GPA?</label>
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="curr-gpa"
                                                                name="curr-gpa"
                                                                placeholder="Name of curr-gpa"
                                                                value={this?.props?.summaryData?.personalDetail?.profile["curr-gpa"] ? this.props.summaryData.personalDetail.profile["curr-gpa"] : "-"}
                                                                disabled
                                                            />
                                                        </div> */}

                                                        <label className="common-lable">What year do you graduate high school?</label>
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="gra-year"
                                                                name="gra-year"
                                                                placeholder="Name of gra-year"
                                                                value={this?.props?.summaryData?.personalDetail?.profile["gra-year"] ? this.props.summaryData.personalDetail.profile["gra-year"] : "-"}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div className='edit-50'>
                                                            <div className="w-33 p-0-14">
                                                                <div className="form-group">
                                                                    <label>GPA </label>
                                                                    <input className="form-control" type="text" value={this?.props?.summaryData?.personalDetail?.profile['curr-gpa'] ? this?.props?.summaryData?.personalDetail?.profile['curr-gpa'] : "-"} disabled />
                                                                </div>
                                                            </div>
                                                            <div className="w-33 p-0-14">
                                                                <div className="form-group">
                                                                    <label>SAT</label>
                                                                    <input className="form-control" type="text" value={this?.props?.summaryData?.personalDetail?.profile?.sat ? this?.props?.summaryData?.personalDetail?.profile?.sat : "-"} disabled />
                                                                </div>
                                                            </div>
                                                            <div className="w-33 p-0-14">
                                                                <div className="form-group">
                                                                    <label>ACT</label>
                                                                    <input className="form-control" type="text" value={this?.props?.summaryData?.personalDetail?.profile?.act ? this?.props?.summaryData?.personalDetail?.profile?.act : "-"} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="w-50">
                                                <label className="common-lable">Email address</label>
                                                <div className="form-group mb-0">
                                                    <div className="serch-box">
                                                        <input type="email" className="form-control" id="usr" placeholder="Enter email address" disabled
                                                            name="email" value={this?.props?.summaryData?.personalDetail?.email ? this.props.summaryData.personalDetail.email : "-"} />
                                                    </div>
                                                </div>

                                                <div className='edit-50'>
                                                    <div className="w-50">
                                                        <label className="common-lable">Height</label>
                                                        <div className="serch-box">
                                                            <div className="form-group mb-0">
                                                                <InputMask {...this.props} className="form-control" name="height" placeholder="Height" inputmode="numeric" value={this?.props?.summaryData?.personalDetail?.profile?.height ? this.props.summaryData.personalDetail.profile.height : "-"} autoComplete="off" disabled />
                                                            </div>
                                                            <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                                                                Ft.
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="w-50">
                                                        <label className="common-lable">Weight</label>
                                                        <div className="serch-box">
                                                            <div className="form-group mb-0">
                                                                <input type="text" className="form-control" id="usr" name="weight" placeholder="Weight" value={this?.props?.summaryData?.personalDetail?.profile?.weight ? this.props.summaryData.personalDetail.profile.weight : "-"} disabled />
                                                            </div>
                                                            <Link className="serch-btn" style={{ background: "#fff", color: "#909caf", height: "35px", top: "6px", right: "3px" }}>
                                                                Pound
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="w-50">
                                                        <label className="common-lable">Throw</label>
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="useHanded"
                                                                name="useHanded"
                                                                placeholder="Name of useHanded"
                                                                value={this?.props?.summaryData?.personalDetail?.profile.useHanded ? this.props.summaryData.personalDetail.profile.useHanded : "-"}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="w-50">
                                                        <label className="common-lable">Bat</label>
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="batting-pos"
                                                                name="batting-pos"
                                                                placeholder="Name of batting-pos"
                                                                value={this?.props?.summaryData?.personalDetail?.profile["batting-pos"] ? this.props.summaryData.personalDetail.profile["batting-pos"][0] : "-"}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="w-50">
                                                        <label className="common-lable">Primary Position</label>
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="position"
                                                                name="position"
                                                                placeholder="Name of position"
                                                                value={this?.props?.summaryData?.personalDetail?.profile.position ? this.props.summaryData.personalDetail.profile.position : "-"}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="w-50">
                                                        <label className="common-lable">Secondary Position</label>
                                                        <div className="form-group mb-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="position2"
                                                                name="position2"
                                                                placeholder="Name of position2"
                                                                value={this?.props?.summaryData?.personalDetail?.profile.position2 ? this.props.summaryData.personalDetail.profile.position2 : "-"}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <label className="common-lable">Zip Code <span>( To Look Up City and State)</span></label>
                                                <div className="form-group mb-0">
                                                    <div className="serch-box">
                                                        <input type="text" className="form-control" id="zipcode" placeholder="Zip Code" name="zipcode"
                                                            value={this?.props?.summaryData?.personalDetail?.profile?.address ? this.props.summaryData.personalDetail.profile.address.zipcode : "-"} disabled />
                                                    </div>
                                                </div>

                                                <label className="common-lable">State</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" className="form-control" id="state" placeholder="State" name="state" readonly=""
                                                        value={this?.props?.summaryData?.personalDetail?.profile?.address ? this.props.summaryData.personalDetail.profile.address.state : "-"} disabled />
                                                </div>

                                                <label className="common-lable">City</label>
                                                <div className="form-group mb-0">
                                                    <input type="text" className="form-control" id="city" placeholder="City" name="city" readonly=""
                                                        value={this?.props?.summaryData?.personalDetail?.profile?.address ? this.props.summaryData.personalDetail.profile.address.city : "-"} disabled />
                                                </div>

                                                <div className="player_list_collage">
                                                    <div className="otherbrand-logo">
                                                        <label className="common-lable w-100">Your Favorite College</label>
                                                        {
                                                            this?.props?.summaryData?.personalDetail?.profile["fav-colleges"].length !== 0 ?
                                                                this.props.summaryData.personalDetail.profile["fav-colleges"].map((item, i) => {
                                                                    return (
                                                                        <div className="brand-logo-inner">
                                                                            <div className="brand-logo-main">
                                                                                <img src={item?.image?.filePath ? item.image.filePath : imagesArr.default_user} alt="collage_name1" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <p className="text-center">-</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-part"></div>
                                    <h3 className="section_title">Coach Details</h3>
                                    {
                                        uid === loginUid &&
                                        <div className="text-right mt-15">
                                            <button className="btn" onClick={() => this.setState({ coachdetail: !coachdetail, popupStat: 'add' })}>Add </button>
                                        </div>
                                    }
                                    {
                                        summaryData &&
                                            summaryData.coachDetail &&
                                            summaryData.coachDetail.length !== 0 ?
                                            summaryData.coachDetail.map((item, i) => {
                                                return (
                                                    <div className="personal-details-group coach-detail mt-30">
                                                        <div className="personal-details-list">
                                                            <div className="personal-item ">
                                                                <img src={localStorage.getItem('company')==="pwb"?imagesArr.user_icon : imagesArr.user_icon_light} alt="user" />{item.fname + " " + item.lname}
                                                            </div>
                                                        </div>
                                                        <div className="personal-details-list">
                                                            <div className="personal-item ">
                                                                <img src={localStorage.getItem('company')==="pwb"?imagesArr.mail_bg_icon : imagesArr.mail_bg_icon_light} alt="user" />{item.email ? item.email : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="personal-details-list">
                                                            <div className="personal-item ">
                                                                <img src={localStorage.getItem('company')==="pwb"?imagesArr.call_icon : imagesArr.call_icon_light} alt="user" />{item.phone ? item.phone : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="personal-details-list">
                                                            <div className="personal-item ">
                                                                <img src={localStorage.getItem('company')==="pwb"?imagesArr.user_icon : imagesArr.user_icon_light} alt="user" />{item.teamName ? item.teamName : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="personal-details-list">
                                                            <div className="personal-item ">
                                                                <img src={localStorage.getItem('company')==="pwb"?imagesArr.location_bg_icon : imagesArr.location_bg_icon_light} alt="user" />{item.city + " / " + item.state}
                                                            </div>
                                                        </div>

                                                        {
                                                            item.external &&
                                                            <div className="next-button">
                                                                <a className="edit-icon" onClick={() => this.setState({ coachdetail: !coachdetail, popupStat: 'edit', editDetail: item })}><img src={localStorage.getItem('company')==="pwb"?imagesArr.edit : imagesArr.edit_light} /></a>
                                                                <a className="delete-icon" onClick={() => this.onRemove(item)}><img src={localStorage.getItem('company')==="pwb"?imagesArr.remove : imagesArr.remove_light} /></a>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                            :
                                            <p className="text-center pt-25">No data found</p>
                                    }
                                    <div className="border-part"></div>
                                    <h3 className="section_title">Parents Details</h3>
                                    <div className="personal-details-group mt-30">
                                        {this.renderParent()}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <ProductList />
                </div>
                {
                    coachdetail &&
                    <CoachDetail
                        flag={coachdetail}
                        close={this.handleClose}
                        increment={this.props.increment}
                        decrement={this.props.decrement}
                        popupStat={this.state.popupStat}
                        data={this.state.editDetail}
                    />
                }
                {
                    delConfirm &&
                    <Confirmation
                        flag={delConfirm}
                        type="delContactConfirm"
                        close={this.onHandleClose}
                        ok={this.nextHandle}
                    />
                }
            </>
        )
    }
}

infoContact.propTypes = {
    saveSocialMediaLink: PropTypes.func.isRequired,
    getLoginResponse: PropTypes.func.isRequired,
    deleteCoachDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileInfo: state.profileSummaryInfo
});

export default connect(mapStateToProps, { saveSocialMediaLink, getLoginResponse, deleteCoachDetails })(infoContact);

import axios from 'axios';
import { API_Url } from '../../../helpers/authToken';
import {
    SOCIAL_CHECK_REQUEST,
    SOCIAL_CHECK_SUCCESS,
    SOCIAL_CHECK_ERROR,
} from '../../../constants/authantication/signup/step1.const';

export const socialCheck = (socialData) => dispatch => {
    dispatch({ type: SOCIAL_CHECK_REQUEST })
    axios({
        method: 'POST',
        url: `${API_Url}bc/registration/social/check`,
        data: socialData,
        headers: {
            'Accept': 'application/json'
        }
    }).then(social => {
        console.log("social:---- ", social);
        if (social.data.flag) {
            dispatch({
                type: SOCIAL_CHECK_SUCCESS,
                payload: social.data,
                storageData: socialData
            })
        } else {
            dispatch({
                type: SOCIAL_CHECK_ERROR,
                error: social.data,
                storageData: socialData
            })
        }

    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: SOCIAL_CHECK_ERROR, error: error.response.data.message })
    });
};

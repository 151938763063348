import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getProfile } from '../../../saga/actions/authantication/verify.action';
import { saveBasic, searchZipcode, uploadImage, getCoachInvitation, getSubCoachInvitation, saveCoachAccept, cancelCoachAccept } from '../../../saga/actions/authantication/signup/coach/basic.action';
import { saveTeam } from '../../../saga/actions/authantication/signup/coach/team.action';
import { saveFacility } from '../../../saga/actions/authantication/signup/coach/facility.action';
import { saveRoster, saveRosterByTeam } from '../../../saga/actions/authantication/signup/coach/roster.action';
import { saveTraining } from '../../../saga/actions/authantication/signup/coach/training.action';
import { saveComplete } from '../../../saga/actions/authantication/signup/coach/complete.action';

import Header from '../../../containers/header';
import Footer from '../../../containers/footer';
import { imagesArr } from '../../../assets/images';
import CommonHeroBanner from '../../../components/commonHeroBanner';
import Basic from './setting/basic';
import Team from './setting/team';
import Facility from './setting/facility';
import Roster from './setting/roster';
import Training from './setting/training';
import commonCalls from "../../../businesslogic/commonCalls";
import Preloader from '../../../components/preloader';
import ImageCrop from '../../../views/popup/imageCrop';
import SuccessMessage from '../../../views/popup/successMessage';
import Notification from '../../../views/popup/notification';
import Confirmation from '../../../views/popup/confirmation';
import ComplateMessage from '../../../views/popup/complateMessage';

class coachStep4 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            coachProfileData: [],
            basicArr: {
                profilePic: "",
                // bio: "",
                "game-type": "1",
                address: {
                    city: "",
                    state: "",
                    zipcode: ""
                }
            },
            zipcodeFlag: false,
            file: null,
            descLen: 0,
            loader: false,
            cropPopup: false,
            renderFile: "",
            imgObj: "",
            claimPopup: false,
            chkTeamType: false,
            fillTab: 0,
            invite: false,
            inviteData: [],
            inviteInfo: false,
            inviteType: "",
            backupData: "",
            acceptPop: false,
            isCompleted: false,
            teamType: "youth",
            showRosterList: false,
            parentTeamId: "",
            disableTeamStep: false,
        }
        this.basicInfo = null;
        this.teamInfo = null;
        this.facilityInfo = null;
        this.rosterInfo = null;
        this.trainingInfo = null;
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        if (this.props.verifyData.profileData) {
            this.props.verifyData.profileData = null;
        }
        window.scrollTo(0, 0);
        this.setLoader(true);
        this.props.getProfile();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.verifyData &&
            nextProps.verifyData.profileReq &&
            nextProps.verifyData.profileData
        ) {
            nextProps.verifyData.profileReq = false;
            this.setState({
                coachProfileData: nextProps.verifyData.profileData.result,
            })
            if (nextProps.verifyData.profileData.result.profile.isCompleted) {
                nextProps.verifyData.profileData = null
                this.setLoader(false);
                // this.setState({ tabs: 2, fillTab: 2 });
                // this.commonCall.successMessage("Login Successfully");
                this.props.history.push('/coach-dashboard');
            } else {
                if (nextProps.verifyData.profileData.result.profile.hasOwnProperty('formData')) {
                    if ((nextProps.verifyData.profileData.result.profile.hasOwnProperty('teamType') &&
                        nextProps.verifyData.profileData.result.profile.teamType === 2) ||
                        nextProps.verifyData.profileData.result.profile.teamType === 3
                    ) {
                        let teamTypeAccept = nextProps.verifyData.profileData.result.profile.teamTypeAccept;
                        let message = teamTypeAccept ? "You can't login as your signup request rejected. check your emails." : "Please wait! You can't SingIn until your SignUp request is not accepted.";
                        this.setState({
                            chkTeamType: true,
                            tabs: 1,
                            fillTab: 1,
                            teamType: nextProps.verifyData.profileData.result.profile.teamType === 2 ? "collage" : "pro",
                        }, () => {
                            this.setLoader(false);
                            nextProps.verifyData.profileData = null;
                            localStorage.clear();
                            this.commonCall.errorMessage(message);
                            this.props.history.push("/");
                        })
                    } else {
                        nextProps.verifyData.profileData.result.profile.formData.map((item, i) => {
                            if (item.sequence === 1) {
                                if (nextProps.verifyData.profileData.result.profile.team &&
                                    nextProps.verifyData.profileData.result.profile.team[0] !== null
                                ) {
                                    this.setState({ tabs: 1, fillTab: 1, teamType: "youth" }, () => {
                                        this.setLoader(false);
                                        nextProps.verifyData.profileData = null
                                    })
                                } else {
                                    this.setState({ tabs: 0, fillTab: 0, teamType: "youth" }, () => {
                                        nextProps.verifyData.profileData = null
                                        // this.setLoader(false);
                                        this.props.getSubCoachInvitation();
                                    })
                                }
                            } else if (item.sequence === 2) {
                                if (nextProps.verifyData.profileData.result.profile.hasOwnProperty('subCoach') && !nextProps.verifyData.profileData.result.profile.subCoach) {
                                    this.setState({ tabs: 3, fillTab: 3, chkTeamType: false }, () => {
                                        this.setLoader(false);
                                        nextProps.verifyData.profileData = null
                                    })
                                } else {
                                    this.setState({
                                        tabs: 2,
                                        fillTab: 2,
                                        showRosterList: true,
                                        parentTeamId: nextProps.verifyData.profileData.result.profile.team ? nextProps.verifyData.profileData.result.profile.team[0]._id : "",
                                        disableTeamStep: nextProps.verifyData.profileData.result.profile.invite ? true : false
                                    }, () => {
                                        this.setLoader(false);
                                        nextProps.verifyData.profileData = null
                                    })
                                }
                            } else if (item.sequence === 3) {
                                this.setState({ tabs: 3, fillTab: 3, chkTeamType: false }, () => {
                                    this.setLoader(false);
                                    nextProps.verifyData.profileData = null
                                })
                            } else if (item.sequence === 4) {
                                this.setState({ tabs: 4, fillTab: 4 }, () => {
                                    this.setLoader(false);
                                    nextProps.verifyData.profileData = null
                                })
                            } else {
                                // this.setState({ tabs: 2 });
                                nextProps.verifyData.profileData = null
                                this.setLoader(false);
                                this.props.history.push('/coach-dashboard');
                            }
                        });
                    }
                } else {
                    this.setState({
                        tabs: 0,
                        teamType: "youth"
                    }, () => {
                        this.setLoader(false);
                        nextProps.verifyData.profileData = null
                    })
                }
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.basicRes &&
            nextProps.basicDetails.basicData
        ) {
            nextProps.basicDetails.basicRes = false;
            if (nextProps.basicDetails.basicData.hasOwnProperty('_id')) {
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        profilePic: (nextProps.basicDetails.basicData.fieldArr.profilePic) ? nextProps.basicDetails.basicData.fieldArr.profilePic._id : "",
                        // bio: nextProps.basicDetails.basicData.fieldArr.bio,
                        "game-type": nextProps.basicDetails.basicData.fieldArr['game-type'],
                        address: {
                            ...prevState.basicArr.address,
                            city: nextProps.basicDetails.basicData.fieldArr.address.city,
                            state: nextProps.basicDetails.basicData.fieldArr.address.state,
                            zipcode: nextProps.basicDetails.basicData.fieldArr.address.zipcode
                        }
                    },
                    // descLen: nextProps.basicDetails.basicData.fieldArr.bio.length,
                    zipcodeFlag: true,
                    file: (nextProps.basicDetails.basicData.fieldArr.profilePic) ? nextProps.basicDetails.basicData.fieldArr.profilePic.filePath : ""
                }), () => {
                    this.setLoader(false);
                    nextProps.basicDetails.basicData = null
                });
            } else {
                this.setLoader(false);
                nextProps.basicDetails.basicData = null
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.imageRes &&
            nextProps.basicDetails.imageData
        ) {
            nextProps.basicDetails.imageRes = false;
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    profilePic: nextProps.basicDetails.imageData._id
                },
                file: nextProps.basicDetails.imageData.filePath,
                imgObj: "",
                cropPopup: false,
                renderFile: ""
            }), () => {
                document.body.classList.remove('modal-open');
                this.setLoader(false);
                nextProps.basicDetails.imageData = null
            });
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.searchRes &&
            nextProps.basicDetails.searchData
        ) {
            nextProps.basicDetails.searchRes = false;
            if (nextProps.basicDetails.searchData.flag) {
                let data = nextProps.basicDetails.searchData;
                this.setState((prevState) => ({
                    ...prevState,
                    basicArr: {
                        ...prevState.basicArr,
                        address: {
                            ...prevState.basicArr.address,
                            city: data.result.city,
                            state: data.result.state,
                            zipcode: data.result.zipcodestr
                        }
                    },
                    zipcodeFlag: true
                }), () => {
                    this.setLoader(false);
                    nextProps.basicDetails.searchData = null
                });
            } else {
                this.setState({
                    zipcodeFlag: false
                }, () => {
                    this.commonCall.errorMessage(nextProps.basicDetails.searchData.message)
                    this.setLoader(false);
                    nextProps.basicDetails.searchData = null;
                })
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.saveRes &&
            nextProps.basicDetails.saveData
        ) {
            nextProps.basicDetails.saveRes = false;
            if (nextProps.basicDetails.saveData.flag) {
                nextProps.basicDetails.saveData = null
                this.setLoader(false);
                this.props.getCoachInvitation();
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.getInviteCoachRes &&
            nextProps.basicDetails.getInviteCoachData
        ) {
            nextProps.basicDetails.getInviteCoachRes = false;
            if (nextProps.basicDetails.getInviteCoachData.result.data.length !== 0) {
                this.setState({
                    invite: true,
                    inviteInfo: true,
                    inviteType: "coachteam",
                    inviteData: nextProps.basicDetails.getInviteCoachData.result.data
                }, () => {
                    nextProps.basicDetails.getInviteCoachData = null
                })
            } else {
                this.props.getSubCoachInvitation();
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.getInviteSubCoachRes &&
            nextProps.basicDetails.getInviteSubCoachData
        ) {
            nextProps.basicDetails.getInviteSubCoachRes = false;
            if (nextProps.basicDetails.getInviteSubCoachData.result.data.length !== 0) {
                this.setState({
                    invite: true,
                    inviteInfo: true,
                    inviteType: "subcoach",
                    inviteData: nextProps.basicDetails.getInviteSubCoachData.result.data
                }, () => {
                    nextProps.basicDetails.getInviteSubCoachData = null
                })
            } else {
                this.setState({
                    tabs: 1,
                    fillTab: 1
                }, () => {
                    nextProps.basicDetails.getInviteSubCoachData = null
                    this.setLoader(false);
                })
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.acceptInviteCoachRes &&
            nextProps.basicDetails.acceptInviteCoachData
        ) {
            nextProps.basicDetails.acceptInviteCoachRes = false;
            if (nextProps.basicDetails.acceptInviteCoachData.flag) {
                this.setState({
                    invite: false,
                    acceptPop: true,
                    disableTeamStep: true
                }, () => {
                    nextProps.basicDetails.acceptInviteCoachData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.basicDetails.acceptInviteCoachData.message);
                nextProps.basicDetails.acceptInviteCoachData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.basicDetails &&
            nextProps.basicDetails.cancelInviteCoachRes &&
            nextProps.basicDetails.cancelInviteCoachData
        ) {
            nextProps.basicDetails.cancelInviteCoachRes = false;
            if (nextProps.basicDetails.cancelInviteCoachData.flag) {
                document.body.classList.remove('modal-open');
                this.setState({
                    invite: false
                }, () => {
                    nextProps.basicDetails.cancelInviteCoachData = null;
                    this.setLoader(false);
                    this.props.history.push('/coach-dashboard');
                })
            } else {
                this.commonCall.errorMessage(nextProps.basicDetails.cancelInviteCoachData.message);
                nextProps.basicDetails.cancelInviteCoachData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.teamDetails &&
            nextProps.teamDetails.addTeamRes &&
            nextProps.teamDetails.addTeamData
        ) {
            nextProps.teamDetails.addTeamRes = false;
            if (nextProps.teamDetails.addTeamData.flag) {
                if (nextProps.teamDetails.addTeamData.result.teamType === 2 || nextProps.teamDetails.addTeamData.result.teamType === 3) {
                    this.setState({
                        claimPopup: !this.state.claimPopup,
                        chkTeamType: true
                    }, () => {
                        nextProps.teamDetails.addTeamData = null;
                        this.setLoader(false);
                    })
                } else {
                    localStorage.setItem("newTeam", true);
                    if (this.teamInfo?.state?.skipRoster) {
                        this.setState({
                            tabs: 3,
                            fillTab: 3,
                        }, () => {
                            this.setLoader(false);
                            nextProps.teamDetails.addTeamData = null
                        })
                    } else {
                        this.setState({
                            tabs: 2,
                            fillTab: 2,
                            showRosterList: true,
                            parentTeamId: nextProps.teamDetails.addTeamData.result.parentTeam
                        }, () => {
                            this.setLoader(false);
                            nextProps.teamDetails.addTeamData = null
                        })
                    }
                }
            }
        }

        if (nextProps.rosterDetails &&
            nextProps.rosterDetails.saveRosterByTeamRes &&
            nextProps.rosterDetails.saveRosterByTeamData
        ) {
            nextProps.rosterDetails.saveRosterByTeamRes = false;
            if (nextProps.rosterDetails.saveRosterByTeamData.flag) {
                this.setState({
                    tabs: 3,
                    fillTab: 3,
                    chkTeamType: false
                }, () => {
                    this.setLoader(false);
                    nextProps.rosterDetails.saveRosterByTeamData = null
                })
            }
        }

        // if (nextProps.facDetails &&
        //     nextProps.facDetails.saveFacRes &&
        //     nextProps.facDetails.saveFacData
        // ) {
        //     nextProps.facDetails.saveFacRes = false;
        //     if (nextProps.facDetails.saveFacData.flag) {
        //         this.setState({
        //             tabs: 3,
        //             fillTab: 3,
        //         }, () => {
        //             this.setLoader(false);
        //             nextProps.facDetails.saveFacData = null
        //         })
        //     }
        // }

        if (nextProps.rosterDetails &&
            nextProps.rosterDetails.saveRosterRes &&
            nextProps.rosterDetails.saveRosterData
        ) {
            nextProps.rosterDetails.saveRosterRes = false;
            if (nextProps.rosterDetails.saveRosterData.flag) {
                // this.commonCall.successMessage(nextProps.rosterDetails.saveRosterData.message);

                document.body.classList.remove('modal-open');
                this.setLoader(true);
                this.rosterInfo.addNewRoster();
                this.props.saveRosterByTeam({ rosterTeamId: nextProps.rosterDetails.saveRosterData.result.rosterTeamId });
                nextProps.rosterDetails.saveRosterData = null;
            } else {
                this.commonCall.errorMessage(nextProps.rosterDetails.saveRosterData.message);
                nextProps.rosterDetails.saveRosterData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.trainingDetails &&
            nextProps.trainingDetails.saveTrainingRes &&
            nextProps.trainingDetails.saveTrainingData
        ) {
            nextProps.trainingDetails.saveTrainingRes = false;
            if (nextProps.trainingDetails.saveTrainingData.flag) {
                nextProps.trainingDetails.saveTrainingData = null;
                this.props.saveComplete();
            }
        }

        if (nextProps.complateDetails &&
            nextProps.complateDetails.saveComplateRes &&
            nextProps.complateDetails.saveComplateData
        ) {
            nextProps.complateDetails.saveComplateRes = false;
            if (nextProps.complateDetails.saveComplateData.flag) {
                this.setLoader(false);
                if (localStorage.getItem("payPlans")) {
                    if (localStorage.getItem("rolPay") === "Coach") {
                        this.commonCall.successMessage(nextProps.complateDetails.saveComplateData.message);
                        nextProps.complateDetails.saveComplateData = null;
                        this.props.history.push('/coach-payment');
                    } else {
                        localStorage.removeItem("rolPay");
                        localStorage.removeItem("dcStats");
                        localStorage.removeItem("payPlans");
                        localStorage.removeItem("analyzr");
                        this.props.history.push('/coach-dashboard');
                        setTimeout(() => {
                            this.setState({
                                isCompleted: true
                            })
                        }, 100);
                        // this.props.history.push('/coach-dashboard');
                    }
                } else {
                    setTimeout(() => {
                        this.setState({
                            isCompleted: true
                        })
                    }, 100);
                    // this.props.history.push('/coach-dashboard');
                }

                // this.props.history.push('/coach-dashboard');
            }
        }

    }

    handleTabs = (e) => {
        if (this.state.fillTab <= e) {
            this.setState({
                tabs: this.state.fillTab
            })
        } else {
            this.setState({
                tabs: e
            })
        }
    }

    onHandleChange = (name, value) => {
        // if (name === "bio") {
        //     if (value.length <= 200) {
        //         this.setState((prevState) => ({
        //             ...prevState,
        //             basicArr: {
        //                 ...prevState.basicArr,
        //                 [name]: value
        //             },
        //             descLen: value.length
        //         }));
        //     }
        // }

        if (name === "game-type") {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    [name]: value
                },
            }));
        }

        if (name === "city" ||
            name === "state"
        ) {
            this.setState((prevState) => ({
                ...prevState,
                basicArr: {
                    ...prevState.basicArr,
                    address: {
                        ...prevState.basicArr.address,
                        [name]: value
                    }
                },
            }));
        }

        if (name === "zipcode") {
            const re = /^[0-9\b]+$/;
            if (value.length <= 9) {
                if (re.test(value) || value.length <= 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        basicArr: {
                            ...prevState.basicArr,
                            address: {
                                ...prevState.basicArr.address,
                                [name]: value
                            }
                        },
                    }));
                }
            }
        }

    }


    handleStateChange = (city, state, zipcode) => {
        this.setState((prevState) => ({
            ...prevState,
            basicArr: {
                ...prevState.basicArr,
                address: {
                    ...prevState.basicArr.address,
                    city: city,
                    state: state,
                    zipcode: zipcode
                }
            },
        }));
    }

    handleChange = (e) => {
        let file = e.target.files[0];
        if (file.name.match(new RegExp('[^.]+$'))[0] !== "jpg" && file.name.match(new RegExp('[^.]+$'))[0] !== "jpeg" && file.name.match(new RegExp('[^.]+$'))[0] !== "png" && file.name.match(new RegExp('[^.]+$'))[0] !== "heic") {
            return this.commonCall.errorMessage("Accept only JPG,JPEG,PNG,HEIC file");
        }
        this.setState({
            imgObj: file,
            cropPopup: true,
            renderFile: URL.createObjectURL(file)
        });
    }

    handleClose = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            cropPopup: false,
        })
    }

    onHadleObject = (obj) => {
        let data = {
            documentType: 16,
            imgFile: obj.file
        }
        this.setLoader(true);
        this.props.uploadImage(data, "Coach")
    }

    searchZipcode = () => {
        this.setLoader(true);
        this.props.searchZipcode(this.state.basicArr.address.zipcode, "Coach");
    }

    nextStep = () => {
        if (this.state.tabs === 0) {
            this.basicInfo.validationUpdate(flag => {
                if (this.state.basicArr.address.state !== "" || this.state.basicArr.address.city !== "") {
                    if (flag) {
                        this.setLoader(true);
                        this.props.saveBasic(this.state.basicArr);
                    }
                } else {
                    this.setState({
                        zipcodeFlag: true
                    })
                }
            });
        } else if (this.state.tabs === 1) {
            this.teamInfo.validationUpdate(obj => {
                if (obj.flag) {
                    this.props.rosterDetails.rosterListData = null;
                    this.setLoader(true);
                    if (obj.type === "School" || obj.type === "Youth") {
                        this.props.saveTeam({ parentTeam: obj.parentTeam, teamType: obj.type === "School" ? 1 : 4 });
                    } else {
                        this.props.saveTeam({ teamName: obj.teamName, teamType: obj.type === "collage" ? 2 : 3 });
                    }
                } else if (obj.skipTeam) {
                    this.setState({
                        tabs: 3,
                        fillTab: 3,
                        chkTeamType: false
                    })
                } else {
                    if (obj.type === "School" || obj.type === "Youth") {
                        this.teamInfo.handleOpenHeadCoach();
                        // this.commonCall.errorMessage("Please select team name");
                    } else {
                        this.commonCall.errorMessage("Please enter team name");
                    }
                }
            });
        } else if (this.state.tabs === 2) {

            this.rosterInfo.validationUpdate(obj => {
                if (obj.flag) {
                    this.setLoader(true);
                    this.props.saveRosterByTeam(obj.data);
                } else if (obj.skipRoster) {
                    this.setState({
                        tabs: 3,
                        fillTab: 3,
                        chkTeamType: false
                    })
                } else {
                    this.rosterInfo.handleOpenHeadCoach();
                }
            });
        }
        // else if (this.state.tabs === 2) {
        //     this.facilityInfo.validationUpdate(obj => {
        //         if (obj.flag) {
        //             this.setLoader(true);
        //             this.props.saveFacility(obj.facilityArr);
        //         }
        //     });
        // } else if (this.state.tabs === 3) {
        //     this.rosterInfo.validationUpdate(obj => {
        //         if (obj) {
        //             this.props.saveRoster(obj);

        //             // if (obj[0]['facilityRoster'][0]['rosterName'] === "") {
        //             //     this.setLoader(true);
        //             //     let data = { teamRoster: obj[0]['teamRoster'] };
        //             //     this.props.saveRoster(data);
        //             // } else {
        //             //     this.setLoader(true);
        //             //     this.props.saveRoster(obj[0]);
        //             // }
        //         }
        //     });
        // }
        else if (this.state.tabs === 3) {
            this.trainingInfo.validationUpdate(obj => {
                if (obj) {
                    this.setLoader(true);
                    this.props.saveTraining(obj);
                }
            });
        } else {

        }
    }

    redirectDashborad = () => {
        this.commonCall.successMessage(this.props.complateDetails.saveComplateData.message);
        this.props.complateDetails.saveComplateData = null;
        if (localStorage.getItem("newTeam") && localStorage.getItem("noAge")) {
            localStorage.removeItem("newTeam");
            this.commonCall.setStorage("coachTab", "roster", flag => {
                if (flag) {
                    this.props.history.push('/coach-setting');
                }
            });
        } else {
            this.props.history.push('/coach-dashboard');
        }
    }

    imageRemove = () => {
        this.setState((prevState) => ({
            ...prevState,
            basicArr: {
                ...prevState.basicArr,
                profilePic: ""
            },
            file: null,
        }), () => {
        });
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    handleCloseClaim = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            claimPopup: !this.state.claimPopup
        }, () => {
            if (this.props.verifyData.profileData) {
                this.props.verifyData.profileData = null;
            }
            localStorage.clear();
            this.props.history.push("/");
        })
    }

    handleCloseComplate = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            isCompleted: false
        }, () => {
            if (this.props.verifyData.profileData) {
                this.props.verifyData.profileData = null;
            }
            this.redirectDashborad();
        })
    }

    onHandleClose = () => {
        this.setLoader(true);
        this.props.cancelCoachAccept({ userId : this.state.coachProfileData._id });
    }

    acceptPopup = (data) => {
        this.setState({
            backupData: data
        }, () => {
            this.setLoader(true);
            this.props.saveCoachAccept({ notification: data._id });
        })
    }

    cancelHandle = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            tabs: 0,
            acceptPop: false,
            inviteType: "",
        })
    }

    nextHandle = () => {
        document.body.classList.remove('modal-open');
        this.setState({
            invite: false,
            acceptPop: false,
        }, () => {
            this.props.saveTeam({ parentTeam: this.state.backupData.parentTeam._id, teamType: 1 });
        })
    }

    render() {
        const tabs = this.state.tabs;
        const { loader, imgObj, cropPopup, renderFile, chkTeamType, invite, inviteData, acceptPop, inviteType } = this.state

        return (
            <>
                <Header />
                <CommonHeroBanner dataList="" />
                <section className="main-timeline">
                    <div className="container">
                        <div className="time-line">
                            <div className="timeline-scoll">
                                <ul className="timeline-inner">
                                    <li className={`${tabs === 0 ? "select" : tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                        <Link>1</Link>
                                    </li>
                                    <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.state.disableTeamStep ? "" : this.handleTabs(1)}>
                                        <Link>2</Link>
                                    </li>
                                    {this.state.showRosterList && <li className={`${tabs === 2 ? "select" : tabs > 2 ? "active" : ""}`} onClick={() => this.handleTabs(chkTeamType ? 1 : 2)}>
                                        <Link>3</Link>
                                    </li>}
                                    <li className={`${tabs === 3 ? "select" : tabs > 3 ? "active" : ""}`} onClick={() => this.handleTabs(chkTeamType ? 1 : 3)}>
                                        <Link>{this.state.showRosterList ? 4 : 3}</Link>
                                    </li>
                                    {/* <li className={`${tabs === 4 ? "select" : tabs > 4 ? "active" : ""}`} onClick={() => this.handleTabs(chkTeamType ? 1 : 4)}>
                                        <Link>5</Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="all_btn_step">
                                <Link className="btn light-green" onClick={() => this.nextStep()}>Next</Link>
                            </div>
                        </div>

                        {tabs === 0 &&
                            <Basic
                                onRef={e => this.basicInfo = e}
                                profileData={this.state.coachProfileData}
                                basicInput={this.state.basicArr}
                                onChange={this.onHandleChange}
                                manageZipcode={this.state.zipcodeFlag}
                                getZipcode={this.searchZipcode}
                                imageUrl={this.state.file}
                                removeImg={this.imageRemove}
                                descLenth={this.state.descLen}
                                load={this.setLoader}
                                handleChange={this.handleChange}
                                handleClose={this.handleClose}
                                onHadleObject={this.onHadleObject}
                                handleStateChange={this.handleStateChange}
                            />
                        }
                        {tabs === 1 &&
                            <Team
                                onRef={e => this.teamInfo = e}
                                profileData={this.state.coachProfileData}
                                chkTeamType={this.state.chkTeamType}
                                nextStep={this.nextStep}
                            />
                        }
                        {tabs === 2 &&
                            <Roster
                                onRef={e => this.rosterInfo = e}
                                profileData={this.state.coachProfileData}
                                parentTeamId={this.state.parentTeamId}
                                nextStep={this.nextStep}
                            />}
                        {/* {tabs === 2 &&
                            <Facility
                                onRef={e => this.facilityInfo = e}
                                profileData={this.state.coachProfileData}
                            />
                        }
                        {tabs === 3 &&
                            <Roster
                                onRef={e => this.rosterInfo = e}
                                profileData={this.state.coachProfileData}
                            />} */}
                        {tabs === 3 &&
                            <Training
                                onRef={e => this.trainingInfo = e}
                                profileData={this.state.coachProfileData}
                            />
                        }

                        <div className="time-line">
                            <div className="timeline-scoll">
                                <ul className="timeline-inner">
                                    <li className={`${tabs === 0 ? "select" : tabs >= 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                        <Link>1</Link>
                                    </li>
                                    <li className={`${tabs === 1 ? "select" : tabs > 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                        <Link>2</Link>
                                    </li>
                                    {this.state.showRosterList && <li className={`${tabs === 2 ? "select" : tabs > 2 ? "active" : ""}`} onClick={() => this.handleTabs(chkTeamType ? 1 : 2)}>
                                        <Link>3</Link>
                                    </li>}
                                    <li className={`${tabs === 3 ? "select" : tabs > 3 ? "active" : ""}`} onClick={() => this.handleTabs(chkTeamType ? 1 : 3)}>
                                        <Link>{this.state.showRosterList ? 4 : 3}</Link>
                                    </li>
                                    {/* <li className={`${tabs === 4 ? "select" : tabs > 4 ? "active" : ""}`} onClick={() => this.handleTabs(chkTeamType ? 1 : 4)}>
                                        <Link>5</Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="all_btn_step">
                                <Link className="btn light-green" onClick={() => this.nextStep()}>Next</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Preloader flag={loader} />
                <ImageCrop
                    imgObj={imgObj}
                    flag={cropPopup}
                    close={this.handleClose}
                    renderImg={renderFile}
                    getObj={this.onHadleObject}
                />
                <SuccessMessage
                    profileInfo={this.state.coachProfileData}
                    close={this.handleCloseClaim}
                    flag={this.state.claimPopup}
                />
                <Notification
                    flag={invite}
                    data={inviteData}
                    inviteType={inviteType}
                    close={this.onHandleClose}
                    accept={this.acceptPopup}
                />
                <Confirmation
                    flag={acceptPop}
                    close={this.onHandleClose}
                    type="coach"
                    closeCancel={this.cancelHandle}
                    closeNext={this.nextHandle}
                />
                <ComplateMessage
                    close={this.handleCloseComplate}
                    flag={this.state.isCompleted}
                />
            </>
        );
    }
}

coachStep4.propTypes = {
    getProfile: PropTypes.func.isRequired,
    saveBasic: PropTypes.func.isRequired,
    searchZipcode: PropTypes.func.isRequired,
    saveTeam: PropTypes.func.isRequired,
    saveFacility: PropTypes.func.isRequired,
    saveRoster: PropTypes.func.isRequired,
    saveTraining: PropTypes.func.isRequired,
    saveComplete: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    getCoachInvitation: PropTypes.func.isRequired,
    saveCoachAccept: PropTypes.func.isRequired,
    cancelCoachAccept: PropTypes.func.isRequired,
    getSubCoachInvitation: PropTypes.func.isRequired,
    saveRosterByTeam: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    verifyData: state.verifyOtp,
    basicDetails: state.basicDetails,
    teamDetails: state.teamDetails,
    facDetails: state.facDetails,
    rosterDetails: state.rosterDetails,
    trainingDetails: state.trainingDetails,
    complateDetails: state.complateDetails
});

export default connect(mapStateToProps, { saveRosterByTeam, getCoachInvitation, getSubCoachInvitation, saveCoachAccept, cancelCoachAccept, getProfile, saveBasic, searchZipcode, saveTeam, saveFacility, saveRoster, saveTraining, saveComplete, uploadImage })(coachStep4);

import axios from 'axios';
import { API_Url } from '../../../helpers/authToken';
import {
    CODE_REQUEST,
    CODE_SUCCESS,
    CODE_ERROR,

    REGI_STEP2_REQUEST,
    REGI_STEP2_SUCCESS,
    REGI_STEP2_ERROR,
} from '../../../constants/authantication/signup/step2.const';
import moment from "moment";

export const getCode = () => dispatch => {
    // dispatch({ type: CODE_REQUEST })
    axios({
        method: 'GET',
        url: `${API_Url}setting/app`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(code => {
        // console.log("code:---- ", code.data.result);
        dispatch({
            type: CODE_SUCCESS,
            payload: code.data.result
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: CODE_ERROR, error: error.response.data.message })
    });
};

export const signupStep2 = (step2Data) => dispatch => {
    console.log("up:--", step2Data);
    if (step2Data.type === "") {
        delete step2Data["type"];
        delete step2Data["socialId"];
    }
    console.log("belove:--", step2Data);
    dispatch({ type: REGI_STEP2_REQUEST })
    axios({
        method: 'POST',
        url: `${step2Data.type ? API_Url + 'bc/registration/social' : API_Url + 'bc/registration'}`,
        data: step2Data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(register => {
        console.log("register:---- ", register);
        dispatch({
            type: REGI_STEP2_SUCCESS,
            payload: register.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401)
            dispatch({ type: REGI_STEP2_ERROR, error: error.response.data.message })
    });
};


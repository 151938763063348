import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import ProductList from "../../../company/components/common/productList";
import AddVideo from '../../../popup/addVideo';
import { getLoginResponse, sendCount } from '../../../../saga/actions/views/company/pwRanking/profileSummary.action';
import DatePicker from "react-datepicker";
import { imagesArr } from "../../../../assets/images";
import moment from "moment";
class gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: "",
            isAddVideo: false,
            loginUid: ""
        }
    }

    componentDidMount = () => {
        let url = new URL(document.location);
        let getId = url.pathname.split("/")[2];
        this.setState({
            uid: getId
        }, () => {
            if (localStorage.getItem("webglToken")) {
                this.props.increment();
                this.props.getLoginResponse();
            }
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.profileInfo &&
            nextProps.profileInfo.getLoginResReq &&
            nextProps.profileInfo.getLoginResData
        ) {
            nextProps.profileInfo.getLoginResReq = false;
            if (nextProps.profileInfo.getLoginResData.flag) {
                this.setState({
                    loginUid: nextProps.profileInfo.getLoginResData.result._id
                }, () => {
                    nextProps.profileInfo.getLoginResData = null;
                    this.props.decrement();
                })
            } else {
                nextProps.profileInfo.getLoginResData = null;
                this.props.decrement();
            }
        }

        if (nextProps.profileInfo &&
            nextProps.profileInfo.saveViewCountReq &&
            nextProps.profileInfo.saveViewCountData
        ) {
            nextProps.profileInfo.saveViewCountReq = false;
            if (nextProps.profileInfo.saveViewCountData.flag) {
                nextProps.profileInfo.saveViewCountData = null;
                // this.props.decrement();
                this.props.getPlayerList();
            } else {
                nextProps.profileInfo.saveViewCountData = null;
                this.props.decrement();
            }
        }
    }

    onHandleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            isAddVideo: !this.state.isAddVideo
        }, () => {
            this.props.getPlayerList();
        })
    };

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    sendViews = (item) => {
        console.log("click View:--------", item);
        if (localStorage.getItem("webglToken")) {
            // this.props.increment();
            this.props.sendCount({ vId: item.vId._id, companyId: localStorage.getItem("cid") });
        }
    }

    render() {
        const { summaryData, sendReq } = this.props;
        const years = Array.from(new Array(81), (val, index) => ((new Date()).getFullYear() - index))
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            summaryData?.length !== 0 &&
            <>
                <div className="gallery-report-filter pb-15">
                    <div className="filter-text"><h3>Sort By</h3></div>
                    <div className="form-group select-box">
                        <select className="custom-select" name="filterOn" value={sendReq.filterOn} onChange={(e) => this.props.onFilter(e)}>
                            <option value={0}>None</option>
                            <option value={1}>Date</option>
                            <option value={2}>View Count</option>
                        </select>
                    </div>
                    {
                        sendReq.filterOn === 1 &&
                        <>
                            <div className="form-group">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={(c) => (this._calendar = c)}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                    {years.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                            </div>
                                        )}
                                        selected={sendReq.sortOn.gallerystartDate}
                                        maxDate={moment().subtract(1, 'days').toDate()}
                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(e) => this.props.onChangeDate(e, "gallerystartDate", "galleryTab")}
                                        placeholderText="Start date"
                                    />
                                    <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="bc_relative">
                                    <DatePicker
                                        ref={(c) => (this._calendar = c)}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        }) => (
                                            <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                <a className="datearrow" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</a>
                                                <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)} >
                                                    {years.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                    {months.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <a className="datearrow" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</a>
                                            </div>
                                        )}
                                        selected={sendReq.sortOn.galleryendDate}
                                        minDate={moment(sendReq.sortOn.gallerystartDate).subtract(0, 'days').toDate()}
                                        maxDate={moment().subtract(1, 'days').toDate()}
                                        onChangeRaw={(e) => this.handleDateChangeRaw(e)}
                                        onChange={(e) => this.props.onChangeDate(e, "galleryendDate", "galleryTab")}
                                        placeholderText="End date"
                                    />
                                    <img src={imagesArr.calendar} alt="calendar" className="bc_datepicker-icon" />
                                </div>
                            </div>
                        </>
                    }

                </div>
                <div className="gallery-content">

                    {
                        localStorage.getItem("webglToken") &&
                        this.state.uid === this.state.loginUid &&
                        <div className="text-right pb-15">
                            <button className="btn" onClick={() => this.setState({ isAddVideo: !this.state.isAddVideo })}>Upload Media</button>
                        </div>
                    }
                    <div className="gallary-video">
                        {
                            summaryData?.cVideos?.map((video, i) => {
                                return (
                                    <div className="video-box" key={i}>
                                        <div className="video-bg">
                                            <ReactPlayer
                                                className='react-player'
                                                url={video.vId.filePath}
                                                controls
                                                width='100%'
                                                height='250px'
                                                onPlay={() => this.sendViews(video)}
                                            />
                                        </div>
                                        <div className="counter_text">
                                            <h3>{video.title}</h3>
                                            <p><span>{video.count}</span>View </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        summaryData?.cVideos?.length === 0 &&
                        <p className="text-center pt-10">No data found</p>
                    }
                </div>
                <ProductList />
                {this.state.isAddVideo && (
                    <AddVideo
                        flag={this.state.isAddVideo}
                        close={this.onHandleClose}
                        videoList={summaryData?.cVideos ? summaryData.cVideos : []}
                        uid={this.state.uid}
                    />
                )}
            </>
        )
    }
}

gallery.propTypes = {
    getLoginResponse: PropTypes.func.isRequired,
    sendCount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profileInfo: state.profileSummaryInfo
});

export default connect(mapStateToProps, { getLoginResponse, sendCount })(gallery);
import React from 'react'
import { imagesArr } from '../../../../../assets/images';

function Awards({ allRecapDescription }) {
  return (
    <>
            <div className="recap-info-tab">
                <div className="touranment-awards">
                    <div className="container">
                        <h1 className="section_title">Tournament Awards</h1>
                        {/* <p className="text-center mt-15">Check back on the recap page after the event has finished.</p> */}
                        {/* <p className="text-center">Event recap article to go here - entered in CMS with text editor and tagging capability in PW</p> */}

                        {/* allRecapDescription?.eventObj?.Nohitter || allRecapDescription?.eventObj?.perfectGammer */}
                        {/* allRecapDescription?.eventObj?.nohitter?.length !== 0 */}

                        {/* allRecapDescription?.eventObj?.TMVPPlayer */}
                        {/* allRecapDescription?.eventObj?.tournamentMVP_Player?.length !== 0 */}

                        {/* allRecapDescription?.eventObj?.TMVPPitcher */}
                        {/* allRecapDescription?.eventObj?.tournamentMVP_Pitcher?.length !== 0 */}

                        {/* allRecapDescription?.eventObj?.TMVPSlugger */}
                        {/* allRecapDescription?.eventObj?.tournamentMVP_Slugger?.length !== 0 */}

                        {/* allRecapDescription?.eventObj?.TournamentFirstTeam */}
                        {/* allRecapDescription?.eventObj?.Tournament_FirstTeam?.length === 0 */}

                        {/* allRecapDescription?.eventObj?.TournamentSecondTeam */}
                        {/* allRecapDescription?.eventObj?.Tournament_SecondTeam?.length !== 0 */}

                        {/* allRecapDescription?.eventObj?.HounerableMention */}
                        {/* allRecapDescription?.eventObj?.Tournament_HounerableTeam?.length !== 0 */}

                        {
                            (allRecapDescription?.eventObj?.Nohitter || allRecapDescription?.eventObj?.perfectGammer) &&
                            <div className="row">
                                <div className="col-4">
                                    <div className="awards-type">
                                        <div className="award-img">
                                            <img src={imagesArr.touranment_award} />
                                        </div>
                                        <h4>No Hitter or Perfect Game</h4>
                                        <p>- {allRecapDescription?.eventObj?.Nohitter && allRecapDescription?.eventObj?.nohitter?.length !== 0 ? allRecapDescription?.eventObj?.nohitter[0].player.fname + " " + allRecapDescription?.eventObj?.nohitter[0].player.lname : allRecapDescription?.eventObj?.perfectGammer && allRecapDescription?.eventObj?.PerfectGammer?.length !== 0 ? allRecapDescription?.eventObj?.PerfectGammer[0].player.fname + " " + allRecapDescription?.eventObj?.PerfectGammer[0].player.lname : "No data found"} -</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {
                                allRecapDescription?.eventObj?.TMVPPlayer &&
                                <div className="col-4">
                                    <div className="awards-type">
                                        <div className="award-img">
                                            <img src={allRecapDescription?.eventObj?.awardImage.TMVPPlayer ? allRecapDescription?.eventObj?.awardImage.TMVPPlayer : imagesArr.default_user} />
                                        </div>
                                        <h4>MVP Player</h4>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.tournamentMVP_Player?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.tournamentMVP_Player.map((item, i) => {
                                                            return (
                                                                <li>{item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.TMVPPitcher &&
                                <div className="col-4">
                                    <div className="awards-type">
                                        <div className="award-img">
                                            <img src={allRecapDescription?.eventObj?.awardImage.TMVPPitcher ? allRecapDescription?.eventObj?.awardImage.TMVPPitcher : imagesArr.default_user} />
                                        </div>
                                        <h4>MVP Pitcher</h4>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.tournamentMVP_Pitcher?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.tournamentMVP_Pitcher.map((item, i) => {
                                                            return (
                                                                <li>{item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.TMVPSlugger &&
                                <div className="col-4">
                                    <div className="awards-type">
                                        <div className="award-img">
                                            <img src={allRecapDescription?.eventObj?.awardImage.TMVPSlugger ? allRecapDescription?.eventObj?.awardImage.TMVPSlugger : imagesArr.default_user} />
                                        </div>
                                        <h4>MVP Slugger</h4>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.tournamentMVP_Slugger?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.tournamentMVP_Slugger.map((item, i) => {
                                                            return (
                                                                <li>{item?.player ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            allRecapDescription?.eventObj?.TournamentFirstTeam &&
                            <div className="touranment-team-rank">
                                <div className="touranment-awards-type">
                                    <div className="award-img">
                                        <img src={allRecapDescription?.eventObj?.awardImage.Tournament_FirstTeam ? allRecapDescription?.eventObj?.awardImage.Tournament_FirstTeam : imagesArr.default_user} />
                                        <h4>1st All Tournament Team</h4>
                                    </div>
                                    <div className="rank-list">
                                        <div className={`team-list ${allRecapDescription?.eventObj?.Tournament_FirstTeam?.length === 0 ? "no-data" : ""}`}>
                                            {
                                                allRecapDescription?.eventObj?.Tournament_FirstTeam?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.Tournament_FirstTeam.map((item, i) => {
                                                        return (
                                                            <div className="team-name">
                                                                {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>
                                                        No data found
                                                    </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.TournamentSecondTeam &&
                            <div className="touranment-team-rank">
                                <div className="touranment-awards-type">
                                    <div className="award-img">
                                        <img src={ allRecapDescription?.eventObj?.awardImage.Tournament_SecondTeam ? allRecapDescription?.eventObj?.awardImage.Tournament_SecondTeam : imagesArr.default_user} />
                                        <h4>2nd All Tournament Team</h4>
                                    </div>
                                    <div className="rank-list">
                                        <div className={`team-list ${allRecapDescription?.eventObj?.Tournament_SecondTeam?.length === 0 ? "no-data" : ""}`}>
                                            {
                                                allRecapDescription?.eventObj?.Tournament_SecondTeam?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.Tournament_SecondTeam.map((item, i) => {
                                                        return (
                                                            <div className="team-name">
                                                               {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>
                                                        No data found
                                                    </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            allRecapDescription?.eventObj?.HounerableMention &&
                            <div className="touranment-team-rank">
                                <div className="touranment-awards-type">
                                    <div className="award-img">
                                        <img src={ allRecapDescription?.eventObj?.awardImage.Tournament_HounerableTeam ? allRecapDescription?.eventObj?.awardImage.Tournament_HounerableTeam : imagesArr.default_user} />
                                        <h4>Tournament Honorable Mention</h4>
                                    </div>
                                    <div className="rank-list">
                                        <div className={`team-list ${allRecapDescription?.eventObj?.Tournament_HounerableTeam?.length === 0 ? "no-data" : ""}`}>
                                            {
                                                allRecapDescription?.eventObj?.Tournament_HounerableTeam?.length !== 0 ?
                                                    allRecapDescription?.eventObj?.Tournament_HounerableTeam.map((item, i) => {
                                                        return (
                                                            <div className="team-name">
                                                                {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>
                                                        No data found
                                                    </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/* <div className="stat-leaders">
                    <div className="container">
                        <h1 className="section_title">Stats Leaders</h1>
                        {
                            (allRecapDescription?.eventObj?.RunsScored || allRecapDescription?.eventObj?.TotalBase || allRecapDescription?.eventObj?.RunsBatted || allRecapDescription?.eventObj?.Walks || allRecapDescription?.eventObj?.StolenBases
                                || allRecapDescription?.eventObj?.OBP || allRecapDescription?.eventObj?.OPS || allRecapDescription?.eventObj?.HR || allRecapDescription?.eventObj?.B1 || allRecapDescription?.eventObj?.B2 || allRecapDescription?.eventObj?.B3 || allRecapDescription?.eventObj?.B4) ?
                                <h4 className="section-sub-title">Batting Leaders</h4>
                                :
                                <>
                                    {allRecapDescription.scheduleLive ? <p className="text-center mt-15">No data found</p> :
                                        <p className="text-center mt-15">Check back on the recap page after the event has finished.</p>}
                                </>
                        }
                        <div className="row">
                            {
                                allRecapDescription?.eventObj?.RunsScored &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>Runs Scored (R)</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Runs Scored (R)', data: allRecapDescription?.eventObj?.topRunscorerR } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.topRunscorerR?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.topRunscorerR.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.TotalBase &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>Total Bases (TB)</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Total Bases (TB)', data: allRecapDescription?.eventObj?.totalbase } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.totalbase?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.totalbase.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.RunsBatted &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>Runs Batted In (RBI)</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Runs Batted In (RBI)', data: allRecapDescription?.eventObj?.runsbattedin } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.runsbattedin?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.runsbattedin.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.Walks &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>Walks (BB)</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Walks (BB)', data: allRecapDescription?.eventObj?.walksbb } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.walksbb?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.walksbb.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.StolenBases &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>Stolen Bases (SB)</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Stolen Bases (SB)', data: allRecapDescription?.eventObj?.stolebase } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.stolebase?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.stolebase.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.OBP &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>OBP</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'OBP', data: allRecapDescription?.eventObj?.obp } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.obp?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.obp.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.OPS &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>OPS</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'OPS', data: allRecapDescription?.eventObj?.ops } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.ops?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.ops.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.HR &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>HR</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'HR', data: allRecapDescription?.eventObj?.hr } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.hr?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.hr.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.B1 &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>1B</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: '1B', data: allRecapDescription?.eventObj?.b1 } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.b1?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.b1.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.B2 &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>2B</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: '2B', data: allRecapDescription?.eventObj?.b2 } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.b2?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.b2.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.B3 &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>3B</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: '3B', data: allRecapDescription?.eventObj?.b3 } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.b3?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.b3.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.B4 &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>BA</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'BA', data: allRecapDescription?.eventObj?.ba } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.ba?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.ba.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            (allRecapDescription?.eventObj?.WHIP || allRecapDescription?.eventObj?.StrikeOUts) &&
                            <h4 className="section-sub-title">Pitching Leaders</h4>
                        }
                        <div className="row">
                            {
                                allRecapDescription?.eventObj?.WHIP &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>WHIP</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'WHIP', data: allRecapDescription?.eventObj?.whip } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.whip?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.whip.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                allRecapDescription?.eventObj?.StrikeOUts &&
                                <div className="col-3">
                                    <div className="stat-leaders-box">
                                        <div className="title-tag">
                                            <h5>Strikeouts</h5>
                                            {
                                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout") &&
                                                <button className="btn" onClick={() => this.setState({ topRec: !this.state.topRec, topRecData: { title: 'Strikeouts', data: allRecapDescription?.eventObj?.strikeouts } })}>Top 10</button>
                                            }
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {
                                                    allRecapDescription?.eventObj?.strikeouts?.length !== 0 ?
                                                        allRecapDescription?.eventObj?.strikeouts.map((item, i) => {
                                                            return (
                                                                <li>{item.rank}. {item.player.fname ? item.player.fname + " " + item.player.lname : "-"}</li>
                                                            )
                                                        })
                                                        :
                                                        <li className="text-center">
                                                            No data found
                                                        </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div> */}
            </div>
        </>
  )
}

export default Awards
import React, { Component } from "react";
import Routers from "./Route";
import store from './saga/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css";
import 'antd/dist/antd.css';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Routers />
        <ToastContainer autoClose={4000}/>
      </Provider>
    );
  }
}

export default App;

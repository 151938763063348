import {
    COM_RECAP_INFO_REQUEST,
    COM_RECAP_INFO_SUCCESS,
    COM_RECAP_INFO_ERROR,

    COM_RECAP_DIVISION_REQUEST,
    COM_RECAP_DIVISION_SUCCESS,
    COM_RECAP_DIVISION_ERROR,

    COM_RECAP_INFORMATION_REQUEST,
    COM_RECAP_INFORMATION_SUCCESS,
    COM_RECAP_INFORMATION_ERROR,

    COM_RECAP_DESCRIPTION_REQUEST,
    COM_RECAP_DESCRIPTION_SUCCESS,
    COM_RECAP_DESCRIPTION_ERROR,

    COM_RECAP_AGE_GROUP_REQUEST,
    COM_RECAP_AGE_GROUP_SUCCESS,
    COM_RECAP_AGE_GROUP_ERROR,

    GET_RECAP_FILTER_DATA_REQUEST,
    GET_RECAP_FILTER_DATA_SUCCESS,
    GET_RECAP_FILTER_DATA_ERROR,

    GET_RECAP_SHOWCASE_REQUEST,
    GET_RECAP_SHOWCASE_SUCCESS,
    GET_RECAP_SHOWCASE_ERROR,

    COM_ALL_RECAP_DESCRIPTION_REQUEST,
    COM_ALL_RECAP_DESCRIPTION_SUCCESS,
    COM_ALL_RECAP_DESCRIPTION_ERROR,

    COM_STATS_LEADER_HITTING_REQUEST,
    COM_STATS_LEADER_HITTING_SUCCESS,
    COM_STATS_LEADER_HITTING_ERROR,

    COM_STATS_LEADER_PITCHING_REQUEST,
    COM_STATS_LEADER_PITCHING_SUCCESS,
    COM_STATS_LEADER_PITCHING_ERROR


} from '../../../../constants/view/company/pwb_pws/recapInfo.const';

const initialState = {
    recapInfoReq: false,
    recapInfoData: null,
    recapInfoError: null,

    recapInformationReq: false,
    recapInformationData: null,
    recapInformationError: null,

    recapDivisionReq: false,
    recapDivisionData: null,
    recapDivisionError: null,

    recapDescriptionReq: false,
    recapDescriptionData: null,
    recapDescriptionError: null,

    recapAgeGroupReq: false,
    recapAgeGroupData: null,
    recapAgeGroupError: null,

    recapFilterValReq: false,
    recapFilterValData: null,
    recapFilterValError: null,

    recShowcaseReq: false,
    recShowcaseData: null,
    recShowcaseError: null,

    pwbOptionReq: false,
    pwbOptionData: null,
    pwbOptionError: null,

    pwsOptionReq: false,
    pwsOptionData: null,
    pwsOptionError: null,

    pwbBracketReq: false,
    pwbBracketData: null,
    pwbBracketError: null,

    pwsBracketReq: false,
    pwsBracketData: null,
    pwsBracketError: null,

    pwbBracketDivReq: false,
    pwbBracketDivData: null,
    pwbBracketDivError: null,

    pwsBracketDivReq: false,
    pwsBracketDivData: null,
    pwsBracketDivError: null,

    allRecapDescriptionReq: false,
    allRecapDescriptionData: null,
    allRecapDescriptionError: null,

    hittingStatsReq: null,
    hittingStatsResponse: null,
    hittingStatsError: null,

    pitchingStatsReq: null,
    pitchingStatsResponse: null,
    pitchingStatsError: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Event PWB recap bracket division data req    
        case "GET_PWB_BRACKET_DIVISION_REQUEST":
            return {
                ...state,
                pwbBracketDivReq: true,
            };

        case "GET_PWB_BRACKET_DIVISION_SUCCESS":
            return {
                ...state,
                pwbBracketDivData: action.payload,
            };
        case "GET_PWB_BRACKET_DIVISION_ERROR":
            return {
                ...state,
                pwbBracketDivError: action.error,
            };

        // Event PWS recap bracket division data req    
        case "GET_PWS_BRACKET_DIVISION_REQUEST":
            return {
                ...state,
                pwsBracketDivReq: true,
            };

        case "GET_PWS_BRACKET_DIVISION_SUCCESS":
            return {
                ...state,
                pwsBracketDivData: action.payload,
            };
        case "GET_PWS_BRACKET_DIVISION_ERROR":
            return {
                ...state,
                pwsBracketDivError: action.error,
            };

        // Event PWB recap bracket filter data req    
        case "GET_PWB_BRACKET_FILTER_REQUEST":
            return {
                ...state,
                pwbBracketReq: true,
            };

        case "GET_PWB_BRACKET_FILTER_SUCCESS":
            return {
                ...state,
                pwbBracketData: action.payload,
            };
        case "GET_PWB_BRACKET_FILTER_ERROR":
            return {
                ...state,
                pwbBracketError: action.error,
            };

        // Event PWS recap bracket filter data req    
        case "GET_PWS_BRACKET_FILTER_REQUEST":
            return {
                ...state,
                pwsBracketReq: true,
            };

        case "GET_PWS_BRACKET_FILTER_SUCCESS":
            return {
                ...state,
                pwsBracketData: action.payload,
            };
        case "GET_PWS_BRACKET_FILTER_ERROR":
            return {
                ...state,
                pwsBracketError: action.error,
            };

        // Event PWB recap bracket data req    
        case "GET_PWB_OPTION_FILTER_REQUEST":
            return {
                ...state,
                pwbOptionReq: true,
            };

        case "GET_PWB_OPTION_FILTER_SUCCESS":
            return {
                ...state,
                pwbOptionData: action.payload,
            };
        case "GET_PWB_OPTION_FILTER_ERROR":
            return {
                ...state,
                pwbOptionError: action.error,
            };

        // Event PWS recap bracket data req    
        case "GET_PWS_OPTION_FILTER_REQUEST":
            return {
                ...state,
                pwsOptionReq: true,
            };

        case "GET_PWS_OPTION_FILTER_SUCCESS":
            return {
                ...state,
                pwsOptionData: action.payload,
            };
        case "GET_PWS_OPTION_FILTER_ERROR":
            return {
                ...state,
                pwsOptionError: action.error,
            };

        // Event recap showcase data req    
        case GET_RECAP_SHOWCASE_REQUEST:
            return {
                ...state,
                recShowcaseReq: true,
            };

        case GET_RECAP_SHOWCASE_SUCCESS:
            return {
                ...state,
                recShowcaseData: action.payload,
            };
        case GET_RECAP_SHOWCASE_ERROR:
            return {
                ...state,
                recShowcaseError: action.error,
            };

        // Event recap filter value req    
        case GET_RECAP_FILTER_DATA_REQUEST:
            return {
                ...state,
                recapFilterValReq: true,
            };

        case GET_RECAP_FILTER_DATA_SUCCESS:
            return {
                ...state,
                recapFilterValData: action.payload,
            };
        case GET_RECAP_FILTER_DATA_ERROR:
            return {
                ...state,
                recapFilterValError: action.error,
            };

        // Event recap information req    
        case COM_RECAP_INFORMATION_REQUEST:
            return {
                ...state,
                recapInformationReq: true,
            };

        case COM_RECAP_INFORMATION_SUCCESS:
            return {
                ...state,
                recapInformationData: action.payload,
            };
        case COM_RECAP_INFORMATION_ERROR:
            return {
                ...state,
                recapInformationError: action.error,
            };

        // Event recap info req    
        case COM_RECAP_INFO_REQUEST:
            return {
                ...state,
                recapInfoReq: true,
            };

        case COM_RECAP_INFO_SUCCESS:
            return {
                ...state,
                recapInfoData: action.payload,
            };
        case COM_RECAP_INFO_ERROR:
            return {
                ...state,
                recapInfoError: action.error,
            };

        // Event recap division req    
        case COM_RECAP_DIVISION_REQUEST:
            return {
                ...state,
                recapDivisionReq: true,
            };

        case COM_RECAP_DIVISION_SUCCESS:
            return {
                ...state,
                recapDivisionData: action.payload,
            };
        case COM_RECAP_DIVISION_ERROR:
            return {
                ...state,
                recapDivisionError: action.error,
            };

        // Event recap description req    
        case COM_RECAP_DESCRIPTION_REQUEST:
            return {
                ...state,
                recapDescriptionReq: true,
            };

        case COM_RECAP_DESCRIPTION_SUCCESS:
            return {
                ...state,
                recapDescriptionData: action.payload,
            };
        case COM_RECAP_DESCRIPTION_ERROR:
            return {
                ...state,
                recapDescriptionError: action.error,
            };

        // Event recap age group req    
        case COM_RECAP_AGE_GROUP_REQUEST:
            return {
                ...state,
                recapAgeGroupReq: true,
            };

        case COM_RECAP_AGE_GROUP_SUCCESS:
            return {
                ...state,
                recapAgeGroupData: action.payload,
            };
        case COM_RECAP_AGE_GROUP_ERROR:
            return {
                ...state,
                recapAgeGroupError: action.error,
            };

        // Event recap description req    
        case COM_ALL_RECAP_DESCRIPTION_REQUEST:
            return {
                ...state,
                allRecapDescriptionReq: true,
            };

        case COM_ALL_RECAP_DESCRIPTION_SUCCESS:
            return {
                ...state,
                allRecapDescriptionData: action.payload,
            };
        case COM_ALL_RECAP_DESCRIPTION_ERROR:
            return {
                ...state,
                allRecapDescriptionError: action.error,
            };

        // StatsLeader Hitting
        case COM_STATS_LEADER_HITTING_SUCCESS:
            return {
                ...state,
                hittingStatsResponse: action.payload,
            };
        case COM_STATS_LEADER_HITTING_ERROR:
            return {
                ...state,
                hittingStatsError: action.error,
            };

        // StatsLeader Pitching
        case COM_STATS_LEADER_PITCHING_SUCCESS:
            return {
                ...state,
                pitchingStatsResponse: action.payload,
            };
        case COM_STATS_LEADER_PITCHING_ERROR:
            return {
                ...state,
                pitchingStatsError: action.error,
            };


        default:
            return state;
    }
}
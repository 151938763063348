import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import { imagesArr } from "../../../../assets/images";
import commonCalls from "../../../../businesslogic/commonCalls";
import Preloader from "../../../../components/preloader";
import { getEventDetails } from '../../../../saga/actions/common/eventDetails.action';
import moment from "moment";
class pwsHotelInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventId: "",
            loader: false,
            eventDetail: [],
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let eventId = url.pathname.split("/")[2];
        this.setState({
            eventId: eventId
        }, () => {
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventDetails &&
            nextProps.eventDetails.getDetailsReq &&
            nextProps.eventDetails.getDetailsData
        ) {
            nextProps.eventDetails.getDetailsReq = false;
            if (nextProps.eventDetails.getDetailsData.flag) {
                this.setState({
                    eventDetail: nextProps.eventDetails.getDetailsData.result.data[0]
                }, () => {
                    nextProps.eventDetails.getDetailsData = null;
                    this.setLoader(false);
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventDetails.getDetailsData.message);
                nextProps.eventDetails.getDetailsData = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        this.props.getEventDetails({ eventId: this.state.eventId }, false);
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    renderHotels = () => {
        return (
            this.state.eventDetail &&
                this.state.eventDetail.hotels &&
                this.state.eventDetail.hotels.length !== 0 ?
                this.state.eventDetail.hotels.map((item, i) => {
                    return (
                        <div className="hotel-list" key={i}>
                            <div className="hotel-view-img">
                                <img src="https://artoon-taxuz.s3.amazonaws.com/7/b7c3fd18ba90aec6eb05d11e6c942743.jpg" className="image" alt="hotel" />
                                <div class="overlay">
                                    <div class="search-icon"><img src={imagesArr.search_icon} /></div>
                                </div>
                            </div>
                            <div className="hotel-info">
                                <div className="hotel-name">
                                    <div>
                                        {/* <h2>Ritz Carlton Hotel</h2> */}
                                        <p className="map-location">
                                            <span>
                                                <img src={imagesArr.map_icon_aquamarine} />
                                            </span>
                                            {item.hotelAddress}
                                        </p>
                                    </div>
                                    <div>
                                        <a class="btn" href={item.link} target="_blank">Book it</a>
                                    </div>
                                </div>
                                {/* <div className="hotel-policy">
                                    <h4>Cancellation Policy</h4>
                                    <p>MUST CANCEL 48 HOURS PRIOR TO ARRIVAL DATE.</p>
                                    <div className="notes-info">
                                        <h4>Notes</h4>
                                        <p className="note-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui vel eu tempus sed a tortor dolor, arcu, dui. Turpis eget interdum nunc augue condimentum. Convallis nunc pellentesque augue aliquam, egestas odio nam elit tellus. Praesent magna turpis ullamcorper adipiscing enim faucibus mattis. Vestibulum nisl, vel eu a integer felis nibh sit arcu.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    render() {
        const { loader, eventDetail } = this.state;
        return (
            <div>
                <Header />
                <div className="hotelinfo-section event-main">
                    <div className="team-banner-section">
                        <img src={eventDetail && eventDetail.eventImages && eventDetail.eventImages.filePath ? eventDetail.eventImages.filePath : imagesArr.eventinfo_banner} alt="event banner" />
                        <div class="banner-details">
                            <div class="event_card_logo">
                                <img src={eventDetail && eventDetail.eventLogo && eventDetail.eventLogo.filePath ? eventDetail.eventLogo.filePath : imagesArr.event_info_bannerlogo} alt="event logo" />
                            </div>
                            <div class="event_card_titles">
                                <h3>{eventDetail.eventTitle}</h3>
                                <div class="event_card_dates">
                                    <p class="map_icon">{eventDetail && eventDetail.location && eventDetail.location.streetAddress}</p>
                                    <p class="date_icon">{eventDetail && moment(eventDetail.startDate).format("DD MMM") + " - " + moment(eventDetail.endDate).format("DD MMM")}</p>
                                    <p class="team_icons">{eventDetail && eventDetail.teamLimit} Teams</p>
                                </div>
                            </div>
                        </div>
                        <div className="arrow">
                            <img src={imagesArr.round_arrow} />
                        </div>
                        <div className="shap">
                            <img src={imagesArr.shap} />
                        </div>
                    </div>
                    <div className="hotels-list-section">
                        <h1 className="section_title">Hotels</h1>
                        <div className="container">
                            {this.renderHotels()}
                        </div>
                    </div>
                </div>
                <Footer />
                <Preloader flag={loader} />
            </div>
        )
    }
}

pwsHotelInfo.propTypes = {
    getEventDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventDetails: state.eventDetails,
});
var pwsHotelInfoComponent = connect(mapStateToProps, { getEventDetails })(pwsHotelInfo);
export default withRouter(pwsHotelInfoComponent);

import axios from 'axios';
import moment from "moment";
import { Auth_Company_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken } from '../../helpers/authToken';
import {
    COM_FEATURE_LEAGUES_REQUEST,
    COM_FEATURE_LEAGUES_SUCCESS,
    COM_FEATURE_LEAGUES_ERROR,

    COM_UP_COMING_LEAGUES_REQUEST,
    COM_UP_COMING_LEAGUES_SUCCESS,
    COM_UP_COMING_LEAGUES_ERROR,

    COM_OPTION_LEAGUES_REQUEST,
    COM_OPTION_LEAGUES_SUCCESS,
    COM_OPTION_LEAGUES_ERROR,

    COM_LEAGUES_STATE_REQUEST,
    COM_LEAGUES_STATE_SUCCESS,
    COM_LEAGUES_STATE_ERROR,
} from '../../constants/common/leagues.const';
import histrory from '../../../History';

export const featuredComLeague = (data) => dispatch => {
    if (data.startDate !== "" && data.endDate !== "") {
        data.startDate = moment.utc(moment(data.startDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
        data.endDate = moment.utc(moment(data.endDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString();
    }

    dispatch({ type: COM_FEATURE_LEAGUES_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/web/company/live`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(featureLeague => {
        console.log("featureLeague:--", featureLeague);
        dispatch({
            type: COM_FEATURE_LEAGUES_SUCCESS,
            payload: featureLeague.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_FEATURE_LEAGUES_ERROR, error: error.response.data.message })
        }
    });
};

export const upcomingComLeagues = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_UP_COMING_LEAGUES_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/web/company/live`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(upcomingLeague => {
        console.log("upcomingLeague:--", upcomingLeague);
        dispatch({
            type: COM_UP_COMING_LEAGUES_SUCCESS,
            payload: upcomingLeague.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_UP_COMING_LEAGUES_ERROR, error: error.response.data.message })
        }
    });
};

export const leaguesOptionList = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_OPTION_LEAGUES_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}league/list`,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allLeagues => {
        dispatch({
            type: COM_OPTION_LEAGUES_SUCCESS,
            payload: allLeagues.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_OPTION_LEAGUES_ERROR, error: error.response.data.message })
        }
    });
};

export const leaguesStateOption = (data) => dispatch => {
    console.log("data:--", data);
    dispatch({ type: COM_LEAGUES_STATE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/state/list`,
        data: data,
        headers: {
            'Accept': 'application/json'
        }
    }).then(allState => {
        dispatch({
            type: COM_LEAGUES_STATE_SUCCESS,
            payload: allState.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: COM_LEAGUES_STATE_ERROR, error: error.response.data.message })
        }
    });
};



import axios from 'axios';
import { Auth_Company_Key, Auth_Coach_Key, Auth_Player_Key,Auth_Fan_Key, EVENT_API_Url, Authorization, checkIsValidCompanyToken, Auth_Login_Key } from '../../helpers/authToken';
import {
    GET_EVENT_DETAILS_REQUEST,
    GET_EVENT_DETAILS_SUCCESS,
    GET_EVENT_DETAILS_ERROR,

    GET_PARTICIPATION_EVENT_REQUEST,
    GET_PARTICIPATION_EVENT_SUCCESS,
    GET_PARTICIPATION_EVENT_ERROR,

    GET_ROSTER_GROUP_LIST_REQUEST,
    GET_ROSTER_GROUP_LIST_SUCCESS,
    GET_ROSTER_GROUP_LIST_ERROR,

    FETCH_PAYMENT_INTENT_REQUEST,
    FETCH_PAYMENT_INTENT_SUCCESS,
    FETCH_PAYMENT_INTENT_ERROR,

    EVENT_REGISTRATION_PAYMENT_REQUEST,
    EVENT_REGISTRATION_PAYMENT_SUCCESS,
    EVENT_REGISTRATION_PAYMENT_ERROR,

    EVENT_PAYMENT_COMPLETE_REQUEST,
    EVENT_PAYMENT_COMPLETE_SUCCESS,
    EVENT_PAYMENT_COMPLETE_ERROR,

    GET_TEAM_AGE_VEFICATION_REQUEST,
    GET_TEAM_AGE_VEFICATION_SUCCESS,
    GET_TEAM_AGE_VEFICATION_ERROR,

    GET_ROSTER_AGE_AMOUNT_REQUEST,
    GET_ROSTER_AGE_AMOUNT_SUCCESS,
    GET_ROSTER_AGE_AMOUNT_ERROR,

    SET_EVENT_REGI_AGE_GROUP_REQUEST,
    SET_EVENT_REGI_AGE_GROUP_SUCCESS,
    SET_EVENT_REGI_AGE_GROUP_ERROR,

    GET_COUPAN_CODE_AMOUNT_REQUEST,
    GET_COUPAN_CODE_AMOUNT_SUCCESS,
    GET_COUPAN_CODE_AMOUNT_ERROR,

    GET_LEAGUE_COUPAN_CODE_AMOUNT_REQUEST,
    GET_LEAGUE_COUPAN_CODE_AMOUNT_SUCCESS,
    GET_LEAGUE_COUPAN_CODE_AMOUNT_ERROR,

    GET_INSTALLMENT_DETAILS_REQUEST,
    GET_INSTALLMENT_DETAILS_SUCCESS,
    GET_INSTALLMENT_DETAILS_ERROR,

    EVENT_INSTALLMENT_PAYMENT_REQUEST,
    EVENT_INSTALLMENT_PAYMENT_SUCCESS,
    EVENT_INSTALLMENT_PAYMENT_ERROR,
    
    GET_MULTIPLE_EVENT_SUCCESS,
    GET_MULTIPLE_EVENT_ERROR,

    MULTIPLE_EVENT_PAYMENT_SUCCESS,
    MULTIPLE_EVENT_PAYMENT_ERROR,

    BAY_SCOUT_PACKAGE_SUCCESS,
    BAY_SCOUT_PACKAGE_ERROR,

    DOWNLOAD_SCOUT_PACKAGE_SUCCESS,
    DOWNLOAD_SCOUT_PACKAGE_ERROR,

    GET_EVENT_RULE_SUCCESS,
    GET_EVENT_RULE_ERROR,
    GET_EVENT_LINKED_PLAYER_LIST_REQ,
    GET_EVENT_LINKED_PLAYER_LIST_SUCCESS,
    GET_EVENT_LINKED_PLAYER_LIST_ERROR,
    GET_COST_BY_AGE_REQUEST,
    GET_COST_BY_AGE_SUCCESS,
    GET_COST_BY_AGE_ERROR,
} from '../../constants/common/eventDetails.const';
import histrory from '../../../History';

export const rendLeagueCoupanCode = (data) => dispatch => {
    dispatch({ type: GET_LEAGUE_COUPAN_CODE_AMOUNT_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}league/coupon/price`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(getLeagueCoupanCode => {
        dispatch({
            type: GET_LEAGUE_COUPAN_CODE_AMOUNT_SUCCESS,
            payload: getLeagueCoupanCode.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_LEAGUE_COUPAN_CODE_AMOUNT_ERROR, error: error.response.data.message })
        }
    });
};

export const rendCoupanCode = (data) => dispatch => {
    dispatch({ type: GET_COUPAN_CODE_AMOUNT_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/coupon/price`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(getCoupanCode => {
        dispatch({
            type: GET_COUPAN_CODE_AMOUNT_SUCCESS,
            payload: getCoupanCode.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_COUPAN_CODE_AMOUNT_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventRegistrationAge = (data) => dispatch => {
    dispatch({ type: SET_EVENT_REGI_AGE_GROUP_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/cost/find/byage`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(getRosterOpt => {
        dispatch({
            type: SET_EVENT_REGI_AGE_GROUP_SUCCESS,
            payload:data.hasOwnProperty("playerId") ? {...getRosterOpt.data,isNext:true}: getRosterOpt.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: SET_EVENT_REGI_AGE_GROUP_ERROR, error: error.response.data.message })
        }
    });
};

export const getCostByAge = (data) => dispatch => {
    dispatch({ type: GET_COST_BY_AGE_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/v2/cost/find/byage`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(getRosterOpt => {
        dispatch({
            type: GET_COST_BY_AGE_SUCCESS,
            payload: getRosterOpt.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_COST_BY_AGE_ERROR, error: error.response.data.message })
        }
    });
};

export const getEventDetails = (data, type) => dispatch => {
    console.log("data:--", data);
    let roleToken = localStorage.getItem("role") === "Parent" ? localStorage.getItem("fanToken") :Auth_Company_Key
    if (type) {
        checkIsValidCompanyToken(histrory);
        dispatch({ type: GET_EVENT_DETAILS_REQUEST })
        axios({
            method: 'GET',
            url: `${EVENT_API_Url}event/v2?id=${data}`,
            headers: {
                'x-auth-token': roleToken,
                'Accept': 'application/json'
            }
        }).then(details => {
            dispatch({
                type: GET_EVENT_DETAILS_SUCCESS,
                payload: details.data
            })
        }).catch((error) => {
            if (error.response) {
                console.log("error.response:--", error.response);
                dispatch({ type: GET_EVENT_DETAILS_ERROR, error: error.response.data.message })
            }
        });
    } else {
        dispatch({ type: GET_EVENT_DETAILS_REQUEST })
        axios({
            method: 'POST',
            url: `${EVENT_API_Url}event/getEventById`,
            data: data,
            headers: {
                'Accept': 'application/json'
            }
        }).then(details => {
            dispatch({
                type: GET_EVENT_DETAILS_SUCCESS,
                payload: details.data
            })
        }).catch((error) => {
            if (error.response) {
                console.log("error.response:--", error.response);
                dispatch({ type: GET_EVENT_DETAILS_ERROR, error: error.response.data.message })
            }
        });
    }
};

export const getParticipating = (data) => dispatch => {
    console.log("data:--", data);
    // checkIsValidCompanyToken(histrory);
    dispatch({ type: GET_PARTICIPATION_EVENT_REQUEST })
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}event/participateTeam?id=${data}`,
        headers: {
            // 'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(plist => {
        dispatch({
            type: GET_PARTICIPATION_EVENT_SUCCESS,
            payload: plist.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_PARTICIPATION_EVENT_ERROR, error: error.response.data.message })
        }
    });
};

export const getRosterOption = (data) => dispatch => {
    dispatch({ type: GET_ROSTER_GROUP_LIST_REQUEST })
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}bc/coach/rostergroup/list`,
        data: data,
        headers: {
            'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    }).then(getRosterOpt => {
        dispatch({
            type: GET_ROSTER_GROUP_LIST_SUCCESS,
            payload: getRosterOpt.data
        })
    }).catch((error) => {
        if (error.response) {
            console.log("error.response:--", error.response);
            dispatch({ type: GET_ROSTER_GROUP_LIST_ERROR, error: error.response.data.message })
        }
    });
};

export const fetchPaymentIntent = (data) => (dispatch) => {
    dispatch({ type: FETCH_PAYMENT_INTENT_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}payment/create-payment-intent`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((intent) => {
        dispatch({
            type: FETCH_PAYMENT_INTENT_SUCCESS,
            payload: intent.data,
        });
    }).catch((error) => {
        dispatch({ type: FETCH_PAYMENT_INTENT_ERROR, error: error });
    });
};

export const eventRegistrationPayment = (data) => (dispatch) => {
    dispatch({ type: EVENT_REGISTRATION_PAYMENT_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/v2/registration`,
        data: data,
        headers: {
            "x-auth-token": Auth_Login_Key,
            Accept: "application/json",
        },
    }).then((payment) => {
        console.log("payment action:--", payment);
        dispatch({
            type: EVENT_REGISTRATION_PAYMENT_SUCCESS,
            payload: payment.data,
        });
    }).catch((error) => {
        dispatch({ type: EVENT_REGISTRATION_PAYMENT_ERROR, error: error });
    });
};

export const eventPaymentComplete = (data) => (dispatch) => {
    dispatch({ type: EVENT_PAYMENT_COMPLETE_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/v2/payment/complete`,
        data: data,
        headers: {
            "x-auth-token": Auth_Login_Key,
            Accept: "application/json",
        },
    }).then((payComplete) => {
        console.log("payComplete action:--", payComplete);
        dispatch({
            type: EVENT_PAYMENT_COMPLETE_SUCCESS,
            payload: payComplete.data,
        });
    }).catch((error) => {
        dispatch({ type: EVENT_PAYMENT_COMPLETE_ERROR, error: error });
    });
};

export const getAgeVerification = (data) => (dispatch) => {
    dispatch({ type: GET_TEAM_AGE_VEFICATION_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}team/ageverification`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((ageVerify) => {
        console.log("ageVerify action:--", ageVerify);
        dispatch({
            type: GET_TEAM_AGE_VEFICATION_SUCCESS,
            payload: ageVerify.data,
        });
    }).catch((error) => {
        dispatch({ type: GET_TEAM_AGE_VEFICATION_ERROR, error: error });
    });
};

export const getRosterAmount = (data) => (dispatch) => {
    dispatch({ type: GET_ROSTER_AGE_AMOUNT_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/fee/roster`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((getAmount) => {
        console.log("getAmount action:--", getAmount);
        dispatch({
            type: GET_ROSTER_AGE_AMOUNT_SUCCESS,
            payload: getAmount.data,
        });
    }).catch((error) => {
        dispatch({ type: GET_ROSTER_AGE_AMOUNT_ERROR, error: error });
    });
};

export const getInstallmentDetails = (data) => (dispatch) => {
    dispatch({ type: GET_INSTALLMENT_DETAILS_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/v2/payment/installment/detail`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((getInst) => {
        console.log("getInst action:--", getInst);
        dispatch({
            type: GET_INSTALLMENT_DETAILS_SUCCESS,
            payload: getInst.data,
        });
    }).catch((error) => {
        dispatch({ type: GET_INSTALLMENT_DETAILS_ERROR, error: error });
    });
};

export const registerInstallment = (data) => (dispatch) => {
    dispatch({ type: EVENT_INSTALLMENT_PAYMENT_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/v2/installment/payment`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((payment) => {
        console.log("payment action:--", payment);
        dispatch({
            type: EVENT_INSTALLMENT_PAYMENT_SUCCESS,
            payload: payment.data,
        });
    }).catch((error) => {
        dispatch({ type: EVENT_INSTALLMENT_PAYMENT_ERROR, error: error });
    });
};

export const getMultipleEvent = (data) => (dispatch) => {
    // dispatch({ type: EVENT_INSTALLMENT_PAYMENT_REQUEST });
    checkIsValidCompanyToken(histrory);
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/v2/multiple/payment/detail`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((payment) => {
        console.log("payment action:--", payment);
        dispatch({
            type: GET_MULTIPLE_EVENT_SUCCESS,
            payload: payment.data,
        });
    }).catch((error) => {
        dispatch({ type: GET_MULTIPLE_EVENT_ERROR, error: error });
    });
};

export const multipleEventPayment = (data) => (dispatch) => {
    // dispatch({ type: EVENT_INSTALLMENT_PAYMENT_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}event/v2/multiple/payment`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((payment) => {
        console.log("payment action:--", payment);
        dispatch({
            type: MULTIPLE_EVENT_PAYMENT_SUCCESS,
            payload: payment.data,
        });
    }).catch((error) => {
        dispatch({ type: MULTIPLE_EVENT_PAYMENT_ERROR, error: error });
    });
};

export const buyScoutPackage = (data) => (dispatch) => {
    // dispatch({ type: EVENT_INSTALLMENT_PAYMENT_REQUEST });
    axios({
        method: "POST",
        url: `${EVENT_API_Url}payment/stripe/complete`,
        data: data,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((res) => {
        console.log("res action:--", res);
        dispatch({
            type: BAY_SCOUT_PACKAGE_SUCCESS,
            payload: res.data,
        });
    }).catch((error) => {
        dispatch({ type: BAY_SCOUT_PACKAGE_ERROR, error: error });
    });
};

export const downloadScoutPackage = (id) => (dispatch) => {
    // dispatch({ type: EVENT_INSTALLMENT_PAYMENT_REQUEST });
    axios({
        method: "GET",
        url: `${EVENT_API_Url}event/v2/digital/scout/packet/pdf?id=${id}`,
        headers: {
            "x-auth-token": Auth_Company_Key,
            Accept: "application/json",
        },
    }).then((res) => {
        console.log("res action:--", res);
        dispatch({
            type: DOWNLOAD_SCOUT_PACKAGE_SUCCESS,
            payload: res.data,
        });
    }).catch((error) => {
        dispatch({ type: DOWNLOAD_SCOUT_PACKAGE_ERROR, error: error });
    });
};

export const getEventRule = (id) => dispatch => {
    // checkIsValidCompanyToken(histrory)
    axios({
        method: 'GET',
        url: `${EVENT_API_Url}Company/get/termsAndCondition/${id}`,
        headers: {
            // 'x-auth-token': Auth_Company_Key,
            'Accept': 'application/json'
        }
    })

        .then(admins => {
            dispatch({
                type: GET_EVENT_RULE_SUCCESS,
                payload: admins.data.result
            })
        })
        .catch((error) => {
            if (error.response && error.response.status === 401)
                Authorization(histrory)
            dispatch({ type: GET_EVENT_RULE_ERROR, error: error })
        });
};

export const getEventLinkedPlayerList = (data) => dispatch => {
    dispatch({ type: GET_EVENT_LINKED_PLAYER_LIST_REQ })
    let role= localStorage.getItem("role")
    axios({
        method: 'POST',
        url: `${EVENT_API_Url}event/v2/linked/player/list`,
        data: data,
        headers: {
            'x-auth-token':role === "Parent"?Auth_Fan_Key: Auth_Coach_Key,
            'Accept': 'application/json'
        }
    }).then(getPlayer => {
        dispatch({
            type: GET_EVENT_LINKED_PLAYER_LIST_SUCCESS,
            payload: getPlayer.data
        })
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            Authorization(histrory)
            dispatch({ type: GET_EVENT_LINKED_PLAYER_LIST_ERROR, error: error })
        }
    });
};

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { imagesArr } from '../../../../assets/images';
import { topComEvents } from '../../../../saga/actions/common/events.action';
import commonCalls from "../../../../businesslogic/commonCalls";
import moment from "moment";
import { checkLoginValidation } from '../../../../saga/helpers/authToken';
import { getSumOfRegisterTeam, getAvailableSport } from "../../../../businesslogic/content";
class topEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topEvent: [],
            sendReq: {
                isPresent: 4,
                companies: window.location.pathname === "/dc-event" ? "" : localStorage.getItem("cid"),
                eventType: this.props.eventType,
                isCollegeShowcase: this.props.location.pathname === 'dc-collegeShowcase' ? true : false,
            }
        }

        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        if (localStorage.getItem("cid")) {
            this.props.increment();
            this.props.topComEvents(this.state.sendReq)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.eventsInfo &&
            nextProps.eventsInfo.topEventReq &&
            nextProps.eventsInfo.topEventData
        ) {
            nextProps.eventsInfo.topEventReq = false;
            console.log("nextProps.eventsInfo:--", nextProps.eventsInfo.topEventData);
            if (nextProps.eventsInfo.topEventData.flag) {
                this.setState({
                    topEvent: nextProps.eventsInfo.topEventData.result.data
                }, () => {
                    nextProps.eventsInfo.topEventData = null;
                    this.props.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.eventsInfo.topEventData.message);
                nextProps.eventsInfo.topEventData = null;
                this.props.decrement();
            }
        }
    }

    renderEvents = () => {
        return (
            this.state.topEvent.length !== 0 &&
            this.state.topEvent.map((list, i) => {
                const location = list.location && list.location.streetAddress.split(",");
                let svalid_date = moment().format("MM/DD/YYYY");
                let evalid_date = moment(list.regClosedDate).format("MM/DD/YYYY");
                let chkdate = moment(svalid_date).isBefore(evalid_date);
                return (
                    <div key={i} onClick={() => this.redirectDetails(list)}>
                        <div className="event_box">
                            <div className="event_card">
                                <div class="event_card_img">
                                    <img src={list.eventImages && list.eventImages.filePath} alt="event" />
                                    {

                                        (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                        <span className="event_card_address">
                                            {list.location &&
                                                list.location.city ?
                                                list.location.city :
                                                list.location.streetAddress ?
                                                    location[0] :
                                                    list.location.stateRegion}
                                        </span>
                                    }
                                </div>
                                {
                                    // !chkdate &&
                                    <div class="event_tags text-right">
                                        {list.regCountDown !== "Registration Closed" ? 'Registration Closes In ' : ""}<span>{list.regCountDown}</span>
                                    </div>
                                }
                                <div class="event_card_desc">
                                    <div class="event_card_logo">
                                        <img src={list.eventLogo && list.eventLogo.filePath ? list.eventLogo.filePath : imagesArr.default_user} alt="event logo" />
                                    </div>
                                    <div class="event_card_titles">
                                        <h3>{list.eventTitle}</h3>
                                        <div class="event_card_dates">
                                            <p class="date_icon">{moment(list.startDate).format("DD-MMM-YYYY")} - {moment(list.endDate).format("DD-MMM-YYYY")}</p>
                                            {list?.showSpotAvail ?
                                                <>
                                                    <p class="team_icons">{list.feesByGroups.length ? getSumOfRegisterTeam(list.feesByGroups) : 0} {list.eventTeamType === 1 ? 'Teams Registered' : 'Players Registered'}</p>
                                                    <p class="team_icons">{list.feesByGroups.length ? getAvailableSport(list.feesByGroups) : 0} Available Spots</p>
                                                </>
                                                :
                                                <p class="team_icons showSpotAvail"> {!chkdate ? "Registration Closed" : "Registration Open"}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    redirectDetails = (data) => {
        this.props.history.push(`/event-detail/${data._id}`);
    }

    render() {
        var ballrslider = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        return (
            <>
                <div className="topevent_section">
                    <div className="container">
                        <div className="event_slider">
                            <h1 className="section_title">Top Events</h1>
                            <div className="all_event">
                                <Slider {...ballrslider}>
                                    {this.renderEvents()}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

topEvents.propTypes = {
    topComEvents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    eventsInfo: state.eventsInfo,
});
var topEventComponent = connect(mapStateToProps, { topComEvents })(topEvents);
export default withRouter(topEventComponent);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Slider from "react-slick";
import { getSumOfRegisterTeam, getAvailableSport } from "../../../businesslogic/content";
import Confirmation from '../../popup/confirmation';
import PayBalance from '../../popup/payBalance';
import { leaveEvent } from '../../../saga/actions/common/api.action';
import { LEAVE_EVENT_SUCCESS } from '../../../saga/constants/common/api.const';
import commonCalls from "../../../businesslogic/commonCalls";

var ballrslider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            }
        }
    ],
};
const EventSlider = ({ eventList, redirectDetails, eventType, setLoader, renderEventDash }) => {
    const [display, setDisplay] = useState(true)
    const [confirm, setConfirm] = useState(false)
    const [finalMsg, setFinalMsg] = useState(false)
    const [payPopUp, setPayPopUp] = useState(false)
    const [name, setName] = useState("")
    const [eventId, setEventId] = useState("")
    const [remainDetail, setRemainDetail] = useState(null)
    const [rosterName, setRosterName] = useState("")
    const dispatch = useDispatch()
    const { getLeaveEventData: _LE } = useSelector((state) => state.api);
    const commonCall = new commonCalls();

    useEffect(() => {
        setDisplay(false)
    }, [eventList])

    useEffect(() => {
        if (!display) setDisplay(true)
    }, [display])

    useEffect(() => {
        if (_LE) {
            console.log("_LE:--", _LE);
            if (_LE.flag) {
                setLoader(false);
                setConfirm(false);
                setFinalMsg(true);
            } else {
                commonCall.errorMessage(_LE.message)
                setLoader(false);
            }
            dispatch({
                type: LEAVE_EVENT_SUCCESS,
                payload: null,
            });

        }
    }, [_LE])

    if (!display) return null

    const setOnLeaveEvent = (list) => {
        setName(list.eventTitle);
        setConfirm(true);
    }

    const onHandleChange = (e, list) => {
        const { value } = e.target;
        setRosterName(value)
        setEventId(list._id)
        setRemainDetail(list.teams.find(_ => _._id === value));
    }

    const closeConfirm = () => {
        document.body.classList.remove('modal-open');
        setConfirm(false);
    }

    const confirmNext = () => {
        setLoader(true);
        leaveEvent(rosterName)(dispatch);
    }

    const closeFinalMsg = () => {
        document.body.classList.remove('modal-open');
        setFinalMsg(false);
        setName("");
        setRosterName("");
        renderEventDash();
    }


    const payBalancePopUp = () =>{
        setPayPopUp(!payPopUp)
    }

    const closePayBalancePopUp = () =>{
        document.body.classList.remove('modal-open');
        setPayPopUp(!payPopUp)
    }

    const remainingAmt = (roster) => {
        let isRemaining = false;
        roster.map(_ => {
            if (_?.remainingAmt !== 0 && _?.remainingAmt !== undefined) {
                isRemaining = true
            } else {
                isRemaining = false
            }
        })
        return isRemaining
    }

    return (
        <>
            <Slider {...ballrslider}>
                {eventList.length !== 0 &&
                    eventList.map((e, item) => {
                        const location = e.location && e.location.streetAddress.split(",");
                        let svalid_date = moment().format("MM/DD/YYYY");
                        let evalid_date = moment(e.regClosedDate).format("MM/DD/YYYY");
                        let chkdate = moment(svalid_date).isBefore(evalid_date);
                        return (
                            <div key={item}>
                                <div className="event_box">
                                    <div className="event_card">
                                        {
                                            e.invited &&
                                            <div className="black-layer" onClick={() => redirectDetails(e)}>
                                                <p>You haven't fully registered for this event. Click here to finish registration now.</p>
                                            </div>
                                        }
                                        <div class="event_card_img" onClick={() => redirectDetails(e)}>
                                            <img src={e.eventImages && e.eventImages.filePath ? e.eventImages.filePath : "https://diamondconnect.s3.us-east-2.amazonaws.com/1/c51257cb3f79268ae73e34e3f97903f4.png"} alt="event" />
                                            {

                                                (e.location && (e.location.city || e.location.streetAddress || e.location.stateRegion)) &&
                                                <span className="event_card_address">
                                                    {e.location &&
                                                        e.location.city ?
                                                        e.location.city :
                                                        e.location.streetAddress ?
                                                            location[0] :
                                                            e.location.stateRegion}
                                                </span>
                                            }
                                        </div>
                                        {/* {!chkdate && */}
                                        <div class="event_tags text-right">
                                            {e.regCountDown !== "Registration Closed" ? 'Registration Closes In ' : ""}<span>{e.regCountDown}</span>
                                        </div>
                                        {/* } */}
                                        <div class="event_card_desc">
                                            <div class="event_card_logo" onClick={() => redirectDetails(e)}>
                                                <img src={e.eventLogo && e.eventLogo.filePath ? e.eventLogo.filePath : "https://diamondconnect.s3.us-east-2.amazonaws.com/1/c51257cb3f79268ae73e34e3f97903f4.png"} alt="event logo" />
                                            </div>
                                            <div class="event_card_titles">
                                                <h3 onClick={() => redirectDetails(e)}>{e.eventTitle}</h3>
                                                <div class="event_card_dates" onClick={() => redirectDetails(e)}>
                                                    <p class="date_icon">{moment(e.startDate).format("DD-MMM-YYYY")} - {moment(e.endDate).format("DD-MMM-YYYY")}</p>
                                                    <p class="team_icons">{e.feesByGroups.length ? getSumOfRegisterTeam(e.feesByGroups) : 0} {e.eventTeamType === 1 ? 'Teams Registered' : 'Players Registered'}</p>
                                                    <p class="team_icons">{e.feesByGroups.length ? getAvailableSport(e.feesByGroups) : 0} Available Spots</p>
                                                </div>
                                                {
                                                    // eventType === 1 &&
                                                    // (chkdate || remainingAmt(e.teams)) &&
                                                    <div className="roster-sign-up">
                                                        <div>
                                                            <div className="event-roster-select">
                                                                <select className="custom-select" name="rosterName" value={rosterName} onChange={(ev) => onHandleChange(ev, e)}>
                                                                    <option value="">Rosters signed up</option>
                                                                    {
                                                                        e.teams &&
                                                                        e.teams.map((list) => {
                                                                            return (
                                                                                <option value={list._id}>{list.rosterTeamId.rosterName + " " + (list.ageGroup ? list.ageGroup + "U" + " - " + list.ageGroupTo + "U" : "")}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {
                                                                rosterName && eventId === e._id &&
                                                                <div class="leave-event">
                                                                    {remainDetail?.remainingAmt ? remainDetail?.remainingAmt !== 0 ? <button type="button" className="btn" onClick={payBalancePopUp}>Pay Balance</button> : "" : ""}
                                                                    {
                                                                    //  chkdate &&
                                                                      <button type="button" className="btn" onClick={() => setOnLeaveEvent(e)}>Leave Event</button>}
                                                                </div>
                                                            }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </Slider>
            {eventList.length === 0 &&
                <p className="text-center mb-0">You’re not registered for any events yet. <a href="#">Download</a> Diamond Connect to find events near you</p>
            }

            {
                confirm &&
                <Confirmation
                    flag={confirm}
                    type="isLeaveEventConfirm"
                    close={closeConfirm}
                    confirm={confirmNext}
                />
            }
            {
                finalMsg &&
                <Confirmation
                    flag={finalMsg}
                    type="isFinalMsg"
                    eventName={name}
                    close={closeFinalMsg}
                />
            }
            {
                payPopUp &&
                <PayBalance
                    flag={payPopUp}
                    close={closePayBalancePopUp}
                    remainDetail={remainDetail}
                    eventId={eventId}
                    // applyCode={this.applyCode}
                />
            }
        </>
    )
}

export default EventSlider
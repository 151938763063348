import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../containers/header";
import Footer from "../../../containers/footer";
import { useDispatch, useSelector } from 'react-redux';
import { imagesArr } from '../../../assets/images';
import Preloader from '../../../components/preloader';
import { getPlayerTeam, getScoutAwardTabDetail, getTeamScoutFeed } from '../../../saga/actions/views/company/pwRanking/teamProfile.action';
import moment from "moment";
import { getSumOfRegisterTeam, getAvailableSport } from "../../../businesslogic/content";
import Slider from "react-slick";
import PurchaseProspectwire from './../../popup/purchaseProspectwire';
import ReactPaginate from "react-paginate";
import PwLock from "../../company/components/common/pwLock";
import ReactTooltip from 'react-tooltip';


class teamProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sendReq: {
                id: "",
                companyId: localStorage.getItem("cid")
            },
            loaderCount: 0,
            teamData: [],
            playerList: [],
            awardsList: [],
            tab: 1,
            purchase: false,
            packType: "PW",
            scoutTab: 1,
            scoutTabReq: {
                teamId: "",
                page: 0,
                limit: 0,
                companyId: localStorage.getItem("cid"),
                tab: "scout-feed"
            },
            messageReq: {
                id: "",
                page: 1,
                limit: 0,
                companyId: localStorage.getItem("cid"),
                teamId: ""
            },
            scoutDetails: null,
            eventInfo: "",
            isScroll: false,
            scouting: [],
            pageCount: "",
            eventList: [],
            awardsList: [],
            mentionRecap: []
        }
    }

    componentDidMount = () => {
        this.getTeamList();
        this.getTabDetails();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.teamProfileInfo &&
            nextProps.teamProfileInfo.getPlayerTeamReq &&
            nextProps.teamProfileInfo.getPlayerTeamData
        ) {
            nextProps.teamProfileInfo.getPlayerTeamReq = false;
            console.log(" nextProps.teamProfileInfo.getPlayerTeamData:--", nextProps.teamProfileInfo.getPlayerTeamData);
            if (nextProps.teamProfileInfo.getPlayerTeamData.flag) {
                this.setState({
                    teamData: nextProps.teamProfileInfo.getPlayerTeamData.result,
                    playerList: nextProps.teamProfileInfo.getPlayerTeamData.result.playerInfo
                }, () => {
                    nextProps.teamProfileInfo.getPlayerTeamData = null;
                    this.decrement();
                })
            } else {
                this.commonCall.errorMessage(nextProps.teamProfileInfo.getPlayerTeamData.message);
                nextProps.teamProfileInfo.getPlayerTeamData = null;
                this.decrement();
            }
        }

        if (nextProps.teamProfileInfo &&
            nextProps.teamProfileInfo.getScoutAwardTabReq &&
            nextProps.teamProfileInfo.getScoutAwardTabSuc
        ) {
            nextProps.teamProfileInfo.getScoutAwardTabReq = false;
            console.log(" nextProps.teamProfileInfo.getScoutAwardTabSuc:--", nextProps.teamProfileInfo.getScoutAwardTabSuc);
            if (nextProps.teamProfileInfo.getScoutAwardTabSuc.flag) {
                if (this.state.scoutTabReq.tab === "scout-feed") {
                    this.setState({
                        eventInfo: nextProps.teamProfileInfo.getScoutAwardTabSuc.result.docs[this.state.scoutTabReq.page],
                        pageCount: nextProps.teamProfileInfo.getScoutAwardTabSuc.result.total,
                        eventList: nextProps.teamProfileInfo.getScoutAwardTabSuc.result.docs,
                        mentionRecap: nextProps.teamProfileInfo.getScoutAwardTabSuc.result.recap,
                    }, () => {
                        if (nextProps.teamProfileInfo.getScoutAwardTabSuc.result.docs.length) {
                            this.setState({
                                ...this.state,
                                messageReq: {
                                    ...this.state.messageReq,
                                    id: nextProps.teamProfileInfo.getScoutAwardTabSuc.result.docs[this.state.scoutTabReq.page]._id
                                }
                            }, () => {
                                this.increment();
                                this.props.getTeamScoutFeed(this.state.messageReq);
                            })
                        }
                        nextProps.teamProfileInfo.getScoutAwardTabSuc = null;
                        this.decrement();
                    })
                } else {
                    this.setState({
                        awardsList: nextProps.teamProfileInfo.getScoutAwardTabSuc.result.docs,
                    })
                    this.decrement();
                    nextProps.teamProfileInfo.getScoutAwardTabSuc = null;
                }
            } else {
                this.commonCall.errorMessage(nextProps.teamProfileInfo.getScoutAwardTabSuc.message);
                nextProps.teamProfileInfo.getScoutAwardTabSuc = null;
                this.decrement();
            }
        }


        if (nextProps.teamProfileInfo &&
            nextProps.teamProfileInfo.getTeamScoutFeedReq &&
            nextProps.teamProfileInfo.getTeamScoutFeedSuc
        ) {
            nextProps.teamProfileInfo.getTeamScoutFeedReq = false;
            console.log(" nextProps.teamProfileInfo.getTeamScoutFeedSuc:--", nextProps.teamProfileInfo.getTeamScoutFeedSuc);
            if (nextProps.teamProfileInfo.getTeamScoutFeedSuc.flag) {
                let listData = nextProps.teamProfileInfo.getTeamScoutFeedSuc.result.docs;
                if (this.state.isScroll) {
                    let temp = [];

                    listData.map((item) => {
                        temp.push(item)
                    });
                    var joined = this.state.scouting.concat(temp);
                    if (listData.length !== 0) {
                        this.setState({
                            scouting: joined,
                        }, () => {
                            nextProps.teamProfileInfo.getTeamScoutFeedSuc = null;
                            this.decrement();
                        })
                        this.decrement();
                    } else {
                        nextProps.teamProfileInfo.getTeamScoutFeedSuc = null;
                        this.decrement();
                    }
                } else {
                    this.setState({
                        scouting: listData,
                    }, () => {
                        nextProps.teamProfileInfo.getTeamScoutFeedSuc = null;
                        this.decrement();
                    })
                }
            } else {
                this.commonCall.errorMessage(nextProps.teamProfileInfo.getTeamScoutFeedSuc.message);
                nextProps.teamProfileInfo.getTeamScoutFeedSuc = null;
                this.decrement();
            }
        }
    }

    renderPitchingHead = () => {
        return (
            this?.state?.teamData?.stats?.pitchingkeys?.map((th, i) => {
                return (
                    <th>{th === "player" ? "Pitchers" : th}</th>
                )
            })
        )
    }

    renderPitchingData = () => {
        return (
            this?.state?.teamData?.stats?.pitching.length !== 0 ?
                this?.state?.teamData?.stats?.pitching?.map((td, i) => {
                    return (
                        <tr>
                            {
                                this?.state?.teamData?.stats?.pitchingkeys?.map((th, i) => {
                                    return (
                                        <td>{th !== "player" ? td[th] : td[th] !== null ? td[th].fname + " " + td[th].lname : "-"}</td>
                                    )
                                })
                            }
                        </tr>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    renderBattingHead = () => {
        return (
            this?.state?.teamData?.stats?.battingkeys?.map((th, i) => {
                return (
                    <th>{th === "player" ? "Hitters" : th}</th>
                )
            })
        )
    }

    renderBattingData = () => {
        return (
            this?.state?.teamData?.stats?.batting.length !== 0 ?
                this?.state?.teamData?.stats?.batting?.map((td, i) => {
                    return (
                        <tr>
                            {
                                this?.state?.teamData?.stats?.battingkeys?.map((th, i) => {
                                    return (
                                        <td>{th !== "player" ? td[th] : td[th] !== null ? td[th].fname + " " + td[th].lname : "-"}</td>
                                    )
                                })
                            }
                        </tr>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }

    getTeamList = () => {
        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];

        this.setState((prevState) => ({
            ...prevState,
            sendReq: {
                ...prevState.sendReq,
                id: pid
            }
        }), () => {
            this.increment();
            this.props.getPlayerTeam(this.state.sendReq);
        });
    }

    getTabDetails = () => {
        let url = new URL(document.location);
        let pid = url.pathname.split("/")[2];

        this.setState((prevState) => ({
            ...prevState,
            scoutTabReq: {
                ...prevState.scoutTabReq,
                teamId: pid
            },
            messageReq: {
                ...prevState.messageReq,
                teamId: pid
            }
        }), () => {
            this.increment();
            this.props.getScoutAwardTabDetail(this.state.scoutTabReq);
        });
    }

    increment = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { loaderCount: prevState.loaderCount - 1 }
        })
    }


    renderPlayer = () => {
        return (
            this.state.playerList &&
                this.state.playerList.length !== 0 ?
                this.state.playerList.map((item, i) => {
                    return (
                        <tr>
                            <td>
                                <div>
                                    <img className="user-img" src={item.profilePic ? item.profilePic : imagesArr.default_user} alt="event logo" />
                                </div>
                            </td>
                            <td>{item.fname ? item.fname + " " + item.lname : "-"}</td>
                            <td>{item.rosterName ? item.rosterName : "-"}</td>
                            <td>
                                {item.position &&
                                    item.position.length !== 0 ?
                                    item.position.map((pos) => (
                                        pos
                                    )).reduce((prev, curr) => [prev, ", ", curr])
                                    : "-"
                                }
                            </td>
                            {/* <td>number</td> */}
                            <td>{item.height ? item.height : "-"}</td>
                            <td>{item.weight ? item.weight : "-"}</td>
                            <td>{item.gradeYear ? item.gradeYear : "-"}</td>
                            <td>{item.TeamName ? item.TeamName : "-"}</td>
                            <td>{item.GPA ? item.GPA : "-"}</td>
                            <td>{item.SAT ? item.SAT : "-"}</td>
                            <td>{item.ACT ? item.ACT : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colspan="11">
                        <p className="text-center">No data found</p>
                    </td>
                </tr>
        )
    }

    purchasePW = (type) => {
        this.setState({
            purchase: true,
            packType: type
        })
    }

    handlePurchaseClose = () => {
        this.setState({
            purchase: false,
        }, () => {
            document.body.classList.remove("modal-open");
        })
    }


    renderMessage = () => {
        return (
            this.state.scouting.length !== 0 ?
                this.state.scouting.map((item, i) => {
                    return (
                        <div className="media" key={i}>
                            <img src={item?.sId?.profileUrl ? item.sId.profileUrl : imagesArr.default_user} alt="media" />
                            <div className="media-contant">
                                <h4>{item?.sId ? item.sId.fname + " " + item.sId.lname : ""}</h4>
                                <div className="media-type">

                                    {
                                        item.file &&
                                            item.mTy === 2 ?
                                            <div className="media-img">
                                                <img src={item.file.filePath} alt="team logo" onClick={() => this.redirectLink(item.file.filePath)} />
                                            </div>
                                            :
                                            item.mTy === 3 ?
                                                <audio controls>
                                                    <source src={item.file.filePath} type="audio/ogg" />
                                                </audio>
                                                : item.mTy === 6 ?
                                                    <div className="media-file">
                                                        <div className="file-formate" onClick={() => this.redirectLink(item.file.filePath)} >
                                                            <div className="file-icon">
                                                                <img src={imagesArr.pdf_icon} alt="media" />
                                                            </div>
                                                            <div className="file-name">
                                                                <h4>{this.getExtantion(item.file.filePath)}</h4>
                                                                <p>{item.file.size} KB</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : item.mTy === 4 ?
                                                        <div>
                                                            <video src={item.file.filePath} type="video" controls style={{ width: "100%", height: "200px" }}></video>
                                                        </div>
                                                        : ""
                                    }
                                    {
                                        item.msg &&
                                        // <p className="text">{this.mentionText(item.msg)}</p>
                                        <p className="text" dangerouslySetInnerHTML={{
                                            __html: this.mentionText(item.msg)
                                        }}></p>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }


    renderAward = () => {
        return (
            this.state.awardsList.length !== 0 ?
                this.state.awardsList.map((item, i) => {
                    return (
                        <tr>
                            <td>{item.event ? item.event.eventTitle : "-"}</td>
                            <td>{moment(item.awardDate).format("MM/DD/YYYY")}</td>
                            <td>{item?.award?.name} {item?.award?.awardIcon ? <img src={item.award.awardIcon.filePath} width="120px" /> : "-"}</td>
                        </tr>
                    )
                })
                :
                <tr>
                    <td colSpan="4">
                        <p className="text-center m-0">No data found</p>
                    </td>
                </tr>
        )
    }

    changeScoutTab = (type) => {
        this.setState({
            ...this.state,
            scoutTabReq: {
                ...this.state.scoutTabReq,
                tab: type
            },
            messageReq: {
                ...this.state.messageReq,
                id: "",
                page: 1,
                limit: 0,
            },
            eventInfo: "",
            isScroll: false,
            scouting: [],
            pageCount: "",
            eventList: [],
        }, () => {
            this.increment();
            this.props.getScoutAwardTabDetail(this.state.scoutTabReq);
        })
    }

    redirectLink = (link) => {
        window.open(link, '_blank')
    }

    getExtantion = (file) => {
        var fileExtension = file.split('/').pop();
        return fileExtension;
    }

    mentionText = (text) => {
        // let str = text.replace(/\</g, "&lt;b&gt;").replace(/\>/g, "&lt;/b&gt;");
        // let finalText = "";
        // if (text.split("<")[1]) {
        //     finalText = "<b>" + text.split("<")[1].split(">")[0] + "</b>" + "&nbsp;" + text.split("<")[1].split(">")[1];
        // } else {
        //     finalText = text;
        // }
        return text;
    }

    handlePageClick = (e) => {
        console.log("e.selected:---", e.selected);
        const selectedPage = e.selected;
        this.setState((prevState) => ({
            ...prevState,
            eventInfo: this.state.eventList[selectedPage],
            isScroll: false,
            messageReq: {
                ...prevState.messageReq,
                page: 1,
                limit: 0,
                id: this.state.eventList[selectedPage]._id
            },
            scouting: []
        }), () => {
            this.props.teamProfileInfo.getTeamScoutFeedSuc = null;
            this.increment();
            this.props.getTeamScoutFeed(this.state.messageReq);
        });
    };


    getScoutScroll = (event) => {
        let bottom = Math.ceil(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight;
        if (bottom && this.state.scouting.length !== 0) {
            this.setState({
                messageReq: {
                    ...this.state.messageReq,
                    page: this.state.messageReq.page + 1,
                    limit: this.state.messageReq.limit
                },
                isScroll: true,
            }, () => {
                this.increment();
                this.props.getTeamScoutFeed(this.state.messageReq);
            })
        }
    }

    redirectDetails = (data) => {
        localStorage.setItem("recapInfo", JSON.stringify(data))
        if (data.eventType === 1) {
            this.props.history.push(`/pwb-event-info/${data._id}`);
        } else {
            this.props.history.push(`/pws-event-info/${data._id}`);
        }
    }

    redirectInfo = (recap) => {
        localStorage.setItem("recapInfo", JSON.stringify(recap));
        let route = process.env.REACT_APP_WEB_LINK + "/pw-player-info";
        window.open(route, "_blank");
    }

    renderMention = () => {
        return (
            this.state.mentionRecap &&
                this.state.mentionRecap.length !== 0 ?
                this.state.mentionRecap.map((recap, i) => {
                    return (
                        <div className="player-list-item" onClick={() => this.redirectInfo(recap)}>
                            <div className="player-profile">
                                <img src={recap.event ? recap.event.eventImages.filePath ? recap.event.eventImages.filePath : imagesArr.default_user : imagesArr.default_user} alt="player" />
                            </div>
                            <div className="media-player-info">
                                <h4>{recap.title}</h4>
                                <p>{moment(recap.date).format("MMMM DD YYYY")}</p>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center">No data found</p>
        )
    }


    render() {
        const { loaderCount, teamData, tab, purchase, packType, eventInfo, pageCount } = this.state;
        let plen = teamData?.stats?.pitchingkeys?.length;
        let blen = teamData?.stats?.battingkeys?.length;
        var upcomingevent = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        };
        return (
            <>
                <Header />
                <div className="team-profile-section main-ranking">
                    <div className="container">
                        <div className="team-profile-details">
                            <div className="team-profile-item team-info-blog">
                                <h3>Team Info</h3>
                                <div className="team-contact">
                                    <div className="team-info">
                                        <div className="team-logo">
                                            <img src={teamData?.teamLogo ? teamData?.teamLogo : imagesArr.default_user} alt="team logo" />
                                        </div>
                                        <div className="team-title">
                                            <p>{teamData?.rosterName}</p>
                                        </div>
                                    </div>
                                    <div className="contact-id">
                                        <div className="contact-input">
                                            <label>Hometown :</label>
                                            <div className="form-group">
                                                <input type="text" className="form-control" value="-" disabled />
                                            </div>
                                        </div>
                                        <div className="contact-input">
                                            <label>PW Record :</label>
                                            <div className="form-group">
                                                <input type="text" className="form-control" value={teamData?.teamRecord ? teamData?.teamRecord : "-"} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="team-profile-item upcoming-schedule">
                                <h3>Upcoming Events</h3>
                                <div className="upcoming-schedule-event">
                                    <Slider {...upcomingevent}>
                                        {
                                            teamData?.upcompingEvent &&
                                                teamData?.upcompingEvent.length !== 0 ?
                                                teamData?.upcompingEvent.map((item, i) => {
                                                    return (
                                                        <div className="event_box">
                                                            <div className="event_card">
                                                                <div className="event_card_img">
                                                                    <img src={item?.eventImages?.filePath ? item?.eventImages?.filePath : imagesArr.default_user} alt="team logo" />
                                                                    <span class="event_card_address">
                                                                        {item?.location &&
                                                                            item?.location.city ?
                                                                            item?.location.city :
                                                                            item?.location.streetAddress ?
                                                                                item?.location && item?.location.streetAddress.split(",")[0] :
                                                                                item?.location.stateRegion}
                                                                    </span>
                                                                </div>
                                                                <div className="event_card_desc">
                                                                    <div className="event_card_logo">
                                                                        <img src={item?.eventLogo && item?.eventLogo.filePath ? item?.eventLogo.filePath : imagesArr.default_user} alt="team logo" />
                                                                    </div>
                                                                    <div className="event_card_titles">
                                                                        <h3>{item?.eventTitle}</h3>
                                                                        <div class="event_card_dates">
                                                                            <p class="date_icon">{moment(item?.startDate).format("DD-MMM")} - {moment(item?.endDate).format("DD-MMM")}</p>
                                                                            {/* <p class="team_icons">{item?.payment.feesByGroups.length ? getSumOfRegisterTeam(item?.payment.feesByGroups) : 0} Team Registered</p> */}
                                                                            <p class="team_icons">{item?.payment.feesByGroups.length ? getAvailableSport(item?.payment.feesByGroups) : 0} Available Spots</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p className="text-center">No data found</p>
                                        }
                                    </Slider>
                                </div>
                            </div>
                            <div className="team-profile-item pastevent-scores">
                                <h3>Event Recaps</h3>
                                <div className="upcoming-schedule-event">
                                    <Slider {...upcomingevent}>
                                        {
                                            teamData?.pastEvent &&
                                                teamData?.pastEvent.length !== 0 ?
                                                teamData?.pastEvent.map((item, i) => {
                                                    return (
                                                        <div className="event_box">
                                                            <div className="event_card">
                                                                <div className="event_card_img">
                                                                    <img src={item?.eventImages?.filePath ? item?.eventImages?.filePath : imagesArr.default_user} alt="team logo" />
                                                                    <span class="event_card_address">
                                                                        {item?.location &&
                                                                            item?.location.city ?
                                                                            item?.location.city :
                                                                            item?.location.streetAddress ?
                                                                                item?.location && item?.location.streetAddress.split(",")[0] :
                                                                                item?.location.stateRegion}
                                                                    </span>
                                                                </div>
                                                                <div className="event_card_desc">
                                                                    <div className="event_card_logo">
                                                                        <img src={item?.eventLogo && item?.eventLogo.filePath ? item?.eventLogo.filePath : imagesArr.default_user} alt="team logo" />
                                                                    </div>
                                                                    <div className="event_card_titles">
                                                                        <h3>{item?.eventTitle}</h3>
                                                                        <div class="event_card_dates">
                                                                            <p class="date_icon">{moment(item?.startDate).format("DD-MMM")} - {moment(item?.endDate).format("DD-MMM")}</p>
                                                                            {/* <p class="team_icons">{item?.payment.feesByGroups.length ? getSumOfRegisterTeam(item?.payment.feesByGroups) : 0} Team Registered</p> */}
                                                                            <p class="team_icons">{item?.payment.feesByGroups.length ? getAvailableSport(item?.payment.feesByGroups) : 0} Available Spots</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p className="text-center">No data found</p>
                                        }
                                    </Slider>
                                </div>
                            </div>
                            <div className="team-profile-item national-rank">
                                <h3>National Rank</h3>
                                <div className="national-rank-list mb-25">
                                    <div className="rank-list">
                                        <div className="rank-status">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input type="text" className="form-control text-center" name="id" value={teamData?.national?.rank ? teamData?.national?.rank : "-"} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>State Rank</h3>
                                <div className="national-rank-list">
                                    <div className="rank-list">
                                        <div className="rank-status">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input type="text" className="form-control text-center" name="id" value={teamData?.state?.rank ? teamData?.state?.rank : "-"} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {console.log('this.state',this.props.match.params.id)}
                                <button className="btn light-green team_claim_btn" onClick={() => this.props.history.push(`/account-creation/${this.props.match.params.id}/1`)}>Claim Your PLAYERS Accounts Create Guardian Accounts</button>
                            </div>
                        </div>
                        <div className="batting-pitching-section mt-60">
                            <div className="list-group-scroll">
                                <ul className="list-group mt-15 mb-15">
                                    <li className={`list-group-item ${tab === 1 ? "active" : ""}`} onClick={() => this.setState({ tab: 1 })}>Pitching</li>
                                    <li className={`list-group-item ${tab === 2 ? "active" : ""}`} onClick={() => this.setState({ tab: 2 })}>Hitting</li>
                                </ul>
                            </div>
                            <div className="individual-stats">
                                <div className="batting-stats stats-blog">
                                    <div className="table-responsive-xl">
                                        <table className="table">
                                            <thead>
                                                {
                                                    tab === 1 ?
                                                        (!localStorage.getItem("dc_stats") || localStorage.getItem("dc_stats") !== "true") ?
                                                            <>
                                                                <th>Pitching</th>
                                                                <th>AB</th>
                                                                <th>R</th>
                                                                <th>H</th>
                                                                <th>RBI</th>
                                                                <th>BB</th>
                                                                <th>K</th>
                                                                <th>AVG</th>
                                                                <th>OBP</th>
                                                                <th>SLG</th>
                                                            </>
                                                            :
                                                            this.renderPitchingHead()
                                                        :
                                                        (!localStorage.getItem("dc_stats") || localStorage.getItem("dc_stats") !== "true") ?
                                                            <>
                                                                <th>Hitting</th>
                                                                <th>AB</th>
                                                                <th>R</th>
                                                                <th>H</th>
                                                                <th>RBI</th>
                                                                <th>BB</th>
                                                                <th>K</th>
                                                                <th>AVG</th>
                                                                <th>OBP</th>
                                                                <th>SLG</th>
                                                            </>
                                                            :
                                                            this.renderBattingHead()
                                                }
                                            </thead>
                                            <tbody>
                                                {
                                                    tab === 1 ?
                                                        (!localStorage.getItem("dc_stats") || localStorage.getItem("dc_stats") !== "true") ?
                                                            <>
                                                                <tr>
                                                                    <td>D. LeMahieu 18</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>A. Judge RF</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>G. Torres SS</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>A. Judge RF</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>G. Torres SS</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                            </>
                                                            :
                                                            this.renderPitchingData()
                                                        :
                                                        (!localStorage.getItem("dc_stats") || localStorage.getItem("dc_stats") !== "true") ?
                                                            <>
                                                                <tr>
                                                                    <td>D. LeMahieu 18</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>A. Judge RF</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>G. Torres SS</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>A. Judge RF</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>G. Torres SS</td>
                                                                    <td>6</td>
                                                                    <td>0</td>
                                                                    <td>2</td>
                                                                    <td>1</td>
                                                                    <td>0</td>
                                                                    <td>1</td>
                                                                    <td>.256</td>
                                                                    <td>.568</td>
                                                                    <td>.232</td>
                                                                </tr>
                                                            </>
                                                            :
                                                            this.renderBattingData()
                                                }
                                                <tr>
                                                    <td colSpan={blen} className="p-0">

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    {
                                        (!localStorage.getItem("dc_stats") || localStorage.getItem("dc_stats") !== "true") &&
                                        <div className="lock_view">
                                            <div className="logo_lock">
                                                <div className="logo_img">
                                                    <img src={imagesArr.dc_stats} />
                                                </div>
                                                <div className="lock_text">
                                                    <p>DC stats displays the top five rankings in all categories.<span onClick={() => this.purchasePW('dcStats')}>Subscribe </span>to one of our Premium Packages to<span onClick={() => this.purchasePW('dcStats')}>see more.</span></p>
                                                </div>
                                                <div className="lock_img" onClick={() => this.purchasePW('dcStats')}>
                                                    <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="team-roster-awards-section mt-60">
                            <div className="team-roster-awards">
                                <div className="team-awaeds-list">
                                    <h3>Team Roster</h3>
                                    <div className="team-awaeds">
                                        <div className="table-responsive-xl">
                                            <table className="table">
                                                <thead>
                                                    <th>Picture</th>
                                                    <th>Name</th>
                                                    <th>Roster</th>
                                                    <th>Position</th>
                                                    {/* <th>Number</th> */}
                                                    <th>Height</th>
                                                    <th>Weight</th>
                                                    <th>Grad Year </th>
                                                    <th>Team </th>
                                                    <th>GPA </th>
                                                    <th>SAT </th>
                                                    <th>ACT </th>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") ?
                                                    //     <>
                                                    //         <tr>
                                                    //             <td>
                                                    //                 <div>
                                                    //                     <img className="user-img" src={imagesArr.cart_user} alt="event logo" />
                                                    //                 </div>
                                                    //             </td>
                                                    //             <td>Tony Simpson</td>
                                                    //             <td>rosterName</td>
                                                    //             <td>B1, B2</td>
                                                    //             <td>5.5</td>
                                                    //             <td>50</td>
                                                    //             <td>2021</td>
                                                    //             <td>Team Name</td>
                                                    //             <td>20</td>
                                                    //             <td>10</td>
                                                    //             <td>05</td>
                                                    //         </tr>
                                                    //         <tr>
                                                    //             <td>
                                                    //                 <div>
                                                    //                     <img className="user-img" src={imagesArr.dashboard_user} alt="event logo" />
                                                    //                 </div>
                                                    //             </td>
                                                    //             <td>Gabriel Bates</td>
                                                    //             <td>rosterName</td>
                                                    //             <td>B1, B2</td>
                                                    //             <td>5.5</td>
                                                    //             <td>50</td>
                                                    //             <td>2021</td>
                                                    //             <td>Team Name</td>
                                                    //             <td>20</td>
                                                    //             <td>10</td>
                                                    //             <td>05</td>
                                                    //         </tr>
                                                    //         <tr>
                                                    //             <td>
                                                    //                 <div>
                                                    //                     <img className="user-img" src={imagesArr.cart_user} alt="event logo" />
                                                    //                 </div>
                                                    //             </td>
                                                    //             <td>Penny Wallace</td>
                                                    //             <td>rosterName</td>
                                                    //             <td>B1, B2</td>
                                                    //             <td>5.5</td>
                                                    //             <td>50</td>
                                                    //             <td>2021</td>
                                                    //             <td>Team Name</td>
                                                    //             <td>20</td>
                                                    //             <td>10</td>
                                                    //             <td>05</td>
                                                    //         </tr>
                                                    //         <tr>
                                                    //             <td>
                                                    //                 <div>
                                                    //                     <img className="user-img" src={imagesArr.dashboard_user} alt="event logo" />
                                                    //                 </div>
                                                    //             </td>
                                                    //             <td>Penny Vaghle</td>
                                                    //             <td>rosterName</td>
                                                    //             <td>B1, B2</td>
                                                    //             <td>5.5</td>
                                                    //             <td>50</td>
                                                    //             <td>2021</td>
                                                    //             <td>Team Name</td>
                                                    //             <td>20</td>
                                                    //             <td>10</td>
                                                    //             <td>05</td>
                                                    //         </tr>
                                                    //         <tr>
                                                    //             <td>
                                                    //                 <div>
                                                    //                     <img className="user-img" src={imagesArr.cart_user} alt="event logo" />
                                                    //                 </div>
                                                    //             </td>
                                                    //             <td>Varun Dhavan</td>
                                                    //             <td>rosterName</td>
                                                    //             <td>B1, B2</td>
                                                    //             <td>5.5</td>
                                                    //             <td>50</td>
                                                    //             <td>2021</td>
                                                    //             <td>Team Name</td>
                                                    //             <td>20</td>
                                                    //             <td>10</td>
                                                    //             <td>05</td>
                                                    //         </tr>
                                                    //     </>
                                                    //     :
                                                        this.renderPlayer()
                                                        }
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") &&
                                            <div className="lock_view">
                                                <div className="logo_lock">
                                                    <div className="logo_img">
                                                        <img src={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} />
                                                    </div>
                                                    <div className="lock_text">
                                                        <p>PW+ displays the top five rankings in all categories.<span onClick={() => this.purchasePW('PW')}>Subscribe </span>to one of our Premium Packages to <span onClick={() => this.purchasePW('PW')}>see more.</span></p>
                                                    </div>
                                                    <div className="lock_img" onClick={() => this.purchasePW('PW')}>
                                                        <img src={localStorage.getItem('company') === "pwb" ? imagesArr.lock : imagesArr.lock_light} />
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                            {/* {
                                (!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") !== "Scout") &&
                                <div className="ranking-banner">
                                    <div className="ranking-banner-logo">
                                        <img src={imagesArr.ranking_banner_img} alt="ranking banner " />
                                        <p>Available Exclusivly Through the DiamondConnect App</p>
                                        <div className="see-more-btn">
                                            <a class="btn ranking-btn" href="">See More</a>
                                        </div>
                                        <span className="lock-icon" onClick={() => this.purchasePW()}>
                                            <img src={imagesArr.lock_icon} alt="lock icon" />
                                        </span>
                                    </div>
                                </div>
                            } */}

                        </div>


                        <div className="batting-pitching-section mt-60 tab-summary-content">
                            <div className="list-group-scroll">
                                <ul className="list-group mt-15 mb-15">
                                    <li className={`list-group-item ${this.state.scoutTabReq.tab === "scout-feed" ? "active" : ""}`} onClick={() => this.changeScoutTab("scout-feed")}>Scout Feed</li>
                                    <li className={`list-group-item ${this.state.scoutTabReq.tab === "award-feed" ? "active" : ""}`} onClick={() => this.changeScoutTab("award-feed")}>Team Awards</li>
                                </ul>
                            </div>
                            {this.state.scoutTabReq.tab === "scout-feed" ?
                                <div className="individual-stats media-content mt-25">
                                    <ReactTooltip className="tooltip" type='dark' html={true} />
                                    <div className="player-list">
                                        <h3 class="section_title">Mentions
                                            {(!localStorage.getItem("subscribe")) ?
                                                ""
                                                :
                                                <img className="info-tooltip" src={imagesArr.info_black} data-tip="<span>
                                        <b>What is it?</b>
                              <p>Here you will see what Event Recaps your name appeared in</p>
                              </span>"/>
                                            }
                                        </h3>
                                        <div className="player-list-group">
                                            {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                                                this.renderMention()
                                                :
                                                <>
                                                    <p className="text-center">You have no mentions at this time. Sign up for a Prospect Wire Showcase.</p>
                                                </>
                                            }
                                        </div>
                                        {(!localStorage.getItem("subscribe") || localStorage.getItem("subscribe") === "Scout" || localStorage.getItem("subscribe") === "Premium" || localStorage.getItem("subscribe") === "Basic" || localStorage.getItem("subscribe") === "Free") ?
                                            ""
                                            :
                                            <PwLock lock={imagesArr.lock} image={localStorage.getItem("company") === "pwb" ? imagesArr.ranking_banner_img : imagesArr.company_logo_2} showLock={true} />
                                        }
                                    </div>


                                    <div className="batting-stats PW-scouting-feed">
                                        <h3 class="section_title">PW Scouting Feed</h3>
                                        {this.state.eventList.length !== 0 &&
                                            <div className="scouting-heading" >
                                                <div className="scouting-title" >
                                                    <img src={eventInfo?.eventLogo?.filePath} alt="player" />{eventInfo?.eventTitle}
                                                </div>
                                                <div className="scouting-btn pw_scouting_btn">
                                                    <button class="btn" onClick={() => (eventInfo?.companies?.name !== "USA Travel Ball" && eventInfo?.companies?.name !== "GOBTournament") ? this.redirectDetails(eventInfo) : ""}>Recap</button>
                                                </div>
                                            </div>}
                                        <div className="scouting-feed-group">
                                            <div className="scouting-feed-media" onScroll={(e) => this.getScoutScroll(e)}>
                                                {this.renderMessage()}
                                            </div>
                                        </div>
                                        {this.state.eventList.length !== 0 &&
                                            <div className="Paginate mt-20">
                                                <ReactPaginate
                                                    previousLabel={"<<"}
                                                    nextLabel={">>"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={1}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className='awards-content'>
                                    <div className="award-table">
                                        <div className="table-responsive-xl">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Event Name</th>
                                                        <th>Date</th>
                                                        <th>Award Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderAward()}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>}
                        </div>

                    </div>
                </div>
                <Footer />
                <Preloader flag={loaderCount > 0 ? true : false} />
                {
                    purchase &&
                    <PurchaseProspectwire
                        flag={purchase}
                        close={this.handlePurchaseClose}
                        type={packType}
                    />
                }
            </>
        )
    }
}

teamProfile.propTypes = {
    getPlayerTeam: PropTypes.func.isRequired,
    getScoutAwardTabDetail: PropTypes.func.isRequired,
    getTeamScoutFeed: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    teamProfileInfo: state.teamProfileInfo
});

export default connect(mapStateToProps, { getPlayerTeam, getScoutAwardTabDetail, getTeamScoutFeed })(teamProfile);

import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";


class productPurchase extends Component {

    nextPage = (url) => {
        document.body.classList.remove('modal-open');
        localStorage.removeItem("payPlans");
        localStorage.removeItem("analyzr");
        localStorage.removeItem("rolPay");
        localStorage.removeItem("dcStats");
        this.props.history.push(url);
    }

    render() {
        const { flag, nextUrl, productImage } = this.props;
        if (flag) {
            document.body.classList.add('modal-open');
        }
        return (
            <>
                {/* sccess_payment modal */}
                <section className={`common-modal ${flag ? 'show' : ''}`}>
                    <div className="modal-dialog">
                        <div className="modal-content sccess_payment">
                            <div className="modal-body">
                                <div className="sccess_payment_inner">
                                    <div className="thumb_up">
                                        <img src={productImage} alt="modal_close" />
                                    </div>
                                    <h4>
                                        Purchase Complete
                                    </h4>
                                    {/* <p>You will be redirected to dashboard</p> */}
                                    <div className="flex-align">
                                        <Link className="btn" onClick={() => this.nextPage(nextUrl)}>Go To {nextUrl === "/pw-leaderboard" ? "PW+" : "Dashboard"}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default withRouter(productPurchase);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../../../containers/header";
import Footer from "../../../../containers/footer";
import CommonHeroBanner from '../../../../components/commonHeroBanner';
import { getLeagueDetails, getLeagueEvents, leagueEventSelect } from '../../../../saga/actions/common/leagueDetails.action';
import { Link } from 'react-router-dom';
import { imagesArr } from "../../../../assets/images";
import ThankYou from '../../../popup/thankYou';
import LeagueRow from './details/leagueRow';
import Preloader from "../../../../components/preloader";
import moment from "moment";
import commonCalls from "../../../../businesslogic/commonCalls";

class leagueSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thanq: false,
            loader: false,
            leagueId: "",
            leagueDetailData: [],
            sendReq: {
                leageId: "",
                page: 0,
                limit: 10,
            },
            leagueEvent: [],
            showCount: 0
        };
        this.commonCall = new commonCalls();
    }

    componentDidMount = () => {
        this.setLoader(true);
        let url = new URL(document.location);
        let leagueId = url.pathname.split("/")[2];
        this.setState({
            leagueId: leagueId,
            sendReq: {
                leageId: leagueId,
                limit: 0,
                page: 0
            }
        }, () => {
            this.getDetails();
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueDetailsReq &&
            nextProps.leagueDetails.leagueDetailsData
        ) {
            nextProps.leagueDetails.leagueDetailsReq = false;
            console.log("nextProps.leagueDetails:--", nextProps.leagueDetails.leagueDetailsData);
            if (nextProps.leagueDetails.leagueDetailsData.flag) {
                this.setState({
                    leagueDetailData: nextProps.leagueDetails.leagueDetailsData.result
                }, () => {
                    nextProps.leagueDetails.leagueDetailsData = null;
                    this.props.getLeagueEvents(this.state.sendReq);
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueDetailsData.message);
                nextProps.leagueDetails.leagueDetailsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueEventsReq &&
            nextProps.leagueDetails.leagueEventsData
        ) {
            nextProps.leagueDetails.leagueEventsReq = false;
            console.log("nextProps.leagueEvents:--", nextProps.leagueDetails.leagueEventsData);
            if (nextProps.leagueDetails.leagueEventsData.flag) {
                this.setState({
                    leagueEvent: nextProps.leagueDetails.leagueEventsData.result.docs
                }, () => {
                    nextProps.leagueDetails.leagueEventsData = null;
                    this.setLoader(false);
                    this.openPopup()
                })
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueEventsData.message);
                nextProps.leagueDetails.leagueEventsData = null;
                this.setLoader(false);
            }
        }

        if (nextProps.leagueDetails &&
            nextProps.leagueDetails.leagueEventSelectReq &&
            nextProps.leagueDetails.leagueEventSelectData
        ) {
            nextProps.leagueDetails.leagueEventSelectReq = false;
            console.log("nextProps.leagueEventSelect:--", nextProps.leagueDetails.leagueEventSelectData);
            if (nextProps.leagueDetails.leagueEventSelectData.flag) {
                nextProps.leagueDetails.leagueEventSelectData = null;
                this.props.getLeagueEvents(this.state.sendReq);
            } else {
                this.commonCall.errorMessage(nextProps.leagueDetails.leagueEventSelectData.message);
                nextProps.leagueDetails.leagueEventSelectData = null;
                this.setLoader(false);
            }
        }
    }

    getDetails = () => {
        this.props.getLeagueDetails(this.state.leagueId, true);
    }

    checkEvent = (eid) => {
        var event = this.state.leagueEvent.filter(_ => _._id === eid)[0]
        if (event.selPer === 2) {
            this.commonCall.errorMessage("You need to tie up with one team before doing event selection.");
        } else {
            let selectEventReq = {
                leageId: this.state.leagueId,
                eventId: eid,
                flag: event.selPer === 0 ? true : false
            }
            console.log("eid:---", selectEventReq);
            this.props.leagueEventSelect(selectEventReq);
        }
    }

    renderEvent = (lcount) => {
        return (
            this.state.leagueEvent.length !== 0 ?
                this.state.leagueEvent.map((list, i) => {
                    const location = list.location && list.location.streetAddress.split(",");
                    return (
                        <div className="w-25" key={i}>
                            <div className="event_box">
                                <div className="event_card ">
                                    <div class="event_card_img">
                                        <img src={list.eventImages && list.eventImages.filePath} alt="event" />
                                        {
                                            (list.location && (list.location.city || list.location.streetAddress || list.location.stateRegion)) &&
                                            <span className="event_card_address">
                                                {list.location &&
                                                    list.location.city ?
                                                    list.location.city :
                                                    list.location.streetAddress ?
                                                        location[0] :
                                                        list.location.stateRegion}
                                            </span>
                                        }
                                        <div className="tablecheckbox event-select-checkbox">
                                            <div className="custom-checkbox">
                                                <label>
                                                    <input type="checkbox" checked={list.selPer === 1 ? true : false} onClick={(e) => this.checkEvent(list._id)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="event_card_desc">
                                        <div class="event_card_logo">
                                            <img src={list.eventLogo && list.eventLogo.filePath ? list.eventLogo.filePath : imagesArr.default_user} alt="event logo" />
                                        </div>
                                        <div class="event_card_titles">
                                            <h3>{list.eventTitle}</h3>
                                            <div class="event_card_dates">
                                                <p class="date_icon">{moment(list.startDate).format("DD-MMM")} - {moment(list.endDate).format("DD-MMM")}</p>
                                                <p class="team_icons">{list.teamLimit} Teams</p>
                                            </div>
                                        </div>
                                        {/* <div class="event_tags"><span class="">Super {lcount}</span></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <p className="text-center w-100">No events found.</p>
        )
    }

    setLoader = (val) => {
        this.setState({
            loader: val
        })
    }

    openPopup = () => {
        var Counter = 0;
        this.state.leagueEvent &&
            this.state.leagueEvent.forEach(event => {
                if (event.selPer === 1) {
                    Counter++;
                }
            });

        this.setState({
            showCount: Counter
        })
        let lcount = this.state.leagueDetailData.payment && this.state.leagueDetailData.payment.eventToChoose && this.state.leagueDetailData.payment.eventToChoose;
        if (lcount === Counter) {
            this.setState({
                thanq: true,
            })
        }
    }

    handleClose = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            thanq: false,
        })
    }
    
    backToDetail = () => {
        document.body.classList.remove("modal-open");
        this.setState({
            thanq: false,
        }, () => {
            this.props.history.push(`/league-detail/${this.state.leagueId}`)
        })
    }

    render() {
        const { loader, thanq, leagueDetailData, leagueId, showCount } = this.state;
        const lcount = leagueDetailData.payment && leagueDetailData.payment.eventToChoose && leagueDetailData.payment.eventToChoose;
        return (
            <>
                <div className="event_details league-selection">
                    <Header />
                    <CommonHeroBanner dataList={leagueDetailData} />
                    <div className="container">
                        <LeagueRow dataList={leagueDetailData} isHideJoinButton={true} leagueId={leagueId} complete={false} isHideAddEventButton={true} />
                    </div>
                    <div className="container-fluid">
                        <div className="choose-super-event d-flex flex-wrap">
                            <div><h3>Choose {lcount} Event(s) <button class="btn light-green">{showCount}</button></h3></div>
                            <div><Link onClick={() => this.backToDetail()}>Back</Link></div>
                        </div>
                        <div className="row">
                            {this.renderEvent(lcount)}
                        </div>
                    </div>
                    <Footer />
                    <Preloader flag={loader} />
                </div>
                {
                    thanq &&
                    <ThankYou
                        flag={thanq}
                        close={this.handleClose}
                        back={this.backToDetail}
                        registeredCount={lcount}
                    />
                }
            </>
        )
    }
}

leagueSelection.propTypes = {
    getLeagueDetails: PropTypes.func.isRequired,
    getLeagueEvents: PropTypes.func.isRequired,
    leagueEventSelect: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leagueDetails: state.leagueDetails,
});
export default connect(mapStateToProps, { getLeagueDetails, getLeagueEvents, leagueEventSelect })(leagueSelection);

import React, { Component } from 'react'
import Purchase from '../../../popup/purchaseProspectwire';
import { imagesArr } from "../../../../assets/images";

class pwLock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchase: false,
        }
    }
    purchaseProspectwire = () => {
        this.setState({
            purchase: true,
        })
    }

    handleClose = () => {
        if (!this.props.strength) {
            document.body.classList.remove("modal-open");
        }

        this.setState({
            purchase: false,
        })
    }
    render() {
        const { lock, image, showLock } = this.props;
        const { purchase } = this.state;
        return (
            <div className="lock_view">
                <div className="logo_lock">
                    <div className="logo_img">
                        <img src={image} />
                    </div>
                    <div className="lock_text">
                        <p>PW+ displays the top five rankings in all categories.<span onClick={() => this.purchaseProspectwire()}>Subscribe </span>to one of our Premium Packages to <span onClick={() => this.purchaseProspectwire()}>see more.</span></p>
                    </div>
                    {
                        showLock &&
                        <div className="lock_img" onClick={() => this.purchaseProspectwire()}>
                            <img src={localStorage.getItem('company')==="pwb"?imagesArr.lock : imagesArr.lock_light} />
                        </div>
                    }
                </div>
                {
                    purchase &&
                    <Purchase
                        flag={purchase}
                        close={this.handleClose}
                        type="PW"
                    />
                }
            </div>
        )
    }
}

export default pwLock;

import React, { Component } from 'react';
import { imagesArr } from "../../../../../assets/images";

export default class socialMediaLink extends Component {
    render() {
        const { dataList } = this.props;
        return (
            <div className="web_description_fullborderbox">
                {/* <div className="web_desc_title">
                    <h3>Event Company</h3>
                </div> */}
                <div className="web_description_box_20">
                    <div className="companyLogos">
                        <img src={dataList?.companies && dataList?.companies?.companyLogo && dataList?.companies?.companyLogo[0]?.filePath} alt="cpmpany logo" />
                    </div>
                    <div className="company_social_link">
                        <a target="_blank" class="fblink nav-link" href={dataList?.companies && dataList?.companies.facebookUrl}>
                            <i class="fab fa-facebook-f"></i>
                            {dataList?.companies && dataList?.companies.facebookLink}
                        </a>
                        <a target="_blank" class="twtlink nav-link" href={dataList?.companies && dataList?.eventTeamType === 2 ? dataList?.companies.showcasetwtUrl : dataList?.companies.twitterUrl}>
                            <i class="fab fa-twitter"></i>
                            {dataList?.companies && dataList?.eventTeamType === 2 ? dataList?.companies.showcasetwtLink : dataList?.companies.twitterLink}
                        </a>
                        {(localStorage.getItem("company") === "pwb" || localStorage.getItem("company") === "pws") &&
                            <>
                                <a target="_blank" class="instalink nav-link" href={dataList?.companies && dataList?.eventTeamType === 2 ? dataList?.companies.showcaseInstaUrl : dataList?.companies.instagramUrl}>
                                    <i class="fab fa-instagram"></i>
                                    {dataList?.companies && dataList?.eventTeamType === 2 ? dataList?.companies.showcaseInstaLink : dataList?.companies.instagramLink}
                                </a>
                            {
                                (localStorage.getItem("company") === "pioneer" || dataList?.isNewCompanyEvent) ? "" :
                                    <a target="_blank" class="tiktoklink nav-link" href={dataList?.companies && dataList?.companies.tiktokUrl}>
                                        <img src={imagesArr.c_tiktok} />
                                        {dataList?.companies && dataList?.companies.tiktokLink}
                                    </a>
                            }
                            </>}
                        {
                          (localStorage.getItem("company") === "pioneer" || (localStorage.getItem("company") === "dc" && dataList?.isNewCompanyEvent)) &&
                            <>
                                <a target="_blank" class="instalink nav-link" href={dataList?.companies && dataList?.eventTeamType === 2 ? dataList?.companies.showcaseInstaUrl : dataList?.companies.instagramUrl}>
                                    <i class="fab fa-instagram"></i>
                                    {dataList?.companies && dataList?.eventTeamType === 2 ? dataList?.companies.showcaseInstaLink : dataList?.companies.instagramLink}
                                </a>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
